import { Component, OnInit, Self, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, InitServ, UtilServ, LoaderServ, CacheService } from '../../../../Services';
import { BkngFormPopupServ } from '../../BkngFormPopup.service';
// Constant
import { MAP_ADDRESS_LINK } from '../../../../Constants';

@Component({
	selector: 'bk-merchant-locations-popup',
	template: `
		<div class="position-relative">
			<div [id]="secId" class="tjs-popup tjs-popup--md">
				<!-- Header -->
				<bk-popup-header [headingId]="section?.title?.id" [heading]="section?.title?.content" [isCloseAll]="false"></bk-popup-header>
				<!-- Content -->
				<div class="tjs-popup__content tjs-popup__body" [id]="section?.listing">
					<div class="row" *ngIf="locations && locations?.length > 0">
						<div *ngFor="let loc of locations; trackBy: utilServ.trackByFnIndex; let isLast = last" class="col-12 col-sm-6 col-md-6 col-lg-4 {{ isLast ? '' : 'mb-15 mb-sm-25'}}">
							<div class="card h-100 rounded-5  position-relative tjs-pointer border tjs-hover-shadow bk-card" [ngClass]="{'active-border': selectedLoc && selectedLoc?.form_location?.id == loc?.form_location?.id}">
								<div class="card-body p-15 pe-40 bk-card-body text--color" (click)="merchantLocSelect(loc)">
									<span>{{loc?.location_name | translate}}</span>
									<ng-container *ngIf="!loc?.hide_ml_address">
										<span>, {{loc?.merchant_location_address | translate}}</span>
										<div *ngIf="loc?.apt">{{'Apt' | translate}} : {{loc?.apt}}</div>
									</ng-container>
								</div>
								<div class="position-absolute d-flex flex-column text-center top-5 end-5 my-10 mx-5">
									<button *ngIf="!loc?.hide_ml_image && loc?.picture" type="button" class="btn btn-link text-decoration-none bk-icon p-0 mb-5" (click)="locPicturePopup(loc?.picture)"><i class="tjsicon-camera"></i></button>
									<a *ngIf="!loc?.hide_ml_address" class="text-decoration-none bk-icon" target="_blank" href="{{mapAddrLink}}{{loc?.merchant_location_address}}">
										<i class="tjsicon-placeholder-1"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Button -->
				<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive border-top-0 d-flex justify-content-end bk-footColor">
					<bk-navigate class="d-block mb-5" [secId]="section?.button?.id" [designId]="section?.button?.design_id" [innerHTML]="section?.button?.content" [secSett]="section?.button" customClass="btn-primary rounded-5" essentialClass="btn w-100 px-40" (callback)="selectLocation()"></bk-navigate>
				</div>
			</div>
			<!-- Loader -->
			<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
		</div>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class MerchantLocationsPopupComponent implements OnInit {
	// Variables
	locations: any;
	selectedLoc: any;
	mapAddrLink: string = MAP_ADDRESS_LINK;
	loaderId: string = 'merchant-location-popup-loader';
	// section data for popup
	slug: string = 'merchant_locations';
	secId: string = '';
	section: any = { title: null, listing: null, button: null };

	constructor(public dialogRef: MatDialogRef<MerchantLocationsPopupComponent>, public initServ: InitServ, private bkngFormPopupServ: BkngFormPopupServ, public utilServ: UtilServ, private toastr: ToastrService, @Self() public destroy: NgOnDestroy, public loader: LoaderServ, private cacheServ: CacheService) { }

	ngOnInit(): void {
		// build popup section
		this.buildSectionData();
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
		});
	}
	/**
	 * Merchant location
	 * @param loc : location
	 */
	public merchantLocSelect(loc: any): void {
		this.selectedLoc = loc;
	}
	/**
	 * Location picture popup
	 * @param locPicture : any
	 */
	public locPicturePopup(locPicture: any): void {
		this.bkngFormPopupServ.picturePreviewPopup(locPicture);
	}
	/**
	 * Selected location and close the popup
	 */
	public selectLocation(): void {
		if (this.selectedLoc) {
			this.dialogRef.close(this.selectedLoc);
		} else {
			this.toastr.error(this.initServ?.appStr?.toastr?.selectLoc);
		}
	}
}
