<ng-container *ngIf="isStarterPlans || initServ.siteData?.theme_settings?.settings?.powered_by_bookingkoala_branding">
	<ng-container [ngSwitch]="type">
		<!-- Text format branding -->
		<ng-container *ngSwitchCase="'text'">
			<div class="powered-by-text {{customWrapClass}}" [ngStyle]="{'color': (initServ.appAdmnStngs && (initServ.appAdmnStngs.package == 'premiumpro' || initServ.appAdmnStngs.package == 'premium' || initServ.appAdmnStngs.package == 'growing') && initServ.siteData?.theme_settings?.settings?.branding_color) ? initServ.siteData?.theme_settings?.settings?.branding_color :''}">
				{{'Powered by' | translate}}
				<a [ngStyle]="{'color': (initServ.appAdmnStngs && (initServ.appAdmnStngs.package == 'premiumpro' || initServ.appAdmnStngs.package == 'premium' || initServ.appAdmnStngs.package == 'growing') && initServ.siteData?.theme_settings?.settings?.branding_color) ? initServ.siteData?.theme_settings?.settings?.branding_color :''}" class="tjs-highlight-link position-relative fw-bold {{customAnchorClass}}" [href]="poweredByLink" target="_blank" translate (click)="poweredByLinkRedirect($event)">BookingKoala</a>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="'link_text'">
			<div class="justify-content-center d-flex {{customWrapClass}}">
				<div class="powered-by-text">
					<a [ngStyle]="{'color': (initServ.appAdmnStngs && (initServ.appAdmnStngs.package == 'premiumpro' || initServ.appAdmnStngs.package == 'premium' || initServ.appAdmnStngs.package == 'growing') && initServ.siteData?.theme_settings?.settings?.branding_color) ? initServ.siteData?.theme_settings?.settings?.branding_color :''}" [href]="poweredByLink" target="_blank" translate>{{'Powered by' | translate}} <span [ngStyle]="{'color': (initServ.appAdmnStngs && (initServ.appAdmnStngs.package == 'premiumpro' || initServ.appAdmnStngs.package == 'premium' || initServ.appAdmnStngs.package == 'growing') && initServ.siteData?.theme_settings?.settings?.branding_color) ? initServ.siteData?.theme_settings?.settings?.branding_color :''}" class="position-relative tjs-highlight-link fw-bold {{customAnchorClass}}">BookingKoala</span></a>

				</div>
			</div>
		</ng-container>
		<!-- BookingKoala Image branding -->
		<ng-container *ngSwitchCase="'img'">
			<div class="{{customWrapClass}}">
				<a [href]="poweredByLink" target="_blank" class="text-decoration-none d-inline-flex">
					<img src="{{initServ.imgBase}}/assets/images/powered-by-bookingkoala.png" alt="powered-by-bookingkoala-logo" width="168" height="36">
				</a>
			</div>
		</ng-container>
		<!-- Default Case -->
		<ng-container *ngSwitchDefault></ng-container>
	</ng-container>
</ng-container>
