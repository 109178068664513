<div [ngClass]="variationId == 'bk_short_form_V3' ? 'excludes_version_3' : 'excludes_version_4'" [formGroup]="excludesForm" [id]="section?.id" *ngIf="section && excludes && excludes.length > 0">
	<!-- Title -->
	<!-- <div class="mb-30" *ngIf="section?.title">
		<h4 class="mb-0 bk-title" [ngClass]="{'display-5 text-center fw-normal' : variationId == 'bk_short_form_V4'}" [id]="section?.title_id"  [innerHTML]="section?.title | safeHtml"></h4>
	</div> -->
	<!-- Is partial cleaning checkbox -->
	<div class="mb-30" [ngClass]="{'col-md-4 mx-auto bg-white rounded p-15' : variationId == 'bk_short_form_V4'}">
		<div class="form-check form-check-inline">
			<input id="partial_cleaning" class="form-check-input " type="checkbox" formControlName="is_partial_cleaning">
			<label for="partial_cleaning" class="form-check-label bk-form-check-label" [id]="sectionIds?.label"  [innerHTML]="section?.excludes | safeHtml"></label>
		</div>
	</div>
	<!-- Excludes block -->
	<div *ngIf="excludesForm.value['is_partial_cleaning']" [ngClass]="{'col-md-7 card p-20 shadow-sm mx-auto text-center form-slider mb-25' : variationId == 'bk_short_form_V4'}">
		<!-- Label -->
		<label class="mb-20 form-label bk-form-label" *ngIf="section?.select_excludes" [id]="sectionIds?.label" [innerHTML]="section?.select_excludes | safeHtml"></label>
		<!-- Excludes -->
		<bk-select-element-block controlName="partial_cleaning" [data]="excludes" [elementForm]="excludesForm" [bookingType]="bookingType" (elementChange)="excludeChange.emit()" [isSlider]="true" [sectionIds]="sectionIds"></bk-select-element-block>
	</div>
</div>
