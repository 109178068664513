import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ } from '../../Services';

@Component({
	selector: 'bk-call-to-action',
	template: `
	<div [id]="secId" class="py-35 py-md-60 tjs-cta--single tjs-bg-dark" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
		<div class="container">
			<div class="text-center d-md-flex justify-content-center align-items-center flex-wrap">
				<!-- Title -->
				<h4 *ngIf="section?.title?.content" [id]="section?.title?.id" class="display-6 fw-bold mb-md-0 mb-30 bk-title section-title" [innerHTML]="section?.title?.content | safeHtml"></h4>
				<!-- Button -->
				<div [id]="section?.button?.id" class="ms-md-70 m-5 bk-btn-group" *ngIf="section?.button && section?.button?.buttons && (section?.button?.buttons).length > 0">
					<div class="tjs-navigate-wrapper">
						<ng-container *ngFor="let btn of section?.button?.buttons;trackBy: secServ.trackByFnIndex">
							<bk-navigate [secSett]="btn" [innerHTML]="btn?.content"></bk-navigate>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class CallToActionComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		button: null
	}
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
	}
}