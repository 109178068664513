<div class="pt-30 pb-20 px-md-30 border-bottom bk-card-border min-height--100" [id]="section?.id" *ngIf="settings && section" [formGroup]="ServiceProviderControl">
	<!-- Title and description -->
	<bk-section-heading [section]="section" [pageSett]="pageSett"></bk-section-heading>
	<!-- app box title closed -->
	<div id="provider-section-id">
		<ng-container *ngIf="admnStngs?.merchant_settings?.providers?.scheduling_type != 'manually'; else manualBlock">
			<div class="row">
				<div class="col-md-6 col-lg-4 align-self-center pe-0 mb-10" *ngIf="customerScheduleOptions.specificProvider">
					<div class="form-check form-check-inline me-0">
						<input id="specific_provider" class="form-check-input" type="checkbox" [(ngModel)]="selectedScheduleOptions.isSpecificProvider" [ngModelOptions]="{standalone: true}" (change)="onProviderTypeChangePart($event, 'specific_provider')">
						<label for="specific_provider" class="form-check-label bk-form-check-label" [id]="section?.same_prov_id" [innerHTML]="section?.specific_prov | safeHtml"></label>
					</div>
				</div>
				<div class="col-md-6 col-lg-4 align-self-center pe-0 mb-10" *ngIf="customerScheduleOptions.previousProvider">
					<div class="form-check form-check-inline me-0">
						<input id="previous_provider" class="form-check-input" type="checkbox" [(ngModel)]="selectedScheduleOptions.isPreviousProvider" [ngModelOptions]="{standalone: true}" (change)="onProviderTypeChangePart($event, 'previous_provider')">
						<label for="previous_provider" class="form-check-label bk-form-check-label" [id]="section?.previous_prov_id" [innerHTML]="section?.previous_prov | safeHtml"></label>
					</div>
				</div>
				<div class="col-md-6 col-lg-4 align-self-center pe-0 mb-10" *ngIf="customerScheduleOptions.waitingList">
					<div class="form-check form-check-inline me-0">
						<input id="unassigned" class="form-check-input" type="checkbox" [(ngModel)]="selectedScheduleOptions.isUnassignedProvider" [ngModelOptions]="{standalone: true}" (change)="onProviderTypeChangePart($event, 'unassigned')">
						<label for="unassigned" class="form-check-label bk-form-check-label">
							<span [id]="section?.waiting_list_id" [innerHTML]="section?.waiting_list | safeHtml"></span>
						</label>
						<bk-tooltip *ngIf="section?.waiting_list_tooltip" customClass="bk-tooltip" [content]="section?.waiting_list_tooltip | translate"></bk-tooltip>
					</div>
				</div>
			</div>
			<!-- section to select specific provider -->
			<div class="mt-15 search-provider-section" *ngIf="customerScheduleOptions.specificProvider && ServiceProviderControl.value['provider_type'] == 'specific_provider'">
				<bk-search-provider [availProvFilters]="availProvFilters" (selectedProv)="getSelectedProvider($event)" (click)="prepareAvailProviderFilters()"></bk-search-provider>
			</div>
			<div class="d-flex" [ngClass]="{'flex-column' : ServiceProviderControl.value['provider_type'] != 'specific_provider', 'flex-column-reverse' : ServiceProviderControl.value['provider_type'] == 'specific_provider'}">
				<div class="row">
					<div class="col-md-6 my-10" *ngIf="ServiceProviderControl.value['provider_type'] != 'specific_provider' || (ServiceProviderControl.value['provider_type'] == 'specific_provider' &&(ServiceProviderControl.controls['provider_details'].value).length > 0 )">
						<ng-container *ngTemplateOutlet="selectDateLayout"></ng-container>
					</div>
					<div class="col-md-6 my-10" *ngIf="ServiceProviderControl.controls['arrival_date'].value">
						<ng-template *ngTemplateOutlet="selectSlotLayout"></ng-template>
					</div>
				</div>
				<div class="position-relative">
					<div *ngIf="(ServiceProviderControl.controls['provider_details'].value).length > 0 && showSelectedProvider && (ServiceProviderControl?.value?.arrival_date || ServiceProviderControl.value['provider_type'] == 'specific_provider')">
						<ng-container *ngFor="let provider of ServiceProviderControl.controls['provider_details'].value">
							<bk-selected-provider [provider]="provider" [ServiceProviderControl]="ServiceProviderControl" [section]="section"></bk-selected-provider>
						</ng-container>
					</div>
					<bk-app-loader [loaderId]="provLoaderId"></bk-app-loader>
				</div>
			</div>
		</ng-container>
		<ng-template #manualBlock>
			<div class="row">
				<div class="col-12 col-md-6 col-lg-4 mb-20 mb-md-0">
					<ng-container *ngTemplateOutlet="selectDateLayout"></ng-container>
				</div>
				<div class="col-12 col-md-6 col-lg-4" *ngIf="ServiceProviderControl.controls['arrival_date'].value">
					<ng-template *ngTemplateOutlet="selectSlotLayout"></ng-template>
				</div>
				<div class="col-md-6 col-lg-4 align-self-center pe-0 mt-20" *ngIf="customerScheduleOptions.waitingList && ServiceProviderControl.controls['arrival_date'].value">
					<div class="form-check form-check-inline me-0">
						<input id="unassigned" class="form-check-input" type="checkbox" [(ngModel)]="isWaitingListVal" [ngModelOptions]="{standalone: true}" (change)="onWaitListChng($event)">
						<label for="unassigned" class="form-check-label bk-form-check-label">
							<span [innerHTML]="section?.waiting_list | safeHtml"></span>
						</label>
						<bk-tooltip *ngIf="section?.unassigned_tooltip" customClass="bk-tooltip" [content]="section?.unassigned_tooltip | translate"></bk-tooltip>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>
<!-- <ng-template #ElseBlockLoader>
	<div class="bkt-custom-loader align-items-center justify-content-center">
		<div><span translate>Please wait while we are getting the available providers for you</span>
			<div class="spot-loader"></div>
		</div>
	</div>
</ng-template> -->

<!-- Template for Select date -->
<ng-template #selectDateLayout>
	<ng-container [formGroup]="ServiceProviderControl">
		<label class="form-label bk-form-label" for="dateFormControlInput" [innerHTML]="section?.sel_date | safeHtml"></label>
		<div class="tjs-form-icon position-relative tjs-pointer" (click)="scheduleCalendarPopup()">
			<input type="text" class="form-control tjs-pointer tjs-bg-white--disabled pe-50 pe-none" id="dateFormControlInput" attr.placeholder="{{'Select a date' | translate}}" [value]="setDateValueCalendar()" [disabled]="true" [readonly]="true" [ngClass]="{'is-invalid': ServiceProviderControl.controls['arrival_date'].hasError('required') && ServiceProviderControl.controls['arrival_date'].touched}">
			<i class="tjsicon-calendar-1 tjs-icon--right position-absolute"></i>
		</div>
		<div class="tjs-error bk-error" *ngIf="ServiceProviderControl.controls['arrival_date'].hasError('required') && ServiceProviderControl.controls['arrival_date'].touched" translate>
			<i class="tjsicon-attention"></i>Please select a date.
		</div>
	</ng-container>
</ng-template>

<!-- Template for Select spot -->
<ng-template #selectSlotLayout>
	<bk-slot
		[BKFrm] = "ServiceProviderControl"
		[section] = "section"
		[selectedDate] = "selectedDate"
		[perDateAvailSpots] = "perDateAvailSpots"
		[availableDates] = "availableDates"
		[notAvailableDates] = "notAvailableDates"
		[availableProviders] = "availableProviders"
		[dateDiscountsArray] = "dateDiscountsArray"
		[dayDiscountsArray] = "dayDiscountsArray"
		[dayDiscountBookings] = "dayDiscountBookings"
		[prefilledData] = "prefilledData"
		[isSpotAvailable]="isSpotAvailable"
		(callScheduleCalendarPopup)="scheduleCalendarPopup()"
		(onSlotChange) = "onSlotChangeFunc($event)">
</bk-slot>
</ng-template>