import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { InitServ, NgOnDestroy, UtilServ } from '../../../../Services';

@Component({
	selector: 'bk-invoice-listing',
	templateUrl: './InvoiceListing.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class InvoiceListingComponent implements OnInit {

	//Variables
	@Input() type: string = 'invoices'; // invoices/giftCards
	@Input() data: any;
	@Input() section:any;

	constructor(public utilServ: UtilServ, private initServ: InitServ) { }

	// eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
	ngOnInit(): void {}

	/**
	 * When an array changes, Angular re-renders the whole DOM tree. But if you use trackBy, Angular will know which element has changed and will only make DOM changes for that particular element.
	 * @param _index
	 * @param freq
	 * @returns frequency id
	 */
	public trackById(_index:any, invoice: any) {
		return invoice?._id || invoice?.inv_id;
	}


	/**
	 * Redirect to invoice page
	 * @param invoice invoice data
	 * @param isPrint boolean variable
	 */
	public invRedirect(invoice: any, isPrint: boolean = false): void {
		if(invoice?.type == 'invoice'){
			this.utilServ.navigateToInvPage('inv_id',invoice?._id, isPrint);
		} else {
			this.utilServ.bkngInvoiceWindow(invoice?.inv_id);
		}
	}

	/**
	 * Download invoice
	 * @param invoice
	 * @param isPrint
	 */
	public download(invoice: any, isPrint: boolean = false): void {
		if(invoice?.type == 'invoice' && invoice?.inv_id){
			let fileName = `${invoice?.file_name}.pdf`;
			let pdfUrl = `${this.initServ.privateCdn}/${invoice?.folder_path}${fileName}`;
			this.utilServ.downloadFiles(pdfUrl, fileName, true);
		} else {
			this.invRedirect(invoice, isPrint);
		}
	}
}
