<div [ngClass]="variationId == 'bk_short_form_V3' ? 'location_version_3' : 'location_version_4'" [id]="section?.id" [formGroup]="locationForm" *ngIf="section && settings && settings?.locations && (settings?.locations).length > 0">
	<!-- Title -->
	<div class="mb-30" *ngIf="section?.title">
		<h4 class="mb-0 bk-title" [id]="sectionIds?.title" [innerHTML]="section?.title | safeHtml" [ngClass]="variationId == 'bk_short_form_V3' ? 'fw-bold fs-24' : 'display-5 text-center fw-normal'"></h4>
	</div>
	<div class="mb-30" [ngClass]="variationId == 'bk_short_form_V3' ? 'w-100' : 'col-md-6 mx-auto bg-white rounded shadow-sm p-20'">
		<label [id]="sectionIds?.label" class="form-label bk-form-label" *ngIf="section?.loc_name_label" [innerHTML]="section?.loc_name_label | safeHtml"></label>
		<div class="tjs-dropdown dropdown d-flex bk-dropdown">
			<!-- Location dropdown -->
			<button class="text-capitalize form-control w-100 tjs-dropdown__toggle bk-dropdown-btn" type="button" id="locationMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{setLocationName | translate}}</button>
			<div class="w-100 tjs-dropdown__menu tjs-shadow--lg dropdown-menu dropdown-menu-end" aria-labelledby="locationMenu">
				<div class="mb-5 px-5">
					<input type="text" class="form-control form-control-sm bk-input" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" [attr.placeholder]="'Search by name' | translate">
				</div>
				<ng-container *ngFor="let location of settings?.locations | customSearch : searchText; let index = index;trackBy: utilServ.trackByFnIndex">
					<div *ngIf="settings?.form_data?.preferred_service_location == 'both'; else specificLoc" class="dropdown-item text-capitalize d-flex align-items-center tjs-pointer bk-dropdown-item" [class.active]="location._id == selectedLocation" (click)="locSelect(location)">
						<span>{{location?.location?.location_name | translate}}</span> &nbsp; <span *ngIf="location?.location?.location_type == 'ML'" class="badge bg-secondary">ML</span>
					</div>
					<ng-template #specificLoc>
						<div *ngIf="location.location.location_type == selectedType" class="tjs-dropdown__link dropdown-item text-capitalize d-flex align-items-center bk-dropdown-link" [class.active]="location._id == selectedLocation" (click)="locSelect(location)">
							{{location?.location?.location_name | translate}}
						</div>
					</ng-template>
				</ng-container>
			</div>
		</div>
	</div>
</div>