import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, InitServ} from '../../../Services';
// Constants
import { CURRENCY_UNICODE } from '../../../Constants';

@Component({
	selector: 'bk-amount-display, [bk-currency]',
	template: `<span class="dir-ltr {{customClass}}" *ngIf="(amount || isZero); else icon">
		<ng-container *ngTemplateOutlet="currencyTemp"></ng-container>{{amount | number : '1.2-2'}}
	</span>
	<ng-template #icon>
		<ng-container *ngTemplateOutlet="currencyTemp"></ng-container>
	</ng-template>
	<!-- Currency template -->
	<ng-template #currencyTemp>
		<span *ngIf="isString; else unicodeStr ">{{unicode}}&nbsp;</span>
		<ng-template #unicodeStr><i class="fa" [innerHTML]="unicode"></i></ng-template>
	</ng-template>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class AmountDisplayComponent implements OnInit {
	// Variables
	@Input() amount?: number | string = 0;
	@Input() isZero: boolean = false;
	@Input() customClass: string = '';
	isString: boolean = false;
	storeCurrency: string = 'USD';
	unicode: any;
	admnStngs: any;

	constructor(public initServ: InitServ ) {
		this.admnStngs = this.initServ.appAdmnStngs;
		if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.store && this.admnStngs.merchant_settings?.store?.store_currency){
			this.storeCurrency = this.admnStngs.merchant_settings.store.store_currency;
		}
	}

	ngOnInit() {
		this.storeCurrency = (!this.storeCurrency) ? 'USD' : (this.storeCurrency).toUpperCase();
		this.unicode = this.generateCode();
		this.amount = this.amount ? (+this.amount) : 0;
	}
	/**
	 * Generate the currency icon based on unicode
	 * @returns icon
	 */
	private generateCode(){
		let unicode = CURRENCY_UNICODE[(this.storeCurrency)];
		if(this.storeCurrency == unicode) {
			this.isString = true;
			return unicode
		}
		return  "&#x"+ unicode +';';
	}
}
