import { Component, Input, OnInit, ViewEncapsulation, Self, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, LoaderServ, ApiServ, UtilServ, InitServ } from '../../../../Services';

@Component({
	selector: 'bk-rating',
	templateUrl: './Rating.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class RatingComponent implements OnInit {
	// Variables
	@Input() prefilledData: any;

	ratingForm!: FormGroup; // Rating form
	loaderId: string = 'bkng-rating-loader';
	// eslint-disable-next-line max-params
	constructor(private loader: LoaderServ, private frmBldr: FormBuilder, private apiServ: ApiServ, private utilServ: UtilServ, @Self() private destroy: NgOnDestroy, private toastr: ToastrService, public initServ: InitServ, private cDRef: ChangeDetectorRef) {
		this.loader.show(this.loaderId);
		// Build form
		this.ratingForm = this.frmBldr.group({
			uid: [null],
			provider_id: [null],
			booking_id: [null],
			rating: [null, [Validators.required]],
			review: [null],
			rate_by_role: ['customer'],
			rated_by: [null]
		});
	}

	ngOnInit(): void {
		if(this.prefilledData) {
			this.filledForm();
		}
		this.loader.hide(this.loaderId);
	}
	/**
	 * Filled the rating form, in case of ratings variable value
	 */
	private filledForm() {
		this.ratingForm.patchValue({
			uid: this.prefilledData.uid,
			provider_id: (this.prefilledData.provider_info && (this.prefilledData.provider_info).length >0) ? this.prefilledData.provider_info[0].id : 0,
			booking_id: this.prefilledData._id,
			rated_by: this.prefilledData.uid
		});
		if(this.prefilledData.ratings && this.prefilledData.ratings.rating) {
			if(this.prefilledData.ratings.id) {
				this.ratingForm.addControl('id', new FormControl(this.prefilledData.ratings.id));
			}
			this.ratingForm.patchValue({
				rating: this.prefilledData.ratings.rating,
				review: this.prefilledData.ratings.review
			});
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Submit the form data
	 */
	public submitForm(): void {
		if(this.ratingForm.valid) {
			let sendData = this.ratingForm.value;
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId);
			let apiMethod: string = sendData.id ? 'PUT' : 'POST';
			let apiName: string = 'BookingRatingV3';
			this.ratingForm.controls['rated_by'].setValue(this.utilServ.userId());
			// this.apiServ.callApi(apiMethod, apiName, this.ratingForm.value).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
			this.apiServ.callApiWithPathVariables(apiMethod, apiName, [sendData.booking_id], sendData).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
		} else {
			this.ratingForm.controls['rating'].markAsTouched();
		}
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res)){
			this.toastr.success(res.message);
			setTimeout(() => {
				window.location.reload();
			}, 200);
		} else {
			if(res && res.message){
				this.toastr.error(res.message);
			}
			if(res.api_status == 8){
				this.loader.show(this.loaderId);
				setTimeout(() => {
					window.location.reload();
				}, 1500)
			}
		}
		this.loader.hide(this.loaderId);
	}
}