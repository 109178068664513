/* eslint-disable no-empty */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-case-declarations */
/* eslint-disable max-depth */
/* eslint-disable max-params */
/* eslint-disable complexity */
import { Injectable, Self } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormArray, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import dayjs from 'dayjs';
declare const parentToTop: any;
import { BehaviorSubject, takeUntil } from 'rxjs';
// External lib
import { TranslateService } from '@ngx-translate/core';
// Services
import { InitServ, UtilServ, PopupServ, SchedulingServ, NgOnDestroy, OverridePrvdrTimeServ } from './index';
import Bugsnag from '@bugsnag/js';
@Injectable({
	providedIn: 'root'
})
export class BkngFormServ {

	onPriceableFieldChange: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);

	// private variables
	private appData: any;
	private admnStngs: any;
	private _shortFromDynamicData: any;
	private _combinationData: any
	public multiStepPlanPerm: boolean = false;
	private _industryFormSett: any;
	private currentUser: any;
	private _customFieldData: any;
	private _selectedEmail: any;
	public elementName: any;
	public isHourlyServiceInvalid: boolean = false;

	// sectionOrder: any = {}; // Section order

	// sectionOrder: any = {
	// 	location:1,
	// 	service:2,
	// 	frequency:3,
	// 	pricing_parameter:4,
	// 	extras:5,
	// 	custom_fields_step_one:6,
	// 	email:7,
	// 	service_provider:8,
	// 	tip_parking:9,
	// 	customer_details:10,
	// 	address_details:11,
	// 	key_info:12,
	// 	special_notes:13,
	// 	discounts:14,
	// 	custom_fields_step_two:15,
	// 	payment_info:16
	// }


	// Getter function
	// @returns combination data
	get getCombinationData(): any { return this._combinationData; }
	// @returns short form data
	get getShortFormData(): any { return this._shortFromDynamicData; }
	// @returns industry form setting
	public get industryFormSett(): any { return this._industryFormSett; }
	// @returns booking form custom fields
	public get customFields(): any { return this._customFieldData; }
	// @returns selected email
	public get selectedEmail(): any { return this._selectedEmail; }

	sectionOrder: any; // Section order
	formMetaData: any;
	constructor(private initServ: InitServ, public utilServ : UtilServ, protected sanitizer: DomSanitizer, private popupServ: PopupServ, public frmBldr: FormBuilder, @Self() private destroy: NgOnDestroy, public translate: TranslateService, public scheduleServ : SchedulingServ, public overridePrvdrTimeServ: OverridePrvdrTimeServ) {
		this.appData = this.initServ.appData; // App data
		this.admnStngs = this.initServ.appAdmnStngs; // App admin settings
		// Current login user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
		// Multi step plan permission
		this.multiStepPlanPerm = this.initServ.appPlansPermission('multi-step-form');
		// User logged in get again current user local storage
		this.initServ.isUserProfile.pipe(takeUntil(this.destroy)).subscribe((value) => {
			if(value){
				this.currentUser = this.utilServ.appLocalStorage();
			}
		});
		// TODO: Remove appload data usage, check again (Lakhvir)
		// this.elementName = this.formElementName();
	}
	// Setter functions
	/**
	 * Set the combination data.
	 */
	public set setCombinationData(data: any){
		this._combinationData = data;
	}
	/**
	 * Set the data for short form
	 */
	public set setShortFormData(data: any){
		this._shortFromDynamicData = data;
	}
	/**
	 * reset the short form data
	 */
	public resetShortFormData(){
		this._shortFromDynamicData = null;
	}
	/**
	 * Set the industry form setting
	 */
	public set setIndustryFormSett(sett:any){
		this._industryFormSett = sett;
	}
	/**
	 * Set the custom fields
	 */
	public set setCustomData(data: any){
		this._customFieldData = data;
	}
	/**
	 * Set the selected email.
	 */
	public set setSelectedEmailForm(email: string){
		this._selectedEmail = email;
	}
	/**
	 * Selected form layout
	 */
	public getFormLayout(industryId: string | number, formId: string | number): string {
		let formLayout: string = '';
		if(this.appData && (this.appData.industries).length > 0 && industryId && formId){
			for(let industry of this.appData.industries){
				if(industryId == industry.id){
					if(industry.forms && (industry.forms).length > 0){
						for(let form of industry.forms){
							if(+formId == +form.form_id){
								if(form.customer_form_layout){
									formLayout = form.customer_form_layout;
									break;
								}
							}
						}
					}
					break;
				}
			}
		}
		if(!this.multiStepPlanPerm && formLayout == 'multi_step'){
			formLayout = 'one_step';
		}
		// Set the multi step form layout
		this.utilServ.setMultiStepForm = formLayout;
		return formLayout;
	}
	/**
	 * Get tab sanitizer style
	 * @param sett parent page setting
	 * @returns
	 */
	public tabStyle(sett: any): any {
		let tabStyle = null;
		let style = '';
		style += sett?.industry_active_tab_color ? `--bk-tab-active-bg: ${sett?.industry_active_tab_color};`:'';
		style += sett?.industry_active_tab_text_color ? `--bk-tab-active-color: ${sett?.industry_active_tab_text_color};`:'';
		style += sett?.industry_active_tab_top_strip_color ? `--bk-tab-strip-active-color: ${sett?.industry_active_tab_top_strip_color};`:'';
		style += sett?.industry_tab_color ? `--bk-tab-bg: ${sett?.industry_tab_color};`:'';
		style += sett?.industry_menu_text_color ? `--bk-tab-menu-color: ${sett?.industry_menu_text_color};`:'';
		if(style){
			tabStyle = this.sanitizer.bypassSecurityTrustStyle(style);
		}
		return tabStyle
	}
	/**
	 * Get the industry name
	 * @param industryId
	 * @returns
	 */
	public industryName(industryId: any): string {
		if(this.appData && (this.appData.industries).length >0){
			for(let industry of this.appData.industries){
				if(industryId == industry.id){
					if(industry.custom_industry_name && industry.custom_industry_name != ''){
						return industry.custom_industry_name;
					} else {
						return industry.industry_name;
					}
				}
			}
		}
		return '';
	}
	/**
	 * Message popup
	 * @param msg : text
	 * @param flag : true/false
	 */
	public msgPopup(msg: any, flag: boolean = false, eleId: any = null, isCenter: boolean = true): void {
		this.popupServ.msgPopup(msg, true, false, isCenter).pipe(takeUntil(this.destroy)).subscribe(() => {
			if(flag){
				if(eleId == 'service_date'){
					setTimeout(()=>{
						window.scrollTo({top: 0, behavior: 'smooth'});
					},100);
				} else {
					let id: any = eleId ? eleId : 'selected-items-section-scroll';
					setTimeout(()=>{
						this.utilServ.scrollToSpecificEle(id);
					},100)
				}
			}
		});
		this.scrollParentToTop();
	}
	// TODO: Anupam remove this function & merge with above method
	/**
	 * Message popup
	 * @param msg : text
	 * @param flag : true/false
	 */
	public newMsgPopup(msg: any, isCustom:boolean= false, msgType:string= '', flag: boolean = false, eleId: any = null): void {
		this.popupServ.newMsgPopup(msg, isCustom, msgType).pipe(takeUntil(this.destroy)).subscribe(() => {
			if(flag){
				if(eleId == 'service_date'){
					setTimeout(()=>{
						window.scrollTo({top: 0, behavior: 'smooth'});
					},100);
				} else {
					let id: any = eleId ? eleId : 'selected-items-section-scroll';
					setTimeout(()=>{
						this.utilServ.scrollToSpecificEle(id);
					},100)
				}
			}
		});
		this.scrollParentToTop();
	}
	/**
	 * Check the visibility of description popup
	 * @param selectedParam: selected form parameter
	 * @returns
	 */
	public isPopupDisplay(selectedParam: any): boolean {
		if(selectedParam){
			if (selectedParam.popup_display_on == 'both' || selectedParam.popup_display_on == 'customer') {
				return true;
			} else if (selectedParam.popup_display_on == 'customer_backend' && (this.currentUser && this.currentUser.token)) {
				return true;
			}
		}
		return false;
	}
	/**
	 * Form paramters message popup
	 * @param selectedParam: selected paramter object
	 */
	public formParamMsgPopup(selectedParam: any): void {
		if(selectedParam && selectedParam.enable_popup){
			if(selectedParam.popup_display_on){
				if(this.isPopupDisplay(selectedParam)){
					// this.msgPopup(selectedParam.enable_popup_text, false, null, false);
					this.newMsgPopup(selectedParam.enable_popup_text);
				}
			} else{
				// this.msgPopup(selectedParam.enable_popup_text, false, null, false);
				this.newMsgPopup(selectedParam.enable_popup_text);
			}
		}
	}
	/**
	 * Remove special characters and spaces
	 * @param option
	 * @returns
	 */
	public removeSpclCharAndSpaces(option: any){
		option = option.replace(/ /g,'');
		option = option.replace(/[^a-zA-Z0-9]/g,'');
		option = option.replace(/\s/g,'');
		return option;
	}
	/**
	 * Get form element combine status
	 */
	public formElemCombStatus(sett: any): any {
		let obj: any = {"package": {}, "items": {}};
		let types = ['package', 'items'];
		for(let type of types){
			// TODO: Remove appload data usage, check again (Lakhvir)
			if(this.utilServ.objHasProp(sett, type) && this.utilServ.checkArrLength(sett[type])){
				for(let val of sett[type]){
					obj[type][val.id]= (val.combined_for_same_spot) ? val.combined_for_same_spot : 'yes' ;
				}
			}
		}
		return obj;
	}
	/**
	 * Get the form element name
	 * @returns object
	 */
	public formElementName(sett: any): any {
		let obj: any = {'package': {}, 'items': {}, 'addons':{}};
		let types = ['package', 'items', 'addons'];
		for(let type of types){
			// TODO: Remove appload data usage, check again (Lakhvir)
			if(this.utilServ.objHasProp(sett, type) && this.utilServ.checkArrLength(sett[type])){
				for(let val of sett[type]) {
					obj[type][val.id] = this.utilServ.getFormParamName(val);
				}
			}
		}
		return obj;
	}
	/**
	 * Get the element name
	 * @param ele: element obejct
	 * @param type: items/package/addons
	 * @returns name
	 */
	public getElementName(ele: any, type: string): string {
		let name: string = '';
		if(this.elementName && this.elementName[type]){
			name = this.elementName[type][ele.id];
		}
		if(!name){
			name = this.utilServ.getFormParamName(ele);
		}
		return name;
	}
	/**
	 * Scroll to top
	 */
	public scrollParentToTop(): void {
		if(typeof(parentToTop) !== 'undefined'){ parentToTop() }
	}
	/**
	 * Create pricing params/area params and extras from url query params.
	 * @param embededPrefilledParams
	 * @returns
	 */
	public createParamObjectFromUrl(embededPrefilledParams: any){
		let pricingParams = [];
		let extras = [];
		for(let key in embededPrefilledParams) {
			/** To build the pricing param for form 1 **/
			if (key && key.includes("pricing_parameter[", 0)) {
				pricingParams.push(this.getSliceObj(embededPrefilledParams, key));
			} else {
				/** To build the area param for form 1 **/
				if (key && key.includes("area_parameter[", 0)) {
					pricingParams.push(this.getSliceObj(embededPrefilledParams, key));
				}
			}
			/** To build the extras params **/
			if (key && key.includes("extras[", 0)) {
				extras.push(this.getSliceObj(embededPrefilledParams, key));
			}
		}
		return [pricingParams, extras];
	}
	/**
	 * Get the slice object
	 * @param embededPrefilledParams
	 * @param key
	 * @returns
	 */
	private getSliceObj(embededPrefilledParams: any, key: any){
		let paramId = key.slice(key.indexOf('[')+1, key.indexOf(']'));
		return {id : +paramId, quantity : +embededPrefilledParams[key]};
	}
	/**
	 * Check the form parameters are disabled or not
	 * @param bookingType: add/edit
	 * @param prefilledData: prefilled data
	 * @param selectedService: selected service
	 * @param isQuoteEditable: is quote editable
	 * @param isFirst: is first(used only service category & frequency)
	 * @param controlName: used in case of `items` only
	 * @returns
	 */
	// eslint-disable-next-line complexity, max-params
	public disabledParamScope(bookingType: string, prefilledData: any, selectedService: any, isQuoteEditable: any, isFirst: boolean = false, controlName: string = '', isCustomerAllowedRes: boolean = true): string | null {
		if(bookingType == 'reschedule'){
			if(prefilledData && (prefilledData.status == 1 || prefilledData.status == 2 || prefilledData.status == 4 || isCustomerAllowedRes == false)){
				return 'disabled';
			} else if(isFirst && prefilledData?.is_first == 0){
				return 'disabled';
			} else{
				if(selectedService){
					if(selectedService?.can_customer_edit == 'yes'){
						return null;
					} else{
						return 'disabled';
					}
				}else{
					return null;
				}
			}
		} else{
			if(isQuoteEditable == false){
				if(controlName){
					if(controlName == 'area_parameter' && prefilledData && prefilledData.area_parameter && prefilledData.area_parameter.quantity){
						return "disabled";
					} else if(prefilledData && prefilledData[controlName] && (prefilledData[controlName]).length > 0){
						return "disabled";
					} else{
						return null
					}
				} else {
					return 'disabled';
				}
			}
			return null;
		}
	}
	/**
	 * Check the form parameter display setting
	 * @param setting: form parameter
	 * @param bookingType: add.edit
	 * @returns boolean
	 */
	public isFormParamDisplay(setting: any, bookingType: string, prefilledData: any = null): boolean {
		if((setting.display_on == 'both') || (setting.display_on == 'customer_backend' && (this.currentUser && this.currentUser.token))) {
			return true;
		} else {
			if(bookingType == 'reschedule' && prefilledData){
				return true;
			}
		}
		return false;
	}
	/**
	 * Check prefilled location id is exist in deleted locations array
	 * @param bookingType: add/edit
	 * @param selectedLoc: selected location
	 * @param prefilledParam: prefilled paramter(service/frequency/pricing parameter/excludes/extra)
	 * @returns
	 */
	private checkDeletedLoc(bookingType: string, selectedLoc: any, prefilledParam: any): boolean {
		// Reschedule
		if(bookingType == 'reschedule' && selectedLoc && prefilledParam && prefilledParam.deleted_locations && (prefilledParam.deleted_locations).length > 0 && (prefilledParam.deleted_locations).includes(selectedLoc)){
			return true;
		}
		return false;
	}
	/**
	 * Check prefilled service is exist in deleted service array
	 * @param bookingType: add/edit
	 * @param selectedService: selected service
	 * @param prefilledParam: prefilled paramter(frequency/pricing parameter/excludes/extra)
	 * @returns
	 */
	private checkDeletedService(bookingType: string, selectedService: any, prefilledParam: any): boolean {
		// Reschedule
		if(bookingType == 'reschedule' && prefilledParam && selectedService && prefilledParam.deleted_service_categories && (prefilledParam.deleted_service_categories).length > 0 && (prefilledParam.deleted_service_categories).includes(selectedService.id) ){
			return true;
		}
		return false;
	}
	/**
	 * Check prefilled frequency is exist in deleted frequency array
	 * @param bookingType: add/edit
	 * @param selectedFrequency: selected frequency
	 * @param prefilledParam: prefilled paramter(service/pricing parameter/excludes/extra)
	 * @returns
	 */
	private checkDeletedFreq(bookingType: string, selectedFrequency: any, prefilledParam: any): boolean {
		// Reschedule
		if(bookingType == 'reschedule' && prefilledParam && selectedFrequency && prefilledParam.deleted_frequencies && (prefilledParam.deleted_frequencies).length > 0 && (prefilledParam.deleted_frequencies).includes(selectedFrequency.form_frequency_data.id) ){
			return true;
		}
		return false;
	}

	// Form location functions
	/**
	 * Check the location is a test location
	 * @param testDataFlushed
	 * @param locations
	 * @returns total test locations count
	 */
	public checkTestLocations(testDataFlushed: any, locations: any): any{
		let totalTestLoc: number = 0
		if(typeof (testDataFlushed) === 'undefined'){
			testDataFlushed = 'yes';
		}
		if(testDataFlushed && testDataFlushed == 'no' && typeof (locations) !== 'undefined'){
			if(locations && locations.length > 0){
				for(let loc of locations){
					if(loc.location.test_data == 'yes') {
						totalTestLoc++;
					}
				}
			}
		}
		return totalTestLoc;
	}
	// Form Service functions
	/**
	 * Check the service availability
	 * @param service: service
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param bookingType: add/edit
	 * @param prefilledData: prefilled data
	 * @param isHourly: isHourly
	 */
	public isServiceAvailable(services: any, selectedLoc: any, prefilledData: any, settings: any, bookingType: any, isHourly: boolean=false) {
		if (services && services.length >0) {
			let count = 0;
			for(let service of services) {
				if(this.serviceVisible(service, settings, selectedLoc, bookingType, prefilledData)){
					if(isHourly){
						if(service.is_hourly_service == 'yes') {
							count = count + 1;
						}
					} else {
						count = count + 1;
					}
				}
			}
			if (count > 1) {
				return true;
			}
		}
		return false;
	}
	/**
	 * Check service visibility based on location
	 * @param service: service
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param bookingType: add/edit
	 * @param prefilledData: prefilled data
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns
	 */
	public serviceVisible(service: any, settings: any, selectedLoc: any, bookingType: string, prefilledData: any, availSett: any = null): boolean {
		let prefilledService: any;
		// Reschedule
		if(['reschedule', 'draft'].includes(bookingType) && prefilledData && prefilledData.service_category){
			if(service.id == prefilledData.service_category){
				prefilledService = service;
			}
		}
		if(this.isFormParamDisplay(service, bookingType,prefilledService)){
			if(this.serviceVisibleForLoc(service, settings, selectedLoc, bookingType, prefilledService) && this.serviceInactive(bookingType, service, prefilledService, availSett)){
				return true;
			}
		}
		return false;
	}
	/**
	 * Check service dependency with selected location
	 * @param service: service
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param prefilledService: prefilled service object
	 * @returns
	 */
	private serviceVisibleForLoc(service: any, settings: any, selectedLoc: any, bookingType: string, prefilledService: any): boolean {
		if(settings && settings.form_data && settings.form_data.service_based_on_location != 'no' && service.based_on_location != 'no'){
			if((selectedLoc && service && service.locations && (service.locations).includes(selectedLoc)) || !selectedLoc){
				return true
			} else {
				return this.checkDeletedLoc(bookingType, selectedLoc, prefilledService);
			}
		}
		return true;
	}
	/**
	 * Check the service inactive
	 * @param bookingType: add/edit
	 * @param availSett: available settings(used this variable edit booking)
	 * @param prefilledService: prefilled service object
	 * @returns
	 */
	private serviceInactive(bookingType: string, service: any, prefilledService: any, availSett: any): boolean {
		if(['reschedule', 'draft'].includes(bookingType) && ((availSett && availSett.inactive && availSett.inactive.service_category && (availSett.inactive.service_category).includes(service.id)) || service.status == 9)) {
			if(prefilledService){
				return true;
			}
			return false;
		}
		return true;
	}

	// Form frequency functions

	/**
	 * Check frequency availability
	 * @param frequencies: frequencies
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param bookingType: add/edit
	 * @param prefilledData: prefilled data
	 * @returns
	 */
	public isFrequencyAvailable(frequencies: any, selectedService: any, selectedLoc: any, prefilledData: any, settings: any, bookingType: any) {
		if (frequencies && frequencies.length > 0) {
			let count = 0;
			for(let freq of frequencies) {
				if(this.frequencyVisible(freq, settings, selectedLoc, selectedService, bookingType, prefilledData)){
					count = count + 1;
				}
			}
			if (count > 1) {
				return true;
			}
		}
		return false;
	}
	/**
	 * Check frequency visibility based on location and service
	 * @param frequency: frequency
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param bookingType: add/edit
	 * @param prefilledData: prefilled data
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns
	 */
	public frequencyVisible(frequency: any, settings: any,selectedLoc: any, selectedService: any, bookingType: string, prefilledData: any, availSett: any = null): boolean {
		let prefilledFreq: any;
		// Reschedule
		if(['reschedule', 'draft'].includes(bookingType) && prefilledData && prefilledData.frequency){
			if(frequency.form_frequency_data.id == prefilledData.frequency){
				prefilledFreq = frequency;
			}
		}
		if(this.isFormParamDisplay(frequency.form_frequency_data, bookingType, prefilledFreq)){
			if(this.freqVisibleForLoc(frequency, settings, selectedLoc, bookingType, prefilledFreq) && this.freqVisibleForServ(frequency, settings, selectedService, bookingType, prefilledFreq) && this.freqInactive(bookingType,frequency,prefilledFreq,availSett)){
				return true;
			}
		}
		return false;
	}
	/**
	 * Check frequency dependency with selected location
	 * @param frequency: frequency
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param bookingType: add/edit
	 * @param prefilledFreq: prefilled frequency data object
	 * @returns
	 */
	private freqVisibleForLoc(frequency: any, settings: any, selectedLoc: any, bookingType: string, prefilledFreq: any): boolean {
		if(settings && settings?.form_data && settings?.form_data?.frequency_based_on_location != 'no'){
			if(frequency?.form_frequency_data?.based_on_location != 'no'){
				// eslint-disable-next-line no-unsafe-optional-chaining
				if((selectedLoc && frequency && frequency?.form_frequency_data && frequency?.form_frequency_data?.locations && (frequency?.form_frequency_data?.locations).includes(selectedLoc)) || !selectedLoc){
					return true;
				} else{
					if(prefilledFreq && prefilledFreq?.form_frequency_data){
						return this.checkDeletedLoc(bookingType, selectedLoc, prefilledFreq?.form_frequency_data);
					}
					return false
				}
			}
		}
		return true;
	}
	/**
	 * Check frequency dependency with selected service category
	 * @param frequency: frequency
	 * @param settings: industry & form based settings
	 * @param selectedService: selected service category
	 * @param bookingType: add/edit
	 * @param prefilledFreq: prefilled frequency data object
	 * @returns
	 */
	private freqVisibleForServ(frequency: any, settings: any, selectedService: any, bookingType: string, prefilledFreq: any): boolean {
		if(settings && settings.form_data && settings.form_data.service_based_on_frequency != 'no'){
			if(selectedService && selectedService.based_on_frequency != 'no'){
				if((selectedService && selectedService.frequencies && (selectedService.frequencies).includes(frequency.form_frequency_data.id) ) || !selectedService){
					return true;
				} else{
					// Reschedule
					if(bookingType == 'reschedule' && prefilledFreq && selectedService && selectedService.deleted_frequencies && (selectedService.deleted_frequencies).length > 0 && (selectedService.deleted_frequencies).includes(prefilledFreq.form_frequency_data.id)){
						return true;
					}
					return false;
				}
			}
		}
		return true;
	}
	/**
	 * Check the frequency inactive
	 * @param prefilledFreq: prefilled frequency object
	 * @param bookingType: add/edit
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns
	 */
	private freqInactive(bookingType: string, frequency: any, prefilledFreq: any,availSett: any = null): boolean {
		if(['reschedule', 'draft'].includes(bookingType) && ((availSett && availSett.inactive && availSett.inactive.frequencies && (availSett.inactive.frequencies).includes(frequency.form_frequency_data.id)) || frequency.form_frequency_data.status == 9)) {
			if(prefilledFreq){
				return true;
			}
			return false;
		}
		return true;
	}

	// Form pricing params function
	/**
	 * Get the pricing parameter category name
	 * @param industryId : industry id
	 * @param formId : form id
	 * @param slug : slug
	 * @returns string
	 */
	public getPricingParamCatName(slug:string, sett: any): string {
		if(sett && this.utilServ.checkArrLength(sett.variable_categories)){
			for(let cat of sett.variable_categories){
				if(cat.slug == slug){
					return cat.name;
				}
			}
		}
		if(slug){
			return slug.replace(/_/g, " ");
		}
		return '';
	}
	/**
	 * Get the pricing parameter value quantity and change count
	 * Depend on location, service and frequency
	 * @param param : pricing param value
	 * @param changeCount : change count
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns object
	 */
	public getPricingParamQuantity(param: any, changeCount: any, settings: any, selectedLocation: any, selectedServiceType: any, selectedFrequency: any, bookingType: string, prefilledData: any, availSett: any = null): any {
		let temp = 0;
		let quantity = 0;
		if(this.pricingParamsValueVisible(param, settings, selectedLocation, selectedServiceType, selectedFrequency, bookingType, prefilledData, availSett)){
			if(temp == 0){
				quantity = param.id;
				temp ++;
				changeCount = changeCount + 1;
			}
			if(param.default){
				quantity = param.id;
			}
		}
		return {
			changeCount: changeCount,
			quantity: +quantity
		};
	}
	/**
	 * Check pricing parameter visibility based on location, service and frequency
	 * @param param: pricing parameters option
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param prefilledData: prefilled data
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	public pricingParamsValueVisible(param: any, settings: any,selectedLoc: any, selectedService: any, selectedFrequency: any, bookingType: string, prefilledParams: any, availSett: any = null): boolean {
		let prefilledParam: any;
		// Reschedule
		if(['reschedule', 'draft'].includes(bookingType) && prefilledParams && prefilledParams[param.id]){
			prefilledParam = param;
		}
		if(this.isFormParamDisplay(param, bookingType, prefilledParam)){
			if(this.pricingParamsVisibleForLoc(param, settings, selectedLoc, bookingType, prefilledParam) && this.pricingParamsVisibleForServ(param, settings, selectedService, bookingType, prefilledParam) && this.pricingParamsVisibleForFreq(param,settings, selectedFrequency, bookingType, prefilledParam) && this.pricingParamsInactive(bookingType, param, prefilledParam, availSett)){
				return true;
			}
		}
		return false;
	}
	/**
	 * Check pricing parameter dependency with selected location
	 * @param param: pricing parameters option
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param bookingType: add/edit
	 * @param prefilledParam: prefilled pricing parameter object
	 * @returns boolean
	 */
	public pricingParamsVisibleForLoc(param: any, settings: any, selectedLoc: any, bookingType: string, prefilledParam: any): boolean {
		if(settings.form_data && settings.form_data.variables_based_on_location != 'no'){
			if(param.based_on_location != 'no'){
				if((selectedLoc && param && param.locations && (param.locations).includes(selectedLoc)) || !selectedLoc){
					return true;
				} else {
					return this.checkDeletedLoc(bookingType, selectedLoc, prefilledParam);
				}
			}
		}
		return true;
	}
	/**
	 * Check pricing parameter dependency with selected service category
	 * @param param: pricing parameters option
	 * @param settings: industry & form based settings
	 * @param selectedService: selected service category
	 * @param bookingType: add/edit
	 * @param prefilledParam: prefilled pricing parameter object
	 * @returns boolean
	 */
	public pricingParamsVisibleForServ(param: any, settings: any, selectedService: any, bookingType: string, prefilledParam: any): boolean {
		if(settings.form_data && settings.form_data.variables_based_on_service != 'no'){
			if(param.based_on_service != 'no'){
				if((selectedService && param && param.service_categories && (param.service_categories).includes(selectedService.id) ) || !selectedService){
					return true;
				} else {
					return this.checkDeletedService(bookingType, selectedService, prefilledParam);
				}
			}
		}
		return true;
	}
	/**
	 * Check pricing parameter dependency with selected frequency
	 * @param param: pricing parameters option
	 * @param settings: industry & form based settings
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param prefilledParam: prefilled pricing parameter data object
	 * @returns boolean
	 */
	public pricingParamsVisibleForFreq(param: any, settings: any, selectedFrequency: any, bookingType: string, prefilledParam: any): boolean {
		if(settings.form_data && settings.form_data.variables_based_on_frequency != 'no'){
			if(param.based_on_frequency != 'no'){
				if((selectedFrequency && param && param.frequencies && (param.frequencies).includes(selectedFrequency.form_frequency_data.id) ) || !selectedFrequency){
					return true;
				} else {
					return this.checkDeletedFreq(bookingType, selectedFrequency, prefilledParam);
				}
			}
		}
		return true;
	}
	/**
	 * Check the pricing params inactive
	 * @param prefilledParam: prefilled frequency data object
	 * @param bookingType: add/edit
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	private pricingParamsInactive(bookingType: string, param: any, prefilledParam: any,availSett: any = null): boolean {
		if(['reschedule', 'draft'].includes(bookingType) && ((availSett && availSett.inactive && availSett.inactive.pricing_parameters && (availSett.inactive.pricing_parameters).includes(param.id)) || param.status == 9)) {
			if(prefilledParam){
				return true;
			}
			return false;
		}
		return true;
	}
	/**
	 * Get the form pricing parameters
	 * @returns array
	 */
	public getFormPricingParamIds(formValue: any): any {
		let pricingParameters: any = formValue.pricing_parameter;
		let ids : any = [];
		if(pricingParameters && pricingParameters.length > 0){
			for(let param of pricingParameters){
				if(param.quantity){
					ids.push(param.quantity);
				}
			}
		}
		return ids;
	}

	// Excludes functions
	/**
	 * Check excludes visibility based on location, service, frequency and pricing parameter
	 * @param exclude: exclude
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param formValue: pricing paramerts ids
	 * @param prefilledParams: prefilled excludes data
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns
	 */
	public excludeVisible(exclude: any, settings: any,selectedLoc: any, selectedService: any, selectedFrequency: any, bookingType: string, formValue: any, prefilledParams: any, availSett: any = null) : boolean {
		let prefilledParam: any;
		// Reschedule
		if(['reschedule', 'draft'].includes(bookingType) && prefilledParams && prefilledParams[exclude.id]){
			prefilledParam = exclude;
		}
		if(this.isFormParamDisplay(exclude, bookingType, prefilledParam)){
			if(this.excludeVisibleForLoc(exclude, settings, selectedLoc, bookingType, prefilledParam) && this.excludeVisibleForServ(exclude, settings, selectedService, bookingType, prefilledParam) && this.excludeVisibleForFreq(exclude, settings, selectedFrequency, bookingType, prefilledParam) && this.excludeVisibleForPricingParams(exclude, settings, formValue, bookingType, prefilledParam) && this.excludeInactive(bookingType, exclude, prefilledParam,availSett)){
				return true;
			}
		}
		return false;
	}
	/**
	 * Check exclude dependency with selected location
	 * @param exclude: exclude
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param bookingType: add/edit
	 * @param prefilledParam: prefilled exclude object
	 * @returns
	 */
	private excludeVisibleForLoc(exclude: any, settings: any, selectedLoc: any, bookingType: string, prefilledParam: any): boolean {
		if(settings && settings.form_data && settings.form_data.exclude_based_on_location != 'no'){
			if(exclude.based_on_location != 'no'){
				if((selectedLoc && exclude && exclude.locations && (exclude.locations).includes(selectedLoc)) || !selectedLoc){
					return true;
				} else{
					return this.checkDeletedLoc(bookingType, selectedLoc, prefilledParam);
				}
			}
		}
		return true;
	}
	/**
	 * Check exclude dependency with selected service category
	 * @param exclude : exclude
	 * @param settings: industry & form based settings
	 * @param selectedService: selected service category
	 * @param bookingType: add/edit
	 * @param prefilledParam: prefilled exclude object
	 * @returns
	 */
	private excludeVisibleForServ(exclude: any, settings: any, selectedService: any, bookingType: string, prefilledParam: any): boolean{
		if(settings && settings.form_data && settings.form_data.exclude_based_on_service != 'no'){
			if(exclude.based_on_service != 'no'){
				if((selectedService && exclude && exclude.service_categories && (exclude.service_categories).includes(selectedService.id)) || !selectedService){
					return true;
				} else{
					return this.checkDeletedService(bookingType, selectedService, prefilledParam);
				}
			}
		}
		return true;
	}
	/**
	 * Check exclude dependency with selected frequency
	 * @param exclude: exclude
	 * @param settings: industry & form based settings
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param prefilledParam: prefilled exclude object
	 * @returns boolean
	 */
	private excludeVisibleForFreq(exclude: any, settings: any, selectedFrequency: any, bookingType: string, prefilledParam: any): boolean {
		if(settings && settings.form_data && settings.form_data.exclude_based_on_frequency != 'no'){
			if(exclude.based_on_frequency != 'no'){
				if((selectedFrequency && exclude && exclude.frequencies && (exclude.frequencies).includes(selectedFrequency.form_frequency_data.id)) || !selectedFrequency){
					return true;
				} else {
					return this.checkDeletedFreq(bookingType, selectedFrequency, prefilledParam);
				}
			}
		}
		return true;
	}
	/**
	 * Get the status of for
	 * @param formParam: exclude/extra
	 * @param id: parameter id
	 * @returns boolean
	 */
	private deleteParamValue(formParam: any, id: any): boolean {
		if(formParam.deleted_variables && (formParam.deleted_variables).length > 0){
			if(!(formParam.deleted_variables).includes(id)){
				return false;
			} else{
				return true;
			}
		} else{
			return false;
		}
	}
	/**
	 * Check exclude dependency with pricing parameter
	 * @param exclude: exclude
	 * @param settings: industry & form based settings
	 * @param formValue: pricing paramerts ids
	 * @param bookingType: add/edit
	 * @param prefilledParam: prefilled exclude object
	 * @returns boolean
	 */
	private excludeVisibleForPricingParams(exclude: any, settings: any, formValue: any, bookingType: string, prefilledParam: any): boolean {
		if(settings && settings.form_data && settings.form_data.exclude_based_on_variables != 'no'){
			if(exclude.based_on_variables != 'no' && formValue.formId == 1){
				let result: boolean = true;
				if(formValue.params && (formValue.params).length > 0){
					for(let id of formValue.params){
						if(!(exclude.variables).includes(+id)){
							if(bookingType == 'reschedule' && prefilledParam){
								result = this.deleteParamValue(exclude, id);
							} else {
								result = false;
								break;
							}
						}
					}
				}
				return result;
			}
		}
		return true;
	}
	/**
	 * Check the exclude inactive
	 * @param prefilledParam: prefilled frequency data object
	 * @param bookingType: add/edit
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	private excludeInactive(bookingType: string, exclude: any, prefilledParam: any,availSett: any = null): boolean {
		if(['reschedule', 'draft'].includes(bookingType) && ((availSett && availSett.inactive && availSett.inactive.excludes && (availSett.inactive.excludes).includes(+exclude.id)) || exclude.status == 9)) {
			if(prefilledParam){
				return true;
			}
			return false;
		}
		return true;
	}
	/**
	 * Reset the excludes
	 * @param BKFrm: booking form
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param prefilledParams: prefilled exclude object
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns status and change count
	 */
	public resetExcludes(BKFrm: any, settings: any, selectedLoc: any, selectedService: any, selectedFrequency: any, bookingType: string, prefilledParams: any, availSett: any = null): any{
		let changeCount = 0;
		let excludesFormArray:any = <FormArray>BKFrm.controls['partial_cleaning'];
		if(excludesFormArray && excludesFormArray.value && (excludesFormArray.value).length > 0 && settings.excludes && (settings.excludes).length > 0){
			let obj = {
				formId: +BKFrm.value.form_id,
				params: this.getFormPricingParamIds(BKFrm.value)
			};
			// Convert exclude array to object
			let settingsObj: any = this.getSettingParamsObj('excludes', settings);
			(excludesFormArray.value).forEach((selectedExclude: { id: any; }) => {
				if(settingsObj && settingsObj[selectedExclude.id]){
					let exclude: any = settingsObj[selectedExclude.id];
					if(!this.excludeVisible(exclude, settings, selectedLoc, selectedService, selectedFrequency, bookingType, obj, prefilledParams, availSett)){
						// Remove exclude
						excludesFormArray.removeAt(excludesFormArray.value.findIndex((exclude: any) => exclude.id === selectedExclude.id));
						changeCount = changeCount + 1;
					}
				}
			});
			return [true, changeCount];
		} else {
			return [false, changeCount];
		}
	}

	// Extra functions

	/**
	 * Check the availability of extras.
	 * @param extras: extras
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param formValue: form parameters ids(pricing parameter, items and area parameter)
	 * @param prefilledExtras: prefilled extra object
	 * @returns boolean
	 */
	public isExtrasAvailable(extras: any, selectedLoc: any, selectedService: any, selectedFrequency: any, formValue: any, prefilledExtras: any, settings: any, bookingType: any){
		if(extras && extras.length>0){
			for(let extra of extras){
				if(this.extraVisible(extra, settings, selectedLoc, selectedService, selectedFrequency, bookingType, formValue, prefilledExtras)){
					return true;
					}
				}
		}
		return false;
	}
	/**
	 * Check extra visibility based on location, service, frequency and form parameters(pricing parameter, items and area parameter)
	 * @param extra: extra
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param formValue: form parameters ids(pricing parameter, items and area parameter)
	 * @param prefilledExtras: prefilled extra object
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	public extraVisible(extra: any, settings: any, selectedLoc: any, selectedService: any, selectedFrequency: any, bookingType: string, formValue: any, prefilledExtras: any, availSett: any = null) : boolean {
		let prefilledExtra: any;
		// Reschedule
		if(['reschedule', 'draft'].includes(bookingType) && prefilledExtras && prefilledExtras[extra.id]){
			prefilledExtra = extra;
		}
		if(this.isFormParamDisplay(extra, bookingType, prefilledExtra)){
			if(this.extraVisibleForLoc(extra, settings, selectedLoc, bookingType, prefilledExtra) && this.extraVisibleForService(extra, settings, selectedService, bookingType, prefilledExtra) && this.extraVisibleForFreq(extra, settings, selectedFrequency, bookingType, prefilledExtra) && this.extraVisibleForFormParams(extra, settings, formValue, bookingType, prefilledExtra) && this.extraInactive(bookingType, extra, prefilledExtra, availSett)){
				return true;
			}
		}
		return false;
	}
	/**
	 * Check extra dependency with selected location
	 * @param extra: extra
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param bookingType: add/edit
	 * @param prefilledExtra: prefilled extra
	 * @returns boolean
	 */
	private extraVisibleForLoc(extra: any, settings: any, selectedLoc: any, bookingType: string, prefilledExtra: any): boolean {
		if(settings && settings.form_data && settings.form_data.extras_based_on_location != 'no'){
			if(extra.based_on_location != 'no'){
				if( (selectedLoc && extra && extra.locations && (extra.locations).includes(selectedLoc) ) || !selectedLoc){
					return true;
				} else {
					return this.checkDeletedLoc(bookingType, selectedLoc, prefilledExtra);
				}
			}
		}
		return true;
	}
	/**
	 * Check extra dependency with selected service
	 * @param extra: extra
	 * @param settings: industry & form based settings
	 * @param selectedService: selected service
	 * @param bookingType: add/edit
	 * @param prefilledExtra: prefilled extra
	 * @returns boolean
	 */
	private extraVisibleForService(extra: any, settings: any, selectedService: any, bookingType: string, prefilledExtra: any): boolean {
		if(settings && settings.form_data && settings.form_data.extras_based_on_service != 'no'){
			if(extra.based_on_service != 'no'){
				if((selectedService && extra && extra.service_categories && (extra.service_categories).includes(selectedService.id)) || !selectedService){
					return true;
				} else {
					return this.checkDeletedService(bookingType, selectedService, prefilledExtra);
				}
			}
		}
		return true;
	}
	/**
	 * Check extra dependency with selected frequency
	 * @param extra: extra
	 * @param settings: industry & form based settings
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param prefilledExtra: prefilled extra
	 * @returns boolean
	 */
	private extraVisibleForFreq(extra: any, settings: any, selectedFrequency: any, bookingType: string, prefilledExtra: any): boolean {
		if(settings && settings.form_data && settings.form_data.extras_based_on_frequency != 'no'){
			if(extra.based_on_frequency != 'no'){
				if((selectedFrequency && extra && extra.frequencies && (extra.frequencies).includes(selectedFrequency.form_frequency_data.id) ) || !selectedFrequency){
					return true;
				} else {
					return this.checkDeletedFreq(bookingType, selectedFrequency, prefilledExtra);
				}
			}
		}
		return true;
	}
	/**
	 * Check extra dependency with selected form parameters(pricing parameter, items and area parameter)
	 * @param extra: extra
	 * @param settings: industry & form based settings
	 * @param formValue: form parameters ids(pricing parameter, items and area parameter)
	 * @param bookingType: add/edit
	 * @param prefilledExtra: prefilled extra
	 * @returns boolean
	 */
	private extraVisibleForFormParams(extra: any, settings: any, formValue: any, bookingType: string, prefilledExtra: any): boolean {
		if(settings && settings.form_data && ((formValue.formId != 3 && settings.form_data.extras_based_on_variables != 'no') || (formValue.formId == 3 && settings.form_data.extras_based_on_item != 'no'))){
			if((formValue.formId != 3 && extra.based_on_variables != 'no') || (formValue.formId == 3 && extra.based_on_items != 'no')){
				// No need to run extras for loop, if issue please check the old structure function `isExtraVisibleForPricingParam`
				if(formValue.ids && formValue.ids.length > 0){
					for(let id of formValue.ids){
						switch(formValue.type){
							case 'pricing_param':
							case 'area_param':
								if(extra.variables && !(extra.variables).includes(id)){
									if(bookingType == 'reschedule' && prefilledExtra){
										return this.deleteParamValue(extra, id);
									}
									return false;
								}
							break;
							case 'items':
								if(extra.items && !(extra.items).includes(id)){
									if(bookingType == 'reschedule' && prefilledExtra){
										if(extra.deleted_items && (extra.deleted_items).length > 0){
											if(!(extra.deleted_items).includes(id)){
												return false;
											} else{
												return true;
											}
										} else{
											return false;
										}
									}
									return false;
								}
							break;
						}
					}
				}
			}
		}
		return true;
	}
	/**
	 * Check the extra inactive
	 * @param prefilledParam: prefilled extra data object
	 * @param bookingType: add/edit
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	private extraInactive(bookingType: string, extra: any, prefilledExtra: any,availSett: any = null): boolean {
		if(['reschedule', 'draft'].includes(bookingType) && ((availSett && availSett.inactive && availSett.inactive.extras && (availSett.inactive.extras).includes(+extra.id)) || extra.status == 9)) {
			if(prefilledExtra){
				return true;
			}
			return false;
		}
		return true;
	}
	/**
	 * Reset the extras
	 * @param BKFrm: booking form control
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param prefilledExtras: prefilled extra
	 * @returns boolean
	 */
	public resetExtras(BKFrm: any, settings: any, selectedLoc: any, selectedService: any, selectedFrequency: any, bookingType: string, prefilledExtras: any, availSett: any = null): any{
		let changeCount = 0;
		let extrasFormArray:any = <FormArray>BKFrm.controls['extras'];
		if(extrasFormArray && extrasFormArray.value && (extrasFormArray.value).length > 0 && settings.extras && (settings.extras).length > 0){
			// Convert extras array to obejct
			let settingsObj: any = this.getSettingParamsObj('extras', settings);
			// Get the form parameters ids(pricing parameters, items, area parameter)
			let formParamsValues: any = this.getFormParamsValue(BKFrm.value);
			(extrasFormArray.value).forEach((selectedExtra: { id: any; }) => {
				if(settingsObj && settingsObj[selectedExtra.id]){
					let extra: any = settingsObj[selectedExtra.id];
					if(!this.extraVisible(extra, settings, selectedLoc, selectedService, selectedFrequency, bookingType, formParamsValues, prefilledExtras, availSett)){
						// Remove extra
						extrasFormArray.removeAt(extrasFormArray.value.findIndex((extra: any) => extra.id === selectedExtra.id));
						changeCount = changeCount + 1;
					}
				}
			});
			return [true, changeCount];
		} else {
			return [false, changeCount];
		}
	}
	/**
	 * Get the form parameters ids(pricing parameters, items, area parameter)
	 */
	public getFormParamsValue(formValue: any): any {
		let formId: any = +formValue.form_id;
		let obj: any = {
			formId: +formId,
			type: null,
			ids: []
		};
		obj['ids'] = [];
		switch(formId) {
			case 1:
				obj['type'] = 'pricing_param';
				obj['ids'] = this.getFormPricingParamIds(formValue);
			break;
			case 2:
			case 3:
				obj['type'] = 'items';
				if(formValue.items && (formValue.items).length > 0){
					for(let itemParam of formValue.items){
						if(itemParam.id != 0){
							obj['ids'].push(itemParam.id);
						}
					}
				}
			break;
			case 4:
				obj['type'] = 'area_param';
				if(formValue.area_parameter){
					obj['ids'].push(+(formValue.area_parameter.id));
				}
			break;
		}
		return obj;
	}

	// Item functions
	/**
	 * Check item visibility based on location, service and frequency
	 * @param item: item
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param prefilledParams: prefilled items object
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns
	 */
	public itemVisible(item: any, itemIndex: any, settings: any, selectedLoc: any, selectedService: any, selectedFrequency: any, bookingType: string, prefilledParams: any, availSett: any) : boolean {
		let prefilledItem: any;
		// Reschedule
		if(['reschedule', 'draft'].includes(bookingType) && prefilledParams && prefilledParams[itemIndex] && prefilledParams[itemIndex][item.id]){
			prefilledItem = item;
		}
		if(this.isFormParamDisplay(item, bookingType, prefilledItem)){
			if(this.itemVisibleForLoc(item, settings, selectedLoc, bookingType, prefilledItem) && this.itemVisibleForService(item, settings, selectedService, bookingType, prefilledItem) && this.itemVisibleForFreq(item, settings, selectedFrequency, bookingType, prefilledItem) && this.itemInactive(bookingType, item, prefilledItem, availSett)){
				return true;
			}
		}
		return false;
	}
	/**
	 * Check item dependency with selected location
	 * @param item: item
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param bookingType: add/edit
	 * @param prefilledItem: prefilled item
	 * @returns
	 */
	private itemVisibleForLoc(item: any, settings: any, selectedLoc: any, bookingType: string, prefilledItem: any): boolean {
		if(settings && settings.form_data && settings.form_data.item_based_on_location != 'no'){
			if(item.based_on_location != 'no'){
				if((selectedLoc && item && item.locations && (item.locations).includes(selectedLoc) ) || !selectedLoc){
					return true;
				} else{
					return this.checkDeletedLoc(bookingType, selectedLoc, prefilledItem);
				}
			}
		}
		return true;
	}
	/**
	 * Check item dependency with selected service
	 * @param item: item
	 * @param settings: industry & form based settings
	 * @param selectedService: selected service
	 * @param bookingType: add/edit
	 * @param prefilledItem: prefilled item
	 * @returns
	 */
	private itemVisibleForService(item: any, settings: any, selectedService: any, bookingType: string, prefilledItem: any): boolean {
		if(settings && settings.form_data && settings.form_data.item_based_on_service != 'no'){
			if(item.based_on_service != 'no'){
				if((selectedService && item && item.service_categories && (item.service_categories).includes(selectedService.id) ) || !selectedService){
					return true;
				} else {
					return this.checkDeletedService(bookingType, selectedService, prefilledItem);
				}
			}
		}
		return true;
	}
	/**
	 * Check item dependency with selected frequency
	 * @param item: item
	 * @param settings: industry & form based settings
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param prefilledItem: prefilled item
	 * @returns boolean
	 */
	private itemVisibleForFreq(item: any, settings: any, selectedFrequency: any, bookingType: string, prefilledItem: any): boolean {
		if(settings && settings.form_data && settings.form_data.item_based_on_frequency != 'no'){
			if(item.based_on_frequency != 'no'){
				if((selectedFrequency && item && item.frequencies && (item.frequencies).includes(selectedFrequency.form_frequency_data.id) ) || !selectedFrequency){
					return true;
				} else {
					return this.checkDeletedFreq(bookingType, selectedFrequency, prefilledItem);
				}
			}
		}
		return true;
	}
	/**
	 * Check the item inactive
	 * @param bookingType: add/edit
	 * @param prefilledItem: prefilled item data object
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	public itemInactive(bookingType: string, item: any, prefilledItem: any,availSett: any = null): boolean {
		if(['reschedule', 'draft'].includes(bookingType) && ((availSett && availSett.inactive && availSett.inactive.items && (availSett.inactive.items).includes(item.id)) || item.status == 9)) {
			if(prefilledItem){
				return true;
			}
			return false;
		}
		return true;
	}
	/**
	 * Get the settings parameters object
	 * Convert array to object
	 * @param type: parameter type(items,package,package_addons)
	 * @param settings:: industry & form based settings
	 * @returns object
	 */
	public getSettingParamsObj(type: any, settings: any): any {
		let obj:any = {}
		if(settings[type] && (settings[type]).length > 0){
			for(let param of settings[type]){
				obj[param.id]=param;
			}
		}
		return obj;
	}
	/**
	 * Function to set the prefilled obj for form 2
	 * @param item
	 * @returns
	 */
	itemF2ArrayObj(item: any): any {
		let obj: any = this.frmBldr.group({
			id: +item.id,
			name: item.name,
			quantity: item.quantity,
			enable_quantity_based: item.enable_quantity_based,
			quantity_based: item.quantity_based,
			can_combine: item.can_combine,
			count_multiple_spots: item.count_multiple_spots,
			packages: this.frmBldr.array([])
		});
		return obj;
	}
	/**
	 * Function to set the prefilled obj for form 2
	 * @param pckg
	 * @returns
	 */
	packageF2ArrayObj(pckg: any): any {
		let obj: any = this.frmBldr.group({
			id: +pckg.id,
			name: pckg.name,
			quantity: pckg.quantity,
			enable_quantity_based: pckg.enable_quantity_based,
			quantity_based: pckg.quantity_based,
			can_combine: pckg.can_combine,
			count_multiple_spots: pckg.count_multiple_spots,
			package_addons: this.frmBldr.array([])
		});
		return obj;
	}
	/**
	 * Function to set the prefilled obj for form 2
	 * @param addon
	 * @returns
	 */
	packageAddonF2ArrayObj(addon: any): any {
		let obj: any = this.frmBldr.group({
			id: addon.id,
			name: addon.name,
			enable_quantity_based: addon.enable_quantity_based,
			quantity_based: addon.quantity_based,
			quantity: addon.quantity
		});
		return obj;
	}
	/**
	 * Reset the items,packages and package addons
	 * @param BKFrm: Booking form control
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param selectedFrequency: selected frequency
	 * @param typeParam: reset_with_zero
	 * @param bookingType: add/edit
	 * @param prefilledParams: prefilled items object
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	public resetItemsForm2(BKFrm: any, settings: any, selectedLoc: any, selectedService: any, selectedFrequency: any, typeParam: string, bookingType: string, prefilledParams: any, availSett: any=null): any {
		let changeCount: any = 0;
		let itemsArray = <FormArray>BKFrm.controls['items'];
		let tempBKFrm : FormGroup = this.frmBldr.group({
			tempItems: this.frmBldr.array([]),
		});
		let tempItemsArray = <FormArray>tempBKFrm.controls['tempItems'];
		if(settings){
			if(itemsArray.value && (itemsArray.value).length > 0) {
				// Get the object of settings parameters
				let settingsObj: any = {
					items: this.getSettingParamsObj('items', settings),
					packages: this.getSettingParamsObj('package', settings),
					addons: this.getSettingParamsObj('package_addons', settings)
				};
				let i: any = 0;
				(itemsArray.value).forEach((selectedItem: any, index: any) => {
					if(selectedItem.id == 0){
						if(typeParam == 'reset_with_zero'){
							changeCount = changeCount + 1;
						} else {
							let itemObj: any = this.itemF2ArrayObj(selectedItem);
							tempItemsArray.push(itemObj);
							// Package and package addons
							changeCount = changeCount + this.resetPkngAndAddons(itemsArray, index, settings, selectedItem, prefilledParams, selectedLoc, selectedService, selectedFrequency, bookingType, availSett, changeCount, settingsObj, tempItemsArray, i);
						}
					} else {
						if(settingsObj && settingsObj['items'] && settingsObj['items'][selectedItem.id]){
							let item = settingsObj['items'][selectedItem.id];
							if(this.itemVisible(item, index, settings, selectedLoc, selectedService, selectedFrequency, bookingType, prefilledParams, availSett)){
								let itemObj: any = this.itemF2ArrayObj(selectedItem);
								tempItemsArray.push(itemObj);
								// Package and package addons
								changeCount = changeCount + this.resetPkngAndAddons(itemsArray, index, settings, selectedItem, prefilledParams, selectedLoc, selectedService, selectedFrequency, bookingType, availSett, changeCount, settingsObj, tempItemsArray, i);
								i = i + 1;
							} else {
								changeCount = changeCount + 1;
							}
						}
					}
				});
			}
		}

		if(changeCount > 0){
			BKFrm.removeControl('items');
			// Add items control again.
			BKFrm.addControl('items',this.frmBldr.array([]));
			let newItemsArray = <FormArray>BKFrm.controls['items'];
			let j : any = 0;
			(tempItemsArray.value).forEach((item: any) => {
				if(item.packages && (item.packages).length > 0){
					let itemObj: any = this.itemF2ArrayObj(item);
					newItemsArray.push(itemObj);
					let packageArray: any = <FormArray>(<FormGroup>(<FormArray>BKFrm.controls['items']).controls[j]).controls['packages'];
					let k : any = 0;
					for(let pckg of item.packages){
						let packageObj: any = this.packageF2ArrayObj(pckg);
						packageArray.push(packageObj);
						let packageAddonArray = (<FormArray>(<FormGroup>(packageArray).controls[k]).controls['package_addons']);
						if(pckg.package_addons && (pckg.package_addons).length > 0 ){
							for(let packageAddon of pckg.package_addons){
								let packageAddonObj: any = this.packageAddonF2ArrayObj(packageAddon);
								packageAddonArray.push(packageAddonObj);
							}
						}
						k = k + 1;
					}
					j = j+ 1;
				}
			});
		}
		return changeCount;
	}
	/**
	 * Reset the package and package addons based on selected item
	 * @param itemsArray: items array control
	 * @param index: item index
	 * @param settings: industry & form based settings
	 * @param selectedItem: selected item
	 * @param prefilledParams: prefilled items object
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param availSett: available settings(used this variable edit booking)
	 * @param changeCount: change count
	 * @param settingsObj: settings object:: packages & addons
	 * @returns any
	 */
	private resetPkngAndAddons(itemsArray: any, itemIndex: any, settings: any, selectedItem: any, prefilledParams: any, selectedLoc: any, selectedService: any, selectedFrequency: any, bookingType: string, availSett: any, changeCount: any, settingsObj: any, tempItemsArray: any, i: any): any {
		let itemIndexGroup: any = <FormGroup>itemsArray.controls[itemIndex];
		let newPackageArray: any = <FormArray>(<FormGroup>(tempItemsArray).controls[i]).controls['packages'];
		if(settings.package && (settings.package).length > 0 && selectedItem.packages && (selectedItem.packages).length > 0){
			let prefilledItem: any = prefilledParams && prefilledParams[itemIndex][selectedItem.id];
			let j: any = 0;
			(selectedItem.packages).forEach((selectedPackage: any) => {
				if(settingsObj && settingsObj['packages'] && settingsObj['packages'][selectedPackage.id]){
					let packag: any = settingsObj['packages'][selectedPackage.id]
					if(this.packageVisible(packag, settings, selectedLoc, selectedService, selectedFrequency, bookingType, selectedItem.id, prefilledItem, availSett)){
						let packageObj: any = this.packageF2ArrayObj(selectedPackage);
						newPackageArray.push(packageObj);
						let newPackageAddonArray = (<FormArray>(<FormGroup>(newPackageArray).controls[j]).controls['package_addons']);
						if(itemIndexGroup && settings.package_addons && (settings.package_addons).length > 0 && selectedPackage.package_addons && (selectedPackage.package_addons).length > 0){
							// Prefilled addons based on item and package
							let prefilledAddons: any = prefilledItem && prefilledItem[packag.id];
							(selectedPackage.package_addons).forEach((selectedPkngAddon: any) => {
								if(settingsObj && settingsObj['addons'] && settingsObj['addons'][selectedPkngAddon.id]){
									let packageAddon: any = settingsObj['addons'][selectedPkngAddon.id];
									if(this.packageAddonVisible(packageAddon, settings, selectedLoc, selectedService, selectedFrequency, bookingType, selectedPackage.id, prefilledAddons, availSett)){
										let packageAddonObj: any = this.packageAddonF2ArrayObj(selectedPkngAddon);
										newPackageAddonArray.push(packageAddonObj);
									}else{
										changeCount = changeCount + 1;
									}
								}
							});
						}
						j = j + 1;
					} else {
						changeCount = changeCount + 1;
					}
				}
			});
		}
		return changeCount;
	}
	/**
	 * Items are available for selected form, item id not be zero
	 * @param items
	 * @returns
	 */
	public checkItemsAvailability(items: any) {
		if (items && items.length > 0) {
			for(let item of items) {
				if (item.id == 0) {
					return false;
				}
			}
		}
		return true;
	}
	/**
	 * check the status of selected item
	 * @param itemId: selected item
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	public statusOfSelectedItem(itemId: any, availSett: any) {
		if (availSett && availSett.inactive && availSett.inactive.items && (availSett.inactive.items).includes(itemId)) {
			return true;
		}
		return false;
	}

	// Item based packages function
	/**
	 * Check package visibility based on location, service, frequency and selected item
	 * @param pckg: package
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param itemId: selected item id
	 * @param prefilledParams: prefilled items object
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	public packageVisible(pckg: any, settings: any, selectedLoc: any, selectedService: any, selectedFrequency: any, bookingType: string, itemId: any, prefilledParams: any, availSett: any) : boolean {
		let prefilledpckg: any;
		// Reschedule
		if(['reschedule', 'draft'].includes(bookingType) && prefilledParams && prefilledParams[pckg.id]){
			prefilledpckg = pckg;
		}
		if(this.isFormParamDisplay(pckg, bookingType, prefilledpckg)){
			if(this.packageVisibleForLoc(pckg, settings, selectedLoc, bookingType, prefilledpckg) && this.packageVisibleForService(pckg, settings, selectedService, bookingType, prefilledpckg) && this.packageVisibleForFreq(pckg, settings, selectedFrequency, bookingType, prefilledpckg) && this.packageVisibleForItem(pckg, settings, itemId, bookingType, prefilledpckg) && this.packageInactive(bookingType,pckg, prefilledpckg, availSett)){
				return true;
			}
		}
		return false;
	}
	/**
	 * Check package dependency with selected location
	 * @param pckg: package
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param bookingType: add/edit
	 * @param prefilledpckg: prefilled package
	 * @returns boolean
	 */
	private packageVisibleForLoc(pckg: any, settings: any, selectedLoc: any, bookingType: string, prefilledpckg: any): boolean {
		if(settings && settings.form_data && settings.form_data.package_based_on_location != 'no'){
			if(pckg.based_on_location != 'no'){
				if((pckg && pckg.locations && (pckg.locations).includes(selectedLoc) ) || !selectedLoc){
					return true;
				} else {
					return this.checkDeletedLoc(bookingType, selectedLoc, prefilledpckg);
				}
			}
		}
		return true;
	}
	/**
	 * Check package dependency with selected service
	 * @param pckg: package
	 * @param settings: industry & form based settings
	 * @param selectedService: selected service
	 * @param bookingType: add/edit
	 * @param prefilledpckg: prefilled package
	 * @returns boolean
	 */
	private packageVisibleForService(pckg: any, settings: any, selectedService: any, bookingType: string, prefilledpckg: any): boolean {
		if(settings && settings.form_data && settings.form_data.package_based_on_service != 'no'){
			if(pckg.based_on_service != 'no'){
				if((selectedService && pckg && pckg.service_categories && (pckg.service_categories).includes(selectedService.id) ) || !selectedService){
					return true;
				} else {
					return this.checkDeletedService(bookingType, selectedService, prefilledpckg);
				}
			}
		}
		return true;
	}
	/**
	 * Check package dependency with selected frequency
	 * @param pckg: package
	 * @param settings: industry & form based settings
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param prefilledpckg: prefilled package
	 * @returns boolean
	 */
	private packageVisibleForFreq(pckg: any, settings: any, selectedFrequency: any, bookingType: string, prefilledpckg: any): boolean {
		if(settings && settings.form_data && settings.form_data.package_based_on_frequency != 'no'){
			if(pckg.based_on_frequency != 'no'){
				if((selectedFrequency && pckg && pckg.frequencies && (pckg.frequencies).includes(selectedFrequency.form_frequency_data.id) ) || !selectedFrequency){
					return true;
				} else{
					return this.checkDeletedFreq(bookingType, selectedFrequency, prefilledpckg);
				}
			}
		}
		return true;
	}
	/**
	 * Check package dependency with selected item
	 * @param pckg: package
	 * @param settings: industry & form based settings
	 * @param itemId: selected item id
	 * @param bookingType: add/edit
	 * @param prefilledpckg: prefilled package
	 * @returns boolean
	 */
	private packageVisibleForItem(pckg: any, settings: any, itemId: any, bookingType: string,prefilledpckg: any): boolean {
		// Note:: itemId == 0 means only packages
		if(itemId != 0 && settings && settings.form_data && settings.form_data.package_based_on_items != 'no'){
			if(pckg.based_on_items != 'no'){
				if(itemId && pckg && pckg.items && (pckg.items).includes(+itemId) ){
					return true;
				} else{
					// Reschedule
					if(bookingType == 'reschedule' && itemId && prefilledpckg && prefilledpckg.deleted_items && (prefilledpckg.deleted_items).length > 0 && (prefilledpckg.deleted_items).includes(+itemId) ){
						return true;
					}
					return false
				}
			}
		}
		return true;
	}
	/**
	 * Check the package inactive
	 * @param bookingType: add/edit
	 * @param prefilledpckg: prefilled package data object
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	public packageInactive(bookingType: string, pckg: any,  prefilledpckg: any, availSett: any = null): boolean {
		if(['reschedule', 'draft'].includes(bookingType) && ((availSett && availSett.inactive && availSett.inactive.packages && (availSett.inactive.packages).includes(pckg.id)) || pckg.status == 9)) {
			if(prefilledpckg){
				return true;
			}
			return false;
		}
		return true;
	}
	/**
	 * Check the status of selected package.
	 */
	public statusOfSelectedPackage(pckgId:any, availSett: any) {
		if (availSett && availSett.inactive && availSett.inactive.packages && (availSett.inactive.packages).includes(pckgId)) {
			return true;
		}
		return false;
	}
	/**
	 * Check the packages can combine
	 * @param formValues : booking form value
	 * @returns boolean
	 */
	public packagesCanCombine(formValues: any): boolean {
		if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.bookings?.multiple_appointments_per_spot == 'yes'){
			if(formValues.items && (formValues.items).length > 0){
				for(let item of formValues.items){
					if(item.packages && (item.packages).length > 0){
						for(let selectedPackage of item.packages){
							if(selectedPackage.can_combine == 'no'){
								return false;
							}
						}
					}
				}
			}
		}
		return true;
	}
	/**
	 * Check package already selected
	 * @param formValues
	 * @returns boolean
	 */
	public isPackageAlreadySelected(formValues: any): boolean{
		if(formValues.items && (formValues.items).length > 0){
			for(let item of formValues.items){
				if(item.packages && (item.packages).length > 0){
					return false;
				}
			}
		}
		return true;
	}

	// Package addons
	/**
	 * Check addon visibility based on location, service, frequency, selected item and selected package
	 * @param addon: addon
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param pkngId: selected package id
	 * @param prefilledAddons: prefilled items object
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	public packageAddonVisible(addon: any, settings: any, selectedLoc: any, selectedService: any, selectedFrequency: any, bookingType: string, pkngId: any, prefilledAddons: any, availSett: any) : boolean {
		let prefilledAddon: any;
		// Reschedule
		if(['reschedule', 'draft'].includes(bookingType) && prefilledAddons && prefilledAddons[addon.id]){
			prefilledAddon = addon;
		}
		if(this.isFormParamDisplay(addon, bookingType, prefilledAddon)){
			if(this.packageAddonVisibleForLoc(addon, settings, selectedLoc, bookingType, prefilledAddon) && this.packageAddonVisibleForService(addon, settings, selectedService, bookingType, prefilledAddon) && this.packageAddonVisibleForFreq(addon, settings, selectedFrequency, bookingType, prefilledAddon) && this.packageAddonVisibleForPackage(addon, settings, pkngId, bookingType, prefilledAddon) && this.packageAddonInactive(bookingType, addon, prefilledAddon, availSett)){
				return true;
			}
		}
		return false;
	}
	/**
	 * Check addon dependency with selected location
	 * @param addon: addon
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param bookingType: add/edit
	 * @param prefilledAddon: prefilled addon
	 * @returns boolean
	 */
	private packageAddonVisibleForLoc(addon: any, settings: any, selectedLoc: any, bookingType: string, prefilledAddon:any): boolean {
		if(settings && settings.form_data && settings.form_data.package_addon_based_on_location != 'no'){
			if(addon.based_on_location != 'no'){
				if((selectedLoc && addon && addon.locations && (addon.locations).includes(selectedLoc) ) || !selectedLoc){
					return true;
				} else {
					return this.checkDeletedLoc(bookingType, selectedLoc, prefilledAddon);
				}
			}
		}
		return true;
	}
	/**
	 * Check addon dependency with selected service
	 * @param addon: addon
	 * @param settings: industry & form based settings
	 * @param selectedService: selected service
	 * @param bookingType: add/edit
	 * @param prefilledAddon: prefilled addon
	 * @returns boolean
	 */
	private packageAddonVisibleForService(addon: any, settings: any, selectedService: any, bookingType: string, prefilledAddon: any): boolean {
		if(settings && settings.form_data && settings.form_data.package_addon_based_on_service != 'no'){
			if(addon.based_on_service != 'no'){
				if((selectedService && addon && addon.service_categories && (addon.service_categories).includes(selectedService.id) ) || !selectedService){
					return true;
				} else{
					return this.checkDeletedService(bookingType, selectedService, prefilledAddon);
				}
			}
		}
		return true;
	}
	/**
	 * Check addon dependency with selected frequency
	 * @param addon: addon
	 * @param settings: industry & form based settings
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param prefilledAddon: prefilled addon
	 * @returns boolean
	 */
	private packageAddonVisibleForFreq(addon: any, settings: any, selectedFreq: any, bookingType: string,prefilledAddon: any): boolean {
		if(settings && settings.form_data && settings.form_data.package_addon_based_on_frequency != 'no'){
			if(addon.based_on_frequency != 'no'){
				if((selectedFreq && addon && addon.frequencies && (addon.frequencies).includes(selectedFreq.form_frequency_data.id) ) || !selectedFreq){
					return true;
				} else{
					return this.checkDeletedFreq(bookingType, selectedFreq, prefilledAddon);
				}
			}
		}
		return true;
	}
	/**
	 * Check addon dependency with selected package
	 * @param addon: addon
	 * @param settings: industry & form based settings
	 * @param pkngId: selected package id
	 * @param bookingType: add/edit
	 * @param prefilledAddon: prefilled addon
	 * @returns boolean
	 */
	private packageAddonVisibleForPackage(addon: any, settings: any, pkngId: any, bookingType: string, prefilledAddon: any): boolean {
		if(settings && settings.form_data && settings.form_data.package_addon_based_on_packages != 'no'){
			if(addon.based_on_packages != 'no'){
				if(pkngId && addon && addon.packages && (addon.packages).includes(+pkngId) ){
					return true;
				} else{
					if(bookingType == 'reschedule' && pkngId && prefilledAddon && prefilledAddon.deleted_packages && (prefilledAddon.deleted_packages).length > 0 && (prefilledAddon.deleted_packages).includes(+pkngId)){
						return true;
					}
					return false;
				}
			}
		}
		return true;
	}
	/**
	 * Check the package addon inactive
	 * @param bookingType: add/edit
	 * @param prefilledAddon: prefilled addon data object
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	public packageAddonInactive(bookingType: string, addon: any, prefilledAddon: any, availSett: any = null): boolean {
		if(['reschedule', 'draft'].includes(bookingType) && ((availSett && availSett.inactive && availSett.inactive.package_addons && (availSett.inactive.package_addons).includes(addon.id)) || addon.status == 9)) {
			if(prefilledAddon){
				return true;
			}
			return false;
		}
		return true;
	}
	/**
	 * check the status of selected package
	 * @param packageAddonId: selected package addon id
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	public statusOfSelectedPackageAddon(packageAddonId: any, availSett: any){
		if(availSett && availSett.inactive && availSett.inactive.package_addons && (availSett.inactive.package_addons).includes(packageAddonId)){
			return true;
		}
		return false;
	}
	// Item addons function (Form 3)
	/**
	 * Reset the items and addons for form 3
	 * @param BKFrm: booking form value
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param prefilledParams: prefilled items object
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns any
	 */
	public resetItemsForm3(BKFrm: any, settings: any, selectedLoc: any, selectedService: any, selectedFrequency: any, bookingType: string, prefilledParams: any, availSett: any=null): any {
		let changeCount: any = 0;
		let itemsArray = <FormArray>BKFrm.controls['items'];
		let tempBKFrm : FormGroup = this.frmBldr.group({
			tempItems: this.frmBldr.array([]),
		});
		let tempItemsArray = <FormArray>tempBKFrm.controls['tempItems'];
		if(itemsArray.value && (itemsArray.value).length > 0) {
			// Get the object of settings parameters
			let settingsObj: any = {
				items: this.getSettingParamsObj('items', settings),
				addons: this.getSettingParamsObj('addons', settings)
			};
			let i : any = 0;
			(itemsArray.value).forEach((selectedItem: any, index: any) => {
				if(settingsObj && settingsObj['items'] && settingsObj['items'][selectedItem.id]){
					let item: any = settingsObj['items'][selectedItem.id];
					if((this.itemVisible(item,index, settings, selectedLoc, selectedService, selectedFrequency, bookingType, prefilledParams, availSett))){
						let itemObj: any = this.itemF3ArrayObj(selectedItem);
						tempItemsArray.push(itemObj);
						if(selectedItem.addons && (selectedItem.addons).length > 0){
							// Prefilled addons based on item and package
							let prefilledAddons: any = prefilledParams && prefilledParams[selectedItem.id];
							(selectedItem.addons).forEach((selectedAddon: any) => {
								let addonArray: any = <FormArray>(<FormGroup>(<FormArray>tempBKFrm.controls['tempItems']).controls[i]).controls['addons'];
								if(settingsObj && settingsObj['addons'] && settingsObj['addons'][selectedAddon.id]){
									let addon: any = settingsObj['addons'][selectedAddon.id];
									if((this.itemAddonVisible(addon, settings, selectedLoc, selectedService, selectedFrequency, bookingType, item.id, prefilledAddons, availSett))){
										let addonObj: any = this.frmBldr.group({id: selectedAddon.id,name: selectedAddon.name});
										addonArray.push(addonObj);
									}else{
										changeCount = changeCount + 1;
									}
								}
							});
						}
						i = i + 1;
					} else {
						changeCount = changeCount + 1;
					}
				}
			})
		}
		if(changeCount > 0){
			BKFrm.removeControl('items');
			// Add items control again.
			BKFrm.addControl('items',this.frmBldr.array([]));
			let newItemsArray = <FormArray>BKFrm.controls['items'];
			let j : any = 0;
			(tempItemsArray.value).forEach((item: any) => {
				if(item.addons && (item.addons).length > 0){
					let itemObj: any = this.itemF3ArrayObj(item);
					newItemsArray.push(itemObj);
					let addonArray: any = <FormArray>(<FormGroup>(<FormArray>BKFrm.controls['items']).controls[j]).controls['addons'];
					for(let addon of item.addons){
						let addonObj: any = this.frmBldr.group({id: addon.id,name: addon.name});
						addonArray.push(addonObj);
					}
					j = j+ 1;
				}
			});
		}
		return changeCount;
	}
	/**
	 * Function to set the prefilled obj
	 * @param item
	 * @returns
	 */
	itemF3ArrayObj(item: any): any {
		let obj: any = this.frmBldr.group({
			id: +item.id,
			name: item.name,
			quantity: item.quantity,
			enable_quantity_based: item.enable_quantity_based,
			quantity_based: item.quantity_based,
			can_combine: item.can_combine,
			count_multiple_spots: item.count_multiple_spots,
			addons: this.frmBldr.array([])
		});
		return obj;
	}
	/**
	 * Check the items can combine
	 * @param formValues: booking form value
	 * @returns : boolean
	 */
	public itemsCanCombine(formValues: any){
		if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.bookings?.multiple_appointments_per_spot == 'yes'){
			if(formValues.items && (formValues.items).length > 0){
				for(let item of formValues.items){
					if(item.can_combine == 'no'){
						return false;
					}
				}
			}
		}
		return true;
	}
	/**
	 * Check addon visibility based on location, service, frequency, selected item and selected item
	 * @param addon: addon
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param itemId: selected item id
	 * @param prefilledAddons: prefilled items object
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	public itemAddonVisible(addon: any, settings: any, selectedLoc: any, selectedService: any, selectedFrequency: any, bookingType: string, itemId: any, prefilledAddons: any, availSett: any) : boolean {
		let prefilledAddon: any;
		// Reschedule
		if(['reschedule', 'draft'].includes(bookingType) && prefilledAddons && prefilledAddons[addon.id]){
			prefilledAddon = addon;
		}
		if(this.isFormParamDisplay(addon, bookingType, prefilledAddon)){
			if(this.itemAddonVisibleForLoc(addon, settings, selectedLoc, bookingType, prefilledAddon) && this.itemAddonVisibleForService(addon, settings, selectedService, bookingType, prefilledAddon) && this.itemAddonVisibleForFreq(addon, settings, selectedFrequency, bookingType, prefilledAddon) && this.itemAddonVisibleForItem(addon, itemId, bookingType, prefilledAddon) && this.itemAddonInactive(bookingType, addon, prefilledAddon, availSett)){
				return true;
			}
		}
		return false;
	}
	/**
	 * Check addon dependency with selected location
	 * @param addon: addon
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param bookingType: add/edit
	 * @param prefilledAddon: prefilled addon
	 * @returns boolean
	 */
	private itemAddonVisibleForLoc(addon: any, settings: any, selectedLoc: any, bookingType: string, prefilledAddon:any): boolean {
		if(settings && settings.form_data && settings.form_data.booking_addon_based_on_location != 'no'){
			if(addon.based_on_location != 'no'){
				if((selectedLoc && addon && addon.locations && (addon.locations).includes(selectedLoc)) || !selectedLoc){
					return true;
				} else {
					return this.checkDeletedLoc(bookingType, selectedLoc, prefilledAddon);
				}
			}
		}
		return true;
	}
	/**
	 * Check addon dependency with selected service
	 * @param addon: addon
	 * @param settings: industry & form based settings
	 * @param selectedService: selected service
	 * @param bookingType: add/edit
	 * @param prefilledAddon: prefilled addon
	 * @returns boolean
	 */
	private itemAddonVisibleForService(addon: any, settings: any, selectedService: any, bookingType: string, prefilledAddon: any): boolean {
		if(settings && settings.form_data && settings.form_data.booking_addon_based_on_service != 'no'){
			if(addon.based_on_service != 'no'){
				if((selectedService && addon && addon.service_categories && (addon.service_categories).includes(selectedService.id)) || !selectedService){
					return true;
				} else{
					return this.checkDeletedService(bookingType, selectedService, prefilledAddon);
				}
			}
		}
		return true;
	}
	/**
	 * Check addon dependency with selected frequency
	 * @param addon: addon
	 * @param settings: industry & form based settings
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param prefilledAddon: prefilled addon
	 * @returns boolean
	 */
	private itemAddonVisibleForFreq(addon: any, settings: any, selectedFreq: any, bookingType: string,prefilledAddon: any): boolean {
		if(settings && settings.form_data && settings.form_data.booking_addon_based_on_frequency != 'no'){
			if(addon.based_on_frequency != 'no'){
				if((selectedFreq && addon && addon.frequencies && (addon.frequencies).includes(selectedFreq.form_frequency_data.id)) || !selectedFreq){
					return true;
				} else{
					return this.checkDeletedFreq(bookingType, selectedFreq, prefilledAddon);
				}
			}
		}
		return true;
	}
	/**
	 * Check addon dependency with selected item
	 * @param addon: addon
	 * @param settings: industry & form based settings
	 * @param itemId: selected item id
	 * @param bookingType: add/edit
	 * @param prefilledAddon: prefilled addon
	 * @returns boolean
	 */
	private itemAddonVisibleForItem(addon: any, itemId: any, bookingType: string, prefilledAddon: any): boolean {
		if(itemId && (addon.items).includes(+itemId) ){
			return true;
		} else{
			if(bookingType == 'reschedule' && itemId && prefilledAddon && prefilledAddon.deleted_items && (prefilledAddon.deleted_items).length > 0 && (prefilledAddon.deleted_items).includes(+itemId)){
				return true;
			}
		}
		return false;
	}
	/**
	 * Check the item addon inactive
	 * @param bookingType: add/edit
	 * @param prefilledAddon: prefilled addon data object
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	public itemAddonInactive(bookingType: string, addon: any, prefilledAddon: any, availSett: any = null): boolean {
		if(['reschedule', 'draft'].includes(bookingType) && ((availSett && availSett.inactive && availSett.inactive.addons && (availSett.inactive.addons).includes(addon.id)) || addon.status == 9)) {
			if(prefilledAddon){
				return true;
			}
			return false;
		}
		return true;
	}
	/**
	 * Check the status of selected addon
	 * @param addonId: selected addon
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns boolean
	 */
	public statusOfSelectedAddon(addonId: any, availSett: any){
		if (availSett && availSett.inactive && availSett.inactive.addons && (availSett.inactive.addons).includes(addonId)) {
			return true;
		}
		return false;
	}

	// Pricing area parameters
	/**
	 * Reset the area pricing parameters
	 * @param BKFrm: booking form
	 * @param settings: industry & form based settings
	 * @param selectedLoc: selected location
	 * @param selectedService: selected service
	 * @param selectedFrequency: selected frequency
	 * @param bookingType: add/edit
	 * @param prefilledParams: prefilled parameters
	 * @param availSett: available settings(used this variable edit booking)
	 * @returns any
	 */
	public resetPricingAreaParams(BKFrm: any, settings: any, selectedLoc: any, selectedService: any, selectedFrequency: any, bookingType: string, prefilledParams: any, availSett: any = null): any {
		let changeCount = 0;
		if(settings.pricing_parameters && (settings.pricing_parameters).length > 0){
			for(let area_parameter of settings.pricing_parameters){
				if(area_parameter.value && (area_parameter.value).length > 0){
					for(let option of area_parameter.value){
						if(BKFrm.controls['area_parameter'].get('id').value == option.id){
							if(!this.pricingParamsValueVisible(option, settings, selectedLoc, selectedService, selectedFrequency, bookingType, prefilledParams, availSett)){
								// Reset the form value
								BKFrm.controls.area_parameter.patchValue({ id : null, name: '' });
								changeCount = changeCount + 1;
							}
						}
					}
				}
			}
		}
		return changeCount;
	}

	// Custom fields functions
	/**
	 * Get the form required data for get the custom fields
	 * @param formValues: booking form value
	 * @returns object
	 */
	public getCustomFieldsReqData(formValues: any): any {
		let requiredData: any = null;
		if(formValues){
			requiredData = {
				// industry_id: formValues.industry_id,
				form_id: formValues.form_id,
				location_id: formValues.location_id,
				// base_location_id: formValues.base_location_id,
				service_category: formValues.service_category,
				is_service_hourly: (formValues?.is_service_hourly && formValues?.is_service_hourly == 'yes') ? true : false,
				frequency: formValues.frequency,
				extras: [],
				is_customer_new: (this.initServ?.userInfo && this.initServ?.userInfo?._id) ? (this.initServ.userInfo?.is_new) ? true : false : true
			};
			// Extras
			let extras: any = []
			if(formValues && formValues.extras && (formValues.extras).length > 0){
				for(let extra of formValues.extras){
					extras.push(extra.id);
				}
			}
			requiredData['extras'] = extras;
			switch(formValues.form_id){
				case 1:
					// Pricing parameters
					let pricingParams: any = [];
					if(formValues.pricing_parameter && (formValues.pricing_parameter).length > 0){
						for(let param of formValues.pricing_parameter){
							if(param.quantity){
								pricingParams.push(param.quantity);
							}
						}
					}
					requiredData['pricing_parameters'] = pricingParams;
				break;
				case 2:
				case 3:
					// For form 2:: items and packages
					// For form 3:: items and addons
					let items: any = [];
					let packages: any = [];
					let addons: any = [];
					if(formValues.items && (formValues.items).length > 0){
						for(let item of formValues.items){
							if(!items.includes(item.id)){
								items.push(item.id);
							}
							if(formValues.form_id == 2 && item.packages && (item.packages).length > 0){
								for(let pckg of item.packages){
									if(!packages.includes(pckg.id)){
										packages.push(pckg.id);
									}
								}
							}
							if(formValues.form_id == 3 && item.addons && (item.addons).length > 0){
								for(let addon of item.addons){
									if(!addons.includes(addon.id)){
										addons.push(addon.id);
									}
								}
							}
						}
					}
					if(items.includes(0)){
						items = null;
					}
					requiredData['items'] = items;
					if(formValues.form_id == 2){
						requiredData['package'] = packages;
					}
					if(formValues.form_id == 3){
						requiredData['addons'] = addons;
					}
				break;
				case 4:
					if(formValues.area_parameter && formValues.area_parameter.id){
						requiredData['pricing_parameters'] = [formValues.area_parameter.id];
					}
				break;
			}
		}
		return requiredData;
	}
	// Tip & parking functions
	/**
	 * Tip & parking visibility
	 * @param selectedService: selected service
	 * @param locationType: SA/ML
	 * @param type: tip and parking
	 * @returns boolean
	 */
	public tipParkingVisible(selectedService: any, locationType: string, type: string = 'tip'): boolean {
		if (selectedService && selectedService['enable_'+type]) {
			let displayOnStatus: boolean = false;
			let locationStatus: boolean = false;
			if (selectedService[type].display_on == 'both' || (selectedService[type].display_on == 'customer_backend' && (this.currentUser && this.currentUser.token))) {
				displayOnStatus = true;
			} else {
				displayOnStatus = false;
			}
			if (selectedService[type].locations == 'both' || selectedService[type].locations == locationType) {
				locationStatus = true;
			} else {
				locationStatus = false;
			}
			if (displayOnStatus && locationStatus) {
				return true;
			}
		}
		return false;
	}

	// Discount section functions

	/**
	 * Check Coupon dependency on (booking date, industry, form, location, frequency, customer type, min/max order price)
	 * @param coupon : coupon data
	 * @param formValues: form value
	 * @param settings: industry & form based settings
	 * @param prefilledData: prefilled data
	 * @returns
	 */
	public checkCoupon(coupon: any, formValues: any, settings: any, prefilledData: any = null){
		let errorMsg: any = '';
		// Coupon dependency on booking date
		let bkngDateStatus = this.couponBasedOnBkngForm(formValues,coupon, errorMsg);
		if(bkngDateStatus){
			return bkngDateStatus;
		}
		// Coupon dependency on selected industry params (industry, form, location, service category)
		let bkngIndusStatus = this.couponBasedOnIndus(formValues,coupon, errorMsg);
		if(bkngIndusStatus){
			return bkngIndusStatus;
		}
		// Coupon dependency on frequency
		let bkngFreqStatus = this.couponBasedOnFreq(settings, formValues,coupon, errorMsg);
		if(bkngFreqStatus){
			return bkngFreqStatus;
		}
		// Coupon dependency on customer type
		let bkngCustTypeStatus = this.couponBasedOnCustType(formValues, coupon, prefilledData, errorMsg);
		if(bkngCustTypeStatus){
			return bkngCustTypeStatus;
		}
		// Coupon dependency on min/max order also applicable in case of adjusted price
		let bkngMinMaxPriceStatus = this.couponBasedOnMinMax(formValues, coupon, errorMsg);
		if(bkngMinMaxPriceStatus){
			return bkngMinMaxPriceStatus;
		}
		return [true, ''];
	}
	/**
	 * Coupon dependency on booking date
	 * @param formValues: form value
	 * @param coupon: selected coupon data
	 * @param errorMsg: error message
	 * @returns : [status, errorMsg] | null
	 */
	private couponBasedOnBkngForm(formValues: any,coupon:any, errorMsg: string): any {
		if(formValues.booking_date){
			let couponStartDate: any = (dayjs(coupon.start_date).valueOf())/1000;
			let couponEndDate: any = 0;
			if(coupon.end_date){
				couponEndDate = (dayjs(coupon.end_date).valueOf())/1000;
			}
			let bookingDateString = formValues.booking_date;
			let bookingDate: any = (dayjs(bookingDateString).valueOf())/1000;
			let bookingDay: any = (dayjs(bookingDateString).format('dddd')).toLowerCase();
			// Coupon not specific date
			if(!coupon.use_on_specific_date || !coupon.specific_dates.includes(bookingDateString)){
				if(coupon.days && (coupon.days).length > 0){
					if(!coupon.days.includes(bookingDay)){
						errorMsg = 'Coupon is not applicable for this day.';
						return [false, errorMsg];
					} else {
						// End data is empty
						if(couponEndDate == 0){
							// Compare start date to booking date
							if(!(couponStartDate <= bookingDate)){
								errorMsg = 'Coupon is not applicable for this date.';
								return [false, errorMsg];
							}
						} else {
							// Compare start date and end date to booking date
							if(!(couponStartDate <= bookingDate && couponEndDate >= bookingDate) ){
								errorMsg = 'Coupon is not applicable for this date.';
								return [false, errorMsg];
							}
						}
					}
				} else {
					errorMsg = 'Coupon is not applicable for this day.';
					return [false, errorMsg];
				}
			}
		}
		return null;
	}
	/**
	 * Coupon dependency on selected industry params (industry, form, location, service category)
	 * @param formValues: form value
	 * @param coupon: selected coupon data
	 * @param errorMsg: error message
	 * @returns : [status, errorMsg] | null
	 */
	private couponBasedOnIndus(formValues: any, coupon:any, errorMsg: string): any {
		let industryId: any = +formValues.industry_id;
		if(coupon.industries && (coupon.industries).length > 0 && coupon.industries.includes(industryId)){
			let formId: any = +formValues.form_id;
			let formData: any;
			if(coupon.industry_set && coupon.industry_set[industryId] && (coupon.industry_set[industryId]).length > 0){
				for(let industry of coupon.industry_set[industryId]){
					if(industry.form_id == formId){
						formData = industry;
						break;
					}
				}
			}
			if(formData){
				let bookingService = +formValues.service_category;
				if(formData.service_category && ((formData.service_category).length > 0) && formData.service_category.includes(bookingService)){
					let bookingLocation = +formValues.location_id;
					if(bookingLocation != 0 && ((!formData.locations) || (formData.locations && ((formData.locations).length > 0) && !formData.locations.includes(bookingLocation)))){
						errorMsg = 'Coupon is not applicable for this location.';
						return [false, errorMsg];
					}
				} else{
					errorMsg = 'Coupon is not applicable for this service.';
					return [false, errorMsg];
				}
			} else{
				errorMsg = 'Coupon is not applicable for this form.';
				return [false, errorMsg];
			}
		} else {
			if(coupon.can_used_by != '' && coupon.can_used_by !== undefined){
			} else{
				errorMsg = 'Coupon is not applicable for this industry.';
				return [false, errorMsg];
			}
		}
		return null;
	}
	/**
	 * Coupon dependency on frequency
	 * @param settings: selected industry & form settings
	 * @param formValues: form value
	 * @param coupon: selected coupon data
	 * @param errorMsg: error message
	 * @returns : [status, errorMsg] | null
	 */
	private couponBasedOnFreq(settings: any, formValues: any, coupon: any, errorMsg: string): any {
		if(settings.frequencies && (Object.keys(settings.frequencies)).length > 0 && settings.frequencies[+formValues.frequency]){
			let bookingFrequency: any = settings.frequencies[+formValues.frequency];
			if(bookingFrequency && bookingFrequency.occurence_time && bookingFrequency.occurence_time == 'recurring'){
				if(!coupon.applicable_for_recurring){
					errorMsg = 'Coupon is not applicable for recurring bookings.';
					return [false, errorMsg];
				}
			} else{
				if(!coupon.applicable_for_one_time){
					errorMsg = 'Coupon is not applicable for one time bookings.';
					return [false, errorMsg];
				}
			}
		}
		return null;
	}
	/**
	 * Coupon dependency on customer type
	 * @param formValues: form value
	 * @param coupon: selected coupon data
	 * @param prefilledData: prefilled data(used in reschedule)
	 * @param errorMsg: error message
	 * @returns : [status, errorMsg] | null
	 */
	private couponBasedOnCustType(formValues: any, coupon: any, prefilledData: any, errorMsg: string): any{
		if(formValues.customer.customer_type == 'existing customer'){
			if(coupon.applicable_for_customers != 'all_customers' && coupon.applicable_for_customers != 'existing_customers'){
				if(!formValues.customer.is_customer_new){
					if(!(prefilledData && prefilledData.coupon && prefilledData.coupon.code && prefilledData.coupon.code == coupon.code)){
						errorMsg = 'Coupon is not applicable for existing customers.';
						return [false, errorMsg];
					}
				}
			}
		} else{
			if(coupon.applicable_for_customers == 'existing_customers'){
				errorMsg = 'Coupon is not applicable for new customers.';
				return [false, errorMsg];
			}
		}
		return null;
	}
	/**
	 * Coupon dependency on min/max order also applicable in case of adjusted price
	 * @param formValues: form value
	 * @param coupon: selected coupon data
	 * @param errorMsg: error message
	 * @returns : [status, errorMsg, type] | null
	 */
	private couponBasedOnMinMax(formValues: any, coupon: any, errorMsg: string): any {
		let finalAmount: any = formValues.discounted_total;
		let couponValue: any = 0;
		let discount: any = this.utilServ.calValidAmount(formValues.coupon.discount);
		if(formValues.coupon.discount_type == 'percentage'){
			couponValue = this.utilServ.roundToTwo(((+discount)/100)*(+formValues.total_before_coupon_discount));
		} else {
			couponValue = this.utilServ.roundToTwo(discount);
		}
		if(couponValue){
			finalAmount = this.utilServ.roundToTwo((+finalAmount) + (+couponValue));
		}
		if(formValues.adjust_price && formValues.adjusted_price){
			finalAmount = formValues.adjusted_price;
			if(!coupon.apply_order_on_adjusted || (coupon.apply_order_on_adjusted && coupon.apply_order_on_adjusted == 'no')){
				return [true, ''];
			}
		}
		// Check min order
		if(coupon.min_order && (finalAmount < coupon.min_order)){
			errorMsg = coupon.min_order;
			return [false, errorMsg, 'min_order'];
		}
		if(coupon.max_order && (finalAmount > coupon.max_order)){
			errorMsg = coupon.max_order;
			return [false, errorMsg, 'max_order'];
		}
	}
	/**
	 * Get the reset referral message
	 * @param errorMsg: error message
	 * @returns string
	 */
	public resetReferralMsgs(msgType: any): string{
		let msg = "Due to modifications to the amount, Referral was removed, please re-apply your referral.";
		switch(msgType){
			case 'email':
				msg = "Due to change in customer details, please re-apply your referral.";
			break;
			case 'date':
				msg = 'Due to change in schedule, please re-apply your referral.';
			break;
			case 'amount':
				msg = 'Due to change in amount, please re-apply your referral.';
			break;
		}
		return msg;
	}
	/**
	 * Get the reset coupon message
	 * @param errorMsg: error message
	 * @param flag
	 * @returns
	 */
	public resetCouponMsgs(msgType: any, flag : boolean = false): string{
		let msg = 'Due to changes, you must re-apply your coupon code.';
		let rescMsg = 'Since you are modifying the booking, we removed the coupon. Please re-apply the coupon to check if this coupon is still valid for this booking. If you wish to keep the booking as is, you can exit without any modifications, which will keep the coupon as is.';
		if(flag){
			return rescMsg;
		}
		switch(msgType){
			case 'email':
				msg = 'Due to change in customer details, you must re-apply your coupon code.';
			break;
			case 'date':
				msg = 'Due to change in schedule, you must re-apply your coupon code.';
			break;
			case 'amount':
				msg = 'Due to change in amount, you must re-apply your coupon code.';
			break;
			case 'location':
				msg = 'Due to change in location, you must re-apply your coupon code.';
			break;
			case 'service':
				msg = 'Due to change in service, you must re-apply your coupon code.';
			break;
			case 'frequency':
				msg = 'Due to change in frequency, you must re-apply your coupon code.';
			break;
			case 'min_order':
				msg= 'After the modification of your booking, the coupon was removed because the minimum purchase value required for the use of this coupon was not met.';
			break;
			case 'max_order':
				msg= 'After the modification of your booking, the coupon was removed because the purchase amount of the booking exceeded from maximum amount allowed for this coupon.';
			break;
		}
		return msg;
	}
	/**
	 * Get the reset giftcard message
	 * @param errorMsg: error message
	 * @returns
	 */
	public resetGiftCardMsgs(msgType: any): string{
		let msg = "Due to changes, Gift card was removed, please re-apply your gift card.";
		switch(msgType){
			case 'email':
				msg = "Due to change in customer details, please re-apply your gift card.";
			break;
			case 'date':
				msg = 'Due to change in schedule, please re-apply your gift card.';
			break;
			case 'amount':
				msg = 'Due to change in amount, please re-apply your gift card.';
			break;
		}
		return msg;
	}
	/**
	 * Blank the coupon all parameters
	 * @returns
	 */
	public couponNullPatch(): any {
		let value = {
			applicable_with_gift_card : null,
			applicable_with_referral  : null,
			code  : '',
			discount : null,
			discount_type : 'code',
			half_discount : false,
			id : null,
			provider_discount : null,
			provider_discount_type : null,
			recurring : null,
			apply_on_bookings : null
		};
		return value;
	}
	/**
	 * Blank the giftcard all parameters.
	 */
	public giftcardNullPatch(): any{
		let value = {
			id : null,
			code : null,
			gift_card_total : null,
			previously_used : null,
			discount : null,
			status : false,
		};
		return value;
	}
	/**
	 * Fill the coupon control values
	 * @param couponData
	 * @returns
	 */
	public fillCouponValues(couponData:any): any{
		let couponWithGiftCards: any = couponData.allow_with_gift_cards;
		let couponWithReferrals: any  = couponData.allow_with_referrals;
		let applyOnBookings: any = '';
		// Apply on bookings
		if(couponData.applicable_for_recurring_bookings && couponData.applicable_for_recurring_bookings == 'custom'){
			let recurringBookingsCount = couponData.recurring_bookings_count ? couponData.recurring_bookings_count : '1';
			applyOnBookings = recurringBookingsCount.toString();
		} else{
			applyOnBookings = couponData.applicable_for_recurring_bookings;
		}
		let value: any = {
			discount_type : couponData.customer_discount_type,
			discount : couponData.customer_discount_value,
			recurring : couponData.recurring_bookings_count,
			provider_discount_type : couponData.provider_discount_type,
			provider_discount : couponData.provider_discount_value,
			id : couponData.id,
			code : couponData.code,
			applicable_with_gift_card : couponWithGiftCards,
			applicable_with_referral : couponWithReferrals,
			half_discount : couponData.half_discount,
			apply_on_bookings : applyOnBookings,
			min_order : couponData.min_order ? couponData.min_order : 0,
			max_order : couponData.max_order ? couponData.max_order : 0,
			apply_order_on_adjusted : couponData.apply_order_on_adjusted ? couponData.apply_order_on_adjusted : null,
			apply_freq_disc_with_coupon : couponData.apply_freq_disc_with_coupon ? couponData.apply_freq_disc_with_coupon : null,
			override_freq_cancel_settings : couponData.override_freq_cancel_settings ? couponData.override_freq_cancel_settings : null,
			charge_onetime_cancellation : couponData.charge_onetime_cancellation ? couponData.charge_onetime_cancellation : null,
			cancellation_after_appt : couponData.cancellation_after_appt ? couponData.cancellation_after_appt : null,
			prepay_rec_booking : couponData.prepay_rec_booking ? couponData.prepay_rec_booking :  null,
			prepay_bookings_count : couponData.prepay_bookings_count ? couponData.prepay_bookings_count : null
		}
		return value;
	}

	// Calculation functions for summary
	/**
	 * Calculate extra price
	 * @param extra: selected extra
	 * @param formValues: form values
	 * @param extras: industry & form based extras object
	 * @returns price
	 */
	public calExtraPrice(extra: any, formValues: any, extras: any): any {
		let pricingValue: number = 0;
		if(extras && extras[extra.id]){
			let selectedExtra: any = extras[extra.id];
			let locType: string = (formValues.location_type == 'ML') ? 'prices_ml' : 'prices_sa'
			if(selectedExtra.qty_based_price_length_type && selectedExtra.qty_based_price_length_type == 'manual'){
				let quantityIndex = (+extra.quantity) - 1;
				pricingValue = (+pricingValue) + ((+selectedExtra[locType][+quantityIndex]));
			} else{
				pricingValue = (+pricingValue) + ((+selectedExtra[locType][0])*(+extra.quantity));
			}
		}
		return pricingValue;
	}
	/**
	 * Calculate exclude price
	 * @param exclude: selected exclude
	 * @param formValues: form values
	 * @param excludes: industry & form based excludes object
	 * @returns price
	 */
	public calExcludesPrice(exclude: any, formValues: any, excludes: any): any {
		let pricingValue: number = 0;
		if(excludes && excludes[exclude.id]){
			let selectedExclude: any = excludes[exclude.id];
			let locType: string = (formValues.location_type == 'ML') ? 'price_ml' : 'price_sa'
			pricingValue = (+pricingValue) + ((+selectedExclude[locType])*(+exclude.quantity));
		}
		return pricingValue;
	}
	/**
	 * check the visibility of service length
	 * @param selectedService: selected service
	 * @returns boolean
	 */
	public checkVisibilityOfLength(selectedService: any): boolean {
		if(selectedService) {
			if(selectedService.service_length_display_on) {
				if(selectedService.service_length_display_on == 'both') {
					return true;
				} else if (selectedService.service_length_display_on == 'customer_backend' && (this.currentUser && this.currentUser.token)) {
					return true;
				} else {
					return false;
				}
			}
			return true;
		}
		return false;
	}
	/**
	 * Function to check if payment summary visible to customer or not.
	 * @returns true/false
	 */
	isPaySumryVisible(): boolean {
		if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.bookings && this.admnStngs.merchant_settings?.bookings?.show_full_payment_summary){
			if (this.admnStngs.merchant_settings?.bookings?.show_full_payment_summary == 'both') {
				return true;
			} else if (this.admnStngs.merchant_settings?.bookings?.show_full_payment_summary == 'backend' && (this.currentUser && this.currentUser.token)) {
				return true;
			} else if (this.admnStngs.merchant_settings?.bookings?.show_full_payment_summary == 'frontend' && !this.currentUser) {
				return true;
			} else {
				return false;
			}
			return true;
		}
		return false;
	}

	/**
	 * Function is part of submit function of all forms.
	 */
	// eslint-disable-next-line complexity
	submitPartOne(formValues: any){
		let emailsArray = [];
		let phoneArray = [];

		if(formValues.customer.emails && (formValues.customer.emails).length > 0){
			emailsArray.push(formValues.customer.email_id);
			for(let email of formValues.customer.emails){
				emailsArray.push(email.value);
			}
		}

		if(formValues.customer.phone_numbers && (formValues.customer.phone_numbers).length > 0){
			if(formValues.customer.phone_number){
				phoneArray.push(this.utilServ.phoneUnmask(formValues.customer.phone_number));
			}
			for(let phone of formValues.customer.phone_numbers){
				if(phone.value){
					phoneArray.push(this.utilServ.phoneUnmask(phone.value));
				}
			}
		}

		let emailExist = false;
		let phoneExist = false;
		if(emailsArray.length > 0){
			for(let i = 0; i <= emailsArray.length; i++) {
				for(let j = i; j <= emailsArray.length; j++) {
					if(i != j && emailsArray[i] == emailsArray[j]) {
						emailExist = true;
					}
				}
			}
		}

		if(phoneArray.length > 0){
			for(let i = 0; i <= phoneArray.length; i++) {
				for(let j = i; j <= phoneArray.length; j++) {
					if(i != j && phoneArray[i] == phoneArray[j]) {
						phoneExist = true;
					}
				}
			}
		}

		return{
			emailExist : emailExist,
			phoneExist : phoneExist
		};
	}
	/**
	 * Function is 2nd part of submit function of all forms.
	 */
	// eslint-disable-next-line complexity
	submitPartTwo(BKFrm: any, displayFinalAmount: number, prvdrPayVar: any){
		let areaParamGroup = <FormGroup>BKFrm.controls['area_parameter'];
		// Prepare customer data and address for submit.
		BKFrm = this.prepareCustomerDataForSbmnt(BKFrm)
		// Prepare date, time and provider data for submit.
		BKFrm = this.prepareDateTimeProviderForSbmt(BKFrm, prvdrPayVar);

		let adminEmailReminderDate = BKFrm.controls['admin_email_reminder_date'].value;
		if(adminEmailReminderDate){
			BKFrm.controls['admin_email_reminder_date'].setValue(adminEmailReminderDate.epoc);
		}
		if(areaParamGroup.value  && areaParamGroup.controls['quantity'].value){
			let quantity = +(areaParamGroup.controls['quantity'].value);
			areaParamGroup.controls['quantity'].setValue(+quantity);
		}
		if(BKFrm.controls['adjusted_price'].value){
			let adjustedPrice = this.utilServ.roundToTwo(+BKFrm.controls['adjusted_price'].value);
			BKFrm.controls['adjusted_price'].setValue(adjustedPrice, {emitEvent:false});
			if(this.admnStngs && this.admnStngs.merchant_settings?.providers?.calculate_provider_payment_on && this.admnStngs.merchant_settings?.providers?.calculate_provider_payment_on == 'adjusted'){
				BKFrm.controls['wages_on_adjusted'].setValue('yes');
			}else{
				BKFrm.controls['wages_on_adjusted'].setValue('no');
			}
		}else{
			BKFrm.controls['adjusted_price'].setValue(0, {emitEvent:false});
			BKFrm.controls['wages_on_adjusted'].setValue('no');
		}
		if(BKFrm.controls['overridden_service_total'].value){
			let overridenPrice = this.utilServ.roundToTwo(+BKFrm.controls['overridden_service_total'].value);
			BKFrm.controls['overridden_service_total'].setValue(overridenPrice, {emitEvent:false});
		}else{
			BKFrm.controls['overridden_service_total'].setValue(0, {emitEvent:false});
		}
		// Prepare discounted variables like coupon, giftcard, referral, tip and parking
		BKFrm = this.prepareTipParkingAndDiscForSbmt(BKFrm);

		if(BKFrm.controls['can_decline_job'].value){
			if(BKFrm.controls['can_decline_job'].value == 'yes'){
				BKFrm.controls['can_decline_job'].setValue(true);
			}else{
				BKFrm.controls['can_decline_job'].setValue(false);
			}
		}
		BKFrm.controls['final_amount'].setValue(displayFinalAmount);
		if(BKFrm.controls['send_credit_card_link'].value){
			BKFrm.controls['send_credit_card_link'].setValue(1);
		}else{
			BKFrm.controls['send_credit_card_link'].setValue(0);
		}
		return BKFrm;
	}
	/**
	 * Function to check if member is team lead.
	 */
	checkIfTeamLead(member: any, provider: any): boolean{
		let teamId = (provider && provider.id) ? +provider.id : null;
		let teamMember = (member && member.team && member.team.team_info)  ? member.team.team_info : null;
		if(teamId && teamMember && teamMember[teamId] && teamMember[teamId].team_lead){
			return true;
		}
		return false;
	}
	/**
	 * Function to build the provider wages array from provider details.
	 */
	public buildProviderWages(provider: any, BKFrmValue: any, priceableFieldsPrice: any): any {
		// Declare default values
		let providerWages : any = {
			provider_id: null,
			provider_category: null,
			base_pay: null,
			base_pay_unit: null,
			reimbursements: null,
			tip: null,
			tip_type: null,
			parking: null,
			bonus: null,
			service_fee: null,
			coupon_discount: null,
			coupon_discount_type: null,
			is_overridden: false,
			priceable_fields_price: null
		};
		providerWages.provider_id = +provider.id;
		providerWages.provider_category = provider.provider_category;
		// If provider have wages
		if(provider.wages){
			providerWages.base_pay = +(this.utilServ.roundToTwo(+provider.wages.base_pay));
			providerWages.base_pay_unit = provider.wages.base_pay_unit;
		}
		// If provider have reimbursements
		if(provider.reimbursements){
			providerWages.reimbursements = provider.reimbursements;
		}
		let eachProviderTip = BKFrmValue.tip.each_member_amount;
		// Loop on eachProviderTip
		providerWages.tip = this.eachProviderParam(eachProviderTip, provider);

		providerWages.tip_type = BKFrmValue.tip.amount_type;
		let eachProviderParking = BKFrmValue.parking.each_member_amount;
		// Loop on  eachProviderParking
		providerWages.parking = this.eachProviderParam(eachProviderParking, provider);

		let eachProviderBonus = BKFrmValue.bonus.each_member_amount;
		// Loop on eachProviderBonus
		providerWages.bonus = this.eachProviderParam(eachProviderBonus, provider);

		if(BKFrmValue.service_fee){
			let eachProviderServiceFee = BKFrmValue.provider_service_fee;
			// Loop on eachProviderServiceFee
			providerWages.service_fee = this.eachProviderParam(eachProviderServiceFee, provider);
		}
		// Priceable fields
		providerWages.priceable_fields_price = this.utilServ.roundToTwo(priceableFieldsPrice)

		let providerDiscount = BKFrmValue.coupon.provider_discount;
		// If have provider discount for coupon
		if(providerDiscount){
			providerWages.coupon_discount = +(this.utilServ.roundToTwo(+providerDiscount));
			providerWages.coupon_discount_type = BKFrmValue.coupon.provider_discount_type;
		}
		if(BKFrmValue.service_provider_pay_overridden){
			providerWages.is_overridden = true;
		}
		return providerWages;
   }
	/**
	 * Calculate each provider tip, parking, bonus and service fee.
	 * @param paramArray
	 * @param provider
	 * @returns
	 */
	private eachProviderParam(paramArray: any, provider: any){
		if(paramArray && paramArray.length > 0){
			for(let param of paramArray){
				if(provider.id == param.id){
					return +(this.utilServ.roundToTwo(+param.amount));
				}
			}
		}
		return null;
	}
	/**
	 * Function to prepare customer data for submit.
	 * @param BKFrm
	 * @returns
	 */
	prepareCustomerDataForSbmnt(BKFrm: any): any{
		let customerGroup = <FormGroup>BKFrm.controls['customer'];
		let addresssGroup = <FormGroup>BKFrm.controls['address'];
		if(!BKFrm.controls['uid'].value){
			BKFrm.controls['uid'].setValue(0, {emitEvent:false});
		}
		let primaryEmail = (customerGroup.controls['email_id'].value).toLowerCase();
		customerGroup.controls['email_id'].setValue(primaryEmail, {emitEvent:false});

		if(BKFrm.controls['location_type'].value == 'SA'){
			let address = customerGroup.controls['address'].value;
			let zipcode = customerGroup.controls['customer_zipcode'].value;
			if(!zipcode){
				let globalZipcode = BKFrm.controls['zipcode'].value;
				customerGroup.controls['customer_zipcode'].setValue(globalZipcode);
				zipcode = globalZipcode;
			}
			let state = customerGroup.controls['state'].value;
			let city = customerGroup.controls['city'].value;
			let apt = customerGroup.controls['apt'].value;
			addresssGroup.controls['address'].setValue(address);
			addresssGroup.controls['zipcode'].setValue(zipcode);
			addresssGroup.controls['state'].setValue(state);
			addresssGroup.controls['city'].setValue(city);
			addresssGroup.controls['apt'].setValue(apt);
		}

		let customerPhoneNum = customerGroup.controls['phone_number'].value;
		if(customerPhoneNum){
			customerGroup.controls['phone_number'].setValue(this.utilServ.phoneUnmask(customerPhoneNum));
		}
		let customerArray: any = <FormArray>customerGroup.controls['phone_numbers'];
		if((customerGroup.controls['phone_numbers'].value).length > 0){
			(customerGroup.controls['phone_numbers'].value).forEach((value: any, index: any)=>{
				let customerIndexGroup: any = <FormGroup>customerArray.controls[index];
				customerIndexGroup.controls['value'].setValue(this.utilServ.phoneUnmask(value.value));
			});
		}
		if(!BKFrm.controls['address_id'].value){
			BKFrm.controls['address_id'].setValue(null);
		}
		return BKFrm;
	}
	/**
	 * Function to prepare date, time and provider data for submit.
	 * @param BKFrm
	 * @returns
	 */
	// eslint-disable-next-line complexity, max-lines-per-function
	prepareDateTimeProviderForSbmt(BKFrm: any, prvdrPayVar: any): any{
		let arrivalDate = BKFrm.controls['arrival_date'].value;
		if(arrivalDate && arrivalDate.epoc){
			BKFrm.controls['arrival_date'].setValue(arrivalDate.epoc);
		}
		if(arrivalDate && arrivalDate.jsdate){
			BKFrm.controls['arrival_date'].setValue(arrivalDate.jsdate.getTime());
		}
		if(!BKFrm.controls['booking_date'].value && arrivalDate){
			let newdate = null;
			if(arrivalDate.jsdate){
				newdate = arrivalDate.jsdate;
			}else{
				newdate = new Date( BKFrm.controls['arrival_date'].value);
			}
			let tempdate = newdate.getFullYear()+'-'+('0' + (newdate.getMonth()+1)).slice(-2)+'-'+('0' + newdate.getDate()).slice(-2);
			BKFrm.controls['booking_date'].setValue(tempdate);
		}
		if(BKFrm.controls['arrival_time'].value){
			let arrivalTime = +BKFrm.controls['arrival_time'].value;
			BKFrm.controls['arrival_time'].setValue(arrivalTime);
		}
		/**To solve the errors, when some time recurring_dates array goes empty, this code is to handle that case. */
		let datesArray = []
		if(BKFrm.controls['recurring_dates'].value){
			datesArray = (BKFrm.controls['recurring_dates'].value).split(",")
			if(!datesArray || (datesArray).length == 0){
				let frequencyOccurance = BKFrm.controls['frequency_repeat_slug'].value;
				let bookingDate = BKFrm.controls['booking_date'].value;
				let scheduleRange : any = this.scheduleServ.getScheduleRange()
				let globals : any = this.scheduleServ.globals;
				let holidays : any = [];
				let recurringDatesArray = this.scheduleServ.getRecurringDates(bookingDate, frequencyOccurance, scheduleRange, globals.totalBookingsCount, holidays, globals.skipHolidays);
				let recurringDates = recurringDatesArray.toString();
				BKFrm.controls['recurring_dates'].setValue(recurringDates);
				try {
					Bugsnag.notify(new Error('Recurring_dates empty error occured'));
				// eslint-disable-next-line no-empty
				} catch (error) {

				}
			}
		}

		let providerDetails = BKFrm.controls['provider_details'].value;
		if(providerDetails && providerDetails.length > 0){
			BKFrm.controls['is_waiting_list'].setValue(false);
			if(this.initServ.appAdmnStngs.merchant_settings?.providers?.scheduling_type == 'manually'){
				BKFrm.controls['provider_type'].setValue('random_provider');
			}
			if(providerDetails.length > 1)	{
				BKFrm.controls['provider_category'].setValue('pair');
			}else{
				if(providerDetails[0].provider_type == 'team'){
					BKFrm.controls['provider_category'].setValue('team');
				}else{
					BKFrm.controls['provider_category'].setValue('single');
				}
			}
			// Set provider wages
			BKFrm.removeControl('provider_wages');
			BKFrm.addControl('provider_wages', this.frmBldr.array([]));
			this.setWages(BKFrm, providerDetails, prvdrPayVar);
		}else{
			if(BKFrm.controls['provider_type'].value == 'random_provider'){
				BKFrm.controls['provider_type'].setValue('unassigned');
			}
			// If no provider selected the reset the value of overridden provider time
			this.overridePrvdrTimeServ.emptyPrvdrOverriddenTime(BKFrm)
		}

		if(BKFrm.controls['provider_type'].value == 'unassigned'){
			BKFrm.controls['automatically_accept_job'].setValue(true);
			let plength = (BKFrm.controls['provider_length'].value) * (BKFrm.controls['provider_ids'].value.length)
			BKFrm.controls['provider_length'].setValue(plength);
		}

		if(BKFrm.controls['recurring_dates'].value){
			datesArray = (BKFrm.controls['recurring_dates'].value).split(",")
			let skipUnavailableBooking : any = this.admnStngs.merchant_settings?.bookings?.skip_unavailable_booking ? this.admnStngs.merchant_settings?.bookings?.skip_unavailable_booking : 'no';
			// console.log('this.admnStngs',skipUnavailableBooking,BKFrm.controls['provider_skip_dates'].value,this.admnStngs,BKFrm.value)
			if(datesArray && (datesArray).length > 1 && BKFrm.controls['provider_ids'].value && BKFrm.controls['provider_ids'].value.length == 1 && (BKFrm.controls['provider_ids'].value)[0] != 0 && skipUnavailableBooking == 'yes' && BKFrm.controls['provider_skip_dates'].value ){
				let provider_skip_dates = BKFrm.controls['provider_skip_dates'].value ? BKFrm.controls['provider_skip_dates'].value : []
				let pid = (BKFrm.controls['provider_ids'].value)[0]
				let spot = BKFrm.controls['arrival_time'].value
				if(provider_skip_dates && provider_skip_dates[pid] && provider_skip_dates[pid][spot]){
					let newRecurringDatesArray = []
					let i = 0
					for(let d of datesArray){
						// eslint-disable-next-line max-depth
						if(provider_skip_dates && pid && spot && provider_skip_dates[pid] && provider_skip_dates[pid][spot] && (provider_skip_dates[pid][spot]).includes(d) && i == 0){
							i++
						}else{
							newRecurringDatesArray.push(d)
						}
					}
					let recurringDates = newRecurringDatesArray.toString();
					BKFrm.controls['recurring_dates'].setValue(recurringDates);
					// console.log('newRecurringDatesArray',newRecurringDatesArray)
				}
			}
		}
		return BKFrm;
	}
	/**
	 * Set provider wages
	 * @param BKFrm
	 * @param providerDetails
	 * @returns
	 */
	private setWages(BKFrm: any, providerDetails: any, prvdrPayVar: any){
		let providerWagesArray = <FormArray>BKFrm.controls['provider_wages'];
		let priceableFieldsPrice = (providerDetails).length > 1 ? (prvdrPayVar.priceableFieldsPrice / (providerDetails).length) : prvdrPayVar.priceableFieldsPrice;
		// Loop on selected provider
		for(let provider of providerDetails){
			// If team
			if(provider.provider_type == 'team'){
				providerWagesArray = this.setWagesPart(BKFrm, provider, providerWagesArray, prvdrPayVar);
			}else{
				// Build provider wages
				let providerWages = this.buildProviderWages(provider,BKFrm.value, priceableFieldsPrice);
				providerWagesArray.push(new FormControl(providerWages));
			}
		}
		return BKFrm
	}
	/**
	 * Part of set wages function
	 * @param BKFrm
	 * @param provider
	 * @param providerWagesArray
	 * @returns
	 */
	private setWagesPart(BKFrm: any, provider: any, providerWagesArray: any, prvdrPayVar: any){
		// If payment goes to lead
		if(provider.payment_goes_to == 'lead'){
			providerWagesArray = this.wagesWithLead(BKFrm, provider, providerWagesArray, prvdrPayVar);
		}else{
			providerWagesArray = this.wagesWithoutLead(BKFrm, provider, providerWagesArray, prvdrPayVar);
		}
		return providerWagesArray;
	}
	/**
	 * Set wages if wages go to lead of team
	 * @param BKFrm
	 * @param provider
	 * @param providerWagesArray
	 * @returns
	 */
	private wagesWithLead(BKFrm: any, provider: any, providerWagesArray: any, prvdrPayVar: any){
		let tipGroup = <FormGroup>BKFrm.controls['tip'];
		let parkingGroup = <FormGroup>BKFrm.controls['parking'];
		let bonusGroup = <FormGroup>BKFrm.controls['bonus'];
		let priceableFieldsPrice = prvdrPayVar.priceableFieldsPrice;
		if(provider.members && (provider.members).length > 0){
			// Loop on selected members
			for(let member of provider.members){
				if(this.utilServ.checkIfTeamLead(member, provider) && member.wages){
					let providerWages = this.buildProviderWages(member,BKFrm.value, priceableFieldsPrice);
					providerWages.tip = +(this.utilServ.roundToTwo(tipGroup.controls['total_amount'].value));
					providerWages.parking = +(this.utilServ.roundToTwo(parkingGroup.controls['total_amount'].value));
					providerWages.bonus = +(this.utilServ.roundToTwo(bonusGroup.controls['total_amount'].value));
					providerWages.service_fee = +(this.utilServ.roundToTwo(BKFrm.controls['service_fee'].value));
					providerWagesArray.push(new FormControl(providerWages));
					break;
				}
			}
		}
		return providerWagesArray;
	}
	/**
	 * Set wages if wages not gone to lead of team
	 * @param BKFrm
	 * @param provider
	 * @param providerWagesArray
	 * @returns
	 */
	private wagesWithoutLead(BKFrm: any, provider: any, providerWagesArray: any, prvdrPayVar: any){
		if(provider.members && (provider.members).length > 0){
			let priceableFieldsPrice = (provider.members).length > 1 ? (prvdrPayVar.priceableFieldsPrice / (provider.members).length) : prvdrPayVar.priceableFieldsPrice;
			// Loop on the members
			for(let member of provider.members){
				if((member.wages && (+member.wages.base_pay == 0 || +member.wages.base_pay >= 0)) || (member.reimbursements)){
					let providerWages = this.buildProviderWages(member,BKFrm.value, priceableFieldsPrice);
					providerWagesArray.push(new FormControl(providerWages));
				}
			}
		}
		return providerWagesArray;
	}
	/**
	 * Function to prepare data for submit.
	 * @param BKFrm
	 * @returns
	 */
	prepareTipParkingAndDiscForSbmt(BKFrm: any): any {
		let tipGroup = <FormGroup>BKFrm.controls['tip'];
		let parkingGroup = <FormGroup>BKFrm.controls['parking'];
		let bonusGroup = <FormGroup>BKFrm.controls['bonus'];
		let couponGroup = <FormGroup>BKFrm.controls['coupon'];
		let giftCardGroup = <FormGroup>BKFrm.controls['gift_card'];
		if(this.admnStngs && this.admnStngs.merchant_settings?.providers && this.admnStngs.merchant_settings?.providers?.provider_discount_on_all_payments && this.admnStngs.merchant_settings?.providers?.provider_discount_on_all_payments == 'yes'){
			BKFrm.controls['apply_provider_discount'].setValue(true);
		}else{
			BKFrm.controls['apply_provider_discount'].setValue(false);
		}
		if(BKFrm.controls['expedited_amount'].value){
			let expeditedAmount = this.utilServ.roundToTwo(+BKFrm.controls['expedited_amount'].value);
			BKFrm.controls['expedited_amount'].setValue(expeditedAmount, {emitEvent:false});
		}else{
			BKFrm.controls['expedited_amount'].setValue(0, {emitEvent:false});
		}

		if(parkingGroup.controls['total_amount'].value){
			let parkingTotal = this.utilServ.roundToTwo(+parkingGroup.controls['total_amount'].value);
			parkingGroup.controls['total_amount'].setValue(parkingTotal, {emitEvent:false});
		}else{
			parkingGroup.controls['total_amount'].setValue(0, {emitEvent:false});
		}

		if(tipGroup.controls['total_amount'].value){
			let tipsTotal = this.utilServ.roundToTwo(+tipGroup.controls['total_amount'].value);
			tipGroup.controls['total_amount'].setValue(tipsTotal, {emitEvent:false});
		}else{
			tipGroup.controls['total_amount'].setValue(0, {emitEvent:false});
		}
		if(bonusGroup.controls['total_amount'].value){
			let bonusTotal = this.utilServ.roundToTwo(+bonusGroup.controls['total_amount'].value);
			bonusGroup.controls['total_amount'].setValue(bonusTotal, {emitEvent:false});
		}else{
			bonusGroup.controls['total_amount'].setValue(0, {emitEvent:false});
		}
		if(BKFrm.controls['service_fee'].value)	{
			let serviceFee = +BKFrm.controls['service_fee'].value;
			BKFrm.controls['service_fee'].setValue(+serviceFee, {emitEvent:false});
		}else{
			BKFrm.controls['service_fee'].setValue(0, {emitEvent:false});
		}
		if(couponGroup.controls['code'].value){
			BKFrm.controls['is_coupon_applied'].setValue(true);
		}else if(couponGroup.controls['discount'].value){
			BKFrm.controls['is_discount_applied'].setValue(true);
		}
		if(giftCardGroup.controls['id'].value){
			BKFrm.controls['is_giftcard_applied'].setValue(true);
		}
		if(BKFrm.controls['referral_amount'].value){
			BKFrm.controls['is_referral_applied'].setValue(true);
			let referralDiscount = this.utilServ.roundToTwo(+(BKFrm.controls['referral_amount'].value));
			BKFrm.controls['referral_amount'].setValue(referralDiscount);
		}
		if(couponGroup.controls['discount'].value){
			let couponDiscount = this.utilServ.roundToTwo(+couponGroup.controls['discount'].value);
			couponGroup.controls['discount'].setValue(couponDiscount);
		}
		if(couponGroup.controls['provider_discount'].value)	{
			let providerDiscount = this.utilServ.roundToTwo(+couponGroup.controls['provider_discount'].value);
			couponGroup.controls['provider_discount'].setValue(providerDiscount);
		}
		if(giftCardGroup.controls['discount'].value)	{
			let giftCardDiscount = this.utilServ.roundToTwo(+giftCardGroup.controls['discount'].value);
			giftCardGroup.controls['discount'].setValue(giftCardDiscount);
		}
		return BKFrm;
	}
	/**
	 * Validate form parameters
	 * @param settings: industry & form settings
	 * @returns boolean
	 */
	public validateFormParams(settings: any): boolean{
		if(settings && settings.form_data && settings.form_data.validate_pricing_param && settings.form_data.validate_pricing_param == 'yes'){
			return false;
		}
		return true;
	}
	/**
	 * Function is part of comparisonOfDate func.
	 * @param prefilledData
	 * @returns
	 */
	CODPart(prefilledData: any): boolean {
		let time = Math.floor(Date.now()/1000);
		let temp_arrival_date = this.utilServ.dateObj(prefilledData.booking_date);
		let bookingtimestamp = temp_arrival_date.getTime()/1000
		let timediff = bookingtimestamp - time;
		let fivepmoffset = 7*3600;
		if(prefilledData.status == 1 || prefilledData.status == 2 || prefilledData.status == 3 || prefilledData.status == 4){
			return(false);
		}else{
			if(timediff > fivepmoffset){
				return(false);
			}else{
				return(true);
			}
		}
	}
	/**
	 * Get the image description
	 * @param image
	 * @returns
	 */
	getDesc(image: any){
		let html = '';
		html += image.before_tag ? '<span class="badge tags mt-5 me-5">'+ this.translate.instant('Before')+' </span>' : '';
		html += image.after_tag ? '<span class="badge tags mt-5 me-5">'+ this.translate.instant('After')+' </span>' : '';
		html += image.damages_tag ? '<span class="badge tags mt-5 me-5">'+ this.translate.instant('Damages')+' </span>' : '';
		return html;
	}
	setImageGalleyArray(img: any){
		let imgUrl = this.initServ.imgBase + img.photo_url;
		let obj : any = {
			small: imgUrl,
			medium: imgUrl,
			big: imgUrl,
			description: this.getDesc(img)
		}
		return obj;
	}

	/**
	 * Function is part of rescheduleOnlyInfo func on all forms.
	 */
	public ROIPart(BKFrm: any){
		let customerGroup = <FormGroup>BKFrm.controls['customer'];
		if(!BKFrm.controls['uid'].value){
			BKFrm.controls['uid'].setValue(0);
		}
		let primaryEmail = (customerGroup.controls['email_id'].value).toLowerCase();
		customerGroup.controls['email_id'].setValue(primaryEmail);
		if(BKFrm.controls['location_type'].value == 'SA'){
			let address = customerGroup.controls['address'].value;
			let zipcode = customerGroup.controls['customer_zipcode'].value;
			if(!zipcode){
				let globalZipcode = BKFrm.controls['zipcode'].value;
				customerGroup.controls['customer_zipcode'].setValue(globalZipcode);
				zipcode = globalZipcode;
			}
			let state = customerGroup.controls['state'].value;
			let city= customerGroup.controls['city'].value;
			let apt= customerGroup.controls['apt'].value;
			BKFrm.controls['address'].get('address').setValue(address);
			BKFrm.controls['address'].get('zipcode').setValue(zipcode);
			BKFrm.controls['address'].get('state').setValue(state);
			BKFrm.controls['address'].get('city').setValue(city);
			BKFrm.controls['address'].get('apt').setValue(apt);
		}
		let customerPhoneNum = customerGroup.controls['phone_number'].value;
		if(customerPhoneNum){
			customerGroup.controls['phone_number'].setValue(this.utilServ.phoneUnmask(customerPhoneNum));
		}
		let customerArray: any = <FormArray>customerGroup.controls['phone_numbers'];
		if((customerGroup.controls['phone_numbers'].value).length > 0){
			(customerGroup.controls['phone_numbers'].value).forEach((value: any, index: any)=>{
				let customerIndexGroup: any = <FormGroup>customerArray.controls[index];
				customerIndexGroup.controls['value'].setValue(this.utilServ.phoneUnmask(value.value));
			});
		}
		if(!BKFrm.controls['address_id'].value){
			BKFrm.controls['address_id'].setValue(null);
		}
		let adminEmailReminderDate = BKFrm.controls['admin_email_reminder_date'].value;
		if(adminEmailReminderDate && adminEmailReminderDate.epoc){
			BKFrm.controls['admin_email_reminder_date'].setValue(adminEmailReminderDate.epoc);
		}
		if(BKFrm.controls['send_credit_card_link'].value){
			BKFrm.controls['send_credit_card_link'].setValue(1);
		} else{
			BKFrm.controls['send_credit_card_link'].setValue(0);
		}
		return BKFrm;
	}

	// ------------------------SECTION ORDER------------------------
	stepWiseCustGrpPos: any = {
		step_one: [],
		step_two: [],
		step_four: []
	}
	oneStepFormSections: any;
	twoStepFormSections: any;
	/**
	 * Return the section order variables
	 */
	public get sectionOrderVar(): any {
		return {
			location:1,
			service:2,
			frequency:3,
			pricing_parameter:4,
			extras:5,
			custom_fields_step_one:6,
			email:7,
			service_provider:8,
			tip_parking:9,
			customer_details:10,
			address_details:11,
			key_info:12,
			special_notes:13,
			discounts:14,
			custom_fields_step_two:15,
			payment_info:16
		}
	}
	setFormSecOrder(formLayout: string, formMetaData: any): any {
		let sectionOrder: any = this.sectionOrderVar;
		let allSectionsArray: any = [];
		switch(formLayout){
			case 'one_step':
				allSectionsArray = this.setOneStepOrder(formMetaData, allSectionsArray);
				this.stepWiseCustGrpPos['step_one'] = this.oneStepFormSections;
				break;
			case 'two_step':
				allSectionsArray = this.setTwoStepOrder(formMetaData, allSectionsArray);
				this.stepWiseCustGrpPos['step_one'] = this.twoStepFormSections.step_one;
				this.stepWiseCustGrpPos['step_two'] = this.twoStepFormSections.step_two;
				break;
			case 'multi_step':
				break;
		}
		// Handle form section order
		if(this.utilServ.checkArrLength(allSectionsArray)){
			// Add custom fields in sections
			// allSectionsArray = this.setCustomFieldsInSectionOrder(allSectionsArray);
			sectionOrder = this.setSectionOrder(allSectionsArray, sectionOrder);
		}
		return sectionOrder;
	}
		/**
	 * Set the section order.
	 * @param allSectionsArray
	 * @param sectionOrder
	 * @returns
	 */
		public setSectionOrder(allSectionsArray: any, sectionOrder: any){
			for(let i = 0; i < allSectionsArray.length;i++){
				sectionOrder[allSectionsArray[i]] = i + 1;
			}
			return sectionOrder;
		}
	/**
	 * Set the one step form layout order.
	 * @param formMetaData
	 * @param allSectionsArray
	 * @returns
	 */
	private setOneStepOrder(formMetaData: any, allSectionsArray: any){
		if(this.utilServ.checkArrLength(formMetaData?.single_form_sections)){
			this.oneStepFormSections = formMetaData.single_form_sections;
		}
		// Add address_details if not exist
		this.addAddrDetails(this.oneStepFormSections);
		allSectionsArray = this.oneStepFormSections;
		return allSectionsArray;
	}
	/**
	 * Set the two step form layout order.
	 * @param formMetaData
	 * @param allSectionsArray
	 * @returns
	 */
	private setTwoStepOrder(formMetaData: any, allSectionsArray: any){
		if(this.utilServ.checkArrLength(formMetaData?.step_one_sections)){
			this.twoStepFormSections.step_one = formMetaData.step_one_sections;
		}
		if(this.utilServ.checkArrLength(formMetaData?.step_two_sections)){
			this.twoStepFormSections.step_two = formMetaData.step_two_sections;
		}
		// Add address_details if not exist
		if(!(this.twoStepFormSections.step_one).includes("address_details") && !(this.twoStepFormSections.step_two).includes("address_details")){
			this.addAddrDetailsCond();
		}
		allSectionsArray = [...this.twoStepFormSections.step_one, ...this.twoStepFormSections.step_two];
		return allSectionsArray;
	}
	/**
	 * Add address_details if not exist
	 * @param sections
	 */
	private addAddrDetails(sections: any): void {
		if(!(sections).includes("address_details")){
			let i = (sections).indexOf('customer_details');
			(sections).splice(i+1, 0, 'address_details');
		}
	}
	/**
	 * Add address_details accordingly
	 */
	private addAddrDetailsCond(){
		if((this.twoStepFormSections.step_one).includes("customer_details")){
			this.addAddrDetails(this.twoStepFormSections?.step_one);
		} else {
			this.addAddrDetails(this.twoStepFormSections?.step_two);
		}
	}


	/**
	 * Function is used to display items in summary for form 3.
	 */
	public getItemAddons(itemName: string, index:number, formValues: any, addons: any): any{
		let selectedAddons: string[] = [];
		let i =0;
		for(let item of formValues.items){
			if(itemName == item.name && i == index){
				for(let addonList of item.addons){
					selectedAddons.push(' '+addons[addonList?.id]+' ');
				}
			}
			i = i +1;
		}
		return selectedAddons.toString();;
	}

	/**
	* Function to check the visibity of only packages in sidebar.
	*/
	public checkPckgsAvailability(items: any): boolean {
		if(items && items.length > 0){
			for(let item of items){
				if(item.packages && (item.packages).length > 0){
					return true;
				}
			}
		}
		return false;
	}


}
