import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
// Popup components
import { IndustriesPopupComponent, ConfirmPopupComponent, MessagePopupComponent, OtherContactsPopupComponent, RatingPopupComponent, ShareReviewPopupComponent, BlankTempPopupComponent, LeadsFormPopupComponent } from '../Popups';
import { AddOtherContactPopupComponent, ChangeBillingAddressPopupComponent, AddressCardPopupComponent } from '../UserPages';
import { SocialEmailPopupComponent } from '../Session';
import { MakeCardDefaultPopupComponent } from '../UserPages/Info/Components/BillingCard/MakeCardDefaultPopup/MakeCardDefaultPopup.component';
//Checklist popup
import { MediaFilesPopupComponent } from '../RescheduleBookings/Components/MediaFilesPopup/MediaFilesPopup.component';
import { ContractPopupComponent } from '../Session/Invoices/ContractPopup/ContractPopup.component';
import { Contract } from '../Session/Invoices/Invoice';

@Injectable({
	providedIn: 'root'
})
export class PopupServ {

	constructor(private dialog: MatDialog) { }

	/**
	 * Industries popup
	 * @returns popup ref
	 */
	public industriesPopup(): any {
		let ref: MatDialogRef<IndustriesPopupComponent>;
		ref = this.dialog.open(IndustriesPopupComponent);
		return ref.afterClosed();
	}
	/**
	 * Confirmation popup
	 * @param msg
	 * @returns popup ref
	 */
	public confirmPopup(type: string = ''): any {
		let ref: MatDialogRef<ConfirmPopupComponent>;
		ref = this.dialog.open(ConfirmPopupComponent);
		ref.componentInstance.type = type;
		return ref.afterClosed();
	}
	/**
	 * Message popup
	 * @param msg
	 * @returns popup ref
	 */
	public msgPopup(msg: string, isBtn: boolean = false, isSplit: boolean = false, isCenter: boolean =true): any {
		let ref: MatDialogRef<MessagePopupComponent>;
		ref = this.dialog.open(MessagePopupComponent);
		ref.componentInstance.msg = msg;
		ref.componentInstance.btn = isBtn;
		ref.componentInstance.isSplit = isSplit;
		ref.componentInstance.isCenter = isCenter;
		return ref.afterClosed();
	}

	/**
	 * This function opens a message popup component and returns the reference when the popup is
	 * closed.
	 * @param {boolean} isCustMsg - A boolean value indicating whether the message is a custom message or
	 * not. If it is true, then the message is a custom message, otherwise it is not.
	 * @param {string} msgType - string type based message parameter.
	 * * If `isCustMsg` is `true` than give the 'key' name for the type of the message. *Example : 'add_tip'*
	 * * If `isCustMsg` is `false` than provide with static message to be displayed in the popup.
	 * @returns emits the result value when the dialog is closed.
	 */
	public newMsgPopup(staticMsg:string = '', isCustMsg:boolean = false,  msgType: string = '', isSplit:boolean = false): any {
		let ref: MatDialogRef<MessagePopupComponent>;
		ref = this.dialog.open(MessagePopupComponent);
		ref.componentInstance.isCustMsg = isCustMsg;
		ref.componentInstance.staticMsg = staticMsg;
		ref.componentInstance.msgType = msgType;
		ref.componentInstance.isSplit = isSplit;
		return ref.afterClosed();
	}
	/**
	 * User other contacts popup
	 * @param uid
	 * @param hideActions
	 * @returns popup ref
	 */
	public otherContactsPopup(uid: any, hideActions: boolean = false): any {
		let ref: MatDialogRef<OtherContactsPopupComponent>;
		ref = this.dialog.open(OtherContactsPopupComponent);
		ref.componentInstance.uid = uid;
		ref.componentInstance.hideActions = hideActions;
		return ref.afterClosed();
	}
	/**
	 * User add other contacts popup
	 * @param uid
	 * @param contactId
	 * @returns popup ref
	 */
	public addOtherContactPopup(uid: any, contactId: any = null): any {
		let ref: MatDialogRef<AddOtherContactPopupComponent>;
		ref = this.dialog.open(AddOtherContactPopupComponent);
		ref.componentInstance.uid = uid;
		ref.componentInstance.contactId = contactId;
		return ref.afterClosed();
	}
	/**
	 * Social account email is not exist, use this popup.
	 * @param user user information
	 * @returns  popup ref
	 */
	public socialEmailPopup(user: any): any {
		let ref: MatDialogRef<SocialEmailPopupComponent>;
		ref = this.dialog.open(SocialEmailPopupComponent);
		ref.componentInstance.user = user;
		return ref.afterClosed();
	}
	/**
	 * Rating popup
	 * @param booking
	 * @param rating
	 * @param isGlobal use in after login
	 * @returns popup ref
	 */
	public ratingPopup(booking: any, rating: any = null, isGlobal: boolean = false): any {
		let ref: MatDialogRef<RatingPopupComponent>;
		ref = this.dialog.open(RatingPopupComponent);
		ref.componentInstance.rating = rating;
		ref.componentInstance.booking = booking;
		ref.componentInstance.isGlobal = isGlobal;
		return ref.afterClosed();
	}
	/**
	 * Share review popup
	 * @param booking
	 * @param rating
	 * @returns
	 */
	public shareReviewPopup(booking: any, rating: any): any {
		let ref: MatDialogRef<ShareReviewPopupComponent>;
		ref = this.dialog.open(ShareReviewPopupComponent);
		ref.componentInstance.rating = rating;
		ref.componentInstance.booking = booking;
		return ref.afterClosed();
	}
	/**
	 * Change billing address popup
	 * @param cardId
	 * @returns
	 */
	public changeBillingAddressPopup(cardId: any): any {
		let ref: MatDialogRef<ChangeBillingAddressPopupComponent>;
		ref = this.dialog.open(ChangeBillingAddressPopupComponent);
		ref.componentInstance.cardId = cardId;
		return ref.afterClosed();
	}

	// public checklistNotePopup(data: any) {
	// 	const ref: MatDialogRef<NotesPopupComponent> = this.dialog.open(NotesPopupComponent, { autoFocus: false, restoreFocus: false });
	// 	ref.componentInstance.ckData = data;
	// 	ref.componentInstance.users = data?.users;
	// 	return ref.afterClosed();
	// }

	/**
	 * Change add address card popup
	 * @param address
	 * @returns
	 */
	public addressCardPopup(address: any = null): any {
		let ref: MatDialogRef<AddressCardPopupComponent>;
		ref = this.dialog.open(AddressCardPopupComponent);
		ref.componentInstance.addressObj = address;
		return ref.afterClosed();
	}

	/**
	 * Make default card popup
	 * @param address
	 * @returns
	 */
	public makeCardDefaultPopup(card: any = null, bookingSettings: any = null, bookingOptions: any = null): any {
		let ref: MatDialogRef<MakeCardDefaultPopupComponent>;
		ref = this.dialog.open(MakeCardDefaultPopupComponent);
		ref.componentInstance.card = card;
		ref.componentInstance.bookingSettings = bookingSettings;
		ref.componentInstance.bookingOptions = bookingOptions;
		return ref.afterClosed();
	}
	/**
	 * Merchant note popup
	 * @param data
	 * @returns
	 */
	public mediaFilesPopup(data: any) {
		const ref: MatDialogRef<MediaFilesPopupComponent> = this.dialog.open(MediaFilesPopupComponent, { autoFocus: false, restoreFocus: false });
		ref.componentInstance.ckData = data;
		return ref.afterClosed();
	}


	/**
	 * Sign contract popup to pay invoice
	 * @param data
	 * @param id
	 * @returns
	 */
	public addContractPopup(data: Contract, id: any): any {
		let ref: MatDialogRef<ContractPopupComponent>;
		ref = this.dialog.open(ContractPopupComponent, { disableClose: true, autoFocus: 'dialog'});
		ref.componentInstance.contractData = data;
		ref.componentInstance.invId = id;
		return ref.afterClosed();
	}
	/**
	 * Blank template popup for if any component use in page and popup both side
	 * @param data: Pass data object to component
	 * @returns popup ref
	 */
	public blankTempPopup(data: any): any {
		let ref: MatDialogRef<BlankTempPopupComponent>;
		ref = this.dialog.open(BlankTempPopupComponent);
		ref.componentInstance.data = data;
		return ref.afterClosed();
	}

	public leadsFormPopup(data: any): any {
		let ref: MatDialogRef<LeadsFormPopupComponent>;
		ref = this.dialog.open(LeadsFormPopupComponent);
		ref.componentInstance.data = data;
		return ref.afterClosed();
	}

}
