// The PhantomFieldComponent acts as a hidden guardian within forms, functioning as a honey pot to thwart spam submissions without disrupting user flow.
import { Component, Input, ViewEncapsulation } from '@angular/core';
@Component({
	selector: 'bk-phantom-field',
	template: `<input type="text" class="form-control tjs-phantom-field" [formControl]="control" autocomplete="off">`,
	encapsulation: ViewEncapsulation.None
})
export class PhantomFieldComponent {
	// Variables
	// TODO change any (added any due to error of AbstractControl)
	@Input() control!: any;
}
