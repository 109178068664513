<div class="dir-rtl pt-md-50 pb-md-60 p-20 bg-transparent text-center w-100" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div [id]="section?.title?.id" *ngIf="section?.title?.content" class="tjs-html-content" [innerHTML]="section?.title?.content | safeHtml" ></div>
	<div class="justify-content-center d-md-flex mt-25">
		<!-- Buttons -->
		<div [id]="section?.button?.id" class="tjs-navigate-wrapper justify-content-center flex-column bk-btn-group" *ngIf="section?.button && section?.button?.buttons && (section?.button?.buttons).length > 0">
			<ng-container *ngFor="let btn of section?.button?.buttons;trackBy: secServ.trackByFnIndex">
				<bk-navigate [secSett]="btn" [innerHTML]="btn?.content" [isPopup]="true" [dialogRef]="dialogRef"></bk-navigate>
			</ng-container>
		</div>
	</div>
</div>