<div *ngIf="section" class="tjs-thankyou tjs-thankyou--v2 tjs-thankyou--v2_white py-50">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 mx-auto">
				<div [id]="section?.id" class="tjs-thankyou__inner rounded-5 text-center p-0 shadow-sm">
					<div class="pt-20 pb-50 px-25 px-md-70 rounded-5">
						<!-- Title -->
						<h2 [id]="section?.title_id" *ngIf="section?.title" class="tjs-thankyou__title fw-bold" [innerHTML]="section?.title | safeHtml"></h2>
						<!-- Sub title -->
						<h3 [id]="section?.sub_title_id" *ngIf="section?.sub_title" class="mb-5 fw-bold" [innerHTML]="section?.sub_title | safeHtml"></h3>
						<!-- Description -->
						<p [id]="section?.desc_id" *ngIf="section?.desc" class="mb-35 fs-15" [innerHTML]="section?.desc | safeHtml"></p>
						<!-- Fields -->
						<form bkDebounceEvent [isSubmit]="true" [formGroup]="resetForm" (debounceSubmit)="submitForm()">
							<!-- New password -->
							<div [id]="section?.new_password_id" class="mb-25 text-start">
								<label [id]="section?.new_password?.label_id" class="form-label bk-form-label" *ngIf="section?.new_password?.label" [innerHTML]="section?.new_password?.label | safeHtml"></label>
								<input class="form-control bk-input" [ngClass]="{'is-invalid': f['new_password']?.touched && f['new_password']?.errors}" [placeholder]="section?.new_password?.placeholder" formControlName="new_password" type="password">
								<!-- Error template -->
								<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'new_password'}"></ng-container>
							</div>
							<!-- Confirm password -->
							<div [id]="section?.confirm_password_id"class="mb-25 text-start">
								<label [id]="section?.confirm_password?.label_id" class="form-label bk-form-label" *ngIf="section?.confirm_password?.label" [innerHTML]="section?.confirm_password?.label | safeHtml"></label>
								<input  class="form-control bk-input" [ngClass]="{'is-invalid': f['confirm_password']?.touched && f['confirm_password']?.errors}" [placeholder]="section?.confirm_password?.placeholder" formControlName="confirm_password" type="password">
								<!-- Error template -->
								<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'confirm_password'}"></ng-container>
							</div>
							<!-- Buttons -->
							<div class="d-block w-100" *ngIf="section?.button">
								<bk-navigate [secId]="section?.button_id" [designId]="section?.button?.design_id" [isSubmit]="true" [innerHTML]="section?.button?.content" customClass="btn btn-primary btn-lg border-transparent" essentialClass="w-100 bk-form-btn"></bk-navigate>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Form error template-->
<ng-template #errorTemp let-controlName="controlName">
	<ng-container *ngIf="f[controlName]?.touched && (f[controlName]?.errors?.required || f[controlName]?.errors?.minlength || f[controlName]?.errors?.matching)">
		<div class="tjs-error">
			<i class="tjsicon-attention"></i>
			<ng-container *ngIf="f[controlName]?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.minlength"> {{initServ?.appStr?.errorMsg?.passwordLength | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.matching"> {{initServ?.appStr?.errorMsg?.passwordNotMatch | translate}} </ng-container>
		</div>
	</ng-container>
</ng-template>