<div class="position-relative">
	<ng-container *ngTemplateOutlet="addTemp"></ng-container>
	<ng-container [ngSwitch]="paymentGateway">
		<!-- Stripe -->
		<div *ngSwitchCase="'stripe'">
			<div class="mb-10">
				<ng-container *ngTemplateOutlet="labelTemp"></ng-container>
				<ng-container *ngIf="stripeKey; else noKey">
					<div class="row" id="payment_details">
						<div class="col-xl-12 d-flex align-items-center text-start">
							<div id="card-element" class="w-100 mb-10 stripe-account form-control card-container"></div>
							<bk-tooltip *ngIf="section?.cvv_tooltip" customClass="bk-tooltip" [id]="section?.cvv_tooltip_id" [content]="section?.cvv_tooltip | translate"></bk-tooltip>
						</div>
					</div>
					<div id="card-errors" class="tjs-error bk-error"></div>
				</ng-container>
				<ng-template #noKey>
					<span class="d-block bk-form-desc alert alert-warning" [innerHTML]="initServ.appStr?.text?.noPaymentProcessor | translate | safeHtml"></span>
				</ng-template>
			</div>
		</div>
		<!-- SquareUp -->
		<div *ngSwitchCase="'square'">
			<!-- <div class="alert alert-danger mb-20 d-flex align-items-center justify-content-between" role="alert" translate>Please note that it may take some time for the newly added cards to be displayed as we await a response from your payment gateway. Try reloading the page in a few minutes to see the cards. </div> -->
			<ng-container *ngTemplateOutlet="labelTemp"></ng-container>
			<div class="row" id="payment_details">
				<div class="col-xl-12 d-flex align-items-center text-start">
					<div id="square-container" class="w-100 p-0 mb-10 border-0"></div>
					<bk-tooltip class="h-100 mt-30" *ngIf="section?.cvv_tooltip" customClass="bk-tooltip" [id]="section?.cvv_tooltip_id" [content]="section?.cvv_tooltip | translate"></bk-tooltip>
				</div>
				<div id="square-custom-errors" class="tjs-error bk-error"></div>
			</div>
		</div>
		<!-- Paypal -->
		<div *ngSwitchCase="['paypal', 'authorizedotnet'].includes(paymentGateway) ? paymentGateway : '' ">
			<ng-container *ngTemplateOutlet="labelTemp"></ng-container>
			<form [formGroup]="paymentForm">
				<div class="mb-20 text-start">
					<div class="row mx-n5" id="payment_details">
						<div class="col-sm-6 col-md-6 mb-md-0 mb-20 px-5">
							<input [ngClass]="{'is-invalid': paymentForm.controls['card_number'].touched && paymentForm.controls['card_number'].errors}" class="form-control px-10" type="text" [attr.placeholder]="'Card number' | translate" formControlName="card_number">
						</div>
						<div class="col-sm-6 col-md-3 mb-sm-0 mb-20 px-5">
							<input [ngClass]="{'is-invalid': ((paymentForm.controls['card_exp_month'].touched && paymentForm.controls['card_exp_month'].errors) || (paymentForm.controls['card_exp_year'].touched && paymentForm.controls['card_exp_year'].errors))}" class="form-control px-10" type="text" [textMask]="maskObj" [(ngModel)]="expiryDate" [ngModelOptions]="{standalone: true}" [attr.placeholder]="'MM/YYYY' | translate" (focusout)="setExpiryDate()" (focusin)="resetExpiryVali()">
						</div>
						<div class="col-sm-6 col-md-3 d-flex align-items-center px-5">
							<input [ngClass]="{'is-invalid': paymentForm.controls['card_cvv'].touched && paymentForm.controls['card_cvv'].errors}" class="form-control px-10" type="text" [attr.placeholder]="'CVV' | translate" formControlName="card_cvv">
							<bk-tooltip *ngIf="section?.cvv_tooltip" customClass="bk-tooltip" [id]="section?.cvv_tooltip_id" [content]="section?.cvv_tooltip | translate"></bk-tooltip>
						</div>
					</div>
					<!-- Card number -->
					<div class="tjs-error bk-error" *ngIf=" paymentForm.controls['card_number']?.touched && paymentForm.controls['card_number']?.errors?.required" translate>
						<i class="tjsicon-attention"></i>This field should not be empty.
					</div>
					<div class="tjs-error bk-error" *ngIf="paymentForm.controls['card_number']?.touched && paymentForm.controls['card_number']?.errors?.pattern" translate>
						<i class="tjsicon-attention"></i>Please enter a valid card number.
					</div>
					<!-- Card month -->
					<div class="tjs-error bk-error" *ngIf="!paymentForm.controls['card_number']?.errors && ((paymentForm.controls['card_exp_month']?.touched && paymentForm.controls['card_exp_month']?.errors?.required) || (paymentForm.controls['card_exp_year']?.touched && paymentForm.controls['card_exp_year']?.errors?.required))" translate>
						<i class="tjsicon-attention"></i> Please enter a valid date.
					</div>
					<!-- Card CVC -->
					<div class="tjs-error bk-error" *ngIf="!paymentForm.controls['card_number']?.errors && !paymentForm.controls['card_exp_month']?.errors && (paymentForm.controls['card_cvv']?.touched && paymentForm.controls['card_cvv']?.errors?.required)" translate>
						<i class="tjsicon-attention"></i>This field should not be empty.
					</div>
					<div class="tjs-error bk-error" *ngIf="!paymentForm.controls['card_number']?.errors && !paymentForm.controls['card_exp_month']?.errors && (paymentForm.controls['card_cvv']?.touched && !paymentForm.controls['card_cvv']?.errors?.required && paymentForm.controls['card_cvv']?.errors?.pattern)" translate>
						<i class="tjsicon-attention"></i>Please enter a valid cvv.
					</div>
					<div id="paypal-errors" class="tjs-error bk-error"></div>
				</div>
			</form>
		</div>
	</ng-container>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
<div>
	<img loading="auto" [src]="initServ.img?.PaymentCard" width="318" height="30" class="img-fluid bk-payment-card-img" alt="SSL Card">
</div>
<!-- Label template -->
<ng-template #labelTemp>
	<ng-container *ngIf="isDynamic; else customText">
		<label class="form-label bk-form-label d-block bk-label-fw" *ngIf="!hideLabel && section?.add_new_card" [id]="section?.add_new_card_id" [innerHTML]="section?.add_new_card | translate | safeHtml"></label>
		<label class="form-label bk-form-label d-block bk-label-fw" *ngIf="!hideLabel && section?.new_cc_label" [id]="section?.new_cc_label_id" [innerHTML]="section?.new_cc_label | translate | safeHtml"></label>
	</ng-container>
	<ng-template #customText>
		<label  class="form-label bk-form-label" *ngIf="!hideLabel" [id]="section?.form?.payment_info_id">{{initServ.appStr?.text.addNewCard | translate}}</label>
	</ng-template>
</ng-template>

<ng-template #addTemp>
	<div id="billing-address" *ngIf="billingAddr && initServ?.allowBillingAddr" [formGroup]="billingAddrForm">
		<div [class.mt-20]="isMultLocBillingAddr" formGroupName="billing_address" [id]="section?.address?.id">
			<!-- Address -->
			<div class="mb-20">
				<label class="form-label bk-form-label bk-label-fw" [id]="section?.address?.label_id" [innerHTML]="section?.address?.label | safeHtml"></label>
				<bk-tooltip customClass="bk-form-check-label bk-form-label bk-tooltip" *ngIf="section?.address?.label && section?.address?.tooltip"  [id]="section?.address?.tooltip_id" content="{{ section?.address?.tooltip }}"></bk-tooltip>
				<input type="text" bk-google-places-autocomplete [class.is-invalid]="billingAddrGrp.controls['address']?.hasError('required') && billingAddrGrp.controls['address']?.touched" class="form-control bk-input" placeholder="{{section?.address?.placeholder}}" formControlName="address" (focusin)="isZipcode=false" (focusout)="getAddress($event)" (addressChange)="onAddressChange($event)">
				<!-- Error -->
				<div class="tjs-error" *ngIf="billingAddrGrp.controls['address']?.touched && billingAddrGrp.controls['address']?.errors?.required">
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="billingAddrGrp.controls['address']?.errors?.required" translate> {{initServ.appStr?.errorMsg?.empty | translate}} </ng-container>
				</div>
				<input id="city" type="text" class="d-none" formControlName="city">
				<input id="state" type="text" class="d-none" formControlName="state">
			</div>
			<!-- Zipcode -->
			<div class="mb-20" *ngIf="isZipcode && paymentGateway !== 'square' && billingAddrGrp.controls['zipcode']">
				<label class="form-label bk-form-label bk-label-fw w-100"  [innerHTML]="section?.address?.zipcode_label | safeHtml"></label>
				<input id="zipcode" [ngClass]="{'is-invalid':billingAddrGrp.controls['zipcode']?.touched && billingAddrGrp.controls['zipcode']?.errors}" type="text" class="form-control" formControlName="zipcode" placeholder="{{section?.address?.zipcode_placeholder}}">
				<!-- Error -->
				<div class="tjs-error" *ngIf="billingAddrGrp.controls['zipcode']?.touched && (billingAddrGrp.controls['zipcode']?.errors?.required || billingAddrGrp.controls['zipcode']?.errors?.pattern)">
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="billingAddrGrp.controls['zipcode']?.errors?.required" translate> {{initServ.appStr?.errorMsg?.empty | translate}} </ng-container>
					<ng-container *ngIf="billingAddrGrp.controls['zipcode']?.errors?.pattern" translate> {{initServ.appStr?.errorMsg?.specialChars | translate}} </ng-container>
				</div>
			</div>
		</div>
	</div>
</ng-template>
