<ng-container *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType]">
	<!-- Version-2 -->
	<ng-container *ngIf="pageSett[secId].list_type && pageSett[secId].list_type == 'custom'; else dynamic">
		<div [id]="secId" class="tjs-accordion tjs-accordion__arrow tjs-accordion--left py-35 tjs-accordion__button-sm" *ngIf="section">
			<div class="container" [id]="section?.list?.id">
				<!-- Title template-->
				<ng-container *ngIf="section?.title && section?.title?.content">
					<ng-container *ngTemplateOutlet="titleTemp; context:{id:section?.title?.id,content: section?.title?.content}"></ng-container>
				</ng-container>
				<div id="accordion_no-cat_{{secId}}" *ngIf="section?.list && section?.list?.items && (section?.list?.items).length > 0">
					<ng-container *ngFor="let item of section?.list?.items; let i=index;trackBy: secServ.trackByFnIndex">
						<div [id]="item?.id" class="pe-15 bk-item" *ngIf="item?.title || item?.desc">
							<!-- Accordion template -->
							<ng-container *ngTemplateOutlet="accordionTemp; context:{i:i, cat_id:'no-cat', title:item?.title, title_id:item?.title_id, desc:item?.desc, desc_id: item?.desc_id}"></ng-container>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>
	<!-- Version-2 -->
	<ng-template #dynamic>
		<div [id]="secId" class="tjs-accordion tjs-accordion__arrow tjs-accordion__button-sm tjs-accordion--left py-35" *ngIf="faqs && faqs.length > 0 && categories && categories.length > 0">
			<div class="container" [id]="section?.list?.id">
				<div class="row">
					<ng-container *ngFor="let cat of categories;trackBy:secServ.trackByFnIndex">
						<div [ngClass]="{'col-12 col-md-6 col-lg-6 mb-50':cat?.id != 'no-cat'}">
							<!-- Title -->
							<ng-container *ngIf="cat?.category_name">
								<ng-container *ngTemplateOutlet="titleTemp; context:{id:section?.title?.id,content: cat?.category_name}"></ng-container>
							</ng-container>
							<div id="{{'accordion_'+cat?.id+'_'+secId}}" aria-multiselectable="true">
								<ng-container *ngFor="let faq of faqs; let i = index;trackBy: secServ.trackByFnIndex">
									<div class="pe-15 bk-item" *ngIf="faq?.category && ((faq?.category).length > 0 && (faq?.category).includes(cat?.id) || cat?.id == 'no-cat')">
										<!-- Accordion template -->
										<ng-container *ngTemplateOutlet="accordionTemp; context:{i:i, cat_id:cat?.id, title:faq?.question, title_id:'faq_'+i, desc:faq?.answer, desc_id: 'faq_ans_'+i}"></ng-container>
									</div>
								</ng-container>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-template>
</ng-container>
<!-- Title -->
<ng-template #titleTemp let-id="id" let-content="content" >
	<div class="tjs-section__title border-bottom mb-30">
		<div class="tjs-section__heading">
			<h2 [id]="id" class="bk-title mb-30 fw-bold text-primary" [innerHTML]="content | safeHtml"></h2>
		</div>
	</div>
</ng-template>
<!-- Accordion -->
<ng-template #accordionTemp let-i="i" let-cat_id="cat_id" let-title="title" let-title_id="title_id" let-desc="desc" let-desc_id="desc_id">
	<!-- Title -->
	<div id="{{'title_' + i+'_'+secId}}" *ngIf="title">
		<button type="button" class="fw-bold accordion-button collapsed" [ngClass]="{'collapsed':i>0}" data-bs-toggle="collapse" attr.data-bs-target="{{'#collapse_' + i+'_'+secId+'_'+cat_id}}" attr.data-bs-parent="{{'#accordion_'+cat_id+'_'+secId}}" aria-expanded="true" attr.aria-controls="{{'collapse_' + i+'_'+secId+'_'+cat_id}}">
			<span [id]="title_id" class="text-dark ms-5 accordion-title bk-item-title" [innerHTML]="title | safeHtml" ></span>
		</button>
	</div>
	<!-- Description -->
	<div id="{{'collapse_'+i+'_'+secId+'_'+cat_id}}" class="collapse" [ngClass]="{'show':i==0}" attr.aria-labelledby="{{'title_' + i+'_'+secId}}" attr.data-bs-parent="{{'#accordion_'+cat_id+'_'+secId}}" *ngIf="desc">
		<div [id]="desc_id" class="ps-lg-25 ps-30 fs-15 bk-item-desc" [innerHTML]="desc | safeHtml"></div>
	</div>
</ng-template>