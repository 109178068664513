import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { InitServ, UtilServ } from "src/app/Services";

@Component({
	selector: 'bk-branding',
	templateUrl: `./BKBranding.component.html`,
	encapsulation: ViewEncapsulation.None
})
export class BKBrandingComponent implements OnInit {
	@Input() type: string = 'text';
	@Input() customWrapClass: string = ''
	@Input() customAnchorClass: string = ''
	poweredByLink: string = '';
	isStarterPlans: boolean = false;

	constructor(public initServ: InitServ, private utilServ: UtilServ) {
		this.isStarterPlans = this.initServ.isStarterPlans('BK-branding');
	}
	ngOnInit() {
		this.poweredByLink = this.utilServ.poweredByLink();
	}

	public poweredByLinkRedirect(e: any): void {
		e.preventDefault();
		e.stopPropagation();
		if(e?.target?.href){
			window.open(e?.target?.href, '_blank');
		}
	}
}
