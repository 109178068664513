import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, InitServ, LoaderServ, UtilServ } from '../../Services';

@Component({
	selector: 'bk-password-protect',
	templateUrl: './PasswordProtect.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class PasswordProtectComponent implements OnInit {

	protectedForm!: FormGroup; // Password protected form
	// Variables
	loaderId: string = 'protected-loader';
	siteData: any;

	constructor(private frmBldr: FormBuilder, public initServ: InitServ, private router: Router, private toastr: ToastrService, private loader: LoaderServ, private utilServ: UtilServ) {
		this.siteData = this.initServ.siteData;
		// Current login user, no need to protect password
		const currentUser = this.utilServ.appLocalStorage();
		if (currentUser && this.siteData && this.siteData.theme_settings && this.siteData.theme_settings.settings && this.siteData.theme_settings.settings.enable_password_protect) {
			this.router.navigate(['/']);
		}
	}

	ngOnInit(): void {
		// Password protected form build
		this.protectedForm = this.frmBldr.group({ password: ['', Validators.required] })
	}
	/**
	 * Match the password from theme settings and navigate it
	 */
	public submitForm(): void {
		if(this.protectedForm.valid){
			this.loader.show(this.loaderId);
			if(this.protectedForm.controls['password'].value == this.siteData.theme_settings.settings.password){
				try{
					localStorage.setItem('passwordProtected', 'true');
				}catch(err){}
				this.router.navigate(['/']);
			} else{
				this.toastr.error(this.initServ.appStr.toastr.enteredWrongPwd)
			}
			this.loader.hide(this.loaderId);
		} else{
			this.toastr.error(this.initServ.appStr.toastr.enterPwd);
			this.protectedForm.controls['password'].markAsTouched();
		}
	}
}