import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ } from '../../../Services';

@Component({
	selector: 'bk-bullet-list',
	template: `
	<div class="p-10" *ngIf="section && section?.items && (section?.items).length > 0">
		<div [id]="secId" class="bk-list">
			<ng-container *ngFor="let item of section?.items;trackBy: secServ.trackByFnIndex">
				<div *ngIf="item?.desc">
					<div [id]="item?.id" class="bk-inner-elem">
						<div>
							<p class="bk-item-desc" [id]="item?.desc_id" [innerHTML]="item?.desc | safeHtml"></p>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class BulletListComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = null;
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.pageSett = this.sectionData.section_settings;
			this.secServ.setServData(this.pageSett, this.sectionData.content);
			if(this.pageSett && this.pageSett[this.secId] && this.secServ.buildList(this.secId) && this.secServ.checkElementStatus(this.secId)){
				this.section = this.secServ.buildList(this.secId);
			}
		}
	}
}