import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, UtilServ } from '../../../../Services';
@Component({
	selector: 'bk-footer-menu',
	template: `<div class="tjs-footer__menu" *ngIf="menuData && menuData.length>0">
		<ul class="list-unstyled mb-0 mx-n10" [id]="section">
			<li class="tjs-footer__menu-item d-inline-flex mb-10 ps-10 pe-5 pe-sm-15" *ngFor="let menu of menuData;trackBy: utilServ.trackByFnIndex">
				<bk-navigate customClass="d-block btn btn-link bk-footer-link" type="menu" [secSett]="menu" innerHTML="{{(menuContent[menu?.identifier])}}"></bk-navigate>
			</li>
		</ul>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class FooterMenuComponent implements OnInit {
	// Variables
	@Input() section: any;
	@Input() pageSett: any;
	@Input() menus: any;
	@Input() menuContent: any = {};
	menuData: any = [];
	constructor(public utilServ: UtilServ) { }
	ngOnInit() {
		if(this.menus && (this.menus).length > 0){
			this.menuData = [];
			for(let menu of this.menus){
				if(this.utilServ.checkMenu(menu, 'footer', this.pageSett)){
					this.menuData.push(menu);
				}
			}
		}
	}
}