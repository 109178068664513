import { Component } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../Services';
// Component
import { ServiceProviderComponent } from '../ServiceProvider/ServiceProvider.component';

@Component({
	selector: 'bk-service-provider-res',
	templateUrl: './ServiceProviderRes.component.html',
	providers: [NgOnDestroy]
})
export class ServiceProviderResComponent extends ServiceProviderComponent {

	ngOnInit(): void {
		super.ngOnInit();
	}
}