import { Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter, Self} from '@angular/core';
import { takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, InitServ, ApiServ, UtilServ } from '../../../../Services';
// Constants
import { TEXT_REG_EXP, EMAIL_REG_EXP, COMMENT_REG_EXP } from '../../../../Constants';

@Component({
	selector: 'bk-comment-reply-form',
	templateUrl: './CommentReplyForm.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class CommentReplyFormComponent implements OnInit {

	// Variables
	@Input() blogId: any;
	@Input() type: string | undefined;
	@Input() replyTo: any;
	@Input() parentId: any;
	@Output() callback: EventEmitter<any> = new EventEmitter();
	@Input() settings: any;
	commentForm!: FormGroup;
	currentUser: any;

	constructor(private frmBldr: FormBuilder,public initServ: InitServ, private apiServ: ApiServ, public utilServ: UtilServ, @Self() private destroy: NgOnDestroy, private toastr: ToastrService) {
		// Current user
		this.currentUser = this.utilServ.appLocalStorage();
	}

	ngOnInit(): void {
		// Build comment form
		this.commentForm = this.frmBldr.group({
			first_name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
			last_name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
			email : [null, [Validators.required, Validators.pattern(EMAIL_REG_EXP)]],
			comment : [null, [Validators.required, Validators.pattern(COMMENT_REG_EXP)]]
		});
		// Set the form value and validation
		this.formValidation();
	}
	/**
	 * Form validation with form value.
	 */
	private formValidation(): void {
		// Set the form value
		this.filledForm();
		// Update validation
		if(this.settings && !this.settings.fields_required){
			this.commentForm.controls['first_name'].clearValidators();
			this.commentForm.controls['last_name'].clearValidators();
			this.commentForm.controls['email'].clearValidators();
			this.commentForm.controls['email'].setValidators([Validators.pattern(EMAIL_REG_EXP)]);
			this.commentForm.controls['first_name'].updateValueAndValidity();
			this.commentForm.controls['last_name'].updateValueAndValidity();
			this.commentForm.controls['email'].updateValueAndValidity();
		}
	}
	/**
	 * Filled the form field values
	 */
	private filledForm(): void {
		if(this.currentUser){
			this.commentForm.patchValue({
				first_name: this.currentUser.first_name,
				last_name: this.currentUser.last_name && this.currentUser.last_name,
				// TODO: Removed using username from local storage
				email: this.initServ.userInfo?.email_id
				// email: this.currentUser.username
			})
		}
	}
	/**
	 * Submit the comment form
	 */
	public submitForm(): void {
		if(this.commentForm.valid){
			let formData = this.commentForm.value;
			let fullName: string = this.getFullName(formData);
			let isAnonymous: any;
			if(this.currentUser){isAnonymous = "no"}
			let data = {
				email: formData.email,
				comment: formData.comment,
				blog_id: this.blogId,
				parent_id: null,
				reply_to: null,
				reply_to_type: "blog",
				is_anonymous: isAnonymous,
				full_name: fullName ? fullName : "Anonymous"
			}
			if(this.type == 'reply'){
				data.parent_id = this.parentId;
				data.reply_to = this.replyTo ? this.replyTo : this.parentId;
			}
			this.apiServ.callApi('POST', 'Comment', data).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
		} else{
			for(let i in this.commentForm.controls) {
				this.commentForm.controls[i].markAsTouched();
			}
		}
	}
	/**
	 * Get the blog comment user name
	 * @param formData
	 * @returns : string
	 */
	private getFullName(formData: any): string {
		let name = '';
		if(formData.first_name && formData.last_name){
			name = formData.first_name+' '+formData.last_name;
		} else if(formData.first_name){
			name = formData.first_name;
		} else if(formData.last_name){
			name = formData.last_name;
		}
		return name;
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res)){
			this.toastr.success(res.message);
			// Reset form
			this.commentForm.reset();
			// Set the form value and validation
			this.formValidation();
			this.commentForm.markAsUntouched();
			// Callback to parent component
			this.callback.emit(true);
		} else{
			if(res && res.message){
				this.toastr.error(res.message);
			}
		}
	}
}
