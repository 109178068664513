/**
 * Component show the all live reviews
 */
import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef, Self, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
// External library
import KeenSlider, { KeenSliderInstance, KeenSliderOptions } from "keen-slider";
// Services
import { ApiServ, InitServ, NgOnDestroy, UtilServ } from 'src/app/Services';
// Interface
import { APIRes, Review } from 'src/app/Interfaces';
interface ReviewsApiRes extends APIRes {
	data?: Review[];
}
interface CssVariables {
	[x:string]:string
}
@Component({
	selector: 'bk-live-reviews',
	templateUrl: './live-reviews.component.html',
	styles: [`bk-live-reviews {
		--tjs-review-bg-color: #03A9F4;
		--tjs-review-font-color: #fff;
		--tjs-review-name-color: #fff;
		--tjs-review-quote-color: #fff;
		--tjs-review-arrow-bg-color: #fff;
		--tjs-review-arrow-color: #03A9F4;
		--tjs-review-font-size: 16px;
		--tjs-review-title-font-size: 18px;
	}
	/* review section block  */
	bk-live-reviews .review-block {
		background-color: var(--tjs-review-bg-color);
		color: var(--tjs-review-font-color);
		font-size: var(--tjs-review-font-size);
	}
	bk-live-reviews .review-block h5 {
		color: var(--tjs-review-name-color);
		font-size: var(--tjs-review-title-font-size);
	}
	/* review slider css */
	bk-live-reviews .sidebar-reviews { height: 100% !important}
	bk-live-reviews .sidebar-reviews .slide-item { width:100%; }
	bk-live-reviews .sidebar-reviews .slide-content .slide-quote {
		color: var(--tjs-review-quote-color);
		font-size: var(--tjs-review-font-size);
	}
	bk-live-reviews .slide-content a, bk-live-reviews .slide-content a:hover{
		color: #fff;
		font-size: 14px;
		text-decoration: underline;
	}
	/* arrows buttons */
	bk-live-reviews .prev-review, bk-live-reviews .next-review {
		background-color:var(--tjs-review-arrow-bg-color);
		border-radius: 2px;
		color: var(--tjs-review-arrow-color);
		padding: 2px 6px;
	}
	bk-live-reviews .prev-review:hover, bk-live-reviews .next-review:hover {
		color: var(--tjs-review-arrow-color);
	}
	bk-live-reviews .prev-review i, bk-live-reviews .next-review i { font-size: 14px; }
`],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class LiveReviewsComponent implements OnInit {

	@ViewChild("reviewSlide") sliderRef: ElementRef<HTMLElement> | undefined;
	isLiveReviews: boolean = false;
	custDetail: string = '';
	reviews?: Review[] | null = null;
	slider: KeenSliderInstance | null = null;
	slideConfig: KeenSliderOptions = {
		slides: { perView: 1 },
		loop: true,
		defaultAnimation: { duration: 1000 }
	};
	liveReviewLabel: string = 'Live Reviews';
	starColor: string = '#f7c522';
	// these are css variables used in this component to update styles without prefix `--tjs-review-*`
	cssVarObj: CssVariables = {
		'bg-color': '#03A9F4',
		'font-color': '#fff',
		'name-color': '#fff',
		'quote-color': '#fff',
		'arrow-bg-color': '#fff',
		'arrow-color': '#03A9F4',
		'font-size': '16px',
		'title-font-size': '18px'
	}

	constructor(public initServ: InitServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, public utilServ: UtilServ, private cDRef: ChangeDetectorRef, public el: ElementRef) {
		this.setReviewsAdminSett();
		this.setCustomStyleForAccs(); // add custom style for the customer accounts.
	}

	/**
	 * Sets custom styles for different accounts based on the current hostname.
	 *
	 * This method creates a copy of the existing CSS variables object and determines the appropriate styles to apply based on the hostname of the current window location. It executes a specific case for each
	 * recognized hostname, calling the corresponding method to set account specific styles.
	 *
	 * After determining the account-specific styles, the method iterates through the keys of the copied CSS variables object and applies each variable's value using the `setCssVarValues` method.
	 *
	 * Hostname cases include:
	 * - 'organichomes' for Account 1
	 * - 'cleanrapp' for Account 2
	 * - 'ancillis' for Account 3
	 * - 'booking.tidy-keeper' for Account 4
	 * - 'sweeple' for Account 5
	 * - 'baycitypropertyserviceinc' for Account 6
	 */
	// TODO: Harleen mam, Check `font-size` where it is used for heading text or paragraph text and also check below condition for the account names.
	private setCustomStyleForAccs(): void {
		// copy the css variables obj
		let obj: CssVariables = { ...this.cssVarObj };

		// !TODO: get and check the Host and exceute the case.
		let domainName: string =  ((window.location.hostname).split("."))[0] || '';
		switch(domainName){
			case 'organichomes' : this.setForAcc1(obj); break; // organichomes.bookingkoala.com
			case 'cleanrapp' : this.setForAcc2(obj); break; // cleanrapp.bookingkoala.com
			case 'ancillis' : this.setForAcc3(obj); break; // ancillis.bookingkoala.com
			case 'booking.tidy-keeper' : this.setForAcc4(obj); break; // booking.tidy-keeper.com
			case 'sweeple' : this.setForAcc5(obj); break; // https://booksweeple.com/
			case 'baycitypropertyserviceinc' : this.setForAcc6(obj); break; // baycitypropertyserviceinc
			default: break;
		}

		//  Set the color the css variables
		for (let cssVarkey of Object.keys(obj)) {
			this.setCssVarValues(cssVarkey, obj[cssVarkey]);
		}
	}

	private setForAcc1(obj: CssVariables): void {	// organichomes.bookingkoala.com
		obj['bg-color'] = '#50AE48';
		obj['font-color'] = '#fff';
		obj['name-color'] = '#000';
		obj['quote-color'] = '#fff';
		obj['arrow-bg-color'] = 'transparent';
		obj['arrow-color'] = '#8DCC64';
		this.liveReviewLabel = '';
		this.starColor = '#ffc107';
	}
	private setForAcc2(obj: CssVariables): void { // cleanrapp.bookingkoala.com
		obj['bg-color'] = 'transparent';
		obj['font-color'] = '#000';
		obj['quote-color'] = '#8DCC64';
		obj['arrow-bg-color'] = 'transparent';
		obj['arrow-color'] = '#8DCC64';
		this.liveReviewLabel = '';
	}
	private setForAcc3(obj: CssVariables): void {// ancillis.bookingkoala.com
		obj['bg-color'] = '#fff';
		obj['font-color'] = '#000';
		obj['quote-color'] = '#000';
		obj['arrow-bg-color'] = '#000';
		obj['arrow-color'] = '#fff';
		this.liveReviewLabel = '';
	}
	private setForAcc4(obj: CssVariables): void { // booking.tidy-keeper.com
		obj['bg-color'] = '#DBFBAC';
		obj['font-color'] = '#5b9802';
		obj['quote-color'] = '#000';
		obj['arrow-bg-color'] = '#5b9802';
		obj['arrow-color'] = '#DBFBAC';
		this.liveReviewLabel = '';
	}
	private setForAcc5(obj: CssVariables) { // sweeple
		obj['bg-color'] = '#00a9dd';
		obj['arrow-color'] = '#00a9dd';
	}
	private setForAcc6(obj: CssVariables) { // baycitypropertyserviceinc
		obj['bg-color'] = 'rgb(222, 255, 79)';
		obj['font-color'] = '#0F93FE';
		obj['arrow-bg-color'] = '#0F93FE';
		obj['arrow-color'] = 'rgb(222, 255, 79)';
	}

	/**
	 * Sets a CSS custom property (variable) on the element's style.
	 *
	 * @param cssVarKey - The key for the CSS variable (without the prefix).
	 * @param value - The value to assign to the CSS variable.
	 * @param prefix - An optional prefix for the CSS variable (default is 'tjs-review').
	 *
	 * This method constructs a custom property name using the provided prefix and key, and sets its value in the native element's style.
	 * This can be useful for dynamically updating styles based on component state or other logic.
	 */
	private setCssVarValues(cssVarKey: string, value: string, prefix: string = 'tjs-review'): void {
		this.el.nativeElement.style.setProperty(`--${prefix}-${cssVarKey}`, value);
	}

	ngOnInit() {
		if (this.isLiveReviews) {
			this.getReviewsApi();
		}
	}

	/**
	 * Sets the review settings based on the admin's configurations.
	 * Configures whether live reviews are enabled and if customer details should be shown.
	 */
	private setReviewsAdminSett(): void {
		if (this.initServ.appAdmnStngs?.merchant_settings?.customers) {
			let { enable_live_reviews, show_customer_detail } = this.initServ.appAdmnStngs.merchant_settings.customers;
			// Review enable
			if (enable_live_reviews && enable_live_reviews === 'yes') {
				this.isLiveReviews = true;
			}
			// Review customer name settings
			if (show_customer_detail) {
				this.custDetail = show_customer_detail;
			}
		}
	}

	/**
	 * Retrieves reviews from the API with a specified limit.
	 * Sends a GET request to the 'Reviews' endpoint with query parameters and processes the response.
	 */
	private getReviewsApi(): void {
		this.apiServ.callApi('GET', 'Reviews').pipe(takeUntil(this.destroy)).subscribe((res: ReviewsApiRes) => this.onResultCallback(res));
	}

	/**
	 * Handles the API response for reviews.
	 * Updates the reviews data and initializes the slider component if the data is valid.
	 * Triggers change detection to ensure the view updates correctly.
	 * @param res - The API response object containing review data.
	 */
	private onResultCallback(res: ReviewsApiRes): void {
		if (this.apiServ.checkAPIRes(res) && this.utilServ.checkArrLength(res?.data)) {
			this.reviews = res.data;
			setTimeout(() => {
				if (this.sliderRef) {
					if (this.slider) { this.slider.destroy(); }
					this.slider = new KeenSlider(this.sliderRef.nativeElement, this.slideConfig, [(slider) => this.utilServ.autoPlay(slider, 6000)])
				}
			}, 100);
		}
		this.cDRef.detectChanges();
	}

	ngOnDestroy(): void {
		if (this.slider) { this.slider.destroy(); }
	}
}
