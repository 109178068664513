import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-time-display',
	template: `<span class="d-inline-block dir-ltr" [class.ms-5]="ml5Status">{{displayTime}}</span>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class TimeDisplayComponent implements OnInit {
	// Variables
	@Input() time : any;
	@Input() isLocal : boolean = false;
	@Input() ml5Status : boolean = true;
	@Input() isSec:boolean=false;
	Hours: any;
	Minutes: any;
	Seconds: any;
	Meridian: any;
	displayTime : string = '';

	constructor(private utilServ:UtilServ) { }

	ngOnInit(): void {
		if(this.time){
			let date = new Date(this.time * 1000);
			let hours = date.getHours();
			let minutes = date.getMinutes();
			let seconds = date.getSeconds();
			if(hours >= 12){
				this.Meridian = "PM";
				this.Hours = hours;
				if(hours > 12){
					this.Hours = hours - 12;
				}
				this.Minutes = minutes;
			}
			else{
				this.Meridian = "AM";
				this.Hours = hours;
				this.Minutes = minutes;
			}
			this.Hours = this.pad(this.Hours);
			this.Minutes = this.pad(this.Minutes);
			this.Seconds = this.pad(seconds);
			this.displayTime = this.Hours + ":" + this.Minutes;
			if(this.isSec && this.Seconds){
				this.displayTime += ":"+this.Seconds;
			}
			this.displayTime += " " + this.Meridian;
			this.displayTime = this.utilServ.convertTime24HFormat(this.displayTime);
		}
	}
	// Slice the number
	private pad(num: number): string {
		return ("0"+num).slice(-2);
	}
}