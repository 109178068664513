import { Component, OnInit, Self, ViewEncapsulation, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
// External lib
import { ToastrService } from "ngx-toastr";
// Child component, Custom validator
import { PaymentGatewayComponent } from '../../../../Global/GlobalDefault';
// Services
import { ApiServ, CacheService, InitServ, LoaderServ, NgOnDestroy, PaymentGatewayPayload, PaymentGatewayServ, RenderComponentServ, SectionServ, UtilServ } from '../../../../Services';
import { Router } from "@angular/router";
import { BillingAddress } from "src/app/Interfaces";

@Component({
	selector: 'bk-add-card-popup',
	templateUrl: './AddCardPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class AddCardPopupComponent implements OnInit {

	@Input() cards: any;
	@Input() dialogRef: any;
	@Input() uId: string | number = '';
	@Input() slug: string = 'add_card';
	@Input() isPage: boolean = false;
	@Input() secId: string = '';
	@ViewChild(PaymentGatewayComponent) paymentGatewayChild: PaymentGatewayComponent | undefined; //Payment gateway component
	paymentGateway: string = this.initServ.paymentGateway;
	formLoaderId: string = 'card-loader';
	billingForm: FormGroup;
	buildPaymentForm: boolean = false;
	secondStep: boolean = false;
	isGiveOptions: boolean = false;
	isUpcomingBookings: boolean = false;
	isBookingsWithCash: boolean = false;
	isZipcode: boolean = false;
	stripeLocId: any;
	locations: any = [];
	stripeIds: any = [];
	stripeBaseId: any = [];
	setLocation: any = null;
	squareIds: any = [];
	//
	section: any = { title: null, form: null, back_btn: null, add_btn: null, cancel_btn: null, next_btn: null };
	stripeKeys: any;
	addCardBkngSett: any;
	currentUser: any;
	pageSett: any;

	// eslint-disable-next-line max-params
	constructor(public utilServ: UtilServ, public apiServ: ApiServ, @Self() public destroy: NgOnDestroy, public loader: LoaderServ, public initServ: InitServ, public toastr: ToastrService, public cDRef: ChangeDetectorRef, private cacheServ: CacheService, private paymentServ: PaymentGatewayServ, private router: Router, public rcServ: RenderComponentServ, public secServ: SectionServ) {
		this.loader.show(this.formLoaderId);
		this.addCardBkngSett = this.utilServ.addCardBkngOptions();
		// Current login user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
		// initialise the billing form
		this.billingForm = new FormGroup({
			apply_to: new FormControl('upcoming_with_pending'),
			booking_with_cash: new FormControl('no')
		});
		// Get the unique locations in case of stripe and square
		if (this.paymentGateway != 'paypal' && this.paymentGateway != 'authorizedotnet') {
			this.billingForm.addControl('location_id', new FormControl(null, Validators.required));
		}
		this.loadUniqueLocs();
	}
	ngOnInit(): void {
		// build popup section
		if(!this.isPage){
			this.buildPopupSectData();
		} else{
			this.buildPageSectData();
		}
		// Call function to get customer upcoming bookings count.
		this.fetchCustBkngCount();
		this.billingForm.addControl('billing_form_timestamp', new FormControl(''));
	}

	/**
	 * Fetch the customer booking count for
	 */
	private fetchCustBkngCount(): void {
		let uId: string | number = this.uId ? this.uId : this.utilServ.userId();
		let apiName: string = this.uId ? 'PublicUserBkngCount' : 'CustomerBookingsCount';
		if (uId) {
			this.apiServ.callApiWithPathVariables('GET', apiName, [uId]).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.handleBkngCountApiRes(res));
		}
	}

	/**
	 * Function to check the response of get customer bookings count api.
	 * @param res
	 */
	private handleBkngCountApiRes(res: any): void {
		if (this.apiServ.checkAPIRes(res) && res?.data) {
			if (res.data?.upcoming_bookings > 0) {
				this.addCardBkngSett.options.is_upcoming_bookings = true;
			}
			if (res.data?.cash_bookings > 0) {
				this.addCardBkngSett.options.is_bookings_with_cash = true;
			}
		}
		// Update the add card booking settings local variable and form values
		this.updateAddCardBkngSett();
		this.cDRef.detectChanges();
	}
	/**
	 * Updates the card booking settings and patches them to the billing form.
	 */
	private updateAddCardBkngSett(): void {
		// Set flags based on admin options
		if (this.addCardBkngSett?.options && Object.keys(this.addCardBkngSett?.options)?.length > 0) {
			this.isGiveOptions = this.addCardBkngSett?.options.is_give_options;
			this.isUpcomingBookings = this.addCardBkngSett?.options.is_upcoming_bookings;
			this.isBookingsWithCash = this.addCardBkngSett?.options.is_bookings_with_cash;
		}
		// Patch the booking settings options to billing form
		if (this.addCardBkngSett?.bkng && (this.addCardBkngSett?.bkng?.apply_to && this.addCardBkngSett?.bkng?.booking_with_cash)) {
			this.billingForm.patchValue({
				apply_to: this.addCardBkngSett?.bkng?.apply_to,
				booking_with_cash: this.addCardBkngSett?.bkng?.booking_with_cash
			});
		}
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	*/
	private buildPopupSectData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.formLoaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
			this.loader.hide(this.formLoaderId);
			// to detect changes and re-render html
			this.cDRef.detectChanges();
		});
	}
	/**
	 * Build page data
	 */
	private buildPageSectData(): void {
		if (this.secId && this.rcServ.pageData) {
			this.pageSett = this.rcServ.pageData.section_settings;
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
	}
	/**
	 * Reset form
	 */
	public resetForm(): void {
		this.loader.show(this.formLoaderId, this.dialogRef);
		this.buildPaymentForm = false;
		this.secondStep = false;
		if (this.paymentGateway == 'stripe') {
			this.changeStripeLoc();
		} else if (this.paymentGateway == 'square') {
			this.changeSquareLoc();
		} else {
			setTimeout(() => this.resetDefault(), 100);
		}
	}
	/**
	 * The function resets default values and updates the payment form.
	 */
	private resetDefault(): void {
		this.buildPaymentForm = true;
		this.loader.hide(this.formLoaderId, this.dialogRef);
		this.cDRef.detectChanges();
	}
	/**
	 * Change the square location
	 * @param isChange : Change from html
	 */
	public changeSquareLoc(isChange: boolean = false) {
		this.setLocation = null;
		if (isChange) {
			this.buildPaymentForm = false;
		}
		// Location status = true
		if (this.initServ.locationsStatus) {
			if (!isChange && this.locations && (this.locations).length > 0) {
				this.billingForm.controls['location_id'].setValue(this.locations[0].location_id);
			}
		} else {
			// If location status == 0 , set the location_id 0;
			this.billingForm.controls['location_id'].setValue(0);
		}
		this.setLocation = this.billingForm.controls['location_id'].value;
		setTimeout(() => this.resetDefault(), 100);
	}
	/**
	 * Rebuild the stripe form based on location id
	 * @param isChange : Change from html
	 */
	public changeStripeLoc(isChange: boolean = false): void {
		this.stripeLocId = null;
		if (isChange) {
			this.buildPaymentForm = false;
		}
		// Location status = true
		if (this.initServ.locationsStatus) {
			// If stripeIds == 1 so set the stripeBaseLocIds under the location_id.
			// Else set the locations array first value, only work if call this function under the initi function.
			if (this.stripeIds && (this.stripeIds).length == 1) {
				this.billingForm.controls['location_id'].setValue(this.stripeBaseId[0]);
			} else if (!isChange && this.locations && (this.locations).length > 0) {
				this.billingForm.controls['location_id'].setValue(this.locations[0].location_id);
			}
		} else {
			// If location status == 0 , set the location_id 0;
			this.billingForm.controls['location_id'].setValue(0);
		}
		this.stripeLocId = this.billingForm.value.location_id;
		setTimeout(() => this.resetDefault(), 100);
	}

	/**
	 * Method to close the popup with status "true" when successful Add card
	 * @param status boolean
	 */
	public closePopup(status: boolean = false): void {
		this.billingForm.reset();
		this.buildPaymentForm = false;
		this.isZipcode = false;
		if(this.dialogRef){
			this.dialogRef.close(status);
		} else {
			this.redirectUser();
		}
	}

	/**
	 * This function handles the redirection of the user based on the button link type.
	 * It checks the link type and performs different actions accordingly:
	 * - If the link type is 'link' or 'web', it verifies the link and redirects the user to the URL.
	 * - If the link type is 'page', it gets the appropriate redirection URL and redirects the user.
	 * - If the link type is not specified or is of any other type, it redirects the user to either the dashboard or login page based on their authentication status.
	 */
	public redirectUser() {
		let btn = this.section?.add_btn;
		let redirectUrl: any = btn?.link_url;
		let goToLinkUrl: any = null;
		switch (btn?.link_to){
			case 'link':
			case 'web':
				// eslint-disable-next-line no-case-declarations
				let linkUrl: any = this.utilServ.checkHttpExist(redirectUrl);
				this.utilServ.redirect(linkUrl);
				break;
			case 'page':
				goToLinkUrl = this.utilServ.getAfterSuccessRedirection(redirectUrl);
				if(goToLinkUrl){
					this.utilServ.redirect(this.utilServ.generateLink(goToLinkUrl));
				}
			break;
			default:
				if(this.currentUser){
					this.router.navigate([`/${this.initServ.appDynamicRoutes['dashboard']}`]);
				} else {
					this.router.navigate([`/${this.initServ.appDynamicRoutes['login']}`]);
				}
			break;
		}
	}

	/**
	 * Asynchronous function that loads unique locations and their corresponding location data.
	 */
	private async loadUniqueLocs(): Promise<void> {
		if (this.paymentGateway != 'paypal' && this.paymentGateway != 'authorizedotnet') {
			let data:any = await this.utilServ.uniqueLocations();
			this.stripeIds = data.stripeIds;
			this.locations = data.locations;
			this.stripeBaseId = data.stripeBaseId;
			this.squareIds = data.squareIds;
		}
		// set default stripe location for "Stripe" payment gateway, else display the Ui
		this.resetForm();
	}

	/**
	 * Adds a control to the billing form based on certain conditions related to Stripe location IDs.
	 * If there's only one Stripe ID present, it adds the control with that ID. If location status is unavailable,
	 * it adds the control with a base ID of 0.
	 */
	private addStripeLocControl(): void {
		if (this.stripeIds && (this.stripeIds).length == 1) {
			this.billingForm.addControl('stripe_base_location_ids', new FormControl(this.stripeBaseId));
		}
		if (!this.initServ.locationsStatus) {
			this.billingForm.addControl('stripe_base_location_ids', new FormControl([0]));
		}
	}

	/**
	 * Submit form
	 */
	public async submitForm(): Promise<void> {
		if(this.paymentGateway == 'stripe'){
			this.addStripeLocControl();
		}
		this.generateToken();
	}

	/**
	 * Generates a payment gateway token for a new credit card.
	 */
	async generateToken(): Promise<void | boolean> {
		if (!this.utilServ.validatePhantomField(this.billingForm.controls['billing_form_timestamp'].value, 'billing_form_timestamp')) {
			return false;
		}
		if (this.billingForm.valid) {
			this.apiServ.setLoaderId(this.formLoaderId);
			this.loader.show(this.formLoaderId, this.dialogRef);
			let formVal = this.billingForm.value;
			let data: {base_location_id:number | null, payment_method: string, billing_address: BillingAddress} = {
				base_location_id: !isNaN(+formVal.location_id) ? (+formVal.location_id) : null,
				payment_method: 'new_credit_card',
				billing_address: formVal?.billing_address
			}
			let paymentGatewayToken: PaymentGatewayPayload = await this.paymentServ?.generatePaymentGatewayToken(data, this.paymentGatewayChild, true);
			let payload: any = await this.buildPayload();
			// Calls the payment gateway child component's getPaymentToken method to generate the token.
			if(paymentGatewayToken?.token || paymentGatewayToken?.dataValue){
				payload = this.paymentServ.assignToken(payload, paymentGatewayToken, true);
				this.addCard(payload);
			} else {
				this.loader.hide(this.formLoaderId);
			}
		} else {
			this.handleFormErrors();
		}
	}
	/**
	 * Build the add card payload
	 * @returns payload
	 */
	private buildPayload(): any {
		let formVal: any = this.billingForm.value;
		let payload: any = {
			location_id: !isNaN(+formVal.location_id) ? (+formVal.location_id) : null,
			apply_to: formVal.apply_to,
			booking_with_cash: formVal.booking_with_cash,
		};
		if(formVal?.billing_address){
			payload['billing_address'] = formVal.billing_address;
		}
		switch (this.initServ.paymentGateway) {
			case 'stripe':
				payload = this.stripePayload(payload);
				break;
			case 'square':
				if (!this.initServ.locationsStatus) {
					payload['location_id'] = 0;
				}
				break;
		}
		return payload;
	}
	/**
	 * Parpaired stripe payload data
	 * @param payload: payload data
	 * @returns
	 */
	async stripePayload(payload: any): Promise<any> {
		payload['stripe_base_location_ids'] = this.billingForm.value?.stripe_base_location_ids;
		if (!(this.stripeIds && this.stripeIds.length > 1)) {
			if (this.cards && (this.cards).length > 0) {
				payload['location_id'] = await this.setLocationId();
			}
		}
		return payload;
	}

	/**
	 * Handles form validation error
	 */
	private handleFormErrors(): void {
		if(this.paymentGatewayChild){
			this.paymentGatewayChild.isZipcode = true;
		}
		this.secondStep = false;
		this.billingForm.markAllAsTouched();
		// Refresh the payment gateway child component if available.
		if(this.paymentGatewayChild){
			this.paymentGatewayChild.refresh();
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Function to set location id
	 * @returns locId
	 */
	private setLocationId(): any {
		let locId: any;
		for (let card of this.cards) {
			if (card.location_id) {
				locId = card.location_id;
				break;
			}
		}
		return locId;
	}
	/**
	 * Add user card
	 * @param data : send data
	 */
	public addCard(payload: any): void {
		let uId: string | number = this.uId ? this.uId : this.utilServ.userId();
		let apiName: string = this.uId ? 'PublicUserAddCard' : 'UserCards';
		let queryParams: {section_name: string} = {section_name: this.uId ? 'link': 'profile'};
		this.apiServ.callApiWithPathQueryVars('POST', apiName, [uId], queryParams, payload).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.handleAddCardApiRes(res));
	}
	/**
	 * Handle the add card api res
	 * @param res API res
	 */
	private handleAddCardApiRes(res: any): void {
		if (this.apiServ.checkAPIRes(res)) {
			this.toastr.success(res.message);
			this.closePopup(true);
		} else {
			if (res && res.message) {
				this.secondStep = false;
				this.toastr.error(res.message);
			}
		}
		this.loader.hide(this.formLoaderId, this.dialogRef);
		this.cDRef.detectChanges();
	}
}
