<div [id]="secId" class="tjs-about {{variationClass[varId]?.main}}" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div class="{{variationClass[varId]?.container}}">
		<div class="row g-0">
			<!-- Media -->

			<div class="col-12 col-sm-12 {{variationClass[varId]?.col}}">
				<div class="tjs-about__inner">
					<div class="tjs-section__title text-center mb-30" *ngIf="section?.title?.content || section?.desc?.content">
						<!-- Title -->
						<div class="tjs-section__heading mb-30" *ngIf="section?.title?.content">
							<h3 [id]="section?.title?.id" class="display-5 fw-bold bk-title" [innerHTML]="section?.title?.content | safeHtml"></h3>
						</div>
						<!-- Description -->
						<div class="tjs-section__description bk-desc" *ngIf="section?.desc?.content">
							<p [id]="section?.desc?.id" [innerHTML]="section?.desc?.content |safeHtml"></p>
						</div>
					</div>
					<!-- List -->
					<div class="row bk-list" [id]="section?.list?.id" *ngIf="section?.list && section?.list?.items && (section?.list?.items).length > 0" [ngClass]="{'justify-content-center': (varId == 'bk_about_us_V3' || varId == 'bk_about_us_V4')}">
						<ng-container *ngFor="let item of section?.list?.items;trackBy: secServ.trackByFnIndex">
							<div [id]="item?.id" class="col-12 col-sm-6 {{variationClass[varId]?.list_col}} bk-item" *ngIf="(item?.media && item?.media?.length>0) || item?.title || item?.desc">
								<div class="{{variationClass[varId]?.list_item}}">
									<!-- Image -->
									<div class="tjs-about__featured-img" *ngIf="item?.media && item?.media?.length>0">
										<img loading="auto" [id]="item?.media_id" class="img-fluid bk-item-media" [src]="item?.media[0]" width="36" height="36" alt="bk-images" />
									</div>
									<div class="pt-25 px-0" *ngIf="item?.title || item?.desc">
										<!-- Title -->
										<div class="mb-20" *ngIf="item?.title">
											<h6 [id]="item?.title_id" class="fw-bold fs-20 bk-item-title" [innerHTML]="item?.title | safeHtml"></h6>
										</div>
										<!-- Description -->
										<div *ngIf="item?.desc">
											<p [id]="item?.desc_id" class="mb-0 bk-item-desc" [innerHTML]="item?.desc | safeHtml"></p>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 tjs-about__image-wrap tjs-bg-style position-relative {{variationClass[varId]?.media}} bk-media" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0" [ngStyle]="{'background-image' : (section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}">
				<div [id]="section?.media?.id" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0" class="tjs-opacity--bg"></div>
			</div>
		</div>
	</div>
</div>