<div [id]="secId" class="tjs-service pt-30 section-service_grid" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div class="container">
		<div class="tjs-section__title text-center mb-30 tjs-service-content" *ngIf="section?.title?.content || section?.desc?.content">
			<!-- Title -->
			<div class="tjs-section__heading mb-30" *ngIf="section?.title">
				<h3 [id]="section?.title?.id" class="display-5 fw-bold bk-title" [innerHTML]="section?.title?.content | safeHtml"></h3>
			</div>
			<!-- Description -->
			<div *ngIf="section?.desc">
				<p [id]="section?.desc?.id" class="bk-desc" [innerHTML]="section?.desc?.content | safeHtml"></p>
			</div>
		</div>
		<!-- list -->
		<div [id]="section?.categories?.id" class="row" *ngIf="section?.categories && section?.categories?.items && (section?.categories?.items).length > 0">
			<div class="col-md-5 mx-auto">
				<!-- Select list -->
				<select class="form-select mb-40 tjs-pointer" name="service" [(ngModel)]="selectedCat" (ngModelChange)="changeServ(true)">
					<ng-container *ngFor="let item of section?.categories?.items let i=index;trackBy: secServ.trackByFnIndex">
						<option *ngIf="item?.title" [id]="item?.id" [value]="item?.id">
							<span [id]="item?.title_id" [innerHTML]="item?.title | safeHtml"></span>
						</option>
					</ng-container>
				</select>
			</div>
			<ng-container *ngFor="let item of section?.categories?.items;trackBy: secServ.trackByFnIndex">
				<!-- Description -->
				<div class="col-md-9 mx-auto" *ngIf="item?.id == selectedCat && item?.desc">
					<div class="tjs-sec-description mb-50">
						<p [id]="item?.desc_id" class="bk-item-desc" [innerHTML]="item?.desc | safeHtml"></p>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="position-relative">
		<!-- Items list -->
		<div [id]="section?.services?.id" class="tjs-service__list-wrap bk-list" *ngIf="section?.services?.items && (section?.services?.items).length > 0" [ngClass]="{'tjs-alt--row-col' : pageSett[section?.services?.id]?.grid_align == 'alternate', 'tjs-alt--col' : pageSett[section?.services?.id]?.grid_align == 'right'}">
			<ng-container *ngFor="let item of section?.services?.items;trackBy: secServ.trackByFnIndex">
				<ng-container *ngIf="item?.categories_id && pageSett[item?.categories_id] && pageSett[item?.categories_id]?.items && (pageSett[item?.categories_id]?.items).length > 0 && (pageSett[item?.categories_id]?.items).includes(selectedCat)">
					<div [id]="item?.id" class="tjs-service__item py-30 py-md-50 bk-item" *ngIf="(item.media && (item.media).length > 0) || (item?.list_button && item?.list_button?.buttons && (item?.v?.buttons).length > 0) || item?.title || item?.desc" >
						<div class="container">
							<div class="d-md-flex tjs-service-row justify-content-center mx-n15">
								<div class="tjs-service-col-image mb-30 mb-lg-0 px-15" *ngIf="(item.media && (item.media).length > 0) || (item?.list_button && item?.list_button?.buttons && (item?.list_button?.buttons).length > 0)">
									<div class="d-inline-block p-15" [ngClass]="{'border': (item.media && (item.media).length > 0)}">
										<div class="tjs-overlay-wrapper position-relative overflow-hidden">
											<!-- Image -->
											<div *ngIf="item.media && (item.media).length > 0">
												<img loading="auto" [id]="item?.media_id" class="img-fluid bk-item-media" width="745" height="343" [src]="item.media[0]" alt="service-image" />
											</div>
											<!-- Button -->
											<div [id]="item?.list_button_id" class="tjs-overlay tjs-overlay--dark d-flex justify-content-center align-items-center bk-item-btn-group p-10" *ngIf="item?.list_button && item?.list_button?.buttons && (item?.list_button?.buttons).length > 0">
												<div class="tjs-overlay-content">
													<div class="tjs-navigate-wrapper flex-columm justify-content-center">
														<ng-container *ngFor="let btn of item?.list_button?.buttons;trackBy: secServ.trackByFnIndex">
															<bk-navigate essentialClass="bk-item-btn" [secSett]="btn" [innerHTML]="btn?.content" [designId]="pageSett[section?.services?.id]?.design_id" ></bk-navigate>
														</ng-container>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="tjs-service-col-content px-15" *ngIf="item?.title || item?.desc">
									<!-- Title -->
									<div class="mb-15" *ngIf="item?.title">
										<h6 [id]="item?.title_id" class="fw-bold display-9 bk-item-title" [innerHTML]="item?.title | safeHtml"></h6>
									</div>
									<!-- Description -->
									<div class="tjs-ul-style" *ngIf="item?.desc">
										<p [id]="item?.desc_id" class="bk-item-desc" [innerHTML]="item?.desc | safeHtml"></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</ng-container>
		</div>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</div>
</div>