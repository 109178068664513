import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
// Services
import { NgOnDestroy } from '../../../Services';

@Component({
	selector: 'bk-short-frm-excludes',
	templateUrl: './ShortFrmExcludes.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ShortFrmExcludesComponent implements OnInit {

	@Input() excludes: any;
	@Input() variationId: string = '';
	@Input() bookingType: string = '';
	@Input() section: any;
	@Input() excludesForm!: FormGroup;
	@Input() sectionIds: any;
	@Output() excludeChange : EventEmitter<any> = new EventEmitter();
	constructor() { }

	ngOnInit(): void {}

}
