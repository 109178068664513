import { Component, Input, OnInit, ViewEncapsulation, Self, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs';
// Services
import { NgOnDestroy, InitServ, UtilServ, ApiServ, LoaderServ, SectionServ } from '../../../Services';

@Component({
	selector: 'bk-faq-list',
	templateUrl: './FaqList.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class FaqListComponent implements OnInit {
	//Variables
	@Input() section: any;
	@Input() industryId: any;
	@Input() formId : any;
	@Input() pageType: string = 'booking';
	@Input() pageSett: any;
	faqs: any;
	loaderId: string = 'faq-list-loader';
	sectionStatus: boolean = true;

	constructor(public  initServ: InitServ, public utilServ: UtilServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy,private loader: LoaderServ, private secServ: SectionServ, private cDRef: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		if(this.pageType == 'booking' && this.section){
			this.sectionStatus = this.secServ.checkEleStatus(this.pageSett,this.section.id);
		}
		if(this.sectionStatus){
			this.getFaqs();
		}
	}
	/**
	 * Booking faqs
	 */
	 private getFaqs(): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId);
		let queryParams: any = { language: this.initServ.savedLng };
		if(this.pageType == 'booking'){
			queryParams['industry_id'] = this.industryId;
			queryParams['form_id'] = this.formId;
		}
		this.apiServ.callApiWithQueryParams('GET', 'BookingFaqs', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
	}
	/**
	 * On result callback method
	 * @param res api response
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res)){
			this.faqs = res.data;
			this.cDRef.detectChanges();
		}
		this.loader.hide(this.loaderId);
	}
}