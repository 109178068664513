<!-- Hourly service -->
<div [id]="section?.id" [formGroup]="serviceForm" *ngIf="settings && section && hourlyService" [ngClass]="variationId == 'bk_short_form_V4' ? 'row' : ''">
	<div *ngIf="hourlyService" class="mb-20" [ngClass]="(((variationId == 'bk_short_form_V3') || !(notifyCustStatus)) ? 'col-md-12' : 'col-md-6')">
		<div [ngClass]="{'d-md-flex w-100 align-items-center card shadow-sm rounded p-20 px-md-15 py-md-10' : variationId == 'bk_short_form_V4'}">
			<!-- Label -->
			<label *ngIf="section?.sel_time_label" [id]="sectionIds?.label" class="d-block form-label bk-form-label" [innerHTML]="section?.sel_time_label | safeHtml" [ngClass]="variationId == 'bk_short_form_V4' ? 'd-none' : ''"></label>
			<!-- Duration -->
			<div class="row" [ngClass]="variationId == 'bk_short_form_V4' ? 'mx-n5 w-100' : ''">
				<label *ngIf="section?.sel_time_label" [ngClass]="variationId == 'bk_short_form_V4' ? 'px-5 col-sm-12 col-md-4 mb-md-0 py-md-5' : 'd-none'" [id]="sectionIds?.label" class="d-block form-label bk-form-label" [innerHTML]="section?.sel_time_label | safeHtml"></label>
				<div class="col-6" [ngClass]="{'px-5  col-md-4 tjs-border-start-md' : variationId == 'bk_short_form_V4'}">
					<select #serviceHours class="form-select" [ngClass]="{'pe-35 fs-15 bk-select form-select-sm form-select-sm tjs-border-md-0 shadow-none' : variationId == 'bk_short_form_V4'}" (change)="adjustmentService(serviceHours,serviceMinutes)" [(ngModel)]="defaultHour" [ngModelOptions]="{standalone: true}">
						<option value='0' *ngIf="minHours <= 0">{{initServ?.appStr?.text?.hours | translate}}</option>
						<ng-container *ngIf="hours && hours.length > 0">
							<ng-container *ngFor="let hour of hours;trackBy: utilServ.trackByFnIndex">
								<option [value]="hour" *ngIf="minHours <= hour">{{hour}}</option>
							</ng-container>
						</ng-container>
					</select>
				</div>
				<div class="col-6" [ngClass]="{'px-5  col-md-4 tjs-border-start-md' : variationId == 'bk_short_form_V4'}">
					<select #serviceMinutes class="form-select" [ngClass]="{'pe-35 fs-15 bk-select form-select-sm tjs-border-md-0 shadow-none' : variationId == 'bk_short_form_V4'}" (change)="adjustmentService(serviceHours,serviceMinutes)" [(ngModel)]="defaultMinutes" [ngModelOptions]="{standalone: true}">
						<option value='0' *ngIf="minMinutes <= 0">{{initServ?.appStr?.text?.minutes | translate}}</option>
						<ng-container *ngIf="minutes && minutes.length > 0">
							<ng-container *ngFor="let minute of minutes;trackBy: utilServ.trackByFnIndex">
								<option [value]="minute" *ngIf="minMinutes <= minute">{{minute}}</option>
							</ng-container>
						</ng-container>
					</select>
				</div>
			</div>
		</div>
	</div>
	<!-- Notify me -->
	<div class="mb-20" [ngClass]="variationId == 'bk_short_form_V3' ? 'col-md-12' : 'col-md-6'" *ngIf="hourlyService && notifyCustStatus">
		<div [ngClass]="{'d-md-flex w-100 align-items-center card shadow-sm rounded p-20 px-md-15 py-md-10 h-100 justify-content-center' : variationId == 'bk_short_form_V4'}">
			<div class="form-check" [ngClass]="variationId == 'bk_short_form_V4' ? 'w-100' : ''">
				<input id="notify_customer" class="form-check-input" type="checkbox" formControlName="notify_customer_for_time">
				<label for="notify_customer" class="form-check-label bk-form-check-label" [id]="sectionIds?.label" >{{(section?.notify_cust) ? section?.notify_cust : initServ?.appStr?.text?.notifyMe}}</label>
			</div>
		</div>
	</div>
</div>
