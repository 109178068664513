import { Component, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
// Services
import { NgOnDestroy, ApiServ, LoaderServ, UtilServ, InitServ, RenderComponentServ, SectionServ } from '../../Services';

@Component({
	selector: 'bk-info',
	templateUrl: './Info.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class InfoComponent implements OnInit {
	// Variables
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	loaderId: string = 'invoices-loader';
	limit: number = 8;
	page: number = 1;
	totalInvRecords: number = 0;
	totalGcRecords: number = 0;
	showMoreInv: boolean = false;
	showMoreGc: boolean = false;
	invoices: any;
	giftCards: any;
	type: string = 'invoices'; // invoices/giftcards
	invoiceBkngStatus: boolean = true;
	invoiceGiftCardStatus: boolean = true;
	invoiceStatus: any;
	//
	secId: any;
	section: any = { profile: null, billing: null, address: null, invoice: null };

	// eslint-disable-next-line max-params
	constructor(@Self() private destroy: NgOnDestroy, private apiServ: ApiServ, private loader: LoaderServ, public utilServ: UtilServ, public initServ: InitServ, public rcServ: RenderComponentServ, public secServ: SectionServ,) {
		// Get the invoice, booking and gift card status, its depend on admin store option
		this.invoiceStatus = this.utilServ.getInvoiceStatus();
		if (this.invoiceStatus && !this.invoiceStatus.invStatus && this.invoiceStatus.giftCardStatus) {
			this.type = 'giftCards';
		}
		if(this.invoiceStatus?.invStatus){
			this.userInvoices();
		}
		this.userGiftCards();
	}

	ngOnInit(): void {
		if (this.secId && this.rcServ.pageData) {
			let pageSett: any = this.rcServ.pageData.section_settings; // page settings
			this.secServ.setServData(pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
	}

	private userInvoices(): void {
		let queryParams = { limit: this.limit, page: this.page };
		this.apiServ.callApiWithPathQueryVars('GET', 'CustInvoices', [this.utilServ.userId()], queryParams).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'invoices'));
	}
	/**
	 * Get the gift cards
	 * @param type sent/received
	 */
	private userGiftCards(): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId);
		let queryParams: any = { type: 'sent', limit: this.limit, page: this.page };
		this.apiServ.callApiWithPathQueryVars('GET', 'UserGiftCards', [this.utilServ.userId()], queryParams).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'giftCards'));
	}
	/**
	 * On result callback method
	 * @param res: api
	 * @param type: invoices/giftcards
	 * API response handler
	 */
	private onResultCallback(res: any, type: string): void {
		if (this.apiServ.checkAPIRes(res) && this.utilServ.checkArrLength(res?.data)) {
			if(type === 'invoices'){
				this.totalInvRecords = res?.data?.length;
				this.invoices = [];
				// Updates the list of items and determines whether to show a "Load More" button based on the limit.
				let loaMoreData = this.utilServ.updateLoadMoreItems(res?.data, this.invoices, this.limit);
				this.invoices = loaMoreData.items;
				this.showMoreInv = loaMoreData.showLoadMore;
			}else{
				this.totalGcRecords = res?.data?.length;
				this.giftCards = [];
				// Updates the list of items and determines whether to show a "Load More" button based on the limit.
				let loaMoreData = this.utilServ.updateLoadMoreItems(res?.data, this.giftCards, this.limit);
				this.giftCards = loaMoreData.items;
				this.showMoreGc = loaMoreData.showLoadMore;
			}
		}
		this.loader.hide(this.loaderId);
	}
}
