<div class="position-relative">
	<div [id]="section?.id" class="border card rounded-5 mb-30 min-height--100" *ngIf="sectionStatus && section && faqs && faqs.length > 0">
		<div class="card-body">
			<!-- Title -->
			<h5 [id]="section?.title_id" *ngIf="section?.title" class="mb-15 fw-bold bk-title" [innerHTML]="section?.title | safeHtml"></h5>
			<div id="accordion" class="tjs-accordion tjs-accordion__arrow tjs-accordion__arrow-md tjs-accordion--left bk-accordion" aria-multiselectable="true">
				<ng-container *ngFor="let faq of faqs; let i = index;trackBy: utilServ.trackByFnIndex">
					<div class="bk-accordion-item">
						<!-- Heading -->
						<h6 class="fw-bold mb-0" id="{{'heading_' + i}}">
							<!--  tjs-accordion__title--v2 justify-content-start collapsed -->
							<button type="button" class="fw-bold accordion-button collapsed bk-accordion-item-btn" data-bs-toggle="collapse" attr.data-bs-target="{{'#collapse_' + i}}" aria-expanded="true" attr.aria-controls="{{'collapse_' + i}}">
								<span class="fw-normal text-dark accordion-title bk-accordion-item-title" [innerHTML]="faq?.question | safeHtml" ></span>
							</button>
						</h6>
						<!-- Description -->
						<div id="{{'collapse_'+i}}" class="collapse" attr.aria-labelledby="{{'heading_' + i}}" attr.data-bs-parent="#{{section?.id}}">
							<div class="pb-0 pt-0 ps-20 pe-0" *ngIf="faq?.answer">
								<p class="mb-0 bk-accordion-item-desc" [innerHTML]="faq?.answer | safeHtml"></p>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>