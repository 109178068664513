import { Component, OnInit, ViewEncapsulation, Input, ViewChild, Self,ElementRef } from '@angular/core';
import { takeUntil } from 'rxjs';
// External library
import KeenSlider from "keen-slider";
// Services
import { NgOnDestroy, RenderComponentServ,UtilServ, SectionServ, InitServ } from '../../Services';

@Component({
	selector: 'bk-gallery',
	templateUrl: './Gallery.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class GalleryComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	@ViewChild("gallerySlide") sliderRef : ElementRef<HTMLElement> | undefined;
	pageSett: any;
	slider: any = null;
	// Section fields
	section: any = {
		title: null,
		desc: null,
		gallery: null
	}
	// Slick configuration
	slideConfig:any = {
		loop:false,
		slides: { perView: 4 },
		breakpoints: {
			"(max-width: 767px)": {
				slides: { perView: 1 },
			},
		},
		rtl:(this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? true : false,
		defaultAnimation:{duration:1000},
	};
	spaceClass: string = '';
	isHover: boolean = false;
	slidesToShowClass: string = '';
	sliderArrow: boolean = true;
	constructor( public rcServ: RenderComponentServ,public utilServ: UtilServ, public secServ: SectionServ, public initServ: InitServ, @Self() private destroy: NgOnDestroy) {
		// Reset slider on RTL change
		this.initServ.isRTLChange.pipe(takeUntil(this.destroy)).pipe(takeUntil(this.destroy)).subscribe((value) => {
			this.slideConfig['rtl']=value;
			if(this.slider && this.sliderRef){
				this.slider.update(this.slideConfig);
			}
		});
	}
	sectionData: any;
	slickModalId: string = '';
	autoplaySpeed:any = 6000;
	autoplay:boolean=false;
	defVideoCdn: Array<string> = ['https://cdn.cdnlogo.com/logos/v/96/vimeo.svg', 'https://cdn.cdnlogo.com/logos/y/92/youtube.svg'];

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
		// Gallery settings
		if(this.pageSett && this.secId && this.pageSett[this.secId] && this.pageSett[this.secId][this.rcServ.statusType] && this.section && (this.section.gallery && this.section.gallery.items && (this.section.gallery.items).length > 0)){
			let gallerySett: any = this.pageSett[this.section.gallery.id];
			// Space between images
			if(gallerySett.space_bw_img){
				this.spaceClass = "image-gap-"+gallerySett.space_bw_img;
			}
			// Description on hover
			if(gallerySett.show_desc_on && gallerySett.show_desc_on == "hover"){
				this.isHover = true;
			}
			// Slider for variation 4
			if(this.pageSett[this.secId].variation_id == 'bk_gallery_V4'){
				// Sides to slow
				if(gallerySett.slides_to_show){
					this.slideConfig['loop'] = (this.section.gallery.items).length > gallerySett.slides_to_show;
					this.slideConfig['slides'] = {
						perView: gallerySett.slides_to_show,
					}
					this.slidesToShowClass = (gallerySett.slides_to_show > 0 && gallerySett.slides_to_show < 10) ? "slick-item-"+gallerySett.slides_to_show : "";
					if(gallerySett.slides_to_show > 2){
						this.slideConfig['breakpoints'] = {};
						if(gallerySett.slides_to_show > 3){
							this.slideConfig['breakpoints'] = {
								...this.slideConfig['breakpoints'],
								"(max-width: 1199px)": {
									slides: { perView: 3 },
								},
							}
						}
						this.slideConfig['breakpoints'] = {
							...this.slideConfig['breakpoints'],
							"(max-width: 992px)": {
								slides: { perView: 2 },
							},
							"(max-width: 767px)": {
								slides: { perView: 1 },
							},
						}
					}
				}
				// Slider autoplay
				if(gallerySett.autoplay_slide == 'yes'){
					this.autoplay = true;
					if(gallerySett.slide_delay){
						this.autoplaySpeed = +gallerySett.slide_delay;
					}
				}
				// Slider arrow
				this.sliderArrow = this.checkSliderArrow();
			}
		}
		setTimeout(()=>{
			if(this.sliderRef){
				if (this.slider) {this.slider.destroy();}
				this.slider = new KeenSlider(this.sliderRef.nativeElement,this.slideConfig,this.autoplay ? [(slider) => this.utilServ.autoPlay(slider,this.autoplaySpeed)]:undefined)
			}
		})
	}

	/**
	 * Check the show slider arrow, depend on setting.
	 * @returns
	 */
	private checkSliderArrow(): boolean {
		let slides = 0;
		let gallerySett: any = this.pageSett[this.section.gallery.id];
		// Responsive
		if(this.rcServ.statusType == 'mobile_status'){
			slides = 1;
		} else {
			// Settings
			if(gallerySett && gallerySett.slides_to_show){
				slides = gallerySett && gallerySett.slides_to_show;
			}
		}
		if(this.section && this.section.gallery && this.section.gallery.items && (this.section.gallery.items).length > slides){
			return true;
		}
		return false;
	}
	
	/**
	 * Open the modal and set the video link
	 * @param data: section data
	 */
	public videoModal(url: string): void {
		let videoEl = document.getElementById('galleryVideo');
		if(videoEl){
			videoEl.setAttribute('src',url+"?autoplay=1");
		}
		this.utilServ.togglePopup('galleryVideoModal');
	}

	ngOnDestroy():void {
		if (this.slider) {this.slider.destroy();}
	}
}
