<ng-container *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<!-- Custom -->
	<ng-container *ngIf="this.pageSett[this.secId].list_type && this.pageSett[this.secId].list_type == 'custom'; else dynamic">
		<div [id]="secId" class="tjs-service py-30" *ngIf="((section?.media && section?.media?.urls && (section?.media?.urls).length > 0) || section?.title?.content || section?.desc?.content || (section?.button && section?.button?.buttons && (section?.button?.buttons).length > 0)) || (services && services.length > 0)">
			<div class="container">
				<div class="tjs-service__item">
					<!-- Inner content -->
					<ng-container *ngTemplateOutlet="innerTemp; context:{media_id: section?.media?.id, media: (section?.media && section?.media?.urls && (section?.media?.urls).length > 0 && section?.media?.urls[0]), title_id: section?.title?.id , title: section?.title?.content, desc_id: section?.desc?.id, desc: section?.desc?.content, button: section?.button, titleClass:'fw-bold display-7' }">
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>
	<!-- Dynamic: Get the data from content management app-->
	<ng-template #dynamic>
		<div [id]="secId" class="tjs-service py-30" *ngIf="services && services.length > 0">
			<div class="container">
				<div class="tjs-service__item mb-50 tjs-mb-last-0" *ngFor="let serv of services;trackBy: secServ.trackByFnIndex">
					<!-- Inner content -->
					<ng-container *ngTemplateOutlet="innerTemp; context:{ media: (serv?.image_url && initServ?.imgBase+serv?.image_url), title: serv?.name, desc: serv?.excerpt_text, button: section?.button, titleClass:'fw-bold display-7' }">
					</ng-container>
					<!-- Description -->
					<div *ngIf="serv?.description" class="tjs-service__description py-50 border-bottom bk-item-desc" [innerHTML]="serv?.description | safeHtml">
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</ng-container>

<!-- Inner template -->
<ng-template #innerTemp let-media_id="media_id" let-media="media" let-title_id="title_id" let-title="title" let-desc_id="desc_id" let-desc="desc" let-button="button" let-titleClass="titleClass" >
	<div class="p-15 p-md-30 tjs-bg-style bk-item-media rounded position-relative" [ngStyle]="(media) && {'background-image' : 'url('+ media + ')'}">
		<div class="tjs-opacity--bg" *ngIf="media" [id]="media_id ? media_id : ''" ></div>
		<div class="row" *ngIf="title || desc || (button && button?.buttons && (button?.buttons).length > 0)">
			<div class="col-12 col-sm-7 col-md-5 col-lg-4">
				<div class="card bk-card">
					<div class="card-body text-center p-15 p-md-30">
						<!-- Title -->
						<div class="mb-20" *ngIf="title">
							<h5 [id]="title_id ? title_id : ''" class="{{titleClass}} bk-item-title" [innerHTML]="title | safeHtml"></h5>
						</div>
						<!-- Description -->
						<div class="mb-30" *ngIf="desc">
							<p [id]="desc_id ? desc_id : ''" class="bk-item-desc mb-0" [innerHTML]="desc | safeHtml"></p>
						</div>
						<!-- Button -->
						<div [id]="button?.id" class="tjs-service__action bk-item-btn-group" *ngIf="button && button?.buttons && (button?.buttons).length > 0">
							<div class="tjs-navigate-wrapper justify-content-center flex-column">
								<ng-container *ngFor="let btn of button?.buttons;trackBy: secServ.trackByFnIndex">
									<bk-navigate [secSett]="btn" [innerHTML]="btn?.content"></bk-navigate>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>