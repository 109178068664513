import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../Services';

@Component({
	selector: 'bk-tooltip',
	template: `<span class="tjs-tooltip-icon tjs-pointer {{customClass}}" *ngIf="content" [style.color]="inheritStyle && 'inherit'" [ngStyle]="customStyle" data-bs-toggle="tooltip" data-bs-html="true" attr.data-title="{{content | safeHtml}}" (mouseenter)="showTooltip($event)" (mouseleave)="hideTooltip()" (mouseout)="hideTooltip()">
		<i [class]="icon"></i>
	</span>`,

	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class TooltipComponent implements OnInit {

	// Input variables
	@Input() content: string = '';
	@Input() customClass: string = '';
	@Input() customStyle: any;
	@Input() inheritStyle: boolean = false;
	@Input() icon: string = "tjsicon-information";

	constructor() { }

	ngOnInit() {}

	/**Function to show tooltip */
	showTooltip(event:any){
		event.stopPropagation();
		let tooltipTarget:any = document.getElementById('tjs-tooltip-content');
		tooltipTarget.innerHTML = this.content;
		tooltipTarget.style.top = 'auto';
		tooltipTarget.style.left = 'auto';
		tooltipTarget.classList.add('tjs-tooltip--active');
		let tooltipRect:any = tooltipTarget.getBoundingClientRect();
		let tooltipHeight = tooltipRect.height;
		let tooltipWidth = tooltipRect.width;
		let viewportWidth = window.innerWidth;
		let viewportHeight = window.innerHeight;
		let position : any = event.target.getBoundingClientRect();
		if(viewportWidth < 600){
			tooltipTarget.style.left = ((viewportWidth/2)-(tooltipWidth/2))+'px'
		} else{
			let totalWidth = (position.left + (position.width +  tooltipWidth));
			if(totalWidth > viewportWidth){
				tooltipTarget.style.left = (position.left-(tooltipWidth - position.width))+'px'
			} else {
				if(position.left < tooltipWidth){
					tooltipTarget.style.left = (position.left + position.width + 10)+'px';
				} else {
					tooltipTarget.style.left = ((position.left + (position.width/2)) -(tooltipWidth/2))+'px';
				}
			}
		}
		let totalHeight = (position.top + (position.height +  tooltipHeight));
		if((totalHeight < viewportHeight) && (position.top < tooltipHeight)){
			tooltipTarget.style.top = (position.top + position.height + 10)+'px';
		} else {
			let topOffset = 0;
			if(tooltipHeight > position.top){
				topOffset = (tooltipHeight - position.top)+20;
			}
			tooltipTarget.style.top = (position.top - tooltipHeight - 10 + topOffset)+'px';
		}
	}

	/**Function to hide tooltip */
	hideTooltip(){
		let tooltipTarget:any = document.getElementById('tjs-tooltip-content');
		if(tooltipTarget){
			tooltipTarget.classList.remove('tjs-tooltip--active')
			tooltipTarget.innerHTML = ""
		}
	}
}