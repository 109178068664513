/* eslint-disable @typescript-eslint/no-unused-vars */
import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { MainComponent } from './Main/Main.component';
import { ExistingPageComponent } from './ExistingPage/ExistingPage.component';
// Services
import { AuthGuard } from './Core/_guards'; // Auth services
// Components
import { JobDetailsComponent, BlogDetailsComponent, AuthorBlogComponent } from './ThemeElements';
import { QuoteRedirectionComponent } from './QuoteRedirection/QuoteRedirection.component';
import { SessionComponent, PasswordProtectComponent, LiveReviewsComponent, BookingReceiptComponent, ThirdPartyConnectComponent, VerifyEmailMsgComponent } from './Session';


export const routes: Routes = [
	{ path: '', component: MainComponent },
	{ path: "password-protected", component: PasswordProtectComponent },
	{ path: 'live-reviews', component: LiveReviewsComponent},
	{ path: 'booking-invoice/:booking_token', component: BookingReceiptComponent},
	{ path: 'qbconnect', component: ThirdPartyConnectComponent},
	{ path: 'xeroconnect', component: ThirdPartyConnectComponent},
	{ path: 'login', component: SessionComponent },
	{ path: "login/:access_token", component: SessionComponent },
	{ path: 'review/:bid/:ratingtoken', component: SessionComponent },
	{ path: 'signup', component: SessionComponent },
	{ path: "forgot-password", component: SessionComponent },
	{ path: "reset-password", component: SessionComponent },
	{ path: "referral-signup/:rid", component: SessionComponent },
	{ path: "job-details/:slug", component: JobDetailsComponent },
	{ path: "blog/:slug", component: BlogDetailsComponent },
	{ path: "author/:type/:slug", component: AuthorBlogComponent },
	{ path: "category/:type/:slug", component: AuthorBlogComponent },
	{ path: 'booking-quote/:qid/:slink', component: QuoteRedirectionComponent },
	{ path: 'reauth_card', component: SessionComponent },
	{ path: 'invoice', component: SessionComponent},
	{ path: 'invoice/:user_access_token', component: SessionComponent},
	{ path: 'invoice-payment/:user_access_token', component: SessionComponent},
	{ path: 'add-payment/:token', component: SessionComponent},
	{ path: 'verify-email', component: VerifyEmailMsgComponent},
	{
		path: '', component: ExistingPageComponent, children: [
			{ path: 'booknow', loadChildren: () => import('./AddBookings/AddBookings.module').then(m => m.AddBookingsModule) },
			{ path: 'reschedule-booking', loadChildren: () => import('./RescheduleBookings/RescheduleBookings.module').then(m => m.RescheduleBookingsModule), canActivate: [AuthGuard], runGuardsAndResolvers: 'always' }
		]
	},
	{
		path: '', component: MainComponent, children: [
			{ path: 'dashboard', loadChildren: () => import('./Dashboard/Dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
			{ path: 'appointments', loadChildren: () => import('./Appointments/Appointments.module').then(m => m.AppointmentsModule), canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
			{ path: 'gift-cards', loadChildren: () => import('./GiftCards/GiftCards.module').then(m => m.GiftCardsModule) },
			{ path: 'referrals', loadChildren: () => import('./Referrals/Referrals.module').then(m => m.ReferralsModule), canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
			{ path: 'contact-us', loadChildren: () => import('./LeadForm/LeadForm.module').then(m => m.LeadFormModule), runGuardsAndResolvers: 'always' },
			{ path: '', loadChildren: () => import('./UserPages/UserPages.module').then(m => m.UserPagesModule), canActivate: [AuthGuard], runGuardsAndResolvers: 'always' }

		]
	},
	{ path: '**', component: MainComponent },
];

const routeOptions: ExtraOptions = {
	onSameUrlNavigation: 'reload',
	scrollPositionRestoration: 'top',
	relativeLinkResolution: 'legacy',
	anchorScrolling: 'enabled',
	// initialNavigation: 'enabled',
	paramsInheritanceStrategy: 'always'
}

@NgModule({
	imports: [RouterModule.forRoot(routes, routeOptions)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
