<div class="tjs-popup tjs-popup--sm min-height--150 position-relative" [id]="secId">
	<ng-container [ngSwitch]="type">
		<!-- Can Postpone Popup-->
		<ng-container *ngSwitchCase="'CanPostpone'" >
			<bk-popup-header [headingId]="section?.title?.id" [heading]="section?.title?.content"></bk-popup-header>
			<div class="tjs-popup__content tjs-popup__body">
				<div class="py-10 d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse">
					<bk-navigate class="d-block w-100 mb-15 mb-sm-5" [secId]="section?.postpone_btn?.id" [designId]="section?.postpone_btn?.design_id" [innerHTML]="section?.postpone_btn?.content" customClass="btn btn-white rounded-5" essentialClass="w-100" (callback)="postpone()"></bk-navigate>
					<bk-navigate class="d-block w-100 mb-5" [secId]="section?.cancel_btn?.id" [designId]="section?.cancel_btn?.design_id" [innerHTML]="section?.cancel_btn?.content" customClass="btn  btn-white rounded-5" essentialClass="w-100" (callback)="cancel()"></bk-navigate>
				</div>
			</div>
		</ng-container>
		<!-- Postpone Popup -->
		<ng-container *ngSwitchCase="'Postpone'">
			<bk-popup-header *ngIf="section?.title" [headingId]="section?.title?.id" [heading]="section?.title?.content" [isClose]="false"></bk-popup-header>
			<div class="tjs-popup__content tjs-popup__body">
				<!-- Desc -->
				<div class="form-group mb-15" *ngIf="section?.desc?.content">
					<label class="d-block form-label" [id]="section?.desc?.id" [innerHTML]="section?.desc?.content | safeHtml"></label>
				</div>
				<!-- Select & Radio options -->
				<div class="form-group" [id]="section?.select?.id">
					<!-- label -->
					<label class="d-block form-label text--color" *ngIf="section?.select?.label" [id]="section?.select?.label_id" [innerHTML]="section?.select?.label | safeHtml"></label>
					<!-- Select-->
					<div class="form-group" [ngClass]="{'mb-20' : (accordingMonths[selectedMonth])?.length > 0}">
						<select class="form-select {{errorMessage ? 'is-invalid':''}}" (change)="onSelectMonth($event)">
							<option [id]="section?.select?.placeholder_id" value=null>{{section?.select?.placeholder}}</option>
							<ng-container *ngIf="postponeDateMonths">
								<ng-container *ngFor="let name of postponeDateMonths;trackBy: utilServ.trackByFnIndex">
									<option value={{name}}>{{name}}</option>
								</ng-container>
							</ng-container>
						</select>
					</div>
					<!-- Radio options according to month selected -->
					<ng-container *ngIf="accordingMonths[selectedMonth] && (accordingMonths[selectedMonth]).length > 0">
						<div class="py-10" *ngFor="let date of accordingMonths[selectedMonth];trackBy: utilServ.trackByFnIndex">
							<input type="radio" id="{{date?.booking_date}}" class="form-check-input" value="{{date?.booking_date}}" name="postponeDate" [(ngModel)]="selectedPostponeDate">
							<label class="form-check-label text--color" for="{{date?.booking_date}}">{{convertToDate(date?.arrival_date)}}</label>
						</div>
					</ng-container>
					<!-- error message -->
					<div class="tjs-error my-10" *ngIf="errorMessage" translate>
						<i class="tjsicon-attention"></i>Please choose a postpone date & time.
					</div>
					<!-- re-scheduling charge -->
					<div class="mb-0 mt-15 text--color" *ngIf="chargeAmount > 0 && booking && booking?.payment_method == 'existing_credit_card'">
						<span translate>You will be charged a </span>
						<bk-amount-display [amount]="chargeAmount"></bk-amount-display>
						<span translate> as late reschedule fee </span>
						<div>
							<ng-container *ngIf="rescheduleTimeType == 'day_before'; else noDayBefore">
								<span translate> because you are rescheduling after</span>
								<span class="dir-ltr ms-5">{{utilServ.convertDayBeforeTime(null, true)}}</span>
								<span translate> the day before your service.</span>
							</ng-container>
							<ng-template #noDayBefore>
								<span translate> rescheduling fee because you are rescheduling booking</span>
								<span class="dir-ltr ms-5">{{admnStngs?.merchant_settings?.reschedule?.reschedule_max_time}}</span>
								<span translate> hours before the service.</span>
							</ng-template>
						</div>
					</div>
				</div>
			</div>
			<!-- Footer -->
			<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse bk-footColor">
				<bk-navigate [isDebounce]="true" [secId]="section?.postpone_btn?.id" [designId]="section?.postpone_btn?.design_id" [innerHTML]="section?.postpone_btn?.content" essentialClass="w-100" customClass="btn btn-primary rounded-5" class="w-100 d-block mb-15 mb-sm-5" (callback)="postponeService()"></bk-navigate>
				<bk-navigate [secId]="section?.cancel_btn?.id" [designId]="section?.cancel_btn?.design_id" [innerHTML]="section?.cancel_btn?.content" essentialClass="w-100" customClass="btn btn-white rounded-5" class="w-100 d-block mb-5" (callback)="dialogRef.close()"></bk-navigate>
			</div>
		</ng-container>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</ng-container>
</div>
