<div class="tjs-popup tjs-popup--sm position-relative min-height--150" [id]="secId">
	<bk-popup-header *ngIf="section?.title" [heading]="section?.title?.content" [headingId]="section?.title?.id" [isClose]="false"></bk-popup-header>
	<form bkDebounceEvent [isSubmit]="true" [formGroup]="referralForm" (debounceSubmit)="submitForm()">
		<!-- Content -->
		<div class="tjs-popup__content tjs-popup__body" [id]="section?.form?.id">
			<!-- Invite fields -->
			<div class="mb-5">
				<label class="form-label d-block bk-form-label" [id]="section?.form?.invite_id" *ngIf="section?.form?.invite" [innerHTML]="section?.form?.invite | safeHtml"></label>
				<div *ngIf="radioCtrlSection?.email_id && radioCtrlSection?.sms_id" class="mb-15">
					<div class="form-check form-check-inline mb-10">
						<input id="email" type="radio" value="email" class="form-check-input ng-untouched ng-pristine ng-valid" formControlName="type" (change)="emptyForm('email')" >
						<label for="email" class="form-check-label bk-form-label">{{radioCtrlSection?.email}}</label>
					</div>
					<div class="form-check form-check-inline mb-10">
						<input id="sms" type="radio" value="sms" class="form-check-input ng-untouched ng-pristine ng-valid" formControlName="type" (change)="emptyForm('phone')">
						<label for="sms" class="form-check-label bk-form-label">{{radioCtrlSection?.sms}}</label>
					</div>
				</div>
			</div>
			<!-- Email Input -->
			<ng-container *ngIf="referralForm.controls['type'].value == 'email' && radioCtrlSection?.email_id">
				<input class="form-control" [ngClass]="{'is-invalid': referralForm.controls['referred_email']?.touched && referralForm.controls['referred_email']?.errors}" type="email" [attr.placeholder]="section?.form?.placeholder_group?.email_placeholder" formControlName="referred_email">
				<!-- Error -->
				<div class="tjs-error" *ngIf="referralForm.controls['referred_email']?.touched && referralForm.controls['referred_email']?.errors?.required">
					<i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.empty | translate}}
				</div>
			</ng-container>
			<!-- Phone Input -->
			<ng-container *ngIf="referralForm.controls['type'].value == 'sms' && radioCtrlSection?.sms_id">
				<div class="input-group input-group-ltr">
					<div class="input-group-text" *ngIf="initServ?.callingCode">
						<span >{{initServ?.callingCode}}</span>
					</div>
					<input class="form-control" [ngClass]="{'is-invalid': referralForm.controls['referred_phone']?.touched && phoneError == 1}"  type="text" [attr.placeholder]="section?.form?.placeholder_group?.phone_placeholder" formControlName="referred_phone" (keyup)="phoneValidate()">
				</div>
				<!-- Error -->
				<div class="tjs-error" *ngIf="referralForm.controls['type'].value == 'sms' && phoneError == 1" translate><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.validPhone}}</div>
			</ng-container>
			<!-- Desc -->
			<span class="d-block mt-15 bk-desc-clr" *ngIf="section?.form?.placeholder_group?.desc" [innerHTML]="section?.form?.placeholder_group?.desc | safeHtml"></span>
		</div>
		<!-- Footer -->
		<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse bk-footColor">
			<bk-navigate [secId]="section?.send_btn?.id" [designId]="section?.send_btn?.design_id" [innerHTML]="section?.send_btn?.content" [isSubmit]="true" essentialClass="w-100" customClass="btn btn-primary rounded-5 w-100" class="d-block w-100 mb-15 mb-sm-5"></bk-navigate>
			<bk-navigate [secId]="section?.cancel_btn?.id" [designId]="section?.cancel_btn?.design_id" [innerHTML]="section?.cancel_btn?.content" [isSubmit]="false" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" class="d-block w-100 mb-5" (callback)="dialogRef.close()"></bk-navigate>
		</div>
	</form>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
