import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { mergeMap, Subject, takeUntil } from 'rxjs';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, LoadCompService } from '../../Services';
import { loadCompDirective } from '../loadComp.directive';

@Component({
	selector: 'bk-embed-form',
	template:`<div [id]="secId" class="bkt-section" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && varId">
		<ng-container *ngIf="varId == 'bk_embed_booking_form_V1' else otherComp">
			<bk-booking-form [isEmbed]="true" [embedSelectedIndustrySlug]="selectedIndustrySlug" [embedIndustryTab]="industryTab" ></bk-booking-form>
		</ng-container>
		<ng-template #otherComp>
			<ng-template loadComp></ng-template>
		</ng-template>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class EmbedFormComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	admStng: any = this.initServ.appAdmnStngs;
	embedFormUrl:any;
	embedJsUrl:any;
	height: string = '0';
	scrolling: string = 'no';
	sectionData: any;
	varId: any;
	industryTab: boolean = true;
	selectedIndustrySlug: any;

	@ViewChild(loadCompDirective, { static: false }) loadComp: loadCompDirective | undefined;
	private destroySubject = new Subject();

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, private initServ: InitServ, private loadCompServ: LoadCompService) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.pageSett = this.sectionData.section_settings;
			this.varId = this.pageSett[this.secId]?.variation_id;
			if(this.secId && this.varId && this.varId == 'bk_embed_booking_form_V1'){
				let bkngSett: any = this.sectionData?.section_settings[this.secId];
				this.industryTab = bkngSett?.industry_tab_status;
				this.selectedIndustrySlug = bkngSett?.industry;
			}
		}
	}

	ngAfterViewInit() {
		let buildStatus: boolean = true;
		if(this.loadComp && (this.varId != 'bk_embed_booking_form_V1')){
			const viewContainerRef = this.loadComp.viewContainerRef;
			let type = '';
			switch (this.varId) {
				case 'bk_embed_quote_form_V1':
					type = 'leadForm';
					if ((this.admStng && this.admStng.merchant_settings && this.admStng.merchant_settings?.store && this.admStng.merchant_settings?.store?.offer_form && (this.admStng.merchant_settings.store.offer_form).length > 0)) {
						if (!(this.admStng.merchant_settings.store.offer_form).includes('lead_form')) {
							buildStatus = false;
						}
					}
				break;
				case 'bk_embed_send_gift_card_form_V1':
					type = 'giftCard';
				break;
			}
			if(buildStatus){
				this.loadCompServ.isLoadComp$.pipe(takeUntil(this.destroySubject),mergeMap(() => this.loadCompServ.loadDynamicComponent(viewContainerRef, type))).subscribe((res: any) => {
					switch (this.varId) {
						case 'bk_embed_forgot_password_form_V1':
							res.instance.slug = 'forgot-password'
						break;
						case 'bk_embed_login_form_V1':
							res.instance.slug = 'login';
						break;
						case 'bk_embed_signup_form_V1':
							res.instance.slug = 'signup';
						break;
					}
					res.instance.loadSett = false;
					res.instance.isEmbed = true;
					res.instance.embedSecId = this.secId;
				});
			}
		}
	}
}