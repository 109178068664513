import { Component, OnInit, ViewEncapsulation, Self, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, LoaderServ, ApiServ, InitServ, UtilServ, PopupServ, CacheService } from '../../Services';

@Component({
	selector: 'bk-other-contacts-popup',
	templateUrl: './OtherContactsPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class OtherContactsPopupComponent implements OnInit {
	// Variables
	uid: any;
	hideActions: boolean = false;
	loaderId: string = 'other-contact-loader';
	contacts: any;
	// section data variables
	slug: string = 'view_contacts';
	secId: string = '';
	section: any = { title: null, listing: null };

	constructor(public dialogRef: MatDialogRef<OtherContactsPopupComponent>, @Self() private destroy: NgOnDestroy, private cDRef: ChangeDetectorRef, private apiServ: ApiServ, private loader: LoaderServ, public initServ: InitServ, public utilServ: UtilServ, private popupServ: PopupServ, private cacheServ: CacheService, private toastr: ToastrService) { }

	ngOnInit(): void {
		// build popup section
		this.buildSectionData();
		// get the contact info
		if (this.uid) {
			this.otherContacts();
		}
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
			// detect changes
			this.cDRef.detectChanges();
		});
	}
	/**
	 * User other contacts
	 */
	private otherContacts(): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId, this.dialogRef);
		this.apiServ.callApiWithPathVariables('Get', 'OtherContacts', [this.uid]).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'get'));
	}
	/**
	 * Edit the contact
	 * @param uId  user id
	 * @param contactId contact id
	 */
	public editContact(uId: string | number, contactId: string | number): void {
		this.popupServ.addOtherContactPopup(uId, contactId).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res, 'update'));
	}
	/**
	 * Confirm popup for delete contact
	 * @param uId  user id
	 * @param contactId contact id
	 */
	public confirmPopup(uId: string | number, contactId: string | number): void {
		let data: any = [uId, contactId]
		this.popupServ.confirmPopup('delete_contact').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.popupCallback(res, 'delete', data));
	}
	/**
	 * On popup result callback method
	 * @param res popup res
	 * @param type update/delete
	 * @param data api send data
	 * Popup response handler
	 */
	public popupCallback(res: any, type: string, data: any = null): void {
		if (res) {
			if (type == 'delete') {
				this.apiServ.setLoaderId(this.loaderId);
				this.loader.show(this.loaderId, this.dialogRef);
				this.apiServ.callApiWithPathVariables('DELETE', 'Contacts', data).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, type));
			} else {
				this.otherContacts();
			}
		}
	}
	/**
	 * On result callback method
	 * @param res API res
	 * @param type
	 * API response handler
	 */
	private onResultCallback(res: any, type: string): void {
		switch (type) {
			case 'get':
				if (this.apiServ.checkAPIRes(res) && res.data) {
					this.contacts = res.data.profiles;
				}
				break;
			case 'delete':
				if (this.apiServ.checkAPIRes(res)) {
					this.toastr.success(res.message);
					this.otherContacts();
				} else {
					if (res && res.message) {
						this.toastr.error(res.message);
					}
				}
				break;
			default: break;
		}
		this.loader.hide(this.loaderId, this.dialogRef);
		this.cDRef.detectChanges();
	}
}
