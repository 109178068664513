import { Component, Input, OnInit, OnDestroy, Output, ViewEncapsulation, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
// External lib
import { TranslateService } from '@ngx-translate/core';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, InitServ, PaymentGatewayServ, LoaderServ, UtilServ, ApiServ } from '../../../Services';
// Constants
import { CREDIT_REG_EXP, CREDIT_CVV_REG_EXP } from '../../../Constants';
// Interface
import { PaymentGatewayBillingAddress } from 'src/app/Interfaces';
@Component({
	selector: 'bk-payment-gateway',
	templateUrl: './PaymentGateway.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class PaymentGatewayComponent implements OnInit, OnDestroy {
	// Variables
	paymentGateway: string = this.initServ.paymentGateway;
	@Input() section: any;
	@Input() hideLabel: boolean = false;
	@Input() locationId: any;
	@Input() baseLoc: boolean = false;
	@Input() isDynamic: boolean = false;
	@Input() paymentForm!: FormGroup;
	@Input() billingAddr: boolean = false;
	@Input() billingAddrForm!: FormGroup;
	@Input() isMultLocBillingAddr: boolean = true;
	@Output() callback: EventEmitter<number> = new EventEmitter<number>();

	currentUser: any = this.utilServ.appLocalStorage();
	appData: any = this.initServ.appData; // App data
	squarUpKeys: any;
	stripeKey: any;
	paypalKey: any;
	authorizedotnetKey: any = {
		appId: '',
		clientKey: ''
	};
	stripe: any;
	elements: any;
	loaderId: string = 'payment-method-form';
	maskObj: any = {};
	years: any = [];
	expiryDate: any = '';
	isSquareForm: any = false;
	isZipcode: boolean = false;

	get billingAddrGrp(): any {
		return <FormGroup>this.billingAddrForm.controls['billing_address'];
	}

	// eslint-disable-next-line max-params
	constructor(private cDRef: ChangeDetectorRef, public initServ: InitServ, private paymentServ: PaymentGatewayServ, public translate: TranslateService, private loader: LoaderServ, private frmBldr: FormBuilder, private utilServ: UtilServ, public apiServ: ApiServ, private toastr: ToastrService) {
		this.loader.show(this.loaderId);
		// Load payment gateway custom form
		if(this.paymentGateway == 'paypal' || this.paymentGateway == 'authorizedotnet'){
			this.paymentForm = this.frmBldr.group({
				card_number: [null, [Validators.required, Validators.pattern(CREDIT_REG_EXP)]],
				card_cvv: [null, [Validators.required, Validators.pattern(CREDIT_CVV_REG_EXP)]],
				card_exp_month: [null, [Validators.required]],
				card_exp_year: [null, [Validators.required]]
			});
		}

	}

	ngOnInit() {
		this.buildBillingAddr();
		this.beforeFormBuild();
	}

	private async beforeFormBuild(): Promise<void> {
		await this.setPaymentGatewayKeys();
		this.callback.emit(this.stripeKey);
		// Build the payment form
		this.buildForm();
		// Paypal and authorized custom form
		this.paypalAndAuthorizedCustomForm();
	}

	/**
	 * Set the payment gateway kesy
	 */
	private async setPaymentGatewayKeys(): Promise<void> {
		switch(this.paymentGateway){
			case 'stripe':
				if(this.locationId){
					this.stripeKey = await this.paymentServ.stripeKey(this.locationId, this.baseLoc);
				} else {
					this.stripeKey = await this.paymentServ.stripeKey();
				}
			break;
			case 'square':
				if(this.locationId){
					this.squarUpKeys = await this.paymentServ.squareupKeys(this.locationId, this.baseLoc);
				} else {
					this.squarUpKeys = await this.paymentServ.squareupKeys();
				}
			break;
			case 'paypal':
				this.paypalKey = await this.paymentServ.paypalKey();
				break;
			case 'authorizedotnet':
				this.authorizedotnetKey = await this.paymentServ.authorizedontnetKey();
				break;
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Build the payment gateway from
	 */
	private async buildForm(): Promise<void> {
		switch (this.paymentGateway) {
			case 'square':
				if (this.squarUpKeys) {
					this.isSquareForm = await this.paymentServ.buildSquareForm(this.squarUpKeys); //Build the square form
					if(this.isSquareForm){
						this.loader.hide(this.loaderId);
					}
				}
				break;
			default:
				setTimeout(() => {
					if (this.stripeKey) {
						this.stripe = this.paymentServ.setStripeKey(this.stripeKey);
						if (this.stripe) {
							this.elements = this.stripe.elements({ locale: this.initServ.savedLng });
							if (this.elements) {
								this.paymentServ.buildStripeForm(this.elements); //Build the stripe form
							}
						}
					}
				}, 0);
				break;
		}
		setTimeout(() => {
			this.loader.hide(this.loaderId);
			this.cDRef.detectChanges();
		}, 200);
	}
	/**
	 * Paypal and authorized custom form
	 */
	private paypalAndAuthorizedCustomForm(): void {
		if (this.paymentGateway == 'paypal' || this.paymentGateway == 'authorizedotnet') {
			this.years = [];
			let current_year = new Date().getFullYear();
			for (let i = current_year; i < (current_year + 20); i++) {
				(this.years).push(i);
			}
			this.maskObj = {
				mask: [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
				pipe: createAutoCorrectedDatePipe('mm/yyyy', { "minYear": +this.years[0], "maxYear": +this.years[0] + 1000 })
			}
		}
	}
	/**
	 * @param extraData: (location, uid, amount)
	 * @param cardForm: payment form card values
	 * @param isPayment: true(on session payment)
	 * @returns token
	 */
	public async generateToken(extraData: any = null, cardForm:any = null, isPayment:boolean = false): Promise<any>{
		let locId: any = (extraData && extraData?.base_location_id) ? extraData?.base_location_id : 0;
		let clientToken: any = await this.paymentServ.generateClientToken(locId);
		if(clientToken){
			switch (this.paymentGateway) {
				case 'stripe':
					return this.confirmStripeCard(clientToken, extraData);
				case 'paypal':
					extraData['threeDSecure']=true;
					return await this.paymentServ.generatePaypalToken(cardForm, clientToken, extraData, isPayment);
			}
		} else {
			return null;
		}
	}


	/**
	 * Generate the stripe token
	 * @returns Token / null
	 */
	public generateStripeToken(formVal:any): any {
		if (this.initServ.appData && this.initServ.appData.stripe_live_key){
			let billingAddr: PaymentGatewayBillingAddress | null = null;
			if(formVal?.billing_address){
				billingAddr = this.paymentServ.getStripeBillingAddr(formVal.billing_address);
			}
			let stripeCard = this.paymentServ.getStripeCard();
			return this.stripe?.createToken(stripeCard, billingAddr).then((result: { error: any; token: { card: {last4: string}, id: string; }; }) => {
				if (result.error) {
					this.paymentServ.stripeElementsErrors(result);
					return null;
				} else {
					return {last4: result.token?.card?.last4, token: result.token.id};
				}
			});
		} else{

			this.toastr.error(this.initServ.appStr.toastr.paymentGateError);
			return null;
		}
	}
	/**
	 * confirm stripe card (3DS)
	 */
	private confirmStripeCard(setupIntent: any, formVal: any): any {
		let billingAddr: PaymentGatewayBillingAddress | null = null
		if(formVal?.billing_address){
			billingAddr = this.paymentServ.getStripeBillingAddr(formVal.billing_address);
		}
		let stripeCard = this.paymentServ.getStripeCard();
		return this.stripe.confirmCardSetup(setupIntent, { payment_method: { card: stripeCard, billing_details: {address:billingAddr} } }).then((result: any) => {
			if (result?.error) {
				this.paymentServ.stripeElementsErrors(result);
				return null;
			} else {
				return result?.setupIntent?.payment_method;
			}
		});
	}
	/**
	 * Paypal setup intent
	 * @param extraData: (location, uid, amount)
	 * @param isPayment: true(on session payment)
	 * @returns token
	 */
	public async paypalSetupInt(extraData: any = {}, isPayment:boolean = false): Promise<void> {
		this.paymentServ.removePaypalError();
		if (this.paymentForm.valid) {
			this.cDRef.detectChanges();
			return await this.generateToken(extraData, this.paypalFormFields(), isPayment);
		} else {
			this.paymentForm.markAllAsTouched();
			// Call function to scroll to specific element
			this.utilServ.scrollToSpecificEle('payment_details');
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Generate the paypal token
	 */
	public async generatePaypalToken(extraData: any = null) {
		this.paymentServ.removePaypalError();
		if (this.paymentForm.valid) {
			if (this.paypalKey) {
				let token: any = await this.paymentServ.generatePaypalToken(this.paypalFormFields(), this.paypalKey, extraData);
				return token;
			}
		} else {
			this.paymentForm.markAllAsTouched();
			// Call function to scroll to specific element
			this.utilServ.scrollToSpecificEle('payment_details');
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Paypal form fields
	 * @returns object
	 */
	private paypalFormFields(): any {
		let data: any = {
			creditCard: {
				number: this.paymentForm.controls['card_number'].value,
				cvv: this.paymentForm.controls['card_cvv'].value,
				expirationDate: (this.paymentForm.controls['card_exp_month'].value) + '/' + (this.paymentForm.controls['card_exp_year'].value)
			}
		}
		return data;
	}
	/**
	 * Re auth page payment with new card
	 * @param clientInstance : client instance
	 * @param extraData: extraData
	 * @returns object|null
	 */
	public async paypalNewCardPayment(clientInstance: any, extraData:any){
		this.paymentServ.removePaypalError();
		if(clientInstance){
			if (this.paymentForm.valid) {
				return await clientInstance.request({
					endpoint: 'payment_methods/credit_cards',
					method: 'post',
					data: this.paypalFormFields()
				}).then(async (res: any) => {
					if(res && res._httpStatus == 200){
						extraData['device_data'] = await this.paymentServ.paypalDeviceToken(clientInstance);
						let tokenData: any = { nonce: res.creditCards[0].nonce, bin: res.creditCards[0].details?.bin, instance: clientInstance };
						extraData['last4'] = res.creditCards[0].details?.lastFour;
						return await this.paymentServ.paypalThreeDSecure(tokenData, extraData, true);
					} else {
						return null;
					}
				}).catch((err: any) => {
					this.paymentServ.paypalElementsErrors(err);
				});
			} else {
				this.paymentForm.markAllAsTouched();
				// Call function to scroll to specific element
				this.utilServ.scrollToSpecificEle('payment_details');
				this.cDRef.detectChanges();
			}
		} else {
			return null;
		}
	}
	/**
	 * Generate the Authorize dot net token
	 */
	public async generateAuthDotNetToken() {
		if (this.paymentForm.valid) {
			let cardData: any = {};
			cardData.cardNumber = this.paymentForm.controls['card_number'].value;
			cardData.month = (this.paymentForm.controls['card_exp_month'].value).toString();
			cardData.year = (this.paymentForm.controls['card_exp_year'].value).toString();
			cardData.cardCode = this.paymentForm.controls['card_cvv'].value;
			if (this.authorizedotnetKey && this.authorizedotnetKey.appId && this.authorizedotnetKey.clientKey) {
				let token: any = await this.paymentServ.generateAuthorizedotnetToken(cardData, this.authorizedotnetKey);
				token['last4']=(cardData.cardNumber).slice(-4);
				return token;
			}
		} else {
			this.paymentForm.markAllAsTouched();
			// Call function to scroll to specific element
			this.utilServ.scrollToSpecificEle('payment_details');
			this.cDRef.detectChanges();
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Set the expiry date for paypal form
	 */
	public setExpiryDate(): void {
		let expiryDate = this.expiryDate.toString();
		if (expiryDate && expiryDate.length == 7) {
			let dateArr = expiryDate.split("/");
			this.paymentForm.controls['card_exp_month'].setValue(+dateArr[0]);
			this.paymentForm.controls['card_exp_year'].setValue(+dateArr[1]);
		} else {
			this.paymentForm.controls['card_exp_month'].setValue('');
			this.paymentForm.controls['card_exp_year'].setValue('');
			this.paymentForm.controls['card_exp_month'].markAsTouched();
			this.paymentForm.controls['card_exp_year'].markAsTouched();
		}
	}
	/**
	 * Reset expiry date
	 */
	public resetExpiryVali(): void {
		this.paymentForm.controls['card_exp_month'].markAsUntouched();
		this.paymentForm.controls['card_exp_year'].markAsUntouched();
	}
	// Address functions
	/**
	 * Build the billing address
	 */
	private buildBillingAddr(): void {
		if(this.billingAddr && this.initServ.allowBillingAddr){
			this.billingAddrForm.addControl('billing_address', this.frmBldr.group({
				address: ['', [Validators.required]],
				state: [],
				city: [],
				short_address: [''],
				country:[''],
				country_code:['']
			}));
			if(this.paymentGateway !== 'square' && this.billingAddrGrp){
				this.billingAddrGrp.addControl('zipcode', new FormControl('', Validators.required))
			}
		}
	}
	/*
	 * Get address from google and store it in address variable.
	 */
	public getAddress(place: any) {
		this.billingAddrGrp.controls['address'].setValue(place.target.value);
	}
	/**
	 * On address change, set the address group control values
	 * @param addr Address
	 */
	public onAddressChange(addr: any): void {
		this.billingAddrGrp.controls['short_address'].setValue(this.utilServ.createShortAddr(addr));
		if(this.paymentGateway !== 'square'){
			// Zipcode
			let pCode: any = this.utilServ.getComponentByType(addr, 'postal_code');
			let zipcode: any = this.utilServ.getLongAddr(pCode);
			this.billingAddrGrp.controls['zipcode'].setValue(zipcode);
		}
		// City
		let cCode: any = this.utilServ.getComponentByType(addr, 'locality');
		let selectedCity: any = this.utilServ.getLongAddr(cCode);
		this.billingAddrGrp.controls['city'].setValue(selectedCity);
		// State
		let sCode: any = this.utilServ.getComponentByType(addr, 'administrative_area_level_1');
		let selectedState: any = this.utilServ.getLongAddr(sCode);
		this.billingAddrGrp.controls['state'].setValue(selectedState);
		// country
		let sCountry: any = this.utilServ.getComponentByType(addr, 'country');
		let selectedCountry: any = this.utilServ.getLongAddr(sCountry);
		this.billingAddrGrp.controls['country'].setValue(selectedCountry);
		// country
		this.billingAddrGrp.controls['country_code'].setValue(this.utilServ.getLongAddr(sCountry, 'short_name'));
	}
	/**
	 * Refresh the component by parent
	 */
	public refresh(): void{
		if(this.billingAddr && this.initServ.allowBillingAddr){
			this.billingAddrGrp.markAllAsTouched();
		}
		this.cDRef.detectChanges();
	}
	/**
	* Destroy the component
	*/
	ngOnDestroy(): void {
		switch (this.paymentGateway) {
			case 'square':
				this.paymentServ.destroySquareCard();
				break;
			case 'paypal':
			case 'authorizedotnet':
				this.paymentForm.markAsUntouched();
				break;
			default:
				this.paymentServ.destroyStripeCard();
				break;
		}
	}
}
