import { Component, Input, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, ApiServ, InitServ } from '../../Services';

@Component({
	selector: 'bk-services',
	templateUrl: './Services.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ServicesComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		desc: null,
		media: null,
		button: null
	}
	services: any;
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, public initServ: InitServ) { }

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
		// List type: custom/dynamic
		if(this.secId && this.pageSett[this.secId] && this.pageSett[this.secId].list_type && this.pageSett[this.secId].list_type == 'dynamic'){
			// Called api for get the dynamic services from content management app
			let queryParams: any = {status: 'publish', sort:'yes', language:this.initServ.savedLng};
			this.apiServ.callApiWithQueryParams('GET', 'Services', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
		}
	}
	/**
	 * On result callback method
	 * @param res api response
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res)){
			this.services = res.data;
		}
	}
}