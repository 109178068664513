import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
// External lib
import { TranslateService} from '@ngx-translate/core';
@Injectable({
	providedIn: 'root'
})
export class DateServ {
	constructor(private translate: TranslateService){
		dayjs.extend(utc);
	}

	dayFromDate(bdate:any, shortname:boolean = false){
		// console.log('dayFromDate',bdate,shortname)
		let displayDate = dayjs(bdate); // Thursday Feb 2015
		if(shortname){
			let day = displayDate.format('ddd');
			return day;
		}else{
			let day = displayDate.format('dddd');
			return day;
		}
	}

	/* function to return formatted date form unix timestamp based on store options */
	formattedDateFromUnix(date:any,isLocal:boolean=true,format:any){
		let formattedDate = ''
		if(isLocal){
			formattedDate = dayjs.unix(date).format(format)
		}else{
			formattedDate = dayjs.unix(date).utc().format(format)
		}
		return formattedDate
	}

	// TODO
	/* function to return formatted date form YMD date based on store options */
	/*formattedDateDefault(date:any,isLocal:boolean=true,format:any){
		let formattedDate = ''
		if(isLocal){
			formattedDate = dayjs(date).format(format)
		}else{
			formattedDate = dayjs(date).utc().format(format)
		}
		return formattedDate
	}*/

	/* function to return formatted date form YMD date based on formate passed as param */
	formattedDateFromString(date:any,isLocal:boolean=true,format:any){
		let formattedDate = ''
		if(isLocal){
			formattedDate = dayjs(date).format(format)
		}else{
			formattedDate = dayjs(date).utc().format(format)
		}
		return formattedDate
	}

	/* function to return formatted date for multistep calendar */
	formattedDateMultiStep(date:any, isLocal:boolean=true){
		let formattedDate = ''
		if(isLocal){
			formattedDate = dayjs(date).format('ddd, MMM DD')
		}else{
			formattedDate = dayjs(date).utc().format('ddd, MMM DD')
		}
		return formattedDate
	}
	/* function to return formatted day */
	formattedDateDay(date:any,isLocal:boolean=true){
		let format = 'dddd';
		let formattedDate = ''
		if(isLocal){
			formattedDate = dayjs(date).format(format)
		}else{
			formattedDate = dayjs(date).utc().format(format)
		}
		return this.translate.instant(formattedDate);
	}
}