import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
// Services
import { NgOnDestroy, BkngFormServ, UtilServ, InitServ, SectionServ } from '../../../Services';

@Component({
	selector: 'bk-service-category',
	templateUrl: './ServiceCategory.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class ServiceCategoryComponent implements OnInit, OnChanges {
	// Variables
	@Input() isQuoteEditable: boolean = true;
	@Input() bookingType: string = '';
	@Input() section: any;
	@Input() prefilledData: any;
	@Input() availableSett: any;
	@Input() settings: any;
	@Input() selectedLocation: any;
	@Input() selectedServiceType: any;
	@Input() selectedFrequency: any;
	@Input() serviceForm!: FormGroup;
	@Input() pageSett: any;
	@Input() isCustomerAllowedRes: any;
	@Input() isShortForm: boolean = false;
	@Output() serviceChange: EventEmitter<any> = new EventEmitter();
	@Output() hourlyServiceChange: EventEmitter<any> = new EventEmitter();
	@Output() getServices: EventEmitter<any> = new EventEmitter();
	isHourly: boolean = true;
	services: any;
	isDisabled: any;
	descStatus: boolean = false;

	constructor(private bkngFormServ: BkngFormServ, public utilServ: UtilServ, public initServ: InitServ, private secServ: SectionServ, private cDRef: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		if(this.section){
			this.descStatus = this.secServ.checkEleStatus(this.pageSett,this.section.serv_desc_id);
		}
		// Build services
		this.buildServices();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange) {
					// Service change by own and propName('selectedServiceType'), not build the service again
					if(propName != 'selectedServiceType'){
						let cur = JSON.stringify(chng.currentValue);
						let prev = JSON.stringify(chng.previousValue);
						if(cur != prev){
							this.buildServices();
							break;
						}
					}
				}
			}
		}
	}
	/**
	 * Build services
	 */
	private buildServices(): void {
		this.services = [];
		if(this.settings && this.settings.service_category && (this.settings.service_category).length > 0){
			for(let service of this.settings.service_category){
				if(this.bkngFormServ.serviceVisible(service, this.settings, this.selectedLocation,this.bookingType, this.prefilledData, this.availableSett)){
					this.services.push(service);
				}
			}
		}
		this.isDisabled = this.bkngFormServ.disabledParamScope(this.bookingType, this.prefilledData, this.selectedServiceType, this.isQuoteEditable, true, '', this.isCustomerAllowedRes);

		// Use this emit in case of short form
		if(this.isShortForm && this.services && this.services.length > 0){
			this.getServices.emit(this.services)
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Set the service
	 * @param service: selected service
	 */
	public selectService(service: any): void {
		this.selectedServiceType = service;
		this.serviceChange.emit(service);
		this.bkngFormServ.formParamMsgPopup(this.selectedServiceType);
	}
	/**
	 * Get the hourly status
	 * @param value: boolean
	 */
	public getHourlVal(value: any): void {
		this.isHourly = value;
		this.cDRef.detectChanges();
	}
}
