import { Component, OnInit, Self, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
// External lib
import { ToastrService } from 'ngx-toastr';
// Serviecs
import { NgOnDestroy, UtilServ, ApiServ, LoaderServ, InitServ, CacheService } from '../../../../../Services';

@Component({
	selector: 'bk-change-billing-address-popup',
	templateUrl: './ChangeBillingAddressPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ChangeBillingAddressPopupComponent implements OnInit {
	// Variables
	cardId: any;
	changeAddressForm!: FormGroup;
	addressForm: any;
	apiResponse: any;
	isZipcode: boolean = false;
	currentUser: any;
	customerProfile: any;
	existingCard: string = '';
	existingAddress: string = '';
	loaderId: string = 'billing-address-loader';
	//
	slug: string = 'change_billing_address';
	secId: string = '';
	section: any = { title: null, form: null, update_btn: null, cancel_btn: null };

	// eslint-disable-next-line max-params
	constructor(public dialogRef: MatDialogRef<ChangeBillingAddressPopupComponent>, private frmBldr: FormBuilder, public utilServ: UtilServ, private apiServ: ApiServ, private toastr: ToastrService, private loader: LoaderServ, public initServ: InitServ, @Self() public destroy: NgOnDestroy, private cacheServ: CacheService) {
		// Current login user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
	}

	ngOnInit(): void {
		// build the change address form
		this.changeAddressForm = this.frmBldr.group({
			card_id: [this.cardId],
			customer_id: [+this.currentUser.id],
			address: this.frmBldr.group({
				address: ['', [Validators.required]],
				zipcode: ['', [Validators.required]],
				state: [],
				city: [],
				short_address: []
			})
		});
		this.addressForm = <FormGroup>this.changeAddressForm.controls['address'];
		this.GetAuthorizePayProfile();
		// build popup section
		this.buildSectionData();
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	*/
	private buildSectionData(): void {
		// loader is chosen different as same loader Id is used to hide/ show in GetAuthorizePayProfile() method.
		let popupData: any = { slug: this.slug, loaderId: 'change-billing-loader', section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
		});
	}
	/**
	 * Function to get the authorize pay profile.
	 */
	private GetAuthorizePayProfile(): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId, this.dialogRef);
		let obj: any = {
			customer_id: +this.currentUser.id,
			card_id: this.cardId
		}
		this.apiServ.callApiWithPathVariables('POST', 'AuthorizePayProfile', [+this.currentUser.id], obj).subscribe((res: any) => this.onResultCallback(res, 'getAuthorizePayProfile'));
	}
	/**
	 * Function for callback
	 * @param res
	 * @param type
	 */
	private onResultCallback(res: any, type: string): void {
		switch (type) {
			case 'getAuthorizePayProfile':
				if (this.apiServ.checkAPIRes(res)) {
					if (res.data && res.data.paymentProfile) {
						this.customerProfile = res.data.paymentProfile;
						this.existingCard = this.customerProfile?.payment?.creditCard?.cardNumber;
						this.existingAddress = this.customerProfile?.billTo?.address;
						this.addressForm.controls['address'].setValue(this.customerProfile?.billTo?.address);
						this.addressForm.controls['zipcode'].setValue(this.customerProfile?.billTo?.zip);
					}
				}
				break;
			case 'updateAuthorizePayProfile':
				if (this.apiServ.checkAPIRes(res)) {
					this.toastr.success(res.message);
					this.dialogRef.close(true);
				} else {
					if (res && res.message) {
						this.toastr.error(res.message);
					}
				}
				break;
		}
		this.loader.hide(this.loaderId, this.dialogRef);
	}
	/**
	 * This function submits a change address form and makes an API call to update the user's payment
	 * profile if the form is valid.
	 */
	public submitChangeAddressForm(): void {
		if (this.addressForm.valid) {
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId, this.dialogRef);
			this.isZipcode = false;
			this.apiServ.callApiWithPathVariables('PUT', 'AuthorizePayProfile', [+this.currentUser.id], this.changeAddressForm.value).subscribe((res: any) => this.onResultCallback(res, 'updateAuthorizePayProfile'));
		} else {
			this.isZipcode = true;
			this.addressForm.controls['address'].markAsTouched();
			this.addressForm.controls['zipcode'].markAsTouched();
		}
	}
	/*
	 * Get address from google and store it in address variable.
	 */
	public getAddress(place: any): void {
		if (place.target.value) {
			this.addressForm.controls['address'].setValue(place.target.value);
		} else {
			this.addressForm.patchValue({
				address: '',
				zipcode: '',
				state: '',
				city: '',
				short_address: ''
			})
		}
	}
	/**
	 * Create short address
	 * @param addr
	 */
	private createShortAddr(addr: any): void {
		let shortAddr: any = this.utilServ.createShortAddr(addr);
		this.addressForm.controls['short_address'].setValue(shortAddr);
	}
	/**
	 * Set address
	 * @param addr
	 * @param type
	 */
	public setAddress(addr: any, type: string) {
		let code: any;
		switch (type) {
			case 'zipcode':
				this.createShortAddr(addr);
				code = this.utilServ.getComponentByType(addr, 'postal_code');
				break;
			case 'city':
				code = this.utilServ.getComponentByType(addr, 'locality');
				break;
			case 'state':
				code = this.utilServ.getComponentByType(addr, 'administrative_area_level_1');
				break;
		}
		let val = '';
		if (code && code.long_name) {
			val = code.long_name;
		}
		this.addressForm.controls[type].setValue(val);
	}
}
