// debounce.directive.ts
import { Directive, Output, EventEmitter, HostListener, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LoaderServ } from '../Services';
@Directive({
	selector: '[bkDebounceEvent]'
})
export class DebounceClickDirective implements OnDestroy {

	@Input() debounceTime: number = 1000; // Default debounce time
	@Input() isSubmit: boolean = false;
	@Input() loaderId: string = 'main';
	@Input() isLoader: boolean = true;
	// isDebounceLoader loader false in case of booking only
	@Input() isDebounceLoader: boolean = true;
	@Output() debounceClick = new EventEmitter();
	@Output() debounceSubmit = new EventEmitter<Event>();
	private clicks = new Subject();
	private submitEvents = new Subject<Event>();
	private destroy$ = new Subject<void>();

	constructor(private loader: LoaderServ) {
		// Handle click events with debouncing
		// The click events are piped through a debounceTime operator to prevent
		// multiple rapid clicks. After debouncing, if isDebounceLoader is true,
		// the loader is hidden and the debounced click event is emitted.
		this.clicks.pipe(debounceTime(this.debounceTime)).subscribe((e) => {
			if(this.isDebounceLoader){
				this.loader.hide(this.loaderId);
			}
			this.debounceClick.emit(e);
		});

		// Handle submit events with debouncing
		// The submit events are piped through a debounceTime operator to prevent
		// multiple rapid submissions. After debouncing, the loader is hidden
		// and the debounced submit event is emitted.
		this.submitEvents.pipe(debounceTime(this.debounceTime)).subscribe((e) => {
			this.loader.hide(this.loaderId);
			this.debounceSubmit.emit(e);
		});
	}

	/**
	 * This method listens for click events on the host element.
	 * If the isSubmit flag is false, it shows the loader,
	 * prevents the default action and event propagation and emits the event to the clicks subject.
	 * @param event
	 */
	@HostListener('click', ['$event'])
	clickEvent(event: Event): void {
		if(!this.isSubmit){
			this.loader.show(this.loaderId);
			event.preventDefault();
			event.stopPropagation();
			this.clicks.next(event);
		}
	}

	/**
	 * This method listens for submit events on the host element.
	 * It shows the loader, prevents the default action and event
	 * propagation, and emits the event to the submitEvents subject.
	 * @param event
	 */
	@HostListener('submit', ['$event'])
	onSubmit(event: Event): void {
		if(this.isLoader){
			this.loader.show(this.loaderId);
		}
		event.preventDefault();
		event.stopPropagation();
		this.submitEvents.next(event);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
