
<div [id]="section?.id" [formGroup]="serviceForm" *ngIf="settings && section && hourlyService">
	<!-- Hourly service -->
	<div [ngClass]="{'mt-30': !isMultiStepForm && services.length > 1}">
		<!-- Label -->
		<label *ngIf="!isMultiStepForm && section?.sel_time_label" [id]="section?.sel_time_label_id" class="d-block form-label bk-form-label" [innerHTML]="section?.sel_time_label | safeHtml"></label>
		<!-- Duration -->
		<div class="row">
			<div class="col-12 col-md-6 mb-20 mb-md-0">
				<label *ngIf="isMultiStepForm && section?.sel_hours_label" [id]="section?.sel_hours_label_id" class="d-block form-label bk-form-label" [innerHTML]="section?.sel_hours_label | safeHtml"></label>
				<select #serviceHours class="form-select" [ngClass]="{'form-control-lg':isMultiStepForm}" (change)="adjustmentService(serviceHours,serviceMinutes)" [(ngModel)]="defaultHour" [ngModelOptions]="{standalone: true}" [attr.disabled]="isDisabledHourly">
					<option value='0' *ngIf="minHours <= 0">{{initServ?.appStr?.text?.hours | translate}}</option>
					<ng-container *ngIf="hours && hours.length > 0">
						<ng-container *ngFor="let hour of hours;trackBy: utilServ.trackByFnIndex">
							<option [value]="hour" *ngIf="minHours <= hour">{{hour}}</option>
						</ng-container>
					</ng-container>
				</select>
			</div>
			<div class="col-12 col-md-6" [ngClass]="{'mb-20':isMultiStepForm}">
				<label *ngIf="isMultiStepForm && section?.sel_minutes_label" [id]="section?.sel_minutes_label_id" class="d-block form-label bk-form-label" [innerHTML]="section?.sel_minutes_label | safeHtml"></label>
				<select #serviceMinutes class="form-select" [ngClass]="{'form-control-lg':isMultiStepForm}" (change)="adjustmentService(serviceHours,serviceMinutes)" [(ngModel)]="defaultMinutes" [ngModelOptions]="{standalone: true}" [attr.disabled]="isDisabledHourly">
					<option value='0' *ngIf="minMinutes <= 0">{{initServ?.appStr?.text?.minutes | translate}}</option>
					<ng-container *ngIf="minutes && minutes.length > 0">
						<ng-container *ngFor="let minute of minutes;trackBy: utilServ.trackByFnIndex">
							<option [value]="minute" *ngIf="minMinutes <= minute">{{minute}}</option>
						</ng-container>
					</ng-container>
				</select>
			</div>
		</div>
	</div>
	<!-- Notify me -->
	<!-- <div class="form-check form-check-inline mt-20" [ngClass]="{'mb-30': isMultiStepForm}" *ngIf="settings && settings?.form_data && ((settings?.form_data?.notify_for_more_job_time && settings?.form_data?.notify_for_more_job_time == 'yes') || !settings?.form_data?.notify_for_more_job_time)"> -->
	<div class="form-check form-check-inline mt-20" [ngClass]="{'mb-30': isMultiStepForm}" *ngIf="notifyCustStatus">
		<input id="notify_customer" class="form-check-input" type="checkbox" formControlName="notify_customer_for_time" [attr.disabled]="isDisabledHourly">
		<label for="notify_customer" class="form-check-label bk-form-check-label" [id]="section?.notify_cust_id" >{{(section?.notify_cust) ? section?.notify_cust : initServ?.appStr?.text?.notifyMe}}</label>
	</div>
</div>