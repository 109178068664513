<div class="p-md-40 p-20" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div [id]="section?.title?.id" *ngIf="section?.title?.content" class="mb-50 tjs-html-content" [innerHTML]="section?.title?.content | safeHtml" ></div>
	<div class="justify-content-center d-sm-flex">
		<!-- Buttons -->
		<div [id]="section?.button?.id" class="bk-btn-group" *ngIf="section?.button && section?.button?.buttons && (section?.button?.buttons).length > 0">
			<div class="tjs-navigate-wrapper justify-content-center flex-column text-center">
				<ng-container *ngFor="let btn of section?.button?.buttons;trackBy: secServ.trackByFnIndex">
					<bk-navigate [secSett]="btn" [innerHTML]="btn?.content" [isPopup]="true" [dialogRef]="dialogRef"></bk-navigate>
				</ng-container>
			</div>
		</div>
	</div>
	<!-- Branding -->
	<bk-branding type="link_text" customWrapClass="mt-20" customAnchorClass="fs-14"></bk-branding>
</div>
