<div class="tjs-summary__box p-10 rounded" *ngIf="prefilledData">
	<div>
		<p class="d-flex">
			<span class="tjs-summary__more-label" applyOn translate>Service Total</span>
			<span class="tjs-summary__more-detail">
				<bk-amount-display [amount]="updatedDisplayServicePrice" [isZero]="true"></bk-amount-display>
			</span>
		</p>
		<!-- Before freq disc priceable custom fields -->
		<ng-container *ngTemplateOutlet="priceableCustomField; context:{type: 'include_in_freq_disc'}"></ng-container>

		<p class="d-flex" *ngIf="prefilledData?.referral_discount">
			<span class="tjs-summary__more-label" applyOn translate>Referral Discount</span>
			<span class="tjs-summary__more-detail">
				<bk-amount-display [amount]="prefilledData?.referral_discount"></bk-amount-display>
			</span>
		</p>
		<p class="d-flex" *ngIf="prefilledData?.same_day_booking && prefilledData?.expedited_amount && !prefilledData?.exclude_expedited_charge">
			<span class="tjs-summary__more-label" applyOn translate>Expedited Amount</span>
			<span class="tjs-summary__more-detail">
				<bk-amount-display [amount]="prefilledData?.expedited_amount"></bk-amount-display>
			</span>
		</p>
		<p class="d-flex" *ngIf="prefilledData?.frequency_discount_amount && prefilledData?.frequency_discount_amount > 0">
			<span class="tjs-summary__more-label" applyOn translate>Frequency Discount</span>
			<span class="tjs-summary__more-detail">
				<bk-amount-display [amount]="prefilledData?.frequency_discount_amount"></bk-amount-display>
			</span>
		</p>
		<p class="d-flex mt-10" *ngIf="prefilledData?.exempt_extras_price  && prefilledData?.exempt_extras_price > 0">
			<span class="tjs-summary__more-label" applyOn translate>Exempted Extras</span>
			<span class="tjs-summary__more-detail">
				<bk-amount-display [amount]="prefilledData?.exempt_extras_price"></bk-amount-display>
			</span>
		</p>
		<!-- Exempted priceable custom fields -->
		<ng-container *ngTemplateOutlet="priceableCustomField; context:{type: 'exempt_from_freq_disc'}"></ng-container>

		<p class="d-flex" *ngIf="spotDiscount && prefilledData?.day_discount && prefilledData?.day_discount.discount && prefilledData?.day_discount.discount > 0">
			<span class="tjs-summary__more-label" applyOn translate>Spot Discount</span>
			<span class="tjs-summary__more-detail">
				<bk-amount-display [amount]="spotDiscount"></bk-amount-display>
			</span>
		</p>
		<p class="d-flex" *ngIf="couponDiscount">
			<span class="tjs-summary__more-label" applyOn translate>Coupon Discount</span>
			<span class="tjs-summary__more-detail">
				<bk-amount-display [amount]="couponDiscount"></bk-amount-display>
			</span>
		</p>
		<!-- Add service fee here if service fee is taxable. -->
		<p class="d-flex" *ngIf="prefilledData?.is_service_fee_taxable && prefilledData?.is_service_fee_taxable == 'yes' && prefilledData?.service_fee && !prefilledData?.exclude_service_fee">
			<span class="tjs-summary__more-label" applyOn>{{((initServ.serviceFeeLabel) ? (initServ.serviceFeeLabel) : 'Service Fee') | translate}}</span>
			<span class="tjs-summary__more-detail">
				<bk-amount-display [amount]="prefilledData?.service_fee"></bk-amount-display>
			</span>
		</p>
	</div>
	<div>
		<p class="d-flex">
			<span class="tjs-summary__more-label" applyOn translate>Discounted Total</span>
			<span class="tjs-summary__more-detail dir-ltr">
				<bk-amount-display [amount]="prefilledData?.discounted_total" [isZero]="true"></bk-amount-display>
			</span>
		</p>
		<p class="d-flex" *ngIf="prefilledData?.adjust_price">
			<label class="tjs-summary__more-label m-0" applyOn translate>Adjusted Amount</label>
			<span class="tjs-summary__more-detail dir-ltr" *ngIf="prefilledData?.adjusted_price; else zeroAmount">
				<bk-amount-display [amount]="prefilledData?.adjusted_price"></bk-amount-display>
			</span>
		</p>
		<!-- After discounted total priceable custom fields -->
		<ng-container *ngTemplateOutlet="priceableCustomField; context:{type: 'after_discounted_total'}"></ng-container>

		<p class="d-flex" *ngIf="prefilledData?.booking_tax_rate && !prefilledData?.exempt_sales_tax">
			<span class="tjs-summary__more-label">
				<span *ngIf="admnStngs && admnStngs?.merchant_settings && admnStngs?.merchant_settings?.store && admnStngs?.merchant_settings?.store?.custom_tax_label; else defaultTaxLabel">{{admnStngs?.merchant_settings?.store?.custom_tax_label | translate}}</span>
				<ng-template #defaultTaxLabel>{{initServ?.appStr?.text?.tax | translate}}</ng-template>
				<ng-container *ngIf="prefilledData?.booking_tax_type == 'percentage'">
					({{prefilledData.booking_tax_rate}}%)
				</ng-container>
			</span>
			<span class="tjs-summary__more-detail dir-ltr">
				<ng-container *ngIf="prefilledData.booking_tax; else zeroAmount">
					<bk-amount-display [amount]="prefilledData?.booking_tax"></bk-amount-display>
				</ng-container>
			</span>
		</p>
	</div>
	<div>
		<ng-container *ngIf="tipDiscount && prefilledData?.tip && prefilledData?.tip?.total_amount && prefilledData?.tip?.total_amount > 0">
			<p class="d-flex">
				<span class="tjs-summary__more-label" applyOn translate>Tip</span>
				<span class="tjs-summary__more-detail dir-ltr">
					<bk-amount-display [amount]="tipDiscount"></bk-amount-display>
				</span>
			</p>
		</ng-container>
		<ng-container *ngIf="prefilledData?.parking?.total_amount && prefilledData?.parking?.total_amount > 0">
			<p class="d-flex">
				<span class="tjs-summary__more-label" applyOn translate>Parking</span>
				<span class="tjs-summary__more-detail dir-ltr">
					<bk-amount-display [amount]="prefilledData?.parking?.total_amount"></bk-amount-display>
				</span>
			</p>
		</ng-container>
		<!-- Add service fee here if service fee is not taxable. -->
		<p class="d-flex" *ngIf="(!prefilledData?.is_service_fee_taxable || (prefilledData?.is_service_fee_taxable == 'no')) && prefilledData.service_fee && !prefilledData.exclude_service_fee">
			<span class="tjs-summary__more-label" applyOn>{{((initServ.serviceFeeLabel) ? (initServ.serviceFeeLabel) : 'Service Fee') | translate}}</span>
			<span class="tjs-summary__more-detail dir-ltr">
				<bk-amount-display [amount]="prefilledData?.service_fee" [isZero]="true"></bk-amount-display>
			</span>
		</p>
		<!-- Non taxable priceable custom fields -->
		<ng-container *ngTemplateOutlet="priceableCustomField; context:{type: 'non_taxable'}"></ng-container>

		<p class="d-flex mb-0 tjs-summary__more-footer">
			<span class="tjs-summary__more-label fw-bold" applyOn>
				<ng-container *ngIf="(prefilledData?.gift_card.discount || prefilledData?.referral_amount) else subTotal">
					{{'Sub Total' | translate}}
				</ng-container>
				<ng-template #subTotal>
					{{(section?.total) ? section?.total : 'Total'}}
				</ng-template>
			</span>
			<span class="tjs-summary__more-detail dir-ltr fw-bold">
				<ng-container *ngIf="prefilledData?.is_voided; else totalBlock">
					<bk-amount-display [isZero]="true"></bk-amount-display>
				</ng-container>
				<ng-template #totalBlock>
					<bk-amount-display [amount]="prefilledData?.total" [isZero]="true"></bk-amount-display>
				</ng-template>
			</span>
		</p>
	</div>
	<div *ngIf="!prefilledData?.is_voided">
		<ng-container *ngIf="prefilledData?.gift_card.discount || prefilledData?.referral_amount">
			<p class="d-flex mt-10" *ngIf="prefilledData?.gift_card.discount">
				<span class="tjs-summary__more-label" applyOn translate>Gift Card Discount</span>
				<span class="tjs-summary__more-detail dir-ltr">
					<bk-amount-display [amount]="prefilledData?.gift_card.discount"></bk-amount-display>
				</span>
			</p>
			<p class="d-flex" *ngIf="prefilledData?.referral_amount">
				<span class="tjs-summary__more-label" applyOn translate>Referral Discount</span>
				<span class="tjs-summary__more-detail dir-ltr">
					<bk-amount-display [amount]="prefilledData?.referral_amount"></bk-amount-display>
				</span>
			</p>
			<p class="d-flex">
				<label class="tjs-summary__more-label m-0 l-highlight" applyOn>{{(section?.total) ? (section?.total) : 'Total'}}</label>
				<span class="tjs-summary__more-detail dir-ltr l-highlight">
					<ng-container *ngIf="prefilledData?.final_amount else zeroAmount">
						<bk-amount-display [amount]="prefilledData?.final_amount"></bk-amount-display>
					</ng-container>
				</span>
			</p>
		</ng-container>
	</div>
</div>
<!-- Empty amount -->
<ng-template #zeroAmount>
	<bk-amount-display [isZero]="true"></bk-amount-display>
</ng-template>

<!-- Priceable custom fields template-->
<ng-template #priceableCustomField let-type="type">
	<ng-container *ngIf="priceableFieldNamePriceObj && priceableFieldNamePriceObj?.[type]">
		<ng-container *ngFor="let field of priceableFieldNamePriceObj[type]">
			<p class="d-flex mt-10">
				<span class="tjs-summary__more-label" applyOn>{{field?.fieldName | translate}}</span>
				<bk-amount-display class="tjs-summary__more-detail" [amount]="field.fieldPrice" [isZero]="true"></bk-amount-display>
			</p>
		</ng-container>
	</ng-container>
</ng-template>