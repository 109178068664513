<div [id]="secId" class="custom-referral" *ngIf="pagePerm && rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div class="py-35 py-md-60">
		<div class="container">
			<div class="row" *ngIf="referrals && referrals.length > 0; else noData">
				<!-- Referral list -->
				<div class="col-12 col-md-12 col-lg-7 mb-30 mb-lg-0">
					<div  [id]="section?.referrals?.id"  class="card">
						<div class="card-body">
							<!-- Title -->
							<div class="tjs-card-title bk-form-border" *ngIf="section?.referrals && section?.referrals?.title">
								<h4 [id]="section?.referrals?.title_id"  class="mb-0 fw-bold d-flex align-items-end">
									<span [innerHTML]="section?.referrals?.title | safeHtml"></span>
									<bk-tooltip *ngIf="section?.referrals?.tooltip" customClass="{{section?.referrals?.title_id}}-tooltip" [id]="section?.referrals?.tooltip_id" [content]="section?.referrals?.tooltip | translate"></bk-tooltip>
								</h4>
							</div>
							<!-- List -->
							<div class="card shadow-sm">
								<div class="card-body p-0">
									<ul class="list-unstyled mb-0 list-group-flush rounded bk-list">
										<li class="list-group-item d-flex flex-column flex-sm-row justify-content-between align-items-center bk-item" *ngFor="let ref of referrals;trackBy: secServ.trackByFnIndex">
											<div class="align-items-center text-center text-sm-start d-flex flex-column flex-sm-row">
												<!-- Avatar -->
												<div class="tjs-media-profile__thumb-sm mb-10 mx-sm-0 mx-auto bk-item-media flex-shrink-0 mb-md-0">
													<bk-user-img [imgSrc]="ref?.photo_url" [isParentMedia]="true" class="rounded-3" alt="user image" width="60" height="60"></bk-user-img>
												</div>
												<div class="bk-item-title flex-grow-1 ms-sm-15 ms-0 mb-10 mb-sm-0">
													<a *ngIf="ref?.referred_email" href="mailto:{{ref?.referred_email}}" class="fw-bold text-dark ">{{ref?.referred_email}}</a>
													<bk-phone-number *ngIf="ref?.referred_phone" [phone]="ref?.referred_phone" customClass="fw-bold text-dark dir-ltr" [hideIcon]="true"></bk-phone-number>
												</div>
											</div>
											<!-- Badge -->
											<span class="badge bg-{{badgeClass(ref?.status)}} text-capitalize bk-item-btn">{{ref?.status | translate}}</span>
										</li>
									</ul>
								</div>
								<!-- Loader -->
								<bk-app-loader [loaderId]="listLoaderId"></bk-app-loader>
							</div>
						</div>
					</div>
					<!-- Pagination -->
					<bk-pagination class="d-block mt-20 text-end" customClass="bk-pagination" *ngIf="totalRecords>limit" [total]="totalRecords" [limit]="limit" [page]="page" (callback)="pageChange($event)"></bk-pagination>
				</div>
				<!-- Summary -->
				<div class="col-12 col-md-12 col-lg-5">
					<div [id]="section?.summary?.id" class="card rounded-5 mb-20">
						<div class="card-body">
							<!-- Sub title -->
							<div class="tjs-card-title bk-form-border" *ngIf="section?.summary && section?.summary?.title">
								<h4 class="mb-0 card-title fw-bold bk-sub-title" [id]="section?.summary?.title_id" [innerHTML]="section?.summary?.title | safeHtml">
								</h4>
							</div>
							<!-- Block -->
							<div class="position-relative">
								<ul class="list-unstyled mb-0 bk-list">
									<li class="d-flex align-items-center py-10 bk-item">
										<span class="w-60" *ngIf="section?.summary?.total_invites" [id]="section?.summary?.total_invites?.label_id">
											<span *ngIf="section?.summary?.total_invites?.label" [innerHTML]="section?.summary?.total_invites?.label | translate | safeHtml"></span>
											<bk-tooltip customClass="{{section?.summary?.id}}-tooltip" [content]="section?.summary?.total_invites?.tooltip | translate"></bk-tooltip>
										</span>
										<span class="w-10 text-center mx-10">:</span>
										<span class="w-30 text-end">{{totalInvites}}</span>
									</li>
									<li class="d-flex align-items-center py-10 bk-item">
										<span class="w-60" *ngIf="section?.summary?.success_referrals" [id]="section?.summary?.success_referrals?.label_id" >
											<span *ngIf="section?.summary?.success_referrals?.label" [innerHTML]="section?.summary?.success_referrals?.label | translate | safeHtml"></span>
											<bk-tooltip customClass="{{section?.summary?.id}}-tooltip" [content]="section?.summary?.success_referrals?.tooltip | translate"></bk-tooltip>
										</span>
										<span class="w-10 text-center mx-10">:</span>
										<span class="w-30 text-end">{{summary?.success}}</span>
									</li>
									<li class="d-flex align-items-center py-10 bk-item">
										<span class="w-60" *ngIf="section?.summary?.total_credits" [id]="section?.summary?.total_credits?.label_id">
											<span *ngIf="section?.summary?.total_credits?.label" [innerHTML]="section?.summary?.total_credits?.label | translate | safeHtml"></span>
											<bk-tooltip customClass="{{section?.summary?.id}}-tooltip" [content]="section?.summary?.total_credits?.tooltip | translate"></bk-tooltip>
										</span>
										<span class="w-10 text-center mx-10">:</span>
										<span class="w-30 text-end"><bk-amount-display [amount]="summary['creditsEarned']" [isZero]="true" ></bk-amount-display></span>
									</li>
									<li class="d-flex align-items-center py-10 bk-item">
										<span class="w-60" *ngIf="section?.summary?.balance" [id]="section?.summary?.balance?.label_id">
											<span *ngIf="section?.summary?.balance?.label" [innerHTML]="section?.summary?.balance?.label | translate | safeHtml"></span>
											<bk-tooltip customClass="{{section?.summary?.id}}-tooltip" [content]="section?.summary?.balance?.tooltip | translate"></bk-tooltip>
										</span>
										<span class="w-10 text-center mx-10">:</span>
										<span class="w-30 text-end"><bk-amount-display [amount]="summary['balance']" [isZero]="true"></bk-amount-display></span>
									</li>
								</ul>
								<!-- Loader -->
								<bk-app-loader [loaderId]="summaryLoaderId"></bk-app-loader>
							</div>
						</div>
					</div>
					<!-- Button -->
					<div class="bk-btn-group" *ngIf="section?.button">
						<bk-navigate [secSett]="section?.button" [innerHTML]="section?.button?.content"></bk-navigate>
					</div>
				</div>
			</div>
			<ng-template #noData>
				<div class="card">
					<div class="card-body">
						<h4 class="mb-0 fw-bold">{{initServ?.appStr?.text?.noRerrals | translate}}</h4>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>