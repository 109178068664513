import { Injectable } from '@angular/core';
// Services
import { InitServ } from '../';
import { CalCmnFuncServ } from './CalCmnFunc.service';
import { PriceableFieldCalServ } from './PriceableFieldCal.service';

@Injectable({
	providedIn: 'root'
})
export class PrvdrPayCalServ {

	// private variables
	private admnStngs: any;
	constructor(private initServ: InitServ, private cmnFunc: CalCmnFuncServ, private priceableFieldCal: PriceableFieldCalServ) {
		this.admnStngs = this.initServ.appAdmnStngs; // App admin settings
	}

	/*****************Calculate provider payment functions*************************************/

	/**
	 * Function first checks if both selectedService and BKFrmValue have a property override_provider_pay with a truthy value.
	 * If this condition is true, the function calls another function calcOverriddenPrvdrTotalAmt with the same parameters and returns its result.
	 * If the condition is false, the function calls another function calcPrvdrTotalAmtMain with the same parameters and returns its result.
	 * Overall, this code is used to decide which function to call based on certain conditions.
	 * @param BKFrmValue : form control
	 * @param priceLocalVar : price variable
	 * @param prvdrPayVar : provider payment variables
	 * @param selectedService : service
	 */
	public calcPrvdrTotalAmt(BKFrmValue: any, priceLocalVar: any, prvdrPayVar: any, selectedService: any): any{
		if(selectedService && selectedService.override_provider_pay && BKFrmValue.override_provider_pay){
			// calculate overridden provider total amount
			return this.calcOverriddenPrvdrTotalAmt(BKFrmValue, priceLocalVar, prvdrPayVar, selectedService);
		}else{
			return this.calcPrvdrTotalAmtMain(BKFrmValue, priceLocalVar, prvdrPayVar, selectedService);
		}
	}
	/**
	 * Function named calcPrvdrTotalAmtMain. It takes four parameters: BKFrmValue, priceLocalVar, prvdrPayVar, and selectedService.
	 * Inside the function, it initializes some variables and retrieves the provider details from the BKFrmValue object.
	 * Based on the number of providers, the function calls different helper functions to calculate the provider payment.
	 * After calculating the provider payment, the function sets the tip, parking, bonus, and service fee in provider payment.
	 * Finally, it resets some variables and returns an object containing the updated prvdrPayVar, displayProviderAmount, and BKFrmValue.
	 * @returns
	 */
	private calcPrvdrTotalAmtMain(BKFrmValue: any, priceLocalVar: any, prvdrPayVar: any, selectedService: any){
		let providerAmount = 0;
		let providerIds: any = [];
		let providers = BKFrmValue.provider_details;
		BKFrmValue.provider_ids = [];
		let prvdrObj : any = {
			providers : providers,
			providerAmount : providerAmount,
			providerIds : providerIds
		}
		let outputObj : any = {};
		switch(+providers.length){
			case 0:
				providerAmount = 0;
			break;
			case 1:
				// This block for single provider(individual or team)
				if(providers[0].provider_type == 'team'){
					// This block is for team
					outputObj = this.calcPrvdrPayTeam(BKFrmValue, prvdrPayVar, priceLocalVar, prvdrObj);

				}else{
					// This block is for individual provider
					outputObj = this.calcPrvdrPayIndividual(BKFrmValue, prvdrPayVar, priceLocalVar, prvdrObj);
				}
				BKFrmValue = outputObj.BKFrmValue;
				prvdrPayVar = outputObj.prvdrPayVar;
				priceLocalVar = outputObj.priceLocalVar;
				providerAmount = outputObj.providerAmount;
			break;
			default:
				// This block is for pair provider
				outputObj = this.calcPrvdrPayPair(BKFrmValue, prvdrPayVar, priceLocalVar, prvdrObj);
				BKFrmValue = outputObj.BKFrmValue;
				prvdrPayVar = outputObj.prvdrPayVar;
				priceLocalVar = outputObj.priceLocalVar;
				providerAmount = outputObj.providerAmount;
				providerIds = outputObj.providerIds;
			break;
		}
		// Set the tip, parking, bonus and service fee in provider payment
		let tipParkInptObj = {providerAmount: providerAmount, prvdrPayVar: prvdrPayVar, BKFrmValue: BKFrmValue, priceLocalVar: priceLocalVar, selectedService: selectedService}
		let tipParkingObj = this.setTipParkingBonusServiceFeeForProv(tipParkInptObj);
		providerAmount = tipParkingObj.providerAmount;
		prvdrPayVar = tipParkingObj.prvdrPayVar;
		// Reset variables
		// Assign
		BKFrmValue.service_provider_pay_overridden = false;
		BKFrmValue.provider_pay_overridden_value = null;
		BKFrmValue.provider_pay_overridden_type = null;
		prvdrPayVar.override_provider_pay = false;
		prvdrPayVar.provider_pay_value = 0;
		priceLocalVar.displayProviderAmount = providerAmount;
		return {
			prvdrPayVar,
			BKFrmValue,
			displayProviderAmount : priceLocalVar.displayProviderAmount,
		}
	}
	/**
	 * Function takes in several parameters: BKFrmValue, prvdrPayVar, priceLocalVar, and prvdrObj.
	 * Inside the function, it initializes two variables - "providers" and "providerAmount" - using the "prvdrObj" parameter.
	 * After that, it pushes the first provider's ID into the "provider_ids" array of "BKFrmValue" parameter.
	 * Then, it initializes two more variables - "providerBasicAmount" and "providerReimbursements" - with initial values of 0 and an empty array respectively.
	 * Next, it checks if the first provider has any members and if so, it loops through each member.
	 * Inside the loop, it calls another function named "calcPayForProvider" to calculate the payment for each member.
	 * The calculated values are then added to the "providerAmount" and "providerBasicAmount" variables.
	 * After that, it checks if the member has any reimbursements and if so, it pushes them into the "providerReimbursements" array.
	 * Finally, it rounds the "providerBasicAmount" to two decimal places using the "roundToTwo" function from "cmnFunc" object, sets the "reimbursements" property of "prvdrPayVar" to the "providerReimbursements" array, and returns an object containing the values of "BKFrmValue", "providerAmount", "prvdrPayVar", and "priceLocalVar".
	 * @param BKFrmValue
	 * @param prvdrPayVar
	 * @param priceLocalVar
	 * @param prvdrObj
	 * @returns
	 */
	private calcPrvdrPayTeam(BKFrmValue: any, prvdrPayVar: any, priceLocalVar: any, prvdrObj: any){
		let providers = prvdrObj.providers;
		let providerAmount = prvdrObj.providerAmount;
		(BKFrmValue.provider_ids).push(providers[0].id);
		let providerBasicAmount = 0;
		let providerReimbursements = [];
		if(providers[0].members && (providers[0].members).length > 0){
			// Loop on team's members
			for(let member of providers[0].members){
				// call function to get the amount for member
				let provdrPayObj = this.calcPayForProvider(member, BKFrmValue, prvdrPayVar, priceLocalVar.serviceTotalForProvider)
				providerAmount = providerAmount + provdrPayObj.providerAmount;
				providerBasicAmount = providerBasicAmount + provdrPayObj.providerBasicAmount;
				// calculate reimbursements
				member?.reimbursements ? (providerReimbursements).push(member.reimbursements) : null;
				providerAmount = this.calcPrvdrReimbursement(member, providerAmount);
			}
		}
		prvdrPayVar.providerBasicAmount = this.cmnFunc.roundToTwo(+providerBasicAmount);
		prvdrPayVar.reimbursements = providerReimbursements;
		return {
			BKFrmValue,
			providerAmount,
			prvdrPayVar,
			priceLocalVar,
		}
	}
	/**
	 * Calculates the provider pay and reimbursement amount for a specific provider. It takes in four parameters: BKFrmValue, prvdrPayVar, priceLocalVar, and prvdrObj.Inside the function, it performs the following actions:
	 * Stores the providers and providerAmount from prvdrObj into local variables.
	 * Pushes the id of the first provider into the provider_ids array of BKFrmValue.
	 * Initializes providerBasicAmount to 0 and creates an empty array providerReimbursements.
	 * Calls a function calcPayForProvider and passes in the necessary parameters to calculate the pay for the provider. The calculated values are then added to providerAmount and providerBasicAmount.
	 * Checks if the providers[0] has reimbursements and if so, pushes it to providerReimbursements.
	 * Calculates the reimbursement amount for the provider and updates providerAmount.
	 * Rounds providerBasicAmount to two decimal places.
	 * Sets prvdrPayVar.providerBasicAmount to the rounded providerBasicAmount.
	 * Sets prvdrPayVar.reimbursements to providerReimbursements.
	 * Returns an object with properties: BKFrmValue, providerAmount, prvdrPayVar, and priceLocalVar.
	 * @param BKFrmValue
	 * @param prvdrPayVar
	 * @param priceLocalVar
	 * @param prvdrObj
	 * @returns
	 */
	private calcPrvdrPayIndividual(BKFrmValue: any, prvdrPayVar: any, priceLocalVar: any, prvdrObj: any){
		let providers = prvdrObj.providers;
		let providerAmount = prvdrObj.providerAmount;
		(BKFrmValue.provider_ids).push(providers[0].id);
		let providerBasicAmount = 0;
		let providerReimbursements = [];
		// call function to get the amount for provider
		let provdrPayObj = this.calcPayForProvider(providers[0], BKFrmValue, prvdrPayVar, priceLocalVar.serviceTotalForProvider)
		providerAmount = providerAmount + provdrPayObj.providerAmount;
		providerBasicAmount = providerBasicAmount + provdrPayObj.providerBasicAmount;
		// calculate reimbursements
		providers[0].reimbursements ? (providerReimbursements).push(providers[0].reimbursements) : null;
		providerAmount = this.calcPrvdrReimbursement(providers[0], providerAmount);

		prvdrPayVar.providerBasicAmount = this.cmnFunc.roundToTwo(+providerBasicAmount);
		prvdrPayVar.reimbursements = providerReimbursements;
		return {
			BKFrmValue,
			providerAmount,
			prvdrPayVar,
			priceLocalVar,
		}
	}
	/**
	 * Function initializes some variables, including providerBasicAmount and providerReimbursements.
	 * Then it loops through the providers array and checks if the provider's ID is not already included in the providerIds array.
	 * If the provider's ID is not present, it pushes the ID to the BKFrmValue.provider_ids array.
	 * It calls another function calcPayForProvider, passing in the provider, BKFrmValue, prvdrPayVar, and priceLocalVar?.serviceTotalForProvider.
	 * It calculates the provider's amount and basic amount by adding the values returned by calcPayForProvider to the respective variables.
	 * Next, it checks if the provider has reimbursements and if so, it pushes them to the providerReimbursements array.
	 * Then it calculates the reimbursement amount by calling the calcPrvdrReimbursement function, passing in the provider and providerAmount.
	 * Finally, it updates the prvdrPayVar object with the providerBasicAmount and the providerReimbursements.
	 * It returns an object containing the calculated values, including BKFrmValue, providerAmount, prvdrPayVar, priceLocalVar, and providerIds.
	 * @param BKFrmValue
	 * @param prvdrPayVar
	 * @param priceLocalVar
	 * @param prvdrObj
	 * @returns
	 */
	private calcPrvdrPayPair(BKFrmValue: any, prvdrPayVar: any, priceLocalVar: any, prvdrObj: any){
		let { providers, providerAmount, providerIds } = prvdrObj;
		let providerBasicAmount = 0;
		let providerReimbursements = [];
		// Loop on selected providers
		for(let provider of providers){
			if(!providerIds.includes(provider.id)){
				(BKFrmValue.provider_ids).push(provider.id);
				// call function to get the amount for provider
				let provdrPayObj = this.calcPayForProvider(provider, BKFrmValue, prvdrPayVar, priceLocalVar?.serviceTotalForProvider)
				providerAmount = providerAmount + provdrPayObj.providerAmount;
				providerBasicAmount = providerBasicAmount + provdrPayObj.providerBasicAmount;
				// calculate reimbursements
				provider.reimbursements ? (providerReimbursements).push(provider.reimbursements) : null;
				providerAmount = this.calcPrvdrReimbursement(provider, providerAmount);

				providerIds.push(provider.id)
			}
		}
		prvdrPayVar.providerBasicAmount = this.cmnFunc.roundToTwo(+providerBasicAmount);
		prvdrPayVar.reimbursements = providerReimbursements;
		return {
			BKFrmValue,
			providerAmount,
			prvdrPayVar,
			priceLocalVar,
			providerIds,
		}
	}
	/**
	 * Calculates the reimbursement amount for a provider. It takes in two input parameters: provider and providerAmount.
	 * Inside the function, it checks if the provider has any reimbursements. If it does, it loops through each reimbursement and adds its value to the providerAmount variable, rounding the result to two decimal places using the roundToTwo function.
	 * Finally, it returns the calculated providerAmount.
	 * @param provider
	 * @param providerAmount
	 * @returns
	 */
	private calcPrvdrReimbursement(provider: any, providerAmount: any){
		if(provider.reimbursements){
			for(let reimbursement in provider.reimbursements){
				providerAmount = this.cmnFunc.roundToTwo(providerAmount + (+provider?.reimbursements[reimbursement]));
			}
		}
		return providerAmount;
	}
	/**
	 * Function that calculates the overridden provider total amount for a given set of inputs.
	 * First initializes two variables, providerAmount and providerTotalOverriddenAmount, and retrieves the providers from the BKFrmValue object.
	 * Then, it sets several properties of the BKFrmValue object to null or false.
	 * Next, the code checks if the selectedService and BKFrmValue have overridden provider payment values. If so, it calls the calcOverridePrvdrPay function to perform additional calculations and updates the BKFrmValue and prvdrPayVar objects.
	 * After that, the code checks the length of the providers array. Depending on the length, different calculations are performed.
	 * If there are no providers, the providerAmount is set to 0.
	 * If there is only one provider, the provider_ids array is updated with the ID of the provider, and the providerAmount and providerBasicAmount are calculated based on the overridden provider payment value.
	 * If there are multiple providers, the provider_ids array is updated with the IDs of all the providers, and the providerAmount and providerBasicAmount are calculated based on the overridden provider payment value.
	 * Then, the code calls the setTipParkingBonusServiceFeeForProv function to set the tip, parking, bonus, and service fee in the provider payment. The function takes several input parameters and returns an updated providerAmount and prvdrPayVar objects.
	 * Finally, the code sets the displayProviderAmount to the calculated providerAmount, updates the provider_pay_value in the prvdrPayVar object, and returns an object containing the prvdrPayVar, displayProviderAmount, and BKFrmValue.
	 * @param BKFrmValue
	 * @param priceLocalVar
	 * @param prvdrPayVar
	 * @param selectedService
	 * @return object
	 */
	public calcOverriddenPrvdrTotalAmt(BKFrmValue: any, priceLocalVar: any, prvdrPayVar: any, selectedService: any): any{
		let providerAmount = 0;
		let providerTotalOverridenAmount = 0;
		let providers = BKFrmValue?.provider_details;

		BKFrmValue.provider_ids = [];
		BKFrmValue.service_provider_pay_overridden = false;
		BKFrmValue.provider_pay_overridden_value = null;
		BKFrmValue.provider_pay_overridden_type = null;
		// If overriden payment for provider
		if(selectedService && selectedService.override_provider_pay && BKFrmValue.override_provider_pay){
			let ovrdPrvdrObj : any = {
				providerTotalOverridenAmount : providerTotalOverridenAmount,
				bookingTotalTime: priceLocalVar.bookingTotalTime
			}
			let outputObj = this.calcOverridePrvdrPay(BKFrmValue, prvdrPayVar, selectedService, ovrdPrvdrObj);
			BKFrmValue = outputObj.BKFrmValue;
			prvdrPayVar = outputObj.prvdrPayVar;
			providerTotalOverridenAmount = outputObj.providerTotalOverridenAmount;
		}
		let providerBasicAmount = 0;
		//If provider length is 0
		switch(+providers.length){
			case 0:
				providerAmount = 0;
			break;
			case 1:
				// If single provider
				(BKFrmValue.provider_ids).push(providers[0].id);
				providerBasicAmount = 0;
				providerAmount = this.cmnFunc.roundToTwo(providerAmount + (+BKFrmValue.provider_pay_overridden_value));
				providerBasicAmount = this.cmnFunc.roundToTwo((+providerBasicAmount) + (+BKFrmValue.provider_pay_overridden_value));
				prvdrPayVar.providerBasicAmount = (+providerBasicAmount);
			break
			default:
				// If providers more than 1
				providerBasicAmount = 0;
				providerAmount = this.cmnFunc.roundToTwo(providerAmount + (+BKFrmValue.provider_pay_overridden_value));
				providerBasicAmount = this.cmnFunc.roundToTwo((+providerBasicAmount) + (+BKFrmValue.provider_pay_overridden_value));
				for(let provider of providers){
					(BKFrmValue.provider_ids).push(provider.id);
				}
				prvdrPayVar.providerBasicAmount = this.cmnFunc.roundToTwo(+providerBasicAmount);
			break;
		}
		// Set the tip, parking, bonus and service fee in provider payment
		let tipParkInptObj = {providerAmount: providerAmount, prvdrPayVar: prvdrPayVar, BKFrmValue: BKFrmValue, priceLocalVar: priceLocalVar, selectedService: selectedService}
		let tipParkingObj = this.setTipParkingBonusServiceFeeForProv(tipParkInptObj);
		providerAmount = tipParkingObj.providerAmount;
		prvdrPayVar = tipParkingObj.prvdrPayVar;
		priceLocalVar.displayProviderAmount = providerAmount;
		prvdrPayVar.provider_pay_value = this.cmnFunc.roundToTwo(+priceLocalVar.displayProviderAmount);
		return {
			prvdrPayVar,
			BKFrmValue,
			displayProviderAmount : priceLocalVar.displayProviderAmount,
		}
	}
	/**
	 * Calculates the overridden provider pay amount based on certain input values and conditions.
	 * It takes in parameters including BKFrmValue, prvdrPayVar, selectedService, and ovrdPrvdrObj.
	 * It initializes variables providerTotalOverridenAmount and bookingTotalTime based on values from ovrdPrvdrObj.
	 * It checks the provider pay type and if it is "amount", it sets providerTotalOverridenAmount to the selectedService's provider pay value. It also sets prvdrPayVar's override_provider_pay property to true.
	 * If the provider pay type is not "amount", it checks if the selectedService is an hourly service and adjusts the bookingTotalTime accordingly.
	 * It then calculates the serviceHours as the bookingTotalTime divided by 60, rounds it to 2 decimal places, and multiplies it by the selectedService's provider pay value to get the providerTotalOverridenAmount. It also sets prvdrPayVar's override_provider_pay property to true.
	 * Finally, it sets some values in BKFrmValue, including service_provider_pay_overridden, provider_pay_overridden_value, and provider_pay_overridden_type, and returns an object with the updated values of BKFrmValue, prvdrPayVar, and providerTotalOverridenAmount.
	 * @param BKFrmValue
	 * @param prvdrPayVar
	 * @param selectedService
	 * @param ovrdPrvdrObj
	 * @returns
	 */
	private calcOverridePrvdrPay(BKFrmValue: any, prvdrPayVar: any, selectedService: any, ovrdPrvdrObj: any){
		let providerTotalOverridenAmount = ovrdPrvdrObj.providerTotalOverridenAmount;
		let bookingTotalTime = ovrdPrvdrObj.bookingTotalTime;
		let providerOverriddenType = selectedService.provider_pay_type;
		let serviceHours : any;
		let decimalserviceHours: any;
		// if payment overridden type amount
		switch(providerOverriddenType){
			case 'amount':
				providerTotalOverridenAmount = this.cmnFunc.roundToTwo(+selectedService.provider_pay_value);
				prvdrPayVar.override_provider_pay = true;
			break;
			default:
				// if payment overridden type hourly
				bookingTotalTime = BKFrmValue.adjust_time ? +BKFrmValue.adjusted_time : bookingTotalTime;
				if(selectedService.is_hourly_service == 'yes'){
					if(BKFrmValue.adjust_time_hourly){
						bookingTotalTime = BKFrmValue.service_hourly_value;
					}else if(this.cmnFunc.ifPriceBaseOfHSCustom(selectedService)){
						bookingTotalTime = BKFrmValue.service_hourly_value;
						if(BKFrmValue.add_fields_time){
							bookingTotalTime = bookingTotalTime + BKFrmValue.add_fields_time;
						}
					}
				}
				// If prvdr time overriden
				bookingTotalTime = this.overrideTimeForOverridePrvdr(BKFrmValue, bookingTotalTime);

				serviceHours = (+bookingTotalTime) / 60;
				decimalserviceHours = Number(serviceHours).toFixed(2);
				providerTotalOverridenAmount = this.cmnFunc.roundToTwo((+selectedService.provider_pay_value) * (+decimalserviceHours));
				prvdrPayVar.override_provider_pay = true;
			break;
		}
		// set variales
		BKFrmValue.service_provider_pay_overridden = true;
		BKFrmValue.provider_pay_overridden_value = providerTotalOverridenAmount;
		BKFrmValue.provider_pay_overridden_type = providerOverriddenType;
		return {
			BKFrmValue,
			prvdrPayVar,
			providerTotalOverridenAmount,
		}
	}
	/**
	 * Calculates the overridden time for overridden providers based on specific conditions.
	 * @param BKFrmValue Specific values from the form data related to provider overrides.
	 * @param time The initial time.
	 * @returns The calculated overridden time for overridden providers.
	 */
	public overrideTimeForOverridePrvdr(BKFrmValue: any, time: number): number {
		// Check if the form data includes specific properties related to provider overrides
		// and if the each_prvdr_time array contains any elements
		let teamLead: number = this.teamLeadIdIfTeamLeadPayBaseTeam(BKFrmValue);
		if(BKFrmValue?.override_each_prvdr_time && BKFrmValue?.each_prvdr_time && (BKFrmValue.each_prvdr_time).length > 0){
			time = 0; // Initialize time to 0
			// Iterate through each provider in the each_prvdr_time array
			for(let prvdr of BKFrmValue.each_prvdr_time){
				// If the time property of the provider is greater than 0, add it to the time variable
				if((teamLead == 0 || +teamLead == +prvdr.provider_id) && prvdr?.time > 0){
					time += prvdr?.time
				}
			}
		}
		// Return the calculated overridden time for overridden providers
		return time
	}
	/**
	 * If provider type is team and team payment only goes to lead, then return the team lead id
	 * @param BKFrmValue Specific values from the form data related to provider overrides.
	 * @returns Team lead id
	 */
	public teamLeadIdIfTeamLeadPayBaseTeam(BKFrmValue: any): number {
		let teamLead: number = 0;
		let providers = BKFrmValue.provider_details;
		if(+providers.length == 1){
			if(providers[0]?.provider_type == 'team' && providers[0]?.payment_goes_to == "lead"){
				teamLead = providers[0]?.team_lead_id
			}
		}
		return teamLead;
	}
	/**
	 * Calculates the payment amount for a service provider based on various factors such as their wages, the booking length, and any applicable discounts.
	 * It takes in parameters for the provider, booking form values, provider payment variables, and the total service amount for the provider.
	 * The code initializes variables for the provider amount, provider basic amount, wages base, booking total time, service hours, decimal service hours, and adjusted price.
	 * It checks if the provider has specified wages and a base pay. If so, it checks the base pay unit.
	 * If the base pay unit is "amount", it calculates the provider amount and provider basic amount by applying any provider coupon discount.
	 * If the base pay unit is "hourly", it calculates the booking total time and converts it to service hours. It then calculates the wages base by multiplying the base pay with the service hours. It also applies any provider coupon discount.
	 * If the base pay unit is not "amount" or "hourly", it assumes it is a percentage and calculates the provider amount and provider basic amount by multiplying the base pay with the adjusted price (either the service total or the adjusted price if specified).
	 * The function returns an object with the provider amount and provider basic amount.
	 * @param provider : provider
	 * @param BKFrmValue : form control
	 * @param prvdrPayVar : object
	 * @param serviceTotalForProvider: amount
	 * @returns object
	 */
	public calcPayForProvider(provider: any, BKFrmValue: any, prvdrPayVar: any, serviceTotalForProvider: number): any {
		let providerAmount = 0;
		let providerBasicAmount = 0;
		let wagesBase = 0;
		let bookingTotalTime : any;
		let serviceHours : any;
		let decimalserviceHours : any;
		let adjustedPrice : any;
		if(provider.wages && provider.wages.base_pay){
			// if provider pay unit is amount
			switch(provider.wages.base_pay_unit){
				case 'amount':
					wagesBase = +provider.wages.base_pay;
					// Apply provider coupon discount
					wagesBase = this.getPrvdrDiscAmount(+wagesBase, BKFrmValue, prvdrPayVar, true);

					providerAmount = this.cmnFunc.roundToTwo(providerAmount + (+wagesBase));
					providerBasicAmount = this.cmnFunc.roundToTwo((+providerBasicAmount) + (+wagesBase));
				break;
				case 'hourly':
					// if providr pay unit is hourly
					bookingTotalTime = this.calcPrvdrTimeVal(BKFrmValue, +provider.id);

					serviceHours = (+bookingTotalTime) / 60;
					decimalserviceHours = Number(serviceHours).toFixed(2);
					wagesBase = ((+provider.wages.base_pay) * (+decimalserviceHours));
					// Apply provider coupon discount
					wagesBase = this.getPrvdrDiscAmount(+wagesBase, BKFrmValue, prvdrPayVar, true);

					providerAmount = this.cmnFunc.roundToTwo(providerAmount + (+wagesBase));
					providerBasicAmount = this.cmnFunc.roundToTwo((+providerBasicAmount) + (+wagesBase));
				break;
				default:
					// if providr pay unit is percentage
					adjustedPrice = +serviceTotalForProvider;
					adjustedPrice = this.ifPrvdrPayBasedOnAdjusted(BKFrmValue) ? +BKFrmValue.adjusted_price : adjustedPrice;
					providerAmount = this.cmnFunc.roundToTwo(providerAmount + (((+provider.wages.base_pay)/100)*adjustedPrice));
					providerBasicAmount = this.cmnFunc.roundToTwo((+providerBasicAmount) + (((+provider.wages.base_pay)/100)*adjustedPrice));
			}
		}
		return {
			providerAmount,
			providerBasicAmount,
		};
	}
	/**
	 * Calculate the provider's total booking time based on various conditions.
	 *
	 * @param {any} BKFrmValue - The booking form values containing information about the booking.
	 * @param {number} providerId - The ID of the provider for whom the time is being calculated.
	 * @returns {number} - The total booking time for the specified provider.
	 *
	 * The function performs the following steps:
	 * 1.	Sets the initial booking total time from the provider_length property of BKFrmValue.
	 * 2.	If there is an adjusted time provided (adjust_time property is true), update the bookingTotalTime to the adjusted_time value.
	 * 3. If there is an override for each provider's time (override_each_prvdr_time property is true) and
	 *		the each_prvdr_time array is not empty, iterate over the each_prvdr_time array:
	 *		- If a matching provider_id is found in the each_prvdr_time array, return the overridden time for that provider.
	 * 4.	If no specific time override is found for the provider, return the general booking total time.
	 */
	public calcPrvdrTimeVal(BKFrmValue: any, providerId: number): number {
		// if providr pay unit is hourly
		let bookingTotalTime = BKFrmValue?.provider_length;
		// If adjusted time
		bookingTotalTime = (BKFrmValue?.adjust_time) ? +BKFrmValue?.adjusted_time : bookingTotalTime;
		// If overriden time
		if(BKFrmValue?.override_each_prvdr_time && BKFrmValue?.each_prvdr_time && (BKFrmValue.each_prvdr_time).length > 0){
			for(let prvdr of BKFrmValue.each_prvdr_time){
				if(prvdr?.provider_id == providerId){
					return +prvdr?.time
				}
			}
		}
		return +bookingTotalTime;
	}
	/**
	 * Function that determines whether the payment for a provider should be based on the adjusted price.
	 * It takes a parameter BKFrmValue and checks if the adjust_price property is true, and if the calculate_provider_payment_on property of this.admnStngs.merchant_settings.providers is equal to 'adjusted'.
	 * If both conditions are true, it returns true, otherwise it returns false.
	 * @param BKFrmValue
	 * @returns
	 */
	private ifPrvdrPayBasedOnAdjusted(BKFrmValue: any){
		if(BKFrmValue.adjust_price && this.cmnFunc.isValExist(this.admnStngs.merchant_settings?.providers?.calculate_provider_payment_on) && this.admnStngs.merchant_settings?.providers?.calculate_provider_payment_on == 'adjusted'){
			return true;
		}
		return false;
	}
	/**
	 * Function that calculates a discounted value based on certain parameters.
	 * First initializes a variable isPrvdrDscntApplicable to false. Inside the function, there is an if statement that checks the value of flag.
	 * If flag is true, it checks if a specific condition is met. If the condition is true, it sets isPrvdrDscntApplicable to true.
	 * Otherwise, it sets isPrvdrDscntApplicable based on another condition from BKFrmValue.
	 * Next, the code initializes a variable providerDiscountValue as the numeric value of prvdrPayVar.couponDiscount.
	 * Then, discountedValue is initialized as 0.
	 * There is another if statement that checks if providerDiscountValue is truthy and isPrvdrDscntApplicable is true. If both conditions are met, discountedValue is calculated as the difference between discountBaseAmount and providerDiscountValue. If the condition is not met, discountedValue is simply discountBaseAmount.
	 * Finally, the function returns the calculated discountedValue.
	 * @param discountBaseAmount : number
	 * @param BKFrmValue : form control values
	 * @param prvdrPayVar : object of provider details
	 * @param flag : boolean
	 * @returns number
	 */
	public getPrvdrDiscAmount(discountBaseAmount: number, BKFrmValue: any, prvdrPayVar: any, flag: boolean = false): number {
		let isPrvdrDscntApplicable = false;
		if(flag){
			if(this.cmnFunc.isValExist(this.admnStngs.merchant_settings?.providers?.provider_discount_on_all_payments) && this.admnStngs.merchant_settings?.providers?.provider_discount_on_all_payments == 'yes'){
				isPrvdrDscntApplicable = true;
			}
		}else{
			isPrvdrDscntApplicable = BKFrmValue.apply_provider_discount ? BKFrmValue.apply_provider_discount : false;
		}
		let providerDiscountValue = +prvdrPayVar.couponDiscount;
		let discountedValue = 0;
		if(providerDiscountValue && isPrvdrDscntApplicable){
			discountedValue = (providerDiscountValue <= discountBaseAmount) ? ((+discountBaseAmount) - (+providerDiscountValue)) : 0;
		}else{
			discountedValue = +discountBaseAmount;
		}
		return discountedValue;
	}
	/**
	 * Function that takes an input object and performs some calculations based on the values inside the object.
	 * Here is a brief explanation of what the code does:
	 * It retrieves the values from the input object and assigns them to local variables.
	 * It checks if there is a "tip" value in the input object. If so, it adds the tip amount to the provider amount and updates the "tipsAmount" property in the "prvdrPayVar" object.
	 * It checks if there is a "parking" value in the input object. If so, it adds the parking amount to the provider amount and updates the "parkingAmount" property in the "prvdrPayVar" object.
	 * It checks if there is a "bonus" value in the input object. If so, it adds the bonus amount to the provider amount and updates the "bonusAmount" property in the "prvdrPayVar" object.
	 * It checks if there is a "service_fee" value in the input object and if the "excludeServiceFeeValue" is false. If so, it adds the service fee amount to the provider amount and updates the "serviceFeeAmount" property in the "prvdrPayVar" object.
	 * It checks if there are any "priceable_custom_fields" in the input object. If so, it calculates the provider value from the priceable fields and adds it to the provider amount and updates the "priceableFieldsPrice" property in the "prvdrPayVar" object.
	 * Finally, it returns an object containing the updated "providerAmount" and "prvdrPayVar" values.
	 * @returns object
	 */
	public setTipParkingBonusServiceFeeForProv(inptObj: any): any{
		let BKFrmValue = inptObj.BKFrmValue;
		let priceLocalVar = inptObj.priceLocalVar;
		let prvdrPayVar = inptObj.prvdrPayVar;
		let providerAmount = inptObj.providerAmount;
		let selectedService = inptObj.selectedService;
		// tip block
		if(BKFrmValue.tip.total_amount){
			providerAmount = this.cmnFunc.roundToTwo(providerAmount + (+priceLocalVar.displayTipsAmount));
			prvdrPayVar.tipsAmount = this.cmnFunc.roundToTwo((+priceLocalVar.displayTipsAmount));
		}
		// parking block
		if(BKFrmValue.parking.total_amount){
			providerAmount = this.cmnFunc.roundToTwo(providerAmount + (+BKFrmValue.parking.total_amount));
			prvdrPayVar.parkingAmount = this.cmnFunc.roundToTwo((+BKFrmValue.parking.total_amount));
		}
		// bonus block
		if(BKFrmValue.bonus.total_amount){
			providerAmount = this.cmnFunc.roundToTwo(providerAmount + (+BKFrmValue.bonus.total_amount));
			prvdrPayVar.bonusAmount = this.cmnFunc.roundToTwo((+BKFrmValue.bonus.total_amount));
		}
		// service fee block
		if(BKFrmValue.service_fee && !priceLocalVar.excludeServiceFeeValue){
			providerAmount = this.cmnFunc.roundToTwo((+providerAmount) + (+BKFrmValue.service_fee));
			prvdrPayVar.serviceFeeAmount = this.cmnFunc.roundToTwo((+BKFrmValue.service_fee));
		}
		// priceable custom fields
		if(BKFrmValue.priceable_custom_fields) {
			let priceableFieldValue = this.priceableFieldCal.calcPrvdrValFromPriceableFields(BKFrmValue, selectedService, '', '');
			providerAmount = this.cmnFunc.roundToTwo((+providerAmount) + (+priceableFieldValue?.price));
			prvdrPayVar.priceableFieldsPrice = this.cmnFunc.roundToTwo((+priceableFieldValue?.price));
		}
		return{
			providerAmount,
			prvdrPayVar,
		};
	}
	/**
	 * Function first initializes two boolean variables: isPrvdrPayDifferent which is set to false, and prevPrvdrOvridePay which is set to 0.
	 * It then checks if prefilledData exists and if it has a property provider_wages that is an array with length greater than 0.
	 * If this condition is true, it further checks if the first element of provider_wages has a property overridden_pay and if providerAmount is not equal to prefilledData.provider_wages[0].overridden_pay.
	 * If this condition is also true, it sets isPrvdrPayDifferent to true and assigns the value of prefilledData.provider_wages[0].overridden_pay to prevPrvdrOvridePay.
	 * Finally, the function returns an object with two properties: isPrvdrPayDifferent and prevPrvdrOvridePay
	 * @param providerAmount
	 */
	public checkProviderOverridePayment(providerAmount: any, prefilledData: any): any {
		let isPrvdrPayDifferent = false;
		let prevPrvdrOvridePay = 0;
		if(prefilledData && prefilledData.provider_wages && (prefilledData.provider_wages).length > 0){
			if(prefilledData.provider_wages[0].overridden_pay && providerAmount != prefilledData.provider_wages[0]?.overridden_pay){
				isPrvdrPayDifferent = true;
				prevPrvdrOvridePay = prefilledData.provider_wages[0].overridden_pay;
			}
		}
		return {
			isPrvdrPayDifferent,
			prevPrvdrOvridePay,
		}
	}
}
