import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// External lib
import { TranslateModule } from '@ngx-translate/core';
// Module
import { GlobalDefaultModule } from '../GlobalDefault/GlobalDefault.module'; // Global module
// Services
import { UserPopupServ } from './UserPopup.service';
// Popup components
import { TipPopupComponent, PostponeComponent, CancelPopupComponent } from './Bookings/Popups';
// Components & Popup
import { ProfileCardComponent, ChangePasswordPopupComponent, InvoiceListingComponent, AddCardPopupComponent, CardApplyToComponent } from './Profile';
import { BookingListingComponent, CancelBookingComponent } from './Bookings';

@NgModule({
	imports: [
		CommonModule, RouterModule, TranslateModule, MatDialogModule, FormsModule, ReactiveFormsModule,
		GlobalDefaultModule
	],
	declarations: [ProfileCardComponent, BookingListingComponent, CancelBookingComponent, TipPopupComponent, PostponeComponent, CancelPopupComponent, ChangePasswordPopupComponent, InvoiceListingComponent, AddCardPopupComponent, CardApplyToComponent],
	exports: [ProfileCardComponent, BookingListingComponent, CancelBookingComponent, InvoiceListingComponent, AddCardPopupComponent, CardApplyToComponent],
	entryComponents: [TipPopupComponent, PostponeComponent, CancelPopupComponent, ChangePasswordPopupComponent],
	providers: [UserPopupServ]
})
export class UserComponentsModule { }
