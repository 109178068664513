import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
	name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

	constructor(protected _santizer: DomSanitizer) { }

	public transform(value?: string, type: string='html'): SafeHtml | SafeResourceUrl {
		if(value){
			switch (type) {
				case 'html':
					return this._santizer.bypassSecurityTrustHtml(value);
				case 'resourceUrl':
					return this._santizer.bypassSecurityTrustResourceUrl(value);
				default:
					throw new Error(`Unable to bypass security for invalid type: ${type}`);
			}
		}
		return '';
	}

}
