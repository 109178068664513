import { Component, Input } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../../Services';
// Component
import { ServiceProviderComponent } from '../../ServiceProvider/ServiceProvider.component';

@Component({
	selector: 'bk-multi-service-provider',
	templateUrl: './MultiServiceProvider.component.html',
	providers: [NgOnDestroy]
})
export class MultiServiceProviderComponent extends ServiceProviderComponent {

	@Input() isStepOneAvail : any;
	@Input() activeStep : any;
	isDateClicked : boolean = false;
	ngOnInit(): void {
		super.ngOnInit();
	}
	/**
	 * Function to select multi step date.
	 */
	selectMultiStepDate(res: any): void {
		this.perDateAvailSpots = res.spots;
		this.availableDates = res.availableDates;
		this.selectedDateForSlot = res.providers;
		this.teamMembers = res.team_members;
		this.minProvidersReq = res.minprovidersreq
		this.clickMultiStepDate(res.date.year + '-' + res.date.month + '-' + res.date.day)
		// this.clickMultiStepDate(value.year + '-' + value.month + '-' + value.day)
	}

	onDateSelect(date: any): void{
		this.isDateClicked = false;
		setTimeout(()=>{
			this.isDateClicked = true;
			this.cDRef.detectChanges();
		},0);
		this.clickMultiStepDate(date);
	}
}
