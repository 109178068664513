import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// Services
import { NgOnDestroy, InitServ } from '../../../../Services';

@Component({
	selector: 'bk-picture-preview-popup',
	template: `<div class="tjs-popup tjs-popup--sm">
		<!-- Header -->
		<bk-popup-header [heading]="initServ?.appStr?.text?.yourLocations" [isCloseAll]="false"></bk-popup-header>
		<!-- Picture -->
		<div class="tjs-popup__content text-center">
			<img loading="auto" *ngIf="picture" width="400" height="400" [src]="initServ?.imgBase + picture" class="img-fluid popup-img" alt="location" />
		</div>
		<!-- Button -->
		<div class="tjs-popup__footer d-flex">
			<button type="button" class="btn btn-primary w-100" (click)="dialogRef.close()">{{initServ?.appStr?.text?.done | translate}}</button>
		</div>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers:[NgOnDestroy]
})
export class PicturePreviewPopupComponent implements OnInit {
	// Variables
	picture: any;
	constructor(public dialogRef: MatDialogRef<PicturePreviewPopupComponent>, public initServ: InitServ) { }

	ngOnInit(): void {}

}