<ng-container *ngIf="booking">
	<div class="tjs-popup tjs-popup--sm position-relative" [id]="secId">
		<!-- Popup header -->
		<bk-popup-header *ngIf="section?.title" [headingId]="section?.title?.id" [heading]="section?.title?.content" [isClose]="false"></bk-popup-header>
		<form [formGroup]="ratingForm" bkDebounceEvent [isSubmit]="true" (debounceSubmit)="submitForm()">
			<!-- Popup body -->
			<div class="tjs-popup__content tjs-popup__body" [id]="section?.form?.id">
				<ng-container *ngIf="(booking?.provider_info && (booking?.provider_info).length > 0 && !(booking.provider_info).includes(0)); else dateTime">
					<!-- Profile card -->
					<div class="d-flex d-flex align-items-start mb-sm-50 mb-30">
						<div class="" *ngIf="utilServ.userNameSett('photo')">
							<bk-user-img class="d-inline-block" customClass="rounded-5 img-fluid-disabled" [imgSrc]="booking?.provider_info[0]?.photo_url" [isParentMedia]="true" alt="user image" height="60" width="60"></bk-user-img>
						</div>
						<div class="px-15 py-0 w-100">
							<ng-container *ngIf="utilServ.userNameVisible()">
								<div class="" [ngSwitch]="booking?.provider_category">
									<!-- Single -->
									<ng-container *ngSwitchCase="'single'">
										<h5 class="title text-capitalize mb-5 bk-popupName" *ngIf="utilServ.userNameSett('firstname') || utilServ.userNameSett('lastname') || utilServ.userNameSett('last_initial')">
											<ng-container *ngIf="utilServ.userNameSett('firstname')">{{booking?.provider_info[0]?.first_name}}&nbsp;</ng-container>
											<ng-container *ngIf="utilServ.userNameSett('lastname')">{{booking?.provider_info[0]?.last_name}}</ng-container>
											<ng-container *ngIf="!utilServ.userNameSett('lastname') && utilServ.userNameSett('last_initial')">{{utilServ.nameLastInitial(booking?.provider_info[0]?.last_name)}}</ng-container>
										</h5>
									</ng-container>
									<!-- Team -->
									<div class="mb-5" *ngSwitchCase="'team'">
										<h5 class="title text-capitalize fw-bold mb-5 bk-popupName">{{booking?.provider_info[0]?.first_name}}</h5>
									</div>
									<!-- Pair -->
									<div class="mb-5" *ngSwitchCase="'pair'">
										<div class="badge bg-warning tjs-pointer" translate>Pair</div>
									</div>
								</div>
							</ng-container>
							<!-- Booking date/time -->
							<ng-container *ngTemplateOutlet="dateTimeTemp; context:{isCenter: false, booking: booking}"></ng-container>
						</div>
					</div>
				</ng-container>
				<!-- Booking date/time -->
				<ng-template #dateTime>
					<ng-container *ngTemplateOutlet="dateTimeTemp; context:{isCenter: true, booking: booking}"></ng-container>
				</ng-template>
				<!-- Rating  -->
				<div class="mb-30 text-center">
					<h5 class="fw-bold bk-form-title" *ngIf="section?.form?.rating?.label" [innerHTML]="section?.form?.rating?.label | safeHtml"></h5>
					<div class="d-inline-block">
						<bk-rating-display customClass="tjs-rating--lg bk-rating bk-star-group" [rating]="ratingForm.value['rating']" (ratingCallback)="ratingForm.controls['rating'].setValue($event)"></bk-rating-display>
					</div>
					<div class="tjs-error justify-content-center mt-0 mb-10" *ngIf="ratingForm.controls['rating']?.errors?.required && ratingForm.controls['rating']?.touched"><i class="tjsicon-attention"></i> {{initServ?.appStr?.errorMsg?.emptyRating | translate}}</div>
				</div>
				<!-- Feedback review -->
				<div class="">
					<label for="review" class="form-label bk-form-label d-block" *ngIf="section?.form?.feedback?.label" [innerHTML]="section?.form?.feedback?.label | safeHtml"></label>
					<textarea class="form-control p-10" rows="4" id="review" formControlName="review" [attr.placeholder]="section?.form?.feedback?.placeholder"></textarea>
				</div>
			</div>
			<!-- Popup footer -->
			<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse bk-footColor">
				<bk-navigate [secId]="section?.send_btn?.id" [designId]="section?.send_btn?.design_id" [innerHTML]="section?.send_btn?.content" [isSubmit]="true" essentialClass="w-100" customClass="btn btn-primary rounded-5 w-100" class="d-block w-100 mb-15 mb-sm-5"></bk-navigate>
				<bk-navigate [secId]="section?.cancel_btn?.id" [designId]="section?.cancel_btn?.design_id" [innerHTML]="section?.cancel_btn?.content" [isSubmit]="false" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" class="d-block w-100 mb-5" (callback)="skipRating('skip')"></bk-navigate>
			</div>
		</form>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</div>
</ng-container>
<!-- Date Time Template -->
<ng-template #dateTimeTemp let-iscenter="isCenter" let-booking="booking">
	<p class="d-flex align-items-center flex-wrap bk-dateTime" [ngClass]="(iscenter) ? 'justify-content-center mb-30': 'mb-0'">
		{{booking?.day | translate}},
		<bk-date-display class="d-inline-block mx-5" [date]="booking?.arrival_date"></bk-date-display>{{'at' | translate}}
		<span class="d-inline-block dir-ltr ms-5"> {{utilServ.slotToString(booking?.base_location_id, booking?.day, booking?.arrival_time,booking?.spot_type,booking?.arrival_window)}}</span>
	</p>
</ng-template>
