import { Component, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../Services';
// Inheritance components
import { ServiceCategoryComponent } from '../../../Global/BookingForm';

@Component({
	selector: 'bk-short-form-service-cat',
	templateUrl: './ShortFrmServiceCat.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ShortFrmServiceCatComponent extends ServiceCategoryComponent {
	@Input() variationId: string = '';
	@Input() sectionIds: any;
}