/**
 * Component use for connect third party apps that requires redirection, When access return to app-integration page other wise show error
 */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/internal/operators/map';
// Interface
import { APIRes } from 'src/app/Interfaces';
// Services
import { ApiServ, InitServ, NgOnDestroy } from 'src/app/Services';
interface ConnectApiRes extends APIRes {
	data?: string;
}
@Component({
	selector: 'bk-third-party-connect',
	template: `<div *ngIf="isInvalidState" class="align-items-center bg-primary d-flex justify-content-center text-center vh-100">
		<div class="align-items-center d-flex flex-column gap-40">
			<img src="{{initServ.imgBase}}/assets/images/bookingkoala-logo.png" alt="kirbyy" width="277" height="167">
			<h3>Invalid State</h3>
			<p *ngIf="apiRes">{{apiRes | json}}</p>
		</div>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ThirdPartyConnectComponent implements OnInit {

	queryParams: { code: string; state: string; realmId: string; baseUrl: string } = { code: '', state: '', realmId: '', baseUrl: '' };
	isInvalidState: boolean = false;
	apiRes: ConnectApiRes | null = null;
	currentRoute: string = '';

	constructor(private actRoute: ActivatedRoute, private apiServ: ApiServ, public initServ: InitServ, private http: HttpClient) {
		// Get current route
		this.currentRoute = this.actRoute.snapshot?.routeConfig?.path ?? '';
		this.setQueryParams();
	}

	ngOnInit() {
		if (this.queryParams.code) {
			this.hitGetConnectApi();
		} else {
			this.redirectToApp();
		}
	}

	/**
	 * Set the query params in local variable, get from url
	 */
	private setQueryParams(): void {
		this.queryParams.code = this.actRoute.snapshot.queryParamMap.get('code') ?? '';
		this.queryParams.realmId = this.actRoute.snapshot.queryParamMap.get('realmId') ?? '';
		this.queryParams.baseUrl = this.actRoute.snapshot.queryParamMap.get('url') ?? '';
		let state: string | null = this.actRoute.snapshot.queryParamMap.get('state');
		if (state) {
			let stateArray: string[] = state.split('&url=');
			this.queryParams.state = stateArray[0];
			this.queryParams.baseUrl = stateArray[1];
		}
	}

	private hitGetConnectApi(): void {
		if (this.currentRoute) {
			switch (this.currentRoute) {
				case 'qbconnect':
					this.getQbConnectApi();
					break;
				case 'xeroconnect':
					this.getXeroConnectApi();
					break;
				default:
					break;
			}
		}
	}

	/**
	 * Initiates a call to the QuickBooks OAuth API with the necessary query parameters.
	 * It subscribes to the API response and processes the result through the onResultCallback method.
	 */
	private async getQbConnectApi(): Promise<void> {
		let httpParams = new HttpParams().set('code', this.queryParams.code).set('state', this.queryParams.state).set('realmId', this.queryParams.realmId);
		let apiUrl: string = `https://${this.queryParams.baseUrl}/bksync/v1/qb-oauth2redirect`;
		this.hitApiToConnect(apiUrl, httpParams);
	}

	/**
	 * Initiates a call to the Xero OAuth API with the necessary query parameters.
	 * It subscribes to the API response and processes the result through the onResultCallback method.
	 */
	private async getXeroConnectApi(): Promise<void> {
		let httpParams = new HttpParams().set('code', this.queryParams.code).set('state', this.queryParams.state);
		let apiUrl: string = `https://${this.queryParams.baseUrl}/bksync/v1/xero-oauth2redirect`;
		this.hitApiToConnect(apiUrl, httpParams);
	}

	/**
	 * Hit api for the mentioned url.
	 * @param apiUrl url to hit api
	 * @param httpParams : query params
	 */
	private async hitApiToConnect(apiUrl: string, httpParams: HttpParams): Promise<void> {
		let headers: any = await this.initServ.getSessionHeaders(this.queryParams.baseUrl.split('.')[0]);
		headers['auth'] = 'false';
		this.http.get<{ response: ConnectApiRes }>(apiUrl, { params: httpParams, headers: new HttpHeaders(headers) }).pipe(map((result: { response: ConnectApiRes }) => { return result?.response; })).subscribe((res: ConnectApiRes) => this.onResultCallback(res));
	}

	/**
	 * Callback function that processes the API response.
	 * If the response is valid, redirects to the specify app integration page.
	 * Otherwise, sets the isInvalidState flag and stores the response.
	 */
	private onResultCallback(res: ConnectApiRes): void {
		if (this.apiServ.checkAPIRes(res)) {
			this.redirectToApp();
		// If status = 3 means xero account is already connected with booking koala. Then we will show error toastr to user after redirect.
		} else if (res?.api_status === 2) {
			this.redirectToApp(res?.message);
		} else {
			this.isInvalidState = true;
			this.apiRes = res;
		}
	}

	/**
	 * @param msg Pass message if you want to show it in error toastr after redirect.
	 */
	private redirectToApp(msg: string = ''): void {
		if (this.currentRoute) {
			switch (this.currentRoute) {
				case 'qbconnect':
					this.redirectToURL('admin/app-integration/quickbooks', msg);
					break;
				case 'xeroconnect':
					this.redirectToURL('admin/app-integration/xero', msg);
					break;
				default:
					this.redirectToURL('');
					break;
			}
		}
	}

	/**
	 * Redirects the user to the app integration page.
	 * @param url: url where we have to redirect
	 * @param msg: pass message if we want to show it in error toastr after redirect.
	 */
	private redirectToURL(url: string, msg: string = ''): void {
		window.location.href = `${window.location.protocol}//${this.queryParams.baseUrl}/${url}${msg ? `?error=${msg}` : ''}`;
	}
}