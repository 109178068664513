/* eslint-disable complexity */
/* eslint-disable max-depth */
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
// Services
import { NgOnDestroy, UtilServ, InitServ, BkngFormServ, SectionServ, BuildCustomSectionService } from '../../../Services';

@Component({
	selector: 'bk-pricing-parameter',
	templateUrl: './PricingParameter.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class PricingParameterComponent implements OnInit {

	// Variables
	@Input() isQuoteEditable: boolean = true;
	@Input() bookingType: string = '';
	@Input() section: any;
	@Input() prefilledData: any;
	@Input() prefilledParams: any;
	@Input() prefilledExcludes: any;
	@Input() availableSett: any;
	@Input() pricingParamForm!: FormGroup;
	@Input() settings: any;
	@Input() selectedLocation: any;
	@Input() selectedServiceType: any;
	@Input() selectedFrequency: any;
	@Input() isMultiStepForm: any;
	@Input() isShortForm: boolean = false;
	@Input() pageSett: any;
	@Input() isCustomerAllowedRes: any;
	@Output() pricingParamChange: EventEmitter<any> = new EventEmitter();
	@Output() excludeChange : EventEmitter<any> = new EventEmitter();
	@Output() getExcludes : EventEmitter<any> = new EventEmitter();
	@Output() pricingParamsStatus: EventEmitter<boolean> = new EventEmitter();
	pricingParams: any;
	pricingFormValue: any;
	paramChangeByList: boolean = false;
	isDisabled: any;
	pricingParamObj: any;
	excludes: any;
	excludeStatus: boolean = false;
	excludesFormValue: any;
	isChangeByEvent: boolean = false;

	/**
	 * When an array changes, Angular re-renders the whole DOM tree. But if you use trackBy, Angular will know which element has changed and will only make DOM changes for that particular element.
	 * @param _index
	 * @param paramCat
	 * @returns selected_value
	 */
	public trackByParamValue(_index:any, paramCat: any) {
		return paramCat.selected_value;
	}

	// eslint-disable-next-line max-params
	constructor(public utilServ: UtilServ, public initServ: InitServ, public bkngFormServ: BkngFormServ, private cDRef: ChangeDetectorRef, private secServ: SectionServ, public frmBldr: FormBuilder, private buildCustSecServ: BuildCustomSectionService) {}

	ngOnInit(): void {
		if(this.section){
			this.excludeStatus = this.secServ.checkEleStatus(this.pageSett,this.section.excludes_id);
		}
		this.buildPricingParams();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange) {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev){
						this.buildPricingParams();
						break;
					}
				}
			}
		}
	}
	/**
	 * ngOnChanges is not detect the changes, then ngDoCheck works
	 * Param change by list(user selection), build function is not working
	 */
	ngDoCheck(): void {
		if(!this.paramChangeByList){
			let cur = JSON.stringify(this.pricingParamForm.value['pricing_parameter']);
			let prev = JSON.stringify(this.pricingFormValue);
			let isParamsChange = (cur != prev) ? true : false;
			// On partial_cleaning change, its only work prefilled the excludes
			if(!this.isChangeByEvent && !isParamsChange && !this.isShortForm){
				cur = JSON.stringify(this.pricingParamForm.value['partial_cleaning']);
				prev = JSON.stringify(this.excludesFormValue);
			}
			if(cur != prev){
				this.buildPricingParams();
			}
		}
	}
	/**
	 * Check the visibility and build the pricing parameter array
	 */
	// eslint-disable-next-line complexity
	public buildPricingParams(): void {
		this.pricingParams = [];
		this.buildCustSecServ.availableElems['variables'] = [];
		this.pricingFormValue = this.pricingParamForm.value['pricing_parameter']
		this.pricingParamObj = this.getFormPricingParams();
		if(this.settings && this.settings.pricing_parameters && (this.settings.pricing_parameters).length > 0){
			for(let pricingParam of this.settings.pricing_parameters){
				if(pricingParam.value && (pricingParam.value).length > 0){
					let obj: any = {
						id: pricingParam.id,
						name: pricingParam.name,
						cat_name: this.bkngFormServ.getPricingParamCatName(pricingParam?.name, this.settings),
						value: [],
						selected_value:null
					}
					for(let section of pricingParam.value){
						if(this.bkngFormServ.pricingParamsValueVisible(section, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledParams, this.availableSett)){
							section['param_name']=this.utilServ.getFormParamName(section);
							(obj.value).push(section);
						}
						// Form pricing_parameter exist, set the selected value
						if(this.pricingParamObj && this.pricingParamObj[section.id]){
							obj.selected_value = section.id;
						}
					}
					if(obj && (obj.value).length > 0){
						//  Add 'none' pricing parameter if enabled from form settings
						if(this.settings?.form_data?.none_pricing_param && this.settings?.form_data?.none_pricing_param == 'yes'){
							let noneObj: any = {
								id: "none",
								param_name: "None"
							};
							(obj.value).unshift(noneObj);
						}
						this.pricingParams.push(obj);
						this.buildCustSecServ.availableElems['variables'].push(pricingParam.id);
					}
				}
			}
		}
		let status: boolean = this.pricingParams.length > 0 ? true : false;
		this.pricingParamsStatus.emit(status);
		this.isDisabled = this.bkngFormServ.disabledParamScope(this.bookingType, this.prefilledData, this.selectedServiceType, this.isQuoteEditable, false, '', this.isCustomerAllowedRes);
		// Build excludes
		if((this.prefilledData && this.prefilledData.partial_cleaning && (this.prefilledData.partial_cleaning).length > 0) || (this.excludeStatus)){
			this.buildExcludes();
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Get the form pricing parameters
	 * @returns object
	 */
	private getFormPricingParams(): any {
		let pricingParameters: any = this.pricingParamForm.value.pricing_parameter;
		let params : any = {}
		if(pricingParameters && pricingParameters.length > 0){
			for(let param of pricingParameters){
				params[param.quantity] = param;
			}
		}
		return params;
	}
	/**
	 * Pricing parameter change
	 * @param event : selected param event
	 * @param pricingParam: pricing parameter category
	 * @param index: pricing parameter category index
	 */
	public pricingParameterChange(event: any, pricingParam: any): void {
		// Change by list, set true
		this.paramChangeByList = true;
		// Set the form control value
		let quantity: any = (event.target.value && event.target.value != 'none') ? (+event.target.value) : 'none';
		// For show the tooltip, set the selected value
		pricingParam.selected_value = quantity;
		let pricingParams : any = <FormArray>this.pricingParamForm.value['pricing_parameter'];
		let pricingArray: any = <FormArray>this.pricingParamForm.controls['pricing_parameter'];
		let isControl: boolean = false;
		if(pricingParams && pricingParams.length>0){
			(pricingParams).forEach((value: any, paramIndex: any)=>{
				// if((value.name == pricingParam.name) && (+value.id == +pricingParam.id)){
				if((+value.id == +pricingParam.id)){
					isControl = true;
					if(quantity == 'none'){
						pricingArray.removeAt(pricingArray.value.findIndex((param: any) => param?.id === pricingParam?.id))
					} else {
						(<FormGroup>(pricingArray).controls[paramIndex]).controls['quantity'].setValue(quantity);
					}
				}
			});
		}
		// Control not exist, add the control
		this.addPricingParamControl(isControl, pricingParam, quantity);
		// Get the selected param setting
		let selectedParam: any = null;
		if(pricingParam.value && (pricingParam.value).length > 0){
			for(let param of pricingParam.value){
				if(param.id == quantity){
					selectedParam = param;
				}
			}
		}
		if(selectedParam){
			this.bkngFormServ.formParamMsgPopup(selectedParam);
		}
		// Pricing parameter change, build the excludes again
		this.buildExcludes();
		this.pricingParamChange.emit();
	}
	/**
	 * Add the pricing parameter control, if not exist
	 * Note: Call if the pricing parameter control not exist in BKFrm
	 * @param isControl: Check the control exist
	 * @param pricingParam: Selected parameter
	 * @param quantity: Pricing parameter quantity
	 */
	private addPricingParamControl(isControl: boolean, pricingParam: any, quantity: any): void {
		if(!isControl){
			let pricingParamFormArray = <FormArray>this.pricingParamForm.controls['pricing_parameter'];
			let control: any = {
				id: pricingParam?.id,
				name: pricingParam?.name,
				quantity: quantity,
				recurring: null
			};
			pricingParamFormArray.push(this.frmBldr.group(control));
		}
	}

	// Excludes functions
	/**
	 * Build the excludes
	 */
	private buildExcludes(): void {
		this.excludesFormValue = this.pricingParamForm.value['partial_cleaning'];
		this.excludes = [];
		if(this.settings.excludes && (this.settings.excludes).length > 0){
			let obj = {
				formId: +this.pricingParamForm.value.form_id,
				params: this.bkngFormServ.getFormPricingParamIds(this.pricingParamForm.value)
			}
			for(let exclude of this.settings.excludes){
				if(this.bkngFormServ.excludeVisible(exclude, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, obj, this.prefilledExcludes, this.availableSett)){
					this.excludes.push(exclude);
				}
			}
		}
		if(this.isShortForm){
			this.getExcludes.emit(this.excludes);
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Excludes change by event
	 */
	public excludesChangeEvent(): void {
		this.isChangeByEvent = true;
		this.excludeChange.emit()
	}
}
