<!-- Discount bar -->
<ng-container *ngIf="couponCode && couponData && couponData?.discount_bar_status && couponData?.discount_bar_status == 'yes'">
	<bk-bkng-discount-bar [class.multistep-discount-bar]="!(formLayout == '' || formLayout != 'multi_step')" [data]="couponData" [formLayout]="(formLayout == 'multi_step') ? 'multistep_form' : 'form'"></bk-bkng-discount-bar>
</ng-container>
<ng-container *ngIf="pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div id="booking-form-main">
		<ng-container *ngIf="(formLayout == '' || formLayout != 'multi_step'); else multiStepLayout">
			<div [id]="secId" *ngIf="selectedIndustryId; else noIndustry" class="tjs-bg-style booking-add-form bk-media position-relative" [ngStyle]="{'background-image' : (mediaStatus && section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}">
				<div [id]="section?.media?.id" class="tjs-opacity--bg" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0"></div>
				<ng-container *ngIf="showPrequalifier">
					<!-- Industry tab -->
					<div class="text-center pt-30 bg-white min-height--50" *ngIf="industryBar && tabVisibility && industries && industries.length > 0" [ngStyle]="{'background-color': (parentPageSett?.industry_tab_bg_color) ? parentPageSett?.industry_tab_bg_color : ''}">
						<div class="container">
							<div class="tjs-tab tjs-tab--box tjs-tab--box__secondary">
								<ul class="mb-0 tjs-list" [ngClass]="{'justify-content-center': (noOfIndustries && noOfIndustries < 5), 'justify-content-start': (noOfIndustries && noOfIndustries > 4)}" >
									<li *ngFor="let industry of industries;trackBy: secServ.trackByFnIndex">
										<span class="tjs-tab__link fw-bold text-dark" [ngClass]="{'active tjs-pointer-none' : (bookingVar?.industrySlug == industry?.industry_slug || industry?.id == selectedIndustryId) }" (click)="industryChange(industry)" [style]="tabStyle">
											{{(industry?.custom_industry_name ? industry?.custom_industry_name : industry?.industry_name) |translate }}
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<!-- Combine form questions -->
					<div class="container" *ngIf="showMultiFormBlock && ((industryData && industryData?.combined_combination && (industryData?.combined_combination).length > 0) || (industryData && industryData?.single_combination && (industryData?.single_combination).length > 0))">
						<div class="pre-qualifier-block pt-30">
							<div class="row">
								<div class="col-12 col-md-12 col-lg-8">
									<div class="card border rounded-5 bk-card">
										<div class="card-body py-0">
											<ng-container *ngIf="industryData && industryData?.combined_combination  && (industryData?.combined_combination).length > 0; else singleCombination">
												<div class="py-30 px-md-30 border-bottom bk-card-border">
													<!-- Question -->
													<div class="mb-25" *ngIf="industryData?.combined_combination[0]?.question">
														<h4 class="mb-0 bk-form-title"> {{industryData?.combined_combination[0]?.question |translate}}	</h4>
													</div>
													<!-- Options -->
													<ng-container *ngIf="industryData?.combined_combination[0]?.prequalifier_data && (industryData?.combined_combination[0]?.prequalifier_data).length > 0">
														<div class="form-check mb-20" *ngFor="let option of industryData?.combined_combination[0]?.prequalifier_data;trackBy: secServ.trackByFnIndex">
															<input id="{{option?.options}}" class="form-check-input" type="radio" name="form_id" value="{{option?.id}}" (change)="combineOptionChange(option)" [attr.checked]="combineDefaultChecked(option)" />
															<label for="{{option?.options}}" class="form-check-label bk-form-check-label">{{option?.options |translate}}</label>
														</div>
													</ng-container>
												</div>
												<!-- Selected combination -->
												<ng-container *ngIf="selectedCombination && selectedCombination?.prequalifier_data && (selectedCombination?.prequalifier_data).length > 0">
													<ng-container *ngTemplateOutlet="singleCombine; context:{title: selectedCombination?.question, options: selectedCombination?.prequalifier_data}"></ng-container>
												</ng-container>
											</ng-container>
											<!-- Single combination -->
											<ng-template #singleCombination>
												<ng-container *ngIf="industryData && industryData?.single_combination  && (industryData?.single_combination).length > 0 && industryData?.single_combination[0]?.prequalifier_data && (industryData?.single_combination[0]?.prequalifier_data).length > 0">
													<ng-container *ngTemplateOutlet="singleCombine; context:{title: industryData?.single_combination[0]?.question, options: industryData?.single_combination[0]?.prequalifier_data}"></ng-container>
												</ng-container>
											</ng-template>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
				<!-- Forms set for industries -->
				<div class="container" *ngIf="industries && industries.length > 0">
					<ng-container *ngFor="let industry of industries;trackBy: secServ.trackByFnIndex">
						<ng-container *ngIf="industry.id == selectedIndustryId && section">
							<ng-container *ngIf="formLayout == 'one_step'; else twoStep">
								<ng-container *ngTemplateOutlet="singleFormTemp"></ng-container>
							</ng-container>
							<ng-template #twoStep>
								<ng-container *ngTemplateOutlet="twoStepFormTemp"></ng-container>
							</ng-template>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</ng-container>
		<!-- Multi step layout -->
		<ng-template #multiStepLayout>
			<div [id]="secId">
				<!-- Show in responsive -->
				<div [id]="section?.multi_step_header?.id" *ngIf="section?.multi_step_header" class="multistep__wrapper bg-white bk-form-multistep-wrap" [ngClass]="{'multistep-logo-hidden':!section?.multi_step_header?.logo, 'd-none': multiStepFrmFirstStep, 'main-header--enable': utilServ.multiStepHeader}">
					<div class="multistep-bglayer vh-100 position-absolute top-0 end-0 bottom-0 start-0 pe-none bg-white bk-position-absolute bk-form-multistep-wrap"></div>
					<!-- Multi step header -->
					<div class="multistep__header d-flex flex-column flex-lg-row justify-content-between shadow-sm tjs-body-bg flex-column">
						<!-- Mobile responsive -->
						<div [id]="section?.multi_step_header?.logo" class="multistep__responsive--logo d-flex justify-content-between align-items-center px-15 tjs-pointer d-lg-none">
							<div class="fw-bold" *ngIf="section?.multi_step_header?.logo && utilServ?.siteLogo">
								<ng-container *ngTemplateOutlet="logoTemp"></ng-container>
							</div>
							<div class="multistep__responsive--arrow d-lg-none ps-10 d-flex justify-content-center align-items-center tjs-pointer bk-arrow ms-auto" (click)="addBkngServ.respSummary()">
								<i class="tjsicon-down-arrow text-white"></i>
							</div>
						</div>
						<!-- Booking summary -->
						<div class="multistep__header--elements d-none d-lg-flex">
							<div class="multistep__responsive d-lg-none p-15">
								<div class="d-flex justify-content-between w-100 h-50px align-items-center">
									<div class="multistep__overlay--title">
										<h5 class="fw-bold mb-0 bk-form-title">{{initServ?.appStr?.text?.bookingSummary | translate}}</h5>
									</div>
									<div class="multistep__overlay--icon d-flex justify-content-center align-items-center tjs-pointer bk-arrow" (click)="addBkngServ.respSummary()">
										<span class="tjsicon-up-arrow tjs-pointer"></span>
									</div>
								</div>
							</div>
							<div class="multistep__header--elements-wrap">
								<!-- Responsive > 991 -->
								<div [id]="section?.multi_step_header?.logo" *ngIf="section?.multi_step_header?.logo && utilServ?.siteLogo" class="multistep__header--ele multistep__header--logo multistep__header--ele-fixed justify-content-center align-items-center tjs-pointer step-completed bk-form-multi-header-ele">
									<ng-container *ngTemplateOutlet="logoTemp"></ng-container>
								</div>
								<!-- Industry name -->
								<div class="multistep__header--ele multistep__header--industry justify-content-center align-items-center tjs-pointer bk-form-multi-header-ele">
									<h5 class="fw-bold mb-0 bk-form-multi-header-label">{{bkngFormServ.industryName(selectedIndustryId) |translate}}</h5>
								</div>
								<!-- Amount -->
								<div class="multistep__responsive--summary justify-content-center flex-column align-items-center p-15 d-lg-none w-100" >
									<h2 class="fw-bold bk-amount"> <bk-amount-display amount="0" [isZero]="true"></bk-amount-display></h2>
								</div>
							</div>
						</div>
						<!-- Responsive > 991 (Amount) -->
						<div [id]="section?.header_summary?.id" class="d-inline-flex multistep__header--summary bg-white bk-form-multi-header-summary" *ngIf="section?.header_summary && section?.header_summary?.total">
							<div class="d-flex justify-content-between align-items-center w-100 bk-form-sum-total">
								<h5 class="mb-0 fw-bold text-muted text-uppercase bk-form-sum-total-label w-50">{{section?.header_summary?.total}}</h5>
								<h4 class="fw-bold d-flex mb-0 text-secondary bk-form-sum-total-value justify-content-end w-50"> <bk-amount-display amount="0" [isZero]="true"></bk-amount-display></h4>
							</div>
						</div>
					</div>
					<!-- Content -->
					<div [id]="section?.multi_sel_ind?.id" class="multistep__content tjs-transition--base" *ngIf="section?.multi_sel_ind">
						<div class="multistep__content--main tjs-animate-fadedown">
							<div class="pt-40 p-md-50 pt-lg-50">
								<div class="container">
									<div class="col-12 col-md-9 mx-auto">
										<!-- Title & desc -->
										<div class="text-center mb-50" *ngIf="section?.multi_sel_ind?.title || section?.multi_sel_ind?.desc">
											<h3 [id]="section?.multi_sel_ind?.title_id" class="display-5 fw-bold mb-0 bk-form-heading" *ngIf="section?.multi_sel_ind?.title" [innerHTML]="section?.multi_sel_ind?.title | safeHtml"></h3>
											<div class="mt-20" *ngIf="section?.multi_sel_ind?.desc">
												<p [id]="section?.multi_sel_ind?.desc_id" class="fs-20 mb-0 bk-form-desc" [innerHTML]="section?.multi_sel_ind?.desc | safeHtml"></p>
											</div>
										</div>
										<!-- Industries -->
										<div *ngIf="selectedIndustryId; else noIndustry">
											<ng-container *ngIf="showPrequalifier">
												<label *ngIf="section?.multi_sel_ind?.ind_label" [id]="section?.multi_sel_ind?.ind_label_id" class="form-label bk-form-label" [innerHTML]="section?.multi_sel_ind?.ind_label | safeHtml"></label>
												<!-- Industry tab -->
												<div class="mb-20" *ngIf="industries && industries.length > 0">
													<div class="d-md-flex mx-n10 flex-wrap tjs-hover-primary">
														<ng-container *ngFor="let industry of industries;trackBy: secServ.trackByFnIndex">
															<div class="tjs-transition--base flex-grow-1 mb-10 text-center px-10" [ngClass]="{'input--checked' : +selectedIndustryId == +industry?.id }">
																<div class="position-relative d-block p-0 h-100 bk-form-multi-tab">
																	<input id="industry-{{industry?.id}}" class="invisible form-check-input bk-form-check position-absolute start-5 top-5" type="radio" name="selected-industry" [value]="industry?.industry_slug" [(ngModel)]="bookingVar.industrySlug" (change)=industryChange(industry) />
																	<label for="industry-{{industry.id}}" class="justify-content-center flex-column d-flex btn btn-outline-primary p-25 fw-bold text-dark border h-100 bk-form-multi-tab-label">
																		<div class="mb-15">
																			<img loading="auto" [src]="utilServ.getImgUrl(industry?.photo_url)" width="50" height="50" alt="Industry icons" class="img-fluid tjs-object_fit" (error)="utilServ.handleImgError($event, initServ?.img?.KoalaIcon)"/>
																		</div>
																		<div>{{(industry?.custom_industry_name ? industry?.custom_industry_name : industry?.industry_name) |translate }}</div>
																	</label>
																</div>
															</div>
														</ng-container>
													</div>
												</div>
												<!-- Combine form questions -->
												<div *ngIf="showMultiFormBlock && ((industryData && industryData?.combined_combination && (industryData?.combined_combination).length > 0) || (industryData && industryData?.single_combination && (industryData?.single_combination).length > 0))">
													<ng-container *ngIf="industryData && industryData?.combined_combination  && (industryData?.combined_combination).length > 0; else singleCombination">
														<!-- Question -->
														<label class="form-label mb-25 bk-form-label" *ngIf="industryData?.combined_combination[0]?.question"> {{industryData?.combined_combination[0]?.question |translate}}</label>
														<!-- Options -->
														<ng-container *ngIf="industryData?.combined_combination[0]?.prequalifier_data && (industryData?.combined_combination[0]?.prequalifier_data).length > 0">
															<div class="form-check mb-15 tjs-mb-last-0" *ngFor="let option of industryData?.combined_combination[0]?.prequalifier_data;trackBy: secServ.trackByFnIndex">
																<input id="{{option?.options}}" class="form-check-input" type="radio" name="form_id" value="{{option?.id}}" (change)="combineOptionChange(option)" [attr.checked]="combineDefaultChecked(option)" />
																<label for="{{option?.options}}" class="form-check-label  bk-form-check-label">{{option?.options |translate}}</label>
															</div>
														</ng-container>
														<!-- Selected combination -->
														<ng-container *ngIf="selectedCombination && selectedCombination?.prequalifier_data && (selectedCombination?.prequalifier_data).length > 0">
															<ng-container *ngTemplateOutlet="singleMultiCombine; context:{title: selectedCombination?.question, options: selectedCombination?.prequalifier_data}"></ng-container>
														</ng-container>
													</ng-container>
													<!-- Single combination -->
													<ng-template #singleCombination>
														<ng-container *ngIf="industryData && industryData?.single_combination  && (industryData?.single_combination).length > 0 && industryData?.single_combination[0]?.prequalifier_data && (industryData?.single_combination[0]?.prequalifier_data).length > 0">
															<ng-container *ngTemplateOutlet="singleMultiCombine; context:{title: industryData?.single_combination[0]?.question, options: industryData?.single_combination[0]?.prequalifier_data}"></ng-container>
														</ng-container>
													</ng-template>
												</div>
											</ng-container>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- Next button -->
						<div *ngIf="section?.multi_next_btn" class="multistep__next multistep__footer text-center pb-25 position-fixed end-0 start-0 bottom-0 bg-transparent pe-none" [ngClass]="{'d-none': multiStepFrmFirstStep, 'position-fixed': !(formSett && formSett?.next_previous_button_scroll && formSett?.next_previous_button_scroll == 'yes')}" >
							<bk-navigate [innerHTML]="section?.multi_next_btn?.content" [secSett]="section?.multi_next_btn" customClass="btn btn-lg btn-primary fs-20 border-transparent" essentialClass="bk-custom-nxt-btn pe-auto" (callback)="showMultiStepForm(true)"></bk-navigate>
						</div>
					</div>
				</div>
				<!-- Forms set for industries -->
				<div *ngIf="industries && industries.length > 0">
					<ng-container *ngFor="let industry of industries;trackBy: secServ.trackByFnIndex">
						<div [ngClass]="{'d-none': !multiStepFrmFirstStep}" *ngIf="industry.id == selectedIndustryId">
							<ng-container *ngIf="formLayout == 'multi_step'">
								<ng-container *ngTemplateOutlet="multiStepFormTemp"></ng-container>
							</ng-container>
						</div>
					</ng-container>
				</div>
			</div>
		</ng-template>
	</div>
</ng-container>
<!-- No industry -->
<ng-template #noIndustry>
	<div class="row">
		<h3 translate>{{initServ?.appStr?.text?.underMaintenance | translate}}</h3>
	</div>
</ng-template>

<!-- Single combination/ selected combination template -->
<ng-template #singleCombine let-title="title" let-options="options">
	<div class="pt-30 pb-10 px-md-30">
		<!-- Question -->
		<div class="mb-25" *ngIf="title">
			<h4 class="mb-0 bk-form-title">{{title |translate}}</h4>
		</div>
		<!-- Options -->
		<ng-container *ngIf="options && options.length > 0">
			<div class="form-check mb-20" *ngFor="let option of options;trackBy: secServ.trackByFnIndex">
				<input id="{{option?.options}}" class="form-check-input" type="radio" name="comb_form_id" value="{{option?.id}}" (change)="combineOptionChange(option, 'single')" [attr.checked]="(+selectedFormId == +option?.form_id) ? true : null" />
				<label class="form-check-label bk-form-check-label" for="{{option?.options}}">{{option?.options |translate}}</label>
			</div>
		</ng-container>
	</div>
</ng-template>
<!-- Single combination/ selected combination template(multiple step) -->
<ng-template #singleMultiCombine let-title="title" let-options="options">
	<!-- Question -->
	<label class="form-label mt-15 mb-25 bk-form-label" *ngIf="title">{{title |translate}}</label>
	<!-- Options -->
	<ng-container *ngIf="options && options.length > 0">
		<div class="form-check mb-15 tjs-mb-last-0" *ngFor="let option of options;trackBy: secServ.trackByFnIndex">
			<input id="{{option?.options}}" class="form-check-input" type="radio" name="comb_form_id" value="{{option?.id}}" (change)="combineOptionChange(option, 'single')" [attr.checked]="(+selectedFormId == +option?.form_id) ? true : null" />
			<label class="form-check-label bk-form-check-label" for="{{option?.options}}">{{option?.options |translate}}</label>
		</div>
	</ng-container>
</ng-template>

<!-- Site logo -->
<ng-template #logoTemp>
	<ng-container *ngIf="(utilServ?.siteLogo?.media && (utilServ?.siteLogo?.media).length > 0); else defaultLogo">
		<bk-navigate essentialClass="bk-logo p-0 text-capitalize" [secSett]="utilServ?.siteLogo?.link" innerHTML="<img loading='lazy' id='{{utilServ?.siteLogo?.media_id}}' width='' height='' class='multistep__responsive--logo-image img-fluid' src='{{utilServ?.siteLogo?.media[0]}}' alt='Your Logo' />"></bk-navigate>
	</ng-container>
	<ng-template #defaultLogo>
		<bk-navigate essentialClass="multistep-header_logo-text fw-bold bk-logo p-0 text-capitalize" [secSett]="utilServ?.siteLogo?.link" innerHTML="{{initServ?.appStr?.text?.yourLogo | translate}}" [isText]="true"></bk-navigate>
	</ng-template>
</ng-template>

<!-- Single form template -->
<ng-template #singleFormTemp>
	<bk-single-form id="booking-form-wrap" class="{{bookingVar?.industrySlug}}" [secId]="secId" [pageSett]="pageSett" [industryId]="selectedIndustryId" [formId]="selectedFormId" [section]="section" [formSett]="formSett" [couponCode]="couponCode" [couponData]="couponData" [formMetaData]="formMetaData" [noOfIndustries]="noOfIndustries" [parentPageSett]="parentPageSett"></bk-single-form>
</ng-template>

<!-- Two step form template -->
<ng-template #twoStepFormTemp>
	<bk-two-step-form id="booking-form-wrap" class="{{bookingVar?.industrySlug}}" [secId]="secId" [pageSett]="pageSett" [industryId]="selectedIndustryId" [formId]="selectedFormId" [section]="section" [formSett]="formSett" [couponCode]="couponCode" [couponData]="couponData" [formMetaData]="formMetaData" (secondStepActivate)="showPrequalifier = $event" [noOfIndustries]="noOfIndustries" [parentPageSett]="parentPageSett"></bk-two-step-form>
</ng-template>

<!-- Multi step form template -->
<ng-template #multiStepFormTemp>
	<bk-multi-step-form class="{{bookingVar?.industrySlug}}" [isMultiIndsFrm]="isMultiIndsFrm" [secId]="secId" [pageSett]="pageSett" (setMultiStpFrmFrstStp)="showMultiStepForm($event)" [industryId]="selectedIndustryId" [formId]="selectedFormId" [section]="section" [formSett]="formSett" [couponCode]="couponCode" [couponData]="couponData" [formMetaData]="formMetaData" [noOfIndustries]="noOfIndustries" [parentPageSett]="parentPageSett"></bk-multi-step-form>
</ng-template>
