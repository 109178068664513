import { Component, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../Services';
// Inheritance components
import { BkngSummaryComponent } from '../../../Global/BookingForm/Sidebar';

@Component({
	selector: 'bk-short-frm-summary',
	templateUrl: './ShortFrmSummary.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ShortFrmSummaryComponent extends BkngSummaryComponent {
	@Input() variationId: string = '';
	@Input() sectionIds: any;
}
