import { Component, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
// Services
import { NgOnDestroy, ApiServ, InitServ, PopupServ } from '../Services';

@Component({
	selector: 'bk-quote-redirection',
	template: ``,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class QuoteRedirectionComponent implements OnInit {
	// variables
	quoteId: any;
	appData: any = this.initServ.appData; // App data

	constructor(private actRoute: ActivatedRoute, @Self() private destroy: NgOnDestroy, private apiServ: ApiServ, private initServ: InitServ,  private router: Router, private popupServ: PopupServ) {
		// Quote id & share link
		this.quoteId = this.actRoute.snapshot.params['qid'] ? (+this.actRoute.snapshot.params['qid']) : '';
		if(this.quoteId && this.quoteId > 0){
			this.markedQuote();
			this.quote();
		}
	}

	ngOnInit(): void {}
	/**
	 * Marked quote as open
	 */
	private markedQuote(): void {
		this.apiServ.callApiWithPathVariables('GET', 'MarkQuoteAsOpen', [this.quoteId]).pipe(takeUntil(this.destroy)).subscribe();
	}
	/**
	 * Get the quote
	 */
	private quote(): void {
		this.apiServ.callApiWithPathVariables('GET', 'Quote', [this.quoteId]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
	}
	/**
	 * On result callback method
	 * @param res API res
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			let booking = res.data;
			let indusSlug: string = '';
			if(this.appData && this.appData.industries && (this.appData.industries).length > 0){
				for(let industry of this.appData.industries){
					if(industry.id == booking.industry_id){
						indusSlug = industry.industry_slug;
						break;
					}
				}
			}
			// Redirect to booknow with query params
			if(indusSlug){
				this.router.navigate(['/'+this.initServ.appDynamicRoutes['booknow']+'/'+indusSlug],{ queryParams: { formid: booking.form_id, qid: booking.quote.id } });
			}
		} else {
			// Open the message popup and redirect to booknow
			// this.popupServ.msgPopup(res.message, true).pipe(takeUntil(this.destroy)).subscribe(() => {
			// 	this.router.navigate(['/'+this.initServ.appDynamicRoutes['booknow']]);
			// });
			this.popupServ.newMsgPopup(res.message).pipe(takeUntil(this.destroy)).subscribe(() => {
				this.router.navigate(['/'+this.initServ.appDynamicRoutes['booknow']]);
			});
		}
	}
}