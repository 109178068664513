import { Injectable } from '@angular/core';
import { CalCmnFuncServ } from './CalCmnFunc.service';

@Injectable({
	providedIn: 'root'
})
export class ExtraAndExcludeCalServ {

	constructor(private cmnFunc: CalCmnFuncServ) {}

	/**
	 * Function takes three parameters:
	 * @param BKFrmValue, which is an object holding the values for the calculations.
	 * @param settingsObj, which is an object holding the settings for the calculations.
	 * @param type, which is a string indicating the type of calculation to be performed (default is 'all').
	 * It checks if 'partial_cleaning' property of BKFrmValue is an array with a length using the checkArrLength method from the cmnFunc object.
	 * If it is, the function enters a loop and performs calculation on each exclusion in the array using the calcExcludeFirstOnlyAndAll method.
	 * Inside the loop, the price and time values of each exclusion are added to the excludeValue and totalTimeValue variables respectively.
	 * Finally, the function returns an object with the calculated price and time values.
	 * @return object
	 */
	public calcValueExcludes(BKFrmValue: any, settingsObj: any, type: string = 'all'): any {
		let excludeValue = 0;
		let totalTimeValue = 0;
		let Excludes = BKFrmValue?.partial_cleaning;
		// If array has length
		if(this.cmnFunc.checkArrLength(Excludes)){
			// Loop on selected exclude
			for(let exclude of Excludes){
				let excludeParamObj = this.calcExcludeFirstOnlyAndAll(exclude, BKFrmValue, settingsObj, type);
				//price addition
				excludeValue += excludeParamObj.price;
				// time addition
				totalTimeValue += excludeParamObj.time;
			}
		}
		return {
			price: excludeValue,
			time : totalTimeValue
		};
	}
	/**
	 * Function calculates the price and time for a selected "exclude" item based on provided parameters.
	 * Firstly checks if type is either "first-only" and the "apply_on_bookings" property of "exclude" is also "first-only", or if the type is "all".
	 * If this condition is met and the "settingsObj" has a property with the ID of "exclude",
	 * it proceeds to calculate the price and time based on the provided inputs.
	 * The calculated price and time are then returned as an object.
	 * @param exclude
	 * @param BKFrmValue
	 * @param settingsObj
	 * @returns
	 */
	public calcExcludeFirstOnlyAndAll(exclude: any, BKFrmValue: any, settingsObj: any, type: string){
		let price = 0;
		let time = 0;
		if(((type == 'first-only' && exclude?.apply_on_bookings == 'first-only') || type == 'all') && settingsObj[exclude?.id]){
			let selectedExclude = settingsObj[exclude.id];
			// calculate exclude time price
			let excludeParamObj = this.calcExcludeTimePrice(BKFrmValue, selectedExclude, exclude);
			//price addition
			price = excludeParamObj.price;
			// time addition
			time = excludeParamObj.time;
		}
		return {
			price,
			time,
		};
	}
	/**
	 * Function calculates the price and time values based on the location type and selected exclude.
	 * It selects the price and time values based on the location type (ML or SA) and calculates the exclude value by multiplying the price with the quantity.
	 * The total time value is calculated by multiplying the time with the quantity.
	 * The function returns an object with the calculated price and time values
	 * @param BKFrmValue: form control values
	 * @param selectedExclude: exclude
	 * @param exclude : exclude from form control
	 * @returns object
	 */
	public calcExcludeTimePrice(BKFrmValue: any, selectedExclude: any, exclude: any): any {
		// select price value on locatin type based
		let excludePrice =  BKFrmValue?.location_type == 'ML' ? (+selectedExclude.price_ml) : (+selectedExclude.price_sa);
		// select time value on locatin type based
		let excludeTime =  BKFrmValue?.location_type == 'ML' ? (+selectedExclude.time_ml) : (+selectedExclude.time_sa);
		//price
		let excludeValue = (+excludePrice)*(+exclude?.quantity);
		//time
		let totalTimeValue = ((+excludeTime)*(+exclude?.quantity));
		return {
			price : this.cmnFunc.roundToTwo(+excludeValue),
			time : totalTimeValue
		};
	}

	/*****************All Forms extras calculation functions*************************************/

	/**
	 * Function called "calcValueExtras" that calculates the price and time for a selected type of extra.
	 * It takes in the booking form values, the settings object, the type of extra to calculate, and the type of extras to calculate.
	 * It returns an object that contains the calculated price and time.
	 * @param BKFrmValue: form control values
	 * @param settingsObj: settings obj
	 * @param  extraType: exempt or empty
	 * @returns object
	 */
	public calcValueExtras(BKFrmValue: any, settingsObj: any, extraType : string = "", type: string = 'all'): any {
		let price = 0;
		let time = 0;
		let Extras = BKFrmValue?.extras;
		// If array has length
		if(this.cmnFunc.checkArrLength(Extras)){
			// Call loopOnExtras func to loop over extras
			let obj : any ={
				extraType : extraType,
				type : type
			}
			let extraObj = this.loopOnExtras(Extras, BKFrmValue, settingsObj, obj);
			price += extraObj.price;
			time += extraObj.time;
		}
		return {
			price : price ? price : 0,
			time,
		};
	}
	/**
	 * Function loopOnExtras calculates the total price and time for selected extras. It takes in the following parameters:
	 * @param extras: array of selected extras
	 * @param BKFrmValue: value of BKFrm
	 * @param settingsObj: object with settings for each extra
	 * @param extraInptObj: object with extra type and type
	 * It gets the values of extra type and type from the extraInptObj parameter.
	 * Next, the function loops through each selected extra.
	 * If the settingsObj exists and contains the current extra's ID, it proceeds to calculate the extra's price and time based on the type.
	 * If the type is 'first', the function calls the calcExtraBasedApplyTo function to calculate the extra's price and time.
	 * If the type is 'without-first', the function sets the 'flag' property of the obj variable to false and
	 * calls the calcExtraTypeBased function to calculate the extra's price and time.
	 * For any other type, the function calls the calcExtraTypeBased function to calculate the extra's price and time.
	 * Finally, the function updates the total price and time by adding the calculated price and time for each extra. It returns an object containing the total price and time.
	 * @returns
	 */
	private loopOnExtras(extras: any, BKFrmValue: any, settingsObj: any, extraInptObj : any){
		let price = 0;
		let time = 0;
		let extraType = extraInptObj?.extraType;
		let type = extraInptObj?.type;
		// Loop on selected extras
		for(let extra of extras){
			if(settingsObj && settingsObj[extra?.id]){
				// Calculate extra time price based on type
				let extraObj = {price: 0, time: 0};
				let obj = {
					extraType : extraType,
					flag: true
				}
				switch(type){
					case('first'):
						extraObj = this.calcExtraBasedApplyTo(extra, BKFrmValue, settingsObj);
						break;
					case('without-first'):
						obj.flag = false;
						extraObj = this.calcExtraTypeBased(extra, BKFrmValue, settingsObj, obj);
						break;
					default:
						extraObj = this.calcExtraTypeBased(extra, BKFrmValue, settingsObj, obj);
						break;
				}
				price += extraObj.price;
				time += extraObj.time;
			}
		}
		return {
			price,
			time,
		}
	}
	/**
	 * Function calculates the price and time value for a selected extra based on certain conditions and inputs.
	 * The function takes four parameters:
	 * @param extra: represents the selected extra item
	 * @param BKFrmValue: represents the booking form value
	 * @param settingsObj: represents an object with settings data
	 * @param extraObj: represents an object with extra data
	 * The function then checks if the selected extra should be applied to bookings based on certain conditions. If so, it proceeds to calculate the price and time values.
	 * The function also checks if the extra value should be excluded from frequency discount calculations. If it should be, the isExtraExcFromFrq variable is set accordingly.
	 * Next, the calcExtraTimePrice() function is called to calculate the extra time price, and the time value is updated accordingly. If the extra is not excluded from frequency discount calculations, the price value is also updated.
	 * Finally, the function returns an object with the calculated price and time values.
	 * @param extra
	 * @param BKFrmValue
	 * @param settingsObj
	 * @param extraType
	 * @returns
	 */
	private calcExtraTypeBased(extra: any, BKFrmValue: any, settingsObj: any, extraObj: any){
		let price = 0;
		let time = 0;
		let selectedExtra = settingsObj[extra?.id];
		if((extra.apply_on_bookings && extra.apply_on_bookings != 'first-only' && !extraObj.flag) || extraObj.flag){
			// Check if extra value exclude from frequency discount
			let isExtraExcFromFrq =  this.cmnFunc.isExtraExcFromFreqDis(selectedExtra);
			if(extraObj.extraType != 'exempt'){
				isExtraExcFromFrq = !isExtraExcFromFrq;
			}
			// calc extra time price
			let extraValueArray = this.calcExtraTimePrice(BKFrmValue, selectedExtra, extra);
			// time addition
			time += extraValueArray.time;
			if(isExtraExcFromFrq){
				//price addition
				price += extraValueArray.price;
			}
		}
		return {
			price,
			time,
		}
	}
	/**
	 * Function calculates the extra time price based on the parameters passed in. It takes three parameters: BKFrmValue, selectedExtra, and extra.
	 * It checks the location type in the BKFrmValue object.
	 * If the location type is 'ML', it selects the price and time values from the selectedExtra object's prices_ml and times_ml properties.
	 * Otherwise, it selects the price and time values from the prices_sa and times_sa properties.
	 * Next, it checks if the selectedExtra has a manual price/length type using the 'isParamQtyBsedPriceManual' function.
	 * If it does, it retrieves the quantity index from the extra object and calculates the price and time based on the selectedExtraPrice and selectedExtraTime arrays at that quantity index.
	 * If the selectedExtra does not have a manual price/length type, it calculates the price and time based on the selectedExtraPrice and selectedExtraTime arrays at the 0 index, multiplied by the quantity in the extra object.
	 * Finally, the function returns an object with the rounded price and the time value.
	 * @returns amount
	 */
	public calcExtraTimePrice(BKFrmValue: any, selectedExtra: any, extra: any): any {
		let price = 0;
		let time = 0;
		// select price value on locatin type based
		let selectedExtraPrice = BKFrmValue.location_type == 'ML' ? selectedExtra.prices_ml : selectedExtra.prices_sa;
		// select time value on locatin type based
		let selectedExtraTime = BKFrmValue.location_type == 'ML' ? selectedExtra.times_ml : selectedExtra.times_sa;
		// check if selected extra has manual price/length type
		if(this.cmnFunc.isParamQtyBsedPriceManual(selectedExtra)){
			let quantityIndex = (+extra?.quantity) - 1;
			//price
			price += (+selectedExtraPrice[+quantityIndex]);
			//time
			time += (+selectedExtraTime[+quantityIndex]);
		}else{
			//price
			price += (+selectedExtraPrice[0])*(+extra?.quantity);
			//time
			time += (+selectedExtraTime[0])*(+extra?.quantity);
		}
		return {
			price : this.cmnFunc.roundToTwo(+price),
			time,
		};
	}
	/**
	 * Function takes in four parameters: extra, BKFrmValue, settingsObj, and flag.
	 * It retrieves the selected extra from the settingsObj.
	 * It checks if the extra is excluded from the frequency discount using the isExtraExcFromFreqDis function.
	 * It calculates the extra time price using the calcExtraTimePrice function.
	 * It checks if the apply_on_bookings property of the extra is set to 'first-only' and updates the applyFirstOnly variable accordingly.
	 * If the flag parameter is false, it negates the value of applyFirstOnly.
	 * If applyFirstOnly is true, it adds the extra price to the price variable (unless it is excluded from the frequency discount) and adds the extra time to the time variable.
	 * The function then returns an object with the price and time values.
	 * @returns
	 */
	private calcExtraBasedApplyTo(extra: any, BKFrmValue: any, settingsObj: any, flag: boolean = true){
		let price = 0;
		let time = 0;
		let selectedExtra = settingsObj[extra.id];
		// if extra excluded from frequency discount
		let isExtraExcFromFrq =  this.cmnFunc.isExtraExcFromFreqDis(selectedExtra);
		// calculate extra time price
		let extraValueObj = this.calcExtraTimePrice(BKFrmValue, selectedExtra, extra);
		let applyFirstOnly = extra && extra.apply_on_bookings && extra.apply_on_bookings == 'first-only';
		if(!flag){
			applyFirstOnly = !applyFirstOnly;
		}
		if(applyFirstOnly){
			//price addition
			price = !isExtraExcFromFrq ? (price + extraValueObj.price) : (price + 0);
			// time addition
			time += extraValueObj.time;
		}
		return {
			price,
			time,
		}
	}
	/**
	 * Function first initializes the priceValue variable to 0.
	 * It then checks if the selected service is not null and if it is not a price-based custom service.
	 * If the type is 'without-first-only', it calls the calcExtrasSeprateEnable function with the parameters BKFrmValue, settingsObj, 'without-first-only', and 'price'.
	 * If the type is not 'without-first-only', it calls the calcExtrasSeprateEnable function with the parameters BKFrmValue, settingsObj, 'all', and 'price'.
	 * The result is added to the priceValue variable.
	 * Finally, the function returns the priceValue variable.
	 * @param BKFrmValue : form control values
	 * @param settingsObj : settings
	 * @param selectedService : service
	 * @param type : type
	 * @returns number
	 */
	public calcExtrasAsSeprateForPriceBaseOnParam(BKFrmValue: any, settingsObj: any, selectedService: any, type : string = 'all'): number{
		let priceValue = 0;
		// check service custom type based or not
		if(selectedService && !this.cmnFunc.ifPriceBaseOfHSCustom(selectedService)){
			if(type == 'without-first-only'){
				// calculate extras(whose add to charge as seprate option enable) for first only
				priceValue  = priceValue + this.calcExtrasSeprateEnable(BKFrmValue, settingsObj, 'without-first-only', 'price');
			}else{
				priceValue = priceValue + this.calcExtrasSeprateEnable(BKFrmValue, settingsObj, 'all', 'price');
			}
		}
		return priceValue;
	}
	/**
	 * Function calculates the total value of selected extras based on the input parameters.
	 * It takes in the BKFrmValue object, settingsObj object, type (default value is 'all'), and outputType as parameters.
	 * The function initializes the outputValue variable to 0 and gets the extras from the BKFrmValue object.
	 * If the length of the Extras array is not 0, the function enters a loop to iterate over the selected extras.
	 * For each extra, it checks if it is separate and applicable based on the settingsObj, outputType, and type.
	 * If it is, the function retrieves the selectedExtra value from the settingsObj and calculates the extraValueObj using the calcExtraTimePrice function.
	 * Depending on the outputType, the function adds the time or price value of the extraValueObj to the outputValue.
	 * After iterating over all selected extras, the function returns the final outputValue.
	 * Overall, this function calculates the total value of selected extras based on the given conditions.
	 * @param BKFrmValue : form control values
	 * @param settingsObj : settings
	 * @param type : type
	 * @returns number
	 */
	public calcExtrasSeprateEnable(BKFrmValue: any, settingsObj: any, type: string = 'all', outputType: string): number{
		let outputValue = 0;
		let Extras = BKFrmValue?.extras;
		if(this.cmnFunc.checkArrLength(Extras)){
			// Loop on selected extras
			for(let extra of Extras){
				if(this.cmnFunc.isExtraSeprateAndAll(extra, settingsObj, outputType, type)){
					let selectedExtra = settingsObj[extra.id];
					// calculate extra price
					let extraValueObj = this.calcExtraTimePrice(BKFrmValue, selectedExtra, extra);
					if(outputType == 'time'){
						//time addition
						outputValue += extraValueObj.time;
					}else{
						//price addition
						outputValue += extraValueObj.price;
					}
				}
			}
		}
		return outputValue;
	}
}