import { Component, Input, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, ApiServ, InitServ, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-blog-grid-section',
	templateUrl: './BlogGridSection.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class BlogGridSectionComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	@Input() settings: any;
	@Input() slug: string = 'blog';
	@Input() secFields: any;
	@Input() custClass: string = '';
	pageSlug: string = 'blog';
	pageSett: any;
	blogs: any;
	// Section fields
	section: any = {
		title: null
	}
	sectionData: any;

	constructor(private actRoute: ActivatedRoute,public rcServ: RenderComponentServ, public secServ: SectionServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, public initServ: InitServ, public utilServ: UtilServ) {
		this.pageSlug = this.actRoute.snapshot.params['type'] ? this.actRoute.snapshot.params['type'] : 'blog';
	}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
		if(!this.settings){
			this.blogSettings();
		}
		this.getBlogs();
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
	}
	/**
	 * Get the blogs from content management app
	 */
	private getBlogs(): void {
		let count: number = 3;
		// How many blogs get by api depend on page settings count
		if(!this.pageSett && this.pageSett[this.secId] && this.pageSett[this.secId].count){
			count = this.pageSett[this.secId].count;
		}
		let queryParams: any = {status:'publish', limit:count, language:this.initServ.savedLng, popular: 'yes'};
		this.apiServ.callApiWithQueryParams('GET', 'Blogs', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'blogs'));
	}
	/**
	 * Get the blog settings, which is get by content management app
	 */
	private blogSettings(): void {
		this.apiServ.callApi('GET', 'BlogsSett').pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'sett'));
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res:any, type: string): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			switch(type){
				case 'blogs':
					this.blogs = res.data;
				break;
				case 'sett':
					this.settings = res.data;
				break;
			}
		}
	}
}
