import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// Services
import { NgOnDestroy, SectionServ, RenderComponentServ, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-discount-popup',
	templateUrl: './DiscountPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class DiscountPopupComponent implements OnInit {

	@Input() secId: string = '';
	@Input() popupId: any;
	popupData: any;
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		button: null
	}
	poweredByLink: string = '';

	constructor(public dialogRef: MatDialogRef<DiscountPopupComponent>,public secServ: SectionServ, public rcServ: RenderComponentServ, private utilServ: UtilServ) {
	}

	ngOnInit(): void {
		if(this.secId && this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.popupData = this.rcServ.popupData[this.popupId];
			this.buildSection();
		}
	}
	/**
	 * Build section
	 */
	private buildSection(): void {
		this.pageSett = this.popupData.section_settings;
		this.utilServ.popupPosition(this.pageSett[this.secId].variation_id, this.popupData.settings, this.dialogRef);
		this.secServ.setServData(this.pageSett, this.popupData.content);
		let secElem : any = null;
		if(this.popupData.sections){
			secElem = this.popupData.sections[this.secId];
		}
		if(secElem && this.pageSett && this.pageSett[this.secId] && this.pageSett[this.secId][this.rcServ.statusType]){
			for(let key in this.section){
				if(secElem[key]){
					switch (key) {
						case "button":
							this.section[key] =  this.secServ.buildMultiButtons(secElem[key]);
							break;
						default:
							this.section[key] = this.secServ.buildText(secElem[key]);
							break;
					}
				}
			}
		}
	}
}