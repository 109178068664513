<h4 class="text-center mb-40 fw-bold display-only-for-multistep" [id]="calSection?.sel_time_id">{{calSection?.sel_time}}</h4>
<div class="tjs-bcalendar__slot-wrap" [id]="calSection?.id" >
	<div class="row mx-n5 tjs-animate-fadeup tjs-scrollbar" style="overflow-x: hidden;"  *ngIf="perDateAvailSpots && perDateAvailSpots[selectedDate] && (perDateAvailSpots[selectedDate]).length > 0">
		<ng-container *ngFor="let slot of perDateAvailSpots[selectedDate]">
			<div class="col-12 col-sm-4 col-md-3 col-lg-2 my-5 px-5" (click)="clickMultiSlotChange(slot, selectedDate)">
				<div class="border rounded p-10 d-flex align-items-center flex-column justify-content-center tjs-pointer h-100 text-center tjs-transition--base tjs-bcalendar__slot bk-slot" [ngClass]="{'tjs-bcalendar__slot--active bk-slot-active': (slot == BKFrm.value.arrival_time && BKFrm.value.booking_date == selectedDate)}">
					<span class="fw-bold dir-ltr">{{scheduleServ.buildSpotName(bookingSpots,slot,selectedDate)}}</span>
					<span class="fs-13 opacity-75 mt-5" *ngIf="scheduleServ.showSlotDiscount(selectedDate, slot , dateDiscountsArray, dayDiscountsArray, dayDiscountBookings, prefilledData)" [innerHTML]="scheduleServ.showSlotDiscount(selectedDate, slot , dateDiscountsArray, dayDiscountsArray, dayDiscountBookings, prefilledData) | safeHtml"></span>
				</div>
			</div>
		</ng-container>
	</div>
</div>
