<ng-container *ngIf="data && data.length > 0">
	<ng-container *ngIf="isSlider else noSlider">
		<div class="tjs-extra-slider tjs-extra mx-0 position-relative">
			<div class="keen-slider" #extraSlide [attr.dir]="initServ.selectedLang?.rtl ? 'rtl' :'ltr'">
				<div class="keen-slider__slide" *ngFor="let val of data">
					<div class="text-center mx-5 tjs-extra-slide--sm">
						<ng-container *ngTemplateOutlet="eleTemp; context:{val:val}"></ng-container>
					</div>
				</div>
			</div>
			<ng-container *ngIf="((windowWidth >= 768) && (data.length > 3)) || ((windowWidth >= 451) && (windowWidth <= 767) && (data.length > 2)) || ((windowWidth <= 450) && (data.length > 1))">
				<button (click)="slider.prev()" type="button" class="btn btn-link tjs-prev-arrow text-body start-0 tjs-pointer position-absolute tjs-hover-text__primary slider-arrow"><i class="tjsicon-left-arrow"></i></button>
				<button (click)="slider.next()" type="button" class="btn btn-link tjs-next-arrow end-0 text-body position-absolute tjs-pointer tjs-hover-text__primary slider-arrow"><i class="tjsicon-right-arrow"></i></button>
			</ng-container>
		</div>
	</ng-container>
	<ng-template #noSlider>
		<div class="tjs-extra mx-n10 flex-wrap d-flex" [ngClass]="isMultiStepForm ? 'tjs-extra--lg' :''" *ngIf="data && data.length > 0">
			<div class="tjs-extra__list text-center mb-20 px-10" *ngFor="let val of data">
				<ng-container *ngTemplateOutlet="eleTemp; context:{val:val}"></ng-container>
			</div>
		</div>
	</ng-template>
</ng-container>
<!-- Common template -->
<ng-template #eleTemp let-val="val">
	<div class="border rounded-2 position-relative mb-5 bk-form-extra" [ngClass]="{'selected-extra': selectedElements[val?.id]}">
		<label class="d-flex tjs-pointer justify-content-center align-items-center h-100 w-100 mb-0" for="{{controlName}}-{{val?.id}}">
			<!-- Checkbox -->
			<input id="{{controlName}}-{{val?.id}}" class="form-check-input bk-form-extra-input" type="checkbox" (change)="selectElement($event,val)" [attr.disabled]="isDisabled" [attr.checked]="selectedElements[val?.id] ? 'checked' : null">
			<!-- Image -->
			<span class="tjs-extra__img m-auto">
				<img loading="auto" width="21" height="20" [src]="utilServ.getImgUrl(val?.icon_url)" alt="extras" class="img-fluid" (error)="utilServ.handleImgError($event, initServ?.img?.KoalaIcon)" />
			</span>
			<!-- Count down -->
			<span class="card-img-overlay tjs-transition--base tjs-overlay__dark d-flex align-items-center rounded-2 p-0 bk-form-extra-overlay">
				<ng-container *ngIf="val?.enable_quantity_based == 'yes' && selectedElements[val?.id]">
					<!-- Decrement -->
					<button class="tjs-extra__counter tjs-transition--base p-0 tjs-extra__decrement bk-form-extra-dec d-flex justify-content-center align-items-center border-0 text-white h-100" type="button" (click)="countChange(val, selectedElements[val?.id]['index'], 'decrement')" [attr.disabled]="isDisabled">-</button>
					<!-- Value -->
					<input class="tjs-extra__number text-center border-0 tjs-transition--base text-white bg-transparent bk-form-extra-desc" type="text" [value]="selectedElements[val?.id]?.quantity" readonly [attr.disabled]="isDisabled">
					<!-- Increment -->
					<button class="tjs-extra__counter tjs-transition--base p-0 tjs-extra__increment bk-form-extra-inc d-flex justify-content-center align-items-center border-0 text-white h-100" type="button" (click)="countChange(val, selectedElements[val?.id]['index'], 'increment')" [attr.disabled]="isDisabled">+</button>
				</ng-container>
			</span>
		</label>
	</div>
	<p class="fs-14 mb-0 bk-form-sub-label">
		<span [id]="sectionIds?.label ? (sectionIds?.label) : ''">{{utilServ.getFormParamName(val) | translate}}</span>
		<span *ngIf="['reschedule', 'draft'].includes(bookingType) && val?.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
		<!-- Tooltip -->
		<bk-tooltip *ngIf="val?.show_tooltip_icon && val?.tooltip_text" customClass="tjs-tooltip-icon--sm bk-tooltip" [content]="val?.tooltip_text | translate"></bk-tooltip>
	</p>
</ng-template>