import { Component, Input, OnInit, ViewEncapsulation, Self, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild,ElementRef } from '@angular/core';
import { takeUntil } from 'rxjs';
// External library
import KeenSlider from "keen-slider";
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, UtilServ, ApiServ } from '../../Services';

@Component({
	selector: 'bk-reviews',
	templateUrl: './Reviews.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class ReviewsComponent implements OnInit {

	@Input() secId : string = '';
	@Input() popupId: string = '';
	@ViewChild("reviewSlide") sliderRef : ElementRef<HTMLElement> | undefined;
	// Variables
	pageSett: any;
	admnStngs: any;
	isReviews: boolean = false;
	custDetail: string = '';
	reviews: any = [];
	reviewsLimit: number | string = 30;
	modalData:any;
	// Section fields
	section: any = {
		review: null,
		review_card: null
	}
	slider: any = null;
	// Slider configuration
	slideConfig = {
		slides: { perView: 1 },
		rtl:(this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? true : false,
		loop:false,
		defaultAnimation:{duration:1000},
	};
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, public  initServ: InitServ, public utilServ: UtilServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy,private cDRef: ChangeDetectorRef) {
		this.admnStngs = this.initServ.appAdmnStngs;
		// Set the RTL value
		this.initServ.isRTLChange.pipe(takeUntil(this.destroy)).subscribe((value) => {
			this.slideConfig['rtl']=value;
			if(this.slider && this.sliderRef){
				this.slider.update(this.slideConfig);
			}
		});
		if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.customers){
			// Review enable
			if(this.admnStngs.merchant_settings?.customers?.enable_live_reviews && this.admnStngs.merchant_settings?.customers?.enable_live_reviews == 'yes'){
				this.isReviews = true;
			}
			// Review customer name settings
			if(this.admnStngs.merchant_settings?.customers?.show_customer_detail){
				this.custDetail = this.admnStngs.merchant_settings?.customers?.show_customer_detail;
			}
		}
	}

	ngOnInit(): void {
		// Build section data
		if(this.utilServ.appPermission('rating') && this.isReviews){
			if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
				this.sectionData = this.rcServ.popupData[this.popupId];
			} else {
				this.sectionData = this.rcServ.pageData;
			}
			// Build section data
			if(this.secId && this.sectionData){
				this.buildSection(this.sectionData);
			}
			this.getReviews();
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
	}
	/**
	 * Reviews api
	 */
	private getReviews(): void {
		let queryParams: any = { limit: this.reviewsLimit };
		this.apiServ.callApiWithQueryParams('GET', 'Reviews', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
	}
	/**
	 * On result callback method
	 * @param res api response
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res)){
			if(res.data && res.data.length > 0){
				this.reviews = res.data;
				if(this.reviews.length > 1){
					this.slideConfig['loop'] = true;
				}
				setTimeout(()=>{
					if(this.sliderRef){
						if (this.slider) {this.slider.destroy();}
						this.slider = new KeenSlider(this.sliderRef.nativeElement,this.slideConfig,[(slider) => this.utilServ.autoPlay(slider,6000)])
					}
				})
			}
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Open modal
	 * @param review
	 */
	public openModal(review: any): void {
		this.modalData = review;
		setTimeout(() => {
			this.utilServ.togglePopup('reviewModal_'+review.id);
		}, 100);
	}

	ngOnDestroy():void {
		if (this.slider) {this.slider.destroy();}
	}
}
