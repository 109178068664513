<div [id]="secId" class="tjs-contact tjs-section tjs-section--space-sm position-relative {{variationClass[varId]}}" [ngStyle]="{'background-image' : (varId != 'bk_contact_us_V4' && section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div [id]="section?.media?.id" *ngIf="varId != 'bk_contact_us_V4' && (section?.media && section?.media?.urls && (section?.media?.urls).length > 0)" class="tjs-opacity--bg"></div>
	<div class="container">
		<div class="row justify-content-center">
			<!-- Media -->
			<div class="col-12 col-lg-5 col-xl-5 position-relative" *ngIf="varId == 'bk_contact_us_V4' && section?.media && section?.media?.urls && (section?.media?.urls).length > 0" >
				<div class="tjs-contact__featured-img start-0 end-0 bottom-0 my-0 mx-auto" [class.position-absolute]="section?.form">
					<img loading="auto" [id]="section?.media?.id" class="img-fluid bk-media" width="700" height="952" [src]="section?.media?.urls[0]" alt="bk-images"/>
				</div>
			</div>
			<!-- Form -->
			<div *ngIf="section?.form" class="mb-30 mb-lg-0 col-12 col-sm-12 bk_elem_parent" [ngClass]="{'col-md-12 col-lg-7 col-xl-7': varId == 'bk_contact_us_V4', 'col-md-12 col-lg-6 col-xl-6' : varId == 'bk_contact_us_V1', 'col-md-8' : varId == 'bk_contact_us_V5'}">
				<div [id]="section?.form?.id" class="tjs-contact__form bk-form position-relative" [ngClass]="{'bk-card' : varId == 'bk_contact_us_V5'}">
					<!-- Title -->
					<ng-container *ngTemplateOutlet="titleTemp; context:{data:section?.form}"></ng-container>
					<form [formGroup]="contactUsForm" bkDebounceEvent [isSubmit]="true" (debounceSubmit)="submitForm()">
						<!-- First name -->
						<div [id]="section?.form?.name?.id" class="mb-30">
							<label [id]="section?.form?.name?.label_id" class="form-label bk-form-label" *ngIf="section?.form?.name?.label" [innerHTML]="section?.form?.name?.label | safeHtml" ></label>
							<input class="form-control bk-input" [ngClass]="{'is-invalid': f['name']?.touched && f['name']?.errors}" [placeholder]="section?.form?.name?.placeholder" formControlName="name" type="text" (focusout)="detailsFocusOut()">
							<!-- Error -->
							<div class="tjs-error" *ngIf="f['name']?.touched && (f['name']?.errors?.required || f['name']?.errors?.pattern)">
								<i class="tjsicon-attention"></i>
								<ng-container *ngIf="f['name']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
								<ng-container *ngIf="f['name']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.specialCharsExceptSpace | translate}} </ng-container>
							</div>
						</div>
						<!-- Email -->
						<div [id]="section?.form?.email?.id" class="mb-30">
							<label [id]="section?.form?.email?.label_id" class="form-label bk-form-label" *ngIf="section?.form?.email?.label" [innerHTML]="section?.form?.email?.label | safeHtml" ></label>
							<input class="form-control bk-input" [ngClass]="{'is-invalid': f['email']?.touched && f['email']?.errors}" [placeholder]="section?.form?.email?.placeholder" formControlName="email" type="text" (focusout)="detailsFocusOut()">
							<!-- Error -->
							<div class="tjs-error" *ngIf="f['email']?.touched && (f['email']?.errors?.required || f['email']?.errors?.pattern)">
								<i class="tjsicon-attention"></i>
								<ng-container *ngIf="f['email']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
								<ng-container *ngIf="f['email']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.validEmail | translate}} </ng-container>
							</div>
						</div>
						<!-- Phone -->
						<div [id]="section?.form?.phone?.id" class="mb-30">
							<label [id]="section?.form?.phone?.label_id" class="form-label bk-form-label" *ngIf="section?.form?.phone?.label" [innerHTML]="section?.form?.phone?.label | safeHtml" ></label>
							<div class="input-group input-group-ltr">
								<div class="input-group-text" *ngIf="initServ?.callingCode">
									<span>{{initServ?.callingCode}}</span>
								</div>
								<input class="form-control bk-input" type="text" [textMask]="{mask: phoneMask}" [placeholder]="section?.form?.phone?.placeholder" formControlName="phone_number" (focusout)="detailsFocusOut()">
							</div>
						</div>
						<!-- Send notification checkbox -->
						<div class="form-check form-check-inline mb-30" *ngIf="phoneNotifStatus">
							<input id="send_sms_notification" class="form-check-input " type="checkbox" [(ngModel)]="sendSmsNotification" [ngModelOptions]="{standalone: true}" (change)="sendSmsNotifChange($event)">
							<label for="send_sms_notification" class="form-check-label bk-form-check-label" [id]="section?.form?.phone?.notification_id" [innerHTML]="((section?.form?.phone?.notification) ? section?.form?.phone?.notification : initServ?.appStr?.text?.sms_reminder) | translate | safeHtml" ></label>
						</div>
						<!-- Message -->
						<div [id]="section?.form?.additional_msg?.id" class="mb-30">
							<label [id]="section?.form?.additional_msg?.label_id" class="form-label bk-form-label" *ngIf="section?.form?.additional_msg?.label" [innerHTML]="section?.form?.additional_msg?.label | safeHtml" ></label>
							<textarea [ngClass]="{'is-invalid': f['message']?.touched && f['message']?.errors}" class="form-control bk-input" [placeholder]="section?.form?.additional_msg?.placeholder" formControlName="message"></textarea>
							<!-- Error -->
							<div class="tjs-error" *ngIf="f['message']?.touched && f['message']?.errors?.required">
								<i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.empty | translate}}
							</div>
						</div>
						<!-- Send label -->
						<label [id]="section?.form?.send_label_id" class="mb-10 fs-16 text-body" *ngIf="section?.form?.send_label" [innerHTML]="section?.form?.send_label | safeHtml"></label>
						<!-- Cookie content -->
						<div class="tjs-privacy-text mb-20 text-body" *ngIf="section?.form?.cookie_content">
							<span class="bk-cookie fs-14" [id]="section?.form?.cookie_content_id" [innerHTML]="section?.form?.cookie_content | safeHtml"></span>
						</div>
						<!-- Submit -->
						<div>
							<bk-navigate  [secId]="section?.form?.button_id" [designId]="section?.form?.button?.design_id" [innerHTML]="section?.form?.button?.content" [isSubmit]="true" essentialClass="{{varId == 'bk_contact_us_V4' && 'w-100'}}" customClass="btn btn-primary border-transparent" ></bk-navigate>
						</div>
					</form>
					<!-- Loader -->
					<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
				</div>
			</div>
			<!-- Get in touch -->
			<div *ngIf="section?.get_in_touch" [id]="section?.get_in_touch?.id" class="col-12 col-sm-12 col-lg-6 col-xl-6">
				<div class="ps-lg-20">
					<!-- Title -->
					<ng-container *ngTemplateOutlet="titleTemp; context:{data:section?.get_in_touch}"></ng-container>
					<!-- List -->
					<div class="tjs-contact__list">
						<ol class="list-unstyled tjs-list">
							<!-- Twitter users-->
							<li [id]="section?.get_in_touch?.twitter_id" class="tjs-contact__list-item border py-20 px-25 mb-30 rounded bk-item" *ngIf="section?.get_in_touch?.twitter && section?.get_in_touch?.twitter?.content">
								<div class="ps-50">
									<span [id]="section?.get_in_touch?.twitter?.content_id" class="text-dark fw-bold d-block bk-item-title" [innerHTML]="section?.get_in_touch?.twitter?.content | safeHtml"></span>
									<span *ngIf="section?.get_in_touch?.twitter?.users && (section?.get_in_touch?.twitter?.users).length > 0">
										<a class="bk-item-desc text-break" [id]="section?.get_in_touch?.twitter?.users_id" *ngFor="let user of section?.get_in_touch?.twitter?.users; let isLast=last;trackBy: secServ.trackByFnIndex" href="{{twitterLink}}{{user}}" >{{user}}{{isLast ? '' : ', '}}</a>
									</span>
								</div>
							</li>
							<!-- Emails -->
							<li [id]="section?.get_in_touch?.email_id" class="tjs-contact__list-item border py-20 px-25 mb-30 rounded bk-item" *ngIf="section?.get_in_touch?.email && section?.get_in_touch?.email?.content">
								<div class="ps-50">
									<span [id]="section?.get_in_touch?.email?.content_id" class="text-dark fw-bold d-block bk-item-title" [innerHTML]="section?.get_in_touch?.email?.content | safeHtml"></span>
									<span *ngIf="section?.get_in_touch?.email?.emails && (section?.get_in_touch?.email?.emails).length > 0">
										<ng-container *ngFor="let email of section?.get_in_touch?.email?.emails; let isLast=last;trackBy: secServ.trackByFnIndex">
											<a class="bk-item-desc text-break" [id]="section?.get_in_touch?.email?.emails_id" href="mailto:{{email}}">{{email}}{{isLast ? '' : ', '}}</a>
										</ng-container>
									</span>
								</div>
							</li>
							<!-- Phone numbers -->
							<li [id]="section?.get_in_touch?.phone_id" class="tjs-contact__list-item border py-20 px-25 mb-30 rounded bk-item" *ngIf="section?.get_in_touch?.phone && section?.get_in_touch?.phone?.content">
								<div class="ps-50">
									<span [id]="section?.get_in_touch?.phone?.content_id" class="text-dark fw-bold d-block bk-item-title" [innerHTML]="section?.get_in_touch?.phone?.content | safeHtml"></span>
									<span *ngIf="section?.get_in_touch?.phone?.phone_no && (section?.get_in_touch?.phone?.phone_no).length > 0">
										<ng-container *ngFor="let phone of section?.get_in_touch?.phone?.phone_no; let isLast=last;trackBy: secServ.trackByFnIndex">
											<bk-phone-number customClass="bk-item-desc text-break" [id]="section?.get_in_touch?.phone?.phone_no_id" [phone]="phone" [hideIcon]="true"></bk-phone-number>{{isLast ? '' : ', '}}
										</ng-container>
									</span>
								</div>
							</li>
							<!-- Chat link -->
							<li [id]="section?.get_in_touch?.chat_id" class="tjs-contact__list-item border py-20 px-25 mb-30 rounded bk-item" *ngIf="section?.get_in_touch?.chat && section?.get_in_touch?.chat?.content">
								<div class="ps-50">
									<span [id]="section?.get_in_touch?.chat?.content_id" class="text-dark fw-bold d-block bk-item-title" [innerHTML]="section?.get_in_touch?.chat?.content | safeHtml"></span>
									<span *ngIf="section?.get_in_touch?.chat?.desc">
										<a class="bk-item-desc text-break" [id]="section?.get_in_touch?.chat?.desc_id" *ngIf="section?.get_in_touch?.chat?.chat_link == '/'; else link" [routerLink]="section?.get_in_touch?.chat?.chat_link" [innerHTML]="section?.get_in_touch?.chat?.desc | safeHtml"></a>
										<ng-template #link>
											<a class="bk-item-desc text-break" [id]="section?.get_in_touch?.chat?.desc_id" [href]="section?.get_in_touch?.chat?.chat_link" [innerHTML]="section?.get_in_touch?.chat?.desc | safeHtml"></a>
										</ng-template>
									</span>
								</div>
							</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Title template -->
<ng-template #titleTemp let-data="data" >
	<div class="mb-35 mb-lg-40" *ngIf="data?.title || data?.sub_title">
		<div class="tjs-section__heading mb-20" *ngIf="data?.title">
			<h4 [id]="data?.title_id" class="display-6 fw-bold bk-title" [innerHtml]="data?.title | safeHtml"></h4>
		</div>
		<div class="tjs-section__description" *ngIf="data?.sub_title">
			<p [id]="data?.sub_title_id" class="bk-sub-title text-body fs-16" [innerHTML]="data?.sub_title | safeHtml"></p>
		</div>
	</div>
</ng-template>
