import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'RmSpclCharAndSpace',
	pure: true
	// standalone: true
})
export class RmSpclCharAndSpacePipe implements PipeTransform {
	/**
	 * Transform the value, remove special characters and spaces
	 * @param value : value
	 * @returns string value
	 */
	public transform(value: string): string {
		if (value == null) return '';
		value = value.replace(/ /g,'');
		value = value.replace(/[^a-zA-Z0-9]/g,'');
		value = value.replace(/\s/g,'');
		return value;
	}
}