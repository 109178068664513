import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'customSearch'
})
export class CustomSearchPipe implements PipeTransform {

	transform(items: any[], filter: Object): any {
		if (!items || !filter) {
			return items;
		}
		return items.filter(item => item?.location?.location_name.toLowerCase().indexOf(filter.toString().toLowerCase()) > -1);
	}
}
