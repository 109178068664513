import { Component, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
// Services
import { NgOnDestroy, ApiServ, InitServ, UtilServ } from '../../../Services';
// Constants
import { BK_SITE_LINK } from '../../../Constants';

@Component({
	selector: 'bk-job-details',
	templateUrl: './JobDetails.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]

})
export class JobDetailsComponent implements OnInit {
	// Variables
	slug: string = '';
	job: any;
	defaultJob: any = {
		title_desc: '',
		banner_status: true,
		banner_img_url: this.initServ.imgBase + this.initServ.img.JobBanner,
		first_icon_status: true,
		first_icon_img_url: this.initServ.imgBase + this.initServ.img.JobClock,
		first_icon_label: this.initServ.appStr.jobParHour,
		second_icon_status: true,
		second_icon_img_url: this.initServ.imgBase + this.initServ.img.JobCash,
		second_icon_label: this.initServ.appStr.paidWeekly,
		third_icon_status: true,
		third_icon_img_url: this.initServ.imgBase + this.initServ.img.JobHeart,
		third_icon_label: this.initServ.appStr.lotsOfBenefits
	}

	constructor(@Self() private destroy: NgOnDestroy,  private actRoute: ActivatedRoute, private apiServ: ApiServ, public initServ: InitServ, private utilServ: UtilServ ) { }

	ngOnInit() {
		this.slug = this.actRoute.snapshot.params['slug'];
		if(this.slug){
			// Called api for get the single job details based on slug
			let queryParams: any = {slug: this.slug, language:this.initServ.savedLng};
			this.apiServ.callApiWithQueryParams('GET', 'Job', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
		}
	}
	/**
	 * On result callback method
	 * @param res api response
	 * API response handler
	 */
	 private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res)){
			this.job = res.data;
			// Set job data
			if(this.job){
				// Title description
				if(('title_description' in this.job) && this.job.title_description != null && (this.job.title_description && (this.job.title_description).length > 0)){
					this.defaultJob['title_desc'] = this.job.title_description;
				} else{
					// title_description build
					let desc = ``;
					if(this.job.title && this.job.title.length > 0){
						desc = desc + `<h3 style="text-align: center;"><span style="color:#293141;"><span style="font-size:36px;">`+this.job.title+`</span></span></h3>`;
					}
					desc = desc + `<p style="text-align: center;"><span style="color:#293141;"><span style="font-size:20px;"><strong>`;
					if((this.job.city && (this.job.city).length > 0) || (this.job.state && (this.job.state).length > 0)){
						if(this.job.city && (this.job.city).length > 0){
							desc = desc + this.job.city;
						}
						if(this.job.state && (this.job.state).length > 0){
							if(this.job.city && (this.job.city).length > 0){
								desc = desc + `, `;
							}
							desc = desc + this.job.state;
						}
						if((this.job.earning_per_hours && (this.job.earning_per_hours).length > 0) || (this.job.hours_per_week && (this.job.hours_per_week).length > 0)){
							desc = desc + `&nbsp; | `;
						}
					}
					if(this.job.earning_per_hours && (this.job.earning_per_hours).length > 0){
						desc = desc + `$`+this.job.earning_per_hours+` Per Hour`;
						if(this.job.hours_per_week && (this.job.hours_per_week).length > 0){
							desc = desc + `&nbsp; | `;
						}
					}
					if(this.job.hours_per_week && (this.job.hours_per_week).length > 0){
						desc = desc + this.job.hours_per_week+` Hours A Week`;
					}
					desc = desc + `</strong></span></span></p>`;
					this.defaultJob['title_desc'] = desc;
				}
				// Images
				this.defaultJob['banner_img_url'] = this.initServ.imgBase + this.getFieldVal('banner_img_url');
				this.defaultJob['first_icon_img_url'] = this.initServ.imgBase + this.getFieldVal('first_icon_img_url');
				this.defaultJob['second_icon_img_url'] = this.initServ.imgBase + this.getFieldVal('second_icon_img_url');
				this.defaultJob['third_icon_img_url'] = this.initServ.imgBase + this.getFieldVal('third_icon_img_url');
				// Content
				if(('first_icon_label' in this.job) && this.job.first_icon_label != null){
					this.defaultJob['first_icon_label'] = this.job.first_icon_label;
				} else{
					if(this.job.earning_per_hours){
						this.defaultJob['first_icon_label'] = '$'+this.job.earning_per_hours+'/hour';
					}
				}
				this.defaultJob['second_icon_label'] = this.getFieldVal('second_icon_label');
				this.defaultJob['third_icon_label'] = this.getFieldVal('third_icon_label');
				// Status
				this.defaultJob['banner_status'] = this.getFieldVal('banner_status');
				this.defaultJob['first_icon_status'] = this.getFieldVal('first_icon_status');
				this.defaultJob['second_icon_status'] = this.getFieldVal('second_icon_status');
				this.defaultJob['third_icon_status'] = this.getFieldVal('third_icon_status');
			}
		}
	}
	/**
	 * Check the field value
	 * @param fieldName
	 * @returns field value/ default value
	 */
	private getFieldVal(fieldName: string): string {
		if((fieldName in this.job) && this.job[fieldName] != null){
			return this.job[fieldName];
		}
		return this.defaultJob[fieldName];
	}
	/**
	 * Redirect to next page
	 */
	public nextPage(): void {
		// Form url
		if(this.job && this.job.jot_form_url && this.job.jot_form_url != ''){
			let url = this.utilServ.checkHttpExist(this.job.jot_form_url)
			window.open(url, '_blank');
		} else{
			// BK site link
			window.location.href = BK_SITE_LINK;
		}
	}
}
