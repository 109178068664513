<div *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div [id]="secId" class="tjs-referral {{variationClass[varId]}}"  [ngStyle]="{'background-image' : varId== 'bk_referral_V1' && (section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}">
		<div class="tjs-opacity--bg" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0 && varId == 'bk_referral_V1'" [id]="section?.media?.id" ></div>
		<div class="container">
			<div class="row justify-content-center align-items-center" *ngIf="varId != 'bk_referral_V4'; else elseV4">
				<!-- V2 & V3 media -->
				<div class="col-12 col-sm-12 col-md-12 col-lg-4" *ngIf="(varId == 'bk_referral_V2' || varId == 'bk_referral_V3') && (section?.media && section?.media?.urls && (section?.media?.urls).length > 0)">
					<div class="tjs-referral__media overflow-hidden rounded-circle mb-15" [ngClass]="{'bg-light me-lg-25 mx-auto ms-lg-auto' : varId == 'bk_referral_V2' || varId == 'bk_referral_V3'}">
						<img loading="auto" width="250" height="250" [id]="section?.media?.id" class="img-fluid" [src]="section?.media?.urls[0]" alt="referral" />
					</div>
				</div>
				<div class="col-12 col-sm-12 col-md-12" [ngClass]="{'col-lg-8 col-xl-8': (varId == 'bk_referral_V2' || varId == 'bk_referral_V3'), 'col-lg-12 col-xl-12 text-center': varId == 'bk_referral_V1'}" *ngIf="section?.title?.content || section?.sub_title?.content || (section?.button && section?.button?.buttons && (section?.button?.buttons).length > 0)">
					<!-- Title -->
					<div class="mb-30 tjs-referral__highlighted" *ngIf="section?.title?.content">
						<span class="text-uppercase letter-spacing-25" [id]="section?.title?.id" [innerHTML]="section?.title?.content | safeHtml"></span>
					</div>
					<!-- Sub title -->
					<div class="tjs-referral__title" *ngIf="section?.sub_title?.content">
						<h3 [id]="section?.sub_title?.id" class="tjs-referral__subheading text-capitalize fw-bold mb-30 {{varId == 'bk_referral_V2'  || varId == 'bk_referral_V3' ? 'display-5' : 'display-3'}}" [innerHTML]="section?.sub_title?.content | safeHtml"></h3>
					</div>
					<!-- Button -->
					<ng-container *ngTemplateOutlet="btnTemp; context:{section:section,class:'' }"></ng-container>
				</div>
			</div>
			<!-- Variation v4 -->
			<ng-template #elseV4>
				<ng-container *ngIf="(section?.list && section?.list?.items && (section?.list?.items).length > 0) || section?.title?.content || (section?.button && section?.button?.buttons && (section?.button?.buttons).length > 0)">
					<!-- List -->
					<div class="row justify-content-center"[id]="section?.list?.id"  *ngIf="section?.list && section?.list?.items && (section?.list?.items).length > 0">
						<ng-container *ngFor="let item of section?.list?.items let i=index;trackBy: secServ.trackByFnIndex">
							<div [id]="item?.id" class="col-12 col-sm-12 col-md-4 my-20 bk-item" [class.mt-cus-sm-30]="i>2" *ngIf="(item?.media && (item?.media).length > 0) || item?.title || item?.desc">
								<!-- Media -->
								<div class="tjs-referral__featured-img rounded-circle mx-auto d-flex align-items-center justify-content-center bg-light bk-item-img" *ngIf="item?.media && (item?.media).length > 0">
									<img loading="auto" [id]="item?.media_id" class="img-fluid" alt="bk_image" [src]="item?.media[0]" width="100" height="100">
								</div>
								<div class="px-20" *ngIf="item?.title || item?.desc">
									<!-- Title -->
									<h6 [id]="item?.title_id" *ngIf="item?.title" class="fw-bold display-9 mt-35 mb-25 bk-item-title" [innerHTML]="item?.title | safeHtml"></h6>
									<!-- Description -->
									<p [id]="item?.desc_id" class="mb-0 bk-item-desc" *ngIf="item?.desc" [innerHTML]="item?.desc | safeHtml"></p>
								</div>
							</div>
						</ng-container>
					</div>
					<div class="tjs-referral__link mt-40" *ngIf="section?.title?.content || (section?.button && section?.button?.buttons && (section?.button?.buttons).length > 0)">
						<!-- Title -->
						<div [id]="section?.title?.id" *ngIf="section?.title?.content" class="fs-14 letter-spacing-25 pb-20 fw-bold text-uppercase border-bottom mb-50" [innerHTML]="section?.title?.content | safeHtml"></div>
						<!-- Button -->
						<ng-container *ngTemplateOutlet="btnTemp; context:{section:section}"></ng-container>
					</div>
				</ng-container>
			</ng-template>
		</div>
	</div>
</div>
<!-- Button template -->
<ng-template #btnTemp let-section="section" let-class="class" >
	<div [id]="section?.button?.id" *ngIf="section?.button && section?.button?.buttons && (section?.button?.buttons).length > 0">
		<div class="tjs-navigate-wrapper">
			<ng-container *ngFor="let btn of section?.button?.buttons;trackBy: secServ.trackByFnIndex">
				<bk-navigate [secSett]="btn" [innerHTML]="btn?.content"></bk-navigate>
			</ng-container>
		</div>
	</div>
</ng-template>
