<div [id]="section?.id" [formGroup]="zipcodeForm" *ngIf="settings && section" [ngClass]="variationId == 'bk_short_form_V3' ? 'zipcode_version_3' : 'sform-zipcode_version_4 col-xl-10 mx-auto px-0'">
	<!-- Title -->
	<div class="mb-30" *ngIf="section?.title">
		<h4 class="mb-0 bk-title" [ngClass]="variationId == 'bk_short_form_V4' ? 'display-5 text-center' : 'fw-bold'" [id]="sectionIds?.title"  [innerHTML]="section?.title | safeHtml"></h4>
	</div>
	<!-- Zipcode radio buttons SA & ML -->
	<div [ngClass]="{'d-flex justify-content-center mb-30' : variationId == 'bk_short_form_V4'}" *ngIf="settings?.form_data && settings?.form_data?.preferred_service_location == 'both' && (merchantLocations && merchantLocations.length > 0)">
		<div [ngClass]="{'card shadow-sm justify-content-center' : variationId == 'bk_short_form_V4'}">
			<div [ngClass]="{'form-check-inline my-5' : variationId == 'bk_short_form_V4'}">
				<div class="mb-25" [ngClass]="{'card-body py-10 mb-0-imp' : variationId == 'bk_short_form_V4'}">
					<div class="form-check mb-15 bk_custom_radio" [ngClass]="{'form-check-inline mb-md-0': variationId == 'bk_short_form_V4'}">
						<input id="customerLoc" class="form-check-input" type="radio" name="location_type" value="SA" formControlName="location_type" (change)="locTypeChange('SA')" />
						<label for="customerLoc" class="form-check-label">
							<span class="bk-radio-option" [id]="sectionIds?.label" [innerHTML]="section?.my_loc_label | safeHtml"></span>
							<bk-tooltip customClass="tjs-tooltip-icon--sm" *ngIf="zipcodeForm.controls['location_type'].value == 'ML' && section?.my_loc_tooltip" [id]="section?.my_loc_tooltip_id" [content]="section?.my_loc_tooltip | translate"></bk-tooltip>
						</label>
					</div>
					<div class="form-check mb-15 bk_custom_radio" [ngClass]="{'form-check-inline mb-0-imp me-0 my-5': variationId == 'bk_short_form_V4'}">
						<input id="merchantLoc" class="form-check-input" type="radio" name="location_type" value="ML" formControlName="location_type" (change)="locTypeChange('ML')"/>
						<label for="merchantLoc" class="form-check-label">
							<span class="bk-radio-option" [id]="sectionIds?.label" [innerHTML]="section?.merchant_loc_label | safeHtml"></span>
							<bk-tooltip customClass="tjs-tooltip-icon--sm" *ngIf="zipcodeForm.controls['location_type'].value == 'SA' && section?.merchant_loc_tooltip" [id]="section?.merchant_loc_tooltip_id" [content]="section?.merchant_loc_tooltip | translate"></bk-tooltip>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- SA location -->
	<div class="mb-20" [ngClass]="{'row bg-white shadow-sm rounded p-20 py-20 py-md-10 col-md-8 mx-auto align-items-center mb-30' : variationId == 'bk_short_form_V4'}" *ngIf="settings?.form_data && settings?.form_data?.preferred_service_location == 'customer_location' || zipcodeForm.controls['location_type'].value == 'SA'">
		<label *ngIf="section?.my_loc_zipcode_label" [id]="sectionIds?.label" class="form-label fw-bold mb-10 bk-form-label" [ngClass]="{'px-5 col-md-6 mb-md-0' : variationId == 'bk_short_form_V4'}" [innerHTML]="section?.my_loc_zipcode_label | safeHtml"></label>
		<div [ngClass]="{'px-5 tjs-border-start-md tjs-tjs-border-md-0-sm col-md-6' : variationId == 'bk_short_form_V4'}">
			<input bkTrimSpace [ngClass]="{'is-invalid': zipcodeForm.controls['zipcode'].hasError('required') && zipcodeForm.controls['zipcode'].touched, 'tjs-border-for-md tjs-focus-none tjs-border-md-0': variationId == 'bk_short_form_V4'}" id="zipcode-value" class="form-control bk-input" placeholder="{{section?.my_loc_zipcode_placeholder}}" formControlName="zipcode" (focusin)="zipCodeStatus = false; noZipCodeStatus = false;" (focusout)="checkZipcodeAvail($event)">
			<!-- Error -->
			<div class="tjs-error bk-error" [id]="section?.my_loc_zipcode_err_id" *ngIf="zipcodeForm.controls['zipcode'].hasError('required') && zipcodeForm.controls['zipcode'].touched">
				<i class="tjsicon-attention"></i>{{section?.my_loc_zipcode_err ? section?.my_loc_zipcode_err : 'Please enter a valid zipcode.'}}
			</div>
			<div class="tjs-error bk-error" [id]="section?.my_loc_zipcode_dont_service_err_id" *ngIf="noZipCodeStatus && !zipcodeForm.controls['zipcode'].hasError('required')">
				<i class="tjsicon-attention"></i>{{section?.my_loc_zipcode_dont_service_err ? section?.my_loc_zipcode_dont_service_err : "We don't service this area yet."}}
			</div>
		</div>
	</div>
	<!-- ML location -->
	<ng-container *ngIf="mlDesignId == 'bk_ml_loc_v1' && (settings && settings?.form_data && settings?.form_data?.preferred_service_location == 'merchant_location' || zipcodeForm.controls['location_type'].value == 'ML')">
		<div class="mb-25" [ngClass]="{'card col-xl-9 mx-auto shadow-sm justify-content-center popup-short-form--wid' : variationId == 'bk_short_form_V4'}">
			<label *ngIf="section?.merchant_loc_zipcode_label" [id]="sectionIds?.label" class="form-label bk-form-label" [innerHTML]="section?.merchant_loc_zipcode_label | safeHtml" [ngClass]="{'d-none' : variationId == 'bk_short_form_V4'}"></label>
			<div [ngClass]="{'card-body py-md-10' : variationId == 'bk_short_form_V4'}">
				<div class="row d-flex flex-wrap mx-n5" [ngClass]="{'align-items-center' : variationId == 'bk_short_form_V4'}">
					<label *ngIf="section?.merchant_loc_zipcode_label" [id]="sectionIds?.label" class="form-label bk-form-label" [ngClass]="variationId=='bk_short_form_V3' ? 'd-none' : 'px-5 col-sm-12 col-md-4 mb-md-0 py-md-5'" [innerHTML]="section?.merchant_loc_zipcode_label | safeHtml"></label>
					<div [ngClass]="variationId=='bk_short_form_V3' ? 'col-md-7 mb-md-0 mb-15 px-5' : 'px-5 col-sm-6 col-md-4 tjs-border-start-md tjs-border-start-md mb-sm-0 mb-20'">
						<input [ngClass]="{'is-invalid': zipcodeForm.controls['zipcode'].hasError('required') && zipcodeForm.controls['zipcode'].touched, 'tjs-border-md-0': variationId == 'bk_short_form_V4' }" class="form-control bk-input" placeholder="{{section?.merchant_loc_zipcode_placeholder}}" [(ngModel)]="mlTypeKeyword" [ngModelOptions]="{standalone: true}">
					</div>
					<div [ngClass]="variationId=='bk_short_form_V3' ? 'col-md-5 px-5' : 'px-5 col-sm-6 col-md-4'">
						<ng-container *ngTemplateOutlet="mlBtn"></ng-container>
					</div>
				</div>
				<!-- Error -->
				<div class="tjs-error bk-error" [id]="section?.my_loc_zipcode_err_id" *ngIf="zipcodeForm.controls['zipcode'].hasError('required') && zipcodeForm.controls['zipcode'].touched">
					<i class="tjsicon-attention"></i>{{section?.my_loc_zipcode_err ? section?.my_loc_zipcode_err : 'Please enter a valid zipcode.'}}
				</div>
			</div>
		</div>
	</ng-container>
	<div class="position-relative" >
		<ng-container *ngIf="settings && settings?.form_data && settings?.form_data?.preferred_service_location == 'merchant_location' || zipcodeForm.controls['location_type'].value == 'ML'">
			<!-- Merchant address (selected and first 3) -->
			<div class="mb-10" [ngClass]="{'col-xl-9 mx-auto card shadow-sm pb-10 rounded p-20 mb-30 popup-short-form--wid' : variationId == 'bk_short_form_V4'}" *ngIf="mlDesignId != 'bk_ml_loc_v3' && selectedMerchantLoc; else merchantAllLocations">
				<div class="row mx-n5">
					<ng-container *ngTemplateOutlet="merchantLocTemp; context:{loc: selectedMerchantLoc, isSelected: true}"></ng-container>
				</div>
				<div class="mb-15" *ngIf="selectedMerchantLoc && merchantLocations && merchantLocations.length > 1 && mlDesignId == 'bk_ml_loc_v2'">
					<ng-container *ngTemplateOutlet="mlBtn"></ng-container>
				</div>
			</div>
			<!-- First 3 address -->
			<ng-template #merchantAllLocations>
				<div *ngIf="['add', 'draft'].includes(bookingType) && merchantLocations && merchantLocations.length > 0 && zipcodeForm.controls['location_type'].value == 'ML'" [ngClass]="{'col-xl-9 mx-auto card shadow-sm pb-10 rounded p-20 mb-30 popup-short-form--wid' : variationId == 'bk_short_form_V4'}">
					<div class="mb-0" [ngClass]="{'mb-0-imp' : variationId == 'bk_short_form_V4'}">
						<div class="row mx-n5">
							<ng-container *ngFor="let loc of merchantLocations; let i = index;trackBy: utilServ.trackByFnIndex">
								<ng-container *ngIf="i < 3 || (mlDesignId == 'bk_ml_loc_v3')">
									<ng-container *ngTemplateOutlet="merchantLocTemp; context:{loc: loc, isSelected: false}"></ng-container>
								</ng-container>
							</ng-container>
						</div>
					</div>
					<!-- View more btn -->
					<div class="mb-15" [ngClass]="{'mb-0-imp' : variationId == 'bk_short_form_V4'}" *ngIf="merchantLocations && merchantLocations.length > 3 && mlDesignId != 'bk_ml_loc_v3'">
						<bk-navigate [secId]="section?.view_more_loc_btn?.id" [designId]="section?.view_more_loc_btn?.design_id" [innerHTML]="section?.view_more_loc_btn?.content" customClass="btn btn-primary btn-sm border-transparent" (callback)="merchantLocationsPopup()"></bk-navigate>
					</div>
				</div>
			</ng-template>
		</ng-container>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</div>
</div>
<!-- Merchant address template -->
<ng-template #merchantLocTemp let-loc="loc" let-isSelected="isSelected">
	<div class="px-5" [ngClass]="variationId=='bk_short_form_V3' ? 'col-12 col-md-6 col-lg-6 mb-20' : 'col-sm-6 col-lg-4 mb-10'">
		<div class=" border card h-100 bg-light shadow-sm position-relative tjs-pointer tjs-hover-shadow bg-transparent" [ngClass]="{'border-success-imp': isSelected || (zipcodeForm.controls['location_id'].value && zipcodeForm.controls['location_id'].value == loc?.form_location?.id)}">
			<div class="card-body p-15 pe-30 bk-card-body bk-form-desc" (click)="!isSelected && merchantLocSelect(loc)">
				{{loc?.location_name | translate}}
				<ng-container *ngIf="!loc?.hide_ml_address">
					<span class="fs-14 bk-form-desc">, {{loc?.merchant_location_address | translate}}</span>
					<div class="fs-14 bk-form-desc" *ngIf="loc?.apt">{{'Apt' | translate}} : {{loc?.apt}}</div>
				</ng-container>
			</div>
			<div class="position-absolute d-flex flex-column end-5 top-5">
				<button *ngIf="!loc?.hide_ml_image && loc?.picture" type="button" class="btn btn-link text-decoration-none bk-icon p-0" (click)="locPicturePopup(loc?.picture)"><i class="tjsicon-camera bk-form-desc"></i></button>
				<a *ngIf="!loc?.hide_ml_address" class="text-decoration-none bk-icon" target="_blank" href="{{mapAddrLink}}{{loc?.merchant_location_address}}">
					<i class="tjsicon-placeholder-1 bk-form-desc"></i>
				</a>
			</div>
		</div>
	</div>
</ng-template>
<ng-template #mlBtn>
	<ng-container *ngIf="findLocBtnText == 'Find Locations'; else chooseAnotherLoc">
		<bk-navigate [secSett]="section?.find_loc_btn" [innerHTML]="section?.find_loc_btn?.content" essentialClass="h-100 bk-btn" customClass="btn btn-primary py-15 border-transparent" (callback)="findMerchantLoc()" essentialClass="loc-btn {{(variationId=='bk_short_form_V4' && mlDesignId != 'bk_ml_loc_v2') && 'w-100'}}" [btnDisabled]="(bookingType == 'reschedule' || !isQuoteEditable) ? true : false"></bk-navigate>
	</ng-container>
	<ng-template #chooseAnotherLoc>
		<bk-navigate [secSett]="section?.choose_another_loc_btn" [innerHTML]="section?.choose_another_loc_btn?.content" essentialClass="h-100 bk-btn" customClass="btn btn-success border-transparent" (callback)="findMerchantLoc()" essentialClass="loc-btn {{(variationId=='bk_short_form_V4' && mlDesignId != 'bk_ml_loc_v2') && 'w-100'}}" [btnDisabled]="(bookingType == 'reschedule' || !isQuoteEditable) ? true : false"></bk-navigate>
	</ng-template>
</ng-template>