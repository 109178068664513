<div [id]="section?.id" [formGroup]="serviceForm" *ngIf="settings && section && (services && services.length > 0)" [ngClass]="{'invisible':!((services && services.length > 1) || isHourly)}">
	<!-- Label -->
	<div class="mb-15" *ngIf="services.length > 1 && (section?.serv_label || (descStatus && section?.serv_desc))">
		<label *ngIf="section?.serv_label" [id]="section?.serv_label_id" class="mb-0 form-label bk-form-label" [innerHTML]="section?.serv_label | safeHtml"></label>
		<small  *ngIf="descStatus && section?.serv_desc" [id]="section?.serv_desc_id" class="d-block mt-10 bk-form-desc" [innerHTML]="section?.serv_desc | safeHtml"></small>
	</div>
	<div class="tjs-check-btn tjs-btn-multi d-flex flex-wrap mx-n10" *ngIf="services && services.length > 1">
		<!-- Service selection -->
		<ng-container *ngFor="let service of services;trackBy: utilServ.trackByFnIndex">
			<div [ngClass]="{'input--checked': selectedServiceType == service}" class="px-10 text-center mb-15 flex-grow-1 bk-radio" >
				<div class="form-check form-check-inline m-0 d-block">
					<input id="service_{{service.id}}" class="form-check-input bk-form-check-input" type="radio" name="service" [value]="service" [(ngModel)]="selectedServiceType" [ngModelOptions]="{standalone: true}" (change)="selectService(service)" [attr.disabled]="isDisabled"/>
					<label for="service_{{service.id}}" class="form-check-label w-100 btn btn-light fs-17 fw-bold d-flex align-items-center justify-content-center bk-radio-option bk-form-check-btn">
						{{utilServ.getFormParamName(service) | translate}} <span *ngIf="['reschedule', 'draft'].includes(bookingType) && service?.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
						<!-- Tooltip -->
						<bk-tooltip customClass="tjs-tooltip-icon--sm bk-form-check-tooltip" *ngIf="service && service?.show_tooltip_icon" [content]="service?.tooltip_text | translate"></bk-tooltip>
					</label>
				</div>
			</div>
		</ng-container>
	</div>
	<!-- Hourly service -->
	<bk-hourly
		[isQuoteEditable]="isQuoteEditable"
		[services]="services"
		[bookingType]="bookingType"
		[section]="section"
		[serviceForm]="serviceForm"
		[selectedLocation]="selectedLocation"
		[selectedServiceType]="selectedServiceType"
		[selectedFrequency]="selectedFrequency"
		[settings]="settings"
		[pageSett]="pageSett"
		(hourlyServiceChange)="hourlyServiceChange.emit()"
		[isMultiStepForm]="true"
		(isHourlyService)="getHourlVal($event)"
	></bk-hourly>
</div>