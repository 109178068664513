import { Component, Input, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil, forkJoin, Observable } from 'rxjs';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, ApiServ, InitServ } from '../../Services';

@Component({
	selector: 'bk-faq',
	templateUrl:'./FAQ.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class FAQComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		list: null
	}
	categories: any;
	faqs: any;
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, private initServ: InitServ) { }

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	 private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		// List type: custom/dynamic
		if(this.pageSett[this.secId] && this.pageSett[this.secId].list_type && this.pageSett[this.secId].list_type == 'dynamic'){
			// Call multiple API
			// Get the dynamic faq from content management app
			forkJoin([this.callAPI(),this.callAPI('faq')]).pipe(takeUntil(this.destroy)).subscribe((res:any)=> {
				if(res && res.length>0){
					this.onResultCallback(res[0],'cat');
					this.onResultCallback(res[1],'faq');
				}
			});
		}
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
	}
	/**
	 * Called api: category & faq
	 * @param type
	 * @returns api res
	 */
	private callAPI(type: string = 'cat'): Observable<any> {
		let queryParams: any = {language:this.initServ.savedLng};
		if(type == 'cat'){
			queryParams['type']='faq';
			return this.apiServ.callApiWithQueryParams('GET', 'Category', queryParams);
		} else{
			queryParams['status']='publish';
			queryParams['sort']='yes';
			return this.apiServ.callApiWithQueryParams('GET', 'Faq', queryParams);
		}
	}
	/**
	 * On result callback method
	 * @param res: api
	 * @param type: category/faq
	 * API response handler
	 */
	private onResultCallback(res:any, type:string): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			switch(type) {
				case 'cat':
					this.categories = res.data;
				break;
				case 'faq':
					this.faqs = res.data;
				break;
			}
		}
		if(type == 'cat' && !this.categories){
			let obj={id:'no-cat'};
			this.categories = [obj];
		}
	}
}
