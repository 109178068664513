import { Component, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, InitServ, ApiServ, UtilServ, LoaderServ, CacheService } from '../../../../Services';
// Custom validator
import { CustomValidators } from '../../../../Global/GlobalDefault';

@Component({
	selector: 'bk-referral-popup',
	templateUrl: './ReferralPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ReferralPopupComponent implements OnInit {
	// Variables
	radioCtrlSection: any
	defReferralAmt: any;
	thankYouSett: any;
	referralForm!: FormGroup; // Referral form
	loaderId: string = 'thank-you-send-ref-loader';
	phoneError: number = 0;
	// Section fields
	secId: string = '';
	slug: string = 'referral';
	section: any = { title: null, form: null, send_btn: null, cancel_btn: null };

	constructor(public dialogRef: MatDialogRef<ReferralPopupComponent>, private frmBldr: FormBuilder, public utilServ: UtilServ, public initServ: InitServ, @Self() private destroy: NgOnDestroy, private apiServ: ApiServ, private toastr: ToastrService, private loader: LoaderServ, public customValidators: CustomValidators, private cacheServ: CacheService) { }

	ngOnInit(): void {
		// build popup section
		this.buildSectionData();
		// Build form
		this.referralForm = this.frmBldr.group({
			customer_id: [this.utilServ.userId()],
			type: ['email'],
			referred_email: [],
			referred_phone: [],
			referral_amount: [this.defReferralAmt],
			sent_from_ip: [this.initServ.ipAddress]
		});
		// Section email is hide, set the sms type
		if (!this.radioCtrlSection?.email) {
			this.referralForm.controls['type'].setValue('sms');
			this.emptyForm('phone');
		} else {
			this.emptyForm('email');
		}
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
		});
	}
	/**
	 * Radio button type change
	 * @param type email/phone
	 */
	public emptyForm(type: string): void {
		this.referralForm.controls['referred_phone'].setValue(null);
		this.referralForm.controls['referred_email'].setValue(null);
		if (type == 'email') {
			this.phoneError = 0;
			CustomValidators.requiredValidator(true, this.referralForm.controls['referred_email']);
		}
	}
	/**
	 * Phone number validator
	 */
	public phoneValidate(): any {
		let value = this.customValidators.phoneValidate(this.referralForm.controls['referred_phone'].value);
		if (value) {
			this.phoneError = value.phoneError;
			if (this.phoneError == 0) {
				this.referralForm.controls['referred_phone'].setValue(value.numbers);
			}
		}
	}
	/**
	 * Submit referral form
	 */
	public submitForm(): void {
		if ((this.referralForm.value['referred_email'] != null || (this.phoneError == 0 && this.referralForm.value['referred_phone'] != null))) {
			let formData = this.referralForm.value;
			// Email checker and send referral
			if (formData.referred_email) {
				let status = this.customValidators.emailValid(this.referralForm.controls['referred_email']);
				if (status != false) {
					this.sendRef(formData);
				}
			}
			// Phone checker and send referral
			if (formData.referred_phone && this.phoneError == 0) {
				let phoneNumbers = [];
				if (formData.referred_phone && (formData.referred_phone).length > 0) {
					for (let phone of formData.referred_phone) {
						phoneNumbers.push(this.utilServ.phoneUnmask(phone))
					}
				}
				formData.referred_phone = phoneNumbers.toString();
				this.sendRef(formData);
			}
		} else {
			this.toastr.error(this.initServ.appStr.toastr.emptyInput);
			if (this.referralForm.value['type'] == 'email') {
				this.referralForm.controls['referred_email'].markAsTouched();
			} else {
				this.phoneError = 1;
			}
		}
	}
	/**
	 * Send the invitation using email & phone
	 * @param formData
	 */
	private sendRef(formData: any): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId, this.dialogRef);
		let sendData: any = {
			customer_id: formData.customer_id,
			referred_email: formData.referred_email,
			referred_phone: formData.referred_phone,
			referral_amount: formData.referral_amount,
			sent_from_ip: formData.sent_from_ip,
		}
		let queryParams: any = { source: 'thankyou', referrer: '', referral: '' };
		if (this.thankYouSett) {
			if (this.thankYouSett.audience_id) {
				queryParams.referrer = this.thankYouSett.audience_id;
			}
			if (this.thankYouSett.recipient_aud_id) {
				queryParams.referral = this.thankYouSett.recipient_aud_id;
			}
		}
		this.apiServ.callApiWithPathQueryVars('POST', 'SendReferral', [formData.customer_id], queryParams, sendData).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res));
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res: any): void {
		if (this.apiServ.checkAPIRes(res)) {
			this.toastr.success(res.message);
			this.dialogRef.close();
		} else {
			if (res && res.message) {
				this.toastr.error(res.message);
			}
		}
		this.emptyForm(this.referralForm.value['type']);
		this.loader.hide(this.loaderId, this.dialogRef);
	}
}
