<ng-container *ngIf="buildHeader && sett && secId && sett[secId] && sett[secId][rcServ.statusType] && section && pageSett && pageSett?.header">
	<header [id]="secId" class="tjs-header-wrap">
		<div class="tjs-header bk-header shadow-sm">
			<nav class="navbar navbar-expand-lg navbar-light py-10 p-lg-0" [id]="section?.menu?.id">
				<div class="align-items-stretch-imp" [ngClass]="{'container-fluid': fluid,'container': !fluid, 'justify-content-center': (!currentUser && !section?.menu && !pageSett?.headerMenu && !(menuChild?.menuData && (menuChild?.menuData).length > 0) && !(sessionMenu && (sessionMenu).length > 0) && !(isPlanPermission && !isAllowOneLang && initServ.appLanguages && (initServ.appLanguages).length > 1))}" [style]="headerStyle">
					<!-- Logo -->
					<ng-container *ngIf="section?.logo && logoStatus">
						<ng-container *ngIf="(section?.logo?.media && (section?.logo?.media).length > 0); else defaultLogo">
							<bk-navigate class="navbar-brand-wrap text-start" essentialClass="navbar-brand text-start text-capitalize" [secSett]="section?.logo?.link" innerHTML="<img id='{{section?.logo?.media_id}}' class='img-fluid' width='126' height='97' src='{{section?.logo?.media[0]}}' alt='site logo' style='width:{{sett[section?.logo?.media_id][widthType]}}px' />"></bk-navigate>
							<!-- <bk-navigate class="navbar-brand-wrap text-start" essentialClass="navbar-brand text-start text-capitalize" [secSett]="section?.logo?.link" innerHTML="<img id='{{section?.logo?.media_id}}' class='img-fluid' width='126' height='97' src='{{section?.logo?.media[0]}}' alt='site logo' style='width:{{sett[section?.logo?.media_id][widthType] ? sett[section?.logo?.media_id][widthType] : ''}}px; height:{{sett[section?.logo?.media_id][heightType] ? sett[section?.logo?.media_id][heightType] : ''}}px' />"></bk-navigate> -->
						</ng-container>
						<ng-template #defaultLogo>
							<bk-navigate class="navbar-brand-wrap text-start" essentialClass="navbar-brand text-start fw-bold bk-site-logo" [secSett]="section?.logo?.link" innerHTML="{{initServ?.appStr?.text?.yourLogo | translate}}" [isText]="true"></bk-navigate>
						</ng-template>
					</ng-container>
					<!-- Responsive toggle button -->
					<button class="navbar-toggler border-0 px-0 ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" *ngIf="(section?.menu && pageSett?.headerMenu) || (currentUser) || (isPlanPermission && !isAllowOneLang && initServ.appLanguages && (initServ.appLanguages).length > 1)">
						<span class="navbar-toggler-icon bk-navbar-toggler"></span>
					</button>
					<!-- Menu -->
					<div class="collapse navbar-collapse justify-content-end equal-height-menu" id="navbarSupportedContent">
						<!-- Dynamic menu -->
						<div class="nav-menu-wrap" [ngClass]="varId == 'bk_header_V2' ? 'mx-lg-auto' : 'ms-lg-auto'">
							<bk-header-menu class="tjs-header-bkmenu" *ngIf="section?.menu && pageSett?.headerMenu && headerMenu" [secId]="secId" [varId]="varId" [section]="section?.menu" [sett]="sett" [pageSett]="pageSett" [headerMenu]="headerMenu"></bk-header-menu>
						</div>
						<!-- After login and session menu -->
						<div class="tjs-header-session d-lg-flex">
							<!-- Session menu -->
							<ng-container *ngIf="sessionMenu && sessionMenu.length > 0 && (!currentUser || initServ?.theme)">
								<ng-container *ngFor="let menu of sessionMenu;trackBy: utilServ.trackByFnIndex">
									<bk-navigate class="tjs-bk-navigate" essentialClass="nav-link position-relative btn bk-header-link text-capitalize" [secSett]="menu" innerHTML="{{menu?.title | translate}}" type="menu"></bk-navigate>
								</ng-container>
							</ng-container>
							<!-- After login menu dropdown -->
							<div class="dropdown bk-header-dropdown tjs-dropdown--user d-lg-flex align-items-center" *ngIf="currentUser">
								<button class="bk-header-dropdown-btn btn text-start w-100 h-100 nav-link" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
									<span class="text-capitalize">{{currentUser?.first_name}}</span>
									<i class="tjsicon-down-arrow ms-5 me-10 fs-12"></i>
									<bk-user-img [imgSrc]="currentUser?.photo_url" alt="user image" width="40" height="40" essentialClass="rounded img-fluid"></bk-user-img>
								</button>
								<ul class="dropdown-menu tjs-bg-dark dropdown-menu-lg-end rounded-0 p-0 border-0" aria-labelledby="dropdownMenuButton1">
									<li><a class="dropdown-item text-white py-10 px-lg-15" [routerLink]="['/'+this.initServ.appDynamicRoutes['info-and-billing']]"><i class="tjsicon-user-2 me-10"></i>{{initServ?.appStr?.text?.yourInfoBilling | translate}}</a></li>
									<li><a class="dropdown-item text-white py-10 px-lg-15" [routerLink]="['/'+this.initServ.appDynamicRoutes['dashboard']]"><i class="tjsicon-dashboard me-10"></i>{{initServ?.appStr?.text?.dashboard | translate}}</a></li>
									<li *ngIf="admnStngs && admnStngs?.merchant_settings && admnStngs?.merchant_settings?.customers && admnStngs?.merchant_settings?.customers?.show_drive_to_customer == 'yes'"><a class="dropdown-item text-white py-10 px-lg-15" [routerLink]="['/'+this.initServ.appDynamicRoutes['my-drive']]" ><i class="tjsicon-document me-10"></i>{{initServ?.appStr?.text?.myDrive | translate}}</a></li>
									<li *ngIf="utilServ.appPermission('emailNotif') || utilServ.appPermission('smsNotif') || utilServ.appPermission('appNotif')"><a class="dropdown-item text-white py-10 px-lg-15" [routerLink]="['/'+this.initServ.appDynamicRoutes['notifications']]"><i class="tjsicon-bell me-10"></i>{{initServ?.appStr?.text?.notifications | translate}}</a></li>
									<li><button type="button" class="dropdown-item text-white py-10 px-lg-15" (click)="deactivateAccount()"><i class="tjsicon-user-1 me-10"></i>{{initServ?.appStr?.text?.deactivateAccount | translate}}</button></li>
									<li><a class="dropdown-item text-white py-10 px-lg-15" [href]="faqLink" [target]="(faqLink) ? '_blank' : '_self'"><i class="tjsicon-question me-10"></i>{{initServ?.appStr?.text?.faqSupport | translate}}</a></li>
									<li><button type="button" class="dropdown-item text-white py-10 px-lg-15" (click)="authServ.logout()"><i class="tjsicon-power-variant me-10"></i>{{initServ?.appStr?.text?.logout | translate}}</button></li>
								</ul>
							</div>
							<!-- Login as menu -->
							<ng-container *ngIf="currentUser && currentUser?.admin_token">
								<bk-navigate class="tjs-bk-navigate" secId="login-as-admin" innerHTML="{{'Log In As Admin' | translate}}" customClass="btn nav-link position-relative bk-header-link" (callback)="loginAsMerchant()" type="menu" ></bk-navigate>
							</ng-container>
							<!-- Language dropdown -->
							<div class="bk-header-dropdown dropdown tjs-dropdown--lang d-lg-flex align-items-center h-100" *ngIf="isPlanPermission && !isAllowOneLang && initServ.appLanguages && (initServ.appLanguages).length > 1">
								<button class="bk-header-dropdown-btn btn w-100 h-100 d-flex align-items-center nav-link" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
									<img loading="auto" *ngIf="initServ?.selectedLang?.flag" alt="language" class="me-10" src="{{flagUrl}}{{initServ?.selectedLang?.flag}}" width="25" height="25"><span class="text-capitalize">{{initServ?.selectedLang?.code}}</span>
								</button>
								<ul class="dropdown-menu dropdown-menu-lg-end rounded-0 p-0 border-0" aria-labelledby="dropdownMenuButton1">
									<ng-container *ngFor="let lng of initServ.appLanguages;trackBy: utilServ.trackByFnIndex">
										<li *ngIf="lng.enable_for_customer">
											<button type="button" class="px-10 py-5 rounded-0 dropdown-item text-capitalize d-flex align-items-center fs-14 text-wrap" (click)="langChange(lng?.code)" [class.active]="lng?.code == translate.currentLang">
												<img loading="auto" *ngIf="lng?.flag" alt="language" class="img-fluid me-10" src="{{flagUrl}}{{lng?.flag}}" width="30" height="30"> <span>{{lng?.name}} ( {{lng?.code}} )</span>
											</button>
										</li>
									</ng-container>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</div>
	</header>
	<div id="header-clone"></div>
</ng-container>