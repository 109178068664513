<div class="overflow-hidden rounded-3 border mb-30">
	<div class="tjs-table-header--fixed position-relative tjs-scrollbar overflow-auto" [ngClass]="{'tjs-inv-table-side-col--sticky': ((invData?.settings?.tip_settings?.pay_type == 'specific') && (isPlanPermission))}" [formGroup]="invForm">
		<table class="table bk-table tjs-table tjs-table--responsive tjs-table--hover tjs-table--border__x" [id]="invSec?.bkng_table_id">
			<thead *ngIf="invData?.form_params && invData?.form_params.length > 0">
				<tr>
					<th *ngFor="let param of invData?.form_params;trackBy: utilServ.trackByFnIndex;let isLastTh = last" class="tjs-inv-column" [ngClass]="{'text-end': isLastTh}" [attr.id]="param">{{invServ.formParamsName[param] | translate}}</th>
					<th *ngIf="invData?.settings?.tip_settings?.pay_type == 'specific' && isPlanPermission" class="tjs-inv-column--tip">
						<div [id]="invData?.settings?.allow_tip_by_cust && !this.invData?.no_of_payments && invSec?.bkng_table?.tip_per_bkng_id" [innerHTML]="invSec?.bkng_table?.tip_per_bkng | safeHtml" ></div>
					</th>
				</tr>
			</thead>
			<tbody *ngIf="(bkngs && (bkngs).length > 0) || (invData?.bkng_line_items && invData?.bkng_line_items.length > 0)" formGroupName="bookings_tip">
				<tr *ngFor="let bkng of bkngs let i = index;trackBy: utilServ.trackByFnIndex;" class="position-relative">
					<ng-container *ngTemplateOutlet="bkngTemp;context:{bkng:bkng}"></ng-container>
					<td *ngIf="invData?.settings?.allow_tip_by_cust && !this.invData?.no_of_payments && invData?.settings?.tip_settings?.pay_type == 'specific' && isPlanPermission; else paidTip" [formGroupName]="bkng?._id"  [attr.data-label]="invSec?.bkng_table?.tip_per_bkng | translate">
						<div >
							<div class="input-group input-group-ltr input-group-sm">
								<span class="input-group-text" bk-currency></span>
								<input type="text" class="form-control form-control-sm" BKNumeric formControlName="pay" (blur)="calBkngTip()">
							</div>
						</div>
					</td>
					<ng-template #paidTip>
						<td *ngIf="invData?.bookings_tip && invData?.bookings_tip?.[bkng?._id]">
							<div><bk-amount-display [amount]="invData?.bookings_tip?.[bkng?._id]?.pay" [isZero]="true"></bk-amount-display></div>
						</td>
					</ng-template>
				</tr>
				<!-- Booking line items -->
				<ng-container *ngIf="invData?.bkng_line_items && invData?.bkng_line_items.length > 0">
					<tr *ngFor="let bkng of invData?.bkng_line_items;trackBy: utilServ.trackByFnIndex;">
						<ng-container *ngTemplateOutlet="bkngTemp;context:{bkng:bkng, isLineItem: true}"></ng-container>
					</tr>
				</ng-container>
			</tbody>
		</table>
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</div>
	<div class="z-99 position-sticky bottom-0 bg-transparent text-end border-top p-10" *ngIf="bkngIdsBatches && ((this.offset + 1) != (this.bkngIdsBatches.length))">
		<button type="button" class="btn btn-link btn-xs" (click)="loadMore()" translate>Load more</button>
	</div>
</div>
<ng-template #noFormParams>
	<span>--</span>
</ng-template>

<!-- Booking template -->
<ng-template #bkngTemp let-bkng="bkng" let-isLineItem="isLineItem">
	<td *ngFor="let param of invData?.form_params;trackBy: utilServ.trackByFnIndex;let isLastTd = last" [attr.data-label]="invServ.formParamsName[param] | translate" [ngClass]="{'text-end': isLastTd}">
		<div [ngSwitch]="param">
			<!-- Booking id -->
			<div *ngSwitchCase="'booking_id'">
				<ng-container *ngIf="isLineItem; else bkngId">
					<span class="d-block">#{{bkng?.booking_id}}</span>
					<ng-container *ngIf="bkng?.type">
						<span class="badge bg-light tjs-badge mt-5 text-dark fs-12" [ngSwitch]="bkng?.type">
							<ng-container *ngSwitchCase="'canceled'">Cancelled</ng-container>
							<ng-container *ngSwitchCase="'postponed'">Postponed</ng-container>
							<ng-container *ngSwitchCase="'id_changed'">Booking ID changed</ng-container>
							<ng-container *ngSwitchCase="'voided'">Voided</ng-container>
							<ng-container *ngSwitchCase="'ind_deleted'">Industry deleted</ng-container>
							<ng-container *ngSwitchCase="'form_deleted'">Form deleted</ng-container>
							<ng-container *ngSwitchCase="'leave_as_it_is_with_increase'">Leave as it is with increase amount</ng-container>
							<ng-container *ngSwitchCase="'leave_as_it_is_with_decrease'">Leave as it is with decrease amount</ng-container>
						</span>
					</ng-container>
				</ng-container>
				<ng-template #bkngId>
					<span class="d-block">#{{bkng?._id}}</span>
					<span class="badge bg-light tjs-badge mt-5 text-dark fs-12">{{utilServ.bkngStatus[bkng?.status]?.text | translate}}</span>
				</ng-template>
			</div>
			<!-- Service date -->
			<div *ngSwitchCase="'service_date'">
				<ng-container *ngIf="!isLineItem; else noFormParams">
					<div class="text-capitalize">{{bkng?.day | translate}}</div>
					<bk-date-display [date]="bkng?.arrival_date"></bk-date-display>
					<div class="dir-ltr">{{utilServ.slotToString(bkng?.base_location_id, bkng?.day, bkng?.arrival_time,bkng?.spot_type,bkng?.arrival_window)}}</div>
				</ng-container>
			</div>
			<!-- Total -->
			<div *ngSwitchCase="'total'">
				<ng-container *ngIf="isLineItem; else bkngTotal">
					<bk-amount-display class="d-block" [amount]="bkng?.amount" [isZero]="true"></bk-amount-display>
				</ng-container>
				<ng-template #bkngTotal>
					<bk-amount-display [amount]="bkng?.final_amount" [isZero]="true"></bk-amount-display>
				</ng-template>
			</div>
			<!-- Industry -->
			<div *ngSwitchCase="'industry'">
				<span *ngIf="!isLineItem; else noFormParams">{{bkngFormServ.industryName(bkng?.industry_id) | translate}}</span>
			</div>
			<!-- Frequency -->
			<div *ngSwitchCase="'frequency'">
				<span *ngIf="!isLineItem; else noFormParams">{{ elementName?.['form_frequencies'][bkng?.frequency] | translate}}</span>
			</div>
			<!-- Service -->
			<div *ngSwitchCase="'service'">
				<span *ngIf="!isLineItem; else noFormParams">{{ elementName?.['service_category'][bkng?.service_category] | translate}}</span>
			</div>
			<!-- Form 1 -->
			<div *ngSwitchCase="'pricing_parameter'">
				<ng-container *ngIf="!isLineItem; else noFormParams">
					<ng-container *ngIf="bkng?.form_id == 1 && bkng?.pricing_parameter && (bkng?.pricing_parameter).length > 0 && pricingParams; else elseForm4;">
						<div *ngFor="let pricingParameter of bkng?.pricing_parameter; let isLast = last;trackBy: utilServ.trackByFnIndex;">
							<ng-container *ngIf="pricingParameter?.quantity">
								<label class="mb-0">{{pricingParams[pricingParameter?.id]?.cat_name | translate}}</label>:
								<span>{{utilServ.getFormParamName(pricingParams[pricingParameter?.id]?.value[pricingParameter?.quantity]) | translate}}{{isLast ? '' : ', '}}</span>
							</ng-container>
						</div>
					</ng-container>
					<ng-template #elseForm4>
						<ng-container *ngIf="bkng?.form_id == 4 && bkng?.['area_parameter']['quantity'] && pricingParams; else noFormParams">
							<label class="mb-0">{{utilServ.getFormParamName(pricingParams[0]?.value[bkng?.area_parameter?.id]) | translate}}</label>:
							<span>{{bkng?.area_parameter?.quantity | translate}}</span>
						</ng-container>
					</ng-template>
				</ng-container>
			</div>
			<!-- Form 2 and Form 3 -->
			<div *ngSwitchCase="['items', 'packages'].includes(param) ? param : ''">
				<ng-container *ngIf="!isLineItem; else noFormParams">
					<ng-container *ngIf="bkng?.form_id == 2; else form3">
						<ng-container *ngSwitchCase="'items'">
							<ng-container *ngIf="bkng?.items && bkngFormServ.checkItemsAvailability(bkng?.items); else noFormParams">
								<div *ngFor="let item of bkng?.items; let i = index;trackBy: utilServ.trackByFnIndex">
									<label class="mb-0">{{elementName?.['items'][item?.id] | translate}} : </label>
									<!-- Packages -->
									<ng-container *ngTemplateOutlet="itemPackages; context:{item:item}"></ng-container>
								</div>
							</ng-container>
						</ng-container>
						<ng-container *ngSwitchCase="'packages'">
							<ng-container *ngIf="bkng?.items && bkngFormServ.checkPckgsAvailability(bkng?.items) && !bkngFormServ.checkItemsAvailability(bkng?.items); else noFormParams">
								<div *ngFor="let item of bkng?.items; let i = index;trackBy: utilServ.trackByFnIndex">
									<!-- Packages -->
									<ng-container *ngTemplateOutlet="itemPackages; context:{item:item}"></ng-container>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>
					<!-- Items & Addons -->
					<ng-template #form3>
						<div *ngSwitchCase="'items'">
							<ng-container *ngIf="bkng?.form_id == 3 && bkng?.items && bkng?.items?.length > 0; else noFormParams">
								<div *ngFor="let item of bkng?.items; let i = index;trackBy: utilServ.trackByFnIndex">
									<label class="mb-0">{{elementName?.['items'][item?.id] | translate}} ({{item?.quantity}}) : </label>
									<span *ngIf="elementName?.addons" class="mb-10"> {{bkngFormServ.getItemAddons(item?.name, i, bkng, elementName?.addons) | translate}}</span>
								</div>
							</ng-container>
						</div>
						<div *ngSwitchCase="'packages'">--</div>
					</ng-template>
				</ng-container>
			</div>
			<!-- Extras -->
			<div *ngSwitchCase="'extras'">
				<ng-container *ngIf="!isLineItem && bkng?.extras && bkng?.extras.length > 0; else noFormParams">
					<div *ngFor="let extra of bkng?.extras;let isLast = last;trackBy: utilServ.trackByFnIndex">
						<span>{{elementName?.['extras'][extra?.id] | translate}}</span><span *ngIf="extra?.quantity && extra?.quantity > 1">({{extra?.quantity | translate}})</span>{{isLast ? '' : ', '}}
					</div>
				</ng-container>
			</div>
			<!-- Excludes -->
			<div *ngSwitchCase="'excludes'">
				<ng-container *ngIf="!isLineItem && ((bkng?.form_id == 1) &&  bkng?.is_partial_cleaning && bkng?.partial_cleaning && (bkng?.partial_cleaning).length > 0); else noFormParams">
					<div *ngFor="let exclude of bkng?.partial_cleaning;let isLast = last;trackBy: utilServ.trackByFnIndex">
						<span>{{elementName?.['excludes'][exclude?.id] | translate}}</span><span *ngIf="exclude?.quantity && exclude?.quantity > 1">({{exclude?.quantity | translate}})</span>{{isLast ? '' : ', '}}
					</div>
				</ng-container>
			</div>
			<!-- Custom sections -->
			<div *ngSwitchCase="'custom_section'">
				<ng-container *ngIf="!isLineItem && bkngCustSecServ.custFieldsData; else noFormParams">
					<bk-custom-fields-display customClass="w-90" class="d-flex flex-column" [bkng]="bkng" [isEmpty]="true"></bk-custom-fields-display>
				</ng-container>
			</div>
			<!-- Service total -->
			<div *ngSwitchCase="'service_total'">
				<ng-container *ngIf="!isLineItem else noFormParams">
					<bk-amount-display [amount]="bkngPriceObj?.[bkng?._id]?.updatedDisplayServicePrice" [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
			<!-- Referral discount -->
			<div *ngSwitchCase="'referral_discount'">
				<ng-container *ngIf="!isLineItem; else noFormParams">
					<ng-container *ngIf="bkng?.referral_discount; else displayRefDiscount">
						<bk-amount-display [amount]="bkng?.referral_discount" [isZero]="true"></bk-amount-display>
					</ng-container>
					<ng-template #displayRefDiscount>
						<ng-container *ngIf="bkngPriceObj?.[bkng?._id]?.displayReferralDiscount; else noFormParams">
							<bk-amount-display [amount]="bkngPriceObj[bkng?._id].displayReferralDiscount" [isZero]="true"></bk-amount-display>
						</ng-container>
					</ng-template>
				</ng-container>
			</div>
			<!-- Expedited amount -->
			<div *ngSwitchCase="'expedited_amount'">
				<ng-container *ngIf="!isLineItem && bkng?.same_day_booking && bkng?.expedited_amount && !bkng?.exclude_expedited_charge; else noFormParams">
					<bk-amount-display [amount]="bkng?.expedited_amount" [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
			<!-- Frequency discount -->
			<div *ngSwitchCase="'frequency_discount'">
				<ng-container *ngIf="!isLineItem && bkngPriceObj?.[bkng?._id]?.displayFrequencyDiscount; else noFormParams">
					<bk-amount-display [amount]="bkngPriceObj[bkng?._id].displayFrequencyDiscount" [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
			<!-- Exempt extras price -->
			<div *ngSwitchCase="'exempt_extras_price'">
				<ng-container *ngIf="!isLineItem && bkng?.exempt_extras_price && bkng.exempt_extras_price > 0; else noFormParams">
					<bk-amount-display [amount]="bkng.exempt_extras_price" [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
			<!-- Spot discount -->
			<div *ngSwitchCase="'spot_discount'">
				<ng-container *ngIf="!isLineItem &&  bkngPriceObj?.[bkng?._id]?.displaySpotDiscount && bkngPriceObj[bkng?._id].displaySpotDiscount > 0 && !bkng.exclude_day_discount; else noFormParams">
					<bk-amount-display [amount]="bkngPriceObj[bkng?._id].displaySpotDiscount" [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
			<!-- Coupon discount -->
			<div *ngSwitchCase="'coupon_discount'">
				<ng-container *ngIf="!isLineItem && bkngPriceObj?.[bkng?._id]?.displayCouponDiscount; else noFormParams">
					<bk-amount-display [amount]="bkngPriceObj[bkng?._id].displayCouponDiscount" [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
			<!-- Service fee -->
			<div *ngSwitchCase="'service_fee'">
				<ng-container *ngIf="!isLineItem && bkng?.service_fee && !bkng.exclude_service_fee; else noFormParams">
					<bk-amount-display [amount]="bkngPriceObj[bkng?._id].displayServiceFee" [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
			<!-- Discounted total -->
			<div *ngSwitchCase="'discounted_total'">
				<ng-container *ngIf="!isLineItem && bkngPriceObj?.[bkng?._id]?.displayDiscountedAmount; else noFormParams">
					<bk-amount-display [amount]="bkngPriceObj[bkng?._id].displayDiscountedAmount" [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
			<!-- Adjusted amount -->
			<div *ngSwitchCase="'adjusted_amount'">
				<ng-container *ngIf="!isLineItem && bkng?.adjust_price; else noFormParams">
					<bk-amount-display [amount]="bkng?.adjusted_price" [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
			<!-- Sales tax -->
			<div *ngSwitchCase="'sales_tax'">
				<ng-container *ngIf="!isLineItem && bkngPriceObj?.[bkng?._id]?.displaySaleTax; else noFormParams">
					<bk-amount-display [amount]="bkngPriceObj[bkng?._id].displaySaleTax" [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
			<!-- Tip -->
			<div *ngSwitchCase="'tip'">
				<ng-container *ngIf="!isLineItem && bkngPriceObj?.[bkng?._id]?.displayTipsAmount; else noFormParams">
					<bk-amount-display [amount]="bkngPriceObj[bkng?._id].displayTipsAmount" [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
			<!-- Parking -->
			<div *ngSwitchCase="'parking'">
				<ng-container *ngIf="!isLineItem && bkngPriceObj?.[bkng?._id]?.displayParkingAmount; else noFormParams">
					<bk-amount-display [amount]="bkngPriceObj[bkng?._id].displayParkingAmount" [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
			<!-- Gift card discount -->
			<div *ngSwitchCase="'gift_card_discount'">
				<ng-container *ngIf="!isLineItem && bkngPriceObj?.[bkng?._id]?.displayGiftCardAmount; else noFormParams">
					<bk-amount-display [amount]="bkngPriceObj[bkng?._id].displayGiftCardAmount" [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
			<!-- Sub-total -->
			<!-- TODO: Harleen mam, calculate the price for sub-total and also check the below conditions -->
			<div *ngSwitchCase="'sub_total'">
				<ng-container *ngIf="!isLineItem; else noFormParams">
					<bk-amount-display [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
			<!-- priceable_custom_sections -->
			<!-- TODO: Harleen mam, calculate the price for priceable_custom_sections also check the below conditions -->
			<div *ngSwitchCase="'priceable_custom_sections'">
				<ng-container *ngIf="!isLineItem; else noFormParams">
					<bk-amount-display [isZero]="true"></bk-amount-display>
				</ng-container>
			</div>
		</div>
	</td>
	<td *ngIf="isLineItem && invData?.settings?.allow_tip_by_cust && !this.invData?.no_of_payments && invData?.settings?.tip_settings?.pay_type == 'specific'">
		<span>--</span>
	</td>
</ng-template>

<!-- Packages template -->
<ng-template #itemPackages let-item="item">
	<div class="tjs-summary__detail bk-form-sem-desc d-inline-block" *ngIf="item?.packages && (item?.packages).length > 0">
		<div class="mb-5 d-flex flex-column" *ngFor="let package of item?.packages; let i = index; let isLastPckg = last; trackBy: utilServ.trackByFnIndex">
			<span>{{elementName?.['package'][package?.id] | translate}} ({{package?.quantity}})
				<ng-container *ngIf="!isLastPckg || (package?.package_addons && (package?.package_addons).length > 0)">, </ng-container>
			</span>
			<span *ngIf="package?.package_addons && (package?.package_addons).length > 0">
				<ng-container *ngFor="let addon of package?.package_addons; let i = index; let isLast = last; trackBy: utilServ.trackByFnIndex">
					{{elementName?.['package_addons'][addon?.id] | translate}} ({{addon.quantity | translate}})
					<span *ngIf="!isLast">, </span>
				</ng-container>
			</span>
		</div>
	</div>
</ng-template>
