import { Component, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../../Services';
// Inheritance components
import { LocationComponent } from '../../../../Global/BookingForm';

@Component({
	selector: 'bk-short-form-loc',
	templateUrl: './ShortFrmLoc.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ShortFrmLocComponent extends LocationComponent {

	@Input() variationId: string = '';
	@Input() sectionIds: any;
}