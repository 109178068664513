import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// External library
import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from 'angular2-text-mask';
// Modules
import { GlobalDefaultModule, BookingFormModule } from '../Global';
// Components
import { BkngShortFormComponent, ShortFormComponent} from './index';
import { ShortFrmLocComponent, ShortFrmZipcodeComponent, ShortFrmServiceCatComponent, ShortFrmFreqComponent, ShortFrmPricingParamsComponent, ShortFrmExcludesComponent, ShortFrmExtrasComponent, ShortFrmCustomFieldsComponent, ShortFrmCustomerEmailComponent, ShortFrmSummaryComponent, ShortFrmItemsWithPckgsComponent, ShortFrmItemsWithAddonsComponent, ShortFrmPricingParameterWithAreaComponent, ShortFrmHourlyComponent } from './Components';

@NgModule({
	declarations: [BkngShortFormComponent, ShortFormComponent, ShortFrmLocComponent, ShortFrmZipcodeComponent, ShortFrmServiceCatComponent, ShortFrmFreqComponent, ShortFrmPricingParamsComponent, ShortFrmExcludesComponent, ShortFrmExtrasComponent, ShortFrmCustomFieldsComponent, ShortFrmCustomerEmailComponent, ShortFrmSummaryComponent, ShortFrmItemsWithPckgsComponent, ShortFrmItemsWithAddonsComponent, ShortFrmPricingParameterWithAreaComponent, ShortFrmHourlyComponent],
	imports: [
		CommonModule, MatDialogModule, FormsModule, ReactiveFormsModule, TranslateModule, TextMaskModule, GlobalDefaultModule, BookingFormModule
	],
	exports: [BkngShortFormComponent]
})
export class ShortFormModule { }
