import { Component, OnInit, Input, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
// Services
import { NgOnDestroy, InitServ, ApiServ, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-comments',
	templateUrl: './Comments.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class CommentsComponent implements OnInit {
	// Variables
	@Input() blogId: any;
	@Input() settings: any;
	comments: any;
	limit: number = 20;
	page: number = 1;
	totalRecords: number = 0;
	currentUser: any;
	isCommentForm: boolean = true;
	isReplyLink: boolean = false;

	constructor(public initServ: InitServ, private apiServ: ApiServ, public utilServ: UtilServ, @Self() private destroy: NgOnDestroy) {
		this.currentUser = this.utilServ.appLocalStorage();
	}

	ngOnInit(): void {
		// Get the comments
		this.getComments();
		// Comment form show/hide depend on blog settings
		if(this.settings && !this.settings.allow_anonymous_comment && !this.currentUser){
			this.isCommentForm = false;
		}
	}
	/**
	 * Get the comments based on blog id.
	 */
	private getComments(): void{
		let queryParams: any = {limit:this.limit, page:this.page, replies:'true',language:this.initServ.savedLng};
		this.apiServ.callApiWithPathQueryVars('GET', 'Comments',[this.blogId], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			if (this.page == 1) { this.totalRecords = res.data.comment_count; }
			this.comments = res.data.comments;
			this.replyLink();
		}
	}
	/**
	 * Show replay link depend on blog settings
	 */
	private replyLink(): void{
		if(!this.currentUser){
			if(this.settings && this.settings.enable_reply && this.settings.allow_anonymous_comment){
				this.isReplyLink = true
			}
		} else if(this.settings && this.settings.enable_reply){
			this.isReplyLink =  true
		} else{
			this.isReplyLink =  false;
		}
	}
	/**
	 * Override the comment data
	 * @param comment
	 */
	public commentReply(comment: any): void {
		comment.is_open_form = !comment.is_open_form;
		comment.type = 'reply';
	}
	/**
	 * Get the page number, get the blog comments based on page number
	 */
	public pageChange(event: any): void{
		this.page = event;
		this.getComments();
	}
	/**
	 * Is comment form submit, get the blog comments.
	 */
	public submitCommentForm(value: boolean): void{
		if(value){
			this.getComments();
		}
	}
}
