<div class="position-relative" [formGroup]="customerForm">
	<div [ngClass]="variationId == 'bk_short_form_V3' ? 'email_version_3' : 'email_version_4'" [id]="section?.id" formGroupName="customer" *ngIf="settings && section">
		<div [ngClass]="{'row justify-content-center' : variationId == 'bk_short_form_V4'}">
			<div [ngClass]="{'col-lg-10 col-xl-8' : variationId == 'bk_short_form_V4'}">
				<!-- Title -->
				<div class="mb-30" *ngIf="section?.title">
					<h4 class="mb-0 bk-title" [ngClass]="variationId == 'bk_short_form_V3' ? 'fw-bold' : 'display-5 text-center fw-normal'" [id]="sectionIds?.title"  [innerHTML]="section?.title | safeHtml"></h4>
				</div>
				<div class="mb-20">
					<div [ngClass]="{'shadow-sm mb-30 p-20 px-md-10 py-md-5 d-md-flex bg-white rounded align-items-center' : variationId == 'bk_short_form_V4'}">
						<!-- Label -->
						<label class="form-label bk-form-label" [ngClass]="{'px-5 col-md-6 mb-md-0' : variationId == 'bk_short_form_V4'}" *ngIf="section?.email" [id]="sectionIds?.label" [innerHTML]="section?.email | safeHtml"></label>
						<!-- Email id -->
						<div [ngClass]="{'px-5 tjs-border-start-md col-md-6' : variationId == 'bk_short_form_V4'}">
							<ng-container *ngIf="customerGroup.controls['customer_type'].value == 'existing customer'; else newCust">
								<input class="form-control bk-input" [ngClass]="{'tjs-border-sm-0 tjs-shadow-sm-none' : variationId == 'bk_short_form_V4'}" placeholder="{{section?.email_placeholder}}" formControlName="email_id" readonly>
							</ng-container>
							<ng-template #newCust>
								<input class="form-control bk-input {{section?.email_errors?.existing_email_id}}" placeholder="{{section?.email_placeholder}}" formControlName="email_id" (focusout)="emailExistControl(customerGroup.controls['email_id'], true, false, 'theme_builder_short_form')" [ngClass]="{'is-invalid': customerGroup.controls['email_id']?.touched && (customerGroup.controls['email_id']?.errors?.required || customerGroup.controls['email_id']?.errors?.pattern || customerGroup.controls['email_id']?.errors?.emailExists), 'tjs-border-sm-0 tjs-shadow-sm-none' : variationId == 'bk_short_form_V4'}" [class.existing-email]="section?.email_errors && section?.email_errors?.existing_email && customerGroup.controls['email_id']?.errors?.emailExists">
								<!-- Error -->
								<div class="tjs-error bk-error" *ngIf="(customerGroup.controls['email_id']?.touched && (customerGroup.controls['email_id']?.errors?.required || customerGroup.controls['email_id']?.errors?.pattern))">
									<i class="tjsicon-attention"></i>
									<ng-container *ngIf="customerGroup.controls['email_id']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
									<ng-container *ngIf="customerGroup.controls['email_id']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.validEmail | translate}} </ng-container>
								</div>
								<div [id]="section?.email_errors?.existing_email_id" class="tjs-error bk-error" *ngIf="section?.email_errors && section?.email_errors?.existing_email && customerGroup.controls['email_id']?.errors?.emailExists">
									<i class="tjsicon-attention"></i>
									{{section?.email_errors?.existing_email}}
								</div>
							</ng-template>
						</div>
					</div>
				</div>
				<ng-container *ngIf="customerGroup.controls['email_id']?.errors?.emailExists || (customerGroup.controls['customer_type'].value == 'existing customer' && !currentUser)">
					<!-- Password -->
					<div id="password-section-id" class="mb-10" [ngClass]="{'shadow-sm p-15 d-md-flex bg-white rounded align-items-center' : variationId == 'bk_short_form_V4'}">
						<div class="d-flex" [ngClass]="{'px-5 col-md-6 mb-md-0' : variationId == 'bk_short_form_V4'}">
							<label class="form-label bk-form-label"  *ngIf="section?.password" [id]="sectionIds?.label" [innerHTML]="section?.password | safeHtml"></label>
							<bk-tooltip *ngIf="section?.password_tooltip" class="bk-tooltip" [id]="section?.password_tooltip_id" [content]="section?.password_tooltip | translate"></bk-tooltip>
						</div>
						<div class="d-flex align-items-center" [ngClass]="{'px-5 flex-column tjs-border-start-md col-md-6' : variationId == 'bk_short_form_V4'}" >
							<input type="password" class="form-control bk-input" formControlName="password" placeholder="{{section?.password_placeholder}}" [ngClass]="{'is-invalid': customerGroup.controls['password']?.touched && customerGroup.controls['password']?.errors,'tjs-border-sm-0 tjs-shadow-sm-none' : variationId == 'bk_short_form_V4'}">
							<!-- Error -->
							<div class="tjs-error bk-error w-100" [ngClass]="{'d-none-imp' : variationId == 'bk_short_form_V3'}" *ngIf="customerGroup.controls['password']?.touched && customerGroup.controls['password']?.errors">
								<i class="tjsicon-attention"></i>
								{{initServ?.appStr?.errorMsg?.empty | translate}}
							</div>
						</div>
						<!-- Error -->
						<div class="tjs-error bk-error w-100" [ngClass]="{'d-none-imp' : variationId == 'bk_short_form_V4'}" *ngIf="customerGroup.controls['password']?.touched && customerGroup.controls['password']?.errors">
							<i class="tjsicon-attention"></i>
							{{initServ?.appStr?.errorMsg?.empty | translate}}
						</div>
					</div>
					<!-- Forgot password -->
					<a href="javascript:void(0)" class="mb-10" [id]="section?.forgot_password_id" [innerHTML]="section?.forgot_password | safeHtml" (click)="forgotPwd()"></a>
				</ng-container>
				<!-- Cookie content -->
				<div class="mt-10 mb-20" *ngIf="section?.cookie_content">
					<span class="bk-cookie fs-14" [id]="section?.cookie_content_id" [innerHTML]="section?.cookie_content | safeHtml"></span>
				</div>
			</div>
		</div>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>

