<div [id]="secId" class="tjs-banner position-relative tjs-py-140 tjs-bg-style {{variationClass[pageSett[secId]?.variation_id]}} bk-media" [ngStyle]="{'background-image' : (section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}" *ngIf="pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div [id]="section?.media?.id" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0" class="tjs-opacity--bg"></div>
	<div class="container">
		<!-- Call us -->
		<div class="text-center text-md-start mb-30 mb-md-0" *ngIf="section?.give_us_call && (section?.give_us_call?.title || section?.give_us_call?.number)">
			<div [id]="section?.give_us_call?.id" class="tjs-banner__contact text-start bg-primary bk-group px-15 py-10 d-inline-block">
				<!-- Title --><span [id]="section?.give_us_call?.title_id" class="bk-gp-title text-white d-block" *ngIf="section?.give_us_call?.title" [innerHTML]="section?.give_us_call?.title | safeHtml"></span>
				<!-- Number -->
				<a [id]="section?.give_us_call?.number_id" class="bk-gp-sub-title text-white fw-bold dir-ltr" *ngIf="section?.give_us_call?.number" href="tel: {{section?.give_us_call?.number}}" [innerHTML]="section?.give_us_call?.number | safeHtml"></a>
			</div>
		</div>
		<div [ngClass]="pageSett[secId]?.variation_id == 'bk_banner_V7' ? 'tjs-banner__inner--rounded' : 'tjs-banner__content tjs-banner__overlay py-40 py-md-70 px-20 px-md-40 position-relative mx-auto text-center'">
			<div [ngClass]="pageSett[secId]?.variation_id == 'bk_banner_V5' ? 'mb-35' : 'mb-20'" *ngIf="(section?.title && section?.title?.content) || (section?.desc && section?.desc?.content)">
				<!-- Title -->
				<ng-container *ngIf="section?.title && section?.title?.content">
					<h2 [id]="section?.title?.id" *ngIf="(pageSett[secId]?.variation_id == 'bk_banner_V1' || pageSett[secId]?.variation_id == 'bk_banner_V6'); else otherVarHeading" class="bk-title" [ngClass]="pageSett[secId]?.variation_id == 'bk_banner_V6' ? 'fw-bold display-1' : 'display-1'" [innerHTML]="section?.title?.content | safeHtml"></h2>
					<ng-template #otherVarHeading>
						<h3 [id]="section?.title?.id" class="mb-0 bk-title display-5 fw-bold" [innerHTML]="section?.title?.content | safeHtml"></h3> </ng-template>
				</ng-container>
				<!-- Description -->
				<div class="tjs-banner__description mt-20 bk-desc" *ngIf="section?.desc && section?.desc?.content">
					<p [id]="section?.desc?.id" [innerHTML]="section?.desc?.content | safeHtml"></p>
				</div>
			</div>
			<!-- Sub title -->
			<div *ngIf="section?.sub_title && section?.sub_title?.content">
				<h3 [id]="section?.sub_title?.id" [ngClass]="pageSett[secId]?.variation_id == 'bk_banner_V6' ? 'fw-bold mb-20 mb-md-40 display-6' : 'fw-light mb-20 mb-md-50 display-6'" class="bk-sub-title" [innerHTML]="section?.sub_title?.content | safeHtml"></h3> </div>
			<!-- List -->
			<div [id]="section?.list?.id" class="row bk-list justify-content-center" *ngIf="section?.list && section?.list?.items && (section?.list?.items).length>0">
				<ng-container *ngFor="let item of section?.list?.items let i=index;trackBy: secServ.trackByFnIndex">
					<div [id]="item?.id" class="col-12 col-md-4 px-lg-25 px-10 my-15" [class.mt-cus-sm-30]="i>2" *ngIf="(item?.media && (item?.media).length>0) || item?.desc">
						<div class="bk-item" [ngClass]="{'tjs-banner__rounded-item text-center d-flex justify-content-center align-items-center mx-auto rounded-circle bg-black-1' : pageSett[secId]?.variation_id == 'bk_banner_V7'}">
							<div>
								<!-- Image --><img loading="auto" [id]="item?.media_id" *ngIf="item?.media && (item?.media).length>0" class="img-fluid mb-15 bk-item-media" alt="banner-item" [attr.width]="pageSett[secId]?.variation_id == 'bk_banner_V7' ? '120' :'38'" [attr.height]="pageSett[secId]?.variation_id == 'bk_banner_V7' ? '120' :'38'" [src]="item?.media[0]" />
								<!-- Description -->
								<p [id]="item?.desc_id" class="bk-item-desc mb-0" [ngClass]="pageSett[secId]?.variation_id == 'bk_banner_V7' ? 'tjs-banner__rounded-desc h5 text-white fw-bold' : ''" *ngIf="item?.desc" [innerHTML]="item?.desc | safeHtml"></p>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
			<!-- Button -->
			<div class="tjs-navigate-wrapper bk-btn-group" *ngIf="section?.button && section?.button?.buttons && (section?.button?.buttons).length > 0">
				<ng-container *ngFor="let btn of section?.button?.buttons;trackBy: secServ.trackByFnIndex">
					<bk-navigate [secSett]="btn" [innerHTML]="btn?.content"></bk-navigate>
				</ng-container>
			</div>
		</div>
	</div>
</div>