<div class="position-relative">
	<form bkDebounceEvent [isSubmit]="true" [formGroup]="ratingForm" (debounceSubmit)="submitForm()">
		<div class="tjs-card card border rounded-5 tjs-rounded--lg mb-30">
			<div class="card-body">
				<div>
					<h5 class="fw-bold">{{initServ?.appStr?.text?.ratings | translate}}</h5>
				</div>
				<div class="tjs-d-border__top mt-20 pt-20">
					<h5 *ngIf="prefilledData?.ratings?.id" class="mb-10 fw-bold"> {{initServ?.appStr?.text?.updateRatings | translate}}</h5>
					<div class="mb-15">
						<bk-rating-display customClass="tjs-rating--md" [rating]="ratingForm.value['rating']" (ratingCallback)="ratingForm.controls['rating'].setValue($event)"></bk-rating-display>
					</div>
					<textarea class="form-control" rows="5" formControlName="review" attr.placeholder="{{initServ?.appStr?.text?.yourFeedback | translate}}"></textarea>
					<!-- Submit -->
					<bk-navigate  innerHTML="{{initServ?.appStr?.text?.updateReview | translate}}" [isSubmit]="true" customClass="btn btn-primary mt-20"></bk-navigate>
				</div>
			</div>
		</div>
	</form>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>