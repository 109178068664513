<div [id]="secId" class="tjs-thankyou py-50 {{variationClass[pageSett[secId]?.variation_id]}}" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<ng-container *ngIf="!resetPass; else resetPassword">
		<div class="container">
			<div class="row" [ngSwitch]="varId">
				<!-- V1 -->
				<ng-container *ngSwitchDefault>
					<div class="col-sm-12 col-md-12 col-lg-8 mx-auto" *ngIf="section?.thank_you">
						<div [id]="section?.thank_you?.id" class="tjs-thankyou__inner rounded-5 text-center">
							<!-- Title -->
							<h2 [id]="section?.thank_you?.title_id" *ngIf="section?.thank_you?.title" class="tjs-thankyou__title fw-bold bk-title" [innerHTML]="section?.thank_you?.title | safeHtml"></h2>
							<!-- Sub title -->
							<h3 [id]="section?.thank_you?.sub_title_id" *ngIf="section?.thank_you?.sub_title" class="mb-20 fw-bold bk-sub-title" [innerHTML]="section?.thank_you?.sub_title | safeHtml"></h3>
							<!-- Description -->
							<p [id]="section?.thank_you?.desc_id" *ngIf="section?.thank_you?.desc" class="mb-35 bk-desc" [innerHTML]="section?.thank_you?.desc | safeHtml"></p>
							<!-- Button -->
							<div [id]="section?.thank_you?.button?.id" class="bk-btn-group" *ngIf="section?.thank_you?.button && section?.thank_you?.button?.buttons && (section?.thank_you?.button?.buttons).length > 0">
								<div class="tjs-navigate-wrapper justify-content-center flex-column">
									<ng-container *ngFor="let btn of section?.thank_you?.button?.buttons;trackBy: secServ.trackByFnIndex">
										<bk-navigate [secSett]="btn" [innerHTML]="btn?.content"></bk-navigate>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
				<!-- V2 -->
				<ng-container *ngSwitchCase="'bk_thank_you_V2'">
					<div class="col-lg-6 mx-auto" *ngIf="section?.thank_you">
						<div class="tjs-thankyou__inner rounded-5 text-center p-0 shadow-sm overflow-hidden" >
							<div class="p-30 tjs-bg-gray" *ngIf="section?.thank_you?.media && (section?.thank_you?.media).length > 0">
								<!-- Media -->
								<img loading="auto" [id]="section?.thank_you?.media_id" class="mb-25 img-fluid-disabled bk-media" [src]="section?.thank_you?.media[0]" width="100" height="100" alt="Media">
							</div>
							<ng-container *ngIf="msgStatus; else elseBlock">
								<div class="pt-20 pb-50 px-25 px-md-70 rounded-5 bg-white">
									<!-- Success message -->
									<h4 [id]="section?.thank_you?.form?.success_msg_id" *ngIf="section?.thank_you?.form?.success_msg" class="mb-20 fw-bold bk-title" [innerHTML]="section?.thank_you?.form?.success_msg | safeHtml"></h4>
									<!-- Button -->
									<div class="d-flex justify-content-between flex-column align-items-center" *ngIf="section?.thank_you?.button && section?.thank_you?.button?.buttons && (section?.thank_you?.button?.buttons).length > 0">
										<div [id]="section?.thank_you?.button?.id" class="d-inline-block w-100 mx-0 bk-btn-group">
											<ng-container *ngFor="let btn of section?.thank_you?.button?.buttons;trackBy: secServ.trackByFnIndex">
												<bk-navigate [secSett]="btn" [innerHTML]="btn?.content" essentialClass="w-100 mx-0"></bk-navigate>
											</ng-container>
										</div>
									</div>
								</div>
							</ng-container>
							<ng-template #elseBlock>
								<div class="pt-35 pb-50 px-25 px-md-70 bg-white position-relative" [id]="section?.thank_you?.id">
									<!-- Title -->
									<h2 [id]="section?.thank_you?.title_id" *ngIf="section?.thank_you?.title" class="tjs-thankyou__title fw-bold bk-title" [innerHTML]="section?.thank_you?.title | safeHtml"></h2>
									<ng-container *ngIf="showMsg">
										<!-- Sub title -->
										<h3 [id]="section?.thank_you?.sub_title_id" *ngIf="section?.thank_you?.sub_title" class="mb-5 fw-bold bk-sub-title" [innerHTML]="section?.thank_you?.sub_title | safeHtml"></h3>
										<!-- Extra sub title -->
										<h4 [id]="section?.thank_you?.extra_sub_title_id" *ngIf="section?.thank_you?.extra_sub_title" class="mb-20 fw-bold bk-sub-title" [innerHTML]="section?.thank_you?.extra_sub_title | safeHtml"></h4>
										<!-- Description -->
										<p [id]="section?.thank_you?.desc_id" *ngIf="section?.thank_you?.desc" class="mb-35 fs-15 bk-desc" [innerHTML]="section?.thank_you?.desc | safeHtml"></p>
										<!-- Fields -->
										<div>
											<div [id]="section?.thank_you?.form?.name?.id" class="mb-25" *ngIf="section?.thank_you?.form?.name">
												<label class="form-label bk-form-label d-block text-start" [id]="section?.thank_you?.form?.name?.label_id" *ngIf="section?.thank_you?.form?.name?.label" [innerHTML]="section?.thank_you?.form?.name?.label | safeHtml"></label>
												<input class="form-control" [placeholder]="section?.thank_you?.form?.name?.placeholder" [ngModel]="firstName" readonly>
											</div>
											<div [id]="section?.thank_you?.form?.phone?.id" class="mb-25" *ngIf="section?.thank_you?.form?.phone">
												<label class="form-label bk-form-label d-block text-start" [id]="section?.thank_you?.form?.phone?.label_id" *ngIf="section?.thank_you?.form?.phone?.label" [innerHTML]="section?.thank_you?.form?.phone?.label | safeHtml"></label>
												<div class="input-group input-group-ltr">
													<div class="input-group-text" *ngIf="initServ?.callingCode">
														<span>{{initServ?.callingCode}}</span>
													</div>
													<input class="form-control" type="text" [textMask]="{mask: phoneMask}" [placeholder]="section?.thank_you?.form?.phone?.placeholder" [ngModel]="phone" readonly>
												</div>
											</div>
											<div [id]="section?.thank_you?.form?.email?.id" class="mb-25" *ngIf="section?.thank_you?.form?.email">
												<label class="form-label bk-form-label d-block text-start" [id]="section?.thank_you?.form?.email?.label_id" *ngIf="section?.thank_you?.form?.email?.label" [innerHTML]="section?.thank_you?.form?.email?.label | safeHtml"></label>
												<input class="form-control" [placeholder]="section?.thank_you?.form?.email?.placeholder" [ngModel]="email" readonly>
											</div>
										</div>
									</ng-container>
									<!-- Buttons -->
									<div class="d-flex justify-content-between flex-column align-items-center">
										<div class="w-100 mx-0 bk-btn-group" *ngIf="showMsg && section?.thank_you?.form && section?.thank_you?.form?.claim_btn && section?.thank_you?.form?.claim_btn?.content">
											<bk-navigate [isDebounce]="true" [secId]="section?.thank_you?.form?.claim_btn_id" [designId]="section?.thank_you?.form?.claim_btn?.design_id" [innerHTML]="section?.thank_you?.form?.claim_btn?.content" customClass="btn btn-lg btn-primary border-transparent" essentialClass="mb-15 mx-0 w-100" (callback)="claim()"></bk-navigate>
										</div>
										<div class="w-100 mx-0" *ngIf="section?.thank_you?.button && section?.thank_you?.button?.buttons && (section?.thank_you?.button?.buttons).length > 0">
											<div [id]="section?.thank_you?.button?.id" class="d-inline-block w-100 bk-btn-group">
												<div class="d-flex flex-column">
													<ng-container *ngFor="let btn of section?.thank_you?.button?.buttons;trackBy: secServ.trackByFnIndex">
														<bk-navigate class="px-0-imp my-10" [secSett]="btn" [innerHTML]="btn?.content" essentialClass="w-100 mx-0"></bk-navigate>
													</ng-container>
												</div>
											</div>
										</div>
									</div>
									<!-- Loader -->
									<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
								</div>
							</ng-template>
						</div>
					</div>
				</ng-container>
				<!-- V3 -->
				<ng-container *ngSwitchCase="'bk_thank_you_V3'">
					<div class="col-lg-6 mx-auto">
						<div [id]="section?.thank_you?.id" class="tjs-thankyou__inner rounded-5 text-center">
							<!-- Media -->
							<img loading="auto" [id]="section?.thank_you?.media_id" class="img-fluid mb-30 bk-media" *ngIf="section?.thank_you?.media && (section?.thank_you?.media).length > 0" [src]="section?.thank_you?.media[0]" width="120" alt="Media" height="120">
							<!-- Title -->
							<h2 [id]="section?.thank_you?.title_id" *ngIf="section?.thank_you?.title" class="tjs-thankyou__title fw-bold bk-title" [innerHTML]="section?.thank_you?.title | safeHtml"></h2>
							<ng-container *ngIf="showMsg">
								<!-- Sub title -->
								<h4 [id]="section?.thank_you?.sub_title_id" *ngIf="section?.thank_you?.sub_title" class="mb-10 fw-bold bk-sub-title" [innerHTML]="tokenReplace(section?.thank_you?.sub_title) | safeHtml"></h4>
								<!-- Extra sub title -->
								<h6 [id]="section?.thank_you?.extra_sub_title_id" *ngIf="section?.thank_you?.extra_sub_title" class="mb-10 fw-bold bk-sub-title" [innerHTML]="tokenReplace(section?.thank_you?.extra_sub_title) | safeHtml"></h6>
								<!-- Description -->
								<p [id]="section?.thank_you?.desc_id" *ngIf="section?.thank_you?.desc" class="mb-35 bk-desc" [innerHTML]="tokenReplace(section?.thank_you?.desc) | safeHtml"></p>
								<!-- Social btn -->
								<div *ngIf="currentUser && currentUser.id && (section?.thank_you?.form?.fb_btn || section?.thank_you?.form?.twitter_btn || section?.thank_you?.form?.send_btn)">
									<!-- Share via label -->
									<p [id]="section?.thank_you?.share_label_id" class="bk-share-label" *ngIf="section?.thank_you?.share_label" [innerHTML]="section?.thank_you?.share_label | safeHtml"></p>
									<!-- Share via buttons -->
									<div class="tjs-share-buttons d-sm-flex justify-content-center flex-wrap mb-25">
										<!-- Facebook -->
										<bk-navigate *ngIf="fbAppId && section?.thank_you?.form?.fb_btn" [secId]="section?.thank_you?.form?.fb_btn_id" [designId]="section?.thank_you?.form?.fb_btn?.design_id" customClass="btn text-white fs-14 p-10" essentialClass="d-flex justify-content-center align-items-center tjs-facebook-color mb-10 me-10 bk-gp-btn" (callback)="socialServ.postOnFacebook(fbAppId, referralUrl)" innerHTML="<i class='fa fa-facebook ty-icon d-flex justify-content-center align-items-center thankyou-icon fs-20 me-5' aria-hidden='true'></i> {{section?.thank_you?.form?.fb_btn?.content}}"></bk-navigate>
										<!-- Twitter -->
										<bk-navigate *ngIf="section?.thank_you?.form?.twitter_btn" [secId]="section?.thank_you?.form?.twitter_btn_id" [designId]="section?.thank_you?.form?.twitter_btn?.design_id" customClass="btn text-white fs-14 p-10" essentialClass="d-flex align-items-center justify-content-center bg-black mb-10 me-10 bk-gp-btn" (callback)="socialServ.tweetOnTwitter(referralUrl, '', defReferralAmt, overrideDesc)" innerHTML="<img src='{{initServ.imgBase}}/assets/images/twitter-x-black.png' alt='twitter-x-black logo' width='18' height='18' class='me-5'> {{section?.thank_you?.form?.twitter_btn?.content}}"></bk-navigate>
										<!-- Send  -->
										<bk-navigate *ngIf="section?.thank_you?.form?.send_btn && (section?.thank_you?.form.email || section?.thank_you?.form?.sms)" [secId]="section?.thank_you?.form?.send_btn_id" [designId]="section?.thank_you?.form?.send_btn?.design_id" customClass="btn fs-14 p-10 rounded btn-primary border-transparent" essentialClass="d-flex align-items-center justify-content-center bk-gp-btn" (callback)="shareRefPopup()" innerHTML="<i class='fa fa-envelope-o ty-icon d-flex justify-content-center align-items-center thankyou-icon fs-20 me-5' aria-hidden='true'></i> {{section?.thank_you?.form?.send_btn?.content}}"></bk-navigate>
									</div>
								</div>
							</ng-container>
							<!-- Button -->
							<div [id]="section?.thank_you?.button?.id" class="bk-btn-group" *ngIf="section?.thank_you?.button && section?.thank_you?.button?.buttons && (section?.thank_you?.button?.buttons).length > 0">
								<div class="d-flex justify-content-center flex-column w-100">
									<ng-container *ngFor="let btn of section?.thank_you?.button?.buttons;trackBy: secServ.trackByFnIndex">
										<bk-navigate class="my-5" [secSett]="btn" [innerHTML]="btn?.content" essentialClass="w-100"></bk-navigate>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
				<!-- V4 -->
				<ng-container *ngSwitchCase="'bk_thank_you_V4'">
					<div class="col-lg-6 mx-auto">
						<div [id]="section?.thank_you?.id" class="p-30 rounded-5 text-center">
							<!-- Media -->
							<img [id]="section?.thank_you?.media_id" [src]="section?.thank_you?.media[0]" width="120" alt="Media" height="120"  class="img-fluid mb-20 bk-media" *ngIf="section?.thank_you?.media && (section?.thank_you?.media).length > 0">
							<h2 [id]="section?.thank_you?.title_id" class="fw-bold " [innerHTML]="section?.thank_you?.title"></h2>
							<h5 [id]="section?.thank_you?.desc_id" [innerHTML]="section?.thank_you?.desc" class="bk-sub-title"></h5>
							<div class="w-100 mx-0" *ngIf="section?.thank_you?.button && section?.thank_you?.button?.buttons && (section?.thank_you?.button?.buttons).length > 0">
								<div [id]="section?.thank_you?.button?.id" class="d-inline-block w-100 bk-btn-group">
									<div class="d-flex flex-column">
										<ng-container *ngFor="let btn of section?.thank_you?.button?.buttons;trackBy: secServ.trackByFnIndex">
											<bk-navigate [secId]="btn?.id" class="px-0-imp my-10" [secSett]="btn" [innerHTML]="btn?.content" [designId]="btn?.design_id" essentialClass="mx-0"></bk-navigate>
										</ng-container>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</ng-container>
	<ng-template #resetPassword>
		<bk-reset-password *ngIf="section?.form" [section]="section?.form" [queryParams]="queryParams" ></bk-reset-password>
	</ng-template>
</div>


