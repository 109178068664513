<div [id]="secId" class="tjs-contact-page py-80 tjs-bg-style position-relative" [ngStyle]="{'background-image' : (section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div [id]="section?.media?.id" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0" class="tjs-opacity--bg"></div>
	<div class="container">
		<div class="row" [ngClass]="{'justify-content-center': !(section?.reviews && section?.faq)}">
			<div class="col-12 col-sm-12 col-md-12 col-lg-8 mb-30 mb-lg-0" *ngIf="section?.form">
				<div [id]="section?.form?.id" *ngIf="embedFormUrl" style="background-color: transparent !important;">
					<iframe id="bkQuoteForm" [src]='embedFormUrl' style="border:none;" [height]="height" width="100%" [scrolling]="scrolling"></iframe>
					<script [src]='embedJsUrl' defer></script>
				</div>
			</div>
			<!-- Side panel widgets-->
			<div class="col-12 col-lg-4" *ngIf="section?.reviews || section?.faq">
				<!-- Live reviews -->
				<bk-live-review *ngIf="section?.reviews" [section]="section?.reviews" pageType="lead"></bk-live-review>
				<!-- FAQ -->
				<bk-faq-list *ngIf="section?.faq" [section]="section?.faq" pageType="lead"></bk-faq-list>
			</div>
		</div>
	</div>
</div>
