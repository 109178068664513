import { Component, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy } from '../../Services';
// Inheritance component
import { CommonAddFormComponent } from '../index';
// import { MultiServiceProviderComponent } from '../../Global/BookingForm/MultiStepComp';
@Component({
	selector: 'bk-multi-step-form',
	templateUrl: './MultiStepForm.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class MultiStepFormComponent extends CommonAddFormComponent {

	// @ViewChild(MultiServiceProviderComponent) multiServiceProviderComp : MultiServiceProviderComponent | undefined; //Multi step service provider
	isButtonDisabled : boolean = false;
	/**
	 * Goto select form section
	 */
	public backToFormSelection(): void {
		this.setMultiStpFrmFrstStp.emit(false);
		window.scrollTo({top: 0, behavior: 'smooth'});
		this.bkngFormServ.scrollParentToTop()
	}
	/**
	 * Goto previour step
	 * @param event
	 */
	// eslint-disable-next-line complexity
	public prevStep(event: any): void {
		if(((+this.formId != 5 && this.activeStep == 2) || (+this.formId == 5 && this.isStepAvailFormFive && this.activeStep == 2) || (+this.formId == 5 && !this.isStepAvailFormFive && this.activeStep == 1) || ((!this.isStepOneAvail && +this.formId != 5) && this.activeStep == 1)) && this.BKFrm.controls['arrival_date'].value){
			if(this.multiServiceProviderComp){
				this.multiServiceProviderComp.resetDateTime();
			}
		} else{
			this.bkngCustSecServ.addRemoveStepWiseValOnCustFields(false, this.bkngCustSecServ.getStep(this.activeStep));
			let par = event.target.parentNode;
			let ppar = par.parentNode.parentNode;
			let el = ppar.previousElementSibling;
			if(el.firstElementChild && el.firstElementChild.firstElementChild){
				this.activeStep = this.activeStep - 1;
			} else{
				this.activeStep = this.activeStep - 2;
			}
		}
		window.scrollTo({top: 0, behavior: 'smooth'});
		if(this.utilServ.inIframe(this.utilServ.embedStatus)){
			this.bkngFormServ.scrollParentToTop()
		}
		let activeCalEl = document.querySelectorAll('.tjs-bcalendar__active');
		if(activeCalEl && activeCalEl.length >0){
			activeCalEl.forEach(element => element.classList.remove('tjs-bcalendar__active'));
		}
		let resizeEvent:any = window.document.createEvent('UIEvents');
		resizeEvent.initUIEvent('resize', true, false, window, 0);
		window.dispatchEvent(resizeEvent);
		// Refresh the items/packages component
		if(this.itemsWithPckgsComp){
			this.itemsWithPckgsComp?.refresh();
		}
		this.addBkngServ.refreshMultiStepSlider();
	}
	/**
	 * Goto next step
	 * @param event
	 * @param step
	 */
	public nextStep(event: any, step: any){
		this.isButtonDisabled = true;
		let par = event.target.parentNode;
		let ppar = par.parentNode.parentNode;
		let el = ppar.nextElementSibling;
		this.partCheckEmailVali(step);
		setTimeout(()=>{
			if(this.validateSteps(step)){
				if(el.firstElementChild && el.firstElementChild.firstElementChild){
					this.activeStep = this.activeStep + 1;
				} else {
					this.activeStep = this.activeStep + 2;
				}
				this.bkngCustSecServ.addRemoveStepWiseValOnCustFields(true, this.bkngCustSecServ.getStep(this.activeStep));
				window.scrollTo({top: 0, behavior: 'smooth'});
				if(this.utilServ.inIframe(this.utilServ.embedStatus)){
					this.bkngFormServ.scrollParentToTop()
				}
				if(this.customerComp){
					this.customerComp?.refresh();
				}
				// Refresh the custom field component
				if(this.customFieldsComp){
					this.customFieldsComp?.markAllCustFieldsAsUntouched();
				}
				// Refresh the items/packages component
				if(this.itemsWithPckgsComp){
					this.itemsWithPckgsComp?.refresh();
				}
				// Add contact to lead
				this.addContactToLead();
				this.cDRef.detectChanges();
			}
			this.isButtonDisabled = false;
		},500);
		this.addBkngServ.refreshMultiStepSlider();
	}
}
