<div class="text-center bkt-side-img mb-30 min-height--100" *ngIf="fbShareStatus && sectionStatus && section">
	<a data-bs-toggle="modal" href="#shareOnFb" role="button" (click)="utilServ.showPopup('shareOnFb')">
		<ng-container *ngIf="section?.urls && (section?.urls).length > 0 && section?.urls[0]; else defaultFbImg">
		<img loading="auto" [id]="section?.id" [src]="section?.urls[0]" class="img-fluid" alt="Facebook" height="350" width="740" />
		</ng-container>
		<ng-template #defaultFbImg>
			<img loading="auto" [id]="section?.id" [src]="initServ?.img?.FbShare" alt="Facebook" class="img-fluid" height="350" width="740" />
		</ng-template>
	</a>
</div>

<!-- Modal -->
<div id="shareOnFb" class="modal fade" aria-hidden="true" tabindex="-1">
	<div class="modal-dialog modal-dialog-centered mw-100 mx-15">
		<div class="modal-content tjs-popup tjs-popup--sm border-0 mx-auto position-relative">
			<div class="px-20 pb-20 pt-15 d-flex justify-content-end">
				<button type="button" class="btn-close fs-13 tjs-popup__closed" data-bs-dismiss="modal" aria-hidden="true"><i class="tjsicon-close text-danger"></i></button>
			</div>
			<div class="modal-body tjs-popup__content text-center">
				<div class="fb-share-popup-content text-center" [innerHTML]="couponText | translate | safeHtml"></div>
			</div>
			<!-- Submit button -->
			<div class="tjs-popup__footer d-flex">
				<bk-navigate *ngIf="fbAppId" class="w-100" customClass="btn btn-lg" essentialClass="w-100 text-white tjs-facebook-btn d-flex align-items-center justify-content-center mb-0" (callback)="socialServ.postOnFacebook(fbAppId, siteUrl, true, 'booking')" innerHTML="<i class='fa fa-facebook-official me-15' aria-hidden='true'></i>{{initServ?.appStr?.text?.shareOnFacebook | translate}}"></bk-navigate>
				<div class="d-none">
					<button type="button" class="show-fb-coupon-btn" (click)="getFbCoupon()"></button>
				</div>
			</div>
			<!-- Loader -->
			<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
		</div>
	</div>
</div>
<!-- Facebook coupon modal -->
<div id="fbCoupon" class="modal fade nested-modal" aria-hidden="true" tabindex="-1">
	<div class="modal-dialog modal-dialog-centered mw-100 mx-15">
		<div class="modal-content tjs-popup tjs-popup--sm border-0 mx-auto">
			<div class="px-20 pb-20 pt-15 d-flex justify-content-end">
				<button type="button" class="btn-close fs-13 tjs-popup__closed" data-bs-dismiss="modal" aria-hidden="true"><i class="tjsicon-close text-danger"></i></button>
			</div>
			<div class="modal-body tjs-popup__content text-center">
				<h2>{{initServ?.appStr?.text?.congratulations | translate}}</h2>
				<h6>{{initServ?.appStr?.text?.yourCouponIsHere | translate}}</h6>
				<span class="d-block">{{initServ?.appStr?.text?.youCanNowUseItDuringCheckout | translate}}</span>
			</div>
			<div class="tjs-popup__footer text-center">
				<span>{{initServ?.appStr?.text?.couponCode | translate}} : <b>{{fbCode}}</b></span>
			</div>
		</div>
	</div>
</div>