import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ } from '../../Services';

@Component({
	selector: 'bk-row',
	templateUrl: './Row.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class RowComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		cols:null
	}
	elements: any;
	sections: string[] = [];
	colCount: any = 1;
	colClass: string = '';
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, private initServ: InitServ, private cDRef: ChangeDetectorRef) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId]
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.pageSett = this.sectionData.section_settings;
			this.secServ.setServData(this.pageSett, this.sectionData.content);
			if(this.pageSett && this.pageSett[this.secId] && this.pageSett[this.secId].col_count){
				this.colCount = this.pageSett[this.secId].col_count;
				this.colClass = 'col-lg-'+Math.round(12/this.colCount);
			}
			let secElem = this.sectionData.sections[this.secId];
			this.section['cols'] = (secElem['cols'] && this.secServ.checkEleStatus(this.sectionData.section_settings, secElem['cols'])) ? secElem['cols'] : null;
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Get Image url
	 * @param id: section id
	 * @returns url
	 */
	public getMedia(id: any): any {
		if(this.pageSett[id] && this.pageSett[id]?.elements?.media && this.getMediaUrlsSett(this.pageSett[id]?.elements?.media)){
			let urls: any = this.getMediaUrlsSett(this.pageSett[id]?.elements?.media);
			if(urls && urls.length > 0){
				return urls[0];
			}
			return '';
		}
	}

	/**
	 * Return the element urls
	 * @param id : element id
	 * @returns urls/null
	 */
	 public getMediaUrlsSett(id:string): any{
		if(id && this.secServ.checkEleStatus(this.pageSett, id) && this.pageSett && ((this.pageSett[id].urls && (this.pageSett[id].urls).length > 0) || (this.pageSett[id].thumbnails && (this.pageSett[id].thumbnails).length > 0))){
			let urls : string[] = [];
			for(let url of this.pageSett[id].urls){
				if(url){
					url = this.initServ.imgBase + ((url && url.startsWith("/")) ? url : '/'+url);
					urls.push(url);
				}
			}
			if(urls && urls.length > 0){
				return urls;
			}
		} else {
			return null;
		}
	}
}