import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
// Services
import { ApiServ, BkngFormServ, BkngListPriceCalServ, BkngPrice, BuildCustomSectionService, InitServ, LoaderServ, NgOnDestroy, UtilServ } from 'src/app/Services';
import { InvServ } from '../../Invoices';
// Interfaces
import { SummBkngTotalFields } from 'src/app/Interfaces/Invoice';

@Component({
	selector: 'bk-inv-from-bkng-table',
	templateUrl: './InvFromBkngTable.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvFromBkngTableComponent implements OnInit {

	@Input() invSec : any;
	@Input() invData: any;
	@Input() invForm!: FormGroup;
	@Output() bkngsTipAmt: EventEmitter<number> = new EventEmitter<number>();
	@Output() summBkngFieldsChange: EventEmitter<any> = new EventEmitter<any>();
	bkngs: any[] = [];
	elementName: any;
	offset: number = 0;
	limit: number = 10;
	bkngIdsBatches:any;
	loaderId: string = 'bkng-ids-loader';
	customFieldsBids: number[] = [];
	pricingParams: any;
	isPlanPermission!: boolean;
	formParams: any;
	bkngPriceObj: Record<string, BkngPrice> = {};
	summBkngTotalFields: SummBkngTotalFields = this.invServ.summBkngTotalFields;

	// convenience getter for easy access to form fields
	get bkngTipGroup(): FormGroup {
		return <FormGroup>this.invForm.controls['bookings_tip'];
	}
	// eslint-disable-next-line max-params
	constructor(public utilServ: UtilServ, public bkngFormServ: BkngFormServ, public initServ: InitServ, private loader: LoaderServ,private apiServ: ApiServ, private destroy: NgOnDestroy, private cDRef: ChangeDetectorRef, private frmBldr: FormBuilder, public invServ: InvServ, public bkngCustSecServ: BuildCustomSectionService, private el: ElementRef, public listPriceCal: BkngListPriceCalServ) {
		this.isPlanPermission = this.initServ.appPlansPermission('Invoice Charges Settings');
	}

	ngOnInit(): void {
		this.invForm.addControl('bookings_tip', this.frmBldr.group({}));
		this.buildTable();
		this.fetchPricingParams();
		this.getFormParamsApi();
	}

	/**
	 * Get the form element name
	 * @returns object
	 */
	public formElementName(data: any): any {
		let obj: any = {'service_category':{}, 'form_frequencies':{}, 'extras':{}, 'excludes':{}, 'package':{}, 'items':{}, 'package_addons':{}, 'addons':{}};
		let types = ['service_category', 'form_frequencies', 'extras', 'excludes', 'package', 'items', 'package_addons', 'addons'];
		for(let type of types){
			// TODO: Remove appload data usage, check again (Lakhvir)
			if(this.utilServ.checkArrLength(data?.[type])) {
				for(let val of data[type]) {
					obj[type][val?.id] = this.utilServ.getFormParamName(val);
				}
			}
		}
		return obj;
	}

	private buildTable(): void {
		if(this.invData){
			// Get the bookings based on ids
			if(this.utilServ.checkArrLength(this.invData?.booking_ids)){
				// Build the bookings ids chunk
				this.bkngIdsBatches = this.buildBkngsIdsChunk();
				if(this.utilServ.checkArrLength(this.bkngIdsBatches)){
					this.fetchBkngsByIds();
					this.getCustomSec();
				}
			}
		}
	}

	/**
	 * Get the booking by ids
	 */
	private fetchBkngsByIds(): void {
		this.loader.show(this.loaderId);
		let postData: any = {
			bids : this.bkngIdsBatches[this.offset]
		}
		this.fetchBkngsCustomFields(postData?.bids);
		this.apiServ.callApi('POST','BkngByIds',postData).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.handleBkngRes(res));
	}
	/**
	 * Booking api res handler
	 * @param res API Response
	 */
	private handleBkngRes(res: any): void {
		if(this.apiServ.checkAPIRes(res) && this.utilServ.checkArrLength(res?.data)){
			// Updates the list of items and determines whether to show a "Load More" button based on the limit.
			let loaMoreData = this.utilServ.updateLoadMoreItems(res?.data, this.bkngs);
			this.bkngs = loaMoreData.items;
			this.addTipControl();
			// Build pricing fields
			this.buildPriceFields();// New Changes added
		}
		this.loader.hide(this.loaderId);
		this.cDRef.detectChanges();
	}

	/**
	 * Get the booking ids array or array with the limit of 10
	 * Get the booking IDs from invData
	 * Array to store resulting chunks
	 * Check if booking IDs exist and exceed the limit
	 * @returns Return the resulting chunks
	 */
	private buildBkngsIdsChunk():any {
		let bkngIds: any = this.invData?.booking_ids;
		let result: number[][] = [];
		if(bkngIds.length > this.limit){
			//Calculate the total number of chunks needed
			let totalPages: number = Math.ceil(bkngIds.length / this.limit);
			//Iterate through each chunk
			for (let i = 0; i < totalPages; i++) {
				let startIndex: number = i * this.limit;
				let endIndex: number = Math.min(startIndex + this.limit, bkngIds.length);
				// Slice the booking IDs to create a chunk and add it to the result array
				let chunk: number[] = bkngIds.slice(startIndex, endIndex);
				result.push(chunk);
			}
		} else {
			result.push(bkngIds);
		}
		return result;
	}

	/**
	 * Load more
	 */
	public loadMore(): void {
		this.offset = this.offset+1;
		this.fetchBkngsByIds();
	}

	private addTipControl(): void {
		if(this.invData?.settings?.tip_settings?.pay_type == 'specific'){
			for(let bkng of this.bkngIdsBatches[this.offset]){
				this.bkngTipGroup.addControl(bkng, this.frmBldr.group({
					pay: [null],
					unit: ['amount']
				}))
			}
		}
	}

	/**
	 * Calculate total tip amount for bookings.
	 * Emits the total tip amount.
	 */
	public calBkngTip(): void {
		this.invServ.bkngsTipAmt = 0;
		for(let bid in this.bkngTipGroup.value){
			if(this.bkngTipGroup.value[bid]?.pay){
				this.invServ.bkngsTipAmt += +(this.bkngTipGroup.value[bid]?.pay);
			}
		}
		this.bkngsTipAmt.emit(this.invServ.bkngsTipAmt);
	}

	/**
	 * Fetch the bookings custum fields based on booking ids
	 * @param bids: Booking ids
	 */
	private fetchBkngsCustomFields(bids: number[]): void {
		if(this.utilServ.checkArrLength(bids) && this.isCustomSec()){
			this.customFieldsBids = Array.from(new Set([...this.customFieldsBids, ...bids]));
			this.bkngCustSecServ.fetchBkngsCustomFields(this.customFieldsBids, this.cDRef);
		}
	}

	private isCustomSec(): boolean {
		return (this.utilServ.checkArrLength(this.invData?.form_params) && this.invData?.form_params.includes('custom_section'));
	}

	/**
	 * Fetch the pricing params
	 */
	private fetchPricingParams(): void {
		this.apiServ.callApi('GET', 'PricingParams').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.handlePricingParamsApiRes(res));
	}

	/**
	 * Handles the response from the pricing parameters API and updates the global pricing parameters accordingly.
	 * @param res The response from the pricing parameters API.
	 */
	private handlePricingParamsApiRes(res: any): void {
		if(this.apiServ.checkAPIRes(res) && res?.data){
			this.pricingParams = this.createGlobalPricingParam(res.data);
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Fetch the form params
	 */
	private getFormParamsApi(): void {
		this.apiServ.callApi('GET', 'FormParams').pipe(takeUntil(this.destroy)).subscribe((resp: any) => this.formParamsApiRes(resp));
	}

	/**
	 * Handles the response from the form parameters API.
	 * @param res The response from the form parameters API.
	 */
	private formParamsApiRes(resp: any): void {
		if(this.apiServ.checkAPIRes(resp)){
			this.formParams = resp.data;
			this.elementName = this.formElementName(this.formParams);
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Create global object for pricing parameters.
	 * @param industryId : industry id
	 * @param formId : Form id
	 * @param settings : Industry form settings
	 * @returns settings object
	 */
	public createGlobalPricingParam(pricingParams: any): any {
		let settingsObj: any = {}
		if(this.utilServ.checkArrLength(pricingParams)){
			for(let pricingParam of pricingParams){
				let obj : any = {}
				obj = this.setObjForParam(pricingParam, obj);
				if(+pricingParam?.form_id == 1){
					settingsObj[pricingParam.id] = obj;
				}else{
					settingsObj[0] = obj;
				}
			}
		}
		return settingsObj
	}
	/**
	 * Set the pricing param object
	 * @param pricingParam
	 * @param obj
	 * @param industryId
	 * @param formId
	 * @returns
	 */
	private setObjForParam(pricingParam: any, obj: any){
		if(this.utilServ.checkArrLength(pricingParam?.values)){
			obj['id'] = pricingParam.id;
			obj['cat_name'] = pricingParam.name,
			obj['value'] = {};
			for(let section of pricingParam.values){
				obj.value[section.id] = section;
			}
		}
		return obj;
	}

	//Select custom section td to apply class conditionally
	private getCustomSec(): void{
		setTimeout(() => {
			let element =  this.el.nativeElement.querySelector('#custom_section');
			if(element){
				element.classList.add('w-250');
			}
		},500)
	}

	// PRICE FUNCTIONS

	/**
	 * Function to build price fields for bookings.
	 * This function initializes booking price objects and summary booking total fields.
	 * It checks if form parameters exist in the table heading.
	 * If there are bookings and form parameters exist, it calculates price details and updates booking price objects.
	 * It also updates display service prices and builds booking summaries.
	 * Modifies:
	 * - `this.bkngPriceObj`: Object to store booking price details.
	 * - `this.summBkngTotalFields`: Summary booking total fields.
	 */
	private buildPriceFields(): void {
		this.bkngPriceObj = {};
		this.summBkngTotalFields = {...this.invServ.summBkngTotalFields};

		if(this.utilServ.checkArrLength(this.bkngs)){
			for(let bkng of this.bkngs){
				let calcPricDetailObj:{priceLocalVar: BkngPrice} = this.listPriceCal.calcPriceDetails(this.listPriceCal.getCmnListPriceVar, bkng, this.listPriceCal.getCmnPrvdrPayDtls);
				let calcTipParkObj:{priceLocalVar: BkngPrice} = this.listPriceCal.calculateTipsParkingBonus(calcPricDetailObj.priceLocalVar, bkng);
				this.bkngPriceObj[bkng?._id] = calcTipParkObj.priceLocalVar;

				this.bkngPriceObj[bkng?._id]['updatedDisplayServicePrice'] = this.listPriceCal.updateServPriceAccPriceableFields(calcPricDetailObj.priceLocalVar, bkng, this.getBkngServiceCat(bkng));
				this.buildBkngSumm(bkng);
			}
		}
		setTimeout(() => {
			this.summBkngFieldsChange.emit(this.summBkngTotalFields);
		}, 0);
	}

	/**
	 * Retrieves the service category for a given booking.
	 * This function checks if the `service_category` array in the `appData` object
	 * of the `initServ` service is non-empty. If it is, the function iterates
	 * through the array to find a service whose `id` matches the `service_category`
	 * of the provided booking (`bkng`). If a match is found, the corresponding
	 * service is returned.
	 * @param {any} bkng - The booking object for which the service category is being retrieved.
	 * @returns {any} - The matching service category object, or undefined if no match is found.
	 */
	public getBkngServiceCat(bkng: any): any {
		if(this.utilServ.checkArrLength(this.initServ?.appData?.service_category)){
			for(let service of this.initServ.appData.service_category){
				if(service?.id === bkng?.service_category){
					return service;
				}
			}
		}
	}

	/**
	 * Builds the booking summary by calculating and updating various fields based on the provided booking.
	 * This function retrieves the pricing object for the given booking (`bkng`) and updates the booking summary
	 * fields such as service total, frequency discount, referral discount, expedited amount, exempt extra amount,
	 * adjusted amount, spot discount, service fee, and other common fields (coupon discount, discounted total,
	 * sales tax, tips, parking, gift card discount).
	 * @param {any} bkng - The booking object for which the summary is being built.
	 * @returns {void}
	 */
	private buildBkngSumm(bkng: any): void {
		let singleBkngPriceObj: BkngPrice = this.bkngPriceObj?.[bkng?._id];
		// Service total
		if(singleBkngPriceObj?.updatedDisplayServicePrice){
			this.summBkngTotalFields.service_total = this.utilServ.roundToTwo(this.summBkngTotalFields.service_total + singleBkngPriceObj?.updatedDisplayServicePrice);
		}
		// Frequency discount
		this.summBkngTotalFields.frequency_discount = this.utilServ.roundToTwo(this.summBkngTotalFields.frequency_discount + singleBkngPriceObj.displayFrequencyDiscount);

		this.calSummReferralDisc(bkng, singleBkngPriceObj);
		this.calSummExpeditedAmnt(bkng);
		this.calSummExemptExtraAmnt(bkng);
		this.calSummAjustedAmnt(bkng);
		this.calSummSpotDisc(bkng, singleBkngPriceObj);
		this.calSummServiceFee(bkng, singleBkngPriceObj);
		this.calSummCommanFields(singleBkngPriceObj, 'displayCouponDiscount', 'coupon_discount');
		this.calSummCommanFields(singleBkngPriceObj, 'displayDiscountedAmount', 'discounted_total');
		this.calSummCommanFields(singleBkngPriceObj, 'displaySaleTax', 'sales_tax');
		this.calSummCommanFields(singleBkngPriceObj, 'displayTipsAmount', 'tip');
		this.calSummCommanFields(singleBkngPriceObj, 'displayParkingAmount', 'parking');
		this.calSummCommanFields(singleBkngPriceObj, 'displayGiftCardAmount', 'gift_card_discount');
	}

	/**
	 * Calculates and updates the referral discount for the booking summary.
	 * This function checks if the booking (`bkng`) has a referral discount and adds it to the summary.
	 * If not, it checks if the single booking price object (`singleBkngPriceObj`) has a display referral discount
	 * and adds it to the summary.
	 * @param {any} bkng - The booking object containing the referral discount information.
	 * @param {BkngPrice} singleBkngPriceObj - The booking price object containing the display referral discount information.
	 * @returns {void}
	 */
	private calSummReferralDisc(bkng: any, singleBkngPriceObj: BkngPrice): void {
		if(bkng?.referral_discount){
			this.summBkngTotalFields.referral_discount = this.utilServ.roundToTwo(this.summBkngTotalFields.referral_discount + bkng.referral_discount);
		} else if(singleBkngPriceObj?.displayReferralDiscount){
			this.summBkngTotalFields.referral_discount = this.utilServ.roundToTwo(this.summBkngTotalFields.referral_discount + singleBkngPriceObj.displayReferralDiscount);
		}
	}

	/**
	 * Calculates and updates the expedited amount for the booking summary.
	 * This function checks if the booking (`bkng`) is a same-day booking and has an expedited amount that
	 * is not excluded, and adds it to the summary.
	 * @param {any} bkng - The booking object containing the expedited amount information.
	 * @returns {void}
	 */
	private calSummExpeditedAmnt(bkng: any): void {
		if(bkng?.same_day_booking && bkng?.expedited_amount && !bkng?.exclude_expedited_charge){
			this.summBkngTotalFields.expedited_amount = this.utilServ.roundToTwo(this.summBkngTotalFields.expedited_amount + bkng.expedited_amount);
		}
	}

	/**
	 * Calculates and updates the exempt extra amount for the booking summary.
	 * This function checks if the booking (`bkng`) has an exempt extras price and adds it to the summary.
	 * @param {any} bkng - The booking object containing the exempt extras price information.
	 * @returns {void}
	 */
	private calSummExemptExtraAmnt(bkng: any): void {
		if(bkng?.exempt_extras_price){
			this.summBkngTotalFields.exempt_extras_price = this.utilServ.roundToTwo(this.summBkngTotalFields.exempt_extras_price + bkng.exempt_extras_price);
		}
	}

	/**
	 * Calculates and updates the adjusted amount for the booking summary.
	 * This function checks if the booking (`bkng`) has an adjusted price and adds it to the summary.
	 * @param {any} bkng - The booking object containing the adjusted price information.
	 * @returns {void}
	 */
	private calSummAjustedAmnt(bkng: any): void {
		if(bkng?.adjust_price){
			this.summBkngTotalFields.adjusted_amount = this.utilServ.roundToTwo(this.summBkngTotalFields.adjusted_amount + bkng.adjusted_price);
		}
	}

	/**
	 * Calculates and updates the spot discount for the booking summary.
	 * This function checks if the single booking price object (`singleBkngPriceObj`) has a spot discount that is greater
	 * than 0 and the booking (`bkng`) does not exclude day discount, and adds it to the summary.
	 * @param {any} bkng - The booking object containing the spot discount information.
	 * @param {BkngPrice} singleBkngPriceObj - The booking price object containing the display spot discount information.
	 * @returns {void}
	 */
	private calSummSpotDisc(bkng: any, singleBkngPriceObj: BkngPrice): void {
		if(singleBkngPriceObj?.displaySpotDiscount > 0 && !bkng?.exclude_day_discount){
			this.summBkngTotalFields.spot_discount = this.utilServ.roundToTwo(this.summBkngTotalFields.spot_discount + singleBkngPriceObj.displaySpotDiscount);
		}
	}

	/**
	 * Calculates and updates the service fee for the booking summary.
	 * This function checks if the booking (`bkng`) has a service fee that is not excluded, and adds it to the summary
	 * based on the single booking price object (`singleBkngPriceObj`).
	 * @param {any} bkng - The booking object containing the service fee information.
	 * @param {BkngPrice} singleBkngPriceObj - The booking price object containing the display service fee information.
	 * @returns {void}
	 */
	private calSummServiceFee(bkng: any, singleBkngPriceObj: BkngPrice): void{
		if(bkng?.service_fee && !bkng?.exclude_service_fee){
			this.summBkngTotalFields.service_fee = this.utilServ.roundToTwo(this.summBkngTotalFields.service_fee + singleBkngPriceObj?.displayServiceFee);
		}
	}

	/**
	 * Calculates and updates common fields for the booking summary.
	 * This function updates the booking summary fields such as coupon discount, discounted total, sales tax, tips,
	 * parking, and gift card discount based on the values from the single booking price object (`singleBkngPriceObj`).
	 * @param {BkngPrice} singleBkngPriceObj - The booking price object containing the price information.
	 * @param {string} priceFieldName - The field name in the booking price object to retrieve the price information.
	 * @param {string} storeFieldName - The field name in the summary total fields to store the calculated value.
	 * @returns {void}
	 */
	private calSummCommanFields(singleBkngPriceObj: BkngPrice, priceFieldName: keyof BkngPrice, storeFieldName: keyof SummBkngTotalFields): void {
		if(singleBkngPriceObj?.[priceFieldName]){
			this.summBkngTotalFields[storeFieldName] = this.utilServ.roundToTwo(this.summBkngTotalFields.coupon_discount + (singleBkngPriceObj[priceFieldName] ?? 0));
		}
	}
}
