import { Component, Input, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
// Services
import { NgOnDestroy, UtilServ, InitServ, RenderComponentServ } from '../../../../Services';
// Constants
import { IS_TAB } from '../../../../Constants';
@Component({
	selector: 'bk-header-menu',
	templateUrl: './HeaderMenu.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class HeaderMenuComponent implements OnInit {
	// Variables
	@Input() section: any;
	@Input() sett: any;
	@Input() pageSett: any;
	@Input() varId: any;
	@Input() secId: any;
	@Input() headerMenu: any;
	layout: any = this.initServ.headerFooter; // App header/footer
	currentUser: any;
	afterLogin: boolean = false;
	menus: any;
	menuData: any;
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	// Logged menu
	loggedMenu : any = [
		{ id: 1, title: "Book Now", link_to: "page", link_url: 'booknow'},
		{ id: 2, title: "Bookings", link_to: "page", link_url: "dashboard"},
		{ id: 3, title: "Gift Cards", link_to: "page", link_url: "gift-cards"},
		{ id: 4, title: "Earn Credits - ", link_to: "page", link_url: this.initServ.appDynamicRoutes['referrals']},
		{ id: 5, title: "Contact Us", link_to: "page", link_url: this.initServ.appDynamicRoutes['contact-us']}
	]
	refBalance: any;
	menuContent: any;

	constructor(public utilServ: UtilServ, public initServ: InitServ, @Self() private destroy: NgOnDestroy, public rcServ: RenderComponentServ) {
		// Current login user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
		// Get the referral balance
		if(this.currentUser){
			this.initServ.userRefBal.pipe(takeUntil(this.destroy)).subscribe((value) => {
				if(value){
					let balance = (value.balance) ? (+value.balance) : 0;
					this.refBalance = this.utilServ.amountWithCurrency(balance, '', ' ms-5');
				} else {
					this.refBalance = this.utilServ.amountWithCurrency(0,'', ' ms-5');
				}
			});
		}
	}

	ngOnInit() {
		if(this.section && this.sett && this.pageSett){
			this.menuData = null;
			// Same menu after login
			if(this.sett[this.section.id] && this.sett[this.section.id].same_menu_after_login && this.section.after_login?.status){
				this.afterLogin = true;
			}
			if(this.headerMenu && this.headerMenu.content){
				this.menuContent = this.headerMenu.content;
			}
			if((!this.currentUser || (this.currentUser.role == 'merchant' || this.currentUser.role == 'staff')) || this.afterLogin){
				if(this.section.before_login && this.section.before_login?.status && this.sett[this.section.before_login?.id] && this.sett[this.section.before_login?.id].menu_id){
					this.dynamicMenu();
				} else {
					if(this.afterLogin && this.currentUser){
						this.dynamicMenu();
					}
				}
			} else {
				if(this.section.after_login?.status){
					this.menuData = this.utilServ.processFilteredMenu(this.loggedMenu, this.utilServ.getChildren(this.loggedMenu, null, this.pageSett), this.pageSett);
				}
			}
		}
	}

	/**
	 * Dynamic menu data
	 */
	private dynamicMenu(): void {
		let menuId: any = this.sett[this.section.before_login?.id].menu_id;
		if(this.headerMenu && this.headerMenu._id == menuId && this.headerMenu.status && this.headerMenu.menu_items && (this.headerMenu.menu_items).length > 0){
			this.menus = this.headerMenu.menu_items;
		}
		if(this.menus && (this.menus).length > 0){
			this.menuData = this.utilServ.processFilteredMenu(this.menus, this.utilServ.getChildren(this.menus, null, this.pageSett), this.pageSett);
		}
	}
	/**
	 * Add class on submenu
	 * @param index for loop index
	 * @param id menu id
	 */
	public addRespClass(index: number, id:string | number, event: any): void {
		// if(this.rcServ.statusType == 'mobile_status'){
		if(IS_TAB){
			let menuItemEl:any = document.getElementById(index+'_'+id);
			if(menuItemEl){
				menuItemEl.classList.toggle('submenu-open');
			}
			event.stopPropagation();
		}
	}
}
