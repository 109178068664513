/* eslint-disable max-depth */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { ApiServ, InitServ, NgOnDestroy, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-media-files',
	templateUrl: './MediaFilesPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy],
})
export class MediaFilesPopupComponent implements OnInit {
	// Variables
	@Input() ckData: any;
	tags: any;
	fileTags: any = [];
	tagNames: any = [];
	selectedTag: any = 'all';
	layout: any = 'grid';
	files: any
	selectedFile: any;
	hideGrid: boolean = true;
	showSelectedFile: boolean = false;
	activeSlide:number = 0;

	// eslint-disable-next-line max-params
	constructor(public dialogRef:MatDialogRef<MediaFilesPopupComponent>,private destroy: NgOnDestroy, private apiServ: ApiServ, public initServ: InitServ, public utilServ: UtilServ, private cDRef: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.getFiles();
	}

	/**
	 * Get the task media
	 */
	public getFiles(): void {
		const params:{type:string} = { type : this.ckData?.parent_type };
		this.apiServ.callApiWithPathQueryVars("GET", "GetTaskMedia", [this.ckData?.booking_id, this.ckData?.checklist_id, this.ckData?.parent_id], params).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'media-files'));
	}

	/**
	 * API to get tags data
	*/
	public getTags(): void {
		const params:{checklist:string} = { checklist: this.ckData?.checklist_id };
		this.apiServ.callApiWithQueryParams("GET", "TagNames", params).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'tags'));
	}

	/**
	 * API call response
	 * @param resObj
	 */
	private onResultCallback(res:any, type: string): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			switch (type) {
				case 'media-files':
					// eslint-disable-next-line no-case-declarations
					let mediaFiles: any = this.utilServ.mergeJobMedia(res.data);
					this.files = this.utilServ.buildMediaBasedOnTags(mediaFiles, this.ckData);
					this.fileTags = [];
					this.tagNames = [];
					if (this.files?.length > 0) {
						this.getTags();
					}
					break;
				case 'tags':
					if (res?.data?.length > 0) {
						let tagObj:any = {};
						this.tags = res.data;
						for (let tag of res.data) {
							tagObj[tag?._id] = tag?.name;
						}
						this.tagNames = tagObj;
						this.fileTags = this.utilServ.buildFileTags(this.files, tagObj);
					}
					break;
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Filter files using selected tags
	 * @param value
	 */
	public selectTag(value: string): void {
		this.selectedTag = value;
		this.layout = "grid";
		this.selectedFile = null;
	}

	/**
	 * Function to change layout
	 * @param value
	 */
	public setLayout(value: string): void {
		this.layout = value;
		if(value === 'slider'){
			this.activeSlide = (this.selectedTag === 'all') ? 0 : this.files?.urls?.findIndex((file:any) => (file?.tag_ids?.includes(this.selectedTag)));
		}
	}

	/**
	 * Function to select slide and change layout
	 * @param event
	 * @param file
	 */
	public selectSlide(e: Event, file: any): void {
		e.preventDefault();
		e.stopPropagation();
		this.selectedFile = file;
	}
}
