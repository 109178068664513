import { Component, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../../Services';
// Parent component
import { ServiceCategoryComponent } from '../../index';

@Component({
	selector: 'bk-service-category-btn',
	templateUrl: './ServiceCategoryBtn.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ServiceCategoryBtnComponent extends ServiceCategoryComponent {

}