<ng-container *ngIf="['add', 'draft'].includes(bookingType); else rescheduleBtn">
	<!-- Accept terms and conditions -->
	<div class="mb-20 mt-15" *ngIf="['add', 'draft'].includes(this.bookingType) && acceptTcStatus">
		<div class="form-check align-items-start-imp" [formGroup]="BKFrm">
			<input id="accept_terms_and_conditions" class="form-check-input" type="checkbox" formControlName="accept_terms_conditions" (change)="acceptTcChange.emit()">
			<ng-container *ngIf="section?.accept_tc && section?.accept_tc?.content; else noAcceptTC">
				<label for="accept_terms_and_conditions" class="form-check-label" [id]="section?.accept_tc?.id" [innerHTML]="section?.accept_tc?.content | safeHtml"></label>
			</ng-container>
			<ng-template #noAcceptTC>
				<label for="accept_terms_and_conditions" class="form-check-label" [id]="section?.accept_tc?.id">{{initServ?.appStr?.text?.acceptTC | translate}}</label>
			</ng-template>
		</div>
		<!-- Error -->
		<div class="tjs-error bk-error" *ngIf="!isAcceptTcValid">
			<i class="tjsicon-attention"></i>
			<ng-container *ngIf="section?.accept_tc?.error_msg_content; else defAcceptTcMsg">{{section?.accept_tc?.error_msg_content}}</ng-container>
			<ng-template #defAcceptTcMsg>{{initServ.appStr?.errorMsg?.acceptTc | translate}}</ng-template>
		</div>
	</div>
	<div class="btn-noti" *ngIf="BKFrm.controls['payment_method'].value == 'new_credit_card' || BKFrm.controls['payment_method'].value == 'existing_credit_card'; else customBlockTerms ">
		<ng-container *ngTemplateOutlet="acceptCookies"></ng-container>
		<span class="your_card_is_charged_after d-inline-block mb-20" [id]="section?.card_charged_after?.id" *ngIf="section?.card_charged_after && ((cardChargedAfterStatus && section?.card_charged_after?.title) || section?.card_charged_after?.tooltip)">
			<span *ngIf="cardChargedAfterStatus" [id]="section?.card_charged_after?.title_id" [innerHTML]="section?.card_charged_after?.title | safeHtml"></span>
			<bk-tooltip customClass="bk-tooltip" [inheritStyle]="true" *ngIf="section?.card_charged_after?.tooltip" [id]="section?.card_charged_after?.tooltip_id" [content]="section?.card_charged_after?.tooltip | translate"></bk-tooltip>
		</span>
	</div>
	<ng-template #customBlockTerms>
		<ng-container *ngTemplateOutlet="acceptCookies"></ng-container>
		<div class="mt-15 d-inline-block mb-20" [id]="section?.accept_save_booking_tc?.id" *ngIf="acceptSaveBookingTcStatus && section?.accept_save_booking_tc && section?.accept_save_booking_tc?.content" [innerHTML]="section?.accept_save_booking_tc?.content | safeHtml">
		</div>
	</ng-template>
	<!-- Save booking -->
	<div class="form-btn-fixed" *ngIf="!isBlockedStatus">
		<bk-navigate [isDebounce]="true" [isDebounceLoader]="false" innerHTML="<i class='tjsicon-calendar-1 me-10'></i>{{section?.save_booking_btn?.content}}" customClass="fs-24 rounded-5 btn-lg btn-primary border-transparent" essentialClass="w-100 d-flex justify-content-center align-items-center" (callback)="submit()" [secSett]="section?.save_booking_btn" ></bk-navigate>
	</div>
</ng-container>
<ng-template #rescheduleBtn>
	<div *ngIf="prefilledData && (prefilledData?.is_refunded || prefilledData?.is_tip_charged || prefilledData?.is_extra_charge || prefilledData?.is_charged_after_first_apt)">
		<bk-payment-adjustment [prefilledData]="prefilledData"></bk-payment-adjustment>
	</div>
	<!-- Update -->
	<div *ngIf="isCustomerAllowedRes == false">
		<bk-navigate *ngIf="isStepOneValid" innerHTML="{{initServ?.appStr?.text?.update | translate}}" customClass="btn btn-primary btn-lg fs-20 border-transparent" essentialClass="w-100 mt-10" (callback)="rescheduleInfo()" ></bk-navigate>
	</div>
	<!-- Update -->
	<ng-container *ngIf="isStepOneValid && prefilledData">
		<!-- Save changes  -->
		<div class="mt-10" *ngIf="!isBlockedStatus && (prefilledData?.status != 1 && prefilledData?.status != 2 && prefilledData?.status != 3 && prefilledData?.status != 4 && isCustomerAllowedRes && !isBkngStarted)">
			<bk-navigate [isDebounce]="true" [isDebounceLoader]="false" innerHTML="{{initServ?.appStr?.text?.saveChanges | translate}}" customClass="btn btn-primary btn-lg fs-20 border-transparent" essentialClass="w-100" (callback)="submit()" ></bk-navigate>
		</div>
		<!-- Resume -->
		<div class="mt-10" *ngIf="(prefilledData?.status == 3 && isCustomerAllowedRes)">
			<bk-navigate [isDebounce]="true" [isDebounceLoader]="false" innerHTML="{{initServ?.appStr?.text?.resume | translate}}" customClass="btn btn-success btn-lg fs-20 border-transparent" essentialClass="w-100" (callback)="submit()" ></bk-navigate>
		</div>
		<!-- Discard changes -->
		<div class="mt-10" *ngIf="(prefilledData?.status != 1 && prefilledData?.status != 2 && prefilledData?.status != 3 && prefilledData?.status != 4 && prefilledData?.status != 9 && isCustomerAllowedRes)">
			<bk-navigate innerHTML="{{initServ?.appStr?.text?.discardChanges | translate}}" customClass="btn btn-light btn-lg fs-20 border-transparent" (callback)="router.navigate(['/'+initServ.appDynamicRoutes['dashboard']])" essentialClass="w-100" ></bk-navigate>
		</div>
		<!-- Cancel booking -->
		<div class="mt-10" *ngIf="(prefilledData?.status != 1 && prefilledData?.status != 2 && prefilledData?.status != 3 && prefilledData?.status != 4 && prefilledData?.status != 9)">
			<bk-cancel-booking [booking]="prefilledData" class="d-block" customClass="btn-lg fs-20 btn btn-danger border-transparent" (refreshComp)="refreshBkng($event)"></bk-cancel-booking>
		</div>
		<!-- Download invoice -->
		<div class="mt-10" *ngIf="prefilledData?.status != 9 && invoiceStatus">
			<bk-navigate innerHTML="{{initServ?.appStr?.text?.downloadInvoice | translate}}" customClass="btn btn-secondary btn-lg fs-20 border-transparent" essentialClass="w-100 fw-bold" (callback)="utilServ.bkngInvoiceWindow(prefilledData._id)" ></bk-navigate>
		</div>
	</ng-container>
</ng-template>

<!-- Cookies -->
<ng-template #acceptCookies>
	<div class="tjs-privacy-text mt-10 mb-20" *ngIf="cookieStatus && section?.cookie_content && section?.cookie_content?.content && !isMultiStepForm">
		<span class="bk-cookie fs-14" [id]="section?.cookie_content?.id" [innerHTML]="section?.cookie_content?.content | safeHtml"></span>
	</div>
</ng-template>
