import { Component, OnInit, ViewEncapsulation, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
// Services
import { RenderComponentServ, SectionServ, InitServ, UtilServ } from '../../Services';
@Component({
	selector: 'bk-lead-form',
	templateUrl: './LeadForm.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeadFormComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	pageSett: any;
	// Section fields
	section:  {form: any, reviews: any, faq: any, media: any} = {
		form: null,
		reviews: null,
		faq: null,
		media: null
	}
	leadFormId: string = 'f8f955f1-3fff-4556-bda4-13f431ec3fd0';
	embedFormUrl: SafeResourceUrl | undefined;
	embedJsUrl:  SafeResourceUrl | undefined;
	height: string = '650';
	scrolling: string = 'no';
	// eslint-disable-next-line max-params
	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, private utilServ: UtilServ, private router: Router, public sanitizer: DomSanitizer, public cdRef: ChangeDetectorRef) {
		// Check if the current page is not a lead form; if not, redirect to the home page
		if(!this.utilServ.bookingOrLeadFormStatus('lead_form')){
			this.router.navigate(['/']);
		}else{
			// Initialize the embed JavaScript URL with the primary custom domain
			this.embedJsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://${this.initServ.appData.primary_custom_domain}/resources/embed.js`);
		}
	}
	ngOnInit(): void {
		// Check if the section ID and page data are available
		if(this.secId && this.rcServ.pageData){
			// Set the page settings using the RenderComponentServ service
			this.pageSett = this.rcServ.pageData.section_settings;
			// Set service data and section settings using the SectionServ service
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
			// Build section fields and update the section object using the SectionServ service
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
			// Build and embed the form
			this.buildEmbedForm();
		}
	}
	/**
	 * Builds and embeds the lead form in the component.
	 * Sets the lead form ID using the `setLeadFormId` method.
	 * Constructs the form URL with the lead form ID and embed options.
	 * Sets the embedded form URL as a trusted resource URL.
	 * Adjusts the height of the embedded form container for smoother rendering.
	 */
	private async buildEmbedForm(): Promise<void> {
		// Set the lead form ID
		this.setLeadFormId();
		let formId: string = await this.utilServ.dataEncryptByGCM(this.leadFormId);
		// Construct the form URL with the lead form ID and embed options
		let formUrl: string = `${this.initServ.baseUrl}/leads/form/${formId}?embed=true&hide_trans=true&lang=${this.initServ.savedLng}`;
		if(formUrl){
			// Set the embedded form URL as a trusted resource URL
			this.embedFormUrl = this.sanitizer.bypassSecurityTrustResourceUrl(formUrl);
			// Adjust the height of the embedded form container for smoother rendering
			this.utilServ.setHeight(1000, 'bkQuoteForm');
			this.utilServ.setHeight(3000, 'bkQuoteForm');
			this.utilServ.setHeight(5000, 'bkQuoteForm');
			this.utilServ.setHeight(8000, 'bkQuoteForm');
		}
		this.cdRef.detectChanges();
	}
	/**
	 * Sets the lead form ID based on the section's form ID.
	 * Checks if the lead form ID is already set in the page settings.
	 * If found, assigns the lead form ID from the page settings.
	 */
	private setLeadFormId(): void {
		// Retrieve the form ID from the section
		let formId: string = this.section?.form?.id;
		// Check if the lead form ID is already set in the page settings
		if(this.rcServ.pageData?.section_settings[formId]?.lead_form_id){
			// Assign the lead form ID from the page settings
			this.leadFormId = this.rcServ.pageData?.section_settings[formId]?.lead_form_id;
		}
	}
}
