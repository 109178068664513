import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// Services
import { NgOnDestroy, InitServ } from '../../../Services';
// Constants
import { EMAIL_REG_EXP } from '../../../Constants';
// Custom validator
import { CustomValidators } from '../../../Global/GlobalDefault';

@Component({
	selector: 'bk-social-email-popup',
	template: `<div class="tjs-popup tjs-popup--sm tjs-popup--editor" *ngIf="user">
		<bk-popup-header heading="Add email"></bk-popup-header>
		<form [formGroup]="emailForm" (ngSubmit)="submitForm()">
			<div class="tjs-popup__content tjs-popup__content--height">
				<!-- Label -->
				<label class="form-label mb-15">{{'Hi' | translate}}, {{user?.firstName}} {{user?.lastName}} {{'Please fill your email.' | translate}}</label>
				<input class="form-control" [ngClass]="{'is-invalid': emailForm.controls['email_id']?.touched && emailForm.controls['email_id']?.errors}" [attr.placeholder]="'Ex: example@xyz.com' | translate" formControlName="email_id">
				<!-- Error -->
				<div class="tjs-error" *ngIf="emailForm.controls['email_id']?.touched && (emailForm.controls['email_id']?.errors?.required || emailForm.controls['email_id']?.errors?.pattern || emailForm.controls['email_id']?.errors?.emailExists)">
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="emailForm.controls['email_id']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
					<ng-container *ngIf="emailForm.controls['email_id']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.validEmail | translate}} </ng-container>
					<ng-container *ngIf="emailForm.controls['email_id']?.errors?.emailExists"> {{initServ?.appStr?.errorMsg?.emailAlreadyExist | translate}} </ng-container>
				</div>
			</div>
			<div class="tjs-popup__footer d-sm-flex d-grid">
				<button type="submit" class="btn btn-primary w-100 mb-10 mb-sm-0 me-20" translate>Save</button>
				<button type="button" class="btn btn-danger w-100 mt-0" (click)="dialogRef.close()" translate>Cancel</button>
			</div>
		</form>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class SocialEmailPopupComponent implements OnInit {
	// Variables
	emailForm: FormGroup; // Email form
	user: any;

	constructor(public dialogRef: MatDialogRef<SocialEmailPopupComponent>, private frmBldr: FormBuilder, public initServ: InitServ, private customValidators: CustomValidators) {
		this.emailForm = this.frmBldr.group({
			email_id : [null, [Validators.required, Validators.pattern(EMAIL_REG_EXP)]]
		});
	}

	ngOnInit() {}
	/**
	 * Check the user email exist
	 */
	public async userEmailExist(email: any) {
		if(email){
			let res = await this.customValidators.emailExist(email);
			this.emailForm.controls['email_id'].setErrors(res);
			if(res){
				return true;
			}
		}
		return false;
	}
	/**
	 * Submit form
	 */
	public async submitForm() {
		if(this.emailForm.valid){
			let emailStatus = await this.userEmailExist(this.emailForm.controls['email_id'].value);
			if(!emailStatus){
				this.dialogRef.close(this.emailForm.controls['email_id'].value);
			}
		} else{
			this.emailForm.controls['email_id'].markAsTouched();
		}
	}
}