import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BookingFormComponent } from './index';

const routes: Routes = [
	{ path: '', component: BookingFormComponent },
	{ path: ':industry', component: BookingFormComponent }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AddBookingsRoutingModule { }
