import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, UtilServ } from '../../Services';
import { AuthServ } from '../../Core/_services';
// Constants
import { PASSWORD_MIN_LENGTH } from '../../Constants';
//Custom validator
import { CustomValidators } from '../../Global/GlobalDefault';

@Component({
	selector: 'bk-reset-password-page',
	templateUrl: './ResetPassword.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy, CustomValidators]
})
export class ResetPasswordComponent implements OnInit {

	// Variables
	@Input() secId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		sub_title: null,
		media: null,
		form: null,
		thank_you_msg: null
	}
	resetForm!: FormGroup; // Reset password form
	loaderId: string = 'reset-loader';
	merchantId: any;
	resetToken: any;
	isbooked: boolean = false;
	fromEmbed: boolean = false;

	constructor(private actRoute: ActivatedRoute, private frmBldr: FormBuilder, public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, private authServ: AuthServ, public utilServ: UtilServ, public toastr: ToastrService) {
		this.merchantId = this.initServ.appAdmnStngs && this.initServ.appAdmnStngs.merchant_id;
		// Reset token get by query params
		this.resetToken = this.actRoute.snapshot.queryParamMap.get('token');
		// isbooked use for show the description on top of the page.
		this.isbooked = (this.actRoute.snapshot.queryParamMap.get('isbooked') && this.actRoute.snapshot.queryParamMap.get('isbooked') == 'true') ? true : false;
		this.fromEmbed = (this.actRoute.snapshot.queryParamMap.get('fromEmbed') && this.actRoute.snapshot.queryParamMap.get('fromEmbed') == 'true') ? true : false;
		// Current login user info from browser local storage
		let currentUser = this.utilServ.appLocalStorage();
		if(this.resetToken != undefined && this.resetToken && !this.initServ.theme){
			// removed storage in case for without embed and current user and user role.
			if(!currentUser || !this.fromEmbed || (currentUser && currentUser.role == 'customer')){
				this.authServ.removeCurrentUser();
			}
		}
	}

	ngOnInit(): void {
		// Embed status: true and theme not exist remove the current login user local storage
		if(this.utilServ.embedStatus && !this.initServ.theme){
			this.authServ.removeCurrentUser();
			this.utilServ.id = null;
		}
		// Forgot password form build
		this.resetForm = this.frmBldr.group({
			new_password: ['', [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH)]],
			confirm_password: ['', [Validators.required]],
			password_reset_token: [this.resetToken],
			merchant_id: [this.merchantId]
		},{
			validators: [CustomValidators.matchPassword('new_password', 'confirm_password')]
		});
		// Build section data
		if(this.secId && this.rcServ.pageData){
			this.pageSett = this.rcServ.pageData.section_settings;
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
	}
	// convenience getter for easy access to form fields
	get f(): { [key: string]: AbstractControl } {
		return this.resetForm.controls;
	}
	/**
	 * Submit the login form
	 */
	public submitForm(): void {
		if(this.resetForm.valid){
			this.authServ.resetPassword(this.resetForm.value, this.loaderId);
		} else{
			this.resetForm.markAllAsTouched();
			this.toastr.error('Please fill the required fields marked in red.');
		}
	}
}