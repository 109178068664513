<div [id]="secId" class="tjs-blog py-80 {{custClass}}" *ngIf="((pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType]) || secFields) && (section && blogs && blogs.length > 0)">
	<div class="container">
		<!-- Title -->
		<div class="tjs-section__title text-center mb-30" *ngIf="(section?.title?.content) || (secFields?.title)">
			<div class="tjs-section__heading">
				<h3 [id]="secFields?.title_id ? secFields?.title_id : section?.title?.id" class="display-5 fw-bold bk-title" [innerHTML]="(secFields?.title ? secFields?.title : section?.title?.content) | safeHtml"></h3>
			</div>
		</div>
		<!-- Blog -->
		<div class="row justify-content-center">
			<div class="col-12 col-sm-12 col-md-6 col-lg-4 mb-40 mb-lg-0 tjs-mb-last-0" *ngFor="let blog of blogs;trackBy: secServ.trackByFnIndex">
				<div class="tjs-blog__item">
					<!-- Media -->
					<div class="tjs-blog-img mb-20">
						<a [routerLink]="['/',pageSlug,blog?.seo?.url]">
							<img loading="auto" class="img-fluid" [src]="blog?.image_url ? (utilServ.getImgUrl(blog?.image_url)) : (initServ?.img?.Empty)" width="1280" height="768" alt="blog">
						</a>
					</div>
					<div>
						<div class="tjs-blog__meta mb-5" *ngIf="(blog?.date_time && !settings?.hide_date) || (blog?.author_data?.name) || (blog?.comment_count)">
							<ul class="list-unstyled d-flex flex-wrap mb-0">
								<!-- Date -->
								<li class="me-15 mb-5" *ngIf="blog?.date_time && !settings?.hide_date">
									<div class="d-flex align-items-center fs-14 bk-item-desc">
										<span>{{initServ?.appStr?.text?.published | translate}} <bk-date-display class="d-inline-block" [date]="blog?.date_time" [isLocal]="true"></bk-date-display></span>
									</div>
								</li>
								<!-- Comment count -->
								<li class="me-15 mb-5" *ngIf="blog?.comment_count">
									<div class="d-flex align-items-center fs-14 bk-item-desc">
										<span>{{blog?.comment_count}} {{initServ?.appStr?.text?.comment | translate}}</span>
									</div>
								</li>
								<!-- Author name -->
								<li class="me-15 mb-5" *ngIf="blog?.author_data?.name">
									<div class="d-flex align-items-center fs-14">
										<a class="text-body bk-item-desc" [routerLink]="['/author',pageSlug, blog?.author_data?.slug]">{{initServ?.appStr?.text?.by | translate}} {{blog?.author_data?.name}}</a>
									</div>
								</li>
							</ul>
						</div>
						<!-- Title -->
						<div class="tjs-blog__title" *ngIf="blog?.title">
							<h3 class="fw-bold mb-0"><a class="text-dark bk-item-title tjs-title-hover" [routerLink]="['/',pageSlug, blog?.seo?.url]">{{blog?.title}}</a></h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
