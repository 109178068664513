import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
@Component({
	selector: 'bk-card-apply',
	templateUrl: './CardApplyTo.component.html',
	encapsulation: ViewEncapsulation.None
})
export class CardApplyToComponent implements OnInit {
	@Input() form!: FormGroup;
	@Input() id: any;
	@Input() isBookingsWithCash: boolean = false;
	@Input() section: any;
	constructor() { }
	ngOnInit(): void { }
}
