import { Component, OnInit, Input, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
import { Router } from '@angular/router';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, ApiServ } from '../../Services';

@Component({
	selector: 'bk-join-our-team',
	templateUrl: './JoinOurTeam.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class JoinOurTeamComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		media: null,
		list: null,
		form_label: null,
		form_btn: null
	}
	selectedCity: string = '#';
	selectedJob: string = '';
	jobs: any;
	sectionData: any;
	selectedLinkData: any;

	constructor( public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, @Self() private destroy: NgOnDestroy, private apiServ: ApiServ, private router: Router) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
		// Set the selected city link, used index 0
		if(this.pageSett[this.secId].variation_id == 'bk_join_our_team_V1'){
			if(this.section && this.section.list && this.section.list.items && (this.section.list.items).length > 0){
				for(let list of this.section.list.items){
					if(list.title){
						this.selectedCity = list.link;
						break;
					}
				}
				this.selectedLinkData = this.selectedCity;
			}
		} else{
			// Called api for variation 2, Get the jobs data from content management app
			let queryParams: any = {language:this.initServ.savedLng};
			this.apiServ.callApiWithQueryParams('GET', 'Jobs', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
		}
	}
	/**
	 * On result callback method
	 * @param res api response
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res)){
			this.jobs = res.data;
			this.selectedJob = this.jobs && this.jobs.length > 0 && this.jobs[0].slug;
		}
	}
	/**
	 * Set the selected city link
	 * @param data: data
	 */
	public setSelectedCity(data: any): void {
		this.selectedLinkData = null;
		setTimeout(() => {
			this.selectedLinkData = data;
		}, 100);
	}
	/**
	 * Go to next page depend on variation id
	 */
	public nextPage(): void {
		// Variation v2, navigate to details page
		if(this.pageSett[this.secId].variation_id == 'bk_join_our_team_V2'){
			if(this.selectedJob){
				let url = '/job-details/'+this.selectedJob;
				this.router.navigate([url]);
			}
		}
	}
}
