<div [ngClass]="isMultiStepForm ? 'mb-30' : 'py-30 px-md-30 border-bottom bk-card-border'" [formGroup]="KeyInfoForm" [id]="section?.id" *ngIf="section && sectionStatus && sectionStatus?.keyInfo">
	<!-- Title and description -->
	<bk-section-heading *ngIf="!isMultiStepForm" [section]="section" [pageSett]="pageSett"></bk-section-heading>
	<!-- Radio buttons -->
	<div class="tjs-check-btn mb-10" [ngClass]="{'tjs-btn-multi d-md-flex flex-wrap flex-grow-1 mx-n10 px-10 text-center': isMultiStepForm}">
		<div class="form-check form-check-inline mb-10 me-25">
			<input id="at_home" class="form-check-input bk-form-check-input" type="radio" formControlName="key_info" value="at_home" (change)="updateValidation()" [attr.disabled]="isDisabled">
			<label for="at_home" class="form-check-label btn btn-light text-capitalize bk-form-check-btn" [ngClass]="isMultiStepForm ? 'w-100 fw-bold' :'fw-normal fs-14'" [id]="section?.someone_at_home_btn_id" [innerHTML]="section?.someone_at_home_btn | safeHtml"></label>
		</div>
		<div class="form-check form-check-inline mb-10 me-25">
			<input id="hidden" class="form-check-input bk-form-check-input" type="radio" formControlName="key_info" value="hidden" (change)="updateValidation()" [attr.disabled]="isDisabled">
			<label for="hidden" class="form-check-label btn btn-light text-capitalize bk-form-check-btn" [ngClass]="isMultiStepForm ? 'w-100 fw-bold' :'fw-normal fs-14'" [id]="section?.hide_key_btn_id" [innerHTML]="section?.hide_key_btn | safeHtml"></label>
		</div>
	</div>
	<!-- Checkbox -->
	<div class="form-check form-check-inline mb-30 mt-10 mt-md-0" *ngIf="sectionStatus?.keyWithProv">
		<input class="form-check-input" id="with_provider" type="checkbox" formControlName="key_with_provider" [attr.disabled]="isDisabled">
		<label class="form-check-label bk-form-check-label" for="with_provider" [id]="section?.key_with_prov_id" [innerHTML]="section?.key_with_prov | safeHtml"></label>
	</div>
	<!-- Text area -->
	<div id="keyinfo-section-id" [ngClass]="{'px-0': !isMultiStepForm}">
		<div>
			<label class="form-label bk-form-label" *ngIf="section?.note" [id]="section?.note_id" [innerHTML]="section?.note | safeHtml"></label>
			<!-- <textarea [ngClass]="{'is-invalid': KeyInfoForm.controls['key_note']?.touched && KeyInfoForm.controls['key_note']?.errors}"  class="form-control bk-input" rows="5" placeholder="{{section?.note_placeholder}}" formControlName="key_note" [attr.disabled]="isDisabled"></textarea> -->
			<ng-container *ngIf="isDisabled; else editorBlock">
				<textarea  class="form-control bk-input" rows="5" placeholder="{{section?.note_placeholder}}" formControlName="key_note" [attr.disabled]="isDisabled"></textarea>
			</ng-container>
			<ng-template #editorBlock>
				<editor class="blank-editor" [apiKey]="initServ?.tinymceAPIKey" [init]="tinymceConfig" formControlName="key_note" debounce="500"></editor>
			</ng-template>
			<!-- Error -->
			<ng-container *ngIf="KeyInfoForm.controls['key_note'].touched">
				<div class="tjs-error bk-error" *ngIf="KeyInfoForm.controls['key_note']?.errors?.required"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.emptyNote | translate}}</div>
				<div class="tjs-error bk-error" *ngIf="KeyInfoForm.controls['key_note']?.errors?.extraLongTextValidationCheck"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.extraLongMaxLength | translate}}</div>
			</ng-container>
		</div>
	</div>
</div>
