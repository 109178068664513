
<div [id]="secId"  class="custom-row-wrap col-element-m-0" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section && section?.cols && (pageSett[section.cols].cols && (pageSett[section.cols].cols).length > 0)">
	<div class="row no-gutters bk-col-m mx-0">
		<ng-container *ngFor="let colGroupId of pageSett[section.cols].cols">
			<ng-container *ngIf="colGroupId && secServ.checkEleStatus(sectionData?.section_settings, colGroupId) && pageSett[colGroupId].elements.col && pageSett[pageSett[colGroupId].elements.col] && pageSett[pageSett[colGroupId].elements.col].elements && (pageSett[pageSett[colGroupId].elements.col].elements).length > 0">
				<div [id]="colGroupId" class="bk-col col-sm-12 p-0 tjs-bg-style {{colClass}} position-relative" [ngStyle]="{'background-image' : (getMedia(colGroupId) ) && 'url('+getMedia(colGroupId) + ')'}">
					<ng-container *ngIf="pageSett[pageSett[colGroupId].elements.col].section_status; else elementBlock">
						<!-- Sections -->
						<ng-container *ngTemplateOutlet="sectionsBlock; context:{colId: pageSett[colGroupId].elements.col}"></ng-container>
					</ng-container>
					<ng-template #elementBlock>
						<!-- Elements -->
						<ng-container *ngTemplateOutlet="elementsBlock; context:{colId: pageSett[colGroupId].elements.col}"></ng-container>
					</ng-template>
				</div>
			</ng-container>
		</ng-container>
	</div>
</div>
<!-- Sections -->
<ng-template #sectionsBlock let-colId="colId">
	<ng-container *ngIf="secServ.checkEleStatus(sectionData?.section_settings, colId) && pageSett[colId].elements && pageSett[colId].elements[0] && pageSett[pageSett[colId].elements[0]]">
		<ng-container [ngSwitch]="pageSett[pageSett[colId].elements[0]]?.slug">
			<bk-banner-area *ngSwitchCase="'banner'" [popupId]="popupId" [secId]="pageSett[colId].elements[0]" ></bk-banner-area>
			<bk-about-us *ngSwitchCase="'about_us'" [popupId]="popupId" [secId]="pageSett[colId].elements[0]" ></bk-about-us>
			<bk-grid-content *ngSwitchCase="'how_it_works'" [popupId]="popupId" [secId]="pageSett[colId].elements[0]" ></bk-grid-content>
			<bk-app-widgets *ngSwitchCase="'app_widgets'" [popupId]="popupId" [secId]="pageSett[colId].elements[0]" ></bk-app-widgets>
			<bk-referral *ngSwitchCase="'referral'" [popupId]="popupId" [secId]="pageSett[colId].elements[0]" ></bk-referral>
			<bk-call-to-action *ngSwitchCase="'call_to_action'" [popupId]="popupId" [secId]="pageSett[colId].elements[0]" ></bk-call-to-action>
			<bk-text-content *ngSwitchCase="'text_content'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-text-content>
			<bk-services *ngSwitchCase="'services'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-services>
			<bk-features *ngSwitchCase="'features'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-features>
			<bk-team-members *ngSwitchCase="'team_members'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-team-members>
			<bk-opening-hours *ngSwitchCase="'opening_hours'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-opening-hours>
			<bk-faq *ngSwitchCase="'faq'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-faq>
			<bk-reviews *ngSwitchCase="'live_reviews'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-reviews>
			<bk-client-reviews *ngSwitchCase="'testimonial'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-client-reviews>
			<bk-benefits *ngSwitchCase="'benefits'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-benefits>
			<bk-join-our-team *ngSwitchCase="'join_our_team'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-join-our-team>
			<bk-locations *ngSwitchCase="'location_map'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-locations>
			<bk-partners *ngSwitchCase="'our_clients'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-partners>
			<bk-blog-listing *ngSwitchCase="'blog'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-blog-listing>
			<bk-blog-grid-section *ngSwitchCase="'blog_section'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-blog-grid-section>
			<bk-gallery *ngSwitchCase="'gallery'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-gallery>
			<bk-grid-services *ngSwitchCase="'grid_services'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-grid-services>
			<bk-embed-form *ngSwitchCase="['embed_booking_form', 'embed_login_form', 'embed_signup_form', 'embed_send_gift_card_form', 'embed_forgot_password_form', 'embed_quote_form'].includes(pageSett[pageSett[colId].elements[0]]?.slug) ? pageSett[pageSett[colId].elements[0]]?.slug : ''" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-embed-form>
			<bk-embed-video *ngSwitchCase="'embed_video'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-embed-video>
			<bk-html-code *ngSwitchCase="'html_code'"  [popupId]="popupId" [secId]="pageSett[colId].elements[0]" ></bk-html-code>
			<bk-bkng-short-form *ngSwitchCase="'short_form'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]"></bk-bkng-short-form>
			<bk-row *ngSwitchCase="'row'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]"></bk-row>
			<bk-contact-us *ngSwitchCase="'contact_us'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]" ></bk-contact-us>
			<bk-discount-bar *ngSwitchCase="'discount_bar'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]"></bk-discount-bar>
			<bk-thank-you *ngSwitchCase="'thank_you'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]"></bk-thank-you>
			<bk-service-grid *ngSwitchCase="'service_grid'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]"></bk-service-grid>
			<bk-embed-hiring-form *ngSwitchCase="'hiring_form'" [popupId]="popupId"  [secId]="pageSett[colId].elements[0]"></bk-embed-hiring-form>
		</ng-container>
	</ng-container>
</ng-template>
<!-- Elements -->
<ng-template #elementsBlock let-colId="colId">
	<ng-container *ngIf="secServ.checkEleStatus(sectionData?.section_settings,colId) && pageSett[colId] && pageSett[colId].elements && ((pageSett[colId]).elements).length > 0">
		<ng-container *ngFor="let elem of (pageSett[colId]).elements">
			<ng-container *ngIf="secServ.checkEleStatus(sectionData?.section_settings,elem)">
				<ng-container [ngSwitch]="pageSett[elem]?.type">
					<bk-text *ngSwitchCase="'text'" [popupId]="popupId" [secId]="elem"></bk-text>
					<bk-image *ngSwitchCase="'media'" [popupId]="popupId" [secId]="elem"></bk-image>
					<bk-button *ngSwitchCase="'btn_group'" [popupId]="popupId" [secId]="elem"></bk-button>
					<bk-bullet-list *ngSwitchCase="'list'" [popupId]="popupId" [secId]="elem"></bk-bullet-list>
				</ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-template>