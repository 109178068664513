<div [id]="secId" class="pt-30 pb-10" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div class="container" *ngIf="section?.title?.content || (section?.list && section?.list?.items && (section?.list?.items).length> 0)">
		<!-- Title -->
		<div class="mb-30 text-center" *ngIf="section?.title?.content">
			<div class="tjs-section__heading">
				<h4 [id]="section?.title?.id" class="display-6 fw-bold" [innerHTML]="section?.title?.content | safeHtml"></h4>
			</div>
		</div>
		<!-- List -->
		<div [id]="section?.list?.id" class="row justify-content-center" *ngIf="section?.list && section?.list?.items && (section?.list?.items).length> 0">
			<ng-container *ngFor="let item of section?.list?.items let i=index;trackBy: secServ.trackByFnIndex">
				<div [id]="item?.id" class="my-20 col-md-6" *ngIf="(item?.media && (item?.media).length > 0) || item?.title || item?.desc">
					<div class="bk-item">
						<!-- Media -->
						<div *ngIf="item?.media && (item?.media).length > 0">
							<img loading="auto" [id]="item?.media_id" [src]="item?.media[0]" class="img-fluid" alt="Team member" width="700" height="575">
						</div>
						<div class="mt-25" *ngIf="item?.title || item?.desc">
							<!-- Title -->
							<h6 *ngIf="item?.title" [id]="item?.title_id" class="fw-bold bk-item-title mb-25" [innerHTML]="item?.title | safeHtml">
							</h6>
							<!-- Description -->
							<p *ngIf="item?.desc" [id]="item?.desc_id" [innerHTML]="item?.desc | safeHtml" class="mb-0 bk-item-desc"></p>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
