<div [id]="invSidebar?.id" class="bk-border">
	<div *ngIf="isForm && (invData?.settings?.allow_partial_pay || invData?.settings?.allow_tip_by_cust)" [formGroup]="invForm">
		<ng-container *ngIf="isPlanPermission">
			<!-- Partial payment -->
			<div [id]="invSidebar?.partial_payment_id" *ngIf="invData?.settings?.allow_partial_pay == true &&  invData?.no_of_payments != (invData?.settings?.partial_pay?.split_percentage).length">
				<label [id]="invSidebar?.partial_payment?.payment_option_id" [innerHTML]="invSidebar?.partial_payment?.payment_option | safeHtml" class="form-label bk-label-clr bk-label-fw"></label>
				<div class="mb-25">
					<div class="form-check me-0 bk-val-color mb-15">
						<input type="radio" id="full" class="form-check-input tjs-pointer" value="full" name="payment option" formControlName="payment_type" (change)="calTotalAmt(false, true)">
						<label for="full" class="form-check-label w-45 word-break" [id]="invSidebar?.partial_payment?.pay_full_amount_id">{{invSidebar?.partial_payment?.pay_full_amount}}</label>
						<div class="w-55 d-flex align-items-center justify-content-between word-break" [id]="invSidebar?.partial_payment?.pay_full_amount_id">
							<span class="me-5">: &nbsp;</span>
							<bk-amount-display [amount]="invFullAmt" [isZero]="true"></bk-amount-display>
						</div>
					</div>
					<div class="form-check me-0 bk-val-color">
						<input type="radio" id="advance" class="form-check-input tjs-pointer" value="partial" checked="checked"
						name="payment option" formControlName="payment_type" (change)="calTotalAmt(false, true)">
						<label for="advance" class="form-check-label word-break w-45" [id]="invSidebar?.partial_payment?.pay_partial_id">{{invSidebar?.partial_payment?.pay_partial}}</label>
						<div class="w-55 d-flex align-items-center justify-content-between word-break" [id]="invSidebar?.partial_payment?.pay_partial_id">
							<span class="me-5">: &nbsp;</span>
							<span>{{partialPayPercentage}}%</span>
						</div>
					</div>
				</div>
				<hr class="bg-transparent rounded-pill opacity-100 bk-border-prop border-bottom-0-imp">
			</div>
			<!-- Add tip -->
			<div *ngIf="invData?.settings?.allow_tip_by_cust && !invData?.no_of_payments && (invData?.inv_type == 'custom' || invData?.settings?.tip_settings?.pay_type == 'combine')" formGroupName="tip" [id]="invSidebar?.add_tip_id">
				<label [innerHTML]="invSidebar?.add_tip | safeHtml" class="form-label bk-label-clr bk-label-fw"></label>
				<div class="mb-25">
					<div class="input-group input-group-ltr">
						<span class="input-group-text" bk-currency></span>
						<input type="text" class="form-control" BKNumeric formControlName="pay" [ngClass]="{'is-invalid': tipGrp.controls['pay'].touched && tipGrp.controls['pay'].errors}" [placeholder]="invSidebar?.tip_placeholder" (blur)="calTotalAmt(false, true)">
					</div>
					<div class="tjs-error" *ngIf="tipGrp.controls['pay'].touched && tipGrp.controls['pay']?.errors?.maxPriceLimit">
						<i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.maxAmtRange | translate}}
					</div>
				</div>
				<hr class="bg-transparent rounded-pill opacity-100 bk-border-prop border-bottom-0-imp">
			</div>
		</ng-container>
	</div>
	<!-- Side bar summary -->
	<div class="position-relative tjs-summary__content">
		<div class="mb-20" [id]="invSidebar?.summary_label_id">
			<h5 class="mb-15 bk-label-clr bk-label-fw">{{invSidebar?.summary_label}}</h5>
			<ul class="tjs-list tjs-summary__list list-unstyled d-flex flex-column gap-15">
				<!-- Invoice total -->
				<li class="bk-label" [id]="invSidebar?.inv_total_id">
					<div class="w-50 word-break" [innerHTML]="invSidebar?.inv_total | safeHtml"></div>
					<div class="fw-bold d-flex align-items-center justify-content-between w-50 word-break">
						<span>: &nbsp;</span>
						<bk-amount-display [isZero]="true" [amount]="summary.inv_total"></bk-amount-display>
					</div>
				</li>
				<!-- Card hold -->
				<li [id]="invSidebar?.card_hold_id" *ngIf="invData?.booking_ids && invData?.booking_ids.length > 0 && summary?.card_hold" class="bk-label">
					<div class="w-50 word-break" [innerHTML]="invSidebar?.card_hold | safeHtml"></div>
					<div  class="fw-bold d-flex align-items-center justify-content-between w-50">
						<span>: &nbsp;</span>
						<div class="tjs-tooltip-wrap summary--tooltip">
							<i class="tjsicon-attention tjs-pointer td-badge"></i>
							<div class="tjs-tooltip tjs-tooltip--lg tjs-tooltip--left tjs-scrollbar mb-0-imp tjs-overflow-scroll tjs-maxheight-250">
								<ng-container *ngFor="let bkng of invData?.booking_ids;trackBy: utilServ.trackByFnIndex">
									<div class="fs-15 d-flex justify-content-between px-15 py-10 rounded" *ngIf="summary?.card_hold?.[bkng]">
										<span class="d-inline-block">#{{bkng}}</span>
										<span class="fw-bold"><bk-amount-display [isZero]="true" [amount]="summary.card_hold?.[bkng]"></bk-amount-display></span>
									</div>
								</ng-container>
							</div>
						</div>
					</div>
				</li>
				<!-- Tip -->
				<li [id]="invSidebar?.tip_amt_id" class="bk-label" *ngIf="summary?.tip">
					<div class="w-50 word-break" [innerHTML]="invSidebar?.tip_amt | safeHtml"></div>
					<div class="fw-bold d-flex align-items-center justify-content-between w-50 word-break">
						<span>: &nbsp;</span>
						<bk-amount-display [isZero]="true" [amount]="summary.tip"></bk-amount-display>
					</div>
				</li>
				<!-- Advance paid amount -->
				<li *ngIf="(invData?.paid_amount && (invData?.paid_amount != summary?.inv_total))" [id]="invSidebar?.advance_amt_id" class="bk-label">
					<div class="d-flex align-items-center w-50">
						<div class="word-break me-5" [innerHTML]="invSidebar?.advance_amt | safeHtml"></div>
						<span class="badge rounded-pill px-10 py-5 fw-bold badge-success--opacity">Paid</span>
					</div>
					<div class="fw-bold d-flex align-items-center justify-content-between w-50 word-break">
						<span>: &nbsp;</span>
						<bk-amount-display [isZero]="true" [amount]="invData?.paid_amount"></bk-amount-display>
					</div>
				</li>
				<!-- Advance -->
				<li *ngIf="paymentType == 'partial' && summary.advance && summary.advance !== summary?.inv_total" [id]="invSidebar?.advance_amt_id" class="bk-label">
					<div class="w-50 word-break" [innerHTML]="invSidebar?.advance_amt| safeHtml"></div>
					<div class="fw-bold d-flex align-items-center justify-content-between w-50 word-break">
						<span>: &nbsp;</span>
						<span class="dir-ltr d-inline-flex gap-5"><bk-amount-display [isZero]="true" [amount]="summary.advance" class="d-inline-block"></bk-amount-display> <span class="d-inline-block">({{partialPayPercentage}}%)</span></span>
					</div>
				</li>
				<!-- Total amount -->
				<li *ngIf="summary?.total !== summary?.inv_total" [id]="invSidebar?.total_id" class="bk-label">
					<div class="w-50 word-break" [innerHTML]="invSidebar?.total | safeHtml"></div>
					<div class="fw-bold d-flex align-items-center justify-content-between w-50 word-break">
						<span>: &nbsp;</span>
						<bk-amount-display [isZero]="true" [amount]="summary.total"></bk-amount-display>
					</div>
				</li>
				<!-- Remaining -->
				<li *ngIf="summary?.remaining && summary?.remaining != summary?.inv_total" [id]="invSidebar?.remaining_amt_id" class="bk-label">
					<div class="w-50 word-break" [innerHTML]="invSidebar?.remaining_amt | safeHtml"></div>
					<div class="fw-bold d-flex align-items-center justify-content-between w-50 word-break">
						<span>: &nbsp;</span>
						<bk-amount-display [isZero]="true" [amount]="summary.remaining"></bk-amount-display>
					</div>
				</li>
			</ul>
		</div>
		<hr class="bg-transparent rounded-pill opacity-100 bk-border-prop border-bottom-0-imp">
		<div>
			<!-- Pay amount -->
			<h4 class="d-flex flex-wrap align-items-center justify-content-between flex-grow-1 mb-0" [id]="invSidebar?.pay_id">
				<div class="bk-label-clr bk-label-fw" [innerHTML]="invSidebar?.pay ?? 'Pay' | safeHtml"></div>
				<div class="fw-bold bk-label-clr bk-label-fw">
					<bk-amount-display [isZero]="true" [amount]="summary.pay"></bk-amount-display>
				</div>
			</h4>
		</div>
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</div>
</div>
