import { Injectable, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
// Services
import { ApiServ, InitServ, LoaderServ, UtilServ, NgOnDestroy, BkngFormServ, BuildCustomSectionService } from '../';
//Custom validator
import { CustomValidators } from '../../Global/GlobalDefault';
// Pipes
import { RmSpclCharAndSpacePipe } from "src/app/Pipes";
// Constants
import { TEXT_REG_EXP } from '../../Constants';
@Injectable({
	providedIn: 'root'
})
export class BkngCustomSectionService {
	// Variables
	BKFrm!: FormGroup;
	buildCustomFields: boolean = true;
	prefilledData: any;
	cDRef: any;
	loaderId: string = 'custom-field';
	customFields: any;
	existingSections: any = [];
	rmSpclCharAndSpacePipe: any; // Form element pipe
	stepType: string = 'all';
	// convenience getter for easy access to form fields
	get customFieldsGroup() {
		return <FormGroup>this.BKFrm.controls['custom_fields'];
	}
	get cfCheckboxGroup() {
		return <FormGroup>this.BKFrm.controls['custom_fields_checkbox'];
	}
	stepWiseCustGrp: any = {
		step_one: [],
		step_two: [],
		step_four: []
	}
	stepWiseCustFields: any = {
		step_one: [],
		step_two: [],
		step_four: []
	}
	custFieldsObj: any = {};
	depFieldDisWhen: any = {};
	custFieldChilds: any = {};
	priceableCustFields: any = [];
	priceableCustFieldsObj: any = {};
	optSelDisplayMsg: any = {};
	isCustFieldAlreadyPrefilled: boolean = false;
	isRescFirstLoad: boolean = false;
	videoFieldsAspectRatio: any = {}
	checkboxOptNames: any = {};
	// eslint-disable-next-line max-params
	constructor(public initServ: InitServ, public apiServ: ApiServ, public loader: LoaderServ, private utilServ: UtilServ, @Self() public destroy: NgOnDestroy, public frmBldr: FormBuilder, private bkngFormServ: BkngFormServ, private buildCustSecServ: BuildCustomSectionService, private customValidators: CustomValidators) {
		// Remove pipe
		this.rmSpclCharAndSpacePipe = new RmSpclCharAndSpacePipe();
	}
	/**
	 * Reset variables on industry or form change
	 */
	public resetVal(): void {
		this.prefilledData = null;
		this.customFields = null;
		this.existingSections = [];
		this.stepType = 'all';
		this.stepWiseCustGrp = {
			step_one: [],
			step_two: [],
			step_four: []
		}
		this.stepWiseCustFields = {
			step_one: [],
			step_two: [],
			step_four: []
		}
		this.custFieldsObj = {};
		this.depFieldDisWhen = {};
		this.custFieldChilds = {};
		this.priceableCustFields = [];
		this.priceableCustFieldsObj = {};
		this.optSelDisplayMsg = {};
		this.isCustFieldAlreadyPrefilled = false;
		this.isRescFirstLoad = false;
		this.videoFieldsAspectRatio = {};
		this.checkboxOptNames = {};
	}
	/**
	 * Retrieves custom fields for a booking form.
	 * @param {any} BKFrm - The booking form object.
	 * @param {any} cDRef - The reference to the custom data object.
	 * @param {any} reqData - The request data object.
	 * @param {string} bookingType - The type of booking.
	 */
	public getCustomFields(BKFrm: any, cDRef: any, reqData: any, bookingType: string): void {
		this.BKFrm = BKFrm;
		this.cDRef = cDRef;
		this.buildCustSecServ.customFields = null;
		this.loader.show(this.loaderId);
		if(reqData.booking_id && reqData.booking_id){
			this.isRescFirstLoad = true;
		}
		this.apiServ.callApi('POST', 'FormCustomFields', reqData).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.formCustomFieldsRes(res, bookingType));
	}
	/**
	 * Handles the response from the API call to retrieve custom fields for a booking form.
	 * @param {any} res - The response object from the API call.
	 * @param {string} bookingType - The type of booking.
	 */
	private formCustomFieldsRes(res: any, bookingType: string): void {
		if(this.apiServ.checkAPIRes(res)){
			this.buildCustSecServ.customFields = res.data;
			this.buildCustSecServ.buildCustomFields();
			this.rebuildCustomFields(this.BKFrm, this.cDRef, bookingType);
		}
		this.loader.hide(this.loaderId);
	}
	/**
	 * Rebuilds the custom fields for a booking form.
	 * @param {any} BKFrm - The booking form object.
	 * @param {any} cDRef - The reference to the custom data object.
	 * @param {string} bookingType - The type of booking.
	 */
	public rebuildCustomFields(BKFrm: any, cDRef: any, bookingType: string, selServ: any = null): void {
		this.BKFrm = BKFrm;
		this.cDRef = cDRef;
		let requiredData = this.bkngFormServ.getCustomFieldsReqData(this.BKFrm.value);
		if(requiredData){
			this.loader.show(this.loaderId);
			this.resetCustFieldVal();
			if(this.BKFrm.controls['custom_fields'].value && this.utilServ.checkArrLength(Object.keys(this.BKFrm.controls['custom_fields'].value))){
				this.existingSections = [];
				for(let field in this.BKFrm.controls['custom_fields'].value){
					let res: any = this.utilServ.splitUnderscore(field);
					this.existingSections.push(res[0]);
				}
			}
			if(this.prefilledData && this.utilServ.objHasProp(this.prefilledData, 'status')){
				requiredData['status'] = this.prefilledData?.status;
			}
			let data: any = this.buildCustSecServ.getFilteredCustomFields(requiredData, this.prefilledData, this.isRescFirstLoad);
			this.filterCustomfields(data, bookingType, selServ);
		}
	}
	/**
	 * Filters custom fields data based on the booking type.
	 * @param {any} data
	 * @param {string} bookingType - The type of booking.
	 */
	private filterCustomfields(data: any, bookingType: string, selServ: any = null): void {
		this.customFields = JSON.parse(JSON.stringify(data));
		this.buildCustomFieldsData(bookingType);
		setTimeout(()=> {
			this.buildPricableCustomFields();
			// Set required validation on the step one fields
			this.addRemoveStepWiseValOnCustFields();
			if(bookingType == 'reschedule'){
				this.removeReqValidation(selServ);
			}
			this.loader.hide(this.loaderId);
			this.cDRef.detectChanges();
		},100)
	}
	/**
	 * Removes required validation from custom fields based on the selected service.
	 * @param {any} selServ - The selected service object.
	 */
	private removeReqValidation(selServ: any): void {
		// Check if selected service exists, has can_customer_edit property set to 'no', and if custom fields exist.
		if(selServ && this.utilServ.objHasProp(selServ, 'can_customer_edit') && selServ.can_customer_edit == 'no' && this.customFieldsGroup.value && this.utilServ.checkArrLength(Object.keys(this.customFieldsGroup.value))){
			for(let ctrl in this.customFieldsGroup.value){
				if(this.utilServ.objHasProp(this.custFieldsObj, ctrl)){
					// If the field type is Checkbox, remove required validation from the cfCheckboxGroup.
					if(this.custFieldsObj[ctrl].field_type == 'Checkbox'){
						this.addRemoveRequiredValidation(false, this.cfCheckboxGroup, ctrl);
					}else{
						// If the field type is not Checkbox, remove required validation from the customFieldsGroup and set validation on text and textarea.
						this.addRemoveRequiredValidation(false, this.customFieldsGroup, ctrl);
						this.setValidationOnTextAndTextarea(ctrl);
					}
				}
			}
		}
	}
	/**
	 * Sets validation on text and textarea custom fields.
	 * @param {string} ctrl - The custom field control.
	 */
	private setValidationOnTextAndTextarea(ctrl: string): void {
		if(['Text', 'Textarea'].includes(this.custFieldsObj[ctrl].field_type)){
			let control: any = this.customFieldsGroup.controls[ctrl];
			if(this.custFieldsObj[ctrl].field_type == 'Text'){
				control.setValidators([Validators.pattern(TEXT_REG_EXP), this.customValidators.shortTextValidate]);
			}else{
				control.setValidators([Validators.pattern(TEXT_REG_EXP), this.customValidators.longTextValidate]);
			}
			control.updateValueAndValidity();
		}
	}
	/**
	 * Build the priceable custom fields
	 */
	public buildPricableCustomFields(){
		this.BKFrm.removeControl('priceable_custom_fields');
		this.BKFrm.addControl('priceable_custom_fields', new FormGroup({}));
		if(this.customFields && this.utilServ.checkArrLength(this.customFields)){
			for(let customSection of this.customFields){
				if(customSection && this.utilServ.checkArrLength(customSection.custom_fields)){
					for(let field of customSection.custom_fields){
						this.setPriceableFieldArray(customSection, field)
					}
				}
			}
		}
		this.bkngFormServ.onPriceableFieldChange.next(true);
	}
	/**
	 * Build priceable field according to pricing type like taxable or not
	 * @param customSection
	 * @param field
	 */
	private setPriceableFieldArray(customSection: any, field: any){
		let priceableCustomFormGroup:any = <FormGroup>this.BKFrm.controls['priceable_custom_fields'];
		if(field.is_priceable){
			let controlName = this.setControlNameForPriceableObj(field);
			if(!priceableCustomFormGroup.get(controlName)){
				priceableCustomFormGroup.addControl(controlName, this.frmBldr.array([]));
			}
			let priceableField : any = {
				custom_field_section_id: customSection._id,
				_id: field._id,
				section_field_comb_id : customSection._id+'_'+field._id,
				field_type: field.field_type,
				section_name: customSection.name,
				section_slug: customSection.slug,
				name : field.name,
				slug: field.slug,
				value : [],
				apply_to : field.apply_on_bookings,
				is_taxable : field.is_taxable,
				add_price_in : field.add_price_in,
				add_as_separate_charge : field.add_as_separate_charge,
				separate_charge_type : field.separate_charge_type,
				provider_pay_type: field.provider_pay_type,
				provider_pay_amount : field.provider_pay_amount,
				provider_pay_unit : field.provider_pay_unit
			}
			if(field.field_type == 'Quantity Based'){
				priceableField['quantity_details'] = {
					enable_quantity_based : field?.quantity_details?.enable_quantity_based,
					quantity_based: field?.quantity_details?.quantity_based,
					qty_based_price_length: field?.quantity_details?.qty_based_price_length,
					qty_based_price_length_type: field?.quantity_details?.qty_based_price_length_type,
					prices_sa : field?.quantity_details?.prices_sa,
					prices_ml : field?.quantity_details?.prices_ml,
					times_sa : field?.quantity_details?.times_sa,
					times_ml : field?.quantity_details?.times_ml
				}
			}else{
				priceableField = this.setOptionBasedPriceTime(field, priceableField);
			}
			priceableField = this.setFieldValue(field, priceableField);
			let priceableCustomFormObj:any = <FormGroup>priceableCustomFormGroup.controls[controlName];
			priceableCustomFormObj.push(new FormControl(priceableField));
		}
	}
	/**
	 * Set the priceable custom field value
	 * @param field
	 * @param priceableField
	 * @returns
	 */
	private setFieldValue(field: any, priceableField: any){
		if(field && field?.field_type == 'Checkbox'){
			let customFields = this.BKFrm.controls['custom_fields'].value;
			if(customFields && priceableField && customFields[priceableField?.section_field_comb_id]){
				priceableField = this.pushValueForCheckbox(customFields, priceableField);
			}
		}else{
			priceableField = this.setNonCheckboxFieldVal(field, priceableField);
		}
		return priceableField;
	}
	/**
	 * Sets the value of a non-checkbox field in a priceable field object based on the corresponding custom field value.
	 * @param {any} field - The custom field object to get the value from.
	 * @param {any} priceableField - The priceable field object to set the value in.
	 * @returns {any} - Returns the updated priceable field object with the value set.
	 */
	private setNonCheckboxFieldVal(field: any, priceableField: any): any {
		let customFields = this.BKFrm.controls['custom_fields'].value;
		priceableField.value = [];
		if(customFields[priceableField?.section_field_comb_id]){
			let val = customFields[priceableField?.section_field_comb_id];
			if(field?.field_type == 'Priceable Input' || field?.field_type == 'Quantity Based'){
				val = (val) ? val.toString() : '';
			}
			(priceableField.value).push(val);
		}
		return priceableField;
	}
	/**
	 * Push values for checkbox value array
	 * @param customFields
	 * @param priceableField
	 * @returns
	 */
	private pushValueForCheckbox(customFields: any, priceableField: any){
		if(customFields[priceableField?.section_field_comb_id] && this.utilServ.checkArrLength(Object.keys(customFields[priceableField?.section_field_comb_id]))){
			for(let opt in customFields[priceableField?.section_field_comb_id]){
				if(customFields[priceableField?.section_field_comb_id][opt]){
					if(!priceableField.value){
						priceableField.value = [];
					}
					if(customFields[priceableField?.section_field_comb_id][opt]){
						(priceableField.value).push(opt)
					}
				}
			}
		}
		return priceableField;
	}
	/**
	 * Make control for option based time/price for field
	 * @param field
	 * @param priceableField
	 * @returns
	 */
	private setOptionBasedPriceTime(field: any, priceableField: any){
		if(field.field_type == "Dropdown" ||  field.field_type == "Checkbox" || field.field_type == "Radio"){
			let i = 0;
			priceableField['prices_sa'] = {};
			priceableField['times_sa'] = {};
			priceableField['prices_ml'] = {};
			priceableField['times_ml'] = {};
			if(this.utilServ.checkArrLength(field.options)){
				for(let option of field.options){
					if(field.field_type == "Checkbox"){
						option = option.replace(/\s/g, "");
						option = this.rmSpclCharAndSpacePipe.transform(option);
					}
					priceableField['prices_sa'][option] = {val: field['options_data'][i]['prices_sa'][0], type: field['options_data'][i]['amount_type'], calc_on: field['options_data'][i]['calculate_on']}
					priceableField['times_sa'][option] = {val: field['options_data'][i]['times_sa'][0], type: field['options_data'][i]['amount_type'], calc_on: field['options_data'][i]['calculate_on']}
					priceableField['prices_ml'][option] = {val: field['options_data'][i]['prices_ml'][0], type: field['options_data'][i]['amount_type'], calc_on: field['options_data'][i]['calculate_on']}
					priceableField['times_ml'][option] = {val: field['options_data'][i]['times_ml'][0], type: field['options_data'][i]['amount_type'], calc_on: field['options_data'][i]['calculate_on']}
					i++;
				}
			}
		}
		return priceableField;
	}
	/**
	 * Set control name according to type
	 * @param field
	 * @returns
	 */
	private setControlNameForPriceableObj(field: any){
		if(field?.is_taxable){
			switch(field?.add_price_in){
				case 'after_discounted_total':
					return 'after_discounted_total';
				case 'exempt_from_freq_disc':
					return 'exempt_from_freq_disc';
				default:
					return 'include_in_freq_disc';
			}
		}else{
			return 'non_taxable';
		}
	}
	/**
	 * Update the value of priceable custom fields
	 * @param name
	 * @param value
	 * @param type
	 */
	public changeValueForPriceable(name: any, value: any, field: any){
		if(this.BKFrm.controls['priceable_custom_fields'].value){
			let fieldName = this.setControlNameForPriceableObj(field);
			if(fieldName && this.BKFrm.controls['priceable_custom_fields'].value[fieldName] && this.utilServ.checkArrLength(this.BKFrm.controls['priceable_custom_fields'].value[fieldName])){
				for(let priceableField of this.BKFrm.controls['priceable_custom_fields'].value[fieldName]){
					this.updateFieldValue(priceableField, field, value, name);
				}
			}
		}
		this.bkngFormServ.onPriceableFieldChange.next(true);
	}
	/**
	 * Updates the value of a priceable field object based on the corresponding custom field value.
	 * @param {any} priceableField - The priceable field object to update the value in.
	 * @param {any} field - The custom field object to get the value from.
	 * @param {any} value - The value to set in the priceable field object.
	 * @param {any} name - The name of the section field combination to check against.
	 */
	private updateFieldValue(priceableField: any, field: any, value: any, name: any){
		if(priceableField?.section_field_comb_id == name){
			priceableField.value = [];
			let newValue = this.setPriceableFieldValue(field, value);
			if(field?.field_type == 'Priceable Input'){
				newValue = (newValue) ? newValue.toString() : '';
			}
			if(newValue){
				if(field?.field_type == 'Checkbox'){
					priceableField.value = newValue;
				}else{
					(priceableField.value).push(newValue)
				}
			}
		}
	}
	/**
	 * Set the priceable field value
	 * @param field
	 * @param value
	 * @returns
	 */
	public setPriceableFieldValue(field: any, value: any){
		if(field?.field_type == 'Dropdown' || field?.field_type == 'Priceable Input'){
			return value?.target?.value;
		}else{
			return value;
		}
	}
	// -----------------------------NEW FUNCTIONS-------------------------------------------
	/**
	 * Builds customized fields data for a form.
	 * It starts by getting the form layout using the industry ID and form ID.
	 * Then, it resets the custom field values and checks if there is a length to the custom fields array.
	 * If there is, it iterates through each custom field and adds the corresponding form control.
	 * It then sets the step-wise data for custom fields and groups.
	 * Finally, it deletes any extra custom fields, runs change detection, and returns void.
	 * @param {string} bookingType - The type of booking.
	 */
	private buildCustomFieldsData(bookingType: string): void {
		// Get form layout using industry ID and form ID
		let layout: string = this.bkngFormServ.getFormLayout(this.BKFrm.value.industry_id, this.BKFrm.value.form_id);
		// Reset custom field values
		this.resetCustFieldVal();
		// Check if there is a length to the custom fields array
		if(this.utilServ.checkArrLength(this.customFields)){
			// Iterate through custom fields
			for(let custField of this.customFields){
				let fieldSlug: string = 'custom_fields_'+custField._id;
				let priceableCustFieldGroup: any = {
					id: custField._id,
					name: custField.name,
					isIndividual: custField.is_individual ? true : false,
					custFields: []
				};
				// Check if there is a length to the custom fields property of custom field
				if(this.utilServ.checkArrLength(custField?.custom_fields)){
					// Iterate through each custom field and add the corresponding form control
					for(let field of custField.custom_fields){
						field.name = this.utilServ.getFormParamName(field);
						let fieldName: string = custField._id+'_'+field._id;
						this.custFieldsObj[fieldName] = field;
						this.setMinMaxDate(fieldName);
						this.addCustFieldCtrl(field, fieldName, bookingType);
						this.stepWiseCustFields = this.setStepWiseData(fieldSlug, fieldName, layout, this.stepWiseCustFields);
						this.processOpt(custField._id, field, fieldName);
						priceableCustFieldGroup = this.setPriceableFieldData(priceableCustFieldGroup, field, fieldName);
						this.setVideoFieldAspectRatio(fieldName, field);
					}
				}
				this.stepWiseCustGrp = this.setStepWiseData(fieldSlug, custField, layout, this.stepWiseCustGrp);
				if(this.utilServ.checkArrLength(priceableCustFieldGroup.custFields)){
					this.priceableCustFields.push(priceableCustFieldGroup);
				}
			}
			this.resetPriceableInputFieldDefVal();
			this.prefillCustFields();
		}
		// Delete any extra custom fields
		this.deleteExtraCustFields();
		// Run change detection
		this.cDRef.detectChanges();
	}
	/**
	 * Resets the default value of the priceable input field.
	 */
	private resetPriceableInputFieldDefVal(): void {
		// If the custom field is not already prefilled and the prefilled data has priceable_custom_fields
		if(!this.isCustFieldAlreadyPrefilled && this.prefilledData && this.utilServ.objHasProp(this.prefilledData, 'priceable_custom_fields') && this.prefilledData?.priceable_custom_fields && this.utilServ.checkArrLength(Object.keys(this.prefilledData.priceable_custom_fields))){
			this.resetAllPriceableInputFields();
		}
	}
	/**
	 * Resets the first value of each priceable input field in the prefilled data.
	 */
	private resetAllPriceableInputFields(): void {
		// Loops through the prefilled data and resets the first value of each field
		if(this.prefilledData.priceable_custom_fields && this.utilServ.checkArrLength(Object.keys(this.prefilledData.priceable_custom_fields))){
			for(let obj in this.prefilledData.priceable_custom_fields){
				if(this.utilServ.checkArrLength(this.prefilledData.priceable_custom_fields[obj])){
					for(let field of this.prefilledData.priceable_custom_fields[obj]){
						this.resetPriceableInputFirstOnlyVal(field);
					}
				}
			}
		}
	}
	/**
	 * Resets the default value of the first priceable input field.
	 * @param {any} field - The custom field object to be reset.
	 */
	private resetPriceableInputFirstOnlyVal(field: any): void {
		let ctrl: string = field.custom_field_section_id+'_'+field._id;
		if(field.field_type == 'Priceable Input' && field.apply_to == 'first-only' && this.customFieldsGroup.contains(ctrl)){
			this.customFieldsGroup.controls[ctrl].setValue(0);
		}
	}
	/**
	 * Prefills the custom fields in the form with prefilled data if available.
	 * - Checks if custom fields are not already prefilled and if there is prefilled data.
	 * - Iterates through the custom fields in the prefilled data and sets their values in the form.
	 * - Handles cases where custom fields might have moved between groups.
	 * - Marks the custom fields as already prefilled to avoid redundant operations.
	 * - Triggers change detection to update the view.
	 */
	private prefillCustFields(): void {
		// Check if custom fields are not already prefilled and there is prefilled data available
		if(!this.isCustFieldAlreadyPrefilled && this.prefilledData && this.utilServ.objHasProp(this.prefilledData, 'custom_fields') && this.prefilledData?.custom_fields && this.utilServ.checkArrLength(Object.keys(this.prefilledData.custom_fields))){
			// Iterate through the custom fields in the prefilled data
			for(let ctrl in this.prefilledData.custom_fields){
				let val : any = this.prefilledData.custom_fields[ctrl];
				// Check if the form group contains the custom field
				if(this.customFieldsGroup.contains(ctrl)){
					this.prefillValInForm(ctrl, val);
				}else{
					// This to handle the case where the custom field is move from group to individual or vise versa.
					let newCtrl: string | null = this.extractUpdatedFormCtrl(ctrl);
					if(newCtrl){
						this.prefillValInForm(newCtrl, val);
					}
				}
			}
			// Mark custom fields as already prefilled to avoid redundant operations
			this.isCustFieldAlreadyPrefilled = true;
		}
		// Trigger change detection to update the view
		this.cDRef.detectChanges();
	}
	/**
	 * Extracts the updated form control name for a given custom field control.
	 * - Extracts the custom field ID from the given control name.
	 * - Checks if the custom field ID exists in the custom fields object.
	 * - If the custom field ID exists, it retrieves the new control name for the custom field.
	 * - Returns the new control name if found; otherwise, returns null.
	 * @param {string} ctrl - The original control name.
	 * @returns {string | null} - The updated control name or null if not found.
	 */
	private extractUpdatedFormCtrl(ctrl: string): string | null {
		// Extract the custom field ID from the given control name
		let custFieldId: string | null = this.extractCustFieldIdFromCtrl(ctrl);
		// Check if the custom field ID exists and if there are custom fields in the object
		if(custFieldId && this.custFieldsObj && this.utilServ.checkArrLength(Object.keys(this.custFieldsObj))){
			// Retrieve the new control name for the custom field
			let newCtrl: string | null = this.extractNewCtrlForCustField(custFieldId);
			// Return the new control name if found
			if(newCtrl){
				return newCtrl;
			}
		}
		// Return null if the new control name is not found
		return null;
	}
	/**
	 * Extracts the custom field ID from the given form control name.
	 * @param {string} ctrl - The name of the form control.
	 * @returns {string | null} - The extracted custom field ID if available, otherwise null.
	 */
	private extractCustFieldIdFromCtrl(ctrl: string): string | null {
		// Split the string by the underscore character
		let parts: string[] = ctrl.split('_');
		// Check if there is a part after the underscore
		if (parts.length > 1) {
			// Return the last part of the split string as the custom field ID
			return parts[parts.length - 1];
		}else{
			// Return null if there is no underscore or parts after the underscore
			return null;
		}
	}
	/**
	 * Extracts the new control name for a given custom field ID from the custom fields object.
	 * @param {string} custFieldId - The ID of the custom field to find.
	 * @returns {string | null} - The new form control name if found, otherwise null.
	 */
	private extractNewCtrlForCustField(custFieldId: string): string | null {
		// Convert the custom field ID to a string, in case it's not already a string
		let targetStr: string = String(custFieldId);
		// Use Object.keys to get an array of the keys from the custom fields object
		// Then use find to locate the key that matches the custom field ID
		let entry: string | undefined = Object.keys(this.custFieldsObj).find((key) => {
			// Split the key by underscore to check for the custom field ID
			let parts = key.split('_');
			// Check if there is more than one part in the split result and if the second part matches the custom field ID
			return parts.length > 1 && parts[1] === targetStr;
		});
		// Return the found key, or null if no matching key is found
		return entry || null;
	}
	/**
	 * Prefills the value in the specified form control.
	 * - If the value is an object, it handles the prefill for checkbox values.
	 * - Otherwise, it sets the value directly in the form control.
	 * @param {string} ctrl - The name of the form control.
	 * @param {any} val - The value to be set in the form control.
	 */
	private prefillValInForm(ctrl: string, val: any): void {
		// Check if the value is an object
		if(typeof val === 'object'){
			// Handle the prefill for checkbox values
			this.prefillCheckboxVal(ctrl, val);
		}else{
			// Set the value directly in the form control
			this.customFieldsGroup.controls[ctrl].setValue(val);
		}
	}
	/**
	* Pre-fill checkbox values.
	* @param {string} ctrl - The control object's name.
	* @param {any} val - The value of the control object.
	*/
	private prefillCheckboxVal(ctrl: string, val: any): void {
		// Update the value of the control object.
		this.customFieldsGroup.controls[ctrl].patchValue(val);
		// Loop through the checkbox group.
		if(val && this.utilServ.checkArrLength(Object.keys(val))){
			for(let obj in val){
				// Check if the value of this checkbox is true.
				if(val[obj] && this.cfCheckboxGroup.contains(ctrl)){
					// Add this checkbox to the form array of checkboxes.
					(<FormArray>this.cfCheckboxGroup.controls[ctrl]).push(new FormControl(obj));
				}
			}
		}
	}
	/**
	 * Adds data to group.custFields if field is priceable.
	 * @param {any} group - The group object to add the data to.
	 * @param {any} field - The field object to check if it is priceable.
	 * @param {string} ctrl - The control identifier for the field.
	 * @returns {any} - The updated group object with the added custFields array.
	 */
	private setPriceableFieldData(group: any, field: any, ctrl: string): any {
		if(field.is_priceable && field.field_type !== 'Priceable Input'){
			group.custFields.push({
				id: field._id,
				name: field.name,
				ctrl: ctrl,
				isCheckbox: field.field_type == 'Checkbox' ? true : false
			})
		}
		return group;
	}
	/**
	 * Processes the options selected by the user and sets the corresponding display message.
	 * @param {any} groupId - the id of the group that contains the field
	 * @param {any} field - the field object containing the options
	 * @param {string} fieldName - the name of the field object
	 */
	private processOpt(groupId: any, field: any, fieldName: string): void {
		// Clear the current display message
		this.optSelDisplayMsg[fieldName] = {};
		// Check if there are any options to process
		if(this.utilServ.checkArrLength(field.options)){
			// Create a fieldName variable using the groupId and field id
			let fieldName: string = groupId+'_'+field._id;
			// Loop through the options array
			for(let i = 0; i < (field.options).length; i++){
				// Create an optObj object containing the option value and its associated data
				let optObj: any = {
					val: field.options[i],
					data: (this.utilServ.checkArrLength(field.options_data)) ? field.options_data[i] : null
				}
				// Set the display data for the dependent field
				this.setDepFieldDisplayData(groupId, field, fieldName, optObj);
				// Set the display message for the current option
				this.setOptDisplayMsg(fieldName, optObj);
			}
		}else{
			if(field.field_type == 'Quantity Based'){
				let obj: any = {
					val: (1).toString(),
					data: {
						enable_message: field.enable_message,
						message_display_type: field.message_display_type,
						message: field.message
					}
				}
				this.setOptDisplayMsg(fieldName, obj);
			}
		}
	}
	/**
	 * Sets the display message for a given fieldName and option object.
	 * @param {any} fieldName - the field name to which the option belongs.
	 * @param {any} optObj - the option object that contains the display message data.
	 */
	private setOptDisplayMsg(fieldName: any, optObj: any): void {
		if(optObj && optObj.data && Object.keys(optObj.data).length > 0){
			this.optSelDisplayMsg[fieldName][optObj.val] = {
				status: optObj?.data?.enable_message,
				type: optObj?.data?.message_display_type,
				msg: optObj?.data?.message
			};
			// Checks whether the message is available and if not, sets the display status to false.
			if(!(optObj?.data?.message && this.utilServ.isNoteAvailable(optObj?.data?.message))){
				this.optSelDisplayMsg[fieldName][optObj.val].status = false;
			}
		}
	}
	/**
	* Sets the display data for dependent fields of a hierarchical field
	* @param {any} groupId - The unique id of the group
	* @param {any} field - The hierarchical field object
	*/
	private setDepFieldDisplayData(groupId: any, field: any, fieldName: string, optObj: any): void {
		if(field.is_hierarchical){
			// Check if the option has dependent fields
			if(optObj.data.has_dependent_fields && this.utilServ.checkArrLength(optObj.data.dependent_fields)){
				// Call the processDepFields function to process the dependent fields for this option
				this.processDepFields(groupId, fieldName, field, optObj);
			}
		}
	}
	/**
	* Process dependent fields for a given group ID, field name, field, and options
	* @param {any} groupId - The unique id of the group
	* @param {string} fieldName - The name of the hierarchical field
	* @param {any} field - The hierarchical field object
	* @param {any} opt - The hierarchical field option object
	*/
	private processDepFields(groupId: any, fieldName: string, field: any, opt: any): void {
		if(this.utilServ.checkArrLength(opt.data.dependent_fields)){
			for(let depField of opt.data.dependent_fields){
				let depFieldCtrl: string = groupId+'_'+depField;
				let value: string = (field.field_type == 'Checkbox') ? this.rmSpclCharAndSpacePipe.transform(opt.val) : opt.val;
				if(this.utilServ.objHasProp(this.depFieldDisWhen, depFieldCtrl)){
					if(!(this.depFieldDisWhen[depFieldCtrl]['val']).includes(value)){
						(this.depFieldDisWhen[depFieldCtrl]['val']).push(value);
					}
				}else{
					// Create an object with dependent field data
					this.depFieldDisWhen[depFieldCtrl] = {
						ctrl: fieldName,
						val: [value],
						isCheckbox: (field.field_type == 'Checkbox') ? true : false
					}
				}
				// Add dependent fields to the parent field's child list
				// If the field does not have children, create an empty list
				if(!this.utilServ.objHasProp(this.custFieldChilds, fieldName)){
					this.custFieldChilds[fieldName]= [];
				}
				// If the current dependent field does not already exist in the child list, add it to that list
				if(!(this.custFieldChilds[fieldName]).includes(depFieldCtrl)){
					this.custFieldChilds[fieldName].push(depFieldCtrl);
				}
			}
		}
	}
	/**
	 * Resets the values for custom group and custom fields associated with each step
	 */
	private resetCustFieldVal(): void {
		this.stepWiseCustGrp = { step_one: [], step_two: [], step_four: [] }
		this.stepWiseCustFields = { step_one: [], step_two: [], step_four: [] }
		this.depFieldDisWhen = {};
		this.custFieldChilds = {};
		this.optSelDisplayMsg = {};
		this.priceableCustFields = [];
		this.priceableCustFieldsObj = {};
	}
	/**
	 * Returns the default value of a field based on the booking type.
	 * @param {any} field - The field object.
	 * @param {string} bookingType - The type of booking.
	 * @returns {any} - The default value of the field.
	 */
	private getPriceableInputDefVal(field: any, bookingType: string): any {
		if(bookingType == 'reschedule'){
			if(!field.default_val_in_resc){
				return '';
			}
		}
		return +field.default_value;
	}
	/**
	 * Adds custom field controls to the form group
	 * @param {any} field - The custom field object to add to the form
	 * @param {string} fieldName - The name of the field to add to the form group
	 */
	private addCustFieldCtrl(field: any, fieldName: string, bookingType: string): void {
		// Set default value for priceable inputs
		let defVal: any = '';
		if(field.field_type == 'Priceable Input' && field.is_priceable && field.default_value && !field.is_dependent){
			defVal = this.getPriceableInputDefVal(field, bookingType);
		}
		// Check if the field already exists in the form group
		if(!this.customFieldsGroup.contains(fieldName)){
			// Create control based on field type
			switch (field.field_type) {
				case "Checkbox":
					// Add checkbox form group and array controls
					if(this.utilServ.checkArrLength(field?.options)){
						this.customFieldsGroup.addControl(fieldName, new FormGroup({}));
						this.cfCheckboxGroup.addControl(fieldName, new FormArray([]));
						// Add controls for each checkbox option
						for(let option of field.options){
							let name = this.rmSpclCharAndSpacePipe.transform(option);
							this.checkboxOptNames[name] = option;
							(<FormGroup>(this.customFieldsGroup).controls[fieldName]).addControl(name, new FormControl(false));
						}
					}
					break;
				default:
					// Add form control to form group
					this.customFieldsGroup.addControl(fieldName, new FormControl(defVal));
					break;
			}
		}
	}
	/**
	* Sets the data based on the step layout selected
	* @param {string} fieldSlug - The field slug for the input
	* @param {any} val - The value entered
	* @param {string} layout - The step layout selected
	* @param {any} obj - The object to be returned
	* @returns {any} - Returns the object with the data set
	*/
	private setStepWiseData(fieldSlug: string, val: any, layout: string, obj: any): any {
		switch (layout) {
			case 'multi_step':
				//Adds the value to the appropriate step based on the field slug and step order
				obj = this.setMultiStepData(obj, val, fieldSlug);
				break;
			case 'two_step':
				//Adds the value to the appropriate step based on the field slug and step order
				if(this.bkngFormServ.stepWiseCustGrpPos && (this.bkngFormServ.stepWiseCustGrpPos.step_two).length > 0 && (this.bkngFormServ.stepWiseCustGrpPos.step_two).includes(fieldSlug)){
					(obj['step_two']).push(val);
				}else{
					(obj['step_one']).push(val);
				}
				break;
			default:
				//If no specific step layout is selected, the value is added to the first step
				(obj['step_one']).push(val);
				break;
		}
		//Returns the object with the data set
		return obj;
	}
	/**
	 * Adds the value to the appropriate step based on the field slug and step order for multi step form
	 * @param {any} obj - The object to be returned
	 * @param {any} val - The value entered
	 * @param {string} fieldSlug - The field slug for the input
	 * @returns {any} - Returns the object with the data set
	 */
	private setMultiStepData(obj: any, val: any, fieldSlug: string): any {
		if((this.bkngFormServ.stepWiseCustGrpPos.step_four).length > 0 && (this.bkngFormServ.stepWiseCustGrpPos.step_four).includes(fieldSlug)){
			(obj['step_four']).push(val);
		}else if((this.bkngFormServ.stepWiseCustGrpPos.step_two).length > 0 && (this.bkngFormServ.stepWiseCustGrpPos.step_two).includes(fieldSlug)){
			(obj['step_two']).push(val);
		}else{
			(obj['step_one']).push(val);
		}
		return obj;
	}
	/**
	 * Deletes any extra customer fields that were not included in steps 1, 2, or 3.
	 * If a field was added and is not in the list of customer fields, it will be removed.
	 * If the field is also in the cfCheckboxGroup, it will be removed from that group as well.
	 */
	private deleteExtraCustFields(): void {
		// Combine all customer fields from steps 1, 2, and 3 into one array.
		let custFields: any = [...this.stepWiseCustFields.step_one, ...this.stepWiseCustFields.step_two, ...this.stepWiseCustFields.step_four];
		// Get the keys for all added customer fields
		let addedCustCtrlKeys: any = Object.keys(this.customFieldsGroup.value);
		// If any customer fields are added, iterate through them and check if they are included in the custFields array.
		if(addedCustCtrlKeys && addedCustCtrlKeys.length > 0){
			for(let key of addedCustCtrlKeys){
				// If the added field is not included in the custFields array, remove that field from the customFieldsGroup.
				if(!custFields.includes(key)){
					this.customFieldsGroup.removeControl(key);
					// If the added field is also included in the cfCheckboxGroup, remove it from that group as well.
					if(this.cfCheckboxGroup.contains(key)){
						this.cfCheckboxGroup.removeControl(key);
					}
				}
			}
		}
	}
	/**
	 * Add or remove step-wise validation on custom fields.
	 * @param {boolean} status - A boolean value indicating whether to add or remove the validation. Defaults to 'true'.
	 * @param {string} step - The step in the custom group. Defaults to 'step_one'.
	 */
	public addRemoveStepWiseValOnCustFields(status: boolean = true, step: string = 'step_one'): void {
		// checks if the step has available custom groups defined
		if (this.stepWiseCustGrp[step] && this.stepWiseCustGrp[step].length > 0) {
			// iterates through each custom group on the specified step and handles the fields accordingly
			for (let custGrp of this.stepWiseCustGrp[step]) {
				this.handleGroupFields(status, custGrp);
			}
		}
	}
	/**
	 * Handle group fields based on status and customer group.
	 * If customer group has custom fields and any of them is required and not dependent,
	 * then it adds or removes the required validation based on status.
	 * @param {boolean} status - True if required validation needs to be added else false.
	 * @param {any} custGrp - Customer group object containing custom fields.
	 */
	private handleGroupFields(status: boolean, custGrp: any): void {
		if (custGrp.custom_fields && custGrp.custom_fields.length > 0) {
			for (let custField of custGrp.custom_fields) {
				if (!custField.is_dependent) {
					let ctrl: string = custGrp._id + '_' + custField._id;
					if(custField.field_type == 'Checkbox'){
						this.addRemoveRequiredValidation(status, this.cfCheckboxGroup, ctrl);
					}else{
						this.addRemoveRequiredValidation(status, this.customFieldsGroup, ctrl);
					}
				}
			}
		}
	}
	/**
	 * Aadd or remove required validation on a form control based on isChecked parameter
	 * @param {any} isChecked - Boolean flag indicating if the control needs to have required validation added or removed
	 * @param {FormGroup} ctrlGroup - The FormGroup where the control to be validated exists
	 * @param {string} ctrl - The string value representing the form control name
	 * @param {boolean} setVal - Boolean flag indicating if a new value should be set to the form control
	 * @param {any} val - The new value to be set to the form control if setVal parameter is set to true
	 * @param {boolean} isElem - Boolean flag indicating if isChecked parameter is derived from the selected HTML element value
	 */
	public addRemoveRequiredValidation(isChecked: any = false, ctrlGroup: FormGroup, ctrl: string): void {
		//Get the specific form control from the FormGroup using the control string parameter name
		let control: any = ctrlGroup.controls[ctrl];
		if(control){
			//Mark the form control as untouched
			control.markAsUntouched();
			//If isChecked parameter is true, set the control validators to required, else clear the validators
			if(isChecked){
				let fieldType = this.custFieldsObj[ctrl].field_type;
				switch (fieldType) {
					case 'Text':
						this.addValidationOnText(ctrl, control);
						break;
					case 'Text Area':
						this.addValidationOnTextarea(ctrl, control);
						break;
					case 'Quantity Based':
						control.setValidators(this.getQuantityBasedValidation(ctrl, control.value));
						break;
					default:
						if(this.custFieldsObj[ctrl].is_required){
							control.setValidators([Validators.required]);
						}
						break;
				}
			}else{
				control.clearValidators();
			}
			//Update the form control validity status
			control.updateValueAndValidity();
		}
	}
	/**
	 * Returns an array of validators based on the quantity details of a custom field.
	 * @param {any} ctrl - The control name of the custom field.
	 * @param {any} value - The value of the custom field.
	 * @returns {array} - An array of validators.
	 */
	private getQuantityBasedValidation(ctrl: any, value: any): Array<any> {
		let validaterArr: any = [];
		if(this.custFieldsObj[ctrl].is_required){
			validaterArr.push(Validators.required);
		}
		if(value && value > 0 && this.custFieldsObj?.[ctrl]?.quantity_details?.enable_min_quantity){
			let minQty: any = this.custFieldsObj[ctrl]?.quantity_details?.min_quantity;
			validaterArr.push(this.customValidators.minValueValidate(minQty));
		}
		return validaterArr;
	}
	/**
	 * Adds validation to a text input control based on the provided parameters
	 * @param {string} ctrl - The input control name on which to add the validation
	 * @param {any} control - The control to add the validation
	 */
	private addValidationOnText(ctrl: string, control: any): void {
		if(this.custFieldsObj[ctrl].is_required){
			control.setValidators([Validators.required, Validators.pattern(TEXT_REG_EXP), this.customValidators.shortTextValidate]);
		}else{
			control.setValidators([Validators.pattern(TEXT_REG_EXP), this.customValidators.shortTextValidate]);
		}
	}
	/**
	 * Adds validation on a textarea control based on the parameters passed
	 * @param {string} ctrl - The textarea control name on which to add the validation
	 * @param {any} control - The control to add the validation
	 */
	private addValidationOnTextarea(ctrl: string, control: any): void {
		if(this.custFieldsObj[ctrl].is_required){
			control.setValidators([Validators.required, Validators.pattern(TEXT_REG_EXP), this.customValidators.longTextValidate]);
		}else{
			control.setValidators([Validators.pattern(TEXT_REG_EXP), this.customValidators.longTextValidate]);
		}
	}
	/**
	 * Handling the change event
	 * @param {any} event - The change event object.
	 * @param {string} ctrl - The control name of checkbox group
	 * @param {any} value - The checkbox value to be handled
	 */
	public custCheckBoxChange(event: any, ctrl: string, value: any, field: any): void {
		let val = this.rmSpclCharAndSpacePipe.transform(value);
		if(this.cfCheckboxGroup.contains(ctrl)){
			if(event.target.checked){
				// If checkbox is checked and value is not present in the control then add the value into control
				if(!(this.cfCheckboxGroup.value[ctrl]).includes(val)){
					(<FormArray>this.cfCheckboxGroup.controls[ctrl]).push(new FormControl(val));
				}
			}else{
				// If checkbox is unchecked and value is present in the control then remove the value from control
				if((this.cfCheckboxGroup.value[ctrl]).includes(val)){
					let index: any = (this.cfCheckboxGroup.value[ctrl]).indexOf(val);
					if(index > -1) {
						(<FormArray>this.cfCheckboxGroup.controls[ctrl]).removeAt(index);
					}
				}
			}
			if(ctrl && val){
				this.changeValueForPriceable(ctrl, this.cfCheckboxGroup.controls[ctrl].value, field);
			}
		}
	}
	/**
	 * Resets the value and validation of child controls when the value of a hierarchical control is changed
	 * @param {any} ctrl - The hierarchical control whose value is changed
	 * @param {array} resetCtrlsArr - The array of child controls which have to be reset.
	 * @returns {array} - The array of child controls which have to be reset.
	 */
	public resetHierarchicalValOnChange(ctrl: any, resetCtrlsArr: Array<any> = []): Array<any> {
		// Check if the control has any child controls and if so, loop through them
		if(this.custFieldChilds && this.utilServ.objHasProp(this.custFieldChilds, ctrl) && this.utilServ.checkArrLength(this.custFieldChilds[ctrl])){
			for(let child of this.custFieldChilds[ctrl]){
				if(this.customFieldsGroup.contains(child)){
					if(typeof this.customFieldsGroup.controls[child].value == 'object'){
						this.resetHierarchicalCheckboxVal(child);
					}else{
						// Remove the required validation and reset the value of the child control
						this.addRemoveRequiredValidation(false, this.customFieldsGroup, child);
						this.customFieldsGroup.controls[child].setValue('');
					}
					if(!resetCtrlsArr.includes(child)){
						resetCtrlsArr.push(child);
					}
				}
				// Recursively call the function to handle any nested child controls
				resetCtrlsArr = this.resetHierarchicalValOnChange(child, resetCtrlsArr);
			}
		}
		return resetCtrlsArr;
	}
	/**
	* Resets the value of a hierarchical checkbox and removes any required validations
	* @param {any} child - the child checkbox group to reset
	*/
	private resetHierarchicalCheckboxVal(child: any): void {
		// Remove required validation
		this.addRemoveRequiredValidation(false, this.cfCheckboxGroup, child);
		//Reset all options in the selected checkbox group
		if(this.customFieldsGroup.controls[child].value && this.utilServ.checkArrLength(Object.keys(this.customFieldsGroup.controls[child].value))){
			for(let opt in this.customFieldsGroup.controls[child].value){
				//Set the value to false
				(<FormGroup>this.customFieldsGroup.controls[child]).controls[opt].setValue(false);
			}
		}
	}
	/*
	This function resets the value of custom fields when a user changes the step in a multi-step form.
	It takes in a number ' represents the current step in the form.
	*/
	/**
	 * Resets the value of custom fields when a user changes the step in a multi-step form.
	 * @param {number} step - Current step in the form.
	 */
	public resetValOnMultiHeadChange(step: number): void {
		for(let i = step+1; i <= 3; i++){
			this.addRemoveStepWiseValOnCustFields(false, this.getStep(i));
		}
	}
	/**
	 * Returns the string representation of the step number passed as an argument.
	 * @param {any} step - A number representing the current step in the form.
	 * @returns {string} - A string representing the current step in the form.
	 */
	public getStep(step: any): string {
		switch (step) {
			case 0:
				return 'step_one';
			case 1:
				return 'step_two';
			case 2:
				return 'step_three';
			case 3:
				return 'step_four';
			default:
				break;
		}
		return '';
	}
	/**
	 * Sets the minimum and maximum dates for a given date field.
	 * @param {string} fieldName - The name of the date field to set the minimum and maximum dates for.
	 */
	private setMinMaxDate(fieldName: string): void {
		if(this.utilServ.objHasProp(this.custFieldsObj, fieldName)){
			if(this.custFieldsObj[fieldName].field_type == 'Datepicker'){
				this.custFieldsObj[fieldName]['disableFuture'] = false;
				this.custFieldsObj[fieldName]['disablePast'] = false;
				switch (this.custFieldsObj[fieldName].dates_type) {
					case 'past':
						this.custFieldsObj[fieldName]['disableFuture'] = true;
						break;
					case 'future':
						this.custFieldsObj[fieldName]['disablePast'] = true;
						break;
					default:
						break;
				}
			}
		}
	}
	/**
	 * Updates the sequence for a recurring schedule form based on the form metadata and the form sections.
	 * @param {any} formMetaData - An object containing metadata for the form.
	 * @param {any} oneStepFormSections - An array containing the form sections.
	 * @returns {any} - An array containing the updated sequence for the recurring schedule form.
	 */
	updateSeqForReschedule(formMetaData: any, oneStepFormSections: any): any {
		// Filter out the sections that do not include custom fields.
		let seqWithoutCustFields: any = oneStepFormSections.filter((sec: any)=>{
			return !sec.includes('custom_fields_');
		})
		let arr: any = ['multi_stp_form_stp_one_sec', 'multi_stp_form_stp_two_sec', 'multi_stp_form_stp_four_lft_sec'];
		for(let i = 0; i < arr.length; i++){
			if(this.utilServ.objHasProp(formMetaData, arr[i])){
				let currStep: Array<any> = formMetaData[arr[i]];
				let prevStep: Array<any> = (i > 0) ? formMetaData[arr[i-1]] : [];
				if(this.utilServ.checkArrLength(currStep)){
					for(let i = 0; i < currStep.length; i++){
						// eslint-disable-next-line max-depth
						if(currStep[i].includes('custom_fields_')){
							let addOnIndex: any = this.getAddOnIndex(i, currStep, seqWithoutCustFields, prevStep);
							seqWithoutCustFields.splice(addOnIndex, 0, currStep[i]);
						}
					}
				}
			}
		}
		return seqWithoutCustFields;
	}
	/**
	 * Returns the index of the next add-on in the main array based on the previous step.
	 * @param {any} i - the current step index
	 * @param {array} stepArray - the array of steps
	 * @param {array} mainArray - the main array of add-ons
	 * @param {array} prevStep - the previous step array
	 * @returns {any} - The index of the next add-on in the main array
	 */
	private getAddOnIndex(i: any, stepArray: Array<any>, mainArray: Array<any>, prevStep: Array<any>): any {
		if(!i && !this.utilServ.checkArrLength(prevStep)){ return i; }
		let prevSecName: string = (!i) ? prevStep[prevStep.length - 1] : stepArray[i-1];
		let secIndex: any = mainArray.indexOf(prevSecName);
		if(secIndex > -1){
			return secIndex+1;
		}
		return 0;
	}
	/**
	 * Adds or removes validation for minimum quantity based on the checkbox status.
	 * @param {any} ctrl - The control for which the needs to be added or removed.
	 * @param {boolean} isChecked - The status of the checkbox.
	 */
	public addRemoveMinValueValidationFromQuantityBased(ctrl: any, isChecked: boolean): void {
		let control: any = <FormGroup>this.customFieldsGroup.controls[ctrl];
		control.clearValidators();
		let validaterArr: any = [];
		if(this.custFieldsObj?.[ctrl].is_required){
			validaterArr.push(Validators.required);
		}
		if(isChecked && this.custFieldsObj?.[ctrl]?.quantity_details?.enable_min_quantity){
			let minQty: any = this.custFieldsObj?.[ctrl]?.quantity_details?.min_quantity;
			validaterArr.push(this.customValidators.minValueValidate(minQty));
		}
		control.setValidators(validaterArr);
		control.updateValueAndValidity();
		control.markAsTouched();
	}
	/**
	 * Extracts the Youtube video ID from a Youtube video URL.
	 * @param {string} url - The Youtube video URL.
	 * @returns {string} - The Youtube video ID.
	 */
	public getYoutubeVideoId(url: string): string {
		// Regular expression to match a Youtube video URL and extract the video ID.
		let VID_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
		let videoId: any = url.match(VID_REGEX);
		// If a video ID is found, return it. Otherwise, return an empty string.
		return (videoId && videoId[1]) ? videoId[1] : '';
	}
	/**
	 * Extracts the Vimeo video ID from a Vimeo video URL.
	 * @param {string} url - The Vimeo video URL.
	 * @returns {string} - The Vimeo video ID.
	 */
	public getVimeoVideoId(url: string): string {
		// Regular expression to match a Vimeo video URL and extract the video ID.
		let VID_REGEX = /(?:http:|https:|)\/\/(?:player.|www.)?vimeo\.com\/(?:video\/|embed\/|watch\?\S*v=|v\/)?(\d*)/;
		let videoId: any = url.match(VID_REGEX);
		// If a video ID is found, return it. Otherwise, return an empty string.
		return (videoId && videoId[1]) ? videoId[1] : '';
	}
	/**
	 * Sets the aspect ratio of a video field in the videoFieldsAspectRatio object.
	 * @param {string} fieldName - The name of the video field.
	 * @param {any} field - The video object containing the original width and height.
	 */
	private setVideoFieldAspectRatio(fieldName: string, field: any): void {
		this.videoFieldsAspectRatio[fieldName] = this.calAspectRatio(field.media_width, field.media_height);
	}
	/**
	 * Calculates the aspect ratio of the preview element by getting the height and width of the element and finding the greatest common denominator of the two.
	 * The aspect ratio is then calculated by dividing the width and height by the greatest common denominator.
	 * The result is stored in the 'aspectRatio' property.
	 */
	private calAspectRatio(width: number, height: number): string {
		let gcd: any = (...arr: any) => {
			let _gcd: any = (x: any, y: any) => (!y ? x : gcd(y, x % y));
			return [...arr].reduce((a, b) => _gcd(a, b));
		};
		let gcdResult: any = gcd(width, height);
		return `${width / gcdResult}:${height / gcdResult}`;
	}
	/**
	 * Filters an array of custom fields and returns only the objects where field_type is 'video'.
	 * @param {Array<any>} customFields - An array of custom fields.
	 * @returns {Array<any>} - An array of custom fields where field_type is 'video'.
	 */
	public filterByFieldType(customFields: Array<any>): Array<any> {
		// Use the filter method to get only the objects where field_type is 'video'
		return customFields.filter(field => field.field_type === 'Video');
	}
	/**
	 * Calculates the height of a video based on its aspect ratio and the desired width.
	 * If the video's original width is greater than the desired width, the aspect ratio is retrieved from a pre-calculated object.
	 * @param {any} field - The video object containing the original width and height.
	 * @param {number} width - The desired width of the video.
	 * @returns {number} - The calculated height of the video.
	 */
	public getVideoHeight(field: any, width: number): number {
		if(field.media_width > width){
			let fieldName: string = field.custom_field_section_id+'_'+field._id;
			return this.calculateHeightBasedOnAR(width, this.videoFieldsAspectRatio[fieldName]);
		}
		return field.media_height;
	}
	/**
	 * Calculates the height of a media element based on its aspect ratio.
	 * If the `constrainAspectRatio` property is set to true, the height is calculated based on the aspect ratio defined in the `aspectRatio` property.
	 * The height is then set in the `media_height` form control of the `customFieldForm`.
	 */
	private calculateHeightBasedOnAR(width: number, aspectRatio: string): number {
		let [w, h]: Array<any> = aspectRatio.split(':').map(Number);
		let height = (width * h) / w;
		return Math.round(height);
	}
}