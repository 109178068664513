<div class="tjs-popup tjs-popup--sm position-relative" [id]="secId">
	<!-- Header -->
	<bk-popup-header *ngIf="section?.title" [heading]="section?.title?.content" [headingId]="section?.title?.id" [isClose]="false"></bk-popup-header>
	<!-- Form -->
	<form class="d-flex flex-column" [formGroup]="addressForm" bkDebounceEvent [isSubmit]="true" (debounceSubmit)="submitForm()" [id]="section?.form?.id">
		<div class="tjs-popup__content tjs-popup__body">
			<!-- Address -->
			<div class="mb-25" [id]="section?.form?.address_id">
				<label class="form-label bk-form-label d-block" [id]="section?.form?.address?.label_id" *ngIf="section?.form?.address?.label" [innerHTML]="section?.form?.address?.label | safeHtml"></label>
				<input [ngClass]="{'is-invalid': f['address'].touched && f['address'].errors}" class="form-control" type="text" [attr.placeholder]="section?.form?.address?.placeholder" formControlName="address" (focusout)="getAddress($event)" (addressChange)="setAddress($event, 'zipcode')" (addressChange)="setAddress($event, 'city')" (addressChange)="setAddress($event,'state')" (focusin)="isZipcode=false" bk-google-places-autocomplete />
				<!-- Error -->
				<div class="tjs-error" *ngIf="f['address']?.touched && f['address']?.errors?.required">
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="f['address']?.errors?.required"> {{initServ.appStr?.errorMsg?.empty | translate}} </ng-container>
				</div>
				<input id="city" type="text" class="d-none" formControlName="city">
				<input id="state" type="text" class="d-none" formControlName="state">
			</div>
			<!-- Zipcode -->
			<div class="mb-25" *ngIf="isZipcode" [id]="section?.form?.zip_code_id">
				<label class="form-label bk-form-label d-block" [id]="section?.form?.zip_code?.label_id" *ngIf="section?.form?.zip_code?.label" [innerHTML]="section?.form?.zip_code?.label | safeHtml"></label>
				<input id="zipcode" [ngClass]="{'is-invalid': f['zipcode'].touched && f['zipcode'].errors}" type="text" class="form-control" formControlName="zipcode" [attr.placeholder]="section?.form?.zip_code?.placeholder">
				<!-- Error -->
				<div class="tjs-error" *ngIf="f['zipcode']?.touched && (f['zipcode']?.errors?.required || f['zipcode']?.errors?.pattern)">
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="f['zipcode']?.errors?.required"> {{initServ.appStr?.errorMsg?.empty | translate}} </ng-container>
					<ng-container *ngIf="f['zipcode']?.errors?.pattern"> {{initServ.appStr?.errorMsg?.specialChars | translate}} </ng-container>
				</div>
			</div>
			<!-- APT -->
			<div class="" [ngClass]="(editableAddr && editableAddr.default) ? 'mb-0' :'mb-25'" [id]="section?.form?.apt_no_id">
				<label class="form-label bk-form-label d-block"  [id]="section?.form?.apt_no?.label_id" *ngIf="section?.form?.apt_no?.label" [innerHTML]="section?.form?.apt_no?.label | safeHtml"></label>
				<input [ngClass]="{'is-invalid': f['apt'].touched && f['apt'].errors}" class="form-control" type="text" [placeholder]="section?.form?.apt_no?.placeholder" formControlName="apt">
				<div class="tjs-error" *ngIf="f['apt']?.touched && f['apt']?.errors?.pattern">
					<i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.specialChars | translate}}
				</div>
			</div>
			<!-- Make default -->
			<div class="form-check" *ngIf="(!editableAddr || (editableAddr && !editableAddr.default))" [id]="section?.form?.default_label_id">
				<input type="checkbox" class="form-check-input" id="default-tag" formControlName="default">
				<label class="form-check-label d-block" for="default-tag" [id]="section?.form?.default_label_id">{{section?.form?.default_label}}</label>
			</div>
		</div>
		<!-- Submit button -->
		<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse bk-footColor" >
			<bk-navigate [secId]="section?.save_btn?.id" [designId]="section?.save_btn?.design_id" [innerHTML]="(editableAddr && editableAddr?.address) ? section?.update_btn?.content : section?.save_btn?.content" [isSubmit]="true" essentialClass="w-100" customClass="btn btn-primary rounded-5 w-100" class="d-block w-100 mb-15 mb-sm-5"></bk-navigate>
			<bk-navigate [secId]="section?.cancel_btn?.id" [designId]="section?.cancel_btn?.design_id" [innerHTML]="section?.cancel_btn?.content" [isSubmit]="false" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" class="d-block w-100 mb-5" (callback)="resetForm()"></bk-navigate>
		</div>
	</form>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
