<div class="tjs-popup__content dir-rtl tjs-popup__content--height bg-transparent w-100" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<!-- Title -->
	<div class="px-10 text-center" *ngIf="section?.title?.content">
		<h4 [id]="section?.title?.id" class="mb-40 fw-normal" [innerHTML]="section?.title?.content | safeHtml"></h4>
	</div>
	<!-- List -->
	<ul class="tjs-list list-unstyled" *ngIf="section?.list && section?.list?.items && (section?.list?.items).length > 0">
		<li class="card shadow-sm tjs-hover-shadow mb-20 tjs-mb-last-0" *ngFor="let item of section?.list?.items;trackBy: secServ.trackByFnIndex">
			<bk-navigate customClass="p-20 text-decoration-none d-block text-body btn-transparent btn btn-link w-100 lh-base" [isPopup]="true" [dialogRef]="dialogRef" [secSett]="item?.link" [innerHTML]="getHTML(item)">
			</bk-navigate>
		</li>
	</ul>
</div>