<div class="tjs-blog pt-40">
	<div class="tjs-blog__detail pb-30" *ngIf="blog">
		<div class="container">
			<div class="mb-50">
				<!-- Title -->
				<div class="mb-15" *ngIf="blog?.title">
					<h1 class="display-6 fw-bold">{{blog?.title}}</h1>
				</div>
				<div>
					<div class="tjs-blog__meta mb-15">
						<ul class="list-unstyled  d-inline-flex flex-wrap mb-0">
							<!-- Date -->
							<li class="me-15 mb-5" *ngIf="blog?.date_time && !settings?.hide_date">
								<div class="d-flex align-items-center fs-14">
									<i class="tjsicon-calendar-1 pe-10"></i>
									<bk-date-display class="d-inline-block" [date]="blog?.date_time" [isLocal]="true"></bk-date-display>
								</div>
							</li>
							<!-- Name -->
							<li class="me-15 mb-5" *ngIf="blog?.author_data?.name">
								<div class="d-flex align-items-center fs-14">
									<i class="tjsicon-user-2 pe-10"></i>
									<a class="text-capitalize text-body" [routerLink]="['/author',pageSlug, blog?.author_data?.slug]">{{blog?.author_data?.name}}</a>
								</div>
							</li>
						</ul>
					</div>
					<!-- Categories -->
					<div class="tjs-blog__meta mb-15" *ngIf="categories && categories.length > 0">
						<div class="d-flex align-items-center flex-wrap">
							<span class="me-15">{{initServ?.appStr?.text?.category | translate}} :</span>
							<div class="d-flex flex-wrap cat-list-blog">
								<a *ngFor="let cat of categories;let isLast=last; trackBy: secServ.trackByFnIndex" class="text-capitalize fs-14 text-body" [routerLink]="['/category', pageSlug, cat?.slug]"> {{cat?.category_name}} {{isLast ? '' : ', '}} </a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12 col-md-12" [ngClass]="pageSlug ? 'col-lg-8' : 'col-lg-12'">
					<div>
						<!-- Media -->
						<div class="tjs-blog__featured-image mb-45" *ngIf="blog?.image_url">
							<img loading="auto" class="img-fluid" alt="blog" [src]="utilServ.getImgUrl(blog?.image_url)" width="1845" height="971">
						</div>
						<!-- Description -->
						<div class="mb-50">
							<div *ngIf="blog?.description">
								<p [innerHTML]="blog?.description | safeHtml"></p>
							</div>
						</div>
						<div class="tjs-author mb-50">
							<div class="mb-20">
								<h3 class="fw-bold" translate>{{initServ?.appStr?.text?.author}}</h3>
							</div>
							<div class="border-top border-bottom py-20">
								<div class="row">
									<!-- Avatar -->
									<div class="col-12 col-sm-2 col-md-2 col-lg-2 mb-20 mb-sm-0">
										<bk-user-img customClass="img-fluid-disabled" [imgSrc]="blog?.author_data?.image_url" alt="user image" width="100" height="100"></bk-user-img>
									</div>
									<div class="col-12 col-sm-10 col-md-10 col-lg-10 align-self-center">
										<!-- Name -->
										<h6 class="display-9 fw-bold mb-5 text-capitalize" *ngIf="blog?.author_data?.name">{{blog?.author_data?.name}}</h6>
										<!-- Description -->
										<div class="tjs-author__designation" *ngIf="blog?.author_data?.description">
											<p class="mb-0" [innerHTML]="blog?.author_data?.description | safeHtml"></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- Comments -->
					<bk-comments *ngIf="settings && showComments && blog?.allow_comments && settings?.allow_comments" [blogId]="blog?.id" [settings]="settings"></bk-comments>
				</div>
				<div class="col-12 col-md-12 col-lg-4 mt-40 mt-lg-0" *ngIf="section && (section?.subscribe || section?.description || section?.social_list || section?.recent_posts)">
					<!-- Side bar -->
					<bk-blog-sidebar [settings]="settings" [slug]="slug" [section]="section" [blogs]="blogs" [pageSett]="pageSett" ></bk-blog-sidebar>
				</div>
			</div>
		</div>
	</div>
	<!-- Popuplar blogs -->
	<ng-container *ngIf="popularBlogIds && popularBlogIds.length > 0">
		<ng-container *ngFor="let id of popularBlogIds;trackBy: secServ.trackByFnIndex">
			<bk-blog-grid-section [secId]="id" [settings]="settings" [slug]="slug" custClass="bg-white"></bk-blog-grid-section>
		</ng-container>
	</ng-container>
</div>
