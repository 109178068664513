<div [ngClass]="isMultiStepForm ? 'mb-20' :'pt-30 pb-20 px-md-30 border-bottom bk-card-border'" [id]="section?.id" [formGroup]="frequencyForm" *ngIf="settings && section && frequencies && frequencies.length > 1">
	<!-- Title and description -->
	<bk-section-heading *ngIf="!isMultiStepForm" [section]="section" [pageSett]="pageSett"></bk-section-heading>
	<!-- Label -->
	<div class="mb-15" *ngIf="(section?.freq_label || (descStatus && section?.freq_desc))">
		<label *ngIf="section?.freq_label" [id]="section?.freq_label_id" class="form-label bk-form-label mb-0" [innerHTML]="section?.freq_label | safeHtml"></label>
		<small  *ngIf="descStatus && section?.freq_desc" [id]="section?.freq_desc_id" class="d-block mt-10 bk-form-desc" [innerHTML]="section?.freq_desc | safeHtml"></small>
	</div>
	<!-- Frequencies -->
	<div class="tjs-check-btn d-flex flex-wrap" [ngClass]="{'tjs-btn-multi mx-n10' : isMultiStepForm}">
		<ng-container *ngFor="let freq of frequencies;trackBy: trackById">
			<div [ngClass]="{'input--checked': selectedFrequency == freq, 'px-10 text-center mb-15 flex-grow-1 flex-column': isMultiStepForm}">
				<div class="form-check form-check-inline" [ngClass]="isMultiStepForm ? 'm-0 d-block' : 'mb-15'">
					<input id="freq_{{freq?.form_frequency_data?.id}}" class="form-check-input bk-form-check-input" type="radio" name="frequency" [value]="freq" [(ngModel)]="selectedFrequency" [ngModelOptions]="{standalone: true}" (change)="getFrequency(freq)" [attr.disabled]="isDisabled"/>
					<label for="freq_{{freq?.form_frequency_data?.id}}" class="form-check-label btn btn-light justify-content-center align-items-center d-flex bk-form-check-btn" [ngClass]="isMultiStepForm ? 'fs-17 fw-bold w-100' : 'fs-14 fw-normal'">
						<span class="flex-shrink-0">{{(utilServ.getFrequencyName(freq)) | translate}}</span>
						<span *ngIf="bookingType != 'add' && freq?.form_frequency_data.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
						<!-- Tooltip -->
						<bk-tooltip *ngIf="freq?.form_frequency_data && freq?.form_frequency_data?.show_tooltip_icon && freq?.form_frequency_data?.tooltip_text" customClass="bk-form-check-tooltip {{!isMultiStepForm && 'tjs-tooltip-icon--sm'}}" [content]="freq?.form_frequency_data?.tooltip_text | translate"></bk-tooltip>
					</label>
				</div>
				<div class="my-5 fs-14" *ngIf="isMultiStepForm && freq?.form_frequency_data?.frequency_discount">
					<ng-container *ngIf="freq?.form_frequency_data?.frequency_discount_type == 'amount'">
						<span class="d-inline-block">
							<bk-amount-display [amount]="freq?.form_frequency_data?.frequency_discount"></bk-amount-display>
							{{'off' | translate}}
						</span>
					</ng-container>
					<ng-container *ngIf="freq?.form_frequency_data?.frequency_discount_type == 'percentage'">
						{{freq?.form_frequency_data?.frequency_discount}}<span>%</span> {{'off' | translate}}
					</ng-container>
				</div>
			</div>
		</ng-container>
	</div>
</div>
