<div class="position-relative" [formGroup]="customerForm">
	<div [ngClass]="{'py-30 px-md-30 border-bottom bk-card-border': !isMultiStepForm}" [id]="section?.id" formGroupName="customer" *ngIf="settings && section">
		<!-- Title and description -->
		<bk-section-heading *ngIf="!isMultiStepForm" [section]="section" [pageSett]="pageSett"></bk-section-heading>
		<!-- Email -->
		<div class="col-md-6 mb-20 px-0">
			<label class="form-label bk-form-label" *ngIf="section?.email" [id]="section?.email_id" [innerHTML]="section?.email | safeHtml"></label>
			<ng-container *ngIf="customerGroup.controls['customer_type'].value == 'existing customer'; else newCust">
				<input class="form-control bk-input" [ngClass]="{'form-control-lg':isMultiStepForm}" placeholder="{{section?.email_placeholder}}" formControlName="email_id" readonly>
			</ng-container>
			<ng-template #newCust>
				<input class="form-control bk-input {{section?.email_errors?.existing_email_id}}" placeholder="{{section?.email_placeholder}}" formControlName="email_id" [value]="customerGroup.controls['email_id']?.value" (focusout)="emailExistControl(customerGroup.controls['email_id'])" [ngClass]="{'is-invalid': customerGroup.controls['email_id']?.touched && (customerGroup.controls['email_id']?.errors?.required || customerGroup.controls['email_id']?.errors?.pattern || customerGroup.controls['email_id']?.errors?.emailExists || emailExistVar), 'form-control-lg':isMultiStepForm}" (keyup)="addPwdControl()" [class.existing-email]="section?.email_errors && section?.email_errors?.existing_email && (customerGroup.controls['email_id']?.errors?.emailExists || emailExistVar)">
				<!-- Error -->
				<div class="tjs-error bk-error" *ngIf="(customerGroup.controls['email_id']?.touched && (customerGroup.controls['email_id']?.errors?.required || customerGroup.controls['email_id']?.errors?.pattern))">
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="customerGroup.controls['email_id']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
					<ng-container *ngIf="customerGroup.controls['email_id']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.validEmail | translate}} </ng-container>
				</div>
				<div [id]="section?.email_errors?.existing_email_id" class="tjs-error" *ngIf="section?.email_errors && section?.email_errors?.existing_email && (customerGroup.controls['email_id']?.errors?.emailExists || emailExistVar)">
					<i class="tjsicon-attention"></i>
					{{section?.email_errors?.existing_email}}
				</div>
			</ng-template>
		</div>
		<div class="row" *ngIf="customerGroup.controls['email_id']?.errors?.emailExists || emailExistVar || (customerGroup.controls['customer_type'].value == 'existing customer' && !currentUser)">
			<!-- Password -->
			<div id="password-section-id" class="col-md-6 mb-20">
				<div class="d-flex align-items-center mb-10">
					<div>
						<label class="mb-0 form-label bk-form-label" *ngIf="section?.password" [id]="section?.password_id" [innerHTML]="section?.password | safeHtml"></label>
					</div>
					<bk-tooltip *ngIf="section?.password_tooltip" customClass="bk-tooltip" [id]="section?.password_tooltip_id" [content]="section?.password_tooltip | translate"></bk-tooltip>
				</div>
				<input type="password" class="form-control bk-input" formControlName="password" placeholder="{{section?.password_placeholder}}" [ngClass]="{'is-invalid': customerGroup.controls['password']?.touched && customerGroup.controls['password']?.errors}">
				<!-- Error -->
				<div class="tjs-error bk-error" *ngIf="customerGroup.controls['password']?.touched && customerGroup.controls['password']?.errors">
					<i class="tjsicon-attention"></i>
					{{initServ?.appStr?.errorMsg?.empty | translate}}
				</div>
				<!-- Forgot password -->
				<div class="d-flex align-items-center justify-content-between mt-5 forgot-pass-link" *ngIf="!isMultiStepForm">
					<a href="javascript:void(0)" class="fs-14" [id]="section?.forgot_password_id" [innerHTML]="section?.forgot_password | safeHtml" (click)="forgotPwd()"></a>
				</div>
			</div>
			<!-- Login btn -->
			<div class="col-sm-6 col-md-6" *ngIf="formLayout != 'two_step'">
				<label class="mb-5">&nbsp;</label>
				<bk-navigate innerHTML="<i class='tjsicon-pin-code me-10'></i>{{section?.login_btn?.content}}" customClass="btn btn-success border-transparent" essentialClass="w-100 d-block m-5 text-capitalize bk-btn" (callback)="loginUser()" [secSett]="section?.login_btn" ></bk-navigate>
				<!-- Forgot password -->
				<div class="d-flex align-items-center justify-content-between" *ngIf="isMultiStepForm">
					<div class="mt-5">
						<a href="javascript:void(0)" class="fs-14" [id]="section?.forgot_password_id" [innerHTML]="section?.forgot_password | safeHtml" (click)="forgotPwd()"></a>
					</div>
				</div>
			</div>
		</div>
		<!-- Extra desc -->
		<small class="bk-form-desc" *ngIf="!isMultiStepForm && extraDescStatus && section?.extra_desc" [id]="section?.extra_desc_id" [innerHTML]="section?.extra_desc | safeHtml"></small>
		<!-- Cookie content -->
		<div class="mt-10 mb-20" *ngIf="cookieStatus && section?.cookie_content">
			<span [id]="section?.cookie_content_id" [innerHTML]="section?.cookie_content | safeHtml"></span>
		</div>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
