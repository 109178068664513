<ng-container *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div [id]="secId" class="tjs-py-140 tjs-bg-style position-relative" [ngStyle]="{'background-image' : (section?.media && section?.media?.urls && (section?.media?.urls).length > 0) && 'url('+ section?.media?.urls[0] + ')'}">
		<div class="tjs-opacity--bg" [id]="section?.media?.id" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0"></div>
		<div class="container">
			<div class="row mx-0">
				<div class="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-5 px-0">
					<div class="py-25 py-md-40 px-20 px-md-50 bg-white rounded-3 bk-card">
						<!-- Title -->
						<div class="tjs-section__title mb-35" *ngIf="section?.title?.content">
							<h5 [id]="section?.title?.id" class="h2 fw-bold bk-title" [innerHtml]="section?.title?.content | safeHtml"></h5>
						</div>
						<!-- Form -->
						<div class="bk-form mb-30">
							<label [id]="section?.form_label?.id" class="form-label fw-bold bk-form-label" *ngIf="section?.form_label?.content" [innerHTML]="section?.form_label?.content | safeHtml"></label>
							<!-- V1 -->
							<ng-container *ngIf="pageSett[secId]?.variation_id == 'bk_join_our_team_V1'; else v2">
								<select [id]="section?.list?.id" *ngIf="(section?.list && section?.list?.items && (section?.list?.items).length > 0) ;else noCity" class="form-select bk-select" [(ngModel)]="selectedCity" (ngModelChange)="setSelectedCity(selectedCity)" name="city">
									<ng-container *ngFor="let item of section?.list?.items;trackBy: secServ.trackByFnIndex">
										<option [id]="item?.id" class="bk-select-option" *ngIf="item?.title" [ngValue]="item?.link" [innerHTML]="item?.title | safeHtml"></option>
									</ng-container>
								</select>
								<ng-template #noCity>
									<div class="bk-error" translate>{{initServ?.appStr?.text?.citiesNotFound}}</div>
								</ng-template>
							</ng-container>
							<!-- V2 -->
							<ng-template #v2>
								<select *ngIf="jobs && jobs.length>0; else noJob" class="form-select bk-select" [(ngModel)]="selectedJob" name="job" >
									<option class="bk-select-option" *ngFor="let job of jobs;trackBy: secServ.trackByFnIndex" value="{{job?.slug}}">{{job.title}}</option>
								</select>
								<ng-template #noJob>
									<div class="bk-error" translate>{{initServ?.appStr?.text?.availablePositionsNotFound}}</div>
								</ng-template>
							</ng-template>
						</div>
						<!-- Button -->
						<div class="bk-btn-group" *ngIf="section?.form_btn && section?.form_btn?.content">
							<ng-container *ngIf="pageSett[secId].variation_id == 'bk_join_our_team_V2'; else gotoNext;">
								<ng-container *ngIf="jobs && jobs.length>0">
									<bk-navigate [secSett]="section?.form_btn" [innerHTML]="section?.form_btn?.content" (callback)="nextPage()" customClass="btn btn-primary border-transparent" essentialClass="bk-form-btn"></bk-navigate>
								</ng-container>
							</ng-container>
							<ng-template #gotoNext>
								<ng-container *ngIf="selectedLinkData">
									<bk-navigate [secSett]="selectedLinkData" [designId]="section?.form_btn?.design_id" [secId]="section?.form_btn?.id" [innerHTML]="section?.form_btn?.content" customClass="btn btn-primary border-transparent" essentialClass="bk-form-btn"></bk-navigate>
								</ng-container>
							</ng-template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>