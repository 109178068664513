import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
// Services
import { NgOnDestroy, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-pagination',
	template: `<ul [attr.id]="secId" class="pagination tjs-list list-unstyled {{customClass}}">
		<li class="page-item" [class.disabled]="isFirstPage()">
			<a class="page-link d-inline-flex h-100 align-items-center" role="button" (click)="previous()">
				<span><i class="tjsicon-left-arrow"></i></span>
			</a>
		</li>
		<ng-container *ngIf="pages && pages.length > 0">
			<ng-container *ngFor="let item of pages;trackBy: utilServ.trackByFnIndex">
				<li class="page-item" [ngClass]="{'active': page == item.value}">
					<a class="page-link d-inline-flex h-100 align-items-center" role="button" (click)="setCurrent(item.value)">{{ item?.label }}</a>
				</li>
			</ng-container>
		</ng-container>
		<li class="page-item" [class.disabled]="isLastPage()">
			<a class="page-link d-inline-flex h-100 align-items-center" role="button" (click)="next()">
			<span><i class="tjsicon-right-arrow"></i></span>
			</a>
		</li>
	</ul>`,
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class PaginationComponent implements OnInit {

	@Input() total: number = 0;
	@Input() limit: number = 0;
	@Input() page: number = 1;
	@Input() customClass: string = '';
	@Input() secId:any;
	@Output() callback: EventEmitter<number> = new EventEmitter<number>();
	maxSize: number = 7;
	pages: any = [];

	constructor(public utilServ: UtilServ) { }

	ngOnInit(): void { }

	/**
	* Change the input value and its previous value using ngOnChanges.
	*/
	ngOnChanges(): void {
		//Create a page array using createPageArray function.
		if (this.total && this.limit) {
			this.pages = this.createPageArray(this.page, this.limit, this.total, this.maxSize);
		}
	}

	/**
	* Returns an array of Page objects to use in the pagination.
	* @param page: number, limit: number, total: number, maxSize: number.
	*/
	public createPageArray(page: number, limit: number, total: number, maxSize: number) {
		let pages = [];
		let totalPages = Math.ceil(total / limit);
		let maxSizeHalf = Math.ceil(maxSize / 2);

		let isStart = page <= maxSizeHalf;
		let isEnd = totalPages - maxSizeHalf < page;
		let isMiddle = !isStart && !isEnd;

		let dots = maxSize < totalPages;
		let i = 1;
		while (i <= totalPages && i <= maxSize) {
			let label;
			let pageNumber = this.calculatePageNumber(i, page, maxSize, totalPages);
			let opening = (i === 2 && (isMiddle || isEnd));
			let closing = (i === maxSize - 1 && (isMiddle || isStart));
			if (dots && (opening || closing)) {
				label = '...';
			} else {
				label = pageNumber;
			}
			pages.push({
				label: label,
				value: pageNumber
			});
			i++;
		}
		return pages;
	}

	/**
	* Given the position in the pagination links [i],
	* @param i: number, page: number, maxSize: number, totalPages: number.
	* Figure out what page number corresponds to that position.
	*/
	public calculatePageNumber(i: number, page: number, maxSize: number, totalPages: number) {
		let maxSizeHalf = Math.ceil(maxSize / 2);
		if (i === maxSize) {
			return totalPages;
		} else if (i === 1) {
			return i;
		} else if (maxSize < totalPages) {
			if (totalPages - maxSizeHalf < page) {
				return totalPages - maxSize + i;
			} else if (maxSizeHalf < page) {
				return page - maxSizeHalf + i;
			} else {
				return i;
			}
		} else {
			return i;
		}
	}
	/**
	* Go to the previous page
	*/
	public previous() {
		this.setCurrent(this.page - 1);
	}

	/**
	* Go to the next page
	*/
	public next() {
		this.setCurrent(this.page + 1);
	}
	/**
	* Returns true if current page is first page.
	*/
	public isFirstPage(): boolean {
		return this.page === 1;
	}
	/**
	* Returns true if current page is last page.
	*/
	public isLastPage(): boolean {
		if (this.total < 1) {
			return 1 === this.page;
		} else {
			let getLastPage = Math.ceil(this.total / this.limit);
			return getLastPage === this.page;
		}
	}
	/**
	* Set the current page number.
	*/
	public setCurrent(page: number) {
		let totalPages = Math.ceil(this.total / this.limit);
		if (page <= totalPages) {
			this.callback.emit(page);
		}
	}
}
