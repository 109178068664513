import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// External lib
import { conformToMask } from 'angular2-text-mask';
// services
import { NgOnDestroy, InitServ } from '../../../Services';

@Component({
	selector: 'bk-phone-number',
	template: `<a [attr.id]="id" [class]="customClass" href="tel:{{initServ?.callingCode}}{{phone}}">
		<span *ngIf="!hideIcon" class="tjsicon-call me-5"></span>
		{{initServ?.callingCode}} {{maskedPhoneNo?.conformedValue}}
	</a>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class PhoneNumberComponent implements OnInit {
	// Variables
	@Input() phone?: string | number = '';
	@Input() hideIcon: boolean = false;
	@Input() customClass: string = '';
	@Input() id:any = null;
	maskedPhoneNo: any;

	constructor(public initServ: InitServ) { }

	ngOnInit() {
		this.maskedPhoneNo = conformToMask(this.phone, this.initServ.selectedMask, {guide: false} );
	}
}
