import { Component, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../Services';
// Inheritance component
import { BkngSummaryComponent } from '../../../Global/BookingForm/Sidebar';

@Component({
	selector: 'bk-multi-step-form-header',
	templateUrl: './MultiStepFormHeader.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class MultiStepFormHeaderComponent extends BkngSummaryComponent{
}
