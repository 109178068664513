import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// External library
import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from 'angular2-text-mask';
// Modules
import { AddBookingsRoutingModule } from './AddBookings-routing.module';
import { GlobalDefaultModule, BookingFormModule } from '../Global';
// Components
import { BookingFormComponent, AddBookingFormComponent, SingleFormComponent,CommonAddFormComponent, MultiStepFormComponent, TwoStepFormComponent, MultiStepFormHeaderComponent, BookingAddedSuccessfullyMsgComponent } from './index';
@NgModule({
	declarations: [
		BookingFormComponent,AddBookingFormComponent, SingleFormComponent, CommonAddFormComponent, MultiStepFormComponent, TwoStepFormComponent, MultiStepFormHeaderComponent, BookingAddedSuccessfullyMsgComponent
	],
	imports: [
		CommonModule,TranslateModule,MatDialogModule,FormsModule, ReactiveFormsModule, TextMaskModule,
		AddBookingsRoutingModule, GlobalDefaultModule, BookingFormModule
	],
	exports: [ AddBookingFormComponent, CommonAddFormComponent, BookingAddedSuccessfullyMsgComponent, BookingFormComponent ],
	entryComponents: [BookingAddedSuccessfullyMsgComponent]
})
export class AddBookingsModule { }
