import { Component, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../Services';
// Inheritance components
import { ItemsWithAddonsComponent } from '../../../Global/BookingForm';

@Component({
	selector: 'bk-short-form-items-with-addons',
	templateUrl: './ShortFrmItemsWithAddons.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ShortFrmItemsWithAddonsComponent extends ItemsWithAddonsComponent{
	@Input() variationId: string = '';
	@Input() parentSection: any;
	@Input() sectionIds: any;

	@Output() serviceChange: EventEmitter<any> = new EventEmitter();
	@Output() hourlyServiceChange: EventEmitter<any> = new EventEmitter();
	@Output() frequencyChange: EventEmitter<any> = new EventEmitter();

	services: any;
	windowWidth :any = window.innerWidth;

}