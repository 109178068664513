<div [id]="secId" class="tjs-popup tjs-popup--sm rounded-5 position-relative">
	<!-- Header -->
	<bk-popup-header [headingId]="section?.title?.id" [heading]="section?.title?.content"></bk-popup-header>
	<form [formGroup]="tipForm">
		<!-- Content -->
		<div class="tjs-popup__content tjs-popup__body" [id]="section?.form?.id">
			<div class="mb-35">
				<label class="form-label bk-form-label d-block" *ngIf="section?.form?.tip?.label" [innerHTML]="section?.form?.tip?.label | safeHtml"></label>
				<div class="input-group">
					<span class="input-group-text" bk-currency></span>
					<input [ngClass]="{'is-invalid': f['amount'].touched && f['amount'].errors}" class="form-control" type="text" [attr.placeholder]="section?.form?.tip?.placeholder" formControlName="amount" (focusout)="customValidators.setControlDecimalNo(f['amount'])">
				</div>
				<!-- Error -->
				<div class="tjs-error" *ngIf="f['amount'].touched && f['amount']?.errors?.required">
					<i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.empty | translate}}
				</div>
				<div class="tjs-error" *ngIf="f['amount'].touched && f['amount']?.errors?.min">
					<i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.validAmount | translate}}
				</div>
			</div>
			<div class="position-relative" [ngClass]="{'min-height--100': !(cards && cards.length > 0) }">
				<!-- Existing payment cards -->
				<ng-container *ngIf="cards && cards.length > 0">
					<div class="mb-5">
						<div class="form-check form-check-inline mb-15">
							<input id="existing" type="radio" class="form-check-input" value="existing_credit_card" formControlName="method" (change)="cardTypeChange()">
							<label class="form-check-label bk-form-label" for="existing">{{ section?.form?.payment_info?.existing_card}}</label>
						</div>
						<div class="form-check form-check-inline mb-15">
							<input id="new" type="radio" class="form-check-input" value="new_credit_card" formControlName="method" (change)="cardTypeChange()">
							<label class="form-check-label bk-form-label" for="new">{{ section?.form?.payment_info?.new_card}}</label>
						</div>
					</div>

					<div *ngIf="f['method'].value == 'existing_credit_card'">
						<label class="form-label bk-form-label d-block" *ngIf="section?.form?.payment_info?.saved_card" [innerHTML]="section?.form?.payment_info?.saved_card | safeHtml"></label>
						<select class="form-select" formControlName="card_id" (change)="onCardChange($event)">
							<ng-container *ngFor="let card of cards;trackBy: utilServ.trackByFnIndex">
								<option *ngIf="card?.is_default; else notDefault" [value]="card?.id" selected>XXXX XXXX XXXX {{card?.last4}}</option>
								<ng-template #notDefault>
									<option [value]="card?.id">XXXX XXXX XXXX {{card?.last4}}</option>
								</ng-template>
							</ng-container>
						</select>
					</div>
				</ng-container>
				<!-- New card block -->
				<ng-container *ngIf="f['method'].value == 'new_credit_card'">
					<!-- [hideLabel]="initServ.paymentGateway == 'authorizedotnet' ? false : true", this condition is removed as from the theme-builder its the value is given and made the common structure for payment gateway, therefore to maintain the common Ui this condition is removed: this is made confirm from Harleen mam & Vikram sir -->
					<bk-payment-gateway *ngIf="buildPaymentForm" [locationId]="booking.base_location_id" [baseLoc]="true" [section]="section?.form?.payment_info" [isDynamic]="true" [billingAddr]="true" [billingAddrForm]="tipForm"></bk-payment-gateway>
				</ng-container>
				<!-- Loader -->
				<bk-app-loader [loaderId]="cardsLoaderId"></bk-app-loader>
			</div>
		</div>
		<!-- Footer -->
		<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive border-top-0 d-flex justify-content-end bk-footColor">
			<bk-navigate [isDebounce]="true" class="d-block mb-5" [secId]="section?.submit_btn?.id" [designId]="section?.submit_btn?.design_id" [innerHTML]="section?.submit_btn?.content" customClass="btn btn-primary rounded-5" [btnDisabled]="!isCardLoaded" (callback)="submitForm()"></bk-navigate>
		</div>
	</form>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
