import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// Services
import { NgOnDestroy, SectionServ, RenderComponentServ, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-short-form-popup',
	templateUrl: './ShortFormPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ShortFormPopupComponent implements OnInit {

	@Input() secId: string = '';
	@Input() popupId: any;
	popupData: any;
	pageSett: any;

	constructor(public secServ: SectionServ, public rcServ: RenderComponentServ, private utilServ: UtilServ, public dialogRef: MatDialogRef<ShortFormPopupComponent>) {
	}

	ngOnInit(): void {
		if(this.secId && this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.popupData = this.rcServ.popupData[this.popupId];
			this.pageSett = this.popupData.section_settings;
			this.utilServ.popupPosition(this.pageSett[this.secId].variation_id, this.popupData.settings, this.dialogRef);
		}
	}
}
