import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
if ('serviceWorker' in navigator) {
	// Unregister all service workers
	navigator.serviceWorker.getRegistrations().then((registrations) => {
		registrations.forEach((registration) => {
		registration.unregister();
		});
	});
}
if ('caches' in window) {
	// Clear all caches
	caches.keys().then((cacheNames) => {
		cacheNames.forEach((cacheName) => {
			caches.delete(cacheName);
		});
	});
}

platformBrowserDynamic().bootstrapModule(AppModule, { preserveWhitespaces: false }).catch(err => console.error(err));

// platformBrowserDynamic().bootstrapModule(AppModule, { preserveWhitespaces: false }).then(module => enableDebugTools(module.injector.get(ApplicationRef).components[0])).catch(err => console.error(err));
