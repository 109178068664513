<div class="row justify-content-between align-items-center my-10 both-total-active-sform2">
	<div class="col-sm-6 my-5" *ngIf="section?.total_before_tax">
		<div class="d-flex align-items-center justify-content-sm-start justify-content-center">
			<label class="form-label me-10 mb-0 text-wrap tjs-word-break" [id]="sectionIds?.sub_title"><span translate [innerHTML]="section?.total_before_tax | safeHtml"></span>:</label>
			<h6 class="mb-0 fw-bold text-nowrap fs-20 flex-shrink-0" [id]="sectionIds?.sub_title"><bk-amount-display [amount]="totalWithoutTax" [isZero]="true"></bk-amount-display></h6>
		</div>
	</div>
	<div class="col-sm-6 my-5" *ngIf="totalDisplayStatus">
		<div class="d-flex align-items-center justify-content-sm-start justify-content-center">
			<label class="form-label me-10 mb-0 text-wrap tjs-word-break" [id]="sectionIds?.sub_title"><span translate [innerHTML]="(section?.total) ? section?.total : totalLabelForShortForm | safeHtml"></span>:</label>
			<h6 class="mb-0 fw-bold text-nowrap fs-20 flex-shrink-0" [id]="sectionIds?.sub_title"><bk-amount-display [amount]="displayFinalAmount" [isZero]="true"></bk-amount-display></h6>
		</div>
	</div>
	<div class="col-sm-6 mt-20 mt-sm-0" *ngIf="selectedFrequency && selectedFrequency?.form_frequency_data.occurence_time && selectedFrequency?.form_frequency_data.occurence_time == 'recurring' && selectedFrequency?.form_frequency_data?.frequency_discount_on_bookings == 'exclude-first-booking'">
		<div class="d-flex align-items-center justify-content-center justify-md-content-start">
			<label class="form-label me-10 mb-0 text-wrap tjs-word-break" [id]="sectionIds?.sub_title"><span translate [innerHTML]="section['first_apt_total'] | safeHtml"></span>:</label>
			<h6 class="mb-0  fw-bold text-nowrap fs-20" [id]="sectionIds?.sub_title"><bk-amount-display [amount]="firstRecAptFinalAmount" [isZero]="true"></bk-amount-display></h6>
		</div>
	</div>
</div>
