import { Pipe, PipeTransform } from '@angular/core';
// External libraries
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
// Services
import { InitServ } from '../Services';

@Pipe({
	name: 'fmtDate',
	pure: true
})
export class FmtDatePipe implements PipeTransform {
	// Variables
	dateFormat: string = "MM/DD/YYYY";

	constructor(private initServ: InitServ){
		this.dateFormat = this.initServ.storeDateFormat;
	}
	/**
	 * transform the date in the format
	 * @param value value the date timestamp
	 * @param isLocal Local time zone(means your system time zone), so isLocal=true
	 * @returns A date string in the desired format.
	 */
	transform(value: number, isStr:boolean = false, isLocal: boolean = false, isDayName: boolean = false): any {
		if (value == null || value !== value) return null;
		try {
			if(isStr){
				value = dayjs(value).unix();
			}
			if(isDayName){
				this.dateFormat = `ddd ${this.dateFormat}`;
			}
			if(isLocal){
				return dayjs.unix(value).format(this.dateFormat);
			} else {
				dayjs.extend(utc);
				return dayjs.unix(value).utc().format(this.dateFormat);
			}
		} catch (error) {
			throw new Error(`InvalidPipeArgument`);
		}
	}
}
