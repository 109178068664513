import { Component, Input, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
// Services
import { BkngFormServ, InitServ, NgOnDestroy, SectionServ, UtilServ } from '../../../Services';
import { CustomValidators } from '../../../Global/GlobalDefault';
// Constant
import { BLANK_TINYMCE_CONFIG, IOS } from '../../../Constants'

@Component({
	selector: 'bk-key-info',
	templateUrl: './KeyInfo.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class KeyInfoComponent implements OnInit {
	// Variables
	@Input() bookingType: any;
	@Input() section: any;
	@Input() KeyInfoForm! : FormGroup;
	@Input() prefilledData: any;
	@Input() pageSett: any;
	@Input() isMultiStepForm: boolean = false;
	isDisabled: any;
	sectionStatus: any ={
		keyInfo: false,
		keyWithProv: false
	}
	tinymceConfig: any = JSON.parse(JSON.stringify(BLANK_TINYMCE_CONFIG));
	// eslint-disable-next-line max-params
	constructor(private secServ: SectionServ, private bkngFormServ: BkngFormServ, public initServ: InitServ, private cDRef: ChangeDetectorRef, private utilServ: UtilServ, public custValidators: CustomValidators) { }

	ngOnInit(): void {
		// Key info textarea validation
		this.updateValidation();
		this.isDisabled = this.bkngFormServ.disabledParamScope(this.bookingType, this.prefilledData, null, null);
		// Section status
		// if(this.prefilledData && this.prefilledData.key_info){
		// 	this.sectionStatus.keyInfo = true;
		// } else {
			if(this.section && this.secServ.checkEleStatus(this.pageSett,this.section.id)){
				this.sectionStatus.keyInfo = true;
			}
		// }
		// Key with provider status
		if(this.sectionStatus.keyInfo){
			// if(this.prefilledData && this.prefilledData.key_with_provider){
			// 	this.sectionStatus.keyWithProv = true;
			// } else {
				if(this.section && this.secServ.checkEleStatus(this.pageSett,this.section.key_with_prov_id)){
					this.sectionStatus.keyWithProv = true;
				}
			// }
		}
		this.setEditorData();
	}
	/**
	 * Update validation
	 */
	public updateValidation(): void {
		this.KeyInfoForm.controls['key_note'].markAsUntouched();
		this.KeyInfoForm.controls['key_note'].clearValidators();
		// TODO: In response to issues with existing bookings, we've temporarily removed the maximum length validation from text editors. This change allows bookings to be rescheduled or duplicated even if the text exceeds the previous length limit. However, we acknowledge that this is not an ideal long-term solution. Moving forward, we'll explore alternative methods to handle text validation while ensuring a seamless user experience. This might involve implementing a different validation approach or finding more effective ways to manage text content without strict length constraints.
		// let noteFieldValidationArr: Array<any> = [this.custValidators.extraLongTextValidate];
		let noteFieldValidationArr: Array<any> = [];
		if(this.KeyInfoForm.value['key_info'] == 'hidden'){
			noteFieldValidationArr.push(Validators.required);
		}
		this.KeyInfoForm.controls['key_note'].setValidators(noteFieldValidationArr);
		this.KeyInfoForm.controls['key_note'].updateValueAndValidity();
	}
	public setEditorData(): any {
		this.tinymceConfig['placeholder'] = this.section?.note_placeholder;
		this.tinymceConfig['directionality'] = (this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? "rtl" : 'ltr';
		this.tinymceConfig['setup'] = this.editorFunc.bind(this);
		this.cDRef.detectChanges();
	}
	/**
	 * Editor functiona
	 * @param editor
	 */
	private editorFunc(editor: any): void {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let self = this;
		// Called on focusin
		editor.on('focusin', function(){
			self.markUntouched();
		});
		// Called on focusout
		editor.on('blur', function(){
			if(!IOS){
				self.emptyCtrl();
			}
		});
		editor.on('focusout', function(){
			if(IOS){
				self.emptyCtrl();
			}
		});
	}
	private markUntouched(): void {
		this.KeyInfoForm.controls['key_note'].markAsUntouched();
		this.cDRef.detectChanges();
	}
	private emptyCtrl(): void {
		if(!this.utilServ.isNoteAvailable(this.KeyInfoForm.controls['key_note'].value)) {
			this.KeyInfoForm.controls['key_note'].setValue(null);
		}
		this.KeyInfoForm.controls['key_note'].markAsTouched();
		this.cDRef.detectChanges();
	}
}