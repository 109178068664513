<form [formGroup]="commentForm" class="row">
	<!-- First name -->
	<div class="col-12 col-md-6 mb-25">
		<input type="text" [class.is-invalid]="commentForm.controls['first_name'].hasError('required') && commentForm.controls['first_name']?.touched" class="form-control" [attr.placeholder]="initServ?.appStr?.text?.firstName | translate" formControlName="first_name">
		<!-- Error -->
		<ng-container *ngTemplateOutlet="errorTemp; context:{controlName: 'first_name', type: 'name'}"></ng-container>
	</div>
	<!-- Last name -->
	<div class="col-12 col-md-6 mb-25">
		<input [class.is-invalid]="commentForm.controls['last_name'].hasError('required') && commentForm.controls['last_name']?.touched" class="form-control" [attr.placeholder]="initServ?.appStr?.text?.lastName | translate" formControlName="last_name">
		<!-- Error -->
		<ng-container *ngTemplateOutlet="errorTemp; context:{controlName: 'last_name', type: 'name'}"></ng-container>
	</div>
	<!-- Email -->
	<div class="col-12 col-md-12 mb-25">
		<input [class.is-invalid]="commentForm.controls['email'].hasError('required') && commentForm.controls['email']?.touched" class="form-control" [attr.placeholder]="initServ?.appStr?.text?.email | translate" formControlName="email">
		<!-- Error -->
		<ng-container *ngTemplateOutlet="errorTemp; context:{controlName: 'email', type: 'email'}"></ng-container>
	</div>
	<!-- Comment -->
	<div class="col-12 col-md-12 mb-25">
		<textarea rows="3" [class.is-invalid]="commentForm.controls['comment'].hasError('required') && commentForm.controls['comment']?.touched" class="form-control" [attr.placeholder]="initServ?.appStr?.text?.comment | translate" formControlName="comment"></textarea>
		<!-- Error -->
		<ng-container *ngTemplateOutlet="errorTemp; context:{controlName: 'comment'}"></ng-container>
	</div>
	<!-- Submit button -->
	<div class="col-12 col-md-12 mb-35">
		<button class="btn theme-btn btn-primary" type="button" (click)="submitForm()">{{initServ?.appStr?.text?.submit | translate}}</button>
	</div>
</form>
<!-- Form error template -->
<ng-template #errorTemp let-controlName="controlName" let-type="type" >
	<ng-container *ngIf="commentForm.controls[controlName]?.touched">
		<div class="tjs-error" *ngIf="commentForm.controls[controlName].hasError('required')"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.empty | translate}}</div>
		<div class="tjs-error" *ngIf="type && type == 'name' && commentForm.controls[controlName].hasError('pattern')"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.specialChars | translate}}</div>
		<div class="tjs-error" *ngIf="type && type == 'email' && commentForm.controls[controlName].hasError('pattern')"><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.validEmail | translate}}</div>
	</ng-container>
</ng-template>