import { Component, OnInit, ViewEncapsulation, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { takeUntil } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
// External lib
import { ToastrService } from 'ngx-toastr';
// Child component, Custom validator
import { PaymentGatewayComponent, CustomValidators } from '../../../../GlobalDefault';
// Services
import { NgOnDestroy, InitServ, UtilServ, ApiServ, LoaderServ, PaymentGatewayServ, CacheService, PaymentGatewayPayload } from '../../../../../Services';
// Interface
import { BillingAddress, BillingCard } from 'src/app/Interfaces';
@Component({
	selector: 'bk-tip-popup',
	templateUrl: './TipPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TipPopupComponent implements OnInit {
	// Variables
	tipForm: FormGroup; // Tip form
	booking: any;
	loaderId: string = 'bkng-tip-loader';
	cardsLoaderId: string = 'cards-loader';
	cards: any;
	isCardLoaded: boolean = true;
	slug: string = 'give_tip';
	secId: string = '';
	section: any = { title: null, form: null, submit_btn: null };
	buildPaymentForm: boolean = false;
	// Payment gatway component
	@ViewChild(PaymentGatewayComponent) paymentGatewayChild: PaymentGatewayComponent | undefined;
	// eslint-disable-next-line max-params
	constructor(public dialogRef: MatDialogRef<TipPopupComponent>, private destroy: NgOnDestroy, private frmBldr: FormBuilder, private toastr: ToastrService, public initServ: InitServ, public utilServ: UtilServ, private apiServ: ApiServ, private loader: LoaderServ, public customValidators: CustomValidators, private cDRef: ChangeDetectorRef, private cacheServ: CacheService, private paymentServ: PaymentGatewayServ) {
		// Load the google api script
		if(this.utilServ){
			this.utilServ.loadGoogleApiScript();
		}
		if(this.initServ.paymentGateway){
			this.utilServ.loadPaymentGatewayScript(this.initServ.paymentGateway);
		}
		// Build form
		this.tipForm = this.frmBldr.group({
			booking_id: [null, [Validators.required]],
			amount: ['', [Validators.required, Validators.min(0.1)]],
			card_id: [],
			method: [],
			card_last4_digits:['']
		});
	}
	ngOnInit(): void {
		if(this.booking){
			this.isCardLoaded = false;
			this.f['booking_id'].setValue(this.booking._id);
			this.userCards();
		}
		// build popup section
		this.buildSectionData();
		this.cDRef.detectChanges();
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
			// detect changes
			this.cDRef.detectChanges();
		}).catch((err) => console.log(err));
	}
	// convenience getter for easy access to form fields
	get f(): { [key: string]: AbstractControl } {
		return this.tipForm.controls;
	}
	/**
	 * Call API to get user cerdit cards.
	 */
	private userCards(): void {
		this.apiServ.setLoaderId(this.cardsLoaderId);
		this.loader.show(this.cardsLoaderId, this.dialogRef);
		this.apiServ.callApiWithPathQueryVars('GET', 'UserCards', [this.utilServ.userId()], { location_id: this.booking.base_location_id }).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.userCardsRes(res));
	}
	/**
	 * Handles the response of the customer cards request
	 * @param {any} res - the response object from the API
	 */
	private userCardsRes(res: any): void {
		if(this.apiServ.checkAPIRes(res)) {
			this.cards = res.data;
			if(this.utilServ.checkArrLength(this.cards)){
				this.f['method'].setValue('existing_credit_card');
			}else{
				this.f['method'].setValue('new_credit_card');
			}
		}else{
			this.f['method'].setValue('new_credit_card');
		}
		this.cardTypeChange();
		this.loader.hide(this.cardsLoaderId, this.dialogRef);
		this.cDRef.detectChanges();
		this.loader.hide(this.loaderId, this.dialogRef);
	}
	/**
	 * Handles the change of card type in the payment form.
	 */
	public cardTypeChange(): void {
		this.buildPaymentForm = false;
		if(this.f['method'].value == 'existing_credit_card'){
			let billingCard: BillingCard = this.utilServ.getDefaultCardId(this.cards);
			this.f['card_id'].setValue(billingCard?.card_id);
			this.f['card_last4_digits'].setValue(billingCard?.card_last4_digits);
			// Remove billing address for existing card case
			if(this.tipForm.controls['billing_address']){
				this.tipForm.removeControl('billing_address');
			}
		} else {
			this.f['card_id'].setValue('');
			this.f['card_last4_digits'].setValue('');
			setTimeout(() => this.resetDefault(), 100);
		}
		this.isCardLoaded = true;
		this.cDRef.detectChanges();
	}
	/**
	 * Resets default values and updates the payment form.
	 */
	private resetDefault(): void {
		this.buildPaymentForm = true;
		// this.loader.hide(this.cardLoaderId);
		this.cDRef.detectChanges();
	}
	/**
	 * Submits the tipForm if it is valid
	 */
	public submitForm(): void {
		if(this.tipForm.valid){
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId, this.dialogRef);
			this.f['amount'].setValue(+this.f['amount'].value);
			if((this.initServ.threeDSecure && this.initServ.paymentGateway != 'authorizedotnet') || this.f['method'].value == 'new_credit_card') {
				this.generateToken();
			} else {
				this.saveForm(this.tipForm.value);
			}
		}else{
			this.tipForm.markAllAsTouched();
			this.toastr.error('Please fill the required fields marked in red.');
			if(this.paymentGatewayChild){
				if(this.f['method'].value == 'new_credit_card'){
					this.paymentGatewayChild.isZipcode = true;
				}
				this.paymentGatewayChild.refresh();
			}
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Generates a payment gateway token for a new credit card.
	 */
	private async generateToken(): Promise<void> {
		let data: {amount: number, booking_id: string|number, location_id: number, base_location_id: number, uid: string|number, payment_method: string, pay_with_cc: string, payment: boolean, billing_address: BillingAddress} = {
			amount: this.tipForm.value['amount'],
			booking_id: +this.f['booking_id'].value,
			location_id: +this.booking.base_location_id,
			base_location_id: +this.booking.base_location_id,
			uid: this.utilServ.userId(),
			payment_method: this.f['method'].value,
			pay_with_cc: this.f['card_id'].value,
			payment: true,
			billing_address: this.tipForm.controls['billing_address'] && this.tipForm.controls['billing_address'].value
		}
		// Calls the payment gateway child component's getPaymentToken method to generate the token.
		let paymentGatewayToken: PaymentGatewayPayload = await this.paymentServ?.generatePaymentGatewayToken(data, this.paymentGatewayChild);
		if(paymentGatewayToken?.token || paymentGatewayToken?.dataValue){
			// If the token is successfully generated, assigns the token data to tokenData and calls the saveTip method with the tokenData.
			let payload: any = this.paymentServ.assignToken(this.tipForm.value, paymentGatewayToken, false, true);
			this.saveForm(payload);
		}else{
			// If the token is not successfully generated, hides the main loader.
			this.loader.hide(this.loaderId);
		}
	}
	/**
	 * Call API to save the tip.
	 * @param {any} payload - payload data
	 */
	private saveForm(payload: any): void {
		this.apiServ.callApi('POST', 'GiveTip', payload).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.giveTipRes(res));
	}
	/**
	 * Handle respone of API.
	 * @param {any} res - API respone data.
	 */
	private giveTipRes(res: any): void {
		if(this.apiServ.checkAPIRes(res)){
			this.toastr.success(res.message);
		}else{
			if(res && res.message){
				this.toastr.error(res.message);
			}
		}
		this.dialogRef.close(true);
		this.loader.hide(this.loaderId, this.dialogRef);
	}

	/**
	 * Handles the change event when a new card is selected.
	 * Updates the payment form with the selected card details.
	 * @param {Event} event - The change event containing the selected card ID.
	 */
	public onCardChange(event: Event): void {
		let cardId: string = (<HTMLInputElement>event.target).value;
		let billingCard: BillingCard = this.cards.find((card: BillingCard) => card.id === cardId);
		this.f['card_id'].setValue(billingCard?.id);
		this.f['card_last4_digits'].setValue(billingCard?.last4);
	}

	@HostListener('document:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			event.preventDefault();
			// Additional logic if needed
		}
	}
}
