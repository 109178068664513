import { Component, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, UtilServ, InitServ, ApiServ, LoaderServ, CacheService } from '../../../../Services';
// Constants
import { PASSWORD_MIN_LENGTH } from '../../../../Constants';
//Custom validator
import { CustomValidators } from '../../../GlobalDefault';

@Component({
	selector: 'bk-change-password-popup',
	templateUrl: './ChangePasswordPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ChangePasswordPopupComponent implements OnInit {
	// Variables
	passwordForm: FormGroup; // Change password form
	loaderId: string = 'change-pwd-loader';
	// Section fields
	secId: string = '';
	slug: string = 'change_password'
	section: any = { title: null, form: null, save_btn: null, cancel_btn: null };

	constructor(public dialogRef: MatDialogRef<ChangePasswordPopupComponent>, @Self() private destroy: NgOnDestroy, private frmBldr: FormBuilder, private utilServ: UtilServ, public initServ: InitServ, private apiServ: ApiServ, private loader: LoaderServ, private toastr: ToastrService, private cacheServ: CacheService) {
		this.passwordForm = this.frmBldr.group({
			old_password: ['', [Validators.required]],
			new_password: ['', [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH)]],
			confirm_password: ['', [Validators.required]],
			user_id: [this.utilServ.userId()],
			role: ["customer"]
		}, {
			validators: [CustomValidators.matchPassword('new_password', 'confirm_password')]
		});
	}

	ngOnInit(): void {
		// build popup section
		this.buildSectionData();
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
		});
	}
	// convenience getter for easy access to form fields
	get f(): { [key: string]: AbstractControl } {
		return this.passwordForm.controls;
	}
	/**
	 * Submit form
	 */
	public submitForm(): void {
		if (this.passwordForm.valid) {
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId, this.dialogRef);
			this.apiServ.callApi('POST', 'ResetPassword', this.passwordForm.value).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res));
		} else {
			this.passwordForm.markAllAsTouched();
		}
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res: any): void {
		if (this.apiServ.checkAPIRes(res)) {
			this.toastr.success(res.message);
			this.dialogRef.close();
		} else {
			if (res && res.message) {
				this.toastr.error(res.message);
			}
		}
		this.loader.hide(this.loaderId, this.dialogRef);
	}
}
