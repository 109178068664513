import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KeyValuePipe } from '@angular/common';
// External library
import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from 'angular2-text-mask';
import { EditorModule } from '@tinymce/tinymce-angular';
// Module
import { GlobalDefaultModule } from '../GlobalDefault/GlobalDefault.module'; // Global module
import { UserComponentsModule } from '../UserComponents/UserComponents.module' // Used for cancel button
import { FormWidgetsModule } from '../FormWidgets/FormWidgets.module'; // Form Widgets(Live reviews, faq)
// Services
import { BkngFormPopupServ } from './BkngFormPopup.service';
// Directives
import { NgInitDirective } from 'src/app/Directives';
// Components
import { LocationComponent, ZipcodeComponent, PicturePreviewPopupComponent, MerchantLocationsPopupComponent, ServiceCategoryComponent, FrequencyComponent, PricingParameterComponent, ExtrasComponent, SectionHeadingComponent, SelectElementBlockComponent, ItemsWithPckgsComponent, PackagesPopupComponent, ItemsWithAddonsComponent, PricingParameterWithAreaComponent, CustomFieldsComponent, CustomerDetailsComponent, CustomerEmailComponent, TipParkingComponent, AmountTypeDropdownComponent, AddressComponent, KeyInfoComponent, SpecialNotesComponent, DiscountsComponent, PaymentInfoComponent, ServiceProviderComponent, ServiceProviderResComponent, SelectedProviderComponent, CommonButtonsComponent, ScheduleCalendarComponent, ScheduleCalendarPopupComponent, HourlyComponent, SlotLayoutComponent, ViewProviderReviewsComponent, ViewProviderReviewsPopupComponent} from './index';
import {SidebarComponent, PaymentAdjustmentComponent,HowItWorksComponent, RatingComponent, FacebookShareComponent, BkngSummaryComponent, ChargedPaymentSummaryComponent, HowItWorksPopupComponent } from './Sidebar';
// Multi step components
import { MultiZipcodeComponent, ServiceCategoryBtnComponent, MultiServiceProviderComponent, MultiSlotLayoutComponent, MultiDateLayoutComponent } from './MultiStepComp';
// Pipe
import { RmSpclCharAndSpacePipe } from '../../Pipes';

@NgModule({
	declarations: [LocationComponent, ZipcodeComponent, PicturePreviewPopupComponent, MerchantLocationsPopupComponent, ServiceCategoryComponent, FrequencyComponent, PricingParameterComponent, ExtrasComponent, SectionHeadingComponent, SelectElementBlockComponent, ItemsWithPckgsComponent, PackagesPopupComponent, ItemsWithAddonsComponent, CustomFieldsComponent, PricingParameterWithAreaComponent, CustomerDetailsComponent, CustomerEmailComponent, TipParkingComponent, AmountTypeDropdownComponent, AddressComponent, KeyInfoComponent, SpecialNotesComponent, DiscountsComponent, PaymentInfoComponent, ServiceProviderComponent, ServiceProviderResComponent, SelectedProviderComponent, CommonButtonsComponent, PaymentAdjustmentComponent, SidebarComponent, HowItWorksComponent, RatingComponent, FacebookShareComponent, BkngSummaryComponent, ChargedPaymentSummaryComponent, ScheduleCalendarComponent, ScheduleCalendarPopupComponent, MultiZipcodeComponent, ServiceCategoryBtnComponent, MultiServiceProviderComponent, HourlyComponent, SlotLayoutComponent, MultiSlotLayoutComponent, MultiDateLayoutComponent, ViewProviderReviewsComponent,ViewProviderReviewsPopupComponent,NgInitDirective,HowItWorksPopupComponent, RmSpclCharAndSpacePipe],
	imports: [
		CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, GlobalDefaultModule, TextMaskModule, UserComponentsModule, FormWidgetsModule, EditorModule
	],
	exports: [LocationComponent, ZipcodeComponent, ServiceCategoryComponent, FrequencyComponent, PricingParameterComponent, ExtrasComponent, ItemsWithPckgsComponent, ItemsWithAddonsComponent, PricingParameterWithAreaComponent, CustomFieldsComponent, CustomerDetailsComponent, CustomerEmailComponent, TipParkingComponent, AddressComponent, KeyInfoComponent, SpecialNotesComponent, DiscountsComponent, PaymentInfoComponent, ServiceProviderComponent, ServiceProviderResComponent,  SelectedProviderComponent, CommonButtonsComponent, SidebarComponent, MultiZipcodeComponent, ServiceCategoryBtnComponent, MultiServiceProviderComponent, BkngSummaryComponent, SelectElementBlockComponent, HourlyComponent, SlotLayoutComponent, MultiSlotLayoutComponent, MultiDateLayoutComponent, RmSpclCharAndSpacePipe, AmountTypeDropdownComponent],
	entryComponents: [PicturePreviewPopupComponent, MerchantLocationsPopupComponent, PackagesPopupComponent, ScheduleCalendarPopupComponent],
	providers: [KeyValuePipe, BkngFormPopupServ]
})
export class BookingFormModule { }
