import { Component, Input, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
// Services
import { InitServ, NgOnDestroy, UtilServ, RenderComponentServ, SectionServ } from '../../../Services';

@Component({
	selector: 'bk-bkng-discount-bar',
	templateUrl: './BkngDiscountBar.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class BkngDiscountBarComponent implements OnInit {
	// Variables
	@Input() data: any;
	@Input() formLayout: string = 'form';
	@Input() layoutType : string = 'top-bar';
	@Input() btnData: any;

	planPerm: boolean = false;
	barVisibility: boolean = false;
	isMobile: boolean = false;
	hideBar: boolean = false;
	desktopVClass: any = {
		bar_layout_v1: 'tjs-couponbar--clock-v1',
		bar_layout_v2: 'tjs-couponbar--clock-v2',
		bar_layout_v3: 'tjs-couponbar--clock-v1 tjs-couponbar--clock-v3',
		bar_layout_v4: 'tjs-couponbar--clock-v1 tjs-couponbar--clock-v3',
		bar_layout_v5: 'tjs-couponbar--text-v1',
		bar_layout_v6: 'tjs-couponbar--text-v2',
		bar_layout_v7: 'tjs-couponbar--text-v3 d-flex flex-column'
	}
	mobileVClass: any = {
		mobile_layout_v1: 'tjs-couponbar--mobile-v1',
		mobile_layout_v2: 'tjs-couponbar--mobile-v2',
		mobile_layout_v3: 'tjs-couponbar--mobile-v3',
		mobile_layout_v4: 'tjs-couponbar--mobile-v4',
		mobile_layout_v5: 'tjs-couponbar--mobile-v5',
		mobile_layout_v6: 'tjs-couponbar--mobile-text-v1',
		mobile_layout_v7: 'tjs-couponbar--mobile-text-v2',
		mobile_layout_v8: 'tjs-couponbar--mobile-text-v3'
	}

	constructor(private initServ: InitServ, public utilServ: UtilServ, public rcServ: RenderComponentServ, public secServ: SectionServ, private cDRef: ChangeDetectorRef) {
		this.planPerm = this.initServ.appPlansPermission('discount-bar');
		// Screen type
		let secreenType = this.utilServ.secreenType();
		this.isMobile = (secreenType == 'mobile') ? true : false;
	}

	ngOnInit(): void {
		if(this.formLayout == 'multistep_form'){
			setTimeout(()=>{
				var body = document.body;
				if(!body.classList.contains('dbar-multistep__active')){
					body.classList.add("dbar-multistep__active");
				}
				let discBar = document.getElementsByClassName("multistep-discount-bar");
				if(discBar && discBar.length > 0){
					let height = (discBar[0]).clientHeight;
					let elem = document.getElementsByClassName('multistep-discount-bar-style');
					while(elem.length > 0){
						if(elem[0] && elem[0].parentNode){
							elem[0].parentNode.removeChild(elem[0]);
						}
					}
					var styleElem = document.createElement('style');
					styleElem.type = 'text/css';
					styleElem.className += "multistep-discount-bar-style"
					let fixedHeight = this.isMobile ? '4.4' : '7.5';
					let css = `.dbar-multistep__active .multistep__header{
						top:`+height+`px
					}
					.dbar-multistep__active .multistep__content--main {
						margin-top:calc(`+fixedHeight+`rem + `+height+`px)
					}`;
					document.body.appendChild(styleElem);
					styleElem.appendChild(document.createTextNode(css));
				}
			}, 1000);
		}
		this.barVisibility = this.checkBarVisibility();
		this.cDRef.detectChanges();
	}
	/**
	 * Check the bar visibility
	 * @returns boolean
	 */
	private checkBarVisibility(): boolean {
		if(this.formLayout == 'section'){
			return true;
		}
		let barPosition = this.data.bar_design_desktop;
		if(this.isMobile){
			barPosition = this.data.bar_design_mobile;
		}
		if(this.layoutType == 'summary'){
			if(barPosition && barPosition == 'summary'){
				return true;
			}
		} else {
			if(barPosition && barPosition == 'top_bar'){
				return true;
			}
		}
		return false;
	}
	/**
	 * Hide the discount bar
	 */
	public hideBlock(): void {
		this.hideBar = true;
		if(document.body.classList.contains('dbar-multistep__active')){
			document.body.className = document.body.className.replace("dbar-multistep__active","");
		}
		this.cDRef.detectChanges();
	}

	ngOnDestroy(){
		if(document.body.classList.contains('dbar-multistep__active')){
			document.body.className = document.body.className.replace("dbar-multistep__active","");
		}
	}
}
