/**
 * Component show the booking receipt based on user id
 */
import { Component, OnInit, Self, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
// Interface
import { APIRes } from 'src/app/Interfaces';
// Services
import { ApiServ, NgOnDestroy } from 'src/app/Services';

interface BookingReceiptApiRes extends APIRes {
	data?: string
}

@Component({
	selector: 'bk-booking-receipt',
	template: '<div id="booking-invoice" *ngIf="bookingReceipt" [innerHTML]=" bookingReceipt | safeHtml"></div>',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class BookingReceiptComponent implements OnInit {

	bkngId: string | null = null;
	bookingReceipt: string | null = null;

	constructor(private apiServ: ApiServ, private actRoute: ActivatedRoute, @Self() private destroy: NgOnDestroy,) {}

	ngOnInit() {
		this.getBkngId();
	}

	/**
	 * Retrieves and decrypts the booking ID from the route parameters.
	 * If the booking token is present and successfully decrypted,
	 * it then proceeds to fetch the booking receipt from the API.
	 * @returns {Promise<void>} A promise that resolves when the booking receipt API call is made.
	 */
	async getBkngId(): Promise<void> {
		this.bkngId = (this.actRoute.snapshot.params?.['booking_token']) ? this.actRoute.snapshot.params['booking_token'] : null;
		if(this.bkngId){
			this.getBookingReceiptApi();
		}
	}

	/**
	 * This function makes an API call to retrieve booking receipt based on the provided slug and booking ID.
	 * The result is then passed to the `onResultCallback` function for further processing.
	 */
	private getBookingReceiptApi(): void {
		this.apiServ.callApiWithQueryParams('GET', 'TemplateData', {slug:'service_receipt', booking_id: this.bkngId}).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
	}

	/**
	 * This function is a callback that processes the API response.
	 * @param res : BookingReceiptApiRes
	 * It checks if the response is valid and contains data, then assigns the data to `bookingReceipt`.
	 * If the data is valid, it calls the `printBookingReceipt` function to handle printing.
	 */
	private onResultCallback(res:BookingReceiptApiRes): void {
		if(this.apiServ.checkAPIRes(res) && res?.data){
			this.bookingReceipt = res.data;
			this.printBookingReceipt();
		}
	}

	/**
	 * This function handles printing the booking receipt. It waits for 100ms to ensure the DOM is ready.
	 * It retrieves the content of the element with the ID 'booking-invoice' and writes it into a new document for printing.
	 * Finally, it focuses the window and triggers the print dialog.
	 */
	private printBookingReceipt(): void {
		setTimeout(() => {
			let printElem = document.getElementById('booking-invoice');
			if(printElem){
				let printContents = printElem?.innerHTML;
				window.document.write('<html><head><meta charset="utf-8"><title></title><style type="text/css" media="print">@page {size: auto; margin: 0;}</style></head><body>');
				window.document.write(printContents);
				window.document.write('</body></html>');
				window.focus();
				window.print();
			}
		}, 100);
	}
}
