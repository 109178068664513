<div class="tjs-testimonial py-30 {{variationClass[varId]}}" [id]="secId" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div class="container">
		<!-- Title -->
		<div class="tjs-section__title mb-50" *ngIf="section?.title?.content">
			<div class="tjs-section__heading text-center">
				<h3 [id]="section?.title?.id" class="fw-bold display-5 bk-title" [innerHTML]="section?.title?.content | safeHtml"></h3>
			</div>
		</div>
		<!-- List -->
		<div [id]="section?.list?.id" class="position-relative" *ngIf="section?.list && section?.list?.items && (section?.list?.items).length > 0">
			<div [attr.dir]="initServ?.selectedLang?.rtl ? 'rtl' :'ltr'" class="keen-slider" #testimonialSlide>
				<ng-container *ngFor="let item of section?.list?.items;trackBy: secServ.trackByFnIndex">
					<div class="slide keen-slider__slide"  [id]="item?.id" *ngIf="(item?.media && (item?.media).length > 0) || item?.title || item?.sub_title || item?.desc">
						<div class="tjs-testimonial__item rounded bk-item">
							<ng-container *ngTemplateOutlet="contentTemp; context:{item:item,isReadMore: true}"></ng-container>
						</div>
					</div>
				</ng-container>
			</div>
			<ng-container *ngIf="(varId == 'bk_testimonial_V3') && (section?.list?.items).length > 1">
				<span (click)="slider.prev()" class="d-flex justify-content-center align-items-center tjs-pointer text-body position-absolute fs-16 slider-arrow prev-review"><i class="tjsicon-left-arrow"></i></span>
				<span (click)="slider.next()" class="tjs-pointer d-flex justify-content-center align-items-center text-body position-absolute fs-16 slider-arrow next-review"><i class="tjsicon-right-arrow"></i></span>
			</ng-container>
		</div>
	</div>
</div>
<!-- Content template -->
<ng-template #contentTemp let-item="item" let-isReadMore="isReadMore">
	<!-- Media -->
	<div class="mb-20" *ngIf="item?.media && (item?.media).length > 0">
		<img loading="auto"  [id]="item?.media_id" class="rounded-circle img-fluid-disabled mx-auto" [src]="item?.media[0]" [width]="(varId == 'bk_testimonial_V3') ? '105' : '70'" [height]="(varId == 'bk_testimonial_V3') ? '105' : '70'" alt="review" />
	</div>
	<!-- Title template -->
	<ng-container *ngIf="varId != 'bk_testimonial_V2'">
		<ng-container *ngTemplateOutlet="titleTemp; context:{item:item}"></ng-container>
	</ng-container>
	<!-- Description -->
	<div class="tjs-section__description" *ngIf="item?.desc" [class.excerpt-length--popup]="isReadMore && utilServ.reviewCount(item?.desc)">
		<p [id]="item?.desc_id" class="bk-item-desc mb-0" [innerHTML]="(isReadMore ? utilServ.reviewDesc(item?.desc) : item?.desc) | safeHtml"></p>
		<button type="button" class="btn btn-link read-more bk-item-read-more tjs-pointer fs-14 text-capitalize p-0" [id]="section?.read_more_id" *ngIf="isReadMore && utilServ.reviewCount(item?.desc)" (click)="openModal(item)">{{initServ?.appStr?.text?.readMore | translate}}</button>
	</div>
	<!-- Title template -->
	<ng-container *ngIf="varId == 'bk_testimonial_V2'">
		<ng-container *ngTemplateOutlet="titleTemp; context:{item:item}"></ng-container>
	</ng-container>
</ng-template>

<!-- Title template -->
<ng-template #titleTemp let-item="item" >
	<div class="mb-15" *ngIf="item?.title || item?.sub_title">
		<!-- Title -->
		<span [id]="item?.title_id" class="fw-bolder d-block bk-item-title" *ngIf="item?.title" [innerHTML]="item?.title | safeHtml"></span>
		<!-- Sub title -->
		<span [id]="item?.sub_title_id" class="fs-14 bk-item-sub-title" *ngIf="item?.sub_title" [innerHTML]="item?.sub_title | safeHtml"></span>
	</div>
</ng-template>

<!-- Modal -->
<div class="extend-content-popup" *ngIf="modalData">
	<div id="testimonialModal_{{modalData?.id}}" class="modal fade pe-0" data-bs-keyboard="true">
		<div [id]="section?.list?.id" class="modal-dialog modal-dialog-centered mw-100 mx-15">
			<div class="modal-content tjs-popup tjs-popup--sm mx-auto bk-item">
				<div class="text-end px-20 pt-15">
					<button type="button" class="btn-close fs-13 tjs-popup__closed" data-bs-dismiss="modal" aria-hidden="true" (click)="modalData = null"><i class="tjsicon-close text-danger"></i></button>
				</div>
				<div class="modal-body tjs-popup__content text-center mb-20 testimonial-modal-content">
					<ng-container *ngTemplateOutlet="contentTemp; context:{item:modalData,isReadMore: false}"></ng-container>
				</div>
			</div>
		</div>
	</div>
</div>