import { Component, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
// External lib
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
// Services
import { NgOnDestroy, InitServ, UtilServ, ApiServ, LoaderServ, CacheService } from '../../../Services';

@Component({
	selector: 'bk-deactivate-account-popup',
	template: `<div class="tjs-popup tjs-popup--sm position-relative min-height--150" [id]="secId">
		<form bkDebounceEvent [isSubmit]="true" [formGroup]="deactivateForm" (debounceSubmit)="submitForm()" [id]="section?.form?.id">
			<div class="tjs-popup__content tjs-popup__body text-center">
				<!-- Image -->
				<div class="bk-media" *ngIf="section?.media?.urls[0]">
					<img loading="auto" [id]="section?.media?.id" class="img-fluid d-inline-block" alt="SadFace" [src]="section?.media?.urls[0]" width="120" height="120">
				</div>
				<!-- Message -->
				<div class="mt-20">
					<h5 class="fw-bold mb-20 bk-form-label" [id]="section?.message_label?.id" *ngIf="section?.message_label?.content" [innerHTML]="section?.message_label?.content | safeHtml"></h5>
					<textarea [ngClass]="{'is-invalid': deactivateForm.controls['note']?.touched &&  deactivateForm.controls['note']?.errors}" class="form-control" rows="5" formControlName="note"></textarea>
					<!-- Error -->
					<div class="tjs-error" *ngIf="deactivateForm.controls['note']?.touched && deactivateForm.controls['note']?.errors?.required">
						<i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.empty | translate}}
					</div>
				</div>
			</div>
			<!-- Footer -->
			<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse bk-footColor">
				<bk-navigate [secId]="section?.cancel_btn?.id" [designId]="section?.cancel_btn?.design_id" [innerHTML]="section?.cancel_btn?.content" [isSubmit]="false" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" class="w-100 d-block mb-5" (callback)="dialogRef.close()"></bk-navigate>
				<bk-navigate [secId]="section?.deactivate_btn?.id" [designId]="section?.deactivate_btn?.design_id" [innerHTML]="section?.deactivate_btn?.content" [isSubmit]="true" essentialClass="w-100" customClass="btn btn-danger rounded-5 w-100" class="w-100 d-block mb-15 mb-sm-5"></bk-navigate>
			</div>
		</form>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class DeactivateAccountPopupComponent implements OnInit {
	// variables
	deactivateForm: FormGroup;
	loaderId: string = 'deactivate-account-loader';
	// Section fields
	secId: string = '';
	slug: string = 'deactivate_account';
	section: any = { message_label: null, media: null, cancel_btn: null, deactivate_btn: null };
	// eslint-disable-next-line max-params
	constructor(private frmBldr: FormBuilder, @Self() private destroy: NgOnDestroy, public dialogRef: MatDialogRef<DeactivateAccountPopupComponent>, public initServ: InitServ, private utilServ: UtilServ, private apiServ: ApiServ, private loader: LoaderServ, private toastr: ToastrService, private translate: TranslateService, private cacheServ: CacheService) {
		let noteMsg = this.translate.instant('I no longer need your services.');
		this.deactivateForm = this.frmBldr.group({
			uid: [this.utilServ.userId(), [Validators.required]],
			note: [noteMsg, [Validators.required]]
		});
	}

	ngOnInit(): void {
		// build popup section
		this.buildSectionData();
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
		});
	}
	/**
	 * Submit form
	 */
	public submitForm(): void {
		if (this.deactivateForm.valid) {
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId, this.dialogRef);
			this.apiServ.callApiWithPathVariables('POST', 'DeactivateReq', [this.utilServ.userId()], this.deactivateForm.value).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res));
		} else {
			this.deactivateForm.controls['note'].markAsTouched();
		}
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res: any): void {
		if (this.apiServ.checkAPIRes(res)) {
			this.toastr.success(res.message);
			this.dialogRef.close();
		} else {
			if (res && res.message) {
				this.toastr.error(res.message);
			}
		}
		this.loader.hide(this.loaderId, this.dialogRef);
	}
}
