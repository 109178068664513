import { Component, OnInit, ViewEncapsulation, Self, ViewContainerRef, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
// Services
import { NgOnDestroy, ApiServ, InitServ, LoaderServ, UtilServ, BkngFormServ, BkngCustomSectionService } from '../Services';
import { AddBkngServ } from './index';

@Component({
	selector: 'bk-booking-form',
	template: `<div id="{{containerId}}"></div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class BookingFormComponent implements OnInit {
	// Variables
	@Input() isEmbed: boolean = false;
	@Input() embedSelectedIndustrySlug: any; // Used in embed form case
	@Input() embedIndustryTab: boolean = true; // Used in embed form case

	containerId: string = 'booking-form-container';
	previewMode: boolean = false;
	appData: any = this.initServ.appData; // App data
	industrySlug : any;
	selectedIndustryId!: string | number;
	selectedFormId!: string | number;
	formWidgets: any;
	shortFormData: any;
	embededPrefilledParams: any;
	industryData: any;
	selectedCombination: any;
	paramFormId: string | null;
	bannerSecStatus: boolean = true;

	constructor(private vcRef: ViewContainerRef,public apiServ : ApiServ, @Self() private destroy: NgOnDestroy, private initServ: InitServ, private actRoute: ActivatedRoute, private loader: LoaderServ, private utilServ: UtilServ, private bkngFormServ: BkngFormServ, private addBkngServ: AddBkngServ, private bkngCustSecServ: BkngCustomSectionService) {
		this.bkngCustSecServ.resetVal();
		// Load the payment gateway script
		if(this.initServ.paymentGateway){
			this.utilServ.loadPaymentGatewayScript(this.initServ.paymentGateway);
		}
		// Preview mode, depend on theme slug and query params preview
		this.previewMode = this.initServ.theme ? true : false;
		// Industry slug
		this.industrySlug = this.actRoute.snapshot.params['industry'] ? this.actRoute.snapshot.params['industry'] : '';
		// Form id
		this.paramFormId = this.actRoute.snapshot.queryParamMap.get('formid');
		// Form id
		let bannerSecStatus: any = this.actRoute.snapshot.queryParamMap.get('banner');
		this.bannerSecStatus = (bannerSecStatus == 'false') ? false : true;
		// Store query parameters of form Widgets
		this.setFormWidgets();
	}

	ngOnInit(): void {
		// Selected industry slug, in case of embed(original form) by builder
		if(this.isEmbed && this.embedSelectedIndustrySlug && this.embedSelectedIndustrySlug != 'all'){
			this.industrySlug = this.embedSelectedIndustrySlug;
		}
		// Short form data
		this.shortFormData = this.bkngFormServ.getShortFormData;
		// Embeded prefilled params
		if(this.utilServ && this.utilServ.embedStatus){
			this.embededPrefilledParams = this.utilServ.appLocalStorage('embeded_params', true);
			if(this.embededPrefilledParams && this.embededPrefilledParams.industry_id){
				// Check industry exist
				if(this.addBkngServ.isIndustryExist(this.embededPrefilledParams.industry_id)){
					this.selectedIndustryId = +this.embededPrefilledParams.industry_id;
				}
			}
		}
		// Default initializer
		this.defaultInit();
	}
	/**
	 * Set the form widgets
	 */
	private setFormWidgets(): void {
		let queryParams: any = null;
		if(this.actRoute.snapshot.queryParamMap){
			let queryParamMap: any = this.actRoute.snapshot.queryParamMap;
			if(queryParamMap.params && (Object.keys(queryParamMap.params)).length > 0){
				queryParams = queryParamMap.params;
			}
		}
		if(queryParams && (Object.keys(queryParams)).length > 0){
			if(queryParams.referrer_source || queryParams.industry_id || queryParams.form_id || queryParams.location || queryParams.f_name || queryParams.l_name || queryParams.email || queryParams.phone || queryParams.service_id || queryParams.frequency_id || queryParams.zipcode || queryParams.minutes || queryParams.pricing_param || queryParams.date || this.addBkngServ.checkParamAvail(queryParams)){
				this.formWidgets = {
					referrer_source : queryParams.referrer_source,
					industry_id : queryParams.industry_id,
					form_id : queryParams.form_id,
					location_id : queryParams.location,
					first_name : queryParams.f_name,
					last_name : queryParams.l_name,
					email_id : queryParams.email ? queryParams.email : '',
					phone_number : queryParams.phone,
					service : queryParams.service_id,
					frequency : queryParams.frequency_id,
					zipcode : (queryParams.zipcode) ? (queryParams.zipcode).trim() : '',
					service_hourly_value : queryParams.minutes,
					date : queryParams.date,
					pricing_parameter : queryParams.pricing_param ? JSON.parse(queryParams.pricing_param) : null
				}
				/** Create params from url. **/
				let paramOutput: any = this.addBkngServ.createParamObjFromUrl(queryParams);
				if(paramOutput && paramOutput[0] && paramOutput[0].length > 0){
					this.formWidgets['pricing_parameter'] = paramOutput[0];
				}
				if(paramOutput[1] && paramOutput[1].length > 0){
					this.formWidgets['extras'] = paramOutput[1];
				}
				if(paramOutput[2] && paramOutput[2].length > 0){
					this.formWidgets['partial_cleaning'] = paramOutput[2];
				}
				// Set the form widgets
				this.utilServ.setWidgetFormData = this.formWidgets;
				// Check industry exist
				if(this.addBkngServ.isIndustryExist(queryParams.industry_id)){
					this.selectedIndustryId = +queryParams.industry_id;
				}
				this.selectedFormId = +queryParams.form_id;
			}
		}
	}
	/**
	 * Default initializer
	 */
	private defaultInit(): void {
		if(this.appData && this.appData.industries && (this.appData.industries).length > 0){
			if(!this.selectedIndustryId){
				if(this.industrySlug){
					for(let industry of this.appData.industries){
						if(industry.industry_slug == this.industrySlug){
							if(industry.status == 1){
								this.selectedIndustryId = industry.id;
								// Industry form settings
								this.getIndustryFormSett();
							} else{
								this.selectedIndustryId = 0;
							}
						}
					}
				} else{
					for(let industry of this.appData.industries){
						if(industry.status == 1 && this.utilServ.checkIndustryStatusForCust(industry)){
							this.selectedIndustryId = industry.id;
							this.industrySlug = industry.industry_slug;
							// Industry form settings
							this.getIndustryFormSett();
							break;
						} else {
							this.loader.hide()
						}
					}
				}
			} else {
				// Industry form settings
				this.getIndustryFormSett();
			}
		}
	}
	/**
	 * Get the industry form settings
	 */
	private getIndustryFormSett(): void {
		this.loader.show()
		this.apiServ.callApiWithPathVariables('GET', 'IndustryFormSett', [this.selectedIndustryId]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
	}
	/**
	 * Get the combination of selected form for widget form
	 * @param industryData
	 * @returns
	 */
	private getWidgetFrmCombination(): any {
		let combination = ''
		if(this.industryData && this.industryData.combined_combination && (this.industryData.combined_combination).length > 0){
			if(this.industryData.combined_combination[0] && this.industryData.combined_combination[0].form_ids && !(this.industryData.combined_combination[0].form_ids).includes(+this.selectedFormId) && (this.industryData.single_combination && (this.industryData.single_combination).length >  0)){
				if(this.industryData.combined_combination[0].prequalifier_data && (this.industryData.combined_combination[0].prequalifier_data).length > 0){
					let  i = 0;
					for(let pqd of this.industryData.combined_combination[0].prequalifier_data){
						if(pqd.form_id == 0){
							for(let combi of this.industryData.single_combination){
								if(this.industryData.combined_combination[0].prequalifier_data[i] && combi.id == this.industryData.combined_combination[0].prequalifier_data[i].combination_id && combi.form_ids && (combi.form_ids).includes(+this.selectedFormId) ){
									combination = combi;
									break;
								}
							}
							break;
						}
						i = i + 1;
					}
				}
			}
		}
		return combination;
	}
	/**
	 * Industry form settings
	 * Conditions::
	 *	1. Short form data
	 *	2. Form widgets
	 *	3. Embed data
	 *	4. Industry data (combination & single)
	 *	5. Default industry
	 */
	private industryFormSett(): void {
		// short form data
		if(this.shortFormData && this.shortFormData.form_id){
			this.selectedCombination = this.bkngFormServ.getCombinationData; // Get the combination data
			this.selectedFormId = +this.shortFormData.form_id;
		} else if(this.formWidgets && this.formWidgets.form_id && this.addBkngServ.isFormExist(this.selectedIndustryId, this.formWidgets.form_id)){
			// Form widgets
			this.selectedFormId = +this.formWidgets.form_id;
			this.selectedCombination = this.getWidgetFrmCombination();
		} else if(this.embededPrefilledParams && +this.embededPrefilledParams.form_id && this.addBkngServ.isFormExist(this.selectedIndustryId, this.embededPrefilledParams.form_id)){
			// Embed data
			this.selectedFormId = +this.embededPrefilledParams.form_id;
			this.selectedCombination = this.getWidgetFrmCombination();
		} else {
			// Industry data (combination & single)
			let sett: any = this.addBkngServ.getCombinationForm();
			this.selectedFormId = sett.formId;
			this.selectedCombination = sett.combination;
		}
	}
	/**
	 * On result callback method
	 * @param res api response
	 * API response handler
	 */
	private onResultCallback(res:any){
		if(this.apiServ.checkAPIRes(res) && res.data){
			this.industryData = res.data;
			this.bkngFormServ.setIndustryFormSett = this.industryData;
			this.industryFormSett();
		} else {
			this.loader.hide()
		}
		// Query param form id is not match with current form id, build the page
		let formId: any;
		if(this.paramFormId && (+this.paramFormId !=  +this.selectedFormId)){
			formId = this.paramFormId;
		} else {
			formId = this.selectedFormId;
		}
		// Build page
		let data: any = {
			isEmbed: this.isEmbed,
			vcRef: this.vcRef,
			destroy: this.destroy,
			containerId: this.containerId,
			loaderId: 'main',
			industrySlug: this.industrySlug,
			industry: this.selectedIndustryId,
			form: formId,
			combination: this.selectedCombination,
			formLayout: this.bkngFormServ.getFormLayout(this.selectedIndustryId, this.selectedFormId),
			embedIndustryTab: this.embedIndustryTab,
			bannerSecStatus: this.bannerSecStatus
		}
		this.addBkngServ.setBkngVariables = data;
		this.addBkngServ.buildPage(data);
	}
}
