import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ } from '../../Services';

@Component({
	selector: 'bk-features',
	template: `
	<div [id]="secId" class="py-35 py-sm-40 bg-white tjs-feature" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
		<div class="container" *ngIf="section?.list && section?.list?.items && (section?.list?.items).length > 0">
			<div class="row bk-list justify-content-center" [id]="section?.list?.id">
				<!-- List -->
				<div *ngFor="let item of section?.list?.items let i=index;trackBy: secServ.trackByFnIndex" [id]="item?.id" class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 my-20" [class.mt-cus-md-30]="i>3">
					<div class="bk-item">
						<!-- Media -->
						<div class="tjs-h-100 tjs-w-100 tjs-bg-dark rounded-circle mx-auto tjs-feature__img d-flex align-items-center justify-content-center mb-30 bk-item-img" *ngIf="item?.media && (item?.media).length > 0">
							<img loading="auto" [id]="item?.media_id" class="img-fluid bk-item-media" width="50" height="50" [src]="item?.media[0]" alt="Feature" />
						</div>
						<!-- Title -->
						<div class="text-center" *ngIf="item?.title">
							<p [id]="item?.title_id" class="lead fw-normal mb-0 bk-item-title" [innerHTML]="item?.title | safeHtml"></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class FeaturesComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		list:null
	}
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
	}
}
