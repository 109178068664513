import { Component, Input, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
// Services
import { BkngFormServ, InitServ, NgOnDestroy, SectionServ, UtilServ } from '../../../Services';
// Constant
import { BLANK_TINYMCE_CONFIG, IOS } from '../../../Constants'

@Component({
	selector: 'bk-special-notes',
	template: `<div [ngClass]="isMultiStepForm ? 'mb-30' : 'py-30 px-md-30 border-bottom bk-card-border'" [formGroup]="noteForm" [id]="section?.id" *ngIf="section && sectionStatus">
		<!-- Title and description -->
		<bk-section-heading [section]="section"  [pageSett]="pageSett"></bk-section-heading>
		<!-- Note -->
		<div id="specialnote-section-id">
			<label class="form-label bk-form-label" *ngIf="section?.note" [id]="section?.note_id" [innerHTML]="section?.note | safeHtml"></label>
			<!-- <textarea [ngClass]="{'is-invalid': noteForm.controls['special_notes']?.touched && noteForm.controls['special_notes']?.errors}"  class="form-control bk-input {{isMultiStepForm ? 'form-control-lg':''}}" rows="5" placeholder="{{section?.note_placeholder}}" formControlName="special_notes" [attr.disabled]="isDisabled"></textarea> -->
			<editor class="blank-editor" [apiKey]="initServ?.tinymceAPIKey" [init]="tinymceConfig" formControlName="special_notes" debounce="500"></editor>
			<!-- Error -->
			<div class="tjs-error bk-error" *ngIf="noteForm.controls['special_notes'].touched && noteForm.controls['special_notes']?.errors?.extraLongTextValidationCheck">
				<i class="tjsicon-attention"></i>
				{{initServ?.appStr?.errorMsg?.extraLongMaxLength | translate}}
			</div>
		</div>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class SpecialNotesComponent implements OnInit {
	// Variables
	@Input() bookingType: any;
	@Input() section: any;
	@Input() noteForm! : FormGroup;
	@Input() prefilledData: any;
	@Input() isMultiStepForm: boolean = false;
	@Input() pageSett: any;

	isDisabled: any;
	sectionStatus: boolean = false;
	tinymceConfig: any = JSON.parse(JSON.stringify(BLANK_TINYMCE_CONFIG));
	// eslint-disable-next-line max-params
	constructor(private bkngFormServ: BkngFormServ, private secServ: SectionServ, public initServ: InitServ, private cDRef: ChangeDetectorRef, private utilServ: UtilServ) {}

	ngOnInit(): void {
		this.isDisabled = this.bkngFormServ.disabledParamScope(this.bookingType, this.prefilledData, null, null);
		if(this.section && this.secServ.checkEleStatus(this.pageSett,this.section.id)){
			this.sectionStatus = true;
		}
		this.setEditorData();
	}
	public setEditorData(): any {
		this.tinymceConfig['placeholder'] = this.section?.note_placeholder;
		this.tinymceConfig['directionality'] = (this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? "rtl" : 'ltr'
		this.tinymceConfig['setup'] = this.editorFunc.bind(this);
	}
	/**
	 * Editor functiona
	 * @param editor
	 */
	editorFunc(editor: any): void {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let self = this;
		// Called on focusin
		editor.on('focusin', function(){
			self.markUntouched();
		});
		// Called on focusout
		editor.on('blur', function(){
			if(!IOS){
				self.emptyCtrl();
			}
		});
		editor.on('focusout', function(){
			if(IOS){
				self.emptyCtrl();
			}
		});
	}
	private markUntouched(): void {
		this.noteForm.controls['special_notes'].markAsUntouched();
		this.cDRef.detectChanges();
	}
	private emptyCtrl(): void {
		if(this.noteForm.controls['special_notes'].value && !this.utilServ.isNoteAvailable(this.noteForm.controls['special_notes'].value)) {
			this.noteForm.controls['special_notes'].setValue(null);
		}
		this.noteForm.controls['special_notes'].markAsTouched();
		this.cDRef.detectChanges();
	}
}
