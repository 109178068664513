<div [id]="secId" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section" class="tjs-session-page py-100 tjs-bg-style position-relative" [ngStyle]="{'background-image' : (section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}">
	<div class="tjs-opacity--bg" [id]="section?.media?.id" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0"></div>
	<div class="tjs-session__wrapper">
		<div class="container">
			<!-- Alert -->
			<div class="col-sm-8 mx-auto mt-20" *ngIf="tokenError else buildSec">
				<div class="alert alert-danger text-center text-danger">{{tokenError}}</div>
			</div>
			<ng-template #buildSec>
				<!-- <div class="d-flex py-100 reauth-payment text-center flex-column justify-content-center" *ngIf="paymentLog"> -->
				<div class="d-flex reauth-payment text-center flex-column justify-content-center" *ngIf="paymentLog">
					<ng-container *ngIf="authWithNewCard; else oldCardHeading">
						<div class="tjs-section__title text-center mb-30" *ngIf="section?.new_card_title?.content || section?.new_card_desc?.content">
							<!-- Title -->
							<div class="tjs-section__heading mb-15" *ngIf="section?.new_card_title?.content">
								<h4 [id]="section?.new_card_title?.id" class="fw-bold display-6 bk-title" [innerHTML]="title | safeHtml"></h4>
							</div>
							<!-- Sub title -->
							<div class="tjs-section__description col-md-7 mx-auto" *ngIf="section?.new_card_desc?.content">
								<p [id]="section?.new_card_desc?.id" class="bk-sub-title mb-0" [innerHTML]="desc | safeHtml"></p>
							</div>
						</div>
					</ng-container>
					<ng-template #oldCardHeading>
						<!-- Square -->
						<ng-container *ngIf="initServ.paymentGateway == 'square'; else otherGateway">
							<div class="tjs-section__title text-center mb-30" *ngIf="section?.square_old_card_title?.content || section?.square_old_card_desc?.content">
								<!-- Title -->
								<div class="tjs-section__heading mb-15" *ngIf="section?.square_old_card_title?.content">
									<h4 [id]="section?.square_old_card_title?.id" class="fw-bold display-6 bk-title" [innerHTML]="title | safeHtml"></h4>
								</div>
								<!-- Sub title -->
								<div class="tjs-section__description col-md-7 mx-auto" *ngIf="section?.square_old_card_desc?.content">
									<p [id]="section?.square_old_card_desc?.id" class="bk-sub-title mb-0" [innerHTML]="desc | safeHtml"></p>
								</div>
							</div>
						</ng-container>
						<!-- Other payment gateway -->
						<ng-template #otherGateway>
							<div class="tjs-section__title text-center mb-30" *ngIf="section?.old_card_title?.content || section?.old_card_desc?.content">
								<!-- Title -->
								<div class="tjs-section__heading mb-15" *ngIf="section?.old_card_title?.content">
									<h4 [id]="section?.old_card_title?.id" class="fw-bold display-6 bk-title" [innerHTML]="title | safeHtml"></h4>
								</div>
								<!-- Sub title -->
								<div class="tjs-section__description col-md-7 mx-auto" *ngIf="section?.old_card_desc?.content">
									<p [id]="section?.old_card_desc?.id" class="bk-sub-title mb-0" [innerHTML]="desc | safeHtml"></p>
								</div>
							</div>
						</ng-template>
					</ng-template>
					<div class="col-md-9 col-lg-7 col-xl-6 col-xxl-5 mx-sm-auto">
						<div [ngClass]="{'mb-35': !authWithNewCard && initServ.paymentGateway != 'square' }">
							<ng-container *ngIf="authWithNewCard; else oldCardCvv">
								<form [formGroup]="newCardForm">
									<bk-payment-gateway [billingAddr]="true" [billingAddrForm]="newCardForm" [section]="section" [locationId]="paymentLog?.location" [baseLoc]="true" [hideLabel]="true"></bk-payment-gateway>
								</form>
							</ng-container>
							<!-- Cvv card -->
							<ng-template #oldCardCvv>
								<ng-container *ngIf="initServ.paymentGateway != 'square'">
									<div class="position-relative col-sm-6 mx-auto">
										<div class="mb-20" id="payment_details">
											<div id="card-cvc" class="form-control stripe-account"></div>
											<div id="card-errors" class="tjs-error bk-error text-start"></div>
										</div>
										<!-- loader -->
										<bk-app-loader [loaderId]="cvvLoader"></bk-app-loader>
									</div>
									<!-- Image -->
									<img loading="auto" [src]="initServ.img?.PaymentCard" width="398" height="30" class="img-fluid bk-payment-card-img" alt="SSL Card">
								</ng-container>
							</ng-template>
						</div>
						<div class="d-flex justify-content-center flex-column-reverse flex-sm-row mt-20">
							<ng-container *ngIf="authWithNewCard; else authWithOldCardBtn">
								<!-- Cancel -->
								<bk-navigate *ngIf="section?.cancel_btn" [secId]="section?.cancel_btn?.id" [designId]="section?.cancel_btn?.design_id" [innerHTML]="section?.cancel_btn?.content" class="d-sm-inline-block d-block" customClass="btn btn-secondary w-100" essentialClass="bk-form-btn" (callback)="changeCardLayout(); addClickEventForNewCard()"></bk-navigate>
								<!-- Reauthorize -->
								<bk-navigate *ngIf="section?.add_authorize_btn" [secId]="section?.add_authorize_btn?.id" [designId]="section?.add_authorize_btn?.design_id" [innerHTML]="section?.add_authorize_btn?.content" class="d-sm-inline-block d-block" customClass="btn btn-success w-100" essentialClass="bk-form-btn ms-sm-20 mb-sm-0 mb-20" (callback)="reAuthCard()"></bk-navigate>
							</ng-container>
							<ng-template #authWithOldCardBtn>
								<!-- Reauthorize -->
								<bk-navigate *ngIf="section?.authorize_btn" [secId]="section?.authorize_btn?.id" [designId]="section?.authorize_btn?.design_id" [innerHTML]="section?.authorize_btn?.content" class="d-sm-inline-block d-block" customClass="btn btn-success w-100" essentialClass="bk-form-btn" (callback)="reAuthCard()"></bk-navigate>
							</ng-template>
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>
