import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { client,xml } from '@xmpp/client';
import { InitServ,UtilServ,APIURL } from './index';

@Injectable()
export class EjabService {

	public XMPP : any = null;
	public ckProgress : any = null;
	public ckNotes : any = null;
	public taskNotes : any = null;
	public subtaskNotes : any = null;
	public note : any = null;
	public currentUser : any = null;
	private _noteIds : any = [];
	public ckProgressRender : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public ckNoteRender : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(private initServ: InitServ,private utilServ:UtilServ,private APIURL: APIURL) {
		// get the logged in user's details
		this.currentUser = this.utilServ.appLocalStorage();
	}

  /**
	 * Function to connect admin to XMPP server / Ejabberd
	 */
	public connectEjab = () => {
		// get the admin settings
		const admnStngs : any = this.initServ.appAdmnStngs;
		// create the username by combining merchant id and user id
		const username = `${admnStngs?.merchant_id}_${this.currentUser?.id}@${this.APIURL['ejabDomain']}`;
		// check if domain is not empty and XMPP connection is not already established
		if (this.utilServ.isEmpty(this.XMPP)) {
			// create a new XMPP instance
			this.XMPP = client({
				service: this.APIURL['ejabSocketURL'],
				domain: this.APIURL['ejabDomain'],
				username,
				password: username,
			});
			// connect to XMPP server
			this.XMPP.on('connect', () => {
				// console.log('EJABBERD - CONNECTED');
			});
			// handle any errors during connection
			this.XMPP.on('error', () => {
				// console.error('err', err);
				//disconnect the XMPP server
				this.disconnectEjab();
			});
			// handle online status event
			this.XMPP.on('online', () => {
				this.XMPP.send(xml('presence', { type: 'available' }, xml('show', {}, 'chat')));
			});
			// handle incoming messages
			this.XMPP.on('stanza', (stanza:any) => {
				if (stanza.is('message') && (stanza.attrs.type === 'chat')) {
					const data = JSON.parse(stanza.getChildText('body'));
					// console.log(`NEW MESSAGE - ${data?.trigger}`,JSON.stringify(data));
					this.handleTrigger(data);
				}
			});
			// check if XMPP connection is already online
			if (this.XMPP.status === 'online') {
				// console.log('Already online');
				return;
			}
			// start the XMPP connection
			this.XMPP.start().catch(console.error);
		}
	}

	/**
	 * @desc Function to handle Ejab trigger
	 * @param {object} data - The data to be processed by the function
	 */
	public handleTrigger(data:any){
		// Make a copy of the data to prevent mutation of the original object
		const copiedData = this.utilServ.getObjCopy(data);
		// Switch statement to handle various trigger types
		switch(data?.trigger){
			// If trigger type is checklist, update progress via ejabActions.updateCKProgress
			case 'checklist':
				this.ckProgress = copiedData;
				this.ckProgressRender.next(true);
				break;

			// If trigger type is note, save note via saveNote function
			case 'note':
				this.saveNote(copiedData);
				break;
		}
	}

	/**Function to get notes */
	public getNotes(type:string):any {
		switch (type) {
			case 'checklist':
				return this.ckNotes ? this.utilServ.getObjCopy(this.ckNotes) : {};
			case 'task':
				return this.taskNotes ? this.utilServ.getObjCopy(this.taskNotes) : {};
			case 'subtask':
				return this.subtaskNotes ? this.utilServ.getObjCopy(this.subtaskNotes) : {};
		}
	}

	/**
	 * Function to save note data
	 * @param {*} note - Object containing the note data
	 */
	public saveNote(note:any){
		// Only process if note type is customer and event is not delete
		if (note?.type === 'customer') {
			if (note?.event !== 'delete'){
				// Add note to corresponding parent type
				const notes = this.addNote(this.getNotes(note?.parent_type), note);
				this.updateCkNotes(note?.parent_type,notes);
			}
			// Set current note to new note
			this.note = note;
			// Update note rendering for the checklist
			this.ckNoteRender.next(true);
		}
	}

	/**
	 * Function to add a new note to the notes object
	 * @param notes - The current notes object
	 * @param note - The new note to add to the object, with optional booking_id, parent_id, and _id properties
	 * @return - The updated notes object
	 */
	public addNote(notes:any, note:any) {
		const { booking_id, parent_id,_id } = note || {};
		// If the note does not have a booking_id property, return the original notes object
		if (!booking_id) { return notes; }
		// If a booking_id key does not exist in the notes object, create it
		notes[booking_id] = notes[booking_id] || {};
		if(!this._noteIds.includes(_id)){
			this._noteIds.push(_id);
			// If a parent_id key does not exist in the booking_id object, create it as an empty array
			notes[booking_id][parent_id] = (notes[booking_id][parent_id] ? notes[booking_id][parent_id] : 0) + 1;
		}
		// Return the updated notes object
		return notes;
	}

	/**
	 * Function to reset note
	 */
	public resetNote() {
		this.note = null;
		this.ckNoteRender.next(true);
	}

	/**
	 * Function to reset note count
	 * @param {*} note - the note object to remove
	 * @param {*} isRenderChecklist - boolean indicating whether to render checklist
	 */
	public resetNoteCount(note:any, isRenderChecklist?:boolean):void {
		// get all notes of the parent type and remove the note object
		const notes = this.removeNote(this.getNotes(note?.parent_type), note);
		// update the corresponding notes array based on the parent type of the note
		this.updateCkNotes(note?.parent_type,notes);
		if(isRenderChecklist){
			// Update note rendering for the checklist
			this.ckProgressRender.next(true);
		}
	}

	/**Function to remove note from notes array */
	public removeNote(notes:any, note:any) {
		if (notes?.[note?.booking_id]?.[note?.parent_id] > 0){
			notes[note?.booking_id][note?.parent_id] = 0;
		}
		return notes;
	}

	/**Function to disconnect Ejabberd */
	public disconnectEjab(){
		if (!this.utilServ.isEmpty(this.XMPP)){
			// console.log('EJABBERD - DISCONNECTED');
			this.XMPP.stop().catch(console.error);
			this.XMPP = null;
		}
	}

	/**Function to updated checklist notes */
	public updateCkNotes(type:string, notes:any){
		if(type === 'checklist'){
			this.ckNotes = notes; // update the checklist notes array
		} else if(type === 'task'){
			this.taskNotes = notes; // update the task notes array
		} else if(type === 'subtask'){
			this.subtaskNotes = notes; // update the subtask notes array
		}
	}
}
