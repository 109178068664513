import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Self, ViewEncapsulation } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";
// Services
import { ApiServ, CacheService, LoaderServ, NgOnDestroy, UtilServ } from '../../../../../Services';

@Component({
	selector: 'bk-view-provider-reviews-popup',
	templateUrl: './ViewProviderReviewsPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})

export class ViewProviderReviewsPopupComponent implements OnInit {
	selectedProv: any;
	reviews: any;
	customerDetail: string = '';
	limit: number = 5;
	totalRecords: number = 0;
	isReadmore: any = {};
	loaderId: string = 'provider-reviews-loader';
	//
	secId: string = 'view-review-popup';
	slug: string = 'view_provider_reviews';
	section: any = { load_more_btn: null, profile: null, listing: null };

	constructor(public dialogRef: MatDialogRef<ViewProviderReviewsPopupComponent>, public utilServ: UtilServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, private loader: LoaderServ, private cacheServ: CacheService, private cDRef: ChangeDetectorRef) { }

	ngOnInit(): void {
		this.buildSectionData();
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
			// detect changes
			this.cDRef.detectChanges();
		});
	}
	/**
	 * Get the customer last name
	 * @param lastName customer last name
	 * @returns
	 */
	public customerLastName(lastName: string): string {
		if (this.customerDetail == 'first_name_last_initial') {
			if (lastName) {
				return lastName.charAt(0);
			}
		}
		return lastName;
	}
	/**
	 * Get more reviews on click of load more link
	 */
	public loadMoreReviews(): void {
		this.limit += 5;
		this.getReviews();
	}
	/**
	 * Get the reviews
	 */
	private getReviews(): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId, this.dialogRef);
		let queryParams = { limit: this.limit };
		this.apiServ.callApiWithPathQueryVars('GET', 'ProvReviews', [this.selectedProv.id], queryParams).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res));
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res: any): void {
		if (this.apiServ.checkAPIRes(res) && res.data) {
			this.reviews = res.data;
			if (res.total_record) {
				this.totalRecords = res.total_record;
			}
			this.scrollTo()
		}
		this.loader.hide(this.loaderId, this.dialogRef);
		this.cDRef.detectChanges();
	}
	/**
	 * Method is used to scroll the popup, when new review list are rendered in html and get the last review from
	 * the html and scroll to the first list of the latest review.
	 */
	private scrollTo(): void {
		setTimeout(() => {
			let index = this.limit - 5;
			let reviewContainer: HTMLElement | null = document.getElementById('review-section');
			let reviewList: HTMLElement | null = document.getElementById(`review_list_${index}`);
			//
			if (reviewContainer && reviewList) {
				reviewContainer.scroll({
					top: reviewList?.offsetTop - 50,
					behavior: 'smooth'
				});
			}
		}, 100)
	}
}
