import { Component, OnInit, Input} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// Service
import { UtilServ, InitServ } from '../../../Services';
@Component({
	selector: 'BookingAddedSuccessfullyMsg',
	template: `<div class="tjs-popup">
		<div class="tjs-popup__content text-center">
			<div class="conf-message">
				<h5>{{'Congratulations your booking has been completed! Please' | translate}} <a href="{{link}}" target="_parent" translate>click here</a> {{'to sign in to your account so you can make any changes to future bookings.' | translate}}</h5>
			</div>
		</div>
	</div>`
})
export class BookingAddedSuccessfullyMsgComponent implements OnInit {
	@Input() data : any;
	link : any;
	currentUser : any;
	constructor(public utilServ: UtilServ, public dialogRef: MatDialogRef<BookingAddedSuccessfullyMsgComponent>, private initServ: InitServ) {
		dialogRef.disableClose = true;
		document.body.style.overflow = 'hidden';
	}
	ngOnInit() {
		try{
			this.currentUser = this.utilServ.appLocalStorage();
		}catch{}
		// If user exist
		if(this.currentUser){
			this.link = window.location.protocol + "//" + window.location.hostname+"/dashboard"
		}else{
			this.link = window.location.protocol + "//" + window.location.hostname+"/"+this.initServ.appDynamicRoutes['reset-password']+"?token="+this.data.response.password_reset_token+"&first=true";
		}
	}
}
