import { Injectable } from '@angular/core';
declare var window: any;
declare var FB: any;
// Services
import { InitServ,UtilServ } from './index';
// Constants
import { TWEET_LINK, IS_DEV, FACEBOOK_SDK_LINK, TWITTER_PLATFORM_LINK } from '../Constants';

@Injectable({
	providedIn: 'root'
})
export class SocialServ {
	// private variables
	private admnStngs: any;

	constructor(private initServ: InitServ,private utilServ: UtilServ) {
		this.admnStngs = this.initServ.appAdmnStngs; // App admin settings
	}
	/**
	 * FB initialize
	 */
	public fbInit(fbAppId: any): void {
		// initialize facebook sdk after it loads if required
		if (!window['fbAsyncInit']) {
			window['fbAsyncInit'] = function () {
				window['FB'].init({ appId: +fbAppId, cookie: true, autoLogAppEvents: true,xfbml: true, version: 'v3.0'});
				window['FB'].AppEvents.logPageView();
			};
		}
		// load facebook sdk if required
		if (!document.querySelector(`script[src='${FACEBOOK_SDK_LINK}']`)) {
			let script = document.createElement('script');
			script.id = 'facebook-jssdk';
			script.src = FACEBOOK_SDK_LINK;
			document.body.appendChild(script);
		}
	}
	/**
	 * Twitter initialize
	 */
	public twitterInit(): void {
		// initialize twitter sdk after it loads if required
		let ngJs: any;
		const ngFjs:any = document.getElementsByTagName('script')[0];
		// load twitter sdk if required
		if (!document.getElementById('twitter-wjs')) {
			ngJs = document.createElement('script');
			ngJs.id = 'twitter-wjs';
			ngJs.src = TWITTER_PLATFORM_LINK
			ngFjs.parentNode.insertBefore(ngJs, ngFjs);
		}
	}
	/**
	 * Post on facebook
	 */
	public postOnFacebook(fbAppId: any, url: string, isClick:boolean = false, type: string = ''): void {
		let _this = this;
		if(fbAppId){
			FB.ui({
				display: 'popup', method: 'share', href: url,
			}, function (res: any) {
				if (isClick && typeof res != 'undefined'){
					if(type == 'booking'){
						let couponBtn:any = document.querySelector('.show-fb-coupon-btn');
						_this.utilServ.triggerEvnt(couponBtn,'click');
					} else {
						let fbCredit:any = document.querySelector('.fb-credit');
						_this.utilServ.triggerEvnt(fbCredit,'click');
					}
				} else {
					if(IS_DEV){console.log('FB post fail.');}
				}
			});
		}
	}
	/**
	 * Tweet on twitter
	 */
	public tweetOnTwitter(shareUrl: string, shareText: string = '', defReferralAmt: any = 0, overrideDesc: string= '', isClick:boolean = false): void {
		let sharedText: string = '';
		if(shareText){
			sharedText = shareText;
		} else {
			sharedText = this.initServ.appStr.text.joinWith + this.admnStngs?.merchant_settings?.store?.store_currency+defReferralAmt + " "+ this.initServ.appStr.text.withMyLink;
		}
		if (overrideDesc) { sharedText = overrideDesc; }
		let url = TWEET_LINK + shareUrl + '&text=' + sharedText + '&original_referer=' + shareUrl;
		let x = screen.width/2 - 700/2;
		let y = screen.height/2 - 450/2;
		let child = window.open(url, "popupWindow", "dialog=yes, close=0, width=600, height=400,left="+x+",top="+y);
		child.focus();
		if(isClick){
			let _this = this;
			let timer = setInterval(function(){
				if(child.closed){
					let twitterCredit:any = document.querySelector('.twitter-credit');
					_this.utilServ.triggerEvnt(twitterCredit,'click');
					clearInterval(timer);
				}
			}, 1);
		}
	}
}