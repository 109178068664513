/* eslint-disable complexity */
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
// Services
import { BkngFormServ, InitServ, LoaderServ, NgOnDestroy, UtilServ, BuildCustomSectionService } from '../../../Services';

@Component({
	selector: 'bk-pricing-parameter-with-area',
	templateUrl: './PricingParameterWithArea.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class PricingParameterWithAreaComponent implements OnInit {

	// Variables
	@Input() isQuoteEditable: boolean = true;
	@Input() bookingType: string = '';
	@Input() section: any;
	@Input() prefilledData: any;
	@Input() prefilledAreaParam: any;
	@Input() prefilledExcludes: any;
	@Input() availableSett: any;
	@Input() areaParamForm!: FormGroup;
	@Input() settings: any;
	@Input() selectedLocation: any;
	@Input() selectedServiceType: any;
	@Input() selectedFrequency: any;
	@Input() isMultiStepForm: any;
	@Input() pageSett: any;
	@Input() isCustomerAllowedRes: any;
	@Output() areaUnitChange: EventEmitter<any> = new EventEmitter();
	@Output() areaParamsStatus: EventEmitter<boolean> = new EventEmitter();
	areaParams: any;
	isDisabled: any;
	selectedArea: any;
	selectedParam: any;
	areaFormValue: any;
	isChange: boolean = false;
	loaderId: string = 'bkng-area-params';

	// eslint-disable-next-line max-params
	constructor(private bkngFormServ: BkngFormServ, public utilServ: UtilServ, public initServ: InitServ, private loader: LoaderServ, private cDRef: ChangeDetectorRef, private buildCustSecServ: BuildCustomSectionService) { }

	ngOnInit(): void {
		this.buildPricingParams();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange) {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev){
						this.buildPricingParams();
						break;
					}
				}
			}
		}
	}

	// convenience getter for easy access to form fields
	get areaParamGroup() {
		return <FormGroup>this.areaParamForm.controls['area_parameter'];
	}
	/**
	 * Check the visibility and build the pricing parameter array
	 */
	public buildPricingParams(): void {
		this.loader.show(this.loaderId);
		this.areaParams = [];
		this.buildCustSecServ.availableElems['variables'] = [];
		this.areaFormValue = this.areaParamGroup.value;
		if(this.settings && this.settings.pricing_parameters && (this.settings.pricing_parameters).length > 0){
			for(let pricingParam of this.settings.pricing_parameters){
				if(pricingParam.value && (pricingParam.value).length > 0){
					let obj: any = {
						id: pricingParam.id,
						name: pricingParam.name,
						cat_name: this.bkngFormServ.getPricingParamCatName(pricingParam?.name, this.settings),
						value: [],
						selected_value:null
					}
					for(let section of pricingParam.value){
						if(this.bkngFormServ.pricingParamsValueVisible(section, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledAreaParam, this.availableSett)){
							(obj.value).push(section);
						}
						// Form pricing_parameter exist, set the selected value
						if(this.areaFormValue && this.areaFormValue.id == section.id){
							obj.selected_value = section.id;
							this.selectedArea = this.areaParamGroup.controls['id'].value;
							this.selectedParam = section;
						}
					}
					if(obj && (obj.value).length > 0){
						this.areaParams.push(obj);
						this.buildCustSecServ.availableElems['variables'].push(pricingParam.id);
					}
				}
			}
		}
		this.isDisabled = this.bkngFormServ.disabledParamScope(this.bookingType, this.prefilledData, this.selectedServiceType, this.isQuoteEditable, false, 'area_parameter', this.isCustomerAllowedRes);
		if(!this.prefilledData && !this.areaParamGroup.controls['id'].value && this.areaParams && (this.areaParams).length > 0){
			if(this.areaParams[0] && this.areaParams[0]['value'] && (this.areaParams[0]['value']).length > 0){
				for(let param of this.areaParams[0]['value']){
					if(param.default){
						this.setControl(param);
						break;
					}
				}
			}
		}
		if(!this.areaParamGroup.controls['id'].value && this.areaParams && (this.areaParams).length > 0){
			if(this.areaParams[0] && this.areaParams[0]['value'] && (this.areaParams[0]['value']).length > 0){
				let param = this.areaParams[0]['value'][0];
				if(param){
					this.setControl(param);
				}
			}
		}
		let status: boolean = this.areaParams.length > 0 ? true : false;
		this.areaParamsStatus.emit(status);
		this.areaUnitChange.emit();
		this.cDRef.detectChanges();
		this.loader.hide(this.loaderId);
	}
	/**
	 * Function to set the control
	 * @param param
	 */
	private setControl(param : any): void {
		this.areaParamGroup.patchValue({
			id: param.id,
			name: param.name
		});
		this.selectedArea = param.id;
		this.selectedParam = param;
	}
	/**
	 * Area value focus out, emit the value
	 */
	public areaValueChange(): void {
		this.isChange = true;
		this.areaUnitChange.emit();
	}
	/**
	 * Change area params change
	 * @param event: input event
	 * @param areaParam: selected area parameter
	 */
	public changeAreaUnit(event: any, areaParam: any): void {
		this.isChange = true;
		// Set the form control value
		let quantity: any = +(event.target.value);
		if(areaParam.value && (areaParam.value).length > 0){
			for(let param of areaParam.value){
				if(param.id == quantity){
					this.areaParamGroup.patchValue({
						id: param.id,
						name: param.name
					});
					this.bkngFormServ.formParamMsgPopup(param);
					this.selectedParam = param;
				}
			}
		}
		this.areaUnitChange.emit();
	}
	/**
	 * Refresh the component by parent
	 */
	public refresh(): void{
		this.cDRef.detectChanges();
		/**To solve the live issue, when on service change area param remain blank **/
		this.buildPricingParams();
	}
}
