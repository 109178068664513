import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { UtilServ } from './index';

@Injectable()
export class ChecklistService {

	public conditions : any = {};
	public validationFuncRef : any = null;
	public validatorsArray : any = [];

	//Validation regular expression
	public REGEX : any = {
		"specialchar":/^[^<>\\";%{}@#$:]*$/,
		"url":/^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
		"email":/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/,
		"alphanumeric":/^\w+$/,
		"numeric":/^\d+$/,
		"alphabetic":/^[A-Za-z ]+$/,
		"textarea":/^[^<>\\";]*$/,
		"camelcase":/^([a-z]+)(([A-Z]([a-z]+))+)$/,
		"number":/^[0-9\b]+$/,
		"emailarray":/^((\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)*([,])*)*$/,
		"phone":/^\d{10,10}(,\d{10,10})*$/,
	}

	constructor(private utilServ:UtilServ) {}

	public handleConditions(tasks:any){
		if(this.conditions){
			Object.values(this.conditions).forEach((condition:any) => {
				if (condition?.length > 0) {
					condition.forEach((data:any) => {
						if (data.status == 1 && (data?.terms?.length > 0)) {
							this.handleTermAction(data, tasks);
						}
					});
				}
			});
		}
	}

	//function to handle conditions terms/actions
	// eslint-disable-next-line complexity
	public handleTermAction(condition:any,tasks:any){
		const termStatus = condition?.terms?.map((term:any) => {
			const { value } = this.getValue(term?.subtask, tasks);
			const termValue = (term.target === 'field') ? this.getValue(term?.value, tasks)?.value : term?.value;
			if (Array.isArray(value)) {
				if (value?.length > 0) {
					return value.some((val:any) => this.operatorCases(term?.operator, termValue, val))
				}
				return this.operatorCases(term?.operator, termValue, '');
			}
			return this.operatorCases(term?.operator, termValue, value);
		});
		if (condition?.actions?.length > 0){
			for (const action of condition.actions) {
				let toggleStatus = false; // Initialize toggleStatus to false
				// Check the condition and termStatus to determine toggleStatus
				if (condition.link === 'any' && termStatus.includes(true)) {
					toggleStatus = true; // If any termStatus is true, set toggleStatus to true
				} else if (condition.link === 'all' && !termStatus.includes(false)) {
					toggleStatus = true; // If all termStatus are not false, set toggleStatus to true
				}
				if (action.type === 'hide') {
					toggleStatus = !toggleStatus; // Toggle the toggleStatus if the action type is 'hide'
				}
				// Toggle fields based on the toggleStatus
				if (action.tasks) {
					this.toggleFields({ data: action.tasks, status: toggleStatus, type: 'task' });
				}
				if (action.subtasks) {
					this.toggleFields({ data: action.subtasks, status: toggleStatus, type: 'subtask' });
				}
			}
		}
	}

	//function to get field value
	public getValue(name:any,tasks:any){
		const subtaskEl:any = document.querySelector(`#subtask_${name}`);
		const taskIndex:number = subtaskEl?.dataset?.taskindex;
		const subtaskIndex = subtaskEl?.dataset?.subtaskindex;
		return subtaskEl ? {
			value : tasks?.[taskIndex]?.subtasks?.[subtaskIndex]?.value,
			name : `tasks.${taskIndex}.subtasks.${subtaskIndex}.value`
		} : {};
	}

	//Function to toggle tasks
	public toggleFields({data,status,type}:any){
		if(data?.length >0){
			for(const id of data){
				const taskEl : any = document.querySelector(`#${type}_${id}`);
				if(taskEl){
					taskEl.style.display = status ? '' : 'none';
				}
			}
		}
	}

	//Function to check operator cases
	// eslint-disable-next-line complexity
	public operatorCases(operator:any, termVal:any, value:any): boolean {
		if (operator === "is_filled") {
			return Boolean(!this.utilServ.isEmpty(value));
		} else if (operator === "is_empty") {
			return Boolean(this.utilServ.isEmpty(value));
		} else if (operator === "equal") {
			return Boolean(value == termVal)
		} else if (operator === "not_equal") {
			return Boolean(value != termVal);
		} else if (operator === "contains") {
			return Boolean(value?.includes(termVal));
		} else if (operator === "not_contain") {
			return Boolean(!(value?.includes(termVal)));
		} else if (operator === "starts_with") {
			return Boolean(value?.startsWith(termVal));
		} else if (operator === "not_start_with") {
			return Boolean(value && !value.startsWith(termVal));
		} else if (operator === "not_end_with") {
			return Boolean(value && !value.endsWith(termVal));
		} else if (operator === "end_with") {
			return Boolean(value?.endsWith(termVal));
		} else if (operator === "greater_than") {
			return Boolean(value > termVal);
		} else if (operator === "less_than") {
			return Boolean(value < termVal);
		} else {
			return false;
		}
	}


	/**
	 * Function to add the validations on fields according to options
	 * @param {*} subtask
	 * @returns
	 */
	// eslint-disable-next-line complexity
	public addValidations(subtask:any) {
		const validators = [];
		if (subtask?.is_charlimit === "yes") {
			validators.push(Validators.maxLength(subtask.char_limit));
		}
		if (subtask?.validation && subtask.validation !== "none") {
			validators.push(Validators.pattern(this.REGEX[subtask.validation]));
		}
		if (subtask?.is_required === "yes") {
			validators.push(Validators.required);
		}
		switch (subtask.type) {
			case "email":
				validators.push(Validators.pattern(this.REGEX['email']));
				break;
			case "text_input":
				validators.push(Validators.pattern(this.REGEX['specialchar']));
				break;
			case "multi_line_input":
				if (subtask.validation === "none" && subtask.editor_type !== "editor") {
					validators.push(Validators.pattern(this.REGEX['textarea']));
				}
				break;
			default:
				break;
		}
		return validators;
	}
}
