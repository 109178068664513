<div class="tjs-popup tjs-popup--sm position-relative min-height--150" [id]="secId">
	<!-- Popup Header -->
	<bk-popup-header *ngIf="section?.title" [headingId]="section?.title?.id" [heading]="section?.title?.content" [isClose]="false"></bk-popup-header>
	<!-- Content -->
	<div [id]="section?.form?.id" class="tjs-popup__content tjs-popup__body" [ngClass]="{'text-center pb-sm-30 pt-20 tjs-popup__content--height' : (!isGiveOptions || !isUpcomingBookings)}">
		<ng-container *ngIf="(isGiveOptions && isUpcomingBookings); else msgBlock">
			<form [formGroup]="defaultCardForm" *ngIf="section?.form">
				<bk-card-apply [form]="defaultCardForm" [id]="3" [isBookingsWithCash]="bookingOptions?.is_bookings_with_cash" [section]="section?.form"></bk-card-apply>
			</form>
		</ng-container>
		<ng-template #msgBlock>
			<div class="bk-media">
				<img loading="auto" *ngIf="section?.media" [id]="section?.media?.id" class="img-fluid mb-30" alt="alert" [src]="section?.media?.urls[0]" width="100" height="100">
			</div>
			<p class="mb-0 fs-20" [innerHTML]="section?.form?.make_default_msg | safeHtml"></p>
		</ng-template>
	</div>
	<!-- Submit button -->
	<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse bk-footColor">
		<bk-navigate [secId]="section?.make_default_btn?.id" [designId]="section?.make_default_btn?.design_id" [innerHTML]="section?.make_default_btn?.content" essentialClass="w-100" customClass="btn btn-primary rounded-5 w-100" class="d-block w-100 mb-15 mb-sm-5" (callback)="makeCardDefault()"></bk-navigate>
		<bk-navigate [secId]="section?.cancel_btn?.id" [designId]="section?.cancel_btn?.design_id" [innerHTML]="section?.cancel_btn?.content" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" class="d-block w-100 mb-5" (callback)="dialogRef.close()"></bk-navigate>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
