<div [id]="secId" *ngIf="rcServ && pageSett && pageSett[secId] && pageSett[secId][rcServ.statusType] && section" class="tjs-section py-60 {{variationClass[pageSett[secId]?.variation_id]}}">
	<div class="container">
		<!-- Title -->
		<div class="tjs-section__title mb-30" *ngIf="section?.title && section?.title?.content">
			<div class="tjs-section__heading text-center">
				<h3 [id]="section?.title?.id" class="display-5 fw-bold bk-title" [innerHTML]="section?.title?.content | safeHtml"></h3>
			</div>
		</div>
		<!-- List -->
		<div [id]="section?.list?.id" class="row bk-list justify-content-center" *ngIf="section?.list && section?.list?.items && (section?.list?.items).length > 0">
			<ng-container *ngFor="let item of section?.list?.items let i=index;trackBy: secServ.trackByFnIndex">
				<div [id]="item?.id" class="col-12 col-sm-12 col-md-4 my-20 bk-item" *ngIf="(item?.media && (item?.media).length>0) || item?.title || item?.desc">
					<div class="tjs-grid__inner h-100 py-md-30 py-20">
						<!-- Image -->
						<div *ngIf="item?.media && (item?.media).length>0">
							<div class="tjs-grid__img position-relative mx-auto">
								<img loading="auto" [id]="item?.media_id" class="img-fluid rounded bk-item-media" [src]="item?.media[0]" width="640" height="480" alt="bk-image" />
							</div>
						</div>
						<div class="tjs-grid__content pt-30 pt-md-50 px-15 text-center" *ngIf="item?.title || item?.desc">
							<!-- Title -->
							<h5 [id]="item?.title_id" *ngIf="item?.title" class="fs-20 mb-20 fw-bold bk-item-title" [innerHTML]="item?.title | safeHtml"></h5>
							<!-- Content -->
							<p [id]="item?.desc_id" class="mb-0 bk-item-desc" *ngIf="item?.desc" [innerHTML]="item?.desc | safeHtml"></p>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
