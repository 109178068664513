<div class="row" [id]="section?.id" [formGroup]="frequencyForm" *ngIf="settings && section && frequencies && frequencies.length > 1">
	<div [ngClass]="variationId == 'bk_short_form_V3' ? 'col-md-12 mb-20' : 'col-md-6 full-wid mb-20 mb-md-30'">
		<div [ngClass]="{'card-body d-md-flex justify-content-between shadow-sm rounded bg-white align-items-center p-20 px-md-15 py-md-10' : variationId == 'bk_short_form_V4'}">
			<label *ngIf="section?.freq_label" [id]="sectionIds?.label" class="form-label me-sm-15 me-10 bk-form-label" [ngClass]="{'mb-10 mb-md-0 me-md-10 flex-shrink-0' : variationId == 'bk_short_form_V4'}" [innerHTML]="section?.freq_label | safeHtml"></label>
			<div [ngClass]="{'w-100' : variationId == 'bk_short_form_V4', 'd-flex align-items-center': (selectedFrequency && selectedFrequency?.form_frequency_data && selectedFrequency?.form_frequency_data?.show_tooltip_icon && selectedFrequency?.form_frequency_data?.tooltip_text)}">
				<select class="form-select pe-35 fs-15 bk-select" [ngClass]="{'form-select-sm tjs-border-md-0 shadow-none':variationId == 'bk_short_form_V4'}" [(ngModel)]="selectedFrequency" [ngModelOptions]="{standalone: true}" (ngModelChange)="getFrequency($event)" [attr.disabled]="isDisabled">
					<ng-container *ngFor="let freq of frequencies;trackBy: trackById">
						<option class="bk-select-option" [ngValue]="freq">{{(utilServ.getFrequencyName(freq)) | translate}}</option>
					</ng-container>
				</select>
				<bk-tooltip *ngIf="selectedFrequency && selectedFrequency?.form_frequency_data && selectedFrequency?.form_frequency_data?.show_tooltip_icon && selectedFrequency?.form_frequency_data?.tooltip_text" class="bk-tooltip" content="{{selectedFrequency?.form_frequency_data?.tooltip_text | translate}}"></bk-tooltip>
			</div>
		</div>
	</div>
</div>