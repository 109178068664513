<span #provContainer class="auto-complete">
	<input class="keyword form-control" attr.placeholder="{{'Search By Name, Email or Phone' | translate}}" (keyup)="getProviders($event)" (focusout)="hideList($event)" [(ngModel)]="keyword" />
	<ul *ngIf="dropdownVisible" class="ps-0 mb-0 list-unstyled tjs-auto-list position-relative rounded-bottom bg-white">
		<ng-container *ngIf="providers && providers.length > 0; else noProv">
			<li class="d-flex tjs-transition--base border-bottom align-items-center px-15 py-10 tjs-pointer" *ngFor="let prov of providers; let i=index" (mousedown)="selectOne(prov)"  [ngClass]="{selected: i === itemIndex}">
				<!-- Avatar -->
				<div class="flex-shrink-0" *ngIf="utilServ.userNameSett('photo')">
					<bk-user-img customClass="rounded img-fluid-disabled" [imgSrc]="prov?.photo_url" [isParentMedia]="true" alt="user image" height="60" width="60"></bk-user-img>
				</div>
				<div class="flex-grow-1 ms-15 text-capitalize fs-14" *ngIf="utilServ.userNameSett('firstname') || utilServ.userNameSett('lastname') || utilServ.userNameSett('last_initial')">
					<ng-container *ngIf="utilServ.userNameSett('firstname')">{{prov?.first_name}}&nbsp;</ng-container>
					<ng-container *ngIf="utilServ.userNameSett('lastname')">{{prov?.last_name}}</ng-container>
					<ng-container *ngIf="!utilServ.userNameSett('lastname') && utilServ.userNameSett('last_initial')">{{utilServ.nameLastInitial(prov?.last_name)}}</ng-container>
				</div>
			</li>
		</ng-container>
		<ng-template #noProv>
			<li class="d-flex align-items-center px-15 py-10 " (mousedown)="selectOne('')">
				<span translate>No Result Found</span>
			</li>
		</ng-template>
	</ul>
</span>