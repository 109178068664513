import { Injectable } from '@angular/core';
import { ApiServ, NgOnDestroy, UtilServ } from '../';
import { takeUntil } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class BuildCustomSectionService {
	// Variables
	customFields: any;
	customFieldsById: any = {};
	customFieldsbaedOnElems: any = {};
	customFieldSec: any = {};
	custSecData: any = {};
	custFieldChild: any = {};
	customFieldsWithMatchTypeAny: any = {};
	matchTypeCtrls: any = {
		packages: 'package_match_type',
		items: 'item_match_type',
		extras: 'extra_match_type',
		addons: 'addon_match_type'
	}
	dependentFieldsArr: Array<string> = ['frequencies', 'locations', 'packages', 'service_categories', 'items', 'extras', 'addons', 'variables']
	dependentFieldsKey: any = {
		location_id: 'locations',
		service_category: 'service_categories',
		frequency: 'frequencies',
		extras: 'extras',
		items: 'items',
		package: 'packages',
		pricing_parameters: 'variables',
		addons: 'addons'
	}
	availableElems: any = {
		extras: [],
		variables: [],
		items: [],
		packages: [],
		addons: []
	}
	custFieldsWithApplyToFirstOnly: Array<any> = [];

	custFieldsData: any;
	custFieldsBasedOnId: any = {};
	custFieldsBasedOnBkng: any;

	constructor(private util: UtilServ, private apiServ: ApiServ, private destroy: NgOnDestroy) {}
	/**
 	 * Builds the custom fields data.
 	 */
	public buildCustomFields(): void {
		this.resetCustomFieldsData();
		if(this.util.checkArrLength(this.customFields)){
			let custFieldChild: any = {};
			for(let group of this.customFields){
				// Creates a deep copy of the group object and adds it to custSecData using group's _id as key
				this.custSecData[group._id] = JSON.parse(JSON.stringify(group));
				this.custSecData[group._id].custom_fields = [];
				if(this.util.checkArrLength(group.custom_fields)){
					for(let field of group.custom_fields){
						// Creates a deep copy of the field object and adds it to customFieldsById using field's _id as key
						this.customFieldsById[field._id] = JSON.parse(JSON.stringify(field));
						custFieldChild = this.getCustFieldChild(field, custFieldChild);
						// Sets customFieldSec[field._id] to store the group._id that the field belongs to
						this.customFieldSec[field._id] = group._id;
						// Processes the field element in the customFields array
						this.processElemInCustomFields(field);
						this.setApplyToVal(field);
					}
				}
			}
			this.storeHierarchy(custFieldChild);
			this.updateApplyToVal();
		}
	}
	/**
	 * Sets the apply_to value for a custom field.
	 * If the field has apply_on_bookings set to 'first-only' and it is not already in the custFieldsWithApplyToFirstOnly array,
	 * it will be added to the array.
	 * @param {any} field - The custom field object to set the apply_to value for.
	 */
	private setApplyToVal(field: any): void {
		if(!this.custFieldsWithApplyToFirstOnly.includes(field._id) && field.apply_on_bookings == 'first-only'){
			this.custFieldsWithApplyToFirstOnly.push(field._id)
		}
	}
	/**
	 * Updates the apply_to value for custom fields that have apply_on_bookings set to 'first-only'.
	 */
	private updateApplyToVal(): void {
		if(this.util.checkArrLength(this.custFieldsWithApplyToFirstOnly)){
			// Loops through the custFieldsWithApplyToFirstOnly array and updates the apply_to value for each custom field's children.
			for(let id of this.custFieldsWithApplyToFirstOnly){
				if(this.util.objHasProp(this.custFieldChild, id) && this.util.checkArrLength(this.custFieldChild[id])){
					for(let childId of this.custFieldChild[id]){
						this.customFieldsById[childId].apply_on_bookings = 'first-only';
					}
				}
			}
		}
	}
	/**
	 * Adds a custom field's ID to its dependent field's child array in the custFieldChild object.
	 * @param {any} field - The custom field object to add to the custFieldChild object.
	 * @param {any} custFieldChild - The object that holds the child arrays for each custom field's dependent fields.
	 * @returns {any} - The updated custFieldChild object.
	 */
	private getCustFieldChild(field: any, custFieldChild: any): any {
		// If the custFieldChild object does not have a property for the custom field's ID, it will be added as an empty array.
		if(!this.util.objHasProp(custFieldChild, field._id)){
			custFieldChild[field._id] = [];
		}
		// If the custom field is dependent and has dependencies, it will loop through each dependency and add the custom field's ID to its child array.
		if(field.is_dependent && this.util.checkArrLength(field.depend_on)){
			for(let depField of field.depend_on){
				if(!this.util.objHasProp(custFieldChild, depField)){
					custFieldChild[depField] = [];
				}
				if(!(custFieldChild[depField]).includes(field._id)){
					custFieldChild[depField].push(field._id);
				}
			}
		}
		return custFieldChild;
	}
	/**
	 * Stores the hierarchy of custom fields and their dependent in the custFieldChild object.
	 * @param {any} custFieldChild - The object that holds the child arrays for each custom field's dependent fields.
	 */
	private storeHierarchy(custFieldChild: any): void {
		if(custFieldChild && this.util.checkArrLength(Object.keys(custFieldChild))){
			for(let id in custFieldChild){
				if(this.util.checkArrLength(custFieldChild[id])){
					if(!this.util.objHasProp(this.custFieldChild, id)){
						this.custFieldChild[id] = [];
					}
					this.custFieldChild[id] = this.processAllChild(this.custFieldChild[id], custFieldChild, id);
				}
			}
		}
	}
	/**
	 * Recursively processes all child elements of a given ID in a custom field hierarchy and returns an array of all parent IDs.
	 * @param {array} parentArr - The array of parent IDs to be returned.
	 * @param {any} custFieldChild - The custom field hierarchy object.
	 * @param {any} id - The ID of the parent element to be processed.
	 * @returns {any} - An array of all parent IDs in the custom field hierarchy.
	 */
	private processAllChild(parentArr: Array<any>, custFieldChild: any, id: any): any {
		if(this.util.checkArrLength(custFieldChild[id])){
			for(let childId of custFieldChild[id]){
				if(!parentArr.includes(childId)){
					parentArr.push(childId);
				}
				parentArr = this.processAllChild(parentArr, custFieldChild, childId);
			}
		}
		return parentArr;
	}
	/**
	 * Reset the data of custom fields.
	 */
	private resetCustomFieldsData(): void {
		this.customFieldsById = {};
		this.customFieldsWithMatchTypeAny = {};
		this.customFieldsbaedOnElems = {};
		this.customFieldSec = {};
		this.custSecData = {};
		this.custFieldChild = {};
		this.custFieldsWithApplyToFirstOnly = [];
	}
	/**
	 * Processes the element in the customFields array.
	 * @param {any} field - The field object to process.
	 */
	private processElemInCustomFields(field: any): void {
		if(this.util.checkArrLength(this.dependentFieldsArr)){
			for(let elem of this.dependentFieldsArr){
				if(this.util.objHasProp(field, elem)){
					this.customFieldsbaedOnElems = this.addPropInObj(this.customFieldsbaedOnElems, elem, {});
					if(this.util.checkArrLength(field[elem])){
						// eslint-disable-next-line max-depth
						for(let elemId of field[elem]){
							this.customFieldsbaedOnElems[elem] = this.addPropInObj(this.customFieldsbaedOnElems[elem], elemId, []);
							// eslint-disable-next-line max-depth
							if(!(this.customFieldsbaedOnElems[elem][elemId]).includes(field._id)){
								(this.customFieldsbaedOnElems[elem][elemId]).push(field._id);
							}
						}
					}
					this.storeElemWithMatchTypeAny(field, elem);
				}
			}
		}
	}
	/**
	 * Adds a property to an object if it doesn't already exist.
	 * @param {any} obj - The object to add the property to.
	 * @param {string} prop - The name of the property to add.
	 * @param {any} val - The value of the property to add.
	 */
	private addPropInObj(obj: any, prop: string, val: any): void {
		if(!this.util.objHasProp(obj, prop)){
			obj[prop] = val;
		}
		return obj;
	}
	/**
	 * Stores an element with match type 'any' in a custom field object.
	 * @param {any} field - The field object to check for the match type.
	 * @param {string} elem - The name of the element to check for the match type.
	 */
	private storeElemWithMatchTypeAny(field: any, elem: string): void {
		if(this.util.objHasProp(field, this.matchTypeCtrls[elem]) && field[this.matchTypeCtrls[elem]] == 'any'){
			if(!this.util.objHasProp(this.customFieldsWithMatchTypeAny, elem)){
				this.customFieldsWithMatchTypeAny[elem] = [];
			}
			this.customFieldsWithMatchTypeAny[elem].push(field._id);
		}
	}
	/**
	 * Input request data and filters out the relevant custom fields.
	 * It returns an array containing the filtered custom fields.
	 * @param {any} reqData - The input request data
	 * @returns {any} - The filtered custom fields array
	 */
	public getFilteredCustomFields(reqData: any, prefillData: any, isRescFirstLoad: boolean): any {
		let custoFields: Array<any> = [];
		// Get the custom fields array for the given required data
		let customFieldsArrPerElem: Array<any> = this.getCustomFieldsOfElem(reqData);
		if(this.util.checkArrLength(customFieldsArrPerElem)){
			let fields: any = this.getCustomFields(customFieldsArrPerElem, reqData, prefillData);
			fields = this.removeChild(fields);
			if(prefillData && isRescFirstLoad){
				fields = this.addSelCustFields(fields, prefillData.custom_fields);
			}
			if(this.util.checkArrLength(fields)){
				custoFields = this.generateCustomFieldsArr(fields, custoFields);
			}
		}
		return custoFields;
	}
	/**
	 * Removes child elements from an array of fields based on a custom.
	 * @param {array} fields - The array of fields to remove child elements from.
	 * @returns {array} - The updated array of fields with child elements removed.
	 */
	private removeChild(fields: Array<any>): Array<any> {
		if(this.custFieldChild && this.util.checkArrLength(Object.keys(this.custFieldChild))){
			for(let id in this.custFieldChild){
				if(!fields.includes(+id)){
					fields = fields.filter((elem: any) => !(this.custFieldChild[id]).includes(elem));
				}
			}
		}
		return fields;
	}
	/**
	 * Adds selected customer fields to an array of fields, based on the existing customer fields.
	 * @param {array} fields - The array of fields to add selected customer fields to.
	 * @param {any} existingCustFields - The existing customer fields to check against.
	 * @returns {array} - Returns the updated array of fields with selected customer fields added.
	 */
	private addSelCustFields(fields: Array<number>, existingCustFields: any): Array<number> {
		if(existingCustFields && Object.keys(existingCustFields).length > 0){
			for(let field in existingCustFields){
				if(existingCustFields[field] && (existingCustFields[field].toString()).length > 0){
					let id: number = +field.split('_')[1];
					if(!fields.includes(id)){
						fields.push(id);
					}
				}
			}
		}
		return fields;
	}
	/**
	 * Get the custom fields of an element based on the provided request data.
	 * @param {any} reqData - The request data containing the element information
	 * @returns {array} - An array of custom fields
	 */
	private getCustomFieldsOfElem(reqData: any): Array<any> {
		let customFieldsArr: Array<any> = [];
		if(reqData && Object.keys(reqData).length > 0){
			for(let elem in reqData){
				if(reqData[elem] && this.util.objHasProp(this.customFieldsbaedOnElems, this.dependentFieldsKey[elem])){
					if(reqData[elem] && typeof reqData[elem] == 'object'){
						customFieldsArr = this.getCustomFieldsBasedOnMultiVal(reqData, elem, customFieldsArr);
					}else{
						customFieldsArr = this.getCustomFieldsBasedOnSingleVal(reqData[elem], elem, customFieldsArr);
					}
					if(!customFieldsArr){
						return [];
					}
				}
			}
		}
		return customFieldsArr;
	}
	/**
	 * Get the custom fields based on multiple values of an element.
	 * @param {any} reqData - The request data containing the element information
	 * @param {any} elem - The element for which custom fields are to be determined
	 * @param {array} customFieldsArr - The array of custom fields
	 * @returns {any} - The updated customFieldsArr after processing multiple values of the element.
	 */
	private getCustomFieldsBasedOnMultiVal(reqData: any, elem: any, customFieldsArr: Array<any>): any {
		if(this.util.checkArrLength(reqData[elem])){
			for(let id of reqData[elem]){
				customFieldsArr = this.getCustomFieldsBasedOnSingleVal(id, elem, customFieldsArr);
				if(!customFieldsArr){
					return false;
				}
			}
		}
		return customFieldsArr;
	}
	/**
	 * Checks if there are custom fields associated with the given element ID and element.
	 * If there are, it pushes the custom fields into the customFieldsArr array.
	 * @param {any} elemId - The ID of the element
	 * @param {any} elem - The element itself
	 * @param {array} customFieldsArr - An array of custom fields
	 * @returns {any} -  The updated customFieldsArr after processing single values of the element.
	 */
	private getCustomFieldsBasedOnSingleVal(elemId: any, elem: any, customFieldsArr: Array<any>): any {
		let fieldsArr: Array<any> = [];
		if(this.util.objHasProp(this.customFieldsbaedOnElems[this.dependentFieldsKey[elem]], elemId) && this.util.checkArrLength(this.customFieldsbaedOnElems[this.dependentFieldsKey[elem]][elemId])){
			fieldsArr = this.customFieldsbaedOnElems[this.dependentFieldsKey[elem]][elemId];
		}
		fieldsArr = this.getCustomFieldsWithMatchTypeAny(elem, fieldsArr);
		if(this.util.checkArrLength(fieldsArr)){
			customFieldsArr.push(fieldsArr);
		}else{
			return false;
		}
		return customFieldsArr;
	}
	/**
	 * Retrieves custom fields with match type 'any' for a given element.
	 * @param {any} elem - The element to retrieve custom fields for.
	 * @param {array} fieldsArr - The array of fields to check for custom fields with match type 'any'.
	 * @returns {array} - The updated array of fields with custom fields with match type 'any' added.
	 */
	private getCustomFieldsWithMatchTypeAny(elem: any, fieldsArr: any): any {
		if(this.util.objHasProp(this.customFieldsWithMatchTypeAny, this.dependentFieldsKey[elem]), this.util.checkArrLength(this.customFieldsWithMatchTypeAny[this.dependentFieldsKey[elem]])){
			fieldsArr = [...fieldsArr, ...this.customFieldsWithMatchTypeAny[this.dependentFieldsKey[elem]]];
			return [...new Set(fieldsArr)];
		}
		return fieldsArr;
	}
	/**
	 * Generates a filtered custom fields array using the provided fields and customFields array.
	 * @param {array} fields - The array of common fields among the custom fields
	 * @param {array} custoFields - The array of all custom fields
	 * @returns {array} - The filtered custom fields array
	 */
	private generateCustomFieldsArr(fields: Array<number>, custoFields: Array<any>): Array<any> {
		if(!this.util.checkArrLength(fields)){
			return custoFields;
		}
		let group: any = {};
		for(let field of fields){
			let section: any = this.customFieldSec[field];
			if(section){
				if(!this.util.objHasProp(group, section)){
					group[section] = JSON.parse(JSON.stringify(this.custSecData[section]));
				}
				group[section].custom_fields.push(JSON.parse(JSON.stringify(this.customFieldsById[field])));
			}
		}
		return Object.values(group)
	}
	/**
	 * Get the custom fields based on the given custom fields array per element and request data.
	 * @param {array} customFieldsArrPerElem - Array of custom fields per element
	 * @param {any} reqData - Request data object
	 * @returns {array} Array of custom fields
	 */
	// eslint-disable-next-line complexity
	private getCustomFields(customFieldsArrPerElem: Array<any>, reqData: any, prefillData: any): Array<any> {
		let customFieldsArray: Array<number> = [];
		let commonCustomFields: Array<any> = this.findCommonElements(customFieldsArrPerElem);
		let combinedCustomFields: Array<any> = this.getUniqueCombinedFields(customFieldsArrPerElem);
		// Check if combinedCustomFields array has any elements
		if(this.util.checkArrLength(combinedCustomFields)){
			for(let id of combinedCustomFields){
				let hasCustomField: boolean = this.util.objHasProp(this.customFieldsById, id);
				// Check if the custom field should be displayed based on the request data and display status
				if((!this.checkCustomFieldDisplayStatus(id, reqData) || !this.checkCustFieldDisplayOn(id)) && !this.isValAlreadySaved(id, prefillData)){
					continue;
				}
				if(!this.checkPriceableCustFieldStatusForHourlyServ(id, reqData)){
					continue;
				}
				let matchTypeStatus: boolean = this.getFormWiseMatchTypeStatus(reqData, id, commonCustomFields);
				let basedOnMatchType: boolean = this.getBasedOnMatchType({ ctrl: 'extra_match_type', compairWithCtrl: 'extras'}, id, reqData.extras, commonCustomFields);
				if( hasCustomField &&  matchTypeStatus &&  basedOnMatchType && !customFieldsArray.includes(id)){
					customFieldsArray.push(id);
				}
			}
		}
		return customFieldsArray;
	}
	/**
	 * Checks the status of a custom field for hourly service.
	 * @param {any} id - The ID of the customer field.
	 * @param {any} reqData - The request data object.
	 * @returns {boolean} Whether the customer field is available for hourly service.
	 */
	private checkPriceableCustFieldStatusForHourlyServ(id: any, reqData: any): boolean {
		// Retrieve the field object using the provided ID
		let field: any = this.customFieldsById[id];
		// Check if request data is provided and if the service is hourly and the field type is "Priceable Input"
		if(reqData && reqData.is_service_hourly && field.field_type == "Priceable Input"){
			// Check if the field is priceable, taxable, included in frequency discount, and not added as separate charge
			if(field.is_priceable && field.is_taxable && ['include_in_freq_disc', 'exempt_from_freq_disc'].includes(field.add_price_in) && !field.add_as_separate_charge){
				return false;
			}
		}
		return true;
	}
	/**
	 * Checks if a custom field should be displayed based on its display_on property.
	 * @param {any} id - The id of the custom field to check.
	 * @returns {boolean} - A boolean indicating whether the custom field should be displayed.
	 */
	private checkCustFieldDisplayOn(id: any): boolean{
		if(this.customFieldsById[id].display_on){
			switch (this.customFieldsById[id].display_on){
				case 'customer_backend':
					return (this.util.appLocalStorage()) ? true : false;
				case 'admin':
					return false;
				default:
					return true;
			}
		}
		return true;
	}
	/**
	 * Checks if a custom field value is already saved in prefill data.
	 * @param {any} id - The ID of the custom field to check.
	 * @param {any} prefillData - The prefill data to check for the custom field value.
	 * @returns {boolean} - Returns true if the custom field value is already saved in prefill data, false otherwise.
	 */
	private isValAlreadySaved(id: any, prefillData: any): boolean {
		let ctrl: string = this.customFieldsById[id]?.custom_field_section_id+'_'+this.customFieldsById[id]?._id;
		if(prefillData && prefillData.custom_fields && prefillData.custom_fields[ctrl] && (prefillData.custom_fields[ctrl].toString()).length > 0){
			return true;
		}
		return false;
	}
	/**
	 * Checks the display status of a custom field based on its ID and request data.
	 * @param {any} id - The ID of the custom field to check.
	 * @param {any} reqData - The request data to check for the custom field's display status.
	 * @returns {boolean} - Returns true if the custom field should be displayed, false otherwise.
	 */
	private checkCustomFieldDisplayStatus(id: any, reqData: any): boolean {
		if(this.isApplyOnlyForNew(id)){
			return reqData.is_customer_new ? true : false;
		}else{
			if(this.isExceptChargedBkng(id)){
				return (this.util.objHasProp(reqData, 'status') && reqData.status == 2) ? false : true;
			}else{
				return true;
			}
		}
	}
	/**
	 * Checks if a custom field should only be applied for new customers based on ID.
	 * @param {any} id - The ID of the custom field to check.
	 * @returns {boolean} - Returns true if the custom field should only be applied for new customers, false otherwise.
	 */
	private isApplyOnlyForNew(id: any): boolean {
		return (this.customFieldsById[id].apply_for_customers && this.customFieldsById[id].apply_for_customers == 'new') ? true : false;
	}
	/**
	 * Checks if a custom field should not be applied for charged bookings based on its ID.
	 * @param {any} id - The ID of the custom field to check.
	 * @returns {boolean} - Returns true if the custom field should not be applied for charged bookings, false otherwise.
	 */
	private isExceptChargedBkng(id: any): boolean {
		return (this.customFieldsById[id].apply_for_bookings && this.customFieldsById[id].apply_for_bookings == 'except_charged') ? true : false;
	}
	/**
	 * Get the status of form wise match type based on the given request data, custom field id, and common custom fields.
	 * @param {any} reqData - Request data object
	 * @param {any} id - Custom field id
	 * @param {any} commonCustomFields - Common custom fields
	 * @returns {boolean} Boolean value indicating the match type status
	 */
	private getFormWiseMatchTypeStatus(reqData: any, id: any, commonCustomFields: any): boolean {
		switch (reqData.form_id) {
			case 2:
				// Check for items and packages
				if(!(this.getBasedOnMatchType({ ctrl: 'item_match_type', compairWithCtrl: 'items'}, id, reqData.items, commonCustomFields) && this.getBasedOnMatchType({ ctrl: 'package_match_type', compairWithCtrl: 'packages'}, id, reqData.package, commonCustomFields))){
					return false;
				}
				break;
			case 3:
				// Check for items and addons
				if(!(this.getBasedOnMatchType({ ctrl: 'item_match_type', compairWithCtrl: 'items'}, id, reqData.items, commonCustomFields) && this.getBasedOnMatchType({ ctrl: 'addon_match_type', compairWithCtrl: 'addons'}, id, reqData.addons, commonCustomFields))){
					return false;
				}
				break;
			default:
				break;
		}
		return true;
	}
	/**
	 * Get the status of form-wise match type based on the given request data, custom field id, and common custom fields.
	 * @param {string} ctrl - Control string
	 * @param {number} id - The custom field ID
	 * @param {any} reqData - The request data object
	 * @param {string} compareWithCtrl - The control string to compare with
	 * @param {array} commonCustomFields - An array of common custom field IDs
	 * @returns {any} A boolean value indicating the match type status
	 */
	private getBasedOnMatchType(ctrlData: any, id: number, reqData: any, commonCustomFields: Array<number>): any {
		let type: string = 'mixed';
		if(this.util.objHasProp(this.customFieldsById[id], ctrlData?.ctrl)){
			type = this.customFieldsById[id][ctrlData?.ctrl];
		}
		switch (type) {
			case 'exact':
				return this.checkBasedOnExact(id, ctrlData?.compairWithCtrl, reqData);
			case 'any':
				if((this.findCommonElements([reqData, this.customFieldsById[id][ctrlData?.compairWithCtrl]])).length > 0){
					return true;
				}
				break;
			default:
				if(commonCustomFields.includes(id)){
					return true;
				}
				break;
		}
		return false;
	}
	/**
	 * Check the match type status based on exact comparison of custom field values.
	 * @param {number} id - The custom field ID
	 * @param {string} compareWithCtrl - The control string to compare with
	 * @param {any} reqData - The request data object
	 * @returns {boolean} A boolean value indicating the match type status
	 */
	private checkBasedOnExact(id: number, compairWithCtrl: string, reqData: any): boolean {
		if(JSON.stringify((this.customFieldsById[id][compairWithCtrl]).sort()) == JSON.stringify((reqData).sort())){
			return true;
		}else{
			// Check for archived
			let arrDiff: Array<number> = this.findArrayDifference(this.customFieldsById[id][compairWithCtrl], reqData);
			if(this.util.checkArrLength(arrDiff)){
				for(let elem of arrDiff){
					if(this.availableElems[compairWithCtrl].includes(elem)){
						return false;
					}
				}
			}
			return true;
		}
	}
	/**
	 * Get the difference between two arrays.
	 * @param {array} arr1 - The first array to compare
	 * @param {array} arr2 - The second array to compare
	 * @returns {array} An array containing the elements that are only present in one of the arrays
	 */
	private findArrayDifference(arr1: any, arr2: any): Array<any> {
		let arrDiff1: Array<number> = (arr1).filter((x:any) => !(arr2).includes(x));
		let arrDiff2: Array<number> = (arr2).filter((x:any) => !(arr1).includes(x));
		return this.getUniqueCombinedFields([arrDiff1, arrDiff2]);
	}
	/**
	 * Retrieves the common fields among all the arrays in the given array
	 * @param {array} arr - An array of arrays containing any type of values
	 * @returns {array} An array of common fields across all the arrays
	 */
	private findCommonElements(arr: Array<any>): Array<number> {
		if (!arr || arr.length === 0){
			return [];
		}
		return arr.reduce((x, y) => x?.filter((z: any) => y.includes(z)));
	}
	/**
	 * Get the unique combined fields from an array.
	 * @param {array} arr - The array to retrieve the combined fields from.
	 * @returns {array} An array containing only the unique elements from the combined fields.
	 */
	private getUniqueCombinedFields(arr: any): Array<any> {
		return [...new Set(arr.flat())];
	}

	/**
	 * Resets the values of the custFieldsData, custFieldsBasedOnId, and custFieldsBasedOnBkng properties to null.
	 */
	public resetVars(): void {
		this.custFieldsData = null;
		this.custFieldsBasedOnId = {};
		this.custFieldsBasedOnBkng = null;
	}
	/**
	 * Checks if custom fields exist for a given booking object.
	 * @param {any} bkng - The booking object to check.
	 * @returns {boolean} - Returns true if custom fields exist, false otherwise.
	 */
	public checkIfCustomFieldsExist(bkng: any): boolean {
		if(bkng && bkng.custom_fields && this.util.checkArrLength(Object.keys(bkng.custom_fields))){
			for(let field in bkng.custom_fields){
				let val: any = bkng.custom_fields[field];
				if(typeof field == 'object'){
					if(Object.values(val).some((el: any) => el == true)){
						return true;
					}
				}else{
					if(val && val.length > 0){
						return true;
					}
				}
			}
		}
		return false;
	}
	/**
	 * Retrieves custom fields for multiple bookings.
	 * @param {Array} bkngIds - An array of booking IDs to retrieve custom fields for.
	 */
	public fetchBkngsCustomFields(bkngIds: Array<any>, cDRef: any = null): void {
		this.resetVars();
		let reqData: any = {
			booking_ids: bkngIds
		}
		this.apiServ.callApi('POST', 'CustomFieldsForMultipleBkngs', reqData).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.customFieldsForMultipleBkngsRes(res, cDRef));
	}
	/**
	 * Retrieves custom fields for multiple bookings and updates the relevant properties.
	 * @param {any} res - The response object from the API.
	 */
	private customFieldsForMultipleBkngsRes(res: any, cDRef: any = null): void {
		if(this.apiServ.checkAPIRes(res)){
			this.custFieldsBasedOnBkng = res.data.bkg_field_ids;
			this.groupCustFieldsById(res.data?.custom_fields);
			this.custFieldsData = res.data?.custom_fields;
			if(cDRef){
				cDRef.detectChanges();
			}
		}
	}
	/**
	 * Groups custom fields based on their ID and assigns a display order to each field.
	 * @param {Array} custSec - Ther arrey of custom sections and fields.
	 */
	private groupCustFieldsById(custSec: Array<any>): void {
		if(this.util.checkArrLength(custSec)){
			for(let sec of custSec){
				if(this.util.checkArrLength(sec.fields)){
					for(let field of sec.fields){
						// Group the field based on its ID
						this.custFieldsBasedOnId[field.id] = field;
					}
				}
			}
		}
	}
}
