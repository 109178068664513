<ng-container [ngSwitch]="type">
	<!-- Cancel Appointments popup -->
	<div [id]="secId" *ngSwitchCase="'both'" class="tjs-popup tjs-popup--sm min-height--150 position-relative">
		<!-- Header -->
		<bk-popup-header [headingId]="section?.title?.id" [heading]="section?.title?.content"></bk-popup-header>
		<!-- Content -->
		<div class="tjs-popup__content tjs-popup__body">
			<div class="py-10 border-top-0" [ngClass]="{'d-sm-flex flex-column gap-15 flex-sm-row-reverse': (custRecBkngCancelOpt.length != 3) || (custRecBkngCancelOpt.length == 3 && booking?.is_first) }">
				<bk-navigate *ngIf="custRecBkngCancelOpt.includes('single')" [secId]="section?.this_only_btn?.id" [designId]="section?.this_only_btn?.design_id" [innerHTML]="section?.this_only_btn?.content" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" class="d-block mb-5 w-100" (callback)="cancel('single')"></bk-navigate>
				<ng-container *ngIf="custRecBkngCancelOpt.includes('upcoming') || (custRecBkngCancelOpt.includes('all') && booking?.is_first)">
					<bk-navigate *ngIf="custRecBkngCancelOpt.includes('upcoming') && !booking?.is_first; else wholeBtnBlock" [secId]="section?.all_upcoming_btn?.id" [designId]="section?.all_upcoming_btn?.design_id" [innerHTML]="section?.all_upcoming_btn?.content" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" class="d-block mb-15 mb-sm-5 w-100" (callback)="cancel('recurring')"></bk-navigate>
					<ng-template #wholeBtnBlock>
						<bk-navigate [secId]="section?.whole_schedule_btn?.id" [designId]="section?.whole_schedule_btn?.design_id" [innerHTML]="section?.whole_schedule_btn?.content" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" class="d-block mb-15 mb-sm-5 w-100" (callback)="cancel('recurring')"></bk-navigate>
					</ng-template>
				</ng-container>
				<bk-navigate *ngIf="custRecBkngCancelOpt.includes('all') && !booking?.is_first" [secId]="section?.whole_schedule_btn?.id" [designId]="section?.whole_schedule_btn?.design_id" [innerHTML]="section?.whole_schedule_btn?.content" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" class="d-block mb-15 mb-sm-5 w-100" (callback)="cancelFromFirst()"></bk-navigate>
			</div>
		</div>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</div>
	<!-- Cancel Booking popup -->
	<div [id]="secId" *ngSwitchCase="['single', 'recurring'].includes(type) ? type : ''" class="tjs-popup position-relative">
		<!-- Header -->
		<bk-popup-header *ngIf="section?.title" [headingId]="section?.title?.id" [heading]="section?.title?.content" [isClose]="false"></bk-popup-header>
		<!-- Content -->
		<form bkDebounceEvent [isSubmit]="true" [formGroup]="cancelForm" (debounceSubmit)="submitSingleForm()">
			<div [id]="section?.form?.id" class="tjs-popup__content tjs-popup__body">
				<ng-container *ngIf="cancelFee || reasonVisible || (settings && settings?.visible_comment_box == 'yes' && checkValueIsInAarray(settings?.comment_box_booking_types) && reasonTextarea); else confirmMsg">
					<!-- Cancellation fees -->
					<div [ngClass]="{'mb-20': reasonVisible || reasonTextarea }" *ngIf="cancelFee && settings && cancelFeeObj">
						<label class="form-label bk-form-label d-block" *ngIf="section?.form?.cancel_policy?.label" [innerHTML]="section?.form?.cancel_policy?.label | safeHtml"></label>
						<div class="bk-form-desc">
							<span translate>The customer will be charged a </span>
							<bk-amount-display [amount]="cancelForm.value['cancellation_fee']"></bk-amount-display>
							<ng-container *ngIf="cancelFeeObj.type == 'day_before'; else noDay">
								<span *ngIf="cancelFeeObj?.cancellation_fee_unit == 'percentage'">({{cancelFeeObj?.cancellation_val}}%) </span>
								<span translate>cancellation fee because you are canceling after </span>
								<span class="dir-ltr">{{cancelFeeObj?.time}} </span>
								<span class="dir-ltr">{{cancelFeeObj?.days}} </span>
								<span translate>day(s) before the service.</span>
							</ng-container>
							<ng-template #noDay>
								<span translate>cancellation fee because you are canceling </span>
								<span class="dir-ltr text-lowercase">{{cancelFeeObj?.time}} </span>
								<span translate>before the service.</span>
							</ng-template>
						</div>
					</div>
					<!-- Reasons -->
					<div [ngClass]="{'mb-20': settings?.visible_comment_box == 'yes'}" *ngIf="reasonVisible">
						<ul class="list-unstyled mb-0">
							<label class="d-block form-label bk-form-label d-block" *ngIf="section?.form?.cancel_reason?.label" [innerHTML]="section?.form?.cancel_reason?.label |safeHtml"></label>
							<ng-container *ngIf="settings?.cancellation_reason && (settings?.cancellation_reason).length > 0">
								<li class="mb-10" *ngFor="let reason of settings?.cancellation_reason;trackBy: utilServ.trackByFnIndex">
									<div class="form-check" *ngIf="checkReasonfrequency(reason) && ((booking?.occurrence == 'onetime') || (booking?.occurrence == 'recurring' && reason[reasonFieldType]))">
										<input id="{{reason?.id}}_{{reason?.title}}" type="checkbox" class="form-check-input" (change)="reasonChange($event, reason)">
										<label class="form-check-label bk-reason-label" for="{{reason?.id}}_{{reason?.title}}">{{reason?.title | translate}}</label>
									</div>
								</li>
							</ng-container>
						</ul>
						<div class="tjs-error" *ngIf="!reasonSelected" translate>
							<i class="tjsicon-attention"></i>Select at least one reason.
						</div>
					</div>
					<div class="mb-0" *ngIf="settings && settings?.visible_comment_box == 'yes' && checkValueIsInAarray(settings?.comment_box_booking_types) && reasonTextarea">
						<label class="form-label bk-form-label d-block" *ngIf="section?.form?.reason?.label" [innerHTML]="section?.form?.reason?.label |safeHtml"></label>
						<textarea [class.is-invalid]="cancelForm.controls['reason'].hasError('required') && cancelForm.controls['reason'].touched" class="form-control" rows="5" formControlName="reason" [attr.placeholder]="section?.form?.reason?.placeholder"></textarea>
						<div class="tjs-error" *ngIf="cancelForm.controls['reason'].hasError('required') && cancelForm.controls['reason'].touched">
							<i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.empty | translate}}
						</div>
					</div>
				</ng-container>
				<ng-template #confirmMsg>
					<p class="mb-0 fs-20 text-center" [id]="section?.form?.confirm_msg_id" [innerHTML]="section?.form?.confirm_msg | safeHtml"></p>
				</ng-template>
			</div>
			<!-- Footer -->
			<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse bk-footColor">
				<!-- Close -->
				<bk-navigate [secId]="section?.keep_btn?.id" [designId]="section?.keep_btn?.design_id" [innerHTML]="section?.keep_btn?.content" essentialClass="w-100" customClass="btn btn-primary rounded-5 w-100" class="w-100 d-block mb-5" (callback)="matDialog.closeAll()"></bk-navigate>
				<!-- Cancel -->
				<bk-navigate *ngIf="!isBkngStarted" [secId]="section?.cancel_btn?.id" [designId]="section?.cancel_btn?.design_id" [innerHTML]="type == 'single' ? section?.cancel_btn?.content: (booking?.is_first) ? section?.cancel_all_btn?.content : section?.cancel_upcoming_btn?.content" [isSubmit]="true" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" class="w-100 d-block mb-15 mb-sm-5"></bk-navigate>
			</div>
		</form>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</div>
</ng-container>
