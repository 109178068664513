<div [id]="secId" class="tjs-gallery" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div class="container" *ngIf="section?.title?.content || section?.desc?.content">
		<div class="tjs-section__title tjs-section__title-lg text-center  pt-60 pb-30">
			<!-- Title -->
			<div class="tjs-section__heading mb-15" *ngIf="section?.title?.content">
				<h3 [id]="section?.title?.id" class="h3 fw-bold bk-title display-5" [innerHTML]="section?.title?.content | safeHtml">
				</h3>
			</div>
			<!-- Description -->
			<div class="tjs-section__description" *ngIf="section?.desc?.content">
				<p class="bk-desc" [id]="section?.desc?.id" [innerHTML]="section?.desc?.content | safeHtml"></p>
			</div>
		</div>
	</div>
	<!-- List -->
	<ng-container *ngIf="(section?.gallery && section?.gallery?.items && (section?.gallery?.items).length > 0)">
		<div [id]="section?.gallery?.id" class="row g-0 overflow-hidden {{spaceClass}} bk-list" [class.tjs-gallery__onhover]="isHover" *ngIf="(pageSett[secId]?.variation_id == 'bk_gallery_V1' || pageSett[secId]?.variation_id == 'bk_gallery_V2' || pageSett[secId]?.variation_id == 'bk_gallery_V3') else v4">
			<div class="col-12 col-sm-12 col-md-6 bk-item" [ngClass]="{'col-xl-6': pageSett[secId]?.variation_id == 'bk_gallery_V1', 'col-xl-4': pageSett[secId]?.variation_id == 'bk_gallery_V2', 'col-xl-3': pageSett[secId]?.variation_id == 'bk_gallery_V3'}" *ngFor="let item of section?.gallery?.items; let i = index;;trackBy: secServ.trackByFnIndex">
				<!-- Content template -->
				<ng-container *ngTemplateOutlet="contentTemp; context:{item:item, type:'item'}"></ng-container>
			</div>
		</div>
		<!-- V4 slider -->
		<ng-template #v4>
			<div [id]="section?.gallery?.id" class="tjs-gallery tjs-gallery--slider bk-slider">
				<div class="{{spaceClass}} {{slidesToShowClass}}">
					<div class="keen-slider list" #gallerySlide [attr.dir]="initServ?.selectedLang?.rtl ? 'rtl' :'ltr'">
						<div class="bk-slider-item keen-slider__slide" [class.tjs-gallery__onhover]="isHover" *ngFor="let item of section?.gallery?.items; let i = index;trackBy: secServ.trackByFnIndex">
							<!-- Content template -->
							<ng-container *ngTemplateOutlet="contentTemp; context:{item:item, type:'slider-item'}"></ng-container>
						</div>
					</div>
					<ng-container *ngIf="sliderArrow">
						<span (click)="slider.prev()" class="tjs-gallery--slider-prev-arrow d-flex justify-content-center slider-arrow align-items-center rounded"><i class="tjsicon-left-arrow"></i></span>
						<span (click)="slider.next()" class="tjs-gallery--slider-next-arrow d-flex justify-content-center slider-arrow align-items-center rounded"><i class="tjsicon-right-arrow"></i></span>
					</ng-container>
				</div>
			</div>
		</ng-template>
	</ng-container>
</div>
<!-- Modal -->
<div class="tjs-gallery__popup">
	<div id="galleryVideoModal" class="modal fade" data-bs-keyboard="true">
		<div class="modal-dialog modal-dialog-centered mw-100 mx-15">
			<div class="modal-content bg-transparent tjs-popup tjs-popup--md border-0 mx-auto bg-transparent-imp">
				<div class="modal-header border-0 ms-auto p-0 mb-10">
					<button type="button" class="close fs-13 tjs-popup__closed border-0 rounded" data-bs-dismiss="modal" aria-hidden="true"><i class="tjsicon-close text-danger"></i></button>
				</div>
				<div class="modal-body tjs-popup__content bg-white overflow-hidden py-20 px-0">
					<iframe id="galleryVideo" autoplay class="w-100" width="870" height="480" src="" frameborder="0" allowfullscreen></iframe>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Content Template -->
<ng-template #contentTemp let-item="item" let-type="type" >
	<div [id]="item?.id" class="tjs-gallery__item tjs-gallery__hover-layer  overflow-hidden position-relative tjs-pointer">
		<!-- Video -->
		<div [id]="item?.media_id" class="tjs-gallery__video-player d-flex justify-content-center align-items-center bk-{{type}}-video" *ngIf="item?.media && item?.media?.url" (click)="videoModal(item?.media?.url)">
			<div class="tjs-gallery__featured-img h-auto w-100 tjs-bg-style bk-{{type}}-video-thumbnail" [class.tjs-video-placeholder]="defVideoCdn.includes(item?.media?.thumbnail)" [style.background-image]="'url(' + item?.media?.thumbnail + ')'">
			</div>
			<span class="tjs-gallery__icon rounded-circle d-inline-flex justify-content-center align-items-center position-absolute bg-dark"> <i class="tjsicon-play-button text-white display-4"></i> </span>
		</div>
		<!-- Media -->
		<div [id]="item?.media_id" class="tjs-gallery__featured-img h-auto w-100 tjs-bg-style bk-{{type}}-media"  *ngIf="item?.media && (item?.media).length > 0" [style.background-image]="'url(' + item?.media[0] + ')'">
		</div>
		<!-- <div class="tjs-overlay tjs-overlay--dark"> -->
			<div class="tjs-gallery__content p-25" *ngIf="item?.title || item?.desc">
				<!-- Title -->
				<div class="tjs-gallery__heading mb-15" *ngIf="item?.title">
					<h6 [id]="item?.title_id" class="fs-20 fw-bold bk-{{type}}-title" [innerHTML]="item?.title | safeHtml"></h6>
				</div>
				<!-- Description -->
				<div class="tjs-section__description" *ngIf="item?.desc">
					<p [id]="item?.desc_id" class="bk-{{type}}-desc mb-0" [innerHTML]="item?.desc | safeHtml"></p>
				</div>
			</div>
	<!-- </div> -->
	</div>
</ng-template>