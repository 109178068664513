import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// Services
import { NgOnDestroy, InitServ, CacheService } from '../../Services';

@Component({
	selector: 'bk-confirm-popup',
	template: `
	<div class="tjs-popup tjs-popup--sm position-relative" [id]="secId">
		<!-- Popup body -->
		<div class="tjs-popup__content tjs-popup__body min-height--100" [id]="section?.confirm_msg?.id">
			<div class="d-flex flex-column flex-sm-row gap-sm-20 text-center text-sm-start ">
				<!-- image -->
				<div class="bk-media flex-shrink-0" *ngIf="section?.media?.urls[0]">
					<img loading="auto" [id]="section?.media?.id" class="img-fluid" alt="alert" [src]="section?.media?.urls[0]" width="75" height="75">
				</div>
				<!-- Message -->
				<div [id]="section?.confirm_msg?.id">
					<p class="mb-0 fs-18 mt-10" [innerHTML]="(section?.confirm_msg?.[type] || defaultMsg[type] ) | safeHtml"></p>
				</div>
			</div>
		</div>
		<!-- Popup footer -->
		<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse bk-footColor">
			<bk-navigate class="d-block mb-15 mb-sm-0" [secId]="section?.yes_btn?.id" [designId]="section?.yes_btn?.design_id" [innerHTML]="section?.yes_btn?.content" essentialClass="w-100" customClass="btn btn-primary rounded-5 w-100" (callback)="dialogRef.close(true)"></bk-navigate>
			<bk-navigate class="d-block mb-5" [secId]="section?.no_btn?.id" [designId]="section?.no_btn?.design_id" [innerHTML]="section?.no_btn?.content" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" (callback)="dialogRef.close(false)"></bk-navigate>
		</div>
			<!-- Loader -->
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</div>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ConfirmPopupComponent implements OnInit {
	// Variables
	type: string = '';
	loaderId:string = 'confirm-loader'
	// Section fields
	secId: string = '';
	slug: string = 'confirmation';
	section: any = { confirm_msg: null, no_btn: null, yes_btn: null, media: null };
	// Add temporary if in case default string want to show that doesn't exist on builder. For this field should be builder and migration should run.
	defaultMsg:{[x:string]:string} = {
		'delete_note': 'Are you sure you want to delete this note?'
	}

	constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>, public initServ: InitServ, private cacheServ: CacheService) { }

	ngOnInit(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
		});
	}
}
