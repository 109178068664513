import { Injectable } from '@angular/core';
import { XhrFactory } from '@angular/common';
import { LoaderServ } from '../../Services';

@Injectable()
export class BrowserXhrWithAbortError implements XhrFactory {
	constructor(private loader: LoaderServ) { }

	build(): any {
		const request = <any>(new XMLHttpRequest());
		request.onabort = (ev: any) => {
			// Hide all loader
			this.loader.stopAll();
			request.onerror && request.onerror(ev)
		};
		return request;
	}
}
