<div [id]="secId" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section" class="tjs-session-page py-30 tjs-bg-style position-relative" [class.embeded-container]="utilServ?.embedStatus" [ngStyle]="{'background-image' : (section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}">
	<div class="tjs-opacity--bg" [id]="section?.media?.id" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0"></div>
	<div class="py-80">
		<div class="container">
			<div class="row position-relative">
				<div class="col-12 col-md-8 mx-auto">
					<div class="tjs-section__title text-center mb-50 pb-10">
						<!-- Title -->
						<div class="tjs-section__heading mb-15">
							<h4 class="fw-bold display-6"><span class="text-capitalize">{{referralData?.user_profile?.first_name}}&nbsp;</span>{{referralData?.user_profile?.last_name}} {{initServ?.appStr?.text?.gaveYou | translate}} <bk-amount-display [amount]="admnStngs?.merchant_settings?.admin?.def_referral_amount"></bk-amount-display> {{initServ?.appStr?.text?.forYourFistBooking | translate}}.</h4>
						</div>
						<!-- Sub title -->
						<div class="tjs-section__description">
							<p class="bk-sub-title">{{initServ?.appStr?.text?.claimYour | translate}} <bk-amount-display [amount]="admnStngs?.merchant_settings?.admin?.def_referral_amount"></bk-amount-display> {{initServ?.appStr?.text?.referralCouponCode | translate}}</p>
						</div>
					</div>
				</div>
				<!-- Loader -->
				<bk-app-loader [loaderId]="loaderInfoId"></bk-app-loader>
			</div>
			<form *ngIf="section?.form" class="tjs-session__form bk-form" [formGroup]="refSignupForm"  bkDebounceEvent [isSubmit]="true" (debounceSubmit)="submitForm()">
				<div class="row justify-content-center">
					<div class="col-12 col-sm-11 col-md-9 col-lg-7 col-xl-6">
						<div class="shadow-sm card" [id]="section?.form?.id">
							<div class="text-center card-body px-sm-30">
								<!-- Avatar -->
								<div class="tjs-session__thumb shadow-sm mt-n60 bg-white overflow-hidden mb-20 rounded-circle white-bg d-flex align-items-center justify-content-center shadow-sm tjs-h-100 tjs-w-100 mx-auto">
									<bk-user-img imgSrc="{{referralData?.user_profile?.photo_url}}" alt="user image" width="90" height="90"></bk-user-img>
								</div>
								<div class="text-start position-relative">
									<!-- Title -->
									<span [id]="section?.form?.title_id" class="d-block mb-20" *ngIf="section?.form?.title" [innerHTML]="section?.form?.title | safeHtml"></span>
									<!-- First name -->
									<div class="mb-30" *ngIf="section?.form?.first_name">
										<input [id]="section?.form?.first_name_id" class="form-control bk-input" [ngClass]="{'is-invalid': f['first_name']?.touched && f['first_name']?.errors}" [placeholder]="section?.form?.first_name?.placeholder" formControlName="first_name" type="text" (focusout)="detailsFocusOut()">
										<!-- Error template -->
										<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'first_name'}"></ng-container>
									</div>
									<!-- Last name -->
									<div class="mb-30" *ngIf="section?.form?.last_name">
										<input [id]="section?.form?.last_name_id" class="form-control bk-input" [ngClass]="{'is-invalid': f['last_name']?.touched && f['last_name']?.errors}" [placeholder]="section?.form?.last_name?.placeholder" formControlName="last_name" type="text" (focusout)="detailsFocusOut()">
										<!-- Error template -->
										<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'last_name'}"></ng-container>
									</div>
									<!-- Email -->
									<div class="mb-30" *ngIf="section?.form?.email">
										<div class="tjs-form-icon">
											<input [id]="section?.form?.email_id" class="form-control bk-input ps-50" [ngClass]="{'is-invalid': f['email_id']?.touched && f['email_id']?.errors}" [placeholder]="section?.form?.email?.placeholder" formControlName="email_id" type="text" [attr.disabled]="emailDisabled ? 'disabled' : null" (focusout)="detailsFocusOut()">
											<span class="tjsicon-email tjs-icon"></span>
										</div>
										<!-- Error template -->
										<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'email_id'}"></ng-container>
									</div>
									<!-- Password -->
									<div class="mb-30" *ngIf="section?.form?.password">
										<div class="tjs-form-icon">
											<input [id]="section?.form?.password_id" class="form-control bk-input ps-50" type="password" [ngClass]="{'is-invalid': f['password']?.touched && f['password']?.errors}" [placeholder]="section?.form?.password?.placeholder" formControlName="password">
											<span class="tjsicon-key tjs-icon"></span>
										</div>
										<!-- Error template -->
										<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'password'}"></ng-container>
									</div>
									<!-- Confirm password -->
									<div class="mb-30" *ngIf="section?.form?.confirm_password">
										<div class="tjs-form-icon">
											<input [id]="section?.form?.confirm_password_id" class="form-control bk-input ps-50" type="password" [ngClass]="{'is-invalid': f['confirm_password']?.touched && f['confirm_password']?.errors}" [placeholder]="section?.form?.confirm_password?.placeholder" formControlName="confirm_password">
											<span class="tjsicon-key tjs-icon"></span>
										</div>
										<!-- Error template -->
										<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'confirm_password'}"></ng-container>
									</div>
									<!-- Submit button -->
									<bk-navigate *ngIf="section?.form?.button" [secId]="section?.form?.button_id" [designId]="section?.form?.button?.design_id" [innerHTML]="section?.form?.button?.content" customClass="btn btn-primary btn-lg border-transparent" essentialClass="mb-25 w-100 bk-form-btn" [isSubmit]="true"></bk-navigate>
									<!-- Login link -->
									<span class="d-block" [id]="section?.form?.login_link_id" *ngIf="section?.form?.login_link">
										<span class="me-5" [innerHTML]="section?.form?.login_link | safeHtml"></span> <a [id]="section?.form?.login_link_id" class="link--orange bk-login-link" [routerLink]="['/'+initServ.appDynamicRoutes['login']]" [queryParams]="utilServ?.embedStatus ? { embed: 'true'} : {}"> {{initServ?.appStr?.text?.logIn | translate}}</a>
									</span>
									<!-- Loader -->
									<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
<!-- Form error template-->
<ng-template #errorTemp let-controlName="controlName">
	<ng-container *ngIf="f[controlName]?.touched && (f[controlName]?.errors?.required || f[controlName]?.errors?.pattern || f[controlName]?.errors?.minlength || f[controlName]?.errors?.matching || f[controlName]?.errors?.emailExists)">
		<div class="tjs-error">
			<i class="tjsicon-attention"></i>
			<ng-container *ngIf="f[controlName]?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.pattern"> {{(controlName == 'email_id') ? (initServ?.appStr?.errorMsg?.validEmail) : (initServ?.appStr?.errorMsg?.specialChars) | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.minlength"> {{initServ?.appStr?.errorMsg?.passwordLength | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.matching"> {{initServ?.appStr?.errorMsg?.passwordNotMatch | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.emailExists"> {{initServ?.appStr?.errorMsg?.signInEmailExists | translate}} </ng-container>
		</div>
	</ng-container>
</ng-template>
