import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ } from '../../../Services';

@Component({
	selector: 'bk-text',
	template:`
	<div *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section && section?.content" class="p-10 bk-row-text"  [id]="secId">
		<div [innerHTML]="section?.content | safeHtml"></div>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class TextComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section:any = null;
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.pageSett = this.sectionData.section_settings;
			this.secServ.setServData(this.pageSett, this.sectionData.content);
			if(this.pageSett && this.pageSett[this.secId] && this.secServ.buildText(this.secId)){
				this.section = this.secServ.buildText(this.secId);
			}
		}
	}
}
