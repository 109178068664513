/* eslint-disable no-prototype-builtins */
/* eslint-disable max-depth */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Injectable, OnDestroy, Self } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
// Services
import { ApiServ,NgOnDestroy } from './index';
// Constants
import { MIGRATION_FIELDS,IS_TAB, IS_MOBILE } from '../Constants';
@Injectable({
	providedIn: 'root'
})
export class StyleServ implements OnDestroy {

	//Variables
	groupedSec : any = ['give_us_call','list','form','review_card','get_in_touch','services','categories','discount_bar','faq','lead_form','reviews','preview_card','blog','single_post','blog_list','blog_section','subscribe','description','social_list','recent_posts','referrals','summary','row','review','booking_summary','header_summary','how_it_works','video','calendar', 'appointments', 'profile', 'address', 'billing', 'invoice', 'edit_profile','sent_list', 'received_list', 'listing'];
	styleData : any = {};
	rspExFields:any = ['font-size','font_size','padding-left','padding-right','padding-top','padding-bottom'];//responsive excluded fields
	resPaddingFields:any = ['padding-top_mob','padding-right_mob','padding-bottom_mob','padding-left_mob'];//Padding fields
	_routeListener: any;
	previousUrl: string = '';
	currentUrl: string = '';

	constructor(private apiServ : ApiServ,private router: Router,@Self() private destroy: NgOnDestroy) {
		this._routeListener = this.router.events.pipe(takeUntil(this.destroy)).subscribe((ev) => {
			if(ev instanceof NavigationEnd) {
				this.previousUrl = (this.currentUrl && (this.currentUrl !== '')) ? this.currentUrl : ev.url;
				this.currentUrl = ev.url;
			}
		});
	}

	/**
	 * Function to build page style and apply to elements
	 * @param res page style response/header style/footer style
	 */
	// eslint-disable-next-line max-params
	public async applyPageStyle(res:any, sectionType?:string, secId?:string, section?:any, isMultiStep:boolean=false, isEmbed: boolean = false):Promise<any> {
		if(res){
			let style = '';
			if(sectionType === 'header'){
				if(secId && res[secId]){
					if((res[secId]['layout'] === 'full') && (res[secId]['padding-left'+(IS_TAB ? '_mob' : '')] || res[secId]['padding-right'+(IS_TAB ? '_mob' : '')])){
						style += '#'+ secId + ' .bk-header { padding-left:'+ (res[secId]['padding-left'+(IS_TAB ? '_mob' : '')]?res[secId]['padding-left'+(IS_TAB ? '_mob' : '')]:'') +';padding-right:'+ (res[secId]['padding-right'+(IS_TAB ? '_mob' : '')]?res[secId]['padding-right'+(IS_TAB ? '_mob' : '')]:'') +';}'
					}
					if(res[secId]['background-color']){
						style += '#'+ secId + ' .bk-header,'+'#'+ secId + ' .bk-submenu{ background-color:'+ res[secId]['background-color'] + ';}'
					}
				}
				if(section){
					if(section['menu'] && section['menu']['id'] && res[section['menu']['id']]){
						if(res[section['menu']['id']]['active_color']){
							style += '#'+ section['menu']['id'] + ' .bk-header-link:not(.active):hover ,'+'#'+ section['menu']['id'] + ' .bk-header-link.active{ color:'+ res[section['menu']['id']]['active_color'] + ';}'
							style += '#'+ section['menu']['id'] +' .bk-header-link:not(.active):hover:after ,'+'#'+ section['menu']['id'] +' .bk-header-link.active:after{ background-color:'+res[section['menu']['id']]['active_color'] + ';}'
						}
						if(res[section['menu']['id']]['color']){
							style += '#'+ secId + ' .bk-header-dropdown .bk-header-dropdown-btn ,'+'#'+ secId + ' .bk-site-logo ,'+ '#'+ section['menu']['id'] + ' .bk-header-link:not(.active){ color:'+res[section['menu']['id']]['color'] + ';}'
							style += '@media(max-width:991px){'+ '#'+ secId + ' .dropdown .dropdown-menu .dropdown-item{ color:'+res[section['menu']['id']]['color']+ ';}}'
						}
						if(res[section['menu']['id']]['font-size']){
							style += '#'+ section['menu']['id'] + ' .bk-header-link{ font-size:'+this.applyFontSize(res[section['menu']['id']]['font-size']) + ';}'
						}
						if(res[section['menu']['id']]['mob_icon_color']){
							style += '#'+ section['menu']['id'] + ' .bk-navbar-toggler{ color:'+res[section['menu']['id']]['mob_icon_color'] + ';}'
						}
					}
					if(section['logo'] && section['logo']['media_id'] && res[section['logo']['media_id']]){
						if(res[section['logo']['media_id']]['opacity']){
							style += '#'+ section['logo']['media_id'] + '{ opacity:'+ res[section['logo']['media_id']]['opacity'] + ';}'
						}
					}
				}
			} else if(sectionType === 'footer'){
				if(secId && res[secId]){
					if(res[secId]['background-color']){
						style += '#'+ secId + ' .bk-footer { background-color:'+ res[secId]['background-color'] + ';}'
					}
					if((res[secId]['layout'] === 'full') && (res[secId]['padding-left'+(IS_TAB ? '_mob' : '')] || res[secId]['padding-right'+(IS_TAB ? '_mob' : '')])){
						style += '#'+ secId + ' .bk-footer { padding-left:'+ (res[secId]['padding-left'+(IS_TAB ? '_mob' : '')]?res[secId]['padding-left'+(IS_TAB ? '_mob' : '')]:'') +';padding-right:'+ (res[secId]['padding-right'+(IS_TAB ? '_mob' : '')]?res[secId]['padding-right'+(IS_TAB ? '_mob' : '')]:'') +';}'
					}
				}
				if(section){
					if(section['menu'] && res[section['menu']]){
						if(res[section['menu']]['active_color']){
							style += '#'+ section['menu'] + ' .bk-footer-link:hover,'+'#'+ section['menu'] + ' .bk-footer-link.active{ color:'+ res[section['menu']]['active_color'] + ';}'
						}
						if(res[section['menu']]['color']){
							style += '#'+ secId + ' .bk-site-logo,'+'#'+ section['menu'] + ' .bk-footer-link:not(.active){ color:'+res[section['menu']]['color'] + ';}'
						}
						if(res[section['menu']]['font-size']){
							style += '#'+ section['menu'] + ' .bk-footer-link{ font-size:'+this.applyFontSize(res[section['menu']]['font-size']) + ';}'
						}
					}
					if(section['phone'] && section['phone']['id'] && res[section['phone']['id']] && res[section['phone']['id']]['color']){
						style += '#'+ section['phone']['id'] + ' .bk-footer-contact-link{ color:'+res[section['phone']['id']]['color'] + ';}'
					}
					if(section['cr_text'] && section['cr_text']['id'] && res[section['cr_text']['id']] && (res[section['cr_text']['id']]['color'] || res[section['cr_text']['id']]['background-color'] || res[section['cr_text']['id']]['font-size'])){
						if(res[section['cr_text']['id']]['background-color']){
							style += '#'+ section['cr_text']['id'] + '{ background-color:'+ res[section['cr_text']['id']]['background-color'] + ';}'
						}
						if(res[section['cr_text']['id']]['color'] || res[section['cr_text']['id']]['font-size']){
							style +=  '#'+ section['cr_text']['id'] + ' .bk-powered-by{ color:'+ (res[section['cr_text']['id']]['color']?res[section['cr_text']['id']]['color']:'') + '; font-size:'+this.applyFontSize(res[section['cr_text']['id']]['font-size']?res[section['cr_text']['id']]['font-size']:'') + ';}'
						}
					}
					if(section['cookie_content'] && section['cookie_content']['id'] && res[section['cookie_content']['id']]){
						let cookieStyle = res[section['cookie_content']['id']];
						if(cookieStyle['background-color']){
							style += '#'+ section['cookie_content']['id'] + '{ background-color:'+cookieStyle['background-color'] + ';}'
						}
						if(cookieStyle['color'] || cookieStyle['font-size']){
							style += '#'+ section['cookie_content']['id'] + ' .bk-cookie-title{ color:'+(cookieStyle['color']?cookieStyle['color']:'') +'; font-size:'+this.applyFontSize(cookieStyle['font-size'] ? cookieStyle['font-size']:'')+';}'
						}
						if(cookieStyle['btn_bg_color'] || cookieStyle['btn_border_color'] || cookieStyle['btn_color']){
							style += '#'+ section['cookie_content']['id'] + ' .bk-cookie-acpt-btn{ background-color:'+(cookieStyle['btn_bg_color']?cookieStyle['btn_bg_color']:'') + '; border-color:'+(cookieStyle['btn_border_color']?cookieStyle['btn_border_color']:'') +'; color:'+(cookieStyle['btn_color']?cookieStyle['btn_color']:'') + ';}'
						}
						if(cookieStyle['sett_color'] || cookieStyle['sett_font_size']){
							style += '#'+ section['cookie_content']['id'] + ' .bk-cookie-set-btn{ color:'+cookieStyle['sett_color'] + '; font-size:'+this.applyFontSize(cookieStyle['sett_font_size']?cookieStyle['sett_font_size']:'') + ';}'
						}
					}
					if(section['logo'] && section['logo']['media_id'] && res[section['logo']['media_id']]){
						if(res[section['logo']['media_id']]['opacity']){
							style += '#'+ section['logo']['media_id'] + '{ opacity:'+ res[section['logo']['media_id']]['opacity'] + ';}'
						}
					}
					if(section['apply_now_btn'] && section['apply_now_btn']['id'] && res[section['apply_now_btn']['id']]){
						let applyBtn = res[section['apply_now_btn']['id']];
						if(applyBtn['background-color'] || applyBtn['color'] || applyBtn['border-color'] || applyBtn['font-size']){
							style += '#'+ section['apply_now_btn']['id'] + ' { background-color:'+(applyBtn['background-color']?applyBtn['background-color']:'') + '; border-color:'+(applyBtn['border-color']?applyBtn['border-color']:'') +'; color:'+(applyBtn['color']?applyBtn['color']:'') + '; font-size:'+this.applyFontSize(applyBtn['font-size']?applyBtn['font-size']:'') + ';}'
						}
					}
					if(section['booknow_btn'] && section['booknow_btn']['id'] && res[section['booknow_btn']['id']]){
						let bookingBtn = res[section['booknow_btn']['id']];
						if(bookingBtn['background-color'] || bookingBtn['color'] || bookingBtn['border-color'] || bookingBtn['font-size']){
							style += '#'+ section['booknow_btn']['id'] + ' { background-color:'+(bookingBtn['background-color']?bookingBtn['background-color']:'') + '; border-color:'+(bookingBtn['border-color']?bookingBtn['border-color']:'') +'; color:'+(bookingBtn['color']?bookingBtn['color']:'') + '; font-size:'+this.applyFontSize(bookingBtn['font-size']?bookingBtn['font-size']:'') + ';}'
						}
					}
				}
			} else {
				if(this.apiServ.checkAPIRes(res) && res.data && res.data.style){
					this.styleData = res.data.style;
					let secElem = res.data.section_elements;
					if(sectionType === 'booking_form'){
						await this.migrateStyle(this.styleData, secElem);
					}
					for(let selectorKey in this.styleData) {
						let styleVal = this.styleData[selectorKey];
						if(styleVal && Object.keys(styleVal) && (Object.keys(styleVal)).length > 0){
							style += '#'+ selectorKey +'{'
							for(let property in styleVal){
								if(styleVal[property]){
									if((property === 'opacity') && (styleVal[property] != undefined)){
										style += `opacity:${styleVal['opacity']};`
									} else {
										let styleProp = property.replace("_", "-");
										if(this.resPaddingFields.includes(property)){
											if(IS_TAB){
												let propArray:any = styleProp.split('-');
												if(propArray && propArray.length > 1){
													style += `${propArray[0]}-${propArray[1]}: ${styleVal[property]};`
												}
											}
										} else {
											if(styleVal[property] && (!IS_TAB || (IS_TAB && !this.rspExFields.includes(property)))){
												style += `${styleProp}: ${(property === 'background-image') ? "url(" + styleVal[property] + ")" : styleVal[property]};`
											}
										}
									}
								}
							}
							style += '}'
							/**Form live Review tooltip*/
							if((selectorKey.includes('reviews_title') || selectorKey.includes('referrals_title')) && styleVal['color'] !== undefined){
								style += `.${selectorKey}-tooltip{color:${styleVal['color']};}`
							}
							/**Background image Opacity */
							if(styleVal.hasOwnProperty('opacity') && styleVal['opacity'] != undefined){
								style += `#${selectorKey}.tjs-opacity--bg{opacity:${(1 - styleVal['opacity'])};}`
							}
							/**Global style with different property name */
							if(styleVal.hasOwnProperty('card_background_color')){
								style += '#'+ selectorKey +' .bk-card { background-color:'+styleVal['card_background_color'] + ';}'
							}
							// card label, values
							if(styleVal['label_color'] || styleVal['value_color'] || styleVal['label_font_size'] || styleVal['value_font_size']){
								style += '#'+selectorKey+' .bk-card-label { color:'+((styleVal['label_color']) ? styleVal['label_color'] : '')+'; font-size:'+this.applyFontSize((styleVal['label_font_size']) ? styleVal['label_font_size'] : '')+'; }';
								style += '#'+selectorKey+' .bk-card-value { color:'+((styleVal['value_color']) ? styleVal['value_color'] : '')+'; font-size:'+this.applyFontSize((styleVal['value_font_size']) ? styleVal['value_font_size'] : '')+'; }';
							}
							/** Pagination */
							if(styleVal['active_background_color'] || styleVal['active_border_color'] || styleVal['active_color'] || styleVal['page_link_color'] || styleVal['page_link_background_color'] || styleVal['border_radius']){
								style += `#${selectorKey}.bk-pagination .page-link i { font-size: inherit; display: inline-flex; } `;
								style += `#${selectorKey}.bk-pagination .page-item.active .page-link { color : ${styleVal['active_color'] ? styleVal['active_color']:''}; background-color: ${styleVal['active_background_color'] ? styleVal['active_background_color']:''}; border-color: ${styleVal['active_border_color'] ? styleVal['active_border_color']:''}; font-size: ${this.applyFontSize((styleVal['font-size']) ? styleVal['font-size'] : '')};} `;
								style += `#${selectorKey}.bk-pagination .page-item .page-link { color : ${styleVal['page_link_color'] ? styleVal['page_link_color']:''}; background-color: ${styleVal['page_link_background_color'] ? styleVal['page_link_background_color']:''}; border-color: ${styleVal['page_link_border_color'] ? styleVal['page_link_border_color']:''}; font-size: ${this.applyFontSize((styleVal['font-size']) ? styleVal['font-size'] : '')}; } `;
								style += `.tjs-app--root:not(.tjs-rtl-layout) #${selectorKey}.bk-pagination .page-item:first-child .page-link { border-top-left-radius : ${styleVal['border_radius']}px; border-bottom-left-radius: ${styleVal['border_radius']}px; }`;
								style += `.tjs-app--root:not(.tjs-rtl-layout) #${selectorKey}.bk-pagination .page-item:last-child .page-link { border-top-right-radius : ${styleVal['border_radius']}px; border-bottom-right-radius: ${styleVal['border_radius']}px; }`;
								style += `.tjs-app--root.tjs-rtl-layout #${selectorKey}.bk-pagination .page-item:first-child .page-link { border-top-right-radius : ${styleVal['border_radius']}px; border-bottom-right-radius : ${styleVal['border_radius']}px; }`;
								style += `.tjs-app--root.tjs-rtl-layout #${selectorKey}.bk-pagination .page-item:last-child .page-link { border-top-left-radius : ${styleVal['border_radius']}px; border-bottom-left-radius : ${styleVal['border_radius']}px; }`;
							}
							// notifications
							if(sectionType === 'notifications'){
								style += '.'+selectorKey+'--color { color:'+((styleVal['color'])? styleVal['color']: '')+';}';
								style += '#'+selectorKey+' .bk-tooltip { color:'+((styleVal['tooltip_color']) ? styleVal['tooltip_color'] : '')+';}';
								style += '#'+selectorKey+' .bk-title-border { border-color:'+((styleVal['border-color']) ? styleVal['border-color'] : '')+'; border-width:'+styleVal['border_width']+'px;}';
								style += '#'+selectorKey+' .bk-list { background-color:'+((styleVal['list_background_color'])? styleVal['list_background_color']: '')+';}';
								style += '#'+selectorKey+' .bk-list__title { color:'+((styleVal['label_color']) ? styleVal['label_color'] : '')+';  font-size:'+this.applyFontSize((styleVal['label_font_size']) ? styleVal['label_font_size'] : '')+';}';
								style += '#'+selectorKey+' .tjs-switch-disable span { color:'+((styleVal['disable_btn_color']) ? styleVal['disable_btn_color'] : '')+'; background-color:'+((styleVal['disable_btn_background_color'])? styleVal['disable_btn_background_color']: '')+';}';
								style += '#'+selectorKey+' .tjs-switch-enable span { color:'+((styleVal['enable_btn_color']) ? styleVal['enable_btn_color'] : '')+'; background-color:'+((styleVal['enable_btn_background_color'])? styleVal['enable_btn_background_color']: '')+';}';
							}
							// Cards having title with tooltip
							if(styleVal['title_color']){
								style += '#'+selectorKey+' .bk-section-tooltip, #'+selectorKey+' .bk-section-title, .'+selectorKey+'--color { color:'+((styleVal['title_color']) ? styleVal['title_color'] : '')+';}';
							}
							if(styleVal['color']){
								style += '#'+selectorKey+' .bk-section-tooltip, #'+selectorKey+' .bk-section-title, .'+selectorKey+'--color { color:'+((styleVal['color']) ? styleVal['color'] : '')+';}';
							}
							/** Invoice page */
							if(styleVal['label_color'] || styleVal['label_font_size']){
								style += '#'+selectorKey+' .bk-label-clr, '+'#'+selectorKey+' .bk-form-label{ color:'+(styleVal['label_color']?styleVal['label_color']:'') + ';font-size:'+this.applyFontSize((styleVal['label_font_size']?styleVal['label_font_size']:'')) + '; }';
							}
							if(styleVal['label_font_weight']){
								style += '#'+selectorKey+' .bk-label-fw{ font-weight:'+styleVal['label_font_weight']+ ';}';
							}
							if(styleVal['tooltip_color']){
								style += '#'+selectorKey+' .bk-tooltip{ color:'+styleVal['tooltip_color']+ ';}';
							}
							if(styleVal['desc_color'] || styleVal['desc_font_size']){
								style += '#'+selectorKey+' .bk-inv-desc-clr{ color:'+(styleVal['desc_color'] ? styleVal['desc_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['desc_font_size']?styleVal['desc_font_size']:'')) + ';}';
							}
							if(styleVal['border_radius'] || styleVal['border_width']){
								style += '#'+selectorKey+'.bk-border{ border-radius:'+styleVal['border_radius'] + 'px; border-width:'+styleVal['border_width'] + 'px;}';
							}
							if(styleVal['border_radius'] || styleVal['border_width'] || styleVal['border_style'] || styleVal['border-color']){
								style += '#'+selectorKey+' .bk-border-prop{ border-radius:'+styleVal['border_radius'] + 'px; border-width:'+styleVal['border_width'] + 'px; border-style: '+styleVal['border_style'] +'; border-color: '+styleVal['border-color'] +';}';
							}
							if(styleVal['button-alignment'] || styleVal['button-width']){
								style += '#'+selectorKey+' .bk-button{ text-align:'+styleVal['button-alignment'] + '; width:'+styleVal['button-width'] + ';}';
							}
							if(styleVal['value_color']){
								style += '#'+selectorKey+' .bk-val-color{ color:'+(styleVal['value_color']?styleVal['value_color']:'') + ';}';
							}
							if(styleVal['color']){
								style += '#'+selectorKey+' .bk-label-clr, '+'#'+selectorKey+' .bk-form-label { color:'+(styleVal['color']?styleVal['color']:'') + ';}';
							}
							/** Invoice page table */
							if(sectionType === 'invoice') {
								// table body
								if(styleVal['body_background_color']){
									style += `#${selectorKey}.bk-table tbody tr { background-color: ${styleVal['body_background_color'] ? styleVal['body_background_color']:''}; }`;
								}
								if(styleVal['body_background_color'] || styleVal['body_color'] || styleVal['body_font_size']){
									style += `#${selectorKey}.bk-table tbody tr td { background-color: ${styleVal['body_background_color'] ? styleVal['body_background_color']:''}; color: ${styleVal['body_color'] ? styleVal['body_color'] : ''}; font-size:${this.applyFontSize((styleVal['body_font_size'] ? styleVal['body_font_size'] : ''))};}`;
								}
								// table header
								if(styleVal['header_background_color'] || styleVal['header_font_size'] || styleVal['header_font_weight'] || styleVal['header_color']){
									style += `#${selectorKey}.bk-table thead th { background-color:${styleVal['header_background_color'] ? styleVal['header_background_color']:''}; color:${styleVal['header_color'] ? styleVal['header_color']:''}; font-size:${this.applyFontSize((styleVal['header_font_size'] ? styleVal['header_font_size'] : ''))}; font-weight:${styleVal['header_font_weight']}; }`;
									style += `#${selectorKey}.bk-table.tjs-table--responsive tbody td[data-label]:before { color:${styleVal['header_color']}; font-size:${this.applyFontSize(styleVal['header_font_size'])}; font-weight:${styleVal['header_font_weight']}; }`;
								}
								// Inv detail section
								if(secElem[selectorKey] === 'inv_details') {
									style += '#'+selectorKey+'.bk-inv-card-bg { background-color:#fff;}'
									style += '#'+selectorKey+'.bk-inv-card-p-0 { padding:0;}'
									if(styleVal['background-color']){
										style += '#'+selectorKey+' .bk-inv-bg { background-color:'+styleVal['background-color']+';}'
									}
									if(styleVal['padding-top'] || styleVal['padding-bottom'] || styleVal['padding-left'] || styleVal['padding-left']){
										style += '#'+selectorKey+' .bk-inv-padding{' +this.applyCustomSpacing(styleVal,'','padding')+';}';
									}

									if(styleVal['border-radius'] || styleVal['border-style'] || styleVal['border-color'] || styleVal['border-width']) {
										style += '#'+selectorKey+' .bk-inv-border{ border-radius:'+styleVal['border-radius'] +'px; border-width: '+styleVal['border-width'] +'px; border-style: '+styleVal['border-style'] +'; border-color: '+styleVal['border-color'] +'}'
									}
								}
							}
							/** Invoice payment page */
							if(sectionType === 'invoice-payment') {
								if(styleVal['color']){
									style += '#'+selectorKey+' .bk-label-clr, '+'#'+selectorKey+' .bk-form-label { color:'+(styleVal['color']?styleVal['color']:'') + ';}';
								}
							}
							// address section title & tooltip color
							if(secElem && (this.groupedSec?.length > 0) && this.groupedSec?.includes(secElem[selectorKey])){
								// Section card & Section card border styling
								if(styleVal['background-color'] || styleVal['border-color']){
									style += '#'+ selectorKey +'.bk-section-card { background-color:'+(styleVal['background-color'] ? styleVal['background-color']: '' ) + ';} ';
									style += '#'+ selectorKey +' .bk-section-border { border-color:'+(styleVal['border-color']?styleVal['border-color']:'')+'; border-width:'+styleVal['border_width']+'px;} ';
								}
								/** Dashboard Appointments tab Style */
								if(secElem[selectorKey] === 'appointments'){
									// tabs
									if(styleVal['active_tab_background_color'] || styleVal['active_tab_border_color'] || styleVal['active_tab_color'] ){
										style += '#'+selectorKey+' .bk-active-tab { background-color:'+(styleVal['active_tab_background_color'] ? styleVal['active_tab_background_color']: '') + '; --bk-tab-strip-active-color:'+(styleVal['active_tab_border_color'] ? styleVal['active_tab_border_color']: '')+'; color: '+(styleVal['active_tab_color'] ? styleVal['active_tab_color'] : '')+'; font-size: '+this.applyFontSize(styleVal['tab_font_size'] ? styleVal['tab_font_size'] : '')+';}';
										style += '#'+selectorKey+' .bk-tab { background-color:'+(styleVal['tab_background_color'] ? styleVal['tab_background_color']: '') +'; color: '+((styleVal['tab_color']) ? styleVal['tab_color'] : '')+'; font-size: '+this.applyFontSize(styleVal['tab_font_size'] ? styleVal['tab_font_size'] : '')+';}';
										style += '#'+selectorKey+' .bk-tab:hover { --bk-tab-strip-active-color:'+(styleVal['active_tab_border_color']  ? styleVal['active_tab_border_color']: '')+'; background-color:'+(styleVal['active_tab_background_color']? styleVal['active_tab_background_color']: '') + '; color: '+(styleVal['active_tab_color'] ? styleVal['active_tab_color'] : '')+';}';
										style += '#'+selectorKey+' .bk-active--color { color:' +(styleVal['active_tab_color'] ? styleVal['active_tab_color'] : '')+'; }';
										style += '#'+selectorKey+' .bk-active-border-color { border-color:' +(styleVal['active_tab_color'] ? styleVal['active_tab_color'] : '')+'; }';
									}
									// booking-card warp
									if(styleVal['active_content_background_color']){
										style += '#'+selectorKey+' .bk-booking-card-wrap { background-color:'+styleVal['active_content_background_color']+';}'
									}
									// booking-card
									if(styleVal['border_radius'] || styleVal['card_background_color']){
										style += '#'+selectorKey+' .bk-booking-card { border-radius:'+(styleVal['border_radius'] ? styleVal['border_radius']: '')+'px; background-color:'+(styleVal['card_background_color'] ? styleVal['card_background_color'] : '')+'}';
									}
									// card border color
									if(styleVal['separator_color'] || styleVal['separator_height']){
										style += '#'+selectorKey+' .bk-booking-card .bk-card-hr { background-color:'+(styleVal['separator_color'] ? styleVal['separator_color'] : '')+'; height:'+styleVal['separator_height']+'px; opacity: 1;}';
										style += '#'+selectorKey+' .bk-booking-card .bk-card-border { border-color:'+(styleVal['separator_color'] ? styleVal['separator_color'] : '')+'; border-width:'+styleVal['separator_height']+'px;}';
										style += '#'+selectorKey+' .bk-title-border { border-color:'+(styleVal['separator_color'] ? styleVal['separator_color'] : '')+'; border-width:'+styleVal['separator_height']+'px;}';
									}
									// booking card buttons
									if(styleVal['edit_btn_background_color'] || styleVal['edit_btn_color'] || styleVal['edit_btn_border_color'] || styleVal['edit_btn_font_size']){
										style += '#'+selectorKey+' .bk-edit-btn { background-color:'+(styleVal['edit_btn_background_color'] ? styleVal['edit_btn_background_color'] : '')+'; color: '+(styleVal['edit_btn_color'] ? styleVal['edit_btn_color'] : '')+'; border-color:'+(styleVal['edit_btn_border_color'] ? styleVal['edit_btn_border_color'] : '')+'; font-size: '+this.applyFontSize(styleVal['edit_btn_font_size'] ? styleVal['edit_btn_font_size'] : '')+';}';
									}
									if(styleVal['view_btn_background_color'] || styleVal['view_btn_color'] || styleVal['view_btn_border_color'] || styleVal['view_btn_font_size']){
										style += '#'+selectorKey+' .bk-detail-btn { background-color:'+(styleVal['view_btn_background_color'] ? styleVal['view_btn_background_color'] : '')+'; color: '+(styleVal['view_btn_color'] ? styleVal['view_btn_color'] : '')+'; border-color:'+(styleVal['view_btn_border_color'] ? styleVal['view_btn_border_color'] : '')+'; font-size: '+this.applyFontSize(styleVal['view_btn_font_size'] ? styleVal['view_btn_font_size'] : '')+';}';
									}
									if(styleVal['book_btn_background_color'] || styleVal['book_btn_color'] || styleVal['book_btn_border_color'] || styleVal['book_btn_font_size']){
										style += '#'+selectorKey+' .bk-book-again-btn { background-color:'+(styleVal['book_btn_background_color'] ? styleVal['book_btn_background_color'] : '')+'; color: '+(styleVal['book_btn_color'] ? styleVal['book_btn_color'] : '')+'; border-color:'+(styleVal['book_btn_border_color'] ? styleVal['book_btn_border_color'] : '')+'; font-size: '+this.applyFontSize(styleVal['book_btn_font_size'] ? styleVal['book_btn_font_size'] : '')+';}';
									}
									if(styleVal['add_tip_btn_background_color'] || styleVal['add_tip_btn_color'] || styleVal['add_tip_btn_border_color'] || styleVal['add_tip_btn_font_size']){
										style += '#'+selectorKey+' .bk-add-tip-btn { background-color:'+(styleVal['add_tip_btn_background_color'] ? styleVal['add_tip_btn_background_color'] : '')+'; color: '+(styleVal['add_tip_btn_color'] ? styleVal['add_tip_btn_color'] : '')+'; border-color:'+(styleVal['add_tip_btn_border_color'] ? styleVal['add_tip_btn_border_color'] : '')+'; font-size: '+this.applyFontSize(styleVal['add_tip_btn_font_size'] ? styleVal['add_tip_btn_font_size'] : '')+';}';
									}
									if(styleVal['resume_btn_background_color'] || styleVal['resume_btn_color'] || styleVal['resume_btn_border_color'] || styleVal['resume_btn_font_size']){
										style += '#'+selectorKey+' .bk-resume-btn { background-color:'+(styleVal['resume_btn_background_color'] ? styleVal['resume_btn_background_color'] : '')+'; color: '+(styleVal['resume_btn_color'] ? styleVal['resume_btn_color'] : '')+'; border-color:'+(styleVal['resume_btn_border_color'] ? styleVal['resume_btn_border_color'] : '')+'; font-size: '+this.applyFontSize(styleVal['resume_btn_font_size'] ? styleVal['resume_btn_font_size'] : '')+';}';
									}
									if(styleVal['cancel_btn_background_color'] || styleVal['cancel_btn_color'] || styleVal['cancel_btn_border_color'] || styleVal['cancel_btn_font_size']){
										style += '#'+selectorKey+' .bk-cancel-btn { background-color:'+(styleVal['cancel_btn_background_color'] ? styleVal['cancel_btn_background_color'] : '')+'; color: '+(styleVal['cancel_btn_color'] ? styleVal['cancel_btn_color'] : '')+'; border-color:'+(styleVal['cancel_btn_border_color'] ? styleVal['cancel_btn_border_color'] : '')+'; font-size: '+this.applyFontSize(styleVal['cancel_btn_font_size'] ? styleVal['cancel_btn_font_size'] : '')+';}';
									}
									if(styleVal['edit_review_btn_background_color'] || styleVal['edit_review_btn_color'] || styleVal['edit_review_btn_border_color'] || styleVal['edit_review_btn_font_size']){
										style += '#'+selectorKey+' .bk-edit-review { background-color:'+(styleVal['edit_review_btn_background_color'] ? styleVal['edit_review_btn_background_color'] : '')+'; color: '+(styleVal['edit_review_btn_color'] ? styleVal['edit_review_btn_color'] : '')+'; border-color:'+(styleVal['edit_review_btn_border_color'] ? styleVal['edit_review_btn_border_color'] : '')+'; font-size: '+this.applyFontSize(styleVal['edit_review_btn_font_size'] ? styleVal['edit_review_btn_font_size'] : '')+';}';
									}
									if(styleVal['error_bg_color']){
										style += '#'+selectorKey+' .bk-error { background-color:'+styleVal['error_bg_color']+';}';
									}
									if(styleVal['error_color'] || styleVal['error_font_size']){
										style += '#'+selectorKey+' .bk-error-text {color:'+styleVal['error_color']+'; font-size: '+this.applyFontSize(styleVal['error_font_size'] ? styleVal['error_font_size'] : '')+'; }';
									}
									if(styleVal['view_details_btn_bg_color'] || styleVal['view_details_btn_color'] || styleVal['view_details_btn_border_color'] || styleVal['view_details_btn_font_size']){
										style += '#'+selectorKey+' .bk-view-detail-btn { background-color:'+(styleVal['view_details_btn_bg_color'] ? styleVal['view_details_btn_bg_color'] : '')+'; color: '+(styleVal['view_details_btn_color'] ? styleVal['view_details_btn_color'] : '')+'; border-color:'+(styleVal['view_details_btn_border_color'] ? styleVal['view_details_btn_border_color'] : '')+'; font-size: '+this.applyFontSize(styleVal['view_details_btn_font_size'] ? styleVal['view_details_btn_font_size'] : '')+';}';
									}
									// title
									if(styleVal['title_color'] || styleVal['title_font_size']){
										style += '#'+selectorKey+' .bk-card-title { color:'+(styleVal['title_color'] ? styleVal['title_color'] : '')+'; font-size:'+this.applyFontSize(styleVal['title_font_size'] ? styleVal['title_font_size'] : '')+';}';
									}
									// price
									if(styleVal['price_color'] || styleVal['price_font_size']){
										style += '#'+selectorKey+' .bk-card-price { color:'+(styleVal['price_color'] ? styleVal['price_color'] : '')+'; font-size:'+this.applyFontSize(styleVal['price_font_size'] ? styleVal['price_font_size'] : '')+';}';
									}
									// rating stars
									if(styleVal['star_color']){
										style += '#'+selectorKey+' .tjs-rating-inner, '+'#'+selectorKey+' #stars-half-star, '+'#'+selectorKey+' #stars-full-star{ --activeStarColor:'+styleVal['star_color'] + ' !important;}';
										// style += '#'+selectorKey+' .bk-rating.bk-custom-rating > .bk-star-group:hover~span,'+ '#'+selectorKey+' .bk-rating.bk-custom-rating > .bk-star-group:hover,'+ '#'+selectorKey+' .bk-rating > .star { color :'+styleVal['star_color'] + ';}';
									}
								}
								// address card section
								if(secElem[selectorKey] === 'address'){
									if(styleVal['card_background_color'] || styleVal['border_radius'] || styleVal['section_border_width']){
										style += '#'+ selectorKey +' .bk-address-card { background-color:'+(styleVal['card_background_color']?styleVal['card_background_color'] :'') + '; border-radius:'+(styleVal['border_radius']?styleVal['border_radius']:'')+'px;}';
									}
									if(styleVal['card_border_color'] || styleVal['card_border_width']){
										style += '#'+ selectorKey +' .bk-card-border { border-color:'+(styleVal['card_border_color']?styleVal['card_border_color']:'')+'; border-width:'+styleVal['card_border_width']+'px;}';
									}
									if(styleVal['title_color'] || styleVal['title_font_size']){
										style += '#'+ selectorKey +' .bk-card-title { color:'+(styleVal['title_color']?styleVal['title_color']:'') + '; font-size:'+this.applyFontSize(styleVal['title_font_size'] ? styleVal['title_font_size'] : '')+';}';
									}
									if(styleVal['default_bg_color'] || styleVal['default_color']){
										style += '#'+ selectorKey +' .bk-default-tag { color:'+(styleVal['default_color']?styleVal['default_color']:'') + '; background-color:'+(styleVal['default_bg_color'] ? styleVal['default_bg_color'] : '') + ';}';
									}
									// card label, values
									if(styleVal['label_color'] || styleVal['value_color'] || styleVal['label_font_size'] || styleVal['value_font_size']){
										style += '#'+selectorKey+' .bk-card-label { color:'+(styleVal['label_color'] ? styleVal['label_color'] : '')+'; font-size:'+this.applyFontSize(styleVal['label_font_size'] ? styleVal['label_font_size'] : '')+'; }';
										style += '#'+selectorKey+' .bk-card-value { color:'+(styleVal['value_color'] ? styleVal['value_color'] : '')+'; font-size:'+this.applyFontSize(styleVal['value_font_size'] ? styleVal['value_font_size'] : '')+'; }';
									}
									if(styleVal['value_color']){
										style += '#'+selectorKey+' .bk-dropdown-btn { color:'+(styleVal['value_color'] ? styleVal['value_color'] : '')+'; }';
									}
								}
								// billing info page styling
								if(secElem[selectorKey] === 'billing'){
									if(styleVal['card_background_color']){
										style += '#'+ selectorKey +' .bk-credit-card { background-color:'+(styleVal['card_background_color'] ? styleVal['card_background_color']: '' ) + ';}';
									}
									if(styleVal['card_number_color'] || styleVal['card_number_font_size']){
										style += '#'+ selectorKey +' .bk-credit-card__number { color:'+(styleVal['card_number_color'] ? styleVal['card_number_color']: '' ) + '; font-size:'+this.applyFontSize(styleVal['card_number_font_size']?styleVal['card_number_font_size']:'') + ';}';
									}
									if(styleVal['expiry_label_color'] || styleVal['expiry_label_font_size']){
										style += '#'+ selectorKey +' .bk-credit-card__label { color:'+(styleVal['expiry_label_color'] ? styleVal['expiry_label_color']: '' ) + '; font-size:'+this.applyFontSize(styleVal['expiry_label_font_size']?styleVal['expiry_label_font_size']:'') + ';}';
									}
									if(styleVal['expiry_value_color'] || styleVal['expiry_value_font_size']){
										style += '#'+ selectorKey +' .bk-credit-card__value { color:'+(styleVal['expiry_value_color'] ? styleVal['expiry_value_color']: '' ) + '; font-size:'+this.applyFontSize(styleVal['expiry_value_font_size']?styleVal['expiry_value_font_size']:'') + ';}';
									}
									if(styleVal['default_bg_color'] || styleVal['default_color']){
										style += '#'+selectorKey+' .bk-default-badge { color:'+(styleVal['default_color'] ? styleVal['default_color'] : '')+';  background-color:'+(styleVal['default_bg_color'] ? styleVal['default_bg_color']: '' ) + ';}';
									}
									if(styleVal['gear_icon_color']){
										style += '#'+selectorKey+' .bk-settings-icon { color:'+(styleVal['gear_icon_color'] ? styleVal['gear_icon_color'] : '')+'; }';
									}
								}
								// billing info page having invoice section styling
								if(secElem[selectorKey] === 'invoice'){
									style += '#'+selectorKey+' .bk-invoice-card { border-radius:'+styleVal['border_radius']+'px;}';
									style += '#'+selectorKey+' .bk-active-tab {background-color:'+(styleVal['active_tab_background_color'] ? styleVal['active_tab_background_color']: '' ) + '; border-color:'+(styleVal['active_tab_border_color'] ? styleVal['active_tab_border_color'] : '')+'; color:'+((styleVal['active_tab_color']) ? styleVal['active_tab_color'] : '')+'; font-size:'+this.applyFontSize(styleVal['tab_font_size']?styleVal['tab_font_size']:'') + ';}';
									style += '#'+selectorKey+' .bk-tab {background-color:'+(styleVal['tab_background_color'] ? styleVal['tab_background_color']: '' ) + '; border-color:'+(styleVal['tab_border_color'] ? styleVal['tab_border_color'] : '')+'; color:'+((styleVal['tab_color']) ? styleVal['tab_color'] : '')+'; font-size:'+this.applyFontSize(styleVal['tab_font_size']?styleVal['tab_font_size']:'') + ';}';
									//
									if(styleVal['card_background_color']){
										style += '#'+selectorKey+' .bk-invoice-card { background-color:'+styleVal['card_background_color']+'; }';
									}
									if(styleVal['card_border_color'] || styleVal['card_border_width']){
										style += '#'+selectorKey+' .bk-card-border { border-color:'+(styleVal['card_border_color']?styleVal['card_border_color']:'')+'; border-width:'+styleVal['card_border_width']+'px;}';
									}
									if(styleVal['card_title_color'] || styleVal['card_title_font_size']){
										style += '#'+selectorKey+' .invoice-card-title { color:'+(styleVal['card_title_color']?styleVal['card_title_color']:'') + '; font-size:'+this.applyFontSize(styleVal['card_title_font_size'] ? styleVal['card_title_font_size'] : '')+';}';
									}
									if(styleVal['card_label_color'] || styleVal['card_value_color'] || styleVal['card_label_font_size'] || styleVal['value_font_size']){
										style += '#'+selectorKey+' .invoice-card-label { color:'+(styleVal['card_label_color'] ? styleVal['card_label_color'] : '')+'; font-size:'+this.applyFontSize(styleVal['card_label_font_size'] ? styleVal['card_label_font_size'] : '')+'; }';
										style += '#'+selectorKey+' .invoice-card-value { color:'+(styleVal['card_value_color'] ? styleVal['card_value_color'] : '')+'; font-size:'+this.applyFontSize(styleVal['card_value_font_size'] ? styleVal['card_value_font_size'] : '')+'; }';
									}
									if(styleVal['download_btn_bg_color'] || styleVal['download_btn_color'] || styleVal['download_btn_border_color'] || styleVal['download_btn_font_size']){
										style += '#'+selectorKey+' .invoice-download-btn { background-color:'+(styleVal['download_btn_bg_color'] ? styleVal['download_btn_bg_color'] : '')+'; color: '+(styleVal['download_btn_color'] ? styleVal['download_btn_color'] : '')+'; border-color:'+(styleVal['download_btn_border_color'] ? styleVal['download_btn_border_color'] : '')+'; font-size: '+this.applyFontSize(styleVal['download_btn_font_size'] ? styleVal['download_btn_font_size'] : '')+';}';
									}
									if(styleVal['view_rcpt_bg_color'] || styleVal['view_rcpt_border_color'] || styleVal['view_rcpt_color'] || styleVal['view_rcpt_font_size']){
										style += '#'+selectorKey+' .invoice-view-btn { background-color:'+(styleVal['view_rcpt_bg_color'] ? styleVal['view_rcpt_bg_color'] : '')+'; color: '+((styleVal['view_rcpt_color']) ? styleVal['view_rcpt_color'] : '')+'; border-color:'+(styleVal['view_rcpt_border_color'] ? styleVal['view_rcpt_border_color'] : '')+'; font-size: '+this.applyFontSize((styleVal['view_rcpt_font_size']) ? styleVal['view_rcpt_font_size'] : '')+';}';
									}
									if(styleVal['print_btn_bg_color'] || styleVal['print_btn_border_color'] || styleVal['print_btn_color'] || styleVal['print_btn_font_size']){
										style += '#'+selectorKey+' .invoice-print-btn { background-color:'+(styleVal['print_btn_bg_color'] ? styleVal['print_btn_bg_color'] : '')+'; color: '+((styleVal['print_btn_color']) ? styleVal['print_btn_color'] : '')+'; border-color:'+(styleVal['print_btn_border_color'] ? styleVal['print_btn_border_color'] : '')+'; font-size: '+this.applyFontSize(styleVal['print_btn_font_size'] ? styleVal['print_btn_font_size'] : '')+';}';
									}
								}
								// My drive page & edit-profile page styling
								if(secElem[selectorKey] === 'form'){
									if(styleVal['border-color'] || styleVal['border_width']){
										style += '#'+selectorKey+' .bk-border { border-color:'+((styleVal['border-color'])? styleVal['border-color']: '')+'; border-width:'+((styleVal['border_width'])? styleVal['border_width']: '')+'px;}';
									}
									if(styleVal['tooltip_color']){
										style += '#'+selectorKey+' .tjs-tooltip-icon { color:'+((styleVal['tooltip_color']) ? styleVal['tooltip_color'] + '!important' : '')+';}';
									}
									if(styleVal['desc_color']){
										style += '#'+selectorKey+' .bk-desc { color:'+((styleVal['desc_color']) ? styleVal['desc_color'] : '')+';}';
									}
									if(styleVal['add_color']){
										style += '#'+selectorKey+' .bk-add { color:'+((styleVal['add_color']) ? styleVal['add_color'] : '')+';}';
									}
									if(styleVal['del_color']){
										style += '#'+selectorKey+' .bk-del { color:'+((styleVal['del_color']) ? styleVal['del_color'] : '')+';}';
									}
									if(styleVal['label_color']){
										style += '#'+selectorKey+' .bk-label { color:'+((styleVal['label_color']) ? styleVal['label_color'] : '')+';}';
									}
									if(styleVal['button_background_color'] || styleVal['button_color']){
										style += '#'+selectorKey+' .tjs-custom-file .upload-file__label:after { background-color:'+(styleVal['button_background_color'] ? styleVal['button_background_color'] : '')+'; color:' +(styleVal['button_color'] ? styleVal['button_color'] : '')+ '; content: attr(data-browse-label);}';
									}
									if(styleVal['color'] || styleVal['font-size']){
										style += '#'+selectorKey+' .bk-tooltip { color:'+((styleVal['color']) ? styleVal['color'] : '')+'; font-size: '+this.applyFontSize(styleVal['font-size'] ? styleVal['font-size'] : '')+';}';
									}
								}
								// Table styling
								if(secElem[selectorKey] === 'sent_list' || secElem[selectorKey] === 'received_list' || secElem[selectorKey] === 'listing'){
									if(styleVal['body_background_color'] || styleVal['body_color']){
										style += `#${selectorKey} .bk-table tbody tr, #${selectorKey} .bk-bg--color { background-color: ${styleVal['body_background_color'] ? styleVal['body_background_color']:''}; color: ${styleVal['body_color'] ? styleVal['body_color'] : ''}; }`;
									}
									if(styleVal['body_font_size']){
										style += `#${selectorKey} .bk-table .td-text--fs { font-size: ${this.applyFontSize(styleVal['body_font_size'])}; }`;
									}
									if(styleVal['body_color']){
										style += `#${selectorKey} .bk-table .td-text--color, #${selectorKey} .text--color {  color: ${styleVal['body_color']}; }`;
									}
									if(styleVal['header_background_color'] || styleVal['header_font_size']){
										style += `#${selectorKey} .bk-table thead th { background-color:${styleVal['header_background_color'] ? styleVal['header_background_color']:''}; color:${styleVal['header_color'] ? styleVal['header_color']:''}; font-size:${this.applyFontSize((styleVal['header_font_size'] ? styleVal['header_font_size'] : ''))}; }`;
									}
									if(styleVal['header_color']){
										style += `#${selectorKey} .bk-table thead th {color:${styleVal['header_color']}; }`;
										style += `#${selectorKey} .bk-table.tjs-table--responsive tbody td[data-label]:before { color:${styleVal['header_color']}; }`;
									}
									style += `#${selectorKey} .bk-table .action-btn { font-size:${this.applyFontSize((styleVal['action_btn_font_size'] ? styleVal['action_btn_font_size'] : ''))}; color: ${styleVal['action_btn_color'] ? styleVal['action_btn_color'] : ''};}`;
									style += `#${selectorKey} .bk-table .action-btn__download { color:${styleVal['download_btn_color'] ? styleVal['download_btn_color']:''}; font-size:${this.applyFontSize((styleVal['download_btn_font_size'] ? styleVal['download_btn_font_size'] : ''))}}`
									style += `#${selectorKey} .bk-table .action-btn__edit { color:${styleVal['edit_btn_color'] ? styleVal['edit_btn_color']:''}; }`
									style += `#${selectorKey} .bk-table .action-btn__delete { color:${styleVal['delete_btn_color'] ? styleVal['delete_btn_color']:''}; font-size:${this.applyFontSize((styleVal['delete_btn_font_size'] ? styleVal['delete_btn_font_size'] : ''))}}`
									style += `#${selectorKey} .bk-table .tooltip--color { color:${styleVal['tooltip_color'] ? styleVal['tooltip_color']:''}; }`
									style += `#${selectorKey} .bk-table .td-badge { background-color: ${styleVal['badge_bg_color'] ? styleVal['badge_bg_color']:''}; color:${styleVal['badge_color'] ? styleVal['badge_color']:''}; }`
								}
							}
							/**Element style with parent selector*/
							if(secElem && this.groupedSec && (this.groupedSec.length > 0) && this.groupedSec.includes(secElem[selectorKey])){
								if(secElem[selectorKey] === 'give_us_call'){
									style += '#'+ selectorKey +' .bk-gp-sub-title, #'+selectorKey+' .bk-gp-title{ color:'+styleVal['color'] + ';}'
								}
								if(secElem[selectorKey] === 'list' || (secElem[selectorKey] === 'get_in_touch') || (secElem[selectorKey] === 'categories')){
									if(styleVal['background-color']){
										style += '#'+selectorKey+'{ background-color:transparent;}';
										style += '#'+selectorKey+' .bk-item{ background-color:'+styleVal['background-color'] + ';}';
									}
									if(styleVal['title_color']){
										style += '#'+selectorKey+' .bk-item-title{ color:'+styleVal['title_color'] + ';}';
									}
									if(styleVal['sub_title_color']){
										style += '#'+selectorKey+' .bk-item-sub-title{ color:'+styleVal['sub_title_color'] + ';}';
									}
									if(styleVal['desc_color']){
										style += '#'+selectorKey+' .bk-item-desc{ color:'+styleVal['desc_color'] + ';}';
									}
									if(styleVal['img_background_color']){
										style += '#'+selectorKey+' .bk-item-img{ background-color:'+styleVal['img_background_color'] + ';}';
									}
									if(styleVal['read_more_color']){
										style += '#'+selectorKey+' .bk-item-read-more{ color:'+styleVal['read_more_color'] + ';}';
									}
									if(styleVal['card_footer_background_color']){
										style += '#'+selectorKey+' .bk-item-footer{ background-color:'+styleVal['card_footer_background_color'] + ';}';
									}
									if(styleVal['btn_background_color'] || styleVal['btn_border_color'] || styleVal['btn_color']){
										style += '#'+selectorKey+' .bk-item-btn{ background-color:'+(styleVal['btn_background_color'] ? styleVal['btn_background_color'] : '') + '; color:'+(styleVal['btn_color'] ? styleVal['btn_color'] : '')+ '; border-color:'+(styleVal['btn_border_color'] ? styleVal['btn_border_color'] : '')+';}';
									}
								}
								/**Earn Credits ***/
								if(secElem[selectorKey] === 'summary'){
									style += `.${selectorKey}-tooltip{color:${styleVal['color']};}`
								}
								/**Live Reviews */
								if(secElem[selectorKey] === 'review'){
									if(styleVal['color']){
										style += '#'+selectorKey+' .bk-tooltip, ' + '#'+selectorKey+' .bk-title{ color:'+styleVal['color'] + ';}';
									}
								}
								if(secElem[selectorKey] === 'review_card' || secElem[selectorKey] === 'reviews'){
									if(styleVal['background-color']){
										style += '#'+selectorKey+'{ background-color:transparent;}';
										style += '#'+selectorKey+' .bk-slider-item{ background-color:'+styleVal['background-color'] + ';}';
									}
									if(styleVal['name_color'] || styleVal['name_font_size']){
										style += '#'+selectorKey+' .bk-slider-item-title{ color:'+(styleVal['name_color']?styleVal['name_color']:'') + '; font-size:'+this.applyFontSize((styleVal['name_font_size']?styleVal['name_font_size']:'')) + '}';
									}
									if(styleVal['review_color'] || styleVal['review_font_size']){
										style += '#'+selectorKey+' .bk-slider-item-desc{ color:'+(styleVal['review_color']?styleVal['review_color']:'') + '; font-size:'+this.applyFontSize((styleVal['review_font_size']?styleVal['review_font_size']:'')) + '}';
									}
									if(styleVal['star_color']){
										style += '#'+selectorKey+' .tjs-rating-inner, '+'#'+selectorKey+' #stars-half-star, '+'#'+selectorKey+' #stars-full-star{ --activeStarColor:'+styleVal['star_color'] + ' !important;}';
									}
									if(styleVal['read_more_color']){
										style += '#'+selectorKey+' .bk-slider-read-more{ color:'+styleVal['read_more_color'] + ';}';
									}
								}
								/**Service List */
								if(secElem[selectorKey] === 'services'){
									if(styleVal['alt_background_color']){
										style += '#'+selectorKey+' .bk-item{ background-color:'+styleVal['alt_background_color'] + ';}';
									}
									if(styleVal['alt_title_color']){
										style += '#'+selectorKey+' .bk-item-title{ color:'+styleVal['alt_title_color'] + ';}';
									}
									if(styleVal['alt_desc_color']){
										style += '#'+selectorKey+' .bk-item-desc{ color:'+styleVal['alt_desc_color'] + ';}';
									}
									if(styleVal['background-color']){
										// style += '#'+selectorKey+'{ background-color:transparent;}';
										style += '#'+selectorKey+' .bk-item:nth-child(2n){ background-color:'+styleVal['background-color'] + ';}';
									}
									if(styleVal['title_color']){
										style += '#'+selectorKey+' .bk-item:nth-child(2n) .bk-item-title{ color:'+styleVal['title_color'] + ';}';
									}
									if(styleVal['desc_color']){
										style += '#'+selectorKey+' .bk-item:nth-child(2n) .bk-item-desc{ color:'+styleVal['desc_color'] + ';}';
									}
									if(styleVal['btn_bg_color'] || styleVal['btn_border_color'] || styleVal['btn_color']){
										style += '#'+selectorKey+' .bk-item-btn{ background-color:'+(styleVal['btn_bg_color'] ? styleVal['btn_bg_color'] : '') + '; color:'+(styleVal['btn_color'] ? styleVal['btn_color'] : '')+ '; border-color:'+(styleVal['btn_border_color'] ? styleVal['btn_border_color'] : '')+';}';
									}
								}
								/**Discount Bar */
								if(secElem[selectorKey] === 'discount_bar'){
									if(styleVal['background-color']){
										style += '#'+selectorKey+' .bk-discount-bar{ background-color:'+styleVal['background-color'] + ';}';
									}
									if((styleVal['clock_timer_color']) || (styleVal['clock_timer_font_size'])){
										style += '#'+selectorKey+' .bk-discount-clock-timer{ color:'+(styleVal['clock_timer_color'] ? styleVal['clock_timer_color'] : '') + '; font-size:'+this.applyFontSize((styleVal['clock_timer_font_size'] ? styleVal['clock_timer_font_size'] : ''))+';}';
									}
									if((styleVal['clock_unit_color']) || (styleVal['clock_unit_font_size'])){
										style += '#'+selectorKey+' .bk-discount-clock-unit{ color:'+(styleVal['clock_unit_color'] ? styleVal['clock_unit_color'] : '') + '; font-size:'+this.applyFontSize((styleVal['clock_unit_font_size'] ? styleVal['clock_unit_font_size'] : ''))+';}';
									}
									if((styleVal['terms_of_deal_color']) || (styleVal['terms_of_deal_font_size'])){
										style += '#'+selectorKey+' .bk-discount-term{ color:'+(styleVal['terms_of_deal_color'] ? styleVal['terms_of_deal_color'] : '') + '; font-size:'+this.applyFontSize((styleVal['terms_of_deal_font_size'] ? styleVal['terms_of_deal_font_size'] : ''))+';}';
									}
								}
								/**Lead Form */
								if(secElem[selectorKey] === 'faq'){
									if((styleVal['que_color']) || (styleVal['que_font_size'])){
										style += '#'+selectorKey+' .bk-accordion-item-btn, '+'#'+selectorKey+' .bk-accordion-item-title{ color:'+(styleVal['que_color'] ? styleVal['que_color'] : '') + '; font-size:'+this.applyFontSize((styleVal['que_font_size'] ? styleVal['que_font_size'] : ''))+';}';
									}
									if((styleVal['ans_color']) || (styleVal['ans_font_size'])){
										style += '#'+selectorKey+' .bk-accordion-item-desc{ color:'+(styleVal['ans_color'] ? styleVal['ans_color'] : '') + '; font-size:'+this.applyFontSize((styleVal['ans_font_size'] ? styleVal['ans_font_size'] : ''))+';}';
									}
								}
								/**
								 * Reauth page
								 */
								if(sectionType === 'reauth-card'){
									if(styleVal['color'] || styleVal['font-size']){
										style += '#'+selectorKey+' .bk-form-label { color:'+((styleVal['color']) ? styleVal['color'] : '')+'; font-size: '+this.applyFontSize(styleVal['font-size'] ? styleVal['font-size'] : '')+';}';
									}
								}
								/**Booking Form */
								if(secElem[selectorKey] === 'form'){
									if(styleVal['color']){
										style += '#'+selectorKey+' .bk-form-label{ color:'+styleVal['color'] + ';}';
									}
									if(styleVal['label_color']){
										style += '#'+selectorKey+' .bk-form-label-clr, '+'#'+selectorKey+' .bk-form-check-label,'+'#'+selectorKey+' .bk-form-label, '+'#'+selectorKey+' .bk-tooltip{ color:'+styleVal['label_color'] + ';}';
									}
									if(styleVal['label_font_size']){
										style += '#'+selectorKey+' .bk-form-check-label, '+'#'+selectorKey+' .bk-label-fs, '+'#'+selectorKey+' .bk-form-label{ font-size:'+this.applyFontSize(styleVal['label_font_size']) + ';}';
										if(isMultiStep){
											style += '#'+selectorKey+' .bk-form-sub-label{ font-size:'+this.applyFontSize((styleVal['label_font_size']?styleVal['label_font_size']:'')) + ';}';
										}
									}
									if(styleVal['sub_label_color'] || styleVal['sub_label_font_size']){
										style += '#'+selectorKey+' .bk-form-sub-label{ color:'+(styleVal['sub_label_color'] ? styleVal['sub_label_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['sub_label_font_size']?styleVal['sub_label_font_size']:'')) + ';}';
										if(styleVal['sub_label_color']){
											style += '#'+selectorKey+' .bk-form-sub-label .bk-tooltip{ color:'+styleVal['sub_label_color']+ ';}';
										}
									}
									if(styleVal['title_color'] || styleVal['title_font_size']){
										style += '#'+selectorKey+' .bk-form-title{ color:'+(styleVal['title_color'] ? styleVal['title_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['title_font_size']?styleVal['title_font_size']:'')) + ';}';
									}
									if(styleVal['title_font_weight']){
										style += '#'+selectorKey+' .bk-form-title{ font-weight:'+styleVal['title_font_weight']+ ';}';
									}
									if(styleVal['heading_color'] || styleVal['heading_font_size']){
										style += '#'+selectorKey+' .bk-form-heading{ color:'+(styleVal['heading_color'] ? styleVal['heading_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['heading_font_size']?styleVal['heading_font_size']:'')) + ';}';
									}
									if(styleVal['heading_font_weight']){
										style += '#'+selectorKey+' .bk-form-heading{ font-weight:'+styleVal['heading_font_weight']+ ';}';
									}
									if(styleVal['desc_color'] || styleVal['desc_font_size']){
										if(styleVal['desc_color']){
											style += '#'+selectorKey+' .bk-desc-clr{ color:'+styleVal['desc_color'] + ';}';
										}
										style += '#'+selectorKey+' .bk-form-desc{ color:'+(styleVal['desc_color']?styleVal['desc_color']:'') + ';font-size:'+this.applyFontSize((styleVal['desc_font_size']?styleVal['desc_font_size']:'')) + ';}';
									}
									if(sectionType === 'booking_form'){
										if(styleVal['error_color']){
											style += '#'+selectorKey+' .bk-error{ color:'+styleVal['error_color'] + ';}';
											style += '#'+selectorKey+' .form-control.is-invalid{ border-color:'+styleVal['error_color'] + ';}';
										}
										if(styleVal['card_border_color']){
											style += '#'+selectorKey+' .bk-card-border{ border-color:'+styleVal['card_border_color'] + ';}';
										}
										/**Frequency Buttons */
										if(styleVal['button_background_color'] || styleVal['button_border_color'] || styleVal['button_color']){
											style += '#'+selectorKey+' .bk-form-check-btn{ color:'+(styleVal['button_color']?styleVal['button_color']:'') + ';background-color:'+(styleVal['button_background_color']?styleVal['button_background_color']:'') + ';border-color:'+(styleVal['button_border_color']?styleVal['button_border_color']:'') + ';font-size:'+this.applyFontSize((styleVal['button_font_size']?styleVal['button_font_size']:'')) + ';}';
										}
										if(styleVal['button_active_background_color'] || styleVal['button_active_border_color'] || styleVal['button_active_color']){
											style += '#'+selectorKey+' .bk-form-check-input:checked + label{ color:'+(styleVal['button_active_color']?styleVal['button_active_color']:'') + ' !important;background-color:'+(styleVal['button_active_background_color']?styleVal['button_active_background_color']:'') + ' !important;border-color:'+(styleVal['button_active_border_color']?styleVal['button_active_border_color']:'') + ' !important;}';
										}
										if(styleVal['button_tooltip_color']){
											style += '#'+selectorKey+' .bk-form-check-tooltip{ color:'+styleVal['button_tooltip_color'] + ';}';
										}
										if(styleVal['button_active_tooltip_color']){
											style += '#'+selectorKey+' .bk-form-check-input:checked + label .bk-form-check-tooltip{ color:'+styleVal['button_active_tooltip_color'] + '!important;}';
										}

										/**Extras */
										if(styleVal['border_color']){
											style += '#'+selectorKey+' .bk-form-extra{ border-color:'+styleVal['border_color'] + ';}';
										}
										if(styleVal['increase_button_hover_background_color'] || styleVal['increase_button_hover_color']){
											style += '#'+selectorKey+' .bk-form-extra-inc:hover{ color:'+(styleVal['increase_button_hover_color']?styleVal['increase_button_hover_color']:'') + ';background-color:'+(styleVal['increase_button_hover_background_color']?styleVal['increase_button_hover_background_color']:'') + ';}';
										}
										if(styleVal['decrease_button_hover_background_color'] || styleVal['decrease_button_hover_color']){
											style += '#'+selectorKey+' .bk-form-extra-dec:hover{ color:'+(styleVal['decrease_button_hover_color']?styleVal['decrease_button_hover_color']:'') + ';background-color:'+(styleVal['decrease_button_hover_background_color']?styleVal['decrease_button_hover_background_color']:'') + ';}';
										}
										if(styleVal['overlay_color']){
											style += '#'+selectorKey+' .bk-form-extra:hover .bk-form-extra-overlay,'+'#'+selectorKey+' .bk-form-extra-overlay{ background-color:'+styleVal['overlay_color'] + ';}';
											style += '#'+selectorKey+' .bk-form-extra:not(.selected-extra):hover{ background-color:transparent;}';
											if(!IS_MOBILE){
												style += '@media(hover:hover){'+ '#'+selectorKey+' .bk-form-extra:hover .bk-form-extra-overlay{ opacity:1;}}';
											}
										}
										if(styleVal['overlay_text_color']){
											style += '#'+selectorKey+' .bk-form-extra-desc{ color:'+styleVal['overlay_text_color'] + ';}';
										}

										/**Discount */
										if(styleVal['tab_background_color'] || styleVal['tab_color'] || styleVal['tab_font_size']){
											style += '#'+selectorKey+' .bk-form-discount-tab{ color:'+(styleVal['tab_color']?styleVal['tab_color']:'') + ';background-color:'+(styleVal['tab_background_color']?styleVal['tab_background_color']:'') + ';font-size:'+this.applyFontSize((styleVal['tab_font_size']?styleVal['tab_font_size']:'')) + ';}';
										}
										if(styleVal['active_tab_background_color'] || styleVal['active_tab_color']){
											style += '#'+selectorKey+' .bk-form-discount-tab:hover, '+'#'+selectorKey+' .bk-form-discount-tab.active{ color:'+(styleVal['active_tab_color']?styleVal['active_tab_color']:'') + ';background-color:'+(styleVal['active_tab_background_color']?styleVal['active_tab_background_color']:'') + ';}';
										}
										if(styleVal['active_tab_border_color']){
											style += '#'+selectorKey+' .bk-form-discount-tab:hover:after, '+'#'+selectorKey+' .bk-form-discount-tab.active:after{ background-color:'+styleVal['active_tab_border_color'] + ';}';
										}
										if(styleVal['tab_content_background_color']){
											style += '#'+selectorKey+' .bk-form-discount-tab-content{ background-color:'+styleVal['tab_content_background_color'] + ';}';
										}
										if(styleVal['tab_button_background_color'] || styleVal['tab_button_color'] || styleVal['tab_button_border_color']){
											style += '#'+selectorKey+' .bk-form-discount-btn:not(.btn-danger){ color:'+(styleVal['tab_button_color']?styleVal['tab_button_color']:'') + ';background-color:'+(styleVal['tab_button_background_color']?styleVal['tab_button_background_color']:'') + ';border-color:'+(styleVal['tab_button_border_color']?styleVal['tab_button_border_color']:'') + ';}';
										}
										if(styleVal['tab_button_font_size']){
											style += '#'+selectorKey+' .bk-form-discount-btn{ font-size :'+styleVal['tab_button_font_size'] + ';}';
										}
										if(styleVal['tab_label_color'] || styleVal['tab_label_font_size']){
											style += '#'+selectorKey+' .bk-form-discount-label{ color:'+(styleVal['tab_label_color'] ? styleVal['tab_label_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['tab_label_font_size']?styleVal['tab_label_font_size']:'')) + ';}';
											style += '#'+selectorKey+' .bk-form-discount-desc{ color:'+(styleVal['tab_label_color'] ? styleVal['tab_label_color'] : '') + ';}';
										}

										/**Packages */
										if(styleVal['package_slider_icon_color']){
											style += '#'+selectorKey+' .bk-form-slick-arrow, .bk-package-popup .bk-form-slick-arrow{ color:'+styleVal['package_slider_icon_color'] + ';}';
										}
										if(styleVal['package_background_color']){
											style += '#'+selectorKey+' .bk-form-package, .bk-package-popup .bk-form-package{ background-color:'+styleVal['package_background_color'] + ';}';
										}
										if(styleVal['package_border_color']){
											style += '#'+selectorKey+' .bk-form-package-border, .bk-package-popup .bk-form-package-border{ border-color:'+styleVal['package_border_color'] + ';}';
										}
										if(styleVal['package_color'] || styleVal['package_font_size']){
											style += '#'+selectorKey+' .bk-form-package-label, .bk-package-popup .bk-form-package-label{ color:'+(styleVal['package_color']?styleVal['package_color']:'') + ';font-size:'+this.applyFontSize((styleVal['package_font_size']?styleVal['package_font_size']:'')) + ';}';
										}

										if(styleVal['selected_package_background_color']){
											style += '#'+selectorKey+' .bk-form-selected-package, .bk-package-popup .bk-form-selected-package{ background-color:'+styleVal['selected_package_background_color'] + ';}';
										}
										if(styleVal['selected_package_color'] || styleVal['selected_package_font_size']){
											style += '#'+selectorKey+' .bk-form-selected-package-label, .bk-package-popup .bk-form-selected-package-label{ color:'+(styleVal['selected_package_color']?styleVal['selected_package_color']:'') + ';font-size:'+this.applyFontSize((styleVal['selected_package_font_size']?styleVal['selected_package_font_size']:'')) + ';}';
										}

										if(styleVal['add_package_btn_background_color'] || styleVal['add_package_btn_border_color'] || styleVal['add_package_btn_color']){
											style += '#'+selectorKey+' .package-hover:hover .bk-form-package-btn, .bk-package-popup .package-hover:hover .bk-form-package-btn{ background-color:'+(styleVal['add_package_btn_background_color']?styleVal['add_package_btn_background_color']:'') + ';color:'+(styleVal['add_package_btn_color']?styleVal['add_package_btn_color']:'') + ';border-color:'+(styleVal['add_package_btn_border_color']?styleVal['add_package_btn_border_color']:'') + ';}';
											style += '#'+selectorKey+' .bk-form-package-btn,.bk-package-popup .bk-form-package-btn{ color:'+(styleVal['add_package_btn_background_color']?styleVal['add_package_btn_background_color']:'') + ';border-color:'+(styleVal['add_package_btn_border_color']?styleVal['add_package_btn_border_color']:'') + ';}';
										}
										if(styleVal['selected_item_background_color']){
											style += '#'+selectorKey+' .bk-form-selected-item{ background-color:'+styleVal['selected_item_background_color'] + ';}';
										}
										if(styleVal['selected_item_color'] || styleVal['selected_item_font_size']){
											style += '#'+selectorKey+' .bk-form-selected-label{ color:'+(styleVal['selected_item_color']?styleVal['selected_item_color']:'') + ';font-size:'+this.applyFontSize((styleVal['selected_item_font_size']?styleVal['selected_item_font_size']:'')) + ';}';
										}

										/**MultiStep Form*/
										if(styleVal['label_color'] || styleVal['border_color']){
											style += '#'+selectorKey+' .bk-form-multi-tab .bk-form-multi-tab-label{ color:'+(styleVal['label_color']?styleVal['label_color']:'') + ';border-color:'+(styleVal['border_color']?styleVal['border_color']:'') + ';}';
										}
										if(styleVal['active_industry_border_color'] || styleVal['active_industry_label_color']){
											style += '#'+selectorKey+' .bk-form-multi-tab .bk-form-check:checked + .bk-form-multi-tab-label{ color:'+(styleVal['active_industry_label_color']?styleVal['active_industry_label_color']:'') + '!important;border-color:'+(styleVal['active_industry_border_color']?styleVal['active_industry_border_color']:'') + '!important;}';
										}
										if(styleVal['header_background_color']){
											style += '#'+selectorKey+' .bk-form-multi-header-ele:not(.multistep__header--logo){ background-color:'+styleVal['header_background_color'] + ';}';
										}
										if(styleVal['header_active_step_border_color']){
											style += '#'+selectorKey+' .bk-form-multi-header-ele:before{ background-color:'+styleVal['header_active_step_border_color'] + ';}';
										}
										if(styleVal['header_logo_background_color']){
											style += '#'+selectorKey+' .multistep__header--logo, '+'#'+selectorKey+' .multistep__responsive--logo{ background-color:'+styleVal['header_logo_background_color'] + ';}';
										}
										if(styleVal['header_title_color']){
											style += '#'+selectorKey+' .bk-form-multi-header-label{ color:'+styleVal['header_title_color'] + ';}';
										}
										if(styleVal['header_description_color']){
											style += '#'+selectorKey+' .bk-form-multi-header-desc{ color:'+styleVal['header_description_color'] + ';}';
										}
										if(styleVal['payment_summary_background_color']){
											style += '#'+selectorKey+' .bk-form-multi-header-summary{ background-color:'+styleVal['payment_summary_background_color'] + ';}';
										}
										if(styleVal['payment_summary_color']){
											style += '#'+selectorKey+' .bk-form-multi-header-summary-label{ color:'+styleVal['payment_summary_color'] + ';}';
										}
										if(styleVal['card_background_color']){
											style += '#'+selectorKey+' .bk-form-multistep-wrap{ background-color:'+styleVal['card_background_color'] + ';}';
										}
									}

									if(styleVal['name_color'] || styleVal['name_font_size']){
										style += '#'+selectorKey+' .bk-popupName{ color:'+styleVal['name_color'] + '; font-size:'+styleVal['name_font_size'] + '; }';
									}

									if(styleVal ['date_time_color'] || styleVal['date_time_font_size']){
										style += '#'+selectorKey+' .bk-dateTime{ color:'+styleVal['date_time_color'] + '; font-size:'+styleVal['date_time_font_size']+ '; }';
									}

									// if(styleVal['star_color']){
									// 	style += '#'+selectorKey+' .bk-rating.bk-custom-rating > .bk-star-group:hover~span,' + '#'+selectorKey+' .bk-rating.bk-custom-rating > .bk-star-group:hover { color :'+styleVal['star_color'] + '! important;}';
									// }
									if(styleVal['add_color']){
										style += '#'+selectorKey+' .bk-add { color:'+((styleVal['add_color']) ? styleVal['add_color'] : '')+';}';
									}
									if( styleVal['del_color']){
										style += '#'+selectorKey+' .bk-del { color:'+((styleVal['del_color']) ? styleVal['del_color'] : '')+';}';
									}

								}
								if(sectionType !== 'booking_form' && styleVal['border-color']){
									style += '#'+selectorKey+' .bk-form-border{ border-color:'+styleVal['border-color'] + ';}';
								}
								/**Booking Summary */
								if(((secElem[selectorKey] === 'booking_summary') || (secElem[selectorKey] === 'header_summary')) && (sectionType === 'booking_form')){
									if(styleVal['summary_background_color']){
										style += '#'+selectorKey+' .bk-form-summary{ background-color:'+styleVal['summary_background_color']+ ';}';
									}
									if(styleVal['summary_border_color']){
										style += '#'+selectorKey+' .bk-form-sum-list{ border-color:'+styleVal['summary_border_color'] + ';}';
									}
									if(styleVal['summary_heading_color'] || styleVal['summary_heading_font_size']){
										style += '#'+selectorKey+' .bk-form-summary-header .accordion-header, '+'#'+selectorKey+' .bk-form-summary-header{ color:'+(styleVal['summary_heading_color']?styleVal['summary_heading_color']:'') + ';font-size:'+this.applyFontSize((styleVal['summary_heading_font_size']?styleVal['summary_heading_font_size']:'')) + ';}';
									}
									if(styleVal['parameter_font_size']){
										style += '#'+selectorKey+' .bk-form-sum-desc, '+'#'+selectorKey+' .bk-form-sum-label{ font-size:'+this.applyFontSize(styleVal['parameter_font_size']) + ';}';
									}
									if(styleVal['parameter_label_color'] || styleVal['parameter_label_font_weight']){
										style += '#'+selectorKey+' .bk-form-sum-label{ color:'+styleVal['parameter_label_color'] + '; font-weight:'+(styleVal['parameter_label_font_weight'] ? styleVal['parameter_label_font_weight'] : '')+';}';
									}
									if(styleVal['parameter_value_color'] || styleVal['parameter_value_font_weight']){
										style += '#'+selectorKey+' .bk-form-sum-desc .bk-tooltip, ' + '#'+selectorKey+' .bk-form-sum-desc{ color:'+styleVal['parameter_value_color'] + '; font-weight:'+(styleVal['parameter_value_font_weight'] ? styleVal['parameter_value_font_weight'] : '')+';}';
									}

									/**Total Before tax */
									if(styleVal['total_before_tax_background_color'] || styleVal['total_before_tax_margin_top'] || styleVal['total_before_tax_margin_right'] || styleVal['total_before_tax_margin_bottom'] || styleVal['total_before_tax_margin_left'] || styleVal['total_before_tax_padding_top'] || styleVal['total_before_tax_padding_right'] || styleVal['total_before_tax_padding_bottom'] || styleVal['total_before_tax_padding_left']){
										style += '#'+selectorKey+' .bk-form-sum-tbt{ background-color:'+(styleVal['total_before_tax_background_color']?styleVal['total_before_tax_background_color']:'') + ';'+this.applySpacing(styleVal,'total_before_tax','margin') + ''+this.applySpacing(styleVal,'total_before_tax','padding')+';}';
									}
									if(styleVal['total_before_tax_font_size']){
										style += '#'+selectorKey+' .bk-form-sum-tbt-label, '+'#'+selectorKey+' .bk-form-sum-tbt-value{ font-size:'+this.applyFontSize(styleVal['total_before_tax_font_size']) + ';}';
									}
									if(styleVal['total_before_tax_label_color'] || styleVal['total_before_tax_label_font_weight']){
										style += '#'+selectorKey+' .bk-form-sum-tbt-label{ color:'+styleVal['total_before_tax_label_color'] + '; font-weight:'+(styleVal['total_before_tax_label_font_weight'] ? styleVal['total_before_tax_label_font_weight'] : '')+';}';
									}
									if(styleVal['total_before_tax_value_color'] || styleVal['total_before_tax_value_font_weight']){
										style += '#'+selectorKey+' .bk-form-sum-tbt-value{ color:'+styleVal['total_before_tax_value_color'] + '; font-weight:'+(styleVal['total_before_tax_value_font_weight'] ? styleVal['total_before_tax_value_font_weight'] : '')+';}';
									}
									/**Total */
									if(styleVal['total_background_color'] || styleVal['total_margin_top'] || styleVal['total_margin_right'] || styleVal['total_margin_bottom'] || styleVal['total_margin_left'] || styleVal['total_padding_top'] || styleVal['total_padding_right'] || styleVal['total_padding_bottom'] || styleVal['total_padding_left']){
										style += '#'+selectorKey+' .bk-form-sum-total{ background-color:'+(styleVal['total_background_color']?styleVal['total_background_color']:'') + ';'+this.applySpacing(styleVal,'total','margin') + ''+this.applySpacing(styleVal,'total','padding')+';}';
									}
									if(styleVal['total_font_size']){
										style += '#'+selectorKey+' .bk-form-sum-total-label, '+'#'+selectorKey+' .bk-form-sum-total-value{ font-size:'+this.applyFontSize(styleVal['total_font_size']) + ';}';
									}
									if(styleVal['total_label_color'] || styleVal['total_label_font_weight']){
										style += '#'+selectorKey+' .bk-form-sum-total-label{ color:'+styleVal['total_label_color'] + '; font-weight:'+(styleVal['total_label_font_weight'] ? styleVal['total_label_font_weight'] : '')+';}';
									}
									if(styleVal['total_value_color']  || styleVal['total_value_font_weight']){
										style += '#'+selectorKey+' .bk-form-sum-total-value{ color:'+styleVal['total_value_color'] + '; font-weight:'+(styleVal['total_value_font_weight'] ? styleVal['total_value_font_weight'] : '')+';}';
									}

									/**Next N APT Total */
									if(styleVal['next_n_apt_total_background_color'] || styleVal['next_n_apt_total_margin_bottom'] || styleVal['next_n_apt_total_margin_right'] || styleVal['next_n_apt_total_margin_top'] || styleVal['next_n_apt_total_margin_left'] || styleVal['next_n_apt_total_padding_top'] || styleVal['next_n_apt_total_padding_right'] || styleVal['next_n_apt_total_padding_bottom'] || styleVal['next_n_apt_total_padding_left']){
										style += '#'+selectorKey+' .bk-form-sum-nxt-apt{ background-color:'+(styleVal['next_n_apt_total_background_color']?styleVal['next_n_apt_total_background_color']:'') + ';'+this.applySpacing(styleVal,'next_n_apt_total','margin') + ''+this.applySpacing(styleVal,'next_n_apt_total','padding')+';}';
									}
									if(styleVal['next_n_apt_total_font_size']){
										style += '#'+selectorKey+' .bk-form-sum-nxt-apt-label, '+'#'+selectorKey+' .bk-form-sum-nxt-apt-value{ font-size:'+this.applyFontSize(styleVal['next_n_apt_total_font_size']) + ';}';
									}
									if(styleVal['next_n_apt_total_label_color'] || styleVal['next_n_apt_total_label_font_weight']){
										style += '#'+selectorKey+' .bk-form-sum-nxt-apt-label{ color:'+styleVal['next_n_apt_total_label_color'] + '; font-weight:'+(styleVal['next_n_apt_total_label_font_weight'] ? styleVal['next_n_apt_total_label_font_weight'] : '')+';}';
									}
									if(styleVal['next_n_apt_total_value_color'] || styleVal['next_n_apt_total_value_font_weight']){
										style += '#'+selectorKey+' .bk-form-sum-nxt-apt-value{ color:'+styleVal['next_n_apt_total_value_color'] + '; font-weight:'+(styleVal['next_n_apt_total_value_font_weight'] ? styleVal['next_n_apt_total_value_font_weight'] : '')+';}';
									}

									/**Recurring Total After N APT */
									if(styleVal['rec_total_after_n_apt_background_color'] || styleVal['rec_total_after_n_apt_margin_bottom'] || styleVal['rec_total_after_n_apt_margin_right'] || styleVal['rec_total_after_n_apt_margin_top'] || styleVal['rec_total_after_n_apt_margin_left'] || styleVal['rec_total_after_n_apt_padding_top'] || styleVal['rec_total_after_n_apt_padding_right'] || styleVal['rec_total_after_n_apt_padding_bottom'] || styleVal['rec_total_after_n_apt_padding_left']){
										style += '#'+selectorKey+' .bk-form-sum-rec-apt{ background-color:'+(styleVal['rec_total_after_n_apt_background_color']?styleVal['rec_total_after_n_apt_background_color']:'') + ';'+this.applySpacing(styleVal,'rec_total_after_n_apt','margin') + ''+this.applySpacing(styleVal,'rec_total_after_n_apt','padding')+';}';
									}
									if(styleVal['rec_total_after_n_apt_font_size']){
										style += '#'+selectorKey+' .bk-form-sum-rec-apt-label, '+'#'+selectorKey+' .bk-form-sum-rec-apt-value{ font-size:'+this.applyFontSize(styleVal['rec_total_after_n_apt_font_size']) + ';}';
									}
									if(styleVal['rec_total_after_n_apt_label_color'] || styleVal['rec_total_after_n_apt_label_font_weight']){
										style += '#'+selectorKey+' .bk-form-sum-rec-apt-label{ color:'+styleVal['rec_total_after_n_apt_label_color'] + '; font-weight:'+(styleVal['rec_total_after_n_apt_label_font_weight'] ? styleVal['rec_total_after_n_apt_label_font_weight'] : '')+';}';
									}
									if(styleVal['rec_total_after_n_apt_value_color'] || styleVal['rec_total_after_n_apt_value_font_weight']){
										style += '#'+selectorKey+' .bk-form-sum-rec-apt-value{ color:'+styleVal['rec_total_after_n_apt_value_color'] + '; font-weight:'+(styleVal['rec_total_after_n_apt_value_font_weight'] ? styleVal['rec_total_after_n_apt_value_font_weight'] : '')+';}';
									}

									/**First APT Total */
									if(styleVal['first_apt_total_background_color'] || styleVal['first_apt_total_margin_bottom'] || styleVal['first_apt_total_margin_right'] || styleVal['first_apt_total_margin_top'] || styleVal['first_apt_total_margin_left'] || styleVal['first_apt_total_padding_top'] || styleVal['first_apt_total_padding_right'] || styleVal['first_apt_total_padding_bottom'] || styleVal['first_apt_total_padding_left']){
										style += '#'+selectorKey+' .bk-form-sum-fst-apt{ background-color:'+(styleVal['first_apt_total_background_color']?styleVal['first_apt_total_background_color']:'') + ';'+this.applySpacing(styleVal,'first_apt_total','margin') + ''+this.applySpacing(styleVal,'first_apt_total','padding')+';}';
									}
									if(styleVal['first_apt_total_font_size']){
										style += '#'+selectorKey+' .bk-form-sum-fst-apt-label, '+'#'+selectorKey+' .bk-form-sum-fst-apt-value{ font-size:'+this.applyFontSize(styleVal['first_apt_total_font_size']) + ';}';
									}
									if(styleVal['first_apt_total_label_color'] || styleVal['first_apt_total_label_font_weight']){
										style += '#'+selectorKey+' .bk-form-sum-fst-apt-label{ color:'+styleVal['first_apt_total_label_color'] + '; font-weight:'+(styleVal['first_apt_total_label_font_weight'] ? styleVal['first_apt_total_label_font_weight'] : '')+';}';
									}
									if(styleVal['first_apt_total_value_color'] || styleVal['first_apt_total_value_font_weight']){
										style += '#'+selectorKey+' .bk-form-sum-fst-apt-value{ color:'+styleVal['first_apt_total_value_color'] + '; font-weight:'+(styleVal['first_apt_total_value_font_weight'] ? styleVal['first_apt_total_value_font_weight'] : '')+';}';
									}

									/**Learn More */
									if(styleVal['learn_more_margin_bottom'] || styleVal['learn_more_margin_right'] || styleVal['learn_more_margin_bottom'] || styleVal['learn_more_margin_left'] || styleVal['learn_more_padding_top'] || styleVal['learn_more_padding_right'] || styleVal['learn_more_padding_bottom'] || styleVal['learn_more_padding_left']){
										style += '#'+selectorKey+' .bk-form-sum-learnmore{'+this.applySpacing(styleVal,'learn_more','margin') + ''+this.applySpacing(styleVal,'learn_more','padding')+';}';
									}

									if(styleVal['learn_more_label_color'] || styleVal['learn_more_font_size'] || styleVal['learn_more_label_font_weight']){
										style += '#'+selectorKey+' .bk-form-sum-learnmore-label{ color:'+(styleVal['learn_more_label_color']?styleVal['learn_more_label_color']:'') + ';font-size:'+this.applyFontSize((styleVal['learn_more_font_size']?styleVal['learn_more_font_size']:'')) + '; font-weight:'+(styleVal['learn_more_label_font_weight'] ? styleVal['learn_more_label_font_weight'] : '')+';}';
									}
									//Responsive summary
									style += '@media(max-width:575px){';
									if(styleVal['short_summary_heading_color']){
										style += '#'+selectorKey+' .bk-form-summary-header.collapsed .accordion-header{ color:'+(styleVal['short_summary_heading_color']?styleVal['short_summary_heading_color']:'')+ ';}';
									}
									if(styleVal['short_summary_heading_color']){
										style += '#'+selectorKey+' .bk-form-summary-header.collapsed::after{ color:'+(styleVal['short_summary_heading_color']?styleVal['short_summary_heading_color']:'') + ';}';
									}
									if(styleVal['short_summary_value_color'] || styleVal['short_summary_value_font_size']){
										style += '#'+selectorKey+' .bk-form-summary-header.collapsed .bk-form-sum-total-value, #'+selectorKey+' .bk-form-summary-header.collapsed .bk-form-sum-tbt-value{ color:'+(styleVal['short_summary_value_color']?styleVal['short_summary_value_color']:'') + ';font-size:'+this.applyFontSize((styleVal['short_summary_value_font_size']?styleVal['short_summary_value_font_size']:'')) + ';}';
									}
									if(styleVal['short_summary_background_color']){
										style += '#booking-summary-box.collapsed-sum .bk-form-summary{ background-color:'+(styleVal['short_summary_background_color']?styleVal['short_summary_background_color']:'') + ';}';
									}
									if(styleVal['short_summary_margin_bottom_mob'] || styleVal['short_summary_margin_right_mob'] || styleVal['short_summary_margin_top_mob'] || styleVal['short_summary_margin_left_mob']){
										style += ' #booking-summary-box.tjs-summary-mob{'+this.applySpacing(styleVal,'short_summary','margin', 'padding') + ';}';
									}
									if(styleVal['short_summary_padding_bottom_mob'] || styleVal['short_summary_padding_right_mob'] || styleVal['short_summary_padding_top_mob'] || styleVal['short_summary_padding_left_mob']){
										style += ' #booking-summary-box.collapsed-sum .bk-mini-sum-header{'+this.applySpacing(styleVal,'short_summary','padding')+';}';
									}
									style += '}'
								}
								/**How It works */
								if(secElem[selectorKey] === 'how_it_works'){
									style += '#'+selectorKey+' .bk-form-how-it-work{ background-color:'+styleVal['how_it_works'] + ';}';
									style += '#'+selectorKey+' .bk-form-how-it-work-link{ color:'+(styleVal['color']?styleVal['color']:'') + ';font-size:'+this.applyFontSize((styleVal['font-size']?styleVal['font-size']:'')) + ';}';
								}
								/**Gift Card Form*/
								if(secElem[selectorKey] === 'preview_card'){
									if(styleVal['color'] || styleVal['font-size']){
										style += '#'+selectorKey+' .bk-card-amount{ color:'+(styleVal['color'] ? styleVal['color'] : '') + ';font-size:'+this.applyFontSize((styleVal['font-size']?styleVal['font-size']:'')) + ';}';
									}
								}

								/**Invoice Form*/
								if(secElem[selectorKey] === 'login_form'){
									if(styleVal['background-color']){
										style += '#'+selectorKey+'{ background-color:transparent;}';
										style += '#'+selectorKey+' .bk-col{ background-color:'+styleVal['background-color'] + ';}';
									}
									if(styleVal['color'] || styleVal['font-size']){
										style += '#'+selectorKey+' .bk-form-label{ color:'+(styleVal['color'] ? styleVal['color'] : '') + ';font-size:'+this.applyFontSize((styleVal['font-size']?styleVal['font-size']:'')) + ';}';
									}
								}

								if(secElem[selectorKey] === 'payment_form'){
									if(styleVal['label_color'] || styleVal['label_font_size']){
										style += '#'+selectorKey+' .bk-form-label{ color:'+(styleVal['label_color'] ? styleVal['label_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['label_font_size']?styleVal['label_font_size']:'')) + ';}';
									}
								}
								/**Blog list */
								if((secElem[selectorKey] === 'single_post') || (secElem[selectorKey] === 'blog_list') || (secElem[selectorKey] === 'blog_section') || (secElem[selectorKey] === 'recent_posts')){
									if(styleVal['color'] || styleVal['font-size']){
										style += '#'+selectorKey+' .bk-item-title{ color:'+(styleVal['color'] ? styleVal['color'] : '') + ';font-size:'+this.applyFontSize((styleVal['font-size']?styleVal['font-size']:'')) + ';}';
									}
									if(styleVal['meta_color'] || styleVal['meta_font_size']){
										style += '#'+selectorKey+' .bk-item-desc{ color:'+(styleVal['meta_color'] ? styleVal['meta_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['meta_font_size']?styleVal['meta_font_size']:'')) + ';}';
									}
								}
								if(secElem[selectorKey] === 'subscribe' || secElem[selectorKey] === 'description' || secElem[selectorKey] === 'social_list' || secElem[selectorKey] === 'recent_posts'){
									if(styleVal['top_border_color']){
										style += '#'+selectorKey+'{ border-color:'+styleVal['top_border_color'] + ';}';
									}
								}
								/**Recent posts */
								if(secElem[selectorKey] === 'recent_posts'){
									if(styleVal['border-color']){
										style += '#'+selectorKey+' .bk-item{ border-color:'+styleVal['border-color'] + ';}';
									}
								}
								/**Embed Video */
								if(secElem[selectorKey] === 'video'){
									if(styleVal['width']){
										style += '#'+selectorKey+'{ width:auto !important;padding:0}';
										style += '#'+selectorKey+' .bk-embed-video{ position:static;}';
										style += '#'+selectorKey+' .bk-embed-video{ width:'+styleVal['width'] + 'px;height:'+Math.round((styleVal['width']*9)/16) + 'px;}';
									}
								}
								/**Calendar */
								if(secElem[selectorKey] === 'calendar'){
									if(styleVal['background-color']){
										style += '#'+selectorKey+'{ background-color:transparent;}';
										style += '#'+selectorKey+' .bk-calendar{ background-color:'+styleVal['background-color'] + ';}';
									}
									if(styleVal['month_color'] || styleVal['month_font_size']){
										style += '#'+selectorKey+' .bk-calendar-header{ color:'+(styleVal['month_color'] ? styleVal['month_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['month_font_size']?styleVal['month_font_size']:'')) + ';}';
									}
									if(styleVal['subtitle_color'] || styleVal['subtitle_font_size']){
										style += '#'+selectorKey+' .bk-calendar-subtitle{ color:'+(styleVal['subtitle_color'] ? styleVal['subtitle_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['subtitle_font_size']?styleVal['subtitle_font_size']:'')) + ';}';
									}
									if(styleVal['week_color'] || styleVal['week_font_size']){
										style += '#'+selectorKey+' .bk-calendar-week{ color:'+(styleVal['week_color'] ? styleVal['week_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['week_font_size']?styleVal['week_font_size']:'')) + ';}';
									}
									if(styleVal['border-color']){
										style += '#'+selectorKey+'{ border-color:transparent;}';
										style += '#'+selectorKey+' .bk-calendar-border{ border-color:'+(styleVal['border-color'] ? styleVal['border-color'] : '') + ';}';
									}
									if(styleVal['date_color'] || styleVal['date_font_size']){
										style += '#'+selectorKey+' .bk-calendar-date{ color:'+(styleVal['date_color'] ? styleVal['date_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['date_font_size']?styleVal['date_font_size']:'')) + ';}';
									}
									if(styleVal['active_date_background_color'] || styleVal['active_date_color']){
										style += '#'+selectorKey+' .bk-calendar-date:hover, '+'#'+selectorKey+' .bk-calendar-date.bk-calendar-active{ color:'+(styleVal['active_date_color'] ? styleVal['active_date_color'] : '') + ';background-color:'+(styleVal['active_date_background_color']?styleVal['active_date_background_color']:'') + ';}';
									}
									//Slot style
									if(styleVal['slot_background_color'] || styleVal['slot_border_color'] || styleVal['slot_color'] || styleVal['slot_font_size']){
										style += '#'+selectorKey+' .bk-slot{ color:'+(styleVal['slot_color'] ? styleVal['slot_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['slot_font_size']?styleVal['slot_font_size']:'')) + ';background-color:'+(styleVal['slot_background_color']?styleVal['slot_background_color']:'') + ';border-color:'+(styleVal['slot_border_color']?styleVal['slot_border_color']:'') + ';}';
									}
									if(styleVal['active_slot_background_color'] || styleVal['active_slot_border_color'] || styleVal['active_slot_color']){
										style += '#'+selectorKey+' .bk-slot:hover, '+'#'+selectorKey+' .bk-slot-active{ color:'+(styleVal['active_slot_color'] ? styleVal['active_slot_color'] : '') + ';background-color:'+(styleVal['active_slot_background_color']?styleVal['active_slot_background_color']:'') + ';border-color:'+(styleVal['active_slot_border_color']?styleVal['active_slot_border_color']:'') + ';}';
									}
									//Multistep Date style
									if(styleVal['day_background_color'] || styleVal['day_border_color'] || styleVal['day_color'] || styleVal['day_font_size']){
										style += '#'+selectorKey+' .bk-multi-date{ color:'+(styleVal['day_color'] ? styleVal['day_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['day_font_size']?styleVal['day_font_size']:'')) + ';background-color:'+(styleVal['day_background_color']?styleVal['day_background_color']:'') + ';border-color:'+(styleVal['day_border_color']?styleVal['day_border_color']:'') + ';}';
									}
									if(styleVal['active_day_background_color'] || styleVal['active_day_border_color'] || styleVal['active_day_color']){
										style += '#'+selectorKey+' .bk-multi-date:hover, '+'#'+selectorKey+' .bk-multi-date-active{ color:'+(styleVal['active_day_color'] ? styleVal['active_day_color'] : '') + ';background-color:'+(styleVal['active_day_background_color']?styleVal['active_day_background_color']:'') + ';border-color:'+(styleVal['active_day_border_color']?styleVal['active_day_border_color']:'') + ';}';
									}
								}
							}
							/**Row Col */
							if(styleVal['align']){
								style += '#'+selectorKey+'{ text-align:'+styleVal['align'] + ';}';
								if(styleVal.hasOwnProperty('desc_color')){
									style += '#'+selectorKey+' .bk-item-desc{ color:'+styleVal['desc_color'] + ';}';
								}
							}
							if(styleVal['width']){
								style += '#'+selectorKey+' .bk-row-image{ width:'+styleVal['width'] + '%;}';
							}
							if(styleVal['border_radius']){
								style += '#'+selectorKey+' .bk-row-image{ border-radius:'+styleVal['border_radius'] + '%;}';
							}
							// popup
							if(sectionType === 'popup'){
								style += '#'+selectorKey+' .bk-headColor { background-color:'+((styleVal['header_bg_color']) ? styleVal['header_bg_color'] : '')+';}';
								style += '#'+selectorKey+' .bk-popupTitle { color:'+((styleVal['color']) ? styleVal['color'] : '')+';}';
								style += '#'+selectorKey+' .bk-closePopup, #'+selectorKey+' .bk-closePopup i { color:'+((styleVal['color']) ? styleVal['color'] : '')+';}';
								style += '#'+selectorKey+' .bk-closePopup, #'+selectorKey+' .bk-closePopup i { color:'+((styleVal['cross_icon_color']) ? styleVal['cross_icon_color'] : '')+';}';
								style += '#'+selectorKey+' .bk-popup-card { border-radius:'+styleVal['border_radius'] + 'px; border-color:'+(styleVal['border-color'] ? styleVal['border-color'] : '')+';}';
								style += '#'+selectorKey+' .card { background-color:'+((styleVal['card_background_color']) ? styleVal['card_background_color'] : '')+';}';
								style += '#'+selectorKey+' .bk-copy_btn { background-color:'+((styleVal['copy_btn_bg_color']) ? styleVal['copy_btn_bg_color'] : '')+'; color:'+((styleVal['copy_btn_color']) ? styleVal['copy_btn_color'] : '')+';}';
								style += '#'+selectorKey+' .text--color { color:'+((styleVal['color']) ? styleVal['color'] : '')+';}';
								style += '#'+selectorKey+' .bk-tooltip { color:'+((styleVal['tooltip_color']) ? styleVal['tooltip_color'] : '')+';}';
								style += '#'+selectorKey+' .bk-review-color { color:'+((styleVal['review_color']) ? styleVal['review_color'] : '')+';}';
								style += '#'+selectorKey+' .bk-readmore-btn { color:'+((styleVal['read_more_color']) ? styleVal['read_more_color'] : '')+';}';
								style += '#'+selectorKey+' .bk-share-review-btn { color:'+((styleVal['button_color']) ? styleVal['button_color'] : '')+'; background-color:'+((styleVal['button_background_color']) ? styleVal['button_background_color'] : '')+'; border-color:'+(styleVal['button_border_color'] ? styleVal['button_border_color'] : '')+'; font-size:'+this.applyFontSize(styleVal['button_font_size']) + ';}';
								style += '#'+selectorKey+' .bk-icon { color:'+((styleVal['icon_color']) ? styleVal['icon_color'] : '')+';}';
								style += '#'+selectorKey+' .bk-footColor { background-color:'+((styleVal['footer_bg_color']) ? styleVal['footer_bg_color'] : '')+';}';
								style += '#'+selectorKey+' .bk-profile-title { color:'+((styleVal['name_color']) ? styleVal['name_color'] : '')+'; font-size:'+this.applyFontSize(styleVal['name_font_size']) + ';}';
								style += '#'+selectorKey+' .bk-author-title { color:'+((styleVal['author_color']) ? styleVal['author_color'] : '')+'; font-size:'+this.applyFontSize(styleVal['author_font_size']) + ';}';
								style += '#'+selectorKey+' .bk-review { color:'+((styleVal['review_color']) ? styleVal['review_color'] : '')+'; font-size:'+this.applyFontSize(styleVal['review_font_size']) + ';}';
								style += '#'+selectorKey+' .bk-card-border { border-color:'+((styleVal['card_border_color']) ? styleVal['card_border_color'] : '')+'; border-width:'+ styleVal['card_border_width'] +'px;}';
								style += '#'+selectorKey+' .border { border-color:'+((styleVal['card_border_color']) ? styleVal['card_border_color'] : '')+';}';
								style += '#'+selectorKey+' .active-border { border-color:'+((styleVal['active_card_border_color']) ? styleVal['active_card_border_color'] : '')+'!important;}';
								style += '#'+selectorKey+' .bk-meta-info { color:'+(styleVal['meta_color'] ? styleVal['meta_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['meta_font_size']?styleVal['meta_font_size']:'')) + ';}';
								style += '#'+selectorKey+' .bk-reason-label { color:'+(styleVal['reason_color'] ? styleVal['reason_color'] : '') + ';font-size:'+this.applyFontSize((styleVal['reason_font_size']?styleVal['reason_font_size']:'')) + ';}';
								if(styleVal['star_color']){
									style += '#'+selectorKey+' .tjs-rating-inner, '+'#'+selectorKey+' #stars-half-star, '+'#'+selectorKey+' #stars-full-star { --activeStarColor:'+styleVal['star_color'] + ' !important;}';
									style += '#'+selectorKey+' .bk-rating .bk-star-group.full.star, '+'#'+selectorKey+' .bk-rating .bk-star-group.half.star, '+ '#'+selectorKey+'.bk-rating .bk-star-group.full.star~span { color:'+styleVal['star_color'] + ' !important;}';
									style += '#'+selectorKey+' .bk-rating.bk-custom-rating > .bk-star-group:hover~span,' + '#'+selectorKey+' .bk-rating.bk-custom-rating > .bk-star-group:hover { color :'+styleVal['star_color'] + '! important;}';
								}
								if(secElem[selectorKey] === 'listing'){
									style+=`#${selectorKey} .bk-table thead th:first-child {border-top-left-radius:0rem}, #{selectorKey} .bk-table thead th:last-child {border-top-right-radius: 0rem}`;
								}
								// Contract popup resend btn link color
								if(styleVal['resend_link_btn']){
									style+='#'+selectorKey+' .resend-btn-link {color:'+(styleVal['resend_link_btn'] ? styleVal['resend_link_btn'] : '') +'}';
								}
							}
							// Handle error border color
							if(styleVal['error_border_color']){
								style += '.'+selectorKey+'.form-control.is-invalid.existing-email{ border-color:'+styleVal['error_border_color'] + ' !important;}';
							}
						}
					}
				}
			}
			let styleType = ((sectionType === 'header') || (sectionType === 'footer') || (sectionType === 'popup')) ?  sectionType :'page';
			let existingStyleSheet : any = document.getElementById(styleType+'-style');
			if(((sectionType === 'popup') || this.currentUrl === this.previousUrl || isEmbed) && existingStyleSheet){
				/**Update existing stylesheet with same id */
				existingStyleSheet.appendChild(document.createTextNode(style));
			} else {
				if(existingStyleSheet){
					existingStyleSheet.parentNode.removeChild(existingStyleSheet);
				}
				this.appendStylesheet(styleType+'-style',style);
			}
		}
	}
	/**Function to apply padding margin */
	public applySpacing (styleVal?:any, prefix:string='', type:string="", styleType: any = null) : string {
		let propType: string = type;
		if(styleType){
			propType = styleType;
		}
		let spacing = '';
		let mobStr: string = (IS_TAB) ? '_mob' : '';
		if(styleVal[prefix+'_'+type+'_top'+mobStr]){
			spacing += propType+'-top:'+styleVal[prefix+'_'+type+'_top'+mobStr]+';';
		}
		if(styleVal[prefix+'_'+type+'_bottom'+mobStr]){
			spacing += propType+'-bottom:'+styleVal[prefix+'_'+type+'_bottom'+mobStr]+';';
		}
		if(styleVal[prefix+'_'+type+'_right'+mobStr]){
			spacing += propType+'-right:'+styleVal[prefix+'_'+type+'_right'+mobStr]+';';
		}
		if(styleVal[prefix+'_'+type+'_left'+mobStr]){
			spacing += propType+'-left:'+styleVal[prefix+'_'+type+'_left'+mobStr]+';';
		}
		return spacing;
	}
	/**
	 * Function to apply booking form migration style
	 * @param style page style data
	 * @param sec_elems page section elements
	 */
	public migrateStyle(style: any, sec_elems: any): void {
		if(style && sec_elems){
			let formStyleId: any = this.getIdFromVal(sec_elems, 'form');
			let formSummStyleId: any = this.getIdFromVal(sec_elems, 'booking_summary');
			let packageStyleId: any = this.getIdFromVal(sec_elems, 'packages');
			if(formStyleId){
				this.updateStyle(style, formStyleId, formStyleId, 'card_body_style');
				this.updateStyle(style, formStyleId, formStyleId, 'card_heading_style');
				this.updateStyle(style, formStyleId, formStyleId, 'radio_btn_style');
				this.updateStyle(style, formStyleId, formStyleId, 'extra_elem_style');
				this.updateStyle(style, formStyleId, formStyleId, 'item_section_card_style');
			}
			if(formSummStyleId){
				this.updateStyle(style, formSummStyleId, formSummStyleId, 'header_style');
				this.updateStyle(style, formSummStyleId, formSummStyleId, 'body_style');
				this.updateStyle(style, formSummStyleId, formSummStyleId, 'footer_style');
			}
			if(packageStyleId){
				this.updateStyle(style, formStyleId, formStyleId, 'package_card_style');
				this.updateStyle(style, formStyleId, formStyleId, 'selected_package_card_style');
				this.updateStyle(style, formStyleId, formStyleId, 'slider_btn_style');
			}
		}
	}
	/**
	 * function to get section element id
	 * @param obj
	 * @param val
	 * @returns element id
	 */
	public getIdFromVal(obj: any, val: string): any {
		return Object.keys(obj).find(key => obj[key] === val);
	}

	/**
	 * Function to update style
	 * @param style
	 * @param id
	 * @param migrateInStyle
	 * @param type
	 */
	private updateStyle(style: any, id: string, migrateInStyle: string, type: string): void {
		if(style && style.hasOwnProperty(id) && style[id]){
			let props: any;
			if(style[id].hasOwnProperty(type) && style[id][type]){
				props = style[id][type];
			}
			if(props && Object.keys(props).length > 0){
				for(let prop in props){
					let fields: Array<string> = MIGRATION_FIELDS[type][prop];
					if(fields && fields.length > 0){
						for(let field of fields){
							if(prop == 'bold_status'){
								if(props[prop]){
									this.styleData[migrateInStyle][field] = 'bold';
								}else{
									this.styleData[migrateInStyle][field] = '';
								}
							}else{
								this.styleData[migrateInStyle][field] = props[prop];
							}
						}
					}
				}
				delete this.styleData[id][type];
			}
		}
	}
	/**Function to apply font Size */
	private applyFontSize(value:string=''):string{
		if(!IS_TAB){
			return value;
		}
		return '';
	}

	/**Function to append stylesheet */
	private appendStylesheet(id:string='',style:any):void{
		let styleTag = document.createElement('style');
		document.body.appendChild(styleTag);
		styleTag.type = 'text/css';
		styleTag.id = id;
		styleTag.appendChild(document.createTextNode(style));
	}

	/**Function to apply padding margin. This function is updated with empty prefix so that css property directly also be applied on the given elem. This is now particularly used in invoice, but can be used anywhere in the section. */
	public applyCustomSpacing (styleVal?:any, prefix:string='', type:string="", styleType: any = null) : string {
		let propType: string = type;
		prefix = prefix ? `${prefix}_` : '';
		type = prefix ? `${type}_` : `${type}-`;
		if(styleType){
			propType = styleType;
		}
		let spacing = '';
		let mobStr: string = (IS_TAB) ? '_mob' : '';
		if(styleVal[prefix+type+'top'+mobStr]){
			spacing += propType+'-top:'+styleVal[prefix+type+'top'+mobStr]+';';
		}
		if(styleVal[prefix+type+'bottom'+mobStr]){
			spacing += propType+'-bottom:'+styleVal[prefix+type+'bottom'+mobStr]+';';
		}
		if(styleVal[prefix+type+'right'+mobStr]){
			spacing += propType+'-right:'+styleVal[prefix+type+'right'+mobStr]+';';
		}
		if(styleVal[prefix+type+'left'+mobStr]){
			spacing += propType+'-left:'+styleVal[prefix+type+'left'+mobStr]+';';
		}
		return spacing;
	}


	ngOnDestroy(): void {
		if(this._routeListener){
			this._routeListener.unsubscribe();
		}
		this.destroy.next(true);
	}
}
