import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
// Services
import { NgOnDestroy, SectionServ, RenderComponentServ, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-video-popup',
	templateUrl: './VideoPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class VideoPopupComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: any;
	popupData: any;
	pageSett: any;
	// Section fields
	section: any = {
		video: null
	}
	safeVideoUrl: any;

	constructor(public dialogRef: MatDialogRef<VideoPopupComponent>,public secServ: SectionServ, public rcServ: RenderComponentServ, private utilServ: UtilServ, public sanitizer: DomSanitizer) {}

	ngOnInit(): void {
		if(this.secId && this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.popupData = this.rcServ.popupData[this.popupId];
			this.buildSection();
		}
	}
	/**
	 * Build section
	 */
	private buildSection(): void {
		this.pageSett = this.popupData.section_settings;
		this.utilServ.popupPosition(this.pageSett[this.secId].variation_id, this.popupData.settings, this.dialogRef);
		this.secServ.setServData(this.pageSett, this.popupData.content);
		let secElem : any = null;
		if(this.popupData.sections){
			secElem = this.popupData.sections[this.secId];
		}
		if(secElem && this.pageSett && this.pageSett[this.secId] && this.pageSett[this.secId][this.rcServ.statusType]){
			this.section['video'] =  this.secServ.buildVideo(secElem['video']);
			if(this.section.video && this.section.video.url){
				this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.section.video?.url + '?autoplay=0');
			}
		}
	}
}
