import { Component, Input, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, Self,ElementRef } from '@angular/core';
import { takeUntil } from 'rxjs';
// External library
import KeenSlider from "keen-slider";
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, UtilServ } from  '../../Services';

@Component({
	selector: 'bk-client-reviews',
	templateUrl: './ClientReviews.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class ClientReviewsComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	@ViewChild("testimonialSlide") sliderRef : ElementRef<HTMLElement> | undefined;
	pageSett: any;
	varId: string = '';
	slider: any = null;
	variationClass: any = {
		bk_testimonial_V1 : 'tjs-testimonial--v1',
		bk_testimonial_V2 : 'tjs-testimonial--v2 text-center',
		bk_testimonial_V3 : 'tjs-testimonial--v3 text-center pb-70',
		bk_testimonial_V4 : 'tjs-testimonial--v4'
	}
	// Section fields
	section: any = {
		title: null,
		list: null
	}
	// Slick configuration
	slideConfig:any = {
		loop:false,
		slides: { perView: 3},
		rtl:(this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? true : false,
		defaultAnimation:{duration:1000},
		breakpoints: {
			"(max-width: 991px)": {
				loop:true,
				slides: { perView: 2},
			},
			"(max-width: 768px)": {
				loop:true,
				slides: { perView: 1},
			},
		},
	};
	modalData:any;
	sectionData: any;
	autoplaySpeed:any = 6000;


	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, public utilServ : UtilServ, @Self() private destroy: NgOnDestroy) {
		// Set the RTL value
		this.initServ.isRTLChange.pipe(takeUntil(this.destroy)).subscribe((value) => {
			this.slideConfig['rtl']=value;
			if(this.slider && this.sliderRef){
				this.slider.update(this.slideConfig);
			}
		});
	}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}

	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
		if(this.pageSett && this.pageSett[this.secId]){
			this.varId = this.pageSett[this.secId].variation_id;
			if(this.pageSett[this.secId].slide_delay){
				this.autoplaySpeed = +this.pageSett[this.secId].slide_delay;
			}
			if (this.pageSett[this.secId].variation_id && (this.pageSett[this.secId].variation_id == 'bk_testimonial_V3')) {
				this.slideConfig.breakpoints = null;
				this.slideConfig.slides['perView'] = 1;
				if(this.section && this.section.list && this.section.list.items && this.section.list.items.length > 1){
					this.slideConfig['loop'] = true;
				}
			} else if(this.section && this.section.list && this.section.list.items && (this.section.list.items.length > 3)){
				this.slideConfig['loop'] = true;
			}
			
		}
		setTimeout(()=>{
			// Set the slider configuration
			if(this.sliderRef){
				if (this.slider) {this.slider.destroy();}
				this.slider = new KeenSlider(this.sliderRef.nativeElement,this.slideConfig,[(slider) => this.utilServ.autoPlay(slider,this.autoplaySpeed)])
			}
		})
	}
	/**
	 * Open modal
	 * @param review
	 */
	public openModal(review: any): void {
		this.modalData = review;
		setTimeout(() => {
			this.utilServ.togglePopup('testimonialModal_'+review.id);
		}, 100);
	}

	ngOnDestroy():void {
		if (this.slider){ this.slider.destroy()}
	}
}