<div class="d-flex flex-column" *ngIf="sidebarSequence && (sidebarSequence).length > 0 && sidebarSecOrder">
	<!-- How it works -->
	<bk-how-it-works class="d-flex w-100 justify-content-start" [style.order]="sidebarSecOrder.how_it_works" *ngIf="['add', 'draft'].includes(bookingType)" [settings]="settings" [section]="section?.how_it_works" [pageSett]="pageSett"></bk-how-it-works>
	<!-- Booking summary -->
	<div [style.order]="sidebarSecOrder.summary">
		<div id="booking-summary-box-ref" [attr.data-margintop]="(formSett && formSett?.margin_top) ? formSett?.margin_top : 0"></div>
		<div id="booking-summary-box" class="summary-ele min-height--100 " [ngClass]="(isMob && smryMobileScrlStatus && (formLayout != 'multi_step')) ? 'tjs-summary-mob collapsed-sum' : ''" >
			<bk-bkng-summary
				[isMultiStepForm]="isMultiStepForm"
				[bookingType]="bookingType"
				[isStepOneValid]="isStepOneValid"
				[prefilledData]="prefilledData"
				[BKFrm]="BKFrm"
				[formValues]="BKFrm?.value"
				[smryScrlStatus]="smryScrlStatus"
				[smryMobileScrlStatus]="isMultiStepForm ? false : smryMobileScrlStatus"
				[section]="section?.summary"
				[pageSett]="pageSett"
				[couponData]="couponData"
				[isMobile]="isMobile"
				[settings]="settings"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				[selectedPricingParams]="selectedPricingParams"
				[selectedItems]="selectedItems"
				[selectedAreaParams]="selectedAreaParams"
				[selectedExtras]="selectedExtras"
				[selectedExcludes]="selectedExcludes"
				[adjustedTimeHours]="adjustedTimeHours"
				[adjustedTimeMin]="adjustedTimeMin"
				[bookingTimeHours]="bookingTimeHours"
				[bookingTimeMin]="bookingTimeMin"
				[selectedAddr]="selectedAddr"
				[isServiceFeeTaxable]="isServiceFeeTaxable"
				[includeInFreqFieldsPrice]="includeInFreqFieldsPrice"
				[exemptFromFreqFieldsPrice]="exemptFromFreqFieldsPrice"
				[nonTaxableFieldsPrice]="nonTaxableFieldsPrice"
				[afterDiscTotalFieldsPrice]="afterDiscTotalFieldsPrice"
				[beforePriceableFieldsPrice]="beforePriceableFieldsPrice"
				[displayServicePrice]="displayServicePrice"
				[excludeExpeditedAmountValue]="excludeExpeditedAmountValue"
				[displayFrequencyDiscount]="displayFrequencyDiscount"
				[displayCouponDiscount]="displayCouponDiscount"
				[displayDiscountedAmount]="displayDiscountedAmount"
				[bookingTaxRate]="bookingTaxRate"
				[exemptSalesTaxValue]="exemptSalesTaxValue"
				[bookingTaxType]="bookingTaxType"
				[displaySaleTax]="displaySaleTax"
				[displayTipsAmount]="displayTipsAmount"
				[excludeServiceFeeValue]="excludeServiceFeeValue"
				[displayServiceFee]="displayServiceFee"
				[displayTotal]="displayTotal"
				[firstRecAptFinalAmount]="firstRecAptFinalAmount"
				[afterNthAptFinalAmount]="afterNthAptFinalAmount"
				[displaySpotDiscount] = "displaySpotDiscount"
				[displayGiftCardAmount]="displayGiftCardAmount"
				[displayReferralDiscount]="displayReferralDiscount"
				[displayFinalAmount]="displayFinalAmount"
				[formMetaData]="formMetaData"
				[settingsObj]="settingsObj"
				[formLayout]="formLayout"
				>
			</bk-bkng-summary>
		</div>
	</div>
	<!-- Live reviews -->
	<div id="live-reviews-box" class="summary-ele" *ngIf="['add', 'draft'].includes(bookingType) && section?.reviews" [style.order]="sidebarSecOrder.reviews">
		<bk-live-review class="bk-slider-rating" *ngIf="section?.reviews" [section]="section?.reviews" pageType="booking" [pageSett]="pageSett"></bk-live-review>
	</div>
	<!-- Facebook share coupon -->
	<div id="share-on-facebook-box" class="summary-ele" *ngIf="['add', 'draft'].includes(bookingType)" [style.order]="sidebarSecOrder.fb_coupon">
		<bk-facebook-share [section]="section?.fb_coupon" [pageSett]="pageSett"></bk-facebook-share>
	</div>
	<!-- FAQ -->
	<div id="booking-form-faq-box" class="summary-ele" *ngIf="['add', 'draft'].includes(bookingType) && section?.faq" [style.order]="sidebarSecOrder.faq">
		<bk-faq-list [section]="section?.faq" [industryId]="industryId" [formId]="formId" pageType="booking" [pageSett]="pageSett"></bk-faq-list>
	</div>
	<!-- Reschedule buttons -->
	<ng-container *ngIf="bookingType == 'reschedule'">
		<bk-common-buttons [style.order]="sidebarSecOrder.buttons"
			[bookingType]="bookingType"
			[prefilledData]="prefilledData"
			[pageSett]="pageSett"
			[isCustomerAllowedRes]="isCustomerAllowedRes"
			[isStepOneValid]="isStepOneValid"
			(submitBKFrm)="submitBKFrm.emit()"
			(rescheduleOnlyInfo)="rescheduleOnlyInfo.emit()"
		>
		</bk-common-buttons>
		<div class="mt-30" [style.order]="sidebarSecOrder?.res_rating" *ngIf="ratingPerm && prefilledData && (prefilledData.status == 1 || prefilledData.status == 2) && initServ.isRatingAllowed && ((this.initServ.ratingAllowedFor).includes(prefilledData?.industry_id))">
			<bk-rating [prefilledData]="prefilledData"></bk-rating>
		</div>
	</ng-container>
</div>