<ng-container *ngIf="blogId && settings">
	<div class="tjs-comment mb-50" *ngIf="comments && comments.length>0">
		<div class="mb-20">
			<h3 class="fw-bold">{{initServ?.appStr?.text?.comments | translate}}</h3>
		</div>
		<div class="border-top border-bottom py-20">
			<!-- Comment template -->
			<ul class="list-unstyled tjs-comment__list mb-0">
				<li class="tjs-comment__list-item " *ngFor="let comment of comments;trackBy: utilServ.trackByFnIndex">
					<ng-container *ngTemplateOutlet="commentTemp; context:{comment:comment, parentId: comment?.id}"></ng-container>
					<!-- Sub comment template -->
					<ul class="tjs-comment__sub-comment ps-30 list-unstyled" *ngIf="comment?.replies && comment?.replies.length > 0">
						<li *ngFor="let reply of comment?.replies;trackBy: utilServ.trackByFnIndex">
							<ng-container *ngTemplateOutlet="commentTemp; context:{comment:reply, replyId: reply?.id}"></ng-container>
						</li>
					</ul>
				</li>
			</ul>
		</div>
		<!-- Pagination -->
		<div class="mt-20" *ngIf="totalRecords>limit">
			<bk-pagination [total]="totalRecords" [limit]="limit" [page]="page" (callback)="pageChange($event)"></bk-pagination>
		</div>
	</div>
	<!-- Comment form -->
	<div *ngIf="isCommentForm">
		<div id="comments">
			<div class="tjs-section__title mb-30">
				<div class="tjs-section__heading">
					<h3 class="fw-bold">{{initServ?.appStr?.text?.leaveAComment | translate}}</h3>
				</div>
			</div>
			<div id="respond">
				<bk-comment-reply-form [settings]="settings" [blogId]="blogId" (callback)="submitCommentForm($event)"></bk-comment-reply-form>
			</div>
		</div>
	</div>
</ng-container>
<!-- Comment template -->
<ng-template #commentTemp let-comment="comment" let-parentId="parentId" let-replyId="replyId">
	<div class="d-block d-sm-flex mb-30 mb-md-50 first-row-comment">
		<div class="tjs-comment__avatar tjs-w-80 tjs-h-80 rounded-circle text-white fs-22 fw-bold d-flex justify-content-center align-items-center mb-sm-0 mb-10 me-0 me-sm-25">
			<!-- Name -->
			<ng-container *ngIf="comment?.is_anonymous == 'yes'; else noAnony">{{initServ?.appStr?.text?.a}}</ng-container>
			<ng-template #noAnony>
				<ng-container *ngIf="comment?.full_name">
					{{comment?.full_name && comment?.full_name.split(' ')[0].charAt(0).toUpperCase()}}{{comment?.full_name && comment?.full_name.split(' ')[1] && comment?.full_name.split(' ')[1].charAt(0).toUpperCase()}}
				</ng-container>
			</ng-template>
		</div>
		<div class="tjs-comment__body w-100">
			<div class="d-sm-flex justify-content-between align-items-start">
				<div class="mb-5">
					<!-- Name -->
					<h6 class="fs-20 fw-bold d-inline-block text-capitalize" *ngIf="comment?.full_name">{{comment?.full_name}}</h6>
					<!-- Date -->
					<div class="tjs-section__description mb-15" *ngIf="comment?.comment">
						<p [innerHTML]="comment?.comment | safeHtml"></p>
					</div>
					<!-- Replay link -->
					<div *ngIf="isReplyLink">
						<button type="button" class="btn btn-link p-0 text-primary fw-bold" (click)="commentReply(comment)">
							{{initServ?.appStr?.text?.reply | translate}}
						</button>
					</div>
				</div>
				<div class="d-flex align-items-center flex-shrink-0" *ngIf="comment?.comment_date">
					<span class="text-muted d-block d-sm-inline-block me-10"><i class="fa fa-clock-o"></i></span>
					<bk-date-display class="d-inline-block" [date]="comment?.comment_date" [isLocal]="true"></bk-date-display>
				</div>
			</div>
			<!-- Comment -->

			<!-- Comment form -->
			<div *ngIf="comment?.is_open_form" class="pt-25">
				<bk-comment-reply-form [settings]="settings" [blogId]="blogId" [type]="comment?.type" [replyTo]="replyId" [parentId]="parentId" (callback)="submitCommentForm($event)"></bk-comment-reply-form>
			</div>
		</div>
	</div>
</ng-template>
