<div *ngIf="!hideSection && planPerm && rcServ && secSett && secSett[rcServ.statusType] && section">
	<ng-container *ngIf="couponData && barType == 'coupon'; else custom">
		<ng-container *ngIf="couponData?.discount_bar_status && couponData?.discount_bar_status == 'yes'">
			<!-- Booking discount bar -->
			<bk-bkng-discount-bar [data]="couponData" openFrom="section" [btnData]="section?.button"></bk-bkng-discount-bar>
		</ng-container>
	</ng-container>
	<ng-template #custom>
		<div class="theme-ele-bar">
			<ng-container *ngTemplateOutlet="customTemp"></ng-container>
		</div>
	</ng-template>
</div>
<ng-template #customTemp>
	<ng-container *ngIf="rcServ.statusType == 'mobile_status'; else desktop">
		<div [id]="secId" class="tjs-couponbar tjs-couponbar--mobile" [ngClass]="secSett?.custom_bar_type == 'clock' ? mobileVClass[secSett?.clock_bar_mobile_layout] : mobileVClass[secSett?.text_bar_mobile_layout]">
			<div class="d-flex align-items-center">
				<div class="tjs-couponbar__elements d-lg-flex align-items-center justify-content-center w-100">
					<ng-container *ngIf="secSett?.custom_bar_type && secSett?.custom_bar_type == 'clock'; else mobText">
						<ng-container [ngSwitch]="secSett?.clock_bar_mobile_layout">
							<!-- Mobile V1 -->
							<ng-container *ngSwitchCase="'mobile_layout_v1'">
								<div class="d-flex justify-content-between">
									<!-- Title -->
									<ng-container *ngTemplateOutlet="titleTemp"></ng-container>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<div class="d-flex justify-content-between mt-5 flex-wrap">
									<!-- Clock -->
									<div class="tjs-couponbar__clock me-10">
										<bk-count-down class="tjs-bk-countdown" *ngIf="section?.clock_bar" [secId]="secId" [section]="section" [data]="pageSett[section?.clock_bar]" (callback)="hideSection=true" [isMobile]="true"></bk-count-down>
										</div>
									<!-- Terms of deal -->
									<ng-container *ngTemplateOutlet="termsOfDealTemp"></ng-container>
								</div>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'mt-10', btnClass:'btn w-100 px-10 py-15'}" ></ng-container>
							</ng-container>
							<!-- Mobile V2 -->
							<ng-container *ngSwitchCase="'mobile_layout_v2'">
								<div class="d-flex justify-content-between">
									<div>
										<!-- Title -->
										<ng-container *ngTemplateOutlet="titleTemp; context:{customCls:'d-inline'}"></ng-container>
										<!-- Terms of deal -->
										<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'d-inline ms-10'}"></ng-container>
									</div>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<div class="d-flex justify-content-between mt-5 flex-wrap">
									<!-- Clock -->
									<div class="tjs-couponbar__clock me-10">
										<bk-count-down class="tjs-bk-countdown" *ngIf="section?.clock_bar" [secId]="secId" [section]="section" [data]="pageSett[section?.clock_bar]" (callback)="hideSection=true" [isMobile]="true"></bk-count-down>
									</div>
								</div>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'mt-10', btnClass:'btn w-100 px-10 py-15'}" ></ng-container>
							</ng-container>
							<!-- Mobile V3 -->
							<ng-container *ngSwitchCase="'mobile_layout_v3'">
								<div class="d-flex justify-content-between">
									<div class="d-flex justify-content-between flex-wrap">
										<!-- Clock -->
										<div class="tjs-couponbar__clock me-10">
											<bk-count-down class="tjs-bk-countdown" *ngIf="section?.clock_bar" [secId]="secId" [section]="section" [data]="pageSett[section?.clock_bar]" (callback)="hideSection=true" [isMobile]="true"></bk-count-down>
										</div>
									</div>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<div class="text-wrapper">
									<!-- Title -->
									<ng-container *ngTemplateOutlet="titleTemp; context:{customCls:'d-inline'}"></ng-container>
									<!-- Terms of deal -->
									<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'d-inline ms-10'}"></ng-container>
								</div>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'', btnClass:'btn w-100 px-10 py-15'}" ></ng-container>
							</ng-container>
							<!-- Mobile V4 -->
							<ng-container *ngSwitchCase="'mobile_layout_v4'">
								<div class="d-flex justify-content-center">
									<!-- Clock -->
									<div class="tjs-couponbar__clock me-10">
										<bk-count-down class="tjs-bk-countdown" *ngIf="section?.clock_bar" [secId]="secId" [section]="section" [data]="pageSett[section?.clock_bar]" (callback)="hideSection=true" [isMobile]="true"></bk-count-down>
									</div>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<div class="mt-5">
									<!-- Title -->
									<ng-container *ngTemplateOutlet="titleTemp; context:{customCls:'text-center'}"></ng-container>
									<!-- Terms of deal -->
									<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'text-center mt-5'}"></ng-container>
								</div>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'mt-10', btnClass:'btn w-100 px-10 py-15'}" ></ng-container>
							</ng-container>
							<!-- Mobile V5 -->
							<ng-container *ngSwitchCase="'mobile_layout_v5'">
								<div class="d-flex justify-content-center">
									<!-- Title -->
									<ng-container *ngTemplateOutlet="titleTemp; context:{customCls:'text-center'}"></ng-container>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<div class="d-flex justify-content-center">
									<!-- Clock -->
									<div class="tjs-couponbar__clock">
										<bk-count-down class="tjs-bk-countdown" *ngIf="section?.clock_bar" [secId]="secId" [section]="section" [data]="pageSett[section?.clock_bar]" (callback)="hideSection=true" [isMobile]="true"></bk-count-down>
									</div>
								</div>
								<!-- Terms of deal -->
								<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'text-center'}"></ng-container>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'', btnClass:'btn w-100 px-10 py-15'}" ></ng-container>
							</ng-container>
						</ng-container>
					</ng-container>
					<ng-template #mobText>
						<ng-container [ngSwitch]="secSett?.text_bar_mobile_layout">
							<!-- Mobile V6 -->
							<ng-container *ngSwitchCase="'mobile_layout_v6'">
								<div class="d-flex justify-content-between">
									<div>
										<!-- Title -->
										<ng-container *ngTemplateOutlet="titleTemp; context:{customCls:'d-inline'}"></ng-container>
										<!-- Terms of deal -->
										<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'d-inline ms-10'}"></ng-container>
									</div>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'mt-10', btnClass:'btn w-100 px-10 py-15'}" ></ng-container>
							</ng-container>
							<!-- Mobile V7 -->
							<ng-container *ngSwitchCase="'mobile_layout_v7'">
								<div class="d-flex justify-content-between">
									<div class="text-wrapper">
										<!-- Title -->
										<ng-container *ngTemplateOutlet="titleTemp"></ng-container>
										<!-- Terms of deal -->
										<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'text-start mt-5'}"></ng-container>
									</div>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'mt-10', btnClass:'btn w-100 px-10 py-15'}" ></ng-container>
							</ng-container>
							<!-- Mobile V8 -->
							<ng-container *ngSwitchCase="'mobile_layout_v8'">
								<div class="d-flex justify-content-center">
									<div class="text-center">
										<!-- Title -->
										<ng-container *ngTemplateOutlet="titleTemp; context:{customCls:'text-center'}"></ng-container>
									</div>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'mt-15 text-center w-70 mx-auto'}" ></ng-container>
								<!-- Terms of deal -->
								<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'text-center mt-10'}"></ng-container>
							</ng-container>
						</ng-container>
					</ng-template>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-template #desktop>
		<div [id]="secId" class="tjs-couponbar" [ngClass]="secSett?.custom_bar_type == 'clock' ? desktopVClass[secSett?.clock_bar_desktop_layout] : desktopVClass[secSett?.text_bar_desktop_layout]">
			<div class="{{(secSett?.custom_bar_type == 'clock' || secSett?.text_bar_desktop_layout != 'bar_layout_v7') ? 'd-flex align-items-center': ''}}">
				<ng-container *ngIf="secSett?.custom_bar_type && secSett?.custom_bar_type == 'clock'; else desktopText">
					<!-- v1,v2,v3&v4 -->
					<div class="tjs-couponbar__elements d-flex flex-column flex-lg-row  align-items-center justify-content-center w-100">
						<!-- Clock -->
						<bk-count-down class="tjs-bk-countdown" *ngIf="(secSett?.clock_bar_desktop_layout == 'bar_layout_v2' || secSett?.clock_bar_desktop_layout == 'bar_layout_v4') && section?.clock_bar" [secId]="secId" [section]="section" [data]="pageSett[section?.clock_bar]" (callback)="hideSection=true"></bk-count-down>
						<!-- Title -->
						<ng-container *ngTemplateOutlet="titleTemp"></ng-container>
						<!-- Clock -->
						<bk-count-down class="tjs-bk-countdown" *ngIf="(secSett?.clock_bar_desktop_layout != 'bar_layout_v2' && secSett?.clock_bar_desktop_layout != 'bar_layout_v4') && section?.clock_bar" [secId]="secId" [section]="section" [data]="pageSett[section?.clock_bar]" (callback)="hideSection=true"></bk-count-down>
						<!-- Terms of deal -->
						<ng-container *ngTemplateOutlet="termsOfDealTemp"></ng-container>
						<!-- Button -->
						<ng-container *ngTemplateOutlet="btnTemp" ></ng-container>
					</div>
					<!-- Cross icon -->
					<ng-container *ngTemplateOutlet="closeBar"></ng-container>
				</ng-container>
				<!-- Text -->
				<ng-template #desktopText>
					<ng-container [ngSwitch]="secSett?.text_bar_desktop_layout">
						<!-- Desktop text variation 5 -->
						<ng-container *ngSwitchCase="'bar_layout_v5'">
							<div class="tjs-couponbar__elements d-flex flex-column flex-lg-row align-items-center justify-content-center w-100">
								<!-- Title -->
								<ng-container *ngTemplateOutlet="titleTemp"></ng-container>
								<!-- Terms of deal -->
								<ng-container *ngTemplateOutlet="termsOfDealTemp"></ng-container>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp" ></ng-container>
							</div>
							<!-- Cross icon -->
							<ng-container *ngTemplateOutlet="closeBar"></ng-container>
						</ng-container>
						<!-- Desktop text variation 6 -->
						<ng-container *ngSwitchCase="'bar_layout_v6'">
							<div class="tjs-couponbar__elements d-lg-flex align-items-center justify-content-center w-100">
								<!-- Title -->
								<ng-container *ngTemplateOutlet="titleTemp"></ng-container>
								<!-- Terms of deal -->
								<ng-container *ngTemplateOutlet="termsOfDealTemp"></ng-container>
							</div>
							<!-- Button -->
							<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'w-20 ms-10'}" ></ng-container>
							<!-- Cross icon -->
							<ng-container *ngTemplateOutlet="closeBar"></ng-container>
						</ng-container>
						<!-- Desktop text variation 7 -->
						<ng-container *ngSwitchCase="'bar_layout_v7'">
							<div class="d-flex align-items-center my-10">
								<div class="d-lg-flex align-items-center justify-content-center w-100">
									<!-- Title -->
									<ng-container *ngTemplateOutlet="titleTemp"></ng-container>
								</div>
								<!-- Cross icon -->
								<ng-container *ngTemplateOutlet="closeBar"></ng-container>
							</div>
							<!-- Terms of deal -->
							<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'my-15 text-center'}"></ng-container>
							<!-- Button -->
							<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'text-center my-15'}" ></ng-container>
						</ng-container>
					</ng-container>
				</ng-template>
			</div>
		</div>
	</ng-template>
</ng-template>
<!-- Title -->
<ng-template #titleTemp let-customCls="customCls">
	<ng-container *ngIf="secSett?.custom_bar_type && secSett?.custom_bar_type == 'clock'; else textBarTitle">
		<div [id]="section?.clock_bar_title?.id" *ngIf="section?.clock_bar_title?.content" class="tjs-couponbar__text bk-discount-title {{customCls}}" [innerHTML]="section?.clock_bar_title?.content | safeHtml: 'html'"></div>
	</ng-container>
	<ng-template #textBarTitle>
		<div [id]="section?.text_bar_title?.id" *ngIf="section?.text_bar_title?.content" class="tjs-couponbar__text bk-discount-title {{customCls}}" [innerHTML]="section?.text_bar_title?.content | safeHtml: 'html'"></div>
	</ng-template>
</ng-template>
<!-- Terms of deal -->
<ng-template #termsOfDealTemp let-customCls="customCls">
	<div [id]="section?.terms_of_deal?.id" class="tjs-couponbar__term {{customCls}}" *ngIf="section?.terms_of_deal && section?.terms_of_deal?.title">
		<a href="#" data-bs-toggle="modal" data-bs-target="#termsOfDealModel" class="text-white fs-14 bk-discount-term" [innerHTML]="section?.terms_of_deal?.title | safeHtml" (click)="utilServ.showPopup('termsOfDealModel')"></a>
	</div>
</ng-template>
<!-- Modal for 'Terms of deal' -->
<div class="modal fade discount-bar-modal" id="termsOfDealModel" tabindex="-1" role="dialog" aria-labelledby="termsOfDealModelOpened" style="display: none;" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title" id="termsOfDealModelOpened">{{section?.terms_of_deal?.title}}</h3>
				<button type="button" class="btn-close fs-13 tjs-popup__closed" data-bs-dismiss="modal" aria-hidden="true"><i class="tjsicon-close text-danger"></i></button>
			</div>
			<div class="modal-body" *ngIf="section?.terms_of_deal?.desc">
				<div [id]="section?.terms_of_deal?.desc_id" class="container" [innerHTML]="section?.terms_of_deal?.desc | safeHtml: 'html'"></div>
			</div>
		</div>
	</div>
</div>
<!-- Button template -->
<ng-template #btnTemp let-customCls="customCls" let-btnClass="btnClass">
	<div class="tjs-couponbar__button {{customCls}}" [id]="section?.button?.id" *ngIf="section?.button && section?.button?.buttons && (section?.button?.buttons).length > 0">
		<ng-container *ngFor="let btn of section?.button?.buttons;trackBy: secServ.trackByFnIndex">
			<bk-navigate [secSett]="btn" class="mx-1" [innerHTML]="btn?.content" [customClass]="btnClass ? btnClass : ''"></bk-navigate>
		</ng-container>
	</div>
</ng-template>
<!-- Close bar -->
<ng-template #closeBar>
	<div class="tjs-couponbar__close" [class.ms-10]="!(secSett?.clock_bar_desktop_layout == 'bar_layout_v5' || secSett?.clock_bar_desktop_layout == 'bar_layout_v7')">
		<button type="button" class="close fs-12 p-5 rounded bg-white border-0" data-bs-dismiss="alert" aria-label="Close" (click)="hideSection = true"><span aria-hidden="true" class="tjsicon-close"></span></button>
	</div>
</ng-template>