import { Component, OnInit, ViewEncapsulation, ViewContainerRef, Self, Input } from '@angular/core';
import { takeUntil, filter } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute  } from '@angular/router';
// Base component
import { BaseComponent } from '../Base/Base.component';
// Services
import { NgOnDestroy, RenderComponentServ, ApiServ, InitServ, LoaderServ, UtilServ,StyleServ, CacheService } from '../Services';

@Component({
	selector: 'bk-session-main',
	template: `<router-outlet id="{{containerId}}"></router-outlet>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class SessionComponent extends BaseComponent implements OnInit {

	// Variables
	@Input() isEmbed: boolean = false;
	@Input() slug: string = '';
	@Input() embedSecId: string = '';
	loaderId: string = 'session-loader';
	containerId: string =  'session-container';
	routerEventSubscription: any;
	// When routing is this type (login/:access_token, review/:bid/:ratingtoken, invoice/:token, add-payment/:token) then replace this with slug for get the theme page data
	parentSlug: {[key: string]: string;} = {
		'review':'login',
		'referral-signup': 'signup',
		'add-payment': 'add-payment',
		'invoice': 'invoice',
		'invoice-payment': 'invoice-payment'
	}

	constructor(vcRef: ViewContainerRef, rcServ : RenderComponentServ, apiServ : ApiServ,public initServ: InitServ, public router: Router, public actRoute: ActivatedRoute, loader: LoaderServ, @Self() public destroy: NgOnDestroy, utilServ: UtilServ ,styleServ : StyleServ, cacheServ: CacheService) {
		// Pass base component required data
		super(vcRef, rcServ, apiServ, initServ, actRoute, loader, destroy, utilServ,styleServ, router, cacheServ);
		// Get slug by routing and called page api
		this.routerEventSubscription =  this.router.events.pipe(takeUntil(this.destroy),filter((event: any) => event instanceof NavigationEnd)).subscribe((event: any) => {
			let slug = event.url.split(/[?#]/)[0];
			if(slug.charAt(0) == '/'){
				slug = slug.substring(1);
			}
			let userAccessToken = (this.actRoute.snapshot.params && this.actRoute.snapshot.params['access_token']) ? this.actRoute.snapshot.params['access_token'] : null;
			// (login/:access_token, review/:bid/:ratingtoken,invoice/:user_access_token, invoice-payment/:user_access_token, add-payment/:token)' page urls replace with slug for get the page data
			let parentSlug = event.url.split(/[/?#]/)[1];
			if(this.parentSlug[parentSlug]){
				slug = this.parentSlug[parentSlug]
			}
			// Login with access token
			if(userAccessToken){
				slug = 'login';
			}
			this.loadComponent(slug, parentSlug);
		});
	}

	ngOnInit() {
		if(this.isEmbed){
			this.loadComponent();
		}
	}
	/**
	 * Load component
	 * @param slug: slug
	 */
	private loadComponent(slug: string='', parentSlug: string=''): void {
		if(this.isEmbed){
			slug = this.slug;
			this.containerId = this.containerId + '_'+this.embedSecId;
		}
		this.loadPageData(slug, this.containerId, this.loaderId, this.isEmbed, parentSlug);
		// if you need to scroll back to top, here is the right place
		setTimeout(() => {
			window.scrollTo({top: 0, behavior: 'smooth'});
		}, 1000);
	}

	ngOnDestroy(): void {
		this.routerEventSubscription.unsubscribe();
		this.destroy.next(true);
	}
}
