import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ } from '../../../Services';

@Component({
	selector: 'bk-button',
	template: `<div [id]="secId" class="bk-row-btn p-10" *ngIf="section && section?.buttons && (section?.buttons).length > 0">
		<div class="tjs-button-spacing bk-btn-group flex-grow-1">
			<div class="d-sm-inline-block p-5" *ngFor="let btn of section?.buttons;trackBy: secServ.trackByFnIndex">
				<bk-navigate [secSett]="btn" [innerHTML]="btn?.content"></bk-navigate>
			</div>
		</div>
	</div>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ButtonComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = null;
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.pageSett = this.sectionData.section_settings;
			this.secServ.setServData(this.pageSett, this.sectionData.content);
			if(this.pageSett && this.pageSett[this.secId] && this.secServ.buildMultiButtons(this.secId)){
				this.section = this.secServ.buildMultiButtons(this.secId);
			}
		}
	}
}
