import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
// Popup components
import { TipPopupComponent, PostponeComponent, CancelPopupComponent } from './Bookings/Popups';
import { ChangePasswordPopupComponent } from './Profile';

@Injectable({
	providedIn: 'root'
})
export class UserPopupServ {

	constructor(private dialog: MatDialog) { }
	/**
	 * Tip popup
	 * @param booking
	 * @returns popup ref
	 */
	public tipPopup(booking: any): any {
		let ref: MatDialogRef<TipPopupComponent>;
		ref = this.dialog.open(TipPopupComponent);
		ref.componentInstance.booking = booking;
		return ref.afterClosed();
	}
	/**
	 * Postpone booking
	 * @param booking
	 * @param postponeDates
	 * @param type CanPostpone/Postpone
	 * @returns popup ref
	 */
	public postponePopup(booking: any, postponeDates: any = null, type: string = 'CanPostpone'): any {
		let ref: MatDialogRef<PostponeComponent>;
		ref = this.dialog.open(PostponeComponent);
		ref.componentInstance.booking = booking;
		ref.componentInstance.postponeDates = postponeDates;
		ref.componentInstance.type = type;
		return ref.afterClosed();
	}
	/**
	 * Cancel service popup
	 * @param booking
	 * @param type : ''/single/recurring
	 * @returns popup ref
	 */
	public cancelServicePopup(booking: any, type: string = 'both'): any {
		let ref: MatDialogRef<CancelPopupComponent>;
		ref = this.dialog.open(CancelPopupComponent);
		ref.componentInstance.booking = booking;
		ref.componentInstance.type = type;
		return ref.afterClosed();
	}
	/**
	 * Change logged user password popup
	 * @returns popup ref
	 */
	public changePwdPopup(): any {
		let ref: MatDialogRef<ChangePasswordPopupComponent>;
		ref = this.dialog.open(ChangePasswordPopupComponent);
		return ref.afterClosed();
	}
}
