<div class="tjs-bcalendar" [ngClass]="{'pt-30 pt-md-70 pb-20 pb-md-40': isMultiStep}">
	<ng-container *ngIf="!noSpotAvailable else NoSpotAvailable">
		<ng-container *ngIf="(monthsReady && isDataLoaded['bookings'] && isDataLoaded['holidays'] && months && (months.length > 0)) else ElseBlockLoader">
			<div class="tjs-bcalendar-mstep-header d-md-flex" [ngStyle]="{'minHeight':!showCalendar ? '60px' : ''}" (ngInit)="initSlider()">
				<div class="tjs-bcalendar-mstep-slider flex-grow-1 position-relative" [ngClass]="{'w-100-imp':!sectionStatus?.first_available_btn}">
					<div id="cal-month-slider" class="keen-slider packages-slide-wrapper slider-item-wrapper" #monthSlide [attr.dir]="initServ?.selectedLang?.rtl ? 'rtl' :'ltr'">
						<ng-container *ngFor="let month of months; trackBy:trackByMonth;">
							<div class="cal-month tjs-bcalendar__header-slider keen-slider__slide">
								<div class="tjs-bcalendar__header d-flex justify-content-around align-items-center mb-30 w-100 tjs-pointer" *ngIf="showCalendar">
									<h3 class="text-center mb-0 fw-bold bk-calendar-header">{{month.name | translate}} {{month.year | translate}}</h3>
								</div>
							</div>
						</ng-container>
					</div>
					<span (click)="sliderOne.prev();" class="text-body start-0 position-absolute tjs-pointer fs-24 top-0 slider-arrow"><i class="tjsicon-left-arrow"></i></span>
					<span (click)="sliderOne.next();" class="text-body position-absolute end-0 top-0 fs-24 tjs-pointer tjs-hover-text__primary slider-arrow"><i class="tjsicon-right-arrow"></i></span>
				</div>
				<div class="tjs-bcalendar-mstep-button text-center mb-20 mb-md-0 text-md-end flex-shrink-0" *ngIf="sectionStatus?.first_available_btn">
					<bk-navigate [innerHTML]="calSection?.first_available_btn?.content" [secSett]="calSection?.first_available_btn" customClass="btn btn-success p-10 fs-14 text-white border-transparent" (callback)="selectFirstAvailabel()"></bk-navigate>
				</div>
			</div>
			<div id="cal-slider" [ngStyle]="{'minHeight':!showCalendar ? '431px' : ''}"  class="keen-slider packages-slide-wrapper slider-item-wrapper {{showCalendar?'tjs-animate-fadeup':''}}" #dateSlide [attr.dir]="initServ?.selectedLang?.rtl ? 'rtl' :'ltr'">
				<ng-container *ngFor="let month of months; trackBy:trackByMonth;">
					<div class="cal-month keen-slider__slide">
						<!-- <div class="tjs-bcalendar__header d-flex justify-content-around align-items-center mb-30 w-100">
							<h3 class="text-center mb-0 fw-bold">{{month.name}} {{month.year}}</h3>
						</div> -->
						<div class="text-center w-100" *ngIf="showCalendar">
							<div class="tjs-bcalendar__head flex-wrap mb-10 w-100 d-flex">
								<div class="bk-calendar-week">{{'Sun'| translate}}</div>
								<div class="bk-calendar-week">{{'Mon'| translate}}</div>
								<div class="bk-calendar-week">{{'Tue'| translate}}</div>
								<div class="bk-calendar-week">{{'Wed'| translate}}</div>
								<div class="bk-calendar-week">{{'Thu'| translate}}</div>
								<div class="bk-calendar-week">{{'Fri'| translate}}</div>
								<div class="bk-calendar-week">{{'Sat'| translate}}</div>
							</div>
							<div class="tjs-bcalendar__body w-100 flex-wrap d-flex bk-calendar-border">
								<ng-container *ngIf="month.daystoskip && (month.daystoskip).length > 0">
									<ng-container *ngFor="let item of month.daystoskip; let i = index;trackBy:trackByDates;">
										<div class="tjs-bcalendar__day tjs-transition--base text-dark d-flex justify-content-center align-items-center flex-column tjs-pointer m-0 position-relative tjs-bcalendar__past-date bk-calendar-border">
											<div></div>
										</div>
									</ng-container>
								</ng-container>
								<ng-container *ngIf="month.days && (month.days).length > 0">
									<ng-container *ngFor="let item of month.days; let i = index; trackBy:trackByDates;">
										<div class="tjs-bcalendar__day tjs-transition--base text-dark d-flex justify-content-center align-items-center flex-column tjs-pointer m-0 position-relative fw-bold bk-calendar-border bk-calendar-date" [ngClass]="{'tjs-bcalendar__active bk-calendar-active' : month.year+'-'+month.month+'-'+(i+1 | number:'2.0') == selectedDate,'tjs-bcalendar__past-date': checkIfPastDate(month.year,month.month,i+1), 'tjs-bcalendar__today' : (month.year+'-'+month.month+'-'+(i+1 | number:'2.0')) == currentDateYMD}" (click)="selectMultiStepDateFun(month.year,month.month,i+1)">
											<div class="tjs-bcalendar-date"><span class="cal-date-day d-lg-none" *ngIf="isMultiStep">{{getDayOfDate(month.year,month.month,i+1)}}</span>{{i+1 | number:'2.0'}}</div>
											<!-- Day discount doesn't display for past dates -->
											<div class="tjs-bcalendar__sale text-body fw-normal fs-13" *ngIf="!checkIfPastDate(month.year,month.month,i+1)">
												<span class="tjs-bcalendar__sale-text">
													{{scheduleServ.isDayDiscountValid(month.year,month.month,i+1, dateDiscountsArray, dayDiscountsArray, dayDiscountBookings, prefilledData) | translate}}
												</span>
											</div>
										</div>
									</ng-container>
								</ng-container>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</ng-container>
	</ng-container>
	<ng-template #ElseBlockLoader>
		<div class="position-relative tjs-section--space-lg text-center" [ngStyle]="{'minHeight':!showCalendar ? '50vh' : ''}" [id]="calSection?.wait_msg_id">
			<bk-app-loader [loaderId]="loaderId" [loading]="true" loaderText="{{(sectionStatus?.wait_msg && calSection?.wait_msg) ? calSection?.wait_msg : ''}}" customClass="tjs-loader tjs-loader--lg" [isCalender]="true"></bk-app-loader>
		</div>
	</ng-template>
	<ng-template #NoSpotAvailable>
		<div class="position-relative tjs-section--space-lg text-center" [ngStyle]="{'minHeight':!showCalendar ? '50vh' : ''}">
			<ng-container *ngIf="((BKFrm.controls['base_location_id'].value == 0 || !BKFrm.controls['base_location_id'].value )&& settings.form_data.location_type != 'no_location') else generalMsg">
				<span translate>It seems you have not filled the zip/postal code on the form, please fill the zip/postal code and check again to see the available spots.</span>
			</ng-container>
			<ng-template #generalMsg>
				<span [id]="calSection?.no_spot_msg_id" *ngIf="sectionStatus?.no_spot_msg">{{calSection?.no_spot_msg}}</span>
			</ng-template>
		</div>
	</ng-template>

</div>