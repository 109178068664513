import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ} from '../../Services';

@Component({
	selector: 'bk-benefits',
	templateUrl: './Benefits.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class BenefitsComponent implements OnInit {

	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		list: null
	}
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
	}
}
