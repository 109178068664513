<div class="p-md-40 p-20 bg-transparent dir-rtl w-100" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div class="text-center">
		<div [id]="section?.title?.id" *ngIf="section?.title?.content" class="mb-30 tjs-html-content" [innerHTML]="section?.title?.content | safeHtml" ></div>
		<!-- Form -->
		<div class="justify-content-center">
			<div class="text-center" *ngIf="section?.form">
				<form bkDebounceEvent [isSubmit]="true" [id]="section?.form?.id" class="tjs-mw-400 mx-auto" [formGroup]="subscribeForm" (debounceSubmit)="submitForm()">
					<div class="row justify-content-center justify-content-md-start mx-auto mx-md-start" [id]="section?.form?.email?.id">
						<div class="col-12 col-md-9 px-5 mb-15 mb-md-0" *ngIf="section?.form?.email?.label">
							<label  [id]="section?.form?.email?.label_id" class="form-label d-block bk-form-label text-start" [innerHTML]="section?.form?.email?.label | safeHtml" [ngClass]="{'text-center': varId=='bk_subscribe_popup_V3'}"></label>
						</div>
						<div class="col-12 col-md-9 px-5 mb-15 mb-md-0">
							<!-- Email -->
							<div [id]="section?.form?.email_id">
								<input type="email" class="form-control text-center text-md-start  bk-input" [class.is-invalid]="subscribeForm.controls['email'].hasError('required') && subscribeForm.controls['email']?.touched" formControlName="email" [placeholder]="section?.form?.email?.placeholder" (focusout)="detailsFocusOut()">
							</div>
							<!-- Error -->
							<div class="tjs-error bk-error" *ngIf="subscribeForm.controls['email']?.touched && (subscribeForm.controls['email'].hasError('required') || subscribeForm.controls['email'].hasError('pattern'))">
								<i class="tjsicon-attention"></i>
								<ng-container *ngIf="subscribeForm.controls['email'].hasError('required')">
									{{initServ?.appStr?.errorMsg?.empty | translate}}
								</ng-container>
								<ng-container *ngIf="subscribeForm.controls['email'].hasError('pattern')">
									{{initServ?.appStr?.errorMsg?.validEmail | translate}}
								</ng-container>
							</div>
						</div>
						<div class="col-12 col-md-3 bk-elem-visible px-5" *ngIf="section?.form?.button && section?.form?.button?.content">
							<!-- Button -->
							<bk-navigate class="w-100 d-block h-100" [secId]="section?.form?.button_id" [designId]="section?.form?.button?.design_id" [isSubmit]="true" [innerHTML]="section?.form?.button?.content" customClass="btn btn-success border-transparent" essentialClass="w-100 h-100 p-10-imp tjs-h-50"></bk-navigate>
						</div>
					</div>


					<!-- Cookie content -->
					<div class="tjs-privacy-text mt-10 mb-20 mx-auto" *ngIf="section?.form?.cookie_content">
						<span class="bk-cookie fs-14" [id]="section?.form?.cookie_content_id" [innerHTML]="section?.form?.cookie_content | safeHtml"></span>
					</div>
				</form>
				<!-- Branding -->
				<bk-branding type="link_text" customWrapClass="mt-20" customAnchorClass="fs-14"></bk-branding>
			</div>
		</div>
	</div>
</div>
