<div [id]="secId" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section" class="tjs-session-page py-100 tjs-bg-style position-relative" [class.embeded-container]="utilServ?.embedStatus" [ngStyle]="{'background-image' : (section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}">
	<div class="tjs-opacity--bg" [id]="section?.media?.id" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0"></div>
	<div class="tjs-session__wrapper">
		<div class="container">
			<div class="row" *ngIf="isbooked && section?.thank_you_msg?.content">
				<div class="col-12 col-md-7 text-center mb-25 mx-auto">
					<div class="tjs-section__description">
						<p [id]="section?.thank_you_msg?.id" class="bk-desc" [innerHTML]="section?.thank_you_msg?.content | safeHtml"></p>
					</div>
				</div>
			</div>
			<div class="tjs-section__title text-center mb-30 pb-30" *ngIf="section?.title?.content || section?.sub_title?.content">
				<!-- Title -->
				<div class="tjs-section__heading mb-15" *ngIf="section?.title?.content">
					<h4 [id]="section?.title?.id" class="fw-bold display-6 bk-title" [innerHTML]="section?.title?.content | safeHtml"></h4>
				</div>
				<!-- Sub title -->
				<div class="tjs-section__description" *ngIf="section?.sub_title?.content">
					<p [id]="section?.sub_title?.id" class="bk-sub-title" [innerHTML]="section?.sub_title?.content | safeHtml"></p>
				</div>
			</div>
			<!-- Forgot password form -->
			<form bkDebounceEvent [isSubmit]="true" *ngIf="section?.form" class="tjs-session__form bk-form" [formGroup]="resetForm" (debounceSubmit)="submitForm()">
				<div class="row">
					<div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto">
						<div class="shadow-sm card" [id]="section?.form?.id">
							<div class="text-center card-body px-sm-30">
								<!-- Avatar -->
								<div class="tjs-session__thumb shadow-sm mt-n60 bg-white overflow-hidden mb-20 rounded-circle white-bg d-flex align-items-center justify-content-center shadow-sm tjs-h-100 tjs-w-100 mx-auto">
									<bk-user-img alt="user image" width="90" height="90"></bk-user-img>
								</div>
								<div class="position-relative">
									<!-- New password -->
									<div class="mb-30 text-start" *ngIf="section?.form?.new_password">
										<label [id]="section?.form?.new_password?.label_id" class="form-label bk-form-label" *ngIf="section?.form?.new_password?.label" [innerHTML]="section?.form?.new_password?.label | safeHtml"></label>
										<input [id]="section?.form?.new_password_id" class="form-control bk-input" [ngClass]="{'is-invalid': f['new_password']?.touched && f['new_password']?.errors}" [placeholder]="section?.form?.new_password?.placeholder" formControlName="new_password" type="password">
										<!-- Error template -->
										<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'new_password'}"></ng-container>
									</div>
									<!-- Confirm password -->
									<div class="mb-30 text-start" *ngIf="section?.form?.confirm_password">
										<label [id]="section?.form?.confirm_password?.label_id" class="form-label bk-form-label" *ngIf="section?.form?.confirm_password?.label" [innerHTML]="section?.form?.confirm_password?.label | safeHtml"></label>
										<input [id]="section?.form?.confirm_password_id" class="form-control bk-input" [ngClass]="{'is-invalid': f['confirm_password']?.touched && f['confirm_password']?.errors}" [placeholder]="section?.form?.confirm_password?.placeholder" formControlName="confirm_password" type="password">
										<!-- Error template -->
										<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'confirm_password'}"></ng-container>
									</div>
									<!-- Submit button -->
									<div class="mb-25" *ngIf="section?.form?.button">
										<bk-navigate  [secId]="section?.form?.button_id" [designId]="section?.form?.button?.design_id" [isSubmit]="true" [innerHTML]="section?.form?.button?.content" customClass="btn btn-primary btn-lg border-transparent" essentialClass="w-100  bk-form-btn"></bk-navigate>
									</div>
									<!-- Loader -->
									<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
								</div>
								<!-- Login link -->
								<div class="mb-10" *ngIf="section?.form?.sign_in_link">
									<a [id]="section?.form?.sign_in_link_id" class="link--orange bk-link" [routerLink]="['/'+initServ.appDynamicRoutes['login']]" [queryParams]="utilServ?.embedStatus ? { embed: 'true'} : {}" [innerHTML]="section?.form?.sign_in_link | safeHtml"></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
<!-- Form error template-->
<ng-template #errorTemp let-controlName="controlName">
	<ng-container *ngIf="f[controlName]?.touched && (f[controlName]?.errors?.required || f[controlName]?.errors?.minlength || f[controlName]?.errors?.matching)">
		<div class="tjs-error">
			<i class="tjsicon-attention"></i>
			<ng-container *ngIf="f[controlName]?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.minlength"> {{initServ?.appStr?.errorMsg?.passwordLength | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.matching"> {{initServ?.appStr?.errorMsg?.passwordNotMatch | translate}} </ng-container>
		</div>
	</ng-container>
</ng-template>