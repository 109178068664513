<div class="tjs-popup tjs-popup--xl tjs-popup-calendar" [id]="calSection?.id" [ngClass]="{'slot-selected': isDateSelected}">
	<!-- Header -->
	<!-- <bk-popup-header [heading]="'Select Date'" [isCloseAll]="false"></bk-popup-header> -->
	<div class="tjs-popup__header d-flex justify-content-between flex-column flex-sm-row position-relative"  *ngIf="isDateSelected && selectedDate; else defaultHeader">
		<div>
			<button class="btn ps-0 btn-link p-10 fs-18 fw-bold tjs-headings-color" (click)="backToCal()" [id]="calSection?.back_to_cal_id"><i class="tjsicon-left-arrow-1 me-10 slider-arrow-v2"></i>{{calSection?.back_to_cal}}</button>
		</div>
		<div class="justify-content-center flex-column text-center">
			<h3 class="m-0 tjs-popup__title flex-column text-center fs-23" [id]="calSection?.sel_time_id">{{calSection?.sel_time}}</h3>
			<h6 class="fw-bold opacity-75 mb-0 bk-calendar-subtitle">{{dateServ.formattedDateMultiStep(selectedDate, true)}}</h6>
		</div>
		<div class="justify-content-end align-items-center">
			<button type="button" class="btn-close fs-13 tjs-popup__closed" (click)="dialogRef.close()"><i class="tjsicon-close text-danger"></i></button>
		</div>
	</div>
	<ng-template #defaultHeader>
		<div class="tjs-popup__header d-flex justify-content-between">
			<div class="d-none d-sm-flex"></div>
			<h3 class="m-0 tjs-popup__title justify-content-center" [id]="calSection?.sel_date_id">{{calSection?.sel_date | translate}}</h3>
			<div class="justify-content-end align-items-center">
				<button type="button" class="btn-close fs-13 tjs-popup__closed" (click)="dialogRef.close()"><i class="tjsicon-close text-danger"></i></button>
			</div>
		</div>
	</ng-template>
	<!-- Content -->
	<div class="tjs-popup__content bk-calendar">
		<div class="main-calendar">
			<bk-schedule-calendar
				[BKFrm] = "BKFrm"
				[settings] = "settings"
				[selectedDate] = "selectedDate"
				[dayDiscountBookings] = "dayDiscountBookings"
				[dayDiscounts] = "dayDiscounts"
				[dateDiscountsArray] = "dateDiscountsArray"
				[dayDiscountsArray] = "dayDiscountsArray"
				[prefilledData] = "prefilledData"
				[isSpotAvailable]="isSpotAvailable"
				(selectMultiStepDate) = "selectMultiStepDate($event)"
				[calSection]="calSection"
				[pageSett]="pageSett"
				>
			</bk-schedule-calendar>
		</div>
		<div class="main-slots">
			<ng-container *ngIf="selectedDate && perDateAvailSpots">
				<bk-multi-slot id="spot-select-list"
					[BKFrm] = "BKFrm"
					[selectedDate] = "selectedDate"
					[perDateAvailSpots] = "perDateAvailSpots"
					[availableProviders] = "availableProviders"
					[dateDiscountsArray] = "dateDiscountsArray"
					[dayDiscountsArray] = "dayDiscountsArray"
					[dayDiscountBookings] = "dayDiscountBookings"
					[calSection] = "calSection"
					[prefilledData] = "prefilledData"
					(onSlotChange) = "onSlotChangeFunc($event)">
				</bk-multi-slot>
			</ng-container>
		</div>
	</div>
</div>