/* eslint-disable max-lines-per-function */
/* eslint-disable max-depth */
/* eslint-disable max-params */
/* eslint-disable complexity */
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
declare const scrlToElement: any;
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { BkngFormServ, UtilServ } from './index';

@Injectable({
	providedIn: 'root'
})
export class BkngFormValidateServ {

	constructor(private bkngFormServ: BkngFormServ, public toastr: ToastrService, private utilServ: UtilServ) { }
	/**
	 * Validate service category
	 * @param BKFrm: booking form
	 * @param selectedServiceType: selected service category
	 * @returns any
	 */
	public validateServiceCat(BKFrm: any, selectedServiceType: any, formType: string= 'add'): any {
		let flag = 0;
		if(BKFrm.controls['is_service_hourly'].value == 'yes'){
			if(BKFrm.controls['service_hourly_value'].value || (['add', 'draft'].includes(formType) && this.isHourlyServParamBased(selectedServiceType))){
				flag = 1;
			}
		} else{
			flag = 1;
		}
		if(flag){
			return [true, null, '', ''];
		} else{
			let msg = 'Please select time duration';
			if(this.isHourlyServParamBased(selectedServiceType)){
				msg = 'Kindly review your parameter selection to ensure that the length is not set to zero.'
			}
			return [false, msg, '', 'service-section-id'];
		}
	}
	private isHourlyServParamBased(selectedServiceType: any){
		if(selectedServiceType && selectedServiceType.hourly_serv_price_on && selectedServiceType.hourly_serv_price_on == 'pricing_param_time'){
			return true;
		}
		return false;
	}
	/**
	 * Check validation result for all form parameters
	 * @param result : Get result by specific form
	 * @returns boolean
	 */
	public checkValidateResult(result: any): any {
		if(result){
			if(result[1]){
				setTimeout(()=>{
					// this.bkngFormServ.msgPopup(result[1])
					this.bkngFormServ.newMsgPopup(result[1])
				},1000)
			}
			if(result[3]){
				this.utilServ.scrollToSpecificEle(result[3]);
				// let resultEl:any = document.getElementById(result[3]);
				// if(resultEl){
					// Scroll to specific element
					// this.utilServ.scrollToSpecificEle(result[3]);
					// if(this.utilServ.inIframe(this.utilServ.embedStatus)){
					// 	let rect = resultEl.getBoundingClientRect();
					// 	this.scrollToElement(rect.top + window.scrollY);
					// }
				// }
			}
			return result[0];
		}
		return result;
	}
	/**
	 * Scroll to element
	 * @param elem
	 */
	public scrollToElement(elem: any){
		if(typeof(scrlToElement) !== 'undefined'){ scrlToElement(elem) }
	}
	/**
	 * Validate customer details multiple emails
	 * @param stepData: step data
	 * @param emailExist: status
	 * @param phoneExist: status
	 * @param customerGroup: customer group
	 * @param section: section
	 * @returns
	 */
	public validateCustomerDetailsEmail(stepData: any, emailExist: any, phoneExist: any, BKFrm: any, section: any): any {
		let flag = false;
		let customerGroup = BKFrm.controls['customer'];
		if(stepData.includes('customer_details') || stepData.includes('personal_details') || stepData.includes('address_details')){
			if(!emailExist && !phoneExist && customerGroup.controls['phone_number'].valid && customerGroup.controls['phone_numbers'].valid && customerGroup.controls['address'].valid && customerGroup.controls['emails'].valid && customerGroup.controls['customer_zipcode'].valid){
				flag = true
			}else if((BKFrm.value['location_type'] == 'SA' && (section.customer_details.design_id == 'bk_customer_v3' || section.customer_details.design_id == 'bk_customer_v4')) && !stepData.includes('address_details') && !customerGroup.controls['address'].valid){
				flag = true;
			} else {
				customerGroup.markAllAsTouched();
				let msg = "";
				if(customerGroup.controls['email_id'].value && emailExist){
					msg = "You're entering the same email address in both the primary and secondary email fields. Please make sure these email addresses are not the same.";
				} else if(customerGroup.controls['phone_number'].value && phoneExist){
					msg = "You're entering the same phone number in both the primary and secondary phone fields. Please make sure these phone numbers are not the same.";
				} else{
					msg = "Please fill in the customer's valid details.";
				}
				let invalidControl = null;
				const subControls = customerGroup.controls;
				let notCheckControls: string[] = ['email_id', 'password']
				for(const subName in subControls){
					if(!notCheckControls.includes(subName) && subControls[subName].invalid){
						invalidControl = subName;
						break
					}
				}
				let errorMsg: any = this.invalidControlMsg(invalidControl, BKFrm, true, section);
				if(!errorMsg && msg){
					this.toastr.error(msg);
				} else {
					this.toastr.error(errorMsg);
				}
				flag = false;
			}
		} else{
			flag = true;
		}
		return flag;
	}
	/**
	 * Validate customer details
	 * @param stepData: step data
	 * @param pwdStatus: password status
	 * @param emailIdStatus: email id status
	 * @param customerGroup: custo,er group control
	 * @param section: section
	 * @returns
	 */
	public validateCustomerDetails(stepData: any, pwdStatus: any, emailIdStatus: any, customerGroup: any, section: any, BKFrm: any): any {
		let flag: boolean = false;
		if(stepData.includes('customer_details') || stepData.includes('personal_details')){
			customerGroup.markAllAsTouched();
			let sectionId = '';
			let msg = "Please fill in the customer's valid details.";
			let userSec: any;
			if(BKFrm.value['location_type'] == 'SA'){
				userSec = section?.customer_details;
			} else {
				userSec = section?.personal_details;
			}
			if(!customerGroup.controls['first_name'].valid){
				sectionId = userSec?.first_name_id;
			}else if(!customerGroup.controls['last_name'].valid){
				sectionId = userSec?.last_name_id;
			}else if(!customerGroup.controls['email_id'].valid){
				sectionId = userSec?.email_id;
			}else if(!pwdStatus){
				sectionId = userSec?.password_id;
				msg = 'Please fill in the password.';
			}
			this.utilServ.scrollToSpecificEle(sectionId);
			this.toastr.error(msg);
			flag = false;
		} else {
			customerGroup.controls['password'].markAsTouched();
			customerGroup.controls['email_id'].markAsTouched();
			let sectionId = section?.email?.email_id;
			let msg = "Please fill in the customer's valid details.";
			if(emailIdStatus && !pwdStatus){
				sectionId = section?.email?.password_id;
				msg = 'Please fill in the password.';
			}
			this.utilServ.scrollToSpecificEle(sectionId);
			this.toastr.error(msg);
			flag = false;
		}
		return flag;
	}
	/**
	 * Validate notes
	 * @param stepData: step data
	 * @param BKFrm: booking form
	 * @param section: section data
	 * @returns
	 */
	public validateNotes(stepData: any, BKFrm: any, section: any): boolean {
		if(stepData.includes('key_info')){
			if(BKFrm.controls['key_note'].valid){
				return true;
			} else{
				BKFrm.controls['key_note'].markAsTouched();
				this.utilServ.scrollToSpecificEle(section?.key_info?.id);
				this.toastr.error('Please fill the value for the key note.');
				return false;
			}
		} else{
			if(BKFrm.controls['special_notes'].valid){
				return true;
			} else{
				BKFrm.controls['special_notes'].markAsTouched();
				this.utilServ.scrollToSpecificEle(section?.special_note?.id);
				this.toastr.error('Please fill the valid special note.');
				return false;
			}
		}
	}
	/**
	 * Validate form parameters
	 * @param BKFrm
	 * @param formId
	 * @param formLayout
	 * @param selectedServiceType
	 * @param settings
	 * @returns
	 */
	// eslint-disable-next-line max-params, max-lines-per-function
	public validateFormParams(BKFrm: any, formId: any, formLayout: string, selectedServiceType: any, settings: any, formParamsStatus:any = null, formType: string = 'add'): any {
		let validStatus = false;
		let validItemStatus = false;
		let validPckgStatus = false;
		let validAreaParam = false;
		let result: any;
		switch(+formId){
			case 1:
				// Check if pricing parameters are selected.
				validItemStatus = true;
				validPckgStatus = true;
				validAreaParam = true;
				if((formParamsStatus.pricingParams && BKFrm.controls['pricing_parameter'].value && (BKFrm.controls['pricing_parameter'].value).length > 0 ) || this.bkngFormServ.validateFormParams(settings)){
					if(formLayout == 'multi_step'){
						validStatus = true;
					} else {
						result = this.validateServiceCat(BKFrm, selectedServiceType, formType);
						validStatus = this.checkValidateResult(result);
					}
				}
			break;
			case 2:
				// Check if item and packages are selected.
				validStatus = true;
				validAreaParam = true;
				// eslint-disable-next-line no-case-declarations
				let formItems: any = BKFrm.controls['items'].value;
				if((formItems && formItems.length > 0) || this.bkngFormServ.validateFormParams(settings)){
					validItemStatus = true;
					if((formParamsStatus.itemPackages && formItems[0] && formItems[0].packages && (formItems[0].packages).length > 0) || this.bkngFormServ.validateFormParams(settings)){
						validPckgStatus = true;
						if(formLayout == 'multi_step'){
							validStatus = true;
						} else {
							result = this.validateServiceCat(BKFrm, selectedServiceType, formType);
							validStatus = this.checkValidateResult(result);
						}
					}
				}
			break;
			case 3:
				// Check if service, frequency, items and addons are selected.
				validStatus = true;
				validPckgStatus = true;
				validAreaParam = true;
				// eslint-disable-next-line no-case-declarations
				let items = BKFrm.controls['items'].value;
				if((formParamsStatus.itemsAddons && this.utilServ.checkArrLength(items)) || this.bkngFormServ.validateFormParams(settings)){
					validItemStatus = true;
					if(formLayout == 'multi_step'){
						validStatus = true;
					} else {
						result = this.validateServiceCat(BKFrm, selectedServiceType, formType);
						validStatus = this.checkValidateResult(result);
					}
				}
			break;
			case 4:
				// Check if service, frequency and area parameters are selected
				validItemStatus = true;
				validPckgStatus = true;
				// eslint-disable-next-line no-case-declarations
				let areaParam: any = <FormGroup>BKFrm.controls['area_parameter'];
				if((formParamsStatus.areaParams && areaParam.controls['id'].value)|| this.bkngFormServ.validateFormParams(settings)){
					if(formLayout == 'multi_step'){
						validStatus = true;
					} else {
						result = this.validateServiceCat(BKFrm, selectedServiceType, formType);
						validStatus = this.checkValidateResult(result);
					}
					if(areaParam.controls['quantity'].valid || this.bkngFormServ.validateFormParams(settings)){
						validAreaParam = true;
					}
				}
				break;
			default:
				if(formLayout == 'multi_step'){
					validStatus = true;
					validItemStatus = true;
					validPckgStatus = true;
					validAreaParam = true;
				} else {
					result = this.validateServiceCat(BKFrm, selectedServiceType, formType);
					let status = this.checkValidateResult(result);
					if(status){
						validStatus = true;
						validItemStatus = true;
						validPckgStatus = true;
						validAreaParam = true;
					}
				}
		}
		let obj: any = {validStatus,validItemStatus,validPckgStatus,validAreaParam}
		return obj;
	}
	/**
	 * Function to call in case of form not valid on step one submission.
	 */
	/**
	 * form not valid on step one submission
	 * @param BKFrm
	 * @param settings
	 * @param customFieldsComp
	 */
	public stpOneElse(BKFrm: any, settings: any, customFieldsComp:any, section: any, stepData: any = []){
		let errorMsg = "";
		let invalidControl = null;
		let isInvalidCust = false;
		const controls = BKFrm.controls;
		for(const name in controls) {
			if(name != 'customer'){
				if((name == 'zipcode' || name == 'location_id') && controls[name].invalid){
					invalidControl = name;
					break;
				}
			} else{
				if((settings.form_data.enable_email_on_first_step && settings.form_data.enable_email_on_first_step == 'yes') || !settings.form_data.enable_email_on_first_step){
					const subControls = BKFrm.controls['customer'].controls;
					for(const subName in subControls){
						if((subName == 'email_id') && subControls[subName].invalid){
							invalidControl = subName;
							isInvalidCust = true;
							break
						}
					}
					if(isInvalidCust){
						break;
					}
				}
			}
		}
		errorMsg = this.invalidControlMsg(invalidControl, BKFrm, true, section);

		BKFrm.controls['zipcode'].markAsTouched();
		BKFrm.controls['customer'].get('first_name').markAsTouched();
		BKFrm.controls['customer'].get('last_name').markAsTouched();
		BKFrm.controls['customer'].get('email_id').markAsTouched();
		BKFrm.controls['adjusted_price'].markAsTouched();
		BKFrm.controls['adjusted_time'].markAsTouched();
		/** Call common function to mark custom fields to touched. **/
		let customFieldValidate: any = this.isCustomFieldValid(BKFrm, stepData);
		// Refresh the custom field component
		if(!customFieldValidate && customFieldsComp){
			customFieldsComp?.markAllCustFieldsAsTouched();
		}
		if(!errorMsg){
			errorMsg = 'Please fill the required fields marked in red.';
		}
		if(errorMsg){
			this.toastr.error(errorMsg);
		}
	}
	/**
	 * Function to return specific msg according to invalid control.
	 */
	public invalidControlMsg(name: any, BKFrm: any, flag : boolean = false, section: any){
		let msg = '';
		let sectionId =  '';
		let userSec: any;
			if(BKFrm.value['location_type'] == 'SA'){
				userSec = section?.customer_details;
			} else {
				userSec = section?.personal_details;
			}
		switch(name){
			case 'location_id':
			case 'zipcode':
				msg = 'Please fill in the location for this booking.';
				if(BKFrm && BKFrm.controls['location_type'].value == 'ML'){
					msg = 'Please select the location for this booking.';
				}
				sectionId = section?.id;
			break;
			case 'arrival_date':
				msg = 'Please select the date for this booking.';
				sectionId = 'provider-section-id';
			break;
			case 'arrival_time':
				msg = 'Please select the time slot for this booking.';
				sectionId = 'provider-section-id';
			break;
			case 'first_name':
			case 'last_name':
			case 'emails':
			case 'phone_number':
			case 'phone_numbers':
				msg = "Please fill in the customer's valid details.";
				sectionId = userSec?.id;
			break;
			case 'email_id':
				msg = "Please fill in the customer's valid email.";
				sectionId = userSec?.email_id;
				if(flag){
					sectionId = section?.email?.email_id;
				}
			break;
			case 'password':
				msg = "Please fill in the customer's password.";
				sectionId = userSec?.password_id;
				if(flag){
					sectionId = section?.email?.email_id;
				}
			break;
			case 'address':
				msg = "Please fill in the customer's address.";
				sectionId = section?.address_details?.address_id;
			break;
			case 'customer_zipcode':
				msg = "Please fill in the customer's address.";
				sectionId = section?.address_details?.zipcode_id;
			break;
			case 'key_note':
				msg = 'Please fill the value for the key note.';
				sectionId = section?.key_info?.id;
			break;
			case 'special_notes':
				msg = 'Please fill the valid special note.';
				sectionId = section?.special_note?.id;
			break;
			case 'newCreditCardDetail':
				msg = 'Please fill in the valid card details.'
				sectionId = section?.payment_info?.id;
			break;
			case('billing-address'):
				msg = "Please fill in the customer's billing address.";
				sectionId = 'billing-address';
			break;
		}
		this.utilServ.scrollToSpecificEle(sectionId);
		return msg;
	}

	/**
	* Function to call in case of form not valid on submit.
	*/
	public sbmtElse(BKFrm: any, emailExist: any, phoneExist: any, customFieldsComp: any, section: any, stepTwoSec: any = []){
		let errorMsg = "";
		let invalidControl = null;
		let isInvalidCust = false;
		const controls = BKFrm.controls;
		for(const name in controls) {
			if(name != 'customer' && name != 'credit_card'){
				if(controls[name].invalid) {
					invalidControl = name;
					break;
				}
			}else{
				const subControls = BKFrm.controls[name].controls;
				for(const subName in subControls){
					if(subName == 'email_id' && subControls[subName].errors && subControls[subName].errors['emailExists']){
						invalidControl = 'password';
						isInvalidCust = true;
					} else {
						if(subControls[subName].invalid){
							invalidControl = subName;
							if(name == 'credit_card'){
								invalidControl = 'billing-address';
							}else{
								isInvalidCust = true;
							}
							break
						}
					}

				}
				if(isInvalidCust){
					break;
				}
			}
		}
		errorMsg = this.invalidControlMsg(invalidControl, BKFrm, false, section);
		BKFrm.controls['arrival_date'].markAsTouched();
		BKFrm.controls['arrival_time'].markAsTouched();
		BKFrm.controls['zipcode'].markAsTouched();
		BKFrm.controls['customer'].get('first_name').markAsTouched();
		BKFrm.controls['customer'].get('last_name').markAsTouched();
		BKFrm.controls['customer'].get('phone_number').markAsTouched();
		BKFrm.controls['customer'].get('address').markAsTouched();
		BKFrm.controls['customer'].get('customer_zipcode').markAsTouched();
		BKFrm.controls['key_note'].markAsTouched();
		BKFrm.controls['adjusted_price'].markAsTouched();
		BKFrm.controls['adjusted_time'].markAsTouched();
		BKFrm.controls['custom_fields'].markAllAsTouched();
		BKFrm.controls['custom_fields_checkbox'].markAllAsTouched();
		/** Call common function to mark custom fields to touched. **/
		if(!errorMsg){
			errorMsg = 'Please fill the required fields marked in red.';
			if(BKFrm.controls['customer'].get('email_id').value && emailExist){
				errorMsg = "You're entering the same email address in both the primary and secondary email fields. Please make sure these email addresses are not the same.";
			} else if(BKFrm.controls['customer'].get('phone_number').value && phoneExist){
				errorMsg = "You're entering the same phone number in both the primary and secondary phone fields. Please make sure these phone numbers are not the same.";
			}
			let customFieldValidate: any = this.isCustomFieldValid(BKFrm, stepTwoSec);
			// Refresh the custom field component
			if(!customFieldValidate && customFieldsComp){
				customFieldsComp?.markAllCustFieldsAsTouched();
			}
		}
		this.toastr.error(errorMsg);
		return errorMsg;
	}
	// ---------------------CUSTOM FIELD VALIDATION NEW FUNCTIONS---------------------
	/**
	 * Checks if the custom field is valid or not. First, it checks if the backup form object is valid.
	 * If not, it scrolls to the first invalid custom field section, marks all the required fields as touched and displays a toastr error message asking to fill the required fields marked in red.
	 * @param BKFrm: any The current backup form object.
	 * @param stepData : any The data object for the current form step.
	 * @returns boolean Returns true if the custom field is valid, false otherwise.
	 */
	public isCustomFieldValid(BKFrm: any, stepData: any): boolean {
		if(!BKFrm.valid){
			if(!(BKFrm.controls['custom_fields'].valid && BKFrm.controls['custom_fields_checkbox'].valid)){
				this.scrollTo1stInvCustFld(BKFrm, stepData);
				BKFrm.controls['custom_fields'].markAllAsTouched();
				BKFrm.controls['custom_fields_checkbox'].markAllAsTouched();
				return false;
			}
		}
		return true;
	}
	/**
	 * scrolls the page to the first invalid custom field section.
	 * @param BKFrm: any The current backup form object.
	 * @param stepData: any The data object for the current form step.
	 */
	private scrollTo1stInvCustFld(BKFrm: any, stepData: any): void {
		// Call a function that finds the first invalid custom field section and stores it into invField variable.
		let invField: any = this.firstInvCustSec(BKFrm, stepData);
		// If invField is not null, scroll to the specific element using the ID of the custom section.
		if(invField){
			let cid = 'custom-field-'+invField;
			this.utilServ.scrollToSpecificEle(cid);
		}
	}
	/**
	 * Retrieves the ID of the first custom field section that contains an invalid field.
	 * @param BKFrm: any The current backup form object.
	 * @param stepData: any The data object for the current form step.
	 * @returns The ID of the first custom field section containing an invalid field, null otherwise.
	 */
	private firstInvCustSec(BKFrm: FormGroup, stepData: any): any {
		// Get an array of the names of all the custom fields
		let custFields: Array<string> = Object.keys(BKFrm.controls['custom_fields'].value);
		// Get the FormGroup object for the custom fields
		let custFieldGrp: any = <FormGroup>BKFrm.controls['custom_fields'];
		// Check each section in the form step for invalid fields
		if(stepData && stepData.length > 0){
			for(let sec of stepData){
				if(sec.includes('custom_fields')){
					// Get the ID of the current custom field section
					let custSecId: any = this.getSecIdFromString(sec)
					// Check each custom field within the current section for validity
					if(custSecId && +custSecId > 0 && custFields && custFields.length > 0){
						for(let field of custFields){
							let fieldIdsArr: string[] = field.split('_');
							let secIdFromField = fieldIdsArr[0];
							// eslint-disable-next-line max-depth
							if(+custSecId == +secIdFromField && !(custFieldGrp.controls[field].valid && this.isCustCheckBoxFieldVal(BKFrm, field))){
								return fieldIdsArr[1];
							}
						}
					}
				}
			}
		}
		return null;
	}
	private isCustCheckBoxFieldVal(BKFrm: any, field: any): boolean {
		let cfCheckboxGrp: any = <FormGroup>BKFrm.controls['custom_fields_checkbox'];
		if(cfCheckboxGrp.contains(field) && !cfCheckboxGrp.controls[field].valid){
			return false;
		}
		return true;
	}
	/**
	 * Retrieve the ID portion of a string composed of sections separated by '_' (underscore).
	 * @param sec:string The input string consisting of sections separated by underscores.
	 * @returns any The ID portion of the input string.
	 */
	private getSecIdFromString(sec: string): any {
		let splitArr: Array<any> = sec.split('_');
		return splitArr[splitArr.length - 1];
	}
}
