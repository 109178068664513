<ng-container *ngIf="readOnly; else activeStar">
	<div class="tjs-rating {{customClass}}">
		<svg aria-hidden="true" focusable="false" class="tjs-rating-inner tjs-rating-readonly" [style.--activeStarColor]="starColor">
			<ng-container *ngIf="stars && stars.length > 0">
				<ng-container *ngFor="let star of stars;trackBy: utilServ.trackByFnIndex">
					<use attr.href="{{showStar(star.value)}}" />
				</ng-container>
			</ng-container>
		</svg>
	</div>
</ng-container>
<ng-template #activeStar>
	<ng-container *ngIf="stars && stars.length > 0">
		<ng-container *ngIf="rating && rating%1 !== 0; else fullRatingBlock">
			<div class="tjs-rating bk-custom-rating {{customClass}}">
				<ng-container  *ngFor="let star of stars;trackBy: utilServ.trackByFnIndex">
					<span class="full {{disable()}} {{filled(star?.value)}}" (click)="toggle(star?.value)" [ngStyle]="{'color':(filled(star?.value) == 'star' ? starColor : color),'fontSize':starSize ? starSize+'px' : ''}"></span>
					<!-- <span class="half {{disable()}} {{filled(star?.value-0.5)}}" (click)="toggle(star?.value-0.5)" [ngStyle]="{'color':(filled(star?.value-0.5) == 'star' ? starColor : color),'fontSize':starSize ? starSize+'px' : ''}"></span> -->
					<span *ngIf="rating" class="half bk-star-group-disabled bk-star-group-readonly hide-half-star {{disable()}} {{filled(star?.value-0.5)}}" [ngStyle]="{'color':(filled(star?.value-0.5) == 'star' ? starColor : color),'fontSize':starSize ? starSize+'px' : ''}" (click)="toggle(star?.value)"></span>
				</ng-container>
			</div>
		</ng-container>
		<ng-template #fullRatingBlock>
			<div class="tjs-rating-clickable {{customClass}}">
				<ng-container  *ngFor="let star of stars.reverse();trackBy: utilServ.trackByFnIndex">
					<span class="full {{disable()}} {{filled(star?.value)}}" (click)="toggle(star?.value)" [ngStyle]="{'color':(filled(star?.value) == 'star' ? starColor : color),'fontSize':starSize ? starSize+'px' : ''}"></span>
				</ng-container>
			</div>
		</ng-template>
	</ng-container>
</ng-template>
<svg id="stars" *ngIf="readOnly" style="display: none;" version="1.1">
	<symbol id="stars-empty-star" viewBox="0 0 102 18" fill="#E5E5E5">
		<path d="M9.5 14.25l-5.584 2.936 1.066-6.218L.465 6.564l6.243-.907L9.5 0l2.792 5.657 6.243.907-4.517 4.404 1.066 6.218" />
	</symbol>
	<symbol id="stars-full-star" viewBox="0 0 102 18">
		<path d="M9.5 14.25l-5.584 2.936 1.066-6.218L.465 6.564l6.243-.907L9.5 0l2.792 5.657 6.243.907-4.517 4.404 1.066 6.218" />
	</symbol>
	<symbol id="stars-half-star" viewBox="0 0 102 18">
		<use xlink:href="#stars-empty-star" />
		<path d="M9.5 14.25l-5.584 2.936 1.066-6.218L.465 6.564l6.243-.907L9.5 0l2.792" />
	</symbol>
</svg>
