import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// External lib
import { TranslateModule } from '@ngx-translate/core';
// Modules
import { GlobalDefaultModule } from '../Global';
// Components
import { HeaderComponent, FooterComponent, DeactivateAccountPopupComponent, HeaderMenuComponent, FooterMenuComponent } from './Layout';
@NgModule({
	declarations: [
		HeaderComponent, FooterComponent, DeactivateAccountPopupComponent,HeaderMenuComponent, FooterMenuComponent
	],
	imports: [
		CommonModule, RouterModule, TranslateModule,MatDialogModule, FormsModule, ReactiveFormsModule,
		GlobalDefaultModule
	],
	exports: [
		HeaderComponent, FooterComponent
	],
	entryComponents: [DeactivateAccountPopupComponent]
})
export class CoreModule { }