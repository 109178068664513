import { Component, OnInit, ViewEncapsulation, Input, ViewChild, Self,ElementRef } from '@angular/core';
import { takeUntil } from 'rxjs';
// External library
import KeenSlider from "keen-slider";
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ,UtilServ } from '../../Services';

@Component({
	selector: 'bk-partners',
	template: `
		<div [id]="secId" class="bg-white py-50 tjs-clients" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section && (section?.list && section?.list?.items && (section?.list?.items).length > 0)">
		<div class="container">
			<div [attr.dir]="initServ?.selectedLang?.rtl ? 'rtl' :'ltr'" class="keen-slider bk-slider" #partnerSlide>
				<ng-container *ngFor="let item of section?.list?.items;trackBy: secServ.trackByFnIndex">
					<div [id]="item?.id" class="keen-slider__slide d-flex-imp justify-content-center align-items-center slide bk-slider-item" *ngIf="(item?.media && (item?.media).length > 0)">
						<img loading="auto" [id]="item?.media_id" class="img-fluid bk-slider-item-media" [src]="item?.media[0]" alt="Partners" height="100" width="230"/>
					</div>
				</ng-container>
			</div>
		</div>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class PartnersComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	@ViewChild("partnerSlide") sliderRef : ElementRef<HTMLElement> | undefined;
	pageSett: any;
	slider: any = null;
	// Section fields
	section: any = {
		list: null
	}
	// Slick configuration
	slideConfig:any = {
		loop:false,
		breakpoints: {
			"(max-width: 1024px)": {
				slides: { perView: 3,spacing: 30 },
			},
			"(max-width: 991px)": {
				slides: { perView: 2 ,spacing: 30},
			},
			"(max-width: 778px)": {
				slides: { perView: 1 ,spacing: 5},
			},
		},
		slides: { perView: 4,spacing: 30},
		rtl:(this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? true : false,
		defaultAnimation:{duration:1000},
	};
	sectionData: any;

	constructor( public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, @Self() private destroy: NgOnDestroy,public utilServ : UtilServ, ) {
		this.initServ.isRTLChange.pipe(takeUntil(this.destroy)).subscribe((value) => {
			this.slideConfig['rtl']=value;
			if(this.slider && this.sliderRef){
				this.slider.update(this.slideConfig);
			}
		});
	}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
		setTimeout(()=>{
			if(this.sliderRef){
				if (this.slider) {this.slider.destroy();}
				this.slider = new KeenSlider(this.sliderRef.nativeElement,this.slideConfig,[(slider) => this.utilServ.autoPlay(slider)])
			}
		})
		if(this.pageSett && this.secId && this.pageSett[this.secId] && this.pageSett[this.secId][this.rcServ.statusType] && this.section && (this.section.list && this.section.list.items && (this.section.list.items).length > 0)){
			if((this.section.list.items).length > 4){
				this.slideConfig['loop'] = true;
			}
			if((this.section.list.items).length < 4){
				this.slideConfig.slides = {
					perView: (this.section.list.items).length ,spacing: 30
				};
				if(this.slider && this.sliderRef){
					this.slider.update(this.slideConfig);
				}
			}
		}
	}

	ngOnDestroy():void {
		if (this.slider) {this.slider.destroy();}
	}
}