<div [id]="secId" class="form__short-form tjs-banner position-relative tjs-section--space-lg tjs-bg-style tjs-banner--with-text bk-media" [ngStyle]="{'background-image' : (section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}" *ngIf="secSett && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section" [ngClass]="{'tjs-short-form-v4': secSett && secSett?.variation_id == 'bk_short_form_V4'}">
	<div [id]="section?.media?.id" class="tjs-opacity--bg" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0"></div>
	<div class="container" [ngSwitch]="secSett?.variation_id">
		<!-- V1 -->
		<div class="tjs-banner__inner mx-auto text-center py-30 py-lg-70 px-10 px-lg-50" *ngSwitchCase="'bk_short_form_V1'">
			<ng-container *ngTemplateOutlet="titleTemp; context:{data: section}"></ng-container>
			<!-- Button -->
			<div class="tjs-button-spacing bk-btn-group" *ngIf="section?.button && section?.button?.content">
				<bk-navigate [secSett]="section?.button" [innerHTML]="section?.button?.content"></bk-navigate>
			</div>
		</div>
		<!-- v2 -->
		<div class="tjs-banner__inner tjs-banner__inner--overlay short-form-title position-relative mx-auto text-center py-30 py-lg-70 px-10 px-lg-50" *ngSwitchCase="'bk_short_form_V2'">
			<!-- Content -->
			<div class="position-relative">
				<div class="step active-step mx-auto" id="email-step-one">
					<ng-container *ngTemplateOutlet="titleTemp; context:{data: section}"></ng-container>
					<!-- Email form -->
					<div class="tjs-banner__btn-xl" [formGroup]="emailAddrForm">
						<div class="mb-0">
							<div class="row d-flex flex-wrap mx-n5 justify-content-center align-items-stretch">
								<div class="col-sm-12 col-md-6 mb-20 mb-md-0 px-5">
									<input *ngIf="userInfo && userInfo?.email_id; else elseEmail" class="form-control h-100 bk-input" formControlName="email_id" placeholder="Ex: example@xyz.com" readonly="">
									<ng-template #elseEmail>
										<input class="form-control h-100 bk-input" placeholder="{{section?.email_placeholder?.content}}" formControlName="email_id" (focusout)="emailExistControl(emailAddrForm.controls['email_id'])" [ngClass]="{'is-invalid': emailAddrForm.controls['email_id']?.touched && (emailAddrForm.controls['email_id']?.errors?.required || emailAddrForm.controls['email_id']?.errors?.pattern)}">
										<!-- Error -->
										<small class="tjs-error bk-error" *ngIf="emailAddrForm.controls['email_id']?.touched && (emailAddrForm.controls['email_id']?.errors?.required || emailAddrForm.controls['email_id']?.errors?.pattern)">
											<i class="tjsicon-attention"></i>
											<ng-container *ngIf="emailAddrForm.controls['email_id']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
											<ng-container *ngIf="emailAddrForm.controls['email_id']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.validEmail | translate}} </ng-container>
										</small>
									</ng-template>
								</div>
								<div class="col-12 col-md-auto px-5">
									<!-- Button -->
									<bk-navigate *ngIf="section?.continue_btn && section?.continue_btn?.content" [secSett]="section?.continue_btn" customClass="btn btn-primary py-15 border-transparent" essentialClass="w-100" [innerHTML]="section?.continue_btn?.content" (callback)="submitForm()"></bk-navigate>
								</div>
							</div>
						</div>
					</div>
					<!-- Cookie content -->
					<div class="mt-10 mb-20" *ngIf="section?.cookie_content && section?.cookie_content?.content">
						<span class="tjs-cookie bk-cookie fs-16" [id]="section?.cookie_content?.id" [innerHTML]="section?.cookie_content?.content | safeHtml"></span>
					</div>
				</div>
				<!-- Industry selection -->
				<div class="step mx-auto" id="email-step-two" *ngIf="section?.industry">
					<h2 [id]="section?.industry?.title_id" *ngIf="section?.industry?.title" class="display-1 bk-title mb-25"  [innerHTML]="section?.industry?.title | safeHtml"></h2>
					<h3 [id]="section?.industry?.sub_title_id" *ngIf="section?.industry?.sub_title" class="display-6 fw-light bk-sub-title mb-20 mb-md-50" [innerHTML]="section?.industry?.sub_title | safeHtml"></h3>
					<!-- Select industry -->
					<div class="row justify-content-center" *ngIf="industries && industries.length > 0">
						<div class="row justify-content-center">
							<div class="col-sm-10 col-md-6 col-xl-5">
								<div class="card shadow-sm mb-10 mb-md-30">
									<div class="card-body d-md-flex justify-content-between align-items-center  ps-md-25 pe-md-15 py-20 py-md-10 px-20 text-start">
										<label class="form-label mb-10 mb-md-0 me-sm-15 me-10 flex-shrink-0" *ngIf="section?.industry?.industry" [id]="section?.industry?.industry_id" [innerHTML]="section?.industry?.industry | safeHtml"></label>
										<!-- Select industry -->
										<select class="form-select form-select-sm tjs-border-md-0 shadow-none pe-35 fs-15 bk-select" (change)=industryChange($event)>
											<ng-container *ngFor="let industry of industries;trackBy: secServ.trackByFnIndex">
												<option [value]="industry.industry_slug" [selected]="industry.id == selectedIndustryId">
													{{(industry?.custom_industry_name ? industry?.custom_industry_name : industry?.industry_name) |translate }}
												</option>
											</ng-container>
										</select>
									</div>
								</div>
								<div class="d-flex justify-content-center flex-sm-row flex-column-reverse">
									<div class="me-sm-20 me-0 mt-5 mt-sm-0 d-md-flex">
										<bk-navigate *ngIf="section?.back_btn && section?.back_btn?.content" [secSett]="section?.back_btn" customClass="btn btn-light py-15 border-transparent" [innerHTML]="section?.back_btn?.content" essentialClass="w-100" (callback)="backToEmail()"></bk-navigate>
									</div>
									<div>
										<bk-navigate *ngIf="section?.continue_btn && section?.continue_btn?.content" [secSett]="section?.continue_btn" customClass="btn btn-primary py-15 border-transparent" essentialClass="w-100" [innerHTML]="section?.continue_btn?.content" (callback)="goToBooknow()"></bk-navigate>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Loader -->
			<bk-app-loader [loaderId]="stepperLoader"></bk-app-loader>
		</div>
		<!-- v3 -->
		<div class="row justify-content-center justify-content-lg-start" *ngSwitchCase="'bk_short_form_V3'">
			<div class="col-md-9 col-lg-7 col-xl-6 position-relative">
				<div class="card shadow-lg rounded-5 bk-card" *ngIf="isShortFormBuild">
					<div class="card-body p-sm-40">
						<!-- Step one Select Industry  -->
						<div class="step" [ngClass]="{'active-step': multiIndusStatus}" *ngIf="multiIndusStatus">
							<div class="mb-30" *ngIf="section?.industry">
								<h4 [id]="section?.industry?.title_id" *ngIf="section?.industry?.title" class="display-6 fw-bold bk-title"  [innerHTML]="section?.industry?.title | safeHtml"></h4>
								<h5 [id]="section?.industry?.sub_title_id" *ngIf="section?.industry?.sub_title" class="mb-0 fw-bold text-body bk-sub-title" [innerHTML]="section?.industry?.sub_title | safeHtml"></h5>
							</div>
							<!-- Industries -->
							<div class="mb-25">
								<label class="form-label" *ngIf="section?.industry && section?.industry?.industry" [id]="section?.industry?.industry_id" [innerHTML]="section?.industry?.industry | safeHtml"></label>
								<!-- Select industry -->
								<select *ngIf="industries && industries.length > 0" class="form-select bk-select" (change)=industryChange($event)>
									<ng-container *ngFor="let industry of industries;trackBy: secServ.trackByFnIndex">
										<option [value]="industry.industry_slug" [selected]="industry.id == selectedIndustryId">
											{{(industry?.custom_industry_name ? industry?.custom_industry_name : industry?.industry_name) |translate }}
										</option>
									</ng-container>
								</select>
							</div>
							<!-- Button -->
							<bk-navigate *ngIf="section?.continue_btn && section?.continue_btn?.content" [secSett]="section?.continue_btn" customClass="btn btn-primary py-15 border-transparent" [innerHTML]="section?.continue_btn?.content" [isBtnEvent]="true" (callback)="nextStep($event)"></bk-navigate>
						</div>
						<!-- Step two combination form data -->
						<div class="step d-flex d-md-block flex-column" [ngClass]="{'active-step': !multiIndusStatus}" *ngIf="(industryData && industryData?.combined_combination  && (industryData?.combined_combination).length > 0) || (industryData && industryData?.single_combination  && (industryData?.single_combination).length > 0)">
							<!-- Combine form questions -->
							<ng-container *ngIf="industryData && industryData?.combined_combination  && (industryData?.combined_combination).length > 0; else singleCombination">
								<!-- Question -->
								<div class="mb-30" *ngIf="industryData?.combined_combination[0]?.question">
									<h4 class="fw-bold bk-title" [id]="sectionIds?.title"> {{industryData?.combined_combination[0]?.question |translate}}	</h4>
								</div>
								<div class="mb-5 mb-md-30" *ngIf="industryData?.combined_combination[0]?.prequalifier_data && (industryData?.combined_combination[0]?.prequalifier_data).length > 0">
									<!-- Options -->
									<div class="form-check mb-15 bk-radio" *ngFor="let option of industryData?.combined_combination[0]?.prequalifier_data;trackBy: secServ.trackByFnIndex">
										<input id="{{option?.options}}" class="form-check-input" type="radio" name="form_id" value="{{option?.id}}" (change)="combineOptionChange(option)" [attr.checked]="combineDefaultChecked(option)" />
										<label for="{{option?.options}}" class="form-check-label bk-radio-option" [id]="sectionIds?.label">{{option?.options |translate}}</label>
									</div>
								</div>
								<!-- Selected combination -->
								<ng-container *ngIf="selectedCombination && selectedCombination?.prequalifier_data && (selectedCombination?.prequalifier_data).length > 0">
									<ng-container *ngTemplateOutlet="singleCombine; context:{title: selectedCombination?.question, options: selectedCombination?.prequalifier_data}"></ng-container>
								</ng-container>
							</ng-container>
							<!-- Single combination -->
							<ng-template #singleCombination>
								<ng-container *ngIf="industryData && industryData?.single_combination  && (industryData?.single_combination).length > 0 && industryData?.single_combination[0]?.prequalifier_data && (industryData?.single_combination[0]?.prequalifier_data).length > 0">
									<ng-container *ngTemplateOutlet="singleCombine; context:{title: industryData?.single_combination[0]?.question, options: industryData?.single_combination[0]?.prequalifier_data, isSingle:true}"></ng-container>
								</ng-container>
							</ng-template>
							<!-- Buttons -->
							<bk-navigate class="order-2 order-md-0" *ngIf="section?.back_btn && section?.back_btn?.content && multiIndusStatus" [secSett]="section?.back_btn" customClass="btn btn-light py-15 border-transparent" essentialClass="me-md-20 me-0 ms-md-auto" [innerHTML]="section?.back_btn?.content" [isBtnEvent]="true" (callback)="prevStep($event)"></bk-navigate>
							<bk-navigate *ngIf="section?.continue_btn && section?.continue_btn?.content" [secSett]="section?.continue_btn" customClass="btn btn-primary py-15 border-transparent" [innerHTML]="section?.continue_btn?.content" essentialClass="text-nowrap" [isBtnEvent]="true" (callback)="nextStep($event)"></bk-navigate>
						</div>
						<!-- Step three form params -->
						<div class="step" *ngIf="isFormChange" [ngClass]="{'active-step': (!multiIndusStatus && !((industryData && industryData.combined_combination  && (industryData.combined_combination).length > 0) || (industryData && industryData.single_combination  && (industryData.single_combination).length > 0)))}">
							<div *ngIf="industries && (industries).length > 0 && selectedFormId">
								<ng-container *ngFor="let industry of industries;trackBy: secServ.trackByFnIndex">
									<div *ngIf="industry.id == selectedIndustryId">
										<ng-container *ngTemplateOutlet="formTemp"></ng-container>
									</div>
								</ng-container>
							</div>
							<!-- Buttons -->
							<bk-navigate *ngIf="section?.back_btn && section?.back_btn?.content && multiIndusStatus" [secSett]="section?.back_btn" secId="{{section?.back_btn?.id+'_prequalifier'}}" customClass="btn btn-light py-15 border-transparent" [innerHTML]="section?.back_btn?.content" essentialClass="d-none mt-30" [isBtnEvent]="true" (callback)="prevStep($event)"></bk-navigate>
						</div>
					</div>
					<!-- Loader -->
					<bk-app-loader [loaderId]="stepperLoader"></bk-app-loader>
				</div>
			</div>
		</div>
		<!-- V4 -->
		<div class="position-relative" *ngSwitchCase="'bk_short_form_V4'">
			<ng-container *ngIf="isShortFormBuild">
				<!-- Step one Select Industry  -->
				<div class="text-center step" [ngClass]="{'active-step': multiIndusStatus}" *ngIf="multiIndusStatus">
					<div class="row justify-content-center">
						<div class="col-12 col-md-10">
							<!-- Title -->
							<h2 [id]="section?.title?.id" *ngIf="section?.title && section?.title?.content" class="display-1 bk-title mb-25"  [innerHTML]="section?.title?.content | safeHtml"></h2>
							<!-- Sub title -->
							<h3 [id]="section?.sub_title?.id" *ngIf="section?.sub_title && section?.sub_title?.content" class="display-6 bk-sub-title mb-45 fw-light" [innerHTML]="section?.sub_title?.content | safeHtml"></h3>
						</div>
						<div class="col-12">
							<div class="row justify-content-center">
								<div class="col-12 col-sm-8 col-md-6 col-lg-4">
									<div class="card shadow-sm mb-15 mb-md-30">
										<div class="card-body d-flex justify-content-between align-items-center py-sm-10 ps-sm-25 pe-sm-15 py-5 ps-10 pe-5">
											<label class="form-label mb-0 me-sm-15 me-10 flex-shrink-0" *ngIf="section?.industry && section?.industry?.industry" [id]="section?.industry?.industry_id" [innerHTML]="section?.industry?.industry | safeHtml"></label>
											<!-- Select industry -->
											<select class="form-select form-select-sm shadow-none border-0 pe-35 fs-15" (change)=industryChange($event)>
												<ng-container *ngIf="industries && industries.length > 0">
													<ng-container *ngFor="let industry of industries;trackBy: secServ.trackByFnIndex">
														<option [value]="industry.industry_slug" [selected]="industry.id == selectedIndustryId">
															{{(industry?.custom_industry_name ? industry?.custom_industry_name : industry?.industry_name) |translate }}
														</option>
													</ng-container>
												</ng-container>
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- Button -->
					<bk-navigate *ngIf="section?.continue_btn && section?.continue_btn?.content" [secSett]="section?.continue_btn" customClass="btn btn-primary py-15 border-transparent" [innerHTML]="section?.continue_btn?.content" [isBtnEvent]="true" (callback)="nextStep($event)"></bk-navigate>
				</div>
				<!-- Step two combination form data -->
				<div class="text-center step" [ngClass]="{'active-step': !multiIndusStatus}" *ngIf="(industryData && industryData?.combined_combination  && (industryData?.combined_combination).length > 0) || (industryData && industryData?.single_combination  && (industryData?.single_combination).length > 0)">
					<!-- Combine form questions -->
					<div class="row justify-content-center mb-30" *ngIf="industryData && industryData?.combined_combination  && (industryData?.combined_combination).length > 0; else singleCombination">
						<!-- Question -->
						<div class="col-12 col-md-10" *ngIf="industryData?.combined_combination[0]?.question">
							<div class="mb-30">
								<h3 class="display-5" [id]="sectionIds?.title">{{industryData?.combined_combination[0]?.question |translate}}</h3>
							</div>
						</div>
						<div class="col-12">
							<div class="d-flex justify-content-center">
								<div class="card shadow-sm">
									<div class="p-15">
										<!-- Options -->
										<ng-container *ngIf="industryData?.combined_combination[0]?.prequalifier_data && (industryData?.combined_combination[0]?.prequalifier_data).length > 0">
											<div class="form-check form-check-inline my-5" *ngFor="let option of industryData?.combined_combination[0]?.prequalifier_data;trackBy: secServ.trackByFnIndex">
												<input id="{{option?.options}}" class="form-check-input" type="radio" name="form_id" value="{{option?.id}}" (change)="combineOptionChange(option)" [attr.checked]="combineDefaultChecked(option)" />
												<label for="{{option?.options}}" class="form-check-label bk-radio-option" [id]="sectionIds?.label">{{option?.options |translate}}</label>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</div>
						<!-- Selected combination -->
						<ng-container *ngIf="selectedCombination && selectedCombination?.prequalifier_data && (selectedCombination?.prequalifier_data).length > 0">
							<ng-container *ngTemplateOutlet="singleCombineV4; context:{title: selectedCombination?.question, options: selectedCombination?.prequalifier_data}"></ng-container>
						</ng-container>
					</div>
					<!-- Single combination -->
					<ng-template #singleCombination>
						<div class="row justify-content-center mb-30" *ngIf="industryData && industryData?.single_combination  && (industryData?.single_combination).length > 0 && industryData?.single_combination[0]?.prequalifier_data && (industryData?.single_combination[0]?.prequalifier_data).length > 0">
							<ng-container *ngTemplateOutlet="singleCombineV4; context:{title: industryData?.single_combination[0]?.question, options: industryData?.single_combination[0]?.prequalifier_data}"></ng-container>
						</div>
					</ng-template>
					<!-- Buttons -->
					<bk-navigate class="order-2 order-md-0" *ngIf="section?.back_btn && section?.back_btn?.content && multiIndusStatus" [secSett]="section?.back_btn" customClass="btn btn-light py-15 border-transparent" essentialClass="me-md-20 me-0 ms-md-auto" [innerHTML]="section?.back_btn?.content" [isBtnEvent]="true" (callback)="prevStep($event)"></bk-navigate>
					<bk-navigate *ngIf="section?.continue_btn && section?.continue_btn?.content" [secSett]="section?.continue_btn" customClass="btn btn-primary py-15 border-transparent" essentialClass="text-nowrap" [innerHTML]="section?.continue_btn?.content" [isBtnEvent]="true" (callback)="nextStep($event)"></bk-navigate>
				</div>
				<!-- Step three form params -->
				<div class="step" *ngIf="isFormChange" [ngClass]="{'active-step': (!multiIndusStatus && !((industryData && industryData.combined_combination  && (industryData.combined_combination).length > 0) || (industryData && industryData.single_combination  && (industryData.single_combination).length > 0)))}">
					<div *ngIf="industries && (industries).length > 0 && selectedFormId">
						<ng-container *ngFor="let industry of industries;trackBy: secServ.trackByFnIndex">
							<div *ngIf="industry.id == selectedIndustryId">
								<ng-container *ngTemplateOutlet="formTemp"></ng-container>
							</div>
						</ng-container>
					</div>
					<!-- Buttons -->
					<bk-navigate *ngIf="section?.back_btn && section?.back_btn?.content && multiIndusStatus" [secSett]="section?.back_btn" secId="{{section?.back_btn?.id+'_prequalifier'}}" customClass="btn btn-light py-15 border-transparent" [innerHTML]="section?.back_btn?.content" [isBtnEvent]="true" essentialClass="d-none mt-30" (callback)="prevStep($event)"></bk-navigate>
				</div>
			</ng-container>
			<!-- Loader -->
			<bk-app-loader [loaderId]="stepperLoader"></bk-app-loader>
		</div>
	</div>
</div>
<!-- Subtitle template -->
<ng-template #titleTemp let-data="data">
	<h2 [id]="data?.title?.id" *ngIf="data?.title && data?.title?.content" class="display-1 bk-title mb-15 mb-md-25"  [innerHTML]="data?.title?.content | safeHtml"></h2>
	<h3 [id]="data?.sub_title?.id" *ngIf="data?.sub_title && data?.sub_title?.content" class="display-6 fw-light bk-sub-title mb-25 mb-md-45"  [innerHTML]="data?.sub_title?.content | safeHtml"></h3>
</ng-template>
<!-- Single combination/ selected combination template -->
<ng-template #singleCombine let-title="title" let-options="options" let-isSingle="isSingle">
	<div class="mb-30" *ngIf="reBuildFormSelection">
		<!-- Question -->
		<ng-container *ngIf="isSingle; else multi">
			<h4 *ngIf="title" [id]="sectionIds?.title" class="fw-bold mb-20 bk-title">{{title |translate}}</h4>
		</ng-container>
		<ng-template #multi>
			<h6 *ngIf="title" [id]="sectionIds?.title" class="fw-bold mb-20 bk-title">{{title |translate}}</h6>
		</ng-template>
		<!-- Options -->
		<ng-container *ngIf="options && options.length > 0">
			<div class="form-check mb-15 bk-radio" *ngFor="let option of options;trackBy: secServ.trackByFnIndex">
				<input id="{{option?.options}}" class="form-check-input bk-radio-option" type="radio" name="comb_form_id" value="{{option?.id}}" (change)="combineOptionChange(option, 'single')" [attr.checked]="(+selectedFormId == +option?.form_id) ? true : null" />
				<label class="form-check-label" for="{{option?.options}}" [id]="sectionIds?.label">{{option?.options |translate}}</label>
			</div>
		</ng-container>
	</div>
</ng-template>
<!-- Short form template -->
<ng-template #formTemp>
	<bk-short-form id="booking-form-wrap" [isShortFormOpen]="true" [secId]="secId" [pageSett]="pageSett" [industryId]="selectedIndustryId" [formId]="selectedFormId" [section]="section" [formSett]="formSett" [formMetaData]="formMetaData" [secSett]="secSett" [isPrevStepExist]="isPrevStepExist" [industrySlug]="industrySlug" [variationId]="secSett?.variation_id" [sectionIds]="sectionIds" [bkngPageSett]="bkngPageSett" [extraStatus]="extraStatus" [mlDesignId]="mlDesignId" [popupId]="popupId"></bk-short-form>
</ng-template>

<!-- Single combination/ selected combination template -->
<ng-template #singleCombineV4 let-title="title" let-options="options">
	<div class="col-12 col-md-10 mt-30">
		<div class="mb-30">
			<!-- Question -->
			<h3 *ngIf="title" class="display-5 bk-title" [id]="sectionIds?.title">{{title |translate}}</h3>
		</div>
	</div>
	<div class="col-12" *ngIf="reBuildFormSelection">
		<div class="card shadow-sm col-md-8 mx-auto">
			<div class="card-body p-15" *ngIf="options && options.length > 0">
				<!-- Options -->
				<div class="form-check form-check-inline my-5 bk-radio" *ngFor="let option of options;trackBy: secServ.trackByFnIndex">
					<input id="{{option?.options}}" class="form-check-input bk-radio-option" type="radio" name="comb_form_id" value="{{option?.id}}" (change)="combineOptionChange(option, 'single')" [attr.checked]="(+selectedFormId == +option?.form_id) ? true : null" />
					<label class="form-check-label" for="{{option?.options}}" [id]="sectionIds?.label">{{option?.options |translate}}</label>
				</div>
			</div>
		</div>
	</div>
</ng-template>
