import { Component, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../Services';
// Inheritance components
import { PricingParameterWithAreaComponent } from '../../../Global/BookingForm';

@Component({
	selector: 'bk-short-form-pricing-parameter-with-area',
	templateUrl: './ShortFrmPricingParameterWithArea.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ShortFrmPricingParameterWithAreaComponent extends PricingParameterWithAreaComponent {
	@Input() variationId: string = '';
	@Input() parentSection: any;
	@Input() sectionIds: any;

	@Output() serviceChange: EventEmitter<any> = new EventEmitter();
	@Output() hourlyServiceChange: EventEmitter<any> = new EventEmitter();
	@Output() frequencyChange: EventEmitter<any> = new EventEmitter();

	services: any;
}