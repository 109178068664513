<div [id]="secId" class="tjs-our-team pt-80 pb-60 text-center" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div class="container">
		<!-- Title -->
		<div class="row">
			<div class="col-md-12 col-lg-9 mx-auto">
				<div class="tjs-section__title mb-30" *ngIf="section?.title?.content">
					<h3 [id]="section?.title?.id" class="fw-bold display-5 bk-title" [innerHTML]="section?.title?.content | safeHtml"></h3>
				</div>
			</div>
		</div>
		<!-- List -->
		<div [id]="section?.list?.id" class="row bk-list justify-content-center" *ngIf="section?.list && section?.list?.items && (section?.list?.items).length>0">
			<ng-container *ngFor="let item of section?.list?.items let i=index;trackBy: secServ.trackByFnIndex">
				<div [id]="item?.id" class="col-12 col-sm-6 col-md-3 my-20 bk-item">
					<div>
						<!-- Media -->
						<div class="mx-auto" *ngIf="item?.media && (item?.media).length>0">
							<img loading="auto" [id]="item?.media_id" class="img-fluid bk-item-media" alt="bk_images" [src]="item?.media[0]" width="120" height="120">
						</div>
						<!-- Title -->
						<div class="px-15 pt-40" *ngIf="item?.title">
							<div class="mb-15">
								<h6 [id]="item?.title_id" class="fw-bold display-9 bk-item-title" [innerHTML]="item?.title | safeHtml"></h6>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
