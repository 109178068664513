<div class="tjs-popup tjs-popup--sm rounded-5 position-relative" [id]="secId">
	<!-- Header -->
	<bk-popup-header *ngIf="section?.title" [heading]="section?.title?.content" [headingId]="section?.title?.id" [isCenter]="false" [isClose]="false"></bk-popup-header>
	<form bkDebounceEvent [isSubmit]="true" [formGroup]="passwordForm" (debounceSubmit)="submitForm()" [id]="section?.form?.id">
		<div class="tjs-popup__content tjs-popup__body">
			<!-- Old password -->
			<div class="mb-25" [id]="section?.form?.current_password_id">
				<label class="form-label bk-form-label d-block" [id]="section?.form?.current_password?.label_id" *ngIf="section?.form?.current_password?.label" [innerHTML]="section?.form?.current_password?.label | safeHtml"></label>
				<input class="form-control" [ngClass]="{'is-invalid': f['old_password']?.touched && f['old_password']?.errors}" [attr.placeholder]="section?.form?.current_password?.placeholder" formControlName="old_password" type="password">
				<!-- Error template -->
				<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'old_password'}"></ng-container>
			</div>
			<!-- New password -->
			<div class="mb-25" [id]="section?.form?.new_password_id">
				<label class="form-label bk-form-label d-block" [id]="section?.form?.new_password?.label_id" *ngIf="section?.form?.new_password?.label" [innerHTML]="section?.form?.new_password?.label | safeHtml"></label>
				<input class="form-control" [ngClass]="{'is-invalid': f['new_password']?.touched && f['new_password']?.errors}" [attr.placeholder]="section?.form?.new_password?.placeholder" formControlName="new_password" type="password">
				<!-- Error template -->
				<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'new_password'}"></ng-container>
			</div>
			<!-- Confirm password -->
			<div [id]="section?.form?.confirm_password_id">
				<label class="form-label bk-form-label d-block" [id]="section?.form?.confirm_password?.label_id" *ngIf="section?.form?.confirm_password?.label" [innerHTML]="section?.form?.confirm_password?.label | safeHtml"></label>
				<input class="form-control" [ngClass]="{'is-invalid': f['confirm_password']?.touched && f['confirm_password']?.errors}" [attr.placeholder]="section?.form?.confirm_password?.placeholder" formControlName="confirm_password" type="password">
				<!-- Error template -->
				<ng-container *ngTemplateOutlet="errorTemp; context:{controlName:'confirm_password'}"></ng-container>
			</div>
		</div>
		<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse bk-footColor">
			<bk-navigate [secId]="section?.save_btn?.id" [designId]="section?.save_btn?.design_id" [innerHTML]="section?.save_btn?.content" [isSubmit]="true" essentialClass="w-100" customClass="btn btn-primary rounded-5 w-100" class="w-100 d-block mb-15 mb-sm-5"></bk-navigate>
			<bk-navigate [secId]="section?.cancel_btn?.id" [designId]="section?.cancel_btn?.design_id" [innerHTML]="section?.cancel_btn?.content" [isSubmit]="false" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" class="w-100 d-block mb-5" (callback)="dialogRef.close()"></bk-navigate>
		</div>
	</form>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
<!-- Form error template-->
<ng-template #errorTemp let-controlName="controlName">
	<ng-container *ngIf="f[controlName]?.touched && (f[controlName]?.errors?.required || f[controlName]?.errors?.minlength || f[controlName]?.errors?.matching)">
		<div class="tjs-error">
			<i class="tjsicon-attention"></i>
			<ng-container *ngIf="f[controlName]?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.minlength"> {{initServ?.appStr?.errorMsg?.passwordLength | translate}} </ng-container>
			<ng-container *ngIf="f[controlName]?.errors?.matching"> {{initServ?.appStr?.errorMsg?.passwordNotMatch | translate}} </ng-container>
		</div>
	</ng-container>
</ng-template>
