import { Component, OnInit, Input, ViewEncapsulation, Self, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, UtilServ, ApiServ, LoaderServ, InitServ } from '../../Services';

@Component({
	selector: 'bk-referral-listing',
	templateUrl: './ReferralListing.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class ReferralListingComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		referrals: null,
		summary: null,
		button: null
	}
	pagePerm: boolean = true;
	listLoaderId: string = 'referrals-list';
	summaryLoaderId: string = 'referrals-summary';
	limit: number = 50;
	page: number = 1;
	totalRecords: number = 0;
	totalInvites: number = 0;
	referrals: any;
	summary: any = {
		success: 0,
		creditsEarned: 0,
		balance: 0
	}
	route: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, private utilServ: UtilServ, private apiServ: ApiServ, private loader: LoaderServ, @Self() private destroy: NgOnDestroy, public initServ: InitServ, private cDRef: ChangeDetectorRef, private actRoute: ActivatedRoute, private router: Router) {
		// Current url without fragment
		this.route = this.utilServ.getUrlWithoutFragment(this.router.url);
		// URL Fragment, based this pagination page number, other wise default.
		let fragment = this.actRoute.snapshot.fragment;
		if(fragment){
			this.page = +fragment;
		}
		this.pagePerm = this.utilServ.appPermission('referrals'); // Page permission
		if(this.pagePerm){
			// Refresh the referral list
			this.utilServ.refListRefresh.pipe(takeUntil(this.destroy)).subscribe(() => {
				this.getReferrals(true);
			});
		}
	}

	ngOnInit(): void {
		// Build section data
		if(this.secId && this.rcServ.pageData && this.pagePerm){
			this.pageSett = this.rcServ.pageData.section_settings;
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
		if(this.pagePerm){
			this.initServ.userRefBal.pipe(takeUntil(this.destroy)).subscribe((value) => {
				this.summary.balance = 0;
				if(value){
					this.summary.balance = (value.balance) ? value.balance : 0;
					this.cDRef.detectChanges();
				}
			});
		}
	}
	/**
	 * User referrals
	 */
	private getReferrals(total: boolean=false): void {
		this.apiServ.setLoaderId(this.listLoaderId);
		this.loader.show(this.listLoaderId);
		this.apiServ.callApiWithPathQueryVars('GET', 'Referrals', [this.utilServ.userId()], {limit:this.limit, page:this.page, total:total}).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
	}
	/**
	 * On result callback method
	 * @param res API res
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res)){
			if (res.total_record) {
				this.totalRecords = res.total_record;
				this.totalInvites = res.total_record;
			}
			this.referrals = res.data;
			this.summary.success = 0;
			this.summary.creditsEarned = 0;
			if (this.referrals && this.referrals.length > 0) {
				for(let referral of this.referrals) {
					if (referral && referral.status == 'completed') {
						this.summary.success += 1;
						this.summary.creditsEarned += referral.credit_earned;
					}
				}
			}
		}
		this.loader.hide(this.listLoaderId);
		this.cDRef.detectChanges();
	}
	/**
	 * Get the page number, get the referrals based on page number
	 */
	public pageChange(event: any): void {
		this.page = event;
		this.router.navigateByUrl(this.route+'#'+this.page);
		this.getReferrals();
	}

	/**
	 * Badge class based on referral status
	 * @param status invited | registered | pending | completed | booked
	 * @returns
	 */
	public badgeClass(status: string): string {
		switch (status) {
			case "invited":
				return "primary"
			case "registered":
			case "pending":
				return "warning"
			case "completed":
				return "success"
			case "booked":
				return "info"
			default:
				return '';
		}
	}
}