import { Injectable } from '@angular/core';
import { CalCmnFuncServ } from './CalCmnFunc.service';

@Injectable({
	providedIn: 'root'
})
export class DiscountCalServ {

	constructor(private cmnFunc: CalCmnFuncServ) {
	}

	/**
	 * Function takes two parameters: "BKFrmValue" and "priceLocalVar".
	 * Inside the function, it checks if the "referral_discount" property in "BKFrmValue" is truthy.
	 * If it is, it compares the "displayServiceTotal" property in "priceLocalVar" with the "referral_discount" value.
	 * If the "displayServiceTotal" is greater than the "referral_discount", it subtracts the "referral_discount" from the "displayServiceTotal" and returns the result.
	 * If the "displayServiceTotal" is not greater than the "referral_discount" then return 0.
	 * If the "referral_discount" is falsy, it returns the "displayServiceTotal" as a number.
	 * Overall, the code calculates the referral amount based on the value of "displayServiceTotal" and "referral_discount".
	 * @param BKFrm : form control
	 * @param priceLocalVar : local variables
	 * @returns : number
	 */
	public calcReferralAmount(BKFrmValue: any, priceLocalVar: any): number{
		// Check if referral discount is available
		if(BKFrmValue.referral_discount){
			// Check if service total is greater than referral discount
			if(priceLocalVar.displayServiceTotal > BKFrmValue.referral_discount){
				return (priceLocalVar.displayServiceTotal - (+BKFrmValue.referral_discount));
			}else{
				// Set service total to 0
				return 0;
			}
		}else{
			return +priceLocalVar?.displayServiceTotal;
		}
	}

	/*****************Calculate frequency discount functions*************************************/

	/**
	 * Function takes two parameters, "selectedFrequency" and "amountBaseFrequencyDiscount".
	 * It checks if the discount type is "percentage". If it is, it calculates the discounted value based on the selected frequency and updates the "displayFrequencyDiscount" and "amountBaseFrequencyDiscount" variables accordingly.
	 * If the discount type is not "percentage", it simply calculates the "displayFrequencyDiscount" based on the selected frequency and updates the "amountBaseFrequencyDiscount" variable.
	 * Finally, the function returns an object with two properties: "displayFrequencyDiscount" and "amountBasedFrequencyDiscount", which contain the calculated values.
	 * @param amountBaseFrequencyDiscount : displayServiceTotal
	 * @param selectedFrequency : frequency
	 * @returns displayFrequencyDiscount, amount
	 */
	public getFrequencyDiscount(selectedFrequency: any, amountBaseFrequencyDiscount: any): any {
		let displayFrequencyDiscount = 0;
		// Check if discount type is percentage
		if(selectedFrequency.form_frequency_data.frequency_discount_type == 'percentage'){
			// Calculate service discounted value and update displayFrequencyDiscount and amountBaseFrequencyDiscount
			let serviceDiscountedValue = this.cmnFunc.roundToTwo((selectedFrequency.form_frequency_data.frequency_discount/100)*amountBaseFrequencyDiscount);
			displayFrequencyDiscount = +serviceDiscountedValue;
			amountBaseFrequencyDiscount = this.cmnFunc.roundToTwo((+amountBaseFrequencyDiscount) - (+serviceDiscountedValue));
		}else{
			// Calculate displayFrequencyDiscount and update amountBaseFrequencyDiscount
			displayFrequencyDiscount = this.cmnFunc.roundToTwo(+selectedFrequency.form_frequency_data.frequency_discount);
			amountBaseFrequencyDiscount = (displayFrequencyDiscount > (+amountBaseFrequencyDiscount)) ? 0 : this.cmnFunc.roundToTwo((+amountBaseFrequencyDiscount) - (+selectedFrequency.form_frequency_data.frequency_discount));
		}
		return {
			displayFrequencyDiscount : displayFrequencyDiscount,
			amountBasedFrequencyDiscount : amountBaseFrequencyDiscount
		};
	}
	/**
	 * Function takes three parameters: "BKFrmValue", "selectedFrequency", and "priceLocalVar".
	 * The code first checks if frequency discount is applicable with a coupon using the "isFrequencyDisWithCoupon" function.
	 * If frequency discount is applicable with a coupon, it calls the "getFrequencyDiscount" function to calculate the frequency discount for the selected frequency and updates the "displayFrequencyDiscount" and "displayServiceTotal" properties of the "priceLocalVar" object.
	 * It also calls the "getFrequencyDiscountProv" function to calculate the frequency discount for the provider and updates the "serviceTotalForProvider" property of the "priceLocalVar" object.
	 * Finally, it returns an object with the updated values of "displayFrequencyDiscount", "displayServiceTotal", and "serviceTotalForProvider".
	 * If frequency discount is not applicable with a coupon, it simply returns an object with the original values of "displayFrequencyDiscount", "displayServiceTotal", and "serviceTotalForProvider".
	 * @param BKFrmValue
	 * @param selectedFrequency
	 * @param priceLocalVar
	 * @return object
	 */
	public applyFreqDiscOnBooking(BKFrmValue: any, selectedFrequency: any, priceLocalVar: any): any{
		// To check if frequency discount applicable with coupon.
		if(this.cmnFunc.isFrequencyDisWithCoupon(BKFrmValue)){
			// Set frequency discount
			let freqDiscObj = this.getFrequencyDiscount(selectedFrequency, priceLocalVar.displayServiceTotal);
			priceLocalVar.displayFrequencyDiscount = freqDiscObj.displayFrequencyDiscount;
			priceLocalVar.displayServiceTotal = freqDiscObj.amountBasedFrequencyDiscount;
			// Set frequency discount for provider
			priceLocalVar.serviceTotalForProvider = this.getFrequencyDiscountProv(selectedFrequency, priceLocalVar.serviceTotalForProvider);
			return {
				displayFrequencyDiscount : priceLocalVar.displayFrequencyDiscount,
				displayServiceTotal : priceLocalVar.displayServiceTotal,
				serviceTotalForProvider : priceLocalVar.serviceTotalForProvider,
			}
		}else{
			return {
				displayFrequencyDiscount : 0,
				displayServiceTotal : priceLocalVar.displayServiceTotal,
				serviceTotalForProvider : priceLocalVar.serviceTotalForProvider,
			}
		}
	}
	/**
	 * Calculates the discounted service total for a provider based on the selected frequency and service total.
	 * If the frequency discount type is 'percentage', the discount is applied to the service total for a provider.
	 * Otherwise, if the frequency discount is greater than the service total for provider, the service total for provider is set to zero.
	 * Otherwise, the discount is subtracted from the service total for provider.
	 * The resulting discounted service total for provider is returned.
	 * @param selectedFrequency : frequency
	 * @param serviceTotalForProvider : total for provider
	 * @returns amount
	 */
	public getFrequencyDiscountProv(selectedFrequency: any, serviceTotalForProvider: any): any {
		if(selectedFrequency.form_frequency_data.frequency_discount_type == 'percentage'){
			// frequency discount apply on serviceTotalForProvider
			let serviceDiscountedValueForProvider = this.cmnFunc.roundToTwo((selectedFrequency.form_frequency_data.frequency_discount/100)*serviceTotalForProvider);
			serviceTotalForProvider = this.cmnFunc.roundToTwo((+serviceTotalForProvider) - (+serviceDiscountedValueForProvider));
		}else{
			// if frequency discount greater than serviceTotalForProvider
			if((+selectedFrequency.form_frequency_data.frequency_discount) > (+serviceTotalForProvider)){
				serviceTotalForProvider = 0;
			}else{
				serviceTotalForProvider = this.cmnFunc.roundToTwo((+serviceTotalForProvider) - (+selectedFrequency.form_frequency_data.frequency_discount));
			}
		}
		return serviceTotalForProvider;
	}

	/*****************Calculate spot discount functions*************************************/

	/**
	 * Function called getSpotDiscRec which calculates the spot discount for a provider based on the selected frequency.
	 * The function takes in multiple parameters: BKFrmValue, excludeDayDiscountValue, amountWithFreqDiscFirstRecApt, and amountWithFreqDiscNthApt.
	 * Inside the function, two variables firstRecAptspotDiscount and afterNthspotDiscount are initialized with a value of 0.
	 * Then checks if the BKFrmValue.day_discount.discount exists and if BKFrmValue.arrival_time is not null, and if excludeDayDiscountValue is false.
	 * If all the conditions are met, the code then checks the BKFrmValue.day_discount.discount_type. If it is 'percentage', it calculates the spot discount based on the percentage value and the provided service amounts (amountWithFreqDiscFirstRecApt and amountWithFreqDiscNthApt).
	 * If the discount_type is not 'percentage', the code assigns the discount value directly to both firstRecAptspotDiscount and afterNthspotDiscount.
	 * Finally, the function returns an object containing the firstRecAptspotDiscount and afterNthspotDiscount values.
	 * @param BKFrmValue
	 * @param excludeDayDiscountValue
	 * @param amountWithFreqDiscFirstRecApt
	 * @param amountWithFreqDiscNthApt
	 * @returns
	 */
	public getSpotDiscRec(BKFrmValue: any, excludeDayDiscountValue: any, amountWithFreqDiscFirstRecApt: number, amountWithFreqDiscNthApt: number): any {
		let firstRecAptspotDiscount = 0;
		let afterNthspotDiscount = 0;
		// if discount exist
		if(BKFrmValue.day_discount.discount && BKFrmValue.arrival_time && !excludeDayDiscountValue){
			// if type percentage
			if(BKFrmValue.day_discount.discount_type == 'percentage'){
				let spotAmount = +(BKFrmValue.day_discount.discount);
				firstRecAptspotDiscount = this.cmnFunc.roundToTwo((spotAmount/100)*amountWithFreqDiscFirstRecApt);
				afterNthspotDiscount = this.cmnFunc.roundToTwo((spotAmount/100)*amountWithFreqDiscNthApt);
			}else{
				// if type fixed
				firstRecAptspotDiscount = BKFrmValue.day_discount.discount;
				afterNthspotDiscount = BKFrmValue.day_discount.discount;
			}
		}
		return {
			firstRecAptspotDiscount,
			afterNthspotDiscount,
		};
	}
	/**
	 * Calculates the spot discount based on the BKFrmValue object and other input values.
	 * If the BKFrmValue object has a day_discount property and an arrival_time property, and excludeDayDiscountValue is not set to true, the code determines the type of discount (percentage or fixed amount) and performs the necessary calculations.
	 * The displaySpotDiscount variable stores the calculated spot discount amount.
	 * If the discount type is percentage, it calculates the spot discount based on the percentage value in BKFrmValue.day_discount.discount and applies it to the displayServiceTotal and serviceTotalForProvider variables.
	 * If the discount type is a fixed amount, it checks if the spot discount is greater than serviceTotalForProvider. If it is, serviceTotalForProvider is set to 0. Otherwise, it subtracts the spot discount amount from serviceTotalForProvider.
	 * Lastly, it checks if displaySpotDiscount is greater than displayServiceTotal. If it is, displayServiceTotal is set to 0. Otherwise, it subtracts displaySpotDiscount from displayServiceTotal.
	 * The method returns an object with the following properties: displaySpotDiscount, displayServiceTotal, and serviceTotalForProvider
	 * @param BKFrmValue : form control
	 * @param displayServiceTotal : service total value
	 * @param serviceTotalForProvider : service total for provider
	 * @param excludeDayDiscountValue : check if excldue day discount
	 * @returns object
	 */
	public getSpotDiscount(BKFrmValue: any, displayServiceTotal: number, serviceTotalForProvider: number, excludeDayDiscountValue: boolean): any{
		// Code for spot discount.
		let displaySpotDiscount = 0;
		if(BKFrmValue.day_discount.discount && BKFrmValue.arrival_time && !excludeDayDiscountValue){
			// if discount type is percentage
			if(BKFrmValue.day_discount.discount_type == 'percentage'){
				let spotAmount = +(BKFrmValue.day_discount?.discount);
				displaySpotDiscount = this.cmnFunc.roundToTwo((spotAmount/100)*displayServiceTotal);
				// spot discount apply on serviceTotalForProvider
				let spotDiscountedValueForProvider = this.cmnFunc.roundToTwo((spotAmount/100)*serviceTotalForProvider);
				serviceTotalForProvider = this.cmnFunc.roundToTwo((+serviceTotalForProvider) - (+spotDiscountedValueForProvider));
			}else{
				displaySpotDiscount = BKFrmValue.day_discount?.discount;
				// if spot discount greater than serviceTotalForProvider
				serviceTotalForProvider = ((+displaySpotDiscount) > (+serviceTotalForProvider)) ? 0 : this.cmnFunc.roundToTwo((+serviceTotalForProvider) - (+displaySpotDiscount));
			}
			displayServiceTotal = (displaySpotDiscount > displayServiceTotal) ? 0 : this.cmnFunc.roundToTwo((+displayServiceTotal) - (+displaySpotDiscount));
		}
		return{
			displaySpotDiscount,
			displayServiceTotal,
			serviceTotalForProvider,
		};
	}
	/**
	 * Function creates two arrays, dateDiscountsArray and dayDiscountsArray.
	 * It iterates through the dayDiscounts array and checks if the discount_for property is equal to 'date'.
	 * If true, it adds the dayDiscount object to the dateDiscountsArray using the date property as the index.
	 * If not, it adds the dayDiscount object to the dayDiscountsArray using the day property as the index.
	 * Finally, it returns an object containing both arrays.
	 * @param dayDiscounts
	 * @returns
	 */
	public makeDayDiscountArray(dayDiscounts: any): any {
		let dateDiscountsArray = [];
		let dayDiscountsArray = [];
		if(dayDiscounts && (dayDiscounts)?.length > 0){
			for(let dayDiscount of dayDiscounts){
				if(dayDiscount.discount_for == 'date'){
					dateDiscountsArray[dayDiscount.date] = dayDiscount;
				}else{
					dayDiscountsArray[dayDiscount.day] = dayDiscount;
				}
			}
		}
		return {
			dateDiscountsArray,
			dayDiscountsArray,
		};
	}

	/****************************Calculate coupon discount functions*************************************/

	/**
	 * Calculates the discount to be applied based on a coupon for a service provider.
	 * It checks if the provider_discount property of the BKFrmValue.coupon object is present.
	 * If it is present, it assigns its value to the couponProviderDiscountValue variable. Otherwise, it assigns 0 to it.
	 * Next, it checks if the provider_discount_type property of the BKFrmValue.coupon object is equal to 'percentage'.
	 * If it is, it calculates the discount value by multiplying the couponProviderDiscountValue by the percentage of the serviceTotalForProvider and rounding it to two decimal places.
	 * It assigns this value to the providerDiscountValue variable and assigns it to couponDiscount.
	 * Then, it subtracts the providerDiscountValue from the serviceTotalForProvider and assigns it back to serviceTotalForProvider.
	 * If the provider_discount_type is not equal to 'percentage', it assumes the discount type is 'fixed'.
	 * In this case, it assigns the couponProviderDiscountValue directly to couponDiscount.
	 * Then, it checks if the serviceTotalForProvider is less than or equal to couponProviderDiscountValue. If it is, it sets serviceTotalForProvider to 0.
	 * Otherwise, it subtracts couponProviderDiscountValue from serviceTotalForProvider.
	 * Finally, it returns an object with couponDiscount and serviceTotalForProvider as properties.
	 * @param BKFrmValue : form control
	 * @param serviceTotalForProvider: service total for provider
	 * @returns object
	 */
	public getCoupnDiscProvider(BKFrmValue: any, serviceTotalForProvider: number): any {
		let couponDiscount = 0;
		let couponProviderDiscountValue = (BKFrmValue.coupon.provider_discount) ? (BKFrmValue.coupon.provider_discount) : 0;
		// if provider discount type is percentage
		if(BKFrmValue.coupon.provider_discount_type == 'percentage'){
			let providerDiscountValue = this.cmnFunc.roundToTwo(((+couponProviderDiscountValue)/100)*(+serviceTotalForProvider));
			couponDiscount = this.cmnFunc.roundToTwo(+providerDiscountValue);
			serviceTotalForProvider = this.cmnFunc.roundToTwo((+serviceTotalForProvider) - (+providerDiscountValue));
		}else{
			// if provider discount type is fixed
			couponDiscount = this.cmnFunc.roundToTwo((+couponProviderDiscountValue));
			serviceTotalForProvider = (serviceTotalForProvider <= couponProviderDiscountValue) ? 0 : this.cmnFunc.roundToTwo((+serviceTotalForProvider) - (+couponProviderDiscountValue));
		}
		return{
			couponDiscount,
			serviceTotalForProvider,
		};
	}
	/**
	 * Function calculates the coupon discount based on the values stored in BKFrmValue.
	 * If the coupon discount type is set to "percentage", it calculates the discount using the formula (couponDiscountValue/100) * displayServiceTotal and rounds the result to two decimal places.
	 * If the coupon discount type is not a percentage, it simply assigns the value of couponDiscountValue to displayCouponDiscount.
	 * After calculating the discount, the code checks if displayDiscountedAmount is less than or equal to displayCouponDiscount.
	 * If it is, it sets displayDiscountedAmount to 0. Otherwise, it subtracts displayCouponDiscount from displayDiscountedAmount and rounds the result to two decimal places using the roundToTwo method.
	 * Finally, the function returns an object with the properties displayCouponDiscount and displayDiscountedAmount, each with their respective calculated values.
	 * @param BKFrmValue : form control
	 * @param displayServiceTotal : service total
	 * @param displayDiscountedAmount : discounted amount
	 * @returns object
	 */
	public getCouponDiscount(BKFrmValue: any, displayServiceTotal: number, displayDiscountedAmount: number): any {
		let displayCouponDiscount = 0;
		let couponDiscountValue = (BKFrmValue.coupon.discount) ? (BKFrmValue.coupon.discount) : 0;
		// if coupon discount type is percentage
		if(BKFrmValue.coupon.discount_type == 'percentage'){
			displayCouponDiscount = this.cmnFunc.roundToTwo(((+couponDiscountValue)/100)*(+displayServiceTotal));
		}else{
			// if provider discount type is fixed
			displayCouponDiscount = this.cmnFunc.roundToTwo(couponDiscountValue);
		}
		displayDiscountedAmount = (displayDiscountedAmount <= displayCouponDiscount) ? 0 : this.cmnFunc.roundToTwo((+displayDiscountedAmount) - (+displayCouponDiscount));
		return {
			displayCouponDiscount,
			displayDiscountedAmount,
		};
	}

	/**
	 * Function first checks if the giftCardTotalAmount exists in priceLocalVar.
	 * If it does not exist, it sets the giftCardDiscountValue to 0. Otherwise, it sets it to the value of giftCardTotalAmount.
	 * Then, it calls the getGiftCardDisc function with the giftCardDiscountValue and priceLocalVar.displayFinalAmount as parameters, and assigns the result to priceLocalVar.displayGiftCardAmount.
	 * Next, it checks if referral_amount exists in BKFrmValue. If it does not exist, it sets the referralDiscountValue to 0.
	 * Otherwise, it sets it to the value of referral_amount. It then calls the roundToTwo function with referralDiscountValue as a parameter, and assigns the result to priceLocalVar.displayReferralDiscount.
	 * After that, it subtracts the displayGiftCardAmount from the displayFinalAmount and assigns the result to displayFinalAmount.
	 * It then subtracts the displayReferralDiscount from the displayFinalAmount and assigns the result to displayFinalAmount.
	 * Finally, it returns an object with the following properties: displayGiftCardAmount, displayReferralDiscount, and displayFinalAmount.
	 * @param BKFrmValue : form control
	 * @param priceLocalVar : local variables
	 * @return object
	 */
	public calcGiftCardReferral(BKFrmValue: any, priceLocalVar:any): any{
		// Calculate giftcard amount
		// let giftCardGroup = <FormGroup>BKFrm?.controls['gift_card'];
		let giftCardDiscountValue = priceLocalVar.giftCardTotalAmount ? priceLocalVar.giftCardTotalAmount : 0;
		priceLocalVar.displayGiftCardAmount = this.getGiftCardDisc(giftCardDiscountValue, priceLocalVar.displayFinalAmount);
		// giftCardGroup?.controls['discount'].setValue(priceLocalVar?.displayGiftCardAmount);
		// Calculate referral discount
		let referralDiscountValue = BKFrmValue.referral_amount ? BKFrmValue.referral_amount : 0;
		priceLocalVar.displayReferralDiscount = this.cmnFunc.roundToTwo(+referralDiscountValue);
		// final amount
		priceLocalVar.displayFinalAmount = this.cmnFunc.roundToTwo((+priceLocalVar.displayFinalAmount) - (+priceLocalVar.displayGiftCardAmount));
		priceLocalVar.displayFinalAmount = this.cmnFunc.roundToTwo((+priceLocalVar.displayFinalAmount) - (+priceLocalVar.displayReferralDiscount));
		return {
			displayGiftCardAmount : priceLocalVar.displayGiftCardAmount,
			displayReferralDiscount : priceLocalVar.displayReferralDiscount,
			displayFinalAmount : priceLocalVar.displayFinalAmount
		}
	}
	/**
	 * Calculates the gift card amount to be applied to the final amount.
	 * If the gift card discount value is greater than zero, it compares it with the final amount.
	 * If the gift card value is greater than the final amount, it assigns the final amount as the gift card amount.
	 * Otherwise, it assigns the gift card value as the gift card amount. The result is then returned.
	 * @param giftCardDiscountValue : gift card value
	 * @param displayFinalAmount : final amount
	 * @returns displayGiftCardAmount
	 */
	public getGiftCardDisc(giftCardDiscountValue: number, displayFinalAmount: number): number {
		let displayGiftCardAmount = 0;
		if(giftCardDiscountValue > 0){
			displayGiftCardAmount = ((+giftCardDiscountValue) > (+displayFinalAmount)) ? this.cmnFunc.roundToTwo(+displayFinalAmount) : this.cmnFunc.roundToTwo(+giftCardDiscountValue);
		}
		return displayGiftCardAmount;
	}
}
