<div class="tjs-card tjs-accordion tjs-accordion__round-circle card border tjs-summary tjs-rounded--lg rounded-5 mb-30">
	<div class="tjs-summary__header p-20" role="tab" id="payments">
		<div class="p-0 accordion-button d-flex align-items-center" data-bs-toggle="collapse" aria-expanded="true" data-bs-target="#payment-adjustment">
			<h5 class="accordion-header fw-bold mb-0">{{initServ?.appStr?.text?.paymentAdjustment | translate}}</h5>
			<bk-tooltip content="{{initServ?.appStr?.tTip?.paymentAdjustment | translate}}"></bk-tooltip>
		</div>
	</div>
	<div id="payment-adjustment" class="accordion-collapse collapse show" role="tabpanel" aria-labelledby="payments">
		<div class="tjs-summary__content px-20 fs-14">
			<ul class="tjs-list tjs-summary__list list-unstyled py-20 border-top d-flex flex-column">
				<li>
					<div class="tjs-summary__label">{{initServ?.appStr?.text?.bookingInitialChargedAmount | translate}}</div>
					<div class="tjs-summary__detail"><bk-amount-display *ngIf="!prefilledData?.is_voided && prefilledData?.total; else zeroAmount" [amount]="initalChargedAmount"></bk-amount-display></div>
				</li>
				<!-- Payment logs -->
				<ng-container *ngIf="prefilledData?.payment_log && (prefilledData?.payment_log).length > 0">
					<ng-container *ngFor="let log of prefilledData?.payment_log">
						<ng-container [ngSwitch]="log?.payment_type">
							<!-- Booking -->
							<ng-container *ngSwitchCase="'booking'">
								<ng-container *ngIf="log?.is_refunded">
									<ng-container *ngTemplateOutlet="refundAmount; context:{log: log, type: 'booking'}"></ng-container>
								</ng-container>
							</ng-container>
							<!-- Booking remaining -->
							<ng-container *ngSwitchCase="'booking_remaining'">
								<!-- commented for now, uncommented based on feedback, discuss with vivek sir -->
								<!-- <ng-container *ngTemplateOutlet="chargedAmount; context:{log: log,label: initServ?.appStr?.text?.bookingRemainingAmount, hideTooltip:(log?.status == 'pending')}"></ng-container> -->
								<ng-container *ngIf="log?.is_refunded">
									<ng-container *ngTemplateOutlet="refundAmount; context:{log: log,type: 'extra'}"></ng-container>
								</ng-container>
							</ng-container>
							<!-- Refunded-->
							<ng-container *ngSwitchCase="'refund'">
								<ng-container *ngTemplateOutlet="chargedAmount; context:{log: log,label: initServ?.appStr?.text?.refunded, hideTooltip:(log?.status == 'pending')}"></ng-container>
							</ng-container>
							<!-- Extra refunded-->
							<ng-container *ngSwitchCase="'extra_remaining'">
								<!-- commented for now, uncommented based on feedback, discuss with vivek sir -->
								<!-- <ng-container *ngTemplateOutlet="chargedAmount; context:{log: log,label: initServ?.appStr?.text?.extraRemainingAmount, hideTooltip:(log?.status == 'pending')}"></ng-container> -->
								<ng-container *ngIf="log?.is_refunded">
									<ng-container *ngTemplateOutlet="refundAmount; context:{log: log,type: 'extra'}"></ng-container>
								</ng-container>
							</ng-container>
							<!-- Tip and tip refunded-->
							<ng-container *ngSwitchCase="'tip'">
								<li>
									<div class="tjs-summary__label">{{initServ?.appStr?.text?.tip | translate}}</div>
									<div class="tjs-summary__detail">
										<bk-amount-display [amount]="log?.charged_amount" [isZero]="true"></bk-amount-display>
									</div>
								</li>
								<ng-container *ngIf="log?.is_refunded">
									<ng-container *ngTemplateOutlet="refundAmount; context:{log: log,type: 'extra'}"></ng-container>
								</ng-container>
							</ng-container>
							<!-- Extra -->
							<ng-container *ngSwitchCase="'extra'">
								<ng-container *ngTemplateOutlet="chargedAmount; context:{log: log,label: initServ?.appStr?.text?.additionalCharge}"></ng-container>
								<ng-container *ngIf="log?.is_refunded">
									<ng-container *ngTemplateOutlet="refundAmount; context:{log: log,type: 'extra'}"></ng-container>
								</ng-container>
							</ng-container>
							<!-- cancelling_after_1st_appointment -->
							<ng-container *ngSwitchCase="'cancelling_after_1st_appointment'">
								<ng-container *ngTemplateOutlet="chargedAmount; context:{log: log,label: initServ?.appStr?.text?.cancellingAfter1stAppointment}"></ng-container>
								<ng-container *ngIf="log?.is_refunded">
									<ng-container *ngTemplateOutlet="refundAmount; context:{log: log,type: 'extra'}"></ng-container>
								</ng-container>
							</ng-container>
						</ng-container>
					</ng-container>
				</ng-container>
				<!-- Final amount -->
				<li>
					<div class="tjs-summary__label">{{initServ?.appStr?.text?.totalAfterAdjustment | translate}}</div>
					<div class="tjs-summary__detail">
						<bk-amount-display [amount]="finalAmount" [isZero]="true"></bk-amount-display>
					</div>
				</li>
			</ul>
		</div>
	</div>
</div>
<!-- Empty amount -->
<ng-template #zeroAmount>
	<bk-amount-display [isZero]="true"></bk-amount-display>
</ng-template>
<!-- Refund amount template-->
<ng-template #refundAmount let-log="log" let-type="type">
	<li>
		<div class="tjs-summary__label">{{initServ?.appStr?.text?.refunded | translate}}</div>
		<div class="tjs-summary__detail tjs-summary__more">
			<ng-container *ngIf="log?.refund_type == 'full'; else refundedAmount">
				<bk-amount-display [amount]="log?.charged_amount" [isZero]="true" class="tjs-pointer"></bk-amount-display>
			</ng-container>
			<ng-template #refundedAmount>
				<bk-amount-display [amount]="log?.refunded_amount" [isZero]="true" class="tjs-pointer"></bk-amount-display>
			</ng-template>
			<!-- Tooltip -->
			<div class="tjs-summary__box p-10 rounded fw-normal">
				<strong *ngIf="log?.refund_type == 'full'; else partialRefund" class="text-capitalize">{{initServ?.appStr?.text?.fullRefund | translate}}</strong>
				<!-- Partial refund -->
				<ng-template #partialRefund>
					<strong *ngIf="log?.payment_type != 'refund'" class="text-capitalize d-block mb-10">{{initServ?.appStr?.text?.partialRefund | translate}} :</strong>
					<p class="d-flex">
						<span class="tjs-summary__more-label">{{initServ?.appStr?.text?.amount | translate}}</span>
						<span class="tjs-summary__more-detail">
							<bk-amount-display [amount]="log?.refunded_amount - (log?.tax_amount - log?.tax_after_refund)" [isZero]="true"></bk-amount-display>
						</span>
					</p>
					<p class="d-flex">
						<span class="tjs-summary__more-label">
							<ng-container *ngIf="admnStngs && admnStngs?.merchant_settings && admnStngs?.merchant_settings?.store && admnStngs?.merchant_settings?.store?.custom_tax_label; else defaultTaxLabel">{{admnStngs?.merchant_settings?.store?.custom_tax_label | translate}}</ng-container>
							<ng-template #defaultTaxLabel>{{initServ?.appStr?.text?.tax | translate}}</ng-template>
						</span>
						<span class="tjs-summary__more-detail">
							<bk-amount-display [amount]="log?.tax_amount - log?.tax_after_refund" [isZero]="true"></bk-amount-display>
						</span>
					</p>
					<p class="d-flex tjs-summary__more-footer">
						<span class="tjs-summary__more-label fw-bold">{{initServ?.appStr?.text?.total | translate}}</span>
						<span class="tjs-summary__more-detail fw-bold">
							<bk-amount-display *ngIf="type == 'booking' else extra" [amount]="getRefundAmount(log) + getTaxAmount(log)" [isZero]="true"></bk-amount-display>
							<ng-template #extra><bk-amount-display [amount]="log?.refunded_amount" [isZero]="true"></bk-amount-display></ng-template>
						</span>
					</p>
				</ng-template>
			</div>
		</div>
	</li>
</ng-template>
<!-- Charged amount template-->
<ng-template #chargedAmount let-log="log" let-label="label" let-hideTooltip="hideTooltip">
	<li>
		<div class="tjs-summary__label">
			{{label | translate}}
			<bk-tooltip *ngIf="log?.payment_type == 'extra_remaining'" customClass="tjs-tooltip-icon--sm bk-tooltip" [content]="initServ?.appStr?.tTip?.extraRemainingTtip"></bk-tooltip>
			<bk-tooltip *ngIf="log?.payment_type == 'booking_remaining'"  customClass="tjs-tooltip-icon--sm bk-tooltip" [content]="initServ?.appStr?.tTip?.bookingRemainingTtip"></bk-tooltip>
		</div>

		<div class="tjs-summary__detail tjs-summary__more">
			<bk-amount-display [amount]="log?.charged_amount" [isZero]="true" class="tjs-pointer"></bk-amount-display>
			<!-- Tooltip -->
			<div class="tjs-summary__box p-10 rounded fw-normal" *ngIf="!hideTooltip">
				<p class="d-flex">
					<span class="tjs-summary__more-label">{{initServ?.appStr?.text?.amount | translate}}</span>
					<span class="tjs-summary__more-detail">
						<bk-amount-display [amount]="log?.charged_amount - log?.tax_amount" [isZero]="true"></bk-amount-display>
					</span>
				</p>
				<p class="d-flex">
					<span class="tjs-summary__more-label">
						<span *ngIf="admnStngs && admnStngs?.merchant_settings && admnStngs?.merchant_settings?.store && admnStngs?.merchant_settings?.store?.custom_tax_label; else defaultTaxLabel">{{admnStngs?.merchant_settings?.store?.custom_tax_label | translate}}</span>
						<ng-template #defaultTaxLabel>{{initServ?.appStr?.text?.tax | translate}}</ng-template>
					</span>
					<span class="tjs-summary__more-detail">
						<bk-amount-display [amount]="log?.tax_amount" [isZero]="true"></bk-amount-display>
					</span>
				</p>
				<p class="d-flex tjs-summary__more-footer">
					<span class="tjs-summary__more-label fw-bold">{{initServ?.appStr?.text?.total | translate}}</span>
					<span class="tjs-summary__more-detail fw-bold">
						<bk-amount-display [amount]="log?.charged_amount" [isZero]="true"></bk-amount-display>
					</span>
				</p>
			</div>
		</div>
	</li>
</ng-template>