import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoaderServ {
	constructor() { }
	hideLoaderOnAppLoad: boolean = true;
	loadingData = new Subject<any>();
	loaderIds: any = {};

	/**
	 * Show loader
	 * @param id: loader id
	 */
	public show(id: string = 'main', dialogRef:any = null): any{
		if(id){
			let obj = {
				id: id,
				status: true
			}
			if(id == 'main'){
				document.body.classList.add("tjs-loader--main");
			}
			// to disable the popup backdrop so that popup close can be prevented whenever api is hitted and loader is visible,
			// user cannot click outside the popup
			if(dialogRef){
				dialogRef.disableClose = true;
			}
			this.loadingData.next(obj);
		}
	}
	/**
	 * Hide loader
	 * @param id: loader id
	 */
	public hide(id: string = 'main', dialogRef:any = null): any{
		if(id){
			let obj = {
				id: id,
				status: false
			}
			setTimeout(()=>{
				if(id == 'main'){
					document.body.classList.remove("tjs-loader--main");
				}
				// popup backdrop is enabled when loader is hide, so that popup can be closed when backdrop is clicked.
				if(dialogRef){
					dialogRef.disableClose = false;
				}
				this.loadingData.next(obj);
			},500);
		}
	}

	/**
	 * Stop all loader, if http request failed
	 */
	public stopAll(): void {
		let allLoaders: any = this.loaderIds;
		if(allLoaders && (Object.keys(allLoaders)).length > 0){
			let loaders: any = Object.keys(allLoaders);
			if(loaders && loaders.length > 0){
				for(let loaderId of loaders){
					if(allLoaders[loaderId]){
						this.hide(loaderId);
					}
				}
			}
		}
	}
}
