import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import{ Subscription, interval } from 'rxjs';
// External library
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
// Services
import { NgOnDestroy, CookieServ, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-count-down',
	template: `<div class="d-flex align-items-center">
			<!-- Days -->
			<div *ngIf="daysToDday && daysToDday != '00'" class="tjs-coupon--clock_list mx-10 mx-lg-10 mx-xl-15 text-center" [ngClass]="{'d-flex': (clockLayout == 'left' || clockLayout == 'right' ||  clockLayout == 'top'), 'align-items-center' : (clockLayout == 'left' || clockLayout == 'right'), 'flex-row-reverse units-at-left' : clockLayout == 'left', 'flex-column-reverse': clockLayout == 'top', 'flex-column': clockLayout == 'bottom'}">
				<h2 class="mb-0 mx-5 bk-discount-clock-timer text-white fw-bold" [ngStyle]="{'color': timerClr && timerClr, 'font-size': timerFs && timerFs}">{{daysToDday}}</h2>
				<span class="tjs-couponbar__clock-unit bk-discount-clock-unit text-white" [ngStyle]="{'color': unitClr && unitClr, 'font-size': unitFs && unitFs}">{{'Days' | translate}}</span>
			</div>
			<!-- Hours -->
			<div *ngIf="(hoursToDday && hoursToDday != '00') || (daysToDday && daysToDday != '00')" class="tjs-coupon--clock_list mx-10 mx-lg-10 mx-xl-15 text-center" [ngClass]="{'d-flex': (clockLayout == 'left' || clockLayout == 'right' ||  clockLayout == 'top'), 'align-items-center' : (clockLayout == 'left' || clockLayout == 'right'), 'flex-row-reverse' : clockLayout == 'left', 'flex-column-reverse': clockLayout == 'top', 'flex-column': clockLayout == 'bottom'}">
				<h2 class="mb-0 mx-5 bk-discount-clock-timer text-white fw-bold" [ngStyle]="{'color': timerClr && timerClr, 'font-size': timerFs && timerFs}">{{hoursToDday}}</h2>
				<span class="tjs-couponbar__clock-unit bk-discount-clock-unit text-white" [ngStyle]="{'color': unitClr && unitClr, 'font-size': unitFs && unitFs}">{{((clockUnitType == 'short') ? 'Hrs' : 'Hours') | translate}}</span>
			</div>
			<!-- Minutes -->
			<div *ngIf="(minutesToDday && minutesToDday != '00') || (hoursToDday && hoursToDday != '00') || (daysToDday && daysToDday != '00')" class="tjs-coupon--clock_list mx-10 mx-lg-10 mx-xl-15 text-center" [ngClass]="{'d-flex': (clockLayout == 'left' || clockLayout == 'right' ||  clockLayout == 'top'), 'align-items-center' : (clockLayout == 'left' || clockLayout == 'right'), 'flex-row-reverse' : clockLayout == 'left', 'flex-column-reverse': clockLayout == 'top', 'flex-column': clockLayout == 'bottom'}">
				<h2 class="mb-0 mx-5 bk-discount-clock-timer text-white fw-bold" [ngStyle]="{'color': timerClr && timerClr, 'font-size': timerFs && timerFs}">{{minutesToDday}}</h2>
				<span class="tjs-couponbar__clock-unit bk-discount-clock-unit text-white" [ngStyle]="{'color': unitClr && unitClr, 'font-size': unitFs && unitFs}">{{((clockUnitType == 'short') ? 'Min' : 'Minutes') | translate}}</span>
			</div>
			<!-- Seconds -->
			<div *ngIf="secondsToDday" class="tjs-coupon--clock_list mx-10 mx-lg-10 mx-xl-15 text-center" [ngClass]="{'d-flex': (clockLayout == 'left' || clockLayout == 'right' ||  clockLayout == 'top'), 'align-items-center' : (clockLayout == 'left' || clockLayout == 'right'), 'flex-row-reverse' : clockLayout == 'left', 'flex-column-reverse': clockLayout == 'top', 'flex-column': clockLayout == 'bottom'}">
				<h2 class="mb-0 mx-5 bk-discount-clock-timer text-white fw-bold" [ngStyle]="{'color': timerClr && timerClr, 'font-size': timerFs && timerFs}">{{secondsToDday}}</h2>
				<span class="tjs-couponbar__clock-unit bk-discount-clock-unit text-white" [ngStyle]="{'color': unitClr && unitClr, 'font-size': unitFs && unitFs}">{{((clockUnitType == 'short') ? 'Sec' : 'Seconds') | translate}}</span>
			</div>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class CountdownComponent implements OnInit, OnDestroy {
	// Variables
	private subscription: Subscription = new Subscription;
	@Input() secId: string= '';
	@Input() section: any;
	@Input() data : any;
	@Input() isMobile: boolean = false;
	@Input() clockUnitType: string = 'short';
	@Input() clockLayout: string = 'clock_layout_v1';
	@Input() timerClr: string = '';
	@Input() timerFs: string = '';
	@Input() unitClr: string = '';
	@Input() unitFs: string = '';

	@Output() callback : EventEmitter<any> = new EventEmitter();

	days: number = 0;
	hours: number = 0;
	minutes: number = 0;
	seconds: number = 0;
	dDay: any;
	milliSecondsInASecond: number = 1000;
	hoursInADay: number = 24;
	minutesInAnHour: number = 60;
	secondsInAMinute: number  = 60;
	timeDiff: number = 0;
	secondsToDday: string | number = 0;
	minutesToDday: string | number = 0;
	hoursToDday: string | number = 0;
	daysToDday: string | number = 0;
	sessionExpired : boolean = false;
	cookieName: string = '';

	constructor(private cookieServ: CookieServ, public utilServ: UtilServ, private cDRef: ChangeDetectorRef) { }

	ngOnInit(): void {
		if(this.data){
			// Clock utit and layout
			if(this.section){
				this.clockUnitType = this.data.clock_unit_type && this.data.clock_unit_type;
				this.clockLayout = this.data.clock_layout && this.data.clock_layout;
			}
			if(this.section && this.isMobile){
				this.clockUnitType = 'short';
			}
			// Theme builder section
			if(this.section){
				this.cookieName = 'sec_discount_bar_'+this.secId;
			} else{
				this.cookieName = this.data.code;
			}
			// let flag = this.cookieServ.getCookie(this.data.code);
			let flag = this.cookieServ.getCookie(this.cookieName);
			if(flag != ''){
				if(this.data.post_clock_expire_action){
					switch(this.data.post_clock_expire_action){
						case 'cookie_redirect':
							if(this.section && this.section.cookie_redirect_url){
								window.location.href = this.utilServ.buildLink(this.section.cookie_redirect_url.link_to, this.section.cookie_redirect_url.link_url);
							} else {
								// Booking form
								window.location.href = this.data.cookie_redirect_url;
							}
							this.sessionExpired = true;
							break;
						case 'cookie_reset_clock':
							this.targetTime();
							break;
						case 'cookie_hide':
							this.sessionExpired = true;
							this.callback.emit();
							break;
					}
				}
			} else {
				this.targetTime();
			}
			this.subscription = interval(1000).subscribe(() => { this.clockTimeDiff(); });
		}
	}
	/**
	 * Target time for clock
	 */
	private targetTime(): void {
		let totalSeconds: number = 0;
		if(this.data.clock_expire_type && this.data.clock_expire_type == 'specific_time'){
			this.days = +this.data.clock_specific_time_days;
			this.hours = +this.data.clock_specific_time_hours;
			this.minutes = +this.data.clock_specific_time_mins;
			this.seconds = +this.data.clock_specific_time_secs;
			if(this.days > 0){
				totalSeconds = this.days * this.hoursInADay * this.minutesInAnHour * this.secondsInAMinute;
			}
			if(this.hours > 0){
				totalSeconds += this.hours * this.minutesInAnHour * this.secondsInAMinute;
			}
			if(this.minutes > 0){
				totalSeconds += this.minutes * this.secondsInAMinute;
			}
			if(this.seconds > 0){
				totalSeconds += this.seconds;
			}
			this.dDay = dayjs().add(totalSeconds, 'seconds');
			this.dDay = dayjs(this.dDay, 'MMMM DD YYYY, h:mm:ss').valueOf();
		} else {
			this.dDay = dayjs(this.data.clock_specific_day+'23:59:59', 'YYYY-MM-DD, h:mm:ss').valueOf();
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Clock time difference
	 */
	private clockTimeDiff(): void {
		//check if session already expired
		if(!this.sessionExpired){
			let timeDiff = this.dDay - dayjs().valueOf();
			if(Math.floor((timeDiff) / (this.milliSecondsInASecond) % this.secondsInAMinute) < 0){
				timeDiff = 0;
				if(this.data.on_page_clock_expire_action){
					switch(this.data.on_page_clock_expire_action){
						case 'redirect':
							this.sessionExpired = true;
							this.checkCookie();
							if(this.section && this.section.clock_redirect_url){
								window.location.href = this.utilServ.buildLink(this.section.clock_redirect_url.link_to, this.section.clock_redirect_url.link_url);
							} else {
								// Booking form
								window.location.href = this.data.redirect_url;
							}
							break;
						case 'reset_clock':
							this.checkCookie();
							this.targetTime();
							break;
						case 'hide':
							this.sessionExpired = true;
							this.checkCookie();
							this.callback.emit();
							break;
					}
				}
			}
			this.timeDiff = timeDiff;
			this.allocateTimeUnits(this.timeDiff);
		}else{
			//if session expired then call with 0 difference
			this.allocateTimeUnits(0);
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Allocare the time difference to time units
	 * @param timeDiff
	 */
	private allocateTimeUnits (timeDiff: number): void {
		this.secondsToDday = Math.floor((timeDiff) / (this.milliSecondsInASecond) % this.secondsInAMinute);
		if(this.secondsToDday < 10){
			if(this.isMobile){
				this.secondsToDday = this.secondsToDday;
			} else {
				this.secondsToDday = '0'+this.secondsToDday;
			}
		}
		this.minutesToDday = Math.floor((timeDiff) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.secondsInAMinute);
		if(this.minutesToDday < 10){
			if(this.isMobile){
				this.minutesToDday = this.minutesToDday;
			} else {
				this.minutesToDday = '0'+this.minutesToDday;
			}
		}
		this.hoursToDday = Math.floor((timeDiff) / (this.milliSecondsInASecond * this.minutesInAnHour * this.secondsInAMinute) % this.hoursInADay);
		if(this.hoursToDday < 10){
			if(this.isMobile){
				this.hoursToDday = this.hoursToDday;
			} else {
				this.hoursToDday = '0'+this.hoursToDday;
			}
		}
		this.daysToDday = Math.floor((timeDiff) / (this.milliSecondsInASecond * this.minutesInAnHour * this.secondsInAMinute * this.hoursInADay));
		if(this.daysToDday < 10){
			if(this.isMobile){
				this.daysToDday = this.daysToDday;
			} else {
				this.daysToDday = '0'+this.daysToDday;
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Check the cookie
	 */
	private checkCookie(): void {
		let flag = this.cookieServ.getCookie(this.cookieName);
		if (flag == '') {
			this.setCouponCookie();
		}
	}
	/**
	 * Set the cookie for the coupon, to remind the expiration time.
	 */
	private setCouponCookie(): void {
		let days = this.data.clock_expire_cookie_days ? this.data.clock_expire_cookie_days : 0;
		let hours = this.data.clock_expire_cookie_hours ? this.data.clock_expire_cookie_hours : 0;
		let minutes = this.data.clock_expire_cookie_mins ? this.data.clock_expire_cookie_mins : 0;
		var time = dayjs().add(days, 'days').add(hours, 'hours').add(minutes, 'minutes');
		dayjs.extend(utc);
		var time2 = dayjs.utc(time).format("ddd, DD MMM YYYY h:mm:ss");
		let cookieName = this.cookieName;
		let val = 1;
		let expires = "expires=" + time2;
		document.cookie = cookieName+"=" + val + ";" + expires + ";path=/";
	}
	// Destroy component
	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}