import { Component, OnInit, Self, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// External lib
import { takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
// Services
import { ApiServ, InitServ, LoaderServ, NgOnDestroy, PopupServ, RenderComponentServ, SectionServ, UtilServ } from '../../../Services';
import { InvServ } from '../Invoice.service';
// Interface
import { APIRes } from 'src/app/Interfaces/Global';
import { CusumSetts, InvApiRes, Invoice, SummBkngTotalFields } from 'src/app/Interfaces/Invoice';
interface PDFAPIRes extends APIRes { data: { file_name: string, folder_path: string } }

@Component({
	selector: 'bk-invoice',
	templateUrl: './Invoice.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class InvoiceComponent implements OnInit {

	secId?: string;
	section: any = {inv_details: null, sidebar: null, contract_popup: null, download_pdf: null};
	invSec: any;
	pageSett: any;
	cusumData: any;
	invForm!: FormGroup;
	// invData: Invoice | null = null;
	invData: any;
	storeLogo: string | null;
	invId: string | number | null;
	tokenError?: string | null;
	loaderId: string = 'inv-loader';
	invLogo?: string;
	isPrint: boolean = false;
	paidInvImgUrl: string = '';
	bkngsTipAmt: number = 0;
	custAddr: string = '';
	isPlanPermission!: boolean;
	accessToken: string | null = '';
	apiHit: boolean = false;
	bkngSummCustomFields: SummBkngTotalFields = this.invServ.summBkngTotalFields;

	// eslint-disable-next-line max-params
	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, public utilServ: UtilServ, private popupServ: PopupServ, private apiServ: ApiServ, @Self() public destroy: NgOnDestroy, private loader: LoaderServ, private actRoute: ActivatedRoute, private frmBldr: FormBuilder, public invServ: InvServ, private toastr: ToastrService, private router: Router){
		this.isPlanPermission = this.initServ.appPlansPermission('Invoice Contract');
		this.storeLogo = this.invServ.getStoreLogo();
		this.accessToken = (this.actRoute.snapshot.params && this.actRoute.snapshot.params['user_access_token']) ? this.actRoute.snapshot.params['user_access_token'] : null;
		// Inv id
		this.invId = this.actRoute.snapshot.queryParamMap.get('token');
		this.isPrint = this.actRoute.snapshot.queryParamMap.get('is_print') == 'true' ? true : false;
		this.utilServ.loadAddrAndPaymentScript();
	}

	ngOnInit(): void {
		this.buildSecData();
		this.invForm = this.frmBldr.group({
			inv_form_timestamp: ['']
		});
		if(this.invId){
			this.getInvAPI();
		} else {
			this.getInvId()
		}
	}

	/**
	 * Retrieves an invoice from the API based on the provided invoice ID.
	 */
	private getInvAPI(): void{
		this.loader.show(this.loaderId);
		this.apiServ.callApiWithPathVariables('GET', 'Invoice', [this.invId]).pipe(takeUntil(this.destroy)).subscribe((res:InvApiRes)=>this.handleInvApiRes(res));
	}

	/**
	 * Handles the response from the invoice API.
	 * @param res The response from the API.
	 */
	private handleInvApiRes(res: InvApiRes): void{
		this.tokenError = null;
		if(this.apiServ.checkAPIRes(res) && res?.data){
			this.invData = res.data;
			if(this.invData?.pay_status !== 'void'){
				this.buildInv();
			}
		} else if(res?.message){
			this.tokenError =  res.message;
		}
		setTimeout(() => {
			this.apiHit = true;
		}, 100);
		this.loader.hide(this.loaderId);
	}

	/**
	 * Asynchronously validates the user's access token and retrieves the invitation ID (invId).
	 * If the validation is successful and invId is obtained, it calls another function to get
	 * further details from an API (getInvAPI).
	 * @returns {Promise<void>}
	 */
	private async getInvId(): Promise<void> {
		this.invId = await this.utilServ.validateUserAccessTokenApi(this.accessToken);
		if(this.invId){
			this.getInvAPI();
		} else {
			setTimeout(() => {
				this.apiHit = true;
			}, 100);
		}
	}

	/**
	 * Builds the URL for the invoice paid image.
	 * This function constructs the URL using the provided invoice data.
	 */
	private buildInvPaidImg(): void {
		this.paidInvImgUrl = `${this.initServ.privateCdn}/${this.invData?.folder_path}${this.invData?.file_name}.png`;
	}

	/**
	 * Handles building the invoice.
	 * This function constructs the invoice based on the invoice data.
	 */
	private buildInv(): void {
		if(this.invData?.pay_status == 'paid'){
			this.buildInvPaidImg();
			setTimeout(() => {
				if(this.isPrint){
					this.downloadInv();
				}
			}, 100);
		} else if(!this.invData?.scheduled){
			this.custAddr = this.buildCustAddr(this.invData?.address);
			this.replaceShortCode();
			this.invLogo = this.buildLogo(); // Build logo
			this.showContract(); // Show the contract
			this.updateInvViewStatus(); //Update the invoice view status
			this.fetchBkngInvAmt();
		}
	}

	/**
	 * Replaces short codes in the invoice customization data.
	 */
	private replaceShortCode(): void {
		this.cusumData = this.invData?.settings?.customization;
		if(this.cusumData?.title?.text){
			this.cusumData.title['text'] = this.invServ.tokenReplace(this.cusumData.title.text, 'StoreName', 'store_name');
		}
		if(this.cusumData?.email?.text){
			this.cusumData.email['text'] = this.invServ.tokenReplace(this.cusumData.email.text, 'SupportEmail', 'support_email');
		}
	}

	/**
	 * Builds secondary data based on section settings and page data.
	 */
	private buildSecData(): void {
		if(this.secId && this.rcServ.pageData){
			this.pageSett= this.rcServ.pageData.section_settings;
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
			this.invSec = this.section?.inv_details;
		}
	}

	/**
	 * Check if custom logo data exists and has a URL, generate <img> tag with custom logo URL
	 * Check if section logo media exists and has at least one element, generate <img> tag with section logo media
	 * Check if storeLogo is available, generate <img> tag with storeLogo
	 * If no logo is available, return a placeholder <span> element
	 * @returns
	 */
	private buildLogo(): string {
		let logoId: string = this.invLogoId();
		let width: string = this.getLogoWidth();
		let imgBase: string = this.initServ.imgBase;
		let cusumSetts: CusumSetts | undefined = this.invData?.settings?.customization;
		if(this.utilServ.checkArrLength(cusumSetts?.logo?.url)){
			return this.invServ.generateImgTag(logoId, width, imgBase, cusumSetts?.logo?.url?.[0]);
		}
		if (this.cusumData?.logo?.url && this.utilServ.checkArrLength(this.cusumData.logo.url)) {
			return this.invServ.generateImgTag(logoId, width, imgBase, this.cusumData.logo.url[0]);
		}
		if (this.utilServ.checkArrLength(this.section?.logo?.media)) {
			return this.invServ.generateImgTag(logoId, width, imgBase, this.section.logo.media?.[0]);
		}
		if (this.storeLogo) {
			return this.invServ.generateImgTag(logoId, width, imgBase, this.storeLogo);
		}
		return `<span class="d-inline-block fw-bold lh-normal text-capitalize text-start w-90">YOUR LOGO</span>`;
	}

	private invLogoId(): string {
		return this.section?.inv_details?.logo_id
	}

	/**
	 * Retrieves the width of the logo.
	 * @returns The width of the logo as a string.
	 */
	private getLogoWidth(): string {
		return this.pageSett?.[this.pageSett?.[this.invLogoId()]?.elements?.media]?.width ?? '80';
	}

	/**
	 * Shows the contract popup if conditions are met.
	 */
	public showContract(): void {
		if(this.isPlanPermission){
			if(!this.invData?.scheduled && this.contractNotAccept()) {
				this.popupServ.addContractPopup(this.section.contract_popup, this.invId).pipe(takeUntil(this.destroy)).subscribe((res: boolean | undefined) => {
					if(res){
						this.getInvAPI();
					}
				});
			}
		}
	}

	/**
	 * Checks if the contract has not been accepted.
	 * This function evaluates whether the contract popup is enabled and the contract has not been accepted.
	 * @returns A boolean indicating whether the contract has not been accepted.
	 */
	private contractNotAccept(): boolean {
		return (this.section?.contract_popup?.add_contract_popup == 'yes' && this.invData?.contract_accepted == false);
	}

	/**
	 * Initiates the download of the invoice.
	 * Makes an API call to download the invoice PDF.
	 * @param invData - Data related to the invoice.
	 */
	public downloadInv(): void {
		this.loader.show(this.loaderId);
		this.apiServ.callApiWithPathVariables("GET", "DownloadInv", [this.invData?._id]).pipe(takeUntil(this.destroy)).subscribe((res: PDFAPIRes) => this.handleDownloadApiRes(res));
	}

	/**
	* Handles the response after attempting to download the invoice.
	* Saves the downloaded PDF file and hides the loader.
	* @param res - The response from the API.
	* @param invData - Data related to the invoice.
	*/
	private handleDownloadApiRes(res: PDFAPIRes): void {
		let fileName: string = '';
		let pdfUrl: string = '';
		if(this.apiServ.checkAPIRes(res) && res?.data){
			fileName = `${res?.data?.file_name}.pdf`;
			pdfUrl = `${this.initServ.privateCdn}/${res?.data?.folder_path}${fileName}`;
		} else {
			fileName = `${this.invData?.file_name}.pdf`;
			pdfUrl = `${this.initServ.privateCdn}/${this.invData?.folder_path}${fileName}`;
		}
		this.utilServ.downloadFiles(pdfUrl, fileName, true);
		this.loader.hide(this.loaderId);
	}

	/**
	 * Update the invoice view status, for customer is view or not
	 */
	private updateInvViewStatus(): void {
		if(this.invData?.inv_view_status && this.invData?.inv_view_status == 'not_viewed'){
			let data: {id: string | number | null} = {id : this.invId}
			this.apiServ.callApiWithPathVariables('PUT', 'InvViewStatus', [this.invId], data).pipe(takeUntil(this.destroy)).toPromise();
		}
	}

	// Get the booking invoice total

	/**
	 * Fetches booking invoice amount if invoice type is 'bookings' and there are booking IDs available.
	 */
	public fetchBkngInvAmt(bkngsTipAmt: number = 0): void {
		this.bkngsTipAmt = bkngsTipAmt;
		if(this.invData?.inv_type === 'bookings' && this.utilServ.checkArrLength(this.invData?.booking_ids)){
			let payload: Invoice = this.invCommanPayload();
			if(payload){
				this.invServ.fetchBkngInvAmt(payload, this.invData);
			}
		}
	}

	/**
	 * Prepares the common payload for invoice-related operations.
	 * @param isInvPay Indicates if the payload is for an invoice payment.
	 * @returns The prepared payload.
	 */
	private invCommanPayload(isInvPay: boolean = false): Invoice {
		let formVal: Invoice = this.invForm.value;
		let payload: Invoice = {
			inv_id : this.invData?._id,
			inv_num : this.invData?.inv_num,
			booking_ids : this.invData?.booking_ids,
			payment_type: formVal?.payment_type,
			no_of_payments : this.invData?.no_of_payments,
			tip: this.invData?.tip
		}
		payload = this.prepareTipData(payload, isInvPay);
		return payload;
	}

	/**
	 * Prepares payment data for invoice payment.
	 * @returns The prepared payment data.
	 */
	private preparePaymentData(): Invoice {
		let data: Invoice = this.invCommanPayload(true);
		data = this.totalAmt(data);
		data['uid'] = this.invData?.uid;
		data['address'] = this.invData?.address;
		data['paid_amount'] = this.invData?.paid_amount;
		data['inv_type'] = this.invData?.inv_type;
		data['settings'] = this.invData?.settings;
		data = this.provPayments(data);
		return data;
	}

	/**
	 * Prepares payment-related data for the invoice.
	 * This function populates payment-related fields in the provided invoice data.
	 * @param data The invoice data to be modified.
	 * @returns The modified invoice data.
	 */
	private provPayments(data: Invoice): Invoice {
		// Provider pay ids and object send to API. when 3DS enabled and request send to customer.
		if(this.utilServ.checkArrLength(this.invData?.pay_provider_ids)){
			data['pay_provider_ids'] = this.invData?.pay_provider_ids;
		}
		if(this.invData?.providers_pay){
			data['providers_pay'] = this.invData?.providers_pay;
		}
		return data;
	}

	/**
	 * Updates the total amount field in the invoice data.
	 * This function updates the total amount field based on the invoice type and booking IDs.
	 * @param data The invoice data to be modified.
	 * @returns The modified invoice data.
	 */
	private totalAmt(data: Invoice): Invoice {
		data['total_amount'] = this.invData?.total_amount;
		if(this.invData?.inv_type === 'bookings' && this.utilServ.checkArrLength(this.invData?.booking_ids)){
			data['total_amount'] = this.invServ.bkngAmtData?.total;
		}
		return data;
	}

	/**
	 * Prepares tip data based on invoice payment status.
	 * @param data The data to be prepared.
	 * @param isInvPay Indicates if the preparation is for invoice payment.
	 * @returns The prepared tip data.
	 */
	private prepareTipData(data: Invoice, isInvPay: boolean = false): Invoice {
		if (this.invData?.settings?.allow_tip_by_cust) {
			if(isInvPay){
				data = this.chargedTipData(data);
			} else {
				data = this.formTipData(data);
			}
		}
		return data;
	}

	/**
	 * Prepares tip data for charged invoice.
	 * @param data The data to be prepared.
	 * @returns The prepared tip data.
	 */
	private chargedTipData(data: Invoice): Invoice {
		if(this.invServ.isObjExist(this.invData?.bookings_tip)){
			data['bookings_tip'] = this.invData?.bookings_tip;
		} else if(this.invData?.tip?.pay) {
			data['tip'] = this.invData.tip;
		} else {
			data = this.formTipData(data);
		}
		data['settings'] = this.invData?.settings;
		return data;
	}

	/**
	 * Prepares tip data from form for invoice.
	 * @param data The data to be prepared.
	 * @returns The prepared tip data.
	 */
	private formTipData(data: Invoice): Invoice {
		let formVal: Invoice = this.invForm.value;
		if(!this.invData?.no_of_payments){
			if(this.invServ.isObjExist(formVal?.bookings_tip)){
				data['bookings_tip'] = formVal.bookings_tip;
				data['has_tip'] = true;
			} else if(formVal?.tip?.pay){
				data['tip'] = formVal.tip;
				data['has_tip'] = true;
			}
		}
		return data;
	}

	/**
	 * Pay invoice button and go to the invoice payment page with prepared the invoice payment data
	 */
	public payInv(): void | boolean{
		if (!this.utilServ.validatePhantomField(this.invForm.controls['inv_form_timestamp'].value, 'inv_form_timestamp')) {
			return false;
		}
		let paymentData: Invoice= this.preparePaymentData();
		if(this.invForm.valid){
			// Added condition on the basis of accesstoken so that user can access of routing depending upon invId(old) and accessToken(new)
			if(this.accessToken){
				this.router.navigate([`/invoice-payment/${this.accessToken}`], { state: { data: paymentData } });
			}else{
				this.router.navigate(['/invoice-payment'], { queryParams: { token: this.invId }, state: { data: paymentData } });
			}
		} else {
			this.toastr.clear();
			this.toastr.error('Please fill in the fields marked in red.')
		}
	}

	/**
	 * Method takes an address object and extracts the specific fields and build customer address field array from the address object in specified order.
	 * @param addrObj - contains city, zipcode, state, country, and the values are the corresponding values for each address field.
	 * @returns the sequence address string of the customer
	 */
	private buildCustAddr(addrObj:{[key:string]: string | undefined} | undefined):string{
		let addrFields: string[] = ['city', 'zipcode', 'state', 'country'];
		let custAddrFields: string[] = [];
		for(let field of addrFields){
			if(addrObj?.[field]){
				custAddrFields.push(addrObj[field] ?? '');
			}else if(field == 'country' && addrObj?.[field] == ''){
				custAddrFields.push(this.initServ.appData?.merchant_country);
			}
		}
		return  custAddrFields.join(', ') ?? '';
	}


	public onSummBkngFieldsChange(summBkngFields: SummBkngTotalFields): void {
		this.bkngSummCustomFields = summBkngFields;
	}
}
