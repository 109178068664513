<div [id]="secId" class="tjs-session-page py-100 tjs-bg-style position-relative" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section" [class.embeded-container]="utilServ?.embedStatus" [ngStyle]="{'background-image' : (section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}">
	<div class="tjs-opacity--bg" [id]="section?.media?.id" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0"></div>
	<div class="tjs-session__wrapper">
		<div class="container">
			<div class="tjs-section__title text-center mb-50 pb-10" *ngIf="section?.title?.content || section?.sub_title?.content">
				<!-- Title -->
				<div class="tjs-section__heading mb-15" *ngIf="section?.title?.content">
					<h4 [id]="section?.title?.id" class="fw-bold display-6 bk-title" [innerHTML]="section?.title?.content | safeHtml"></h4>
				</div>
				<!-- Sub title -->
				<div class="tjs-section__description" *ngIf="section?.sub_title?.content">
					<p [id]="section?.sub_title?.id" class="bk-sub-title" [innerHTML]="section?.sub_title?.content | safeHtml"></p>
				</div>
			</div>
			<!-- Forgot password form -->
			<form *ngIf="section?.form" class="tjs-session__form bk-form" [formGroup]="forgotForm" bkDebounceEvent [isSubmit]="true" (debounceSubmit)="submitForm()">
				<div class="row justify-content-center">
					<div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
						<div class="shadow-sm card" [id]="section?.form?.id" >
							<div class="text-center card-body px-sm-30">
								<!-- Avatar -->
								<div class="tjs-session__thumb shadow-sm mt-n60 bg-white overflow-hidden mb-20 rounded-circle white-bg d-flex align-items-center justify-content-center shadow-sm tjs-h-100 tjs-w-100 mx-auto">
									<bk-user-img alt="user image" width="90" height="90"></bk-user-img>
								</div>
								<div class="position-relative">
									<!-- Email -->
									<div class="mb-30 text-start" *ngIf="section?.form?.email">
										<label [id]="section?.form?.email?.label_id" class="form-label bk-form-label" *ngIf="section?.form?.email?.label" [innerHTML]="section?.form?.email?.label | safeHtml"></label>
										<input [id]="section?.form?.email_id" class="form-control bk-input" [ngClass]="{'is-invalid': f['email_id']?.touched && f['email_id']?.errors}" [placeholder]="section?.form?.email?.placeholder" formControlName="email_id" type="text">
										<!-- Error -->
										<ng-container *ngIf="f['email_id']?.touched && (f['email_id']?.errors?.required || f['email_id']?.errors?.pattern)">
											<div class="tjs-error">
												<i class="tjsicon-attention"></i>
												<ng-container *ngIf="f['email_id']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
												<ng-container *ngIf="f['email_id']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.validEmail | translate}} </ng-container>
											</div>
										</ng-container>
									</div>
									<!-- Submit button -->
									<div class="mb-25" *ngIf="section?.form?.button">
										<bk-navigate  [secId]="section?.form?.button_id" [designId]="section?.form?.button?.design_id" [isSubmit]="true" [innerHTML]="section?.form?.button?.content" customClass="btn btn-primary btn-lg border-transparent" essentialClass="w-100 bk-form-btn"></bk-navigate>
									</div>
									<!-- Loader -->
									<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
								</div>
								<!-- Login link -->
								<div class="mb-10" *ngIf="section?.form?.sign_in_link">
									<a [id]="section?.form?.sign_in_link_id" class="link--orange bk-link" [routerLink]="['/'+initServ.appDynamicRoutes['login']]" [queryParams]="utilServ?.embedStatus ? { embed: 'true'} : {}"  [innerHTML]="section?.form?.sign_in_link | safeHtml"></a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
