<div class="row mb-50 justify-content-end">
	<div class="col-md-6 col-12 justify-content-md-end ">
		<!-- custom fields -->
		<div class="mb-20" [id]="invSec?.list_id" *ngIf="invData?.inv_type == 'custom' && invData?.custom_fields && (invData?.custom_fields).length > 0">
			<ul class="tjs-list list-unstyled d-flex flex-column gap-15">
				<li *ngFor="let summary of invData?.custom_fields; let i =index;trackBy: utilServ.trackById;" class="d-flex align-items-center bk-label-clr">
					<span class="w-60" [innerHTML]="summary?.text | translate | safeHtml"></span>
					<span class="w-40 text-end"><bk-amount-display [amount]="summary?.value" [isZero]="true"></bk-amount-display></span>
				</li>
			</ul>
		</div>
		<!-- bkng custom fields -->
		<div class="mb-20" [id]="invSec?.list_id" *ngIf="invData?.inv_type == 'bookings' && bkngCustomFields?.length && bkngCustomFields.length > 0">
			<ul class="tjs-list list-unstyled d-flex flex-column gap-15">
				<li *ngFor="let summary of bkngCustomFields; let i =index; trackBy: utilServ.trackById;" class="d-flex align-items-center bk-label-clr">
					<span class="w-60" [innerHTML]="invServ.formParamsName[summary.text] | translate | safeHtml"></span>
					<span class="w-40 text-end"><bk-amount-display [amount]="summCustomFields[summary.text]" [isZero]="true"></bk-amount-display></span>
				</li>
			</ul>
		</div>
		<!-- TODO:Sony, check border in case of bkng custom fields -->
		<div [id]="invSec?.total_amount_id" [class.border-top]="invData?.custom_fields">
			<div class="d-flex align-items-center justify-content-between bk-label-clr bk-label-fw " [class.mt-20]="invData?.custom_fields || invData?.inv_type == 'bookings'">
				<span clas="w-60" [innerHTML]="invSec?.total_amount | translate | safeHtml"></span>
				<span class="w-40 text-end">
					<bk-amount-display *ngIf="invData?.inv_type == 'custom'; else bkngAmt" [amount]="invData?.total_amount" [isZero]="true"></bk-amount-display>
					<ng-template #bkngAmt>
						<bk-amount-display [amount]="(bkngAmtData?.total ?? invData?.total_amount) - (invData?.tip?.pay ?? 0)" [isZero]="true"></bk-amount-display>
					</ng-template>
				</span>
			</div>
		</div>
	</div>
</div>
