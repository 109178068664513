import { Component, OnInit, ViewEncapsulation, Self, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
// External lib
import{ TranslateService} from '@ngx-translate/core';
// Services
import { NgOnDestroy, InitServ, RenderComponentServ, SectionServ, UtilServ, CookieServ, StyleServ } from '../../../Services';
import { AuthServ } from '../../_services';
// Popup component
import { DeactivateAccountPopupComponent } from '../DeactivateAccountPopup/DeactivateAccountPopup.component';
// Child component
import { HeaderMenuComponent } from './HeaderMenu/HeaderMenu.component';
// Constants
import { IS_TAB } from '../../../Constants';
@Component({
	selector: 'bk-header',
	templateUrl: './Header.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class HeaderComponent implements OnInit {
	// Variables
	@ViewChild(HeaderMenuComponent) menuChild: HeaderMenuComponent | undefined; //Payment gateway component
	layout: any = this.initServ.headerFooter; // App header/footer
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	secId: any;
	sett: any;
	varId: any;
	style: any;
	headerMenu: any;
	// Section fields
	section: any = {
		logo: null,
		menu: null
	}
	logoStatus: boolean = false;
	faqLink: string = '';
	isPlanPermission: boolean = false;
	isAllowOneLang:boolean = false;
	flagUrl=this.initServ.imgBase+'/assets/images/flags/';
	pageSett: any;
	pageId: any;
	buildHeader: boolean = true;
	currentUser: any;
	fluid: boolean = false;
	headerStyle: any;
	sessionMenu: any = []; // Session menu
	menuContent: any;

	widthType: string = IS_TAB ? 'mobile_width' : 'width';
	// heightType: string = IS_TAB ? 'mobile_height' : 'height';

	constructor(public initServ: InitServ, public rcServ: RenderComponentServ, public secServ: SectionServ, public utilServ: UtilServ, @Self() private destroy: NgOnDestroy, public authServ: AuthServ, public translate: TranslateService, private cookieServ: CookieServ, private dialog: MatDialog,private styleServ: StyleServ){
		// FAQ link
		if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.customers && this.admnStngs.merchant_settings?.customers?.faq_support_link){
			this.faqLink = this.utilServ.checkHttpExist(this.admnStngs.merchant_settings.customers.faq_support_link);
		}
		// Translation plan permission
		if(this.admnStngs && this.admnStngs.package_id){
			this.isPlanPermission = this.initServ.appPlansPermission('translation');
			if(!this.isPlanPermission){
				this.isAllowOneLang = true;
			}
		}
		// User logged in get again current user local storage
		this.initServ.isUserProfile.pipe(takeUntil(this.destroy)).subscribe((value) => {
			if(value){
				this.currentUser = this.utilServ.appLocalStorage();
			}
		});
	}
	ngOnInit(): void {
		// Page settings
		this.utilServ.pageSett.pipe(takeUntil(this.destroy)).subscribe((sett: any) => {
			if(sett && sett.status && JSON.stringify(sett.data) != JSON.stringify(this.pageSett)){
				// Current login user info from browser local storage
				this.currentUser = this.utilServ.appLocalStorage();
				this.pageSett = sett.data;
				this.buildHeader = false;
				this.buildSection();
				// Set default value for mobile width & height of logo
				if(this.sett && this.sett[this.section?.logo?.media_id]){
					if(!(this.sett[this.section?.logo?.media_id]).hasOwnProperty('mobile_width')){
						this.sett[this.section?.logo?.media_id]['mobile_width'] = 72;
					}
					// let imgHeight: any = this.utilServ.getImgHeight(this.sett, this.section?.logo?.media_id);
					// if(!(this.sett[this.section?.logo?.media_id]).hasOwnProperty('height')){
					// 	this.sett[this.section?.logo?.media_id]['height'] =  (imgHeight && imgHeight > 128) ? 128 : imgHeight;
					// }
					// if(!(this.sett[this.section?.logo?.media_id]).hasOwnProperty('mobile_height')){
					// 	this.sett[this.section?.logo?.media_id]['mobile_height'] = (imgHeight && imgHeight > 64) ? 64 : imgHeight;
					// }
				}
			}
		});
	}
	/**
	 * Build the menu section
	 */
	private buildSection() {
		if(this.layout && this.layout.site_data){
			this.secId = this.layout.header_id;
			if(this.secId){
				this.sett = this.layout.site_data.section_settings;
				this.secServ.setServData(this.sett, this.layout.site_data.content);
				this.varId = this.sett[this.secId].variation_id;
				this.style = this.layout.site_data.style;
				if(this.layout.menus && this.layout.menus.header){
					this.headerMenu = this.layout.menus.header;
				}
				let sections = this.layout.site_data.sections;
				// Header layout
				if(this.style && this.style[this.secId].layout && this.style[this.secId].layout == 'full'){
					this.fluid = true;
				}
				let secElem : any = null;
				if(sections){
					secElem = sections[this.secId];
				}
				if(secElem){
					let logoId: string = secElem['logo'];

					// Set the globally logo data
					if(this.sett && this.sett[logoId].elements && (Object.keys(this.sett[logoId].elements)).length > 0){
						let elements = this.sett[logoId].elements;
						// We forcefully update status of media because there is no option to hide/show logo media.
						if(elements.media && this.sett[elements.media]){
							this.sett[elements.media].status = true;
							this.sett[elements.media].mobile_status = true;
						}
						let obj = this.secServ.getElements(logoId, elements);
						this.utilServ.setSiteLogo = obj;
						if(this.secServ.checkElementStatus(logoId)){
							this.section['logo'] = obj;
						}
					}
					// Menu
					let menuId: string = secElem['menu'];
					if(menuId && this.sett && this.sett[menuId].elements && (Object.keys(this.sett[menuId].elements)).length > 0){
						let elements = this.sett[menuId].elements;
						let obj: any = {
							id: menuId
						};
						for(let key in elements){
							if(elements[key]){
								obj[key]={};
								obj[key]['id']=elements[key];
								obj[key]['status']=this.secServ.checkElementStatus(elements[key]);
								// obj[key] = this.secServ.checkElementStatus(elements[key]) && elements[key]
							}
						}
						this.section['menu'] = obj;
					}
				}
				// Logo settings
				if(this.section && this.section.logo && this.sett[this.section.logo.id] && this.sett[this.section.logo.id].disable_on && (this.sett[this.section.logo.id].disable_on).length > 0){
					if((this.sett[this.section.logo.id].disable_on).includes(this.pageSett.id)){
						this.logoStatus = false;
					} else {
						this.logoStatus = true;
					}
				} else {
					this.logoStatus = true;
				}
				//Header style
				this.styleServ.applyPageStyle(this.style,'header',this.secId,this.section);
				if(this.headerMenu && this.headerMenu.content){
					this.menuContent = this.headerMenu.content;
				}
				let sectionMenu: any = this.section?.menu;
				// Session menus
				if((!this.currentUser || !this.currentUser?.token || this.initServ.theme) && sectionMenu && (sectionMenu?.login?.status || sectionMenu?.signup?.status)){
					this.sessionMenu = [];
					if(this.varId == 'bk_header_V1' || this.varId == 'bk_header_V2'){
						if(this.secServ.checkEleStatus(this.sett, sectionMenu?.login?.id)){
							let menu: any = { id: 'login', title: "Login/Sign Up", link_to: "page", link_url: "login"}
							if(this.utilServ.menuChecker(menu, this.pageSett.id)){
								this.sessionMenu.push(menu);
							}
						}
					} else {
						if(this.secServ.checkEleStatus(this.sett, sectionMenu?.login?.id)){
							let menuOne: any = {id: 'login', title: 'login', link_to: 'page', link_url: 'login'};
							if(this.utilServ.menuChecker(menuOne, this.pageSett.id)){
								this.sessionMenu.push(menuOne);
							}
						}
						if(this.secServ.checkEleStatus(this.sett, sectionMenu?.signup?.id)){
							let menuTwo: any = {id: 'signup', title: 'Sign Up', link_to: 'page', link_url: 'signup'};
							if(this.utilServ.menuChecker(menuTwo, this.pageSett.id)){
								this.sessionMenu.push(menuTwo);
							}
						}
					}
				}
				// Sticky header
				setTimeout(() => {
					this.buildHeader = true;
					if(this.sett && this.sett[this.secId] && this.sett[this.secId][this.rcServ.statusType] && this.sett[this.secId].header_type && this.sett[this.secId].header_type == 'fixed' && this.pageSett && this.pageSett?.header){
						document.body.classList.add("tjs-header--sticky");
						setTimeout(()=>{this.setHeaderHeight()},500)
					}
				}, 100);
			}
		}
	}

	/**
	 * Deactivate account popup
	 */
	public deactivateAccount(): void {
		let ref: MatDialogRef<DeactivateAccountPopupComponent>;
		ref = this.dialog.open(DeactivateAccountPopupComponent);
		ref.afterClosed();
	}
	/**
	 * Language code change
	 * Set the cookie and reload tha app
	 * @param code
	 */
	public langChange(code:string):void{
		this.cookieServ.createCookie(this.initServ.lngCookieName, code, 365);
		// if(this.initServ._userInfo){
		// 	this.initServ.updatePreferredLang(code);
		// }else{
			window.location.reload();
		// }
	}
	/**
	 * Login as admin
	 */
	public loginAsMerchant(): void {
		this.authServ.loginAsAdmin(this.currentUser);
	}

	/**Function to set header height */
	setHeaderHeight() {
		let headerEle = document.getElementsByClassName("tjs-header-wrap");
		if(headerEle && headerEle.length > 0 && headerEle[0]){
			let headerHeight = (headerEle[0]).clientHeight;
			var headerCloneEle: any = document.getElementById("header-clone");
			if(headerCloneEle){
				headerCloneEle.style.height = headerHeight+"px";
				this.utilServ.headerHeight = +headerHeight;
			}
		}
	}
}
