<div class="tjs-popup rounded-5 position-relative min-height--150" [id]="secId">
	<bk-popup-header *ngIf="section?.title" [headingId]="section?.title?.id" [heading]="section?.title?.content" [isClose]="false"></bk-popup-header>
	<form [formGroup]="contractForm">
		<div [id]="section?.contract?.id">
			<div class="tjs-popup__content tjs-popup__body">
				<p *ngIf="section?.contract?.desc" [id]="section?.contract?.desc_id" [innerHTML]="section?.contract?.desc | safeHtml"></p>
				<ng-container *ngIf="verifyCode; else codeTemp">
					<div class="form-check form-check-inline" [id]="section?.contract?.terms_and_condition_id">
						<input id="add_contract" class="form-check-input" formControlName="contract_accepted" type="checkbox" [class.is-invalid]="contractForm.controls['contract_accepted'].hasError('required') && contractForm.controls['contract_accepted'].touched">
						<label for="add_contract" class="form-check-label bk-form-check-label" [id]="section?.contract?.terms_and_condition?.label_id" [innerHTML]="section?.contract?.terms_and_condition?.label | safeHtml" *ngIf="section?.contract?.terms_and_condition?.label"></label>
					</div>
					<ng-container *ngTemplateOutlet="errorTemp; context:{controlName: 'contract_accepted', errorId: section?.contract?.terms_and_condition?.error_msg_id, errorMsg: section?.contract?.terms_and_condition?.error_msg}"></ng-container>
					<!-- Signature -->
					<div *ngIf="contractData?.add_signature == 'yes' && (contractForm.controls['cust_signatures'].value && (contractForm.controls['cust_signatures'].value).length>0) && (section?.contract?.signature?.items && (section?.contract?.signature?.items).length > 0)" [id]="section?.contract?.signature?.id" class="mt-20" formArrayName="cust_signatures">
						<div *ngFor="let sign of section?.contract?.signature?.items; let i = index" [id]="sign?.id" [formGroupName]="i">
							<label [id]="sign?.label_id" [innerHTML]="sign?.label | safeHtml" class="d-block mb-15"></label>
							<div class="border rounded overflow-hidden bg-white">
								<canvas #sPad class="signature-canva-pad position-relative" [width]="signCanva.width" [height]="signCanva.height"></canvas>
							</div>
							<ng-container *ngIf="isSignatureNull">
								<ng-container *ngTemplateOutlet="errorTemp; context:{errorId: sign?.error_msg_id, errorMsg: sign?.error_msg, customError:true}"></ng-container>
							</ng-container>
							<div class="mt-10 text-right" *ngIf="signaturePad && !signaturePad.isEmpty()">
								<bk-navigate [secId]="section?.contract?.clear_btn_id" [designId]="section?.contract?.clear_btn?.design_id" customClass="btn btn-white rounded-5" [innerHTML]="section?.contract?.clear_btn?.content" (callback)="clearSignature()"></bk-navigate>
							</div>
						</div>
					</div>
				</ng-container>
				<ng-template #codeTemp>
					<div class="form-group">
						<label class="form-label d-block mb-15" *ngIf="section?.contract?.verify_code?.label" [id]="section?.contract?.verify_code_id" [innerHTML]="section?.contract?.verify_code?.label | safeHtml"></label>
						<input BKNumeric [onlyNumber]="true" [onlyInputNumber]="true" type="text" [placeholder]="section?.contract?.verify_code?.placeholder" formControlName="otp" [class.is-invalid]="contractForm.controls['otp'].errors && contractForm.controls['otp'].touched" class="form-control" maxlength="6" >
					</div>
					<ng-container *ngTemplateOutlet="errorTemp; context:{controlName: 'otp', errorId: section?.contract?.verify_code?.error_msg_id, errorMsg: section?.contract?.verify_code?.error_msg}"></ng-container>
					<div class="mt-10 text-center" [id]="section?.contract?.resend_code_id">
						<div *ngIf="resetTimer" >
							<span class="pe-5" [id]="section?.contract?.resend_code?.resend_code_label_id" [innerHTML]="utilServ.generateDynamicStr(section?.contract?.resend_code?.resend_code_label, '{{.timer}}', displayTimer) | safeHtml"></span>
						</div>
						<button *ngIf="resendCode" type="button" class="p-0 btn btn-link resend-btn-link" (click)="generateOtp(true)" [innerHTML]="section?.contract?.resend_code?.resend_code_link" [id]="section?.contract?.resend_code?.resend_code_link_id"></button>
					</div>
				</ng-template>
				<bk-phantom-field [control]="contractForm.controls['contract_form_timestamp']"></bk-phantom-field>
			</div>
			<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse bk-footColor">
				<bk-navigate [isDebounce]="true" [debounceLoaderId]="loaderId" class="d-block mb-15 mb-sm-5" [secId]="section?.contract?.continue_btn_id" customClass="btn btn-primary rounded-5 w-100" essentialClass="w-100 text-nowrap" [designId]="section?.contract?.continue_btn?.design_id" [innerHTML]="section?.contract?.continue_btn?.content" (callback)="onContinue()"></bk-navigate>
			</div>
		</div>
	</form>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
<!-- Form error template -->
<ng-template #errorTemp let-controlName="controlName" let-errorId="erroId" let-errorMsg="errorMsg" let-customError="customError">
	<ng-container *ngIf="customError || (contractForm.controls[controlName] && contractForm.controls[controlName]?.touched && contractForm.controls[controlName].errors)">
		<div class="tjs-error">
			<i class="tjsicon-attention"></i>
			<span *ngIf="customError || (contractForm.controls[controlName] && contractForm.controls[controlName].hasError('required'))" [id]="errorId">{{errorMsg | translate}}</span>
			<span *ngIf="contractForm.controls[controlName] && contractForm.controls[controlName].hasError('minlength')"> {{initServ.appStr?.errorMsg?.otpLength | translate}} </span>
		</div>
	</ng-container>
</ng-template>

