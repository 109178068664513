/**
 * Images constant
 */
import { environment as env } from '../../environments/environment';
// Image path
const IMG_PATH = env.imgBase+'/assets/images/';
// Static image urls which are used in App.
export const IMG: any = {
	BKIcon: IMG_PATH + 'bk-icon.png',
	ProvMobApp: IMG_PATH + 'phone.png',
	ChecklistIcon: IMG_PATH + 'icon-checklist.png',
	AppStore: IMG_PATH + 'app-store-button.png',
	PlayStore: IMG_PATH + 'play-button.png',
	JobBanner: IMG_PATH + 'banner-bg-4.jpg',
	JobClock: IMG_PATH + 'clock-icon.png',
	JobCash: IMG_PATH + 'cash-icon.png',
	JobHeart: IMG_PATH + 'heart-icon.png',
	Empty: 'https://via.placeholder.com/1280x768',
	Avatar: IMG_PATH + 'avatar.png',
	KoalaIcon: IMG_PATH + 'koala-icon.png',
	FBIcon: IMG_PATH + 'fb-icon-sm.png',
	GoogleIcon: IMG_PATH + 'google-icon.png',
	PaymentCard: IMG_PATH + 'secure-card.png',
	GiftCard: IMG_PATH + 'card-7.png',
	Alert: IMG_PATH + 'alert.png',
	Visa: IMG_PATH + 'paymentCards/visa-card.png',
	Mastercard: IMG_PATH + 'paymentCards/master-card.png',
	American: IMG_PATH + 'paymentCards/amex-card.png',
	Discover: IMG_PATH + 'paymentCards/discover-card.png',
	Diners: IMG_PATH + 'paymentCards/diners-club-card.png',
	Jcb: IMG_PATH + 'paymentCards/jcb-card.png',
	Unionpay: IMG_PATH + 'paymentCards/unionpay-card.png',
	Invoice: IMG_PATH + 'bk-invoice.png',
	SadFace: IMG_PATH + 'sad-face.png',
	DeepCleaning: IMG_PATH + 'deepcleaning-icon.png',
	FbShare: IMG_PATH + 'fb_share.png',
	Quote: IMG_PATH + 'quote.png'
}
