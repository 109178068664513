import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../../Services';

@Component({
	selector: 'bk-amount-type-dropdown',
	template: `<div class="dropdown slot-dropdown">
		<button class="btn form-select text-start btn-light tjs-currency-dropdown py-5 pe-30 ps-10 bg-white {{custClass}}" type="button" id="amountTypeDropdown" data-bs-toggle="dropdown" aria-expanded="false">
			<span *ngIf="type == 'amount'" bk-currency></span>
			<span *ngIf="type == 'percentage'">%</span>
			<span *ngIf="type == 'hourly'">{{'/hr' | translate}}</span>
		</button>
		<div class="tjs-shadow--sm dropdown-menu" aria-labelledby="amountTypeDropdown">
			<button type="button" *ngIf="amountStatus" class="btn btn-link font-weight-semi-bold tjs-dropdown__link dropdown-item" [ngClass]="{'bg-primary text-white': (type == 'amount')}" (click)="selectType.emit('amount')"><span bk-currency></span></button>
			<button type="button" *ngIf="percentageStatus" class="btn btn-link font-weight-semi-bold tjs-dropdown__link dropdown-item" [ngClass]="{'bg-primary text-white': (type == 'percentage')}" (click)="selectType.emit('percentage')">%</button>
			<button type="button" *ngIf="hourlyStatus" class="btn btn-link font-weight-semi-bold tjs-dropdown__link dropdown-item" [ngClass]="{'bg-primary text-white': (type == 'hourly')}" (click)="selectType.emit('hourly')">{{showHourlyText ? 'Hourly' : '/hr' | translate}}</button>
		</div>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class AmountTypeDropdownComponent implements OnInit {

	@Input() custClass: any = ''
	@Input() amountStatus: boolean = true;
	@Input() percentageStatus: boolean = true;
	@Input() hourlyStatus: boolean = false;
	@Input() showHourlyText: boolean = false;
	@Input() type: string = '';
	@Input() isDisabled: any = null;
	@Output() selectType: EventEmitter<any> = new EventEmitter();

	constructor() { }

	ngOnInit(): void {
	}
}