<div class="tjs-popup tjs-popup--sm position-relative min-height--150"  [id]="secId">
	<!-- Popup header -->
	<bk-popup-header *ngIf="section?.title" [headingId]="section?.title?.id" [heading]="section?.title?.content" [isClose]="false"></bk-popup-header>
	<!-- Popup body -->
	<div class="tjs-popup__content tjs-popup__body" [id]="section?.form?.id">
		<!-- Address -->
		<form [formGroup]="addressForm">
			<div class="mb-25">
				<label class="form-label bk-form-label" [id]="section?.form?.payment_info?.address?.label_id" *ngIf="section?.form?.payment_info?.address?.label" [innerHTML]="section?.form?.payment_info?.address?.label | safeHtml"></label>
				<bk-tooltip customClass="bk-tooltip" *ngIf="section?.form?.payment_info?.address?.label"  [id]="section?.form?.payment_info?.address?.tooltip_id" content="{{section?.form?.payment_info?.address?.tooltip}}"></bk-tooltip>
				<input [ngClass]="{'is-invalid': addressForm.controls['address']?.touched && addressForm.controls['address']?.errors}" class="form-control" type="text" [attr.placeholder]="section?.form?.payment_info?.address?.placeholder" formControlName="address" (focusout)="getAddress($event)" (addressChange)="setAddress($event, 'zipcode')" (addressChange)="setAddress($event, 'city')" (addressChange)="setAddress($event,'state')" (focusin)="isZipcode=false" bk-google-places-autocomplete />
				<!-- Error -->
				<div class="tjs-error" *ngIf="addressForm.controls['address']?.touched && addressForm.controls['address']?.errors?.required">
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="addressForm.controls['address']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
				</div>
				<input id="city" type="text" class="d-none" formControlName="city">
				<input id="state" type="text" class="d-none" formControlName="state">
			</div>
			<!-- Zipcode -->
			<div class="mb-25" *ngIf="isZipcode">
				<label class="form-label bk-form-label d-block" [id]="section?.form?.payment_info?.address?.zipcode_label_id" *ngIf="section?.form?.payment_info?.address?.zipcode_label" [innerHTML]="section?.form?.payment_info?.address?.zipcode_label | safeHtml"></label>
				<input id="zipcode" [ngClass]="{'is-invalid': addressForm.controls['zipcode']?.touched && addressForm.controls['zipcode']?.errors}" type="text" class="form-control" formControlName="zipcode" [attr.placeholder]="section?.form?.payment_info?.address?.zipcode_placeholder">
				<!-- Error -->
				<div class="tjs-error" *ngIf="addressForm.controls['zipcode']?.touched && (addressForm.controls['zipcode']?.errors?.required || addressForm.controls['zipcode']?.errors?.pattern)">
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="addressForm.controls['zipcode']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
					<ng-container *ngIf="addressForm.controls['zipcode']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.specialChars | translate}} </ng-container>
				</div>
			</div>
			<!-- Saved card -->
			<div class="mb-0" *ngIf="existingCard">
				<label class="form-label bk-form-label d-block" [id]="section?.form?.saved_card?.label_id" *ngIf="section?.form?.saved_card?.label" [innerHTML]="section?.form?.saved_card?.label | safeHtml"></label>
				<input type="text" class="form-control" value="{{existingCard}}" readonly>
			</div>
		</form>
	</div>
	<!-- Popup footer -->
	<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse bk-footColor">
		<bk-navigate [secId]="section?.update_btn?.id" [designId]="section?.update_btn?.design_id" [innerHTML]="section?.update_btn?.content" essentialClass="w-100" customClass="btn btn-primary rounded-5 w-100" class="d-block w-100 mb-15 mb-sm-5" (callback)="submitChangeAddressForm()"></bk-navigate>
		<bk-navigate [secId]="section?.cancel_btn?.id" [designId]="section?.cancel_btn?.design_id" [innerHTML]="section?.cancel_btn?.content" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" class="d-block w-100 mb-5" (callback)="dialogRef.close()"></bk-navigate>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
