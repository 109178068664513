import { Component, OnInit, ViewEncapsulation, ViewContainerRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// Services
import { NgOnDestroy, RenderComponentServ, LoaderServ, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-theme-popups',
	templateUrl: './ThemePopups.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]

})
export class ThemePopupsComponent implements OnInit {

	// Variables
	disableClose: boolean = false;
	popupContainerId: string = 'popup-container';
	loaderId: string = 'site-popup';
	popupId: any;
	popupData: any;
	secId: any;
	pageSett: any;
	popupSlug: any;
	varId: any;
	isExtendable: boolean = false; // Blank popup

	constructor(public dialogRef: MatDialogRef<ThemePopupsComponent>, private vcRef: ViewContainerRef, public rcServ : RenderComponentServ, private loader: LoaderServ, public utilServ: UtilServ) { }

	ngOnInit(): void {
		this.popupContainerId = this.popupContainerId+'-'+this.popupId;
		this.loaderId = this.loaderId+'-'+this.popupId;
		if(this.popupData){
			this.buildPopup();
		}
	}
	/**
	 * Build popup
	 */
	public buildPopup():any {
		this.loader.show(this.loaderId);
		if(this.popupData && this.popupData.added_sections && (this.popupData.added_sections).length > 0) {
			let timeDuration:number = 0;
			if(this.popupData.column_sections && (this.popupData.column_sections).length > 0){
				timeDuration = 100;
				this.popupData.added_sections = (this.popupData.added_sections).filter( (el: any) => !(this.popupData.column_sections).includes( el ) );
			}
			this.pageSett = this.popupData.section_settings;
			this.rcServ.setPopupData(this.popupId, this.popupData);
			if(this.popupData.settings){
				this.isExtendable = this.popupData.settings.is_extendable;
				if(this.isExtendable){
					this.utilServ.popupPosition(this.popupId, this.popupData.settings, this.dialogRef);
				}
			}
			setTimeout(() => {
				// Render component
				this.createDynamicComponent();
			},timeDuration);
		}
		this.loader.hide(this.loaderId);
	}
	/**
	 * Loop through all sections of the page and popups
	 */
	private createDynamicComponent() {
		let i = 0;
		// Check sections length on the page
		if(this.popupData && this.popupData.added_sections && (this.popupData.added_sections).length > 0) {
			// Loop through all sections of the popup
			this.vcRef.clear();
			this.removeSections();
			for(let secId of this.popupData.added_sections) {
				if(this.pageSett[secId][this.rcServ.statusType]){
					this.secId = secId;
					this.varId = this.pageSett[secId]['variation_id'];
					i = i + 1;
					// Add section on the popup
					this.createComponent(secId, i);
				}
			}
		}
	}
	/**
	 * Load components using "view reference"
	 * @param secId Id of section which we want to add on the page
	 * @param i index, so we can create unique id of wrapper for each section
	 */
	private createComponent(secId: string, i: number,) {
		// Get section slug so we can load the component
		let secSlug : string = this.pageSett[secId].slug;
		if(secSlug){
			if(this.isExtendable){
				this.rcServ.renderComponent(secId, secSlug, this.vcRef, this.popupId);
			} else {
				this.rcServ.renderComponent(secId, secSlug, this.vcRef, this.popupId, this.dialogRef);
			}
			let div = document.createElement('div');
			div.setAttribute('class', 'bk-element-container position-relative');
			div.setAttribute('id', 'component-' + i);
			div.appendChild(this.rcServ.componentRef.location.nativeElement);
			let popupElem: any = document.getElementById(this.popupContainerId);
			if(popupElem){
				popupElem?.appendChild(div);
			}
			setTimeout(() => {
				let popupContainer:any = document.getElementById(secId);
				if(popupContainer){
					let matContainer = popupContainer.closest(".mat-dialog-container");
					if(matContainer){
						let computedStyle = window.getComputedStyle(popupContainer, null);
						if(computedStyle){
							if(computedStyle.backgroundColor && computedStyle.backgroundColor !== ''){
								matContainer.style.backgroundColor = computedStyle.backgroundColor;
							}
						}
					}
				}
			})
		}
	}
	/**
	 * Remove the previous sections child
	 */
	private removeSections(){
		let contId: any = document.getElementById(this.popupContainerId);
		var paras: any;
		if(contId){
			paras = contId.getElementsByClassName('bk-element-container');
		}
		if(paras && paras.length > 0){
			while (paras[0]) {
				paras[0].parentNode.removeChild(paras[0]);
			}
		}
	}
}