import { Component, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
// Services
import { NgOnDestroy, InitServ, UtilServ, SectionServ, RenderComponentServ, CookieServ,StyleServ } from '../../../Services';
// Constants
import { IS_TAB } from '../../../Constants';
@Component({
	selector: 'bk-footer',
	templateUrl: './Footer.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class FooterComponent implements OnInit {
	// Variables
	layout: any = this.initServ.headerFooter; // App header/footer
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	siteData: any = this.initServ.siteData; // App site settings
	variationClass: any = {
		bk_footer_V1 : "tjs-footer--v1",
		bk_footer_V2 : "tjs-footer--v2",
		bk_footer_V3 : "tjs-footer--v3"
	}
	secId: any;
	sett: any;
	varId: any;
	style: any;
	// Section fields
	section: any = {
		logo: null,
		menu: null,
		media: null,
		social_icon: null,
		phone: null,
		cr_text: null,
		cookie_content: null,
		apply_now_btn: null,
		booknow_btn: null
	}
	pageSett: any;
	pageId: any;
	buildFooter: boolean = true;
	currentUser: any;
	disabledSec: any = {
		logo: true,
		social_icon: true,
		phone: true,
		cr_text: true,
		cookie_content: true
	}
	menus: any;
	poweredByLink: string = '';
	cookieBar: boolean = true;
	footerMenu: any;
	menuContent: any;
	widthType: string = IS_TAB ? 'mobile_width' : 'width';
	// heightType: string = IS_TAB ? 'height_width' : 'height';
	constructor(public initServ: InitServ, public utilServ: UtilServ, @Self() private destroy: NgOnDestroy, public secServ: SectionServ, public rcServ: RenderComponentServ, private cookieServ: CookieServ,public styleServ : StyleServ) {
		// Current login user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
		// PoweredByLink
		this.poweredByLink = this.utilServ.poweredByLink();
	}

	ngOnInit(): void {
		// Cookie bar
		if(this.cookieServ.getCookie('disableCookies')){
			this.cookieBar = false;
		}
		// Page settings
		this.utilServ.pageSett.pipe(takeUntil(this.destroy)).subscribe((sett: any) => {
			if(sett && sett.status && JSON.stringify(sett.data) != JSON.stringify(this.pageSett)){
				this.pageSett = sett.data;
				this.buildFooter = false;
				this.buildSection();
				// Set default value for mobile width of logo
				if(this.sett && this.sett[this.section?.logo?.media_id] && !(this.sett[this.section?.logo?.media_id]).hasOwnProperty('mobile_width')){
					this.sett[this.section?.logo?.media_id]['mobile_width'] = this.sett[this.section?.logo?.media_id]['width'];
				}
				// Set default value for mobile width & height of logo
				// if(this.sett[this.section?.logo?.media_id]){
				// 	if(!(this.sett[this.section?.logo?.media_id]).hasOwnProperty('mobile_width')){
				// 		this.sett[this.section?.logo?.media_id]['mobile_width'] = this.sett[this.section?.logo?.media_id]['width'];
				// 	}
				// 	let imgHeight: any = this.utilServ.getImgHeight(this.sett, this.section?.logo?.media_id);
				// 	if(!(this.sett[this.section?.logo?.media_id]).hasOwnProperty('height')){
				// 		this.sett[this.section?.logo?.media_id]['height'] =  (imgHeight && imgHeight > 128) ? 128 : null;
				// 	}
				// 	if(!(this.sett[this.section?.logo?.media_id]).hasOwnProperty('mobile_height')){
				// 		this.sett[this.section?.logo?.media_id]['mobile_height'] = (imgHeight && imgHeight > 128) ? 128 : null;
				// 	}
				// }
			}
		});
	}
	/**
	 * Build the menu section
	 */
	private async buildSection() {
		if(this.layout && this.layout.site_data){
			this.secId = this.layout.footer_id;
			this.sett = this.layout.site_data.section_settings;
			this.secServ.setServData(this.sett, this.layout.site_data.content);
			this.varId = this.sett[this.secId].variation_id;
			this.style = this.layout.site_data.style;
			if(this.layout.menus && this.layout.menus.footer){
				this.footerMenu = this.layout.menus.footer;
			}
			if(this.footerMenu && this.footerMenu.content){
				this.menuContent = this.footerMenu.content;
			}
			let sections = this.layout.site_data.sections;
			// Build section
			let secElem : any = null;
			if(sections){
				secElem = sections[this.secId];
			}
			if(secElem && this.sett && this.sett[this.secId] && this.sett[this.secId][this.rcServ.statusType]){
				for(let key in this.section){
					if(secElem[key]){
						switch (key) {
							case "logo":
							case "phone":
							case "cookie_content":
								this.section[key] =  this.secServ.buildGroup(secElem[key]);
								break;
							case "media":
								this.section[key] =  this.secServ.buildMedia(secElem[key]);
								break;
							case "social_icon":
								this.section[key] =  this.secServ.buildList(secElem[key]);
								break;
							case "cr_text":
								this.section[key] =  this.secServ.buildText(secElem[key]);
								break
							case "apply_now_btn":
							case "booknow_btn":
								this.section[key] =  this.secServ.buildButton(secElem[key]);
								break;
							default:
								this.section[key] = this.secServ.checkElementStatus(secElem[key]) && secElem[key];
								break;
						}
					}
				}
			}
			// Disabled sections
			for(let key in this.section){
				this.disabledBlock(key)
			}
			// App menu
			if(this.footerMenu && this.footerMenu.menu_items && (this.footerMenu.menu_items).length > 0 && this.footerMenu.status && this.section.menu){
				let menuId: any = this.sett[this.section.menu].menu_id;
				if(this.footerMenu._id == menuId){
					this.menus = this.footerMenu.menu_items;
				}
			}
			//Footer style
			this.styleServ.applyPageStyle(this.style,'footer',this.secId,this.section);
			setTimeout(() => {
				this.buildFooter = true;
			}, 0);
		}
	}
	/**
	 * Disabled blocks(logo,social_icon,phone,cr_text,cookie_content )
	 * @param name (logo,social_icon,phone,cr_text,cookie_content )
	 */
	private disabledBlock(name: string): void {
		if(this.section && this.section[name] && this.sett[this.section[name].id] && this.sett[this.section[name].id].disable_on && (this.sett[this.section[name].id].disable_on).length > 0){
			if((this.sett[this.section[name].id].disable_on).includes(this.pageSett.id)){
				this.disabledSec[name] = false;
			} else {
				this.disabledSec[name] = true;
			}
		} else {
			this.disabledSec[name] = true;
		}
	}
	/**
	 * Set the cookie bar cookie value
	 */
	public setCookieForBar(){
		this.cookieBar = false;
		if(!this.cookieServ.getCookie('disableCookies')){
			this.cookieServ.createCookie('disableCookies',1, 365);
		}
	}
}