import { Component, OnInit, Self, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// Services
import { NgOnDestroy, UtilServ, InitServ, CacheService } from '../../Services';

@Component({
	selector: 'bk-industries-popup',
	template: `
	<div class="position-relative">
		<div [id]="secId" class="tjs-popup">
			<!-- popup header -->
			<bk-popup-header [headingId]="section?.title?.id" [heading]="section?.title?.content"></bk-popup-header>
			<!-- popup body -->
			<div class="tjs-popup__content tjs-popup__body tjs-indus-popup">
				<div [id]="section?.listing" class="row justify-content-center" *ngIf="industries && industries?.children && (industries?.children).length > 0">
					<div class="col-12 col-md-4 my-10" *ngFor="let industry of industries.children; trackBy: utilServ.trackByFnIndex">
						<a [routerLink]="['/'+initServ.appDynamicRoutes['booknow'], industry?.state]" class="border card shadow-sm position-relative h-100 text-decoration-none bk-popup-card" (click)="dialogRef.close()">
							<div class="card-body p-15 text-center d-flex justify-content-center align-items-center flex-column">
								<div class="w-100">
									<div>
										<img loading="auto" [src]="utilServ.getImgUrl(industry?.photo_url)" alt="Industry icons" class="mb-20 w-auto img-fluid-disabled tjs-maxheight-50 mw-100" width="50" height="50" (error)="utilServ.handleImgError($event, initServ?.img?.KoalaIcon)"/>
									</div>
									<span class="fw-bold d-block text-dark tjs-capitalize w-100 text-wrap text--color">{{industry?.name | translate}}</span>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})

export class IndustriesPopupComponent implements OnInit {
	// Variables
	industries: any;
	loaderId: string = 'industries-popup-loader';
	slug: string = 'select_industry';
	secId: string = '';
	section: any = { title: null, listing: null };

	constructor(public dialogRef: MatDialogRef<IndustriesPopupComponent>, public utilServ: UtilServ, public initServ: InitServ, @Self() public destroy: NgOnDestroy, private cacheServ: CacheService) { }

	ngOnInit(): void {
		this.industries = this.utilServ.multiIndustries();
		// build popup section
		this.buildSectionData();
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
		});
	}
}
