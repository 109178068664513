

<div [id]="section?.multi_step_header?.id" *ngIf="section?.multi_step_header" class="multistep__wrapper bg-white bk-form-multistep-wrap  multistep__total-wrapper" [ngClass]="{'multistep-logo-hidden':!section?.multi_step_header?.logo, 'main-header--enable': utilServ.multiStepHeader, 'recurr-summary-enabled': (selectedFrequency?.form_frequency_data?.occurence_time && selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings != 'first_booking' && BKFrm?.value?.coupon?.apply_on_bookings != 'all_recurring')}">
	<div class="multistep-bglayer vh-100 position-absolute top-0 end-0 bottom-0 start-0 pe-none bg-white bk-form-multistep-wrap" [id]="section?.multi_step_header?.id"></div>
	<div id="multistep-header-id" class="multistep__header d-flex flex-column flex-lg-row justify-content-between shadow-sm  tjs-body-bg">
		<!-- Mobile responsive -->
		<div [id]="section?.multi_step_header?.logo" class="multistep__responsive--logo bg-secondary d-flex justify-content-between align-items-center px-15 tjs-pointer d-lg-none">
			<div class="fw-bold" *ngIf="section?.multi_step_header?.logo && utilServ?.siteLogo">
				<ng-container *ngTemplateOutlet="logoTemp"></ng-container>
			</div>
			<div class="multistep__responsive--arrow d-lg-none ps-10 d-flex justify-content-center align-items-center tjs-pointer bk-arrow ms-auto" (click)="addBkngServ.respSummary()">
				<i class="tjsicon-down-arrow text-white"></i>
			</div>
		</div>
		<!-- Booking summary -->
		<bk-multi-step-form-header class="d-flex w-100"
			*ngIf="settings"
			[bookingType]="bookingType"
			[isStepOneValid]="isStepOneValid"
			[prefilledData]="prefilledData"
			[BKFrm]="BKFrm"
			[parentSection]="section"
			[section]="section?.summary"
			[pageSett]="pageSett"
			[settings]="settings"
			[selectedServiceType]="selectedServiceType"
			[selectedFrequency]="selectedFrequency"
			[selectedLocation]="selectedLocation"
			[selectedPricingParams]="formId == 1 && BKFrm.value['pricing_parameter']"
			[selectedItems]="(formId == 2 || formId == 3) && BKFrm.value['items']"
			[selectedAreaParams]="formId == 4 && BKFrm.value['area_parameter']"
			[selectedExtras]="BKFrm.value['extras']"
			[selectedExcludes]="formId == 1 && BKFrm?.value['is_partial_cleaning'] && BKFrm.value['partial_cleaning']"
			[adjustedTimeHours]="priceLocalVar?.adjustedTimeHours"
			[adjustedTimeMin]="priceLocalVar?.adjustedTimeMin"
			[bookingTimeHours]="priceLocalVar?.bookingTimeHours"
			[bookingTimeMin]="priceLocalVar?.bookingTimeMin"
			[selectedAddr]="BKFrm?.value['address']"
			[isServiceFeeTaxable]="priceLocalVar?.isServiceFeeTaxable"
			[displayServicePrice]="priceLocalVar?.displayServicePrice"
			[excludeExpeditedAmountValue]="priceLocalVar?.excludeExpeditedAmountValue"
			[displayFrequencyDiscount]="priceLocalVar?.displayFrequencyDiscount"
			[displayCouponDiscount]="priceLocalVar?.displayCouponDiscount"
			[displayDiscountedAmount]="priceLocalVar?.displayDiscountedAmount"
			[bookingTaxRate]="priceLocalVar?.bookingTaxRate"
			[exemptSalesTaxValue]="priceLocalVar?.exemptSalesTaxValue"
			[bookingTaxType]="priceLocalVar?.bookingTaxType"
			[displaySaleTax]="priceLocalVar?.displaySaleTax"
			[displayTipsAmount]="priceLocalVar?.displayTipsAmount"
			[excludeServiceFeeValue]="priceLocalVar?.excludeServiceFeeValue"
			[displayServiceFee]="priceLocalVar?.displayServiceFee"
			[displayTotal]="priceLocalVar?.displayTotal"
			[firstRecAptFinalAmount]="priceLocalVar?.firstRecAptFinalAmount"
			[afterNthAptFinalAmount]="priceLocalVar?.afterNthAptFinalAmount"
			[displaySpotDiscount]="priceLocalVar?.displaySpotDiscount"
			[displayGiftCardAmount]="priceLocalVar?.displayGiftCardAmount"
			[displayReferralDiscount]="priceLocalVar?.displayReferralDiscount"
			[displayFinalAmount]="priceLocalVar?.displayFinalAmount"
			[isStepOneAvail]="isStepOneAvail"
			[isStepAvailFormFive]="isStepAvailFormFive"
			[activeStep]="activeStep"
			(moveToStep)="moveToStep($event)"
			[formMetaData]="formMetaData"
			[isMultiStepForm]="true"
			[settingsObj]="settingsObj"
		></bk-multi-step-form-header>
	</div>
	<!-- Content -->
	<div class="multistep__content">
		<div class="multistep__content--main">
			<div *ngIf="settings && settings?.form_data && settings?.form_data?.mode && settings?.form_data?.status; else underMaintenance">
				<form class="bk-form" [formGroup] = "BKFrm">
					<ng-container *ngIf="multistepFormSteps && multistepFormSteps.length > 0">
						<ng-container *ngFor="let step of multistepFormSteps;let i = index;trackBy: utilServ.trackByFnIndex">
							<div class="step" [ngClass]="{'d-none': i != activeStep}">
								<ng-container [ngSwitch]="step">
									<!-- Step 1 -->
									<div class="pt-40 p-md-50 pt-lg-50 tjs-animate-fadedown" *ngSwitchCase="'step_one'">
										<div class="container" *ngIf="this.multiStepFormSections[step] && (this.multiStepFormSections[step]).length > 0">
											<div class="col-12 col-md-10 mx-auto">
												<!-- Heading -->
												<div class="text-center mb-50" *ngIf="section?.multi_step_one && ((multiStepsHeadingStatus?.multi_step_one?.title && section?.multi_step_one?.title) || (multiStepsHeadingStatus?.multi_step_one?.desc && section?.multi_step_one?.desc))">
													<h3 class="display-5 fw-bold mb-0 bk-form-heading" *ngIf="multiStepsHeadingStatus?.multi_step_one?.title && section?.multi_step_one?.title" [id]="section?.multi_step_one?.title_id" [innerHTML]="section?.multi_step_one?.title | safeHtml"></h3>
													<div class="mt-20" *ngIf="multiStepsHeadingStatus?.multi_step_one?.desc && section?.multi_step_one?.desc">
														<p class="fs-20 mb-0 bk-form-desc" [id]="section?.multi_step_one?.desc_id" [innerHTML]="section?.multi_step_one?.desc | safeHtml"></p>
													</div>
												</div>
												<div class="d-flex flex-column">
													<!-- Sections -->
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('location')">
														<ng-container *ngTemplateOutlet="locationTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="activeStep == 0 && (this.multiStepFormSections[step]).includes('email')">
														<ng-container *ngTemplateOutlet="emailTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('service')">
														<ng-container *ngTemplateOutlet="serviceTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('frequency')">
														<ng-container *ngTemplateOutlet="frequencyTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="activeStep == 0 && (this.multiStepFormSections[step]).includes('customer_details')">
														<ng-container *ngTemplateOutlet="customerDetailsTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('address_details')">
														<ng-container *ngTemplateOutlet="addressTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('key_info')">
														<ng-container *ngTemplateOutlet="keyInfoTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('special_notes')">
														<ng-container *ngTemplateOutlet="specialNotesTemp"></ng-container>
													</ng-container>
													<!-- <ng-container *ngIf="activeStep == 0">
														<ng-container *ngIf="(this.multiStepFormSections[step]).includes('custom_fields_step_one')">
															<ng-container *ngTemplateOutlet="customFieldsOneTemp"></ng-container>
														</ng-container>
														<ng-container *ngIf="(this.multiStepFormSections[step]).includes('custom_fields_step_two')">
															<ng-container *ngTemplateOutlet="customFieldsTwoTemp"></ng-container>
														</ng-container>
													</ng-container> -->
													<!-- Custom field -->
													<ng-container *ngTemplateOutlet="customFieldsTemp; context:{step:'step_one'}"></ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('tip_parking')">
														<ng-container *ngTemplateOutlet="tipParkingTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('discounts')">
														<ng-container *ngTemplateOutlet="discountTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('payment_info')">
														<ng-container *ngTemplateOutlet="paymentInfoTemp"></ng-container>
													</ng-container>
												</div>
											</div>
										</div>
									</div>
									<!-- Step 2 -->
									<div class="pt-40 p-md-50 pt-lg-50 tjs-animate-fadedown" *ngSwitchCase="'step_two'">
										<div class="container" *ngIf="this.multiStepFormSections[step] && (this.multiStepFormSections[step]).length > 0">
											<div class="col-12 col-md-10 mx-auto">
												<!-- Heading -->
												<div class="text-center mb-50" *ngIf="section?.multi_step_two && ((multiStepsHeadingStatus?.multi_step_two?.title && section?.multi_step_two?.title) || (multiStepsHeadingStatus?.multi_step_two?.desc && section?.multi_step_two?.desc))">
													<h3 class="display-5 fw-bold mb-0 bk-form-heading" *ngIf="multiStepsHeadingStatus?.multi_step_two?.title && section?.multi_step_two?.title" [id]="section?.multi_step_two?.title_id" [innerHTML]="section?.multi_step_two?.title | safeHtml"></h3>
													<div class="mt-20" *ngIf="multiStepsHeadingStatus?.multi_step_two?.desc && section?.multi_step_two?.desc">
														<p class="fs-20 mb-0 bk-form-desc" [id]="section?.multi_step_two?.desc_id" [innerHTML]="section?.multi_step_two?.desc | safeHtml"></p>
													</div>
												</div>
												<div class="d-flex flex-column">
													<!-- Sections -->
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('pricing_parameter')">
														<ng-container *ngTemplateOutlet="pricingParamTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('extras')">
														<ng-container *ngTemplateOutlet="extrasTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('frequency')">
														<ng-container *ngTemplateOutlet="frequencyTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(activeStep == 1 || !multistepFormSteps.includes('step_one')) && (this.multiStepFormSections[step]).includes('email')">
														<ng-container *ngTemplateOutlet="emailTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="activeStep == 1 && (this.multiStepFormSections[step]).includes('customer_details')">
														<ng-container *ngTemplateOutlet="customerDetailsTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('address_details')">
														<ng-container *ngTemplateOutlet="addressTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('key_info')">
														<ng-container *ngTemplateOutlet="keyInfoTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('special_notes')">
														<ng-container *ngTemplateOutlet="specialNotesTemp"></ng-container>
													</ng-container>
													<!-- <ng-container *ngIf="activeStep == 1 || (!isStepOneAvail && activeStep == 0)">
														<ng-container *ngIf="(this.multiStepFormSections[step]).includes('custom_fields_step_one')">
															<ng-container *ngTemplateOutlet="customFieldsOneTemp"></ng-container>
														</ng-container>
														<ng-container *ngIf="(this.multiStepFormSections[step]).includes('custom_fields_step_two')">
															<ng-container *ngTemplateOutlet="customFieldsTwoTemp"></ng-container>
														</ng-container>
													</ng-container> -->
													<!-- Custom field -->
													<ng-container *ngTemplateOutlet="customFieldsTemp; context:{step:'step_two'}"></ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('tip_parking')">
														<ng-container *ngTemplateOutlet="tipParkingTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('discounts')">
														<ng-container *ngTemplateOutlet="discountTemp"></ng-container>
													</ng-container>
													<ng-container *ngIf="(this.multiStepFormSections[step]).includes('payment_info')">
														<ng-container *ngTemplateOutlet="paymentInfoTemp"></ng-container>
													</ng-container>
												</div>
											</div>
										</div>
									</div>
									<!-- Step 3 -->
									<div class="px-15 pt-40 p-md-50 pt-lg-50 tjs-animate-fadedown position-relative" *ngSwitchCase="'step_three'">
										<!-- Heading -->
										<div class="text-center mb-50" *ngIf="section?.multi_step_three && ((multiStepsHeadingStatus?.multi_step_three?.title && section?.multi_step_three?.title) || (multiStepsHeadingStatus?.multi_step_three?.desc && section?.multi_step_three?.desc))">
											<h3 class="display-5 fw-bold mb-0 bk-form-heading" *ngIf="multiStepsHeadingStatus?.multi_step_three?.title && section?.multi_step_three?.title" [id]="section?.multi_step_three?.title_id" [innerHTML]="section?.multi_step_three?.title | safeHtml"></h3>
											<div class="mt-20" *ngIf="multiStepsHeadingStatus?.multi_step_three?.desc && section?.multi_step_three?.desc">
												<p class="fs-20 mb-0 bk-form-desc" [id]="section?.multi_step_three?.desc_id" [innerHTML]="section?.multi_step_three?.desc | safeHtml"></p>
											</div>
										</div>
										<!-- Service provider -->
										<ng-container *ngIf="(!isStepOneAvail && activeStep == 1 && formId != 5) || (isStepOneAvail && activeStep == 2 && formId != 5) || (!isStepAvailFormFive && activeStep == 1 && formId == 5) || (isStepAvailFormFive && activeStep == 2 && formId == 5) || (BKFrm?.value?.arrival_time)">
											<div class="col-12 col-lg-12 col-xl-9 mx-auto">
												<ng-container *ngIf="!isQuote || (isQuote && prefilledData)">
													<bk-multi-service-provider
														[isQuote]="isQuote"
														[formLayout] = "formLayout"
														[isStepOneAvail]="formId == 5 ? isStepAvailFormFive : isStepOneAvail"
														[activeStep]="activeStep"
														[ServiceProviderControl]="BKFrm"
														[prefilledData]="prefilledData"
														[pageSett]="pageSett"
														[style.order]="sectionOrder.service_provider"
														[section]="section?.service_providers"
														[calSection]="section?.calendar"
														[settings]="settings"
														[dayDiscountApplyToAll]="dayDiscountApplyToAll"
														[dayDiscountBookings]="dayDiscountBookings"
														[dateDiscountsArray]="dateDiscountsArray"
														[dayDiscountsArray]="dayDiscountsArray"
														[dayDiscounts]="dayDiscounts"
														(onBookingDateChange)="onBookingDateChange()"
														(onSpotChangeCallback)="onSpotChange()"
														(resetPrvdrOverriddenTime)="resetPrvdrOverriddenTime()"
														>
													</bk-multi-service-provider>
												</ng-container>
											</div>
										</ng-container>
									</div>
									<!-- Step 4 -->
									<div class="multistep__last-step tjs-animate-fadedown" *ngSwitchCase="'step_four'">
										<div class="container-fluid">
											<div class="row">
												<div class="col-12 col-md-12 col-lg-7 col-xl-7">
													<div class="pt-40 pb-30 p-md-50 pt-lg-50">
														<!-- Heading -->
														<div class="text-center mb-50" *ngIf="section?.multi_step_four && ((multiStepsHeadingStatus?.multi_step_four?.title && section?.multi_step_four?.title) || (multiStepsHeadingStatus?.multi_step_four?.desc && section?.multi_step_four?.desc))">
															<h3 class="display-5 fw-bold mb-0 bk-form-heading" *ngIf="multiStepsHeadingStatus?.multi_step_four?.title && section?.multi_step_four?.title" [id]="section?.multi_step_four?.title_id" [innerHTML]="section?.multi_step_four?.title | safeHtml"></h3>
															<div class="mt-20" *ngIf="multiStepsHeadingStatus?.multi_step_four?.desc && section?.multi_step_four?.desc">
																<p class="fs-20 mb-0 bk-form-desc" [id]="section?.multi_step_four?.desc_id" [innerHTML]="section?.multi_step_four?.desc | safeHtml"></p>
															</div>
														</div>
														<div class="d-flex flex-column">
															<!-- Sections -->
															<ng-container *ngIf="(this.multiStepFormSections[step]).includes('frequency')">
																<ng-container *ngTemplateOutlet="frequencyTemp"></ng-container>
															</ng-container>
															<ng-container *ngIf="((BKFrm?.value?.form_id != 5 && activeStep == 3) || (BKFrm?.value?.form_id == 5 && isStepAvailFormFive && activeStep == 3) || (BKFrm?.value?.form_id == 5 && !isStepAvailFormFive && activeStep == 2) || (!isStepOneAvail && activeStep == 2)) && (this.multiStepFormSections[step]).includes('customer_details')">
																<ng-container *ngTemplateOutlet="customerDetailsTemp"></ng-container>
															</ng-container>
															<ng-container *ngIf="(this.multiStepFormSections[step]).includes('address_details')">
																<ng-container *ngTemplateOutlet="addressTemp"></ng-container>
															</ng-container>
															<ng-container *ngIf="(this.multiStepFormSections[step]).includes('key_info')">
																<ng-container *ngTemplateOutlet="keyInfoTemp"></ng-container>
															</ng-container>
															<ng-container *ngIf="(this.multiStepFormSections[step]).includes('special_notes')">
																<ng-container *ngTemplateOutlet="specialNotesTemp"></ng-container>
															</ng-container>
															<!-- <ng-container *ngIf="((BKFrm?.value?.form_id != 5 && activeStep == 3) || (BKFrm?.value?.form_id == 5 && isStepAvailFormFive && activeStep == 3) || (BKFrm?.value?.form_id == 5 && !isStepAvailFormFive && activeStep == 2) || (!isStepOneAvail && activeStep == 2))">
																<ng-container *ngIf="(this.multiStepFormSections[step]).includes('custom_fields_step_one')">
																	<ng-container *ngTemplateOutlet="customFieldsOneTemp"></ng-container>
																</ng-container>
																<ng-container *ngIf="(this.multiStepFormSections[step]).includes('custom_fields_step_two')">
																	<ng-container *ngTemplateOutlet="customFieldsTwoTemp"></ng-container>
																</ng-container>
															</ng-container> -->
															<!-- Custom field -->
															<ng-container *ngTemplateOutlet="customFieldsTemp; context:{step:'step_four'}"></ng-container>
															<ng-container *ngIf="(this.multiStepFormSections[step]).includes('tip_parking')">
																<ng-container *ngTemplateOutlet="tipParkingTemp"></ng-container>
															</ng-container>
															<ng-container *ngIf="(this.multiStepFormSections[step]).includes('discounts')">
																<ng-container *ngTemplateOutlet="discountTemp"></ng-container>
															</ng-container>
															<ng-container *ngIf="(this.multiStepFormSections[step]).includes('payment_info')">
																<ng-container *ngTemplateOutlet="paymentInfoTemp"></ng-container>
															</ng-container>
														</div>
														<!-- Buttons -->
														<bk-common-buttons *ngIf="activeStep == ((multistepFormSteps).length - 1)"
															[isMultiStepForm]="true"
															[bookingType]="bookingType"
															[settings]="settings"
															[pageSett]="pageSett"
															[BKFrm]="BKFrm"
															[section]="section"
															[acceptTcStatus]="acceptTcStatus"
															[isAcceptTcValid]="isAcceptTcValid"
															(acceptTcChange)="isAcceptTcValid = true"
															(submitBKFrm)="submitSingleFrm()">
														</bk-common-buttons>
													</div>
												</div>
												<!-- Summary -->
												<div class="col-12 col-md-12 col-lg-5 col-xl-5 tjs-body-bg">
													<div class="px-md-15 py-40">
														<bk-sidebar *ngIf="((BKFrm?.value?.form_id != 5 && activeStep == 3) || (BKFrm?.value?.form_id == 5 && isStepAvailFormFive && activeStep == 3) || (BKFrm?.value?.form_id == 5 && !isStepAvailFormFive && activeStep == 2) || ((!isStepOneAvail && formId != 5) && activeStep == 2))"
															[isMultiStepForm]="true"
															[sidebarSequence]="summarySequence"
															[sidebarSecOrder]="sidebarSecOrder"
															[BKFrm]="BKFrm"
															[section]="section"
															[pageSett]="pageSett"
															[bookingType]="bookingType"
															[settings]="settings"
															[industryId]="industryId"
															[formId]="formId"
															[formSett]="formSett"
															[couponData]="couponData"
															[selectedServiceType]="selectedServiceType"
															[selectedFrequency]="selectedFrequency"
															[selectedPricingParams]="formId == 1 && BKFrm.value['pricing_parameter']"
															[selectedItems]="(formId == 2 || formId == 3) && BKFrm.value['items']"
															[selectedAreaParams]="formId == 4 && BKFrm.value['area_parameter']"
															[selectedExtras]="BKFrm.value['extras']"
															[selectedExcludes]="formId == 1 && BKFrm?.value['is_partial_cleaning'] && BKFrm.value['partial_cleaning']"
															[adjustedTimeHours]="priceLocalVar?.adjustedTimeHours"
															[adjustedTimeMin]="priceLocalVar?.adjustedTimeMin"
															[bookingTimeHours]="priceLocalVar?.bookingTimeHours"
															[bookingTimeMin]="priceLocalVar?.bookingTimeMin"
															[selectedAddr]="BKFrm?.value['address']"
															[isServiceFeeTaxable]="priceLocalVar?.isServiceFeeTaxable"
															[beforePriceableFieldsPrice]="BKFrm?.value['before_priceable_fields_price']"
															[includeInFreqFieldsPrice]="BKFrm?.value['include_in_freq_fields_price']"
															[exemptFromFreqFieldsPrice]="BKFrm?.value['exempt_from_freq_fields_price']"
															[nonTaxableFieldsPrice]="BKFrm?.value['non_taxable_fields_price']"
															[afterDiscTotalFieldsPrice]="BKFrm?.value['after_disc_total_fields_price']"
															[displayServicePrice]="priceLocalVar?.displayServicePrice"
															[excludeExpeditedAmountValue]="priceLocalVar?.excludeExpeditedAmountValue"
															[displayFrequencyDiscount]="priceLocalVar?.displayFrequencyDiscount"
															[displayCouponDiscount]="priceLocalVar?.displayCouponDiscount"
															[displayDiscountedAmount]="priceLocalVar?.displayDiscountedAmount"
															[bookingTaxRate]="priceLocalVar?.bookingTaxRate"
															[exemptSalesTaxValue]="priceLocalVar?.exemptSalesTaxValue"
															[bookingTaxType]="priceLocalVar?.bookingTaxType"
															[displaySaleTax]="priceLocalVar?.displaySaleTax"
															[displayTipsAmount]="priceLocalVar?.displayTipsAmount"
															[excludeServiceFeeValue]="priceLocalVar?.excludeServiceFeeValue"
															[displayServiceFee]="priceLocalVar?.displayServiceFee"
															[displayTotal]="priceLocalVar?.displayTotal"
															[firstRecAptFinalAmount]="priceLocalVar?.firstRecAptFinalAmount"
															[afterNthAptFinalAmount]="priceLocalVar?.afterNthAptFinalAmount"
															[displaySpotDiscount]="priceLocalVar?.displaySpotDiscount"
															[displayGiftCardAmount]="priceLocalVar?.displayGiftCardAmount"
															[displayReferralDiscount]="priceLocalVar?.displayReferralDiscount"
															[displayFinalAmount]="priceLocalVar?.displayFinalAmount"
															[formMetaData]="formMetaData"
															[settingsObj]="settingsObj"
															[formLayout]="formLayout"
														>
														</bk-sidebar>
													</div>
												</div>
											</div>
										</div>
									</div>
								</ng-container>
								<!-- Buttons -->
								<div class="multistep__footer text-center pb-25 w-100 bottom-0 start-0 end-0 bg-transparent pe-none d-inline-flex justify-content-center px-10 flex-wrap" [class.position-fixed]="!(formSett && formSett?.next_previous_button_scroll && formSett?.next_previous_button_scroll == 'yes')">
									<!-- Back btn -->
									<ng-container *ngIf="activeStep == 0 && isMultiIndsFrm && section?.multi_prev_btn?.content; else otherBackBtn">
										<bk-navigate [secSett]="section?.multi_prev_btn" [innerHTML]="section?.multi_prev_btn?.content" customClass="btn btn-lg btn-danger fs-20 border-transparent" essentialClass="m-5 bk-custom-back-btn pe-auto" (callback)="backToFormSelection()"></bk-navigate>
									</ng-container>
									<ng-template #otherBackBtn>
										<bk-navigate *ngIf="activeStep != 0 && (activeStep != ((multistepFormSteps).length - 1))" [secSett]="section?.multi_prev_btn" [innerHTML]="((((formId && formId != 5 && activeStep == 2) || (formId == 5 && isStepAvailFormFive && activeStep == 2) || (formId == 5 && !isStepAvailFormFive && activeStep == 1) || ((!isStepOneAvail && formId != 5) && activeStep == 1)) && BKFrm.controls['arrival_date'].value) ? 'Back To Calendar' : section?.multi_prev_btn?.content) | translate" customClass="btn btn-lg btn-danger fs-20 border-transparent" essentialClass="bk-custom-back-btn pe-auto m-5" [isBtnEvent]="true" (callback)="prevStep($event)"></bk-navigate>
									</ng-template>
									<!-- Next btn -->
									<ng-container *ngIf="((isStepOneAvail && formId != 5 && activeStep == 2) || (formId == 5 && isStepAvailFormFive && activeStep == 2) || (formId == 5 && !isStepAvailFormFive && activeStep == 1) || ((!isStepOneAvail && formId != 5) && activeStep == 1)) && BKFrm.controls['arrival_date'].value; else commonNext">
										<bk-navigate *ngIf="section?.multi_next_btn?.content"
											[secSett]="section?.multi_next_btn"
											[innerHTML]="section?.multi_next_btn?.content"
											[isBtnEvent]="true"
											customClass="btn btn-lg fs-20 btn-primary border-transparent"
											essentialClass="m-5 pe-auto bk-custom-nxt-btn"
											(callback)="nextStep($event, step)"
											[btnDisabled]="(BKFrm.controls['arrival_date'].value && BKFrm.controls['arrival_time'].value) ? false : true">
										</bk-navigate>
									</ng-container>
									<ng-template #commonNext>
										<bk-navigate *ngIf="section?.multi_next_btn && i < ((multistepFormSteps).length - 1)"
											[secSett]="section?.multi_next_btn"
											[innerHTML]="section?.multi_next_btn?.content"
											customClass="btn btn-lg btn-primary fs-20 border-transparent"
											essentialClass="pe-auto bk-custom-nxt-btn m-5"
											(callback)="nextStep($event, step)"
											[isBtnEvent]="true"
											[btnDisabled]="isButtonDisabled ? true : false"></bk-navigate>
									</ng-template>
								</div>
							</div>
						</ng-container>
					</ng-container>
				</form>
			</div>
			<ng-template #underMaintenance>
				<div class="p-10 p-md-30 pt-60">
					<div class="card ">
						<div class="card-body text-center">
							<h3 class="mb-0" *ngIf="settings">{{initServ?.appStr?.text?.undergoingMaintenance | translate}}</h3>
						</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>
<!-- Site logo -->
<ng-template #logoTemp>
	<ng-container *ngIf="(utilServ?.siteLogo?.media && (utilServ?.siteLogo?.media).length > 0); else defaultLogo">
		<bk-navigate essentialClass="bk-logo text-capitalize px-0" [secSett]="utilServ?.siteLogo?.link" innerHTML="<img loading='lazy' id='{{utilServ?.siteLogo?.media_id}}' width='' height='' class='multistep__responsive--logo-image img-fluid' src='{{utilServ?.siteLogo?.media[0]}}' alt='Your Logo' />"></bk-navigate>
	</ng-container>
	<ng-template #defaultLogo>
		<bk-navigate essentialClass="text-white fw-bold bk-logo text-capitalize p-0" [secSett]="utilServ?.siteLogo?.link" innerHTML="{{initServ?.appStr?.text?.yourLogo | translate}}" [isText]="true"></bk-navigate>
	</ng-template>
</ng-template>

<!-- Location template -->
<ng-template #locationTemp>
	<!--Location select list-->
	<div class="position-relative" *ngIf="locationLayout != 'no_location'" [style.order]="sectionOrder.location">
		<!-- we used the buildAddressStatus sectin to build location section,
		because this section dependent on prefilled data load in case of quote,
		so to solve this section, we used this status.-->
		<ng-container *ngIf="buildAddressStatus">
			<bk-location *ngIf="locationLayout == 'name_based'; else zipcode"
				[isQuoteEditable]="isQuoteEditable"
				[isMultiStepForm]="true"
				[pageSett]="pageSett"
				[bookingType]="bookingType"
				[section]="section?.location"
				[locationForm]="BKFrm"
				[settings]="settings"
				(setLocation)="setLocation($event)">
			</bk-location>
			<!-- Zipcode based -->
			<ng-template #zipcode>
				<bk-multi-zipcode
					[isQuoteEditable]="isQuoteEditable"
					[prefilledData]="prefilledData"
					[bookingType]="bookingType"
					[pageSett]="pageSett"
					[section]="section?.location"
					[zipcodeForm]="BKFrm"
					[settings]="settings"
					[refreshLocComp]="refreshLocComp"
					(locationChange)="resetFormControl('location')"
					(locationFocusOut)="checkZipcodeAval($event)"
					(merchantLocFocusOut)="checkMerchantZipcodeAval($event)">
				</bk-multi-zipcode>
			</ng-template>
		</ng-container>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderIds?.location"></bk-app-loader>
	</div>
</ng-template>
<!-- Customer email template -->
<ng-template #emailTemp>
	<bk-customer-email *ngIf="emailSecStatus" [style.order]="sectionOrder.email" #emailComp
		[settings]="settings"
		[isMultiStepForm]="true"
		[pageSett]="pageSett"
		[currentUser]="currentUser"
		[customerForm]="BKFrm"
		[section]="section?.email"
		[formLayout]="formLayout"
		(loginExistingUser)="loginUser(false)"
		(custmrChng)="custmrChng()"
	>
	</bk-customer-email>
</ng-template>
<!--Service Category template-->
<ng-template #serviceTemp>
	<div class="position-relative" [style.order]="sectionOrder.service">
		<bk-service-category-btn
			[isQuoteEditable]="isQuoteEditable"
			[bookingType]="bookingType"
			[section]="section?.service"
			[serviceForm]="BKFrm"
			[selectedLocation]="selectedLocation"
			[selectedServiceType]="selectedServiceType"
			[selectedFrequency]="selectedFrequency"
			[settings]="settings"
			[pageSett]="pageSett"
			[availableSett]="availableSett"
			[prefilledData]="prefilledData"
			(serviceChange)="serviceCatChange($event, true)"
			(hourlyServiceChange)="resetFormControl('hourly-service')"
		>
		</bk-service-category-btn>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderIds?.service"></bk-app-loader>
	</div>
</ng-template>
<!-- Frequency template -->
<ng-template #frequencyTemp>
	<div class="position-relative" [style.order]="sectionOrder.frequency">
		<bk-frequency
			[isMultiStepForm]="true"
			[pageSett]="pageSett"
			[isQuoteEditable]="isQuoteEditable"
			[bookingType]="bookingType"
			[section]="section?.frequency"
			[frequencyForm]="BKFrm"
			[selectedLocation]="selectedLocation"
			[selectedServiceType]="selectedServiceType"
			[selectedFrequency]="selectedFrequency"
			[settings]="settings"
			[availableSett]="availableSett"
			[prefilledData]="prefilledData"
			(frequencyChange)="frequencyChange($event, true)" >
		</bk-frequency>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderIds?.frequency"></bk-app-loader>
	</div>
</ng-template>
<!-- Customer email and details template -->
<ng-template #customerDetailsTemp>
	<ng-container *ngIf="BKFrm.controls['uid'].value && !currentUser; else customerDetails">
		<bk-customer-email [style.order]="sectionOrder.email" #emailComp
			[settings]="settings"
			[pageSett]="pageSett"
			[isMultiStepForm]="true"
			[currentUser]="currentUser"
			[customerForm]="BKFrm"
			[section]="section?.email"
			[formLayout]="formLayout"
			(loginExistingUser)="loginUser(false)"
			(custmrChng)="custmrChng()"
		>
		</bk-customer-email>
	</ng-container>
	<ng-template #customerDetails>
		<ng-container *ngIf="buildAddressStatus">
			<bk-customer-details [style.order]="sectionOrder.customer_details" #customerComp
				[isMultiStepForm]="true"
				[isQuote]="isQuote"
				[currentUser]="currentUser"
				[pageSett]="pageSett"
				[settings]="settings"
				[bookingType]="bookingType"
				[zipcode]="zipcodeValue"
				[customerForm]="BKFrm"
				[section]="section"
				[prefilledData]="prefilledData"
				[locationLayout]="locationLayout"
				[locationType]="BKFrm.value['location_type']"
				(loginExistingUser)="loginUser(false)"
				(zipCodeChange)="reCalculateTax($event)"
				(custmrChng)="custmrChng()"
				(custDetailsChng)="custDetailsFocusOut()"
				[selectedLocation]="selectedLocation"
			>
			</bk-customer-details>
		</ng-container>
	</ng-template>
</ng-template>
<!-- Address template -->
<ng-template #addressTemp>
	<ng-container *ngIf="buildAddressStatus">
		<bk-address [style.order]="sectionOrder.address_details" *ngIf="BKFrm.value['location_type'] == 'SA' && (section.customer_details.design_id == 'bk_customer_v3' || section.customer_details.design_id == 'bk_customer_v4')"
			[isMultiStepForm]="true"
			[isQuote]="isQuote"
			[currentUser]="currentUser"
			[settings]="settings"
			[pageSett]="pageSett"
			[zipcode]="zipcodeValue"
			[bookingType]="bookingType"
			[section]="section.address_details"
			[custSection]="section.customer_details"
			[addressForm]="BKFrm"
			[prefilledData]="prefilledData"
			[locationLayout]="locationLayout"
			[selectedLocation]="selectedLocation"
			[locationType]="BKFrm.value['location_type']"
			(zipCodeChange)="reCalculateTax($event)"
		>
		</bk-address>
	</ng-container>
</ng-template>
<!-- Key info template -->
<ng-template #keyInfoTemp>
	<bk-key-info [style.order]="sectionOrder.key_info" *ngIf="BKFrm.value['location_type'] == 'SA'"
		[isMultiStepForm]="true"
		[bookingType]="bookingType"
		[section]="section.key_info"
		[pageSett]="pageSett"
		[prefilledData]="prefilledData"
		[KeyInfoForm]="BKFrm">
	</bk-key-info>
</ng-template>
<!-- Special note template-->
<ng-template #specialNotesTemp>
	<bk-special-notes
		[isMultiStepForm]="true"
		[style.order]="sectionOrder.special_notes"
		[bookingType]="bookingType"
		[pageSett]="pageSett"
		[section]="section.special_note"
		[noteForm]="BKFrm"
		[prefilledData]="prefilledData">
	</bk-special-notes>
</ng-template>



<ng-template #customFieldsTemp let-step="step">
	<ng-container *ngIf="bkngCustSecServ.stepWiseCustGrp[step] && (bkngCustSecServ.stepWiseCustGrp[step]).length > 0 && bkngCustSecServ.buildCustomFields">
		<ng-container *ngFor="let customField of bkngCustSecServ.stepWiseCustGrp[step]">
			<bk-custom-fields [style.order]="sectionOrder['custom_fields_'+customField._id]"
				[customFieldGroup]="customField"
				[bookingType]="bookingType"
				[BKFrm]="BKFrm"
				[prefilledData]="prefilledData"
				[isMultiStepForm]="true"
				stepType="all"
				[selectedServiceType]="selectedServiceType"
				[buildCustomFields]="bkngCustSecServ.buildCustomFields"
				(customFieldChange)="resetFormControl('custom-fields', $event)"
			></bk-custom-fields>
		</ng-container>
	</ng-container>
</ng-template>
<!-- Tip parking template -->
<ng-template #tipParkingTemp>
	<bk-tip-parking [style.order]="sectionOrder.tip_parking"
		[isMultiStepForm]="true"
		[pageSett]="pageSett"
		[bookingType]="bookingType"
		[tipParkingForm]="BKFrm"
		[selectedServiceType]="selectedServiceType"
		[section]="section?.tip_parking"
		[locationType]="BKFrm.value['location_type']"
		[prefilledData]="prefilledData"
	>
	</bk-tip-parking>
</ng-template>
<!-- Discount template -->
<ng-template #discountTemp>
	<bk-discounts *ngIf="couponPerm"
		[isMultiStepForm]="true"
		[pageSett]="pageSett"
		[style.order]="sectionOrder.discounts"
		[bookingType]="bookingType"
		[section]="section.discount"
		[discountForm]="BKFrm"
		[settings]="settings"
		[prefilledData]="prefilledData"
		[selectedServiceType]="selectedServiceType"
		(couponApply)="couponApply($event)"
		(resetCoupon)="resetCoupon($event)"
		(checkPreChargeIfCoupon)="checkPreChargeIfCoupon($event)"
		(giftCardApply)="giftCardSuccessfullyApplied($event)"
		(resetGiftCard)="resetGiftCard($event)"
		(resetReferralUser)="resetReferralUser($event)"
		(referralApply)="referralApply()"
		(resetReferral)="resetReferral($event)"
		>
	</bk-discounts>
</ng-template>
<!-- Payment info template -->
<ng-template #paymentInfoTemp>
	<bk-payment-info
		[isMultiStepForm]="true"
		[pageSett]="pageSett"
		[style.order]="sectionOrder.payment_info"
		[currentUser]="currentUser"
		[bookingType]="bookingType"
		[section]="section.payment_info"
		[paymentForm]="BKFrm"
		[selectedServiceType]="selectedServiceType"
		[prefilledData]="prefilledData"
		[selectedLocation]="selectedLocation"
		[locationLayout]="locationLayout"
		>
	</bk-payment-info>
</ng-template>
<!-- Form Params template-->
<ng-template #pricingParamTemp>
	<div class="position-relative" [ngSwitch]="formId" [style.order]="sectionOrder.pricing_parameter">
		<ng-container *ngSwitchCase="1">
			<bk-pricing-parameter
				[isMultiStepForm]="true"
				[pageSett]="pageSett"
				[isQuoteEditable]="isQuoteEditable"
				[bookingType]="bookingType"
				[section]="section?.pricing_params"
				[pricingParamForm]="BKFrm"
				[selectedLocation]="selectedLocation"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				[settings]="settings"
				[availableSett]="availableSett"
				[prefilledData]="prefilledData"
				[prefilledParams]="prefilledFormParams?.pricing_param"
				[prefilledExcludes]="prefilledFormParams.excludes"
				(pricingParamChange)="resetFormControl('pricing-param')"
				(excludeChange)="resetFormControl('exclude')"
				(pricingParamsStatus)="buildFormParamsStatus.pricingParams = $event"
			>
			</bk-pricing-parameter>
		</ng-container>
		<ng-container *ngSwitchCase="2">
			<bk-items-with-pckgs
				[isMultiStepForm]="true"
				[isQuoteEditable]="isQuoteEditable"
				[section]="section?.packages"
				[bookingType]="bookingType"
				[itemsForm]="BKFrm"
				[pageSett]="pageSett"
				[settings]="settings"
				[availableSett]="availableSett"
				[selectedLocation]="selectedLocation"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				(itemChange)="resetFormControl('items')"
				(itemParamChange)="resetFormControl('items-params')"
				(packageChange)="resetFormControl('package')"
				[prefilledData]="prefilledData"
				[prefilledItems]="prefilledFormParams.items"
				(prefilledItemsChange)="prefilledFormParams.items = $event"
				(itemPckgStatus)="buildFormParamsStatus.itemPackages = $event"
			>
			</bk-items-with-pckgs>
			<!-- Validation -->
			<ng-container *ngIf="(settings && settings.form_data && settings.form_data.validate_pricing_param && settings.form_data.validate_pricing_param == 'yes')">
				<div class="tjs-error bk-error mb-20" *ngIf="isNoItemSelected || (isNoPackageSelected && !validPckgStatus)" translate>
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="isNoItemSelected">Please select at least one item.</ng-container>
					<ng-container *ngIf="isNoPackageSelected && !validPckgStatus">Please select at least one package</ng-container>
				</div>
			</ng-container>
		</ng-container>
		<ng-container *ngSwitchCase="3">
			<bk-items-with-addons
				[isMultiStepForm]="true"
				[pageSett]="pageSett"
				[availableSett]="availableSett"
				[isQuoteEditable]="isQuoteEditable"
				[section]="section?.addons"
				[bookingType]="bookingType"
				[itemsForm]="BKFrm"
				[settings]="settings"
				[selectedLocation]="selectedLocation"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				(itemChange)="resetFormControl('items')"
				(itemParamChange)="resetFormControl('items-params')"
				[prefilledData]="prefilledData"
				[prefilledItems]="prefilledFormParams.items"
				(itemAddonStatus)="buildFormParamsStatus.itemsAddons = $event"
			>
			</bk-items-with-addons>
			<!-- Validation -->
			<ng-container *ngIf="(settings && settings.form_data && settings.form_data.validate_pricing_param && settings.form_data.validate_pricing_param == 'yes')">
				<div class="tjs-error bk-error mb-20" *ngIf="isNoItemSelected || (isNoAddonSelected && (BKFrm?.value['items']).length == 0)" translate>
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="isNoItemSelected">Please select at least one item.</ng-container>
					<ng-container *ngIf="isNoAddonSelected && (BKFrm?.value['items']).length == 0">Please select at least one addon</ng-container>
				</div>
			</ng-container>
		</ng-container>
		<ng-container *ngSwitchCase="4">
			<bk-pricing-parameter-with-area *ngIf="BKFrm.value['area_parameter']" #areaParamComp
				[isMultiStepForm]="true"
				[pageSett]="pageSett"
				[availableSett]="availableSett"
				[isQuoteEditable]="isQuoteEditable"
				[bookingType]="bookingType"
				[section]="section?.area_params"
				[areaParamForm]="BKFrm"
				[selectedLocation]="selectedLocation"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				[settings]="settings"
				[availableSett]="availableSett"
				(areaUnitChange)="resetFormControl('area-params')"
				[prefilledData]="prefilledData"
				[prefilledAreaParam]="prefilledFormParams.area_param"
				[prefilledAreaParam]="prefilledFormParams.area_param"
				(areaParamsStatus)="buildFormParamsStatus.areaParams = $event"
			>
			</bk-pricing-parameter-with-area>
		</ng-container>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderIds?.formParams"></bk-app-loader>
	</div>
</ng-template>
<!-- Extras -->
<ng-template #extrasTemp>
	<div class="position-relative" [style.order]="sectionOrder.extras">
		<bk-extras
			[pageSett]="pageSett"
			[isMultiStepForm]="true"
			[isQuoteEditable]="isQuoteEditable"
			[bookingType]="bookingType"
			[section]="section?.extras"
			[extrasForm]="BKFrm"
			[settings]="settings"
			[availableSett]="availableSett"
			[prefilledData]="prefilledData"
			[prefilledExtras]="prefilledFormParams.extras"
			[selectedServiceType]="selectedServiceType"
			[selectedFrequency]="selectedFrequency"
			[selectedLocation]="selectedLocation"
			(extraChange)="resetFormControl('extras')">
		</bk-extras>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderIds?.extras"></bk-app-loader>
	</div>
</ng-template>
