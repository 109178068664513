import { Component, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../Services';
// Inheritance components
import { CustomFieldsComponent } from '../../../Global/BookingForm';

@Component({
	selector: 'bk-short-frm-custom-fields',
	templateUrl: './ShortFrmCustomFields.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ShortFrmCustomFieldsComponent extends CustomFieldsComponent{
	@Input() variationId: string = '';
	@Input() sectionIds: any;
}
