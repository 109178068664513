<!-- Subscribe section -->
<div [id]="section?.subscribe?.id" class="tjs-blog-widget mb-30 bg-white" *ngIf="section && section?.subscribe">
	<div class="card rounded-0 tjs-bg-style bg-transparent position-relative" [ngStyle]="(section?.subscribe?.media && (section?.subscribe?.media).length > 0) && {'background-image' : 'url('+ section?.subscribe?.media[0] + ')'}">
		<div class="tjs-opacity--bg" *ngIf="section?.subscribe?.media && (section?.subscribe?.media).length > 0" [id]="section?.subscribe?.media_id"></div>
		<div class="card-body px-30 py-60 text-center">
			<!-- Description -->
			<div class="mb-25 px-0 px-md-20" *ngIf="section?.subscribe?.desc">
				<h5 [id]="section?.subscribe?.desc_id" class="tjs-lh-15 fs-24 fw-bold bk-desc" [innerHTML]="section?.subscribe?.desc | safeHtml"></h5>
			</div>
			<!-- Form -->
			<form bkDebounceEvent [isSubmit]="true" [id]="section?.subscribe?.form_id" class="bk-form" [formGroup]="subscribeForm" (debounceSubmit)="submitForm()" *ngIf="section?.subscribe?.form">
				<!-- Email -->
				<div class="mb-20">
					<label class="form-label bk-form-label" *ngIf="section?.subscribe?.form?.email?.label" [id]="section?.subscribe?.form?.email?.label_id" [innerHTML]="section?.subscribe?.form?.email?.label | safeHtml"></label>
					<input type="email" class="form-control text-center bk-input" [class.is-invalid]="subscribeForm.controls['email_id'].hasError('required') && subscribeForm.controls['email_id']?.touched" formControlName="email_id" [placeholder]="section?.subscribe?.form?.email?.placeholder">
					<!-- Error -->
					<div class="tjs-error bk-error" *ngIf="subscribeForm.controls['email_id']?.touched && (subscribeForm.controls['email_id'].hasError('required') || subscribeForm.controls['email_id'].hasError('pattern'))">
						<i class="tjsicon-attention"></i>
						<ng-container *ngIf="subscribeForm.controls['email_id'].hasError('required')">
							{{initServ?.appStr?.errorMsg?.empty | translate}}
						</ng-container>
						<ng-container *ngIf="subscribeForm.controls['email_id'].hasError('pattern')">
							{{initServ?.appStr?.errorMsg?.validEmail | translate}}
						</ng-container>
					</div>
				</div>
				<!-- Button -->
				<div class="mb-25" *ngIf="section?.subscribe?.form?.button && section?.subscribe?.form?.button?.content">
					<bk-navigate class="w-100 d-block" [secId]="section?.subscribe?.form?.button_id" [designId]="section?.subscribe?.form?.button?.design_id" [isSubmit]="true" [innerHTML]="section?.subscribe?.form?.button?.content" customClass="btn btn-primary border-transparent" essentialClass="w-100 bk-form-btn"></bk-navigate>
				</div>
			</form>
			<!-- Sub title -->
			<div *ngIf="section?.subscribe?.sub_title">
				<small [id]="section?.subscribe?.sub_title_id" class="fw-bold bk-sub-title" [innerHTML]="section?.subscribe?.sub_title | safeHtml"></small>
			</div>
		</div>
	</div>
</div>
<!-- Description section -->
<div [id]="section?.description?.id" class="tjs-blog-widget mb-30 bg-white" *ngIf="section && section?.description">
	<div class="card border rounded-0 bg-transparent">
		<div class="card-body px-25 pt-25 pb-40 text-center">
			<!-- Media -->
			<img loading="auto" [id]="section?.description?.media_id" class="mb-25 bk-media img-fluid" *ngIf="section?.description?.media && (section?.description?.media).length > 0" [src]="section?.description?.media[0]" width="72" height="72" alt="Tip">
			<!-- Title -->
			<div *ngIf="section?.description?.title">
				<h6 [id]="section?.description?.title_id" class="mb-25 fw-bold bk-title" [innerHTML]="section?.description?.title | safeHtml"></h6>
			</div>
			<!-- Description -->
			<div class="tjs-section__description mb-25 bk-desc" *ngIf="section?.description?.desc">
				<p [id]="section?.description?.desc_id" [innerHTML]="section?.description?.desc | safeHtml"></p>
			</div>
			<!-- Button -->
			<div [id]="section?.description?.button_id" *ngIf="section?.description?.button && section?.description?.button?.buttons && (section?.description?.button?.buttons).length > 0">
				<div class="tjs-navigate-wrapper justify-content-center">
					<ng-container *ngFor="let btn of section?.description?.button?.buttons;trackBy: utilServ.trackByFnIndex">
						<bk-navigate [secSett]="btn" customClass="btn btn-link" [innerHTML]="btn?.content"></bk-navigate>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Social list section-->
<div [id]="section?.social_list?.id" class="tjs-blog-widget mb-30 bg-white" *ngIf="section && section?.social_list">
	<div class="card border rounded-0 bg-transparent">
		<div class="card-body p-25">
			<!-- Title -->
			<div class="mb-30" *ngIf="section?.social_list?.title">
				<h6 [id]="section?.social_list?.title_id" class="fw-bold bk-title" [innerHTML]="section?.social_list?.title | safeHtml"></h6>
			</div>
			<!-- Social Media -->
			<ul [id]="section?.social_list?.list_id" class="list-unstyled d-inline-flex bk-list" *ngIf="section?.social_list?.list && section?.social_list?.list?.items && (section?.social_list?.list?.items).length > 0">
				<ng-container *ngFor="let item of section?.social_list?.list?.items;trackBy: utilServ.trackByFnIndex">
					<li class="me-10 bk-item">
						<bk-navigate customClass="p-0" [secSett]="item?.link" innerHTML="<img loading='lazy' id='{{item?.id}}' class='bk-item-media img-fluid' *ngIf='{{item?.media && (item?.media).length > 0}}' src='{{item?.media[0]}}' width='31' height='31' alt='{{item?.title}}' />" >
					</bk-navigate>
					</li>
				</ng-container>
			</ul>
		</div>
	</div>
</div>
<!-- Recent posts -->
<div [id]="section?.recent_posts?.id" class="tjs-blog-widget mb-30 bg-white" *ngIf="section && section?.recent_posts">
	<div class="card border rounded-0 bg-transparent">
		<div class="card-body p-25">
			<!-- Title -->
			<div class="mb-30" *ngIf="section?.recent_posts?.title">
				<h6 [id]="section?.recent_posts?.title_id" class="fw-bold" [innerHTML]="section?.recent_posts?.title | safeHtml"></h6>
			</div>
			<!-- Blogs -->
			<ul class="list-unstyled bk-list mb-0" *ngIf="blogs && blogs.length > 0">
				<ng-container *ngFor="let blog of blogs;let i= index; trackBy: utilServ.trackByFnIndex">
					<li *ngIf="i<4" class="mb-15 pb-15 bk-item border-bottom tjs-mb-last-0 tjs-border-last-0 tjs-pb-last-0 ">
						<!-- Date -->
						<p class="fs-14 mb-5" *ngIf="blog?.date_time">
							<bk-date-display class="d-inline-block bk-item-desc" [date]="blog?.date_time" [isLocal]="true"></bk-date-display>
						</p>
						<!-- Title -->
						<a [routerLink]="['/',pageSlug,blog?.seo?.url]" class="fw-bold bk-item-title text-body tjs-title-hover">{{blog?.title}}</a>
					</li>
				</ng-container>
			</ul>
		</div>
	</div>
</div>