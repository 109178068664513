import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
// Services
import { NgOnDestroy, InitServ, LoaderServ, ApiServ, UtilServ } from '../../Services';
@Component({
	selector: 'bk-leads-form-popup',
	templateUrl: './LeadsFormPopup.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LeadsFormPopupComponent implements OnInit {
	data: any;
	embedFormUrl:any;
	embedJsUrl:any;
	leadFormId: any;
	popupData: any = {
		position: 'center'
	};
	loadPopup: boolean = false;
	// eslint-disable-next-line max-params
	constructor(public dialogRef: MatDialogRef<LeadsFormPopupComponent>, private initServ: InitServ, public sanitizer: DomSanitizer, private apiServ: ApiServ, public loader: LoaderServ, private destroy: NgOnDestroy, private utilServ: UtilServ) {
		this.embedJsUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://' + this.initServ.appData.primary_custom_domain + '/resources/embed.js');
	}
	async ngOnInit(){
		if(this.data && this.data.link_url){
			this.leadFormId = await this.utilServ.dataEncryptByGCM(this.data.link_url);
			this.fetchLeadForm();
			let formUrl: string = this.initServ.baseUrl + '/leads/form/' + this.leadFormId + '?embed=true';
			this.embedFormUrl = this.sanitizer.bypassSecurityTrustResourceUrl(formUrl);
			this.utilServ.setHeight(1000, this.leadFormId);
			this.utilServ.setHeight(3000, this.leadFormId);
			this.utilServ.setHeight(5000, this.leadFormId);
			this.utilServ.setHeight(8000, this.leadFormId);
		}
	}
	private fetchLeadForm(): void {
		this.loader.show();
		this.apiServ.callApiWithPathQueryVars('Get', 'LeadsForm', [this.leadFormId], {position: true}).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.leadsFormRes(res));
	}
	private leadsFormRes(res: any): void {
		if(this.apiServ.checkAPIRes(res)){
			this.popupData.position = this.getPosition(res.data.position);
			this.utilServ.popupPosition('bk-leads-popup-V1', this.popupData, this.dialogRef);
		}
		this.loadPopup = true;
		this.loader.hide();
	}
	private getPosition(position: string): string {
		switch (position) {
			case 'top':
				return 'top_center';
			case 'left':
				return 'bottom_left';
			case 'right':
				return 'bottom_right'
			default:
				return 'center'
		}
	}
}
