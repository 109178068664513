import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// Services
import { InitServ } from '../../Services';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router,private initServ: InitServ) { }
	canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		try{
			if(localStorage.getItem('currentUser')) {
				return true; // logged in so return true
			}
		}catch(err){}
		// not logged in so redirect to login page with the return url
		this.router.navigate(['/'+this.initServ.appDynamicRoutes['login']], { queryParams: { returnUrl: state.url }});
		return false;
	}
}
