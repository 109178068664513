<footer [id]="secId" *ngIf="(siteData && siteData?.theme_settings && siteData?.theme_settings?.settings && !siteData?.theme_settings?.settings?.hide_footer) && buildFooter && sett && secId && sett[secId] && sett[secId][rcServ.statusType] && section && pageSett && pageSett?.footer">
	<div class="tjs-footer {{variationClass[varId]}}">
		<div  class="tjs-footer__top pt-60 bk-footer" [ngClass]="{'tjs-footer--kingdom': initServ?.activeTheme == 'kingdom'}">
			<div [ngClass]="style[secId]?.layout == 'full' ? 'container-fluid' : 'container'">
				<div class="row">
					<ng-container [ngSwitch]="varId">
						<div *ngSwitchDefault class="col-12 mb-25" [ngClass]="varId == 'bk_footer_V1' ? 'col-lg-7' : 'col-lg-5'">
							<!-- Logo -->
							<ng-container *ngTemplateOutlet="logoTemp"></ng-container>
							<!-- Dynamic menu -->
							<bk-footer-menu *ngIf="varId == 'bk_footer_V1' && disabledSec?.menu && section?.menu && pageSett?.footerMenu" [section]="section?.menu" [pageSett]="pageSett" [menus]="menus" [menuContent]="menuContent"></bk-footer-menu>
							<!-- Social -->
							<ng-container *ngIf="varId == 'bk_footer_V2'">
								<ng-container *ngTemplateOutlet="socialTemp"></ng-container>
							</ng-container>
							<!-- Phone -->
							<ng-container *ngTemplateOutlet="phoneTemp"></ng-container>
						</div>
						<div class="col-12 mb-25" *ngIf="(varId == 'bk_footer_V1' && disabledSec?.social_icon && section?.social_icon && section?.social_icon?.items && (section?.social_icon?.items).length > 0) || (varId == 'bk_footer_V2' && disabledSec?.menu && section?.menu && pageSett?.footerMenu)" [ngClass]="varId == 'bk_footer_V1' ? 'col-lg-5' : 'col-lg-7 d-flex align-items-center'">
							<!-- Social -->
							<ng-container *ngIf="varId == 'bk_footer_V1'">
								<ng-container *ngTemplateOutlet="socialTemp"></ng-container>
							</ng-container>
							<!-- Dynamic menu -->
							<bk-footer-menu *ngIf="varId == 'bk_footer_V2' && disabledSec?.menu && section?.menu && pageSett?.footerMenu" [section]="section?.menu" [pageSett]="pageSett" [menus]="menus" [menuContent]="menuContent"></bk-footer-menu>
						</div>
						<!-- V3 -->
						<ng-container *ngSwitchCase="'bk_footer_V3'">
							<div class="col-12 col-md-12 col-lg-5 d-none d-lg-flex align-items-end" *ngIf="section?.media">
								<div class="mt-25 tjs-bg-style tjs-footer__thumb" [ngStyle]="{'background-image' : (section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}"></div>
							</div>
							<div class="col-12 mb-25 pt-80 pb-40" [class.col-lg-7]="section?.media">
								<div class="d-lg-flex align-items-center justify-content-between mb-20 mb-lg-50" *ngIf="(section?.logo && disabledSec?.logo) || (disabledSec?.social_icon && section?.social_icon && section?.social_icon?.items && (section?.social_icon?.items).length > 0)">
									<!-- Logo -->
									<ng-container *ngTemplateOutlet="logoTemp"></ng-container>
									<!-- Social -->
									<ng-container *ngTemplateOutlet="socialTemp"></ng-container>
								</div>
								<!-- Dynamic menu -->
								<bk-footer-menu *ngIf="disabledSec?.menu && section?.menu && pageSett?.footerMenu" [section]="section?.menu" [pageSett]="pageSett" [menus]="menus" [menuContent]="menuContent"></bk-footer-menu>
								<!-- Buttons -->
								<div class="d-sm-flex align-items-sm-center">
									<div class="me-sm-15">
										<div class="tjs-navigate-wrapper">
											<bk-navigate *ngIf="section && section?.apply_now_btn" customClass="btn btn-lg bg-white tjs-btn-modern" essentialClass="h-auto me-5 text-capitalize" [secSett]="section?.apply_now_btn" [innerHTML]="section?.apply_now_btn?.content"></bk-navigate>
											<bk-navigate *ngIf="section && section?.booknow_btn" [innerHTML]="section?.booknow_btn?.content" [secSett]="section?.booknow_btn" customClass="btn btn-lg bg-white tjs-btn-modern" essentialClass="text-uppercase h-auto"></bk-navigate>
										</div>
									</div>
									<!-- Phone -->
									<ng-container *ngTemplateOutlet="phoneTemp"></ng-container>
								</div>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
		<!-- Powered by-->
		<ng-container *ngIf="(siteData && siteData?.theme_settings && siteData?.theme_settings?.settings && siteData?.theme_settings?.settings?.powered_by_bookingkoala_branding) || (admnStngs && admnStngs?.package && (admnStngs?.package == 'premiumpro' || admnStngs?.package == 'premium' || admnStngs?.package == 'growing'))">
			<div class="tjs-footer__bottom py-30" [id]="section?.cr_text?.id" *ngIf="disabledSec?.cr_text && section?.cr_text && section?.cr_text?.content">
				<div class="container">
					<div class="fs-15 text-center">
						<a target="_blank" [href]="poweredByLink" class="bk-powered-by" [innerHTML]="section?.cr_text?.content | safeHtml"></a>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</footer>
<!-- Logo template -->
<ng-template #logoTemp>
	<div class="tjs-footer__logo mb-25" *ngIf="section?.logo && disabledSec?.logo">
		<ng-container *ngIf="(section?.logo?.media && (section?.logo?.media).length > 0); else defaultLogo">
			<bk-navigate essentialClass="d-inline-block fw-bolder tjs-footer__logo-text bk-site-logo p-0" [secSett]="section?.logo?.link" innerHTML="<img loading='lazy' id='{{section?.logo?.media_id}}' class='img-fluid' src='{{section?.logo?.media[0]}}' alt='logo' width='126' height='97' style='width:{{sett[section?.logo?.media_id][widthType]}}px' />"></bk-navigate>
			<!-- <bk-navigate essentialClass="d-inline-block fw-bolder tjs-footer__logo-text bk-site-logo p-0" [secSett]="section?.logo?.link" innerHTML="<img loading='lazy' id='{{section?.logo?.media_id}}' class='img-fluid' src='{{section?.logo?.media[0]}}' alt='logo' width='126' height='97' style='width:{{sett[section?.logo?.media_id][widthType] ? sett[section?.logo?.media_id][widthType] : ''}}px; height:{{sett[section?.logo?.media_id][heightType] ? sett[section?.logo?.media_id][heightType] : ''}}px' />"></bk-navigate> -->
		</ng-container>
		<ng-template #defaultLogo>
			<bk-navigate essentialClass="d-inline-block fw-bolder tjs-footer__logo-text bk-site-logo p-0" [secSett]="section?.logo?.link" innerHTML="{{initServ?.appStr?.text?.yourLogo | translate}}" [isText]="true"></bk-navigate>
		</ng-template>
	</div>
</ng-template>
<!-- Phone template -->
<ng-template #phoneTemp>
	<div [id]="section?.phone?.id" class="d-inline-block mt-20 mt-md-0" *ngIf="section?.phone && disabledSec?.phone">
		<div [id]="section?.phone?.title_id" class="fs-14 tjs-footer__contact-text text-white bk-footer-contact-link" *ngIf="section?.phone?.title" [innerHTML]="section?.phone?.title | safeHtml"></div>
		<a [id]="section?.phone?.number_id" class="fw-bold d-block display-10 tjs-footer-contact bk-footer-contact-link dir-ltr" href="tel:{{section?.phone?.number}}">{{section?.phone?.number}}</a>
	</div>
</ng-template>
<!-- Social template -->
<ng-template #socialTemp>
	<div [id]="section?.social_icon?.id" class="tjs-footer__social-links" *ngIf="disabledSec?.social_icon && section?.social_icon && section?.social_icon?.items && (section?.social_icon?.items).length > 0">
		<ul [id]="section?.social_icon?.id" class="list-inline" [ngClass]="{'text-lg-end': varId == 'bk_footer_V1' }">
			<li class="me-20 d-inline-block mb-10" *ngFor="let item of section?.social_icon?.items;trackBy: utilServ.trackByFnIndex">
				<ng-container *ngIf="item?.media && (item?.media).length > 0; else contentIcon">
					<bk-navigate essentialClass="p-0" [secSett]="item?.link" innerHTML="<img id='{{item?.id}}' loading='lazy' class='bk-item-media img-fluid' src='{{item?.media[0]}}' width='50' height='50' alt='{{item?.img_title}}' />" ></bk-navigate>
				</ng-container>
				<ng-template #contentIcon>
					<bk-navigate essentialClass="p-0" [secSett]="item?.link" [innerHTML]="item?.img_title" ></bk-navigate>
				</ng-template>
			</li>
		</ul>
	</div>
</ng-template>
<ng-container *ngIf="disabledSec?.cookie_content && section?.cookie_content && cookieBar">
	<!-- Cookie Bar -->
	<div class="tjs-cookie alert-dismissible fade show alert mb-0" role="alert">
		<div [id]="section?.cookie_content?.id" class="tjs-cookie-fixed bg-white shadow position-fixed end-0 start-0 bottom-0 px-0 py-15 bk-cookie">
			<div class="px-20 d-lg-flex justify-content-center align-items-baseline text-lg-start text-center">
				<div *ngIf="section?.cookie_content?.title">
					<p [id]="section?.cookie_content?.title_id" class="mb-lg-0 bk-cookie-title" [innerHTML]="section?.cookie_content?.title | safeHtml"></p>
				</div>
				<!-- Buttons -->
				<div>
					<a *ngIf="section?.cookie_content?.cookie_sett" [id]="section?.cookie_content?.cookie_sett_id" href="#cookieModal" class="tjs-cookie-set-btn mx-15 mb-10 text-primary d-inline-block bk-cookie-set-btn" data-bs-toggle="modal" data-bs-target="#cookieModal"  [innerHTML]="section?.cookie_content?.cookie_sett | safeHtml" (click)="utilServ.showPopup('cookieModal')"></a>
					<bk-navigate *ngIf="section?.cookie_content?.cookie_accept && section?.cookie_content?.cookie_accept?.content" innerHTML="{{section?.cookie_content?.cookie_accept?.content}}" [secSett]="section?.cookie_content?.cookie_accept" customClass="btn btn-primary border-transparent" essentialClass="cookie-accept-btn bk-cookie-acpt-btn" (callback)="setCookieForBar()" ></bk-navigate>
				</div>
			</div>
		</div>
	</div>
	<!-- Cookie Settings Modal -->
	<div class="modal fade tjs-cookie-setting-modal" id="cookieModal" tabindex="-1" role="dialog" aria-labelledby="cookieModalOpened" aria-hidden="true">
		<div class="modal-dialog tjs-cookie-modal-width modal-dialog-centered" role="document">
			<div class="modal-content fs-14 lh-base">
				<div class="modal-header ps-20 pe-15">
					<!-- Title -->
					<h6 *ngIf="section?.cookie_content?.popup_title" class="modal-title" id="cookieModalOpened">
						<span [id]="section?.cookie_content?.popup_title_id">{{section?.cookie_content?.popup_title}}</span>
					</h6>
					<button type="button" class="fs-13 btn-closed border-0 bg-white" data-bs-dismiss="modal" aria-hidden="true"><i class="tjsicon-close text-danger"></i></button>
				</div>
				<div class="modal-body">
					<!-- Description -->
					<p [id]="section?.cookie_content?.popup_desc_id" *ngIf="section?.cookie_content?.popup_desc" [innerHTML]="section?.cookie_content?.popup_desc | safeHtml"></p>
					<div class="tjs-accordion tjs-accordion__arrow tjs-accordion__arrow-sm tjs-accordion--left" id="accordionCookie">
						<div class="card mb-5">
							<div class="card-header tjs-bg-gray" id="headingOne">
								<button class="btn w-100 fw-bold accordion-button tjs-cookie-accordion-title fs-15 d-flex align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
									<div class="d-flex justify-content-between w-100">
										<!-- Title -->
										<p class="mb-0 ms-5" *ngIf="section?.cookie_content?.popup_necessary_tab_title" [id]="section?.cookie_content?.popup_necessary_tab_title_id">{{section?.cookie_content?.popup_necessary_tab_title}}</p>
										<!-- Sub title -->
										<p class="mb-0 ms-5" *ngIf="section?.cookie_content?.popup_necessary_tab_sub_title" [id]="section?.cookie_content?.popup_necessary_tab_sub_title_id"><span class="fs-13 d-inline-block">{{section?.cookie_content?.popup_necessary_tab_sub_title}}</span></p>
									</div>
								</button>
							</div>
							<!-- Description -->
							<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionCookie" *ngIf="section?.cookie_content?.popup_necessary_tab_desc">
								<div [id]="section?.cookie_content?.popup_necessary_tab_desc_id" class="card-body py-10" [innerHTML]="section?.cookie_content?.popup_necessary_tab_desc | safeHtml">
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-header tjs-bg-gray d-flex justify-content-between align-items-center" id="headingTwo">
								<!-- Title -->
								<button class="btn w-100 fw-bold accordion-button collapsed tjs-cookie-accordion-title fs-15 d-flex align-items-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" *ngIf="section?.cookie_content?.popup_non_necessary_tab_title">
									<div class="d-flex justify-content-between w-100">
										<p class="mb-0 ms-5" [id]="section?.cookie_content?.popup_non_necessary_tab_title_id">{{section?.cookie_content?.popup_non_necessary_tab_title}}</p>
									</div>
								 </button>
								<div class="form-check form-switch tjs-switch-common mt-5">
									<input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked="">
									<label class="form-check-label" for="flexSwitchCheckDefault"></label>
								</div>
							</div>
							<!-- Description -->
							<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionCookie" *ngIf="section?.cookie_content?.popup_non_necessary_tab_desc">
								<div [id]="section?.cookie_content?.popup_non_necessary_tab_desc_id" class="card-body py-10" [innerHTML]="section?.cookie_content?.popup_non_necessary_tab_desc | safeHtml"></div>
							</div>
						</div>
					</div>
				</div>
				<!-- Button -->
				<div class="modal-footer border-0" *ngIf="section?.cookie_content?.popup_btn">
					<bk-navigate id="modal-cookie-btn" [secId]="section?.cookie_content?.popup_btn_id" innerHTML="{{section?.cookie_content?.popup_btn}}" customClass="btn btn-success border-transparent" data-bs-dismiss="modal" (callback)="setCookieForBar()" ></bk-navigate>
				</div>
			</div>
		</div>
	</div>