import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { InitServ, NgOnDestroy, UtilServ } from '../../../../Services';
import { PriceableFieldCalServ } from '../../../../Services/BkngFormPriceCal';

@Component({
	selector: 'bk-charged-payment-summary',
	templateUrl: './ChargedPaymentSummary.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ChargedPaymentSummaryComponent implements OnInit {
	// Variables
	@Input() prefilledData: any;
	@Input() section: any;
	@Input() selectedServiceType: any;

	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	spotDiscount: any = 0;
	couponDiscount: any = 0;
	tipDiscount: any = 0;

	priceableCustomFields: any = null;
	priceableCustomFieldsPriceType : any;
	priceableFieldNamePriceObj : any = {};
	updatedDisplayServicePrice: any = 0;

	constructor(public utilServ: UtilServ, public initServ: InitServ, private priceableFieldCal: PriceableFieldCalServ) { }

	ngOnInit(): void {
		if(this.prefilledData){
			this.updateServPriceAccPriceableFields();
			// Spot discount
			if(this.prefilledData.day_discount && this.prefilledData.day_discount.discount && this.prefilledData.day_discount.discount > 0){
				this.spotDiscount = this.getSpotDiscount();
			}
			// Coupon discount
			this.couponDiscount = this.getCouponDiscount();
			// Tip discount
			if(this.prefilledData.tip && this.prefilledData.tip?.total_amount && this.prefilledData.tip?.total_amount > 0){
				this.tipDiscount = this.getTipDiscount();
			}
		}
	}

	/**
	 * Get the spot discount
	 * @returns
	 */
	// eslint-disable-next-line complexity
	private getSpotDiscount(): any{
		let serviceTotal: number = 0;
		let displaySpotDiscount: number = 0;
		if(this.prefilledData){
			if(this.prefilledData.referral_discount){
				serviceTotal = (this.prefilledData.service_total - this.prefilledData.referral_discount);
			} else{
				serviceTotal = this.prefilledData.service_total;
			}
			if(this.prefilledData.same_day_booking && this.prefilledData.expedited_amount && !this.prefilledData.exclude_expedited_charge){
				serviceTotal = serviceTotal - this.prefilledData.expedited_amount;
			}
			if(this.prefilledData.frequency_discount_amount){
				serviceTotal = serviceTotal - this.prefilledData.frequency_discount_amount;
			}
			if(serviceTotal > 0){
				// Spot discount
				if(this.prefilledData.day_discount && this.prefilledData.day_discount.discount && !this.prefilledData.exclude_day_discount){
					if(this.prefilledData.day_discount.discount_type == 'percentage'){
						let spotAmount = +this.prefilledData.day_discount.discount;
						displaySpotDiscount = this.utilServ.roundToTwo((spotAmount/100)*serviceTotal);
					} else{
						displaySpotDiscount = this.prefilledData.day_discount.discount;
					}
				} else{
					displaySpotDiscount = 0;
				}
			}
		}
		return displaySpotDiscount;
	}
	/**
	 * Get the coupon discount
	 * @returns
	 */
	private getCouponDiscount(): any{
		let couponDiscountValue = this.prefilledData.coupon.discount;
		if(this.prefilledData.coupon.discount_type == 'percentage'){
			let displayCouponDiscount = this.utilServ.roundToTwo(((+couponDiscountValue)/100)*(+this.prefilledData.total_before_coupon_discount));
			if(!displayCouponDiscount){
				displayCouponDiscount = 0;
			}
			return displayCouponDiscount;
		} else {
			let displayCouponDiscount = this.utilServ.roundToTwo(couponDiscountValue);
			if(!displayCouponDiscount){
				displayCouponDiscount = 0;
			}
			return displayCouponDiscount;
		}
	}
	/**
	 * Get the tip discount
	 * @returns
	 */
	private getTipDiscount(){
		let tipCalculationBase = this.prefilledData.discounted_total;
		if(this.prefilledData.adjust_price){
			tipCalculationBase = this.prefilledData.adjusted_price;
		}
		if(this.prefilledData.tip.amount_type == 'percentage'){
			let tipsAmount = +(this.prefilledData.tip.total_amount);
			let displayTipsAmount = this.utilServ.roundToTwo((tipsAmount/100)*tipCalculationBase);
			if(!displayTipsAmount){
				displayTipsAmount = 0;
			}
			return displayTipsAmount
		} else {
			let displayTipsAmount = this.utilServ.roundToTwo(+(this.prefilledData.tip.total_amount));
			if(!displayTipsAmount){
				displayTipsAmount = 0;
			}
			return displayTipsAmount;
		}
	}

	/**
	 * Update service price according to priceable custom fields
	 */
	// eslint-disable-next-line complexity
	private updateServPriceAccPriceableFields(): void {
		this.priceableCustomFieldsPriceType = this.prefilledData?.location_type === 'ML' ? 'prices_ml' : 'prices_sa';
		this.priceableCustomFields = this.prefilledData?.priceable_custom_fields ? this.prefilledData?.priceable_custom_fields : undefined;
		this.updatedDisplayServicePrice = 0;
		let displayServicePrice = this.prefilledData?.service_total;
		if(this.prefilledData?.service_price || this.prefilledData?.service_price == 0){
			displayServicePrice = this.prefilledData?.service_price;
		}
		if(displayServicePrice > 0){
			this.updatedDisplayServicePrice = displayServicePrice;
			let includeInFreqFieldsPrice = +(this.prefilledData?.include_in_freq_fields_price || 0);
			this.updatedDisplayServicePrice -= includeInFreqFieldsPrice;
		}else if(this.selectedServiceType?.is_override_pricing == 'yes'){
			this.updatedDisplayServicePrice = this.selectedServiceType?.override_pricing;
		}else if(this.prefilledData?.before_priceable_fields_price && this.selectedServiceType?.is_hourly_service != 'yes'){
			this.updatedDisplayServicePrice = +this.prefilledData?.before_priceable_fields_price;
		}
		if(this.prefilledData?.override_service_total){
			this.updatedDisplayServicePrice = this.prefilledData?.overridden_service_total;
		}
		if(this.updatedDisplayServicePrice < 0){
			this.updatedDisplayServicePrice = 0;
		}
		this.displaySetForFieldNamePrice();
	}
	/**
	 * Display priceable custom fields in payment summary according to position
	 */
	private displaySetForFieldNamePrice(){
		if(this.priceableCustomFields){
			let typeArray = ['include_in_freq_disc', 'exempt_from_freq_disc', 'non_taxable', 'after_discounted_total'];
			let locType = this.prefilledData?.location_type;
			for (let fieldType of typeArray){
				this.priceableFieldNamePriceObj[fieldType] = [];
				if(this.priceableCustomFields[fieldType] && (this.priceableCustomFields[fieldType]).length > 0){
					this.setPriceToName(fieldType, locType);
				}
			}
		}
	}
	/**
	 * Set name and price object for display purpose
	 * @param fieldType
	 */
	private setPriceToName(fieldType: any, locType: any){
		for(let field of this.priceableCustomFields[fieldType]){
			if(this.priceableFieldCal.isFieldValue(field, fieldType, this.selectedServiceType)){
				let fieldObj = this.priceableFieldCal.setFieldObj(field, locType);
				(this.priceableFieldNamePriceObj[fieldType]).push(fieldObj);
			}
		}
	}
}