<div [id]="section?.id" [formGroup]="ServiceProviderControl" *ngIf="formLayout == 'multi_step' && settings && section">
	<ng-container *ngIf="initServ?.appAdmnStngs?.merchant_settings?.providers?.scheduling_type != 'manually'; else manualBlock">
		<div class="col-xl-9 mx-auto">
			<div class="row justify-content-center text-center mb-35">
				<div class="col-12 col-md-6 col-lg-4 my-5" *ngIf="customerScheduleOptions.specificProvider">
					<div class="form-check form-check-inline me-0">
						<input id="specific_provider" class="form-check-input" type="checkbox" [(ngModel)]="selectedScheduleOptions.isSpecificProvider" [ngModelOptions]="{standalone: true}" (change)="onProviderTypeChangePart($event, 'specific_provider')">
						<label for="specific_provider" class="form-check-label bk-form-check-label" [innerHTML]="section?.specific_prov | safeHtml: 'html'"></label>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-4 my-5" *ngIf="customerScheduleOptions.previousProvider">
					<div class="form-check form-check-inline me-0">
						<input id="previous_provider" class="form-check-input" type="checkbox" [(ngModel)]="selectedScheduleOptions.isPreviousProvider" [ngModelOptions]="{standalone: true}" (change)="onProviderTypeChangePart($event, 'previous_provider')">
						<label for="previous_provider" class="form-check-label bk-form-check-label" [innerHTML]="section?.previous_prov | safeHtml: 'html'"></label>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-4 my-5" *ngIf="customerScheduleOptions.waitingList">
					<div class="form-check form-check-inline me-0">
						<input id="unassigned" class="form-check-input" type="checkbox" [(ngModel)]="selectedScheduleOptions.isUnassignedProvider" [ngModelOptions]="{standalone: true}" (change)="onProviderTypeChangePart($event, 'unassigned')">
						<label for="unassigned" class="form-check-label bk-form-check-label">
							<span [innerHTML]="section?.waiting_list | safeHtml: 'html'"></span>
							<bk-tooltip *ngIf="section?.waiting_list_tooltip" customClass="bk-tooltip" [content]="section?.waiting_list_tooltip | translate"></bk-tooltip>
						</label>
					</div>
				</div>
			</div>
			<!-- section to select specific provider -->
			<div class="search-provider-section mb-25" *ngIf="customerScheduleOptions.specificProvider && ServiceProviderControl.value['provider_type'] == 'specific_provider'">
				<bk-search-provider [availProvFilters]="availProvFilters" (selectedProv)="getSelectedProvider($event)" (click)="prepareAvailProviderFilters()"></bk-search-provider>
			</div>
		</div>
		<div class="d-flex flex-column">
			<div class="position-relative"  *ngIf="(ServiceProviderControl.controls['provider_details'].value).length > 0 && showSelectedProvider">
				<div class="col-xl-8 mx-auto mb-40">
					<ng-container *ngFor="let provider of ServiceProviderControl.controls['provider_details'].value">
						<bk-selected-provider [provider]="provider" [isMultiStepForm]="true" [ServiceProviderControl]="ServiceProviderControl" [section]="section"></bk-selected-provider>
					</ng-container>
				</div>
				<bk-app-loader [loaderId]="provLoaderId"></bk-app-loader>
			</div>
			<ng-container *ngIf="(!isStepOneAvail && activeStep == 1) || (isStepOneAvail && activeStep == 2)">
				<div [id]="calSection?.id">
					<ng-container *ngIf="isMultiCalReload && (ServiceProviderControl.value['provider_type'] != 'specific_provider' || (ServiceProviderControl.value['provider_type'] == 'specific_provider' &&(ServiceProviderControl.controls['provider_details'].value).length > 0 ))">
						<ng-container *ngTemplateOutlet="multiCalendarLayout"></ng-container>
					</ng-container>
					<ng-container *ngIf="ServiceProviderControl.controls['arrival_date'].value">
						<ng-container *ngTemplateOutlet="slotDatesTab"></ng-container>
						<ng-container *ngTemplateOutlet="multiSlotLayout"></ng-container>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</ng-container>
	<ng-template #manualBlock>
		<div [id]="calSection?.id" *ngIf="(!isStepOneAvail && activeStep == 1) || (isStepOneAvail && activeStep == 2 )">
			<ng-container *ngTemplateOutlet="multiCalendarLayout"></ng-container>
			<ng-container *ngIf="ServiceProviderControl.controls['arrival_date'].value">
				<ng-container *ngTemplateOutlet="slotDatesTab"></ng-container>
				<ng-container *ngTemplateOutlet="multiSlotLayout"></ng-container>
			</ng-container>
		</div>
	</ng-template>
</div>
<!-- Template for Calendar to Select date. -->
<ng-template #multiCalendarLayout>
	<div [ngClass]="{'d-none': ServiceProviderControl.controls['arrival_date'].value}">
	<!-- <div> -->
		<bk-schedule-calendar
			[isMultiStep] = true
			[BKFrm] = "ServiceProviderControl"
			[settings] = "settings"
			[selectedDate] = "selectedDate"
			[dayDiscountBookings] = "dayDiscountBookings"
			[dayDiscounts] = "dayDiscounts"
			[dateDiscountsArray] = "dateDiscountsArray"
			[dayDiscountsArray] = "dayDiscountsArray"
			[prefilledData] = "prefilledData"
			(selectMultiStepDate) = "selectMultiStepDate($event)"
			[calSection]="calSection"
			[pageSett]="pageSett"
			[renderCalendar]="!(ServiceProviderControl.controls['arrival_date'].value)"
		>
		</bk-schedule-calendar>
	</div>
</ng-template>
<div class="grouped">
	<ng-template #slotDatesTab class="animation">
		<bk-multi-date
			[ServiceProviderControl] = "ServiceProviderControl"
			[section] = "section"
			[selectedDate] = "selectedDate"
			[perDateAvailSpots] = "perDateAvailSpots"
			[availableDates] = "availableDates"
			[availableProviders] = "availableProviders"
			[dateDiscountsArray] = "dateDiscountsArray"
			[dayDiscountsArray] = "dayDiscountsArray"
			[dayDiscountBookings] = "dayDiscountBookings"
			[prefilledData] = "prefilledData"
			[calSection]="calSection"
			(onMultiDateChange) = "onDateSelect($event)">
		</bk-multi-date>
	</ng-template>
	<ng-template #multiSlotLayout>
		<div [ngClass]="{'tjs-animate-fadeup': isDateClicked}">
			<bk-multi-slot
				[BKFrm] = "ServiceProviderControl"
				[section] = "section"
				[selectedDate] = "selectedDate"
				[perDateAvailSpots] = "perDateAvailSpots"
				[availableProviders] = "availableProviders"
				[dateDiscountsArray] = "dateDiscountsArray"
				[dayDiscountsArray] = "dayDiscountsArray"
				[dayDiscountBookings] = "dayDiscountBookings"
				[calSection] = "calSection"
				[prefilledData] = "prefilledData"
				(onSlotChange) = "onSlotChangeFunc($event)">
			</bk-multi-slot>
		</div>
	</ng-template>
</div>