import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
// import { takeUntil } from 'rxjs';
import { FormGroup } from '@angular/forms';
// Services
import { NgOnDestroy } from '../../Services';
// Inheritance components
import { CommonAddFormComponent } from '../../AddBookings';
import { ShortFrmCustomFieldsComponent } from '../Components';

@Component({
	selector: 'bk-short-form',
	templateUrl: './ShortForm.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class ShortFormComponent extends CommonAddFormComponent{

	@Input() isPrevStepExist: boolean = false;
	@Input() variationId: any;
	@Input() sectionIds: any;
	@Input() bkngPageSett: any;
	@Input() extraStatus: any;
	@Input() mlDesignId: any;
	@ViewChild(ShortFrmCustomFieldsComponent) shortFrmCustomFieldsComp: ShortFrmCustomFieldsComponent | undefined;

	shortFormLoaderId: string = 'short-forms-params';
	shortFormExcludes: any;
	isCustomFields: boolean = false;
	isExtraVisible: boolean = true;

	/**
	 * Back to previous step
	 */
	public backToPrev(): void {
		let qualEl:any = document.getElementById(this.section?.back_btn?.id+'_prequalifier');
		if(qualEl){
			this.utilServ.triggerEvnt(qualEl,'click');
		}
		this.utilServ.scrollToSpecificEle(this.secId);
	}
	/**
	 * Goto next step
	 * @param event: button event
	 * @param stepName: step name
	 */
	public nextStep(event: any, stepName: any): void{
		this.loader.show(this.shortFormLoaderId);
		if(stepName == 'pricingparam'){
			if(this.formId == 2){
				stepName = 'packages';
			} else if(this.formId == 3){
				stepName = 'items';
			}
		}
		switch(stepName){
			case 'pricingparam':
			case 'items':
			case 'packages':
			case 'extra':
				this.validateShortFrmSteps(event, stepName);
				// this.getCustomFields(event, stepName);
				this.loader.hide(this.shortFormLoaderId);
			break;
			case 'email':
				setTimeout(()=>{
					this.validateUserEmail();
				},500);
			break
			default:
				this.validateShortFrmSteps(event, stepName);
				this.loader.hide(this.shortFormLoaderId);
			break;
		}
		// let typeSlugObj = { type: 'theme_builder_short_form', slug: 'theme_builder_short_form' + "_" + this.secSett?.section_id };
		this.leadsServ.addContactToLead(this.BKFrm.value, this.getShortFormType(), 5000);
		this.utilServ.scrollToSpecificEle(this.secId);
	}
	/**
	 * Previous step
	 * @param event : button event
	 */
	public prevStep(event: any): void {
		this.loader.show(this.shortFormLoaderId);
		if(event){
			let formStepEl = document.querySelectorAll('.form-step');
			if(formStepEl && formStepEl.length > 0){
				formStepEl.forEach(element => element.classList.remove('active-step'));
			}
			let par = event.target.parentNode.parentNode.parentNode.parentNode;
			let el = par.previousElementSibling;
			el.className += ' active-step';
		}
		this.loader.hide(this.shortFormLoaderId);
		this.utilServ.scrollToSpecificEle(this.secId);
	}
	/**
	 * Get the custom fields
	 */
	// private getCustomFields(event: any, stepName: string): void {
	// 	// Get the custom fields required form data
	// 	let requiredData = this.bkngFormServ.getCustomFieldsReqData(this.BKFrm.value);
	// 	this.apiServ.callApi('POST', 'FormCustomFields', requiredData).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onCustomFieldsResultCallback(res, event, stepName));
	// }
	/**
	 * Get the custom fields data
	 * @param res: res
	 * @param event: event
	 * @param stepName: step name
	 */
	// private onCustomFieldsResultCallback(res: any, event: any, stepName: string){
	// 	if(this.apiServ.checkAPIRes(res) && res.data){
	// 		let customFields = res.data;
	// 		if(customFields && (customFields).length > 0){
	// 			for(let customField of customFields){
	// 				if(customField.display_on == 'step_one'){
	// 					this.isCustomFields = true;
	// 					this.cDRef.detectChanges();
	// 					break;
	// 				}
	// 			}
	// 		}
	// 	}
	// 	setTimeout(() => {
	// 		this.validateShortFrmSteps(event, stepName);
	// 	}, 100);
	// }
	/**
	 * Validate short form steps
	 * @param event
	 * @param stepName
	 */
	private validateShortFrmSteps(event: any, stepName: string): void {
		if(this.formParamsValidate(stepName)){
			let formStepEl = document.querySelectorAll('.form-step');
			if(formStepEl && formStepEl.length > 0){
				formStepEl.forEach(element => element.classList.remove('active-step'));
			}
			let par = event.target.parentNode.parentNode.parentNode.parentNode;
			let el = par.nextElementSibling;
			if(el){
				el.className += ' active-step';
			} else{
				this.gotoBookNow();
			}
		}
	}
	/**
	 * Form parameters validate
	 * @param stepName: step name
	 * @returns boolean
	 */
	private formParamsValidate(stepName: string): boolean {
		let validate: boolean = true;
		switch(stepName){
			case 'zipcode':
				this.BKFrm.controls['zipcode'].markAsTouched();
				if(this.BKFrm.controls['zipcode'].valid && this.BKFrm.controls['location_id'].valid){
					validate = true;
				} else {
					validate = false;
				}
			break;
			case 'pricingparam':
			case 'items':
			case 'packages':
				validate = this.checkFormValidation(null, true);
			break;
			case 'customfields':
				validate = this.validateFrmServ.isCustomFieldValid(this.BKFrm, {});
			break;
		}
		return validate;
	}
	/**
	 * Validate the user email
	 * If existing, user is logged it
	 */
	// eslint-disable-next-line complexity
	private async validateUserEmail(): Promise<any> {
		let customerGroup: any = <FormGroup>this.BKFrm.controls['customer'];
		let emailIdStatus: boolean = (customerGroup.controls['email_id'].value && (customerGroup.controls['email_id'].errors && customerGroup.controls['email_id'].errors['emailExists'])) ? true : false;
		let pwdStatus = customerGroup.controls['password'].valid;
		if(customerGroup.controls['email_id'].valid){
			pwdStatus = true;
		}
		if((customerGroup.controls['email_id'].value && pwdStatus) || (this.settings.form_data.enable_email_on_first_step && this.settings.form_data.enable_email_on_first_step == 'no')){
			if(emailIdStatus && !this.currentUser){
				await this.loginUser(true, true);
			} else {
				await this.gotoBookNow();
			}
		} else {
			customerGroup.controls['password'].markAsTouched();
			customerGroup.controls['email_id'].markAsTouched();
		}
		this.loader.hide(this.shortFormLoaderId);
		this.cDRef.detectChanges();
	}
}