import { Component, OnInit, Input, ViewEncapsulation, Self } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, LoaderServ, ApiServ, PopupServ, UtilServ } from '../../Services';
import { AuthServ } from '../../Core/_services';
// Constants
import { EMAIL_REG_EXP, PASSWORD_MIN_LENGTH } from '../../Constants';

@Component({
	selector: 'bk-login',
	templateUrl: './Login.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class LoginComponent implements OnInit {

	// Variables
	@Input() secId: string = '';
	socialKeys: any = this.initServ.appSocialKeys; // App social keys
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		sub_title: null,
		media: null,
		social: null,
		form: null
	}
	loginForm: FormGroup; // Login form
	redirectUrl: string = '';
	ratingToken: any;
	bid: any;
	userAccessToken: any;
	unRatedBkngs: any;
	prvdrAccessToken!: string;

	// convenience getter for easy access to form fields
	get f(): { [key: string]: AbstractControl } {
		return this.loginForm.controls;
	}

	constructor(private frmBldr: FormBuilder, public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, private authServ: AuthServ, private actRoute: ActivatedRoute, private loader: LoaderServ, @Self() private destroy: NgOnDestroy, private router: Router, public toastr: ToastrService, private apiServ: ApiServ, private popupServ: PopupServ, public utilServ: UtilServ) {
		this.initComponent();
		this.loginForm = this.frmBldr.group({
			userName: [null, [Validators.required, Validators.pattern(EMAIL_REG_EXP)]],
			password: [null, [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH)]]
		});
	}

	ngOnInit(): void {
		// Build section data
		this.buildSectionData();
	}

	/**
	 * Initializes the component by setting up rating and login handling,
	 * and retrieves the redirect URL from query parameters.
	 */
	private initComponent(): void {
		this.ratingWithoutLogin();
		this.loginWithAccessToken();
		this.redirectUrl = this.actRoute.snapshot.queryParams['returnUrl'];
	}

	/**
	 * Builds the data required for a section by checking service data,
	 * assigning section settings, and calling helper functions to
	 * configure the section based on the section ID and available data.
	 */
	private buildSectionData(): void {
		if (this.secId && this.rcServ.pageData) {
			let { section_settings, content } = this.rcServ.pageData;
			this.pageSett = section_settings;
			this.secServ.setServData(this.pageSett, content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
	}

	/**
	 * Handles the rating process for a booking when a user is not logged in.
	 * Retrieves rating token and booking ID from route parameters and, if conditions allow,
	 * initiates the retrieval of unrated bookings for the provided booking ID.
	 */
	private ratingWithoutLogin(): void{
		let { ratingtoken, bid } = this.actRoute.snapshot.params;
		this.ratingToken = ratingtoken;
		this.bid = bid;

		if (this.ratingToken && this.bid && this.initServ.isRatingAllowed) {
			this.getUnRatedBkngs(this.bid);
		}
	}

	/**
	 * Attempts to log in the user using an access token from route parameters.
	 * Depending on the query parameters and token availability, handles login actions,
	 * including decryption of access token and removal of current user when applicable.
	 */
	private async loginWithAccessToken(): Promise<void> {
		let { access_token } = this.actRoute.snapshot.params;
		let { queryParams } = this.actRoute.snapshot;
		this.userAccessToken = access_token || null;

		if (queryParams['type']) {
			this.prvdrAccessToken = await this.initServ.secureDecrypt(this.userAccessToken);
			return;
		}

		if (this.userAccessToken && !this.initServ.theme) {
			this.authServ.removeCurrentUser();
			if (this.initServ.ipAddress) {
				this.loader.show();
				await this.loginUsingToken();
			}
		} else if (!this.utilServ.embedStatus && !this.initServ.theme) {
			this.authServ.removeCurrentUser();
		}
	}

	/**
	 * Validates and submits the login form. Checks if the form is valid and
	 * converts the username to lowercase. Depending on the presence of a provider access token,
	 * either triggers a signup or login API request.
	 */
	public submitForm(): void {
		if (!this.loginForm.valid) {
			this.markFormFieldsAsTouched();
			this.toastr.error('Please fill the required fields marked in red.');
			return;
		}

		this.loginForm.controls['userName'].setValue(this.loginForm.controls['userName'].value.toLowerCase());
		let formData = { ...this.loginForm.value };

		if (this.prvdrAccessToken) {
			formData['verification_code'] = this.userAccessToken;
			this.authServ.signupAsPrvdrApi(formData, { redirectUrl : this.redirectUrl, isRedirect : true, loaderId : '', isSession : true, isSetOldData : true });
		} else {
			this.authServ.login(formData, { redirectUrl : this.redirectUrl, loaderId : '', isRedirect : true, isSession : true, isBooknow : false, isSetOldData : false });
			// TODO SONY CONFIRM ABOUT PARAMS FROM HARLEEN MAM
			// this.authServ.login(formData, this.redirectUrl, '', true, true);
		}
	}

	/**
	 * Authenticates the user with an access token. Based on the status of the login attempt,
	 * navigates to the dashboard or login route and shows a success message upon successful login.
	 */
	private async loginUsingToken(): Promise<void> {
		let status = await this.authServ.loginAsUser(this.userAccessToken, '');
		this.loader.hide();

		let route = status ? this.initServ.appDynamicRoutes['dashboard'] : this.initServ.appDynamicRoutes['login'];
		let message = status ? this.initServ.appStr.toastr.loggedIn : '';
		if (status) this.toastr.success(message);
		this.router.navigate([`/${route}`]);
	}

	/**
	 * Fetches a list of unrated bookings for a specific booking ID by calling
	 * the API with query parameters. Subscribes to the response and handles it
	 * by calling a helper function.
	 */
	private getUnRatedBkngs(bid: string): void {
		let queryParams = { bid };
		this.apiServ.callApiWithPathQueryVars('GET', 'NotRatedBkng', [0], queryParams).pipe(takeUntil(this.destroy)).subscribe((resp:any)=>this.handleUnRatedBkngResponse(resp));
	}

	/**
	 * Processes the API response for unrated bookings. Displays a warning
	 * if the booking has already been rated, or opens a rating popup for the first unrated booking
	 * that meets the allowed conditions.
	 */
	private handleUnRatedBkngResponse(resp: any): void {
		if (!this.apiServ.checkAPIRes(resp) || !resp.data?.length) {
			this.toastr.warning('The booking is already rated.');
			return;
		}

		this.unRatedBkngs = resp.data;
		let firstUnrated = this.unRatedBkngs[0];
		if (!this.initServ.ratingAllowedFor.includes(firstUnrated?.industry_id)) return;

		let obj = {
			uid: firstUnrated.uid,
			provider_id: firstUnrated.provider_info?.[0]?.id || 0,
			booking_id: firstUnrated._id,
		};
		firstUnrated.auth_token = this.ratingToken;
		this.popupServ.ratingPopup(this.unRatedBkngs[0], obj, true).pipe(takeUntil(this.destroy)).subscribe((resp: any) => this.handleRatingPopupResponse(resp));
	}

	/**
	 * Processes the response from the rating popup submission. Determines whether to
	 * show a review-sharing popup based on customer settings and the submitted rating.
	 */
	private handleRatingPopupResponse(resp: any): void {
		if (!resp || resp.type !== 'submit') return;
		let shouldShareReview = this.checkShareToThirdPartySites() && this.checkShareReviewForService(resp);

		if (shouldShareReview && this.admnStngs.merchant_settings?.customers?.share_specific_review) {
			if (Number(resp.rating.rating) >= Number(this.admnStngs.merchant_settings.customers.specific_review)) {
				this.popupServ.shareReviewPopup(resp.booking, resp.rating).pipe(takeUntil(this.destroy));
			}
		} else {
			this.popupServ.shareReviewPopup(resp.booking, resp.rating).pipe(takeUntil(this.destroy));
		}
	}

	/**
	 * Checks whether the merchant settings allow sharing reviews to third-party sites.
	 * @returns Returns a boolean indicating the allowed status.
	 */
	private checkShareToThirdPartySites(): boolean {
		return this.admnStngs?.merchant_settings?.customers?.share_to_third_party_sites === 'yes';
	}

	/**
	* Checks if the review can be shared for a specific service based on
	* merchant settings. Returns a boolean indicating the sharing permission.
	*/
	private checkShareReviewForService(resp: any): boolean {
		let { share_review_for_service } = this.admnStngs?.merchant_settings?.customers || {};
		return share_review_for_service?.includes(resp.booking.occurrence) ?? false;
	}

	/**
	 * Marks all form fields as touched, used to show validation errors for
	 * invalid fields in the login form, prompting the user to fill required fields.
	 */
	private markFormFieldsAsTouched(): void {
		Object.values(this.loginForm.controls).forEach(control => control.markAsTouched());
	}
}
