import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, UtilServ} from '../../Services';
// Contants
import { IOS_PROVIDER_APP, ANDROID_PROVIDER_APP } from '../../Constants';

@Component({
	selector: 'bk-app-widgets',
	templateUrl: './AppWidgets.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class AppWidgetsComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	readonly iosProvApp: string = IOS_PROVIDER_APP;
	readonly androidProvApp: string = ANDROID_PROVIDER_APP;
	pageSett: any;
	checkList: string[] = ['checkBookingDetails', 'timeTracking', 'manageScheduleSettings', 'receiveAppReminders']
	onlineList: string[] = ['onlinePricing', 'onlineBooking','onlineManagement'];
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, public utilServ: UtilServ) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.pageSett = this.sectionData.section_settings;
		}
	}
}