<div class="position-relative">
	<div [ngClass]="variationId == 'bk_short_form_V3' ? 'area_version_3' : 'area_version_4 row justify-content-center'" [formGroup]="areaParamForm" [id]="section?.id" *ngIf="settings && section">
		<div [ngClass]="{'col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8' : variationId == 'bk_short_form_V4'}">
			<!-- Title -->
			<div class="mb-30" *ngIf="section?.title">
				<h4 class="mb-0 bk-title" [id]="sectionIds?.title"  [innerHTML]="section?.title | safeHtml" [ngClass]="variationId == 'bk_short_form_V3' ? 'fw-bold' : 'display-5 text-center fw-normal'"></h4>
			</div>
			<div [ngClass]="{'combine-component row justify-content-center' : variationId == 'bk_short_form_V4'}">
				<div [ngClass]="{'col-md-6' : variationId == 'bk_short_form_V4', 'position-absolute':!(services && services.length > 1)}">
					<!-- Service category -->
					<bk-short-form-service-cat
						[sectionIds]="sectionIds"
						[bookingType]="bookingType"
						[variationId]="variationId"
						[section]="parentSection?.service"
						[serviceForm]="areaParamForm"
						[selectedLocation]="selectedLocation"
						[selectedServiceType]="selectedServiceType"
						[selectedFrequency]="selectedFrequency"
						[settings]="settings"
						(serviceChange)="serviceChange.emit($event)"
						(hourlyServiceChange)="hourlyServiceChange.emit()"
						(getServices)="services=$event"
						[isShortForm]="true"
					>
					</bk-short-form-service-cat>
				</div>
				<div [ngClass]="{'col-md-6' : variationId == 'bk_short_form_V4'}">
					<!-- Frequency -->
					<bk-short-form-frequency
						[sectionIds]="sectionIds"
						[variationId]="variationId"
						[bookingType]="bookingType"
						[section]="parentSection?.frequency"
						[frequencyForm]="areaParamForm"
						[selectedLocation]="selectedLocation"
						[selectedServiceType]="selectedServiceType"
						[selectedFrequency]="selectedFrequency"
						[settings]="settings"
						(frequencyChange)="frequencyChange.emit($event)" >
					</bk-short-form-frequency>
				</div>
			</div>
			<bk-short-frm-hourly
				[sectionIds]="sectionIds"
				[bookingType]="bookingType"
				[variationId]="variationId"
				[section]="parentSection?.service"
				[serviceForm]="areaParamForm"
				[selectedLocation]="selectedLocation"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				[settings]="settings"
				(serviceChange)="serviceChange.emit($event)"
				(hourlyServiceChange)="hourlyServiceChange.emit()">

			</bk-short-frm-hourly>
			<div [formGroup]="areaParamGroup" *ngIf="areaParams && areaParams.length > 0">
				<!-- Label -->
				<label [id]="sectionIds?.label" *ngIf="section?.area_label" class="form-label d-block bk-form-label" [ngClass]="{'d-none' : variationId == 'bk_short_form_V4'}" [innerHTML]="section?.area_label | safeHtml"></label>
				<div class="row mx-n10" [ngClass]="{'align-items-center bg-white shadow-sm rounded p-20 px-md-15 py-md-10 mb-15': (variationId == 'bk_short_form_V4')}">
					<!-- Label -->
					<label [id]="sectionIds?.label" *ngIf="section?.area_label" class="form-label d-block bk-form-label" [ngClass]="variationId == 'bk_short_form_V4' ? 'px-5 col-sm-12 col-md-4 mb-md-0 py-md-5' : 'd-none'" [innerHTML]="section?.area_label | safeHtml"></label>
					<div [ngClass]="variationId == 'bk_short_form_V4' ? 'px-5 col-6 col-md-4 tjs-border-start-md' : 'col-lg-6 px-10 mb-lg-0 mb-20'">
						<input class="form-control" type="text" attr.placeholder="{{section?.area_placeholder}}" formControlName="quantity" (keypress)="utilServ.onlyNumbers($event)" (focusout)="areaValueChange()" [ngClass]="{'is-invalid': areaParamGroup.controls['quantity'].hasError('required') && areaParamGroup.controls['quantity'].touched, ' tjs-shadow-sm-none fs-15 tjs-border-md-0 tjs-shadow-none form-control-sm' : variationId == 'bk_short_form_V4'}">
						<!-- Error -->
						<div class="tjs-error bk-error" *ngIf="areaParamGroup.controls['quantity'].hasError('required') && areaParamGroup.controls['quantity'].touched">
							<i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.areaSize | translate}}
						</div>
					</div>
					<div [ngClass]="variationId == 'bk_short_form_V4' ? 'px-5 col-6 col-md-4 tjs-border-start-md' : 'col-lg-6 px-10 mb-lg-0 mb-20'">
						<ng-container *ngFor="let areaParam of areaParams;trackBy: utilServ.trackByFnIndex">
							<div class="d-flex align-items-center">
								<select class="form-select" [ngClass]="{'pe-35 fs-15 form-select-sm tjs-border-md-0 shadow-none' : variationId == 'bk_short_form_V4'}" [(ngModel)]="selectedArea" [ngModelOptions]="{standalone: true}" (change)="changeAreaUnit($event, areaParam)">
									<ng-container *ngIf="areaParam.value && (areaParam.value).length > 0">
										<ng-container *ngFor="let option of areaParam.value; let i=index;trackBy: utilServ.trackByFnIndex">
											<option [value]="option?.id" [selected]="option?.id == areaParam?.selected_value">
												{{utilServ.getFormParamName(option) | translate}}
												<span *ngIf="['reschedule', 'draft'].includes(bookingType) && option.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
											</option>
										</ng-container>
									</ng-container>
								</select>
								<!-- Tooltip -->
								<bk-tooltip *ngIf="selectedParam && selectedParam?.show_tooltip_icon" class="bk-tooltip" content="{{selectedParam?.tooltip_text | translate}}"></bk-tooltip>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>