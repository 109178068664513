import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ } from '../../Services';

@Component({
	selector: 'bk-banner-area',
	templateUrl: './BannerArea.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class BannerAreaComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	variationClass: any = {
		bk_banner_V1 : "tjs-banner--with-text",
		bk_banner_V2 : "tjs-banner__with-text--v2",
		bk_banner_V5 : "tjs-banner--with-text tjs-banner__with-text--v5",
		bk_banner_V6 : "tjs-banner--with-text tjs-banner__with-text--v6"
	}
	// Section fields
	section: any = {
		title: null,
		sub_title: null,
		desc: null,
		media: null,
		give_us_call: null,
		button: null,
		list: null
	}
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
	}
}