<div *ngIf="!hideBar && barVisibility && planPerm && data">
	<ng-container *ngIf="rcServ.statusType == 'mobile_status' || layoutType == 'summary'; else desktop">
		<div class="tjs-couponbar tjs-couponbar--mobile bk-discount-bar" [ngClass]="data?.coupon_bar_type == 'clock' ? mobileVClass[data?.coupon_bar_style?.clock_bar_mobile_layout] : mobileVClass[data?.coupon_bar_style?.text_bar_mobile_layout]" [ngStyle]="{'padding-top': data?.coupon_bar_style?.padding_top && data?.coupon_bar_style?.padding_top, 'padding-bottom': data?.coupon_bar_style?.padding_bottom && data?.coupon_bar_style?.padding_bottom, 'padding-left': data?.coupon_bar_style?.padding_left && data?.coupon_bar_style?.padding_left, 'padding-right': data?.coupon_bar_style?.padding_right && data?.coupon_bar_style?.padding_right, 'background-color': data?.coupon_bar_style?.background_color && data?.coupon_bar_style?.background_color}">
			<div class="d-flex align-items-center">
				<div class="tjs-couponbar__elements d-lg-flex align-items-center justify-content-center w-100">
					<ng-container *ngIf="data?.coupon_bar_type == 'clock'; else mobText">
						<ng-container [ngSwitch]="data?.coupon_bar_style?.clock_bar_mobile_layout">
							<!-- Mobile V1 -->
							<ng-container *ngSwitchCase="'mobile_layout_v1'">
								<div class="d-flex justify-content-between">
									<!-- Title -->
									<ng-container *ngTemplateOutlet="titleTemp; context:{type:'clock'}" ></ng-container>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<div class="d-flex justify-content-between mt-5 flex-wrap">
									<!-- Clock -->
									<ng-container *ngTemplateOutlet="clockTemp"></ng-container>
									<!-- Terms of deal -->
									<ng-container *ngTemplateOutlet="termsOfDealTemp"></ng-container>
								</div>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'', btnClass:'btn w-100 px-10 py-15'}" ></ng-container>
							</ng-container>
							<!-- Mobile V2 -->
							<ng-container *ngSwitchCase="'mobile_layout_v2'">
								<div class="d-flex justify-content-between">
									<div>
										<!-- Title -->
										<ng-container *ngTemplateOutlet="titleTemp; context:{type:'clock', customCls:'d-inline'}"></ng-container>
										<!-- Terms of deal -->
										<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'d-inline ms-10'}"></ng-container>
									</div>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<div class="d-flex justify-content-between mt-5 flex-wrap">
									<!-- Clock -->
									<ng-container *ngTemplateOutlet="clockTemp context:{customCls:'me-10'}"></ng-container>
								</div>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'', btnClass:'btn w-100 px-10 py-15'}" ></ng-container>
							</ng-container>
							<!-- Mobile V3 -->
							<ng-container *ngSwitchCase="'mobile_layout_v3'">
								<div class="d-flex justify-content-between">
									<div class="d-flex justify-content-between flex-wrap">
										<!-- Clock -->
										<ng-container *ngTemplateOutlet="clockTemp context:{customCls:'me-10'}"></ng-container>
									</div>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<div class="text-wrapper">
									<!-- Title -->
									<ng-container *ngTemplateOutlet="titleTemp; context:{type:'clock', customCls:'d-inline'}"></ng-container>
									<!-- Terms of deal -->
									<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'d-inline ms-10'}"></ng-container>
								</div>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'', btnClass:'btn w-100 px-10 py-15'}" ></ng-container>
							</ng-container>
							<!-- Mobile V4 -->
							<ng-container *ngSwitchCase="'mobile_layout_v4'">
								<div class="d-flex justify-content-center">
									<!-- Clock -->
									<ng-container *ngTemplateOutlet="clockTemp context:{customCls:'me-10'}"></ng-container>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<div class="mt-5">
									<!-- Title -->
									<ng-container *ngTemplateOutlet="titleTemp; context:{type:'clock', customCls:'text-center'}"></ng-container>
									<!-- Terms of deal -->
									<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'text-center mt-5'}"></ng-container>
								</div>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'', btnClass:'btn w-100 px-10 py-15'}" ></ng-container>
							</ng-container>
							<!-- Mobile V5 -->
							<ng-container *ngSwitchCase="'mobile_layout_v5'">
								<div class="d-flex justify-content-center">
									<!-- Title -->
									<ng-container *ngTemplateOutlet="titleTemp; context:{type:'clock', customCls:'text-center'}"></ng-container>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<div class="d-flex justify-content-center">
									<!-- Clock -->
									<ng-container *ngTemplateOutlet="clockTemp context:{customCls:'me-10'}"></ng-container>
								</div>
								<!-- Terms of deal -->
								<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'text-center mt-10'}"></ng-container>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'', btnClass:'btn w-100 px-10 py-15'}" ></ng-container>
							</ng-container>
						</ng-container>
					</ng-container>
					<!-- Mobile text -->
					<ng-template #mobText>
						<ng-container [ngSwitch]="data?.coupon_bar_style?.text_bar_mobile_layout">
							<!-- Mobile V6 -->
							<ng-container *ngSwitchCase="'mobile_layout_v6'">
								<div class="d-flex justify-content-between">
									<div class="text-start">
										<!-- Title -->
										<ng-container *ngTemplateOutlet="titleTemp; context:{customCls:'d-inline'}"></ng-container>
										<!-- Terms of deal -->
										<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'d-inline ms-10'}"></ng-container>
									</div>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'', btnClass:'btn w-100 px-10 py-15'}" ></ng-container>
							</ng-container>
							<!-- Mobile V7 -->
							<ng-container *ngSwitchCase="'mobile_layout_v7'">
								<div class="d-flex justify-content-between">
									<div class="text-start text-wrapper">
										<!-- Title -->
										<ng-container *ngTemplateOutlet="titleTemp"></ng-container>
										<!-- Terms of deal -->
										<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'text-start'}"></ng-container>
									</div>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'', btnClass:'btn w-100 px-10 py-15'}" ></ng-container>
							</ng-container>
							<!-- Mobile V8 -->
							<ng-container *ngSwitchCase="'mobile_layout_v8'">
								<div class="d-flex justify-content-center">
									<div class="text-center">
										<!-- Title -->
										<ng-container *ngTemplateOutlet="titleTemp; context:{customCls:'text-center'}"></ng-container>
									</div>
									<!-- Cross icon -->
									<ng-container *ngTemplateOutlet="closeBar"></ng-container>
								</div>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'mt-15 text-center w-70 mx-auto'}" ></ng-container>
								<!-- Terms of deal -->
								<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'text-center'}"></ng-container>
							</ng-container>
						</ng-container>
					</ng-template>
				</div>
			</div>
		</div>
	</ng-container>
	<!-- Desktop -->
	<ng-template #desktop>
		<div class="tjs-couponbar" [ngClass]="data?.coupon_bar_type == 'clock' ? desktopVClass[data?.coupon_bar_style?.clock_bar_desktop_layout] : desktopVClass[data?.coupon_bar_style?.text_bar_desktop_layout]" [ngStyle]="{'padding-top': data?.coupon_bar_style?.padding_top && data?.coupon_bar_style?.padding_top, 'padding-bottom': data?.coupon_bar_style?.padding_bottom && data?.coupon_bar_style?.padding_bottom, 'padding-left': data?.coupon_bar_style?.padding_left && data?.coupon_bar_style?.padding_left, 'padding-right': data?.coupon_bar_style?.padding_right && data?.coupon_bar_style?.padding_right, 'background-color': data?.coupon_bar_style?.background_color && data?.coupon_bar_style?.background_color}">
			<div class="{{(data?.coupon_bar_type == 'clock' || data?.coupon_bar_style?.text_bar_desktop_layout != 'bar_layout_v7') ? 'd-flex align-items-center': ''}}">
				<ng-container *ngIf="data?.coupon_bar_type == 'clock'; else desktopText">
					<!-- v1,v2,v3&v4 -->
					<div class="tjs-couponbar__elements d-flex flex-column flex-lg-row align-items-center justify-content-center w-100">
						<!-- Clock -->
						<ng-container *ngIf="data?.coupon_bar_style?.clock_bar_desktop_layout == 'bar_layout_v2' || data?.coupon_bar_style?.clock_bar_desktop_layout == 'bar_layout_v4'">
							<ng-container *ngTemplateOutlet="clockTemp"></ng-container>
						</ng-container>
						<!-- Title -->
						<ng-container *ngTemplateOutlet="titleTemp; context:{type:'clock'}" ></ng-container>
						<!-- Clock -->
						<ng-container *ngIf="data?.coupon_bar_style?.clock_bar_desktop_layout != 'bar_layout_v2' && data?.coupon_bar_style?.clock_bar_desktop_layout != 'bar_layout_v4'">
							<ng-container *ngTemplateOutlet="clockTemp"></ng-container>
						</ng-container>
						<!-- Terms of deal -->
						<ng-container *ngTemplateOutlet="termsOfDealTemp"></ng-container>
						<!-- Button -->
						<ng-container *ngTemplateOutlet="btnTemp" ></ng-container>
					</div>
					<!-- Cross icon -->
					<ng-container *ngTemplateOutlet="closeBar"></ng-container>
				</ng-container>
				<!-- Text -->
				<ng-template #desktopText>
					<ng-container [ngSwitch]="data?.coupon_bar_style?.text_bar_desktop_layout">
						<!-- Desktop text variation 5 -->
						<ng-container *ngSwitchCase="'bar_layout_v5'">
							<div class="tjs-couponbar__elements d-lg-flex align-items-center justify-content-center w-100">
								<!-- Title -->
								<ng-container *ngTemplateOutlet="titleTemp"></ng-container>
								<!-- Terms of deal -->
								<ng-container *ngTemplateOutlet="termsOfDealTemp"></ng-container>
								<!-- Button -->
								<ng-container *ngTemplateOutlet="btnTemp" ></ng-container>
							</div>
							<!-- Cross icon -->
							<ng-container *ngTemplateOutlet="closeBar"></ng-container>
						</ng-container>
						<!-- Desktop text variation 6 -->
						<ng-container *ngSwitchCase="'bar_layout_v6'">
							<div class="tjs-couponbar__elements d-lg-flex align-items-center justify-content-center w-100">
								<!-- Title -->
								<ng-container *ngTemplateOutlet="titleTemp"></ng-container>
								<!-- Terms of deal -->
								<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'ms-15'}"></ng-container>
							</div>
							<!-- Button -->
							<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'w-20 ms-10'}" ></ng-container>
							<!-- Cross icon -->
							<ng-container *ngTemplateOutlet="closeBar"></ng-container>
						</ng-container>
						<!-- Desktop text variation 7 -->
						<ng-container *ngSwitchCase="'bar_layout_v7'">
							<div class="d-flex align-items-center">
								<div class="tjs-couponbar__elements d-lg-flex align-items-center justify-content-center w-100">
									<!-- Title -->
									<ng-container *ngTemplateOutlet="titleTemp"></ng-container>
								</div>
								<!-- Cross icon -->
								<ng-container *ngTemplateOutlet="closeBar"></ng-container>
							</div>
							<!-- Terms of deal -->
							<ng-container *ngTemplateOutlet="termsOfDealTemp; context:{customCls:'text-center'}"></ng-container>
							<!-- Button -->
							<ng-container *ngTemplateOutlet="btnTemp; context:{customCls:'text-center'}" ></ng-container>
						</ng-container>
					</ng-container>
				</ng-template>
			</div>
		</div>
	</ng-template>
</div>
<!-- Clock -->
<ng-template #clockTemp let-customCls="customCls">
	<bk-count-down class="tjs-bk-countdown {{customCls}}" [clockUnitType]="isMobile ? 'short' : data?.coupon_bar_style?.clock_unit_type" [clockLayout]="data?.coupon_bar_style?.clock_layout" [data]="data" (callback)="hideBlock()" [unitClr]="data?.coupon_bar_style?.clock_unit_color" [timerClr]="data?.coupon_bar_style?.clock_timer_color" [timerFs]="data?.coupon_bar_style?.clock_timer_font_size" [unitFs]="data?.coupon_bar_style?.clock_unit_font_size" [isMobile]="isMobile"></bk-count-down>
</ng-template>
<!-- Title -->
<ng-template #titleTemp let-type="type" let-customCls="customCls">
	<ng-container *ngIf="type == 'clock'; else textType">
		<div class="tjs-couponbar__text {{customCls}}" [innerHTML]="((data?.translated_fields && data?.translated_fields?.clock_bar_text) ? data?.translated_fields?.clock_bar_text : data.clock_bar_text) | safeHtml"></div>
	</ng-container>
	<ng-template #textType>
		<div class="tjs-couponbar__text {{customCls}}" [innerHTML]="((data?.translated_fields && data?.translated_fields?.coupon_bar_text) ? data?.translated_fields?.coupon_bar_text : data.coupon_bar_text) | safeHtml"></div>
	</ng-template>
</ng-template>
<!-- Terms of deal -->
<ng-template #termsOfDealTemp let-customCls="customCls">
	<div class="tjs-couponbar__term {{customCls}}" *ngIf="data?.terms_of_deal_status == 'yes' && data?.terms_of_deal_label">
		<a href="#" data-bs-toggle="modal" data-bs-target="#bkngTermsOfDealModel" class="text-white fs-14 bk-discount-term" [ngStyle]="{'color': data?.coupon_bar_style?.terms_of_deal_color && data?.coupon_bar_style?.terms_of_deal_color, 'font-size': data?.coupon_bar_style?.terms_of_deal_font_size && data?.coupon_bar_style?.terms_of_deal_font_size}" (click)="utilServ.showPopup('bkngTermsOfDealModel')">{{(data?.translated_fields && data?.translated_fields?.terms_of_deal_label) ? data?.translated_fields?.terms_of_deal_label : data?.terms_of_deal_label}}</a>
	</div>
</ng-template>
<!-- Modal for 'Terms of deal' -->
<div class="modal fade discount-bar-modal" id="bkngTermsOfDealModel" tabindex="-1" role="dialog" aria-labelledby="bkngTermsOfDealModelOpened" style="display: none;" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title" id="bkngTermsOfDealModelOpened">{{(data?.translated_fields && data?.translated_fields?.terms_of_deal_label) ? data?.translated_fields?.terms_of_deal_label : data?.terms_of_deal_label}}</h3>
				<button type="button" class="btn-close fs-13 tjs-popup__closed" data-bs-dismiss="modal" aria-hidden="true"><i class="tjsicon-close text-danger"></i></button>
			</div>
			<div class="modal-body">
				<div class="container" [innerHTML]="((data?.translated_fields && data?.translated_fields?.terms_of_deal_content) ? data?.translated_fields?.terms_of_deal_content : data?.terms_of_deal_content) | safeHtml"></div>
			</div>
		</div>
	</div>
</div>
<!-- Button template -->
<ng-template #btnTemp let-customCls="customCls" let-btnClass="btnClass">
	<div class="tjs-couponbar__button {{customCls}}" [id]="btnData?.id" *ngIf="btnData && btnData?.buttons && (btnData?.buttons).length > 0">
		<ng-container *ngFor="let btn of btnData?.buttons;trackBy: secServ.trackByFnIndex">
			<bk-navigate [secSett]="btn" class="mx-1" [innerHTML]="btn?.content" [customClass]="btnClass ? btnClass : ''"></bk-navigate>
		</ng-container>
	</div>
</ng-template>
<!-- Close bar -->
<ng-template #closeBar>
	<div class="tjs-couponbar__close" [class.ms-10]="!(data?.coupon_bar_style?.clock_bar_desktop_layout == 'bar_layout_v5' || data?.coupon_bar_style?.clock_bar_desktop_layout == 'bar_layout_v7')">
		<button type="button" class="close fs-12 p-5 rounded bg-white" data-bs-dismiss="alert" aria-label="Close" (click)="hideBlock()"><span aria-hidden="true" class="tjsicon-close"></span></button>
	</div>
</ng-template>
