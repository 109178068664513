import { Injectable, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppService } from '../app.service';

@Injectable({ providedIn: 'root' })
export class LoadCompService {

	private isLoadComp = new BehaviorSubject(false);
	isLoadComp$ = this.isLoadComp.asObservable();

	constructor(private appService: AppService) {}

	/**
	 * Load the dynamic components
	 * @param vcr: view container
	 * @param type: component name
	 * @returns component
	 */
	public loadDynamicComponent(vcr: ViewContainerRef, type: string='') {
		vcr.clear();
		return this.appService.forChild(vcr, {
			loadChildren: this.loadComp(type)
		});
	}
	/**
	 * Load the component file
	 */
	private loadComp(type: string = ''){
		switch(type){
			case 'giftCard':
				return () =>import('../GiftCards').then(m => m.GiftCardsComponent);
			case 'leadForm':
				return () =>import('../LeadForm/Lead.component').then(m => m.LeadComponent);
			default:
				return () =>import('../Session').then(m => m.SessionComponent);
		}
	}
}