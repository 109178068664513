import { Injectable } from '@angular/core';
// Services
import { InitServ } from '../';
import { Param } from 'src/app/Interfaces/Booking.interface';

@Injectable({
	providedIn: 'root'
})
export class CalCmnFuncServ {

	// private variables
	private admnStngs: any;
	constructor(private initServ: InitServ) {
		this.admnStngs = this.initServ.appAdmnStngs; // App admin settings
	}
	/**
	 * Function that checks if a value exists by determining whether it is undefined or not.
	 * It returns true if the value is not undefined, and false otherwise.
	 * @param val Value which we want to check
	 * @returns Boolean (true/false)
	 */
	public isValExist(val: any): boolean {
		return (val != undefined) ? true : false;
	}
	/**
	 * Function that rounds a given number to two decimal places.
	 * It multiplies the number by 100, rounds it to the nearest whole number, and then divides it by 100 to get the rounded value.
	 * The function returns the rounded value.
	 * @param number
	 * @returns
	 */
	public roundToTwo(number: number): any {
		return Math.round(number * 100) / 100;
	}
	/**
	 * Function that takes two variables varA and varB as inputs.
	 * It converts the variables to numbers using the + operator and then adds them together.
	 * The function returns the sum of the two variables.
	 * @param varA
	 * @param varB
	 * @returns
	 */
	public sumFunc(varA: any, varB: any){
		return ((+varA) + (+varB));
	}
	/**
	 * Function named subFunc that takes in two variables varA and varB.
	 * It uses the + operator to convert the variables to numbers and then subtracts varB from varA. The function returns the result of the subtraction.
	 * @param varA
	 * @param varB
	 * @returns
	 */
	public subFunc(varA: any, varB: any){
		return ((+varA) - (+varB));
	}
	/**
	 * Function named isReschedule that takes in a variable bookingType.
	 * It checks if bookingType is equal to the string 'reschedule', and if it is, it returns true. Otherwise, it returns false.
	 * @param bookingType
	 * @returns
	 */
	public isReschedule(bookingType: any){
		return (bookingType == 'reschedule') ? true : false;
	}
	/**
	 * Function named isServiceFeeTaxable that takes in a variable isServiceFeeTaxable.
	 * It checks if isServiceFeeTaxable is equal to the string 'yes', and if it is, it returns true. Otherwise, it returns false.
	 * @param isServiceFeeTaxable
	 * @returns
	 */
	public isServiceFeeTaxable(isServiceFeeTaxable: any){
		if(isServiceFeeTaxable && isServiceFeeTaxable == 'yes'){
			return true
		}
		return false;
	}
	/**
	 * Function named isRecIdEqualBkngId that checks if recFirstBkngId is equal to bookingId.
	 * It does this by converting both variables to numbers and comparing them. If they are equal, it returns true, otherwise it returns false.
	 * @param recFirstBkngId
	 * @param bookingId
	 * @returns
	 */
	public isRecIdEqualBkngId(recFirstBkngId: any, bookingId: any){
		if(recFirstBkngId && +recFirstBkngId == (+bookingId)){
			return true;
		}
		return false;
	}
	/**
	 * Function named isEqual that checks if two parameters are equal.
	 * It does this by using the equality operator (==) to compare the parameters. If they are equal, the function returns true. Otherwise, it returns false.
	 * @param paramOne
	 * @param paramTwo
	 * @returns
	 */
	public isEqual(paramOne: any, paramTwo: any){
		if(paramOne ==  paramTwo){
			return true;
		}
		return false;
	}
	/**
	 * Function named isExpeditedAmount that checks if a booking is of the same day and has an expedited amount.
	 * It also checks if excludeExpeditedAmount is not true. If all these conditions are met, the function returns true. Otherwise, it returns false.
	 * @param BKFrmValue
	 * @param excludeExpeditedAmount
	 * @returns
	 */
	public isExpeditedAmount(BKFrmValue: any, excludeExpeditedAmount: any){
		if(BKFrmValue.same_day_booking && BKFrmValue.expedited_amount && (!excludeExpeditedAmount)){
			return true;
		}
		return false;
	}
	/**
	 * Function named calcExpeditedForRecApt that takes in three parameters: BKFrmValue, excludeExpeditedAmount, and amountWithoutFrequencyDiscount.
	 * It calls the isExpeditedAmount function is to check if the booking has an expedited amount and if excludeExpeditedAmount is not true.
	 * If these conditions are met, it subtracts the expedited_amount from amountWithoutFrequencyDiscount and returns the updated value.
	 * @param BKFrmValue
	 * @param excludeExpeditedAmount
	 * @param amountWithoutFrequencyDiscount
	 * @returns
	 */
	public calcExpeditedForRecApt(BKFrmValue: any, excludeExpeditedAmount: any, amountWithoutFrequencyDiscount: any){
		if(this.isExpeditedAmount(BKFrmValue, excludeExpeditedAmount)){
			amountWithoutFrequencyDiscount = (+amountWithoutFrequencyDiscount) - (+BKFrmValue.expedited_amount);
		}
		return amountWithoutFrequencyDiscount;
	}

	/**********Service related functions***************/

	/**
	 * Function named ifPriceBaseOfHSCustom that takes in a parameter selectedService.
	 * It checks if selectedService has a property hourly_serv_price_on and if it is either falsy or equal to the string 'custom_time'.
	 * If either of these conditions is true, the function returns true, otherwise it returns false.
	 * @param selectedService : service
	 * @returns boolean
	 */
	public ifPriceBaseOfHSCustom(selectedService: any): boolean{
		if(!selectedService?.hourly_serv_price_on || (selectedService?.hourly_serv_price_on && selectedService?.hourly_serv_price_on == 'custom_time')){
			return true;
		}
		return false;
	}
	/**
	 * Function named checkIfServHoulyBasedOnParam that takes in a parameter selectedService.
	 * It checks various conditions using logical operators and function calls, and returns true if any of the conditions are true.
	 * Otherwise, it returns false.
	 * @param inptObj
	 * @returns
	 */
	public checkIfServHoulyBasedOnParam(selectedService: any){
		if(selectedService && (selectedService?.is_hourly_service != 'yes' || (this.isHourlyAndExtraIncludeInPrice(selectedService) && this.ifPriceBaseOfHSCustom(selectedService)) || this.isHourlyAndPriceBasedOnParam(selectedService))){
			return true;
		}
		return false;
	}
	/**
	 * Function named isHourlyAndExtraIncludeInPrice that takes in a parameter selectedService.
	 * It checks if the selectedService has a property called is_hourly_service with a value of 'yes', and if the isIncludeExtraInPrice function returns true.
	 * If both conditions are true, the function returns true, otherwise it returns false.
	 * @param inptObj
	 * @returns
	 */
	public isHourlyAndExtraIncludeInPrice(selectedService: any){
		if(selectedService?.is_hourly_service == 'yes' && this.isIncludeExtraInPrice(selectedService)){
			return true
		}
		return false;
	}
	/**
	 * Function checks if a service is hourly and if the price is based on parameters.
	 * If both conditions are true, the function returns true, otherwise it returns false.
	 * @param inptObj
	 * @returns
	 */
	public isHourlyAndPriceBasedOnParam(selectedService: any){
		if(selectedService?.is_hourly_service == 'yes' && this.isHourlyServPriceBasedOnParam(selectedService)){
			return true
		}
		return false;
	}
	public isHourlyAndPriceBasedOnCustom(selectedService: any){
		if(selectedService?.is_hourly_service == 'yes' && this.ifPriceBaseOfHSCustom(selectedService)){
			return true
		}
		return false;
	}
	/**
	 * Function checks if the include_extra_in_price property of the selectedService object exists and if its value is equal to 'yes'.
	 * If both conditions are true, the function returns true. Otherwise, it returns false.
	 * @param inptObj
	 * @returns
	 */
	public isIncludeExtraInPrice(selectedService: any){
		if(selectedService?.include_extra_in_price && selectedService?.include_extra_in_price == 'yes'){
			return true;
		}
		return false;
	}
	/**
	 * Function called isHourlyServPriceBasedOnParam which takes in a parameter called selectedService.
	 * The function checks if the hourly_serv_price_on property of the selectedService object exists and if its value is equal to 'pricing_param_time'.
	 * If both conditions are true, the function returns true. Otherwise, it returns false.
	 * @param selectedService
	 * @returns
	 */
	public isHourlyServPriceBasedOnParam(selectedService: any){
		if(selectedService?.hourly_serv_price_on && selectedService?.hourly_serv_price_on == 'pricing_param_time'){
			return true;
		}
		return false;
	}
	/**
	 * Function called isServCatPrice which takes in a parameter called selectedService.
	 * The function checks if the enable_service_category_price property and the service_category_price property of the selectedService object both exist.
	 * If both conditions are true, the function returns true. Otherwise, it returns false.
	 * @param selectedService
	 * @returns
	 */
	public isServCatPrice(selectedService: any){
		if(selectedService?.enable_service_category_price && selectedService?.service_category_price){
			return true;
		}
		return false;
	}
	/**
	 * Function called isServCatTime which takes in a parameter called selectedService.
	 * The function checks if the enable_service_category_time property and the service_category_time property of the selectedService object both exist.
	 * If both conditions are true, the function returns true. Otherwise, it returns false.
	 * @param selectedService
	 * @returns
	 */
	public isServCatTime(selectedService: any){
		if(selectedService?.enable_service_category_time && selectedService?.service_category_time){
			return true;
		}
		return false;
	}
	/**
	 * Function called isHourlyService which takes in a parameter called selectedService.
	 * The function checks if the is_hourly_service property of the selectedService object is equal to 'yes'.
	 * If it is, the function returns true. Otherwise, it returns false.
	 * @param inptObj
	 * @returns
	 */
	public isHourlyService(selectedService: any){
		return (selectedService?.is_hourly_service == 'yes') ? true : false;
	}
	/**
	 * Checks if the is_override_pricing property of the selectedService object is equal to 'yes' and if the override_pricing property is defined.
	 * If both conditions are true, the function returns true. Otherwise, it returns false.
	 * @param inptObj
	 */
	public isOverridePricing(selectedService: any){
		if(selectedService?.is_override_pricing == 'yes' && selectedService?.override_pricing !== undefined){
			return true;
		}
		return false;
	}
	/**
	 * Checks if the service_fee property of the BKFrmValue object is truthy and if the excludeServiceFee parameter is falsy.
	 * If both conditions are true, the function returns true. Otherwise, it returns false.
	 * @param BKFrmValue
	 * @param excludeServiceFee
	 * @returns
	 */
	public isServiceFeeApplicable(BKFrmValue: any, excludeServiceFee:any){
		if(BKFrmValue?.service_fee && !excludeServiceFee){
			return true;
		}
		return false;
	}
	/**
	 * Checks if priceLocalVar.isServiceFeeTaxable is equal to 'yes' and if the isServiceFeeApplicable function returns true when given BKFrmValue and priceLocalVar.excludeServiceFeeValue as arguments.
	 * If both conditions are true, the code proceeds to update priceLocalVar.displayServiceFee and priceLocalVar.displayDiscountedAmount with the values from BKFrmValue.service_fee.
	 * Finally, the function returns an object containing the updated displayServiceFee and displayDiscountedAmount.
	 * @param BKFrmValue
	 * @param priceLocalVar
	 * @returns
	 */
	public calcTaxableServFee(BKFrmValue: any, priceLocalVar: any){
		if(priceLocalVar.isServiceFeeTaxable == 'yes' && this.isServiceFeeApplicable(BKFrmValue, priceLocalVar.excludeServiceFeeValue)){
			priceLocalVar.displayServiceFee = this.roundToTwo(BKFrmValue.service_fee);
			priceLocalVar.displayDiscountedAmount = this.roundToTwo(this.sumFunc(priceLocalVar.displayDiscountedAmount, BKFrmValue.service_fee));
		}
		return {
			displayServiceFee: priceLocalVar.displayServiceFee,
			displayDiscountedAmount: priceLocalVar.displayDiscountedAmount
		}
	}
	/************************Frequnecy based functions*******************/

	/**
	 * Function checks if a condition is met: if inptObj.selectedFrequency is truthy and either this.isReschedule(inptObj.bookingType) is false or this.checkRescFreq(BKFrmValue, inptObj) returns true.
	 * If the condition is met, the function returns true, otherwise, it returns false.
	 * @param BKFrmValue
	 * @param inptObj
	 * @returns
	 */
	public isFreqDiscAppliOnFirstAndNth(BKFrmValue: any, inptObj: any){
		if(inptObj.selectedFrequency && (!this.isReschedule(inptObj.bookingType) || this.checkRescFreq(BKFrmValue, inptObj))){
			return true;
		}
		return false;
	}
	/**
	 * Checks if the function "isReschedule" returns true for the "bookingType" property of "inptObj" and if the function "isDuplicateOrFreqChang" returns true for the "BKFrmValue" and "prefilledData" properties of "inptObj".
	 * If both conditions are true, the function returns true. Otherwise, it returns false.
	 * @param BKFrmValue
	 * @param inptObj
	 * @returns
	 */
	public checkRescFreq(BKFrmValue: any, inptObj: any){
		if(this.isReschedule(inptObj.bookingType) && this.isDuplicateOrFreqChang(BKFrmValue, inptObj.prefilledData)){
			return true
		}
		return false;
	}
	/**
	 * Checks if the "status" property of "prefilledData" is equal to 9 or if the "frequency" property of "prefilledData" is not equal to the "frequency" property of "BKFrmValue".
	 * If either of these conditions is true, the function returns true. Otherwise, it returns false.
	 * @param BKFrmValue
	 * @param prefilledData
	 * @returns
	 */
	public isDuplicateOrFreqChang(BKFrmValue: any, prefilledData: any){
		if(prefilledData?.status == 9 || !this.isEqual(prefilledData.frequency, BKFrmValue.frequency)){
			return true;
		}
		return false;
	}
	/**
	 * Checks if the "selectedFrequency" property of the input object exists and if either of two conditions is true.
	 * The first condition is if the "frequency_discount_on_bookings" property of the "form_frequency_data" property of the "selectedFrequency" property is equal to 'all-bookings'.
	 * The second condition is if the result of the "isRecIdEqualBkngId" function with the parameters "recFirstBkngId" and "bookingId" is false.
	 * If either of these conditions is true, the function returns true. Otherwise, it returns false.
	 * @param inptObj
	 * @returns
	 */
	public checkFreqDiscForResc(inptObj: any){
		if(inptObj.selectedFrequency && (inptObj?.selectedFrequency?.form_frequency_data?.frequency_discount_on_bookings == 'all-bookings' || !this.isRecIdEqualBkngId(inptObj.recFirstBkngId, inptObj.bookingId))){
			return true;
		}
		return false;
	}
	/**
	 * Checks if a coupon is applied and if the coupon allows applying the frequency discount.
	 * If either of these conditions is true, the function returns true. Otherwise, it returns false.
	 * @param BKFrmValue : form control
	 * @returns boolean
	 */
	public isFrequencyDisWithCoupon(BKFrmValue: any): boolean{
		// Check if coupon applied then if coupon allow to apply the frequency discount.
		if(!BKFrmValue?.coupon?.discount || (BKFrmValue?.coupon?.discount && this.isFreqDiscWithCoupon(BKFrmValue))){
			return true;
		}
		return false;
	}
	/**
	 * Checks if a coupon is applied and if the coupon allows applying the frequency discount.
	 * If either of these conditions is true, the function returns true. Otherwise, it returns false.
	 * @param BKFrmValue
	 * @returns
	 */
	public isFreqDiscWithCoupon(BKFrmValue: any){
		if(!BKFrmValue?.coupon?.apply_freq_disc_with_coupon || (BKFrmValue?.coupon?.apply_freq_disc_with_coupon == 'yes')){
			return true;
		}
		return false;
	}
	/**
	 * Checks if an object extra has a property exempt_extra_from_freq_disc and if its value is true.
	 * If these conditions are met, the function returns true. Otherwise, it returns false.
	 * @param extra : extra
	 * @returns boolean
	 */
	public isExtraExcFromFreqDis(extra: any): boolean{
		if(Object.prototype.hasOwnProperty.call(extra, "exempt_extra_from_freq_disc") && extra?.exempt_extra_from_freq_disc){
			return true
		}
		return false;
	}
	/**
	 * Checks if the input parameter selectedFrequency has an property form_frequency_data.occurence_time and if its value is 'recurring'.
	 * If these conditions are met, the function returns true. Otherwise, it returns false.
	 * @param inptObj
	 * @returns
	 */
	public isRecurring(selectedFrequency: any){
		if(this.isValExist(selectedFrequency?.form_frequency_data?.occurence_time) && selectedFrequency?.form_frequency_data?.occurence_time == 'recurring'){
			return true;
		}
		return false;
	}
	/**
	 * Checks if the input parameter selectedFrequency has the property form_frequency_data.occurence_time and if its value is 'recurring'.
	 * If any of the conditions defined inside the function are true, it returns true. Otherwise, it returns false.
	 * @param BKFrmValue
	 * @param inptObj
	 * @returns
	 */
	public checkFreqDiscApplyonRec(BKFrmValue: any, selectedFrequency: any){
		if(this.isExcludeFirstBooking(selectedFrequency) || (this.isShorterJobLength(selectedFrequency) && selectedFrequency?.form_frequency_data?.exclude_first_apt_for_shorter_length) || this.isCouponNotApplyOnRec(BKFrmValue) || this.isParamThisOnly(BKFrmValue.extras) || this.isParamThisOnly(BKFrmValue.partial_cleaning)){
			return true
		}
		return false;
	}
	/**
	 * Check if param(extra or exclude) only applicable to first booking
	 * @param Params
	 * @returns
	 */
	public isParamThisOnly(Params: Param[]): boolean{
		if(Params && Params.length > 0){
			for(let param of Params){
				if(param?.apply_on_bookings == 'first-only'){
					return true
				}
			}
		}
		return false;
	}
	/**
	 * Checks if the property occurence_time exists in the form_frequency_data object of the selectedFrequency.
	 * If it does and its value is 'recurring', the function returns true. Otherwise, it returns false.
	 * @param inptObj
	 * @returns
	 */
	public isExcludeFirstBooking(selectedFrequency: any){
		if(selectedFrequency?.form_frequency_data?.frequency_discount_on_bookings == 'exclude-first-booking'){
			return true;
		}
		return false;
	}
	/**
	 * Checks if the property shorter_job_length exists in the form_frequency_data object of the selectedFrequency.
	 * If it does and its value is 'yes', the function returns true. Otherwise, it returns false.
	 * @param inptObj
	 * @returns
	 */
	public isShorterJobLength(selectedFrequency: any){
		if(this.isValExist(selectedFrequency?.form_frequency_data?.shorter_job_length) && selectedFrequency?.form_frequency_data?.shorter_job_length == 'yes'){
			return true;
		}
		return false;
	}
	/**
	 * Checks if the property shorter_job_length exists in the form_frequency_data object of the selectedFrequency.
	 * If it does and its value is 'yes', the function calls the shorterJobLengthVal function with the selectedFrequency, totalTimeValue, and newTotalTime parameters.Finally, the function returns the newTotalTime value, rounded to 0 decimal places.
	 * @param selectedFrequency : frequency
	 * @param totalTimeValue : time
	 * @returns number
	 */
	public CBTTPartTwo(selectedFrequency: any, totalTimeValue: number): number{
		let newTotalTime = totalTimeValue;
		if(selectedFrequency?.form_frequency_data?.shorter_job_length == 'yes'){
			newTotalTime = this.shorterJobLengthVal(selectedFrequency, totalTimeValue, newTotalTime);
		}
		return +(Number(newTotalTime).toFixed(0));
	}
	/**
	 * Checks if the property shorter_job_length exists in the form_frequency_data object of the selectedFrequency.
	 * If it does and its value is 'yes', the function performs some calculations to determine a new value for newTotalTime.
	 * Calculates cutOffPercentage by extracting the value of shorter_job_length_value from the form_frequency_data object and parsing it as a number.
	 * Then, it calculates cutOffPerctValue by dividing totalTimeValue by 100 and multiplying it by cutOffPercentage.
	 * Next, it sets newTotalTime to 0.
	 * If totalTimeValue is greater than cutOffPerctValue, it subtracts cutOffPerctValue from totalTimeValue and assigns the result to newTotalTime.
	 * Finally, the function returns newTotalTime, rounded to 2 decimal places.
	 * @param selectedFrequency
	 * @param totalTimeValue
	 * @param newTotalTime
	 * @returns
	 */
	public shorterJobLengthVal(selectedFrequency: any, totalTimeValue: any, newTotalTime: any){
		if(this.isShortJobLengthVal(selectedFrequency)){
			let cutOffPercentage = (+selectedFrequency?.form_frequency_data?.shorter_job_length_value);
			let cutOffPerctValue = +this.roundToTwo(((totalTimeValue / 100) * cutOffPercentage));
			newTotalTime = 0;
			if((totalTimeValue > cutOffPerctValue) || (totalTimeValue < 0)){
				newTotalTime = this.roundToTwo((totalTimeValue) - (cutOffPerctValue));
			}
		}
		return newTotalTime;
	}
	/**
	 * Checks if the property shorter_job_length_value exists in the form_frequency_data object of selectedFrequency.
	 * If it does and its value is greater than 0, the function returns true. Otherwise, it returns false.
	 * @param selectedFrequency
	 * @returns
	 */
	public isShortJobLengthVal(selectedFrequency: any){
		if(selectedFrequency?.form_frequency_data?.shorter_job_length_value && selectedFrequency?.form_frequency_data?.shorter_job_length_value > 0){
			return true;
		}
		return false;
	}
	/**
	 *  Inside this function, a newTotalTime value is calculated by calling a function named CBTTPartTwo, passing in the selectedFrequency and extraTimeValue as arguments.
	 * There is an if condition that checks if the recFirstBkngId property of inptObj is equal to the bookingId property of inptObj, or if the prefilledData.frequency property of inptObj is not equal to the frequencyValue.
	 * If either condition is true, a function named timeIfExcFrstFrmShortLength is called, passing in the selectedFrequency, extraTimeValue, and newTotalTime as arguments, and the result is assigned to the extraTimeValue variable. Otherwise, the extraTimeValue is set to the value of newTotalTime.
	 * Finally, the function returns the value of extraTimeValue.
	 * @param selectedFrequency
	 * @param extraTimeValue
	 * @param recFirstBkngId
	 * @param bookingId
	 * @param prefilledData
	 * @param frequencyValue
	 * @returns
	 */
	public checkForShorterLengthForExtraTimeRes(extraTimeValue: any, frequencyValue: any, inptObj: any){
		let newTotalTime = this.CBTTPartTwo(inptObj.selectedFrequency, extraTimeValue);
		if(this.isRecIdEqualBkngId(inptObj.recFirstBkngId, inptObj.bookingId) || !this.isEqual(inptObj.prefilledData.frequency, frequencyValue) || (inptObj.prefilledData && inptObj.prefilledData?.status == 9)){
			extraTimeValue = this.timeIfExcFrstFrmShortLength(inptObj.selectedFrequency, extraTimeValue, newTotalTime);
		}else{
			extraTimeValue = +newTotalTime;
		}
		return extraTimeValue;
	}
	/**
	 * Inside the function, a local variable newTotalTime is assigned the value returned by calling a function CBTTPartTwo with selectedFrequency and extraTimeValue as arguments.
	 * Next, there is an if statement that checks if the function isExcFirstFromShorterLength returns false when passed selectedFrequency as an argument.
	 * If the condition is true, the extraTimeValue is reassigned the value of newTotalTime.
	 * Finally, the function returns extraTimeValue.
	 * @param selectedFrequency : frequency
	 * @param extraTimeValue : extra time value
	 * @returns number
	 */
	public checkForShorterLengthForExtraTime(selectedFrequency: any, extraTimeValue: any): any{
		let newTotalTime = this.CBTTPartTwo(selectedFrequency, extraTimeValue);
		if(!this.isExcFirstFromShorterLength(selectedFrequency)){
			extraTimeValue = +newTotalTime;
		}
		return extraTimeValue;
	}
	public addFieldsTimeForhourlyCustomServ(BKFrmValue: any, inptObj: any){
		let addFieldsTime = BKFrmValue.add_fields_time;
		addFieldsTime = this.isReschedule(inptObj.bookingType) ? this.checkForShorterLengthForExtraTimeRes(addFieldsTime, BKFrmValue.frequency, inptObj) : this.checkForShorterLengthForExtraTime(inptObj.selectedFrequency, addFieldsTime);
		addFieldsTime = +(Number(addFieldsTime).toFixed(0))
		return addFieldsTime;
	}
	/**
	 * Inside the function, there is an if statement that checks multiple conditions.
	 * If any of the conditions are true, the function returns true. Otherwise, the function returns false.
	 * The conditions being checked are:
	 * 1. The result of calling the function "isRecIdEqualBkngId" with the arguments inptObj.recFirstBkngId and inptObj.bookingId is true, or
	 * 2. The value of inptObj.prefilledData.frequency is not equal to BKFrmValue.frequency, or
	 * 3. The value of inptObj.bookingType is not equal to 'reschedule'.
	 * @param BKFrmValue
	 * @param inptObj
	 * @returns
	 */
	public isShorterLengthApplicable(BKFrmValue: any, inptObj: any){
		if((this.isRecIdEqualBkngId(inptObj.recFirstBkngId, inptObj.bookingId) || inptObj.prefilledData?.frequency != BKFrmValue.frequency) || inptObj.bookingType != 'reschedule' || inptObj.prefilledData.status == 9){
			return true;
		}
		return false;
	}
	/**
	 * Function named isExcFirstFromShorterLength which takes an argument selectedFrequency.
	 * It checks if the selectedFrequency object has a property form_frequency_data and if that property has a property exclude_first_apt_for_shorter_length.
	 * If both conditions are true, it returns true, otherwise it returns false.
	 * @param selectedFrequency
	 * @returns
	 */
	public isExcFirstFromShorterLength(selectedFrequency: any){
		if(selectedFrequency?.form_frequency_data && selectedFrequency?.form_frequency_data?.exclude_first_apt_for_shorter_length){
			return true;
		}
		return false;
	}
	/**
	 * Checks if the selectedFrequency object does not have the property form_frequency_data and exclude_first_apt_for_shorter_length using the isExcFirstFromShorterLength method.
	 * If the condition is false, it updates the value of extraTimeValue to be the same as newTotalTime.
	 * Finally, it returns the value of extraTimeValue.
	 * @param selectedFrequency
	 * @param extraTimeValue
	 * @param newTotalTime
	 * @returns
	 */
	public timeIfExcFrstFrmShortLength(selectedFrequency: any, extraTimeValue: any, newTotalTime: any){
		if(!this.isExcFirstFromShorterLength(selectedFrequency)){
			extraTimeValue = +newTotalTime;
		}
		return extraTimeValue;
	}
	/**
	 * Checks the value of conditionType using a switch statement. If conditionType is 'one-two', it checks if the value of type is either 1 or 2.
	 * If the condition is true, it returns true; otherwise, it returns false.
	 * If the value of conditionType is not 'one-two', it checks if the value of type is either 1 or 3.
	 * If the condition is true, it returns true; otherwise, it returns false.
	 * @param type
	 * @param conditionType
	 * @returns
	 */
	public isTypeOneTwoThree(type: any, conditionType: string){
		switch(conditionType){
			case 'one-two':
				if(type == 1 || type == 2){
					return true;
				}
				return false;
			default:
				if(type == 1 || type == 3){
					return true;
				}
				return false;
		}
	}

	/************ Tip, parking, tax and service fee*************** */

	/**
	 * Checks if priceLocalVar.bookingTaxRate exists and priceLocalVar.exemptSalesTaxValue does not exist.
	 * If this condition is true, it continues to the next conditional statement.
	 * Next, it checks if priceLocalVar.bookingTaxType is equal to 'percentage'.
	 * If this condition is true, it calculates the display sale tax by multiplying priceLocalVar.bookingTaxRate (percentage value) with displayFinalAmount.
	 * It calculates the display final amount by adding the display sale tax to displayFinalAmount.
	 * If priceLocalVar.bookingTaxType is not equal to 'percentage', it assumes the booking tax type is fixed and assigns the value of priceLocalVar.bookingTaxRate directly to displaySaleTax. Again, it calculates the display final amount by adding the display sale tax to displayFinalAmount.
	 * Finally, it returns an object containing the calculated displaySaleTax and displayFinalAmount.
	 * @param priceLocalVar
	 * @param displaySaleTax
	 * @param displayFinalAmount
	 * @returns
	 */
	public calcTax(priceLocalVar: any, displaySaleTax: any, displayFinalAmount: any){
		if(priceLocalVar?.bookingTaxRate && !priceLocalVar?.exemptSalesTaxValue){
			// if booking tax type is percentage
			if(priceLocalVar?.bookingTaxType == 'percentage'){
				displaySaleTax = this.roundToTwo(((+priceLocalVar.bookingTaxRate)/100)*(displayFinalAmount));
				displayFinalAmount = this.roundToTwo(this.sumFunc(displayFinalAmount, displaySaleTax));
			}else{
				// if booking tax type is fixed
				displaySaleTax = this.roundToTwo(+priceLocalVar.bookingTaxRate);
				displayFinalAmount = this.roundToTwo(this.sumFunc(displayFinalAmount, displaySaleTax));
			}
		}
		return {
			displaySaleTax : displaySaleTax,
			displayFinalAmount : displayFinalAmount
		}
	}
	/**
	 * Calculates the display tips amount and display final amount based on certain conditions.
	 * Checks if BKFrmValue.tip.amount_type is equal to 'percentage'. If this condition is true, it calculates the display tips amount by multiplying tipsAmount (as a percentage value) with tipCalculationBase and rounds it to two decimal places using the roundToTwo function.
	 * Otherwise, it assigns the value of tipsAmount directly to displayTipsAmount.
	 * Then, it checks if displayTipsAmount exists. If it does, it calculates the display final amount by adding displayFinalAmount and displayTipsAmount and rounds it to two decimal places using the sumFunc and roundToTwo functions.
	 * Finally, it returns an object containing the calculated displayTipsAmount and displayFinalAmount.
	 * @param BKFrmValue
	 * @param tipCalculationBase
	 * @param displayTipsAmount
	 * @param displayFinalAmount
	 * @returns
	 */
	public calcTip(BKFrmValue: any, tipCalculationBase: any, displayTipsAmount: any, displayFinalAmount: any){
		let tipsAmount = +(BKFrmValue?.tip?.total_amount);
		// if tip amount type is percentage
		if(BKFrmValue?.tip?.amount_type == 'percentage'){
			displayTipsAmount = this.roundToTwo((tipsAmount/100)*tipCalculationBase);
		}else{
			// if tip amount type is fixed
			displayTipsAmount = this.roundToTwo(+(tipsAmount));
		}
		// if tip total amount
		if(displayTipsAmount){
			displayFinalAmount = this.roundToTwo(this.sumFunc(displayFinalAmount, displayTipsAmount));
		}
		return {
			displayTipsAmount : displayTipsAmount,
			displayFinalAmount : displayFinalAmount
		}
	}
	/**
	 * Calculates the display final amount for parking based on certain conditions. It takes two arguments: BKFrmValue and displayFinalAmount.
	 * First, it checks if BKFrmValue?.parking?.total_amount exists. If it does, it adds BKFrmValue.parking.total_amount to displayFinalAmount using the sumFunc function and rounds it to two decimal places using the roundToTwo function.
	 * Finally, it returns the calculated displayFinalAmount.
	 * @param BKFrmValue
	 * @param displayFinalAmount
	 * @returns
	 */
	public calcParking(BKFrmValue: any, displayFinalAmount: any){
		// if parking total amount
		if(BKFrmValue?.parking?.total_amount){
			displayFinalAmount = this.roundToTwo(this.sumFunc(displayFinalAmount, BKFrmValue.parking.total_amount));
		}
		return displayFinalAmount;
	}
	/**
	 * Checks if the service fee is not taxable (isServiceFeeTaxable function returns false) and if the service fee is applicable based on the booking values and the excluded service fee value (isServiceFeeApplicable function returns true).
	 * If both conditions are met, it updates the displayServiceFee and displayFinalAmount variables with the service fee value from BKFrmValue and updates the final amount by adding the service fee using the sumFunc function.
	 * Both values are rounded to two decimal places using the roundToTwo function.
	 * Finally, it returns an object with the calculated displayServiceFee and displayFinalAmount.
	 * @param BKFrmValue
	 * @param priceLocalVar
	 * @param displayServiceFee
	 * @param displayFinalAmount
	 * @returns
	 */
	public calcServiceFee(BKFrmValue: any, priceLocalVar: any, displayServiceFee: any, displayFinalAmount: any){
		if(!this.isServiceFeeTaxable(priceLocalVar.isServiceFeeTaxable) && this.isServiceFeeApplicable(BKFrmValue, priceLocalVar.excludeServiceFeeValue)){
			displayServiceFee = this.roundToTwo(BKFrmValue.service_fee);
			displayFinalAmount = this.roundToTwo(this.sumFunc(displayFinalAmount, BKFrmValue.service_fee));
		}
		return {
			displayServiceFee : displayServiceFee,
			displayFinalAmount : displayFinalAmount
		}
	}

	/***************Dicount related functions**************************/

	/**
	 * Checks if a coupon should not be applied to recurring bookings.
	 * The function takes an argument BKFrmValue, which is expected to be an object.
	 * It checks if the apply_on_bookings property of the coupon object in BKFrmValue exists and is not equal to 'all_recurring'.
	 * If the condition is met, the function returns true, indicating that the coupon should not be applied to recurring bookings. Otherwise, it returns false.
	 * @param BKFrmValue
	 * @returns
	 */
	public isCouponNotApplyOnRec(BKFrmValue: any){
		if(BKFrmValue?.coupon?.apply_on_bookings && BKFrmValue?.coupon?.apply_on_bookings != 'all_recurring'){
			return true;
		}
		return false;
	}
	/**
	 * Checks if a coupon should not be applied to the first booking.
	 * It takes an argument BKFrmValue, which is expected to be an object.
	 * It checks if the apply_on_bookings property of the coupon object in BKFrmValue exists and is equal to 'first_booking'.
	 * If the condition is met, the function returns true, indicating that the coupon should not be applied to the first booking. Otherwise, it returns false.
	 * @param BKFrmValue
	 * @returns
	 */
	public isCouponNotApplyOnFirst(BKFrmValue: any){
		if(BKFrmValue?.coupon?.apply_on_bookings && BKFrmValue?.coupon?.apply_on_bookings == 'first_booking'){
			return true;
		}
		return false;
	}
	/**
	 * Checks if the BKFrmValue.coupon.discount property exists.
	 * If it does, it assigns its value to couponDiscountValue. If it doesn't exist, it assigns 0 to couponDiscountValue.
	 * Then, it checks if the BKFrmValue.coupon.discount_type property is equal to 'percentage'. If it is, it calculates the discount amount by multiplying couponDiscountValue (as a percentage) with discountAmount.
	 * If the BKFrmValue.coupon.discount_type property is not equal to 'percentage', it assumes that the discount is fixed and assigns couponDiscountValue directly to discountAmount.
	 * Finally, it returns the calculated discountAmount.
	 * @param BKFrmValue
	 * @param discountAmount
	 * @returns
	 */
	public calcCouponDisc(BKFrmValue: any, discountAmount: any){
		let couponDiscountValue = (BKFrmValue.coupon.discount) ? (BKFrmValue.coupon.discount) : 0;
		// if discount type is percentage
		if(BKFrmValue?.coupon?.discount_type == 'percentage'){
			discountAmount = this.roundToTwo(((+couponDiscountValue)/100)*(+discountAmount));
		}else{
			// if discount is fixed
			discountAmount = this.roundToTwo(couponDiscountValue);
		}
		return discountAmount;
	}
	/**
	 * Function named finalFirstAndNthspotDiscount that calculates the final amount after applying spot discounts to the first and nth appointments.
	 * First checks if firstRecAptspotDiscount is greater than amountWithFreqDiscFirstRecApt. If it is, it sets amountWithFreqDiscFirstRecApt to 0.
	 * Otherwise, it subtracts firstRecAptspotDiscount from amountWithFreqDiscFirstRecApt and rounds it to two decimal places.
	 * Then, it checks if afterNthspotDiscount is greater than amountWithFreqDiscNthApt. If it is, it sets amountWithFreqDiscNthApt to 0.
	 * Otherwise, it subtracts afterNthspotDiscount from amountWithFreqDiscNthApt and rounds it to two decimal places.
	 * Finally, it returns an object with the calculated amountWithFreqDiscFirstRecApt and amountWithFreqDiscNthApt.
	 * @param amountWithFreqDiscFirstRecApt
	 * @param amountWithFreqDiscNthApt
	 * @param firstRecAptspotDiscount
	 * @param afterNthspotDiscount
	 * @returns
	 */
	public finalFirstAndNthspotDiscount(amountWithFreqDiscFirstRecApt: any, amountWithFreqDiscNthApt: any, firstRecAptspotDiscount: any, afterNthspotDiscount: any){
		amountWithFreqDiscFirstRecApt = (firstRecAptspotDiscount > amountWithFreqDiscFirstRecApt) ? 0 : this.roundToTwo((+amountWithFreqDiscFirstRecApt) - (+firstRecAptspotDiscount));
		amountWithFreqDiscNthApt = (afterNthspotDiscount > amountWithFreqDiscNthApt) ? 0 : this.roundToTwo((+amountWithFreqDiscNthApt) - (+afterNthspotDiscount));
		return {
			amountWithFreqDiscFirstRecApt : amountWithFreqDiscFirstRecApt,
			amountWithFreqDiscNthApt : amountWithFreqDiscNthApt
		}
	}

	/****************Provider related functions*************************/

	/**
	 * Inside the function, there is a variable declaration "providerLength" initialized to 0.
	 * After that, there is an if statement that checks if a certain condition is true. If the condition is true, the "providerLength" variable is set to the value of "totalTimeValue".
	 * If the condition is false, there is a function call to "CBTTPartThreeSubFunc" passing "providers" and "totalTimeValue" as arguments. The returned value from this function call is assigned to the "providerLength" variable.
	 * Finally, the function returns the value of "providerLength" converted to a number.
	 * @param providers : service providers array
	 * @param totalTimeValue : time
	 * @returns time
	 */
	public CBTTPartThree(providers: any,totalTimeValue: any): number{
		let providerLength = 0;
		// if divide job length according to providers
		if(this.isValExist(this.admnStngs.merchant_settings?.providers?.divide_providers_job_length) && this.admnStngs?.merchant_settings?.providers?.divide_providers_job_length == 'no'){
			providerLength = +totalTimeValue;
		}else{
			providerLength = this.CBTTPartThreeSubFunc(providers, totalTimeValue);
		}
		return +providerLength;
	}
	/**
	 * Inside the function, there is an if statement that checks if the length of "providers" is greater than 1.
	 * If the condition is true, it returns the result of dividing "totalTimeValue" by the length of "providers" converted to a number.
	 * If the condition is false, there is another if statement that checks if "providers[0]" is a team.
	 * If it is, it returns the result of dividing "totalTimeValue" by the length of "providers[0].members" converted to a number.
	 * If it's not a team, it simply returns "totalTimeValue" converted to a number.
	 * @param providers
	 * @param totalTimeValue
	 * @returns
	 */
	private CBTTPartThreeSubFunc(providers: any,totalTimeValue: any): any{
		// if more than one provider
		if(providers?.length > 1){
			return +(totalTimeValue / (providers.length));
		}else{
			// if team
			if(this.isTeam(providers[0])){
				return +(totalTimeValue / (providers[0].members).length);
			}else{
				return totalTimeValue;
			}
		}
	}
	/**
	 * Function checks if the "provider" exists and if the "provider_type" property of "provider" is equal to the string literal 'team'.
	 * If both conditions are true, the function returns true. Otherwise, it returns false.
	 * @param provider
	 * @returns
	 */
	public isTeam(provider: any){
		if(provider && provider?.provider_type == 'team'){
			return true;
		}
		return false;
	}
	/**
	 * Checks if the property "divide_providers_job_length" exists in "merchant_settings.providers" object and if its value is equal to string literal 'no'.
	 * If both conditions are true, then the "providerLength" is set to the value of "totalTimeValue".
	 * Otherwise, the function calls another function named "prvdrLengthForSingleProv" passing the "prefilledData", "totalTimeValue", and "providerLength" as arguments. The returned value from this function is assigned to the "providerLength" variable.
	 * Finally, the "providerLength" variable is returned as the result of the function.
	 * @param prefilledData
	 * @param totalTimeValue
	 * @returns
	 */
	public CBTTPartFour(prefilledData: any, totalTimeValue: number): number{
		let providerLength = 0;
		if(this.isValExist(this.admnStngs.merchant_settings?.providers?.divide_providers_job_length) && this.admnStngs?.merchant_settings?.providers?.divide_providers_job_length == 'no'){
			providerLength = +totalTimeValue;
		}else{
			providerLength = this.prvdrLengthForSingleProv(prefilledData, totalTimeValue, providerLength);

		}
		return providerLength;
	}
	/**
	 * Function checks if the property "provider_category" in the "prefilledData" object is equal to 'team'.
	 * If it is, then the "providerLength" is calculated by dividing "totalTimeValue" by the length of the "members" array in the "prefilledData" object.
	 * Otherwise, the "providerLength" is calculated by dividing "totalTimeValue" by the length of the "provider_wages" array in the "prefilledData" object.
	 * Finally, the calculated "providerLength" is returned as the result of the function.
	 * @param prefilledData
	 * @param totalTimeValue
	 * @param providerLength
	 * @returns
	 */
	public prvdrLengthForSingleProv(prefilledData: any, totalTimeValue: number, providerLength: number): number{
		if(prefilledData.provider_category == 'team'){
			providerLength = ((prefilledData?.members)?.length) ? (+(totalTimeValue / (prefilledData.members).length)) : +totalTimeValue;
		}else{
			let providers = prefilledData.provider_wages;
			providerLength = (providers?.length) ? (+(totalTimeValue / (providers.length))) : +totalTimeValue;
		}
		return providerLength;
	}
	/**
	 * Checks if inptObj.isStepOneValid or inptObj.prefilledData are truthy.
	 * If either of them is truthy, it calls the function CBTTPartThree with BKFrmValue.provider_details and totalTimeValue as arguments and assigns the result to providerLength.
	 * If inptObj.prefilledData is truthy, it calls the function CBTTPartFour with inptObj.prefilledData and totalTimeValue as arguments and assigns the result to providerLength.
	 * The function then converts providerLength to a number with zero decimal places and assigns it to newproviderLength.
	 * Finally, it assigns newproviderLength to providerFinalLength and returns providerFinalLength as the result of the function.
	 * @param BKFrmValue
	 * @param inptObj
	 * @param totalTimeValue
	 * @param providerFinalLength
	 * @returns
	 */
	public calcPrvdrAmountIfNotAdjust(BKFrmValue: any, inptObj: any, totalTimeValue: any, providerFinalLength: any){
		let providerLength = 0;
		let newproviderLength = 0;
		if(inptObj?.isStepOneValid || inptObj?.bookingType == 'add' || inptObj?.bookingType == 'draft'){
			providerLength = this.CBTTPartThree(BKFrmValue.provider_details,totalTimeValue);
		}else if(inptObj?.prefilledData){
			providerLength = this.CBTTPartFour(inptObj.prefilledData,totalTimeValue);
		}
		newproviderLength = +(Number(providerLength).toFixed(0));
		providerFinalLength = newproviderLength;
		return providerFinalLength;
	}
	/**
	 * Function initializes bookingTimeHours and bookingTimeMin variables to 0.
	 * Then it calls a common function CBTTPartThree with BKFrmValue.provider_details and displayBookingTime as arguments and assigns the result to displayBookingTime.
	 * Next, it checks if displayBookingTime is greater than or equal to 60. If true, it calculates bookingTimeHours by dividing displayBookingTime by 60 and rounding down using Math.floor. It calculates bookingTimeMin by finding the remainder after division by 60.
	 * If displayBookingTime is less than 60, it sets bookingTimeHours to 0 and calculates bookingTimeMin as displayBookingTime with zero decimal places.
	 * Finally, the function returns an object with properties displayBookingTime, bookingTimeHours, and bookingTimeMin.
	 * @param BKFrmValue : form control value
	 * @param displayBookingTime : time
	 */
	public convertBookingTimeToHours(BKFrmValue: any, displayBookingTime: number): any {
		let bookingTimeHours = 0;
		let bookingTimeMin = 0;
		// call common func
		let providers = BKFrmValue?.provider_details;
		// Divide the booking time according to the providers
		displayBookingTime = this.CBTTPartThree(providers, displayBookingTime);
		if(displayBookingTime >= 60){
			bookingTimeHours = Math.floor(displayBookingTime/60);
			bookingTimeMin = +(Number(displayBookingTime%60).toFixed(0));
		}else{
			bookingTimeHours = 0;
			bookingTimeMin = +(Number(displayBookingTime).toFixed(0));
		}
		return{
			displayBookingTime : displayBookingTime,
			bookingTimeHours : bookingTimeHours,
			bookingTimeMin : bookingTimeMin
		};
	}

	/*************Extras related functions******************/

	/**
	 * Checks several conditions and returns true if all conditions are met, otherwise it returns false.
	 * The conditions include checking if the "extra" should be added as separate, checking if the "extra" should not apply to the first only, checking the "outputType" and "settingsObj" properties for specific conditions.
	 * @param extra
	 * @param settingsObj
	 * @param outputType
	 * @param type
	 * @returns
	 */
	public isExtraSeprateAndAll(extra: any, settingsObj: any, outputType: any, type: any){
		if(this.isExtraAddAsSeprate(extra, settingsObj) && ( this.isExtraNotApplyToFirstOnly(extra) || type != 'without-first-only') && ((outputType == 'price' && !this.isExtraExcFromFreqDis(settingsObj[extra.id])) || (outputType == 'time' && settingsObj[extra.id].exempt_extra_hourly_price))){
			return true;
		}
		return false;
	}
	/**
	 * Checks if the "extra" should be added as a separate charge based on a condition in the "settingsObj" object.
	 * If the condition is met, it returns true, otherwise it returns false.
	 * @param extra
	 * @param settingsObj
	 * @returns
	 */
	public isExtraAddAsSeprate(extra: any, settingsObj: any){
		if(settingsObj && settingsObj[extra.id] && settingsObj[extra.id]?.add_as_seprate_charge){
			return true;
		}
		return false;
	}
	/**
	 * Checks if the "extra" should not apply to only the first booking based on a condition.
	 * If the condition is met, it returns true, otherwise it returns false.
	 * @param extra
	 * @returns
	 */
	public isExtraNotApplyToFirstOnly(extra: any){
		if(extra?.apply_on_bookings && extra?.apply_on_bookings != 'first-only'){
			return true
		}
		return false;
	}
	/**
	 * Checks if the "param" has a property "qty_based_price_length_type" and its value is equal to "manual".
	 * If the condition is met, it returns true, otherwise it returns false.
	 * @param param
	 * @returns
	 */
	public isParamQtyBsedPriceManual(param: any){
		if(param?.qty_based_price_length_type && param?.qty_based_price_length_type == 'manual'){
			return true;
		}
		return false;
	}
	/**
	 * Checks if the value at the zero-based index of "param" is either defined or equal to zero.
	 * If the condition is met, it returns true, otherwise it returns false.
	 * @param param
	 * @param quantity
	 * @returns
	 */
	public isZeroIndexQty(param: any, quantity: any){
		if((param[(+quantity) - 1] || param[(+quantity) - 1] == 0 )){
			return true;
		}
		return false;
	}
	/**
	 * Checks the value of "BKFrmValue.location_type" and compares it to the string 'ML'.
	 * If they are the same, it returns the value of "param[type+'_ml']", otherwise it returns the value of "param[type+'_sa']".
	 * @param BKFrmValue
	 * @param param
	 * @param type
	 * @returns
	 */
	public priceTimeBasedOnLocType(BKFrmValue: any, param: any, type: string = 'time'){
		return BKFrmValue?.location_type == 'ML' ? param[type+'_ml'] : param[type+'_sa'];
	}
	/**
	 * Checks if the array exists and has a length greater than zero.
	 * If it does, it returns true. Otherwise, it returns false.
	 * @param arr Array
	 * @returns Boolean (true/false)
	 */
	public checkArrLength(arr: Array<any>): boolean {
		return (arr && arr.length > 0) ? true : false;
	}
}
