<div class="position-relative">
	<div [ngClass]="isMultiStepForm ? 'mb-10' : 'pt-30 pb-10 px-md-30 border-bottom bk-card-border'" [formGroup]="extrasForm" [id]="section?.id" *ngIf="extras && extras.length > 0 && (((prefilledData && prefilledData?.extras && (prefilledData?.extras).length > 0) || (extraStatus && section)))">
		<!-- Title and description -->
		<bk-section-heading *ngIf="!isMultiStepForm" [section]="section" [pageSett]="pageSett"></bk-section-heading>
		<!-- Extras -->
		<div class="card bg-transparent">
			<!-- Label -->
			<label class="form-label bk-form-label mt-10" [id]="section?.title_id" *ngIf="section?.title && isMultiStepForm" [innerHTML]="section?.title | safeHtml"></label>
			<bk-select-element-block controlName="extras" [data]="extras" [isDisabled]="isDisabled" [elementForm]="extrasForm" [bookingType]="bookingType" (elementChange)="extraChangeEvent()" [isMultiStepForm]="isMultiStepForm"></bk-select-element-block>
		</div>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>