<div [id]="secId" *ngIf="pagePerm && rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<!-- Gallery -->
	<div [id]="section?.gallery?.id" class="pt-50 tjs-giftcard-slider" *ngIf="section?.gallery && gallery && gallery.length > 0">
		<div class="container position-relative">
			<!-- Title -->
			<div class="tjs-section__title text-center mb-35" *ngIf="section?.gallery?.title">
				<h3 [id]="section?.gallery?.title_id" class="display-5 tjs-section__heading bk-title" [innerHTML]="section?.gallery?.title | safeHtml"></h3>
			</div>
			<!-- Gallery slider -->
			<div class="position-relative">
				<div [attr.dir]="initServ?.selectedLang?.rtl ? 'rtl' :'ltr'" class="keen-slider bk-slider" #giftCardSlide>
					<ng-container *ngIf="gallery && gallery.length > 0">
						<div class="keen-slider__slide" *ngFor="let item of gallery;trackBy: secServ.trackByFnIndex">
							<div class="m-20" [ngClass]="{'selected-image': selectedImg == item?.image_url }">
								<img loading="auto" [src]="initServ?.imgBase + item?.image_url" class="img-fluid tjs-pointer tjs-object--cover bk-img w-100" alt="Gallery" width="362" height="212" (click)="selectedImg = item?.image_url">
							</div>
						</div>
					</ng-container>
				</div>
				<span (click)="slider.prev()" class="tjs-giftcard-arrow__left position-absolute text-body tjs-pointer slider-arrow"><i class="tjsicon-left-arrow"></i></span>
				<span (click)="slider.next()" class="tjs-giftcard-arrow__right position-absolute text-body tjs-pointer slider-arrow"><i class="tjsicon-right-arrow"></i></span>
			</div>
			<!-- Loader -->
			<bk-app-loader [loaderId]="galleryLoaderId"></bk-app-loader>
		</div>
	</div>
	<!-- Form -->
	<form class="gift-card-form position-relative" [formGroup]="sendForm">
		<div class="container py-50">
			<div class="card" [id]="section?.form?.id">
				<div class="card-body">
					<!-- Form title -->
					<div class="tjs-card-title bk-form-border" *ngIf="section?.form?.title">
						<h4 [id]="section?.form?.title_id" class="mb-0 fw-bold bk-form-title bg-transparent-imp" [innerHTML]="section?.form?.title | safeHtml"></h4>
					</div>
					<div class="row">
						<!-- Form Fields block -->
						<div class="col-12 col-md-12 col-lg-7 col-xl-6 mb-30 mb-lg-0">
							<ng-container [ngSwitch]="currentStep" >
								<div class="position-relative" *ngSwitchCase="'details'" formGroupName="details">
									<!-- Amount -->
									<div [id]="section?.form?.amount_id" class="mb-30">
										<label class="form-label bk-form-label" [id]="section?.form?.amount?.label_id" *ngIf="section?.form?.amount?.label" [innerHTML]="section?.form?.amount?.label | safeHtml"></label>
										<input id="amount_control"  class="form-control bk-input" [ngClass]="{'is-invalid': fDetails['amount']?.touched && fDetails['amount']?.errors}" [placeholder]="section?.form?.amount?.placeholder" formControlName="amount" type="text" (focusout)="customValidators.setControlDecimalNo(fDetails['amount'])" (keypress)="utilServ.onlyNumbers($event)" [attr.readonly]="editId ? true : null">
										<small class="mt-5 d-block" [id]="section?.form?.amount?.min_amount_id" *ngIf="section?.form?.amount?.min_amount && minAmountText" [innerHTML]="minAmountText | safeHtml"></small>
										<!-- Error -->
										<div class="tjs-error" *ngIf="fDetails['amount']?.touched && fDetails['amount']?.errors?.amountCheck">
											<div>
												<i class="tjsicon-attention"></i>
												{{initServ?.appStr?.errorMsg?.giftCardAmount | translate}} <bk-amount-display class="ms-5" customClass="icon-m-0" [amount]="admnStngs?.merchant_settings?.admin?.min_gift_card_amount"></bk-amount-display>.
											</div>
										</div>
									</div>
									<!-- Recipient name -->
									<div [id]="section?.form?.recipient_name_id" class="mb-30">
										<label class="form-label bk-form-label" [id]="section?.form?.recipient_name?.label_id" *ngIf="section?.form?.recipient_name?.label" [innerHTML]="section?.form?.recipient_name?.label | safeHtml"></label>
										<input id="recipient_name_control" class="form-control bk-input" [ngClass]="{'is-invalid': fDetails['recipient_name']?.touched && fDetails['recipient_name']?.errors}" [placeholder]="section?.form?.recipient_name?.placeholder" formControlName="recipient_name" type="text" (focusout)="detailsFocusOut()">
										<!-- Error -->
										<div class="tjs-error" *ngIf="fDetails['recipient_name']?.touched && (fDetails['recipient_name']?.errors?.required || fDetails['recipient_name']?.errors?.pattern)">
											<i class="tjsicon-attention"></i>
											<ng-container *ngIf="fDetails['recipient_name']?.errors?.required"> {{initServ?.appStr?.errorMsg?.recipientNameValid | translate}} </ng-container>
											<ng-container *ngIf="fDetails['recipient_name']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.specialCharsExceptSpace | translate}} </ng-container>
										</div>
									</div>
									<!-- Recipient email -->
									<div [id]="section?.form?.recipient_email_id" class="mb-30">
										<label class="form-label bk-form-label" [id]="section?.form?.recipient_email?.label_id" *ngIf="section?.form?.recipient_email?.label" [innerHTML]="section?.form?.recipient_email?.label | safeHtml"></label>
										<input id="recipient_email_control" class="form-control bk-input" [ngClass]="{'is-invalid': fDetails['recipient_email']?.touched && fDetails['recipient_email']?.errors}" [placeholder]="section?.form?.recipient_email?.placeholder" formControlName="recipient_email" type="email" (focusout)="detailsFocusOut()">
										<!-- Error -->
										<div class="tjs-error" *ngIf="fDetails['recipient_email']?.touched && (fDetails['recipient_email']?.errors?.required || fDetails['recipient_email']?.errors?.pattern)">
											<i class="tjsicon-attention"></i>
											{{initServ?.appStr?.errorMsg?.recipientEmailValid | translate}}
										</div>
									</div>
									<!-- Sender first name -->
									<div [id]="section?.form?.sender_first_name_id" class="mb-30">
										<label class="form-label bk-form-label" [id]="section?.form?.sender_first_name?.label_id" *ngIf="section?.form?.sender_first_name?.label" [innerHTML]="section?.form?.sender_first_name?.label | safeHtml"></label>
										<input id="sender_first_name_control" class="form-control bk-input" [ngClass]="{'is-invalid': fDetails['sender_first_name']?.touched && fDetails['sender_first_name']?.errors}" [placeholder]="section?.form?.sender_first_name?.placeholder" formControlName="sender_first_name" type="text" [attr.readonly]="isLoggedIn" (focusout)="detailsFocusOut()">
										<!-- Error -->
										<div class="tjs-error" *ngIf="fDetails['sender_first_name']?.touched && (fDetails['sender_first_name']?.errors?.required || fDetails['sender_first_name']?.errors?.pattern)">
											<i class="tjsicon-attention"></i>
											<ng-container *ngIf="fDetails['sender_first_name']?.errors?.required"> {{initServ?.appStr?.errorMsg?.firstNameValid | translate}} </ng-container>
											<ng-container *ngIf="fDetails['sender_first_name']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.specialCharsExceptSpace | translate}} </ng-container>
										</div>
									</div>
									<!-- Sender last name -->
									<div [id]="section?.form?.sender_last_name_id" class="mb-30">
										<label class="form-label bk-form-label" [id]="section?.form?.sender_last_name?.label_id" *ngIf="section?.form?.sender_last_name?.label" [innerHTML]="section?.form?.sender_last_name?.label | safeHtml"></label>
										<input id="sender_last_name_control" class="form-control bk-input" [ngClass]="{'is-invalid': fDetails['sender_last_name']?.touched && fDetails['sender_last_name']?.errors}" [placeholder]="section?.form?.sender_last_name?.placeholder" formControlName="sender_last_name" type="text" [attr.readonly]="isLoggedIn" (focusout)="detailsFocusOut()">
										<!-- Error -->
										<div class="tjs-error" *ngIf="fDetails['sender_last_name']?.touched && (fDetails['sender_last_name']?.errors?.required || fDetails['sender_last_name']?.errors?.pattern)">
											<i class="tjsicon-attention"></i>
											<ng-container *ngIf="fDetails['sender_last_name']?.errors?.required"> {{initServ?.appStr?.errorMsg?.lastNameValid | translate}} </ng-container>
											<ng-container *ngIf="fDetails['sender_last_name']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.specialCharsExceptSpace | translate}} </ng-container>
										</div>
									</div>
									<!-- Sender email -->
									<div [id]="section?.form?.sender_email_id" class="mb-30">
										<label class="form-label bk-form-label" [id]="section?.form?.sender_email?.label_id" *ngIf="section?.form?.sender_email?.label" [innerHTML]="section?.form?.sender_email?.label | safeHtml"></label>
										<input id="sender_email_control" class="form-control bk-input {{section?.form?.sender_email?.existing_email_id}}" [ngClass]="{'is-invalid': fDetails['sender_email']?.touched && (fDetails['sender_email']?.errors?.required || fDetails['sender_email']?.errors?.pattern || (section?.form?.sender_email?.existing_email && fDetails['sender_email']?.errors?.emailExists && !currentUser))}" [placeholder]="section?.form?.sender_email?.placeholder" formControlName="sender_email" type="email" [attr.readonly]="isLoggedIn" (focusout)="userEmailExist()" [class.existing-email]="section?.form?.sender_email?.existing_email && fDetails['sender_email']?.errors?.emailExists && !currentUser">
										<!-- Error -->
										<div class="tjs-error" *ngIf="fDetails['sender_email']?.touched && (fDetails['sender_email']?.errors?.required || fDetails['sender_email']?.errors?.pattern)">
											<i class="tjsicon-attention"></i>
											{{initServ?.appStr?.errorMsg?.senderEmailValid | translate}}
										</div>
										<!-- Email exist -->
										<div [id]="section?.form?.sender_email?.existing_email_id" class="tjs-error" *ngIf="section?.form?.sender_email?.existing_email && fDetails['sender_email']?.errors?.emailExists && !currentUser">
											<i class="tjsicon-attention"></i>
											{{section?.form?.sender_email?.existing_email}}
										</div>
									</div>
									<!-- Password show case:: user not login and email is exist -->
									<div [id]="section?.form?.password_field_id" class="mb-30" *ngIf="fDetails['sender_email']?.errors?.emailExists && !currentUser">
										<div class="mb-10 d-flex align-items-center">
											<div>
												<label class="form-label bk-form-label" [id]="section?.form?.password_field?.label_id" *ngIf="section?.form?.password_field?.label" [innerHTML]="section?.form?.password_field?.label | safeHtml"></label>
												<bk-tooltip class="bk-tooltip" *ngIf="section?.form?.password_field?.tooltip" [content]="section?.form?.password_field?.tooltip | translate" [id]="section?.form?.password_field?.tooltip_id"></bk-tooltip>
											</div>
										</div>
										<input id="password_control" class="form-control bk-input" [ngClass]="{'is-invalid': fDetails['password']?.touched && fDetails['password']?.errors}" placeholder="{{section?.form?.password_field?.placeholder}}" type="password" class="form-control" formControlName="password">
										<!-- Error -->
										<div class="tjs-error" *ngIf="fDetails['password']?.touched && fDetails['password']?.errors?.required">
											<i class="tjsicon-attention"></i>
											{{initServ?.appStr?.errorMsg?.empty | translate}}
										</div>
										<!-- Forgot password -->
										<div class="button-wrap d-flex align-items-center justify-content-between mt-5 forgot-pass-link">
											<ng-container *ngIf="section?.form?.password_field?.forgot_password_text; else forgotPwdText">
												<a href="javascript:void(0)" class="px-0 fs-14 text-nowrap" [id]="section?.form?.password_field?.forgot_password_text_id" [innerHTML]="section?.form?.password_field?.forgot_password_text | safeHtml" (click)="forgotPwd()"></a>
											</ng-container>
											<ng-template #forgotPwdText>
												<a href="javascript:void(0)" class="px-0 fs-14 text-nowrap" [id]="section?.form?.password_field?.forgot_password_text_id" [innerHTML]="initServ?.appStr?.text?.forgotPwd | translate" (click)="forgotPwd()"></a>
											</ng-template>
										</div>
									</div>
									<!-- Message -->
									<div [id]="section?.form?.message_id" class="mb-30">
										<label class="form-label bk-form-label" [id]="section?.form?.message?.label_id" *ngIf="section?.form?.message?.label" [innerHTML]="section?.form?.message?.label | safeHtml"></label>
										<textarea class="form-control bk-form-textarea" [ngClass]="{'is-invalid': fDetails['message']?.touched && fDetails['message']?.errors}" [placeholder]="section?.form?.message?.placeholder" formControlName="message" rows="5"></textarea>
										<!-- Error -->
										<div class="tjs-error" *ngIf="fDetails['message']?.touched && fDetails['message']?.errors?.pattern">
											<i class="tjsicon-attention"></i>
											{{initServ?.appStr?.errorMsg?.specialChars | translate}}
										</div>
									</div>
									<!-- Schedule -->
									<div class="mb-10">
										<div [id]="section?.form?.schedule?.send_it_now_id" class="form-check form-check-inline mb-10">
											<input type="radio" id="now" class="form-check-input tjs-pointer" value="schedule_now" checked="checked" formControlName="schedule" (change)="scheduleValidation()">
											<label [id]="section?.form?.schedule?.send_it_now_id" for="now" class="form-check-label tjs-pointer" [innerHTML]="section?.form?.schedule?.send_it_now | safeHtml"></label>
										</div>
										<span [id]="section?.form?.schedule?.send_on_specific_id" class="form-check form-check-inline mb-10">
											<input type="radio" id="date" class="form-check-input tjs-pointer" value="schedule_specific_date" formControlName="schedule" (change)="scheduleValidation(true)">
											<label [id]="section?.form?.schedule?.send_on_specific_id" for="date" class="form-check-label tjs-pointer" [innerHTML]="section?.form?.schedule?.send_on_specific | safeHtml"></label>
										</span>
									</div>
									<!-- Schedule date/time -->
									<div class="row" *ngIf="fDetails['schedule'].value == 'schedule_specific_date'">
										<!-- Date -->
										<div id="schedule_date" class="col-12 col-md-6">
											<div [id]="section?.form?.schedule_date_id" class="mb-30">
												<label class="form-label bk-form-label" [id]="section?.form?.schedule_date?.label_id" *ngIf="section?.form?.schedule_date?.label" [innerHTML]="section?.form?.schedule_date?.label | safeHtml"></label>
												<!-- Date picker -->

												<!-- <div class="tjs-date__picker position-relative">
													<input  class="form-control tjs-pointer bk-input" [options]="calOptions" [ngClass]="{'is-invalid': fDetails['schedule_on_date']?.touched && fDetails['schedule_on_date']?.errors}" [placeholder]="section?.form?.schedule_date?.placeholder" angular-mydatepicker (click)="startDP?.toggleCalendar()" formControlName="schedule_on_date"  #startDP="angular-mydatepicker" [locale]="currentLang"/>
													<div class="tjs-group-icon h-100 position-absolute"><i class="tjsicon-calendar-1"></i></div>
												</div> -->


												<div class="tjs-date__picker position-relative">
													<input [min]="minDate" class="form-control tjs-pointer bk-input pe-45" [ngClass]="{'is-invalid': fDetails['schedule_on_date']?.touched && fDetails['schedule_on_date']?.errors}" [placeholder]="section?.form?.schedule_date?.placeholder" formControlName="schedule_on_date" matInput [matDatepicker]="scheduleDatePicker" (click)="scheduleDatePicker.open()">
													<div class="tjs-group-icon h-100 position-absolute"><i class="tjsicon-calendar-1"></i></div>
													<mat-datepicker #scheduleDatePicker></mat-datepicker>
												</div>
												<!-- Error -->
												<div class="tjs-error" *ngIf="fDetails['schedule_on_date']?.touched && fDetails['schedule_on_date']?.errors?.required">
													<i class="tjsicon-attention"></i>
													{{initServ?.appStr?.errorMsg?.empty | translate}}
												</div>
											</div>
										</div>
										<!-- Time -->
										<div class="col-12 col-md-6">
											<div [id]="section?.form?.schedule_time_id" class="mb-30">
												<label [id]="section?.form?.schedule_time?.label_id" class="form-label bk-form-label" *ngIf="section?.form?.schedule_time?.label" [innerHTML]="section?.form?.schedule_time?.label | safeHtml"></label>
												<select id="schedule_time" [ngClass]="{'is-invalid': fDetails['schedule_on_time']?.touched && fDetails['schedule_on_time']?.errors}" class="form-select tjs-pointer bk-from-select dir-ltr text-start" formControlName="schedule_on_time">
													<option [id]="section?.form?.schedule_time?.placeholder_id" value="null" hidden>{{section?.form?.schedule_time?.placeholder}}</option>
													<ng-container *ngIf="times && times.length > 0">
														<option *ngFor="let time of times let i=index;trackBy: secServ.trackByFnIndex" [value]="i">{{utilServ.convertTime24HFormat(time)}}</option>
													</ng-container>
												</select>
												<!-- Error -->
												<div class="tjs-error" *ngIf="fDetails['schedule_on_time']?.touched && fDetails['schedule_on_time']?.errors?.required">
													<i class="tjsicon-attention"></i>
													{{initServ?.appStr?.errorMsg?.empty | translate}}
												</div>
											</div>
										</div>
									</div>
									<!-- Cookie content -->
									<div class="tjs-privacy-text mt-10 mb-20 text-body" *ngIf="section?.form?.cookie_content">
										<small class="bk-cookie" [id]="section?.form?.cookie_content_id" [innerHTML]="section?.form?.cookie_content | safeHtml"></small>
									</div>
									<!-- Next button -->
									<bk-navigate  [secId]="section?.form?.next_btn_id" [innerHTML]="((section?.form?.next_btn?.content) && (section?.form?.next_btn?.content))" [secSett]="section?.form?.next_btn" customClass="btn btn-primary btn-lg border-transparent" essentialClass="bk-form-btn w-100" (callback)="nextStep()"></bk-navigate>
									<!-- Loader -->
									<bk-app-loader [loaderId]="detailsLoaderId"></bk-app-loader>
								</div>
								<div class="d-flex flex-column justify-content-between h-100 position-relative" id="payment-sec" *ngSwitchCase="'payment'" >
									<div>
										<!-- Amount -->
										<div [id]="section?.form?.amount_id" class="mb-30" formGroupName="details">
											<label class="form-label bk-form-label" [id]="section?.form?.amount?.label_id" *ngIf="section?.form?.amount?.label" [innerHTML]="section?.form?.amount?.label | safeHtml"></label>
											<input id="amount_control" class="form-control disable-field bk-input" [placeholder]="section?.form?.amount?.placeholder" formControlName="amount" type="text" readonly>
											<small class="mt-5 d-block" [id]="section?.form?.amount?.min_amount_id" *ngIf="section?.form?.amount?.min_amount && minAmountText" [innerHTML]="minAmountText | safeHtml"></small>
										</div>
										<!-- Existing payment cards -->
										<ng-container *ngIf="!editId && cards && cards.length > 0" >
											<!-- Payment types: new/existing -->
											<div [id]="section?.form?.card_type?.id" *ngIf="section?.form?.card_type" class="mb-0 mb-md-25" formGroupName="payment">
												<label class="form-label bk-form-label" [id]="section?.form?.card_type?.label_id" *ngIf="section?.form?.card_type?.label" [innerHTML]="section?.form?.card_type?.label | translate | safeHtml"></label>
												<div class="d-block">
													<div class="form-check form-check-inline mb-15 mb-md-0">
														<input id="existing" type="radio" class="form-check-input" value="existing" formControlName="card_type" (change)="cardTypeChange()">
														<label [id]="section?.form?.card_type?.existing_cc_id" class="form-check-label" for="existing">{{section?.form?.card_type?.existing_cc | translate}}</label>
													</div>
													<div class="form-check form-check-inline mb-15 mb-md-0">
														<input id="new" type="radio" class="form-check-input" value="new" formControlName="card_type" (change)="cardTypeChange()">
														<label [id]="section?.form?.card_type?.new_cc_id" class="form-check-label" for="new">{{section?.form?.card_type?.new_cc | translate}}</label>
													</div>
												</div>
											</div>
											<div [id]="section?.form?.card_label?.id" class="mb-30" *ngIf="section?.form?.card_label && fPayment.controls['card_type'].value == 'existing'" formGroupName="details">
												<label  class="form-label bk-form-label"[id]="section?.form?.card_label?.existing_cc_label_id">{{section?.form?.card_label?.existing_cc_label | translate}}</label>
												<div class="gift-selectbox">
													<select class="form-select" formControlName="pay_with_cc" (change)="onCardChange($event)">
														<ng-container *ngFor="let card of cards;trackBy: secServ.trackByFnIndex">
															<option *ngIf="card?.is_default; else notDefault" [value]="card?.id" selected>XXXX XXXX XXXX {{card?.last4}}</option>
															<ng-template #notDefault>
																<option [value]="card?.id">XXXX XXXX XXXX {{card?.last4}}</option>
															</ng-template>
														</ng-container>
													</select>
												</div>
											</div>
										</ng-container>
										<!-- New card block -->
										<ng-container *ngIf="!editId && fPayment.controls['card_type'].value == 'new'">
											<bk-payment-gateway id="payment_gateway" [section]="{'existing_cc_label': section?.form?.card_label?.existing_cc_label, 'existing_cc_label_id': section?.form?.card_label?.existing_cc_label_id, 'new_cc_label': section?.form?.card_label?.new_cc_label, 'new_cc_label_id': section?.form?.card_label?.new_cc_label_id, 'address':  section?.form?.address }" [isDynamic]="true" [billingAddr]="true" [billingAddrForm]="fPayment"></bk-payment-gateway>
										</ng-container>
									</div>
									<div>
										<!-- Buttons -->
										<div class="row mt-25 mt-lg-0">
											<!-- Back -->
											<div class="col-12 col-md-6 mb-15 mb-lg-0">
												<bk-navigate class="w-100 d-block"  [secId]="section?.form?.back_btn_id" [secSett]="section?.form?.back_btn" [innerHTML]="((section?.form?.back_btn?.content) && (section?.form?.back_btn?.content))" customClass="btn btn-light btn-lg border-transparent" essentialClass="mb-10 mb-sm-0 mt-0 w-100" (callback)="changeStep('details')"></bk-navigate>
											</div>
											<!-- Submit -->
											<div class="col-12 col-md-6">
												<bk-navigate [isDebounce]="true" class="w-100 d-block mb-15 mb-md-0"  [secId]="section?.form?.purchase_btn_id" [secSett]="section?.form?.purchase_btn" [innerHTML]="((section?.form?.purchase_btn?.content) && (section?.form?.purchase_btn?.content))" customClass="btn btn-primary btn-lg border-transparent" essentialClass="w-100 me-sm-20 mb-10 mb-sm-0" (callback)="submitForm()"></bk-navigate>
											</div>
										</div>
										<!-- Loader -->
										<bk-app-loader [loaderId]="paymentLoaderId"></bk-app-loader>
									</div>
								</div>
							</ng-container>
						</div>
						<!-- Preview card block -->
						<div class="col-12 col-md-12 col-lg-5 col-xl-6" *ngIf="section?.preview_card">
							<div [id]="section?.preview_card?.id" class="shadow-sm tjs-body-bg card bk-card">
								<div class="card-body p-15 p-sm-25">
									<!-- Image -->
									<div class="mb-25">
										<img loading="auto" [src]="(this.gallery && this.gallery.length > 0) ? (initServ?.imgBase + selectedImg) : selectedImg" class="img-fluid tjs-pointer" alt="Gallery" height="424" width="724">
									</div>
									<!-- Amount -->
									<div class="mb-25">
										<h4 class="bk-card-amount"><bk-amount-display [amount]="(fDetails['amount'].value) ? fDetails['amount'].value : '0.00'" [isZero]="true"></bk-amount-display></h4>
									</div>
									<!-- Recipient name -->
									<div class="mb-30">
										<div class="form-control form-control-auto">
											<h5 class="input-heading text-capitalize d-flex mb-0 align-items-center" [id]="section?.preview_card?.send_to_label_id">
												<i class="tjsicon-user-2 me-10"></i>
												{{section?.preview_card?.send_to_label | translate}}: {{fDetails['recipient_name'].value}}
											</h5>
										</div>
									</div>
									<!-- Sender name -->
									<div class="mb-30">
										<div class="form-control form-control-auto">
											<h5 class="input-heading text-capitalize d-flex mb-0 align-items-center" [id]="section?.preview_card?.send_from_label_id">
												<i class="tjsicon-user-2 me-10"></i>
												{{section?.preview_card?.send_from_label | translate}}: {{fDetails['sender_first_name'].value}}  {{fDetails['sender_last_name'].value}}
											</h5>
										</div>
									</div>
									<!-- Message -->
									<div class="mb-0">
										<div class="form-control tjs-minheight-150 overflow-auto tjs-scrollbar">
											<h5 class="input-heading d-flex align-items-center mb-0" [id]="section?.preview_card?.send_message_label_id">
												{{section?.preview_card?.send_message_label | translate}}: {{fDetails['message'].value}}
											</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
