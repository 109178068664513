import { Component, Input, Output, EventEmitter, OnInit, ViewChild, Self, ViewEncapsulation,ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KeyValuePipe } from '@angular/common';
import { takeUntil } from 'rxjs';
import dayjs from 'dayjs';
// External library
import KeenSlider from "keen-slider";
// Service
import { InitServ, NgOnDestroy, DateServ, SchedulingServ} from '../../../../../Services';

@Component({
	selector: 'bk-multi-date',
	templateUrl: './MultiDateLayout.component.html',
	providers: [NgOnDestroy],
	encapsulation: ViewEncapsulation.None
})
export class MultiDateLayoutComponent implements OnInit {
	globals : any;
	@Input() ServiceProviderControl! : FormGroup;
	@Input() section : any;
	@Input() selectedDate : any;
	@Input() perDateAvailSpots : any;
	@Input() availableDates : any;
	@Input() availableProviders: any;
	@Input() dateDiscountsArray : any;
	@Input() dayDiscountsArray : any;
	@Input() dayDiscountBookings : any;
	@Input() prefilledData : any;
	@Input() calSection: any;
	@Output() onMultiDateChange : EventEmitter<any> = new EventEmitter();
	@ViewChild("dateSlide") sliderRef : ElementRef<HTMLElement> | undefined;
	// Slider configurations
	public slideConfig:any = {
		breakpoints: {
			"(min-width: 768px)": {
				slides: { perView: 2,origin:'center'},
			},
			"(min-width: 991px)": {
				slides: { perView: 3 ,origin:'center'},
			},
			"(min-width: 1200px)": {
				slides: { perView: 4.2,origin:'center'},
			},
			"(min-width: 1366px)": {
				slides: { perView: 5.3,origin:'center' },
			},
		},
		slides: { perView: 1,origin:'center'},
		initial:0,
		rtl:(this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? true : false,
		defaultAnimation:{duration:1000},
		created:(slider:any)=>{
			slider.slides.forEach((slide:any, idx:any) => slide.addEventListener("click", () =>{
				slider.moveToIdx(idx);
			}))
		}
	};
	slider: any = null;
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	newDateObj : any = {};
	constructor(public keyvalue: KeyValuePipe,  public initServ: InitServ, @Self() private destroy: NgOnDestroy, public dateServ : DateServ, public scheduleServ: SchedulingServ){
		this.admnStngs = this.initServ.appAdmnStngs;
		// Set the RTL value
		this.initServ.isRTLChange.pipe(takeUntil(this.destroy)).subscribe((value) => {
			this.slideConfig['rtl']=value;
			if(this.slider && this.sliderRef){
				this.slider.update(this.slideConfig);
			}
		});
	}

	ngOnInit(): void {
		this.globals = this.scheduleServ.globals
		this.sliceFromSelectedDate();
	}

	/**
	 * Function to call on provider slot change.
	 * @param slotDate
	 */
	clickMultiStepDate(slotDate: any): void {
		this.onMultiDateChange.emit(slotDate);
	}
	/**
	 * Slice the object from selected date.
	 */
	// eslint-disable-next-line complexity
	sliceFromSelectedDate(): void {
		let flag : boolean = true;
		this.newDateObj = {};
		let i = 0;
		let selectedIndex: number = 0;
		let currentDate = new Date();
		let currDateString = this.scheduleServ.getDateYMD(currentDate);
		// console.log('this.availableDates',this.availableDates)
		if(this.availableDates && (this.availableDates).length > 0){
			for(let date of this.availableDates){
				let minTimeLimit = this.scheduleServ.checkSameDayLimit();
				let d:any = dayjs(date)
				let t:any = d.unix()
				if(this.globals?.allowSameDayBooking == 'no' && (date == currDateString || t < minTimeLimit)){
					continue;
				}
				if(date == this.selectedDate){
					selectedIndex = i;
					flag = true
				}
				if(flag){
					this.newDateObj[date] = this.perDateAvailSpots[date];
					i++;
				}
			}
		}
		// for(let date in this.perDateAvailSpots){
		// 	if(date == this.selectedDate){
		// 		selectedIndex = i;
		// 		flag = true
		// 	}
		// 	if(flag){
		// 		this.newDateObj[date] = this.perDateAvailSpots[date];
		// 		i++;
		// 	}
		// }
		setTimeout(()=>{
			if(this.sliderRef){
				if (this.slider) {this.slider.destroy();}
				this.slideConfig.initial = selectedIndex;
				this.slider = new KeenSlider(this.sliderRef.nativeElement,this.slideConfig)
			}
		})
	}

	ngOnDestroy():void {
		if (this.slider) {this.slider.destroy();}
	}
}
