<ng-container [formGroup]="BKFrm">
	<label class="fw-bold form-label bk-form-label" [innerHTML]="section?.sel_time | safeHtml: 'html'"></label>
	<div class="dropdown rounded-2 slot-dropdown">

		<!-- <select class="form-select" [ngModel]="selectedSpot" [ngModelOptions]="{standalone: true}" (change)="SlotChange($event.target)">
			<ng-container *ngIf="perDateAvailSpots">
				<ng-container *ngFor="let dateSlot of perDateAvailSpots | keyvalue">
					<ng-container *ngTemplateOutlet="slotDropdown; context:{selectedCurrDate: dateSlot.key, slots: dateSlot.value}"></ng-container>
				</ng-container>
			</ng-container>
		</select> -->

		<button class="btn form-select fw-normal text-start ps-20 bg-white" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" (click)="callBeforeSlotSelect()">
			<span class="dir-ltr" *ngIf="(BKFrm.controls['arrival_time'].value); else arrivalLabel">
				{{scheduleServ.buildSpotName(bookingSpots,BKFrm.controls['arrival_time'].value,BKFrm.controls['booking_date'].value)}}
			</span>
			<ng-template #arrivalLabel>
				{{'Select Arrival Time' | translate}}
			</ng-template>
		</button>
		<div class="tjs-dropdown__menu tjs-scrollbar tjs-dropdown-height tjs-overflow-scroll w-100 dropdown-menu" id="spot-dropdown" aria-labelledby="dropdownMenuButton">
			<ng-container *ngIf="availableDates">
				<ng-container *ngFor="let dateSlot of availableDates">
					<ng-container *ngIf="!notAvailableDates.includes(dateSlot)">
						<ng-container *ngTemplateOutlet="slotDisplay; context:{selectedCurrDate: dateSlot, slots: perDateAvailSpots[dateSlot]}"></ng-container>
					</ng-container>
				</ng-container>
			</ng-container>
	  	</div>
	</div>
	<div class="tjs-error bk-error" *ngIf="BKFrm.controls['arrival_time'].hasError('required') && BKFrm.controls['arrival_time'].touched"><i class="tjsicon-attention"></i>Please select a slot.</div>
</ng-container>

<ng-template #slotDisplay let-selectedCurrDate="selectedCurrDate" let-slots="slots">
	<ng-container *ngIf="selectedCurrDate && slots && (slots).length > 0">
		<div>
			<label class="my-5 form-label d-block px-10 text-center">{{dateServ.formattedDateFromString(selectedCurrDate, true, admnStngs?.merchant_settings?.store?.date_format)}}, {{dateServ.formattedDateDay(selectedCurrDate, true)}}</label>
			<ng-container *ngIf="!slots || (slots).length == 0">
				<ul class="tjs-list list-unstyled">
					<li class="tjs-dropdown__link fst-italic dropdown-item py-5 px-15 d-flex justify-content-between">-- {{'No spot available' | translate}} --</li>
				</ul>
			</ng-container>
		</div>
		<div>
			<ng-container *ngIf="slots && slots.length > 0">
				<ul class="tjs-list list-unstyled">
					<li class="tjs-dropdown__link fst-italic dropdown-item py-5 px-15 d-flex justify-content-between tjs-pointer" *ngFor="let slot of slots" (click)="SlotChangeFunc(slot, selectedCurrDate)" id="li-{{slot}}-{{selectedCurrDate}}" [ngClass]="{'bg-light': selectedCurrDate == selectedDate && slot == BKFrm.controls['arrival_time'].value}">
						<span class="dir-ltr">{{scheduleServ.buildSpotName(bookingSpots,slot,selectedCurrDate)}}</span>
						<ng-container *ngTemplateOutlet="dailyDiscountTemp; context:{slot: slot, selectedCurrDate: selectedCurrDate}"></ng-container>
					</li>
				</ul>
			</ng-container>
		</div>
	</ng-container>
</ng-template>

<ng-template #slotDropdown let-selectedCurrDate="selectedCurrDate" let-slots="slots">
	<ng-container *ngIf="selectedCurrDate && slots && (slots).length > 0">
		<optgroup label="{{dateServ.formattedDateFromString(selectedCurrDate, true, admnStngs?.merchant_settings?.store?.date_format)}}, {{dateServ.formattedDateDay(selectedCurrDate, true)}}">
			<ng-container *ngIf="!slots || (slots).length == 0">
				<option>-- {{'No spot available' | translate}} --</option>
			</ng-container>
			<ng-container *ngIf="slots && slots.length > 0">
				<ng-container *ngFor="let slot of slots">
					<option value="{{selectedCurrDate+','+slot}}">
						<span class="dir-ltr">{{scheduleServ.buildSpotName(bookingSpots,slot,selectedCurrDate)}}</span>
						<ng-container *ngTemplateOutlet="dailyDiscountTemp; context:{slot: slot, selectedCurrDate: selectedCurrDate}"></ng-container>
					</option>
				</ng-container>
			</ng-container>
		</optgroup>
	</ng-container>
</ng-template>

<!-- Template for show daily discount -->
<ng-template #dailyDiscountTemp let-slot="slot" let-selectedCurrDate="selectedCurrDate">
	<span class="upto-txt" [innerHTML]="scheduleServ.showSlotDiscount(selectedCurrDate, slot , dateDiscountsArray, dayDiscountsArray, dayDiscountBookings, prefilledData) | safeHtml"></span>
</ng-template>

