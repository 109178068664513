import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class NgOnDestroy extends Subject<any> implements OnDestroy {
	ngOnDestroy() {
		setTimeout(()=>{
			this.next(null);
			this.complete();
		},0);
	}
}