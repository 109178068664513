import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, SectionServ } from '../../../../Services';

@Component({
	selector: 'bk-section-heading',
	template: `
		<!-- Title and description -->
		<div class="mb-25" *ngIf="section && ((title && section?.title) || (desc && section?.desc))">
			<h4 class="mb-0 bk-form-title" [id]="section?.title_id" *ngIf="title && section?.title" [innerHTML]="section?.title | safeHtml"></h4>
			<div class="mt-10" *ngIf="desc && section?.desc">
				<small class="bk-form-desc" [id]="section?.desc_id" [innerHTML]="section?.desc | safeHtml"></small>
			</div>
		</div>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class SectionHeadingComponent implements OnInit {
	// Variables
	@Input() section: any;
	@Input() pageSett: any;

	title: boolean = false;
	desc: boolean = false;

	constructor(private secServ: SectionServ) { }

	ngOnInit(): void {
		if(this.section){
			this.title = this.secServ.checkEleStatus(this.pageSett, this.section.title_id);
			this.desc = this.secServ.checkEleStatus(this.pageSett, this.section.desc_id);
		}
	}
}