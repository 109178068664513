/**
 * Rating star
 */
 import { ChangeDetectionStrategy, Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter, ElementRef, Renderer2, OnChanges } from '@angular/core';
 // Services
 import { NgOnDestroy, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-rating-display',
	templateUrl: './RatingDisplay.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class RatingDisplayComponent implements OnInit, OnChanges {

	// Input variables
	@Input() rating: any;
	@Input() readOnly: boolean = false;
	@Input() disabled: boolean = false;
	@Input() customClass: string = '';
	@Input() starColor: string = "#FFC107";
	@Input() starSize: string | number = "";
	@Input() color: string = "";
	@Input() count:number = 5;
	// Callback
	@Output() ratingCallback : EventEmitter<number> = new EventEmitter<number>();

	// Private variables
	private eleRef: ElementRef;
	private render: Renderer2;
	// Public variables
	stars?: any[];

	constructor(elementRef: ElementRef, render2: Renderer2, public utilServ: UtilServ) {
		this.eleRef = elementRef;
		this.render = render2;
	}

	ngOnInit(): void {}

	ngOnChanges(): void {
		// Set the variables values
		if(this.readOnly == undefined){
			this.readOnly = false;
		} else if(typeof(this.readOnly)=="string"){
			this.readOnly = (String(this.readOnly)=="true");
		}
		if(this.disabled == undefined){
			this.disabled = false;
		} else if(typeof(this.disabled)=="string"){
			this.disabled = (String(this.disabled)=="true");
		}
		// Build star
		this.buildStar();
	}
	/**
	 * Build the stars array of object
	 */
	private buildStar(): void {
		this.stars = [];
		if(this.readOnly){
			for(let i = 1; i <= this.count; i++){
				this.stars.push({star: i <= this.rating, value: i });
			}
		} else {
			for(let i = this.count; i >= 1; i--){
				this.stars.push({star: i <= this.rating, value: i });
			}
		}
		this.render.setStyle(this.eleRef.nativeElement, 'color', this.starColor);
	}
	/**
	 * Return class the disable & readonly class
	 * @returns class name
	 */
	public disable(): string {
		let bkClass='bk-star-group';
		if(this.disabled){
			return bkClass+'-disabled';
		} else if(this.readOnly){
			return bkClass+'-readonly';
		}
		return bkClass;
	}
	/**
	 * Filled the star
	 * @param val
	 * @returns star/false
	 */
	// eslint-disable-next-line complexity
	public filled(val:any): string | boolean {
		if((val%1 !== 0 && this.rating%1 !== 0 && parseInt(val) == parseInt(this.rating)) || ( val <= this.rating && this.rating && this.rating > 0) ){
			return 'star';
		}
		return false;
	}
	/**
	 * Read-only and disabled : false, callback called
	 * @param value : star value
	 */
	public toggle(value:string | number): void{
		if(this.readOnly === false && this.disabled === false){
			this.rating = value;
			this.buildStar();
			this.ratingCallback.emit(this.rating);
		}
	}
	/**
	 * show full and half star
	 * Fn works only if star read-only
	 * @param val: start
	 * @returns
	 */
	public showStar(val: string | number): string{
		if(val <= Math.floor(this.rating)){
			return '#stars-full-star'
		} else if(val == (Math.floor(this.rating)+1) && ((this.rating)-Math.floor(this.rating) > 0)){
			return '#stars-half-star'
		}
		return '#stars-empty-star';
	}
	// public getStarsArr(stars: any[]): any[] {
	// 	return stars;
	// }
}
