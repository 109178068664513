
<div [id]="section?.id" class="row mx-n10" *ngIf="data && data.length > 0">
	<div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-20 px-10" *ngFor="let invoice of data;trackBy: trackById">
		<div class="card shadow-sm h-100 rounded-5 tjs-hover-shadow__lg bk-invoice-card">
			<div class="card-body">
				<!-- Title -->
				<div class="tjs-card-title bk-card-border">
					<div class="mb-0 pb-0 card-title d-flex justify-content-between align-items-center">
						<h5 class="fw-bold mb-0 me-10 invoice-card-title">{{'ID' | translate}} : {{type == 'invoices' ? invoice?.inv_id : invoice?.id}}</h5>
						<bk-navigate *ngIf="type == 'invoices'; else gcPrint" [secSett]="section?.print_btn" [secId]="section?.print_btn_id" [designId]="section?.print_btn?.design_id" (callback)="invRedirect(invoice, true)" innerHTML="<i class='tjsicon-print me-5'></i>{{section?.print_btn?.content}}" customClass="btn btn-light" essentialClass="invoice-print-btn px-10 py-5 fs-14 d-flex align-items-center"></bk-navigate>
						<ng-template #gcPrint>
							<bk-navigate [isLinkBtn]="true" [secSett]="section?.print_btn" [routeLink]="['/invoices/giftcard-receipt/'+invoice?.id]" [secId]="section?.print_btn_id" [designId]="section?.print_btn?.design_id" innerHTML="<i class='tjsicon-print me-5'></i>{{section?.print_btn?.content}}" customClass="btn btn-light" essentialClass="invoice-print-btn px-10 py-5 fs-14 d-flex align-items-center"></bk-navigate>
						</ng-template>
					</div>
				</div>
				<!-- Date -->
				<div class="mb-20">
					<ng-container *ngIf="type == 'invoices'; else gcDate">
						<h6 class="fw-bold invoice-card-label" translate>Date</h6>
						<div class="fs-15 d-flex align-items-center invoice-card-value">
							<ng-container *ngIf="invoice?.type == 'booking'; else customInv">
								<bk-date-display class="d-inline-block me-5" [date]="invoice?.arrival_date"></bk-date-display>{{'at' | translate}}
								<span class="d-inline-block dir-ltr ms-5"> {{utilServ.slotToString(invoice?.base_location_id, invoice?.day, invoice?.arrival_time,invoice?.spot_type,invoice?.arrival_window)}}</span>
							</ng-container>
							<ng-template #customInv>
								<bk-date-display class="d-inline-block me-5" [date]="invoice?.date"></bk-date-display>{{'at' | translate}}
								<bk-time-display class="d-inline-block dir-ltr ms-5" [time]="invoice?.date"></bk-time-display>
							</ng-template>
						</div>
					</ng-container>
					<ng-template #gcDate>
						<h6 class="fw-bold invoice-card-label" translate>Date purchased</h6>
						<div class="fs-15 tjs-capitalize invoice-card-value">
							<bk-date-display class="d-inline-block me-5" [date]="invoice?.created_on"></bk-date-display>
						</div>
					</ng-template>
				</div>
				<!-- Amount -->
				<div>
					<h6 class="fw-bold invoice-card-label" translate>Amount</h6>
					<div class="fs-15 tjs-capitalize d-flex invoice-card-value">
						<ng-container *ngIf="type == 'invoices'; else gcAmt">
							<ng-container *ngIf="invoice?.type == 'booking'; else invAmt">
								<bk-amount-display *ngIf="!invoice.is_voided && invoice?.final_amount && invoice?.final_amount > 0; else zeroAmount" [amount]="invoice?.final_amount"></bk-amount-display>
								<ng-template #zeroAmount>
									<bk-amount-display [isZero]="true"></bk-amount-display>
								</ng-template>
							</ng-container>
							<ng-template #invAmt>
								<bk-amount-display [amount]="invoice?.final_amount" [isZero]="true"></bk-amount-display>
							</ng-template>
						</ng-container>
						<ng-template #gcAmt>
							<bk-amount-display [amount]="invoice?.amount" [isZero]="true"></bk-amount-display>
						</ng-template>
					</div>
				</div>
			</div>
			<!-- Footer -->
			<div class="card-footer tjs-card-footer pt-0 border-0 pb-20 bg-transparent">
				<div class="row">
					<ng-container *ngIf="type == 'invoices'; else gcAction">
						<div class="d-sm-flex gap-sm-10 d-grid">
							<bk-navigate class="flex-fill w-100" [secSett]="section?.view_btn" [secId]="section?.view_btn_id" [designId]="section?.view_btn?.design_id" (callback)="invRedirect(invoice)" [innerHTML]="section?.view_btn?.content" customClass="btn btn-opacity-info" essentialClass="invoice-view-btn w-100 px-10-imp me-sm-10 mb-10 mb-sm-0"></bk-navigate>
							<bk-navigate class="flex-fill w-100" [secSett]="section?.download_btn" [secId]="section?.download_btn_id" [designId]="section?.download_btn?.design_id" (callback)="download(invoice, true)" [innerHTML]="section?.download_btn?.content" customClass="btn btn-opacity-primary" essentialClass="invoice-download-btn w-100 px-10-imp"></bk-navigate>
						</div>
					</ng-container>
					<ng-template #gcAction>
						<div class="d-sm-flex gap-sm-10 d-grid">
							<bk-navigate class="flex-fill w-100" [isLinkBtn]="true" [routeLink]="['/invoices/giftcard-receipt/'+invoice?.id]" [secSett]="section?.view_btn" [secId]="section?.view_btn_id" [designId]="section?.view_btn?.design_id" [innerHTML]="section?.view_btn?.content" customClass="btn btn-opacity-info" essentialClass="invoice-view-btn w-100 px-10-imp me-sm-10 mb-10 mb-sm-0"></bk-navigate>
							<bk-navigate class="flex-fill w-100" [isLinkBtn]="true" [routeLink]="['/invoices/giftcard-receipt/'+invoice?.id]" [secSett]="section?.download_btn" [secId]="section?.download_btn_id" [designId]="section?.download_btn?.design_id" [innerHTML]="section?.download_btn?.content" customClass="btn btn-opacity-primary" essentialClass="invoice-download-btn w-100 px-10-imp"></bk-navigate>
						</div>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</div>
