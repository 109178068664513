import { Component, OnInit, Self, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { takeUntil } from 'rxjs/operators';
// External Lib
import { ToastrService } from "ngx-toastr";
// Services
import { NgOnDestroy, ApiServ, InitServ, UtilServ, LoaderServ, CacheService } from '../../../../../Services';

@Component({
	selector: 'bk-make-default-card-popup',
	templateUrl: './MakeCardDefaultPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class MakeCardDefaultPopupComponent implements OnInit {
	isGiveOptions: boolean = false;
	isUpcomingBookings: boolean = false;
	card: any;
	bookingOptions: any;
	bookingSettings: any;
	defaultCardForm!: FormGroup;
	currentUser: any;
	userId: any;
	//
	secId: any;
	slug: string = 'make_card_default';
	section: any = { title: null, form: null, media: null, make_default_btn: null, cancel_btn: null };
	loaderId: string = 'make-default-card';

	// eslint-disable-next-line max-params
	constructor(public dialogRef: MatDialogRef<MakeCardDefaultPopupComponent>, public initServ: InitServ, public apiServ: ApiServ, @Self() public destroy: NgOnDestroy, private loader: LoaderServ, public utilServ: UtilServ, public toastr: ToastrService, private cacheServ: CacheService) {
		// Current login user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
		// set the user id
		this.userId = this.utilServ.userId();
		// initialize the default card form
		this.defaultCardForm = new FormGroup({
			'card_id': new FormControl(null),
			'location_id': new FormControl(null),
			'apply_to': new FormControl('upcoming_with_pending'),
			'booking_with_cash': new FormControl('no')
		});
	}

	ngOnInit(): void {
		// build popup section
		this.buildSectionData();
		// settings applied from the admin store options
		if (this.bookingOptions && Object.keys(this.bookingOptions)?.length > 0) {
			this.isGiveOptions = this.bookingOptions.is_give_options;
			this.isUpcomingBookings = this.bookingOptions.is_upcoming_bookings;
		}
		// patching the card details
		if (this.card && Object.keys(this.card)?.length > 0) {
			this.defaultCardForm.patchValue({
				'card_id': this.card.id,
				'location_id': this.card.location_id,
			});
		}
		// patch the booking settings options to billing form
		if (this.bookingSettings && (this.bookingSettings.apply_to && this.bookingSettings.booking_with_cash)) {
			this.defaultCardForm.patchValue({
				'apply_to': this.bookingSettings.apply_to,
				'booking_with_cash': this.bookingSettings.booking_with_cash
			});
		}
	}

	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
		});
	}

	/**
	 * Function to make the card default.
	 */
	public makeCardDefault(): void {
		if (this.currentUser.id) {
			this.loader.show(this.loaderId, this.dialogRef);
			this.apiServ.callApiWithPathVariables('POST', 'MakeDefaultCard', [this.userId], this.defaultCardForm.value).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res));
		}
	}

	/**
	 * Method to handle api response
	 * @param res response
	 */
	public onResultCallback(res: any): void {
		if (this.apiServ.checkAPIRes(res)) {
			this.toastr.success(res.message);
			this.dialogRef.close(true);
		} else {
			if (res && res.message) {
				this.toastr.error(res.message);
			}
		}
		this.loader.hide(this.loaderId, this.dialogRef);
	}
}
