<div class="position-relative">
	<div *ngIf="paymentLogs && paymentLogs.length>0" [ngClass]="{'mt-30':(cusumData?.button?.visibility == 'yes' && invData?.pay_status != 'paid')}">
		<div class="tjs-accordion tjs-accordion__round-circle invoice-accordion tjs-rounded--lg rounded-5">
			<div class="accordion-header" role="tab" id="inv-payments" >
				<div class="d-flex justify-content-end me-25">
					<button [id]="invSec?.payment_logs?.label_id" class="accordion-button collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#inv-payment-logs" aria-expanded="false" aria-controls="flush-collapseOne" [innerHTML]="invSec?.payment_logs?.label | safeHtml">
					</button>
				</div>
			</div>
			<div id="inv-payment-logs" class="accordion-collapse collapse mt-15" role="tabpanel" aria-labelledby="inv-payments">
				<div class="card shadow-sm">
					<div class="card-body border rounded-3 p-0">
						<table [id]="invSec?.payment_logs?.table_id" class="bk-table table tjs-table tjs-table--hover tjs-table--responsive tjs-table--border__x">
							<thead>
								<tr>
									<th class="w-30">{{invSec?.payment_logs?.table?.charged_date_time}}</th>
									<th class="w-30" *ngIf="invData?.inv_type === 'bookings'">{{invSec?.payment_logs?.table?.log_event}}</th>
									<th class="w-40 text-end">{{invSec?.payment_logs?.table?.amount}}</th>
								</tr>
							</thead>
							<tbody>
								<ng-container *ngFor="let log of paymentLogs;trackBy: utilServ.trackByFnIndex">
									<tr>
										<td [attr.data-label]="invSec?.payment_logs?.table?.charged_date_time" class="">
											<div *ngIf="log?.charged_on; else emptyTemp">
												<bk-date-display class="d-block fs-14" [date]="log?.charged_on"></bk-date-display>
												<bk-time-display class="d-block fs-14" [time]="log?.charged_on" [ml5Status]="false"></bk-time-display>
											</div>
											<ng-template #emptyTemp>
												--
											</ng-template>
										</td>
										<td [attr.data-label]="invSec?.payment_logs?.table?.log_event" class="" *ngIf="invData?.inv_type === 'bookings'">
											<div class="word-break text-break">{{log?.event}}</div>
										</td>
										<td [attr.data-label]="invSec?.payment_logs?.table?.amount" class="text-end">
											<div>
												<div class="d-flex align-items-center justify-content-md-end mb-10" *ngIf="(log?.has_tip && invData?.tip?.pay)">
													<div class="tjs-tooltip-wrap">
														<div class="d-flex align-items-center tjs-pointer flex-wrap">
															<label class="mb-0 me-10">{{invSec?.payment_logs?.table?.tip}}</label>
															<span class="tjs-fs_12 text-danger me-10 text-decoration-underline" *ngIf="invData?.booking_ids && invData?.booking_ids.length > 0 && invData?.bookings_tip">Learn more</span>
															<span>: &nbsp;</span>
														</div>
														<div class="tjs-tooltip tjs-tooltip--top" *ngIf="invData?.booking_ids && invData?.booking_ids.length > 0 && invData?.bookings_tip">
															<div *ngFor="let bId of invData?.booking_ids" class="d-flex align-items-center justify-content-between mb-5">
																<ng-container *ngIf="invData?.bookings_tip[bId] && invData?.bookings_tip[bId]?.pay">
																	<span>#{{bId}}</span>
																	<bk-amount-display [amount]="invData?.bookings_tip[bId]?.pay" [isZero]="true" ></bk-amount-display>
																</ng-container>
															</div>
														</div>
													</div>
													<bk-amount-display [amount]="invData?.tip?.pay" [isZero]="true" customClass="word-break"></bk-amount-display>
												</div>
												<div [ngClass]="{'d-flex align-items-center justify-content-end': log?.is_hold}">
													<label class="mb-0 word-break me-10" *ngIf="log?.is_hold" translate>{{invSec?.payment_logs?.table?.card_hold}}: </label>
													<bk-amount-display *ngIf="log?.charged_amount !== invData?.tip?.pay" [amount]="log?.charged_amount" [isZero]="true"></bk-amount-display>
												</div>
											</div>
										</td>
									</tr>
								</ng-container>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
