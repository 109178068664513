import { Component, OnInit, ViewEncapsulation, ViewContainerRef, Self, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Services
import { NgOnDestroy, RenderComponentServ, ApiServ, InitServ, LoaderServ, UtilServ, StyleServ, CacheService } from '../Services';

@Component({
	selector: 'bk-base',
	template: ``,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class BaseComponent implements OnInit {
	@Input() loadSett: boolean = true;
	// Variables
	loaderId: string = '';
	previewMode: boolean = false;
	containerId: string = '';
	/**
	 * Constructor
	 */
	constructor(private vcRef: ViewContainerRef, public rcServ: RenderComponentServ, public apiServ: ApiServ, public initServ: InitServ, public actRoute: ActivatedRoute, public loader: LoaderServ, @Self() public destroy: NgOnDestroy, public utilServ: UtilServ, public styleServ: StyleServ, public router: Router, public cacheServ: CacheService) {
		// Preview mode, depend on theme slug and query params preview
		this.previewMode = this.initServ.theme ? true : false;
		let preview: any = this.actRoute.snapshot.queryParamMap.get('preview');
		if (preview == 'true') {
			this.previewMode = true;
		}
	}
	/**
	 * Initialiser Life-cycle hook
	 */
	ngOnInit() { }
	/**
	 * Load the page data based on page slug
	 * @param slug: page slug
	 * @param containerId: container id to append sections
	 * @param loaderId: page id
	 */
	// eslint-disable-next-line complexity, max-params
	public loadPageData(slug: string, containerId: string, loaderId: string, isEmbed: boolean = false, parentSlug: string = '') {
		if (isEmbed) {
			this.loadPage(slug, containerId, loaderId, isEmbed, parentSlug);
		} else {
			let path: any;
			let currentRoute: any = this.actRoute;
			let parentRoute: any = currentRoute.parent;
			let parentPath = (parentRoute && parentRoute.routeConfig && parentRoute.routeConfig?.path) && parentRoute.routeConfig?.path;
			// Child route
			if (currentRoute) {
				while (currentRoute.firstChild) {
					currentRoute = currentRoute.firstChild;
				}
			}
			let pagePath: any = ((this.router.url).split(/[?#]/))[0];
			if (currentRoute && currentRoute.routeConfig && currentRoute.routeConfig?.path) {
				let parent: any = parentPath ? `/${parentPath}` : '';
				path = parent + `/${currentRoute.routeConfig?.path}`
			}
			if (!path || path == '/**') {
				path = pagePath;
			}
			let appRouteStatus = (this.initServ.appRoutes).includes(path);
			if (appRouteStatus) {
				this.loadPage(slug, containerId, loaderId, isEmbed, parentSlug);
			}
		}
	}
	/**
	 * Load the page data
	 * @param slug: slug
	 * @param containerId: container id
	 * @param loaderId: loader id
	 * @param isEmbed: boolean
	 */
	// eslint-disable-next-line max-params
	private loadPage(slug: any, containerId: string, loaderId: string, isEmbed: boolean = false, parentSlug: string = ''): void {
		this.containerId = containerId;
		this.loaderId = loaderId;
		// Set loader id for hide the loader in case of api handle error.
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId);
		// Query params
		let queryParams: any = { slug: slug, language: this.initServ.savedLng, mode: 'live', theme_slug: this.initServ.theme };
		if (this.initServ.theme) {
			queryParams['mode'] = 'preview';
		}
		// load (Api call) the page data & style if it is not cached, otherwise it return the cached data assoicated to slug provided.
		this.cacheServ.buildPageSection(queryParams, slug, isEmbed).then((res: any) => this.onResultCallback(res, isEmbed, parentSlug));
	}
	/**
	 * On result callback method
	 * @param res api response
	 * API response handler
	 */
	// eslint-disable-next-line complexity
	public onResultCallback(res: any, isEmbed: boolean = false, parentSlug: string = '') {
		if (this.apiServ.checkAPIRes(res) && res.data) {
			let data: any = res.data;
			if (data.column_sections && (data.column_sections).length > 0) {
				data.added_sections = (data.added_sections).filter((el: any) => !(data.column_sections).includes(el));
			}
			if (res.data && res.data.settings) {
				let pageSett: any = res.data.settings;
				if(!isEmbed && pageSett.redirect_status && pageSett.redirect_status == 'yes' && (pageSett.redirect_link_to == 'page' || pageSett.redirect_link_to == 'web') && pageSett.redirect_link_url){
					if(this.loadSett){
						this.utilServ.redirectPageURL(pageSett); // Redirect page url
					}
				} else {
					this.rcServ.setPageData = JSON.parse(JSON.stringify(res.data));
					if (!isEmbed) {
						// Theme popups
						this.rcServ.loadThemePopups(pageSett);
						this.initServ.setCurrPageIdInBody(res.data._id);
					}
					if(this.loadSett){
						// Page title
						this.rcServ.setPageTitle(pageSett);
						// Add tracking codes added in page settings
						this.rcServ.addTrackingCodes(pageSett);
						// Page setting
						this.utilServ.setPageSett(res.data._id, pageSett);
					}
				}
			}
			// Reset the page api hit count;
			this.rcServ.setPageApiHitCount = 0;
			this.createDynamicComponent(isEmbed, parentSlug);
			this.initServ.firstPageData = null;
			this.initServ.firstPageSlug = null;
		} else {
			this.loader.hide(this.loaderId);
			this.utilServ.redirectBrokenUrl();
		}
	}
	/**
	 * Loop through all sections of the page
	 */
	private createDynamicComponent(isEmbed: boolean = false, parentSlug: string = '') {
		let i = 0;
		if (this.rcServ.pageData && this.rcServ.pageData.added_sections && (this.rcServ.pageData.added_sections).length > 0) {
			if (!isEmbed) {
				this.vcRef.clear();
				this.removeSections();
			}
			for (let secId of this.rcServ.pageData.added_sections) {
				i = i + 1;
				this.createComponent(secId, i, parentSlug);
			}
		}
	}
	/**
	 * Load components using "view reference"
	 * @param secId Id of section which we want to add on the page
	 * @param i index, so we can create unique id of wrapper for each section
	 */
	// eslint-disable-next-line complexity
	private createComponent(secId: string, i: number, parentSlug: string = '') {
		let secSlug: string = this.rcServ.pageData.section_settings[secId].slug;
		// Referral signup case
		if (parentSlug && parentSlug == 'referral-signup' && secSlug == 'signup_form') {
			secSlug = 'referral_signup_form';
		}
		let buildSec: boolean = true;
		if (secSlug == 'embed_login_form' || secSlug == 'embed_signup_form' || secSlug == 'embed_send_gift_card_form' || secSlug == 'embed_forgot_password_form' || secSlug == 'embed_quote_form') {
			buildSec = false;
		}
		if (secSlug && buildSec) {
			this.rcServ.renderComponent(secId, secSlug, this.vcRef);
			let div = document.createElement('div');
			div.setAttribute('class', 'bk-element-container position-relative');
			div.setAttribute('id', 'component-' + i);
			div.appendChild(this.rcServ.componentRef.location.nativeElement);
			// When updating the cached page flow, html is not rendered to get the HTML elem having "this.containerId" id
			setTimeout(() => {
				let elem: any = document.getElementById(this.containerId);
				if (elem) {
					elem?.appendChild(div);
				}
			}, 0);
		}
		this.loader.hide(this.loaderId);
	}
	/**
	 * Remove the previous sections child
	 */
	private removeSections() {
		let contId: any = document.getElementById(this.containerId);
		let paras: any;
		if (contId) {
			paras = contId.getElementsByClassName('bk-element-container');
		}
		if (paras && paras.length > 0) {
			while (paras[0]) {
				paras[0].parentNode.removeChild(paras[0]);
			}
		}
	}
}
