import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { XhrFactory } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
// External library
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'; // Translator
import { ToastrModule } from 'ngx-toastr'; // Toaster
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
// Environments
import { environment as env } from '../environments/environment';
// Modules
import { AppRoutingModule } from './app-routing.module'; // app routing module
import { CoreModule } from './Core/Core.module'; // Core module
import { GlobalDefaultModule, BookingFormModule, UserComponentsModule } from './Global';// Global module
import { ThemeElementsModule } from './ThemeElements/ThemeElements.module'; // Theme elements module
import { PopupsModule } from './Popups/Popups.module'; // Popups module

// Services
import { APIURL, InitServ, CustomTranslationLoader,EjabService,ChecklistService } from './Services';
import { AuthGuard } from './Core/_guards'; // Auth services
import { JwtInterceptor, BrowserXhrWithAbortError } from './Core/_helpers';
import { AuthServ } from './Core/_services';
// Constants
import { TOAST_OPTIONS } from './Constants';
// Components
import { AppComponent } from './app.component';
import { MainComponent } from './Main/Main.component';
import { BaseComponent } from './Base/Base.component';
import { QuoteRedirectionComponent } from './QuoteRedirection/QuoteRedirection.component';
// Session components
import { SessionComponent, SessionSocialComponent, LoginComponent, SignupComponent, ForgotPasswordComponent, ResetPasswordComponent, PasswordProtectComponent, ReferralSignupComponent, SocialEmailPopupComponent, ReAuthPaymentComponent, InvoiceComponent, InvPaymentComponent, InvSidebarComponent, InvFromBkngTableComponent, InvPaymentLogsComponent, ContractPopupComponent, InvCustomFieldsComponent, AddPaymentComponent, LiveReviewsComponent, BookingReceiptComponent, ThirdPartyConnectComponent, VerifyEmailMsgComponent } from './Session';
import { ExistingPageComponent } from './ExistingPage/ExistingPage.component';
import { TextMaskModule } from 'angular2-text-mask';

// configure Bugsnag asap
Bugsnag.start({ apiKey: env.bugsnagKey, appVersion: env.appVersion });
// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
	return new BugsnagErrorHandler()
}
export function createTranslateLoader(http: HttpClient, APIURL: APIURL, InitServ: InitServ) {
	return new CustomTranslationLoader(http, APIURL, InitServ);
}
export function initializeApp(initServ: InitServ) {
	return () => initServ.initializeAppData().toPromise();
}
export function appIpAddress(initServ: InitServ ): Function {
	return () => initServ.appIpAddress()
}

const disableAnimations = !('animate' in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));
@NgModule({
	declarations: [AppComponent, MainComponent, BaseComponent, QuoteRedirectionComponent, SessionComponent, SessionSocialComponent, LoginComponent, SignupComponent, ForgotPasswordComponent, ResetPasswordComponent, PasswordProtectComponent, ReferralSignupComponent, SocialEmailPopupComponent, ExistingPageComponent, ReAuthPaymentComponent, InvoiceComponent, InvPaymentComponent, InvSidebarComponent, ContractPopupComponent, InvPaymentLogsComponent, InvFromBkngTableComponent, InvCustomFieldsComponent, AddPaymentComponent, LiveReviewsComponent, BookingReceiptComponent, ThirdPartyConnectComponent, VerifyEmailMsgComponent],
	imports: [
		BrowserModule, HttpClientModule, CoreModule, BrowserAnimationsModule.withConfig({ disableAnimations }), MatDialogModule,
		AppRoutingModule, GlobalDefaultModule, PopupsModule, ThemeElementsModule, FormsModule, ReactiveFormsModule,
		ToastrModule.forRoot(TOAST_OPTIONS), MatDatepickerModule, MatNativeDateModule, BookingFormModule,UserComponentsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient, APIURL, InitServ]
			}
		}),
		TextMaskModule
	],
	providers: [
		APIURL, InitServ,EjabService,ChecklistService,
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: APP_INITIALIZER, useFactory: appIpAddress, deps: [InitServ], multi: true },
		{ provide: APP_INITIALIZER, useFactory: initializeApp, deps: [InitServ], multi: true },
		AuthGuard, AuthServ,
		{provide: XhrFactory,useClass: BrowserXhrWithAbortError},
		{ provide: ErrorHandler, useFactory: errorHandlerFactory }
	],
	bootstrap: [AppComponent],
	entryComponents: [SocialEmailPopupComponent]
})
export class AppModule {}
