import { Component, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../Services';
// Inheritance components
import { PricingParameterComponent } from '../../../Global/BookingForm';

@Component({
	selector: 'bk-short-form-pricing-params',
	templateUrl: './ShortFrmPricingParams.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ShortFrmPricingParamsComponent extends PricingParameterComponent {
	@Input() variationId: string = '';
	@Input() parentSection: any;
	@Input() sectionIds: any;

	@Output() serviceChange: EventEmitter<any> = new EventEmitter();
	@Output() hourlyServiceChange: EventEmitter<any> = new EventEmitter();
	@Output() frequencyChange: EventEmitter<any> = new EventEmitter();
	services: any;
	console:any=console;

}