import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ} from '../../Services';

@Component({
	selector: 'bk-about-us',
	templateUrl: './AboutUs.component.html',
	encapsulation: ViewEncapsulation.None,
	providers:[NgOnDestroy]

})
export class AboutUsComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	variationClass: any = {
		bk_about_us_V1: {
			main:'tjs-about--v1',
			container:'container-full-width',
			col:'col-md-6 col-lg-6',
			list_col:'col-md-12  col-lg-6 mt-45',
			media:'col-md-6 col-lg-6 col-xl-6'
		},
		bk_about_us_V2: {
			main:'tjs-about--v2',
			container:'container-full-width',
			col:'col-md-6 col-lg-6 order-1',
			list_col:'col-md-12  col-lg-6 mt-45',
			media:'col-md-6 col-lg-6 col-xl-6'
		},
		bk_about_us_V3: {
			main:'tjs-about--v3 py-md-80 py-40 tjs-body-bg',
			container:'container',
			col:'col-md-12 col-lg-12 order-last',
			list_col:'col-lg-4 col-md-6 mt-45',
			media:'col-md-12 col-lg-12 order-first',
			list_item:'text-center'
		},
		bk_about_us_V4: {
			main:'tjs-about--v4 pt-md-80 pt-40 pb-md-70 pb-40 bg-white',
			container:'container',
			list_col:'col-lg-4 col-md-6 my-15',
			list_item:'text-center p-20 p-md-35 h-100 border tjs-transition--base'
		}
	}
	// Section fields
	section: any = {
		title: null,
		desc: null,
		media: null,
		list: null
	}
	sectionData: any;
	varId: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ) { }

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
		this.varId = this.pageSett[this.secId]?.variation_id;
	}
}
