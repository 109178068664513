import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, UtilServ } from '../../Services';
import { AuthServ } from '../../Core/_services';
// Constants
import { EMAIL_REG_EXP } from '../../Constants';

@Component({
	selector: 'bk-forgot-password',
	templateUrl: './ForgotPassword.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ForgotPasswordComponent implements OnInit {

	// Variables
	@Input() secId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		sub_title: null,
		media: null,
		form: null
	}
	forgotForm!: FormGroup; // Forgot password form
	loaderId: string = 'forgot-loader';
	constructor(private frmBldr: FormBuilder, public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, private authServ: AuthServ, public utilServ: UtilServ) {
		if(!this.initServ.theme){
			this.authServ.removeCurrentUser()
		}
	}

	ngOnInit(): void {
		// Embed status: true and theme not exist remove the current login user local storage
		if(this.utilServ.embedStatus && !this.initServ.theme){
			this.authServ.removeCurrentUser();
			this.utilServ.id = null;
		}
		// Forgot password form build
		this.forgotForm = this.frmBldr.group({
			email_id: [null, [Validators.required, Validators.pattern(EMAIL_REG_EXP)]]
		});
		// Build section data
		if(this.secId && this.rcServ.pageData){
			this.pageSett = this.rcServ.pageData.section_settings;
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
	}
	// convenience getter for easy access to form fields
	get f(): { [key: string]: AbstractControl } {
		return this.forgotForm.controls;
	}
	/**
	 * Submit the login form
	 */
	public submitForm(): void {
		if(this.forgotForm.valid){
			this.forgotForm.controls['email_id'].setValue((this.forgotForm.controls['email_id'].value).toLowerCase());
			this.authServ.reqResetPassword(this.forgotForm.value, this.loaderId);
		} else{
			this.forgotForm.controls['email_id'].markAsTouched();
		}
	}
}
