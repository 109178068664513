/* eslint-disable complexity */
import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';
// External lib
import { ToastrService } from 'ngx-toastr';
import { sha512Hash } from 'iron-crypto-pkg';
// Services
import { UtilServ, ApiServ, InitServ, LoaderServ } from '../../Services';
// Constants
import { IS_DEV, DEV_HOST } from '../../Constants';

interface RedirectOptions {
	role: string;
	access_token: string;
	onetime_access_token: string;
	is_default_setup: boolean;
	isRedirect: boolean;
}
interface LoginOptions {
	redirectUrl?: string;
	loaderId?: string;
	isRedirect?: boolean;
	isSession?: boolean;
	isBooknow?: boolean;
	isSetOldData?: boolean;
}

@Injectable()
export class AuthServ {
	// Private variables
	private destroy = new Subject<void>();
	public token: string;
	ipAddress: any = this.initServ.ipAddress ? this.initServ.ipAddress : "107.180.54.183";
	cypherToken: string = 'NPZ8fvABP5pKwU3';
	cypherTokenTwo: string = 'AP6EHEG37zJ2c9j';

	constructor(private utilServ: UtilServ, private apiServ: ApiServ, private toastr: ToastrService, private initServ: InitServ, private router: Router, private loader: LoaderServ) {
		const currentUser = this.utilServ.appLocalStorage();
		this.token = currentUser && currentUser.token;
	}

	/**
	 * Authenticates the user and manages login actions.
	 * Sets loader based on provided loaderId and constructs headers for the API call.
	 * Upon successful API response, handles user session, localStorage, and redirection based on role and options.
	 * Handles error and hides loader if the login fails.
	 * @param user User credentials and related login data.
	 * @param options Optional settings for redirection, loader ID, and other login behaviors.
	 * @returns Observable of login status.
	 */
	public login(user: any, options: LoginOptions = {}): Observable<any> {
		let { redirectUrl = '', loaderId = 'main', isRedirect = false, isSession = false, isBooknow = false, isSetOldData = false } = options;
		// Set loader and header
		this.setLoader(loaderId);
		let header = { 'Content-Type': 'application/json', 'Ip': this.ipAddress };

		// API call to login
		return this.apiServ.callApi('POST', 'Login', user, header).pipe(takeUntil(this.destroy)).toPromise().then(async (resp: any) => {
			if (!this.apiServ.checkAPIRes(resp)) {
				this.handleLoginError(resp, loaderId);
				return false;
			}

			let { role, id, access_token, onetime_access_token, is_default_setup } = resp.data;
			if (!isSession || !this.utilServ.inIframe(this.utilServ.embedStatus)) {
				this.setUserLocalStorage(resp.data, isSetOldData);

				if (role === 'customer' && (isBooknow || !this.utilServ.inIframe(this.utilServ.embedStatus))) {
					this.initServ.loggedInUser(id);
				}
			}

			let redirectData = this.getRedirectUrl({ role, access_token, onetime_access_token, is_default_setup, isRedirect });

			if (isRedirect) {
				this.handleRedirect(redirectData, redirectUrl);
			} else if (role !== 'customer') {
				window.location.href = `${window.location.protocol}//${IS_DEV ? DEV_HOST : window.location.hostname}/${redirectData.url}`;
			}

			this.loader.hide(loaderId);
			return true;
		});
	}

	/**
	 * Displays loader based on the provided loader ID.
	 * Calls the API service to set the loader ID, then displays the loader.
	 * @param loaderId Identifier for the loader.
	 */
	private setLoader(loaderId: string): void {
		if (loaderId) {
			this.apiServ.setLoaderId(loaderId);
			this.loader.show(loaderId);
		}
	}

	/**
	 * Handles login errors and hides the loader.
	 * If the response contains an error message, displays it as a toastr error notification.
	 * @param resp API response containing error information.
	 * @param loaderId Identifier for the loader to hide after error handling.
	 */
	private handleLoginError(resp: any, loaderId: string): void {
		if (resp && resp.message) {
			this.toastr.error(resp.message, '', { timeOut: 10000 });
		}
		this.loader.hide(loaderId);
	}

	/**
	 * Generates the URL for redirection based on user role and other settings.
	 * Determines the redirect path and URL depending on role type, access tokens, and setup status.
	 * @param options Settings for redirection URL generation.
	 * @returns Object containing the redirect URL.
	 */
	private getRedirectUrl(options: RedirectOptions): { url: string } {
		let { role, access_token, onetime_access_token, is_default_setup, isRedirect } = options;

		switch (role) {
			case 'provider':
				return { url: isRedirect ? `/provider/login/${access_token}` : '/provider' };
			case 'merchant':
				return {
					url: is_default_setup ? `/admin/dashboard` : `/admin/start/${access_token}`
				};
			case 'staff':
				return { url: `/admin/login/${onetime_access_token}` };
			default:
				return { url: isRedirect ? `/login/${access_token}` : '/dashboard' };
		}
	}

	/**
	 * Handles redirection based on iframe status and provided URLs.
	 * Uses `top` window if inside an iframe without a theme, otherwise displays success message and navigates.
	 * @param redirectData - Object containing the URL for redirection.
	 * @param redirectUrl - Fallback URL in case `redirectData` is not available.
	 */
	private handleRedirect(redirectData: { url: string }, redirectUrl: string): void {
		if (this.utilServ.inIframe(this.utilServ.embedStatus) && !this.initServ.theme) {
			if (top) {
				top.window.location.href = redirectData.url;
			}
		} else {
			this.toastr.success(this.initServ.appStr.toastr.loggedIn);
			redirectUrl === '/dashboard' ? this.router.navigate([`/${this.initServ.appDynamicRoutes['dashboard']}`]) : (window.location.href = redirectUrl);
		}
	}

	/**
	 * Retrieves role-based redirect information, returning the appropriate URL paths.
	 * Uses role type and other user info to construct URLs for both redirect and iframe embeds.
	 * @param userInfo - Contains role, access tokens, and setup status.
	 * @returns - Object with redirect and embed URLs.
	 */
	private getRoleRedirectInfo(userInfo: { role: 'provider' | 'merchant' | 'staff'; is_default_setup: boolean; access_token: string; onetime_access_token: string }): { redirectUrl: string; embedUrl: string }{
		let rolePaths = {
			provider: '/provider',
			merchant: userInfo.is_default_setup ? '/admin/dashboard' : `/admin/start/${userInfo.access_token}`,
			staff: '/admin/dashboard',
			default: '/dashboard'
		};

		let redirectUrls = {
			provider: `/provider/login/${userInfo.access_token}`,
			merchant: userInfo.is_default_setup ? `/admin/login/${userInfo.onetime_access_token}` : `/admin/start/${userInfo.access_token}`,
			staff: `/admin/login/${userInfo.onetime_access_token}`,
			default: `/login/${userInfo.access_token}`
		};
		//  `userInfo.role` has an `any` type, which TypeScript can't confirm as a valid key for the `rolePaths` object
		let roleKey = userInfo.role in rolePaths ? userInfo.role as keyof typeof rolePaths : 'default';
		return { redirectUrl: rolePaths[roleKey], embedUrl: redirectUrls[roleKey] };
	}

	/**
	 * Executes redirection based on iframe status.
	 * Uses `top` window for iframes or redirects directly to the provided URL.
	 * @param url
	 * @param embedUrl
	 * @param isIframe
	 */
	private performRedirection(url: string, embedUrl: string, isIframe: boolean): void {
		if (isIframe && top) {
			top.window.location.href = embedUrl;
		} else {
			window.location.href = url;
		}
	}

	/**
	 *Handles post-login actions including redirection and loader visibility.
	 * Retrieves role-based URLs and performs redirection based on role and iframe status.
	 * @param resp - API response containing user data.
	 * @param redirectUrl - Custom URL for redirection (optional).
	 * @param isRedirect -Boolean to determine if redirection should occur.
	 * @param loaderId - ID for managing loader visibility.
	 * @returns Boolean indicating success of the login process.
	 */
	public handleLoginActions(resp: any, redirectUrl: string = '', isRedirect: boolean = false, loaderId: string = ''): boolean {
		let userInfo = resp?.data;
		let { redirectUrl: roleRedirectUrl, embedUrl } = this.getRoleRedirectInfo(userInfo);
		let redirectPath = redirectUrl || roleRedirectUrl;

		if (isRedirect) {
			let isIframe = this.utilServ.inIframe(this.utilServ.embedStatus) && !this.initServ.theme;
			this.performRedirection(redirectPath, embedUrl, isIframe);
		} else if (userInfo.role !== 'customer') {
			let baseUrl = `${window.location.protocol}//${IS_DEV ? DEV_HOST : window.location.hostname}`;
			window.location.href = `${baseUrl}${embedUrl}`;
		}

		this.toastr.success(this.initServ.appStr.toastr.loggedIn);
		this.loader.hide(loaderId);
		return true;
	}

	/**
	 * Signs up a user as a provider through an API call.
	 * Displays loader based on loaderId, makes signup API call, and handles response accordingly.
	 * @param user Provider signup data.
	 * @param options Optional settings for loader visibility, redirection, and session handling.
	 */
	public signupAsPrvdrApi(user: any, options: LoginOptions = {}): void {
		let { redirectUrl = '', isRedirect = false, loaderId = '', isSession = false, isSetOldData = false } = options;
		// Set loader id for hide the loader in case of api handle error.
		loaderId = loaderId ? loaderId : 'main';
		if (loaderId) {
			this.apiServ.setLoaderId(loaderId);
			this.loader.show(loaderId);
		}
		let header = { 'Content-Type': 'application/json', 'Ip': this.ipAddress };
		this.apiServ.callApi('POST', 'ProviderSignup', user, header).pipe(takeUntil(this.destroy)).subscribe((resp: any) => this.signupAsPrvdrApiRes(resp, { redirectUrl, isRedirect, loaderId, isSession, isSetOldData }));
	}

	/**
	 * Handles the response from the signup API for providers.
	 * @param resp - The response from the signup API.
	 * @param options - Configuration options for login including redirect URL, session control, and local storage settings.
	 * @returns {boolean | void} - Returns false if API response indicates a failure; otherwise, returns nothing.
	 */
	private signupAsPrvdrApiRes(resp: any, options: LoginOptions = {}): boolean | void {
		let { redirectUrl = '', isRedirect = false, loaderId = '', isSession = false, isSetOldData = false } = options;

		if (this.apiServ.checkAPIRes(resp)) {
			if (resp.data) {
				// Set the user local storage
				if (!isSession || !this.utilServ.inIframe(this.utilServ.embedStatus)) {
					this.setUserLocalStorage(resp?.data, isSetOldData);
				}
				this.handleLoginActions(resp, redirectUrl, isRedirect, loaderId)
			} else {
				this.router.navigate(['/verify-email']);
			}
			this.toastr.success(resp?.message);
			return;
		}
		// Indicate failed
		if (resp?.message) {
			this.toastr.error(resp.message, '', { timeOut: 10000 });
		}
		this.loader.hide(loaderId);
		return false;
	}

	/**
	 * Handles the login process for users.
	 * @param accessToken - The access token provided by the user.
	 * @param loaderId - ID for loading indicator.
	 * @param isSetOldData - Flag to determine if old data should be set in storage.
	 * @returns {Promise<boolean>} - Returns true on successful login, otherwise false.
	 */
	public async loginAsUser(accessToken: any, loaderId: string = 'main', isSetOldData: boolean = false): Promise<boolean> {
		try {
			this.apiServ.setLoaderId(loaderId);
			this.loader.show(loaderId);

			let token = `${this.cypherToken}${accessToken}${this.cypherTokenTwo}${this.ipAddress.replace(/\./g, '')}`;
			let encryptKey = await sha512Hash(token);
			let header = { 'Content-Type': 'application/json', 'Ip': this.ipAddress };
			let postdata = { access_token: accessToken };

			let resp = await this.apiServ.callApiWithPathVariables('POST', 'LoginAsUser', [encryptKey], postdata, header)
				.pipe(takeUntil(this.destroy))
				.toPromise();

			if (!this.apiServ.checkAPIRes(resp)) {
				if (resp?.message) this.toastr.error(resp.message, '', { timeOut: 10000 });
				return false;
			}

			let userInfo: any = resp.data;
			this.setUserLocalStorage(userInfo, isSetOldData);

			if (userInfo.role === 'customer') {
				this.initServ.loggedInUser(userInfo.id);
			}

			return true;
		} finally {
			this.loader.hide(loaderId);
		}
	}

	/**
	 * Initiates the login process for an admin.
	 * @param currentUser - Current user data containing access tokens.
	 * @returns {Promise<Observable<any>>} - Returns an observable indicating the status of the login request.
	 */
	public async loginAsAdmin(currentUser: any): Promise<Observable<any>> {
		let token = this.cypherToken + currentUser.admin_token + this.cypherTokenTwo + (currentUser.Ip).replace(/\./g, '')
		let encryptKey = await sha512Hash(token);
		let postdata = { onetime_access_token: currentUser.admin_token };
		return this.apiServ.callApiWithPathVariables('POST', 'LoginWithOtToken', [this.utilServ.userId(), encryptKey], postdata).pipe(takeUntil(this.destroy)).toPromise().then(async (resp: any) => {
			// login successful if there's a jwt token in the response
			if (this.apiServ.checkAPIRes(resp)) {
				let userInfo: any = resp.data;
				// Set the user local storage
				this.setUserLocalStorage(userInfo);
				this.toastr.success(this.initServ.appStr.toastr.loggedIn);
				window.location.href = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname) + '/admin/dashboard';
				return true;
			} else {
				// Indicate failed
				if (resp && resp.message) {
					this.toastr.error(resp.message, '', { timeOut: 10000 });
				}
				return false;
			}
		});
	}

	/**
	 * Handles user signup.
	 * @param data - User signup data.
	 * @param authKey - Key for authenticating the signup.
	 * @param loaderId - ID for loading indicator.
	 * @param isSession - Determines if session should be maintained.
	 * @param isSocial - Determines if the signup is via social login.
	 */
	public async signup(data: any, authKey: any, loaderId: string, isSession: boolean = false, isSocial: boolean = false): Promise<void> {
		// Set loader id for hide the loader in case of api handle error.
		loaderId = loaderId ? loaderId : 'main';
		this.apiServ.setLoaderId(loaderId);
		this.loader.show(loaderId);
		let authToken = await sha512Hash(authKey);
		let header = { 'Content-Type': 'application/json', 'Ip': this.ipAddress, 'Authtoken': authToken };
		this.apiServ.callApi('POST', 'Signup', data, header).pipe(takeUntil(this.destroy)).subscribe(async (resp: any) => {
			// Signup successful
			if (this.apiServ.checkAPIRes(resp)) {
				let userInfo: any = resp.data;
				// Set the user local storage
				if (!isSession || !this.utilServ.inIframe(this.utilServ.embedStatus)) {
					this.setUserLocalStorage(userInfo);
					// Role customer, called single user information
					if (userInfo.role == 'customer' && !this.utilServ.inIframe(this.utilServ.embedStatus)) {
						this.initServ.loggedInUser(userInfo.id);
					}
				}
				// Iframe
				let redirectUrl = '/login/' + userInfo.access_token
				if (this.utilServ.inIframe(this.utilServ.embedStatus) && !this.initServ.theme) {
					if (top) {
						top.window.location.href = redirectUrl;
					}
				} else {
					this.router.navigate(['/' + this.initServ.appDynamicRoutes['dashboard']]).then(() => {
						if (isSocial) {
							this.toastr.success(resp.message);
						} else {
							this.toastr.success(this.initServ.appStr.toastr.signUp);
						}
					});
				}
				this.loader.hide(loaderId);
			} else {
				// Indicate failed
				if (resp && resp.message) {
					this.toastr.error(resp.message, '', { timeOut: 10000 });
				}
				this.loader.hide(loaderId);
			}
		});
	}

	/**
	 * Initiates an API call for an unverified provider.
	 * @param data - Data payload for the request.
	 * @param authKey - Authentication key for the request.
	 */
	public async unverifiedPrvdrApi(data: any, authKey: any): Promise<void> {
		let authToken = await sha512Hash(authKey);
		let header = { 'Content-Type': 'application/json', 'Ip': this.ipAddress, 'Authtoken': authToken };
		this.apiServ.callApi('POST', 'UnVerifiedPrvdr', data, header).pipe(takeUntil(this.destroy)).subscribe((resp: any) => this.unverifiedPrvdrApiRes(resp));
	}

	/**
	 * Handles response of unverifiedPrvdrApi
	 * @param resp
	 */
	private unverifiedPrvdrApiRes(resp: any): void {
		if (resp && resp.api_status == 2) {
			this.toastr.success(resp?.message);
			// TODO SONY REMOVE AFTER FINALIZATION
			this.router.navigate(['/verify-email']);
		} else {
			this.toastr.error(resp?.message);
		}
	}

	/**
	 * Stores user information in local storage.
	 * @param userInfo - Information of the user to be stored.
	 * @param isSetOldData - Flag to determine if old data should be preserved in storage.
	 */
	private setUserLocalStorage(userInfo: any, isSetOldData: boolean = false): void {
		let { session_token, id, first_name, last_name, photo_url, role, status, access_token, is_default_setup, onetime_access_token, is_new } = userInfo;
		let localStorageData = {
			Ip: this.ipAddress,
			token: session_token,
			id,
			first_name,
			last_name,
			photo_url,
			role,
			status,
			access_token,
			is_default_setup,
			onetime_access_token,
			is_new,
			old_local_storage: isSetOldData && this.utilServ.inIframe(this.utilServ.embedStatus) ? this.utilServ.appLocalStorage('currentUser', true) : null
		}
		try {
			localStorage.setItem('currentUser', JSON.stringify(localStorageData));
		} catch (err) {
			console.error();
		}
	}

	/**
	 * Handles password-related requests (e.g., reset, request).
	 * @param endpoint - The API endpoint to call.
	 * @param data - Payload data for the request.
	 * @param loaderId - ID for the loader to show.
	 * @param isRedirect - Indicates if redirect is needed on success.
	 * @param isPromise - Determines if the call should return a promise.
	 * @returns {any} - Subscription or promise based on isPromise parameter.
	 */
	private handlePasswordRequest(endpoint: string, data: any, loaderId: string, isRedirect: boolean, isPromise: boolean): any {
		this.apiServ.setLoaderId(loaderId);
		this.loader.show(loaderId);

		let apiCall = this.apiServ.callApi('POST', endpoint, data).pipe(takeUntil(this.destroy));
		if (isPromise) {
			return apiCall.toPromise().then((resp: any) => this.handlePasswordResponse(resp, isRedirect, loaderId));
		} else {
			return apiCall.subscribe((resp: any) => this.handlePasswordResponse(resp, isRedirect, loaderId));
		}
	}

	public reqResetPassword(data: any, loaderId: string = 'main', isRedirect: boolean = true): void {
		this.handlePasswordRequest('ReqResetPassword', data, loaderId, isRedirect, false);
	}

	public resetPassword(data: any, loaderId: string = 'main', isRedirect: boolean = true): Promise<boolean> {
		return this.handlePasswordRequest('ResetPassword', data, loaderId, isRedirect, true);
	}

	private handlePasswordResponse(resp: any, isRedirect: boolean, loaderId: string): boolean {
		this.loader.hide(loaderId);
		if (this.apiServ.checkAPIRes(resp)) {
			let successMessage = resp?.message || "Operation successful.";
			this.toastr.success(successMessage);
			if (isRedirect) {
				let loginUrl = '/' + this.initServ.appDynamicRoutes['login'];
				let redirectOpts = this.utilServ.embedStatus ? { queryParams: { embed: "true" } } : undefined;
				this.router.navigate([loginUrl], redirectOpts);
			}
			return true;
		} else {
			let errorMessage = resp?.message || "An error occurred.";
			this.toastr.error(errorMessage, '', { timeOut: 10000 });
			return false;
		}
	}

	/**
	 * Remove current user local storage
	 */
	public removeCurrentUser(): void {
		try {
			localStorage.removeItem('currentUser');
			localStorage.removeItem('passwordProtected');
			this.initServ._userInfo = null;
			this.initServ.isUserProfile.next(true);
		} catch { '' }
	}

	private getLogoutUrl(): string {
		let siteSettings = this.initServ.siteData?.theme_settings?.settings;
		let logoutLink = siteSettings?.menu_logout_link;
		let baseHost = `${window.location.protocol}//${IS_DEV ? DEV_HOST : window.location.hostname}`;
		return logoutLink ? this.utilServ.checkHttpExist(logoutLink) : `${baseHost}/`;
	}

	public logout(): void {
		this.apiServ.callApiWithPathVariables('GET', 'Logout', [this.utilServ.userId()]).pipe(takeUntil(this.destroy)).subscribe((resp: any) => {
			if (this.apiServ.checkAPIRes(resp)) {
				this.removeCurrentUser();
				window.location.href = this.getLogoutUrl();
			} else {
				let errorMessage = resp?.message || "Logout failed.";
				this.toastr.error(errorMessage, '', { timeOut: 10000 });
			}
		});
	}

	public redirectFromEmbed(): string {
		if (!this.utilServ.inIframe(this.utilServ.embedStatus) || this.initServ.theme) return '';

		let currentUser = this.utilServ.appLocalStorage('currentUser', true);
		let isCustomer = currentUser?.old_local_storage?.role === 'customer';

		return currentUser?.access_token && (!currentUser.old_local_storage || isCustomer) ? `/login/${currentUser.access_token}` : '';
	}
}
