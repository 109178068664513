/* eslint-disable complexity */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable max-params */
import { Component, OnInit, ViewEncapsulation, Self, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
// External lib
import { TranslateService} from '@ngx-translate/core';
// Services
import { NgOnDestroy, InitServ, UtilServ, LoaderServ, RenderComponentServ,SectionServ } from './Services';
// Constants
import { IS_DEV, DEV_HOST } from './Constants';
@Component({
	selector: 'bk-root',
	templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit{
	secretKey = "51174b0a5163bb67c2f9bdae097736a8a0043d0a43d37d64f13689b207c1f057";
	// Variables
	currentUser: any;
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	siteData: any = this.initServ.siteData; // App site settings
	currentPageSlug: string = '';
	_routeListener: any;
	_routeLoaderListener: any;
	headerFooterStatus: boolean = false;
	hideHeaderFooterEmbed: boolean = false;
	constructor(private actRoute: ActivatedRoute, public utilServ: UtilServ, public initServ: InitServ, private router: Router, @Self() private destroy: NgOnDestroy, private loader: LoaderServ, private rcServ: RenderComponentServ, private dialogRef: MatDialog, private translate: TranslateService,public secServ: SectionServ) {
		// Set the embed form status
		let urlParams = new URLSearchParams(window.location.search);
		let isEmbedForm = urlParams.get('embed');
		this.utilServ.embedStatus = (isEmbedForm && isEmbedForm == 'true') ? true : false;
		if(urlParams.get('admin_page') && urlParams.get('admin_page') == 'true'){
			this.utilServ.adminPage = true;
		}
		this.checkEmbed();
		// Set the transition
		this.initServ.setTranslation.pipe(takeUntil(this.destroy)).subscribe((status: boolean) => {
			if(status){
				// Set the transition language
				if(this.initServ.selectedLang && this.initServ.selectedLang?.code){
					this.translate.use(this.initServ.selectedLang?.code);
				} else {
					this.translate.use('en');
				}
			}
		})
		// App navigate
		let preview: any = window.location.search;
		this._routeListener = this.router.events.pipe(takeUntil(this.destroy)).subscribe((ev) => {
			if(ev instanceof NavigationStart) {
				if (ev.navigationTrigger === 'popstate') {
					window.location.reload();
				 }
				let pageUrl = ev.url.split("?");
				this.currentPageSlug = pageUrl[0].substring(1);
				// Admin status 8 redirect to login page
				if(this.admnStngs && this.admnStngs.status && this.admnStngs.status == 8 && (ev.url != '/'+this.initServ.appDynamicRoutes['login'] && ev.url != '/login')){
					this.router.navigate(['/'+this.initServ.appDynamicRoutes['login']]);
				}
				// Navigate with query params
				if(preview == '?theme='+this.initServ.theme && ev.url.indexOf('?') === -1){
					this.router.navigate([ev.url], { queryParams: { theme: this.initServ.theme } });
				}
			}
		});
		this.initServ.isLangSet.pipe(takeUntil(this.destroy)).subscribe((status: boolean) => {
			if(status){
				this.initServ.loadHeaderFooter().then(()=>{
					this.headerFooterStatus = true;
					// Set the globally logo data - Embed Form
					if(this.utilServ.inIframe(this.utilServ.embedStatus) && this.initServ.headerFooter && this.initServ.headerFooter.site_data){
						let sett:any = this.initServ.headerFooter.site_data.section_settings;
						if(this.initServ.headerFooter.site_data.sections && this.initServ.headerFooter.header_id){
							if(this.initServ.headerFooter.site_data.sections[this.initServ.headerFooter.header_id]){
								let logoId: string = this.initServ.headerFooter.site_data.sections[this.initServ.headerFooter.header_id]['logo'];
								if(sett && sett[logoId] && sett[logoId].elements && (Object.keys(sett[logoId].elements)).length > 0){
									let elements = sett[logoId].elements;
									this.secServ.setServData(sett, '');
									let obj = this.secServ.getElements(logoId, elements);
									this.utilServ.setSiteLogo = obj;
								}
							}
						}
					}
				});
			}
		});
	}
	ngOnInit(): any {
		// Current login user info from browser local storage
		let currentUser = this.utilServ.appLocalStorage();
		// Get the old local storage by embed, and set the value again
		if(currentUser && currentUser.old_local_storage){
			this.currentUser = currentUser.old_local_storage;
			try{
				localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
			}catch(err){ /* empty */ }
		} else {
			this.currentUser = currentUser;
		}
		// App redirection
		this.appRedirection();
		// Check the password protected
		let lastRoute = this.router.url.split('?')[0].split('/').pop();
		if(lastRoute != 'reauth-card'){
			this.pwdProtected();
		}
		// App class for iPhone
		if( /iPhone/i.test(navigator.userAgent) ) {
			try{
				document.querySelector('html')?.classList.add('iphone-only');
			}catch(err){ /* empty */ }
		}
		setTimeout(()=>{
			// Set the app load loader value to false after initialize app.
			this.loader.hideLoaderOnAppLoad = false;
			// Change the browse title after 3 sec
			this.browseTitleChange();
		},3000);
	}
	ngAfterViewInit() {
		this._routeLoaderListener = this.router.events.pipe(takeUntil(this.destroy)).subscribe((ev) => {
			if (ev instanceof NavigationStart) {
				this.loader.show()
				let popupData : any = this.rcServ.popupData;
				if(popupData && (Object.keys(popupData)).length > 0){
					this.dialogRef.closeAll();
				}
			}else if (ev instanceof NavigationEnd || ev instanceof NavigationCancel) {
				// Set the embed form status
				this.utilServ.embedStatus = (this.actRoute.snapshot.queryParamMap.get('embed') && this.actRoute.snapshot.queryParamMap.get('embed') == 'true') ? true : false;
				this.checkEmbed();
				let pagePath: any = ev.url.split("/");
				// For reschedule page never stop the loader
				let isBkngForm: boolean = false;
				if(pagePath && pagePath.length > 0){
					isBkngForm = (pagePath[1] && pagePath[1] == 'reschedule-booking') ? true : false;
					if(this.initServ.appDynamicRoutes['booknow'] == pagePath[1] || pagePath[1] == 'booknow'){
						isBkngForm = true
					}
				}
				if(!isBkngForm){
					this.loader.hide()
				}
				// Hide menu in responsive, on navigation change
				let ele: any = document.getElementById("navbarSupportedContent");
				if(ele && ele.classList.contains("show")){
					ele.classList.remove("show");
				}
				window.scrollTo({top: 0, behavior: 'smooth'});
			}
		});
	}
	/**
	 * App redirection based on user role
	 */
	private appRedirection(): void {
		// Theme & embed form case, app is not redirect
		let canRedirect: boolean = (this.initServ.theme || this.utilServ.embedStatus) ? false : true;
		if(this.currentUser && this.currentUser.role == 'customer'){
			// Store the current loggedIn user all information
			this.initServ.loggedInUser(this.currentUser.id);
		}
		// App redirection based on user role.
		if (this.currentUser && canRedirect && (this.currentUser.role != 'customer' && this.router.url != '/login')) {
			let appLink: string = '';
			switch (this.currentUser.role) {
				case 'provider': appLink = 'provider';
					break;
				case 'merchant': appLink = 'admin/dashboard';
					break;
				case 'staff': appLink = 'admin/dashboard';
					break;
			}
			window.location.href = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname) + '/' + appLink;
		}
	}
	/**
	 * App password protected
	 * Settings handle by theme builder
	 */
	private pwdProtected(): void {
		// Password protected local storage
		const pwdProtected: any = this.utilServ.appLocalStorage('passwordProtected');
		if (this.siteData && this.siteData.theme_settings && this.siteData.theme_settings.settings && this.siteData.theme_settings.settings.enable_password_protect) {
			if(this.currentUser){
				this.router.navigate(['/']);
			} else if(!pwdProtected){
				this.router.navigate(['/password-protected']);
			}
		}
		this.utilServ.removeStorage('passwordProtected');
	}
	/**
	 *  Function to check if page slug related to booknow.
	 */
	public isBooknow(slug: string): boolean{
		if(slug){
			let arr = slug.split("/");
			let booknowUrl: string = 'booknow';
			// eslint-disable-next-line no-prototype-builtins
			if(this.initServ.siteData && this.initServ.siteData.page_urls && (this.initServ.siteData.page_urls).hasOwnProperty('booknow') && this.initServ.siteData.page_urls['booknow']){
				booknowUrl = this.initServ.siteData.page_urls['booknow'];
			}
			if(arr[0] && (arr[0] == booknowUrl || arr[0] == 'booknow')){
				return true
			}
		}
		return false;
	}
	/**
	 * Change the browse title
	 */
	private browseTitleChange(): void {
		let styleElem = document.createElement('style');
		styleElem.type = 'text/css';
		styleElem.className += "custom-translation-style"
		let css = `.tjs-custom-file .form-check-label:after {
			content: "`+this.translate.instant('Browse')+`";
		}`;
		document.body.appendChild(styleElem);
		styleElem.appendChild(document.createTextNode(css));
	}
	/**
	 * Check the embed elements
	 */
	private checkEmbed(){
		// Embed status: true and theme not exist remove the current login user local storage
		if(this.utilServ.embedStatus){
			document.body.classList.add('tjs-app--embed');
			// if(!this.initServ.theme){
			// 	this.utilServ.removeStorage();
			// }
		}
		// Hide header & footer in case of embed
		if(this.utilServ.inIframe(this.utilServ.embedStatus)){
			this.hideHeaderFooterEmbed = true;
		}
	}

	ngOnDestroy() {
		this._routeListener.unsubscribe();
		this._routeLoaderListener.unsubscribe();
	}
}
