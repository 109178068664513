import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ } from '../../Services';

@Component({
	selector: 'bk-text-content',
	template: `
	<div [id]="secId" class="tjs-editor" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
		<div class="container" *ngIf="section?.title?.content || section?.desc?.content || (section?.media && section?.media?.urls && (section?.media?.urls).length > 0)">
			<div class="row">
				<div class="col-12 col-md-10 col-lg-9 mx-auto py-25 py-md-50" *ngIf="section?.title?.content || section?.desc?.content">
					<!-- Title -->
					<div class="mb-25" *ngIf="section?.title?.content">
						<h4 [id]="section?.title?.id" class="display-6 fw-bold" [innerHTML]="section?.title?.content | safeHtml"></h4>
					</div>
					<!-- Content -->
					<div *ngIf="section?.desc?.content">
						<p [id]="section?.desc?.id" [innerHTML]="section?.desc?.content | safeHtml"></p>
					</div>
				</div>
				<!-- Media -->
				<div *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0" class="col-12 mb-0 px-md-25">
					<img loading="auto" [id]="section?.media?.id" class="img-fluid" [src]="section?.media?.urls[0]" width="1200" height="577" alt="bk_images">
				</div>
			</div>
		</div>
	</div>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class TextContentComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		desc: null,
		media: null
	}
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ ) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
	}
}
