import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy, SimpleChanges,ChangeDetectorRef ,ViewChild,ElementRef} from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import KeenSlider from "keen-slider";
// Services
import { NgOnDestroy, UtilServ, InitServ, BkngFormServ } from '../../../../Services';

@Component({
	selector: 'bk-select-element-block',
	templateUrl: './SelectElementBlock.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class SelectElementBlockComponent implements OnInit {

	@ViewChild("extraSlide") sliderRef : ElementRef<HTMLElement> | undefined;
	@Input() controlName: string = '';
	@Input() data: any;
	@Input() isDisabled: any;
	@Input() bookingType: string = '';
	@Input() elementForm!: FormGroup;
	@Input() isSlider: boolean = false;
	@Input() isMultiStepForm: boolean = false;
	@Input() sectionIds: any;
	@Output() elementChange: EventEmitter<any> = new EventEmitter();
	selectedElements: any;
	formControlValue: any;
	isChange: boolean = false; // Changes by own
	slider: any = null;
	windowWidth :any = window.innerWidth;
	// Slider configuration
	public slideConfig = {
		breakpoints: {
			"(min-width: 451px)": {
				slides: { perView: 2 },
			},
			"(min-width: 768px)": {
				slides: { perView: 3 },
			},
		},
		slides: { perView: 1},
		rtl:(this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? true : false,
		defaultAnimation:{duration:1000},
	};

	constructor(public bkngFormServ: BkngFormServ, public utilServ: UtilServ, public initServ: InitServ, private frmBldr: FormBuilder, private cDRef: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.setSelectedFormElements();
		setTimeout(()=>{
			if(this.sliderRef && this.isSlider){
				if (this.slider) {this.slider.destroy();}
				this.slider = new KeenSlider(this.sliderRef.nativeElement,this.slideConfig);
			}
		})
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes && changes.data && !changes.data.firstChange && this.isChange){
			this.isChange = false;
			this.setSelectedFormElements();
		}
		if(this.slider && this.isSlider){
			this.slider.update(this.slideConfig)
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Child component and ngOnChanges is not detect the changes of parent component, then ngDoCheck works
	 * Value change by own(user selection), build function is not working
	 */
	ngDoCheck(): void {
		if(!this.isChange){
			let cur = JSON.stringify(this.elementForm.value[this.controlName]);
			let prev = JSON.stringify(this.formControlValue);
			if(cur != prev){
				this.setSelectedFormElements();
			}
		}
	}
	/**
	 * Set the selected form elements object
	 */
	private setSelectedFormElements(): void {
		this.formControlValue = this.elementForm.value[this.controlName];
		this.selectedElements = {};
		if(this.formControlValue && this.formControlValue.length > 0){
			(this.formControlValue).forEach((val: any, index: any) => {
				let obj: any = {
					index:index,
					quantity: val.quantity
				}
				this.selectedElements[val.id] = obj;
			});
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Select the element
	 * @param event : event value
	 * @param ele : selected element
	 */
	public selectElement(event: any, ele: any): void {
		this.isChange = true;
		let controlFormArray: any = <FormArray>this.elementForm.controls[this.controlName];
		if(event.target.checked){
			let control: any = {
				id: [ele.id],
				name: [ele.name],
				quantity: [1],
				recurring: [],
				apply_on_bookings: [ele.apply_to_bookings]
			};
			if(this.controlName == 'partial_cleaning'){
				control['is_multiple'] = ele.enable_quantity_based;
				// Note: difference of variable name for extra and exclude, thats need to add this line
				control['apply_on_bookings'] = ele.apply_on_bookings;
			}
			controlFormArray.push(this.frmBldr.group(control));
			// Selected parameter popup
			this.bkngFormServ.formParamMsgPopup(ele);
		} else{
			(controlFormArray.value).forEach((value: { id: any; }, i: any) => {
				if(value.id === ele.id){
					controlFormArray.removeAt(i);
				}
			});
		}
		this.setSelectedFormElements();
		this.elementChange.emit();
	}
	/**
	 * Element count increment & decrement
	 * @param ele: selected element
	 * @param index: selected element form index
	 * @param type: increment / decrement
	 */
	public countChange(ele: any, index: any, type: string): void {
		this.isChange = true;
		let eleFormControl: any = (<FormGroup>(<FormArray>this.elementForm?.controls[this.controlName])?.controls[index])?.controls['quantity'];
		if(this.selectedElements[ele.id]){
			let eleValue: any = this.selectedElements[ele.id];
			let quantity: number = +(eleValue.quantity);
			if(type == 'decrement'){
				if(+(eleValue.quantity) > 0){
					quantity = +(eleValue.quantity) - 1;
				}
			} else {
				if(+(eleValue.quantity) < ele.quantity_based){
					quantity = +(eleValue.quantity) + 1;
				}
			}
			// if quantity is zero then uncheck the selected element
			if(quantity == 0){
				setTimeout(() => {
					let elem: any = document.getElementById(this.controlName+'-'+ele?.id);
					if(elem){
						elem.click();
					}
				}, 50)
			}else if(quantity > 0 && (quantity <= ele.quantity_based )){
				// quantity is greater than 0 and quantity less than and equal to `quantity_based` field
				this.selectedElements[ele.id]['quantity'] = quantity;
				eleFormControl.setValue(quantity);
				this.elementChange.emit();
			}
		}
	}

	ngOnDestroy():void {
		if (this.slider){this.slider.destroy()}
	}

}