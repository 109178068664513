import { Component, OnInit, ViewEncapsulation,ViewChild,ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import KeenSlider from "keen-slider";
// Services
import { NgOnDestroy, InitServ, UtilServ } from '../../../../Services';
@Component({
	selector: 'bk-packages-popup',
	templateUrl: './PackagesPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class PackagesPopupComponent implements OnInit {

	@ViewChild("packagesSlide") sliderRef : ElementRef<HTMLElement> | undefined;
	// Variables
	data: any;
	slider: any = null;
	enableArrow: boolean = false;
	public slideConfig:any = {
		breakpoints: {
			"(min-width: 991px)": {
				slides: { perView: 2},
			},
			"(min-width: 1201px)": {
				slides: { perView: 3},
			},
			"(min-width: 1441px)": {
				slides: { perView: 4},
			},
		},
		slides: { perView: 1},
		rtl:(this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? true : false,
		defaultAnimation:{duration:1000},
	};

	constructor(public initServ: InitServ, public utilServ: UtilServ, public dialogRef: MatDialogRef<PackagesPopupComponent>) { 
		
	}

	ngOnInit(): void {
		if(this.data && this.data.packages && this.data.packages.length > 0) {
			if(((window.innerWidth >= 1441) && (this.data.packages.length > 4)) || ((window.innerWidth >= 1201) && (window.innerWidth <= 1440) && (this.data.packages.length > 3)) || ((window.innerWidth >= 991) && (window.innerWidth <= 1200) && (this.data.packages.length > 2)) || ((window.innerWidth <= 990) && (this.data.packages.length > 1))) {
				this.enableArrow = true;
			}
		}
	}
	/**
	 * Package select and close the popup
	 * @param selectedPackage: selected package
	 */
	public packageSelect(selectedPackage: any): void {
		let data: any = {
			type: (this.data && this.data.selectedItem && this.data.selectedItem.id) ? 'item' : 'package',
			selectedPackage: selectedPackage
		}
		this.dialogRef.close(data);
	}

	ngAfterViewInit():void {
		setTimeout(()=>{
			if(this.sliderRef){
				if (this.slider) {this.slider.destroy();}
				this.slider = new KeenSlider(this.sliderRef.nativeElement,this.slideConfig)
			}
		})
	}

	ngOnDestroy():void {
		if (this.slider) {this.slider.destroy();}
	}
}