<div class="position-relative">
	<div [ngClass]="isMultiStepForm ? 'rounded-5 mb-20 bk-custom-card-bg' : 'py-30 px-md-30 border-bottom bk-card-border'" [id]="section?.id" *ngIf="tipVisible || parkingVisible || (prefilledData && (prefilledData?.tip.total_amount || prefilledData?.parking.total_amount) && (this.prefilledData.status == 1 || this.prefilledData.status == 2))">
		<!-- Title and description -->
		<bk-section-heading [section]="section" [pageSett]="pageSett"></bk-section-heading>
		<ng-container *ngIf="['add', 'draft'].includes(bookingType); else reschedule">
			<ng-container *ngTemplateOutlet="tipParkingTemp"></ng-container>
		</ng-container>
		<ng-template #reschedule>
			<div *ngIf="prefilledData && (prefilledData.status != 1 && prefilledData.status != 2 && prefilledData.status != 4 && isCustomerAllowedRes); else bkngStatus">
				<!-- Tip -->
				<div *ngIf="tipVisible" [id]="section?.tip_id">
					<div class="shadow-sm card tjs-bg-gray w-50 mt-25" *ngIf="prefilledData?.is_tip_charged; else tipTemp">
						<ng-container *ngTemplateOutlet="tipChargedTemp"></ng-container>
					</div>
				</div>
				<!-- Parking -->
				<ng-container *ngTemplateOutlet="parkingTemp"></ng-container>
			</div>
			<ng-template #bkngStatus>
				<!-- Tip -->
				<div *ngIf="tipVisible" [id]="section?.tip_id">
					<!-- Tip charged -->
					<div class="shadow-sm card tjs-bg-gray w-50 mt-25" *ngIf="prefilledData?.is_tip_charged; else tipValueTemp">
						<ng-container *ngTemplateOutlet="tipChargedTemp"></ng-container>
					</div>
					<ng-template #tipValueTemp>
						<!-- Prefilled tip -->
						<div class="shadow-sm card tjs-bg-gray w-50 mt-25" *ngIf="(selectedServiceType && selectedServiceType.enable_tip && (selectedServiceType.tip.locations == 'both' || selectedServiceType.tip.locations == tipParkingForm.controls['location_type'].value)) || !selectedServiceType || (tipGroup.controls['total_amount'].value)">
							<div class="card-body">
								<label class="form-label bk-form-label" *ngIf="section?.tip" [id]="section?.tip_id" [innerHTML]="section?.tip | safeHtml"></label>
								<div class="input-group">
									<div class="input-group-text">
										<span bk-currency *ngIf="tipGroup.controls['amount_type'].value == 'amount'"></span>
										<span *ngIf="tipGroup.controls['amount_type'].value == 'percentage'">%</span>
									</div>
									<input type="text" class="form-control" value="{{tipGroup.controls['total_amount'].value | number : '1.2-2'}}" [attr.disabled]="isDisabled" readonly>
								</div>
							</div>
						</div>
					</ng-template>
				</div>
				<!-- Prefilled parking -->
				<div *ngIf="parkingVisible" [id]="section?.parking_id">
					<div class="shadow-sm card tjs-bg-gray w-50 mt-25" *ngIf="(selectedServiceType && selectedServiceType.enable_parking && (selectedServiceType.parking.locations == 'both' || selectedServiceType.parking.locations == tipParkingForm.controls['location_type'].value)) || !selectedServiceType || (parkingGroup.controls['total_amount'].value)">
						<div class="card-body">
							<label class="form-label bk-form-label" *ngIf="section?.parking" [id]="section?.parking_id" [innerHTML]="section?.parking | safeHtml"></label>
							<div class="input-group">
								<div class="input-group-text"><span bk-currency></span></div>
								<input type="text" class="form-control" value="{{parkingGroup.controls['total_amount'].value | number : '1.2-2'}}" [attr.disabled]="isDisabled" readonly>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</ng-template>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
<!-- Tip parking template -->
<ng-template #tipParkingTemp>
	<ng-container *ngTemplateOutlet="tipTemp"></ng-container>
	<ng-container *ngTemplateOutlet="parkingTemp"></ng-container>
</ng-template>
<!-- Tip template-->
<ng-template #tipTemp>
	<div [formGroup]="tipGroup" *ngIf="tipVisible" [id]="section?.tip_id">
		<div [ngClass]="{'pb-30': parkingVisible && !isMultiStepForm, 'pb-15': parkingVisible && isMultiStepForm}">
			<label class="form-label bk-form-label" *ngIf="section?.tip" [id]="section?.tip_id" [innerHTML]="section?.tip | safeHtml"></label>
			<div class="tjs-check-btn flex-wrap d-flex">
				<ng-container *ngIf="tipValues && tipValues.length > 0">
					<div class="form-check form-check-inline my-10" *ngFor="let tip of tipValues;trackBy: utilServ.trackByFnIndex">
						<input id="tip_{{tip}}" class="form-check-input bk-form-check-input" type="radio" value="{{tip}}" name="tip_type" (change)="calTipAmount(tip, 'percentage')" [attr.checked]="(tipGroup.value['amount_type'] == 'percentage' && tip == +(tipGroup.value['total_amount'])) ? 'checked' : null" >
						<label for="tip_{{tip}}" class="tjs-radio__label custom-control-label form-check-label btn btn-light fw-normal bk-form-check-btn">{{tip}}%</label>
					</div>
				</ng-container>
				<!-- Other -->
				<div class="form-check form-check-inline my-10" [id]="section?.other_btn?.id">
					<input #otherTip id="other_tip" class="form-check-input bk-form-check-input" type="radio" value="other" name="tip_type" (change)="calTipAmount('other', 'percentage')" [attr.checked]="(prefilledOther('other')) ? 'checked' : null">
					<label for="other_tip" class="tjs-radio__label custom-control-label form-check-label btn btn-light fw-normal bk-form-check-btn" translate>{{section?.other_btn?.content}}</label>
				</div>
				<!-- Other input -->
				<div class="d-inline-flex my-10 align-items-center" *ngIf="otherTip.checked">
					<div class="d-flex">
						<bk-amount-type-dropdown custClass="form-select-sm" class="me-20" [type]="tipGroup.controls['amount_type'].value" (selectType)="tipGroup.controls['amount_type'].setValue($event); calTipAmount(tipGroup.controls['total_amount'].value, 'nochange')"></bk-amount-type-dropdown>
						<div class="me-20">
							<input class="form-control form-control-sm amount-width tjs-w-100" type="text" formControlName="total_amount" (keypress)="utilServ.onlyNumbers($event)" (focusout)="tipFocusout()">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<!-- Parking template -->
<ng-template #parkingTemp>
	<div [formGroup]="parkingGroup" *ngIf="parkingVisible" [id]="section?.parking_id">
		<div [ngClass]="{'border-top pt-30 bk-card-border': tipVisible && !isMultiStepForm}">
			<label class="form-label bk-form-label" *ngIf="section?.parking" [id]="section?.parking_id" [innerHTML]="section?.parking | safeHtml"></label>
			<div class="tjs-check-btn flex-wrap d-flex">
				<ng-container *ngIf="parkingValues && parkingValues.length > 0">
					<div class="dir-ltr form-check form-check-inline my-10" *ngFor="let parking of parkingValues;trackBy: utilServ.trackByFnIndex">
						<input id="parking_{{parking}}" class="form-check-input bk-form-check-input" type="radio" value="{{parking}}" name="total_amount" (change)="calParkingAmount(parking)" [attr.checked]="(parking == parkingGroup.value['total_amount']) ? 'checked' : null">
						<label for="parking_{{parking}}" class="tjs-radio__label custom-control-label form-check-label btn btn-light fw-normal bk-form-check-btn">
							<span bk-currency class="vr-1"></span>
							<span>{{parking}}</span>
						</label>
					</div>
				</ng-container>
				<!-- Other -->
				<div class="dir-ltr form-check form-check-inline my-10" [id]="section?.other_btn?.id">
					<input #otherparking id="other_parking" class="form-check-input bk-form-check-input" type="radio" value="other" name="total_amount" (change)="calParkingAmount(0)" [attr.checked]="(prefilledOther('other', 'parking')) ? 'checked' : null">
					<label for="other_parking" class="tjs-radio__label custom-control-label form-check-label btn btn-light fw-normal bk-form-check-btn" translate>{{section?.other_btn?.content}}</label>
				</div>
				<!-- Other input -->
				<div class="d-inline-flex my-10 align-items-center" *ngIf="otherparking.checked">
					<div class="me-15">
						<div class="input-group input-group-sm">
							<span class="input-group-text" bk-currency></span>
							<input class="form-control form-control-sm w-100" type="text" formControlName="total_amount" (keypress)="utilServ.onlyNumbers($event)" (focusout)="parkingFocusout()">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>
<!-- Prefilled tip template -->
<ng-template #tipChargedTemp>
	<div class="card-body" *ngIf="prefilledData?.payment_log && (prefilledData?.payment_log).length > 0">
		<label class="form-label bk-form-label" *ngIf="section?.tip" [id]="section?.tip_id" [innerHTML]="section?.tip | safeHtml"></label>
		<ng-container *ngFor="let tip of prefilledData?.payment_log">
			<div class="input-group" *ngIf="tip?.payment_type == 'tip'">
				<span bk-currency class="input-group-text"></span>
				<input type="text" class="form-control" value="{{tip?.charged_amount | number : '1.2-2'}}" [attr.disabled]="isDisabled" readonly>
			</div>
		</ng-container>
	</div>
</ng-template>