/* eslint-disable complexity */
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation, SimpleChanges, Self, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { distinctUntilChanged, takeUntil} from 'rxjs/operators';
// Services
import { BkngFormServ, LoaderServ, NgOnDestroy, UtilServ, SectionServ } from '../../../Services';

@Component({
	selector: 'bk-tip-parking',
	templateUrl: './TipParking.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TipParkingComponent implements OnInit {
	// Variables
	@Input() bookingType: string = '';
	@Input() tipParkingForm!: FormGroup;
	@Input() selectedServiceType: any;
	@Input() section: any;
	@Input() locationType: any;
	@Input() prefilledData: any;
	@Input() isMultiStepForm: any;
	@Input() pageSett: any;
	@Input() isCustomerAllowedRes: any;

	tipVisible: boolean = false;
	parkingVisible: boolean = false;
	tipParking: any = {
		tip:[0,10,15,20],
		parking: [0,5,10,20]
	}
	tipValues:any;
	parkingValues: any;
	isDisabled: any;
	loaderId: string = 'bkng-tip-parking';

	constructor(private bkngFormServ: BkngFormServ, public utilServ: UtilServ, private frmBldr: FormBuilder, private loader: LoaderServ, @Self() private destroy: NgOnDestroy, private secServ: SectionServ, private cDRef: ChangeDetectorRef) { }

	ngOnInit(): void {
		this.buildSection(true);
	}

	/**
	 * on changes only with if dependencies are change like location, service, frequency
	 * @param changes : auto detect
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange) {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev){
						this.buildSection();
						break;
					}
				}
			}
		}
	}
	// convenience getter for easy access to form fields
	get tipGroup(): any{
		return <FormGroup>this.tipParkingForm.controls['tip'];
	}
	get parkingGroup(): any{
		return <FormGroup>this.tipParkingForm.controls['parking'];
	}
	get bonusGroup(): any{
		return <FormGroup>this.tipParkingForm.controls['bonus'];
	}
	/**
	 * BUild the tip & parking section
	 */
	private buildSection(flag : boolean = false): void {
		this.tipValues = [];
		this.parkingValues = [];
		setTimeout(() => {
			this.tipValues = this.tipParking?.tip;
			this.parkingValues = this.tipParking?.parking;
			this.cDRef.detectChanges();
		}, 50);
		this.loader.show(this.loaderId);
		this.tipVisible = false;
		this.parkingVisible = false;
		this.isDisabled = this.bkngFormServ.disabledParamScope(this.bookingType, this.prefilledData, null, null, false, '', this.isCustomerAllowedRes);
		// Tip Visibility
		if(this.bkngFormServ.tipParkingVisible(this.selectedServiceType, this.locationType, 'tip')){
			if(this.prefilledData && this.prefilledData.tip && this.prefilledData.tip.total_amount){
				this.tipVisible = true;
			} else {
				if(this.section && this.secServ.checkEleStatus(this.pageSett,this.section.id) && this.secServ.checkEleStatus(this.pageSett,this.section.tip_id)){
					this.tipVisible = true;
				}
			}
		}
		// Parking Visibility
		if(this.bkngFormServ.tipParkingVisible(this.selectedServiceType, this.locationType, 'parking')){
			if(this.prefilledData && this.prefilledData.parking && this.prefilledData.parking.total_amount){
				this.parkingVisible = true;
			} else {
				if(this.section && this.secServ.checkEleStatus(this.pageSett,this.section.id) && this.secServ.checkEleStatus(this.pageSett,this.section.parking_id)){
					this.parkingVisible = true;
				}
			}
		}
		// Provider change
		this.providerChange();
		// Tip
		let tipTotalAmount = this.tipGroup.controls['total_amount'].value;
		let tipAmountType = this.tipGroup.controls['amount_type'].value;
		if(!tipAmountType){
			this.tipGroup.controls['amount_type'].setValue("percentage", {emitEvent:!flag});
			tipAmountType = "percentage";
		}
		if(tipTotalAmount){
			this.calTipAmount(tipTotalAmount, tipAmountType, flag);
		}
		// Parking
		let parkingTotalAmount = this.parkingGroup.controls['total_amount'].value;
		if(parkingTotalAmount){
			this.calParkingAmount(parkingTotalAmount, flag);
		}
		//Bonus
		let bonusAmount = this.bonusGroup.controls['total_amount'].value;
		if(bonusAmount){
			// Calculate provider amount for parking section
			this.calProviderAmount('bonus', flag);
		}
		// Service fee
		if(this.tipParkingForm.controls['service_fee'].value){
			this.calProviderAmount('service_fee', flag);
		}
		// Reschedule booking
		if(this.bookingType == 'reschedule' && this.prefilledData){
			if(tipTotalAmount){
				this.prefilledValues('tip', flag);
			}
			if(parkingTotalAmount){
				this.prefilledValues('parking', flag);
			}
			if(bonusAmount){
				this.prefilledValues('bonus', flag);
			}
			if(this.tipParkingForm.controls['service_fee'].value){
				this.prefilledValuesServiceFee(flag);
			}
		}
		this.cDRef.detectChanges();
		this.loader.hide(this.loaderId);
	}
	/**
	 * Provider change re-calculation for each provider
	 */
	private providerChange(): void {
		let providerFormArray: any = <FormArray> this.tipParkingForm.controls['provider_details'];
		providerFormArray.valueChanges.pipe(distinctUntilChanged(),takeUntil(this.destroy)).subscribe(() => {
			let tipTotalAmount = this.tipGroup.controls['total_amount'].value;
			let tipAmountType = this.tipGroup.controls['amount_type'].value;
			let parkingTotalAmount = this.parkingGroup.controls['total_amount'].value;
			let bonusAmount = this.bonusGroup.controls['total_amount'].value;
			if(this.tipParkingForm.controls['provider_type'].value == 'same_provider'){
				// Tip
				if(this.prefilledData && this.prefilledData.tip.total_amount && this.prefilledData.tip.total_amount == tipTotalAmount){
					this.prefilledValues('tip');
				} else{
					this.calTipAmount(tipTotalAmount, tipAmountType);
				}
				// Parking
				if(this.prefilledData && this.prefilledData.parking.total_amount && this.prefilledData.parking.total_amount == parkingTotalAmount){
					this.prefilledValues('parking');
				} else{
					this.calParkingAmount(parkingTotalAmount);
				}
				// Bonus
				if(this.prefilledData && this.prefilledData.bonus.total_amount && this.prefilledData.bonus.total_amount == bonusAmount){
					this.prefilledValues('bonus');
				} else{
					this.calProviderAmount('bonus');
				}
				// Service fee
				if(this.prefilledData && this.prefilledData.service_fee && this.prefilledData.service_fee == this.tipParkingForm.controls['service_fee'].value){
					this.prefilledValuesServiceFee();
				} else{
					this.calProviderAmount('service_fee');
				}
			} else{
				this.calTipAmount(tipTotalAmount, tipAmountType);
				this.calParkingAmount(parkingTotalAmount);
				this.calProviderAmount('bonus');
				this.calProviderAmount('service_fee');
			}
			this.cDRef.detectChanges();
		});
	}
	/**
	 * Calculate the tip amount for each providers(member)
	 * @param value: 0,10,15,20,other
	 * @param type: percentage/nochange
	 */
	public calTipAmount(value: any, type: string, flag : boolean = false): void {
		if(value != 'other'){
			this.tipGroup.controls['total_amount'].setValue(value, {emitEvent : !flag});
		}
		if(type != 'nochange'){
			this.tipGroup.controls['amount_type'].setValue(type, {emitEvent : !flag});
		}
		// Calculate provider amount for tip section
		this.calProviderAmount('tip', flag);
	}
	/**
	 * Calculate the parking amount for each providers(member)
	 * @param value: 0,5,10,20,other
	 */
	public calParkingAmount(value: any, flag: boolean = false): void {
		if(value != 'other'){
			this.parkingGroup.controls['total_amount'].setValue(+value, {emitEvent : !flag});
		}
		// Calculate provider amount for parking section
		this.calProviderAmount('parking', flag);
	}
	/**
	 * Prefilled the value of other type of tip/parking
	 * @param value
	 * @returns
	 */
	public prefilledOther(value: any, type: string = 'tip'): any{
		if(value == 'other'){
			let tipParkingGroup = type == 'tip' ? this.tipGroup : this.parkingGroup;
			let totalValue = tipParkingGroup.controls['total_amount'].value;
			let amountStatus = type == 'tip' ? (tipParkingGroup.controls['amount_type'].value == 'percentage') : true;
			if((totalValue == 0 || totalValue == 10 || totalValue == 15 || totalValue == 20) && amountStatus){
				return null;
			} else{
				return true;
			}
		}
	}
	/**
	 * Calculate provider amount based on type
	 * @param type: tip/parking
	 */
	private calProviderAmount(type: string = 'tip', flag: boolean = false): void {
		let formValues: any = this.tipParkingForm.value;
		let members: any = null;
		if(formValues['provider_details'] && formValues['provider_details'].length > 0){
			if(formValues['provider_details'].length == 1){
				// Team
				if(formValues['provider_details'][0].provider_type == 'team' && formValues['provider_details'][0].members){
					members = formValues['provider_details'][0].members;
				} else {
					// Single
					members = [{ id: formValues['provider_details'][0].id }];
				}
			} else if(formValues['provider_details'].length > 1){
				// Pair
				members = formValues['provider_details'];
			}
		}
		if(members && members.length > 0){
			if(type == 'service_fee'){
				this.calServiceFeeAmount(members, flag);
			} else{
				this.setEachMemberAmount(members, type, flag);
			}
		}
	}
	/**
	 * Set the each providers(member) amount
	 * @param members: members array
	 */
	private setEachMemberAmount(members: any, type: string = 'tip', flag: boolean = false): void {
		let formGroup: any;
		switch(type){
			case 'parking':
				formGroup = this.parkingGroup;
			break;
			case 'bonus':
				formGroup = this.bonusGroup;
			break;
			default:
				formGroup = this.tipGroup;
			break
		}
		let amount: number = 0;
		if(formGroup.controls['total_amount'].value){
			amount = (+formGroup.controls['total_amount'].value)/(members.length);
		}
		formGroup.removeControl('each_member_amount', {emitEvent: !flag});
		formGroup.addControl('each_member_amount',this.frmBldr.array([]), {emitEvent: !flag});
		let eachMemberArray: any = <FormArray>formGroup.controls['each_member_amount'];
		if(members && members.length > 0){
			for(let member of members){
				eachMemberArray.push(this.frmBldr.group({id: member.id, amount: amount}), {emitEvent: !flag});
			}
		}
	}
	/**
	 * Calculate the service fees
	 * @param members: members
	 */
	private calServiceFeeAmount(members: any, flag: boolean = false): void {
		if(this.tipParkingForm.controls['service_fee'].value){
			let serviceFee: any = +(this.tipParkingForm.controls['service_fee'].value)/(members.length);
			this.tipParkingForm.removeControl('provider_service_fee', {emitEvent: !flag});
			this.tipParkingForm.addControl('provider_service_fee',this.frmBldr.array([]), {emitEvent: !flag});
			let serviceFeeArray: any = <FormArray>(this.tipParkingForm.controls['provider_service_fee']);
			if(members && members.length > 0){
				for(let member of members){
					serviceFeeArray.push(this.frmBldr.group({id: member.id, amount: serviceFee}), {emitEvent: !flag});
				}
			}
		}
	}

	// Prefilled functions
	/**
	 * Prefilled tip/parking/bonus amount form each member
	 * @param type tip/parking/bonus
	 */
	private prefilledValues(type: string, flag : boolean = false): void {
		let formGroup: any;
		switch(type){
			case 'parking':
				formGroup = this.parkingGroup;
			break;
			case 'bonus':
				formGroup = this.bonusGroup;
			break;
			default:
				formGroup = this.tipGroup;
			break
		}
		let providers = this.prefilledData.provider_wages;
		if(providers && providers.length > 0){
			formGroup.removeControl('each_member_amount', {emitEvent:!flag});
			formGroup.addControl('each_member_amount',this.frmBldr.array([]), {emitEvent:!flag});
			let eachMemberArray: any = <FormArray>formGroup.controls['each_member_amount'];
			for(let provider of providers){
				eachMemberArray.push(this.frmBldr.group({id: provider.provider_id, amount: provider[type]}), {emitEvent:!flag});
			}
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Prefilled service fee for each member
	 */
	private prefilledValuesServiceFee(flag : boolean = false): void {
		let providers = this.prefilledData.provider_wages;
		if(providers && providers.length > 0){
			this.tipParkingForm.removeControl('provider_service_fee', {emitEvent:false});
			this.tipParkingForm.addControl('provider_service_fee',this.frmBldr.array([]), {emitEvent:!flag});
			let serviceFeeArray = <FormArray>(this.tipParkingForm.controls['provider_service_fee']);
			for(let provider of providers){
				serviceFeeArray.push(this.frmBldr.group({id: provider.provider_id, amount: provider.service_fee}), {emitEvent:!flag});
			}
		}
	}
	tipFocusout(): void {
		this.tipGroup.controls['total_amount'].setValue(this.utilServ.roundToTwo(this.tipGroup.controls['total_amount'].value));
		this.calTipAmount(this.tipGroup.controls['total_amount'].value, 'nochange');
	}
	parkingFocusout(): void {
		this.parkingGroup.controls['total_amount'].setValue(this.utilServ.roundToTwo(this.parkingGroup.controls['total_amount'].value));
		this.calParkingAmount(this.parkingGroup.controls['total_amount'].value);
	}
}
