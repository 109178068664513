import { Component, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../Services';
// Inheritance components
import { CustomerEmailComponent } from '../../../Global/BookingForm';

@Component({
	selector: 'bk-short-frm-customer-email',
	templateUrl: './ShortFrmCustomerEmail.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy],
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShortFrmCustomerEmailComponent extends CustomerEmailComponent {
	@Input() variationId: string = '';
	@Input() sectionIds: any;
}