<div [id]="secId" class="tjs-opening-hour py-35 py-md-80" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div class="container">
		<div class="row mx-5">
			<!-- Media -->
			<div *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0" class="col-md-6 col-lg-6 col-xl-6 tjs-bg-style mb-30 mb-md-0 position-relative" [ngStyle]="{'background-image' : 'url('+section?.media?.urls[0] + ')'}">
				<div [id]="section?.media?.id" class="tjs-opacity--bg" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0"></div>
			</div>
			<div class="col-md-6 col-lg-6 col-xl-6 py-25 py-md-50 d-flex justify-content-center align-items-center" [ngClass]="{'col-md-12 col-lg-12 col-xl-12' : !(section?.media && section?.media?.urls && (section?.media?.urls).length > 0)}">
				<div class="text-center">
					<!-- Title -->
					<div class="mb-40" *ngIf="section?.title?.content">
						<h4 [id]="section?.title?.id" class="display-6 fw-bold" [innerHTML]="section?.title?.content | safeHtml"></h4>
					</div>
					<!-- Sub title -->
					<h6 [id]="section?.sub_title?.id" class="fs-20 fw-bold mb-30" *ngIf="section?.sub_title?.content" [innerHTML]="section?.sub_title?.content | safeHtml" ></h6>
					<!-- Description -->
					<div class="tjs-section__description" *ngIf="section?.desc?.content">
						<p [id]="section?.desc?.id" [innerHTML]="section?.desc?.content | safeHtml"></p>
					</div>
					<!-- Button -->
					<div [id]="section?.button?.id" class="mt-25" *ngIf="section?.button && section?.button?.buttons && (section?.button?.buttons).length > 0">
						<div class="tjs-navigate-wrapper justify-content-center flex-column">
							<ng-container *ngFor="let btn of section?.button?.buttons;trackBy: secServ.trackByFnIndex">
								<bk-navigate [secSett]="btn" [innerHTML]="btn?.content"></bk-navigate>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>