import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// External lib
import { TranslateModule } from '@ngx-translate/core';
// Module
import { GlobalDefaultModule, UserComponentsModule } from '../Global'; // Global module
// Popup components
import { IndustriesPopupComponent, ConfirmPopupComponent, MessagePopupComponent, OtherContactsPopupComponent, RatingPopupComponent, ShareReviewPopupComponent, BlankTempPopupComponent, LeadsFormPopupComponent } from './index';

@NgModule({
	declarations: [IndustriesPopupComponent, ConfirmPopupComponent, MessagePopupComponent, OtherContactsPopupComponent, RatingPopupComponent, ShareReviewPopupComponent, BlankTempPopupComponent, LeadsFormPopupComponent],
	imports: [
		CommonModule,TranslateModule,RouterModule,MatDialogModule,FormsModule, ReactiveFormsModule,
		GlobalDefaultModule, UserComponentsModule
	],
	exports: [IndustriesPopupComponent, ConfirmPopupComponent, MessagePopupComponent, OtherContactsPopupComponent, RatingPopupComponent, ShareReviewPopupComponent, BlankTempPopupComponent, LeadsFormPopupComponent],
	entryComponents: [IndustriesPopupComponent, ConfirmPopupComponent, MessagePopupComponent, OtherContactsPopupComponent, RatingPopupComponent, ShareReviewPopupComponent, BlankTempPopupComponent, LeadsFormPopupComponent]
})
export class PopupsModule { }
