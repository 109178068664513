<div [id]="secId" class="tjs-popup tjs-popup--md position-relative" [ngClass]="{'min-height--100': !(contacts && contacts?.length > 0)}">
	<!-- Popup Header -->
	<bk-popup-header [headingId]="section?.title?.id" [heading]="section?.title?.content"></bk-popup-header>
	<!-- Popup Body -->
	<div [id]="section?.listing?.id" class="tjs-popup__content--height tjs-scrollbar tjs-overflow-scroll">
		<ng-container *ngIf="contacts && contacts?.length > 0; else noData">
			<!-- Table -->
			<table class="bk-table table tjs-table tjs-table--hover tjs-table--responsive tjs-table--border__x">
				<thead>
					<tr>
						<th class="w-30">{{section?.listing?.name}}</th>
						<th class="w-25">{{section?.listing?.email}}</th>
						<th class="w-25">{{section?.listing?.phone}}</th>
						<th class="w-auto text-end">{{section?.listing?.action}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let contact of contacts;trackBy: utilServ.trackByFnIndex">
						<!-- Name -->
						<td [attr.data-label]="section?.listing?.name">
							<div class="td-text--color text-break">
								<span class="text-capitalize fw-bold fs-14">{{contact?.first_name}} {{contact?.last_name}}</span>
								<bk-tooltip *ngIf="contact?.note && utilServ.noteAvailable(contact?.note)" icon="tjsicon-file" customClass="tooltip--color" class="bk-tooltip" content="{{contact?.note}}"></bk-tooltip>
							</div>
						</td>
						<!-- Emails -->
						<td [attr.data-label]="section?.listing?.email">
							<div class="text-break">
								<div *ngFor="let email of contact?.emails; let lastIndex = last; trackBy: utilServ.trackByFnIndex" [ngClass]="{'mb-5':!lastIndex}">
									<a href="mailto:{{email?.value}}" class="d-inline-flex fs-14 dir-ltr text-body td-text--color"><i class="tjsicon-email me-5"></i> {{email?.value}} </a>
								</div>
							</div>
						</td>
						<!-- Phone numbers -->
						<td [attr.data-label]="section?.listing?.phone">
							<div>
								<ng-container *ngFor="let phone of contact?.phone_numbers; let lastIndex = last;trackBy: utilServ.trackByFnIndex">
									<div *ngIf="phone?.value  else emptyBlock" [ngClass]="{'mb-5':!lastIndex}">
										<bk-phone-number [phone]="phone?.value" customClass="fs-14 text-body dir-ltr d-inline-block text-nowrap text-body td-text--color"></bk-phone-number>
									</div>
								</ng-container>
							</div>
							<ng-template #emptyBlock>
								<span>--</span>
							</ng-template>
						</td>
						<!-- Actions -->
						<td [attr.data-label]="section?.listing?.action">
							<div class="d-flex align-items-center justify-content-end gap-10 flex-wrap">
								<button type="button" class="bg-transparent border-0 btn-link fs-14 p-0 text-body d-flex align-items-center action-btn__edit" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" (click)="editContact(contact?.uid, contact?.id)" translate><span class="tjsicon-pencil me-5"></span>Edit</button>
								<button type="button" class="bg-transparent border-0 btn-link fs-14 p-0 text-body d-flex align-items-center action-btn__delete" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" (click)="confirmPopup(contact?.uid, contact?.id)" translate><span class="tjsicon-delete me-5"></span>Delete</button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</ng-container>
		<!-- Empty data -->
		<ng-template #noData>
			<div class="px-md-30 py-md-20 p-15 bk-bg--color">
				<h5 class="text--color">{{initServ?.appStr?.errorMsg?.noOtherContacts | translate}}</h5>
			</div>
		</ng-template>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
