<div [id]="data?.id" *ngIf="data && (isFB || isGoogle)" class="bk-group position-relative">
	<!-- Title -->
	<span [id]="data?.title_id" class="d-block mb-20 bk-gp-title text-center text-md-start" *ngIf="data?.title"  [innerHTML]="data?.title | safeHtml"></span>
	<!-- Facebook -->
	<button *ngIf="isFB" type="button" class="btn tjs-facebook-btn d-flex justify-content-center align-items-center w-100 bk-gp-btn" (click)="signInWithFB()">
		<img loading="auto" [src]="initServ?.img?.FBIcon" alt="Facebook" width="21" height="21" class="me-10 img-fluid" /> <span translate>{{(openFrom && openFrom == 'signup') ? 'Sign up with Facebook' : 'Sign in with Facebook'}}</span>
	</button>
	<!-- Google -->
	<ng-container *ngIf="isGoogle">
		<div id="googleBtn" class="w-100" [class.mt-15]="isFB"></div>
		<input id="userData" type="hidden" value="" disabled readonly>
		<button id="createUserBtn" type="button" class="d-none" (click)="googleLoginAsUser()"></button>
	</ng-container>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
