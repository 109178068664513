import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// External lib
import { TranslateModule } from '@ngx-translate/core';
// Module
import { GlobalDefaultModule } from '../GlobalDefault/GlobalDefault.module'; // Global module
// Components
import { LiveReviewComponent, FaqListComponent } from './index';

@NgModule({
	declarations: [LiveReviewComponent, FaqListComponent],
	imports: [CommonModule, RouterModule, TranslateModule, GlobalDefaultModule],
	exports: [LiveReviewComponent, FaqListComponent]
})
export class FormWidgetsModule { }
