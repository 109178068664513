<div  [id]="secId" class="referral-wrap tjs-bg-style position-relative tjs-section--space-lg" [ngStyle]="{'background-image' : (section?.media && section?.media?.urls && (section?.media?.urls).length > 0 ) && 'url('+section?.media?.urls[0] + ')'}" *ngIf="pagePerm && rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div  [id]="section?.media?.id" class="tjs-opacity--bg" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0"></div>
	<div class="container">
		<div class="row">
			<div class="col-12 col-lg-11 mx-auto text-center banner__content">
				<div>
					<!-- Title -->
					<h1 [id]="section?.title?.id" class="fw-light display-1 bk-title section-title" *ngIf="section?.title && section?.title?.content" [innerHTML]="section?.title?.content | safeHtml"></h1>
					<!-- Sub title -->
					<p [id]="section?.sub_title?.id" class="mb-30 bk-sub-title section-sub-title" *ngIf="section?.sub_title && section?.sub_title?.content" [innerHTML]="tokenReplace(section?.sub_title?.content) | safeHtml"></p>
				</div>
				<!-- Social buttons -->
				<div [id]="section?.invite_using?.id" class="mb-30 bk-group" *ngIf="section?.invite_using">
					<!-- Title -->
					<p [id]="section?.invite_using?.title_id" class="bk-gp-title section-gp-title" *ngIf="section?.invite_using?.title" [innerHTML]="section?.invite_using?.title | safeHtml"></p>
					<!-- Gmail -->
					<bk-navigate *ngIf="section?.invite_using?.gmail_button" [secId]="section?.invite_using?.gmail_button_id" [designId]="section?.invite_using?.gmail_button?.design_id" customClass="btn p-15" (callback)="socialInvite('gmail')" essentialClass="text-white gmail-color mx-10 bk-gp-btn" innerHTML="<span class='me-5'>{{section?.invite_using?.gmail_button?.content}}</span> <i class='fa fa-envelope' aria-hidden='true'></i>"></bk-navigate>
					<!-- Yahoo -->
					<bk-navigate *ngIf="section?.invite_using?.yahoo_button" [secId]="section?.invite_using?.yahoo_button_id" [designId]="section?.invite_using?.yahoo_button?.design_id" customClass="btn p-15" essentialClass="text-white yahoo-color mx-10 bk-gp-btn" (callback)="socialInvite('yahoo')" innerHTML="<span class='me-5'>{{section?.invite_using?.yahoo_button?.content}}</span> <i class='fa fa-yahoo' aria-hidden='true'></i>"></bk-navigate>
				</div>
				<!-- Invitation -->
				<div class="row text-start">
					<div class="col-12 col-sm-12 col-md-10 col-xl-6 mb-lg-0 mx-auto position-relative" *ngIf="section?.invite_by && section?.invite_by?.select">
						<!-- Form -->
						<form bkDebounceEvent [isSubmit]="true" [id]="section?.invite_by?.select?.id" class="row gx-10 mb-30 mb-xl-0 referral-form bk-form" [formGroup]="referralForm" (debounceSubmit)="submit()" *ngIf="section?.invite_by?.select?.email || section?.invite_by?.select?.phone">
							<div class="col-12 col-sm-10 pe-xl-0">
								<div class="input-group input-group-ltr" [ngClass]="{'flex-nowrap': initServ?.callingCode}">
									<div class="m-w-120">
										<select [ngStyle]="{'min-width': (initServ?.callingCode ? '120px' : '')}" class="form-select bk-select" formControlName="type" (change)="emptyInput()">
											<option value="email" class="bk-select-option" *ngIf="section?.invite_by?.select?.email?.label" [innerHTML]="section?.invite_by?.select?.email?.label | safeHtml"></option>
											<option value="phone" class="bk-select-option" *ngIf="section?.invite_by?.select?.phone?.label" [innerHTML]="section?.invite_by?.select?.phone?.label | safeHtml"></option>
										</select>
									</div>
									<!-- Email -->
									<input [id]="section?.invite_by?.select?.email_id" *ngIf="f['type'].value == 'email' && section?.invite_by?.select?.email" class="form-control bk-input" type="email" [placeholder]="section?.invite_by?.select?.email?.placeholder" formControlName="referred_email" [ngClass]="{'is-invalid': f['referred_email']?.touched && f['referred_email']?.errors?.required}" (focusout)="detailsFoucusOut()">
									<!-- Phone -->
									<ng-container *ngIf="f['type'].value == 'phone' && section?.invite_by?.select?.phone">
										<div [id]="section?.invite_by?.select?.phone_id" class="input-group input-group-ltr" *ngIf="initServ?.callingCode; else elsePhone">
											<span class="input-group-text">{{initServ?.callingCode}}</span>
											<input class="form-control bk-input" type="text" [placeholder]="section?.invite_by?.select?.phone?.placeholder" formControlName="referred_phone" (keyup)="phoneValidate()" [ngClass]="{'is-invalid': f['referred_phone']?.touched && f['referred_phone']?.errors?.required}" (focusout)="detailsFoucusOut()">
										</div>
										<ng-template #elsePhone>
											<input class="form-control bk-input" type="text" [placeholder]="section?.invite_by?.select?.phone?.placeholder" formControlName="referred_phone" (keyup)="phoneValidate()" [ngClass]="{'is-invalid': f['referred_phone']?.touched && f['referred_phone']?.errors?.required}" (focusout)="detailsFoucusOut()">
										</ng-template>
									</ng-container>
								</div>
							</div>
							<!-- Button -->
							<div class="col-12 col-sm-2 mt-15 mt-sm-0 ps-xl-10 pe-xl-5" *ngIf="section?.invite_by?.button">
								<bk-navigate [secId]="section?.invite_by?.button_id" [designId]="section?.invite_by?.button?.design_id" [isSubmit]="true" [innerHTML]="section?.invite_by?.button?.content" customClass="btn btn-success p-sm-0 border-transparent" essentialClass="h-100 w-100 bk-form-btn"></bk-navigate>
							</div>
							<!-- Error -->
							<div class="tjs-error" *ngIf="(f['type'].value == 'email' && section?.invite_by?.select?.email && f['referred_email']?.touched && f['referred_email']?.errors?.required) || (f['type'].value == 'phone' && section?.invite_by?.select?.phone && f['referred_phone']?.touched && f['referred_phone']?.errors?.required)">
								<i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.empty | translate}}
							</div>
							<!-- Phone error -->
							<div class="tjs-error d-block m-top" *ngIf="f['type'].value == 'phone' && f['referred_phone'].value && phoneError == 1" translate><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.validPhone}}</div>
							<!-- Desc -->
							<div [id]="section?.invite_by?.desc_id" class="text-start text-referral bk-form-desc" *ngIf="section?.invite_by?.desc" [innerHTML]="section?.invite_by?.desc | safeHtml"></div>
						</form>
						<!-- Loader -->
						<bk-app-loader [loaderId]="sendLoaderId"></bk-app-loader>
					</div>
					<div class="col-12 col-sm-12 col-md-10 col-xl-6 banner__fields--btn social-input-wrap mb-lg-0 mx-auto">
						<div class="row">
							<div class="col-12 col-sm-9 pe-sm-0 mb-15 mb-sm-0">
								<div class="input-group">
									<input type="text" id="shareLink" class="form-control bg-white social-code" placeholder="bookingkoala.com/earncredit/invitation/page.php" aria-label="Invite link" [value]="referralUrl" readonly="">
								</div>
							</div>
							<div class="col-12 col-sm-3 ps-sm-0 d-flex">
									<button type="button" class="flex-grow-1 btn h-100 py-sm-15 px-sm-15 border-top border-end border-bottom bg-white rounded-0"(click)="socialServ.postOnFacebook(fbAppId, referralUrl)">
										<i class="fa fa-facebook-f fa-lg facebook-color"></i>
									</button>
									<button type="button" class="flex-grow-1 btn h-100 p-sm-10 border-top border-end border-bottom bg-white rounded-0" (click)="socialServ.tweetOnTwitter(referralUrl, '', defReferralAmt, overrideDesc)">
										<img [src]="initServ.imgBase+'/assets/images/twitter-x-white_trans.png'" alt="twitter-x-white-transparent logo" width="20" height="20">
									</button>
									<button type="button" class="flex-grow-1 btn h-100 p-sm-10 border-top border-end border-bottom bg-white rounded-0" (click)="utilServ.copyToClipboard('shareLink')">
										<i class="fa fa-link fa-lg text-success"></i>
									</button>
							</div>
						</div>
						<div [id]="section?.share_by?.id" class="text-start text-referral bk-form-desc" *ngIf="section?.share_by?.content" [innerHTML]="section?.share_by?.content | safeHtml"></div>
					</div>
				</div>
				<!-- Cookies -->
				<div class="tjs-privacy-text mt-10 mb-20 text-referral" *ngIf="section?.cookie_content">
					<span class="bk-cookie fs-14" [id]="section?.cookie_content?.id" [innerHTML]="section?.cookie_content?.content | safeHtml"></span>
				</div>
			</div>
		</div>
	</div>
</div>
