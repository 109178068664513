import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ} from '../../Services';

@Component({
	selector: 'bk-html-code',
	template: `
	<div [id]="secId" class="bkt-section custom-html-section" *ngIf="rcServ && pageSett && pageSett[secId] && pageSett[secId][rcServ.statusType] && section && section?.code && section?.code?.content">
		<div [id]="section?.code?.id" [innerHTML]="section?.code?.content | safeHtml"></div>
	</div>
	`,
	encapsulation: ViewEncapsulation.None,
	providers:[NgOnDestroy]
})
export class HtmlCodeComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		code: null
	}
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
	}
}