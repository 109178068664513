import { Directive, ElementRef, HostListener, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[BKNumeric]'
})
export class NumericDirective {
	@Input() onlyNumber: boolean = false;
	@Input() onlyInputNumber: boolean = false;

	constructor(private el: ElementRef, @Optional() @Self() private ngControl: NgControl) { }

	private check(inputValue: string): string {
		if (this.onlyNumber) {
			return this.numberInput(inputValue);
		} else {
			return this.decimalInput(inputValue);
		}
	}

	private numberInput(inputValue: string): string {
		// Use a regular expression to check if the input is a valid number
		if (/^[0-9]*$/.test(inputValue)) {
			return inputValue;
		} else {
			// Input is not a valid number, remove non-numeric characters
			return inputValue.replace(/[^0-9]/g, '');
		}
	}

	private decimalInput(inputValue: string): string {
		// Replace any non-numeric and non-dot characters with an empty string
		let cleanValue: any = inputValue.replace(/[^0-9.]/g, '');
		// Split the value by the dot (decimal point)
		let parts = cleanValue.split('.');
		if (parts.length > 2) {
			// Remove all dots except the first one
			return parts[0] + '.' + parts.slice(1).join('');
		} else if (parts[1] && parts[1].length > 2) {
			// There are at most two decimal places, and the second part has more than 2 digits
			parts[1] = parts[1].substr(0, 2);
		}
		return parts.join('.');
	}

	private run() {
		let currentValue: string = this.el.nativeElement.value;
		if(currentValue){
			let val: any = this.check(currentValue);
			val = val ? val : 0;
			this.el.nativeElement.value = val;
			this.setControl(val);
		}
	}

	private setControl(val: any): void {
		if(this.ngControl?.control){
			this.ngControl.control.setValue(val, {emitEvent: false});
		}
	}

	@HostListener('input', ['$event'])
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onInputChange(_event: Event) {
		this.run();
	}

	@HostListener("paste", ["$event"])
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onPaste(_event: ClipboardEvent) {
		this.run();
	}

	@HostListener("blur", ["$event"])
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onBlur(_event: ClipboardEvent) {
		let currentValue: any = this.el.nativeElement.value;
		currentValue = currentValue ? currentValue : 0;
		let val: any = this.onlyInputNumber ? currentValue : +(parseFloat(currentValue).toFixed(2));
		this.el.nativeElement.value = val;
		this.setControl(val);
	}
}
