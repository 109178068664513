import { Component, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
// Services
import { NgOnDestroy, ApiServ, UtilServ, InitServ, LoaderServ, CacheService } from '../../../../../Services';
// External lib
import { ToastrService } from 'ngx-toastr';
// Constant
import { TEXT_REG_EXP } from '../../../../../Constants';
@Component({
	selector: 'bk-address-card-popup',
	templateUrl: './AddressCardPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class AddressCardPopupComponent implements OnInit {
	addressObj: any;
	loaderId: string = 'addr-form-loader';
	addressForm: FormGroup;
	public options: any = {
		types: ['address'],
		fields: ["address_components", "geometry"]
	}
	isZipcode: boolean = false;
	editableAddr: any;
	// Section fields
	slug: string = 'address_card';
	secId: string = '';
	section: any = { title: null, form: null, save_btn: null, cancel_btn: null, update_btn: null };

	// eslint-disable-next-line max-params
	constructor(public dialogRef: MatDialogRef<AddressCardPopupComponent>, public utilServ: UtilServ, @Self() public destroy: NgOnDestroy, public initServ: InitServ, private apiServ: ApiServ, private loader: LoaderServ, private toastr: ToastrService, private cacheServ: CacheService) {
		this.addressForm = this.buildAddressFormGrp();
	}

	ngOnInit(): void {
		if (this.addressObj != null) {
			this.patchFormValue(this.addressObj);
		}
		// build popup section
		this.buildSectionData();
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
		});
	}
	/**
	 * Convenience getter for easy access to form fields
	 */
	get f(): { [key: string]: AbstractControl } {
		return this.addressForm.controls;
	}
	/**
	 * Submit method to add/ update the user address's
	 */
	public submitForm(): void {
		if (this.addressForm.valid) {
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId, this.dialogRef);
			this.isZipcode = false;
			let sendData: any = this.addressForm.value;
			sendData.uid = +this.utilServ.userId();
			if (this.editableAddr) {
				sendData.id = +this.editableAddr.id;
				this.apiServ.callApiWithPathVariables('PUT', 'UpdateUserAddr', [this.utilServ.userId(), this.editableAddr.id], sendData).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res));
			} else {
				this.apiServ.callApiWithPathVariables('POST', 'SaveUserAddress', [this.utilServ.userId()], sendData).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res));
			}
		} else {
			this.isZipcode = true;
			this.addressForm.markAllAsTouched();
		}
	}
	/**
	 * On result callback method, on successful response it close the popup with value = true.
	 * @param res API res
	 * API response handler
	 */
	private onResultCallback(res: any): void {
		if (this.apiServ.checkAPIRes(res)) {
			// this.resetForm();
			this.toastr.success(res?.message);
			this.dialogRef.close(true);
		} else if (res?.message) {
			this.toastr.error(res?.message);
		}
		// backdrop enabled when loader is hide.
		this.loader.hide(this.loaderId, this.dialogRef);
	}
	/**
	 * Method to reset the form values and close the popup.
	 */
	public resetForm(): void {
		this.addressForm.reset();
		this.addressForm.markAsUntouched();
		this.editableAddr = null;
		// Reset the lat & lng
		this.setLatlng();
		this.dialogRef.close(false);
	}
	/**
	 * Set the address: zipcode/city/state
	 * @param addr
	 * @param type
	 */
	public setAddress(addr: any, type: string): void {
		let code: any;
		switch (type) {
			case 'zipcode':
				// Set the lat & lng
				this.setLatlng(addr);
				this.createShortAddr(addr);
				code = this.utilServ.getComponentByType(addr, 'postal_code');
				break;
			case 'city':
				code = this.utilServ.getComponentByType(addr, 'locality');
				break;
			case 'state':
				code = this.utilServ.getComponentByType(addr, 'administrative_area_level_1');
				break;
		}
		let val: string = (code?.long_name) ? code.long_name : '';
		this.f[type].setValue(val);
	}
	/**
	 * Get the address by 'Address' lib
	 * @param place
	 */
	public getAddress(place: any): void {
		let addr: any = place?.target?.value;
		// Compare old addr with new addr and set the lat & lng
		if (addr != this.f.address.value) this.setLatlng();
		this.f.address.setValue(place.target.value);
	}
	/**
	 * Set the latitude and longitude
	 * @param address
	 */
	private setLatlng(address: any = null): void {
		let latitude = (address) ? address?.geometry?.location?.lat() : 0;
		let longitude = (address) ? address?.geometry?.location?.lng() : 0;
		(this.addressForm.get('latlng') as FormGroup).patchValue({
			lat: Number(latitude.toFixed(6)),
			lng: Number(longitude.toFixed(6))
		});
	}
	/**
	 * Create short address
	 * @param addr
	 */
	private createShortAddr(addr: any): void {
		let shortAddr: any = this.utilServ.createShortAddr(addr);
		this.f.short_address.setValue(shortAddr);
	}
	/**
	 * Method to build the Address FormGroup
	 * @returns FormGroup
	 */
	private buildAddressFormGrp(): FormGroup {
		return new FormGroup({
			'address': new FormControl(null, Validators.required),
			'zipcode': new FormControl(null, [Validators.required, Validators.pattern(TEXT_REG_EXP)]),
			'city': new FormControl(null),
			'state': new FormControl(null),
			'apt': new FormControl(null, Validators.pattern(TEXT_REG_EXP)),
			'default': new FormControl(false),
			'short_address': new FormControl(null),
			'latlng': new FormGroup({
				'lat': new FormControl(0),
				'lng': new FormControl(0),
			})
		})
	}
	/**
	 * Method to patch the Address values into FormGroup
	 * @param addr address data
	 */
	private patchFormValue(addr: any): void {
		this.editableAddr = addr;
		if (addr) {
			this.addressForm.patchValue({
				'address': addr?.address,
				'zipcode': addr?.zipcode,
				'city': addr?.city,
				'state': addr?.state,
				'apt': addr?.apt,
				'default': addr?.default,
				'short_address': addr?.short_address,
			});
			if (addr?.latlng) {
				(this.addressForm.get('latlng') as FormGroup).patchValue({
					'lat': (addr?.latlng?.lat) ? addr?.latlng?.lat : 0,
					'lng': (addr?.latlng?.lng) ? addr?.latlng?.lng : 0,
				});
			}
		}
	}
}
