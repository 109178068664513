import { Component, OnDestroy, OnInit, Self, ViewChild, ViewEncapsulation, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
// External package
import { ToastrService } from "ngx-toastr";
// Child component
import { PaymentGatewayComponent } from "../../Global/GlobalDefault";
//Services
import { ApiServ, InitServ, LoaderServ, NgOnDestroy, PaymentGatewayServ, UtilServ, SectionServ, RenderComponentServ } from "../../Services";
@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 're-auth-payment',
	templateUrl: './ReAuthPayment.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})

export class ReAuthPaymentComponent implements OnInit, OnDestroy {
	// Variables
	@Input() secId: string = '';
	token: any; // get token from the url and send in request to get payment log (client_secret and location_id);
	tokenError: any; // to show token error message in alert if token is expired
	paymentLog: any;
	lastRoute: any;
	authWithNewCard: boolean = false; // variable used to toggle confirm card UI.
	cvvLoader: string = 'cvv-loader';
	admnStngs: any;
	paypalData: any;
	cvvText: string = 'CVV';
	private currentUser: any;

	// Section fields
	pageSett: any;
	section: any = {
		add_authorize_btn: null,
		authorize_btn: null,
		cancel_btn: null,
		new_card_desc: null,
		new_card_link_text: null,
		new_card_title: null,
		old_card_desc: null,
		old_card_title: null,
		redirect_link: null,
		square_old_card_desc: null,
		square_old_card_title: null,
		address: null
	}
	cardSc: string = '{{.cardLast4}}';
	cvvSc: string = '{{.cvvText}}';
	newCardSC: string = '{{.newCardText}}';
	title: any = '';
	desc: any = '';
	newCardForm!: FormGroup;

	@ViewChild(PaymentGatewayComponent) paymentGatewayChild: PaymentGatewayComponent | undefined; //Payment gateway component

	// eslint-disable-next-line max-params
	constructor(public initServ: InitServ, public utilServ: UtilServ, private actRoute: ActivatedRoute, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, private toastr: ToastrService, private loader: LoaderServ, private router: Router, private paymentServ: PaymentGatewayServ, public secServ: SectionServ, public rcServ: RenderComponentServ, private frmBldr: FormBuilder) {
		// Current login user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
		this.admnStngs = this.initServ.appAdmnStngs; // Admin settings
		// Load the script
		if (this.initServ?.paymentGateway) {
			this.utilServ?.loadPaymentGatewayScript(this.initServ.paymentGateway);
		}

	}

	ngOnInit(): void {
		this.newCardForm = this.frmBldr.group({});
		if(this.initServ.paymentGateway == 'stripe'){
			this.cvvText = 'CVC';
		}
		this.token = this.actRoute.snapshot.queryParamMap.get('token');
		this.lastRoute = this.actRoute.snapshot.queryParamMap.get('type');
		if (this.token) {
			this.getPaymentLog();
		}
		// Build section data
		if(this.secId && this.rcServ.pageData){
			this.pageSett = this.rcServ.pageData.section_settings;
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
		// TODO: Anupam, remove this code after 2months goes to live. Add Bugsang inside
		if(!this.section?.address){
			this.section.address = this.utilServ.loadDefaultElemJson('address');
		}
	}
	/**
	 * Change the card layout
	 * @param type: new/old
	 */
	public changeCardLayout(type: string = 'old'): void {
		if (type == 'old') {
			this.authWithNewCard = false;
			this.loader.show(this.cvvLoader);
			// If the payment form contains a 'billing_address' control, remove it for existing card cases.
			if(this.newCardForm.controls['billing_address']){
				this.newCardForm.removeControl('billing_address');
			}
			setTimeout(async () => {
				switch(this.initServ.paymentGateway){
					case 'stripe':
						this.paymentServ?.stripeCvvForm(this.paymentLog?.location);
					break;
					case 'paypal':
						this.paypalData = await this.paymentServ.braintreeCvvForm(this.paymentLog.location);
					break;
				}
				this.loader.hide(this.cvvLoader);
			}, 400);
		} else {
			this.authWithNewCard = true;
			if(this.initServ.paymentGateway == 'stripe'){
				this.paymentServ?.destroyStripeCardCvc();
			}
		}
		this.updateCustomizeText();
	}
	/**
	 * Get payment log to confirm payment (contains client_secret and location).
	 */
	private async getPaymentLog(buildForm: boolean = true): Promise<void> {
		let header: any = await this.initServ.getAuthHeader();
		this.apiServ.callApiWithPathQueryVars('GET', 'PayIntClientSecret', [this.token], { type: this.lastRoute }, null, header).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.payIntCallback(res, buildForm));
	}
	/**
	 * 3DS authentication for payment (hold/charge)
	 */
	public reAuthCard(): void {
		// This will authenticate charge
		if (this.utilServ.isValExist(this.paymentLog?.client_secret) && this.newCardForm.valid) {
			switch(this.initServ.paymentGateway){
				case 'stripe':
					this.stripePayment();
				break;
				case 'square':
					this.squareUpPayment();
				break;
				case 'paypal':
					this.paypalPayment();
				break;
			}
		} else {
			if(this.authWithNewCard && this.paymentGatewayChild){
				this.paymentGatewayChild.isZipcode = true;
				this.paymentGatewayChild.refresh();
			}
		}
	}
	/**
	 * Payment from stripe gateway
	 */
	private async stripePayment(): Promise<void> {
		this.loader.show();
		let paymentMethod: any;
		if (this.authWithNewCard) {
			paymentMethod = await this.stripeNewCard();
		} else {
			if(await this.checkPaymentCache()){
				paymentMethod = await this.paymentServ?.confirmStripeCardWithCvv(this.paymentLog);
			}
		}
		if (paymentMethod) {
			let payload: any = {
				id: paymentMethod?.client_secret,
				card_id: paymentMethod?.payment_method,
				status: paymentMethod?.status,
				amount: paymentMethod?.amount
			}
			if(paymentMethod?.last4){
				payload['card_last4_digits']=paymentMethod?.last4
			}
			this.sendPaymentDetails(payload);
		} else {
			this.loader.hide();
		}
	}

	/**
	 * Handles the process of adding a new card using the Stripe payment gateway.
	 * @returns A Promise that resolves to the newly created payment method, if successful; otherwise, resolves to null.
	 */
	private async stripeNewCard(): Promise<any> {
		let paymentMethod: any = null;
		if(this.newCardForm.valid){
			if(await this.checkPaymentCache()){
				let extraData = { stripe: this.paymentGatewayChild?.stripe, billing_address: this.newCardForm.controls['billing_address'] && this.newCardForm.controls['billing_address'].value, booking_ids: this.paymentLog?.booking_ids };
				paymentMethod = await this.paymentServ?.confirmStripePayment(this.paymentLog?.client_secret, extraData);
			}
		} else {
			this.handleFormErrors();
		}
		return paymentMethod;
	}

	private async checkPaymentCache(): Promise<boolean> {
		let status: boolean = true;
		if(this.utilServ.checkArrLength(this.paymentLog?.booking_ids)){
			status = await this.paymentServ?.checkPaymentCache(this.paymentLog?.booking_ids, this.token);
		}
		return status;
	}

	/**
	 * Payment from square up
	 */
	private async squareUpPayment(): Promise<void> {
		this.loader.show();
		let chargeData: any;
		let data: any = {
			base_location_id: this.paymentLog.location,
			location: this.paymentLog.location,
			uid: this.paymentLog?.uid,
			amount: this.paymentLog.amount,
			re_auth_token: this.token,
			pay_type: this.lastRoute,
			payment: true,
			billing_address: this.newCardForm.controls['billing_address'] && this.newCardForm.controls['billing_address'].value
		}
		if (this.authWithNewCard) {
			chargeData = await this.paymentServ.squareVerifyCard(data);
		} else {
			data['pay_with_cc']=this.paymentLog?.card_id
			chargeData = await this.paymentServ.squarePaymentVerify(this.paymentLog?.card_id, data);
		}
		if(chargeData && !chargeData?.rebuild){
			let payload: any = {
				card_id: (chargeData?.card_id) ? (chargeData?.card_id) : this.paymentLog?.card_id,
				charge_id: chargeData?.charge_id,
				amount: this.paymentLog?.amount,
				location: this.paymentLog.location,
				status: chargeData?.status
			}
			if(chargeData?.last4){
				payload['card_last4_digits']=chargeData?.last4
			}
			this.sendPaymentDetails(payload);
		} else {
			this.loader.hide();
			if(chargeData && chargeData?.rebuild){
				this.getPaymentLog(false);
			}
		}
	}
	/**
	 * Payment from paypal gateway
	 */
	private async paypalPayment(): Promise<void> {
		this.loader.show();
		let chargeData: any;
		if (this.authWithNewCard) {
			let data: any = {
				base_location_id: this.paymentLog.location,
				location: this.paymentLog.location,
				uid: this.paymentLog?.uid,
				amount: this.paymentLog.amount,
				re_auth_token: this.token,
				pay_type: this.lastRoute,
				billing_address: this.newCardForm.controls['billing_address'] && this.newCardForm.controls['billing_address'].value
			}
			chargeData = await this.paymentGatewayChild?.paypalNewCardPayment(this.paypalData?.clientInstance, data);
		} else {
			let extraData: any = {
				re_auth_token: this.token,
				pay_type: this.lastRoute
			}
			chargeData = await this.paymentServ.paypalPayWithCvv(this.paypalData, this.paymentLog, extraData);
		}
		if(chargeData && !chargeData?.rebuild){
			let payload: any = {
				card_id: (chargeData?.card_id) ? (chargeData?.card_id) : this.paymentLog?.card_id,
				charge_id: chargeData?.charge_id,
				amount: this.paymentLog?.amount,
				location: this.paymentLog.location,
				status: chargeData?.status,
				device_data: chargeData?.device_data
			}
			if(chargeData?.last4){
				payload['card_last4_digits']=chargeData?.last4
			}
			this.sendPaymentDetails(payload);
		} else {
			this.loader.hide();
			if(chargeData && chargeData?.rebuild){
				this.getPaymentLog(false);
			}
		}
	}
	/**
	 * Send confirmed payment details
	 */
	private async sendPaymentDetails(payload: any): Promise<void> {
		let header: any = await this.initServ.getAuthHeader();
		header['Ip']=this.initServ.ipAddress;
		// Card billing address
		if(this.newCardForm.controls['billing_address']){
			payload['billing_address'] = this.newCardForm.controls['billing_address'].value
		}
		this.apiServ.callApiWithPathQueryVars('POST', 'ConfirmedPayDetails', [this.token], { type: this.lastRoute }, payload, header).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.confirmPayCallback(res));
		// If TTL is not working hit the api
		if(this.initServ.paymentGateway === 'stripe' && this.utilServ.checkArrLength(this.paymentLog?.booking_ids)){
			this.paymentServ.deletePaymentCache(this.paymentLog);
		}
	}

	// Api callback functions

	/**
	 * Function to handle confirm pay detail api response
	 * @param res: api res
	 */
	private confirmPayCallback(res: any): void {
		if (this.apiServ.checkAPIRes(res) && res?.message) {
			this.toastr.success(res.message);
			setTimeout(() => {
				this.redirectUser();
			}, 2000);
		} else {
			if (res && res?.message) {
				this.toastr.error(res.message);
			}
		}
		this.loader.hide();
	}
	/**
	 * Function used to redirect user.
	 */
	private redirectUser(){
		let redirectUrl: any = this.section?.redirect_link?.link_url;
		let gotoLinkUrl: any = null;
		switch (this.section?.redirect_link?.link_to) {
			case "link":
			case "web":
				// eslint-disable-next-line no-case-declarations
				let linkUrl: any = this.utilServ.checkHttpExist(redirectUrl);
				this.utilServ.redirect(linkUrl);
				break;
			case "page":
				gotoLinkUrl = this.utilServ.getAfterSuccessRedirection(redirectUrl);
				if(gotoLinkUrl){
					this.utilServ.redirect(this.utilServ.generateLink(gotoLinkUrl));
				}
				break;
			default:
				if(this.currentUser){
					this.router.navigate(['/'+this.initServ.appDynamicRoutes['dashboard']]);
				} else {
					this.router.navigate(['/'+this.initServ.appDynamicRoutes['login']]);
				}
				break;
		}
	}
	/**
	 * Function to handle payment intent clientSecret api response
	 */
	private payIntCallback(res: any, buildForm: boolean = true): void {
		this.tokenError = null;
		if (this.apiServ.checkAPIRes(res) && res?.data) {
			this.paymentLog = res.data;
			if(buildForm){
				this.changeCardLayout();
			}
		} else {
			if (res && res?.message) {
				this.tokenError = res.message;
			}
		}
		this.updateCustomizeText();
	}
	/**
	 * Update the customization text
	 */
	private updateCustomizeText(): void {
		if(this.authWithNewCard){
			this.title = this.section?.new_card_title?.content;
			this.desc = this.utilServ.generateDynamicStr(this.section?.new_card_desc?.content, this.cvvSc, this.cvvText);
		}else{
			if(this.initServ.paymentGateway == 'square'){
				this.title = this.utilServ.generateDynamicStr(this.section?.square_old_card_title?.content, this.cardSc, 'xxxx-'+this.paymentLog?.last4);
				this.desc = this.utilServ.generateDynamicStr(this.section?.square_old_card_desc?.content, this.newCardSC, this.getNewCardLink());
			}else{
				this.title = this.utilServ.generateDynamicStr(this.utilServ.generateDynamicStr(this.section?.old_card_title?.content, this.cvvSc, this.cvvText), this.cardSc, 'xxxx-'+this.paymentLog?.last4);
				this.desc = this.utilServ.generateDynamicStr(this.utilServ.generateDynamicStr(this.section?.old_card_desc?.content, this.newCardSC, this.getNewCardLink()), this.cvvSc, this.cvvText);
			}
		}
		this.addClickEventForNewCard();
	}
	/**
	 * Get the new card link
	 * @returns html
	 */
	private getNewCardLink(): any {
		return `<span id="reauth-new-card-link" class="tjs-pointer btn-link">`+this.section?.new_card_link_text?.content+`</span>`;
	}
	/**
	 * Add click event
	 */
	public addClickEventForNewCard(): void {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let self: any = this;
		setTimeout(()=>{
			let elem: any = document.getElementById('reauth-new-card-link');
			if(elem){
				elem.addEventListener("click", function() {
					self.changeCardLayout('new');
				});
			}
		}, 1000);
	}

	/**
	 * Handles form validation error
	 */
	private handleFormErrors(): void {
		if(this.paymentGatewayChild){
			this.paymentGatewayChild.isZipcode = true;
		}
		this.newCardForm.markAllAsTouched();
		// Refresh the payment gateway child component if available.
		if(this.paymentGatewayChild){
			this.paymentGatewayChild.refresh();
		}
	}

	// to destroy card cvc when component destroy
	ngOnDestroy(): void {
		if (this.initServ?.paymentGateway == 'Stripe') {
			this.paymentServ?.destroyStripeCardCvc();
		}
	}
}
