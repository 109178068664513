<div class="tjs-popup tjs-popup--lg position-relative" [id]="secId">
	<!-- Header -->
	<bk-popup-header [heading]="(contactId) ? section?.update_title?.content : section?.title?.content" [headingId]="section?.title?.id" *ngIf="section?.title"></bk-popup-header>
	<!-- Form -->
	<form bkDebounceEvent [isSubmit]="true" [formGroup]="contactForm" (debounceSubmit)="submitForm()" [id]="section?.form?.id">
		<div class="tjs-popup__content tjs-popup__body">
			<div class="row">
				<div class="col-12 col-md-6">
					<!-- First name -->
					<div class="mb-25" [ngClass]="{'min-height--100': !(section?.form?.first_name?.label) }">
						<label class="form-label bk-form-label d-block" [id]="section?.form?.first_name?.label_id" *ngIf="section?.form?.first_name?.label" [innerHTML]="section?.form?.first_name?.label | safeHtml"></label>
						<input class="form-control" [ngClass]="{'is-invalid': f['first_name']?.touched && f['first_name']?.errors}" [attr.placeholder]="section?.form?.first_name?.placeholder" formControlName="first_name">
						<!-- Error -->
						<div class="tjs-error" *ngIf="f['first_name']?.touched && (f['first_name']?.errors?.required || f['first_name']?.errors?.pattern)">
							<i class="tjsicon-attention"></i>
							<ng-container *ngIf="f['first_name']?.errors?.required">{{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
							<ng-container *ngIf="f['first_name']?.errors?.pattern">{{initServ?.appStr?.errorMsg?.specialChars | translate}} </ng-container>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<!-- Last name -->
					<div class="mb-25" [ngClass]="{'min-height--100': !(section?.form?.last_name?.label) }">
						<label class="form-label bk-form-label d-block"  [id]="section?.form?.last_name?.label_id" *ngIf="section?.form?.last_name?.label" [innerHTML]="section?.form?.last_name?.label | safeHtml"></label>
						<input class="form-control" [ngClass]="{'is-invalid': f['last_name']?.touched && f['last_name']?.errors}" [attr.placeholder]="section?.form?.last_name?.placeholder" formControlName="last_name">
						<!-- Error -->
						<div class="tjs-error" *ngIf="f['last_name']?.touched && (f['last_name']?.errors?.required || f['last_name']?.errors?.pattern)">
							<i class="tjsicon-attention"></i>
							<ng-container *ngIf="f['last_name']?.errors?.required">{{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
							<ng-container *ngIf="f['last_name']?.errors?.pattern">{{initServ?.appStr?.errorMsg?.specialChars | translate}} </ng-container>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-6" [ngClass]="{'min-height--100': !(section?.form?.email?.label) }">
					<!-- Emails -->
					<label class="form-label bk-form-label d-block" [id]="section?.form?.email?.label_id" *ngIf="section?.form?.email?.label" [innerHTML]="section?.form?.email?.label | safeHtml"></label>
					<ng-container *ngIf="f['emails'].value && (f['emails'].value).length > 0">
						<ng-container formArrayName="emails" *ngFor="let email of getControls('emails'); let i = index;trackBy: utilServ.trackByFnIndex">
							<div class="mb-25" [formGroupName]="i">
								<div class="d-flex align-items-center justify-content-between">
									<input [ngClass]="{'is-invalid': email.controls['value']?.touched && email.controls['value']?.errors}" class="form-control"[attr.placeholder]="section?.form?.email?.placeholder" formControlName="value">
									<!-- Add button -->
									<button *ngIf="i == 0; else deleteEmail" type="button" class="btn ps-10 pe-0 text-dark tjs-hover-text__primary fs-20 bk-add" (click)="addMultiControl('emails')"><i class="tjsicon-add"></i></button>
									<!-- Delete button -->
									<ng-template #deleteEmail>
										<button type="button" class="btn ps-10 pe-0 text-dark tjs-hover-text__danger fs-20 bk-del" (click)="deleteMultiControl('emails', i)"><i class="tjsicon-delete"></i></button>
									</ng-template>
								</div>
								<!-- Error -->
								<div class="tjs-error" *ngIf="email.controls['value']?.touched && (email.controls['value']?.errors?.required || email.controls['value']?.errors?.pattern)">
									<i class="tjsicon-attention"></i>
									<ng-container *ngIf="email.controls['value']?.errors?.required">{{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
									<ng-container *ngIf="email.controls['value']?.errors?.pattern">{{initServ?.appStr?.errorMsg?.validEmail | translate}} </ng-container>
								</div>
								<!-- Send notification -->
								<div class="mt-10">
									<div class="form-check form-check-inline">
										<input id="email_{{i}}" type="checkbox" class="form-check-input" formControlName="send_notifications">
										<label for="email_{{i}}" class="form-check-label bk-form-label">{{section?.form?.email?.send_notification_label}}</label>
									</div>
								</div>
							</div>
						</ng-container>
					</ng-container>
				</div>
				<div class="col-12 col-md-6" [ngClass]="{'min-height--100': !(section?.form?.phone?.label) }">
					<!-- Phone numbers -->
					<label class="form-label bk-form-label d-block" [id]="section?.form?.phone?.label_id" *ngIf="section?.form?.phone?.label" [innerHTML]="section?.form?.phone?.label | safeHtml"></label>
					<ng-container *ngIf="f['phone_numbers'].value && (f['phone_numbers'].value).length > 0">
						<ng-container formArrayName="phone_numbers" *ngFor=" let phone of getControls('phone_numbers'); let i = index;trackBy: utilServ.trackByFnIndex">
							<div class="mb-25" [formGroupName]="i">
								<div class="d-flex align-items-center justify-content-between">
									<div class="input-group input-group-ltr">
										<div class="input-group-text" *ngIf="initServ?.callingCode">
											<span class="">{{initServ?.callingCode}}</span>
										</div>
										<input [ngClass]="{'is-invalid': phone.controls['value']?.touched && phone.controls['value']?.errors}" class="form-control" type="text" [textMask]="{mask: phoneMask}" [attr.placeholder]="section?.form?.phone?.placeholder" formControlName="value">
									</div>
									<!-- Add button -->
									<button *ngIf="i == 0; else deletePhone" type="button" class="btn ps-10 pe-0 text-dark tjs-hover-text__primary fs-20 bk-add" (click)="addMultiControl('phone_numbers')"><i class="tjsicon-add"></i></button>
									<!-- Delete button -->
									<ng-template #deletePhone>
										<button type="button" class="btn ps-10 pe-0 text-dark tjs-hover-text__danger fs-20 bk-del" (click)="deleteMultiControl('phone_numbers', i)"><i class="tjsicon-delete"></i></button>
									</ng-template>
								</div>
								<!-- Error -->
								<div class="tjs-error" *ngIf="phone.controls['value']?.touched && phone.controls['value']?.errors?.required">
									<i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.empty | translate}}
								</div>
								<!-- Send notification -->
								<div class="mt-10">
									<div class="form-check form-check-inline">
										<input id="phone_{{i}}" type="checkbox" class="form-check-input" formControlName="send_notifications">
										<label for="phone_{{i}}" class="form-check-label bk-form-label">{{section?.form?.phone?.send_notification_label}}</label>
									</div>
								</div>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</div>
			<!-- Note -->
			<div class="mb-0">
				<label class="form-label bk-form-label d-block" [id]="section?.form?.notes?.label_id" *ngIf="section?.form?.notes?.label" [innerHTML]="section?.form?.notes?.label | safeHtml"></label>
				<editor [apiKey]="initServ?.tinymceAPIKey" [init]="tinymceConfig" formControlName="note" debounce="500"></editor>
			</div>
		</div>
		<!-- Buttons -->
		<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse bk-footColor">
			<bk-navigate class="d-block mb-15 mb-sm-5" [secId]="section?.save_btn?.id" [isSubmit]="true" [designId]="section?.save_btn?.design_id" [innerHTML]="section?.save_btn?.content" customClass="btn btn-primary rounded-5 w-100" essentialClass="btn w-100"></bk-navigate>
			<bk-navigate class="d-block mb-5" [secId]="section?.cancel_btn?.id" [isSubmit]="false" [designId]="section?.cancel_btn?.design_id" [innerHTML]="section?.cancel_btn?.content" customClass="btn btn-white rounded-5 w-100" essentialClass="btn w-100" (callback)="dialogRef.close()"></bk-navigate>
		</div>
	</form>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
