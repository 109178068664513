<div class="mt-0" [formGroup]="form">
	<label class="form-label bk-form-label d-block" *ngIf="section?.bkngs_with_card?.label" [innerHTML]="section?.bkngs_with_card?.label | safeHtml"></label>
	<div class="form-group" [ngClass]="(isBookingsWithCash) ? 'mb-25' : 'mb-0' ">
		<div class="form-check form-check-inline mb-10">
			<input id="upcoming_without_pending_{{id}}" class="form-check-input tjs-pointer" type="radio" value="upcoming_without_pending" formControlName="apply_to">
			<label class="form-check-label bk-form-label tjs-pointer" for="upcoming_without_pending_{{id}}">{{section?.bkngs_with_card?.exclude_payment}}</label>
		</div>
		<div class="form-check form-check-inline mb-10">
			<input id="upcoming_with_pending_{{id}}" class="form-check-input tjs-pointer" type="radio" value="upcoming_with_pending" formControlName="apply_to">
			<label class="form-check-label bk-form-label tjs-pointer" for="upcoming_with_pending_{{id}}">{{section?.bkngs_with_card?.include_payment}}</label>
		</div>
	</div>
	<ng-container *ngIf="isBookingsWithCash">
		<label class="form-label bk-form-label d-block" *ngIf="section?.bkngs_with_cash?.label" [innerHTML]="section?.bkngs_with_cash?.label | safeHtml"></label>
		<div class="form-group">
			<div class="form-check form-check-inline mb-10">
				<input id="yes_{{id}}" class="form-check-input tjs-pointer" type="radio" value="yes" formControlName="booking_with_cash">
				<label class="form-check-label tjs-pointer" for="yes_{{id}}">{{section?.bkngs_with_cash?.cash_payment_yes}}</label>
			</div>
			<div class="form-check form-check-inline mb-10">
				<input id="no_{{id}}" class="form-check-input tjs-pointer" type="radio" value="no" formControlName="booking_with_cash">
				<label class="form-check-label tjs-pointer" for="no_{{id}}">{{section?.bkngs_with_cash?.cash_payment_no}}</label>
			</div>
		</div>
	</ng-container>
</div>
