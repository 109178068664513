import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// Services
import { CacheService, NgOnDestroy } from '../../Services';

@Component({
	selector: 'bk-message-popup',
	template: `
	<div class="tjs-popup tjs-popup--sm position-relative" [id]="secId">
		<div class="tjs-popup__content tjs-popup__body  min-height--100">
			<div class="mt-10" [id]="section?.message?.id">
				<ng-container *ngIf="isSplit && msgArray && msgArray.length > 0; else msgBlock">
					<h4 class="mb-2 fw-bold text--color">{{msgArray[0]}}</h4>
					<p class="mb-0 fs-18" [innerHTML]="msgArray[1] | translate | safeHtml "></p>
				</ng-container>
				<ng-template #msgBlock>
					<p class="mb-0 fs-18" [innerHTML]="(isCustMsg ? section?.message?.[msgType] : staticMsg) | translate | safeHtml"></p>
				</ng-template>
			</div>
		</div>
		<div class="tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse bk-footColor">
			<bk-navigate class="d-block mb-5 mb-sm-0" [secId]="section?.button?.id" [designId]="section?.button?.design_id" [innerHTML]="section?.button?.content" essentialClass="w-100" customClass="btn btn-primary rounded-5 w-100" (callback)="dialogRef.close(true)"></bk-navigate>
		</div>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</div>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class MessagePopupComponent implements OnInit {

	// Variables
	msg: string = '';
	btn: boolean = false;
	isSplit: boolean = false;
	msgArray: any;
	isCenter: boolean = true;
	//
	isCustMsg: boolean = false;
	staticMsg: string = '';
	msgType: string = '';
	//
	loaderId: string = 'message-loader';
	slug: string = 'message';
	secId: string = '';
	section: any = { message: null, button: null };

	constructor(public dialogRef: MatDialogRef<MessagePopupComponent>, private cacheServ: CacheService) { }

	ngOnInit(): void {
		if (this.staticMsg && this.isSplit) {
			this.msgArray = (this.staticMsg).split(":");
		}
		// build popup section
		this.buildSectionData();
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
		});
	}
}
