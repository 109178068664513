import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation, Self, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
// Services
import { NgOnDestroy, UtilServ, InitServ, RenderComponentServ, PopupServ } from '../../../Services';

@Component({
	selector: 'bk-navigate',
	templateUrl: './BKNavigate.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class BKNavigateComponent implements OnInit, OnChanges {
	// Variables
	@Input() secSett: any;
	@Input() innerHTML: any;
	@Input() customClass: string = '';
	@Input() essentialClass: string = '';
	@Input() secId: string = '';
	@Input() isSubmit: boolean = false;
	@Input() designId: string = '';
	@Input() btnDisabled: boolean = false;
	@Input() isText: boolean = false; // Work in case of none only
	@Input() isBtnEvent: boolean = false;
	@Input() isLinkBtn: boolean = false;
	@Input() routeLink: any = [];
	@Input() isPopup: boolean = false;
	@Input() dialogRef: any;
	@Input() type: string = '';
	@Input() isDebounce: boolean = false;
	@Input() isDebounceLoader: boolean = true;
	@Input() debounceLoaderId: string = 'main';
	@Output() callback: EventEmitter<any> = new EventEmitter<any>();

	btnClass: string = '';
	link: any = null;
	multiIndusStatus: boolean = false;
	disableNavigation: boolean = false;
	navAvailable: boolean = true;
	currentUser: any;

	constructor(private utilServ: UtilServ, public initServ : InitServ, @Self() private destroy: NgOnDestroy, private rcServ: RenderComponentServ, private router: Router, private popupServ: PopupServ) {
		// Multi industry
		this.multiIndusStatus = this.utilServ.multiIndusStatus;
		// Current login user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
		// User logged in get again current user local storage
		this.initServ.isUserProfile.pipe(takeUntil(this.destroy)).subscribe((value) => {
			if(value){
				this.currentUser = this.utilServ.appLocalStorage();
				// Build the gift card link
				this.buildGiftCardLink();
			}
		});
	}

	ngOnInit(): void {
		// Generate button class
		this.generateBtnClass();
		if(this.secSett){
			this.navAvailable = this.checkOfferForm(this.secSett);
			// Link to empty, consider none
			if(!this.secSett.link_to){
				this.secSett.link_to = 'none';
			}
			// Build link
			this.link = this.utilServ.buildLink(this.secSett.link_to, this.secSett.link_url);
			// For booknow, in case of multi industry, link to popup
			if(this.secSett.link_url && this.multiIndusStatus){
				let slug = this.secSett.link_url.replace(/\//g, "");
				if(slug == 'booknow'){
					this.secSett['link_to']='popup';
				}
			}
			// Build the gift card link
			this.buildGiftCardLink();
			// Disable click event
			if(this.secSett.disable_on && (this.secSett.disable_on).length > 0){
				this.utilServ.pageSett.pipe(takeUntil(this.destroy)).subscribe((sett: any) => {
					if(sett && sett.status && sett.data && sett?.data?.id && (this.secSett.disable_on).includes(sett?.data?.id)){
						this.link = null;
						this.disableNavigation = true;
					}
				});
			}
		}
	}
	/**
	 * Life-cycle Hooks method, checks if the designId property has changed and generates a button class if it has.
	 * After render the button component, other props build in the parent and new values are updated with the Input() decorator.
	 * So `generateBtnClass()` need to re-called so it can generate desgin variation according to its `desginId` props.
	 * @param {SimpleChanges} changes - The `changes` parameter is an object that contains the changes
	 * detected in the component's input properties.
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			let chng = changes.designId;
			if (chng && !chng.firstChange) {
				let cur = JSON.stringify(chng.currentValue);
				let prev = JSON.stringify(chng.previousValue);
				if(cur != prev){
					// Generate button class
					this.generateBtnClass();
				}
			}
		}
	}
	/**
	 * Build the gift card link after login
	 */
	private buildGiftCardLink(): void {
		if(this.secSett && this.secSett.link_url && (this.currentUser && !this.initServ.theme) && (this.secSett.link_url == '/gift-cards/send' || this.secSett.link_url == 'gift-card' || this.secSett.link_url == '/gift-card')){
			this.secSett.link_url = '/gift-cards';
		}
	}
	/**
	 * Check the current url for active class
	 * @param link: link
	 * @returns
	 */
	public checkCurrentUrl(linkUrl: string, link: any = null){
		if(this.type == 'menu'){
			if(!link && linkUrl){
				link = JSON.parse(JSON.stringify(linkUrl));
			}
			let url = (link && link.charAt(0) !== '/') ? ('/'+link) : link;
			if(this.initServ.theme){ url = url+'?theme='+this.initServ.theme;}
			if(url == this.router.url){
				return true;
			}
			// if((link == 'booknow' || link == '/booknow') && ((this.router.url).includes('booknow') || (this.router.url).includes('/booknow'))){
			if((linkUrl == 'booknow' || linkUrl == '/booknow') && ((this.router.url).includes(link))){
				return true;
			}
		}
		return false;
	}
	/**
	 * Generate button class based on variation
	 */
	private generateBtnClass(): void {
		this.designId = this.designId ? this.designId : (this.secSett && this.secSett.design_id && this.secSett.design_id);
		this.btnClass = 'btn ';
		switch (this.designId) {
			case "bk_btn_V1":
				this.btnClass += "btn-primary tjs-btn--lg rounded-3 border-transparent";
				break;
			case "bk_btn_V2":
				this.btnClass += "btn-primary tjs-btn--lg tjs-btn--rounded_lg border-transparent";
				break;
			case "bk_btn_V3":
				this.btnClass += "btn-primary tjs-btn--lg rounded-0 border-transparent";
				break;
			case "bk_btn_V4":
				this.btnClass += "btn-outline-primary py-15 px-40 rounded-3";
				break;
			case "bk_btn_V5":
				this.btnClass += "btn-outline-primary py-15 px-40 rounded-0";
				break;
			case "bk_btn_V6":
				this.btnClass += "btn-outline-primary tjs-btn--lg rounded-0";
				break;
			case "bk_btn_V7":
				this.btnClass += "btn-primary py-10 px-20 rounded-0 border-transparent";
				break;
			case "bk_btn_V8":
				this.btnClass += "btn-primary py-15 px-40 rounded-3 border-transparent";
				break;
			case "bk_btn_V9":
				this.btnClass += "btn-primary py-10 px-20 tjs-btn--rounded_lg border-transparent";
				break;
			case "bk_btn_V10":
				this.btnClass += "btn-primary py-10 px-20 rounded-3 border-transparent";
				break;
			case "bk_btn_V11":
				this.btnClass += "btn-primary py-15 px-40 rounded-0 border-transparent";
				break;
			case "bk_btn_V12":
				this.btnClass += "btn-outline-primary py-10 px-20 rounded-0";
				break;
			case "bk_btn_V13":
				this.btnClass += "btn-outline-primary tjs-btn--lg rounded-3";
				break;
			case "bk_btn_V14":
				this.btnClass += "btn-primary py-15 px-40 tjs-btn--rounded_lg border-transparent";
				break;
			case "bk_btn_V15":
				this.btnClass += "btn-outline-primary py-10 px-20 rounded-3";
				break;
			case "bk_btn_V16":
				this.btnClass += "btn-outline-primary tjs-btn--lg tjs-btn--rounded_lg";
				break;
			case "bk_btn_V17":
				this.btnClass += "btn-outline-primary py-15 px-40 tjs-btn--rounded_lg";
				break;
			case "bk_btn_V18":
				this.btnClass += "btn-outline-primary py-10 px-20 tjs-btn--rounded_lg";
				break;
			default:
				this.btnClass += this.customClass;
				break;
		}
		// this.customClass = this.btnClass;
		this.customClass = this.btnClass + ' '+ this.essentialClass;
	}
	/**
	 * Show popup
	 * @param data
	 */
	public showPopup(data: any): void{
		if(!this.disableNavigation){
			if(data.link_url){
				let slug = data.link_url.replace(/\//g, "");
				if(slug == 'booknow'){
					this.popupServ.industriesPopup();
				} else {
					if(data.link_to == 'lead_popup'){
						this.popupServ.leadsFormPopup(data);
					}else{
						this.rcServ.buildPopup(data.link_url);
					}
				}
			}
		}
	}
	/**
	 * Custom button click event callback
	 */
	public clickEvent(evt: any): void {
		if(this.isBtnEvent){
			this.callback.emit(evt);
		} else {
			this.callback.emit();
		}
		if(this.isPopup){
			this.dialogRef.close();
		}
	}
	/**
	 * Scroll to specific section based on id
	 * @param data
	 */
	public scrollToSec(data: any): void {
		if(!this.disableNavigation){
			// Hide menu in responsive, on navigation change
			let ele: any = document.getElementById("navbarSupportedContent");
			if(ele && ele.classList.contains("show")){
				ele.classList.remove("show");
			}
			let id : any = ((data.link_url).trim()).split('#').join('');
			let el : any = document.getElementById(id);
			if(id && el && el != null){
				// If the header is fixed at the top, add its height to the scroll offset to ensure that the section content never goes behind the header."
				let top: number = this.getScrollTopPosition(el);
				if(top > 0){
					window.scrollTo({
						top: top,
						behavior: "smooth" // Smooth scrolling
					});
				}else{
					el.scrollIntoView({behavior: "smooth"});
				}
			}
		}
	}
	/**
	 * Calculates the scroll top position of a given element relative to the top of the viewport, adjusting for the height of the header if applicable.
	 * @param el - The DOM element whose scroll position is to be calculated.
	 * @returns The calculated top scroll position as a number.
	 */
	private getScrollTopPosition(el: any): number {
		// Initialize a variable `top` to store the calculated top position.
		let top: number = 0;
		// Check if the `headerHeight` from `utilServ` is greater than 0.
		// This condition ensures that there's a header height to account for in the calculation.
		if(this.utilServ.headerHeight > 0){
			// Calculate the top position:
			// - `el.getBoundingClientRect().top`: Gets the element's top position relative to the viewport.
			// - `window.scrollY`: Adds the current vertical scroll position of the window, making the position relative to the document.
			// - `this.utilServ.headerHeight`: Subtracts the header height to adjust the position correctly below the header.
			top = el.getBoundingClientRect().top + window.scrollY - this.utilServ.headerHeight;
		}
		// Return the calculated top position, ensuring it is returned as a number.
		return +top;
	}
	/**
	 * Check the booknow and lead form permission and admin settings
	 * @param data: setting
	 * @returns : boolean
	 */
	private checkOfferForm(data: any){
		if(data.link_to == 'page'){
			if(data.link_url == "/booknow" || data.link_url == "booknow"){
				return this.utilServ.bookingOrLeadFormStatus('booking_form');
			} else if(data.link_url == "/contact-us" || data.link_url == "contact-us"){
				if(this.utilServ.appPermission('leadForm')){
					return this.utilServ.bookingOrLeadFormStatus('lead_form');
				}
			}
		}
		return true;
	}
}
