<div class="position-relative">
	<div [ngClass]="isMultiStepForm ? 'mb-10': 'pt-30 pb-10 px-md-30 border-bottom bk-card-border'" [formGroup]="itemsForm" [id]="section?.id" *ngIf="section && items && items.length > 0">
		<!-- Title and description -->
		<bk-section-heading *ngIf="!isMultiStepForm" [section]="section" [pageSett]="pageSett"></bk-section-heading>
		<!-- Items -->
		<div id="item-section-id">
			<!-- Label -->
			<label [id]="section?.sel_item_id" *ngIf="section?.sel_item" class="form-label bk-form-label" [innerHTML]="section?.sel_item | safeHtml"></label>
			<div id="vehicle_selection_area" class="tjs-extra mx-n10 flex-wrap d-flex" [ngClass]="isMultiStepForm ? 'tjs-extra--lg' :''">
				<div class="tjs-extra__list text-center mb-20 px-10"  *ngFor="let item of items;trackBy: utilServ.trackByFnIndex">
					<div class="border rounded-2 position-relative mb-5 bk-form-extra selected-extra--item" [ngClass]="{'selected-extra': item?.id == selectedItem?.id}">
						<label for="item-{{item?.id}}" class="d-flex tjs-pointer justify-content-center align-items-center h-100 w-100 mb-0">
							<!-- <span class="d-flex align-items-center justify-content-center vehicle-select-label-image position-absolute end-0 start-0 bottom-0 top-0" [ngClass]="{'select-label': item?.id == selectedItem?.id}"> -->
							<span class="d-flex align-items-center justify-content-center position-absolute end-0 start-0 bottom-0 top-0">
								<!-- Image -->
								<span class="tjs-extra__img m-auto">
									<img loading="auto" width="21" height="20" [src]="utilServ.getImgUrl(item?.icon_url)" class="img-fluid bk-img" (error)="utilServ.handleImgError($event, initServ?.img?.DeepCleaning)" alt="addons" />
								</span>
								<!-- Radio button -->
								<input id="item-{{item?.id}}" class="form-check-input bk-form-extra-input" type="radio" name="item" value="{{item?.id}}" (change)="itemSelect(item)" [attr.disabled]="isDisabled">
							</span>
							<span class="card-img-overlay tjs-transition--base tjs-overlay__dark d-flex align-items-center rounded-2 p-0 bk-form-extra-overlay">
							</span>
						</label>
					</div>
					<p class="fs-14 mb-0 bk-form-sub-label">
						<span class="text-capitalize">{{item?.display_name | translate}}</span>
						<span *ngIf="['reschedule', 'draft'].includes(bookingType) && item?.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
						<!-- Tooltip -->
						<bk-tooltip *ngIf="item?.show_tooltip_icon && item?.tooltip_text" customClass="tjs-tooltip-icon--sm bk-tooltip" [content]="item?.tooltip_text | translate"></bk-tooltip>
					</p>
				</div>
			</div>
			<!-- Addons -->
			<div id="addon-section-id"  *ngIf="selectedItem && addons && addons[selectedItem?.id] && (addons[selectedItem?.id]).length > 0">
				<label [id]="section?.choose_addon_id" *ngIf="section?.choose_addon" class="me-5 form-label bk-form-label" [innerHTML]="section?.choose_addon | safeHtml"></label>
				<div class="tjs-extra mx-n10 flex-wrap d-flex" [ngClass]="isMultiStepForm ? 'tjs-extra--lg' :''">
					<div class="tjs-extra__list text-center mb-20 px-10" *ngFor="let addon of addons[selectedItem?.id];trackBy: utilServ.trackByFnIndex">
						<div class="border rounded-2 position-relative mb-5 overflow-hidden bk-form-extra selected-extra--item" [ngClass]="{'selected-extra': (selectedAddonIds && (selectedAddonIds).length > 0 && (selectedAddonIds).includes(+addon?.id))}">
							<label for="addon-{{addon?.name}}" class="d-flex tjs-pointer justify-content-center align-items-center h-100 w-100 mb-0">
								<!-- <span class="vehicle-select-label-image d-flex align-items-center position-absolute start-0 end-0 top-0 bottom-0 justify-content-centent" [ngClass]="{'select-label': (selectedAddonIds && (selectedAddonIds).length > 0 && (selectedAddonIds).includes(+addon?.id))}"> -->
								<span class="d-flex align-items-center position-absolute start-0 end-0 top-0 bottom-0 justify-content-centent">
									<span class="tjs-extra__img m-auto">
										<img loading="auto" width="21" height="20" [src]="utilServ.getImgUrl(addon?.icon_url)" alt="addons" class="img-fluid" (error)="utilServ.handleImgError($event, initServ?.img?.DeepCleaning)" />
									</span>
									<!-- Checkbox -->
									<input id="addon-{{addon?.name}}" class="form-check-input bk-form-extra-input addon-checkbox" type="checkbox" (change)="selectAddon($event,addon)" [attr.disabled]="isDisabled" [attr.checked]="(selectedAddonIds && (selectedAddonIds).length > 0 && (selectedAddonIds).includes(+addon?.id)) ? 'checked' : null">
								</span>
								<span class="card-img-overlay tjs-transition--base tjs-overlay__dark d-flex align-items-center rounded-2 p-0 bk-form-extra-overlay">
								</span>
							</label>
						</div>
						<p class="fs-14 mb-0 bk-form-sub-label">
							<span class="text-capitalize">{{utilServ.getFormParamName(addon) | translate}}</span>
							<span *ngIf="['reschedule', 'draft'].includes(bookingType) && addon?.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
							<!-- Tooltip -->
							<bk-tooltip *ngIf="addon?.show_tooltip_icon && addon?.tooltip_text" customClass="tjs-tooltip-icon--sm bk-tooltip" [content]="addon?.tooltip_text | translate"></bk-tooltip>
						</p>
					</div>
				</div>
			</div>
			<!-- Add item button -->
			<div *ngIf="selectedItem && selectedAddonIds && (selectedAddonIds).length > 0">
				<bk-navigate [secId]="section?.add_item_btn?.id" [designId]="section?.add_item_btn?.design_id" [innerHTML]="section?.add_item_btn?.content" customClass="btn btn-lg btn-primary border-transparent" (callback)="addItem()" essentialClass="w-100 d-flex align-items-center justify-content-center mt-20 mb-20" [btnDisabled]="isDisabled"></bk-navigate>
			</div>
			<!-- Selected item and addon -->
			<div *ngIf="itemsForm.controls['items'] && (itemsForm.value['items']).length > 0">
				<label [id]="section?.sel_items_id" *ngIf="section?.sel_items" class="form-label bk-form-label" [innerHTML]="section?.sel_items | safeHtml"></label>
				<div class="d-flex flex-column-reverse">
					<div class="card tjs-d-border tjs-bg-gray mb-30 tjs-mb-first-0 bk-form-selected-item" [ngClass]="{'border': isMultiStepForm}" *ngFor="let item of itemsForm.value['items']; let index = index;trackBy: utilServ.trackByFnIndex">
						<div class="card-body">
							<div class="d-flex align-items-center justify-content-between mb-15">
								<h5 class="text-capitalize fw-bold bk-form-selected-label">
									<span>{{bkngFormServ.getElementName(item, 'items') | translate}}</span>
									<span *ngIf="['reschedule', 'draft'].includes(bookingType) && bkngFormServ.statusOfSelectedItem(item?.id, availableSett)">{{initServ?.appStr?.text?.archived | translate}}</span>
								</h5>
								<button type="button" class="btn  p-0 tjs-btn-close bg-white text-danger tjs-transition--base rounded-circle fs-12" data-placement="top" data-toggle="tooltip" title="Delete" (click)="confirmPopup(index)" [attr.disabled]="isDisabled">
									<i class="tjsicon-close"></i>
								</button>
							</div>
							<div class="d-sm-flex align-items-center justify-content-between">
								<div *ngIf="item.addons && (item.addons).length > 0">
									<div class="my-5 bk-form-selected-label" *ngFor="let addon of item.addons;trackBy: utilServ.trackByFnIndex">
										<span class="text-capitalize">{{bkngFormServ.getElementName(addon, 'addons') | translate}}</span>
										<span *ngIf="['reschedule', 'draft'].includes(bookingType) && bkngFormServ.statusOfSelectedAddon(addon?.id, availableSett)">{{initServ?.appStr?.text?.archived | translate}}</span>
									</div>
								</div>
								<div *ngIf="item?.enable_quantity_based == 'yes'">
									<span class="tjs-pcounter d-flex rounded text-center overflow-hidden mt-20 mt-sm-0">
										<button class="tjs-pcounter__btn fs-24 border-0 p-0 text-white rounded-left tjs-pcounter__btn-dec bk-form-extra-dec" type="button" (click)="itemCountChange(index,item,'decrement')" [attr.disabled]="isDisabled">-</button>
										<input type="text" class="tjs-pcounter__number border-0 text-center bg-white" [value]="item?.quantity" readonly>
										<button class="tjs-pcounter__btn fs-24 border-0 p-0 text-white rounded-right tjs-pcounter__btn-inc bk-form-extra-inc" type="button" (click)="itemCountChange(index,item,'increment')" [attr.disabled]="isDisabled">+</button>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<bk-navigate [secId]="section?.add_another_item_btn?.id" [designId]="section?.add_another_item_btn?.design_id" innerHTML="<i class='tjsicon-plus me-10'></i>{{section?.add_another_item_btn?.content}}" customClass="btn btn-lg btn-primary border-transparent" essentialClass="w-100 mb-20 mt-20 d-flex align-items-center justify-content-center bk-btn" (callback)="utilServ.scrollToSpecificEle('item-section-id')"></bk-navigate>
			</div>
		</div>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>