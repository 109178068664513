import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// Services
import { NgOnDestroy, SectionServ, RenderComponentServ, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-apply-now-popup',
	templateUrl: './ApplyNowPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplyNowPopupComponent implements OnInit {

	@Input() secId: string = '';
	@Input() popupId: any;
	popupData: any;
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		list: null
	}

	constructor(public dialogRef: MatDialogRef<ApplyNowPopupComponent>,public secServ: SectionServ, public rcServ: RenderComponentServ, private utilServ: UtilServ) {
	}

	ngOnInit(): void {
		if(this.secId && this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.popupData = this.rcServ.popupData[this.popupId];
			this.buildSection();
		}
	}
	/**
	 * Build section
	 */
	private buildSection(): void {
		this.pageSett = this.popupData.section_settings;
		this.utilServ.popupPosition(this.pageSett[this.secId].variation_id, this.popupData.settings, this.dialogRef);
		this.secServ.setServData(this.pageSett, this.popupData.content);
		let secElem : any = null;
		if(this.popupData.sections){
			secElem = this.popupData.sections[this.secId];
		}
		if(secElem && this.pageSett && this.pageSett[this.secId] && this.pageSett[this.secId][this.rcServ.statusType]){
			for(let key in this.section){
				if(secElem[key]){
					switch (key) {
						case "list":
							this.section[key] =  this.secServ.buildList(secElem[key]);
							break;
						default:
							this.section[key] = this.secServ.buildText(secElem[key]);
							break;
					}
				}
			}
		}
	}
	/**
	 * Get the html content
	 * @param item: item
	 * @returns html
	 */
	public getHTML(item: any): any {
		let html: any = "<div class='row align-items-center text-md-start text-center'>";
		if(item?.media && item?.media?.length>0){
			html += "<div class='col-sm-5 mb-sm-0 mb-20'><img loading='lazy' id="+item?.media_id+" class='img-fluid bk-item-media' src="+item?.media[0]+" height='65' width='68' alt='bk-images' /></div>"
		}
		if(item?.title){
			html += "<div class='col-sm-7'><p id="+item?.title_id+" class='mb-0 fs-20 text-body'>"+item?.title+"</p></div>";
		}
		html += "</div>";
		return html;
	}
}