import { Injectable } from '@angular/core';
import { UtilServ } from '..';
export interface BkngPrice {
	displayServicePrice: number;
	displayServiceTotal: number;
	serviceTotalForProvider: number;
	displayServiceFee: number;
	displayFrequencyDiscount:number;
	displayDiscountedAmount:number;
	displayCouponDiscount: number;
	displaySpotDiscount: number;
	displayGiftCardAmount: number;
	displayReferralDiscount: number;
	displayFinalAmount: number;
	displayTipsAmount: number;
	displayParkingAmount: number;
	displayBonusAmount: number;
	displaySaleTax: number;
	displayTotal: number;
	updatedDisplayServicePrice?: number;
}
@Injectable({
	providedIn: 'root'
})
export class BkngListPriceCalServ {

	constructor(private util: UtilServ) {}
	/**
	 * Get the common local variables for price calculations
	 */
	public get getCmnListPriceVar(): any {
		return {
			displayServicePrice: 0,
			displayServiceTotal: 0,
			serviceTotalForProvider: 0,
			displayServiceFee: 0,
			displayFrequencyDiscount: 0,
			displayDiscountedAmount: 0,
			displayCouponDiscount: 0,
			displaySpotDiscount: 0,
			displayGiftCardAmount: 0,
			displayReferralDiscount: 0,
			displayFinalAmount: 0,
			displayTipsAmount: 0,
			displayParkingAmount: 0,
			displayBonusAmount: 0,
			displaySaleTax: 0,
			displayTotal: 0,
		}
	}

	public get getCmnPrvdrPayDtls(): any {
		return {
			serviceTotal: 0,
			couponDiscount: 0,
			reimbursements: [],
			tipsAmount: 0,
			parkingAmount: 0,
			bonusAmount: 0,
			serviceFeeAmount: 0,
			providerBasicAmount: 0,
			override_provider_pay: false,
			provider_pay_value: 0,
			priceableFieldsPrice: 0
		}
	}
	/**
	 * calcPriceDetails that takes three parameters: priceLocalVar, bookingDetails, and prvdrPayDtls.
	 * Here is a brief summary of what the code does:
	 * 1. Assigns the value of bookingDetails.service_total to priceLocalVar.displayServicePrice and rounds it to two decimal places.
	 * 2. If bookingDetails.service_price is present, assigns its value to priceLocalVar.displayServicePrice instead.
	 * 3. Rounds priceLocalVar.displayServicePrice to two decimal places and assigns it to priceLocalVar.displayServiceTotal.
	 * 4. Calls the calcExpeditedAmount method, passing bookingDetails and priceLocalVar as arguments, and assigns the returned value to priceLocalVar.displayServiceTotal.
	 * 5. Calls the calcReferalAmount method, passing bookingDetails and priceLocalVar as arguments, and assigns the returned value to priceLocalVar.displayServiceTotal.
	 * 6. Rounds priceLocalVar.displayServiceTotal to two decimal places and assigns it to priceLocalVar.serviceTotalForProvider.
	 * 7. If bookingDetails.inc_in_freq_exc_frm_prvdr is greater than 0, subtracts its value from priceLocalVar.serviceTotalForProvider.
	 * 8. Calls the calcFreqDiscount method, passing bookingDetails and priceLocalVar as arguments, and assigns the returned value to priceLocalVar.
	 * 9. Calls the addExemptExtrasPrice method, passing bookingDetails and priceLocalVar as arguments, and assigns the returned value to priceLocalVar.
	 * 10. Calls the addExemptFrmFreqFieldsPrice method, passing bookingDetails and priceLocalVar as arguments, and assigns the returned value to priceLocalVar.
	 * 11. If bookingDetails.exempt_frm_freq_exc_frm_prvdr is greater than 0, subtracts its value from priceLocalVar.serviceTotalForProvider and rounds it to two decimal places.
	 * 12. Calls the calcSpotDiscount method, passing bookingDetails and priceLocalVar as arguments, and assigns the returned value to priceLocalVar.
	 * 13. Rounds priceLocalVar.serviceTotalForProvider to two decimal places and assigns it to prvdrPayDtls.serviceTotal.
	 * 14. Rounds priceLocalVar.displayServiceTotal to two decimal places and assigns it to priceLocalVar.displayDiscountedAmount.
	 * 15. Calls the calcCouponDiscForPrvdr method, passing bookingDetails, priceLocalVar, and prvdrPayDtls as arguments, and assigns the returned value to priceLocalVar.serviceTotalForProvider.
	 * 16. Calls the calcCouponDiscount method, passing bookingDetails and priceLocalVar as arguments, and assigns the returned value to priceLocalVar.
	 * 17. Calls the calcTaxableServFee method, passing bookingDetails and priceLocalVar as arguments, and assigns the returned value to priceLocalVar.
	 * 18. Returns an object containing priceLocalVar and prvdrPayDtls
	 * @param priceLocalVar
	 * @param bookingDetails
	 * @returns
	 */
	public calcPriceDetails(priceLocalVar: any, bookingDetails: any, prvdrPayDtls: any){
		priceLocalVar.displayServicePrice = this.util.roundToTwo(bookingDetails.service_total);
		if(bookingDetails?.service_price || bookingDetails?.service_price == 0){
			priceLocalVar.displayServicePrice = this.util.roundToTwo(bookingDetails.service_price);
		}
		priceLocalVar.displayServiceTotal = this.util.roundToTwo(priceLocalVar.displayServicePrice);
		// Calculate expedited amount for same day
		priceLocalVar.displayServiceTotal = this.calcExpeditedAmount(bookingDetails, priceLocalVar);
		// Calculate referral amount
		priceLocalVar.displayServiceTotal = this.calcReferalAmount(bookingDetails, priceLocalVar);
		// Set service total for provider
		priceLocalVar.serviceTotalForProvider = this.util.roundToTwo(priceLocalVar.displayServiceTotal);
		// Subtract the value of priceable custom fields from service total for provider whose part directly goes to provider
		if(bookingDetails?.inc_in_freq_exc_frm_prvdr){
			priceLocalVar.serviceTotalForProvider = priceLocalVar.serviceTotalForProvider - bookingDetails.inc_in_freq_exc_frm_prvdr;
			priceLocalVar.serviceTotalForProvider = priceLocalVar.serviceTotalForProvider > 0 ? priceLocalVar.serviceTotalForProvider : 0;
		}
		// Calculate frequency discount
		priceLocalVar = this.calcFreqDiscount(bookingDetails, priceLocalVar);
		// Add exempted extras price
		priceLocalVar = this.addExemptExtrasPrice(bookingDetails, priceLocalVar);
		// Add exempt from frequency fields price
		priceLocalVar = this.addExemptFrmFreqFieldsPrice(bookingDetails, priceLocalVar);
		// Subtract the value of priceable custom fields from service total for provider whose part directly goes to provider
		if(bookingDetails?.exempt_frm_freq_exc_frm_prvdr){
			priceLocalVar.serviceTotalForProvider = this.util.roundToTwo(priceLocalVar.serviceTotalForProvider - bookingDetails.exempt_frm_freq_exc_frm_prvdr);
			priceLocalVar.serviceTotalForProvider = priceLocalVar.serviceTotalForProvider > 0 ? priceLocalVar.serviceTotalForProvider : 0;
		}
		// Calculate spot discount
		priceLocalVar = this.calcSpotDiscount(bookingDetails, priceLocalVar);
		prvdrPayDtls.serviceTotal = this.util.roundToTwo(priceLocalVar.serviceTotalForProvider);
		// Set discounted amount
		priceLocalVar.displayDiscountedAmount = this.util.roundToTwo(priceLocalVar.displayServiceTotal);
		// Calculate coupon discount for provider
		priceLocalVar.serviceTotalForProvider = this.calcCouponDiscForPrvdr(bookingDetails, priceLocalVar, prvdrPayDtls);
		// Calculate coupon discount
		priceLocalVar = this.calcCouponDiscount(bookingDetails, priceLocalVar);
		// Calculate taxable service fee
		priceLocalVar = this.calcTaxableServFee(bookingDetails, priceLocalVar);
		return {
			priceLocalVar: priceLocalVar,
			prvdrPayDtls: prvdrPayDtls
		}
	}
	/**
	 * Calculates the expedited amount for a booking based on certain conditions.
	 * If the booking is a same-day booking and the expedited amount is provided and not excluded, it adds the expedited amount to the displayServiceTotal.
	 * Otherwise, it adds 0 to the displayServiceTotal. The updated displayServiceTotal is then returned.
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @returns
	 */
	public calcExpeditedAmount(bookingDetails: any, priceLocalVar: any){
		if(bookingDetails.same_day_booking && bookingDetails.expedited_amount && (!bookingDetails.exclude_expedited_charge)){
			priceLocalVar.displayServiceTotal = this.util.roundToTwo((+priceLocalVar.displayServiceTotal) + (+bookingDetails.expedited_amount));
		}else{
			priceLocalVar.displayServiceTotal = +priceLocalVar.displayServiceTotal;
		}
		return priceLocalVar.displayServiceTotal;
	}
	/**
	 * Calculates the updated displayServiceTotal after applying a referral discount.
	 * If the bookingDetails object contains a referral_discount property, it checks if the referral discount is less than the current displayServiceTotal.
	 * If it is, it subtracts the referral discount from the displayServiceTotal.
	 * Otherwise, it sets the displayServiceTotal to 0. Finally, it returns the updated displayServiceTotal.
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @returns
	 */
	public calcReferalAmount(bookingDetails: any, priceLocalVar: any){
		if(bookingDetails.referral_discount){
			priceLocalVar.displayServiceTotal = (priceLocalVar.displayServiceTotal > bookingDetails.referral_discount) ? (priceLocalVar.displayServiceTotal - (+bookingDetails.referral_discount)) : 0;
		}
		return priceLocalVar.displayServiceTotal;
	}
	/**
	 * Calculates the updated displayServiceTotal and serviceTotalForProvider after applying a frequency discount.
	 * If the bookingDetails object contains the properties frequency_discount_amount, frequency_discount_value, and if the frequency discount should be applied with a coupon (checked using the isFrequencyDisWithCoupon() function), the code proceeds with the calculation.
	 * If the frequency_discount_type is 'percentage', it calculates the frequency discounted value by multiplying the frequency_discount_value with the displayServiceTotal. It then subtracts this value from the displayServiceTotal and the serviceTotalForProvider.
	 * If the frequency_discount_type is not 'percentage', it directly assigns the frequency_discount_value to the displayFrequencyDiscount property. If the frequency_discount_value is greater than or equal to the displayServiceTotal, it sets the displayServiceTotal to 0. Otherwise, it subtracts the frequency_discount_value from the displayServiceTotal and the serviceTotalForProvider.
	 * Finally, it returns the updated priceLocalVar object.
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @returns
	 */
	public calcFreqDiscount(bookingDetails: any, priceLocalVar: any){
		if(bookingDetails.frequency_discount_amount && bookingDetails.frequency_discount_value && this.util.isFrequencyDisWithCoupon(bookingDetails)){
			if(bookingDetails.frequency_discount_type == 'percentage'){
				let frequencyDiscountedValue = this.util.roundToTwo((+bookingDetails.frequency_discount_value/100)*priceLocalVar.displayServiceTotal);
				priceLocalVar.displayFrequencyDiscount = this.util.roundToTwo(+frequencyDiscountedValue);
				priceLocalVar.displayServiceTotal = this.util.roundToTwo((+priceLocalVar.displayServiceTotal) - priceLocalVar.displayFrequencyDiscount);
				// Frequency discount apply on serviceTotalForProvider
				let frequencyDiscountedValueForProvider = this.util.roundToTwo((+bookingDetails.frequency_discount_value/100)*priceLocalVar.serviceTotalForProvider);
				priceLocalVar.serviceTotalForProvider = this.util.roundToTwo((+priceLocalVar.serviceTotalForProvider) - (+frequencyDiscountedValueForProvider));
			}else{
				priceLocalVar.displayFrequencyDiscount = this.util.roundToTwo(+bookingDetails.frequency_discount_value);
				priceLocalVar.displayServiceTotal = (+priceLocalVar.displayFrequencyDiscount >= (+priceLocalVar.displayServiceTotal)) ? 0 : this.util.roundToTwo((+priceLocalVar.displayServiceTotal) - (+bookingDetails.frequency_discount_value));
				// Frequency discount apply on serviceTotalForProvider
				priceLocalVar.serviceTotalForProvider = ((+bookingDetails.frequency_discount_value) > (+priceLocalVar.serviceTotalForProvider)) ? 0 : this.util.roundToTwo((+priceLocalVar.serviceTotalForProvider) - (+bookingDetails.frequency_discount_value));
			}
		}
		return priceLocalVar;
	}
	/**
	 * Function updates the displayServiceTotal and serviceTotalForProvider in the priceLocalVar object by adding the value of exempted extras.
	 * It checks if the bookingDetails object has the property exempt_extras_price and if its value is greater than 0.
	 * If so, it adds the exempt_extras_price value to the displayServiceTotal and serviceTotalForProvider properties in the priceLocalVar object.
	 * Finally, it returns the updated priceLocalVar object.
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @returns
	 */
	public addExemptExtrasPrice(bookingDetails: any, priceLocalVar: any){
		// Code to calculate the extras value whose value exempted from freq discount.
		if(bookingDetails.exempt_extras_price && bookingDetails.exempt_extras_price > 0){
			priceLocalVar.displayServiceTotal = priceLocalVar.displayServiceTotal + (+bookingDetails.exempt_extras_price);
			priceLocalVar.serviceTotalForProvider = this.util.roundToTwo(priceLocalVar.serviceTotalForProvider + (+bookingDetails.exempt_extras_price));
		}
		return priceLocalVar;
	}
	/**
	 * Function updates the displayServiceTotal and serviceTotalForProvider properties in the priceLocalVar object.
	 * It checks if the bookingDetails object has the property exempt_from_freq_fields_price and if its value is greater than 0.
	 * If both conditions are met, it adds the value of exempt_from_freq_fields_price to the displayServiceTotal and serviceTotalForProvider properties in the priceLocalVar object.
	 * Finally, it returns the updated priceLocalVar object.
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @returns
	 */
	public addExemptFrmFreqFieldsPrice(bookingDetails: any, priceLocalVar: any){
		if(bookingDetails?.exempt_from_freq_fields_price){
			priceLocalVar.displayServiceTotal = priceLocalVar.displayServiceTotal + (+bookingDetails.exempt_from_freq_fields_price);
			priceLocalVar.displayServiceTotal = priceLocalVar.displayServiceTotal > 0 ? priceLocalVar.displayServiceTotal : 0;
			priceLocalVar.serviceTotalForProvider = this.util.roundToTwo(priceLocalVar.serviceTotalForProvider + (+bookingDetails.exempt_from_freq_fields_price));
			priceLocalVar.serviceTotalForProvider = priceLocalVar.serviceTotalForProvider > 0 ? priceLocalVar.serviceTotalForProvider : 0;
		}
		return priceLocalVar;
	}
	/**
	 * Function adds the value of the property after_disc_total_fields_price from the bookingDetails object to the displayFinalAmount property in the priceLocalVar object,
	 * if the value is greater than 0. Then, it returns the updated priceLocalVar object.
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @returns
	 */
	public addAfterDiscTotalPrice(bookingDetails: any, priceLocalVar: any){
		if(bookingDetails?.after_disc_total_fields_price){
			priceLocalVar.displayFinalAmount = priceLocalVar.displayFinalAmount + (+bookingDetails.after_disc_total_fields_price);
			priceLocalVar.displayFinalAmount = priceLocalVar.displayFinalAmount > 0 ? priceLocalVar.displayFinalAmount : 0;
		}
		return priceLocalVar;
	}
	/**
	 * Function checks if the property non_taxable_fields_price in the bookingDetails object has a value greater than 0.
	 * If it does, it adds this value to the displayFinalAmount property in the priceLocalVar object.
	 * Finally, it returns the updated priceLocalVar object.
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @returns
	 */
	public addNonTaxFieldsPrice(bookingDetails: any, priceLocalVar: any){
		if(bookingDetails?.non_taxable_fields_price){
			priceLocalVar.displayFinalAmount = priceLocalVar.displayFinalAmount + (+bookingDetails.non_taxable_fields_price);
			priceLocalVar.displayFinalAmount = priceLocalVar.displayFinalAmount > 0 ? priceLocalVar.displayFinalAmount : 0;
		}
		return priceLocalVar;
	}
	/**
	 * Calculates the spot discount for a booking.
	 * It checks if the bookingDetails object has a day_discount property and if it is not excluded from the calculation.
	 * If these conditions are satisfied, it checks the type of discount (percentage or fixed amount).
	 * If it is a percentage discount, it calculates the spotAmount by dividing the day_discount.discount by 100 and multiplying it by the displayServiceTotal property in the priceLocalVar object.
	 * It then updates the displaySpotDiscount property with the calculated value and subtracts it from the displayServiceTotal property in the priceLocalVar object.
	 * Similarly, it calculates the spotDiscountedValueForProvider for the serviceTotalForProvider property in the priceLocalVar object and updates it accordingly.
	 * If the discount type is not percentage, it directly updates the displaySpotDiscount property with the day_discount.discount value and subtracts it from the displayServiceTotal property in the priceLocalVar object.
	 * Finally, it checks if the updated displaySpotDiscount value is greater than the serviceTotalForProvider and sets it to 0 if it is.
	 * The function then returns the updated priceLocalVar object.
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @returns
	 */
	public calcSpotDiscount(bookingDetails: any, priceLocalVar: any){
		// Code for spot discount.
		priceLocalVar.displaySpotDiscount = 0;
		if(bookingDetails.day_discount && bookingDetails.day_discount.discount && !bookingDetails.exclude_day_discount){
			if(bookingDetails.day_discount.discount_type == 'percentage'){
				let spotAmount = +(bookingDetails.day_discount.discount);
				priceLocalVar.displaySpotDiscount = this.util.roundToTwo((spotAmount/100)*priceLocalVar.displayServiceTotal);
				priceLocalVar.displayServiceTotal = this.util.roundToTwo((+priceLocalVar.displayServiceTotal) - (+priceLocalVar.displaySpotDiscount));
				// spot discount apply on serviceTotalForProvider
				let spotDiscountedValueForProvider = this.util.roundToTwo((spotAmount/100)*priceLocalVar.serviceTotalForProvider);
				priceLocalVar.serviceTotalForProvider = this.util.roundToTwo((+priceLocalVar.serviceTotalForProvider) - (+spotDiscountedValueForProvider));
			}else{
				priceLocalVar.displaySpotDiscount = bookingDetails.day_discount.discount;
				priceLocalVar.displayServiceTotal = (priceLocalVar.displaySpotDiscount > priceLocalVar.displayServiceTotal) ? 0 : this.util.roundToTwo((+priceLocalVar.displayServiceTotal) - (+priceLocalVar.displaySpotDiscount));
				// Frequency discount apply on serviceTotalForProvider
				priceLocalVar.serviceTotalForProvider = ((+priceLocalVar.displaySpotDiscount) > (+priceLocalVar.serviceTotalForProvider)) ? 0 : this.util.roundToTwo((+priceLocalVar.serviceTotalForProvider) - (+priceLocalVar.displaySpotDiscount));
			}
		}
		return priceLocalVar;
	}
	/**
	 * Calculates the coupon discount for provider.
	 * It first gets the value of the provider_discount from the bookingDetails object and stores it in the couponProviderDiscountValue variable.
	 * Then, it checks if the provider_discount_type is 'percentage'. If it is, it calculates the discount amount by dividing the couponProviderDiscountValue by 100 and multiplying it by the serviceTotalForProvider property in the priceLocalVar object.
	 * It updates the couponDiscount property in the prvdrPayDtls object with the calculated discount value and subtracts it from the serviceTotalForProvider property in the priceLocalVar object.
	 * If the provider_discount_type is not 'percentage', it directly updates the couponDiscount property with the couponProviderDiscountValue and subtracts it from the serviceTotalForProvider property in the priceLocalVar object.
	 * Finally, it returns the updated serviceTotalForProvider value.
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @returns
	 */
	public calcCouponDiscForPrvdr(bookingDetails: any, priceLocalVar: any, prvdrPayDtls: any){
		let couponProviderDiscountValue = this.util.calcValidAmount(bookingDetails?.coupon?.provider_discount);
		if(bookingDetails?.coupon?.provider_discount_type == 'percentage'){
			let providerDiscountValue = this.util.roundToTwo(((+couponProviderDiscountValue)/100)*(+priceLocalVar.serviceTotalForProvider));
			prvdrPayDtls.couponDiscount = (+providerDiscountValue);
			priceLocalVar.serviceTotalForProvider = this.util.roundToTwo((+priceLocalVar.serviceTotalForProvider) - (+providerDiscountValue));
		}else{
			prvdrPayDtls.couponDiscount = (+couponProviderDiscountValue);
			priceLocalVar.serviceTotalForProvider = this.util.roundToTwo((+priceLocalVar.serviceTotalForProvider) - (+couponProviderDiscountValue));
		}
		return priceLocalVar.serviceTotalForProvider;
	}
	/**
	 * Calculates the coupon discount for a booking based on the coupon details and the price of the booking.
	 * It first validates the coupon discount value using a utility function calcValidAmount().
	 * Then, it checks if the coupon discount type is 'percentage' or not.
	 * If it is, it calculates the discount amount as a percentage of the total service price. If it is not, it uses the coupon discount value directly.
	 * After calculating the coupon discount amount, it checks if the discounted amount is less than or equal to the coupon discount.
	 * If it is, it sets the display discounted amount to 0. Otherwise, it subtracts the coupon discount from the display discounted amount.
	 * Finally, it returns the updated priceLocalVar object.
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @returns
	 */
	public calcCouponDiscount(bookingDetails: any, priceLocalVar: any){
		let couponDiscountValue = this.util.calcValidAmount(bookingDetails?.coupon?.discount);
		if(bookingDetails?.coupon?.discount_type == 'percentage'){
			priceLocalVar.displayCouponDiscount = this.util.roundToTwo(((+couponDiscountValue)/100)*(+priceLocalVar.displayServiceTotal));
		}else{
			priceLocalVar.displayCouponDiscount = this.util.roundToTwo(couponDiscountValue);
		}
		priceLocalVar.displayDiscountedAmount = ((+priceLocalVar.displayDiscountedAmount) <= (+priceLocalVar.displayCouponDiscount)) ? 0 : this.util.roundToTwo((+priceLocalVar.displayDiscountedAmount) - (+priceLocalVar.displayCouponDiscount));
		return priceLocalVar;
	}
	/**
	 * Function adds a service fee to a booking if the service fee is taxable.
	 * It checks if the "is_service_fee_taxable" property in the "bookingDetails" object is set to "yes".
	 * If it is, it sets the "displayServiceFee" property of the "priceLocalVar" object to 0.
	 * If "service_fee" value exist, set "displayServiceFee" with "service_fee" and adds the "service_fee" to the "displayDiscountedAmount" property.
	 * The "roundToTwo" function is used to round the values to two decimal places.
	 * The updated "priceLocalVar" object is then returned
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @returns
	 */
	public calcTaxableServFee(bookingDetails: any, priceLocalVar: any){
		// Add service fee here if service fee is taxable.
		if(bookingDetails.is_service_fee_taxable && bookingDetails.is_service_fee_taxable == 'yes'){
			priceLocalVar.displayServiceFee = 0;
			if(bookingDetails.service_fee && !bookingDetails.exclude_service_fee){
				priceLocalVar.displayServiceFee = this.util.roundToTwo(bookingDetails.service_fee);
				priceLocalVar.displayDiscountedAmount = this.util.roundToTwo((+priceLocalVar.displayDiscountedAmount) + (+bookingDetails.service_fee));
			}
		}
		return priceLocalVar;
	}
	/**
	 * Calculates various values for a booking's price. It first rounds the "displayDiscountedAmount" property of the "priceLocalVar" object.
	 * If the booking has an "adjusted_price" property, it sets "displayFinalAmount" to the rounded value of the "adjusted_price".
	 * It then calls several functions to calculate sales tax, tips, parking, non-taxable service fees, non-taxable fields price, gift card amount, and referral discounts.
	 * Finally, it subtracts the gift card amount and referral discount from the "displayFinalAmount" property and returns the updated "priceLocalVar" object.
	 * @param priceLocalVar
	 * @param bookingDetails
	 * @returns
	 */
	public calculateTipsParkingBonus(priceLocalVar: any, bookingDetails: any){
		priceLocalVar.displayFinalAmount = this.util.roundToTwo(+priceLocalVar.displayDiscountedAmount);
		if(bookingDetails.adjust_price){
			// Set adjusted amount as final amount
			priceLocalVar.displayFinalAmount = this.util.roundToTwo(+(bookingDetails.adjusted_price));
		}
		//Add after discounted total price
		priceLocalVar = this.addAfterDiscTotalPrice(bookingDetails, priceLocalVar);

		let amountToCharge: number = +priceLocalVar.displayFinalAmount;
		let tipCalculationBase = +priceLocalVar.displayFinalAmount;
		// Calculate sales tax
		priceLocalVar = this.calcSaleTax(bookingDetails, priceLocalVar);
		// Calculate tip, parking
		priceLocalVar =  this.calcTipParking(bookingDetails, priceLocalVar, tipCalculationBase);
		// Calculate non taxable service fee
		priceLocalVar = this.calcNonTaxableServFee(bookingDetails, priceLocalVar);
		// Add non taxable fields price
		priceLocalVar = this.addNonTaxFieldsPrice(bookingDetails, priceLocalVar);
		// Set displayTotal
		priceLocalVar.displayTotal = this.util.roundToTwo(+priceLocalVar.displayFinalAmount);
		// Calculate giftcard amount
		priceLocalVar = this.calcGiftCardAmount(bookingDetails, priceLocalVar);
		// Calculate referral discount
		let referralDiscountValue = this.util.calcValidAmount(bookingDetails.referral_amount);
		priceLocalVar.displayReferralDiscount = this.util.roundToTwo(+referralDiscountValue);
		// Subtract giftcard amount and referral discount from final amount
		priceLocalVar.displayFinalAmount = this.util.roundToTwo((+priceLocalVar.displayFinalAmount) - (+priceLocalVar.displayGiftCardAmount));
		priceLocalVar.displayFinalAmount = this.util.roundToTwo((+priceLocalVar.displayFinalAmount) - (+priceLocalVar.displayReferralDiscount));
		return {
			priceLocalVar: priceLocalVar,
			amountToCharge: amountToCharge
		}
	}
	/**
	 * Calculates the sales tax for a booking.
	 * If the booking has a non-zero tax rate and is not exempt from sales tax, it calculates the sales tax based on the tax rate.
	 * If the tax type is "percentage", it calculates the tax as a percentage of the final amount.
	 * If the tax type is not "percentage", it calculates the tax as a fixed amount.
	 * It then updates the final amount by adding the sales tax. Finally, it returns the updated priceLocalVar object.
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @returns
	 */
	public calcSaleTax(bookingDetails: any, priceLocalVar: any){
		if(bookingDetails.booking_tax_rate && !bookingDetails.exempt_sales_tax){
			if(bookingDetails.booking_tax_type == 'percentage'){
				priceLocalVar.displaySaleTax = this.util.roundToTwo(((+bookingDetails.booking_tax_rate)/100)*(priceLocalVar.displayFinalAmount));
				priceLocalVar.displayFinalAmount = this.util.roundToTwo((+priceLocalVar.displayFinalAmount) + (+priceLocalVar.displaySaleTax));
			}else{
				priceLocalVar.displaySaleTax = this.util.roundToTwo(+bookingDetails.booking_tax_rate);
				priceLocalVar.displayFinalAmount = this.util.roundToTwo((+priceLocalVar.displayFinalAmount) + (+priceLocalVar.displaySaleTax));
			}
		}
		return priceLocalVar;
	}
	/**
	 * Calculates the tip and parking amounts for a booking.
	 * If the tip amount is provided and the amount type is "percentage", it calculates the tip as a percentage of the tip base.
	 * If the amount type is not "percentage", it calculates the tip as a fixed amount.
	 * It then updates the final amount by adding the tip amount.
	 * If the parking amount is provided, it updates the final amount by adding the parking amount.
	 * Finally, it returns the updated priceLocalVar object.
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @param tipCalculationBase
	 * @returns
	 */
	public calcTipParking(bookingDetails: any, priceLocalVar: any, tipCalculationBase: any){
		if(bookingDetails.tip && bookingDetails.tip.total_amount){
			if(bookingDetails.tip.amount_type  && bookingDetails.tip.amount_type == 'percentage'){
				let tipsAmount = +(bookingDetails.tip.total_amount);
				priceLocalVar.displayTipsAmount = this.util.roundToTwo((tipsAmount/100)*tipCalculationBase);
			}else{
				priceLocalVar.displayTipsAmount = this.util.roundToTwo(+(bookingDetails.tip.total_amount));
			}
			priceLocalVar.displayFinalAmount = this.util.roundToTwo(priceLocalVar.displayFinalAmount+(+priceLocalVar.displayTipsAmount));
		}
		if(bookingDetails && bookingDetails.parking && bookingDetails.parking.total_amount){
			priceLocalVar.displayParkingAmount = this.util.roundToTwo(+bookingDetails.parking.total_amount);
			priceLocalVar.displayFinalAmount = this.util.roundToTwo(priceLocalVar.displayFinalAmount+(+bookingDetails.parking.total_amount));
		}
		return priceLocalVar;
	}
	/**
	 * Calculates the non-taxable service fee for a booking.
	 * If the service fee is not taxable, it sets the displayServiceFee to 0.
	 * Then, if a service fee is provided and not excluded, it adds the service fee to the displayFinalAmount.
	 * Finally, it returns the updated priceLocalVar object.
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @returns
	 */
	public calcNonTaxableServFee(bookingDetails: any, priceLocalVar: any){
		// Add service fee here if service fee is not taxable.
		if(!bookingDetails.is_service_fee_taxable|| (bookingDetails.is_service_fee_taxable == 'no')){
			priceLocalVar.displayServiceFee = 0;
			if(bookingDetails.service_fee && !bookingDetails.exclude_service_fee){
				priceLocalVar.displayServiceFee = this.util.roundToTwo(bookingDetails.service_fee);
				priceLocalVar.displayFinalAmount = this.util.roundToTwo((+priceLocalVar.displayFinalAmount) + (+bookingDetails.service_fee));
			}
		}
		return priceLocalVar;
	}
	/**
	 * Calculates the gift card amount to be applied to a booking.
	 * It takes in two parameters: bookingDetails for the details of the booking and priceLocalVar for the pricing details.
	 * The code first checks if there is a gift card and if it has a discount value.
	 * If there is a discount value, it calculates the valid amount of the discount using a utility function called calcValidAmount.
	 * The code then sets the displayGiftCardAmount of priceLocalVar to 0.
	 * If there is a gift card discount value, it compares it with the displayFinalAmount of priceLocalVar.
	 * If the gift card discount value is greater, it assigns the rounded displayFinalAmount to displayGiftCardAmount.
	 * Otherwise, it assigns the rounded gift card discount value to displayGiftCardAmount.
	 * Finally, it returns the modified priceLocalVar object.
	 * @param bookingDetails
	 * @param priceLocalVar
	 * @returns
	 */
	public calcGiftCardAmount(bookingDetails: any, priceLocalVar: any){
		let giftCardDiscountValue = 0;
		if(bookingDetails.gift_card && bookingDetails.gift_card.discount){
			giftCardDiscountValue = this.util.calcValidAmount(bookingDetails.gift_card.discount);
		}
		priceLocalVar.displayGiftCardAmount = 0;
		if(giftCardDiscountValue > 0){
			priceLocalVar.displayGiftCardAmount = ((+giftCardDiscountValue) > (+priceLocalVar.displayFinalAmount)) ? this.util.roundToTwo(+priceLocalVar.displayFinalAmount) : this.util.roundToTwo(+giftCardDiscountValue);
		}
		return priceLocalVar;
	}

	/**
	 * Updates and returns the display service price based on various conditions.
	 * @param {BkngPrice} priceLocalVar - Object containing local pricing variables.
	 * @param {any} bkng - Object containing detailed booking information.
	 * @param {any} selectedService - Object containing details about the selected service.
	 * @returns {number} - The updated display service price.
	 * The function follows these steps to determine the updated display service price:
	 * 1. If the local variable `displayServicePrice` is greater than 0:
	 *		- Set `updatedDisplayServicePrice` to `displayServicePrice`.
	 *		- Subtract `includeInFreqFieldsPrice` (extracted from `priceDetails`) from `updatedDisplayServicePrice`.
	 * 2. If the selected service has override pricing enabled:
	 *		- Set `updatedDisplayServicePrice` to the override pricing value from `selectedService`.
	 * 3. If `before_priceable_fields_price` exists in `priceDetails` and the service is not hourly:
	 *		- Set `updatedDisplayServicePrice` to `before_priceable_fields_price`.
	 * 4. If `override_service_total` exists in `priceDetails`:
	 *		- Set `updatedDisplayServicePrice` to the overridden service total value.
	 * 5. Ensure `updatedDisplayServicePrice` is not negative.
	 * The function finally returns the `updatedDisplayServicePrice` after applying the above logic.
	 * ! Duplicate function, this same function in PriceTooltipComponent component(updateServPriceAccPriceableFields), Tarn sir please common this function
	*/
	public updateServPriceAccPriceableFields(priceLocalVar: BkngPrice, bkng: any, selectedService: any): number {
		let updatedDisplayServicePrice: number = 0;
		if (priceLocalVar.displayServicePrice > 0) {
			updatedDisplayServicePrice = priceLocalVar.displayServicePrice;
			let includeInFreqFieldsPrice: number = +(bkng?.include_in_freq_fields_price || 0);
			updatedDisplayServicePrice -= includeInFreqFieldsPrice;
		} else if (selectedService?.is_override_pricing === 'yes') {
			updatedDisplayServicePrice = selectedService.override_pricing;
		} else if (bkng?.before_priceable_fields_price && selectedService?.is_hourly_service !== 'yes') {
			updatedDisplayServicePrice = +bkng.before_priceable_fields_price;
		}
		if (bkng?.override_service_total) {
			updatedDisplayServicePrice = bkng.overridden_service_total;
		}
		if (updatedDisplayServicePrice < 0) {
			updatedDisplayServicePrice = 0;
		}
		return updatedDisplayServicePrice;
	}
}
