import { Component, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../Services';
// Inheritance components
import { HourlyComponent } from '../../../Global/BookingForm';

@Component({
	selector: 'bk-short-frm-hourly',
	templateUrl: './ShortFrmHourly.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ShortFrmHourlyComponent extends HourlyComponent {
	@Input() variationId: string = '';
	@Input() sectionIds: any;
}