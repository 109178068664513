import { Component, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../../Services';
// Inheritance components
import { ZipcodeComponent } from '../../../../Global/BookingForm';

@Component({
	selector: 'bk-short-from-zipcode',
	templateUrl: './ShortFrmZipcode.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ShortFrmZipcodeComponent extends ZipcodeComponent {

	@Input() variationId: string = '';
	@Input() sectionIds: any;
}