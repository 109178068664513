<ng-container *ngIf="job && defaultJob">
	<div class="container">
		<!-- Title -->
		<div class="py-35 py-md-80" *ngIf="defaultJob?.title_desc" [innerHTML]="defaultJob?.title_desc | safeHtml">
		</div>
	</div>
	<!-- Banner -->
	<div class="tjs-banner tjs-py-140 tjs-bg-style position-relative" [ngStyle]="defaultJob?.banner_status && defaultJob?.banner_img_url && {'background-image' : 'url('+defaultJob?.banner_img_url+')'}" *ngIf="defaultJob?.first_icon_status || defaultJob?.second_icon_status || defaultJob?.third_icon_status">
		<div class="opacity-bg"></div>
		<!-- Banner elements -->
		<div class="container">
			<div class="tjs-banner__inner--rounded">
				<div class="row">
					<div class="col-12 col-md-4 col-lg-4 px-lg-25 px-10 mb-30 mb-md-0 tjs-mb-last-0" *ngIf="defaultJob?.first_icon_status">
						<div class="tjs-banner__rounded-item text-center d-flex justify-content-center align-items-center mx-auto rounded-circle bg-black-1">
							<div>
								<img loading="auto" *ngIf="defaultJob?.first_icon_img_url" [src]="defaultJob?.first_icon_img_url" class="img-fluid mb-15" alt="Job clock" width="120" height="120" />
								<p *ngIf="defaultJob?.first_icon_label" class="tjs-banner__rounded-desc text-white h5 fw-bold mb-0" translate>{{defaultJob?.first_icon_label}}</p>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-4 col-lg-4 px-lg-25 px-10 mb-30 mb-md-0 tjs-mb-last-0" *ngIf="defaultJob?.second_icon_status">
						<div class="tjs-banner__rounded-item text-center d-flex justify-content-center align-items-center mx-auto rounded-circle bg-black-1">
							<div>
								<img loading="auto" *ngIf="defaultJob?.second_icon_img_url" [src]="defaultJob?.second_icon_img_url" class="img-fluid mb-15" alt="Job cash" width="120" height="120" />
								<p *ngIf="defaultJob?.second_icon_label" class="tjs-banner__rounded-desc text-white h5 fw-bold mb-0" translate>{{defaultJob?.second_icon_label}}</p>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-4 col-lg-4 px-lg-25 px-10 mb-30 mb-md-0 tjs-mb-last-0" *ngIf="defaultJob?.third_icon_status">
						<div class="tjs-banner__rounded-item text-center d-flex justify-content-center align-items-center mx-auto rounded-circle bg-black-1">
							<div>
								<img loading="auto" *ngIf="defaultJob?.third_icon_img_url" [src]="defaultJob?.third_icon_img_url" class="img-fluid mb-15" alt="Job heart" width="120" height="120" />
								<p *ngIf="defaultJob?.third_icon_label" class="tjs-banner__rounded-desc text-white h5 fw-bold mb-0" translate>{{defaultJob?.third_icon_label}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Description -->
	<div class="container">
		<div class="py-35 py-md-50 editor-desc-wrap">
			<div class="job-desc-reset" *ngIf="job?.description" [innerHTML]="job?.description | safeHtml"></div>
			<bk-navigate [innerHTML]="initServ?.appStr?.text?.applyNow" (callback)="nextPage()" customClass="btn btn-primary border-transparent" essentialClass="mt-30 text-capitalize"></bk-navigate>
		</div>
	</div>
</ng-container>