import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';
// Services
import { UtilServ } from '../Services';
declare let google: any;

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: "[bk-google-places-autocomplete]"
})
export class GooglePlacesDirective implements AfterViewInit {

	@Output() addressChange: EventEmitter<any> = new EventEmitter();
	// Variable
	private autocomplete: any;
	eventListener: any;
	public place: any;
	public options: any = {
		types: ['address'],
		fields: ["address_components", "geometry"]
	}

	constructor(private el: ElementRef, private utilServ: UtilServ) {
		// Load the google api script
		if(this.utilServ){
			this.utilServ.loadGoogleApiScript();
		}
	}

	ngAfterViewInit(): void {
		this.initialize();
		// this.initAddr();
	}
	/**
	 * Initializes the google api service
	 */
	public initialize(): void {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let _this = this;
		this.eventListener = null;
		this.autocomplete =false;
		//initialize after 4 chars are typed
		this.el.nativeElement.addEventListener('keyup', (event: any) => {
			if(event.target.value.length >= 4){
				if (_this.autocomplete == false) {
					if (typeof google !== 'undefined') {
						_this.autocomplete = new google.maps.places.Autocomplete(_this.el.nativeElement, _this.options);
					}else {
						console.error('Google Maps API is not available.');
					}
					if(_this.autocomplete && !_this.autocomplete.addListener != null) {
						_this.eventListener = _this.autocomplete.addListener('place_changed', () => {
							_this.handleChangeEvent();
						});
					}
				}
			} else {
				_this.stopEvent();
			}

			if(!event.key) {
				return;
			}
			let key = event.key.toLowerCase();
			if (key == 'enter' && event.target === this.el.nativeElement) {
				event.preventDefault();
				event.stopPropagation();
			}
		});
	}
	/**
	 * Handle the change event and emit the place value
	 * Null the autocomplete and eventListeners variables
	 */
	public handleChangeEvent(): void {
		if(this.autocomplete){
			this.place = this.autocomplete.getPlace();
			if(this.place) {
				this.addressChange.emit(this.place);
			}
			this.stopEvent();
		}
	}
	/**
	 * Stop the event listeners
	 */
	private stopEvent(): void {
		if(this.autocomplete){
			const elements = document.getElementsByClassName('pac-container');
			if((Object.keys(elements)).length > 0){
				while(elements.length > 0){
					elements[0].parentNode?.removeChild(elements[0]);
				}
			}
			if (typeof google !== 'undefined') {
				new google.maps.event.clearListeners(this.el.nativeElement);
			}else {
				console.error('Google Maps API is not available.');
			}
			this.eventListener = null;
			this.autocomplete = false;
		}
	}
}
