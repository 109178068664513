import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, UtilServ, LeadsServ } from '../../Services';
import { AuthServ } from '../../Core/_services';
// Constants
import { EMAIL_REG_EXP, INPUT_TEXT_LIMIT, PASSWORD_MIN_LENGTH, TEXT_REG_EXP } from '../../Constants';
//Custom validator
import { CustomValidators } from '../../Global/GlobalDefault';
@Component({
	selector: 'bk-signup',
	templateUrl: './Signup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy, CustomValidators]
})
export class SignupComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	socialKeys: any = this.initServ.appSocialKeys; // App social keys
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		sub_title: null,
		media: null,
		social: null,
		form: null
	}
	signupForm!: FormGroup; // signup form
	admnStngs: any;
	phoneMask: any = this.initServ.selectedMask; // App phone number masking
	type: string = 'customer';
	passwordVisible: boolean = false;
	confirmPasswordVisible: boolean = false;
	isPlanPermission!: boolean;

	// convenience getter for easy access to form fields
	get f(): { [key: string]: AbstractControl } {
		return this.signupForm.controls;
	}

	// eslint-disable-next-line max-params
	constructor(private frmBldr: FormBuilder, public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, private customValidators: CustomValidators, private authServ: AuthServ, public utilServ: UtilServ, public toastr: ToastrService, private leadsServ: LeadsServ) {
		this.isPlanPermission = this.initServ.appPlansPermission('Provider Signup');
		this.admnStngs = this.initServ.appAdmnStngs; // Admin settings
		if (!this.utilServ.embedStatus && !this.initServ.theme) {
			this.authServ.removeCurrentUser()
		}
	}

	ngOnInit(): void {
		// Embed status: true and theme not exist remove the current login user local storage
		if (this.utilServ.embedStatus && !this.initServ.theme) {
			// this.authServ.removeCurrentUser();
			this.utilServ.id = null;
		}
		// Initialize the signup form
		this.initSignupForm();
		// Build section data
		if (this.secId && this.rcServ.pageData) {
			this.pageSett = this.rcServ.pageData.section_settings;
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
	}

	/**
	 * Build the signup form with its validations
	 */
	private initSignupForm(): void {
		this.signupForm = this.frmBldr.group({
			first_name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP), Validators.maxLength(INPUT_TEXT_LIMIT)]],
			last_name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP), Validators.maxLength(INPUT_TEXT_LIMIT)]],
			email_id: [null, {
				validators: [Validators.required, Validators.pattern(EMAIL_REG_EXP), Validators.maxLength(INPUT_TEXT_LIMIT)],
				asyncValidators: [this.customValidators.existingEmail()],
				updateOn: 'blur'
			}],
			password: ['', [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH),
				CustomValidators.strongPasswordValidator()
			]],
			confirm_password: ['', [Validators.required]],
			sign_up_form_timestamp: [''],
			ip: [this.initServ.ipAddress],
			user_agent: [navigator.userAgent],
			sign_up_role: ['customer']
		}, {
			validators: [CustomValidators.matchPassword('password', 'confirm_password')]
		});
	}

	/**
	 * Handles action on toggle of radio buttons
	 * @param type - role type
	 */
	public signUpRoleChange(type: string): void {
		// Reset form and reinitialize with default fields
		this.signupForm.reset();
		this.initSignupForm();
		if (type === 'provider') {
			this.signupForm.addControl('phone_number', new FormControl(null, [Validators.required])); // Add phone_number control for provider
		} else{
			this.signupForm.removeControl('phone_number'); // Remove phone_number control if role is customer
		}
		this.signupForm.get('sign_up_role')?.setValue(type); // Set the selected role
	}

	/**
	 * Submits the signup form after validating required fields and formatting.
	 * - Checks if a hidden 'phantom' field is empty as an anti-bot measure.
	 * - Validates the form, converts email to lowercase, and generates an authorization key.
	 * - Triggers focus-out validation for form details, builds payload data,
	 *   and initiates signup based on the user's role.
	 * - If form validation fails, handles errors by marking fields and displaying a message.
	 * @returns {any} Returns false if phantom field validation fails, otherwise proceeds.
	 */
	public submitForm(): any {
		// Check if phantom field is empty
		if (!this.utilServ.validatePhantomField(this.signupForm.controls['sign_up_form_timestamp'].value, 'sign_up_form_timestamp')) {
			return false;
		}

		// Validate form and proceed
		if (this.signupForm.valid) {
			let emailControl = this.signupForm.controls['email_id'];
			emailControl.setValue(emailControl.value.toLowerCase());
			let authKey = this.generateAuthKey();

			this.detailsFocusOut();
			let formData = this.createPayload();

			// Handle signup based on role
			this.signupBasedOnRole(formData, authKey);
		} else {
			this.handleFormError();
			this.toastr.error('Please fill the required fields marked in red.');
		}
	}

	/**
	 * Generates an authorization key by combining the domain name with the user's email.
	 * - Fetches domain name from merchant settings, if available.
	 * - Concatenates domain name with the lowercase email to create the key.
	 * @returns {string} Returns the authorization key as a string.
	 */
	private generateAuthKey(): string {
		let domainName = this.admnStngs?.merchant_settings?.store?.domain_name;
		return domainName ? domainName + this.signupForm.controls['email_id'].value : '';
	}

	/**
	 * Creates a payload object for form submission by cloning form data and excluding specific fields.
	 * - Removes 'sign_up_form_timestamp' and 'sign_up_role' to avoid sending them to the backend.
	 * @returns {any} Returns the modified form data object.
	 */
	private createPayload(): any {
		let formData = { ...this.signupForm.value };
		delete formData['sign_up_form_timestamp'];
		delete formData['sign_up_role'];
		return formData;
	}

	/**
	 * Initiates signup process based on the selected role ('customer' or 'provider').
	 * - Calls the customer signup API if role is 'customer'.
	 * - Calls an API for unverified providers if role is not 'customer'.
	 * @param {any} formData - The processed signup form data.
	 * @param {string} authKey - The authorization key generated for the request.
	 */
	private signupBasedOnRole(formData: any, authKey: string): void {
		if (this.signupForm.controls['sign_up_role'].value === 'customer') {
			this.authServ.signup(formData, authKey, '', true);
		} else {
			this.authServ.unverifiedPrvdrApi(formData, authKey);
		}
	}

	/**
	 * Handles form validation errors by marking all controls as 'touched'.
	 * - Ensures that fields display validation feedback to the user.
	 */
	private handleFormError(): void {
		for (let control in this.signupForm.controls) {
			this.signupForm.controls[control].markAsTouched();
		}
	}

	/**
	 * Handles the focus out event for the details section.
	 * This function checks the validation status of the email_id form control.
	 * If the email is valid and meets all the specified criteria, it adds contact information to a lead.
	 */
	public detailsFocusOut(interval: number | null = null): void {
		if (this.isEmailValid()) {
			this.leadsServ.addContactToLead(this.signupForm.value, { type: 'sign_up', slug: 'sign-up' }, interval);
		}
	}

	/**
	 * Checks the validity of the 'email_id' field by verifying specific validation errors.
	 * - Returns true if there are no 'required', 'pattern', 'minlength', 'matching',
	 * or 'emailExists' errors.
	 * @returns {boolean} Returns true if the email field is valid, otherwise false.
	 */
	private isEmailValid(): boolean {
		let { required, pattern, minlength, matching, emailExists } = this.f['email_id']?.errors || {};
		return !(required || pattern || minlength || matching || emailExists);
	}

	/**
	 * Toggles the visibility of the password input field between plain text and hidden.
	 * - Updates the 'passwordVisible' flag to reflect current visibility state.
	 */
	public togglePasswordVisibility(): void {
		this.passwordVisible = !this.passwordVisible;
	}

	/**
	 * Toggles the visibility of the confirm password input field between plain text and hidden.
	 * - Updates the 'confirmPasswordVisible' flag to reflect current visibility state.
	 */
	public toggleConfirmPasswordVisibility(): void {
		this.confirmPasswordVisible = !this.confirmPasswordVisible;
	}
}
