import { ChangeDetectorRef, Component, OnInit, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
// External lib
import { takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
// Services
import { ApiServ, InitServ, LoaderServ, NgOnDestroy, PaymentGatewayPayload, PaymentGatewayServ, RenderComponentServ, SectionServ, UtilServ } from '../../../Services';
import { InvServ } from '../Invoice.service';
// Global Components
import { PaymentGatewayComponent } from 'src/app/Global/GlobalDefault';
// Interface
import { APIRes, BillingAddress, BillingCard, BillingCardApiRes, User } from 'src/app/Interfaces';
import { Invoice } from 'src/app/Interfaces/Invoice';
interface ThreeDSChargedAmt extends APIRes{
	data?:any;
}
interface PaymentGatewayData {amount: number; uid?: number; base_location_id: number; payment_method: string; pay_with_cc: string | null; payment: boolean; inv_num?: string; billing_address: BillingAddress; is_captured_amount: boolean; captured_amount: object | null; inv_id?: string | number; booking_ids?: number[]}

@Component({
	selector: 'bk-inv-payment',
	templateUrl: './InvPayment.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class InvPaymentComponent implements OnInit {

	@ViewChild(PaymentGatewayComponent) paymentGatewayChild: PaymentGatewayComponent | undefined; //Payment gateway component
	secId?: string;
	section: any = {location:null, payment_info: null, sidebar: null}
	pageSett: any;
	invPaymentForm!: FormGroup;
	cards: BillingCard[] = [];
	locations: any = [];
	stripeIds: string[] = [];
	loaderId: string = 'inv-payment-loader';
	cardSecLoaderId: string = 'inv-card-sec-loader';
	currentUser: User | null = null;
	locationStatus: boolean = this.initServ.locationsStatus;
	stripeLocId: number | null = null;
	stripeBaseId?: number[];
	buildPaymentForm: boolean = false;
	formLoaderId: string = 'card-loader';
	invData: Invoice | null = null;
	chargedAmt: any;
	invId?: string | null = null;
	paymentSuccessMsg?: string | null = null;
	accessToken: string | null = '';

	// eslint-disable-next-line max-params
	constructor (public initServ: InitServ, public utilServ: UtilServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, private loader: LoaderServ, public rcServ: RenderComponentServ, public secServ: SectionServ,  public cDRef: ChangeDetectorRef, public toastr: ToastrService, private location: Location, public router: Router, private paymentServ: PaymentGatewayServ, private actRoute: ActivatedRoute, public invServ: InvServ){
		this.accessToken = (this.actRoute.snapshot.params && this.actRoute.snapshot.params['user_access_token']) ? this.actRoute.snapshot.params['user_access_token'] : null;
		this.invId = this.actRoute.snapshot.queryParamMap.get('token');
	}

	ngOnInit(): void {
		this.buildForm();
	}

	/**
	 * Builds invoice data for the booking process.
	 * This function orchestrates the steps required to construct
	 * the invoice, including showing a loader, fetching booking
	 * invoice amount, building secondary data, and resetting the form.
	 * If the payment gateway is not 'stripe', it also fetches user cards.
	 */
	private buildInv(): void {
		this.loader.show(this.loaderId);
		this.fetchBkngInvAmt();
		this.buildSecData();
		this.resetForm();
		if(this.initServ.paymentGateway != 'stripe'){
			this.fetchUserCards();
		}
	}

	/**
	 * Constructs the payment form based on navigation data.
	 * This function retrieves data from navigation state and builds
	 * the payment form accordingly. If the payment gateway is 'stripe',
	 * it loads unique locations. If invoice data is available, it builds
	 * the invoice payment form.
	 */
	private buildForm(): void {
		// Get the data from navigation
		let states = this.location.getState() as { data: Invoice };
		if(states){
			this.invData = states.data;
			if(this.initServ.paymentGateway == 'stripe'){
				this.loadUniqueLocs();
			}
			if(this.invData){
				this.invId = this.invData?.inv_id;
				this.invPaymentForm = this.invServ.buildInvPaymentForm();
				setTimeout(() => {
					this.buildInv();
					this.invPaymentForm.addControl('inv_payment_timestamp', new FormControl(''));
				}, 0);
			} else {
				this.backToInv();
			}
		}
	}

	/**
	 * Fetches booking invoice amount if booking ids available.
	 */
	public fetchBkngInvAmt(): void {
		if(this.invData?.inv_type == 'bookings' && this.utilServ.checkArrLength(this.invData?.booking_ids)){
			this.invServ.fetchBkngInvAmt(null, this.invData);
		}
	}

	/**
	 * Build page data
	 */
	private buildSecData(): void{
		if(this.secId && this.rcServ.pageData){
			this.pageSett = this.rcServ.pageData.section_settings;
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
	}

	/**
	 * loads unique locations and their corresponding location data.
	 */
	private async loadUniqueLocs(): Promise<void>{
		let data: any = await this.utilServ.uniqueLocations();
		this.stripeIds = data?.stripeIds;
		this.locations = data.locations;
		this.stripeBaseId = data.stripeBaseId;
	}

	/**
	 * Reset form data
	 */
	private resetForm(): void{
		this.loader.show(this.formLoaderId);
		this.buildPaymentForm = false;
		if(this.initServ.paymentGateway == 'stripe'){
			this.changeStripeLoc();
		}
	}

	/**
	 * Resets default values and updates the payment form.
	 */
	private resetPayFormDefault(): void{
		this.buildPaymentForm = true;
		this.loader.hide(this.formLoaderId);
		this.cDRef.detectChanges();
	}

	/**
	 * Handles the process of changing the Stripe location.
	 * @param isChange - A boolean indicating if the location is being changed.
	 */
	public changeStripeLoc(isChange: boolean = false){
		this.stripeLocId = null;
		// If a change is indicated, set buildPaymentForm to false
		if(isChange){
			this.buildPaymentForm = false;
		}
		this.setLocControlVal(isChange);
		this.stripeLocId = +this.invPaymentForm.controls['location_id'].value;
		// If the payment gateway is Stripe, fetch the user's saved cards
		if(this.initServ.paymentGateway == 'stripe'){
			this.fetchUserCards();
		}
		// Reset the payment form to its default state after a short delay
		setTimeout(() => this.resetPayFormDefault(), 100);
	}

	/**
	 * Sets the location control value based on certain conditions.
	 * @param isChanging Indicates whether the location is being changed.
	 */
	private setLocControlVal(isChanging: boolean): void {
		if (this.locationStatus) {
			// If stripeIds == 1 so set the stripeBaseLocIds under the location_id.
			// Else set the locations array first value, only work if call this function under the initi function.
			if (this.stripeIds && this.stripeIds.length === 1) {
				this.invPaymentForm.controls['location_id'].setValue(this.stripeBaseId?.[0]);
			} else if (!isChanging && this.utilServ.checkArrLength(this.locations)) {
				this.invPaymentForm.controls['location_id'].setValue(this.locations[0].location_id);
			}
		} else {
			// If location status == 0 , set the location_id 0;
			this.invPaymentForm.controls['location_id'].setValue(0);
		}
	}

	/**
	 * Fetch the user cards based on uid, location id
	 */
	private fetchUserCards(): void{
		this.loader.show(this.cardSecLoaderId);
		let baseLocId: string | number = this.invPaymentForm.controls['location_id'].value;
		baseLocId = baseLocId ? baseLocId : 0;
		// If stripe ids are different on different locations isSame 0 other wise 1
		let isSame: string = (this.stripeIds && this.stripeIds.length > 1) ? '0' : '1';
		this.apiServ.callApiWithPathQueryVars('GET', 'PublicUserCards', [this.invData?.uid], {location_id: baseLocId, isSame}).pipe(takeUntil(this.destroy)).subscribe((res: BillingCardApiRes) => this.handleCardsApiRes(res));
	}

	/**
	 * Handles the response from the cards API.
	 * @param res API response
	 */
	private handleCardsApiRes(res: BillingCardApiRes): void{
		// Resetting the 'cards' variable to null.
		this.cards = [];
		if (this.apiServ.checkAPIRes(res) && res?.data) {
			this.cards = res.data;
		}
		// Perform actions related to payment method and card type change.
		this.setPaymentMethod();
		this.cardTypeChange();
		this.loader.hide(this.loaderId);
		this.loader.hide(this.cardSecLoaderId);
	}

	/**
	 * Set the payment method in form
	 */
	private setPaymentMethod(): void {
		let paymentMethod: string = (this.cards && this.cards.length > 0) ? 'existing_credit_card' : 'new_credit_card';
		this.invPaymentForm.controls['payment_method'].setValue(paymentMethod);
	}

	/**
	 * Handles changes in card types within the payment form.
	 */
	public cardTypeChange(): void {
		// Check if the selected payment method is 'existing_credit_card'.
		if(this.invPaymentForm.controls['payment_method'].value == 'existing_credit_card') {
			// Retrieve the default card and set it for payment.
			let billingCard: BillingCard = this.utilServ.getDefaultCardId(this.cards);
			this.invPaymentForm.controls['pay_with_cc'].setValue(billingCard?.card_id);
			this.invPaymentForm.controls['card_last4_digits'].setValue(billingCard?.card_last4_digits);
			// If the payment form contains a 'billing_address' control, remove it for existing card cases.
			if(this.invPaymentForm.controls['billing_address']){
				this.invPaymentForm.removeControl('billing_address');
			}
		} else {
			// If the payment method is not 'existing_credit_card', reset 'pay_with_cc' to empty and reset payment form default after a delay.
			this.invPaymentForm.controls['pay_with_cc'].setValue('');
			this.invPaymentForm.controls['card_last4_digits'].setValue('');
			setTimeout(() => this.resetPayFormDefault(), 100);
		}
	}

	// Submit form functions

	/**
	 * Submits the payment form.
	 * @returns {void | boolean} Returns false if validation of the phantom field fails, otherwise void.
	 */
	public submitForm(): void | boolean{
		// Validate the phantom field 'inv_payment_timestamp'
		if (!this.utilServ.validatePhantomField(this.invPaymentForm.controls['inv_payment_timestamp'].value, 'inv_payment_timestamp')) {
			return false;
		}
		if(this.invPaymentForm.valid) {
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId);
			// Check if the payment process involves 3D Secure authentication.
			if(this.invServ.isThreeDS()){
				this.fetchInvAmt();
			} else {
				// Submit the payment form values.
				this.submitFormVal();
			}
		} else {
			// Handle form validation errors
			this.handleFormErrors();
		}
	}

	/**
	 * Fetch the calculated amount with tip and advance amount to charge invoice in case Of 3DS
	 */
	private fetchInvAmt(): void {
		if(this.invData){
			let data: Invoice = this.invServ.invAmtPayload(this.invData);
			if(this.invData?.inv_type == 'custom'){
				this.apiServ.callApi('POST', 'CustomInvChargeAmt', data).pipe(takeUntil(this.destroy)).subscribe((res: ThreeDSChargedAmt) => this.handleInvAmtRes(res));
			} else {
				this.apiServ.callApi('POST', 'BkngInvTotal', data).pipe(takeUntil(this.destroy)).subscribe((res: ThreeDSChargedAmt) => this.handleInvAmtRes(res));
			}
		}
	}

	/**
	 * Handles the response for the invoice amount.
	 * @param res - The response containing the 3D Secure charged amount.
	 */
	private handleInvAmtRes(res: ThreeDSChargedAmt): void {
		if(this.apiServ.checkAPIRes(res) && res?.data){
			// Charged amount and captured_amount
			this.chargedAmt = res?.data;
			// Submit the payment form values
			this.submitFormVal();
		} else {
			this.toastr.error(res.message);
			this.loader.hide(this.loaderId);
		}
	}

	/**
	 * Submit the form values base on new card and existing card
	 */
	private submitFormVal(): void {
		// Generate the payload for the invoice charge
		let payload: Invoice = this.invServ.invChargePayload(this.invData, this.invPaymentForm.value,this.chargedAmt);
		if(this.invServ.isThreeDS() || this.invPaymentForm.controls['payment_method'].value == 'new_credit_card') {
			this.generateToken(payload);
		} else {
			// Add additional payment details to the payload and charge the invoice
			payload['pay_with_cc'] = this.invPaymentForm.controls['pay_with_cc'].value;
			payload['card_last4_digits'] = this.invPaymentForm.controls['card_last4_digits'].value;
			this.chargeInv(payload);
		}
	}

	/**
	 * Checks if the charged amount has been captured, considering
	 * whether 3D Secure is enabled and if there is a captured amount.
	 * Returns true if the conditions are met, otherwise false.
	 */
	private isCapturedAmt(): boolean {
		return (this.invServ.isThreeDS() && this.chargedAmt?.captured_amount);
	}

	/**
	 * Prepares the payment gateway data object.
	 * @returns {PaymentGatewayData} The data required for processing the payment.
	 */
	private paymentGatewayData(): PaymentGatewayData {
		// is_captured_amount and captured_amount use only invoice payment when some bookings with card hold and setting is captured.
		return {
			amount: (this.chargedAmt?.amount) ? this.chargedAmt.amount : this.invServ.calInvRemainingTotal(this.invData),
			uid: this.invData?.uid,
			base_location_id: +this.invPaymentForm.controls['location_id'].value,
			payment_method: this.invPaymentForm.controls['payment_method'].value,
			pay_with_cc: this.invPaymentForm.controls['pay_with_cc'].value,
			payment: true,
			inv_num: this.invData?.inv_num,
			billing_address: this.invPaymentForm.controls['billing_address'] && this.invPaymentForm.controls['billing_address'].value,
			is_captured_amount: (this.isCapturedAmt()) ? true : false,
			captured_amount: (this.isCapturedAmt()) ? this.chargedAmt?.captured_amount : 0,
			inv_id: this.invData?.inv_type == 'custom' ? this.invData?.inv_id : 0,
			booking_ids: this.invData?.booking_ids
		}
	}

	/**
	 * Generates a payment token using the payment gateway and processes the payment.
	 * @param payload - The payload containing payment information.
	 */
	public async generateToken(payload: Invoice): Promise<void> {
		let paymentGatewayData: PaymentGatewayData = this.paymentGatewayData();
		// Calls the payment gateway child component's getPaymentToken method to generate the token.
		let paymentGatewayToken: PaymentGatewayPayload = await this.paymentServ?.generatePaymentGatewayToken(paymentGatewayData, this.paymentGatewayChild);
		if(paymentGatewayToken?.token || paymentGatewayToken?.dataValue){
			payload = this.paymentServ.assignToken(payload, paymentGatewayToken);
			// If using an existing credit card, add the last 4 digits to the payload
			if(this.invPaymentForm.controls['payment_method'].value == 'existing_credit_card'){
				payload['card_last4_digits'] = this.invPaymentForm.controls['card_last4_digits'].value;
			}
			// Proceed to charge the invoice with the updated payload
			this.chargeInv(payload);
		} else {
			// Handle cases where the card is held for capture
			this.handleCapturedCardHold(payload, paymentGatewayToken);
		}
	}

	/**
	 * Handles the captured card hold process.
	 * If 3D Secure is enabled and there is a captured amount,
	 * it updates the payload with the captured amount, sets
	 * charged_amount to 0, adds decline reason from the payment
	 * gateway token, and proceeds to charge the invoice. Otherwise,
	 * it hides the loader.
	 * @param payload The payload containing transaction details.
	 * @param paymentGatewayToken The token containing payment gateway data.
	 */
	private handleCapturedCardHold(payload: Invoice, paymentGatewayToken: PaymentGatewayPayload): void {
		if(this.invServ.isThreeDS() && this.chargedAmt?.captured_amount){
			payload['charged_amount'] = 0;
			payload['captured_amount'] = this.chargedAmt?.captured_amount;
			payload['decline_reason'] = paymentGatewayToken?.decline_reason;
			this.chargeInv(payload);
		} else {
			this.loader.hide(this.loaderId);
		}
	}

	/**
	 * Charges the invoice with the given payload.
	 * @param payload - The payload containing payment and invoice information.
	 */
	private chargeInv(payload: Invoice): void {
		this.loader.show(this.loaderId);
		if(this.invData?.inv_type == 'custom'){
			// Add provider pay IDs and providers pay to the payload if they exist
			if(this.utilServ.checkArrLength(this.invData?.pay_provider_ids)){
				payload['pay_provider_ids'] = this.invData.pay_provider_ids;
			}
			if(this.invData?.providers_pay){
				payload['providers_pay'] = this.invData.providers_pay;
			}
			this.apiServ.callApi('POST', 'ChargeCustomInv', payload).pipe(takeUntil(this.destroy)).subscribe((res: APIRes) => this.handleChargeInvRes(res));
		} else {
			this.apiServ.callApi('POST', 'BkngInvCharge', payload).pipe(takeUntil(this.destroy)).subscribe((res: APIRes) => this.handleChargeInvRes(res));
		}
	}

	/**
	 * Handles the response after attempting to charge an invoice.
	 * @param res The response received after attempting to charge the invoice.
	 */
	public handleChargeInvRes(res: APIRes): void {
		if(this.apiServ.checkAPIRes(res)){
			// If successful, display a success message using toastr and redirect the user to the invoice section.
			this.toastr.success(res.message);
			setTimeout(() => {
				this.paymentSuccessMsg = res.message;
				this.invServ.invRedirectUser(this.section, this.currentUser);
				this.loader.hide(this.loaderId);
			}, 3000);
		} else {
			this.toastr.error(res.message);
			this.loader.hide(this.loaderId);
		}
	}

	/**
	 * Handles form validation error
	 */
	private handleFormErrors(): void {
		// If payment method is new credit card and paymentGatewayChild exists, set isZipcode to true
		if(this.invPaymentForm.controls['payment_method'].value == 'new_credit_card' && this.paymentGatewayChild){
			this.paymentGatewayChild.isZipcode = true;
		}
		this.invPaymentForm.markAllAsTouched();
		this.toastr.error('Please fill the required fields marked in red.');
		// Refresh the payment gateway child component if available.
		if(this.paymentGatewayChild){
			this.paymentGatewayChild.refresh();
		}
	}

	/**
	 * Back to invoice page
	 */
	public backToInv(): void {
		this.invServ.hideSidebar = false;
		// Added condition on the basis of accesstoken so that user can access of routing depending upon invId(old) and accessToken(new)
		if(this.accessToken){
			this.utilServ.navigateToInvPage('short_url',this.accessToken);
		}else{
			this.utilServ.navigateToInvPage('inv_id',this.invId);
		}
	}

	/**
	 * Handles the change event when a new card is selected.
	 * Updates the payment form with the selected card details.
	 * @param {Event} event - The change event containing the selected card ID.
	 */
	public onCardChange(event: Event): void {
		let cardId: string = (<HTMLInputElement>event.target).value;
		let billingCard: BillingCard | undefined = this.cards.find((card: BillingCard) => card.id === cardId);
		this.invPaymentForm.controls['pay_with_cc'].setValue(billingCard?.id);
		this.invPaymentForm.controls['card_last4_digits'].setValue(billingCard?.last4);
	}
}
