import { Component, OnInit, ViewEncapsulation, Input, ChangeDetectorRef } from '@angular/core';
// Services
import { BuildCustomSectionService, InitServ, UtilServ } from 'src/app/Services';

@Component({
	selector: 'bk-custom-fields-display',
	template: `
		<ng-container *ngIf="selCustomFields && selCustomFields.length > 0; else emptyTemp">
			<div class="row mx-n5" *ngFor="let field of selCustomFields" [style.order]="field?.display_order">
				<div class="col-6 mb-10 px-5">
					<div class="d-flex">
						<label class="mb-0 {{customClass}}">{{field?.name}}</label>
						<span class="ms-10 me-10">:</span>
					</div>
				</div>
				<div class="col-6 mb-10 px-5">
					<ng-container [ngSwitch]="field?.type">
						<ng-container *ngSwitchCase="'Datepicker'">
							<!-- <span><bk-date-display [date]="field.value" [isLocal]="true"></bk-date-display></span> -->
							<span>{{field?.value | fmtDate:true:true}}</span>
						</ng-container>
						<ng-container *ngSwitchCase="'Image'">
							<img loading="auto" [src]="initServ.imgBase+field?.value" alt="custom field" class="img-fluid" (error)="utilServ.onImgError($event)" />
						</ng-container>
						<ng-container *ngSwitchCase="'Video'">
							<div [innerHTML]="field?.value | safeHtml: 'html'"></div>
						</ng-container>
						<ng-container *ngSwitchCase="'Priceable Input'">
							<bk-amount-display [amount]="field?.value" [isZero]="true"></bk-amount-display>
						</ng-container>
						<ng-container *ngSwitchDefault>
							<span>{{field?.value}}</span>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</ng-container>
		<ng-template #emptyTemp>
			<span *ngIf="isEmpty">--</span>
		</ng-template>
	`,
	encapsulation: ViewEncapsulation.None
})
export class CustomFieldsDisplayComponent implements OnInit {
	@Input() bkng: any;
	@Input() isEmpty: boolean = false;
	@Input() customClass!: string;
	@Input() isPriceable: boolean = false;
	selCustomFields: Array<any> = [];
	constructor(public utilServ: UtilServ, private bkngCustSecServ: BuildCustomSectionService, public initServ: InitServ, private cDRef: ChangeDetectorRef){
	}
	ngOnInit() {
		this.processCustFields();
	}
	/**
	 * Process custom fields based on booking ID
	 */
	private processCustFields(): void {
		// Check if booking ID exists and if there are custom fields based on booking ID.
		if(this.isCustFields() && this.utilServ.checkArrLength(this.bkngCustSecServ.custFieldsBasedOnBkng[this.bkng?._id])){
			// Loop through each custom field ID based on booking ID.
			for(let custFieldId of this.bkngCustSecServ.custFieldsBasedOnBkng[this.bkng?._id]){
				// Check if custom field ID exists
				if(this.utilServ.objHasProp(this.bkngCustSecServ.custFieldsBasedOnId, custFieldId)){
					this.checkCustomFields(custFieldId);
				}
			}
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Checks if custom fields are available based on the booking ID.
	 * @returns {boolean} True if custom fields are available, otherwise false.
	 */
	private isCustFields(): boolean {
		return (this.bkng?._id && this.utilServ.objHasProp(this.bkngCustSecServ.custFieldsBasedOnBkng, this.bkng?._id));
	}

	/**
	 * Checks if custom fields are available based on the booking ID and build the custom fields.
	 * @returns {boolean} True if custom fields are available, otherwise false.
	 */
	private checkCustomFields(custFieldId: string): void {
		// Get the custom field and check if it is not a priceable input.
		let field: any = this.bkngCustSecServ.custFieldsBasedOnId[custFieldId];
		if(this.isPriceable || field.field_type != 'Priceable Input'){
			let ctrl: string = field?.custom_field_section_id+'_'+field?.id;
			// Build custom field data.
			this.buildCustFieldData(field, ctrl);
		}
	}

	/**
	 * Build custom field data based on field data and control.
	 * @param {any} fieldData - The data of the field to be built.
	 * @param {string} ctrl - The control to be checked.
	 */
	private buildCustFieldData(fieldData: any, ctrl: string): void {
		if(this.utilServ.objHasProp(this.bkng.custom_fields, ctrl)){
			let val: any = this.bkng.custom_fields[ctrl];
			if(typeof val == 'object'){
				this.buildObjTypeCustFields(val, fieldData);
			}else{
				this.buildStrCustFields(val, fieldData);
			}
		}
	}

	/**
	 * Builds custom fields for object type data.
	 * @param {any} val - The value of the custom field.
	 * @param {any} fieldData - Data of the custom field.
	 */
	private buildObjTypeCustFields(val: any, fieldData: any): void {
		// Check if any checkbox is selected
		if(val && Object.values(val).some((el: any) => el == true)){
			let checkboxValArr: Array<string> = [];
			// Loop through the object and push the selected checkbox values to an array.
			for(let key in val){
				if(val[key]){
					checkboxValArr.push(key);
				}
			}
			// Push the custom field data to the selected custom fields array.
			this.selCustomFields.push({
				name: this.utilServ.getFormParamName(fieldData),
				value: checkboxValArr.join(', '),
				display_order: fieldData?.display_order
			})
		}
	}

	/**
	 * Builds custom fields for string type data.
	 * @param {any} val - The value of the custom field.
	 * @param {any} fieldData - Data of the custom field.
	 */
	private buildStrCustFields(val: any, fieldData: any): void {
		if((val && (val.toString()).length > 0)){
			// Push the custom field data to the selected custom fields array.
			this.selCustomFields.push({
				name: this.utilServ.getFormParamName(fieldData),
				value: this.getCustFieldVal(fieldData, val),
				type: fieldData.field_type,
				display_order: fieldData.display_order,
			})
		}
	}

	/**
	 * Gets the value of a custom field based on its type.
	 * @param {any} field - Data of the custom field.
	 * @param {any} val - The value of the custom field.
	 * @returns {any} The processed value of the custom field.
	 */
	private getCustFieldVal(field: any, val: any): any {
		switch (field.field_type) {
			case 'Timepicker':
				return this.getTime(val);
			case 'Video':
				if(field?.video_details?.video_type == 'custom' || val.includes('uploads')){
					return `<a href="`+this.initServ?.imgBase+val+`" target="_blank">Watch video</a>`;
				}else{
					return `<a href="`+val+`" target="_blank">Watch video</a>`;
				}
			default:
				return val;
		}
	}
	/**
	 * Get time in user's preferred format.
	 * @param {any} time - The time value to be formatted.
	 * @returns {any} - The formatted time value.
	 */
	private getTime(time: any): any {
		if(time){
			if(this.utilServ.timeFormat == '24'){
				return this.utilServ.convertTimeIn24HoursFormat(time);
			}else{
				return this.utilServ.getTimeStringFrom24HFormat(time, null)+':'+this.utilServ.addPrefixZeroBeforeNum(time % 100)+' '+this.utilServ.getTimeStringFrom24HFormat(time, 'meridian');
			}
		}
		return null;
	}
}
