<div class="tjs-popup tjs-popup--sm position-relative" [id]="secId" *ngIf="selectedProv">
	<!-- Popup Header -->
	<bk-popup-header></bk-popup-header>
	<div id="review-section" class="tjs-popup__content tjs-popup__body">
		<!-- Provider profile card -->
		<div class="text-center border-bottom mb-sm-25 mb-20 bk-card-border">
			<!-- Avatar -->
			<div class="mb-15" *ngIf="utilServ.userNameSett('photo')">
				<bk-user-img customClass="rounded-5 img-fluid-disabled" [imgSrc]="selectedProv?.photo_url" [isParentMedia]="true" alt="user image" height="60" width="60"></bk-user-img>
			</div>
			<!-- Name -->
			<h4 class="title text-capitalize fw-bold mb-10 bk-profile-title" *ngIf="utilServ.userNameSett('firstname') || utilServ.userNameSett('lastname') || utilServ.userNameSett('last_initial')">
				<ng-container *ngIf="utilServ.userNameSett('firstname')">{{selectedProv?.first_name}}&nbsp;</ng-container>
				<ng-container *ngIf="utilServ.userNameSett('lastname')">{{selectedProv?.last_name}}</ng-container>
				<ng-container *ngIf="!utilServ.userNameSett('lastname') && utilServ.userNameSett('last_initial')">{{utilServ.nameLastInitial(selectedProv?.last_name)}}</ng-container>
			</h4>
			<!-- Rating -->
			<ng-container *ngIf="utilServ.userNameSett('ratings')">
				<div class="mb-0 d-inline-block">
					<bk-rating-display customClass="tjs-rating--lg bk-rating bk-star-group pe-none" *ngIf="selectedProv?.average_rating" [rating]="selectedProv?.average_rating" [readOnly]="false"></bk-rating-display>
				</div>
				<div class="mb-20">
					<span class="fs-14 bk-meta-info">({{selectedProv?.average_rating | number : '1.2-2'}} {{'out of' | translate}} 5)</span>
					<span class="fs-14 bk-meta-info" *ngIf="utilServ.userNameSett('jobs')"> {{selectedProv?.total_jobs_done}} {{'Jobs Completed' | translate}}</span>
				</div>
			</ng-container>
		</div>
		<!-- Reviews Wrap -->
		<div class="" id="reviews">
			<ul class="list-group tjs-list" *ngIf="reviews && reviews?.length > 0">
				<ng-container *ngFor="let review of reviews; let i = index; trackBy: utilServ.trackByFnIndex">
					<li [id]="'review_list_'+(i+1)" class="list-group-item border-0 bg-transparent mb-sm-25 mb-20">
						<div class="">
							<ng-container *ngIf="review.review">
								<!-- review -->
								<p class="text-muted fst-italic mb-10 bk-review" >
									<i class="tjsicon-quote-1 me-10 opacity-50"></i>
									<span class="" [innerHTML]="(isReadmore[review.id] == undefined && utilServ.reviewCount(review.review)) ? utilServ.reviewDesc(review.review) : review.review "></span>
									<i class="tjsicon-quote ms-10 opacity-50"></i>
								</p>
								<!-- Read more btn -->
								<div class="d-flex d-flex align-items-center justify-content-end" *ngIf="utilServ.reviewCount(review.review) && isReadmore[review.id] == undefined">
									<button class="btn btn-link text-muted p-0 text-decoration-underline bk-readmore-btn" (click)="isReadmore[review.id] = true;">{{section?.listing?.read_more_btn}}</button>
								</div>
							</ng-container>
							<!-- Author & average rating -->
							<div class="d-flex align-items-center gap-15 mt-10"  *ngIf="(customerDetail != 'no_name') || utilServ.userNameSett('ratings')">
								<ng-container *ngIf="customerDetail != 'no_name'">
									<h6 class="mb-0 text-capitalize fw-bold bk-author-title">{{review?.review_fields?.first_name}}
										<span>{{customerLastName(review?.review_fields?.last_name)}}</span>
									</h6>
								</ng-container>
								<ng-container *ngIf="utilServ.userNameSett('ratings')">
									<bk-rating-display class="d-flex" customClass="bk-rating bk-star-group pe-none" *ngIf="selectedProv?.average_rating" [rating]="review?.rating" [readOnly]="true"></bk-rating-display>
								</ng-container>
							</div>
						</div>
					</li>
				</ng-container>
			</ul>
		</div>
		<!-- Load more btn -->
		<div class="d-flex align-items-center justify-content-end mb-10" *ngIf="reviews && (reviews.length < totalRecords)">
			<bk-navigate [secId]="section?.load_more_btn?.id" [designId]="section?.load_more_btn?.design_id" [innerHTML]="section?.load_more_btn?.content" [isSubmit]="false" customClass="btn btn-white rounded-5 w-100" essentialClass="w-100" class="d-block" (callback)="loadMoreReviews()"></bk-navigate>
		</div>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
