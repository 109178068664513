<div class="tjs-popup modal-fullscreen w-100-imp tjs-media-files tjs-bg-dark">
	<bk-popup-header [isClose]="true"></bk-popup-header>
	<div class="tjs-bg-dark">
		<div class="tjs-viewer-container" [ngClass]="(layout === 'slider' || selectedFile) ? 'tjs-viewer--slider-active' : ''">
			<div class="tjs-viewer__header px-30 pt-20 d-flex justify-content-between mb-20">
				<h4 class="text-white" translate>Media Files</h4>
				<div class="me-40 tjs-viewer__layout btn-group btn-group-sm" role="group" aria-label="Change layout" *ngIf="!selectedFile">
					<button type="button" class="py-10 px-15 btn btn-outline-primary" [class.active]="layout === 'grid'" (click)="setLayout('grid')">
						<i class="tjsicon-dashboard"></i>
					</button>
					<button type="button" class="py-10 px-15 btn btn-outline-primary" [class.active]="layout === 'slider'" (click)="setLayout('slider')">
						<i class="tjsicon-content"></i>
					</button>
				</div>
			</div>
			<div class="tjs-tab tjs-tab__chips bg-dark position-sticky top-0 z-99 pb-35 px-30">
				<ng-container *ngIf="selectedFile; else gridTags">
					<ul class="tjs-list nav" *ngIf="selectedFile?.tag_ids?.length > 0">
						<li class="nav-item" *ngFor="let tag of selectedFile?.tag_ids;trackBy: utilServ.trackByFnIndex">
							<a href="javascript:void(0)" class="tjs-tab__link text-white pe-none nav-link">{{tagNames?.[tag]}}</a>
						</li>
					</ul>
					<div class="d-flex justify-content-end">
						<button type="button" class="text-white p-0 d-flex align-items-center btn btn-link" (click)="selectedFile = null;">
							<i class="tjsicon-left-arrow-1 me-10"></i>Back to grid
						</button>
					</div>
				</ng-container>
				<ng-template #gridTags>
					<ul class="tjs-list nav" *ngIf="fileTags?.length > 0">
						<li class="nav-item">
							<a href="javascript:void(0)" [class.active]=" selectedTag == 'all'" (click)="selectTag('all')" class="tjs-tab__link text-white nav-link">All</a>
						</li>
						<li *ngFor="let tag of fileTags;trackBy: utilServ.trackByFnIndex" class="nav-item">
							<a href="javascript:void(0)" [class.active]="selectedTag == tag" (click)="selectTag(tag)" class="tjs-tab__link text-white nav-link">{{tagNames?.[tag]}}</a>
						</li>
					</ul>
				</ng-template>
			</div>
			<div class="tjs-viewer-wrap h-100 px-30 tjs-scrollbar">
				<div [ngClass]="layout == 'slider' || selectedFile ? 'tjs-viewer__slider mx-auto col-lg-7' : 'tjs-viewer__grid' " *ngIf="files?.length > 0; else emptyMedia">
					<div class="position-relative" *ngIf="selectedFile; else gridLayout">
						<ng-container *ngTemplateOutlet="mediaFile; context: {file : selectedFile}"></ng-container>
					</div>
					<ng-template #gridLayout>
						<ng-container *ngIf="layout === 'grid'; else slider">
							<div class="tjs-viewer-list row">
								<ng-container *ngFor="let file of files; let index = index;trackBy: utilServ.trackByFnIndex;">
									<div class="col-lg-6 tjs-viewer-item d-flex justify-content-center align-items-center overflow-hidden position-relative mb-25 tjs-animate-fadein flex-column" *ngIf="!utilServ.isEmpty(file) && (selectedTag === 'all' || file.tag_ids?.includes(selectedTag))">
										<div class="tjs-viewer__file tjs-pointer h-100 w-100 tjs-viewer__{{file.type}}" (click)="selectSlide($event,file)">
											<img *ngIf="file?.type === 'image'" [src]="initServ?.imgBase+file?.url" alt="image" height="500" width="900">
										</div>
										<ng-container *ngTemplateOutlet="mediaFile; context: {file : file,hideFile:true}"></ng-container>
									</div>
								</ng-container>
							</div>
						</ng-container>
						<ng-template #slider>
							<div id="carousel" class="carousel slide" data-bs-interval="false">
								<div class="carousel-inner">
									<ng-container *ngFor="let file of files; let index = index;trackBy: utilServ.trackByFnIndex;">
										<div class="carousel-item" [class.active]="index == activeSlide" *ngIf="!utilServ.isEmpty(file) && (selectedTag === 'all' || file.tag_ids?.includes(selectedTag))">
											<ng-container *ngTemplateOutlet="mediaFile; context: {file : file}"></ng-container>
										</div>
									</ng-container>
								</div>
								<a class="carousel-control-prev" role="button" data-bs-target="#carousel" data-bs-slide="prev">
									<span class="carousel-control-prev-icon" aria-hidden="true"></span>
									<span class="sr-only">Previous</span>
								</a>
								<a class="carousel-control-next" role="button" data-bs-target="#carousel" data-bs-slide="next">
									<span class="carousel-control-next-icon" aria-hidden="true"></span>
									<span class="sr-only">Next</span>
								</a>
							</div>
						</ng-template>
					</ng-template>
				</div>
				<ng-template #emptyMedia>
					<div class="card bg-transparent text-center m-30">
						<div class="card-body">
							<h3 class="mb-0 text-white">No media uploaded yet.</h3>
						</div>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</div>

<!--Media file template-->
<ng-template #mediaFile let-file="file" let-hideFile="hideFile">
	<ng-container *ngIf="!hideFile">
		<div *ngIf="(file.type == 'video') || (file.type == 'audio'); else imgFile">
			<video controls class="w-100" height="320">
				<source [src]="initServ?.imgBase+file?.url" type="video/mp4">
			</video>
		</div>
		<ng-template #imgFile>
			<img [src]="initServ?.imgBase+file?.url">
		</ng-template>
	</ng-container>
	<div class="position-absolute tjs-viewer__action z-99">
		<button type="button" class="fs-14 shadow btn btn-light btn-xs" (click)="utilServ.downloadMedia(file)"><i class="tjsicon-download"></i></button>
	</div>
	<div class="position-absolute bottom-0 start-0 end-0 p-20 tjs-bg-overlay">
		<h6 class="text-white mb-0 text-center w-70 text-truncate mx-auto">{{file?.name}}</h6>
	</div>
</ng-template>
