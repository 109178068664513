import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, InitServ } from '../../../Services';

@Component({
	selector: 'bk-user-img',
	template: `
	<img loading="auto" *ngIf="imgSrc; else noImg" [class]="customClass" id="preview" [class.img-fluid]="!isParentMedia" [src]="prepandImgUrl ? (initServ?.imgBase + imgSrc) : imgSrc" width="{{width}}" height="{{height}}" alt="Avatar" />
	<ng-template #noImg>
		<img loading="auto" *ngIf="logo; else noLogo" [class]="customClass" alt="Avatar" [class.img-fluid]="!isParentMedia" id="preview" src="{{initServ?.img?.KoalaIcon}}" width="{{width}}" height="{{height}}" />
		<ng-template #noLogo>
			<img loading="auto" [class]="customClass" alt="Avatar" [class.img-fluid]="!isParentMedia" id="preview" src="{{initServ?.img?.Avatar}}" width="{{width}}" height="{{height}}" />
		</ng-template>
	</ng-template>`,
	encapsulation: ViewEncapsulation.None,
	providers:[NgOnDestroy]
})
export class UserImgComponent implements OnInit {

	// Variables
	@Input() isParentMedia: boolean = false;
	@Input() imgSrc: string = '';
	@Input() logo: boolean = false;
	@Input() height: string = '80';
	@Input() width: string = '80';
	@Input() customClass: any;
	@Input() prepandImgUrl: boolean = true;

	constructor(public initServ: InitServ) { }

	ngOnInit(): void {
		// Height & width
		if(!this.height || !this.width) {
			this.height = '80';
			this.width = '80';
		}
		// Logs
		if(!this.logo){
			this.logo = false;
		}
	}
}
