import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectorRef,ElementRef } from '@angular/core';
import KeenSlider,{KeenSliderInstance,KeenSliderPlugin} from "keen-slider";
import dayjs from 'dayjs';
import { InitServ, SchedulingServ, DateServ, NgOnDestroy, LoaderServ, SectionServ,UtilServ } from '../../../../Services';

function MonthPlugin(main: KeenSliderInstance): KeenSliderPlugin {
	return (slider) => {
		if(slider && main){
			function removeActive() {
				slider?.slides?.forEach((slide) => {
				  slide?.classList.remove("keen-slider__active")
				})
			}
			function addActive(idx: number) {
				slider?.slides[idx]?.classList.add("keen-slider__active")
			}
			slider.on("created", () => {
				addActive(slider?.track?.details?.rel)
				slider?.slides.forEach((slide, idx) => slide.addEventListener("click", () =>{
					slider.moveToIdx(idx);
					main.moveToIdx(idx);
				}))
				main.on("animationStarted", (main) => {
					removeActive()
					const next = main.animator.targetIdx || 0
					addActive(main.track.absToRel(next));
				})
			})
		}
	}
}

@Component({
	selector: 'bk-schedule-calendar',
	templateUrl: './ScheduleCalendar.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ScheduleCalendarComponent implements OnInit {

	@ViewChild("monthSlide") sliderOneRef : ElementRef<HTMLElement> | undefined;
	@ViewChild("dateSlide") sliderTwoRef : ElementRef<HTMLElement> | undefined;
	@Input() BKFrm : any;
	@Input() selectedDate : any;
	@Input() dayDiscountBookings : any;
	@Input() dayDiscounts : any;
	@Input() dateDiscountsArray : any;
	@Input() dayDiscountsArray : any;
	@Input() prefilledData : any;
	@Input() isSpotAvailable : any = true;
	@Input() isMultiStep : boolean = false;
	@Input() settings : any;
	@Input() calSection: any;
	@Input() pageSett: any;
	@Input() renderCalendar: any;
	@Output() selectMultiStepDate : EventEmitter<any> = new EventEmitter();
	sliderOne: any = null;
	sliderTwo: any = null;
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	globals : any;
	allSpots: any = this.initServ.appBookingSpots; // get all spots
	bookingSpots : any;
	bookingSpotEndArray : any;
	loaderId: string = 'cal-loader';
	currentDate : any;
	activeMonth : any;
	months : any = []
	monthArray : any = []
	monthsReady : boolean = false
	parsedMonths : any = []
	currentDateYMD : string = ''
	afterYearDate : string = ''
	currentTimeStamp : any
	minTimeLimit : any
	blockedDays : any = []
	location_id : number = 0;
	holidays : any = [];
	dailySettings : any = {}
	bookingsToCheckSpots : any;
	isDataLoaded : any = []
	bookingsForDates : any = {}
	bookingsForProviders : any = {}
	teams : any;
	teamIds : any = []
	providersTeams : any = [];
	availProvFilters : any;
	applicableProviderIds : any;
	providerSettings : any;
	memberCount : any;
	ProviderEntities : any;
	pairableProviders : any;
	providerWorkingHours : any;
	/* to maintain recurrring dates for each date to check availability for future dates */
	perDayRecurringDates : any = {};
	perDateSpots : any = {}
	perDateAvailSpots : any= {}
	notAvailableDates : any = []
	availableDates : any = []
	perSpotProviders : any = {}
	perSpotAvailProviders : any = {}
	perProviderSpots : any= {}
	providerSkipDates : any= {}
	monthActiveDates : any = {};
	allDatesToCheck : any = {}
	scheduleRange = this.scheduleServ.getScheduleRange()
	perDateDiscounts : any;
	minProvidersReq : number = 1;
	divideJobLength : string = 'yes';
	bookinglength: any;
	noSpotAvailable : boolean = false;
	slideOneConfig:any = {
		breakpoints: {
			"(max-width: 991px)": {
				slides: { perView: 1 },
			},
		},
		initial:0,
		slides: { perView: 3,origin:'center' },
		rtl:(this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? true : false,
		defaultAnimation:{duration:2000},
		animationStarted:(s:any) => {
			const next = s.animator.targetIdx || 0;
			if(next != this.sliderTwo.track.details.rel){
				this.sliderTwo.moveToIdx(next);
			}
		},
	};
	slideTwoConfig:any = {
		slides: { perView: 1 },
		initial:0,
		rtl:(this.initServ.selectedLang && this.initServ.selectedLang.rtl) ? true : false,
		defaultAnimation:{duration:2000},
		slideChanged:(s:any)=>{
			const next = s.animator.targetIdx || s.track.details.rel || 0;
			this.beforeMonthChange(next);
			if(s.track.details.rel !== this.sliderOne.track.details.rel){
				this.sliderOne.moveToIdx(next);
			}
			this.utilServ.adaptiveHeight(s);
		}
	};
	sectionStatus: any ={
		first_available_btn: false,
		wait_msg: false,
		no_spot_msg: false
	}
	calendarMount :boolean =false;
	prefilledDayNumber : any;
	showCalendar :boolean =false;

	constructor(public initServ: InitServ, public scheduleServ : SchedulingServ, public dateServ : DateServ, private loader: LoaderServ, private secServ: SectionServ, private cDRef: ChangeDetectorRef,private utilServ: UtilServ) {
	}

	// eslint-disable-next-line complexity
	ngOnInit(): void {
		if(this.calSection){
			if(this.calSection.first_available_btn){
				this.sectionStatus['first_available_btn'] = this.secServ.checkEleStatus(this.pageSett, this.calSection.first_available_btn_id);
			}
			if(this.calSection.wait_msg){
				this.sectionStatus['wait_msg'] = this.secServ.checkEleStatus(this.pageSett, this.calSection.wait_msg_id);
			}
			if(this.calSection.no_spot_msg){
				this.sectionStatus['no_spot_msg'] = this.secServ.checkEleStatus(this.pageSett, this.calSection.no_spot_msg_id);
			}
		}
		this.loader.show(this.loaderId);
		this.globals = this.scheduleServ.globals
		// console.log('globals', this.globals)
		/* prepare start and end date to filter data */
		this.currentDate = dayjs()
		this.currentTimeStamp = dayjs().unix();
		this.currentDateYMD = this.currentDate.format("YYYY-MM-DD")
		let afterYearDate = dayjs().add(13, 'month');
		// let afterYearDate = dayjs().add(+(this.globals.availabilityRange)+1, 'month');
		this.blockedDays = this.scheduleServ.disableFreqDays(this.BKFrm.value.frequency_repeat_slug);
		this.afterYearDate = afterYearDate.format("YYYY-MM-DD")
		if(this.globals.providerAvailabilityBasedOn && this.globals.providerAvailabilityBasedOn == 'all_location'){
			this.location_id = 0
		}
		else{
			this.location_id = this.BKFrm.controls['base_location_id'].value
		}
		this.bookingSpots = this.allSpots?.[this.location_id]

		if(this.BKFrm.controls['adjust_time'].value){
			this.bookinglength = this.BKFrm.controls['adjusted_time'].value
		}
		else{
			this.bookinglength = this.BKFrm.controls['length'].value
		}

		if(this.globals.providerMaxTime > 0 && this.bookinglength > this.globals.providerMaxTime){
			this.minProvidersReq = Math.ceil(this.bookinglength/this.globals.providerMaxTime)
		}
		// console.log('prefilledData',this.prefilledData)
		if((this.BKFrm.controls['base_location_id'].value == 0 || !this.BKFrm.controls['base_location_id'].value) && this.settings.form_data.location_type != 'no_location' && !this.prefilledData){
			this.noSpotAvailable = true
			this.dataPrepared()
		}
		/* call function to preload data to check the availability, function to build calendar is called after data load */
		this.preloadData();
	}

	ngOnChanges() {
		if(this.renderCalendar){
			if(this.sliderOne){
				this.sliderOne.update();
			}
			if(this.sliderTwo){
				this.sliderTwo.update();
			}
		}
	}

	/* function to preload data and then call function to builder calendar */
	preloadData(){
		/* load holidays and bookings to check the availability */
		this.getHolidays(this.currentDateYMD,this.afterYearDate)
		this.getBookingsToCheckSpots(this.currentDateYMD,this.afterYearDate)
		// this.minTimeLimit = this.scheduleServ.checkSameDayLimit(this.prefilledData);
		this.minTimeLimit = this.scheduleServ.checkSameDayLimit();
		if(this.prefilledData){
			this.prefilledDayNumber = dayjs(this.prefilledData.booking_date).day();
			// console.log('prefilledDayNumber', this.prefilledDayNumber)
		}
	}

	/* function to prepare data for calendar build */
	getCalendar() {
		let calResponse = this.scheduleServ.getCalendar()
		this.monthArray = calResponse.monthArray
		this.months = calResponse.months
		this.monthActiveDates = calResponse.monthActiveDates
		// console.log('this.monthActiveDates',this.monthActiveDates)

		let month = this.currentDate.format("MM")
		if(this.selectedDate){
			let selectedDateSplit = (this.selectedDate).split("-")
			let indexOfMonth = this.monthArray.indexOf(selectedDateSplit[1])
			if(indexOfMonth > 0){
				this.slideOneConfig.initial = this.slideTwoConfig.initial = indexOfMonth;
				if(month != selectedDateSplit[1] && this.monthActiveDates[month]){
					delete this.monthActiveDates[month];
				}
				month = selectedDateSplit[1];
				if(this.sliderOne){
					this.sliderOne.moveToIdx(indexOfMonth);
					this.sliderOne.update(this.slideOneConfig);
				}
				if(this.sliderTwo){
					this.sliderTwo.moveToIdx(indexOfMonth);
					this.sliderTwo.update(this.slideTwoConfig);
				}
				this.changeMonth(indexOfMonth)
				return true
			}
		}
		this.prepareRecurringDates(month)
		this.prepareSpots(month)
		this.checkProvidersAvailability(month)
		return true
	}

	/* function for trackby */
	trackByMonth(month:any):number{
		return month.month;
	}
	trackByDates(index: number): number {
		return index;
	}

	getDayOfDate(year: string, month:string, day:any){
		let calDay = ("00" + day).slice(-2);
		let d = dayjs(year+'-'+month+'-'+calDay).format('ddd');
		return d;
	}

	selectMultiStepDateFun(year:string, month:string, day:any, autoSpot:boolean=false) {
		if(this.checkIfPastDate(year, month, day)){
			return false;
		}

		let calDay:any = ("00" + day).slice(-2);
		this.selectedDate = year+'-'+month+'-'+calDay;
		// console.log('perDateAvailSpots',this.perDateAvailSpots, this.availableDates)
		// this.prepareAvailableDates(+month)
		let obj:any = { "date" :
			{
				'year' : year,
				'month': month,
				'day': calDay
			},
			"spots" : this.perDateAvailSpots[+month],
			"availableDates" : this.availableDates,
			// "providers" : this.perSpotProviders,
			"providers" : this.perSpotAvailProviders,
			"team_members" : this.teams,
			"teamIds" : this.teamIds,
			"minprovidersreq": this.minProvidersReq,
			"autoSpot" : autoSpot,
			"holidays" : this.holidays,
			"providerskipdates" : this.providerSkipDates,
			"notAvailableDates" : this.notAvailableDates,
		}
		// console.log('obj',obj)
		this.selectMultiStepDate.emit(obj);
		return true
	}

	/* function to check if calendar is past date */
	checkIfPastDate(year:string, month:string, day:any){
		let calDay:any = ("00" + day).slice(-2)
		let date = year+'-'+month+'-'+calDay;

		if(this.prefilledData && this.prefilledData.booking_date == date && this.prefilledData?.status != 3 &&  !(this.blockedDays).includes(this.prefilledDayNumber)){
			return false;
		}

		if((this.holidays).includes(date) || (this.notAvailableDates).includes(date)){
			return true
		}

		let d:any = dayjs(year+'-'+month+'-'+calDay)
		let t:any = d.unix()
		let dayNumber:any = d.day()
		if((this.blockedDays).includes(dayNumber)){
			if(!(this.notAvailableDates).includes(date)){
				this.notAvailableDates.push(date);
			}
			return true;
		}
		if(t >= this.minTimeLimit){
			return false
		}
		if(!(this.notAvailableDates).includes(date)){
			this.notAvailableDates.push(date);
		}
		return true
	}

	/* Function to get the holidays which will be used to check the availability */
	getHolidays(startDate: string, endDate: string){
		this.isDataLoaded["holidays"] = false
		let data = {"startdate": startDate,"enddate":endDate,"location":this.location_id}
		this.scheduleServ.getHolidays(data).subscribe((res:any)=> this.loadHolidays(res));
	}
	loadHolidays(response:any){
		this.holidays = []
		if(response && response.data && (response.data).length > 0){
			for(let holiday of response.data){
				this.holidays = this.holidays.concat(holiday.specific_dates)
			}
		}
		this.isDataLoaded["holidays"] = true;
		this.cDRef.detectChanges();
	}

	/* Function to get the bookings for date range to check availability */
	getBookingsToCheckSpots(startDate: string, endDate : string){
		this.isDataLoaded["bookings"] = false
		let data : any = {"startdate": startDate,"enddate":endDate,"location":this.location_id}
		if(this.prefilledData && this.prefilledData['_id']){
			data['booking_id'] = [this.prefilledData['_id']]
		}
		// console.log('this.BKFrm.value',this.BKFrm.value)
		if((this.BKFrm.controls['provider_ids'].value).length > 0 && this.BKFrm.controls['provider_type'].value != 'random_provider'){
			data['provider_ids'] = this.BKFrm.controls['provider_ids'].value
			if(this.BKFrm.value.provider_details && this.BKFrm.value.provider_details[0] && this.BKFrm.value.provider_details[0].is_team_member){
				data['provider_ids'] = data['provider_ids'].concat(this.BKFrm.value.provider_details[0].team.team_ids)
			}
			if(this.BKFrm.value.provider_details && this.BKFrm.value.provider_details[0] && this.BKFrm.value.provider_details[0].provider_type == 'team'){
				data['provider_ids'] = data['provider_ids'].concat(this.BKFrm.value.provider_details[0].team_members)
			}
		}
		if(this.prefilledData && this.prefilledData['recurring_id']){
			data['recurring_id'] = [this.prefilledData['recurring_id']]
		}
		// this.scheduleServ.getBookingsCheckSpots(data).subscribe((res:any)=> this.loadBookingsData(res));
		this.scheduleServ.getBookingsToCheckSpots(data).subscribe((res:any)=> this.loadBookingsData(res));
	}
	loadBookingsData(response: any){
		if(response?.data?.bookings){
			this.bookingsForDates = response.data.bookings
		}
		if(response?.data?.daily_settings){
			this.dailySettings = this.scheduleServ.parseDailySettings(response.data.daily_settings)
		}

		if(this.globals.scheduleType != 'manually'){
			this.getApplicableProviders()
		}
		else{
			this.isDataLoaded["bookings"] = true
			this.getCalendar()
		}
	}

	/* function to prepare the data required to filter available providers which will be used by other functions to get the available/applicable providers list */
	getApplicableProviders(){
		this.availProvFilters = null;
		this.availProvFilters = this.scheduleServ.availProviderFilters(this.BKFrm.value);
		this.scheduleServ.getApplicableProviders(this.availProvFilters).subscribe((res:any)=> this.parseApplicableProviders(res));
	}

	/* function to process applicable providers */
	parseApplicableProviders(response:any){
		// console.log('response',response)
		this.applicableProviderIds = response.data.providers
		if((this.BKFrm.controls['provider_ids'].value).length > 0 && this.BKFrm.controls['provider_type'].value != 'random_provider'){
			this.applicableProviderIds = this.BKFrm.controls['provider_ids'].value
		}
		this.providerSettings = response.data.break_time
		this.memberCount = response.data.members
		this.ProviderEntities = response.data.entities;
		this.pairableProviders = this.scheduleServ.preparePairableProviders(this.providerSettings)
		this.getApplicableProviderData(this.currentDateYMD,this.afterYearDate)
	}

	/* Function to get applicable provider's data */
	getApplicableProviderData(startDate: string, endDate : string){
		let filters : any = {"startdate": startDate,"enddate":endDate,"location_id":this.BKFrm.controls['base_location_id'].value,"provider_ids":this.applicableProviderIds, "form_id":this.BKFrm.controls['form_id'].value, "industry_id" : this.BKFrm.controls['industry_id'].value,"provider_booking":false}
		if(this.prefilledData && this.prefilledData['_id']){
			filters['booking_id'] = this.prefilledData['_id']
		}
		if(this.prefilledData && this.prefilledData['recurring_id']){
			filters['recurring_id'] = this.prefilledData['recurring_id']
		}
		this.scheduleServ.getApplicableProvidersData(filters).subscribe((res:any)=> this.parseApplicableProvidersData(res));
	}

	/* Process applicable provider's data */
	parseApplicableProvidersData(response:any){
		this.providerWorkingHours = []
		if(response.data){
			if(response.data.working_hours){
				this.providerWorkingHours = this.scheduleServ.prepareWorkingHours(response.data.working_hours)
			}
			if(response.data.provider_bookings){
				this.bookingsForProviders = response.data.provider_bookings
			}
			this.teams = response.data.teams;
			this.teamIds = Object.keys(this.teams)
			this.providersTeams = response.data.members
		}
		// console.log('this.bookingsForProviders',this.bookingsForProviders)
		this.isDataLoaded["bookings"] = true
		this.getCalendar()
	}

	/* function to call on slide change */
	beforeMonthChange(nextSlide:any){
		this.activeMonth = this.months[nextSlide]['month']
		// console.log('monthIndex',nextSlide,this.months[nextSlide]['month'])
		if(!this.monthActiveDates[this.months[nextSlide]['month']] && this.months[nextSlide]){
			this.changeMonth(+nextSlide)
		}
	}

	changeMonth(monthIndex:number){
		let startDate = this.months[monthIndex]['year']+'-'+this.months[monthIndex]['month']+'-0'+this.months[monthIndex]['firstDate']
		let lastDate = this.months[monthIndex]['year']+'-'+this.months[monthIndex]['month']+'-'+this.months[monthIndex]['lastDate']
		if(monthIndex > 0 || !this.monthActiveDates[+this.months[monthIndex]['month']]){
			this.monthActiveDates[+this.months[monthIndex]['month']] = this.scheduleServ.monthDatesCounter(startDate,lastDate)
		}
		this.prepareRecurringDates(this.months[monthIndex]['month'])
		this.prepareSpots(this.months[monthIndex]['month'])
		this.checkProvidersAvailability(this.months[monthIndex]['month'])
	}

	/* function to maintain recurring dates for each day of month, this will be used to check the data and spot availability */
	prepareRecurringDates(month:number){
		let recDateLength = (this.globals.scheduleType == 'manually' && this.globals.spotCheckSettings == 'first') ? 1 : this.globals.totalBookingsCount
		// if((this.prefilledData && this.prefilledData.apply_to_all && this.prefilledData.apply_to_all == 'false') || (this.BKFrm.controls['apply_to_all'] && this.BKFrm.controls['apply_to_all'].value == 'false')){
		// 	recDateLength = 1;
		// }
		if(this.BKFrm.controls['apply_to_all'] && this.BKFrm.controls['apply_to_all'].value == 'false'){
			recDateLength = 1;
		}
		this.perDayRecurringDates[+month] = {}
		if(this.monthActiveDates[+month] && (this.monthActiveDates[+month]).length > 0){
			this.allDatesToCheck[+month] = []
			for(let date of this.monthActiveDates[+month]){
				if(!(this.holidays).includes(date)){
					this.perDayRecurringDates[+month][date] = this.scheduleServ.getRecurringDates(date, this.BKFrm.value.frequency_repeat_slug, this.scheduleRange, recDateLength, this.holidays, this.globals.skipHolidays );
					if(this.perDayRecurringDates[+month][date]){
						this.allDatesToCheck[+month] = [...new Set([...this.allDatesToCheck[+month] ,...this.perDayRecurringDates[+month][date]])];
					}
				}
			}
		}
		// console.log('this.perDayRecurringDates',this.perDayRecurringDates)
	}

	/* function to prepare manual schedling spots */
	prepareSpots(month:number){
		let response : any;
		if(this.BKFrm.controls['provider_type'].value !== 'unassigned' && this.globals.scheduleType != 'manually' && !this.globals.checkSpotLimits){
			response = this.scheduleServ.prepareSpots(month,this.allDatesToCheck,this.bookingSpots)
		}else{
			response = this.scheduleServ.prepareManualSpots(month,this.allDatesToCheck,this.bookingSpots,this.dailySettings,this.bookingsForDates,this.monthActiveDates,this.perDayRecurringDates[+month],this.BKFrm.controls['is_waiting_list'].value)
		}
		this.perDateSpots = response.perDateSpots;


		if(this.perDateAvailSpots[+month] === undefined){
			this.perDateAvailSpots[+month] = {};
		}
		this.perDateAvailSpots[+month] = response.perDateAvailSpots;

		if(this.prefilledData && this.prefilledData.booking_date && this.prefilledData.arrival_time){
			if(this.perDateSpots[this.prefilledData.booking_date] == undefined){
				this.perDateSpots[this.prefilledData.booking_date] = [];
			}
			if(!(this.perDateSpots[this.prefilledData.booking_date]).includes((this.prefilledData.arrival_time).toString())){
				(this.perDateSpots[this.prefilledData.booking_date]).push((this.prefilledData.arrival_time).toString());
			}

			if(this.perDateAvailSpots[+month][this.prefilledData.booking_date] == undefined){
				this.perDateAvailSpots[+month][this.prefilledData.booking_date] = [];
			}
			if(!(this.perDateAvailSpots[+month][this.prefilledData.booking_date]).includes((this.prefilledData.arrival_time).toString())){
				(this.perDateAvailSpots[+month][this.prefilledData.booking_date]).push((this.prefilledData.arrival_time).toString());
			}
		}
		this.notAvailableDates = this.notAvailableDates.concat(response.notAvailableDates)
		this.bookingSpotEndArray = this.scheduleServ.setupBookingSpotEndArray(this.bookingSpots,this.globals.jobBeginTime);
	}

	checkProvidersAvailability(month:number){
		if(this.BKFrm.controls['provider_type'].value !== 'unassigned' && this.globals.scheduleType != 'manually'){
			// console.log('this.applicableProviderIds',this.applicableProviderIds)
			// console.log('this.providerWorkingHours',this.providerWorkingHours)
			// console.log('this.bookingsForProviders',this.bookingsForProviders)
			// console.log('this.pairableProviders',this.pairableProviders)
			// console.log('this.ProviderEntities',this.ProviderEntities)
			// console.log('this.providerSettings',this.providerSettings)
			// console.log('this.teams',this.teams)
			// console.log('this.providersTeams',this.providersTeams)
			// console.log('this.memberCount',this.memberCount)
			// console.log('this.allDatesToCheck',this.allDatesToCheck)
			// console.log('this.perDateSpots',this.perDateSpots)
			// console.log('this.bookingSpots',this.bookingSpots,this.bookingSpotEndArray)
			// console.log('this.minProvidersReq',this.minProvidersReq, this.bookinglength)

			let response = this.scheduleServ.checkProvidersAvailability(month, this.BKFrm, this.applicableProviderIds, this.allDatesToCheck, this.bookingsForProviders, this.providerSettings, this.providerWorkingHours, this.perDateSpots, this.pairableProviders, this.memberCount, this.bookinglength, this.minProvidersReq, this.globals.allPair, this.globals.enableJobLength, this.bookingSpotEndArray, this.globals.divideJobLength,this.prefilledData)

			this.perSpotProviders = response.perSpotProviders
			this.perProviderSpots = response.perProviderSpots
			this.providerSkipDates = response.providerSkipDates
			this.teamIds = (this.teamIds).concat(response.teamIds)

			// console.log('teamIds', this.teamIds)

			// let availResponse = this.scheduleServ.prepareAvailableSpots( this.perProviderSpots, this.perSpotProviders, this.perDayRecurringDates[+month], this.minProvidersReq, this.globals.checkAvailabilityFor)
			let availResponse = this.scheduleServ.prepareAvailableSpots( this.perProviderSpots, this.perDayRecurringDates[+month], this.minProvidersReq,this.teamIds, this.holidays,this.providerSkipDates)
			// console.log('this.providerSkipDates',this.providerSkipDates)
			this.notAvailableDates = this.notAvailableDates.concat(availResponse.notAvailableDates)
			this.perSpotAvailProviders = availResponse.perSpotAvailProviders
			// console.log('this.perDateSpots',this.perDateSpots,'response', response,'availResponse',availResponse)
			if(this.perDateAvailSpots[+month] === undefined){
				this.perDateAvailSpots[+month] = {};
			}
			this.perDateAvailSpots[+month] = availResponse.perDateAvailSpots;
			// Due to live issue on recommendation by vikram sir, fix this issue
			this.prepareAvailableDates(month);
			if(Object.keys(this.perDateAvailSpots[+month]).length == 0 && month == this.months[0]['month']){
				this.slideOneConfig.initial = this.slideTwoConfig.initial = 1;
				if(this.sliderOne){
					this.sliderOne.moveToIdx(1);
					this.sliderOne.update(this.slideOneConfig);
				}
				if(this.sliderTwo){
					this.sliderTwo.moveToIdx(1);
					this.sliderTwo.update(this.slideTwoConfig);
				}
				this.changeMonth(1)
			}
			else{
				this.dataPrepared()
			}
		}
		else{
			// Due to live issue on recommendation by vikram sir, fix this issue
			this.prepareAvailableDates(month)
			this.dataPrepared();
		}
		// console.log('perDateAvailSpots',this.perDateAvailSpots,'month',month,this.months[0]['month'], this.notAvailableDates)
	}

	/* function to prepare available dates */
	prepareAvailableDates(month:number){
		if(this.perDateAvailSpots[+month] && !this.parsedMonths.includes(+month)){
			let tempAvailDates = Object.keys(this.perDateAvailSpots[+month])
			tempAvailDates = tempAvailDates.filter( ( el ) => !(this.notAvailableDates).includes( el ) );
			this.availableDates = this.availableDates.concat(tempAvailDates)
			// console.log('this.availableDates',this.availableDates,this.notAvailableDates)
			this.parsedMonths.push(+month)
		}
		// console.log('this.parsedMonths',this.parsedMonths)
	}

	/* function to confirm data loading and show calendar */
	dataPrepared(){
		// console.log('isSpotAvailable', this.isSpotAvailable, this.selectedDate, this.perSpotProviders)
		this.monthsReady = true
		this.isDataLoaded["months"] = true
		if(!this.isSpotAvailable && this.selectedDate && (this.perSpotProviders[this.selectedDate] || this.globals.scheduleType == 'manually')){
			let selectedDateSplit = (this.selectedDate).split("-")
			this.selectMultiStepDateFun(selectedDateSplit[0],selectedDateSplit[1],selectedDateSplit[2], false)
		}
		this.loader.hide(this.loaderId);
		this.cDRef.detectChanges();
	}

	selectFirstAvailabel(){
		let tempAvailDates = this.availableDates.filter( ( el:any ) => !(this.notAvailableDates).includes( el ) );
		// console.log(tempAvailDates, this.notAvailableDates, this.availableDates, this.perDateAvailSpots)
		if((tempAvailDates).length > 0 ){
			let date = (tempAvailDates[0]).split("-");
			let indexOfMonth = (this.monthArray).indexOf(date[1])
			this.moveToMonth(indexOfMonth)
			if(this.activeMonth == date[1] || !this.activeMonth){
				this.selectMultiStepDateFun(date[0],date[1],date[2], true)
			}
		}
		else{
			// TODO - Show message to customer if no date is available
		}
	}

	ngOnDestroy():void {
		if (this.sliderOne) {this.sliderOne.destroy();}
		if (this.sliderTwo) {this.sliderTwo.destroy();}
	}

	/**Initializer Slider */
	initSlider(){
		setTimeout(()=>{
			if(!this.calendarMount){
				if(this.sliderTwoRef){
					if (this.sliderTwo) {this.sliderTwo.destroy();}
					this.sliderTwo = new KeenSlider(this.sliderTwoRef.nativeElement,this.slideTwoConfig);
				}
				if(this.sliderOneRef){
					if (this.sliderOne) {this.sliderOne.destroy();}
					this.sliderOne = new KeenSlider(this.sliderOneRef.nativeElement,this.slideOneConfig,[MonthPlugin(this.sliderTwo)]);
				}
				this.calendarMount = true;
			}
			this.showCalendar = true;
			this.cDRef.detectChanges();
		})
	}

	/* function to move slider to specific month */
	moveToMonth(monthIndex:number){
		this.slideOneConfig.initial = this.slideTwoConfig.initial = monthIndex;
		if(this.sliderOne){
			this.sliderOne.moveToIdx(monthIndex,true,{'duration':500});
		}
		if(this.sliderTwo){
			this.sliderTwo.moveToIdx(monthIndex,true,{'duration':500});
		}
		this.changeMonth(monthIndex)
	}
}
