import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieServ, UtilServ } from '../Services';
@Component({
	selector: 'bk-existing-page',
	template: `<router-outlet></router-outlet>`
})
export class ExistingPageComponent implements OnInit {
	// Variables
	queryParams: any = null;

	constructor(private utilServ: UtilServ, private actRoute: ActivatedRoute,private cookieServ: CookieServ) {
		this.utilServ.setPageSett(0,null);
		// Set the campaign cookies
		this.campaignCookies();
	}

	ngOnInit(): void {
	}

	/**
	 * Create the campaign cookies
	 * ses_id, campaign_id,contact_id, track_from, sequence_id
	 */
	private campaignCookies(): void {
		// Store query parameters
		if(this.actRoute.snapshot.queryParamMap){
			let queryParamMap: any = this.actRoute.snapshot.queryParamMap;
			if(queryParamMap.params && (Object.keys(queryParamMap.params)).length > 0){
				this.queryParams = queryParamMap.params
			}
		}
		// Create cookie
		if(this.queryParams && (Object.keys(this.queryParams)).length > 0){
			for(let key of Object.keys(this.queryParams)){
				this.cookieServ.createCookie(key, this.queryParams[key], 0);
			}
		}
	}
}