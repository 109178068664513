<div class="position-relative">
	<div [ngClass]="variationId == 'bk_short_form_V3' ? 'form_version_3' : 'form_version_4 row'" [formGroup]="itemsForm" [id]="section?.id" *ngIf="section">
		<div [ngClass]="variationId == 'bk_short_form_V4' ? 'col-md-8 mx-auto' : ''">
			<!-- Title -->
			<div class="mb-30" *ngIf="section?.title">
				<h4 class="mb-0 bk-title" [ngClass]="variationId == 'bk_short_form_V3' ? 'fw-bold' : 'display-5 text-center fw-normal'" [id]="sectionIds?.title"  [innerHTML]="section?.title | safeHtml"></h4>
			</div>
			<div [ngClass]="{'combine-component row justify-content-center' : variationId == 'bk_short_form_V4'}">
				<div [ngClass]="{'col-md-6' : variationId == 'bk_short_form_V4', 'position-absolute':!(services && services.length > 1)}">
					<!-- Service category -->
					<bk-short-form-service-cat
						[sectionIds]="sectionIds"
						[bookingType]="bookingType"
						[variationId]="variationId"
						[section]="parentSection?.service"
						[serviceForm]="itemsForm"
						[selectedLocation]="selectedLocation"
						[selectedServiceType]="selectedServiceType"
						[selectedFrequency]="selectedFrequency"
						[settings]="settings"
						(serviceChange)="serviceChange.emit($event)"
						(hourlyServiceChange)="hourlyServiceChange.emit()"
						(getServices)="services=$event"
						[isShortForm]="true"
					>
					</bk-short-form-service-cat>
				</div>
				<div [ngClass]="{'col-md-6' : variationId == 'bk_short_form_V4'}">
					<!-- Frequency -->
					<bk-short-form-frequency
						[sectionIds]="sectionIds"
						[variationId]="variationId"
						[bookingType]="bookingType"
						[section]="parentSection?.frequency"
						[frequencyForm]="itemsForm"
						[selectedLocation]="selectedLocation"
						[selectedServiceType]="selectedServiceType"
						[selectedFrequency]="selectedFrequency"
						[settings]="settings"
						(frequencyChange)="frequencyChange.emit($event)" >
					</bk-short-form-frequency>
				</div>
			</div>
			<bk-short-frm-hourly
				[sectionIds]="sectionIds"
				[bookingType]="bookingType"
				[variationId]="variationId"
				[section]="parentSection?.service"
				[serviceForm]="itemsForm"
				[selectedLocation]="selectedLocation"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				[settings]="settings"
				(serviceChange)="serviceChange.emit($event)"
				(hourlyServiceChange)="hourlyServiceChange.emit()">

			</bk-short-frm-hourly>
			<!-- Items -->
			<div id="item-section-id" *ngIf="items && items.length > 0">
				<div class="my-20" [id]="section?.sel_item_id" [ngClass]="{'card shadow-sm p-20 rounded' : variationId == 'bk_short_form_V4'}">
					<!-- Label -->
					<label [id]="sectionIds?.label" [ngClass]="{'text-center' : variationId == 'bk_short_form_V4'}" *ngIf="section?.sel_item" class="form-label bk-form-label" [innerHTML]="section?.sel_item | safeHtml"></label>
					<div id="vehicle_selection_area" class="tjs-extra-slider tjs-extra mx-0 position-relative">
						<div class="keen-slider" #itemSlide [attr.dir]="initServ.selectedLang?.rtl ? 'rtl' :'ltr'">
							<div class="keen-slider__slide" *ngFor="let item of items;trackBy: utilServ.trackByFnIndex">
								<div class="tjs-extra__list text-center mx-5 tjs-extra-slide--sm">
									<div class="border rounded-2 position-relative mb-5 selected-extra--item" [ngClass]="{'selected-extra': item?.id == selectedItem?.id}">
										<label for="item-{{item?.id}}" class="d-flex tjs-pointer justify-content-center align-items-center h-100 w-100 mb-0">
											<span class="d-flex align-items-center justify-content-center position-absolute end-0 start-0 bottom-0 top-0">
												<!-- Image -->
												<span class="tjs-extra__img m-auto">
													<img loading="auto" width="21" height="20" [src]="utilServ.getImgUrl(item?.icon_url)" alt="item" class="img-fluid bk-img" (error)="utilServ.handleImgError($event, initServ?.img?.DeepCleaning)" />
												</span>
												<!-- Radio button -->
												<input id="item-{{item?.id}}" class="form-check-input" type="radio" name="item" value="{{item?.id}}" (change)="itemSelect(item)" [attr.disabled]="isDisabled">
											</span>
											<span class="card-img-overlay tjs-transition--base tjs-overlay__dark d-flex align-items-center rounded-2 p-0 bk_sel_extra"></span>
										</label>
									</div>
									<p class="fs-14 mb-0 bk-form-sub-label">
										<span [id]="sectionIds?.label">{{item?.display_name | translate}}</span>
										<span *ngIf="['reschedule', 'draft'].includes(bookingType) && item?.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
										<!-- Tooltip -->
										<bk-tooltip *ngIf="item?.show_tooltip_icon && item?.tooltip_text" customClass="bk-tooltip" content="{{item?.tooltip_text | translate}}"></bk-tooltip>
									</p>
								</div>
							</div>
						</div>
						<ng-container *ngIf="((windowWidth >= 768) && (items.length > 3)) || ((windowWidth >= 451) && (windowWidth <= 767) && (items.length > 2)) || ((windowWidth <= 450) && (items.length > 1))">
							<button (click)="itemSlider.prev()" type="button" class="btn btn-link tjs-prev-arrow text-body start-0 tjs-pointer position-absolute tjs-hover-text__primary slider-arrow"><i class="tjsicon-left-arrow"></i></button>
							<button (click)="itemSlider.next()" type="button" class="btn btn-link tjs-next-arrow end-0 text-body position-absolute tjs-pointer tjs-hover-text__primary slider-arrow"><i class="tjsicon-right-arrow"></i></button>
						</ng-container>
					</div>
				</div>
				<!-- Addons -->
				<div class="my-20" [id]="section?.choose_addon_id" [ngClass]="{'card shadow-sm p-20 rounded' : variationId == 'bk_short_form_V4'}" *ngIf="selectedItem && addons && addons[selectedItem?.id] && (addons[selectedItem?.id]).length > 0">
					<label [ngClass]="{'mb-5 text-center mb-10' : variationId == 'bk_short_form_V4'}" [id]="sectionIds?.label" *ngIf="section?.choose_addon" class="mb-15 me-5 form-label bk-form-label" [innerHTML]="section?.choose_addon | safeHtml"></label>
					<div class="tjs-extra-slider tjs-extra mx-0 vehicle_selection_area position-relative">
						<div class="keen-slider" #addonSlide [attr.dir]="initServ.selectedLang?.rtl ? 'rtl' :'ltr'">
							<div class="keen-slider__slide" *ngFor="let addon of addons[selectedItem?.id];trackBy: utilServ.trackByFnIndex">
								<div class="tjs-extra__list text-center mx-5 tjs-extra-slide--sm">
									<div class="border rounded-2 position-relative mb-5 overflow-hidden selected-extra--item" [ngClass]="{'selected-extra': (selectedAddonIds && (selectedAddonIds).length > 0 && (selectedAddonIds).includes(+addon?.id))}" >
										<label for="addon-{{addon?.name}}" class="d-flex tjs-pointer justify-content-center align-items-center h-100 w-100 mb-0">
											<span class="d-flex align-items-center position-absolute start-0 end-0 top-0 bottom-0 justify-content-centent">
												<span class="tjs-extra__img m-auto">
													<img loading="auto" width="21" height="20" [src]="utilServ.getImgUrl(addon?.icon_url)" alt="addon" class="img-fluid" (error)="utilServ.handleImgError($event, initServ?.img?.DeepCleaning)" />
												</span>
												<!-- Checkbox -->
												<input id="addon-{{addon?.name}}" class="form-check-input bk-checkbox" type="checkbox" (change)="selectAddon($event,addon)" [attr.disabled]="isDisabled" [attr.checked]="(selectedAddonIds && (selectedAddonIds).length > 0 && (selectedAddonIds).includes(+addon?.id)) ? 'checked' : null">
											</span>
											<span class="card-img-overlay tjs-transition--base tjs-overlay__dark d-flex align-items-center rounded-2 p-0 bk_sel_extra"></span>
										</label>
									</div>
									<p class="fs-14 mb-0 bk-form-sub-label">
										<span [id]="sectionIds?.label">{{utilServ.getFormParamName(addon) | translate}}</span>
										<span *ngIf="['reschedule', 'draft'].includes(bookingType) && addon?.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
										<!-- Tooltip -->
										<bk-tooltip *ngIf="addon?.show_tooltip_icon && addon?.tooltip_text" customClass="bk-tooltip" content="{{addon?.tooltip_text | translate}}"></bk-tooltip>
									</p>
								</div>
							</div>
						</div>
						<ng-container *ngIf="((windowWidth >= 768) && ((addons[selectedItem?.id]).length > 3)) || ((windowWidth >= 451) && (windowWidth <= 767) && ((addons[selectedItem?.id]).length > 2)) || ((windowWidth <= 450) && ((addons[selectedItem?.id]).length > 1))">
							<button (click)="addonSlider.prev()" type="button" class="btn btn-link tjs-prev-arrow text-body start-0 tjs-pointer position-absolute tjs-hover-text__primary slider-arrow"><i class="tjsicon-left-arrow"></i></button>
							<button (click)="addonSlider.next()" type="button" class="btn btn-link tjs-next-arrow end-0 text-body position-absolute tjs-pointer tjs-hover-text__primary slider-arrow"><i class="tjsicon-right-arrow"></i></button>
						</ng-container>
					</div>
				</div>

				<!-- Add item button -->
				<div class="mt-20" *ngIf="selectedItem && selectedAddonIds && (selectedAddonIds).length > 0">
					<bk-navigate [secId]="section?.add_item_btn?.id" [designId]="section?.add_item_btn?.design_id" [innerHTML]="section?.add_item_btn?.content" customClass="btn btn-lg btn-primary border-transparent" essentialClass="w-100 d-flex align-items-center justify-content-center mt-20 mb-20" (callback)="addItem()" [btnDisabled]="isDisabled"></bk-navigate>
				</div>
				<!-- Selected item and addon -->
				<div class="my-20" [ngClass]="{'card shadow-sm p-20 rounded' : variationId == 'bk_short_form_V4'}" *ngIf="itemsForm.controls['items'] && (itemsForm.value['items']).length > 0">
					<label [ngClass]="{'text-center' : variationId == 'bk_short_form_V4'}" [id]="sectionIds?.label" *ngIf="section?.sel_items" class="form-label bk-form-label" [innerHTML]="section?.sel_items | safeHtml"></label>
					<div class="d-flex flex-column-reverse">
						<div class="card tjs-bg-gray mb-30 tjs-mb-first-0 tjs-d-border" [ngClass]="{'border': isMultiStepForm}" *ngFor="let item of itemsForm.value['items']; let index = index;trackBy: utilServ.trackByFnIndex">
							<div class="card-body">
								<div class="d-flex align-items-center justify-content-between mb-15">
									<h5 class="fw-bold">
										<span [id]="sectionIds?.label">{{bkngFormServ.getElementName(item, 'items') | translate}}</span>
										<span *ngIf="['reschedule', 'draft'].includes(bookingType) && item?.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
									</h5>
									<button type="button" class="btn  p-0 tjs-btn-close bg-white text-danger tjs-transition--base rounded-circle fs-12" data-placement="top" data-toggle="tooltip" title="Delete" (click)="confirmPopup(index)" [attr.disabled]="isDisabled">
										<i class="tjsicon-close"></i>
									</button>
								</div>
								<div class="d-sm-flex align-items-center justify-content-between">
									<div *ngIf="item.addons && (item.addons).length > 0">
										<div class="my-5" *ngFor="let addon of item.addons;trackBy: utilServ.trackByFnIndex">
											<span class="text-capitalize" [id]="sectionIds?.label">{{bkngFormServ.getElementName(addon, 'addons') | translate}}</span>
											<span *ngIf="['reschedule', 'draft'].includes(bookingType) && addon?.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span>
										</div>
									</div>
									<div *ngIf="item?.enable_quantity_based == 'yes'">
										<span class="tjs-pcounter d-flex rounded text-center overflow-hidden mt-20 mt-sm-0">
											<button class="tjs-pcounter__btn fs-24 border-0 p-0 text-white rounded-left tjs-pcounter__btn-dec bk_custom_extra_decrement" type="button" (click)="itemCountChange(index,item,'decrement')" [attr.disabled]="isDisabled">-</button>
											<input type="text" class="tjs-pcounter__number border-0 text-center bg-white" [value]="item?.quantity" readonly>
											<button class="tjs-pcounter__btn fs-24 border-0 p-0 text-white rounded-right tjs-pcounter__btn-inc bk_custom_extra_increment" type="button" (click)="itemCountChange(index,item,'increment')" [attr.disabled]="isDisabled">+</button>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>