import { Component, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../../Services';
// L
import { ZipcodeComponent } from '../../index';

@Component({
	selector: 'bk-multi-zipcode',
	templateUrl: './MultiZipcode.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class MultiZipcodeComponent extends ZipcodeComponent {

}