import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// Services
import { NgOnDestroy } from 'src/app/Services';

@Component({
	selector: 'bk-verify-email-msg',
	template: `<p class="d-flex justify-content-center">Success! Check your email to activate your Tableau trial and verify your email address.</p>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class VerifyEmailMsgComponent {

	// Variables

	constructor(public router: Router, public actRoute: ActivatedRoute) {}
}
