import { Component, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
// Services
import { NgOnDestroy } from '../../../Services';
// Inheritance components
import { ItemsWithPckgsComponent } from '../../../Global/BookingForm';

@Component({
	selector: 'bk-short-form-items-with-pckgs',
	templateUrl: './ShortFrmItemsWithPckgs.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ShortFrmItemsWithPckgsComponent extends ItemsWithPckgsComponent{
	@Input() variationId: string = '';
	@Input() parentSection: any;
	@Input() sectionIds: any;

	@Output() serviceChange: EventEmitter<any> = new EventEmitter();
	@Output() hourlyServiceChange: EventEmitter<any> = new EventEmitter();
	@Output() frequencyChange: EventEmitter<any> = new EventEmitter();
	services: any;
	windowWidth :any = window.innerWidth;


	packageDescPopup(selectedPackage: any){
		let text :string = '<div class="text-start">'+selectedPackage+'</div>'
		// this.bkngFormServ.msgPopup(text);
		this.bkngFormServ.newMsgPopup(text)
	}

}