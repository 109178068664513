<div [id]="secId" class="py-35 {{variationClass[pageSett[secId]?.variation_id]}}" *ngIf="rcServ && pageSett && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div class="container">
		<ng-container *ngIf="(pageSett[secId]?.variation_id == 'bk_location_map_V1' || pageSett[secId]?.variation_id == 'bk_location_map_V3') else variationV2">
			<!-- Header -->
			<div class="row" *ngIf="section?.title?.content || section?.desc?.content">
				<div class="col-12 col-lg-9 mx-auto">
					<ng-container *ngTemplateOutlet="titleTemp; context:{section:section, center:'text-center'}"></ng-container>
				</div>
			</div>
			<ng-container *ngIf="(section?.list && section?.list?.items && (section?.list?.items).length > 0) || (section?.media && section?.media?.urls && (section?.media?.urls).length > 0)">
				<!-- List -->
				<div [id]="section?.list?.id">
					<ul class="tjs-location__list d-flex flex-wrap list-unstyled pt-lg-70 pt-15 bk-list">
						<ng-container *ngTemplateOutlet="listTemp; context:{section:section, margin:'my-5', bold:'fw-bold'}"></ng-container>
					</ul>
				</div>
				<!-- Media -->
				<div class="tjs-location__image tjs-location__image-v2 text-center d-lg-flex align-items-center flex-column" [class.mt-0]="!(section?.list) || (section?.list?.items && (section?.list?.items).length <= 0)" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0">
					<img loading="auto" [id]="section?.media?.id" alt="location" class="img-fluid bk-media" [src]="section?.media?.urls[0]"  width="550" height="300" />
				</div>
			</ng-container>
		</ng-container>
		<!-- Variation v2 -->
		<ng-template #variationV2>
			<div class="row justify-content-between">
				<div class="col-12 col-md-6 col-lg-5">
					<!-- Header -->
					<ng-container *ngIf="section?.title?.content || section?.desc?.content">
						<ng-container *ngTemplateOutlet="titleTemp; context:{section:section}"></ng-container>
					</ng-container>
					<!-- List -->
					<div [id]="section?.list?.id" *ngIf="section?.list && section?.list?.items && (section?.list?.items).length > 0" >
						<ul class="tjs-location__list row flex-wrap list-unstyled bk-list">
							<ng-container *ngTemplateOutlet="listTemp; context:{section:section, margin:'mb-15'}"></ng-container>
						</ul>
					</div>
				</div>
				<!-- Media -->
				<div class="col-12 col-md-6 col-lg-6 mt-30 mt-md-0">
					<div class="tjs-location__image" *ngIf="section?.media && section?.media?.urls && (section?.media?.urls).length > 0">
						<img loading="auto" [id]="section?.media?.id" alt="location" class="img-fluid bk-media" [src]="section?.media.urls[0]" width="550" height="300">
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>
<!-- Title template -->
<ng-template #titleTemp let-section="section" let-center="center">
	<div class="tjs-section__title {{center}}">
		<!-- Title -->
		<div class="tjs-section__heading mb-30" *ngIf="section?.title?.content">
			<h3 [id]="section?.title?.id" class="fw-bold display-5 bk-title" [innerHTML]="section?.title?.content | safeHtml"></h3>
		</div>
		<!-- Description -->
		<div class="tjs-section__description" [ngClass]="{'mb-35' : pageSett[secId]?.variation_id == 'bk_location_map_V2'}" *ngIf="section?.desc?.content">
			<p [id]="section?.desc?.id" class="bk-desc" [innerHTML]="section?.desc?.content | safeHtml"></p>
		</div>
	</div>
</ng-template>
<!-- List template -->
<ng-template #listTemp let-section="section" let-margin="margin" let-bold="bold">
	<ng-container *ngFor="let item of section?.list?.items;trackBy: secServ.trackByFnIndex">
		<li class="tjs-location__item col-md-6 d-flex align-items-center {{margin}} bk-item" *ngIf="item.title">
			<div class="d-flex align-items-center">
				<i class="tjsicon-placeholder me-15 tjs-location__icon bk-item-title"></i>
				<address class="tjs-location__address mb-0 {{bold}} bk-item-title" [ngClass]="pageSett[secId]?.variation_id == 'bk_location_map_V2' ? 'fs-18' : 'fs-22'"  *ngIf="item.title"	[innerHTML]="item.title | safeHtml"></address>
			</div>
		</li>
	</ng-container>
</ng-template>