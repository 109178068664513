/* eslint-disable complexity */
import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
// Services
import { NgOnDestroy, UtilServ, InitServ } from '../../../Services';
import { IS_TAB } from '../../../Constants';

@Component({
	selector: 'bk-sidebar',
	templateUrl: './Sidebar.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class SidebarComponent implements OnInit {
	// Variables
	@Input() sidebarSequence: any;
	@Input() sidebarSecOrder: any;
	@Input() section: any;
	@Input() pageSett: any;
	@Input() bookingType: string = '';
	@Input() settings: any;
	@Input() industryId: any;
	@Input() formId : any;
	@Input() isCustomerAllowedRes: boolean = false;
	@Input() prefilledData: any;
	@Input() isStepOneValid: any;
	@Input() formSett: any;
	@Input() couponData: any;
	@Input() BKFrm!: FormGroup;
	@Input() selectedServiceType: any;
	@Input() selectedFrequency: any;
	@Input() selectedPricingParams: any;
	@Input() selectedItems: any;
	@Input() selectedAreaParams: any;
	@Input() selectedExtras: any;
	@Input() selectedExcludes: any;
	@Input() adjustedTimeHours : any = 0;
	@Input() adjustedTimeMin : any = 0;
	@Input() bookingTimeHours : any = 0;
	@Input() bookingTimeMin : any = 0;
	@Input() selectedAddr: any;
	@Input() isMultiStepForm: boolean = false;
	@Input() formMetaData: any;
	@Input() settingsObj: any;
	@Input() formLayout: any;

	@Input() displayTotal: number = 0;
	@Input() displayGiftCardAmount: number = 0;
	@Input() displayReferralDiscount: number = 0;
	@Input() displayFinalAmount: number = 0;
	@Input() displayServicePrice : number = 0;
	@Input() displayFrequencyDiscount : number = 0;
	@Input() displaySpotDiscount : number = 0;
	@Input() displayCouponDiscount : number = 0;
	@Input() displayServiceFee : number = 0;
	@Input() displayDiscountedAmount : number = 0;
	@Input() bookingTaxRate : any;
	@Input() bookingTaxType : any;
	@Input() displaySaleTax : number = 0;
	@Input() displayTipsAmount : number = 0;

	@Input() includeInFreqFieldsPrice: any;
	@Input() exemptFromFreqFieldsPrice: any;
	@Input() nonTaxableFieldsPrice: any;
	@Input() afterDiscTotalFieldsPrice: any;
	@Input() beforePriceableFieldsPrice: any;

	@Input() excludeExpeditedAmountValue : boolean = false;
	@Input() excludeServiceFeeValue : boolean = false;
	@Input() isServiceFeeTaxable : string = 'no';
	@Input() exemptSalesTaxValue : boolean = false;

	@Input() firstRecAptFinalAmount : number = 0;
	@Input() afterNthAptFinalAmount : number = 0;

	@Output() submitBKFrm: EventEmitter<any> = new EventEmitter();
	@Output() rescheduleOnlyInfo : EventEmitter<any> = new EventEmitter();

	ratingPerm: boolean = false;
	smryScrlStatus: boolean = false;
	smryMobileScrlStatus: boolean = true;
	isMobile: boolean = false;
	// isMob: boolean = window.innerWidth <= MOBILE_WIDTH ? true : false;
	isMob: boolean = IS_TAB;

	constructor(private utilServ: UtilServ,private cDRef: ChangeDetectorRef, public initServ: InitServ) {
		this.ratingPerm = this.utilServ.appPermission('rating')
		// Screen type
		let secreenType = this.utilServ.secreenType();
		this.isMobile = (secreenType == 'mobile') ? true : false;
	}

	ngOnInit(): void {
		this.buildSummary();
		let self: any = this;
		window.addEventListener('resize', () => {
			// self.isMob = window.innerWidth <= MOBILE_WIDTH ? true : false;
			self.isMob = IS_TAB;
			self.cDRef.detectChanges();
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange && propName == 'sidebarSequence') {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev){
						this.buildSummary()
						break;
					}
				}
			}
		}
	}
	/**
	 * Build summary
	 */
	private buildSummary(): void {
		if(this.formSett){
			// Scrolling summary
			if((this.formSett.booking_summary_scroll && this.formSett.booking_summary_scroll == 'yes') && this.sidebarSequence && (this.sidebarSequence).length > 0 && (this.sidebarSequence[(this.sidebarSequence).length-1]) == 'summary'){
				this.smryScrlStatus = true;
			}
			if(this.formSett.enable_summary_scroll_mobile && this.formSett.enable_summary_scroll_mobile == 'no'){
				this.smryMobileScrlStatus = false;
			}
		}
		// if(this.isMobile && this.smryMobileScrlStatus && (this.formLayout != 'multi_step')){
		if(this.isMob && this.smryMobileScrlStatus && (this.formLayout != 'multi_step')){
			document.body.classList.add('tjs-summary-mob--enabled');
		} else {
			document.body.classList.remove('tjs-summary-mob--enabled');
		}
		this.cDRef.detectChanges();
	}
}
