import { Component, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
// Services
import { NgOnDestroy, ApiServ, InitServ, RenderComponentServ, SectionServ,StyleServ, UtilServ } from '../../../Services';

@Component({
	selector: 'bk-author-blog',
	templateUrl: './AuthorBlog.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class AuthorBlogComponent implements OnInit {

	// Variables
	currentUrl: any;
	blogs: any;
	limit: number = 20;
	page: number = 1;
	totalRecords: number = 0;
	settings: any;
	pageSlug: any;
	slug: string = '';
	secId: any;
	pageSett: any;
	// Section fields
	section: any = {
		single_post: null,
		blog_list:null,
		description:null,
		recent_posts:null,
		social_list:null,
		subscribe:null
	}
	route: any;
	recentBlogs: any;

	constructor(private actRoute: ActivatedRoute, private apiServ: ApiServ, public initServ: InitServ, @Self() private destroy: NgOnDestroy, public secServ: SectionServ, private rcServ: RenderComponentServ,private styleServ : StyleServ, public utilServ: UtilServ, private router: Router) {
		// Current url without fragment
		this.route = this.utilServ.getUrlWithoutFragment(this.router.url);
		// URL Fragment, based this get the blog data
		let fragment = this.actRoute.snapshot.fragment;
		if(fragment){
			this.page = +fragment;
		}
		// Page slug
		this.slug = this.actRoute.snapshot.params['slug'] ? this.actRoute.snapshot.params['slug'] : '';
		this.pageSlug = this.actRoute.snapshot.params['type'] ? this.actRoute.snapshot.params['type'] : 'blog';
		this.currentUrl = location.pathname.split('/')[1];
		this.pageData();
		this.blogSettings();
		this.getBlogs(true);
	}

	ngOnInit() {
	}
	/**
	 * Get the blog settings, which is get by content management app
	 */
	private blogSettings(): void{
		this.apiServ.callApi('GET', 'BlogsSett').pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'sett'));
	}
	/**
	 * Get the blog based on blog slug
	 */
	private getBlogs(total: boolean = false, type: string = 'blogs'): void{
		let queryParams:any = {};
		if(type == 'recent_posts'){
			queryParams = {limit: 4, content: 'no',status:'publish', language:this.initServ.savedLng }
		} else {
			queryParams = {language:this.initServ.savedLng, limit: this.limit, page: this.page, status: 'publish', sort:'yes', total:total};
			if(this.currentUrl == 'author'){
				queryParams['author_slug']=this.slug;
			} else {
				queryParams['category_slug']=this.slug;
			}
		}
		this.apiServ.callApiWithQueryParams('GET', 'Blogs', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, type));
	}
	/**
	 * Get the blog page data
	 */
	private pageData(): void {
		// Query params
		let queryParams: any = {slug:this.pageSlug,language:this.initServ.savedLng, mode: 'live', theme_slug: this.initServ.theme};
		if(this.initServ.theme){
			queryParams['mode']='preview';
		}
		if(this.initServ.firstPageData && this.initServ.firstPageSlug && this.pageSlug == this.initServ.firstPageSlug && this.apiServ.checkAPIRes(this.initServ.firstPageData) && this.initServ.firstPageData.data){
			setTimeout(() => {
				this.onResultCallback(JSON.parse(JSON.stringify(this.initServ.firstPageData)), 'page');
			}, 0)
		}else{
			this.apiServ.callApiWithPathQueryVars('GET', 'PageData',[0], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'page'));
		}
		this.apiServ.callApiWithPathQueryVars('GET', 'PageStyle',[0], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.styleServ.applyPageStyle(res));
	}
	/**
	 * Build the blog sections fields
	 */
	 private buildSectionFields(pageData: any): void{
		let secElem : any = null;
		if(pageData && pageData.sections){
			secElem = pageData.sections[this.secId];
		}
		if(secElem && this.pageSett && this.pageSett[this.secId] && this.pageSett[this.secId][this.rcServ.statusType]){
			for(let key in this.section){
				if(secElem[key]){
					switch (key) {
						case "description":
						case "recent_posts":
						case "social_list":
						case "subscribe":
							this.section[key] =  this.secServ.buildGroup(secElem[key]);
							if(key == 'recent_posts' && this.section['recent_posts']){
								this.getBlogs(false, 'recent_posts');
							}
							break;
						default:
							this.section[key] = this.secServ.checkElementStatus(secElem[key]);
							break;
					}
				}
			}
		}
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res:any, type: string): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			switch(type){
				case 'blogs':
					if (res.total_record) { this.totalRecords = res.total_record; }
					this.blogs = res.data;
				break;
				case 'sett':
					this.settings = res.data;
				break;
				case 'page':
					let pageData : any = res.data;
					if(pageData.column_sections && (pageData.column_sections).length > 0){
						pageData.added_sections = (pageData.added_sections).filter( (el: any) => !(pageData.column_sections).includes( el ) );
					}
					if(pageData.added_sections && (pageData.added_sections).length > 0){
						for(let secId of pageData.added_sections){
							let secData: any = pageData.section_settings[secId];
							if(secData.slug == 'blog'){
								this.secId = secId;
							}
						}
						if(pageData && pageData.settings){
							let pageSett: any = res.data.settings;
							if(pageSett.redirect_status && pageSett.redirect_status == 'yes' && (pageSett.redirect_link_to == 'page' || pageSett.redirect_link_to == 'web') && pageSett.redirect_link_url){
								this.utilServ.redirectPageURL(pageSett); // Redirect page url
							} else {
								// Theme popups
								// this.rcServ.loadThemePopups(pageSett);
								// Page title
								this.rcServ.setPageTitle(pageSett);
								// Add tracking codes added in page settings
								this.rcServ.addTrackingCodes(pageSett);
								// Page setting
								this.utilServ.setPageSett(res.data._id, pageSett);
							}
						}
						// Reset the page api hit count;
						this.rcServ.setPageApiHitCount = 0;
						// Build section data
						if(this.secId && pageData){
							this.pageSett = pageData.section_settings;
							this.secServ.setServData(this.pageSett, pageData.content);
							this.buildSectionFields(pageData);
						}
					} else {
						this.utilServ.redirectBrokenUrl();
					}
					this.initServ.firstPageData = null;
					this.initServ.firstPageSlug = null;
				break;
				case 'recent_posts':
					this.recentBlogs = res.data;
				break;
			}
		}
	}
	/**
	 * Get the page number, get the referrals based on page number
	 */
	public pageChange(page: number): void {
		this.page = page;
		this.router.navigateByUrl(this.route+'#'+page);
		this.getBlogs();
	}
}