import { Component, OnInit, Self, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
// External libs
import { takeUntil } from "rxjs";
// Services
import { ApiServ, InitServ, NgOnDestroy, UtilServ } from "src/app/Services";
// Interfaces
import { BillingCard, BillingCardApiRes } from "src/app/Interfaces";
@Component({
	selector: 'bk-add-payment',
	template: `
	<ng-container *ngIf="isPlanPermission && uId; else permissionDenied">
		<bk-add-card-popup *ngIf="buildForm" [secId]="secId" [uId]="uId" [cards]="cards" slug="add_payment" [isPage]="true"></bk-add-card-popup>
	</ng-container>
	<ng-template #permissionDenied>
		<div class="py-60" *ngIf="callApi">
			<div class="container">
				<div class="card">
					<div class="card-body d-flex justify-content-center gap-15 flex-column align-items-sm-center">
						<h4 class="mb-0 fw-bold">{{!isPlanPermission ? initServ.appStr?.msg?.accessAddPayment : initServ.appStr?.msg?.addPaymentTokenExpired}}</h4>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class AddPaymentComponent implements OnInit {
	uId: string | number | null = '';
	cards: BillingCard[] = [];
	secId: string = '';
	locations: any = [];
	stripeIds: any = [];
	stripeBaseId: any;
	buildForm: boolean = false;
	isPlanPermission: boolean = true;
	userToken: string | null = '';
	callApi: boolean = false;

	constructor(public initServ: InitServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, private actRoute: ActivatedRoute, public utilServ: UtilServ){
		this.isPlanPermission = this.initServ.appPlansPermission('Add Payment');
		this.userToken = (this.actRoute.snapshot.params && this.actRoute.snapshot.params['token']) ? this.actRoute.snapshot.params['token'] : null;
		if(this.isPlanPermission){
			this.utilServ.loadAddrAndPaymentScript();
		} else {
			this.callApi = true;
		}
	}

	ngOnInit(): void {
		if(this.isPlanPermission && this.userToken){
			this.getUserIdFromAccessToken();
		}
	}

	/**
	 * Asynchronously validates the user's access token and retrieves the user ID (uId).
	 * If the user has plan permissions, it waits for a short period to load the scripts,
	 * then sets up the form and loads specific information based on the payment gateway.
	 * If the payment gateway is 'stripe', it loads unique locations and fetches user cards if uId is obtained.
	 * Finally, it sets a flag indicating that the API call has been made.
	 * @returns {Promise<void>}
	 */
	private async getUserIdFromAccessToken(): Promise<void> {
		this.uId = await this.utilServ.validateUserAccessTokenApi(this.userToken);
		if(this.isPlanPermission){
			// Wait for load the scripts
			setTimeout(() => { this.buildForm = true; }, 500);
			if(this.initServ.paymentGateway == 'stripe'){
				this.loadUniqueLocs();
				if(this.uId){
					this.fetchUserCards();
				}
			}
		}
		this.callApi = true;
	}

	/**
	 * loads unique locations and their corresponding location data.
	 */
	private async loadUniqueLocs(): Promise<void> {
		let data: any = await this.utilServ.uniqueLocations();
		this.stripeIds = data.stripeIds;
		this.locations = data.locations;
		this.stripeBaseId = data.stripeBaseId;
	}

	/**
	 * Fetch the user cards based on uid, location id
	 */
	private fetchUserCards(): void{
		let baseLocId: string | number = 0;
		// If stripe ids are different on different locations isSame 0 other wise 1
		let isSame: string = (this.stripeIds && this.stripeIds.length > 1) ? '0' : '1';
		this.apiServ.callApiWithPathQueryVars('GET', 'PublicUserCards', [this.uId], {location_id: baseLocId, isSame}).pipe(takeUntil(this.destroy)).subscribe((res: BillingCardApiRes) => this.handleCardsApiRes(res));
	}

	/**
	 * Handles the response from the cards API.
	 * @param res API response
	 */
	private handleCardsApiRes(res: BillingCardApiRes): void{
		// Resetting the 'cards' variable to null.
		this.cards = [];
		if (this.apiServ.checkAPIRes(res) && res?.data) {
			this.cards = res.data;
		}
	}
}
