import { ChangeDetectorRef, Component, ElementRef, OnInit, Self, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { takeUntil } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// External lib
import { ToastrService } from "ngx-toastr";
import SignaturePad from 'signature_pad';
// Services
import { ApiServ, CacheService, InitServ, LoaderServ, NgOnDestroy, UtilServ } from "../../../Services";
// Constant
import { OTP_CODE_MIN_LENGTH, SIGNATURE_REG_EXP } from "src/app/Constants";
// Interface
import { Contract } from "../Invoice";
import { APIRes } from "src/app/Interfaces";

interface Signature { signature: string}
interface ContractForm {
	inv_id: string,
	contract_accepted: boolean,
	cust_signatures: Signature[],
	contract_form: string,
	otp?: number,
	contract_form_timestamp?: string
}
@Component({
	selector: 'bk-contract-popup',
	templateUrl: './ContractPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ContractPopupComponent implements OnInit {

	contractForm!: FormGroup;
	contractData!: Contract;
	invId: string = '';
	slug: string = 'contract';
	secId: string = '';
	section: any = { title: null, contract: null };
	loaderId: string = 'contract-loader'
	@ViewChild('sPad') signCanvasElement : ElementRef<HTMLCanvasElement> | undefined;
	isSignatureNull: boolean = false;
	verifyCode: boolean = true;
	signature: string[] | undefined | null;
	signaturePad: SignaturePad | undefined;
	signCanva: {height: number, width: number} = {
		height:180,
		width: 548
	}
	resetTimer: boolean = false;
	resendCode: boolean = false;
	displayTimer: string = '';
	minutes: number = 2;

	// eslint-disable-next-line max-params
	constructor(public dialogRef: MatDialogRef<ContractPopupComponent>, private cacheServ: CacheService, public cDRef: ChangeDetectorRef, private frmBldr: FormBuilder, public utilServ: UtilServ, private apiServ: ApiServ, @Self() public destroy: NgOnDestroy, private toastr: ToastrService, private loader: LoaderServ, public initServ: InitServ) {
	}

	ngOnInit(): void {
		this.buildSecData();
		this.contractForm = this.frmBldr.group({
			inv_id: [this.invId],
			contract_accepted: [false, [Validators.requiredTrue]],
			cust_signatures: this.frmBldr.array([this.frmBldr.group({signature: []})]),
			contract_form: [''],
			otp:[null],
			contract_form_timestamp: ['']
		});
	}

	/**
	 * Builds the popup section data and initializes the signature pad if required.
	 * - Creates an object (`popupData`) containing necessary details for the popup section.
	 * - Calls the `buildSectionData` method of `cacheServ` service to build the section data.
	 * - Upon completion of the promise:
	 *		- Sets the `secId` and `section` variables from `cacheServ`.
	 *		- If `add_signature` is 'yes' and `signCanvasElement` is available, initializes the `SignaturePad`.
	 *		- Attaches an event listener to `SignaturePad` for the 'beginStroke' event to trigger `beginStroke` method.
	 * - Triggers change detection to update the view.
	 */
	private buildSecData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: null };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
			setTimeout(() => {
				if(this.contractData?.add_signature == 'yes' && this.signCanvasElement){
					this.signaturePad = new SignaturePad(this.signCanvasElement.nativeElement);
					this.signaturePad.addEventListener('beginStroke', () => {
						this.beginStroke();
					});
				}
			}, 100);
			this.cDRef.detectChanges();
		});
	}

	/**
	 * Initiates a stroke in the signature pad.
	 * This function is called when a stroke begins in the signature pad.
	 * It updates the `isSignatureNull` flag if the signature is initially null,
	 * and triggers change detection to reflect updates in the UI.
	 */
	public beginStroke(): void {
		if (this.isSignatureNull) {
			this.isSignatureNull = false;
		}
		this.cDRef.detectChanges();
	}

	/**
	* Clears the signature from the signature pad.
	* This function clears the signature pad and resets the `signature` variable to null.
	*/
	public clearSignature(): void {
		this.signaturePad?.clear();
		this.signature = null;
	}

	/**
	* Checks if the signature is valid.
	* This function verifies whether a signature is required and if it has been provided.
	* If signature addition is enabled in the contract data and no signature exists,
	* it checks if the signature pad is not empty.
	* @returns boolean - True if the signature is valid, otherwise false.
	*/
	private validSignature(): boolean {
		return (this.contractData?.add_signature == 'yes' && !this.signature) ? (!this.signaturePad?.isEmpty()) : true;
	}

	/**
	 * Fills the signature in the form data if required.
	 * This function checks if adding a signature is required and if the signature is absent.
	 * If adding a signature is necessary and no signature exists,
	 * it extracts the signature from the signature pad and applies regex to match the signature format.
	 * If a valid signature is found, it updates the `formVal` object with the signature.
	 * If adding a signature is not required, it clears the `cust_signatures` array in the `formVal`.
	 * @param formVal - The form data object to be filled with the signature.
	 * @returns The updated form data object with the signature.
	 */
	private filledSignature(formVal: ContractForm): ContractForm {
		// Check if adding signature is required and if signature is absent
		if(this.contractData?.add_signature == 'yes' && !this.signature){
			this.isSignatureNull = false;
			// Extract the signature from signaturePad and apply regex to match the signature format
			this.signature = this.signaturePad?.toDataURL().match(SIGNATURE_REG_EXP);
			// If a signature is found and the length is greater than 0, update formVal with the signature
			if(this.signature && this.signature.length>0){
				formVal['cust_signatures'][0]['signature'] = this.signature[2];
			}
		} else {
			formVal['cust_signatures'] = [];
		}
		return formVal;
	}

	/**
	 * Handles the continuation process after form submission.
	 * This function first checks if the contract form is empty and logs an error if it is.
	 * Then, it validates the contract form and the signature.
	 * If both are valid, it fills the signature in the form data and proceeds based on signature and verification settings.
	 * If adding a signature is required and signature verification is enabled, it either generates an OTP or validates the existing one.
	 * Otherwise, it directly saves the contract without OTP verification.
	 * If the form or signature is invalid, it marks all fields as touched to trigger validation messages and updates the flag for null signature.
	 */
	public onContinue(): void | boolean {
		if (!this.utilServ.validatePhantomField(this.contractForm.controls['contract_form_timestamp'].value, 'contract_form_timestamp')) {
			return false;
		}
		// Check if the contract form is valid and the signature is valid
		if (this.contractForm.valid && this.validSignature()) {
			this.acceptContract();
		} else {
			this.handleFormErr();
		}
	}

	/**
	 * Handles the acceptance of a contract, including signature verification and OTP generation/validation.
	 * - Checks if signature and verification are required based on `contractData`.
	 * - If both signature and verification are required:
	 *   - If `verifyCode` is present, calls `generateOtp` to handle OTP generation.
	 *   - If `verifyCode` is not present, prepares the payload and calls `validateOtp` to handle OTP validation.
	 * - If signature and/or verification are not required:
	 *   - Prepares the payload by filling the signature.
	 *   - Removes the `contract_form_timestamp` from the payload.
	 *   - Shows the loader using `loader.show`.
	 *   - Calls `saveContract` to save the contract without OTP verification.
	 */
	private acceptContract(): void {
		// Proceed based on signature and verification settings
		if(this.contractData?.add_signature == 'yes' && this.contractData?.verify_signature == 'yes'){
			if(this.verifyCode){
				this.generateOtp();
			} else {
				let payload: ContractForm = this.filledSignature(this.contractForm.value);
				delete payload['contract_form_timestamp'];
				this.validateOtp(payload);
			}
		} else {
			let payload: ContractForm = this.filledSignature(this.contractForm.value);
			delete payload['contract_form_timestamp'];
			this.loader.show(this.loaderId);
			this.saveContract(payload); // Save the contract without OTP verification
		}
	}

	/**
	 * Handles form errors by marking all fields as touched to trigger validation messages.
	 * This function marks all fields in the contract form as touched to ensure validation messages are displayed.
	 * It also updates the flag for null signature to display an appropriate message.
	 */
	private handleFormErr(): void {
		this.contractForm.markAllAsTouched();
		if (!this.isSignatureNull) {
			this.isSignatureNull = true;
		}
		this.cDRef.detectChanges();
	}

	/**
	* Adds OTP validation to the contract form.
	* This function sets validators for the OTP field, including required validation and minimum length validation.
	* It marks the OTP field as untouched and updates its validity.
	*/
	private addOtpValidation() {
		this.contractForm.controls['otp'].setValidators([Validators.required, Validators.minLength(OTP_CODE_MIN_LENGTH)]);
		this.contractForm.controls['otp'].markAsUntouched();
		this.contractForm.controls['otp'].updateValueAndValidity();
	}

	/**
	 * Generates an OTP (One-Time Password) for invoice verification.
	 * This function triggers the generation of an OTP for invoice verification.
	 * It shows the loader, resets the timer, and sets flags accordingly.
	 * If the function is not triggered by HTML (i.e., isHtml parameter is false), it adds OTP validation to the form.
	 * @param isHtml - A boolean indicating whether the function is triggered by HTML. Default is false.
	 */
	public generateOtp(isHtml:boolean = false): void {
		this.loader.show(this.loaderId);
		this.resetTimer = false;
		this.resendCode = false;
		if(!isHtml){
			this.addOtpValidation();
		}
		this.apiServ.callApiWithPathVariables('GET', 'GenerateInvOtp', [this.contractForm.value?.inv_id]).pipe(takeUntil(this.destroy)).subscribe((res: APIRes) => this.handleInvOtpApiRes(res));
	}

	/**
	* Handles the response from the invoice OTP API.
	* This function processes the response from the invoice OTP API.
	* If the API response is successful, it either saves the contract (if formVal is provided) or handles the OTP generation.
	* If the API response is unsuccessful, it displays an error message.
	* @param res - The response from the API.
	* @param formVal - The form data object to be saved. Default is null.
	*/
	private handleInvOtpApiRes(res: APIRes, formVal: ContractForm | null = null): void {
		if(this.apiServ.checkAPIRes(res)){
			if(formVal){
				this.saveContract(formVal);
			} else {
				this.startCountdownTimer();
				this.verifyCode = false;
				this.resetTimer = true;
				this.toastr.success(res.message);
				this.loader.hide(this.loaderId);
			}
		} else {
			this.toastr.error(res.message);
			this.loader.hide(this.loaderId);
		}
	}

	/**
	* Validates the OTP (One-Time Password) for invoice verification.
	* This function validates the OTP entered by the user for invoice verification.
	* It shows the loader, prepares the data for API request, and handles the API response.
	* @param formVal - The form data object containing the OTP and token.
	*/
	public validateOtp(formVal: ContractForm): void {
		this.loader.show(this.loaderId);
		let postData: {otp: string, token: string} = {
			otp: formVal.otp ?(formVal.otp).toString() : '',
			token: formVal.inv_id
		}
		this.apiServ.callApi('POST', 'ValidateOtp', postData).pipe(takeUntil(this.destroy)).subscribe((res: APIRes) => this.handleInvOtpApiRes(res, formVal));
	}

	/**
	 * Saves the contract data.
	 * This function sends a request to save the contract data to the API.
	 * It prepares the data for the API request and handles the response accordingly.
	 * @param formVal - The form data object containing contract details.
	 */
	private saveContract(formVal: ContractForm): void {
		let postData: {inv_id: string, contract_accepted: boolean, cust_signatures: Signature[]} = {
			inv_id: formVal.inv_id,
			contract_accepted: formVal.contract_accepted,
			cust_signatures: formVal.cust_signatures
		}
		this.apiServ.callApi('POST', 'SaveInvContract', postData).pipe(takeUntil(this.destroy)).subscribe((res: APIRes) => this.handleContractApiRes(res));
	}

	/**
	* Handles the response from the contract API.
	* This function processes the response from the contract API.
	* If the API response is successful, it displays a success message and closes the dialog.
	* If the API response is unsuccessful, it displays an error message.
	* @param res - The response from the API.
	*/
	public handleContractApiRes(res: APIRes): void {
		if (this.apiServ.checkAPIRes(res)) {
			this.toastr.success(res.message);
			this.dialogRef.close(true);
		}else if(res){
			this.toastr.error(res.message);
		}
		this.loader.hide(this.loaderId);
	}

	/**
	 * Starts a countdown timer based on the specified number of minutes.
	 * Updates a display timer every second until the countdown reaches zero.
	 */
	private startCountdownTimer() {
		let seconds: number = this.minutes * 60; // Convert minutes to seconds
		let textSec: string | number = "0"; // Initialize text for seconds
		let statSec: number = 60; // Initialize static seconds counter
		let prefix: string = this.minutes < 10 ? "0" : ""; // Determine prefix for minutes
		let timer: NodeJS.Timeout = setInterval(() => {
			seconds--; // Decrement seconds
			if (statSec != 0) statSec--; // Decrement static seconds counter unless it's already zero
			else statSec = 59; // Reset static seconds counter to 59 when it reaches zero

			if (statSec < 10) {
				textSec = "0" + statSec; // Add leading zero if static seconds counter is less than 10
			} else textSec = statSec; // Otherwise, use the static seconds counter as is

			this.displayTimer = `${prefix}${Math.floor(seconds / 60)}:${textSec}`; // Update display timer with minutes and seconds
			if (seconds == 0) {
				this.resendCode = true; // Set resendCode flag to true when countdown reaches zero
				clearInterval(timer); // Stop the timer
			}
		}, 1000);
	}
}
