import { Component, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy } from '../../Services';
// Components
import { CommonAddFormComponent } from '../index';

@Component({
	selector: 'bk-single-form',
	templateUrl: './SingleForm.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]

})
export class SingleFormComponent extends CommonAddFormComponent {


}
