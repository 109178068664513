import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { LoaderServ, NgOnDestroy, RenderComponentServ, SectionServ } from '../../Services';

@Component({
	selector: 'bk-service-grid',
	templateUrl: './ServiceGrid.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ServiceGridComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		desc: null,
		categories: null,
		services: null
	}
	loaderId: string = 'services';
	selectedCat: string = '';
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, private loader: LoaderServ) { }

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.pageSett = this.sectionData.section_settings;
			this.secServ.setServData(this.pageSett, this.sectionData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.sectionData);
			if(this.section && !this.section.categories && this.sectionData?.sections && this.sectionData?.sections[this.secId]){
				let categories: any = this.sectionData?.sections[this.secId]?.categories;
				if(this.pageSett && this.pageSett[categories] && this.pageSett[categories]?.items && (this.pageSett[categories]?.items).length > 0 ){
					this.selectedCat = this.pageSett[categories]?.items[0];
				}
			}
			this.changeServ();
		}
	}
	/**
	 * Change service
	 * @param isChange
	 */
	public changeServ(isChange: boolean = false): void {
		this.loader.show(this.loaderId);
		if(!isChange && this.section && this.section.categories && this.section.categories.items && (this.section.categories.items).length > 0){
			this.selectedCat = this.section.categories.items[0].id;
		}
		this.loader.hide(this.loaderId);
	}
}