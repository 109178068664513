import { Component, Input, OnInit, Self, ViewEncapsulation } from '@angular/core';
// External lib
import { takeUntil } from 'rxjs';
// Services
import { ApiServ, LoaderServ, NgOnDestroy, UtilServ } from 'src/app/Services';

@Component({
	selector: 'bk-inv-payment-logs',
	templateUrl: './InvPaymentLogs.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class InvPaymentLogsComponent implements OnInit {

	@Input() invId: any;
	@Input() cusumData: any;
	@Input() invData: any;
	@Input() invSec:any;
	paymentLogs: any;
	loaderId: string = 'inv-payment-logs';

	constructor(public utilServ: UtilServ, private loader: LoaderServ,private apiServ: ApiServ, @Self() public destroy: NgOnDestroy) { }

	ngOnInit() {
		if(this.invId){
			this.fetchInvPaymentLogs();
		}
	}
	/**
	 * Fetch the paid invoice payment logs
	 */
	private fetchInvPaymentLogs(): void {
		this.loader.show(this.loaderId);
		this.apiServ.callApiWithPathQueryVars('GET', 'InvPaymentLogs', [this.invId], {'paid': true}).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.handleInvPayApiRes(res));
	}

	private handleInvPayApiRes(res: any): void {
		if(this.apiServ.checkAPIRes(res)){
			this.paymentLogs = res?.data;
		}
		this.loader.hide(this.loaderId);
	}
}
