<div class="tjs-session-page py-30 position-relative" *ngIf="siteData">
	<!-- Form -->
	<form class="tjs-session__form" [formGroup]="protectedForm" (ngSubmit)="submitForm()">
		<div class="py-80">
			<div class="container">
				<div class="tjs-section__title text-center mb-50 pb-10" *ngIf="siteData?.theme_settings && siteData?.theme_settings?.settings && siteData?.theme_settings?.settings?.message_for_visitors">
					<div class="tjs-section__heading mb-15 px-xxl-40">
						<h4 class="fw-bold display-6">{{siteData?.theme_settings?.settings?.message_for_visitors}}</h4>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto">
						<div class="shadow-sm card">
							<div class="card-body px-sm-30 text-center">
								<!-- Avatar -->
								<div class="tjs-session__thumb shadow-sm mt-n60 bg-white overflow-hidden mb-20 rounded-circle white-bg d-flex align-items-center justify-content-center shadow-sm tjs-h-100 tjs-w-100 mx-auto">
									<bk-user-img alt="user image" width="90" height="90"></bk-user-img>
								</div>
								<!-- Password -->
								<div class="mb-30 text-start">
									<label class="form-label">{{initServ?.appStr?.text?.password | translate}}</label>
									<input [ngClass]="{'is-invalid': protectedForm.controls['password']?.touched && protectedForm.controls['password']?.errors}" class="form-control" placeholder="{{initServ?.appStr?.text?.password | translate}}" formControlName="password" type="password">
									<div class="tjs-error" *ngIf="protectedForm.controls['password'].hasError('required') && protectedForm.controls['password']?.touched" translate><i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.empty | translate}}</div>
								</div>
								<!-- Submit button -->
								<bk-navigate [isSubmit]="true" innerHTML="{{initServ?.appStr?.text?.enter | translate}}" customClass="btn btn-primary btn-lg border-transparent" essentialClass="w-100 mb-10"></bk-navigate>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
