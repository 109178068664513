<div class="tjs-animate-fadeup mb-40 tjs-multi-time-slot multi-date-linear" [id]="calSection?.id" *ngIf="newDateObj">
	<div class="bk-slider keen-slider" #dateSlide [attr.dir]="initServ?.selectedLang?.rtl ? 'rtl' :'ltr'">
		<div class="keen-slider__slide" *ngFor="let dateSlot of newDateObj | keyvalue">
			<div class="my-5 px-5">
				<div [ngClass]="{'tjs-bcalendar__slot--active bk-multi-date-active' : dateSlot.key == selectedDate}" (click)="clickMultiStepDate(dateSlot.key)" class="border rounded p-20 d-flex align-items-center flex-column justify-content-center tjs-pointer h-100 text-center tjs-transition--base tjs-bcalendar__slot fs-24 fw-bold bk-multi-date">
					<span>{{dateServ.formattedDateMultiStep(dateSlot.key,true)}}</span>
					<span class="tjs-date-discount-code mt-5 opacity-50">
						{{scheduleServ.dayDiscountDesc(dateSlot.key, dateDiscountsArray, dayDiscountsArray, dayDiscountBookings, prefilledData) | translate}}
					</span>
				</div>
			</div>
		</div>
	</div>

	<!-- <ng-container *ngFor="let dateSlot of newDateObj | keyvalue">
		<ng-container>
			<div class="col-12 col-sm-4 col-md-3 col-lg-2 my-5 px-5">
				<div [ngClass]="{'tjs-bcalendar__slot--active' : dateSlot.key == selectedDate}"
					(click)="clickMultiStepDate(dateSlot.key)" class="border rounded p-20 d-flex align-items-center flex-column justify-content-center tjs-pointer h-100 text-center tjs-transition--base tjs-bcalendar__slot fs-24 fw-bold">
					{{dateSlot.key}} -->
					<!-- <div *ngIf="dayDiscountDesc(selectedDate)" class="tjs-bcalender__sale tjs-text_gray font-weight-normal tjs-fs_13">
						<span class="upto-txt">{{dayDiscountDesc(selectedDate) |translate}}</span>
					</div> -->
				<!-- </div>
			</div>
		</ng-container>
	</ng-container> -->
</div>
