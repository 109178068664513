<div class="tjs-blog py-80">
	<div class="tjs-section--space-sm blog-list-wrapper">
		<div class="container">
			<div class="col-md-3 ml-auto"></div>
		</div>
		<div class="blog-listing-wrapper">
			<div *ngIf="blogs && blogs.length > 0; else elseBlock">
				<div class="container">
					<div class="row">
						<div class="col-sm-12 col-md-12" [ngClass]="pageSlug ? 'col-md-8 col-xl-8' : 'col-md-12 col-xl-12'">
							<div class="tjs-blog__item mb-50 tjs-mb-last-0" *ngFor="let blog of blogs;trackBy: secServ.trackByFnIndex">
								<div class="tjs-blog__featured-img list-images mb-25" *ngIf="blog?.image_url">
									<img loading="auto" class="img-fluid" alt="blog" [src]="utilServ.getImgUrl(blog?.image_url)" width="1845" height="971">
								</div>
								<div class="tjs-blog__content">
									<div class="tjs-blog__meta mb-5">
										<ul class="list-unstyled d-flex flex-wrap mb-0">
											<!-- Date -->
											<li class="me-15 mb-5" *ngIf="blog?.date_time && !settings?.hide_date">
												<div class="d-flex align-items-center fs-14">
													<i class="tjsicon-calendar-1 pe-10"></i>
													<bk-date-display class="d-inline-block" [date]="blog?.date_time" [isLocal]="true"></bk-date-display>
												</div>
											</li>
											<!-- Name -->
											<li class="me-15 mb-5" *ngIf="blog?.author_data?.name">
												<div class="d-flex align-items-center fs-14">
													<i class="tjsicon-user-2 pe-10"></i>
													<a class="text-capitalize text-body" [routerLink]="['/author',pageSlug, blog?.author_data?.slug]">{{blog?.author_data?.name}}</a>
												</div>
											</li>
											<!-- Comment -->
											<li class="me-15 mb-5" *ngIf="blog?.comment_count">
												<div class="d-flex align-items-center fs-14">
													<i class="tjsicon-chat pe-10"></i>
													<span>{{blog?.comment_count}} {{initServ?.appStr?.text?.comment | translate}}</span>
												</div>
											</li>
										</ul>
									</div>
									<div class="tjs-blog__title mb-30" *ngIf="blog?.title">
										<h4 class="text-capitalize">
											<a class=" text-dark tjs-title-hover" [routerLink]="['/',pageSlug, blog?.seo.url]">{{blog?.title}}</a>
										</h4>
									</div>
									<div class="blog-list-text" *ngIf="blog?.description">
										<p [innerHTML]="blog?.description | safeHtml"></p>
									</div>
								</div>
							</div>
							<div *ngIf="totalRecords > limit">
								<!-- Pagination -->
								<bk-pagination customClass="mt-20" [total]="totalRecords" [limit]="limit" [page]="page" (callback)="pageChange($event)"></bk-pagination>
							</div>
						</div>
						<div class="col-12 col-md-12 col-lg-4 mt-40 mt-lg-0" *ngIf="section && (section?.subscribe || section?.description || section?.social_list || section?.recent_posts)">
							<!-- Side bar -->
							<bk-blog-sidebar [settings]="settings" [slug]="slug" [section]="section" [blogs]="recentBlogs" [pageSett]="pageSett" ></bk-blog-sidebar>
						</div>
					</div>
				</div>
			</div>
			<ng-template #elseBlock>
				<div class="tjs-blog__not-found text-center tjs-py-140">
					<div class="mb-30"><i class="tjsicon-attention text-danger" style="font-size: 5rem;"></i></div>
					<h3 class="fw-bold" translate>{{initServ?.appStr?.text?.noBlogFound}}</h3>
				</div>
			</ng-template>
		</div>
	</div>
</div>
