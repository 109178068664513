import { Component, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy } from '../../Services';
// Inheritance component
import { CommonAddFormComponent } from '../index';

@Component({
	selector: 'bk-two-step-form',
	templateUrl: './TwoStepForm.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class TwoStepFormComponent extends CommonAddFormComponent {


}
