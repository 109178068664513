<div [ngClass]="variationId == 'bk_short_form_V3' ? 'extras_version_3' : 'extras_version_4'" [formGroup]="extrasForm" [id]="section?.id" *ngIf="section && extras && extras.length > 0">
	<!-- Title -->
	<div class="mb-30" *ngIf="section?.title">
		<h4 class="mb-0 bk-title" [ngClass]="variationId == 'bk_short_form_V3' ? 'fw-bold' : 'display-5 text-center fw-normal'" [id]="sectionIds?.title"  [innerHTML]="section?.title | safeHtml"></h4>
	</div>
	<!-- Extras block -->
	<div [ngClass]="{'col-md-7 card pt-25 px-20 pb-20 mx-auto text-center shadow-sm mb-25' : variationId == 'bk_short_form_V4'}">
		<bk-select-element-block controlName="extras" [data]="extras" [elementForm]="extrasForm" [bookingType]="bookingType" (elementChange)="extraChangeEvent()" [isSlider]="true" [sectionIds]="sectionIds"></bk-select-element-block>
	</div>
</div>
