import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Self, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
// External lib
import { ToastrService } from 'ngx-toastr';
// Serviecs
import { NgOnDestroy, InitServ, UtilServ, ApiServ, LoaderServ, CacheService } from '../../Services';

@Component({
	selector: 'bk-rating-popup',
	templateUrl: './RatingPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class RatingPopupComponent implements OnInit {
	// Variables
	ratingForm: FormGroup; // Rating form
	rating: any;
	booking: any;
	loaderId: string = 'bkng-rating-loader';
	isGlobal: boolean = false;
	secId: string = '';
	section: any = { title: null, form: null, send_btn: null, cancel_btn: null };
	slug: string = 'rating';

	constructor(public dialogRef: MatDialogRef<RatingPopupComponent>, @Self() private destroy: NgOnDestroy, private frmBldr: FormBuilder, private toastr: ToastrService, public initServ: InitServ, public utilServ: UtilServ, private apiServ: ApiServ, private loader: LoaderServ, private cacheServ: CacheService, private cDRef: ChangeDetectorRef) {
		// Build form
		this.ratingForm = this.frmBldr.group({
			uid: [null],
			provider_id: [null],
			booking_id: [null],
			rating: [null, [Validators.required]],
			review: [null],
			rate_by_role: ['customer'],
			rated_by: [null],
			review_date: [null]
		});
	}

	ngOnInit(): void {
		// build popup section
		this.buildSectionData();
		//
		if (this.isGlobal) {
			this.dialogRef.disableClose = true;
		}
		// Filled rating form
		if (this.rating) {
			this.filledForm();
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: this.loaderId, section: this.section, dialogRef: this.isGlobal ? null : this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
			// detect the changes
			this.cDRef.detectChanges();
		});
	}
	/**
	 * Filled the rating form, in case of ratings variable value
	 */
	private filledForm(): void {
		if (this.rating.id) {
			this.ratingForm.addControl('id', new FormControl(this.rating.id));
		}
		this.ratingForm.patchValue({
			uid: this.rating.uid,
			provider_id: this.rating.provider_id ? this.rating.provider_id : 0,
			booking_id: this.rating.booking_id,
			rating: this.rating.rating ? this.rating.rating : null,
			review: this.rating.review ? this.rating.review : null,
			rate_by_role: this.rating.rate_by_role ? this.rating.rate_by_role : 'customer',
			rated_by: this.rating.uid,
			review_date: this.rating.review_date ? this.rating.review_date : null
		});
		if (this.isGlobal && typeof (this.booking.auth_token) !== 'undefined' && this.booking.auth_token != '') {
			this.ratingForm.addControl('auth_token', new FormControl(this.booking.auth_token));
		}
	}
	/**
	 * Submit the form data
	 */
	public submitForm(): void {
		if (this.ratingForm.valid) {
			this.apiServ.setLoaderId(this.loaderId);
			this.loader.show(this.loaderId, this.dialogRef);
			let sendData = this.ratingForm.value;
			let apiMethod: string = sendData.id ? 'PUT' : 'POST';
			let apiName: string = (this.isGlobal && typeof (this.booking.auth_token) !== 'undefined' && this.booking.auth_token != '') ? 'RatingWithoutLogin' : 'BookingRating';
			if (apiName == 'BookingRating') {
				apiName = 'BookingRatingV3';
			}
			this.apiServ.callApiWithPathVariables(apiMethod, apiName, [sendData?.booking_id], sendData).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res));
		} else {
			this.ratingForm.controls['rating'].markAsTouched();
		}
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res: any): void {
		if (this.apiServ.checkAPIRes(res)) {
			this.toastr.success(res.message);
			if (this.isGlobal) {
				this.skipRating('submit');
			} else {
				let obj: any = {
					booking: this.booking,
					rating: this.ratingForm.value,
					popupRes: true
				}
				this.dialogRef.close(obj);
			}
		} else {
			if (res && res.message) {
				this.toastr.error(res.message);
			}
			if(res.api_status == 8){
				setTimeout(() => {
					window.location.reload();
				}, 1500)
			}
		}
		this.loader.hide(this.loaderId, this.dialogRef);
		this.cDRef.detectChanges();
	}
	/**
	 * Skip the rating
	 * @param type skip/submit
	 */
	public skipRating(type: string) {
		if (this.isGlobal) {
			let obj: any = {
				booking: this.booking,
				rating: this.ratingForm.value,
				type: type
			}
			this.dialogRef.close(obj);
		} else {
			this.dialogRef.close();
		}
	}
}
