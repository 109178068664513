<div class="py-30 py-md-60" [id]="secId" *ngIf="sectionPerm && notif">
	<div class="container position-relative min-height--150" *ngIf="secBuildData && secBuildData.length > 0">
		<!-- Card Email or SMS -->
		<ng-container *ngFor="let data of secBuildData; trackBy: utilServ.trackByFnIndex; let isLast = last">
			<div class="card position-relative mb-30" [id]="section?.card" [class.mb-0-imp]="isLast" *ngIf="(sectionPerm?.email && notif?.email) || (sectionPerm?.sms && notif?.sms)">
				<div class="card-body pb-0">
					<div class="tjs-card-title bk-title-border" *ngIf="data?.section">
						<h4 class="mb-0 card-title fw-bold" [id]="data?.section?.id" [innerHTML]="data?.section?.content | safeHtml"></h4>
					</div>
					<!-- Notifications -->
					<div class="row">
						<ng-container *ngFor="let notification of data?.notifications; trackBy: utilServ.trackByFnIndex">
							<div class="col-12 col-lg-6 mb-20 px-10" *ngIf="checkNotifFields(notification)">
								<div class="tjs-bg-gray rounded p-20 d-flex align-items-center justify-content-between bk-list" [id]="notification?.id">
									<div class="d-flex align-items-center">
										<p class="fw-bold mb-0 bk-list__title">{{notification.title | translate}}</p>
										<bk-tooltip *ngIf="notification?.tooltip_content" customClass="font-weight-normal bk-tooltip" [content]="notification?.tooltip_content"></bk-tooltip>
									</div>
									<!-- Switch button Enable / Disable -->
									<button *ngIf="notif[notification?.type][notification?.slug]; else disabledBtn" type="button" class="tjs-switch-button tjs-switch-button--flex tjs-switch-enable p-0 border-0 bg-transparent ms-10" (click)="confirmPopup(notification?.slug, notif[notification?.type][notification?.slug], notification?.type)">
										<span class="rounded-pill fs-14 text-white w-100 lh-sm d-flex align-items-center ps-10 pe-30 position-relative" translate>Enabled</span>
									</button>
									<ng-template #disabledBtn>
										<button type="button" class="tjs-switch-button tjs-switch-button--flex tjs-switch-disable p-0 border-0 bg-transparent ms-10" (click)="confirmPopup(notification?.slug, notif[notification?.type][notification?.slug], notification?.type)">
											<span class="rounded-pill fs-14 text-white w-100 lh-sm d-flex align-items-center ps-30 pe-10 position-relative" translate>Disabled</span>
										</button>
									</ng-template>
								</div>
							</div>
						</ng-container>
						<!-- All email notification status false: error message -->
						<ng-container *ngIf="data.slug == 'bk_email_notification'">
							<div class="col-12 mb-20 px-10" *ngIf="!notifStatus?.email?.booking_reminder && !notifStatus?.email?.booking_modified && !notifStatus?.email?.booking_cancellation && !notifStatus?.email?.fees_charged && !notifStatus?.email?.booking_charged && !notifStatus?.email?.rate_us && !notifStatus?.email?.referral_accepted">
								<h5 class="mb-0 fw-bold px-10 {{data?.section?.id}}--color">{{initServ.appStr?.errorMsg?.noEmailNotif | translate}}</h5>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</ng-container>
		<!-- Loader -->
		<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
	</div>
</div>
