import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ} from '../../Services';

@Component({
	selector: 'bk-referral',
	templateUrl: './Referral.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ReferralComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	variationClass: any = {
		bk_referral_V1 : 'position-relative tjs-py-190 tjs-bg-style',
		bk_referral_V2 : 'position-relative py-md-100 py-40 tjs-bg-dark tjs-referral--v2 tjs-referral--common',
		bk_referral_V3 : 'position-relative py-md-100 py-40 bg-white tjs-bg-style tjs-referral--v3 tjs-referral--common',
		bk_referral_V4 : 'tjs-referral--offers pt-60 pb-80 text-center bg-white'
	}
	// Section fields
	section: any = {
		title: null,
		sub_title: null,
		desc: null,
		media: null,
		button: null,
		list: null
	}
	sectionData: any;
	varId: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.varId = this.pageSett[this.secId]?.variation_id;
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
	}
}