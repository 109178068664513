import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation, SimpleChanges, Self, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil, distinctUntilChanged } from 'rxjs';
// Services
import { NgOnDestroy, BkngFormServ, UtilServ, InitServ } from '../../../Services';

@Component({
	selector: 'bk-hourly',
	templateUrl: './Hourly.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class HourlyComponent implements OnInit {

	// Variables
	@Input() isQuoteEditable: boolean = true;
	@Input() bookingType: string = '';
	@Input() section: any;
	@Input() prefilledData: any;
	@Input() availableSett: any;
	@Input() settings: any;
	@Input() selectedLocation: any;
	@Input() selectedServiceType: any;
	@Input() selectedFrequency: any;
	@Input() serviceForm!: FormGroup;
	@Input() services: any;
	@Input() isMultiStepForm: any;
	@Input() isCustomerAllowedRes: any;
	@Input() pageSett: any;
	@Output() hourlyServiceChange: EventEmitter<any> = new EventEmitter();
	@Output() isHourlyService: EventEmitter<any> = new EventEmitter();
	isDisabled: any;
	hours: any = [];
	minutes: any = [];
	defaultHour: any = 0;
	defaultMinutes: any = 0;
	minimumTime: any = 0;
	minHours: any = 0;
	minMinutes: any = 0;
	hourlyService: boolean = false;
	isDisabledHourly: any;
	notifyCustStatus: boolean = false;
	constructor(private bkngFormServ: BkngFormServ, public utilServ: UtilServ, public initServ: InitServ, @Self() private destroy: NgOnDestroy,private cDRef: ChangeDetectorRef) {
		this.hours = this.utilServ.listHours(72);
		this.minutes = this.utilServ.listMinutes();
	}

	ngOnInit(): void {
		this.buildHourlyService();
		// Service hourly value change
		this.serviceForm.controls['service_hourly_value'].valueChanges.pipe(distinctUntilChanged(),takeUntil(this.destroy)).subscribe(() => {
			this.buildHourlyService();
		});
		this.setNotifyCustStatus();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange) {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev){
						this.buildHourlyService();
						break;
					}
				}
			}
		}
	}
	private setNotifyCustStatus(): void {
		if(this.section && this.utilServ.secServ.checkEleStatus(this.pageSett, this.section.notify_cust_id)){
			this.notifyCustStatus = true;
		}
	}
	/**
	 * Build hourly service
	 */
	private buildHourlyService(): void {
		this.isDisabled = this.bkngFormServ.disabledParamScope(this.bookingType, this.prefilledData, this.selectedServiceType, this.isQuoteEditable, true, '', this.isCustomerAllowedRes);
		if(this.selectedServiceType && this.selectedServiceType.is_hourly_service == 'yes' && (!this.selectedServiceType.hourly_serv_price_on || (this.selectedServiceType.hourly_serv_price_on && this.selectedServiceType.hourly_serv_price_on == 'custom_time'))){
			this.hourlyService = true;
		} else {
			this.hourlyService = false;
			this.minHours = 0;
			this.minMinutes = 0;
			this.defaultHour = 0;
			this.defaultMinutes = 0;
		}
		if(this.hourlyService){
			// Set the minimum limits of custom time
			this.setMinLimits();
			// Set the default variable of custom time
			this.setDefault();
			this.isDisabledHourly = this.bkngFormServ.disabledParamScope(this.bookingType, this.prefilledData, this.selectedServiceType, this.isQuoteEditable, false, '', this.isCustomerAllowedRes);
			// This line is introduce to handle the case of adjust_time_hourly in case of custom hourly service, This is a new case introduced with pricable custom field feature
			this.isDisabledHourly = this.prefilledData?.adjust_time_hourly ? false : this.isDisabledHourly;
		}
		this.isHourlyService.emit(this.hourlyService);
		this.cDRef.detectChanges();
	}
	/**
	 * Set the minimum limits of custom time
	 */
	// eslint-disable-next-line complexity
	private setMinLimits(): void {
		this.minimumTime = 0;
		this.minHours = 0;
		this.minMinutes = 0;
		if(this.selectedServiceType.enable_minimum_time && !this.serviceForm.controls['exclude_minimum'].value){
			this.minimumTime = this.selectedServiceType.minimum_time ? (+this.selectedServiceType.minimum_time) : 0;
			this.minHours = Math.floor(+this.minimumTime / 60);
			if(!this.minHours){
				this.minHours = 0;
			}
			if(this.minimumTime && this.minimumTime > 0 && +this.serviceForm.controls['service_hourly_value'].value && +this.minimumTime < +this.serviceForm.controls['service_hourly_value'].value){
				let selectedHour = Math.floor(this.serviceForm.controls['service_hourly_value'].value / 60);
				if(+selectedHour > +this.minHours){
					this.minMinutes = 0;
				} else {
					this.minMinutes = +this.minimumTime % 60;
					if(!this.minMinutes){
						this.minMinutes = 0;
					}
				}
			} else {
				this.minMinutes = +this.minimumTime % 60;
				if(!this.minMinutes){
					this.minMinutes = 0;
				}
			}
		}
	}
	/**
	 * Set the default variables of custom time
	 */
	private setDefault(): void {
		if(this.serviceForm.controls['service_hourly_value'].value){
			let totalHour = Math.floor(this.serviceForm.controls['service_hourly_value'].value / 60);
			let totalMinutes = this.serviceForm.controls['service_hourly_value'].value % 60;
			if(totalHour == 0){
				this.defaultHour = 0;
			} else if(totalHour < 10){
				this.defaultHour = ('0'+totalHour);
			} else{
				this.defaultHour = totalHour;
			}
			if(totalMinutes == 0){
				this.defaultMinutes = '00';
			} else if(totalMinutes < 10){
				this.defaultMinutes = ('0'+totalMinutes);
			} else{
				this.defaultMinutes = totalMinutes;
			}
		}
	}
	/**
	 * Adjust hours and minutes of service
	 * @param hours
	 * @param minutes
	 */
	public adjustmentService(hours: { value: any; }, minutes: { value: string | number; }): void {
		let adjustedTime = 0;
		if(hours.value){
			adjustedTime = +(hours.value) * 60;
		}
		if(minutes.value){
			adjustedTime = (+adjustedTime) + (+minutes.value);
		}
		if(adjustedTime > this.minimumTime){
			this.serviceForm.controls['service_hourly_value'].setValue(adjustedTime);
		} else{
			this.serviceForm.controls['service_hourly_value'].setValue(+this.minimumTime);
		}
		this.hourlyServiceChange.emit();
	}
}
