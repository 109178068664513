<div [ngClass]="isMultiStepForm ? 'py-20' : 'py-30 px-md-30 border-bottom bk-card-border'" [id]="section?.id" [formGroup]="locationForm" *ngIf="section && settings && settings?.locations && (settings?.locations).length > 0">
	<!-- Title and description -->
	<bk-section-heading *ngIf="!isMultiStepForm" [section]="section" [pageSett]="pageSett"></bk-section-heading>
	<div class="row">
		<div class="col-12 col-md-6">
			<label [id]="section?.loc_name_label_id" class="form-label bk-form-label" *ngIf="section?.loc_name_label" [innerHTML]="section?.loc_name_label | safeHtml"></label>
			<div class="tjs-dropdown dropdown d-flex bk-dropdown">
				<!-- Location dropdown -->
				<button class="text-capitalize form-control w-100 tjs-dropdown__toggle bk-dropdown-btn" type="button" id="locationMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [attr.disabled]="((bookingType == 'reschedule' && prefilledData?.status != 9) || !isQuoteEditable) ? 'disabled' : null" [ngClass]="{'form-control-lg btn-light':isMultiStepForm}">{{setLocationName | translate}}</button>
				<div class="w-100 tjs-dropdown__menu tjs-shadow--lg dropdown-menu dropdown-menu-end" aria-labelledby="locationMenu">
					<div class="mb-5 px-5">
						<input type="text" class="form-control form-control-sm bk-input" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" [attr.placeholder]="'Search by name' | translate" [attr.disabled]="((bookingType == 'reschedule' && prefilledData?.status != 9) || !isQuoteEditable) ? 'disabled' : null">
					</div>
					<ng-container *ngFor="let location of settings?.locations | customSearch : searchText; let index = index;trackBy: trackById">
						<div *ngIf="settings?.form_data?.preferred_service_location == 'both'; else specificLoc" class="dropdown-item text-capitalize d-flex align-items-center tjs-pointer bk-dropdown-item" [class.active]="location._id == selectedLocation" (click)="locSelect(location)" [attr.disabled]="((bookingType == 'reschedule' && prefilledData?.status != 9) || !isQuoteEditable) ? 'disabled' : null">
							<span>{{location?.location?.location_name | translate}}</span> &nbsp; <span *ngIf="location?.location?.location_type == 'ML'" class="badge bg-secondary p-5 fs-12 ms-5">ML</span>
						</div>
						<ng-template #specificLoc>
							<div *ngIf="location.location.location_type == selectedType" class="tjs-dropdown__link dropdown-item text-capitalize d-flex align-items-center bk-dropdown-link" [class.active]="location._id == selectedLocation" (click)="locSelect(location)" [attr.disabled]="((bookingType == 'reschedule' && prefilledData?.status != 9) || !isQuoteEditable) ? 'disabled' : null">
								{{location?.location?.location_name | translate}}
							</div>
						</ng-template>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
