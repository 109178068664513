<div class="row" [id]="section?.id" [formGroup]="serviceForm" *ngIf="settings && section && (services && services.length > 1)">
	<div [ngClass]="variationId == 'bk_short_form_V3' ? 'col-md-12 mb-20' : 'col-md-6 full-wid'">
		<div  [ngClass]="variationId == 'bk_short_form_V4' ? 'card shadow-sm mb-30' : ''">
			<div [ngClass]="{'card-body d-md-flex justify-content-between align-items-center p-20 px-md-15 py-md-10' : variationId == 'bk_short_form_V4'}">
				<label *ngIf="section?.serv_label" [id]="sectionIds?.label" class="form-label me-sm-15 me-10 bk-form-label mb-10" [ngClass]="{'mb-md-0 me-md-10 flex-shrink-0' : variationId == 'bk_short_form_V4'}" [innerHTML]="section?.serv_label | safeHtml"></label>
				<!-- Service selection -->
				<div [ngClass]="{'w-100' : variationId == 'bk_short_form_V4', 'd-flex align-items-center': (selectedServiceType && selectedServiceType?.show_tooltip_icon)}">
					<select class="form-select pe-35 fs-15 bk-select" [ngClass]="{'form-select-sm tjs-border-md-0 shadow-none':(variationId == 'bk_short_form_V4')}" [(ngModel)]="selectedServiceType" [ngModelOptions]="{standalone: true}" (ngModelChange)="selectService($event)" [attr.disabled]="isDisabled">
						<ng-container *ngFor="let service of services;trackBy: utilServ.trackByFnIndex">
							<option class="bk-select-option" [ngValue]="service">{{utilServ.getFormParamName(service) | translate}}</option>
						</ng-container>
					</select>
					<!-- Tooltip -->
					<bk-tooltip *ngIf="selectedServiceType && selectedServiceType?.show_tooltip_icon" class="bk-tooltip" content="{{selectedServiceType?.tooltip_text | translate}}"></bk-tooltip>
				</div>
			</div>
		</div>
	</div>
</div>