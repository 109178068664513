<div [id]="secId" class="tjs-popup position-relative" *ngIf="booking">
	<!-- Popup header -->
	<bk-popup-header [headingId]="section?.title?.id" [heading]="section?.title?.content" [isClose]="true" [isCloseAll]="false" [dialogRefCloseRes]="true"></bk-popup-header>
	<!-- Popup body -->
	<div class="tjs-popup__content tjs-popup__body">
		<!-- review card -->
		<div [id]="section?.review?.id" class="" *ngIf="rating && rating?.review && isBlockVisible">
			<div class="card rounded-5 bg-body p-20 position-relative mb-sm-30 mb-20 bk-popup-review-card">
				<blockquote class="blockquote" [ngClass]="(isReadmore) ? 'mb-5': 'mb-20' ">
					<p class="text-muted fs-16 fst-italic mb-0 bk-review">
						<i class=" tjsicon-quote-1 me-10 opacity-50"></i>
						<span class="" [innerHTML]="review"></span>
						<i class="tjsicon-quote ms-10 opacity-50"></i>
					</p>
				</blockquote>
				<div class="d-inline-flex mb-10">
					<button *ngIf="isReadmore" [id]="section?.review?.read_more_btn_id" class="btn btn-link text-muted py-5 px-0 text-decoration-underline bk-readmore-btn" (click)="getReview(true)">{{section?.review?.read_more_btn}}</button>
					<button [id]="section?.review?.copy_btn_id" class="btn rounded-4 btn-white btn-xs position-absolute border-0-imp bottom-0 end-0 mb-10 me-10 bk-copy_btn" (click)="copyReview()"> <i class="tjsicon-content-1 me-5"></i>{{section?.review?.copy_btn}}</button>
				</div>
			</div>
		</div>
		<!-- Referral card -->
		<div [id]="section?.referrals?.id" class="mb-20" [ngClass]="(isBlockVisible) ? 'mb-sm-30' : 'mb-sm-10' " *ngIf="twitterStatus || fbStatus">
			<div  class="card rounded-5 bg-body p-20">
				<ng-container *ngIf="custStoreSett?.post_automatic_rating?.length > 0">
					<ng-container *ngIf="(custStoreSett?.earn_credit_from_sharing_review == 'yes') && !stopSendingRefCredits; else noCredit">
						<!-- Referral Text -->
						<ng-container *ngIf="custStoreSett?.each_share_reward == 'yes' ; else single">
							<ng-container *ngIf="(twitterStatus && !fbStatus) || (!twitterStatus && fbStatus); else shareToBoth ">
								<h3 [id]="" class="fw-bold mb-20 text-center text--color" [innerHTML]="tokenReplace( section?.referrals?.single_credit, singleShortCode) | safeHtml"></h3>
							</ng-container>
							<ng-template #shareToBoth>
								<h3 [id]="" class="fw-bold mb-20 text-center text--color" [innerHTML]="tokenReplace(section?.referrals?.twitter_fb_credit, fbShortCode, tweetShortCode) | safeHtml"></h3>
							</ng-template>
						</ng-container>
						<ng-template #single>
							<h3 [id]="" class="fw-bold mb-20 text-center text--color" [innerHTML]="tokenReplace(section?.referrals?.single_credit, singleShortCode) | safeHtml"></h3>
						</ng-template>
					</ng-container>
					<!-- No credit earn for sharing -->
					<ng-template #noCredit>
						<h3 [id]="" class="fw-bold mb-20 text-center text--color" [innerHTML]="section?.referrals?.no_credit"></h3>
					</ng-template>
				</ng-container>
				<div class="d-flex gap-10 gap-sm-15 flex-sm-row flex-column justify-content-center align-items-center mb-10">
					<bk-navigate *ngIf="fbAppId && fbStatus" customClass="btn d-flex text-white tjs-facebook-color" (callback)="socialServ.postOnFacebook(fbAppId, siteUrl, true)" innerHTML="<i class='fa fa-facebook-official me-10' aria-hidden='true'></i> {{'Facebook' | translate}}"></bk-navigate>
					<bk-navigate *ngIf="twitterStatus" customClass="btn d-flex text-white bg-black" (callback)="socialServ.tweetOnTwitter(siteUrl, rating?.review, 0, '', true)" innerHTML="<img src='{{initServ.imgBase}}/assets/images/twitter-x-black.png' alt='twitter-x-black logo' width='15' height='15' class='me-5'> {{'Twitter' | translate}}"></bk-navigate>
				</div>
			</div>
		</div>
		<!-- Share reviews -->
		<div [id]="section?.share_review?.id" class="" *ngIf="isBlockVisible && custStoreSett?.share_review_to_sites && (custStoreSett?.share_review_to_sites).length > 0">
			<div class="card  rounded-5 bg-body p-20 mb-0">
				<h3 [id]="section?.share_review?.label_id" class="fw-bold mb-20 text-center text--color" *ngIf="section?.share_review?.label" [innerHTML]="section?.share_review?.label | safeHtml"></h3>
				<div class="d-flex gap-5 justify-content-center align-items-center flex-wrap mb-10" [ngClass]="(section?.share_review?.label) ? '' : 'mt-10' ">
					<ng-container *ngFor="let site of custStoreSett.share_review_to_sites;trackBy: utilServ.trackByFnIndex">
						<a *ngIf="site?.status && locationLink(site?.locations, site?.site)" href="{{locationLink(site?.locations)}}" target="_blank" [innerHTML]="site?.site | translate" class="btn btn-white rounded-pill text-capitalize bk-share-review-btn"></a>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
