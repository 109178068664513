<div class="home-page-review-block review-block text-center py-30" *ngIf="isLiveReviews && reviews && reviews.length > 0">
	<div class="w-70 mx-auto">
		<div class="tjs-section__heading d-flex align-items-center justify-content-center mb-10 mb-md-35">
			<h5 class="mb-0 fw-bold">{{liveReviewLabel}}</h5>
		</div>
		<div class="sidebar-reviews keen-slider bk-slider" #reviewSlide>
			<!--  -->
			<div class="keen-slider__slide" *ngFor="let review of reviews;trackBy: utilServ.trackByFnIndex">
				<div class="bk-slider-item slide-item tjs-live-review__content text-center position-relative bg-transparent">
					<!-- Description -->
					 <div class="mb-20" *ngIf="review?.review">
						<div class="bk-slider-item-desc slide-content">
							<span class="slide-quote"><i class="tjsicon-quote-1 me-5 opacity-50"></i></span>
							<span>{{review.review}}</span>
							<span class="slide-quote"><i class="tjsicon-quote ms-5 opacity-50"></i></span>
						</div>
					 </div>
					<!-- User name -->
					<div class="mb-15" *ngIf="custDetail != 'no_name' && (review?.review_fields?.first_name || review?.review_fields?.last_name)">
						<h5 class="text-capitalize fw-bold bk-slider-item-title">{{review?.review_fields?.first_name}} {{utilServ.lastInitial(custDetail, review?.review_fields?.last_name)}}</h5>
					</div>
					<!-- Rating -->
					<div class="d-flex justify-content-center mb-20">
						<bk-rating-display class="bk-slider-rating" [rating]="review?.rating" [readOnly]="true" [starColor]="starColor"></bk-rating-display>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex gap-5 justify-content-center">
			<a (click)="slider && slider.prev()" class="tjs-pointer prev-review"><i class="tjsicon-left-arrow"></i></a>
			<a (click)="slider && slider.next()" class="tjs-pointer next-review"><i class="tjsicon-right-arrow"></i></a>
		</div>
	</div>
</div>
