<div [ngClass]="dialogRef == undefined || null ? '' : 'tjs-popup tjs-popup--sm position-relative min-height--150'" [id]="secId" >
	<div [ngClass]="dialogRef == undefined || null ? 'container py-50' : ''">
		<!-- Popup Header -->
		<ng-container *ngIf="section?.title">
			<bk-popup-header *ngIf="dialogRef; else titleTemp" [headingId]="section?.title?.id" [heading]="section?.title?.content" [isClose]="false"></bk-popup-header>
			<ng-template #titleTemp>
				<h3 class=" m-0 me-20 tjs-popup__title bk-popupTitle flex-grow-1" [ngClass]="dialogRef == undefined || null ? 'text-sm-center mb-15' : ''" [id]="section?.title?.id" [innerHTML]="section?.title?.content | safeHtml"></h3>
			</ng-template>
		</ng-container>
	<!-- Popup Body -->
	<form [formGroup]="billingForm" bkDebounceEvent [isSubmit]="true" (debounceSubmit)="submitForm()" [ngClass]="dialogRef == undefined || null ? 'col-md-9 col-lg-7 col-xl-6 col-xxl-5 mx-sm-auto' : ''">
		<div [ngClass]="dialogRef == undefined || null ? '' : 'tjs-popup__content tjs-popup__body'" [id]="section?.form?.id">
			<div [class.d-none]="secondStep" class="tjs-animate-faderight">
				<ng-container [ngSwitch]="paymentGateway">
					<!-- Stripe -->
					<ng-container *ngSwitchCase="'stripe'">
						<!-- Locations -->
						<div class="mb-20" *ngIf="initServ.locationsStatus && stripeIds && stripeIds.length > 1 && locations && (locations).length > 0">
							<label class="form-label bk-form-label d-block" *ngIf="section?.form?.location?.label" [innerHTML]="section?.form?.location?.label | safeHtml"></label>
							<select [ngClass]="{'is-invalid': billingForm.controls['location_id'].touched && billingForm.controls['location_id'].errors}" class="form-select" formControlName="location_id" (change)="changeStripeLoc(true)">
								<option value=null disabled selected hidden>{{section?.form?.location?.placeholder}}</option>
								<ng-container *ngFor="let location of locations; trackBy: utilServ.trackByFnIndex">
									<option [ngValue]="location.location_id">{{location?.location?.location_name | translate}}</option>
								</ng-container>
							</select>
							<div class="tjs-error" *ngIf="billingForm.controls['location_id']?.touched && billingForm.controls['location_id']?.errors?.required"> <i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.empty | translate}}</div>
						</div>
						<div class="min-height--150">
							<bk-payment-gateway *ngIf="buildPaymentForm" [locationId]="stripeLocId" [baseLoc]="true" [isDynamic]="true" [section]="section?.form?.payment_info" [billingAddr]="true" [billingAddrForm]="billingForm" (callback)="stripeKeys = $event" [isMultLocBillingAddr]="stripeIds && stripeIds.length > 1"></bk-payment-gateway>
						</div>
					</ng-container>
					<!-- Square -->
					<ng-container *ngSwitchCase="'square'">
						<!-- Locations -->
						<div class="mb-20" *ngIf="initServ.locationsStatus && squareIds && squareIds.length > 1 && locations && (locations).length > 0">
							<label class="form-label bk-form-label d-block" *ngIf="section?.form?.location?.label" [innerHTML]="section?.form?.location?.label | safeHtml"></label>
							<select [ngClass]="{'is-invalid': billingForm.controls['location_id'].touched && billingForm.controls['location_id'].errors}" class="form-select" formControlName="location_id" (change)="changeStripeLoc(true)">
								<option value=null disabled selected hidden>{{section?.form?.location?.placeholder}}</option>
								<ng-container *ngFor="let location of locations; trackBy: utilServ.trackByFnIndex">
									<option [ngValue]="location.location_id">{{location?.location?.location_name | translate}}</option>
								</ng-container>
							</select>
							<div class="tjs-error" *ngIf="billingForm.controls['location_id']?.touched && billingForm.controls['location_id']?.errors?.required"> <i class="tjsicon-attention"></i>{{initServ.appStr?.errorMsg?.empty | translate}}</div>
						</div>
						<div class="min-height--150">
							<bk-payment-gateway *ngIf="buildPaymentForm" [locationId]="setLocation" [baseLoc]="true" [isDynamic]="true" [section]="section?.form?.payment_info" [billingAddr]="true" [billingAddrForm]="billingForm" [isMultLocBillingAddr]="squareIds && squareIds.length > 1"></bk-payment-gateway>
						</div>
					</ng-container>
					<!-- Authorize / Paypal -->
					<ng-container *ngSwitchDefault>
						<div [formGroup]="billingForm" *ngIf="buildPaymentForm">
							<bk-payment-gateway [isDynamic]="true" [section]="section?.form?.payment_info" [billingAddr]="true" [billingAddrForm]="billingForm" [isMultLocBillingAddr]="false"></bk-payment-gateway>
						</div>
					</ng-container>
				</ng-container>
			</div>
			<div [class.d-none]="!secondStep" class="tjs-animate-faderight" [ngClass]="dialogRef == undefined || null ? 'text-sm-center' : ''">
				<bk-card-apply [form]="billingForm" [id]="1" [isBookingsWithCash]="isBookingsWithCash" [section]="section?.form"></bk-card-apply>
			</div>
		</div>
		<bk-phantom-field [control]="billingForm.controls['billing_form_timestamp']"></bk-phantom-field>
		<!-- Submit button -->
		<div class="d-sm-flex border-top-0 flex-column gap-15 flex-sm-row-reverse " [ngClass]="dialogRef == undefined || null ? 'mt-25' : 'tjs-popup__footer tjs-popup__footer--white tjs-popup__footer--responsive bk-footColor'">
			<ng-container *ngTemplateOutlet="cmnBtns"></ng-container>
		</div>
	</form>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="formLoaderId"></bk-app-loader>
</div>
<!-- Button templates -->
<ng-template #cmnBtns>
	<bk-navigate *ngIf="secondStep || !isGiveOptions || !isUpcomingBookings" [secId]="section?.add_btn?.id" [designId]="section?.add_btn?.design_id" [innerHTML]="section?.add_btn?.content" [isSubmit]="true" essentialClass="w-100" customClass="btn btn-primary rounded-5 w-100" class="d-block w-100 mb-15 mb-sm-5" [btnDisabled]="(initServ.paymentGateway == 'stripe' && !stripeKeys)"></bk-navigate>
	<bk-navigate *ngIf="!secondStep && isGiveOptions && isUpcomingBookings" [secId]="section?.add_btn?.id" [designId]="section?.add_btn?.design_id" [innerHTML]="section?.next_btn?.content" [isSubmit]="false" essentialClass="w-100" customClass="btn btn-primary rounded-5 w-100" class="d-block w-100 mb-15 mb-sm-5" [btnDisabled]="(initServ.paymentGateway == 'stripe' && !stripeKeys)" (callback)="secondStep=true"></bk-navigate>
	<bk-navigate *ngIf="secondStep && isGiveOptions && isUpcomingBookings" [secId]="section?.cancel_btn?.id" [designId]="section?.cancel_btn?.design_id" [innerHTML]="section?.back_btn?.content" [isSubmit]="false" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" class="d-block w-100 mb-5" (callback)="secondStep=false"></bk-navigate>
	<bk-navigate *ngIf="(!secondStep || !isGiveOptions || !isUpcomingBookings) && dialogRef" [secId]="section?.cancel_btn?.id" [designId]="section?.cancel_btn?.design_id" [innerHTML]="section?.cancel_btn?.content" [isSubmit]="false" essentialClass="w-100" customClass="btn btn-white rounded-5 w-100" class="d-block w-100 mb-5" (callback)="closePopup()"></bk-navigate>
</ng-template>
