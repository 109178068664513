import { Injectable } from "@angular/core";
import { ApiServ, InitServ, LoaderServ, StyleServ, UtilServ } from "./index";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { Subject } from "rxjs/internal/Subject";
import Bugsnag from '@bugsnag/js';
// Constants
import { INTERIOR_PAGES_POPUPS_SLUGS, IS_DEV } from '../Constants';

// DEFAULTS CONSTANTS USED FOR THE CONDITIONAL CHECKS
let PAGE: string = 'page';
let STYLE: string = 'style';
let POPUP: string = 'popup';
let OBJ_SIZE_LIMIT: number = 10;

@Injectable({
	providedIn: 'root'
})
export class CacheService {
	secId: string = '';
	section: any;
	loaderId: string = '';
	interiorSecSlugs: string[] = INTERIOR_PAGES_POPUPS_SLUGS;
	// To maintain the order of insertion/ deletion therefore, Map JS object is chosen
	popups: Map<string, any> = new Map();
	pages: Map<string, any> = new Map();
	// private members
	private destroy = new Subject<void>();

	// eslint-disable-next-line max-params
	constructor(public initServ: InitServ, private apiServ: ApiServ, private styleServ: StyleServ, private loader: LoaderServ, private utilServ: UtilServ) { }

	/**
	 * Method checks if data is available based on the slug and type provided.
	 * @param {string} slug - A unique identifier for the popups.
	 * @param {string} type - A string parameter that specifies the type `PAGE` or `POPUP` of data being checked for availability.
	 * @returns a boolean value.
	 */
	public isCachedDataAvail(slug: string, type: string = PAGE): boolean {
		return (type == PAGE) ? this.pages.has(slug) : this.popups.has(slug);
	}

	/**
	 * Method sets the data object for a given slug in the pages map object, and reduces the
	 * size of the map object if it exceeds a certain limit.
	 * @param {string} slug - A unique identifier for the pages
	 * @param {any} data - The data parameter is an object that contains the data to be stored for a specific page.
	 */
	public setCachedPages(slug: string, data: any): void {
		(this.pages.size > OBJ_SIZE_LIMIT) && this.reduceSize(PAGE);
		this.pages.set(slug, data);
	}

	/**
	 * Method retrieves data from a map object based on a given slug and type, and returns it as a response object.
	 * @param {string} slug - A unique identifier for the pages.
	 * @param {string} type - The "type" parameter is a string that specifies the type of data to retrieve from the pages map.
	 * @returns the object as in the form of response object, if it is truthy. Otherwise, it returns `null`.
	 */
	public getCachedPages(slug: string, type: string = PAGE): any {
		return (this.pages.get(slug)[type]) ? this.utilServ.changeToResObj(this.pages.get(slug)[type]) : null;
	}

	/**
	 * Method sets data for a popup and reduces the size of the popup if it exceeds a certain limit.
	 * @param {string} slug - A unique identifier for the popups
	 * @param {any} res - The parameter "res" is of type "any", which means it can accept any data type. It
	 * is used to store the data associated with the given slug in the popup.
	 */
	public setCachedPopups(slug: string, res: any): void {
		(this.popups.size > OBJ_SIZE_LIMIT) && this.reduceSize(POPUP);
		this.popups.set(slug, res);
	}

	/**
	 * Method returns the data associated with a given slug from the `popups` object,
	 * or `null` if no data is found.
	 * @param {string} slug - A string representing the unique identifier of the popup.
	 * @returns the value of popup if it exists, otherwise it returns `null`.
	 */
	public getCachedPopups(slug: string): any {
		return this.popups.get(slug) ?? null;
	}

	/**
	 * Method reduces the size of `Map object` [ *PAGES* or *POPUPS* ] based on the given type.
	 * @param {string} type - The "type" parameter is a string that specifies whether the data to be reduced is a page or a popup.
	 */
	public reduceSize(type: string): void {
		let dataObj: any;
		if (type == PAGE) {
			dataObj = this.pages.keys().next();
			this.pages.delete(dataObj.value);
		} else if (type == POPUP) {
			dataObj = this.popups.keys().next();
			this.popups.delete(dataObj.value);
		}
	}

	/**
	 * Method to build the popup section if the data is available in the cache, else by hitting the popup api called with the particular slug.
	 * @param {any} obj - parameter that contains various properties used to build the popup section.
	 * Required data as an object: {
	 * 	title: `title of the popup`, slug: `slug of the popup`, loaderId: `loaderId of the popup to show loader over the popup`,
			section : `section data to build sections for the popup`, dialogRef: `reference object mat dialog, ie, dialogRef`
	 * }
	 * @returns A Promise is being returned when resolves.
	 */
	public async buildSectionData(obj: any): Promise<any> {
		this.loaderId = obj?.loaderId ?? '';
		if (obj && Object.keys(obj).length > 0) {
			try {
				if (this.isCachedDataAvail(obj.slug, POPUP)) {
					await this.buildPopupSection(true, obj);
				} else {
					await this.hitPopupApi(obj);
				}
			} catch (err) {
				this.errorhandler(err, true);
			}

		} else {
			this.errorhandler('Insufficient data passed as an object.');
		}
	}

	/**
	 * Method makes an API call to retrieve data for a single popup and then processes the response.
	 * @param {any} obj - parameter that contains additional data of the popup.
	 * @returns a Promise that resolves to an object.
	 */
	public async hitPopupApi(obj: any): Promise<any> {
		this.loader.show(this.loaderId, obj?.dialogRef ?? null);
		let queryParams: any = {
			language: this.initServ.savedLng,
			theme_slug: this.initServ.theme,
			slug: obj.slug,
			mode: this.initServ.theme ? 'preview' : 'live'
		};

		let res:any = await this.apiServ.callApiWithPathQueryVars('GET', 'SinglePopup', [0], queryParams).pipe(takeUntil(this.destroy)).toPromise();
		return this.setPopupDataRes(res, obj);
	}

	/**
	 * Method to set the popup style and build the popup sections
	 * @param res response of popup api
	 */
	public async setPopupDataRes(res: any, obj: any): Promise<boolean> {
		if (this.apiServ.checkAPIRes(res)) {
			this.setCachedPopups(obj.slug, res.data);
			await this.buildPopupSection(false, obj, res);
		} else {
			this.errorhandler(res);
			// load the default json
			let defaultJsonRes = await this.loadDefaultJson(obj.slug, 'InteriorPopupJsons');
			this.setCachedPopups(obj.slug, defaultJsonRes?.data);
			this.buildPopupSection(false, obj, defaultJsonRes);
		}
		this.loader.hide(this.loaderId, obj?.dialogRef ?? null);
		return Promise.resolve(true);
	}

	/**
	 * Method builds a popup section using data from a response object or cached data and apply the popup styling.
	 * @param {any} res - object received from an API call
	 * @param {boolean} - A boolean flag that indicates whether the data is being fetched from a cache or not.
	*/
	public async buildPopupSection(isCached: boolean, obj: any, res: any = null): Promise<void> {
		let dialogRef = obj?.dialogRef ?? null;
		let popupData: any = (isCached) ? this.getCachedPopups(obj.slug) : res?.data;
		await this.styleServ.applyPageStyle((isCached ? this.utilServ.changeToResObj(popupData) : res), 'popup');
		this.secId = popupData?.added_sections[0];
		this.section = this.utilServ.buildPopupSection(popupData, obj?.section, dialogRef);
		return;
	}

	/**
	 * Method to build the page section if the data is available in the cache, else by hitting the page (data/ style) api called with the particular slug.
	 * @param {any} queryParams - An object that contains any additional parameters that may be required for loading the page section.
	 * @param {string} slug - The unique identifier for a specific page section
	 * @returns a Promise with response value
	 */
	public async buildPageSection(queryParams: any, slug: string, isEmbed: boolean = false): Promise<any> {
		try {
			if (this.isCachedDataAvail(slug, PAGE)) {
				this.styleServ.applyPageStyle(this.getCachedPages(slug, STYLE), slug, undefined, undefined, false, isEmbed);
				return this.getCachedPages(slug, PAGE);
			} else {
				return await this.loadPageData(queryParams, slug, isEmbed);
			}
		} catch (err) {
			return err;
		}
	}

	/**
	 * Method makes an API call to retrieve page style data based on query parameters and slug, and then applies the style to the page.
	 * @param {any} queryParams - An object that contains any additional parameters that may be required for loading the page section.
	 * @param {string} slug - The unique identifier for a specific page section
	 */
	public async loadPageStyle(queryParams: any, slug: string, isEmbed: boolean = false): Promise<void> {
		let res:any = await this.apiServ.callApiWithPathQueryVars('GET', 'PageStyle', [0], queryParams).pipe(takeUntil(this.destroy)).toPromise();
		return this.handlePageStyleApiRes(res, slug, isEmbed);
	}

	/**
	 * Method loads page data from an API and sets it into a cache variable, and returns a promise that resolves with the loaded data.
	 * @param {any} queryParams - An object that contains any additional parameters that may be required for loading the page section.
	 * @param {string} slug - A unique identifier for a specific page.
	 * @returns a Promise that resolves to a response object.
	 */
	public async loadPageData(queryParams: any, slug: string, isEmbed: boolean = false): Promise<any> {
		this.loadPageStyle(queryParams, slug, isEmbed);
		if (this.initServ.firstPageData && this.initServ.firstPageSlug && slug == this.initServ.firstPageSlug && this.apiServ.checkAPIRes(this.initServ.firstPageData) && this.initServ.firstPageData?.data) {
			return this.handlePageDataApiRes(this.initServ.firstPageData, slug);
		} else {
			let apiRes:any = await this.apiServ.callApiWithPathQueryVars('GET', 'PageData', [0], queryParams).pipe(takeUntil(this.destroy)).toPromise();
			return await this.handlePageDataApiRes(apiRes, slug);
		}
	}


	/**
	 * Handle the page data api request and cache the page data with provided slug in the object and if request has error then handle the error case.
	 * Only and only if default page data will loaded for Interior pages
	 * @param slug page slug
	 */
	private async handlePageDataApiRes(res: any, slug: string): Promise<any> {
		if (this.apiServ.checkAPIRes(res)) {
			this.setCachedPages(slug, { ...this.pages.get(slug), 'page': res.data });
			// return res;
		} else if (this.interiorSecSlugs?.includes(slug)) {
			this.errorhandler(res);

			try {
				res = await this.loadDefaultJson(slug);
				this.setCachedPages(slug, { ...this.pages.get(slug), 'page': res.data });
			} catch (err) {
				res = err
			}
		}
		return Promise.resolve(res);
	}

	/**
	 * Function  asynchronously loads a default JSON object based on a given slug and API path, then sets a theme slug before returning the result.
	 * @param {string} slug - a unique identifier or key used to retrieve specific JSON data to load.
	 * @param {string} [apiPath=InteriorPageJsons] - api path store in api names.
	 * @returns response object
	 */
	public async loadDefaultJson(slug: string, apiPath: string = 'InteriorPageJsons'): Promise<any> {
		let defaultJsonRes = await this.getDefaultSlugJson(slug, apiPath);
		defaultJsonRes.data.theme_slug = this.initServ.activeTheme;
		return defaultJsonRes
	}

	/**
	 * handle the page style api request and cache the page style with provided slug and if request has error then handle the error case.
	 * @param slug
	 */
	private async handlePageStyleApiRes(res: any, slug: string, isEmbed: boolean = false) {
		if (this.apiServ.checkAPIRes(res)) {
			this.setCachedPages(slug, { ...this.pages.get(slug), 'style': res?.data });
			this.styleServ.applyPageStyle(res, slug, undefined, undefined, false, isEmbed);
		} else if (this.interiorSecSlugs?.includes(slug) && this.initServ.appPlansPermission('Interior Style')) {
			this.errorhandler(res);
		}
	}

	/**
	 * Loads the default json via calling the api with header and path variable and load the `{slug}.json` from the provided api path.
	 * @param slug url slug used to retrieve the default json from the api path.
	 * @param apiPath path of the default json to load.
	 * @returns promise
	 */
	private getDefaultSlugJson(slug: string, apiPath: string = 'InteriorPageJsons'): Promise<any> {
		const header = { 'auth': 'false' };
		return this.apiServ.callApiWithPathVariables('GET', apiPath, [slug], null, header).pipe(takeUntil(this.destroy)).toPromise();
	}

	/**
	 * Method `errorhandler` logs or notifies an error message to BUGSNAG in production build and local console in development mode.
	 * @param {any} err - to capture the error that occurred during the execution of the code.
	 */
	public errorhandler(err: any, isTryCatch: boolean=false): void {
		if (IS_DEV) {
			console.log(`Could not build the section:= ${JSON.stringify(err)}`);
		} else {
			let msg: string = isTryCatch ? 'Could not build the section because of try catch' : 'Could not build the section'
			Bugsnag.notify(new Error(`${msg} = ${JSON.stringify(err)}`));
		}
	}
}
