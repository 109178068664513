<div id="how-it-works" class="w-100" *ngIf="sectionStatus && section && hiwUrl">
	<div class="tjs-card card border rounded-5 tjs-rounded--lg mb-30 w-100 bk-form-how-it-work" [id]="section?.id">
		<div class="card-body">
			<!-- <button type="button" class="btn btn-link d-flex text-capitalize p-0 bk-form-how-it-work-link" data-bs-toggle="modal" data-bs-target="#howItWork" (click)="setVideo(hiwUrl)">
				<i class="tjsicon-play-button me-5"></i><span [innerHTML]="section?.content | safeHtml"></span>
			</button> -->
			<button type="button" class="btn btn-link d-flex text-capitalize p-0 bk-form-how-it-work-link" (click)="bkngFormPopupServ.howItWorksPopup(hiwUrl)">
				<i class="tjsicon-play-button me-5"></i><span [innerHTML]="section?.content | safeHtml"></span>
			</button>
		</div>
	</div>
</div>
<!-- Modal -->
<!-- <div class="bs-example">
	<div id="howItWork" class="modal fade">
		<div class="modal-dialog info-video">
			<div class="modal-content tjs-popup tjs-popup--md mx-auto rounded-5">
				<div class="modal-header tjs-popup__header tjs-popup--mat__header">
					<h4 class="modal-title" id="howItWorkLabel">{{initServ?.appStr?.text?.explainerVideo | translate}}</h4>
					<button type="button" class="btn-close fs-13 tjs-popup__closed border-0 bg-transparent" data-bs-dismiss="modal" aria-hidden="true"><i class="tjsicon-close text-danger"></i></button>
				</div>
				<div class="modal-body tjs-popup__content">
					<iframe id="howItWorkVideo" class="w-100" autoplay width="870" height="480" src="" frameborder="0" allowfullscreen></iframe>
				</div>
			</div>
		</div>
	</div>
</div> -->