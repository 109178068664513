<div class="py-30 py-md-60" [id]="secId">
	<div class="container">
		<bk-profile-card type="info" [section]="section?.profile"></bk-profile-card>
		<!-- Address -->
		<bk-address-card [section]="section?.address"></bk-address-card>
		<!-- Billing -->
		<bk-billing-card-listing [section]="section?.billing"></bk-billing-card-listing>
		<!-- Invoice -->
		<div class="card mb-30 bk-section-card position-relative" [id]="section?.invoice?.id" *ngIf="((invoices && invoices.length > 0)) || (invoiceStatus?.giftCardStatus && (giftCards && giftCards.length > 0))">
			<div class="card-body">
				<div class="tjs-card-title d-flex align-items-center bk-section-border" [attr.id]="section?.invoice?.title_id">
					<h4 class="mb-0 card-title fw-bold d-flex align-items-end bk-section-title" [id]="section?.invoice?.title?.card_title_id" [innerHTML]="(section?.invoice?.title?.card_title) | safeHtml"></h4>
					<bk-tooltip class="align-self-end" customClass="tjs-tooltip-icon--lg bk-section-tooltip" [id]="section?.invoice?.title?.tooltip_id" [content]="(section?.invoice?.title?.tooltip)"></bk-tooltip>
				</div>
				<!-- Tab buttons -->
				<div [id]="section?.invoice?.cards_id" class="">
					<div class="mb-20 d-sm-flex" *ngIf="(invoices && invoices.length > 0) && (invoiceStatus?.giftCardStatus && giftCards && giftCards.length > 0)">
						<div class="d-sm-flex d-grid">
							<button type="button" class="btn btn-lg py-15 w-100 text-nowrap me-sm-10 {{(type == 'invoices') ? 'btn-primary bk-active-tab':'text-dark bk-tab'}}" (click)="type = 'invoices'">{{section?.invoice?.bkng_rcpt_card?.bkng_rcpt_btn}}</button>
							<button type="button" class="btn btn-lg py-15 w-100 text-nowrap {{(type == 'giftCards') ? 'btn-primary bk-active-tab':'text-dark bk-tab'}}" (click)="type = 'giftCards'">{{section?.invoice?.gift_rcpt_card?.gift_card_rcpt_btn}}</button>
						</div>
					</div>
					<ng-container *ngIf="type == 'invoices'; else gcInvoice">
						<ng-container *ngIf="invoices && invoices.length > 0; else gcInvoice">
							<bk-invoice-listing [data]="invoices" [section]="section?.invoice?.bkng_rcpt_card"></bk-invoice-listing>
							<div class="text-end mt-10" *ngIf="showMoreInv">
								<bk-navigate [secSett]="section?.invoice?.view_all_btn" [secId]="section?.invoice?.view_all_btn_id" [designId]="section?.invoice?.view_all_btn?.design_id" [isLinkBtn]="true" [routeLink]="['/invoices/all']" [innerHTML]="section?.invoice?.view_all_btn?.content" customClass="btn btn-primary"></bk-navigate>
							</div>
						</ng-container>
					</ng-container>
					<ng-template #gcInvoice>
						<ng-container *ngIf="invoiceStatus?.giftCardStatus && giftCards && giftCards.length > 0">
							<bk-invoice-listing [data]="giftCards" type="giftCards" [section]="section?.invoice?.gift_rcpt_card"></bk-invoice-listing>
							<div class="text-end mt-10" *ngIf="showMoreGc">
								<bk-navigate [secSett]="section?.invoice?.view_all_btn" [secId]="section?.invoice?.view_all_btn_id" [designId]="section?.invoice?.view_all_btn?.design_id" [isLinkBtn]="true" [routeLink]="['/invoices/giftcard-receipts']" [innerHTML]="section?.invoice?.view_all_btn?.content" customClass="btn btn-primary"></bk-navigate>
							</div>
						</ng-container>
					</ng-template>
				</div>
			</div>
			<!-- Loader -->
			<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
		</div>
	</div>
</div>
