<div class="position-relative" [ngClass]="{'row justify-content-center' : variationId == 'bk_short_form_V4'}" *ngIf="customFields && (customFields).length > 0">
	<div [ngClass]="{'col-lg-10 col-xl-8' : variationId == 'bk_short_form_V4'}">
		<ng-container *ngFor="let customField of customFields;trackBy: utilServ.trackByFnIndex">
			<div id="custom-section-{{customField?._id}}" [ngClass]="variationId == 'bk_short_form_V3' ? 'customfield_version_3' : 'customfield_version_4'" *ngIf="stepType == 'all' || customField?.display_on == stepType || (!customField.display_on && stepType == 'step_two')">
				<!-- Heading -->
				<div class="mb-25" *ngIf="customField?.name">
					<h4 class="mb-0 bk-title" [ngClass]="variationId == 'bk_short_form_V3' ? 'fw-bold' : 'display-5 text-center fw-normal'" [id]="sectionIds?.title ? (sectionIds?.title) : ''">{{customField?.name | translate}}</h4>
				</div>
				<div [formGroup]="customFieldsGroup" *ngIf="customField && customField?.custom_fields && (customField?.custom_fields).length > 0">
					<ng-container *ngFor="let field of customField?.custom_fields;trackBy: utilServ.trackByFnIndex">
						<ng-container [ngSwitch]="field?.field_type">
							<!-- Input -->
							<div class="mb-20" *ngSwitchCase="'Text'" [ngClass]="{'shadow-sm mb-30 p-20 p-md-10 d-md-flex bg-white rounded align-items-center' : variationId == 'bk_short_form_V4'}">
								<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
								<div [ngClass]="{'px-md-10 tjs-border-start-md col-md-6' : variationId == 'bk_short_form_V4'}">
									<input class="form-control bk-input" attr.placeholder="{{field?.placeholder | translate}}" name="{{field?.slug}}" formControlName="{{customField?._id}}_{{field?._id}}" [ngClass]="{'is-invalid': customFieldsGroup.get(customField._id+'_'+field._id)?.touched && customFieldsGroup.get(customField._id+'_'+field._id)?.errors, 'tjs-border-md-0 tjs-shadow-sm-none form-control-sm' : variationId == 'bk_short_form_V4'}">
									<ng-container *ngTemplateOutlet="errorTemp; context:{customField:customField, field:field}"></ng-container>
								</div>
							</div>
							<!-- Text area -->
							<div class="mb-20" *ngSwitchCase="'Text Area'" [ngClass]="{'shadow-sm mb-30 p-20 p-md-10 d-md-flex bg-white rounded align-items-center' : variationId == 'bk_short_form_V4'}">
								<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
								<div [ngClass]="{'px-md-10 tjs-border-start-md col-md-6' : variationId == 'bk_short_form_V4'}">
									<textarea class="form-control bk-form-textarea" rows="5" name="{{field?.slug}}" formControlName="{{customField?._id}}_{{field?._id}}" attr.placeholder="{{field?.placeholder | translate}}" [ngClass]="{'is-invalid': customFieldsGroup.get(customField?._id+'_'+field?._id)?.touched && customFieldsGroup.get(customField?._id+'_'+field?._id)?.errors, 'tjs-border-md-0 tjs-shadow-sm-none' : variationId == 'bk_short_form_V4'}"></textarea>
									<!-- Error -->
									<ng-container *ngTemplateOutlet="errorTemp; context:{customField:customField, field:field}"></ng-container>
								</div>
							</div>
							<!-- Radio -->
							<div class="mb-20" *ngSwitchCase="'Radio'" [ngClass]="{'shadow-sm mb-30 p-20 p-md-10 d-md-flex bg-white rounded align-items-center' : variationId == 'bk_short_form_V4'}">
								<ng-container *ngIf="field?.options && (field?.options).length > 0">
									<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
									<div [ngClass]="{'px-md-10 tjs-border-start-md col-md-6' : variationId == 'bk_short_form_V4'}">
										<div class="form-check my-5 form-check-inline" *ngFor="let option of field?.options;trackBy: utilServ.trackByFnIndex">
											<input id="{{customField?._id}}_{{field?._id}}_{{option}}" class="form-check-input" name="{{customField?._id}}_{{field?._id}}" formControlName="{{customField?._id}}_{{field?._id}}" type="radio" value="{{option}}">
											<label class="form-check-label" for="{{customField?._id}}_{{field?._id}}_{{option}}" [id]="sectionIds?.label ? (sectionIds?.label) : ''">{{option | translate}}</label>
										</div>
									</div>
									<!-- Error -->
									<ng-container *ngTemplateOutlet="errorTemp; context:{customField:customField, field:field}"></ng-container>
								</ng-container>
							</div>
							<!-- Checkbox -->
							<div class="mb-20" *ngSwitchCase="'Checkbox'" [ngClass]="{'shadow-sm mb-30 p-20 p-md-10 d-md-flex bg-white rounded align-items-center' : variationId == 'bk_short_form_V4'}">
								<ng-container *ngIf="field?.options && (field?.options).length > 0">
									<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>



									<ng-container formGroupName="{{customField?._id}}_{{field?._id}}">
										<div [ngClass]="{'px-md-10 tjs-border-start-md col-md-6' : variationId == 'bk_short_form_V4'}">
											<div class="form-check form-check-inline my-5" *ngFor="let option of field?.options;trackBy: utilServ.trackByFnIndex">

												<input id="{{customField?._id}}_{{field?._id}}_{{option}}" type="checkbox" formControlName="{{option | RmSpclCharAndSpace}}" class="form-check-input" (change)="bkngCustSecServ.custCheckBoxChange($event, customField?._id+'_'+field?._id, option, field); onHierarchicalCheckboxFieldChange($event, field, option); customFieldChange.emit(); onValChange(customField?._id+'_'+field._id, option, $event)">
												<label class="form-check-label" for="{{customField?._id}}_{{field?._id}}_{{option}}" [id]="sectionIds?.label ? (sectionIds?.label) : ''">{{option | translate}}</label>
											</div>
											<!-- Error message -->
											<div class="tjs-error bk-error" *ngIf="cfCheckboxGroup.controls[customField?._id+'_'+field?._id].touched && cfCheckboxGroup.controls[customField?._id+'_'+field?._id].errors">
												<i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.required | translate}}
											</div>
										</div>
									</ng-container>
								</ng-container>
							</div>
							<!-- Drop down -->
							<div class="mb-20" *ngSwitchCase="'Dropdown'" [ngClass]="{'shadow-sm mb-30 p-20 p-md-10 d-md-flex bg-white rounded align-items-center' : variationId == 'bk_short_form_V4'}">
								<ng-container *ngIf="field?.options && (field?.options).length > 0">
									<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
									<div [ngClass]="{'px-md-10 tjs-border-start-md col-md-6' : variationId == 'bk_short_form_V4'}">
										<select class="form-select" [ngClass]="{'tjs-custom-select-sm form-select-sm tjs-border-md-0 tjs-shadow-sm-none fs-15' : variationId == 'bk_short_form_V4'}" name="{{field?.slug}}" formControlName="{{customField?._id}}_{{field?._id}}">
											<option value="">-- {{initServ?.appStr?.text?.selectOption | translate}} --</option>
											<ng-container *ngFor="let option of field?.options;trackBy: utilServ.trackByFnIndex">
												<option value="{{option}}" [id]="sectionIds?.label ? (sectionIds?.label) : ''">{{option | translate}}</option>
											</ng-container>
										</select>
										<ng-container *ngTemplateOutlet="errorTemp; context:{customField:customField, field:field}"></ng-container>
									</div>
								</ng-container>
							</div>
							<!-- Note -->
							<div class="mb-20" *ngSwitchCase="'Note'" [ngClass]="{'shadow-sm mb-30 p-20 p-md-10 d-md-flex bg-white rounded align-items-center' : variationId == 'bk_short_form_V4'}">
								<ng-container *ngTemplateOutlet="labelTemp; context:{field:field}"></ng-container>
								<span class="bk-note" [id]="sectionIds?.label ? (sectionIds?.label) : ''" [innerHTML]="field?.note_text | translate | safeHtml"></span>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</ng-container>
		<!-- Loader -->
		<bk-app-loader [loaderId]="bkngCustSecServ.loaderId"></bk-app-loader>
	</div>
</div>
<!-- Custom template -->
<ng-template #labelTemp let-field="field">
	<label class="form-label d-block" [ngClass]="{'px-5 col-md-6 mb-md-0' : variationId == 'bk_short_form_V4'}" [id]="sectionIds?.label ? (sectionIds?.label) : ''">
		{{field?.name | translate}}
		<!-- Tooltip -->
		<bk-tooltip *ngIf="field?.help_text" class="bk-tooltip" content="{{field?.help_text | translate}}"></bk-tooltip>
	</label>
</ng-template>
<!-- Form error template -->
<ng-template #errorTemp let-customField="customField" let-field="field">
	<div class="tjs-error bk-error" *ngIf="field?.is_required && customFieldsGroup.get(customField?._id+'_'+field?._id)?.touched && customFieldsGroup.get(customField?._id+'_'+field?._id)?.errors?.required">
		<i class="tjsicon-attention"></i>{{initServ?.appStr?.errorMsg?.required | translate}}
	</div>
</ng-template>
