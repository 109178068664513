import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ } from '../../Services';

@Component({
	selector: 'bk-embed-video',
	template: `
	<div [id]="secId" class="tjs-emb-video text-center" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section && section?.video && section?.video?.url">
		<div [id]="section?.video?.id" class="position-relative overflow-hidden tjs-emb-video__default-iframe">
			<iframe class="tjs-emb-video__iframe position-absolute top-0 start-0 end-0 w-100 h-100 border-0 bk-embed-video" [src]="section?.video?.url | safeHtml: 'resourceUrl'" [height]="height" width="100%" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			<script [src]="embedJsUrl" defer></script>
		</div>
	</div>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class EmbedVideoComponent implements OnInit {

	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = {
		video: null
	}
	embedJsUrl:any;
	height: string = '0';
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, private initServ: InitServ, public sanitizer: DomSanitizer) {
		this.embedJsUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://' + this.initServ.appData.primary_custom_domain + '/resources/embed.js');
	}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.buildSection(this.sectionData);
		}
	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secServ.setServData(this.pageSett, secData.content);
		this.section = this.secServ.buildSectionFields(this.secId, this.section, secData);
	}
}