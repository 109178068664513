<ng-container *ngIf="navAvailable">
	<ng-container [ngSwitch]="secSett?.link_to">
		<!-- Page -->
		<a *ngSwitchCase="'page'" [id]="secId ? secId : secSett?.id" class="{{customClass}}" [ngClass]="{'active': checkCurrentUrl(secSett?.link_url, link)}" [routerLink]="link ? [link] : null" [target]="(secSett?.open_in_new) ? '_blank' : '_self'" [innerHTML]="(innerHTML ? innerHTML : '') | safeHtml" (click)="isPopup ? dialogRef.close() : ''"></a>
		<!-- Web/Email/Phone -->
		<a *ngSwitchCase="['web', 'email', 'phone'].includes(secSett?.link_to) ? secSett?.link_to : ''" [id]="secId ? secId : secSett?.id" class="{{customClass}}" [ngClass]="{'active': checkCurrentUrl(secSett?.link_url, link), 'pe-none': !link}"  [href]="link" [target]="(secSett?.open_in_new) ? '_blank' : '_self'" [innerHTML]="(innerHTML ? innerHTML : '') | safeHtml" (click)="isPopup ? dialogRef.close() : ''"></a>
		<!-- Popup -->
		<button *ngSwitchCase="['popup', 'lead_popup'].includes(secSett?.link_to) ? secSett?.link_to : ''" [id]="secId ? secId : secSett?.id" type="button" class="{{customClass}}" [ngClass]="{'active': checkCurrentUrl(secSett?.link_url, link)}" (click)="showPopup(secSett)" [innerHTML]="(innerHTML ? innerHTML : '') | safeHtml"></button>
		<!-- section -->
		<button *ngSwitchCase="'section'" [id]="secId ? secId : secSett?.id" type="button" class="{{customClass}}" [ngClass]="{'active': checkCurrentUrl(secSett?.link_url)}" (click)="scrollToSec(secSett)" [innerHTML]="(innerHTML ? innerHTML : '') | safeHtml"></button>
		<!-- None -->
		<ng-container *ngSwitchDefault>
			<ng-container *ngIf="isText; else btnBlock">
				<!-- Plan text -->
				<span [id]="secId ? secId : secSett?.id" class="{{customClass}}" [ngClass]="{'active': checkCurrentUrl(secSett?.link_url)}" [innerHTML]="(innerHTML ? innerHTML : '') | safeHtml"></span>
			</ng-container>
			<ng-template #btnBlock>
				<ng-container *ngIf="isSubmit; else noSubmit">
					<!-- Submit button -->
					<button [id]="secId ? secId : secSett?.id" type="submit" class="{{customClass}}" [ngClass]="{'active': checkCurrentUrl(secSett?.link_url)}" [innerHTML]="(innerHTML ? innerHTML : '') | safeHtml" [disabled]="btnDisabled ? true : null" [class.disabled]="btnDisabled ? true : null"></button>
				</ng-container>
				<ng-template #noSubmit>
					<!-- Normal button -->
					<ng-container *ngIf="isLinkBtn; else defNormalBtn">
						<a [id]="secId ? secId : secSett?.id" [routerLink]="routeLink" class="{{customClass}}" [ngClass]="{'active': checkCurrentUrl(secSett?.link_url)}" [innerHTML]="(innerHTML ? innerHTML : '') | safeHtml" [class.disabled]="btnDisabled ? true : null" ></a>
					</ng-container>
					<ng-template #defNormalBtn>
						<ng-container *ngIf="isDebounce; else normalBtn">
							<button bkDebounceEvent [loaderId]="debounceLoaderId" [isDebounceLoader]="isDebounceLoader" [id]="secId ? secId : secSett?.id" type="button" class="{{customClass}}" [ngClass]="{'active': checkCurrentUrl(secSett?.link_url)}" (debounceClick)="clickEvent($event)" [innerHTML]="(innerHTML ? innerHTML : '') | safeHtml" [attr.disabled]="btnDisabled ? true : null" [class.disabled]="btnDisabled ? true : null"></button>
						</ng-container>
						<ng-template #normalBtn>
							<button [id]="secId ? secId : secSett?.id" type="button" class="{{customClass}}" [ngClass]="{'active': checkCurrentUrl(secSett?.link_url)}" (click)="clickEvent($event)" [innerHTML]="(innerHTML ? innerHTML : '') | safeHtml" [disabled]="btnDisabled ? true : null" [class.disabled]="btnDisabled ? true : null"></button>
						</ng-template>
					</ng-template>
				</ng-template>
			</ng-template>
		</ng-container>
	</ng-container>
</ng-container>
