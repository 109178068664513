import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
// Services
import { NgOnDestroy, InitServ } from '../../../Services';

@Component({
	selector: 'bk-date-display',
	template: `
	<div>
		<span>{{formattedDate}}</span>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class DateDisplayComponent implements OnInit {
	// Variables
	@Input() date : any;
	@Input() isStr: boolean = false;
	@Input() isDayName: boolean = false;
	@Input() isLocal : boolean = false; // Local time zone(means your system time zone), so isLocal=true.

	dateFormat: any = 'MM/DD/YYYY';
	formattedDate : string = '';

	constructor(private initServ: InitServ) {
		this.dateFormat = this.initServ.storeDateFormat;
	}

	ngOnInit() {
		if(this.isStr){
			this.date = dayjs(this.date).unix();
		}
		if(this.isDayName){
			this.dateFormat = 'ddd '+this.dateFormat;
		}
		if(!this.isLocal){
			this.isLocal = false;
		}
		if(this.isLocal){
			this.formattedDate = dayjs.unix(this.date).format(this.dateFormat)
		}else{
			dayjs.extend(utc);
			this.formattedDate = dayjs.unix(this.date).utc().format(this.dateFormat)
		}
	}
}
