import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Invoice, SummBkngTotalFields } from 'src/app/Interfaces/Invoice';
// Service
import { ApiServ, InitServ, UtilServ } from 'src/app/Services';

@Injectable({
	providedIn: 'root'
})
export class InvServ {
	// Private variables
	private destroy = new Subject<void>();
	admnStngs: any;
	bkngAmtData: any;
	hideSidebar: boolean = false;
	bkngsTipAmt: number = 0;
	priceParams: string[] = ['service_total', 'referral_discount', 'expedited_amount', 'frequency_discount', 'exempt_extras_price', 'spot_discount', 'coupon_discount', 'service_fee', 'discounted_total', 'adjusted_amount', 'sales_tax', 'tip', 'parking', 'gift_card_discount', 'sub_total', 'priceable_custom_sections'];
	summBkngTotalFields: SummBkngTotalFields = {
		service_total: 0,
		referral_discount: 0,
		expedited_amount: 0,
		frequency_discount:0,
		exempt_extras_price: 0,
		spot_discount: 0,
		coupon_discount:0,
		service_fee:0,
		discounted_total:0,
		adjusted_amount: 0,
		sales_tax: 0,
		tip: 0,
		parking: 0,
		gift_card_discount: 0,
	}
	formParamsName: {[key:string]:string} = {
		booking_id:'Booking ID',
		service_date: 'Service date',
		industry: 'Industry',
		frequency: 'Frequency',
		service: 'Service category',
		pricing_parameter: 'Pricing parameter',
		excludes:'Excludes',
		items:'Items',
		packages:'Packages',
		extras: 'Extras',
		custom_section: 'Custom section',
		total: 'Total',
		service_total: 'Service total',
		referral_discount: 'Referral discount',
		expedited_amount: 'Expedited amount',
		frequency_discount: 'Frequency discount',
		exempt_extras_price: 'Exempted extras',
		spot_discount: 'Spot discount',
		coupon_discount: 'Coupon discount',
		service_fee: 'Service fee',
		discounted_total: 'Discounted total',
		adjusted_amount: 'Adjusted amount',
		sales_tax: 'Sales tax',
		tip: 'Tip',
		parking: 'Parking',
		gift_card_discount: 'Gift card discount',
		sub_total : 'Sub total',
		priceable_custom_sections: 'Priceable Custom Sections',
	}

	// eslint-disable-next-line max-params
	constructor(private initServ: InitServ,private utilServ: UtilServ, private frmBldr: FormBuilder, public router: Router, private apiServ: ApiServ) {
		this.admnStngs = this.initServ.appAdmnStngs;
	}

	public getStoreLogo(): any {
		if(this.admnStngs?.merchant_settings?.store?.store_logo){
			return this.admnStngs.merchant_settings.store.store_logo;
		}
		return null;
	}

	public generateImgTag(id: string, width: string, imgBase: string, src?: string): string {
		return `<img loading='lazy' id=${id} width=${width} height='auto' src=${imgBase}${src} alt='logo' />`;
	}

	/**
	 * Check if this.admnStngs.merchant_settings.store[storeField] exists,
	 * if not, set val to an empty string
	 * Generate a dynamic string using a utility function (generateDynamicStr)
	 * Replace occurrences of '{{.${shortCode}}}' in content with val
	 * @param content: content
	 * @param shortCode: shortcode
	 * @param storeField: store fields
	 * @returns string
	 */
	public tokenReplace(content: string, shortCode: string, storeField: string): string {
		if(content) {
			let val: string = (this.initServ.appAdmnStngs?.merchant_settings?.store?.[storeField]) ?? '';
			return this.utilServ.generateDynamicStr(content, '{{.'+shortCode+'}}', val)
		}
		return '';
	}

	/**
	 * Get the invoice remaining total in case of custom invoice
	 * @param invData: Invoice data
	 * @returns invoice total
	 */
	public calInvRemainingTotal(invData: any): any {
		// Initialize remaining with the total amount from invData if available
		let remaining: number = invData?.total_amount;
		// Check if paid_amount exists in invData
		if(invData?.paid_amount){
			// Subtract tip amount if present
			if(invData?.tip?.pay){
				remaining = this.utilServ.roundToTwo(remaining - invData.tip.pay);
			}
			return this.utilServ.roundToTwo(remaining - invData.paid_amount);
		}
		return remaining;
	}

	/**
	 * Calculate the invoice total amount
	 * @param invData: invoice data
	 * @returns invoice total
	 */
	public calInvTotal(invData: any): any {
		let invTotal: number = invData?.total_amount;
		if(invData?.paid_amount && invData?.tip?.pay){
			invTotal = this.utilServ.roundToTwo(invTotal - invData.tip.pay);
		}
		return invTotal;
	}

	/**
	 * Build form and load uniquesLoc fucntion in case of stripe
	 */
	public buildInvPaymentForm(): any {
		return this.frmBldr.group({
			location_id: [null],
			pay_with_cc: [],
			payment_method: ['existing_card'],
			card_last4_digits:[''],
			secure_inv_payment: [''] //Added field for honeypot validation
		});
	}

	/**
	 * Invoice amount payload
	 * @param invData: invoice data
	 * @returns payload
	 */
	public invAmtPayload(invData: Invoice): Invoice {
		let payload: Invoice = {
			inv_id: invData?.inv_id,
			inv_num: invData?.inv_num,
			payment_type: invData?.payment_type,
			no_of_payments :invData?.no_of_payments,
			paid_amount: invData?.paid_amount,
			has_tip: invData?.has_tip
		}
		payload = this.invSettPayload(invData, payload)
		return payload;
	}

	/**
	 * Sets the payload for invoice data.
	 * If the invoice type is 'custom', it calculates the invoice
	 * total and adds it to the payload. Otherwise, it generates
	 * the payload for booking data. It also includes tip payload
	 * and partial payment flag if applicable.
	 * @param invData The invoice data.
	 * @param payload The invoice payload.
	 * @returns The updated invoice payload.
	 */
	private invSettPayload(invData: Invoice, payload: Invoice): Invoice {
		if(invData?.inv_type == 'custom'){
			payload['inv_amount'] = this.calInvTotal(invData);
		} else {
			payload = this.bkngPayload(invData, payload);
		}
		payload = this.tipPayload(invData, payload);
		if(invData?.settings?.partial_pay){
			payload['partial_pay'] =  invData.settings.partial_pay;
		}
		return payload;
	}

	/**
	 * Tip payload data
	 * @param invData: Invoice data
	 * @param payload: payload
	 * @returns payload
	 */
	public tipPayload(invData: Invoice, payload: Invoice): Invoice {
		if(invData?.tip?.pay && !invData?.no_of_payments){
			payload['tip'] = invData.tip;
			payload['tip_provider_ids'] = invData.settings?.tip_settings?.prov_ids;
		}
		return payload;
	}

	/**
	 * Booking payload data
	 * @param invData: Invoice data
	 * @param payload: Payload data
	 * @returns payload
	 */
	public bkngPayload(invData: Invoice, payload: Invoice): any {
		if(this.isObjExist(invData?.bookings_tip) && !invData?.no_of_payments){
			payload['bookings_tip'] = invData.bookings_tip;
		}
		if(this.utilServ.checkArrLength(invData?.booking_ids)){
			payload['booking_ids'] = invData.booking_ids;
		}
		return payload;
	}

	/**
	 * Checks if an object exists and is not empty.
	 * Returns true if the object exists and has properties,
	 * otherwise returns false.
	 * @param obj The object to check.
	 * @returns True if the object exists and is not empty, false otherwise.
	 */
	public isObjExist(obj: any): boolean {
		return (obj && Object.keys(obj)?.length !== 0);
	}

	/**
	 * Checks if 3D Secure is enabled.
	 * Returns true if 3D Secure is enabled and the payment gateway
	 * is not 'authorizedotnet', otherwise returns false.
	 * @returns True if 3D Secure is enabled and the payment gateway is not 'authorizedotnet', false otherwise.
	 */
	public isThreeDS(): boolean {
		return (this.initServ.threeDSecure && this.initServ.paymentGateway !== 'authorizedotnet');
	}

	/**
	 * Invoice charge payload
	 * @param invData: Invoice data
	 * @param formVal: Invoice payment form values
	 * @param chargedAmt: Changed amount data in case of 3DS
	 * @returns payload
	 */
	public invChargePayload(invData: any, formVal: any, chargedAmt: any): any {
		// Create an object to store payment-related data
		let chargePayload: any = {
			inv_id: invData?.inv_id,
			inv_num: invData?.inv_num,
			payment_type: invData?.payment_type,
			payment_method: formVal.payment_method,
			inv_amount: this.calInvTotal(invData),
			uid: invData?.uid,
			charged_amount: chargedAmt?.amount, // In case of 3DS
			location_id: +formVal.location_id,
			has_tip: invData?.has_tip,
			billing_address: formVal?.billing_address
		};
		// Check invoice type for bookings and handle accordingly
		chargePayload = this.getBkngPayload(invData, chargePayload, chargedAmt);
		// Include tip-related data in the payload
		chargePayload = this.tipPayload(invData, chargePayload);
		return chargePayload;
	}

	/**
	 * Retrieves the booking payload based on invoice data, charge payload, and charged amount.
	 * If the invoice type is 'bookings', it adds the captured amount in case of card hold and generates
	 * the payload specific to bookings using the 'bkngPayload' function.
	 * @param invData The invoice data.
	 * @param chargePayload The payload containing transaction details.
	 * @param chargedAmt The charged amount data.
	 * @returns The updated charge payload.
	 */
	private getBkngPayload(invData: any, chargePayload: any, chargedAmt: any): any {
		if(invData?.inv_type == 'bookings'){
			// captured amount in case of card hold
			if(chargedAmt?.captured_amount){
				chargePayload['captured_amount'] = chargedAmt.captured_amount;
			}
			// Generate payload specific to bookings
			chargePayload = this.bkngPayload(invData, chargePayload);
		}
		return chargePayload;
	}

	/**
	 * Redirects the user based on the payment information section and the current user.
	 * Retrieves the redirect URL from the payment information section and determines
	 * the type of redirect based on the 'link_to' property. If 'link' or 'web', it
	 * redirects directly to the link URL. If 'page', it generates the redirect URL
	 * after success redirection and redirects accordingly. If none of the above cases
	 * match, it redirects the user to the dashboard if logged in, otherwise to the login page.
	 * @param section The payment information section.
	 * @param currentUser The current user information.
	 */
	public invRedirectUser(section: any, currentUser: any) {
		let redirectUrl: any = section?.payment_info?.redirect_link?.link_url;
		let goToLinkUrl: any = null;
		switch (section?.payment_info?.redirect_link?.link_to){
			case 'link':
			case 'web':
				// eslint-disable-next-line no-case-declarations
				let linkUrl: any = this.utilServ.checkHttpExist(redirectUrl);
				this.utilServ.redirect(linkUrl);
				break;
			case 'page':
				goToLinkUrl = this.utilServ.getAfterSuccessRedirection(redirectUrl);
				if(goToLinkUrl){
					this.utilServ.redirect(this.utilServ.generateLink(goToLinkUrl));
				}
			break;
			default:
				if(currentUser){
					this.router.navigate([`/${this.initServ.appDynamicRoutes['dashboard']}`]);
				} else {
					this.router.navigate([`/${this.initServ.appDynamicRoutes['login']}`]);
				}
			break;
		}
	}

	/**
	 * Fetches the booking invoice amount from the API.
	 * Constructs the payload if not provided and makes a POST request to 'BkngInvDetails' endpoint.
	 * Subscribes to the API response and handles it using 'bkngResHandler'.
	 * @param payload The payload for the API request.
	 * @param invData The invoice data.
	 */
	public fetchBkngInvAmt(payload: any = null, invData: any = null): void {
		payload = payload ? payload : this.invAmtPayload(invData)
		this.apiServ.callApi('POST', 'BkngInvDetails',payload).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.bkngResHandler(res));
	}

	/**
	 * Handles the API response for booking total.
	 * If the API response is successful and data is available,
	 * it assigns the booking amount data to 'bkngAmtData'.
	 * @param res The API response.
	 */
	private bkngResHandler(res: any): void {
		if(this.apiServ.checkAPIRes(res) && res?.data){
			this.bkngAmtData = res.data;
		}
	}
}
