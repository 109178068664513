import { Component, OnInit, ViewEncapsulation, Self } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from "rxjs/operators";
// Services
import { NgOnDestroy, UtilServ, ApiServ, LoaderServ, PopupServ, InitServ, SectionServ, RenderComponentServ } from '../Services';
@Component({
	selector: 'bk-dashboard',
	templateUrl: './Dashboard.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class DashboardComponent implements OnInit {
	// Variables
	tabs: any[] = [
		{ name: 'Appointments', slug: 'upcoming' },
		{ name: 'Previous appointments', slug: 'completed'},
		{ name: 'Canceled appointments', slug: 'cancelled'}
	]
	active: string = 'upcoming';
	limit: number = 9;
	page: number = 1;
	bookings: any;
	loaderId: string = 'upcoming-loader';
	multiIndusStatus: boolean = false;
	renderProfile: boolean = true;
	viewAllRoute: string = '/appointments';
	showLoadMore: boolean = false;
	//
	secId: any;
	section: any = { profile: null, appointments: null };

	// eslint-disable-next-line max-params
	constructor(@Self() public destroy: NgOnDestroy, public actRoute: ActivatedRoute, public router: Router, public utilServ: UtilServ, public apiServ: ApiServ, public loader: LoaderServ, public popupServ: PopupServ, public initServ: InitServ, public rcServ: RenderComponentServ, public secServ: SectionServ) {
		// Declined card bookings
		this.declinedCard();
		// URL Fragment, based this active tab works, other wise default.
		let fragment = this.actRoute.snapshot.fragment;
		if (fragment) {
			this.activeTab(fragment);
		} else {
			this.userBookings();
		}
		// Multi industry
		let multiIndus: any = this.utilServ.multiIndustriesStatus();
		this.multiIndusStatus = multiIndus?.multiIndus;
	}

	ngOnInit(): void {
		// Build section data
		if (this.secId && this.rcServ.pageData) {
			let pageSett:any = this.rcServ.pageData.section_settings; // page settings
			this.secServ.setServData(pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
	}
	/**
	 * User bookings
	 */
	private userBookings(): void {
		this.bookings = null;
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId);
		let queryParams = { status: this.active, limit: this.limit, page: this.page };
		this.apiServ.callApiWithPathQueryVars('GET', 'Bookings', [this.utilServ.userId()], queryParams).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'booking'));
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	public onResultCallback(res: any, type: string = 'null'): void {
		switch (type) {
			case 'declined':
				this.updateTabs(res);
				break;
			case 'booking':
				this.getBookings(res);
				break;
			default: break;
		}
	}
	/**
	 * Method to update the tab when payment declined
	 * @param res response
	 */
	private updateTabs(res:any): void{
		if (this.apiServ.checkAPIRes(res) && res.data) {
			this.tabs.push({ name: 'Declined payments', slug: 'declined'});
		}
	}
	/**
	 * Method to get the bookings data
	 * @param res response
	 */
	private getBookings(res:any): void{
		if(this.apiServ.checkAPIRes(res) && this.utilServ.checkArrLength(res?.data)){
			// Updates the list of items and determines whether to show a "Load More" button based on the limit.
			let loaMoreData = this.utilServ.updateLoadMoreItems(res?.data, this.bookings, this.limit);
			this.bookings = loaMoreData.items;
			this.showLoadMore = loaMoreData.showLoadMore;
		}
		this.loader.hide(this.loaderId);
	}
	/**
	 * Based on active tab get the user bookings.
	 * @param status upcoming/completed/cancelled/declined
	 */
	public activeTab(status: string, navigate: boolean = false): void {
		this.active = status;
		this.loaderId = `${status}-loader`;
		this.userBookings();
		if (navigate && this.router && this.router.url) {
			let route = this.utilServ.getUrlWithoutFragment(this.router.url);
			this.router.navigateByUrl(`${route}#${status}`);
		}
		switch (status) {
			case 'completed':
				this.viewAllRoute = '/appointments/previous';
				break;
			case 'cancelled':
				this.viewAllRoute = '/appointments/canceled';
				break;
			case 'declined':
				this.viewAllRoute = '/appointments/declined-payments';
				break;
			default:
				this.viewAllRoute = '/appointments';
				break;
		}
	}
	/**
	 * Industries popup
	 */
	public industriesPopup(): void {
		this.popupServ.industriesPopup();
	}
	/**
	 * Refresh component
	 * @param status
	 */
	public refreshComp(status: boolean): void {
		this.renderProfile = false;
		if (status) { this.userBookings();}
		setTimeout(() => this.renderProfile = true, 0);
	}
	/**
	 * Get the declined card bookings
	 */
	public declinedCard() {
		let queryParams = {status: 'declined', limit: 1, page: this.page};
		this.apiServ.callApiWithPathQueryVars('GET', 'Bookings', [this.utilServ.userId()], queryParams).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.onResultCallback(res, 'declined'));
	}
}
