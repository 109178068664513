<div class="min-height--100" [ngClass]="isMultiStepForm ? 'mb-30' : 'py-30 px-md-30 border-bottom bk-card-border'"  *ngIf="section && sectionStatus">
	<div class="tjs-tab tjs-tab--box tjs-tab--box__light" [formGroup]="discountForm">
		<ul class="nav tjs-list ms-5">
			<li>
				<a class="tjs-tab__link text-dark active bk-form-discount-tab" [id]="section?.coupon_tab_id" href="#couponcode" data-bs-toggle="tab" (click)="activeTab='coupon'">{{(section?.coupon_tab) ? (section?.coupon_tab) : 'Coupon Code'}}</a>
			</li>
			<li *ngIf="giftCardPerm && (giftCard?.discount || ((disabledPages && !disabledPages.includes('gift-card')) || !disabledPages))">
				<a class="tjs-tab__link text-dark bk-form-discount-tab" [id]="section?.gift_card_tab_id" href="#giftcards" data-bs-toggle="tab" (click)="activeTab = 'giftcards'">{{(section?.gift_card_tab) ? (section?.gift_card_tab) : 'Gift Cards'}}</a>
			</li>
			<li *ngIf="referralPerm && (referral?.display || (prefilledData && prefilledData?.referral_amount)) && (prefilledData?.referral_amount || ((disabledPages && !(disabledPages).includes('referrals')) || !disabledPages))">
				<a class="tjs-tab__link text-dark bk-form-discount-tab" [id]="section?.referral_tab_id" href="#referrals" data-bs-toggle="tab" (click)="activeTab = 'referrals'">{{(section?.referral_tab) ? (section?.referral_tab) : 'Referrals'}}</a>
			</li>
		</ul>
		<div class="tab-content tjs-bg-gray p-20 rounded-3 bk-form-discount-tab-content">
			<!-- Coupon -->
			<div class="tab-pane active" id="couponcode" [formGroup]="couponGroup">
				<ng-container *ngTemplateOutlet="labelTemp; context:{name:'coupon_code'}"></ng-container>
				<div class="row">
					<div class="col-12 col-md-6 mb-20 mb-md-0">
						<input id="couponCodeVal" class="form-control" [ngClass]="isMultiStepForm ? 'form-control-lg' :''" type="text" placeholder="{{section?.coupon_code_placeholder}}" name="coupon_code" [value]="codeValue" [(ngModel)]="codeValue" [ngModelOptions]="{standalone: true}" (focusin)="coupon['errorMsg'] = ''" (focusout)="coupon['errorMsg']= ''; codeValue = codeValue && (codeValue).trim()" [attr.disabled]="isDisabledCoupon">
					</div>
					<!-- Buttons -->
					<div class="col-12 col-md-6">
						<ng-container *ngIf="!(coupon?.flag); else removeCouponBtn">
							<!-- Apply -->
							<bk-navigate *ngIf="codeValue; else disabledCouponBtn" innerHTML="{{section?.coupon_apply_btn?.content | translate}}" customClass="btn btn-primary border-transparent" essentialClass="w-100 h-100 bk-form-discount-btn" [secSett]="section?.coupon_apply_btn" [btnDisabled]="isDisabledCoupon ? true : false" (callback)="applyCoupon('code',codeValue)"></bk-navigate>
							<!-- Apply disabled btn -->
							<ng-template #disabledCouponBtn>
								<bk-navigate innerHTML="{{section?.coupon_apply_btn?.content | translate}}" customClass="btn btn-primary border-transparent" essentialClass="w-100 h-100 bk-form-discount-btn"  [secSett]="section?.coupon_apply_btn" [btnDisabled]="true"></bk-navigate>
							</ng-template>
						</ng-container>
						<ng-template #removeCouponBtn>
							<!-- Remove  -->
							<bk-navigate innerHTML="{{section?.coupon_remove_btn?.content  | translate}}" customClass="btn btn-danger border-transparent" essentialClass="w-100 h-100 bk-form-discount-btn" [secSett]="section?.coupon_remove_btn" [btnDisabled]="isDisabledCoupon ? true : false" (callback)="removeCoupon()"></bk-navigate>
						</ng-template>
					</div>
				</div>
				<!-- Error -->
				<div class="tjs-error bk-error " *ngIf="coupon?.errorType && (coupon?.errorType == 'min_order' || coupon?.errorType == 'max_order'); else otherCouponError">
					<i class="tjsicon-attention"></i>
					<ng-container *ngIf="coupon?.errorType == 'min_order'">
						{{'To use this coupon, a minimum purchase of at least' | translate}} &nbsp; <bk-amount-display></bk-amount-display> {{coupon?.errorMsg}} {{'is required' | translate}}
					</ng-container>
					<ng-container *ngIf="coupon?.errorType == 'max_order'">
						{{'To use this coupon, your purchase must not exceed' | translate}} &nbsp; <bk-amount-display></bk-amount-display> {{coupon?.errorMsg}}.
					</ng-container>
				</div>
				<ng-template #otherCouponError>
					<div class="tjs-error bk-error" *ngIf="coupon?.errorMsg" translate>
						<i class="tjsicon-attention"></i>{{coupon?.errorMsg}}
					</div>
					<div class="tjs-success bk-success" *ngIf="coupon?.successMsg" translate>
						<i class="tjsicon-attention"></i>{{coupon?.successMsg}}
					</div>
				</ng-template>
			</div>
			<!-- Gift card -->
			<div class="tab-pane" id="giftcards" *ngIf="giftCardPerm">
				<ng-container *ngTemplateOutlet="labelTemp; context:{name:'gift_card'}"></ng-container>
				<div class="row">
					<div class="col-12 col-md-6 mb-20 mb-md-0">
						<input id="giftCardVal" class="form-control" [ngClass]="isMultiStepForm ? 'form-control-lg' :''" type="text" placeholder="{{section?.gift_card_placeholder}}" name="coupon_code" [value]="giftCardCode" [(ngModel)]="giftCardCode" [ngModelOptions]="{standalone: true}" (focusin)="giftCard['errorMsg'] = ''" (focusout)="giftCard['errorMsg']= ''; giftCardCode = giftCardCode && (giftCardCode).trim()" [attr.disabled]="isDisabled">
					</div>
					<!-- Buttons -->
					<div class="col-12 col-md-6">
						<ng-container *ngIf="!(giftCard?.flag); else removeGiftCardBtn">
							<!-- Apply -->
							<bk-navigate *ngIf="giftCardCode; else disabledGiftCardBtn" innerHTML="{{section?.gift_card_apply_btn?.content  | translate}}" customClass="btn btn-primary border-transparent" essentialClass="w-100 h-100 bk-form-discount-btn" [secSett]="section?.gift_card_apply_btn" [btnDisabled]="isDisabled ? true : false" (callback)="applyGiftCard(giftCardCode)"></bk-navigate>
							<!-- Apply disabled btn -->
							<ng-template #disabledGiftCardBtn>
								<bk-navigate innerHTML="{{section?.gift_card_apply_btn?.content | translate}}" customClass="btn btn-primary border-transparent" essentialClass="w-100 h-100 bk-form-discount-btn" [secSett]="section?.gift_card_apply_btn" [btnDisabled]="true"></bk-navigate>
							</ng-template>
						</ng-container>
						<ng-template #removeGiftCardBtn>
							<!-- Remove  -->
							<bk-navigate innerHTML="{{section?.gift_card_remove_btn?.content | translate}}" customClass="btn btn-danger border-transparent" essentialClass="w-100 h-100 bk-form-discount-btn" [secSett]="section?.gift_card_remove_btn" [btnDisabled]="isDisabled ? true : false" (callback)="removeGiftCard()"></bk-navigate>
						</ng-template>
					</div>
				</div>
				<!-- Error -->
				<div class="tjs-error bk-error" *ngIf="giftCard?.errorMsg" translate>
					<i class="tjsicon-attention"></i>{{giftCard?.errorMsg}}
				</div>
				<div class="tjs-success bk-success" *ngIf="giftCard?.successMsg" translate>
					<i class="tjsicon-attention"></i>{{giftCard?.successMsg}}
				</div>
			</div>
			<!-- Referral -->
			<div class="tab-pane " id="referrals" *ngIf="(referralPerm) && (referral?.display || ((['add', 'draft'].includes(this.bookingType)) || (prefilledData && prefilledData?.referral_amount)))">
				<ng-container *ngTemplateOutlet="labelTemp; context:{name:'referral'}"></ng-container>
				<span class="d-block mb-15 dir-ltr bk-form-discount-desc" [id]="section?.referral_balance_label_id" *ngIf="referralBalanceLabel" [innerHTML]="referralBalanceLabel | safeHtml"></span>
				<div class="row">
					<div class="col-12 col-md-6 mb-20 mb-md-0">
						<input class="form-control" type="text" [ngClass]="isMultiStepForm ? 'form-control-lg' :''" placeholder="{{section?.referral_placeholder}}" name="referral_amount" [value]="referralValue" [(ngModel)]="referralValue" [ngModelOptions]="{standalone: true}" (keypress)="utilServ.onlyNumbers($event)" (focusin)="referral['errorMsg'] = ''" (focusout)="referral['errorMsg']= ''" [attr.disabled]="isDisabled">
					</div>
					<!-- Buttons -->
					<div class="col-12 col-md-6">
						<ng-container *ngIf="!(referral?.flag); else removeRefBtn">
							<!-- Apply -->
							<bk-navigate *ngIf="referralValue; else disabledRefBtn" innerHTML="{{section?.referral_apply_btn?.content | translate}}" customClass="btn btn-primary border-transparent" essentialClass="w-100 h-100 bk-form-discount-btn" [secSett]="section?.referral_apply_btn" [btnDisabled]="isDisabled ? true : false" (callback)="applyReferral(referralValue)"></bk-navigate>
							<!-- Apply disabled btn -->
							<ng-template #disabledRefBtn>
								<bk-navigate innerHTML="{{section?.referral_apply_btn?.content | translate}}" customClass="btn btn-primary border-transparent" essentialClass="w-100 h-100 bk-form-discount-btn" [secSett]="section?.referral_apply_btn" [btnDisabled]="true"></bk-navigate>
							</ng-template>
						</ng-container>
						<ng-template #removeRefBtn>
							<!-- Remove  -->
							<bk-navigate innerHTML="{{section?.referral_remove_btn?.content | translate}}" customClass="btn btn-danger border-transparent" essentialClass="w-100 h-100 bk-form-discount-btn" [secSett]="section?.referral_remove_btn" [btnDisabled]="isDisabled ? true : false" (callback)="removeReferral()"></bk-navigate>
						</ng-template>
					</div>
				</div>
				<!-- Error -->
				<div class="tjs-error bk-error" *ngIf="referral?.errorMsg" translate>
					<i class="tjsicon-attention"></i>{{referral?.errorMsg}}
				</div>
				<div class="tjs-success bk-success" *ngIf="referral?.successMsg" translate>
					<i class="tjsicon-attention"></i>{{referral?.successMsg}}
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Label -->
<ng-template #labelTemp let-name="name">
	<div class="d-inline-flex align-items-center mb-10">
		<label class="form-label mb-0 bk-form-discount-label" *ngIf="section[name+'_label']" [id]="section[name+'_label_id']" [innerHTML]="section[name+'_label'] | safeHtml"></label>
		<bk-tooltip *ngIf="section[name+'_tooltip']" [id]="section[name+'_tooltip_id']" customClass="bk-tooltip" [content]="section[name+'_tooltip'] | translate"></bk-tooltip>
	</div>
</ng-template>
