
<ul class="navbar-nav tjs-header-navbar tjs-header-bottom__animation">
	<ng-container *ngIf="menuData && menuData.length > 0">
		<ng-container *ngFor="let menu of menuData;let i=index;trackBy: utilServ.trackByFnIndex">
			<li class="nav-item position-relative" [ngClass]="{'menu-item-has-child': menu?.children && (menu?.children).length > 0}" id="{{i}}_{{menu?.id}}" >
				<ng-container *ngTemplateOutlet="titleTemp; context:{menu:menu}"></ng-container>
				<!-- Sub menu -->
				<ng-container *ngIf="menu?.children && (menu?.children).length > 0">
					<div class="resp-submenu-drop d-lg-none" (click)="addRespClass(i, menu?.id, $event)"></div>
					<ng-container *ngTemplateOutlet="subMenu; context: {$implicit: menu}"></ng-container>
				</ng-container>
			</li>
		</ng-container>
	</ng-container>
</ul>
<!-- Sub menu -->
<ng-template #subMenu let-menu>
	<ul [id]="menu?.parent_id" class="tjs-submenu bk-submenu list-unstyled ps-15 ps-lg-0" *ngIf="menu?.children && (menu?.children).length > 0">
		<ng-container *ngFor="let child of menu?.children;let j=index;trackBy: utilServ.trackByFnIndex">
			<li  class="nav-item position-relative" [ngClass]="{'menu-item-has-child': child?.children && (child?.children).length > 0}" id="{{j}}_{{child?.id}}" >
				<ng-container *ngTemplateOutlet="titleTemp; context:{menu:child, customClass: 'nav-link d-block'}"></ng-container>
				<!-- Sub menu -->
				<ng-container *ngIf="child?.children && (child?.children).length > 0">
					<div class="resp-submenu-drop d-lg-none" (click)="addRespClass(j, child?.id, $event)"></div>
					<ng-container *ngTemplateOutlet="subMenu; context: {$implicit: child}"></ng-container>
				</ng-container>
			</li>
		</ng-container>
	</ul>
</ng-template>
<!-- title template -->
<ng-template #titleTemp let-menu="menu">
	<ng-container *ngIf="menu">
		<ng-container *ngIf="menu?.link_url == initServ.appDynamicRoutes['referrals']; else elseMenu">
			<bk-navigate class="tjs-bk-navigate" customClass="nav-link position-relative btn bk-header-link h-100" [secSett]="menu" innerHTML="{{((menu?.identifier && menuContent?.[menu?.identifier]) ? menuContent?.[menu?.identifier] :(menu?.title | translate))}}{{refBalance}}" type="menu"></bk-navigate>
		</ng-container>
		<ng-template #elseMenu>
			<bk-navigate class="tjs-bk-navigate" customClass="nav-link position-relative btn bk-header-link h-100" [secSett]="menu" innerHTML="{{((menu?.identifier && menuContent?.[menu?.identifier]) ? menuContent?.[menu?.identifier] : (menu?.title | translate))}}" type="menu"></bk-navigate>
		</ng-template>
	</ng-container>
</ng-template>
