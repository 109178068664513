<div [ngClass]="isMultiStepForm ? 'mb-30' : 'py-30 px-md-30 border-bottom bk-card-border'" [formGroup]="pricingParamForm" [id]="section?.id" *ngIf="settings && section && pricingParams && pricingParams.length > 0">
	<!-- Title and description -->
	<bk-section-heading *ngIf="!isMultiStepForm" [section]="section" [pageSett]="pageSett"></bk-section-heading>
	<!-- Pricing parameters -->
	<div class="row">
		<div class="col-12 col-md-6 my-10" *ngFor="let pricingParam of pricingParams;let i=index;trackBy: trackByParamValue">
			<!-- Label -->
			<label class="form-label text-capitalize bk-form-label">{{pricingParam?.cat_name | translate}}</label>
			<!-- Select list -->
			<div class="d-flex align-items-center" *ngIf="pricingParam?.value && (pricingParam?.value).length > 0">
				<select class="form-select bk-select" [ngClass]="{'form-select-lg':isMultiStepForm}" *ngIf="pricingParam?.value && (pricingParam?.value).length > 0" (change)="pricingParameterChange($event, pricingParam)" [attr.disabled]="isDisabled">
					<ng-container *ngFor="let param of pricingParam?.value;trackBy: utilServ.trackById">
						<option [value]="param?.id" [selected]="(param?.variable_category == pricingParam?.id && (+param?.id == +pricingParam?.selected_value)) ? true : null">
							<span class="bk-select-option">{{param?.param_name | translate}}
							<span *ngIf="['reschedule', 'draft'].includes(bookingType) && param.status == 8">{{initServ?.appStr?.text?.archived | translate}}</span></span>
						</option>
					</ng-container>
				</select>
				<!-- Tooltip -->
				<ng-container *ngFor="let param of pricingParam?.value;trackBy: utilServ.trackById">
					<bk-tooltip *ngIf="(param?.variable_category == pricingParam?.id && (+param?.id == +pricingParam?.selected_value)) && param?.show_tooltip_icon && param?.tooltip_text" customClass="bk-tooltip" [content]="param?.tooltip_text"></bk-tooltip>
				</ng-container>
			</div>
		</div>
	</div>
	<!-- Partial cleaning(Excludes) -->
	<ng-container *ngIf="((prefilledData && prefilledData?.partial_cleaning && (prefilledData?.partial_cleaning).length > 0) || (excludeStatus)) && (excludes && excludes.length > 0)">
		<!-- Is partial cleaning checkbox -->
		<div class="mt-20 form-check form-check-inline">
			<input id="partial_cleaning" class="form-check-input " type="checkbox" formControlName="is_partial_cleaning" [attr.disabled]="isDisabled">
			<label for="partial_cleaning" class="form-check-label bk-form-check-label" [id]="section?.excludes_id"  [innerHTML]="section?.excludes | safeHtml"></label>
		</div>
		<!-- Excludes block -->
		<div class="card border mt-15 bg-transparent bk-card bk-card-border" *ngIf="pricingParamForm.value['is_partial_cleaning']">
			<div class="card-body pb-5 bk-card-body">
				<!-- Label -->
				<label class="mb-20 form-label bk-form-label" *ngIf="section?.select_excludes" [id]="section?.select_excludes_id" [innerHTML]="section?.select_excludes | safeHtml"></label>
				<!-- Excludes -->
				<bk-select-element-block controlName="partial_cleaning" [data]="excludes" [isDisabled]="isDisabled" [elementForm]="pricingParamForm" [bookingType]="bookingType" (elementChange)="excludesChangeEvent()" [isMultiStepForm]="isMultiStepForm" ></bk-select-element-block>
			</div>
		</div>
	</ng-container>
</div>
