import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: 'bk-blank-temp-popup',
	templateUrl: './BlankTempPopup.component.html',
})
export class BlankTempPopupComponent implements OnInit {

	data: any;
	constructor(public dialogRef: MatDialogRef<BlankTempPopupComponent>,) { }

	// eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
	ngOnInit() {}

}
