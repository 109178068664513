<div class="tjs-popup__content dir-rtl tjs-popup__content--height bg-transparent w-100" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div class="row">
		<div class="col-sm-12 col-md-5 mb-30 mb-md-0 bk-elem-visible">
			<div *ngIf="section?.form" class="bk-elem-group bk_elem_parent position-relative">
				<div *ngIf="section?.form?.title">
					<div [id]="section?.form?.title_id" class="h3 mb-15" [innerHTML]="section?.form?.title | safeHtml"></div>
				</div>
				<div *ngIf="section?.form?.sub_title">
					<div [id]="section?.form?.sub_title_id" class="mb-25" [innerHTML]="section?.form?.sub_title | safeHtml"></div>
				</div>
				<!-- Form -->
				<form bkDebounceEvent [isSubmit]="true" [id]="section?.form?.id" [formGroup]="contactUsForm" (debounceSubmit)="submitForm()">
					<!-- First name -->
					<div [id]="section?.form?.name?.id" class="mb-20">
						<label [id]="section?.form?.name?.label_id" class="form-label bk-form-label" *ngIf="section?.form?.name?.label" [innerHTML]="section?.form?.name?.label | safeHtml" ></label>
						<input class="form-control bk-input" [ngClass]="{'is-invalid': f['name']?.touched && f['name']?.errors}" [placeholder]="section?.form?.name?.placeholder" formControlName="name" type="text" (focusout)="detailsFocusOut()">
						<!-- Error -->
						<div class="tjs-error" *ngIf="f['name']?.touched && (f['name']?.errors?.required || f['name']?.errors?.pattern)">
							<i class="tjsicon-attention"></i>
							<ng-container *ngIf="f['name']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
							<ng-container *ngIf="f['name']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.specialCharsExceptSpace | translate}} </ng-container>
						</div>
					</div>
					<!-- Email -->
					<div [id]="section?.form?.email?.id" class="mb-20">
						<label [id]="section?.form?.email?.label_id" class="form-label bk-form-label" *ngIf="section?.form?.email?.label" [innerHTML]="section?.form?.email?.label | safeHtml" ></label>
						<input class="form-control bk-input" [ngClass]="{'is-invalid': f['email']?.touched && f['email']?.errors}" [placeholder]="section?.form?.email?.placeholder" formControlName="email" type="text" (focusout)="detailsFocusOut()">
						<!-- Error -->
						<div class="tjs-error" *ngIf="f['email']?.touched && (f['email']?.errors?.required || f['email']?.errors?.pattern)">
							<i class="tjsicon-attention"></i>
							<ng-container *ngIf="f['email']?.errors?.required"> {{initServ?.appStr?.errorMsg?.empty | translate}} </ng-container>
							<ng-container *ngIf="f['email']?.errors?.pattern"> {{initServ?.appStr?.errorMsg?.validEmail | translate}} </ng-container>
						</div>
					</div>
					<!-- Cookie content -->
					<div class="tjs-privacy-text mt-10 mb-20" *ngIf="section?.form?.cookie_content">
						<span class="bk-cookie fs-14" [id]="section?.form?.cookie_content_id" [innerHTML]="section?.form?.cookie_content | safeHtml"></span>
					</div>
					<div>
						<!-- Submit -->
						<bk-navigate  [secId]="section?.form?.button_id" [designId]="section?.form?.button?.design_id" [innerHTML]="section?.form?.button?.content" [isSubmit]="true" customClass="btn btn-primary border-transparent" ></bk-navigate>
					</div>
				</form>
				<!-- Loader -->
				<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
			</div>
		</div>
		<!-- Variation v2 -->
		<div class="col-12 col-md-7 bk-media h-100" *ngIf="this.pageSett[this.secId].variation_id == 'bk_contact_us_V2' && section?.media && section?.media?.urls && (section?.media?.urls).length > 0">
			<div class="h-100">
				<img loading="auto" [id]="section?.media?.id" [src]="section?.media?.urls[0]" class="img-fluid object-fit-cover" alt="contact us" width="600" height="795">
			</div>
		</div>
		<!-- Variation v1 -->
		<div class="col-12 col-md-7" *ngIf="this.pageSett[this.secId].variation_id == 'bk_contact_us_V1' && section?.map">
			<div *ngIf="section?.map?.title">
				<h3 [id]="section?.map?.title_id" class="h3 mb-15" [innerHTML]="section?.map?.title | safeHtml"></h3>
			</div>
			<div class="row align-items-center mb-25">
				<div class="col-12 col-md-6">
					<div *ngIf="section?.map?.address">
						<address [id]="section?.map?.address_id" class="mb-0" [innerHTML]="section?.map?.address | safeHtml"></address>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div class="mb-10" *ngIf="section?.map?.email">
						<a href="email:{{section?.map?.email}}" [id]="section?.map?.email_id" [innerHTML]="section?.map?.email | safeHtml"></a>
					</div>
					<div *ngIf="section?.map?.number">
						<a href="tel:{{section?.map?.number}}" [id]="section?.map?.number_id" [innerHTML]="section?.map?.number | safeHtml"></a>
					</div>
				</div>
			</div>
			<div *ngIf="section?.map?.address && pageSett && pageSett[section?.map?.address_id]">
				<iframe width="100%" height="250" id="gmap_canvas" src="" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
			</div>
		</div>
	</div>
</div>
