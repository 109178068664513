<div [id]="secId" class="pt-35 pb-15" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && section">
	<div class="container">
		<div class="tjs-section__title mb-10" *ngIf="section?.title?.content || section?.desc?.content">
			<!-- Title -->
			<div class="tjs-section__heading mb-30" *ngIf="section?.title?.content">
				<h3 [id]="section?.title?.id" class="display-5 fw-bold bk-title" [innerHTML]="section?.title?.content | safeHtml"></h3>
			</div>
			<!-- Content -->
			<div *ngIf="section?.desc?.content">
				<p [id]="section?.desc?.id" class="bk-desc" [innerHTML]="section?.desc?.content | safeHtml"></p>
			</div>
		</div>
		<!-- List -->
		<div [id]="section?.list?.id" class="row bk-list justify-content-center" *ngIf="section?.list && section?.list?.items && (section?.list?.items).length>0">
			<ng-container *ngFor="let item of section?.list?.items let i=index;trackBy: secServ.trackByFnIndex">
				<div [id]="item?.id" class="col-12 col-lg-4 my-20" [class.mt-cus-sm-30]="i>2" *ngIf="(item?.media && (item?.media).length > 0) || item?.title || item?.desc || (item?.list_button && item?.list_button?.buttons && (item?.list_button?.buttons).length > 0)">
					<div class="card border bk-item">
						<!-- Media -->
						<div *ngIf="item?.media && (item?.media).length > 0">
							<img loading="auto" [id]="item?.media_id" class="img-fluid card-img-top bk-item-media" [src]="item?.media[0]" width="640" height="480" alt="Grid service" />
						</div>
						<div class="card-body border-bottom p-20 p-sm-30" *ngIf="item?.title || item?.desc">
							<!-- Title -->
							<div class="mb-15" *ngIf="item?.title">
								<h6 [id]="item?.title_id" class="fs-20 fw-bold bk-item-title" [innerHTML]="item?.title | safeHtml"></h6>
							</div>
							<!-- Description -->
							<div class="tjs-description" *ngIf="item?.desc">
								<p [id]="item?.desc_id" class="bk-item-desc mb-0" [innerHTML]="item?.desc | safeHtml"></p>
							</div>
						</div>
						<!-- Button -->
						<div [id]="item?.list_button_id" class="card-footer tjs-card-footer text-center py-5 bg-white bk-item-footer" *ngIf="item?.list_button && item?.list_button?.buttons && (item?.list_button?.buttons).length > 0">
							<ng-container *ngFor="let btn of item?.list_button?.buttons;trackBy: secServ.trackByFnIndex">
								<bk-navigate essentialClass="bk-item-btn" [designId]="section?.list?.btn_design_id" [secSett]="btn" [innerHTML]="btn?.content"></bk-navigate>
							</ng-container>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>