import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// Services
import { NgOnDestroy } from '../../../Services';

@Component({
	selector: 'bk-popup-header',
	template: `
	<div class="tjs-popup__header tjs-popup__header--responsive d-flex bk-headColor {{customClass}}" [ngClass]="isCenter ? 'justify-content-center' : 'justify-content-between'" *ngIf="heading; else noHeading">
		<h3 class="m-0 me-20 tjs-popup__title bk-popupTitle flex-grow-1" [id]="headingId" [innerHTML]="heading | safeHtml"></h3>
		<button *ngIf="isClose" type="button" aria-label="close" class="align-self-start mt-sm-10 mt-5 fs-13  btn p-0 bg-transparent bk-closePopup" (click)="closePopup(dialogRefCloseRes)" [id]="crossBtnId"><i class="tjsicon-close text-danger"></i></button>
	</div>
	<!-- py-10 px-15 py-sm-20 px-sm-30 -->
   <ng-template #noHeading>
		<div class="px-20 pb-20 pt-15 d-flex justify-content-end bk-headColor">
			<button type="button" class="btn-close fs-13 p-0 border-0 bg-transparent tjs-popup__closed bk-closePopup" (click)="closePopup(dialogRefCloseRes)" [id]="crossBtnId"><i class="tjsicon-close text-danger"></i></button>
		</div>
	</ng-template>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class PopupHeaderComponent implements OnInit {
	// Variables
	@Input() heading: string = '';
	@Input() isCenter: boolean = false;
	@Input() isClose: boolean = true;
	@Input() isCloseAll: boolean = true;
	@Input() dialogRefCloseRes: undefined | boolean;
	@Input() customClass:string = '';

	@Input() headingId: string = '';
	@Input() crossBtnId: string = '';

	constructor(public matDialog: MatDialog, public dialogRef: MatDialogRef<PopupHeaderComponent> ) { }

	ngOnInit() {
		this.heading = (this.heading) ? this.heading : '';
	}
	/**
	 * Close popup (all/single)
	 */
	public closePopup(dialogRefCloseRes:undefined | boolean): void {
		if(this.isCloseAll){
			this.matDialog.closeAll();
		} else {
			this.dialogRef.close(dialogRefCloseRes);
		}
	}
}
