import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
// Services
import { CacheService, NgOnDestroy, UtilServ } from '../../../../../Services';

@Component({
	selector: 'bk-how-it-works-popup',
	templateUrl: './HowItWorksPopup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class HowItWorksPopupComponent implements OnInit {
	videoUrl: any;
	//
	secId: any;
	slug: string = 'how_it_works';
	section: any = { title: null };

	constructor(public dialogRef: MatDialogRef<HowItWorksPopupComponent>, private cacheServ: CacheService, public utilServ: UtilServ) { }

	ngOnInit(): void {
		// build popup section
		this.buildSectionData();
		//
		if (this.videoUrl) {
			this.setVideo(this.videoUrl);
		}
	}
	/**
	 * Method creates an object with data needed to build a popup section, calls
	 * a method to build the section asynchronously, and sets variables based on the result.
	 */
	private buildSectionData(): void {
		// create object that will need to build the popup section.
		let popupData: any = { slug: this.slug, loaderId: '', section: this.section, dialogRef: this.dialogRef };
		// call the build section method and after completion of promise, it will set the `secId` & `section` variables
		this.cacheServ.buildSectionData(popupData).then(() => {
			this.secId = this.cacheServ.secId;
			this.section = this.cacheServ.section;
		});
	}
	/**
	 * Set video
	 * @param videoUrl
	 */
	public setVideo(videoUrl: any): void {
		let videoEl: HTMLElement | null = document.getElementById('howItWorkVideoPopup');
		if (videoEl) videoEl.setAttribute('src', `${videoUrl}?autoplay=1`);
	}
}