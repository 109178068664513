import { Directive, ElementRef, HostListener, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
	selector: '[bkTrimSpace]'
})
export class TrimSpaceDirective {
	constructor(private el: ElementRef, @Optional() @Self() private ngControl: NgControl) { }
	/**
	 * Sets the value of the associated control if `ngControl` and `ngControl.control` exist.
	 * @param val The value to set for the control.
	 */
	private setControl(val: any): void {
		// Check if `ngControl` and `ngControl.control` exist using optional chaining
		if(this.ngControl?.control){
			// If control exists, set its value to `val` and emit the value change event
			this.ngControl.control.setValue(val, {emitEvent: true});
		}
	}
	/**
	 * Host listener for the 'blur' event.
	 * Triggers when the element loses focus.
	 * @param $event The event object associated with the blur event.
	 */
	@HostListener('blur', ["$event"])
	onBlur() {
		// Get the current value of the element
		let currentValue: any = this.el.nativeElement.value;
		// Trim whitespace from the current value
		let val: any = currentValue.trim();
		// Set the value of the native element to the trimmed value
		this.el.nativeElement.value = val;
		// Update the associated control with the trimmed value
		this.setControl(val);
	}
}