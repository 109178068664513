<div class="position-relative">
	<div [ngClass]="variationId == 'bk_short_form_V3' ? 'form_version_3' : 'form_version_4 row'" [formGroup]="itemsForm" [id]="section?.id" *ngIf="section">
		<div [ngClass]="variationId == 'bk_short_form_V4' ? 'col-md-8 mx-auto' : ''">
			<!-- Title -->
			<div class="mb-30" *ngIf="section?.title">
				<h4 class="mb-0 bk-title" [ngClass]="variationId == 'bk_short_form_V3' ? 'fw-bold' : 'display-5 text-center fw-normal'" [id]="sectionIds?.title"  [innerHTML]="section?.title | safeHtml"></h4>
			</div>
			<div [ngClass]="{'combine-component row justify-content-center' : variationId == 'bk_short_form_V4'}">
				<div [ngClass]="{'col-md-6' : variationId == 'bk_short_form_V4', 'position-absolute':!(services && services.length > 1)}">
					<!-- Service category -->
					<bk-short-form-service-cat
						[sectionIds]="sectionIds"
						[bookingType]="bookingType"
						[variationId]="variationId"
						[section]="parentSection?.service"
						[serviceForm]="itemsForm"
						[selectedLocation]="selectedLocation"
						[selectedServiceType]="selectedServiceType"
						[selectedFrequency]="selectedFrequency"
						[settings]="settings"
						(serviceChange)="serviceChange.emit($event)"
						(hourlyServiceChange)="hourlyServiceChange.emit()"
						(getServices)="services=$event"
						[isShortForm]="true"
					>
					</bk-short-form-service-cat>
				</div>
				<!-- Frequency -->
				<div [ngClass]="{'col-md-6' : variationId == 'bk_short_form_V4'}">
					<bk-short-form-frequency
						[sectionIds]="sectionIds"
						[variationId]="variationId"
						[bookingType]="bookingType"
						[section]="parentSection?.frequency"
						[frequencyForm]="itemsForm"
						[selectedLocation]="selectedLocation"
						[selectedServiceType]="selectedServiceType"
						[selectedFrequency]="selectedFrequency"
						[settings]="settings"
						(frequencyChange)="frequencyChange.emit($event)" >
					</bk-short-form-frequency>
				</div>
			</div>
			<bk-short-frm-hourly
				[sectionIds]="sectionIds"
				[bookingType]="bookingType"
				[variationId]="variationId"
				[section]="parentSection?.service"
				[serviceForm]="itemsForm"
				[selectedLocation]="selectedLocation"
				[selectedServiceType]="selectedServiceType"
				[selectedFrequency]="selectedFrequency"
				[settings]="settings"
				(serviceChange)="serviceChange.emit($event)"
				(hourlyServiceChange)="hourlyServiceChange.emit()">
			</bk-short-frm-hourly>
			<ng-container *ngIf="(items && items.length > 0) || (packages && packages.length > 0)">
				<!-- Items, packages and addons -->
				<div class="mb-20" [ngClass]="{'col-md-12 mx-auto text-center sform-slider' : variationId == 'bk_short_form_V4'}" *ngIf="section && ((items && items.length > 0) || (packages && packages.length > 0))">
					<div [id]="section?.sel_item_id" [ngClass]="{'card shadow-sm p-20 rounded' : variationId == 'bk_short_form_V4'}">
						<!-- Items -->
						<div id="item-section-id" *ngIf="items && items.length > 0; else onlyPackages">
							<!-- Label -->
							<label [id]="sectionIds?.label" *ngIf="section?.sel_item" class="form-label bk-form-label" [innerHTML]="section?.sel_item | safeHtml"></label>
							<!-- Items -->
							<div id="vehicle_selection_area" class="tjs-extra-slider tjs-extra mx-0 position-relative">
								<div class="keen-slider" #itemSlideSF [attr.dir]="initServ.selectedLang?.rtl ? 'rtl' :'ltr'">
									<div class="keen-slider__slide" *ngFor="let item of items;let i=index;trackBy: utilServ.trackByFnIndex">
										<div class="tjs-extra__list text-center mx-5 tjs-extra-slide--sm">
											<div class="border rounded-2 position-relative mb-5 selected-extra--item" [ngClass]="{'selected-extra': item?.id == selectedItem?.id}">
												<label for="item-{{item?.id}}" class="d-flex tjs-pointer justify-content-center align-items-center h-100 w-100 mb-0">
													<!-- <span class="d-flex align-items-center justify-content-center vehicle-select-label-image position-absolute end-0 start-0 bottom-0 top-0" [ngClass]="{'select-label': item?.id == selectedItem?.id}"> -->
													<span class="d-flex align-items-center justify-content-center position-absolute end-0 start-0 bottom-0 top-0">
														<!-- Image -->
														<span class="tjs-extra__img m-auto">
															<img loading="auto" [src]="utilServ.getImgUrl(item?.icon_url)" alt="item" class="img-fluid bk-img" width="21" height="20" (error)="utilServ.handleImgError($event, initServ?.img?.KoalaIcon)" />
														</span>
														<!-- Radio button -->
														<input id="item-{{item?.id}}" class="form-check-input" type="radio" name="item" value="{{item?.id}}" (change)="itemSelect(item, i)">
													</span>
													<span class="card-img-overlay tjs-transition--base tjs-overlay__dark d-flex align-items-center rounded-2 p-0 bk_sel_extra"></span>
												</label>
											</div>
											<p class="mb-0 text-capitalize fs-14 bk-form-sub-label">
												<span [id]="sectionIds?.label">{{item?.display_name | translate}}</span>
												<!-- Tooltip -->
												<bk-tooltip *ngIf="item?.show_tooltip_icon && item?.tooltip_text" class="bk-tooltip" content="{{item?.tooltip_text | translate}}"></bk-tooltip>
											</p>
										</div>
									</div>
								</div>
								<ng-container *ngIf="((windowWidth >= 768) && (items.length > 3)) || ((windowWidth >= 451) && (windowWidth <= 767) && (items.length > 2)) || ((windowWidth <= 450) && (items.length > 1))">
									<button (click)="itemSlider.prev()" type="button" class="btn btn-link tjs-prev-arrow text-body start-0 tjs-pointer position-absolute  tjs-hover-text__primary  slider-arrow"><i class="tjsicon-left-arrow"></i></button>
									<button (click)="itemSlider.next()" type="button" class="btn btn-link tjs-next-arrow end-0 text-body position-absolute tjs-pointer tjs-hover-text__primary  slider-arrow"><i class="tjsicon-right-arrow"></i></button>
								</ng-container>
							</div>
							<!-- Packages based on selected item -->
							<div id="package-section-id" class="mt-10" *ngIf="selectedItem">
								<ng-container *ngTemplateOutlet="pckgSliderTemp; context:{type:'item'}"></ng-container>
							</div>
						</div>
						<!-- Only packages -->
						<ng-template #onlyPackages>
							<ng-container *ngTemplateOutlet="pckgSliderTemp; context:{type:'package'}"></ng-container>
						</ng-template>
					</div>
				</div>
				<!-- Selected item/package section -->
				<div id="selected-items-section-scroll" *ngIf="itemsForm.value['items'] && (itemsForm.value['items']).length > 0">
					<div class="mb-20" [ngClass]="{'col-md-12' : variationId == 'bk_short_form_V4'}" *ngIf="items && items.length > 0; else elsePackages">
						<div [ngClass]="{'card shadow-sm p-20 rounded' : variationId == 'bk_short_form_V4'}">
							<!-- Selected item/package label -->
							<ng-container *ngTemplateOutlet="selectedPckgLabel"></ng-container>
							<!-- Items -->
							<div class="tjs-bg-gray card items-list tjs-d-border mb-20" *ngFor="let item of itemsForm.value['items']; let index = index;trackBy: utilServ.trackByFnIndex">
								<div class="card-body p-0 selected-item-wrap">
									<div class="d-flex justify-content-between px-20 pt-20 pb-15 tjs-bg-gray rounded-top">
										<label class="form-label mb-0" [id]="sectionIds?.label">{{bkngFormServ.getElementName(item, 'items')  | translate}}</label>
										<button type="button" class="btn p-0 tjs-btn-close bg-white text-danger tjs-transition--base rounded-circle fs-12" data-placement="top" data-toggle="tooltip" title="Delete" (click)="confirmPopup(index, 'item')">
											<i class="tjsicon-close"></i>
										</button>
									</div>
								</div>
								<!-- Packages & package addons-->
								<ng-container *ngIf="item.packages && (item.packages).length > 0">
									<div *ngFor="let package of item.packages; let pckgIndex = index;trackBy: utilServ.trackByFnIndex">
										<div class="d-sm-flex align-items-center justify-content-between px-20 pb-20 tjs-body-bg" [ngClass]="{'tjs-d-border__bottom':pckgAddons && pckgAddons[item?.id] && pckgAddons[item?.id][package?.id] && (pckgAddons[item?.id][package?.id]).length > 0}">
											<p class="mb-0" [id]="sectionIds?.label">{{bkngFormServ.getElementName(package, 'package') | translate}}</p>
											<div class="tjs-pcounter d-flex rounded text-center overflow-hidden mt-20 mt-sm-0" *ngIf="package?.enable_quantity_based == 'yes'">
												<button class="tjs-pcounter__btn fs-24 border-0 p-0 text-white rounded-left tjs-pcounter__btn-dec bk_custom_extra_decrement" type="button" (click)="pckgCountChange(index, pckgIndex, package, 'decrement')">-</button>
												<input type="text" class="tjs-pcounter__number border-0 text-center" [value]="package?.quantity" readonly>
												<button class="tjs-pcounter__btn fs-24 border-0 p-0 text-white rounded-right tjs-pcounter__btn-inc bk_custom_extra_increment" type="button" (click)="pckgCountChange(index, pckgIndex, package, 'increment')">+</button>
											</div>
										</div>
										<!-- Package addons -->
										<ng-container *ngTemplateOutlet="pckgAddonTemp; context:{item:item, index:index, package:package, pckgIndex:pckgIndex }"></ng-container>
									</div>
								</ng-container>
							</div>
						</div>
					</div>
					<!-- Only packages(No item case) -->
					<ng-template #elsePackages>
						<ng-container *ngIf="itemsForm.value['items'] && (itemsForm.value['items']).length > 0">
							<ng-container *ngFor="let item of itemsForm.value['items']; let index = index;trackBy: utilServ.trackByFnIndex">
								<div class="mb-20" [ngClass]="{'col-md-12' : variationId == 'bk_short_form_V4'}" *ngIf="item?.packages && (item?.packages).length > 0">
									<div [ngClass]="{'card shadow-sm p-20 rounded' : variationId == 'bk_short_form_V4'}">
										<!-- Selected item/package label -->
										<ng-container *ngTemplateOutlet="selectedPckgLabel"></ng-container>
										<ng-container *ngIf="item.packages && (item.packages).length > 0">
											<div class="tjs-body-bg card items-list tjs-d-border mb-20" *ngFor="let package of item.packages; let pckgIndex = index;trackBy: utilServ.trackByFnIndex">
												<div class=" card-body p-0  selected-item-wrap">
													<div class="px-20 pt-20 pb-15 tjs-body-bg rounded-top" [ngClass]="{'tjs-d-border__bottom':pckgAddons && pckgAddons[item?.id] && pckgAddons[item?.id][package?.id] && (pckgAddons[item?.id][package?.id]).length > 0}">
														<div class="d-flex justify-content-between">
															<!-- Package name -->
															<label class="fw-bold mb-0" [id]="sectionIds?.label">{{bkngFormServ.getElementName(package, 'package') | translate}}</label>
															<!-- Delete and inc/dec count -->
															<div class="text-end">
																<button type="button" class="btn p-0 tjs-btn-close bg-white text-danger tjs-transition--base rounded-circle fs-12" data-placement="top" data-toggle="tooltip" title="Delete" (click)="confirmPopup(index, 'package', pckgIndex)" [attr.disabled]="isDisabled">
																	<i class="tjsicon-close"></i>
																</button>
																<div class="tjs-pcounter d-flex rounded text-center overflow-hidden mt-15" *ngIf="package?.enable_quantity_based == 'yes'">
																	<button class="tjs-pcounter__btn fs-24 tjs-border-md-0 p-0 text-white rounded-left tjs-pcounter__btn-dec bk_custom_extra_decrement" type="button" (click)="pckgCountChange(index, pckgIndex, package, 'decrement')" [attr.disabled]="isDisabled">-</button>
																	<input type="text" class="tjs-pcounter__number tjs-border-md-0 text-center" [value]="package?.quantity" readonly>
																	<button class="tjs-pcounter__btn fs-24 tjs-border-md-0 p-0 text-white rounded-right tjs-pcounter__btn-inc bk_custom_extra_increment" type="button" (click)="pckgCountChange(index, pckgIndex, package, 'increment')" [attr.disabled]="isDisabled">+</button>
																</div>
															</div>
														</div>
													</div>
													<!-- Package addons -->
													<ng-container *ngTemplateOutlet="pckgAddonTemp; context:{item:item, index:index, package:package, pckgIndex:pckgIndex }"></ng-container>
												</div>
											</div>
										</ng-container>
									</div>
								</div>
							</ng-container>
						</ng-container>
					</ng-template>
				</div>
			</ng-container>
		</div>
	</div>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>
<!-- Packages slider -->
<ng-template #pckgSliderTemp let-type="type">
	<div [id]="section?.choose_pkg_id" class="mb-20" [ngClass]="{'col-md-12 text-center sform-slider' : variationId == 'bk_short_form_V4', 'mx-auto': variationId == 'bk_short_form_V4' && type == 'item'}">
		<!-- Label -->
		<label [id]="sectionIds?.label" *ngIf="section?.choose_pkg" class="me-5 form-label bk-form-label" [innerHTML]="section?.choose_pkg | safeHtml"></label>
		<div class="tjs-extra-slider tjs-form-package-slider tjs-extra mx-0 position-relative">
			<ng-container *ngIf="packages && packages.length > 0; else noPackageForItem">
				<!-- Packages slider -->
				<div class="keen-slider bk-slider" #pckgSlideSF [attr.dir]="initServ?.selectedLang?.rtl ? 'rtl' :'ltr'">
					<div class="keen-slider__slide" *ngFor="let pckg of packages;trackBy: utilServ.trackByFnIndex">
						<div class="text-center mx-5 tjs-extra-slide--sm">
							<div class="border rounded position-relative mb-5">
								<div class="d-flex justify-content-center h-100">
									<label class="d-flex tjs-pointer justify-content-center align-items-center h-100 w-100 mb-0" for="{{pckg.id}}">
										<input id="{{pckg.id}}" class="checkbox" type="checkbox" name="{{pckg?.name}}">
										<span class="tjs-extra__img m-auto">
											<img loading="auto" [src]="utilServ.getImgUrl(pckg?.icon_url)" alt="package" class="img-fluid m-auto bk-img" width="21" height="20" (error)="utilServ.handleImgError($event, initServ?.img?.KoalaIcon)" />
										</span>
									</label>
									<!-- Add button -->
									<div class="card-img-overlay tjs-overlay__dark d-flex align-items-center justify-content-center rounded p-0">
										<bk-navigate innerHTML="<i class='tjsicon-plus'></i>" customClass="btn btn-primary btn-icon p-10 border-transparent" (callback)="packageSelect(pckg,type)"></bk-navigate>
									</div>
								</div>
							</div>
							<p class="fs-14 mb-0">
								<span [id]="sectionIds?.label">{{pckg?.display_name | translate}}</span>
								<!-- Tooltip -->
								<bk-tooltip *ngIf="pckg?.show_tooltip_icon && pckg?.tooltip_text" customClass="" class="bk-tooltip" content="{{pckg?.tooltip_text | translate}}"></bk-tooltip>
							</p>
							<!-- Read more -->
							<button type="button" class="btn btn-link fs-14 d-inline-block mt-5 text-capitalize" (click)="packageDescPopup(pckg?.description)">{{'Read more'| translate}}</button>
						</div>
					</div>
				</div>
				<ng-container *ngIf="((windowWidth >= 768) && (packages.length > 3)) || ((windowWidth >= 451) && (windowWidth <= 767) && (packages.length > 2)) || ((windowWidth <= 450) && (packages.length > 1))">
					<button (click)="pckgSlider.prev()" type="button" class="btn btn-link tjs-prev-arrow text-body start-0 tjs-pointer position-absolute  tjs-hover-text__primary  slider-arrow"><i class="tjsicon-left-arrow"></i></button>
					<button (click)="pckgSlider.next()" type="button" class="btn btn-link tjs-next-arrow end-0 text-body position-absolute tjs-pointer tjs-hover-text__primary  slider-arrow"><i class="tjsicon-right-arrow"></i></button>
				</ng-container>
			</ng-container>
			<ng-template #noPackageForItem>
				<h6 class="bk-form-label">{{initServ?.appStr?.text?.noPackageAvailable}}</h6>
			</ng-template>
			<!-- Loader -->
			<bk-app-loader [loaderId]="pckgLoaderId"></bk-app-loader>
		</div>
	</div>
</ng-template>
<!-- Added packages label -->
<ng-template #selectedPckgLabel>
	<label class="form-label" *ngIf="section?.added_pkg" [id]="sectionIds?.label" [innerHTML]="section?.added_pkg | safeHtml" ></label>
</ng-template>
<!-- Package addons -->
<ng-template #pckgAddonTemp let-item="item" let-index="index" let-package="package" let-pckgIndex="pckgIndex">
	<div class="py-20 px-30 bg-white rounded-bottom tjs-d-border__top" *ngIf="pckgAddons && pckgAddons[index] && pckgAddons[index][item?.id] && pckgAddons[index][item?.id][package?.id] && (pckgAddons[index][item?.id][package?.id]).length > 0">
		<label [id]="sectionIds?.label" *ngIf="section?.pkg_addons" class="mb-20 form-label bk-form-label" [innerHTML]="section?.pkg_addons | safeHtml"></label>
		<div class="tjs-extra mx-0 tjs-extra-slider position-relative">
			<div class="keen-slider addon-slider" [attr.dir]="initServ.selectedLang?.rtl ? 'rtl' :'ltr'">
				<div class="keen-slider__slide" *ngFor="let pckgAddon of (pckgAddons[index][item?.id][package?.id]);trackBy: utilServ.trackByFnIndex">
					<div class="text-center mx-5 tjs-extra-slide--sm">
						<div class="border rounded position-relative mb-5" [ngClass]="{'selected-extra': prefilledItems[index][item?.id][package?.id][pckgAddon?.id]}">
							<label for="addon-{{item?.id}}-{{index}}-{{package?.id}}-{{pckgAddon?.id}}" class="d-flex tjs-pointer justify-content-center align-items-center h-100 w-100 mb-0">
								<!-- Checkbox -->
								<input id="addon-{{item?.id}}-{{index}}-{{package?.id}}-{{pckgAddon?.id}}" class="form-check-input bk-checkbox" type="checkbox" (change)="selectPckgAddon($event,index, item?.id, pckgIndex, package?.id, pckgAddon)" [attr.disabled]="isDisabled" [attr.checked]="(prefilledItems[index][item?.id][package?.id][pckgAddon?.id]) ? 'checked' : null">
								<!-- Image -->
								<span class="tjs-extra__img m-auto">
									<img loading="auto" [src]="utilServ.getImgUrl(pckgAddon?.icon_url)" alt="package addon" class="img-fluid bk-img" width="21" height="20" (error)="utilServ.handleImgError($event, initServ?.img?.KoalaIcon)" />
								</span>
								<!-- Count down -->
								<span class="card-img-overlay tjs-transition--base tjs-overlay__dark d-flex align-items-center rounded-2 p-0 bk_sel_extra">
									<ng-container *ngIf="pckgAddon?.enable_quantity_based == 'yes' && (prefilledItems[index][item?.id][package?.id][pckgAddon?.id])">
										<!-- Decrement -->
										<button class="tjs-extra__counter tjs-transition--base p-0 tjs-extra__decrement bk_custom_extra_decrement d-flex justify-content-center align-items-center tjs-border-md-0 text-white h-100" type="button" (click)="pckgAddonCountChange(index,item?.id, pckgIndex, package?.id, pckgAddon, 'decrement')" [attr.disabled]="isDisabled">-</button>
										<!-- Value -->
										<input class="tjs-extra__number text-center tjs-border-md-0 tjs-transition--base text-white bg-transparent" type="text" [value]="prefilledItems[index][item?.id][package?.id][pckgAddon?.id]?.quantity" readonly [attr.disabled]="isDisabled">
										<!-- Increment -->
										<button class="tjs-extra__counter tjs-transition--base p-0 tjs-extra__increment bk_custom_extra_increment d-flex justify-content-center align-items-center tjs-border-md-0 text-white h-100" type="button" (click)="pckgAddonCountChange(index,item?.id, pckgIndex, package?.id, pckgAddon, 'increment')" [attr.disabled]="isDisabled">+</button>
									</ng-container>
								</span>
							</label>
						</div>
						<p class="fs-14 mb-0 bk-title">
							<span [id]="sectionIds?.label">{{utilServ.getFormParamName(pckgAddon) | translate}}</span>
							<!-- Tooltip -->
							<bk-tooltip *ngIf="pckgAddon?.show_tooltip_icon && pckgAddon?.tooltip_text" class="bk-tooltip" content="{{pckgAddon?.tooltip_text | translate}}"></bk-tooltip>
						</p>
					</div>
				</div>
			</div>
			<ng-container *ngIf="extraSlider && extraSlider[index] && (((windowWidth >= 768) && ((pckgAddons[index][item?.id][package?.id]).length > 3)) || ((windowWidth >= 451) && (windowWidth <= 767) && ((pckgAddons[index][item?.id][package?.id]).length > 2)) || ((windowWidth <= 450) && ((pckgAddons[index][item?.id][package?.id]).length > 1)))">
				<button (click)="extraSlider[index].prev()" type="button" class="btn btn-link tjs-prev-arrow text-body start-0 tjs-pointer position-absolute  tjs-hover-text__primary  slider-arrow"><i class="tjsicon-left-arrow"></i></button>
				<button (click)="extraSlider[index].next()" type="button" class="btn btn-link tjs-next-arrow end-0 text-body position-absolute tjs-pointer tjs-hover-text__primary  slider-arrow"><i class="tjsicon-right-arrow"></i></button>
			</ng-container>
		</div>
	</div>
</ng-template>