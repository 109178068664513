import { Component, OnInit, Input, ViewEncapsulation, Self } from '@angular/core';
import { takeUntil } from 'rxjs';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, ApiServ, UtilServ } from '../../Services';

@Component({
	selector: 'bk-discount-bar',
	templateUrl: './DiscountBar.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class DiscountBarComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	pageSett: any;
	secSett: any;
	// Section fields
	section: any = {
		clock_bar_title: null,
		clock_bar: null,
		clock_redirect_url: null,
		cookie_redirect_url: null,
		text_bar_title: null,
		terms_of_deal: null,
		button: null
	}
	loaderId: string = 'discount-bar-loader';
	planPerm: boolean = false;
	barType: string = 'custom';
	couponData: any;
	hideSection: boolean = false;
	desktopVClass: any = {
		bar_layout_v1: 'tjs-couponbar--clock-v1',
		bar_layout_v2: 'tjs-couponbar--clock-v2',
		bar_layout_v3: 'tjs-couponbar--clock-v1 tjs-couponbar--clock-v3',
		bar_layout_v4: 'tjs-couponbar--clock-v1 tjs-couponbar--clock-v3',
		bar_layout_v5: 'tjs-couponbar--text-v1',
		bar_layout_v6: 'tjs-couponbar--text-v2',
		bar_layout_v7: 'tjs-couponbar--text-v3 d-flex flex-column'
	}
	mobileVClass: any = {
		mobile_layout_v1: 'tjs-couponbar--mobile-v1',
		mobile_layout_v2: 'tjs-couponbar--mobile-v2',
		mobile_layout_v3: 'tjs-couponbar--mobile-v3',
		mobile_layout_v4: 'tjs-couponbar--mobile-v4',
		mobile_layout_v5: 'tjs-couponbar--mobile-v5',
		mobile_layout_v6: 'tjs-couponbar--mobile-text-v1"',
		mobile_layout_v7: 'tjs-couponbar--mobile-text-v2',
		mobile_layout_v8: 'tjs-couponbar--mobile-text-v3'
	}
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, @Self() private destroy: NgOnDestroy, private apiServ: ApiServ, public utilServ: UtilServ) {
		this.planPerm = this.initServ.appPlansPermission('discount-bar');
	}

	ngOnInit(): void {
		if(this.planPerm){
			if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
				this.sectionData = this.rcServ.popupData[this.popupId];
			} else {
				this.sectionData = this.rcServ.pageData;
			}
			// Build section data
			if(this.secId && this.sectionData){
				this.buildSection(this.sectionData);
			}
		}

	}
	/**
	 * Build the section
	 * @param secData
	 */
	private buildSection(secData: any): void {
		this.pageSett = secData.section_settings;
		this.secSett = this.pageSett[this.secId];
		this.secServ.setServData(this.pageSett, secData.content);
		this.buildSectionFields(secData);

		if(this.secSett && this.secSett.bar_type && this.secSett.bar_type == 'coupon' && this.secSett.coupon_code){
			this.barType = 'coupon';
			// Set query parameters
			let queryParams: any = {
				code: this.secSett.coupon_code,
				user_id: this.utilServ.userId() ? this.utilServ.userId() : 0,
				language: this.initServ.savedLng
			}
			// API call
			this.apiServ.callApiWithQueryParams('GET', 'Coupon', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'coupon'));
		}

	}
	/**
	 * Build the blog sections fields
	 */
	private buildSectionFields(secData:any): void{
		let secElem : any = null;
		if(secData.sections){
			secElem = secData.sections[this.secId];
		}
		if(secElem && this.secSett && this.secSett[this.rcServ.statusType]){
			for(let key in this.section){
				if(secElem[key]){
					switch (key) {
						case "terms_of_deal":
							this.section[key] =  this.secServ.buildGroup(secElem[key]);
							break;
						case 'button':
							this.section[key] = this.secServ.buildMultiButtons(secElem[key]);
							break;
						case 'clock_bar_title':
						case 'text_bar_title':
							this.section[key] = this.secServ.buildText(secElem[key]);
							break;
						case 'cookie_redirect_url':
						case 'clock_redirect_url':
							this.section[key] = this.secServ.buildLink(secElem[key]);
							break;
						default:
							this.section[key] = this.secServ.checkElementStatus(secElem[key]) && secElem[key];
							break;
					}
				}
			}
		}
	}
	/**
	 * On result callback method
	 * @param res api
	 * @param type section/send
	 * API response handler
	 */
	private onResultCallback(res:any, type: string='section'): void {
		if(type == 'coupon'){
			if(this.apiServ.checkAPIRes(res)){
				this.couponData = res.data;
				// if(this.couponData && this.couponData.coupon_bar_style && this.couponData.coupon_bar_style.bar_sequence && this.secData && this.secData.section_settings && this.secData.section_settings.button && this.secData.section_settings.button.hasOwnProperty('coupon_bar_index')){
				// 	let i = this.secData.section_settings.button.coupon_bar_index;
				// 	(this.couponData.coupon_bar_style.bar_sequence).splice(i, 0, 'button');
				// }
			}
		} else {
			if(this.apiServ.checkAPIRes(res) && res.data){
				let secData: any = res.data;
				if(secData){
					this.rcServ.setPageData = secData;
					this.buildSection(secData);
				}
			}
		}
	}
}