import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KeyValuePipe } from '@angular/common';
// Service
import { InitServ, SchedulingServ} from '../../../../../Services';

@Component({
  selector: 'bk-multi-slot',
  templateUrl: './MultiSlotLayout.component.html',
})
export class MultiSlotLayoutComponent implements OnInit {

	@Input() BKFrm! : FormGroup;
	@Input() section : any;
	@Input() selectedDate : any;
	@Input() perDateAvailSpots : any;
	@Input() availableProviders: any;
	@Input() dateDiscountsArray : any;
	@Input() dayDiscountsArray : any;
	@Input() dayDiscountBookings : any;
	@Input() prefilledData : any;
	@Input() calSection: any;
	@Output() onSlotChange : EventEmitter<any> = new EventEmitter();

	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	allSpots: any = this.initServ.appBookingSpots; // get all spots
	bookingSpots: any;

	constructor(public keyvalue: KeyValuePipe,  public initServ: InitServ, public scheduleServ: SchedulingServ){
	}

	ngOnInit(): void {
		this.admnStngs = this.initServ.appAdmnStngs;
		let location_id = this.BKFrm.controls['base_location_id'].value
		if(this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.providers?.provider_availability_based_on && this.admnStngs.merchant_settings?.providers?.provider_availability_based_on == 'all_location'){
			location_id = 0
		}
		this.bookingSpots = this.allSpots[location_id]
	}

	/**
	 * Function to call on provider slot change.
	 * @param slot
	 * @param slotDate
	 */
	clickMultiSlotChange(slot: string, slotDate: any): void {
		let obj = {
			slot : slot,
			slotDate : slotDate,
			// slotLayoutType : this.slotLayoutType
		}
		this.onSlotChange.emit(obj);
	}
}