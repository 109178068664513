import { Component, Input, OnInit, ViewEncapsulation, ViewChild, ElementRef, Self, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs';
// Services
import { NgOnDestroy, UtilServ, ApiServ } from '../../../Services';

@Component({
	selector: 'bk-search-provider',
	templateUrl: './SearchProvider.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchProviderComponent implements OnInit {
	// Variables
	@Input() availProvFilters: any;
	@ViewChild('provContainer', {static: true}) provContainer!: ElementRef;
	@Output() selectedProv: EventEmitter<any> = new EventEmitter();

	keyword: any;
	dropdownVisible: boolean = false;
	providers: any;
	itemIndex: any = null;
	tabToSelect: boolean = true;
	isSelect: boolean = false;

	constructor(public utilServ: UtilServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, private cDRef: ChangeDetectorRef) { }

	ngOnInit(): void {
		
	}

	/**
	 * Get providers
	 * @param evt: input event
	 */
	public getProviders(evt: any): void {
		this.isSelect = false;
		if(evt.target.value.length > 2){
			if(evt.keyCode != 40 && evt.keyCode != 38 && evt.keyCode != 13 && evt.keyCode != 9){
				this.searchProviders(evt.target.value);
				this.dropdownVisible = true;
			}
			// Select value on key up, down, enter and tab
			if(this.providers){
				let totalNumItem = this.providers.length;
				switch (evt.keyCode) {
					case 38: // UP, select the previous li
						this.itemIndex = (totalNumItem + this.itemIndex - 1) % totalNumItem;
						this.scrollToView(this.itemIndex);
					break;
					case 40: // DOWN, select the next li or the first one
						this.dropdownVisible = true;
						let sum = this.itemIndex;
						if (this.itemIndex === null) {
								sum = 0;
						} else {
								sum = sum + 1;
						}
						this.itemIndex = (totalNumItem + sum) % totalNumItem;
						this.scrollToView(this.itemIndex);
					break;
					case 13: // ENTER, choose it!!
						this.selectOne(this.providers[this.itemIndex]);
						evt.preventDefault();
					break;
					case 9: // TAB, choose if tab-to-select is enabled
						if (this.tabToSelect) {
								this.selectOne(this.providers[this.itemIndex]);
						}
					break;
				}
			}
		} else {
			this.providers = null;
			this.dropdownVisible = false;
		}
	}
	/**
	 * Get the selected provider data
	 * @param data: selected data
	 */
	public selectOne(data: any): void{
		if(data) {
			let firstName: any = (this.utilServ.userNameSett('firstname')) ? data.first_name : '';
			let lastName: any = '';
			if(data.last_name){
				if(this.utilServ.userNameSett('lastname')){
					lastName = data.last_name;
				} else{
					lastName = (this.utilServ.userNameSett('last_initial')) ? this.utilServ.nameLastInitial(data.last_name) : '';
				}
			}
			this.keyword = firstName+' '+lastName;
			this.isSelect = true;
			this.selectedProv.emit(data);
			this.dropdownVisible = false;
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Select the value on scroll
	 * @param index: index
	 */
	private scrollToView(index: any): void {
		const container = this.provContainer.nativeElement;
		const ul = container.querySelector('ul');
		const li = ul.querySelector('li');  //just sample the first li to get height
		const liHeight = li.offsetHeight;
		const scrollTop = ul.scrollTop;
		const viewport = scrollTop + ul.offsetHeight;
		const scrollOffset = liHeight * index;
		if(scrollOffset < scrollTop || (scrollOffset + liHeight) > viewport) {
			ul.scrollTop = scrollOffset;
		}
	}
	/**
	 * Hide the list on focus out
	 * @param evt: input event
	 */
	public hideList(evt: any): void{
		this.dropdownVisible = false;
		if(!this.isSelect){
			this.selectedProv.emit(evt.target.value);
		}
	}
	/**
	 * Search providers
	 * @param keyword: Input keywords
	 */
	private searchProviders(keyword: string): void {
		let queryParams = {s: keyword};
		this.apiServ.callApiWithQueryParams('POST', 'SearchQualifiedProviders', queryParams, this.availProvFilters).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
	}
	/**
	 * On result callback method
	 * @param res API res
	 * API response handler
	 */
	private onResultCallback(res:any): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			this.providers = res.data;
		} else {
			this.providers = null;
		}
		this.cDRef.detectChanges();
	}
}