import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { DateServ} from '../../../../Services';

@Component({
	selector: 'bk-schedule-calendar-popup',
	templateUrl: './ScheduleCalendarPopup.component.html',
	encapsulation: ViewEncapsulation.None
})
export class ScheduleCalendarPopupComponent implements OnInit {

	data : any;
	BKFrm : any;
	settings : any;
	selectedDate : any = null;
	isDateSelected : boolean = false;
	dayDiscountBookings : any;
	dayDiscounts : any;
	dateDiscountsArray : any;
	dayDiscountsArray : any;
	prefilledData : any;
	isSpotAvailable: any;
	test : any;
	perDateAvailSpots : any;
	availableProviders : any;
	selectedData : any;
	selectedDateEpoc : any;
	calSection: any;
	pageSett: any;
	constructor(public dialogRef: MatDialogRef<ScheduleCalendarPopupComponent>,  public dateServ : DateServ) { }

	ngOnInit(): void {
		// console.log('isSpotAvailable',this.isSpotAvailable);
	}
	/**
	 * Function to call on date selection from calendar popup.
	 * @param e
	 */
	selectMultiStepDate(e:any): void{
		// console.log('ee',e)
		this.selectedData = e;
		this.perDateAvailSpots = e.spots;
		this.availableProviders = e.providers;
		this.selectedDate = e.date.year + '-' + e.date.month + '-' + e.date.day;
		this.selectedDateEpoc = dayjs(this.selectedDate).unix()
		this.isDateSelected = true;
		if(e.autoSpot){
			let obj = { slot: this.perDateAvailSpots[this.selectedDate][0], slotDate: this.selectedData }
			this.onSlotChangeFunc(obj)
		}
		// this.dialogRef.close(e);
	}
	/**
	 * Function to call on slot change from calendar popup.
	 * @param obj
	 */
	onSlotChangeFunc(obj: any): void {
		let output : any = {
			data : this.selectedData,
			slot : obj.slot
		}
		this.dialogRef.close(output);
	}
	/**
	 * Function to go to back to calendar.
	 */
	backToCal():void{
		this.selectedDate = null;
		this.isDateSelected = false;
	}
}
