import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
// Service
import { NgOnDestroy, InitServ, UtilServ, DateServ } from '../../../Services';

@Component({
	selector: 'bk-selected-provider',
	templateUrl: './SelectedProvider.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class SelectedProviderComponent implements OnInit {

	@Input() section: any;
	@Input() settings : any;
	@Input() provider : any;
	@Input() existingBookingDate : any;
	@Input() existingArrivalTime : any;
	@Input() bookingScheduledDay : any;
	@Input() prefilledData : any;
	@Input() isMember : any = true;
	@Input() showSelectedProvider : boolean = true;
	@Input() ServiceProviderControl! : FormGroup;
	@Input() isMultiStepForm: boolean = false;

	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings

	customerDetails : any;
	ratingPerm : any;

	constructor(public initServ: InitServ, public utilServ: UtilServ, public dateServ : DateServ){}

	/* Init function to load the required data for scheduling*/
	ngOnInit(): void {
		/* Get customer details */
		this.customerDetails = this.initServ.userInfo;
		this.ratingPerm = this.utilServ.appPermission('rating');
	}
}
