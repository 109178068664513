<div class="tjs-main">
	<div class="tjs-main--container">
		<!-- Header -->
		<ng-container *ngIf="headerFooterStatus && initServ.reRender && ((!(utilServ.multiStepForm)) || (utilServ.multiStepForm && utilServ.multiStepHeader) || !isBooknow(currentPageSlug))">
			<bk-header *ngIf="!utilServ.inIframe(utilServ.embedStatus) && !hideHeaderFooterEmbed"></bk-header>
		</ng-container>
		<!-- Content -->
		<div class="tjs-main__content">
			<router-outlet></router-outlet>
		</div>
		<!-- Footer -->
		<ng-container *ngIf="headerFooterStatus && initServ.reRender && ((!(utilServ.multiStepForm)) || (utilServ.multiStepForm && utilServ.multiStepFooter) || !isBooknow(currentPageSlug))">
			<bk-footer *ngIf="!utilServ.inIframe(utilServ.embedStatus) && !hideHeaderFooterEmbed"></bk-footer>
		</ng-container>
	</div>
</div>
<bk-app-loader></bk-app-loader>
<!-- Tooltip -->
<div id="tjs-tooltip-content" class="tjs-tooltip-content tjs-tooltip d-none"></div>
