import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { InitServ, NgOnDestroy, SectionServ } from '../../../../Services';
import { BkngFormPopupServ } from '../../BkngFormPopup.service';

@Component({
	selector: 'bk-how-it-works',
	templateUrl: './HowItWorks.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class HowItWorksComponent implements OnInit {
	// Variables
	@Input() section: any;
	@Input() settings: any;
	@Input() pageSett: any;
	sectionStatus: boolean = false;
	hiwUrl: any = '';
	constructor(public initServ: InitServ, private secServ: SectionServ, public bkngFormPopupServ: BkngFormPopupServ) { }

	ngOnInit(): void {
		if(this.section){
			this.sectionStatus = this.secServ.checkEleStatus(this.pageSett, this.section.id);
			if(this.pageSett && this.pageSett[this.section.id] && this.pageSett[this.section.id].urls && (this.pageSett[this.section.id].urls).length > 0){
				this.hiwUrl = this.pageSett[this.section.id].urls[0];
			}
		}
		// let tjsModal : any = document.getElementById('howItWork')
		// tjsModal.addEventListener('shown.bs.modal', function () {
		// 	document.body.append(tjsModal)
		// })
	}

	// ngAfterViewInit(): void {
	// 	// Video src empty
	// 	var myModalEl: any = document.getElementById('howItWork');
	// 	if(myModalEl){
	// 		myModalEl.addEventListener('hidden.bs.modal', function () {
	// 			let videoEl = document.getElementById('howItWorkVideo');
	// 			if(videoEl){
	// 				videoEl.setAttribute('src',"");
	// 			}
	// 		},{passive: true})
	// 	}
	// }

	// /**
	//  * Set video
	//  * @param videoUrl
	//  */
	// public setVideo(videoUrl: any): void {
	// 	let videoEl = document.getElementById('howItWorkVideo');
	// 	if(videoEl){
	// 		videoEl.setAttribute('src',videoUrl+"?autoplay=1");
	// 	}
	// 	this.bkngFormServ.scrollParentToTop()
	// 	this.utilServ.showPopup('howItWork')
	// }
}