<div class="pt-30 pb-20 px-md-30 border-bottom bk-card-border" [id]="section?.id" *ngIf="settings && section" [formGroup]="ServiceProviderControl">
	<ng-container *ngIf="admnStngs?.merchant_settings?.providers?.scheduling_type != 'manually'; else manualBlock">
		<!-- Title and description -->
		<div class="mb-25">
			<h4 class="mb-0 bk-form-title" [id]="section?.title_id" translate>Assigned to</h4>
			<div class="mt-10" *ngIf="descStatus && section?.desc">
				<small class="bk-form-desc" [id]="section?.desc_id" [innerHTML]="section?.desc | safeHtml"></small>
			</div>
		</div>
		<div *ngIf="isProviderEditable && isCustomerAllowedRes; else providerElseBlock">
			<ng-container *ngIf="prefilledData && ((prefilledData.status == 6 || prefilledData.status == 7 || prefilledData.status == 8) || (prefilledData.status == 3 && prefilledData.provider_type == 'unassigned'))">
				<div class="card tjs-d-border bg-transparent">
					<div class="card-body">
						<div class="row align-items-center">
							<div class="col-12 col-sm-12 col-md-6 mb-20 mb-md-0">
								<h6 class="mb-5 text-capitalize fw-bold bk-form-label-clr">
									{{admnStngs?.merchant_settings?.bookings?.show_with_pending_bookings | translate}}
								</h6>
								<span class="bk-desc-clr fs-14" *ngIf="admnStngs?.merchant_settings?.bookings?.show_tooltip_for_pending_bookings">
									{{admnStngs?.merchant_settings?.bookings?.pending_booking_tooltip_content | translate}}
								</span>
							</div>
							<ng-template *ngTemplateOutlet="onlyDateLayout"></ng-template>
						</div>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="sameProvider && (sameProvider.length) > 0 && prefilledData && (prefilledData.status !=6) && (prefilledData.status !=7) && (prefilledData.status !=8)">
				<ng-container *ngFor="let provider of sameProvider">
					<bk-selected-provider
						[isMember] = "false"
						[provider]="provider"
						[ServiceProviderControl]="ServiceProviderControl"
						[section]="section"
						[existingBookingDate]="existingBookingDate"
						[existingArrivalTime]="existingArrivalTime"
						[bookingScheduledDay]="bookingScheduledDay"
						[prefilledData]="prefilledData"
						[showSelectedProvider]="showSelectedProvider"
						>
					</bk-selected-provider>
				</ng-container>
			</ng-container>
			<div class="mt-20">
				<div *ngIf="isSameProviderApplicable && showRescheduleBtn && prefilledData.status != 9">
					<button class="btn btn-primary" type="button" (click)="ShowRescheduleSection(false)" translate>Reschedule</button>
				</div>
				<div class="mt-20" *ngIf="ServiceProviderControl.controls['occurrence'].value == 'recurring' && isRecButtonVisibility">
					<ng-template *ngTemplateOutlet="forApplyToAll"></ng-template>
				</div>
			</div>
		</div>
		<!-- app box title closed -->
		<div id="provider-section-id" *ngIf="isReschedule">
			<div class="row mt-20">
				<div class="col-md-6 col-lg-4 align-self-center pe-0 mb-10" *ngIf="customerScheduleOptions.sameProvider">
					<div class="form-check form-check-inline me-0">
						<input id="same_provider" class="form-check-input" type="checkbox" [(ngModel)]="selectedScheduleOptions.isSameProvider" [ngModelOptions]="{standalone: true}" (change)="onProviderTypeChangePart($event, 'same_provider')">
						<label for="same_provider" class="form-check-label bk-form-check-label" [id]="section?.same_prov_id" [innerHTML]="section?.same_prov | safeHtml"></label>
					</div>
				</div>
				<div class="col-md-6 col-lg-4 align-self-center pe-0 mb-10" *ngIf="customerScheduleOptions.specificProvider">
					<div class="form-check form-check-inline me-0">
						<input id="specific_provider" class="form-check-input" type="checkbox" [(ngModel)]="selectedScheduleOptions.isSpecificProvider" [ngModelOptions]="{standalone: true}" (change)="onProviderTypeChangePart($event, 'specific_provider')">
						<label for="specific_provider" class="form-check-label bk-form-check-label" [id]="section?.specific_prov_id" [innerHTML]="section?.specific_prov | safeHtml"></label>
					</div>
				</div>
				<div class="col-md-6 col-lg-4 align-self-center pe-0 mb-10" *ngIf="customerScheduleOptions.previousProvider">
					<div class="form-check form-check-inline me-0">
						<input id="previous_provider" class="form-check-input" type="checkbox" [(ngModel)]="selectedScheduleOptions.isPreviousProvider" [ngModelOptions]="{standalone: true}" (change)="onProviderTypeChangePart($event, 'previous_provider')">
						<label for="previous_provider" class="form-check-label bk-form-check-label" [id]="section?.previous_prov_id" [innerHTML]="section?.previous_prov | safeHtml"></label>
					</div>
				</div>
				<div class="col-md-6 col-lg-4 align-self-center pe-0 mb-10" *ngIf="customerScheduleOptions.waitingList">
					<div class="form-check form-check-inline me-0">
						<input id="unassigned" class="form-check-input" type="checkbox" [(ngModel)]="selectedScheduleOptions.isUnassignedProvider" [ngModelOptions]="{standalone: true}" (change)="onProviderTypeChangePart($event, 'unassigned')">
						<label for="unassigned" class="form-check-label bk-form-check-label">
							<span [id]="section?.waiting_list_id" [innerHTML]="section?.waiting_list | safeHtml"></span>
						</label>
						<bk-tooltip *ngIf="section?.waiting_list_tooltip" customClass="bk-tooltip" [content]="section?.waiting_list_tooltip | translate"></bk-tooltip>
					</div>
				</div>
			</div>
			<!-- section to select specific provider -->
			<div class="mt-15 search-provider-section" *ngIf="customerScheduleOptions.specificProvider && ServiceProviderControl.value['provider_type'] == 'specific_provider'">
				<bk-search-provider [availProvFilters]="availProvFilters" (selectedProv)="getSelectedProvider($event)" (click)="prepareAvailProviderFilters()"></bk-search-provider>
			</div>
			<div class="d-flex" [ngClass]="{'flex-column' : (ServiceProviderControl.value['provider_type'] != 'specific_provider' && ServiceProviderControl.value['provider_type'] != 'same_provider'), 'flex-column-reverse' : (ServiceProviderControl.value['provider_type'] == 'specific_provider' || ServiceProviderControl.value['provider_type'] == 'same_provider')}">
				<div class="row">
					<div class="col-md-6 my-10" *ngIf="ServiceProviderControl.value['provider_type'] != 'specific_provider' || (ServiceProviderControl.value['provider_type'] == 'specific_provider' &&(ServiceProviderControl.controls['provider_details'].value).length > 0 )">
						<ng-container *ngTemplateOutlet="selectDateLayout"></ng-container>
					</div>
					<div class="col-md-6 my-10" *ngIf="ServiceProviderControl.controls['arrival_date'].value">
						<ng-template *ngTemplateOutlet="selectSlotLayout"></ng-template>
					</div>
				</div>
				<div class="position-relative">
					<div *ngIf="(ServiceProviderControl.controls['provider_details'].value).length > 0 && showSelectedProvider && (ServiceProviderControl?.value?.arrival_date || ServiceProviderControl.value['provider_type'] == 'specific_provider' || ServiceProviderControl.value['provider_type'] == 'same_provider')">
						<ng-container *ngFor="let provider of ServiceProviderControl.controls['provider_details'].value">
							<bk-selected-provider [provider]="provider" [ServiceProviderControl]="ServiceProviderControl" [section]="section"></bk-selected-provider>
						</ng-container>
					</div>
					<bk-app-loader [loaderId]="provLoaderId"></bk-app-loader>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-template #manualBlock>
		<div class="mb-30" *ngIf="ServiceProviderControl.controls['occurrence'].value == 'recurring' && isRecButtonVisibility">
			<ng-template *ngTemplateOutlet="forApplyToAll"></ng-template>
		</div>
		<div *ngIf="isProviderEditable && isCustomerAllowedRes; else providerElseBlock">
			<div class="row">
				<div class="col-12 col-md-6 col-lg-4 mb-20 mb-md-0">
					<ng-container *ngTemplateOutlet="selectDateLayout"></ng-container>
				</div>
				<div class="col-12 col-md-6 col-lg-4" *ngIf="ServiceProviderControl.controls['arrival_date'].value">
					<ng-template *ngTemplateOutlet="selectSlotLayout"></ng-template>
				</div>
				<div class="col-md-6 col-lg-4 align-self-center pe-0 mt-20" *ngIf="customerScheduleOptions.waitingList && ServiceProviderControl.controls['arrival_date'].value">
					<div class="form-check form-check-inline me-0">
						<input id="unassigned" class="form-check-input" type="checkbox" [(ngModel)]="isWaitingListVal" [ngModelOptions]="{standalone: true}" (change)="onWaitListChng($event)">
						<label for="unassigned" class="form-check-label bk-form-check-label">
							<span [innerHTML]="section?.waiting_list | safeHtml"></span>
						</label>
						<bk-tooltip *ngIf="section?.unassigned_tooltip" customClass="bk-tooltip" [content]="section?.unassigned_tooltip | translate"></bk-tooltip>
					</div>
				</div>
			</div>
			<div class="mt-20" *ngIf="(ServiceProviderControl.controls['provider_details'].value).length > 0 && showSelectedProvider">
				<ng-container *ngFor="let provider of ServiceProviderControl.controls['provider_details'].value">
					<bk-selected-provider [provider]="provider" [ServiceProviderControl]="ServiceProviderControl" [section]="section"></bk-selected-provider>
				</ng-container>
			</div>
		</div>
	</ng-template>
	<ng-template #providerElseBlock>
		<div *ngIf="prefilledData && (prefilledData.status ==6 || prefilledData.status ==7 || prefilledData.status ==8) ">
			<ng-container *ngIf="(showSelectedProvider && sameProvider && (sameProvider.length) > 0); else ElseOnlyDate">
				<div *ngFor="let provider of sameProvider">
					<div class="card tjs-d-border bg-transparent bk-card-border card-body">
						<div class="row align-items-center">
							<div class="col-12 col-sm-12 col-md-6 mb-20 mb-md-0">
								<h6 class="mb-5 text-capitalize fw-bold bk-form-label-clr">
									{{admnStngs?.merchant_settings?.bookings?.show_with_pending_bookings | translate}}
								</h6>
								<span class="bk-desc-clr fs-14" *ngIf="admnStngs?.merchant_settings?.bookings?.show_tooltip_for_pending_bookings">
									{{admnStngs?.merchant_settings?.bookings?.pending_booking_tooltip_content | translate}}
								</span>
							</div>
							<ng-template *ngTemplateOutlet="onlyDateLayout"></ng-template>
						</div>
					</div>
				</div>
			</ng-container>
			<ng-template #ElseOnlyDate>
				<div class="card tjs-d-border bg-transparent card-body">
					<div class="row">
						<ng-template *ngTemplateOutlet="onlyDateLayout"></ng-template>
					</div>
				</div>
			</ng-template>
		</div>
		<div *ngIf="prefilledData && (prefilledData.status !=6) && (prefilledData.status !=7) && (prefilledData.status !=8)">
			<ng-container *ngIf="(showSelectedProvider && sameProvider && (sameProvider.length) > 0); else ElseOnlyDateBlock">
				<ng-container *ngFor="let provider of sameProvider">
					<bk-selected-provider
						[provider]="provider"
						[ServiceProviderControl]="ServiceProviderControl"
						[section]="section"
						[existingBookingDate]="existingBookingDate"
						[existingArrivalTime]="existingArrivalTime"
						[bookingScheduledDay]="bookingScheduledDay"
						[prefilledData]="prefilledData"
						>
					</bk-selected-provider>
				</ng-container>
			</ng-container>
			<ng-template #ElseOnlyDateBlock>
				<ng-container *ngIf="sameProvider && sameProvider.length > 0">
					<div class="card tjs-d-border bg-transparent" *ngFor="let provider of sameProvider">
						<div class="card-body">
							<div class="row">
								<ng-template *ngTemplateOutlet="onlyDateLayout"></ng-template>
							</div>
						</div>
					</div>
				</ng-container>
			</ng-template>
		</div>
	</ng-template>
</div>
<!-- <ng-template #ElseBlockLoader>
	<div class="bkt-custom-loader align-items-center justify-content-center">
		<div><span translate>Please wait while we are getting the available providers for you</span>
			<div class="spot-loader"></div>
		</div>
	</div>
</ng-template> -->

<!-- Template for Select date -->
<ng-template #selectDateLayout>
	<ng-container [formGroup]="ServiceProviderControl">
		<label class="form-label bk-form-label" for="dateFormControlInput" [id]="section?.sel_date_id" [innerHTML]="section?.sel_date | safeHtml"></label>
		<div class="tjs-form-icon position-relative tjs-pointer" (click)="scheduleCalendarPopup()">
			<input type="text" class="form-control tjs-pointer tjs-bg-white--disabled pe-50 pe-none" id="dateFormControlInput" attr.placeholder="{{'Select a date' | translate}}" [value]="setDateValueCalendar()" [disabled]="true" [readonly]="true" [ngClass]="{'is-invalid': ServiceProviderControl.controls['arrival_date'].hasError('required') && ServiceProviderControl.controls['arrival_date'].touched}">
			<i class="tjsicon-calendar-1 tjs-icon--right position-absolute"></i>
		</div>
		<div class="tjs-error bk-error" *ngIf="ServiceProviderControl.controls['arrival_date'].hasError('required') && ServiceProviderControl.controls['arrival_date'].touched" translate>
			<i class="tjsicon-attention"></i>Please select a date.
		</div>
	</ng-container>
</ng-template>

<!-- Template for Select spot -->
<ng-template #selectSlotLayout>
	<bk-slot
		[BKFrm] = "ServiceProviderControl"
		[section] = "section"
		[selectedDate] = "selectedDate"
		[perDateAvailSpots] = "perDateAvailSpots"
		[availableDates] = "availableDates"
		[notAvailableDates] = "notAvailableDates"
		[availableProviders] = "availableProviders"
		[dateDiscountsArray] = "dateDiscountsArray"
		[dayDiscountsArray] = "dayDiscountsArray"
		[dayDiscountBookings] = "dayDiscountBookings"
		[prefilledData] = "prefilledData"
		[isSpotAvailable]="isSpotAvailable"
		(callScheduleCalendarPopup)="scheduleCalendarPopup()"
		(onSlotChange) = "onSlotChangeFunc($event)">
	</bk-slot>
</ng-template>

<!--Only date layout-->
<ng-template #onlyDateLayout>
	<div class="col-12 col-sm-12 col-md-6" *ngIf="existingBookingDate && existingArrivalTime">
		<h6 class="fw-bold bk-form-label-clr" translate>Scheduled on</h6>
		<!-- <span class="d-block">{{'Date' | translate}}: {{bookingScheduledDay | translate}} - {{showExistingDate(existingBookingDate)}}</span> -->
		<span class="d-block fs-14 bk-desc-clr">{{'Date' | translate}}: {{bookingScheduledDay | translate}} - {{dateServ.formattedDateFromString(existingBookingDate, true, admnStngs?.merchant_settings?.store?.date_format)}}</span>
		<span class="d-block fs-14 bk-desc-clr">{{'Arrival time' | translate}}:
			<span class="dir-ltr">{{utilServ.slotToString(prefilledData.base_location_id, prefilledData.day, prefilledData.arrival_time,prefilledData.spot_type,prefilledData.arrival_window)}}</span>
		</span>
	</div>
</ng-template>

<!-- Apply to all layout-->
<ng-template #forApplyToAll>
	<ng-container [formGroup]="ServiceProviderControl">
		<div class="form-check form-check-inline my-5">
			<input id="future-appointments" class="form-check-input" type="radio" value="true" formControlName="apply_to_all" (change)="changeApplyType()">
			<label for="future-appointments" class="form-check-label bk-form-check-label" translate>For All Future Appointments</label>
		</div>
		<div class="form-check form-check-inline my-5">
			<input id="this-booking" class="form-check-input" type="radio" value="false" formControlName="apply_to_all" (change)="changeApplyType()">
			<label for="this-booking" class="form-check-label bk-form-check-label" translate>This Booking Only</label>
		</div>
	</ng-container>
</ng-template>